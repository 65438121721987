import { numberHelpers } from '@approvalmax/utils';
import { domain } from 'modules/data';

export const checkExpenseLineIsEmpty = (expenseLine: domain.NetSuiteExpenseLineExpenseReport) => {
    return !(
        expenseLine.expenseDate ||
        expenseLine.expenseAccount ||
        expenseLine.currency ||
        expenseLine.exchangeRate ||
        numberHelpers.isNumber(expenseLine.amount)
    );
};
