import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('modules.ProfileModule.ContactSupportPopup', {
    doneButtonText: 'Send',
    titleSubject: 'Subject',
    descriptionSubject: 'Description',
    filesHeader: 'Attachments',
    ButtonText: 'Add an attachment',
    dropHereText: 'Drop the files here',
    popupTitle: 'Contact support',
    doneButtonTitle: 'Send the support request.',
    fileTooBigError: `Sorry, the file "{fileName}" exceeds the maximum size of {maxSize}.`,
    fileExtensionNotSupportedError: 'Sorry, the submitted file type is not supported.',
    maxNumberOfAttachmentsExceeded: 'Sorry, you cannot attach more than 10 files to a comment.',
    validationErrorRequired: 'Please fill in the required fields.',
    postErrorResult: 'Some error occurred',
    postSuccessResult: 'The support request has been successfully submitted',
    subjectPlaceholder: 'Enter a subject...',
    descriptionPlaceholder: 'Please provide a step-by-step description of your issue.',
});
