import { domain } from 'modules/data';
import { createAction, createAsyncAction, createErrorAction, ExtractActions } from 'modules/react-redux';
import { api } from 'services/api';

import { messages } from './profile.messages';

export const ADD_BETA_FEATURE = 'PROFILE/ADD_BETA_FEATURE';

export const addProfileBetaFeature = (newBetaFeature: domain.ProfileBetaFeature) => {
    return createAction(ADD_BETA_FEATURE, {
        newBetaFeature,
    });
};

export const REMOVE_BETA_FEATURE = 'PROFILE/REMOVE_BETA_FEATURE';

export const removeProfileBetaFeature = (featureToRemove: domain.ProfileBetaFeature) => {
    return createAction(REMOVE_BETA_FEATURE, {
        featureToRemove,
    });
};

export const APPLY_SAVED_PROFILE_CHANGES = 'PROFILE/APPLY_SAVED_PROFILE_CHANGES';
export const applySavedProfileChanges = (options: { newProfile: domain.Profile }) =>
    createAction(APPLY_SAVED_PROFILE_CHANGES, { ...options });

export const CHANGE_PASSWORD = 'PROFILE/CHANGE_PASSWORD';
export const CHANGE_PASSWORD_RESPONSE = 'PROFILE/CHANGE_PASSWORD_RESPONSE';
export const CHANGE_PASSWORD_FAILURE = 'PROFILE/CHANGE_PASSWORD_FAILURE';
export const changePassword = (isPasswordSet: boolean, newPassword: string, oldPassword = '') =>
    createAsyncAction({
        request: () => createAction(CHANGE_PASSWORD, {}),

        response: async (request) => {
            if (isPasswordSet) {
                await api.profile.updatePassword({
                    oldPwd: oldPassword,
                    newPwd: newPassword,
                });
            } else {
                await api.profile.setPassword({
                    pwd: newPassword,
                    timeZoneForEmail: window.ApprovalMax.userTimeZone,
                });
            }

            return createAction(CHANGE_PASSWORD_RESPONSE, {
                request,
            });
        },

        failure: (error) => createErrorAction(CHANGE_PASSWORD_FAILURE, error, {}),

        successToast: messages.changePasswordMessage,
    });

export const changePasswordIsSet = () => {
    return createAction(CHANGE_PASSWORD_RESPONSE);
};

export const CHANGE_TWO_FA_IS_ENABLED = 'PROFILE/CHANGE_TWO_FA_IS_ENABLED';
export const changeTwoFaIsEnabled = (twoFaIsEnabled: boolean, me: string) =>
    createAction(CHANGE_TWO_FA_IS_ENABLED, { twoFaIsEnabled, me });

export const CHANGE_TWO_FA_BACKUP_CODES_IS_ENABLED = 'PROFILE/CHANGE_TWO_FA_BACKUP_CODES_IS_ENABLED';
export const changeTwoFaBackupCodesIsEnabled = (isEnabled: boolean) =>
    createAction(CHANGE_TWO_FA_BACKUP_CODES_IS_ENABLED, { isEnabled });

export const CHANGE_TWO_FA_ALTERNATIVE_EMAIL_IS_ENABLED = 'PROFILE/CHANGE_TWO_FA_ALTERNATIVE_EMAIL_IS_ENABLED';
export const changeTwoFaAlternativeEmailIsEnabled = (isEnabled: boolean) =>
    createAction(CHANGE_TWO_FA_ALTERNATIVE_EMAIL_IS_ENABLED, { isEnabled });

export type Action = ExtractActions<
    | typeof addProfileBetaFeature
    | typeof applySavedProfileChanges
    | typeof changePassword
    | typeof changeTwoFaAlternativeEmailIsEnabled
    | typeof changeTwoFaBackupCodesIsEnabled
    | typeof changeTwoFaIsEnabled
    | typeof removeProfileBetaFeature
>;
