import { useSetAtom } from 'jotai';
import { selectors } from 'modules/common';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { userBetaFeaturesArrayState } from 'shared/states';

export const useSetUserBetaFeatures = () => {
    const profile = useSelector(selectors.profile.findProfile);
    const setUserBetaFeatures = useSetAtom(userBetaFeaturesArrayState);

    useEffect(() => {
        if (profile?.betaFeatures) {
            setUserBetaFeatures(profile.betaFeatures);
        }
    }, [profile?.betaFeatures, setUserBetaFeatures]);
};
