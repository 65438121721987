import { Guid, Reference } from '@approvalmax/types';

export enum FieldSystemPurpose {
    AirwallexBatchPaymentStatus = 'AirwallexBatchPaymentStatus',
    AirwallexBeneficiary = 'AirwallexBeneficiary',
    AirwallexCurrency = 'AirwallexCurrency',
    AirwallexFeeAmount = 'AirwallexFeeAmount',
    AirwallexPaymentFee = 'AirwallexPaymentFee',
    AirwallexPaymentItemStatus = 'AirwallexPaymentItemStatus',
    AirwallexPaymentMethod = 'AirwallexPaymentMethod',
    AirwallexPaymentPurpose = 'AirwallexPaymentPurpose',
    AirwallexSourceAmount = 'AirwallexSourceAmount',
    AmaxPayBankAccount = 'AmaxPayBankAccount',
    AmaxPayWallet = 'AmaxPayWallet',
    Amount = 'Amount',
    DearAccount = 'DearAccount',
    DearBrand = 'DearBrand',
    DearLocation = 'DearLocation',
    DearProduct = 'DearProduct',
    DearProductCategory = 'DearProductCategory',
    DearProductFamily = 'DearProductFamily',
    DearSupplier = 'DearSupplier',
    EmailToSupplier = 'EmailToSupplier',
    General = 'General',
    NetSuiteAccount = 'NetSuiteAccount',
    NetSuiteClass = 'NetSuiteClass',
    NetSuiteCurrency = 'NetSuiteCurrency',
    NetSuiteCustom = 'NetSuiteCustom',
    NetSuiteCustomer = 'NetSuiteCustomer',
    NetSuiteDepartment = 'NetSuiteDepartment',
    NetSuiteEmployee = 'NetSuiteEmployee',
    NetSuiteExpenseCategory = 'NetSuiteExpenseCategory',
    NetSuiteItem = 'NetSuiteItem',
    NetSuiteLocation = 'NetSuiteLocation',
    NetSuitePayee = 'NetSuitePayee',
    NetSuiteSubsidiary = 'NetSuiteSubsidiary',
    NetSuiteTaxCode = 'NetSuiteTaxCode',
    NetSuiteVendor = 'NetSuiteVendor',
    NetSuiteDocumentStatus = 'NetSuiteDocumentStatus',
    NoteForApprovers = 'NoteForApprovers',
    QBooksAccount = 'QBooksAccount',
    QBooksClass = 'QBooksClass',
    QBooksCurrency = 'QBooksCurrency',
    QBooksCustom = 'QBooksCustom',
    QBooksInvoiceCustom = 'QBooksInvoiceCustom',
    QBooksCustomer = 'QBooksCustomer',
    QBooksDepartment = 'QBooksDepartment',
    QBooksDocumentStatus = 'QBooksDocumentStatus',
    QBooksEmployee = 'QBooksEmployee',
    QBooksInvoiceCustomer = 'QBooksInvoiceCustomer',
    QBooksManualExchangeRate = 'QBooksManualExchangeRate',
    QBooksPayeeCustomer = 'QBooksPayeeCustomer',
    QBooksPayeeEmployee = 'QBooksPayeeEmployee',
    QBooksPayeeVendor = 'QBooksPayeeVendor',
    QBooksPaymentAccount = 'QBooksPaymentAccount',
    QBooksPaymentMethod = 'QBooksPaymentMethod',
    QBooksPaymentType = 'QBooksPaymentType',
    QBooksPoItem = 'QBooksPoItem',
    QBooksSalesItem = 'QBooksSalesItem',
    QBooksTax = 'QBooksTax',
    QBooksTerm = 'QBooksTerm',
    QBooksVendor = 'QBooksVendor',
    Requester = 'Requester',
    XeroAccount = 'XeroAccount',
    XeroAttachment = 'XeroAttachment',
    XeroBankAccount = 'XeroBankAccount',
    XeroBranding = 'XeroBranding',
    XeroContactAccountNumber = 'XeroContactAccountNumber',
    XeroContactBankAccountDetails = 'XeroContactBankAccountDetails',
    XeroContactCompanyNumber = 'XeroContactCompanyNumber',
    XeroContactDefaultCurrency = 'XeroContactDefaultCurrency',
    XeroContactDirectDial = 'XeroContactDirectDial',
    XeroContactFax = 'XeroContactFax',
    XeroContactMobile = 'XeroContactMobile',
    XeroContactName = 'XeroContactName',
    XeroContactPersons = 'XeroContactPersons',
    XeroContactPhone = 'XeroContactPhone',
    XeroContactPostalAddress = 'XeroContactPostalAddress',
    XeroContactPrimaryPersonEmail = 'XeroContactPrimaryPersonEmail',
    XeroContactPrimaryPersonFirstName = 'XeroContactPrimaryPersonFirstName',
    XeroContactPrimaryPersonLastName = 'XeroContactPrimaryPersonLastName',
    XeroContactPurchaseAccount = 'XeroContactPurchaseAccount',
    XeroContactPurchaseTax = 'XeroContactPurchaseTax',
    XeroContactPurchaseTrackingCategories = 'XeroContactPurchaseTrackingCategories',
    XeroContactSalesAccount = 'XeroContactSalesAccount',
    XeroContactSalesTax = 'XeroContactSalesTax',
    XeroContactSalesTrackingCategories = 'XeroContactSalesTrackingCategories',
    XeroContactSkypeNameOrNumber = 'XeroContactSkypeNameOrNumber',
    XeroContactStreetAddress = 'XeroContactStreetAddress',
    XeroContactTaxNumber = 'XeroContactTaxNumber',
    XeroCurrency = 'XeroCurrency',
    XeroCustomer = 'XeroCustomer',
    XeroDate = 'XeroDate',
    XeroDeliveryAddress = 'XeroDeliveryAddress',
    XeroDeliveryDate = 'XeroDeliveryDate',
    XeroDueDate = 'XeroDueDate',
    XeroItem = 'XeroItem',
    XeroLineAmountType = 'XeroLineAmountType',
    XeroLineDescription = 'XeroLineDescription',
    XeroLineDiscount = 'XeroLineDiscount',
    XeroLineQty = 'XeroLineQty',
    XeroLineTaxAmount = 'XeroLineTaxAmount',
    XeroLineUnitPrice = 'XeroLineUnitPrice',
    XeroManualExchangeRate = 'XeroManualExchangeRate',
    XeroQuoteExpiry = 'XeroQuoteExpiry',
    XeroQuoteNumber = 'XeroQuoteNumber',
    XeroQuoteSummary = 'XeroQuoteSummary',
    XeroQuoteTerms = 'XeroQuoteTerms',
    XeroQuoteTitle = 'XeroQuoteTitle',
    XeroReference = 'XeroReference',
    XeroSupplier = 'XeroSupplier',
    XeroTax = 'XeroTax',
    XeroTracking = 'XeroTracking',
    /**
     * The actual meaning is an ability to remove lines
     * on batch edit (the batch is on approve stage)
     */
    XeroBatchPaymentItems = 'XeroBatchPaymentItems',
}

interface NetSuiteFieldInfo {
    id: string;
    fieldName: string;
    fieldScriptId: string;
    level?: string;
    referenceRecordType: {
        id: string;
        name: string;
    } | null;
    type: string;
    order: number;
}

interface FieldWorkflowItem {
    workflowId: string;
    isActive: boolean;
    isEditable: boolean;
    isMandatory: boolean;
}

export interface Field {
    id: Guid;
    name: string;
    companyId: Guid;
    exactValues: Reference[];
    submitterApplicable: boolean;
    systemPurpose: FieldSystemPurpose;
    type: any;
    netSuiteField?: NetSuiteFieldInfo;
    isArchived?: boolean;
    workflows?: FieldWorkflowItem[];
    referenceRecordFieldSystemPurpose: number | null;
}

export enum DocumentSublistType {
    ItemList = 'ItemList',
    ExpenseList = 'ExpenseList',
}
