import { domain } from 'modules/data';
import { useMemo } from 'react';
import { findDocumentFieldState } from 'shared/helpers';

export const useNetSuiteItemsFieldSettings = (documentFields: domain.DocumentField[] | undefined) =>
    useMemo(
        () => ({
            amortizationEndDate: findDocumentFieldState(documentFields, 'ItemsAmortizationEndDate'),
            amortizationResidual: findDocumentFieldState(documentFields, 'ItemsAmortizationResidual'),
            amortizationSchedule: findDocumentFieldState(documentFields, 'ItemsAmortizationSchedule'),
            amortizationStartDate: findDocumentFieldState(documentFields, 'ItemsAmortizationStartDate'),
            amount: findDocumentFieldState(documentFields, 'ItemsAmount'),
            class: findDocumentFieldState(documentFields, 'ItemsClass'),
            customer: findDocumentFieldState(documentFields, 'ItemsCustomer'),
            projectTask: findDocumentFieldState(documentFields, 'ItemsProjectTask'),
            department: findDocumentFieldState(documentFields, 'ItemsDepartment'),
            description: findDocumentFieldState(documentFields, 'ItemsDescription'),
            expectedReceiptDate: findDocumentFieldState(documentFields, 'ItemsExpectedReceiptDate'),
            isBillable: findDocumentFieldState(documentFields, 'ItemsIsBillable'),
            isClosed: findDocumentFieldState(documentFields, 'ItemsIsClosed'),
            item: findDocumentFieldState(documentFields, 'ItemsItem'),
            location: findDocumentFieldState(documentFields, 'ItemsLocation'),
            pst: findDocumentFieldState(documentFields, 'ItemsPST'),
            pstAmount: findDocumentFieldState(documentFields, 'ItemsPSTAmount'),
            quantity: findDocumentFieldState(documentFields, 'ItemsQuantity'),
            taxAmount: findDocumentFieldState(documentFields, 'ItemsTaxAmount'),
            taxCode: findDocumentFieldState(documentFields, 'ItemsTaxCode'),
            taxRate: findDocumentFieldState(documentFields, 'ItemsTaxRate'),
            unitPrice: findDocumentFieldState(documentFields, 'ItemsUnitPrice'),
            units: findDocumentFieldState(documentFields, 'ItemsUnits'),
            vendorName: findDocumentFieldState(documentFields, 'ItemsVendorName'),
        }),
        [documentFields]
    );
