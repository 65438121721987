import './integrationHeader.scss';

import { AmCircleLogo, QBooksLogo } from '@approvalmax/ui';
import { errorHelpers } from '@approvalmax/utils';
import { selectors, ui } from 'modules/common';
import { domain } from 'modules/data';
import { Cin7Logo, DextLogoIcon, OracleNetsuiteLogo, XeroIcon } from 'modules/sprites';
import { FC, memo } from 'react';
import bemFactory from 'react-bem-factory';

import { messages } from './integrationHeader.messages';

const bem = bemFactory.block('reql-toolbar-integration-header');
const qa = bemFactory.qa('reql-toolbar-integration-header');

interface IntegrationHeaderIconProps {
    request: selectors.types.ExpandedRequest;
}

const getSubmitterSource = (request: selectors.types.ExpandedRequest) => {
    switch (request.origin) {
        case domain.RequestOrigin.Xero:
            return messages.submittedViaXero;

        case domain.RequestOrigin.QBooks:
            return messages.submittedViaQuickBooks;

        case domain.RequestOrigin.ReceiptBank:
            return messages.receiptBank;

        case domain.RequestOrigin.NetSuite:
            return messages.netSuite;

        case domain.RequestOrigin.Dear:
            return messages.dear;

        case domain.RequestOrigin.ApprovalMax:
        case domain.RequestOrigin.PublicApi:
            return messages.submittedBy({
                userName: request.author.displayName,
            });

        case domain.RequestOrigin.Email:
            return messages.submittedBy;

        default:
            throw errorHelpers.assertNever(request.origin);
    }
};

const IntegrationHeaderIcon: FC<IntegrationHeaderIconProps> = (props) => {
    const { request } = props;

    switch (request.origin) {
        case domain.RequestOrigin.Xero:
            return <XeroIcon width={30} height={30} className={bem('avatar', 'system')} />;

        case domain.RequestOrigin.QBooks:
            return <QBooksLogo width={30} height={30} className={bem('avatar', 'system')} />;

        case domain.RequestOrigin.ReceiptBank:
            return <DextLogoIcon width={30} height={30} className={bem('avatar', 'system')} />;

        case domain.RequestOrigin.NetSuite:
            return <OracleNetsuiteLogo width={30} height={30} className={bem('avatar', 'system')} />;

        case domain.RequestOrigin.Dear:
            return <Cin7Logo width={30} height={30} className={bem('avatar', 'system')} />;

        case domain.RequestOrigin.Email:
            return <AmCircleLogo width={30} height={30} />;

        case domain.RequestOrigin.ApprovalMax:
        case domain.RequestOrigin.PublicApi:
            return (
                <ui.UserHover user={request.author}>
                    <ui.Avatar user={request.author} size={30} className={bem('avatar')} />
                </ui.UserHover>
            );

        default:
            throw errorHelpers.assertNever(request.origin);
    }
};

const IntegrationHeader: FC<IntegrationHeaderIconProps> = (props) => {
    const { request } = props;

    return (
        <div className={bem()}>
            <IntegrationHeaderIcon request={request} />

            <div className={bem('requested-by-block')}>
                <div className={bem('source')} data-qa={qa('source')}>
                    {getSubmitterSource(request)}
                </div>

                <div className={bem('company-name')} data-qa={qa('company-name')} title={request.company.displayName}>
                    {request.company.displayName}
                </div>
            </div>
        </div>
    );
};

export default memo(IntegrationHeader);
