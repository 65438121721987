import { Box, Flex, Text } from '@approvalmax/ui/src/components';
import { intl } from '@approvalmax/utils';
import { memo } from 'react';

import { messages } from './DifferenceWarning.messages';
import { DifferenceWarningProps } from './DifferenceWarning.types';

export const DifferenceWarning = memo<DifferenceWarningProps>((props) => {
    const { value1, value2, label1, label2, currency, description } = props;

    const price1 = typeof value1 == 'number' ? intl.formatCurrency(value1, currency) : value1;
    const price2 = typeof value2 == 'number' ? intl.formatCurrency(value2, currency) : value2;

    return (
        <Box spacing='8' width={220} color='white100'>
            <Flex spacing='8' direction='column'>
                <Text font='body' fontSize='xsmall' color='midnight100'>
                    {description}
                </Text>

                <Flex spacing='4' direction='column'>
                    <Flex justifyContent='space-between' spacing='8' wrap={false}>
                        <Text font='body' fontSize='xsmall' color='midnight70'>
                            {label1}
                        </Text>

                        <Text font='body' fontSize='xsmall' color='midnight100' fontWeight='bold' wrap={false}>
                            {price1}
                        </Text>
                    </Flex>

                    <Flex justifyContent='space-between' spacing='8' wrap={false}>
                        <Text font='body' fontSize='xsmall' color='midnight70'>
                            {label2}
                        </Text>

                        <Text font='body' fontSize='xsmall' color='midnight100' fontWeight='bold' wrap={false}>
                            {price2}
                        </Text>
                    </Flex>

                    {typeof value1 == 'number' && typeof value2 == 'number' && (
                        <Flex justifyContent='space-between' spacing='8' wrap={false}>
                            <Text font='body' fontSize='xsmall' color='midnight70'>
                                {messages.difference}
                            </Text>

                            <Text font='body' fontSize='xsmall' fontWeight='bold' color='red80' wrap={false}>
                                {intl.formatCurrency(value2 - value1, currency)}
                            </Text>
                        </Flex>
                    )}
                </Flex>
            </Flex>
        </Box>
    );
});

DifferenceWarning.displayName = 'DifferenceWarning';
