import { selectors } from 'modules/common';
import { FC, memo } from 'react';

import FieldEntry from '../../../../../components/FieldEntry';
import { messages } from './RequestContactAddressSection.messages';
import {
    Container,
    HeaderContaier,
    SameAsDeliveryAddress,
    StyledFieldEntryWrapper,
} from './RequestContactAddressSection.styles';
import { RequestContactAddressSectionProps } from './RequestContactAddressSection.types';

const RequestContactAddressSection: FC<RequestContactAddressSectionProps> = memo((props) => {
    const { address, title, sameAsBillingAddress } = props;

    if (sameAsBillingAddress) {
        return (
            <div>
                <HeaderContaier>
                    <span>{title}</span>

                    <SameAsDeliveryAddress>{messages.sameAsBillingAddress}</SameAsDeliveryAddress>
                </HeaderContaier>
            </div>
        );
    }

    if (!address) {
        return null;
    }

    const addressDisplayValue = selectors.ui.getDisplayAddress(address.address, address.postalCode);

    return (
        <div>
            <HeaderContaier>
                <span>{title}</span>
            </HeaderContaier>

            <Container>
                {address.country && (
                    <StyledFieldEntryWrapper>
                        <FieldEntry title={messages.country} value={address.country} />
                    </StyledFieldEntryWrapper>
                )}

                {address.city && (
                    <StyledFieldEntryWrapper>
                        <FieldEntry title={messages.cityTown} value={address.city} />
                    </StyledFieldEntryWrapper>
                )}

                {address.region && (
                    <StyledFieldEntryWrapper>
                        <FieldEntry title={messages.stateRegion} value={address.region} />
                    </StyledFieldEntryWrapper>
                )}

                {addressDisplayValue && (
                    <StyledFieldEntryWrapper>
                        <FieldEntry title={messages.address} value={addressDisplayValue} />
                    </StyledFieldEntryWrapper>
                )}

                {address.attentionTo && (
                    <StyledFieldEntryWrapper>
                        <FieldEntry title={messages.attention} value={address.attentionTo} />
                    </StyledFieldEntryWrapper>
                )}
            </Container>
        </div>
    );
});

export default RequestContactAddressSection;
