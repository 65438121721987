import { CloseIcon } from '@approvalmax/ui';
import { Box, Button, Flex, openDrawerState, pinDrawerState, Spacing, Text } from '@approvalmax/ui/src/components';
import { useAccess } from 'app/(workspace)/customerFeedback/resources/hooks/useAccess';
import { useAtomValue, useSetAtom } from 'jotai';
import { memo, useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { amplitudeService } from 'services/amplitude';

import feedbackPreview from '!!file-loader!./asserts/feedback-preview.png';

import { path } from './FeedbackPromotion.constants';
import { useLocalStorage } from './FeedbackPromotion.hooks';
import { messages } from './FeedbackPromotion.messages';
import { Picture } from './FeedbackPromotion.styles';

export const FeedbackPromotion = memo(() => {
    const isDrawerPinned = useAtomValue(pinDrawerState);
    const setIsDrawerOpen = useSetAtom(openDrawerState);
    const { isHideInStorage, setIsHiddenInStorage } = useLocalStorage();

    const [isVisible, setIsVisible] = useState(!isHideInStorage);
    const hasAccess = useAccess();

    const onClose = useCallback(() => {
        setIsHiddenInStorage(true);
        setIsVisible(false);
    }, [setIsHiddenInStorage]);

    const onVote = useCallback(() => {
        setIsDrawerOpen(false);

        amplitudeService.sendData('navigation: click on canny feedback portal', {
            'action source': 'drawer',
        });
    }, [setIsDrawerOpen]);

    if (!isVisible || !hasAccess) return null;

    return (
        <>
            <Box spacing='12' radius='small' color={isDrawerPinned ? 'white100' : 'silver80'}>
                <Flex spacing='8' alignItems='center' justifyContent='space-between'>
                    <Flex wrap={false} alignItems='center' spacing='4'>
                        <Button icon onClick={onClose} variant='text' color='blue100'>
                            <CloseIcon width={16} height={16} />
                        </Button>

                        <Text font='label' fontWeight='medium' as='h4'>
                            {messages.title}
                        </Text>
                    </Flex>

                    <Flex shrink={0}>
                        <Button as={Link} to={path} onClick={onVote} color='green80' size='xsmall'>
                            {messages.voteNow}
                        </Button>
                    </Flex>
                </Flex>

                <Spacing height={12} />

                <Link to={path} onClick={onVote}>
                    <Picture>
                        <img src={feedbackPreview} alt={messages.title} />
                    </Picture>
                </Link>
            </Box>

            <Spacing height={12} />
        </>
    );
});

FeedbackPromotion.displayName = 'FeedbackPromotion';
