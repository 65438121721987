import { Identifiable } from '@approvalmax/types';

import { messages } from './getSelectorSuggestedItems.messages';

/**
 * Sorts items, placing suggested items at the beginning of the list and adding the "Suggested" label to them
 */
export const getSelectorSuggestedItems = <T extends Identifiable>(items: T[], suggestedItems: string[]) => {
    if (!suggestedItems.length) return items;

    const suggestedItemsSet = new Set(suggestedItems);

    const prioritizedItems: Array<T & { subName: string }> = [];
    const nonPrioritizedItems: T[] = [];

    items.forEach((item) => {
        if (suggestedItemsSet.has(item.id)) {
            prioritizedItems.push({ ...item, subName: messages.suggested });
        } else {
            nonPrioritizedItems.push(item);
        }
    });

    return [...prioritizedItems, ...nonPrioritizedItems];
};
