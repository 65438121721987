import { Button, Grid, Link, List, Popup, Text } from '@approvalmax/ui/src/components';
import { typeGuardHelpers } from '@approvalmax/utils';
import { selectors } from 'modules/common';
import { domain } from 'modules/data';
import { FC, memo } from 'react';
import { getPath, Path } from 'urlBuilder';

import workflowRestartScreenshot from '../../assets/workflowRestartScreenshot.png';
import { messages } from './GroupEditSuccessPopupContent.messages';
import { GroupEditSuccessPopupContentProps } from './GroupEditSuccessPopupContent.types';

export const GroupEditSuccessPopupContent: FC<GroupEditSuccessPopupContentProps> = memo((props) => {
    const { onClose, groupOfAccounts } = props;

    return (
        <>
            <Popup.Header
                title={messages.title({ groupName: groupOfAccounts.name })}
                actions={
                    <Button onClick={onClose} color='blue80' size='large'>
                        {messages.confirmButton}
                    </Button>
                }
            />

            <Popup.Body spacing='32'>
                <Grid rowGap={12}>
                    <Text font='body' fontSize='medium'>
                        {messages.subTitle}
                    </Text>

                    <List listStyle='inside'>
                        {groupOfAccounts.workflows.map((workflow) => (
                            <List.Item key={workflow.id}>
                                <Link
                                    font='body'
                                    href={getPath(Path.workflow, workflow.companyId, workflow.id)}
                                    target='_blank'
                                >
                                    {selectors.template.getTemplateWorkflowNameByCode(
                                        typeGuardHelpers.isValueOfObject(
                                            workflow.integrationCode,
                                            domain.IntegrationCode
                                        )
                                            ? workflow.integrationCode
                                            : null
                                    )}
                                </Link>
                            </List.Item>
                        ))}
                    </List>

                    <Text font='body' fontSize='medium'>
                        {messages.description}
                    </Text>

                    <img width='100%' src={workflowRestartScreenshot} alt={messages.imageAlt} />
                </Grid>
            </Popup.Body>
        </>
    );
});

GroupEditSuccessPopupContent.displayName = 'GroupEditSuccessPopupContent';
