import { RequestMutationOptions } from '@approvalmax/data';
import { useMutate } from 'shared/data';

import { requestsApiPaths } from '../paths';
import type { CancelAmaxPayBill } from './useCancelAmaxPayBill.types';

export const useCancelAmaxPayBill = (mutationOptions?: RequestMutationOptions<CancelAmaxPayBill>) => {
    return useMutate(requestsApiPaths.amaxPayBillCancel, {
        mutationOptions,
        apiVersion: 'v1',
    });
};
