import { useSetAtom } from 'jotai';
import { selectors } from 'modules/common';
import { ActivePopup } from 'modules/company/reducers/moduleReducer';
import { getActivePopup } from 'modules/company/selectors/moduleSelectors';
import { domain } from 'modules/data';
import { useSelector } from 'modules/react-redux';
import moment from 'moment';
import { useEffect } from 'react';
import { usePrevious } from 'react-use';

import { workflowsWizardAllowedTemplatesState } from '../components/WorkflowWizardPopup/WorkflowWizardPopup.states';
import { possibleQbooksWorkflowsForWizard, possibleXeroWorkflowsForWizard } from '../constants';
import { useCanShowWorkflowWizardPopup } from './useCanShowWorkflowWizardPopup';

const wizardVisibleAfterPopups = [
    ActivePopup.XeroDemoOrganisationPopup,
    ActivePopup.SelectPlanPopup,
    ActivePopup.SelectSubscriptionPopup,
];

export const useWorkflowWizard = (
    templates: selectors.types.ExpandedTemplate[],
    company: selectors.types.ExpandedCompany
) => {
    const canShowWorkflowWizardPopup = useCanShowWorkflowWizardPopup();
    const tryOpenWorkflowWizardCount = useSelector(selectors.ui.getTryOpenWorkflowWizardCount);
    const activePopup = useSelector(getActivePopup);
    const prevActivePopup = usePrevious(activePopup);

    const setWorkflowsWizardState = useSetAtom(workflowsWizardAllowedTemplatesState);

    useEffect(() => {
        if (!canShowWorkflowWizardPopup) {
            return;
        }

        if (activePopup || (prevActivePopup && !wizardVisibleAfterPopups.includes(prevActivePopup))) {
            return;
        }

        if (
            !templates.every(
                (template) =>
                    template.isActual &&
                    !template.enabled &&
                    (!template.modifiedDate || moment(template.modifiedDate).isSame(template.createDate, 'seconds'))
            )
        ) {
            return;
        }

        const allowedWizardTemplates: selectors.types.ExpandedTemplate[] = [];

        const allPossibleIntegrateWorkflows =
            company.integration?.integrationType === domain.IntegrationType.Xero
                ? possibleXeroWorkflowsForWizard
                : possibleQbooksWorkflowsForWizard;

        allPossibleIntegrateWorkflows.forEach((code) => {
            const template = templates.find((wf) => wf.integrationCode === code);

            if (template) {
                allowedWizardTemplates.push(template);
            }
        });

        if (allowedWizardTemplates.length > 0) {
            setWorkflowsWizardState(allowedWizardTemplates);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activePopup, tryOpenWorkflowWizardCount]);
};
