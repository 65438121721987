import { ApiProvider } from '@approvalmax/data';
import { ThemeProvider } from '@approvalmax/theme';
import { intl } from '@approvalmax/utils';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Provider as JotaiProvider } from 'jotai';
import { globalQueryClient } from 'modules/common';
import { persistor, store } from 'modules/store';
import { ComponentType } from 'react';
import { IntlProvider } from 'react-intl';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { jotaiStore } from 'shared/store';

import { GlobalErrorBoundary, Head, HistoryProvider } from '../components';

export const withProviders = (App: ComponentType) => {
    return () => (
        <JotaiProvider store={jotaiStore}>
            <ThemeProvider>
                <Provider store={store}>
                    <PersistGate loading={null} persistor={persistor}>
                        <IntlProvider locale={intl.locale} messages={window.localizationMap}>
                            <ApiProvider>
                                <QueryClientProvider client={globalQueryClient}>
                                    <HistoryProvider>
                                        {({ history }) => (
                                            <Router history={history}>
                                                <Head />

                                                <GlobalErrorBoundary>
                                                    <App />
                                                </GlobalErrorBoundary>
                                            </Router>
                                        )}
                                    </HistoryProvider>

                                    <ReactQueryDevtools initialIsOpen={false} />
                                </QueryClientProvider>
                            </ApiProvider>
                        </IntlProvider>
                    </PersistGate>
                </Provider>
            </ThemeProvider>
        </JotaiProvider>
    );
};
