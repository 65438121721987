import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('pages.RequestList.NetSuiteRequestCard.RequestCardVRA', {
    transactionNumberFieldText: 'Transaction number',
    dateFieldText: 'Date',
    memoFieldText: 'Memo',
    departmentFieldText: 'Department',
    classFieldText: 'Class',
    locationFieldText: 'Location',
});
