import { RequestParams, RequestQueryOptions, useInvalidateQuery } from '@approvalmax/data';
import { useGet } from 'shared/data';

import { groupsOfXeroAccountsApiPaths } from '../paths';
import { GetGroupsOfXeroAccounts } from './useGetGroupsOfXeroAccounts.types';

export const useGetGroupsOfXeroAccounts = (
    params?: RequestParams<GetGroupsOfXeroAccounts>,
    queryOptions?: RequestQueryOptions<GetGroupsOfXeroAccounts>
) => {
    return useGet(groupsOfXeroAccountsApiPaths.groupsOfXeroAccounts, {
        params,
        queryOptions,
        apiVersion: 'v2',
        method: 'get',
    });
};

export const useInvalidateGetGroupsOfXeroAccounts = () => {
    return useInvalidateQuery<RequestParams<GetGroupsOfXeroAccounts>>(
        groupsOfXeroAccountsApiPaths.groupsOfXeroAccounts
    );
};
