import uniq from 'lodash/uniq';
import { constants } from 'modules/common';
import { domain } from 'modules/data';
import {
    useNetSuiteAttachmentsFieldSettings,
    useNetSuiteExpenseReportExpenseLinesFieldSettings,
    useNetSuiteExpenseReportFieldSettings,
} from 'shared/hooks';

import {
    CustomFieldsState,
    NetSuiteExpenseLineExtendedExpenseReport,
    NetSuiteFieldsExpenseReport,
} from '../../../data/types';
import { validateCustomFields } from '../../../helpers';
import { isFieldMandatory } from '../../../NetSuiteRequestForm/NetSuiteRequestForm.helpers';
import { messages } from '../../NetSuiteExpenseReportRequestForm.messages';
import { checkExpenseLineIsEmpty } from '../checkExpenseLineIsEmpty/checkExpenseLineIsEmpty';
import { checkExpenseLineIsInvalid } from '../checkExpenseLineIsInvalid/checkExpenseLineIsInvalid';
import { checkExpenseLinesRefLineNumbersUnique } from '../checkExpenseLinesRefLineNumbersUnique/checkExpenseLinesRefLineNumbersUnique';
import { validateAccountForCorporateCard } from '../validateAccountForCorporateCard/validateAccountForCorporateCard';
import { validateExpenseLines } from '../validateExpenseLines/validateExpenseLines';

export const validateExpenseReportForm = (data: {
    fieldValues: Partial<NetSuiteFieldsExpenseReport>;
    customFieldsValues: CustomFieldsState;
    customFieldsList: domain.Field[];
    workflowId: string;
    expenseLines: NetSuiteExpenseLineExtendedExpenseReport[];
    totalExpenses: number;
    advanceToApply: number;
    totalReimbursableAmount: number;
    defaultRate?: number;
    fieldSettings: ReturnType<typeof useNetSuiteExpenseReportFieldSettings>;
    fieldsSettingsExpenseLine: ReturnType<typeof useNetSuiteExpenseReportExpenseLinesFieldSettings>;
    companyCurrency: string;
    requestCurrencyISOCode: string | null;
    attachmentsFieldSettings: ReturnType<typeof useNetSuiteAttachmentsFieldSettings>;
    attachments: domain.RequestAttachment[];
}): string[] => {
    const {
        fieldValues,
        customFieldsValues,
        customFieldsList,
        workflowId,
        expenseLines,
        totalExpenses,
        advanceToApply,
        totalReimbursableAmount,
        fieldSettings,
        fieldsSettingsExpenseLine,
        defaultRate,
        companyCurrency,
        requestCurrencyISOCode,
        attachmentsFieldSettings,
        attachments,
    } = data;

    const errors: string[] = [];

    const hasEmptyExpenseLines = expenseLines.some(checkExpenseLineIsEmpty);
    const hasInvalidExpenseLines = expenseLines.some((expenseLine) =>
        checkExpenseLineIsInvalid(expenseLine, fieldsSettingsExpenseLine)
    );

    const areExpenseLinesRefLineNumbersUnique = checkExpenseLinesRefLineNumbersUnique(expenseLines);

    if (!areExpenseLinesRefLineNumbersUnique) {
        errors.push(messages.errorRefLineNumbersNotUnique);
    }

    if (isFieldMandatory(attachmentsFieldSettings.attachments) && attachments.length === 0) {
        errors.push(messages.errorRequiredFields);
    }

    if (
        (fieldSettings.employee === domain.DocumentFieldState.Mandatory && !fieldValues.employee) ||
        (fieldSettings.date === domain.DocumentFieldState.Mandatory && !fieldValues.date) ||
        (fieldSettings.currency === domain.DocumentFieldState.Mandatory && !fieldValues.currency) ||
        hasInvalidExpenseLines ||
        hasEmptyExpenseLines
    ) {
        errors.push(messages.errorRequiredFields);
    }

    if (
        (fieldSettings.memo === domain.DocumentFieldState.Mandatory && !fieldValues.memo?.trim()) ||
        (fieldSettings.advance === domain.DocumentFieldState.Mandatory && fieldValues.advance == null) ||
        (fieldSettings.dueDate === domain.DocumentFieldState.Mandatory && !fieldValues.dueDate) ||
        (fieldSettings.department === domain.DocumentFieldState.Mandatory && !fieldValues.department) ||
        (fieldSettings.class === domain.DocumentFieldState.Mandatory && !fieldValues.class) ||
        (fieldSettings.location === domain.DocumentFieldState.Mandatory && !fieldValues.location) ||
        (fieldSettings.accountForCorporateCardExpenses === domain.DocumentFieldState.Mandatory &&
            !fieldValues.accountForCorporateCardExpenses)
    ) {
        errors.push(messages.errorRequiredFields);
    }

    if (fieldValues.memo && fieldValues.memo.length > constants.netSuiteConstants.descriptionMaxLength) {
        errors.push(
            messages.errorMemoMoreThanLimit({ maxCharsCount: constants.netSuiteConstants.descriptionMaxLength })
        );
    }

    const errorsCorporateCard = validateAccountForCorporateCard(fieldValues, expenseLines);

    errors.push(...errorsCorporateCard);

    const errorsExpenseLines = validateExpenseLines(expenseLines, customFieldsList, workflowId);

    errors.push(...errorsExpenseLines);

    if (totalReimbursableAmount < 0) {
        errors.push(messages.errorTotalReimbursableAmountLessZero);
    }

    if (totalExpenses < 0) {
        errors.push(messages.errorTotalExpensesLessZero);
    }

    if (advanceToApply < 0) {
        errors.push(messages.errorAdvanceToApplyLessZero);
    }

    if (
        companyCurrency !== requestCurrencyISOCode &&
        typeof defaultRate !== 'number' &&
        typeof fieldValues.exchangeRate !== 'number'
    ) {
        errors.push(messages.errorExchangeRate);
    }

    validateCustomFields({
        customFieldsValues: Object.values(customFieldsValues),
        customFieldsLevels: ['Header', 'Header And Lines'],
        customFieldsList,
        workflowId,
        errors,
    });

    return uniq(errors);
};
