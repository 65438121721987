import { domain } from 'modules/data';
import { GetNetSuiteCustomer } from 'shared/data/webApp/v2';

import { NetSuiteCustomerAddress } from '../../data/types';
import { ValueOrBillingAddress } from './addresses.types';

export const mapAddress = <Value extends domain.BillingAddress | null>(
    address: Value
): ValueOrBillingAddress<Value> => {
    if (!address) {
        return null as ValueOrBillingAddress<Value>;
    }

    return {
        id: address.id,
        fullAddress: address.fullAddress?.replace(/<br>|\n/g, ' ') || null,
    } as ValueOrBillingAddress<Value>;
};

export const mapCustomerAddresses = (addresses: GetNetSuiteCustomer['response']['addresses'] | undefined) => {
    if (!addresses) {
        return [];
    }

    return addresses.reduce<NetSuiteCustomerAddress[]>((addresses, address) => {
        if (address.internalId) {
            addresses.push({
                ...mapAddress({
                    id: address.internalId,
                    fullAddress: address.fullAddress,
                }),
                isDefaultBilling: address.isDefaultBilling ?? false,
                isDefaultShipping: address.isDefaultShipping ?? false,
            });
        }

        return addresses;
    }, []);
};
