import { Mods, mods } from '@approvalmax/theme';
import { StyledMods } from '@styled-kit/mods';
import styled from 'styled-components';

/**
 * This approach is needed coz some forms are separated to several tabs but still need to behave as a single form
 * with validation, submitting etc. It can not be done with JSX only.
 */
export const ContentTab = styled.div<StyledMods<Mods<'active'>>>`
    display: none;

    ${mods.active.true`
        display: block;
    `}
`;
