import { useSetAtom } from 'jotai';
import React, { useCallback, useEffect, useMemo } from 'react';

import { isReactFragment } from './SplitView.helpers';
import { openSplitViewState } from './SplitView.states';
import { HideResizerHook } from './SplitView.types';

export const useHideResizer = ({ openState, fullscreenState, rootRef }: HideResizerHook) => {
    useEffect(() => {
        let display = 'none';

        if (openState && !fullscreenState) {
            display = 'initial';
        }

        if (rootRef.current) {
            const resizerElements = rootRef.current.querySelectorAll<HTMLDivElement>('.sash');

            resizerElements.forEach((resizerElement) => {
                resizerElement.style.display = display;
            });
        }
    }, [fullscreenState, openState, rootRef]);
};

export const useGetChildrenWithCalculatedProps = (childArray: ReturnType<typeof React.Children.toArray>) =>
    useMemo(() => {
        /**
         * We have to use `childArray.map` instead of `childArray[childArray.lenght - 1]` in this case  because
         * TS does not work correctly with dynamic indexes
         */
        const childArrayWithCalculatedProps = childArray.map((child, index) => {
            if (index === childArray.length - 1 && React.isValidElement(child) && !isReactFragment(child)) {
                return React.cloneElement(child, { last: true });
            }

            return child;
        });

        if (
            React.isValidElement(childArrayWithCalculatedProps[0]) &&
            !isReactFragment(childArrayWithCalculatedProps[0])
        ) {
            childArrayWithCalculatedProps[0] = React.cloneElement(childArrayWithCalculatedProps[0], { first: true });
        }

        return childArrayWithCalculatedProps;
    }, [childArray]);

export const useHandleKeyDown = () => {
    const setOpenState = useSetAtom(openSplitViewState);

    const onEscKeyPressed = useCallback(() => {
        setOpenState(false);
    }, [setOpenState]);

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                onEscKeyPressed();
            }
        };

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [onEscKeyPressed]);
};
