import { ForwardedRef, forwardRef, memo, ReactElement, RefAttributes } from 'react';

import { Controller } from './components';
import Content from './components/Content/Content';
import { SelectProvider } from './Select.context';
import { ChildrenComponents, SelectProps } from './Select.types';

/**
 * Select component allows a user to select one or more items from a list of options.
 */
const Select = memo(
    forwardRef(
        <
            Item extends Record<string, any>,
            Multiple extends boolean | undefined = undefined,
            NoInput extends boolean = false,
        >(
            props: SelectProps<Item, Multiple, NoInput>,
            ref: ForwardedRef<HTMLDivElement>
        ) => {
            return (
                <SelectProvider>
                    <Content {...props} ref={ref} />
                </SelectProvider>
            );
        }
    )
) as unknown as (<
    Item extends Record<string, any>,
    Multiple extends boolean | undefined = undefined,
    NoInput extends boolean = false,
>(
    props: SelectProps<Item, Multiple, NoInput> & RefAttributes<HTMLDivElement>
) => ReactElement) &
    ChildrenComponents & { displayName?: string };

Select.Controller = Controller;

Select.displayName = 'Select';

export default Select;
