import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('app.WorkflowId.selectors.conditionSelector', {
    participant_rules_preview_amount_pattern: '{name} is {amount}',
    participant_rules_preview_total_amount_text: 'Total amount',
    participant_rules_preview_net_amount_text: 'Net amount',
    participant_rules_preview_amount_pattern_within: 'over or equal to {greaterEq} and under {less} {currency}',
    participant_rules_preview_amount_pattern_below: 'under {less} {currency}',
    participant_rules_preview_amount_pattern_above: 'over or equal to {greaterEq} {currency}',
    submitter_rules_preview_field_pattern: '{fieldName} matches {values}',
    submitter_rules_preview_not_field_pattern: '{fieldName} does not match {values}',
});
