import type { ApiError } from '@approvalmax/types';
import { routerHelpers } from '@approvalmax/utils';
import { useQuery } from '@tanstack/react-query';

import { Env } from '../configs';
import { getApiInstance, getApiQueryMethod, getApiUrl } from '../helpers/getApi';
import { getAuthorization } from '../helpers/getAuthorization';
import { getQueryKey } from '../helpers/getQueryKey';
import { useHandleApiCall } from '../hooks/useHandleApiCall';
import { ApiSource, RequestHeaders, UseGetOptions } from '../types';

export const createUseGet = (defaultApiSource: ApiSource, defaultEnv: Env, getHeaders?: () => RequestHeaders) => {
    return <ResponseData>(path: string, options: UseGetOptions<ResponseData> = {}) => {
        const {
            apiSource = defaultApiSource,
            apiVersion = 'v1',
            method,
            params,
            queryOptions,
            responseType,
            mapToCamelCase,
            skipToastForErrorCodes,
            accessToken,
            paramsSerializer,
        } = options;

        const baseURL = getApiUrl(apiSource, defaultEnv, apiVersion);
        const url = routerHelpers.testPathByParams(path, params?.path)
            ? routerHelpers.pathToUrl(path, params?.path)
            : '';
        const Authorization = getAuthorization(defaultApiSource, defaultEnv) ?? accessToken;
        const handleApiCall = useHandleApiCall<ResponseData>({ mapToCamelCase, skipToastForErrorCodes });
        const apiFn = getApiInstance(apiSource, apiVersion, getHeaders?.());
        const apiMethod = method ?? getApiQueryMethod(apiSource)[apiVersion];
        const data = method === 'get' || !params?.query ? {} : params?.query;

        return useQuery<ResponseData, ApiError>(
            getQueryKey(url, params?.query),
            () =>
                handleApiCall(
                    apiFn({
                        baseURL,
                        url,
                        method: apiMethod,
                        responseType,
                        headers: { ...(Authorization && { Authorization }) },
                        params: params?.query,
                        data,
                        paramsSerializer,
                    })
                ),
            {
                ...queryOptions,
                enabled: (queryOptions?.enabled ?? true) && Boolean(url),
                meta: { meta: queryOptions?.meta, path, params, data },
            }
        );
    };
};
