import { selectors } from 'modules/common';
import { State } from 'modules/data';
import { helpers as pageHelpers } from 'modules/page';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { messages } from './useNonExistentCompanyError.messages';

export const useNonExistentCompanyError = (companyId: string) => {
    const company = useSelector((state: State) => selectors.company.findCompanyById(state, companyId));

    useEffect(() => {
        if (!company) {
            pageHelpers.redirectAndNotify({ errorMessage: messages.companyNotFound });
        }
    }, [company]);

    return {
        company,
    };
};
