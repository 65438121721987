import { MutableRefObject, Ref } from 'react';

/**
 * Merge multiple refs into a single ref
 */
export const mergeRefs = <Node>(...refs: Array<Ref<Node> | undefined>): Ref<Node> => {
    return (node: Node) => {
        refs.forEach((ref) => {
            if (!ref) return;

            if (typeof ref === 'function') {
                ref(node);
            } else {
                (ref as MutableRefObject<Node | null>).current = node;
            }
        });
    };
};
