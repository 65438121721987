import { Button, ColumnDefinition } from '@approvalmax/ui/src/components';
import { intl } from '@approvalmax/utils';
import { domain } from 'modules/data';
import { ConfirmationDropdown } from 'shared/components';
import { getPath, Path } from 'urlBuilder';

import { messages } from './QBooksMatchingInfoContent.messages';
import { getColumnDefinitionsParams, QBooksMatchingLineItem } from './QBooksMatchingInfoContent.types';

const descriptionColumn: ColumnDefinition<QBooksMatchingLineItem> = {
    id: 'description',
    name: messages.descriptionColumnName,
    value: (li) => li.description,
};

const taxCodeNameColumn: ColumnDefinition<QBooksMatchingLineItem> = {
    id: 'taxCodeName',
    name: messages.taxColumnName,
    value: (li) => (li.taxCode ? li.taxCode.text : null),
};

export const getColumnDefinitions = ({
    request,
    handleMatchingRemove,
    matchingRemoveButtonDisabled,
    isAccountLineItem,
}: getColumnDefinitionsParams) => {
    const { details, currency } = request;

    const hasTaxes = details.lineAmountType !== domain.LineAmountType.NoTax;
    const isBill = request.integrationCode === domain.IntegrationCode.QBooksBill;
    const isPO = request.integrationCode === domain.IntegrationCode.QBooksPo;
    const grossRate = details.lineAmountType === domain.LineAmountType.TaxInclusive;

    const columnDefinitions: ColumnDefinition<QBooksMatchingLineItem>[] = [];

    if (isPO) {
        columnDefinitions.push(
            {
                id: 'bill',
                name: messages.billColumnName,
                value: (li) => (
                    <a
                        target='_blank'
                        title={`${li.name}\n${messages.totalAmount({ totalAmount: li.totalAmount })}`}
                        href={getPath(Path.request, li.lineItemId, request.companyId)}
                    >
                        {li.name}
                    </a>
                ),
            },
            {
                id: 'date',
                name: messages.dateColumnName,
                value: (li) => li.date,
            }
        );
    }

    if (isAccountLineItem) {
        columnDefinitions.push(
            {
                id: 'account',
                name: messages.accountColumnName,
                value: (li: QBooksMatchingLineItem<domain.QBooksAccountLineItem>) =>
                    li.account ? li.account.text : null,
            },
            descriptionColumn
        );

        if (hasTaxes) {
            columnDefinitions.push(taxCodeNameColumn);
        }
    } else {
        columnDefinitions.push(
            {
                id: 'item',
                name: messages.itemColumnName,
                value: (li: QBooksMatchingLineItem<domain.QBooksLineItem>) => (li.item ? li.item.text : null),
            },
            descriptionColumn,
            {
                id: 'qty',
                textAlign: 'right',
                headerTextAlign: 'right',
                name: messages.qtyColumnName,
                value: (li: QBooksMatchingLineItem<domain.QBooksLineItem>) => intl.formatNumber(li.qty || 0, 'auto'),
            },
            {
                id: 'unitPrice',
                textAlign: 'right',
                headerTextAlign: 'right',
                name: messages.unitPriceColumnName,
                value: (li: QBooksMatchingLineItem<domain.QBooksLineItem>) => {
                    const value = grossRate ? li.unitPriceGross : li.unitPrice;

                    return intl.formatNumber(value || 0, 'auto');
                },
            }
        );

        if (hasTaxes) {
            columnDefinitions.push(taxCodeNameColumn);
        }
    }

    columnDefinitions.push(
        {
            id: 'customerName',
            name: messages.customerColumnName,
            value: (li) => (li.customer ? li.customer.text : null),
        },
        {
            id: 'className',
            name: messages.classColumnName,
            value: (li) => (li.class ? li.class.text : null),
        },
        {
            id: 'amount',
            textAlign: 'right',
            headerTextAlign: 'right',
            name: messages.amountColumnName({
                currency,
            }),
            value: (li) => intl.formatNumber(li.amount || 0),
        }
    );

    if (isBill) {
        columnDefinitions.push({
            id: 'remaining',
            textAlign: 'right',
            headerTextAlign: 'right',
            name: messages.remainingColumnName({
                currency,
            }),
            value: (li) => intl.formatNumber(li.remainingBalance || 0),
        });
    }

    if (handleMatchingRemove) {
        columnDefinitions.push({
            id: 'remove',
            name: '',
            textAlign: 'right',
            value: () => (
                <ConfirmationDropdown title={messages.deleteConfirmationText} onConfirm={handleMatchingRemove}>
                    <Button
                        noPadding
                        variant='text'
                        size='small'
                        color='red100'
                        title={messages.deleteButtonTitle}
                        disabled={matchingRemoveButtonDisabled}
                    >
                        {messages.remove}
                    </Button>
                </ConfirmationDropdown>
            ),
        });
    }

    return columnDefinitions;
};
