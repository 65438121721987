import trim from 'lodash/trim';
import { forwardRef, ForwardRefExoticComponent, memo, MemoExoticComponent, RefAttributes } from 'react';
import { useToggle } from 'react-use';

import { VisibilityOffIcon, VisibilityOnIcon } from '../../icons';
import { TextField } from '../index';
import { Controller } from './components';
import { ChildrenComponents, PasswordFieldProps } from './PasswordField.types';

export const PasswordField = memo(
    forwardRef<HTMLInputElement, PasswordFieldProps>((props, ref) => {
        const [isPasswordVisible, togglePasswordVisible] = useToggle(false);

        return (
            <TextField
                inputRef={ref}
                type={isPasswordVisible ? 'text' : 'password'}
                clearable={false}
                transform={trim}
                autoComplete='new-password'
                endIcon={
                    <div onClick={togglePasswordVisible}>
                        {isPasswordVisible ? <VisibilityOnIcon size={16} /> : <VisibilityOffIcon size={16} />}
                    </div>
                }
                {...props}
            />
        );
    })
) as MemoExoticComponent<ForwardRefExoticComponent<PasswordFieldProps & RefAttributes<HTMLInputElement>>> &
    ChildrenComponents;

PasswordField.displayName = 'PasswordField';

PasswordField.Controller = Controller;
