import { domain } from 'modules/data';

export const fieldsAllowingContactCreation = new Set([
    domain.FieldSystemPurpose.QBooksVendor,
    domain.FieldSystemPurpose.QBooksPayeeVendor,
    domain.FieldSystemPurpose.QBooksInvoiceCustomer,
    domain.FieldSystemPurpose.XeroSupplier,
    domain.FieldSystemPurpose.XeroCustomer,
    domain.FieldSystemPurpose.NetSuiteVendor,
]);
