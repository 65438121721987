import {
    type ForwardedRef,
    type RefObject,
    useContext,
    useEffect,
    useImperativeHandle,
    useMemo,
    useRef,
    useState,
} from 'react';

import { GroupContext } from './components';
import { ContextValue } from './components/Group/Group.types';
import { RadioProps } from './Radio.types';

export const useChecked = (checked: RadioProps['checked']) => {
    const [checkedValue, setCheckedValue] = useState(checked);

    useEffect(() => setCheckedValue(checked), [checked]);

    return {
        checkedValue,
        setCheckedValue,
    };
};

export const useValidate = (invalid: RadioProps['invalid']) => {
    const [invalidValue, setInvalidValue] = useState(invalid);

    useEffect(() => setInvalidValue(invalid), [invalid]);

    return {
        invalidValue,
        setInvalidValue,
    };
};

export const useGroup = (hookProps: {
    ref: ForwardedRef<HTMLInputElement>;
    props: RadioProps;
}): RadioProps &
    Omit<ContextValue['contextProps'], 'getCheckedStatus'> & {
        innerRef: RefObject<HTMLInputElement>;
        checked: boolean;
    } => {
    const { props, ref } = hookProps;

    const { contextProps, ref: groupRef } = useContext(GroupContext);
    const { getCheckedStatus, ...groupContextProps } = contextProps;

    /**
     * If `groupContextProps` has the property `name`, then radio in a group.
     */
    const isInGroup = groupContextProps.name;

    useEffect(() => {
        if (!isInGroup) {
            console.warn('Use the Radio component only with Radio.Group!');
        }
    }, [isInGroup]);

    /**
     * Merge props from the Group component with props from the Radio component.
     */
    const preparedProps = useMemo(() => ({ ...groupContextProps, ...props }), [groupContextProps, props]);

    /**
     * If the radio in the Group Component, check if his value in checked value.
     */
    const checked = useMemo(
        () => Boolean(isInGroup ? getCheckedStatus(preparedProps.value) : preparedProps.checked),
        [preparedProps.checked, preparedProps.value, getCheckedStatus, isInGroup]
    );

    /**
     * Create inner ref from the Group component and Checkbox component refs.
     */
    const innerRef = useRef<HTMLInputElement>(null);

    useImperativeHandle(ref, () => innerRef.current as HTMLInputElement);
    useImperativeHandle(groupRef, () => innerRef.current as HTMLInputElement);

    return {
        innerRef,
        ...preparedProps,
        checked,
    };
};
