import { AmCaptureSmallImage, AmPaySmallImage, ChevronRightIcon, CloseIcon } from '@approvalmax/ui';
import { Box, Button, Flex, Link, Text } from '@approvalmax/ui/src/components';
import { useSetAtom } from 'jotai';
import { constants } from 'modules/common';
import {
    tryPayOrCapturePopupInfoState,
    tryPayOrCapturePopupOpenState,
    TryPayOrCapturePopupOrigin,
} from 'modules/profile';
import { FC, memo, useCallback } from 'react';
import { useAddonPromotions } from 'shared/hooks/useAddonPromotions';
import { AddonPromotionType } from 'shared/states';

import { messages } from './PayAndCapturePromotionStep.messages';
import { PayAndCapturePromotionStepProps } from './PayAndCapturePromotionStep.types';

const { commonConstants } = constants;

export const PayAndCapturePromotionStep: FC<PayAndCapturePromotionStepProps> = memo((props) => {
    const { type } = props;

    const { closePromotion } = useAddonPromotions();
    const setTryPayOrCapturePopupOpen = useSetAtom(tryPayOrCapturePopupOpenState);
    const setTryPayOrCapturePopupInfo = useSetAtom(tryPayOrCapturePopupInfoState);

    const onClose = useCallback(() => {
        closePromotion(type);
    }, [closePromotion, type]);

    const onTry = useCallback(() => {
        setTryPayOrCapturePopupOpen(true);
        setTryPayOrCapturePopupInfo({ type, origin: TryPayOrCapturePopupOrigin.Workflow });
    }, [setTryPayOrCapturePopupInfo, setTryPayOrCapturePopupOpen, type]);

    return (
        <>
            {type === AddonPromotionType.Pay && (
                <Flex height={28} alignItems='center'>
                    <ChevronRightIcon size={16} color='midnight60' />
                </Flex>
            )}

            <Box
                width={240}
                height='fit-content'
                spacing='24 16'
                color={type === AddonPromotionType.Pay ? 'cobalt100' : 'mint100'}
                radius='xsmall'
            >
                <Flex direction='column'>
                    <Flex justifyContent='space-between' alignItems='center'>
                        {type === AddonPromotionType.Pay ? <AmPaySmallImage /> : <AmCaptureSmallImage />}

                        <Button icon variant='text' color='blue100' noPadding onClick={onClose}>
                            <CloseIcon color='white100' size={12} />
                        </Button>
                    </Flex>

                    <Flex direction='column' spacing='8'>
                        <Text font='body' fontSize='small'>
                            {type === AddonPromotionType.Pay ? messages.payDescription : messages.captureDescription}
                        </Text>

                        <Link
                            font='label'
                            fontSize='small'
                            hoverColor='white100'
                            color={type === AddonPromotionType.Pay ? 'white100' : 'blue100'}
                            external
                            as='a'
                            href={
                                type === AddonPromotionType.Pay
                                    ? commonConstants.payLearnMoreLink
                                    : commonConstants.captureLearnMoreLink
                            }
                        >
                            {messages.learnMore}
                        </Link>
                    </Flex>

                    <Flex alignItems='start'>
                        <Button onClick={onTry} color={type === AddonPromotionType.Pay ? 'mint80' : 'white100'}>
                            {messages.tryNowButtonText}
                        </Button>
                    </Flex>
                </Flex>
            </Box>

            {type === AddonPromotionType.Capture && (
                <Flex height={28} alignItems='center'>
                    <ChevronRightIcon size={16} color='midnight60' />
                </Flex>
            )}
        </>
    );
});

PayAndCapturePromotionStep.displayName = 'PayAndCapturePromotionStep';
