import { type RequestParams, type RequestQueryOptions, useInvalidateQuery } from '@approvalmax/data';
import { useGet } from 'shared/data/api';

import { paymentServicesApiPaths } from '../paths';
import { GetAmaxPayOpenBankingBankAccounts } from './useGetAmaxPayOpenBankingBankAccounts.types';

export const useGetAmaxPayOpenBankingBankAccounts = (
    params: RequestParams<GetAmaxPayOpenBankingBankAccounts>,
    queryOptions?: RequestQueryOptions<GetAmaxPayOpenBankingBankAccounts>
) =>
    useGet(paymentServicesApiPaths.amaxPayOpenBankingBankAccounts, {
        params,
        queryOptions: {
            ...queryOptions,
            staleTime: 5 * 1000,
        },
        apiVersion: 'v1',
        method: 'get',
    });

export const useInvalidateGetAmaxPayOpenBankingBankAccounts = () => {
    return useInvalidateQuery<RequestParams<GetAmaxPayOpenBankingBankAccounts> | void>(
        paymentServicesApiPaths.amaxPayOpenBankingBankAccounts
    );
};
