import { selectors } from 'modules/common';
import { domain } from 'modules/data';

import { CheckValidFraudBypassing, WorkflowSettingsPopupSectionProps } from '../../WorkflowSettingsPopupContent.types';

export interface SectionNetSuiteFraudBypassingProps extends WorkflowSettingsPopupSectionProps {
    template: selectors.types.ExpandedTemplate;
    savedTemplateSettings: domain.TemplateSettings;
    effectiveDateLimits: selectors.types.EffectiveDateLimits | null;
    checkValidFraudBypassing: CheckValidFraudBypassing;
}

export enum FraudBypassingOption {
    Pull = 'Pull',
    DoNotPull = 'DoNotPull',
}
