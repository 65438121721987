import { CheckIcon } from '@approvalmax/ui';
import { ColumnDefinition, Table } from '@approvalmax/ui/src/components';
import { intl } from '@approvalmax/utils';
import { selectors } from 'modules/common';
import { domain } from 'modules/data';

import { messages } from './QBooksMatchingPopup.messages';

export const getColumnDefinitions = (
    request:
        | selectors.types.QBooksBillExpandedRequest
        | selectors.types.QBooksPoExpandedRequest
        | selectors.types.QBooksExpenseExpandedRequest,
    isAccountLineItem: boolean,
    hideRemaining = false
) => {
    const { details } = request;
    const hasTaxes = details.lineAmountType !== domain.LineAmountType.NoTax;
    const isBill = request.integrationCode === domain.IntegrationCode.QBooksBill;
    const grossRate = details.lineAmountType === domain.LineAmountType.TaxInclusive;
    const currency = request.currency;

    const columnDefinitions: ColumnDefinition<domain.QBooksLineItem | domain.QBooksAccountLineItem>[] = [];

    if (isAccountLineItem) {
        columnDefinitions.push(
            {
                id: 'account',
                name: messages.accountColumnName,
                value: (item) => (item.account ? item.account.text : null),
            },
            {
                id: 'description',
                name: messages.descriptionColumnName,
                value: (item) => item.description,
            }
        );

        if (hasTaxes) {
            columnDefinitions.push({
                id: 'taxCodeName',
                name: messages.taxColumnName,
                value: (item) => (item.taxCode ? item.taxCode.text : null),
            });
        }

        if (isBill) {
            columnDefinitions.push({
                id: 'billable',
                name: messages.billableColumnName,
                value: (item) => (item.isBillable ? messages.billableColumnTooltip : null),
                width: 50,
                cellComponent: (props) => <Table.Cell {...props}>{props.value && <CheckIcon />}</Table.Cell>,
            });
            columnDefinitions.push({
                id: 'taxable',
                name: messages.taxableColumnName,
                value: (item) => (item.isTaxable ? messages.taxableColumnTooltip : null),
                width: 30,
                cellComponent: (props) => <Table.Cell {...props}>{props.value && <CheckIcon />}</Table.Cell>,
            });
        }

        columnDefinitions.push(
            {
                id: 'customerName',
                name: messages.customerColumnName,
                value: (item) => (item.customer ? item.customer.text : null),
            },
            {
                id: 'className',
                name: messages.classColumnName,
                value: (item) => (item.class ? item.class.text : null),
            },
            {
                id: 'amount',
                name: messages.amountColumnName({
                    currency,
                }),
                value: (item) => intl.formatNumber(item.amount || 0),
            }
        );
    } else {
        columnDefinitions.push(
            {
                id: 'item',
                name: messages.itemColumnName,
                value: (item: domain.QBooksLineItem) => (item.item ? item.item.text : null),
                spacing: '8 8 8 0',
            },
            {
                id: 'description',
                name: messages.descriptionColumnName,
                value: (item) => item.description,
            },
            {
                id: 'qty',
                name: messages.qtyColumnName,
                value: (item: domain.QBooksLineItem) => intl.formatNumber(item.qty || 0, 'auto'),
                textAlign: 'right',
                headerTextAlign: 'right',
            },
            {
                id: 'unitPrice',
                name: messages.unitPriceColumnName,
                value: (item: domain.QBooksLineItem) => {
                    const value = grossRate ? item.unitPriceGross : item.unitPrice;

                    return intl.formatNumber(value || 0, 'auto');
                },
                textAlign: 'right',
                headerTextAlign: 'right',
            }
        );

        if (isBill) {
            columnDefinitions.push({
                id: 'billable',
                name: messages.billableColumnName,
                value: (item) => {
                    return item.isBillable ? messages.billableColumnTooltip : null;
                },
                width: 50,
                cellComponent: (props) => <Table.Cell {...props}>{props.value && <CheckIcon />}</Table.Cell>,
            });
            columnDefinitions.push({
                id: 'taxable',
                name: messages.taxableColumnName,
                value: (item) => {
                    return item.isTaxable ? messages.taxableColumnTooltip : null;
                },
                width: 30,
                cellComponent: (props) => <Table.Cell {...props}>{props.value && <CheckIcon />}</Table.Cell>,
            });
        }

        if (hasTaxes) {
            columnDefinitions.push({
                id: 'taxCodeName',
                name: messages.taxColumnName,
                value: (item) => (item.taxCode ? item.taxCode.text : null),
            });
        }

        columnDefinitions.push(
            {
                id: 'customerName',
                name: messages.customerColumnName,
                value: (item) => (item.customer ? item.customer.text : null),
            },
            {
                id: 'className',
                name: messages.classColumnName,
                value: (item) => (item.class ? item.class.text : null),
            },
            {
                id: 'amount',
                name: messages.amountColumnName({
                    currency,
                }),
                value: (item) => intl.formatNumber(item.amount || 0),
                width: 90,
                textAlign: 'right',
                headerTextAlign: 'right',
            }
        );
    }

    if (isBill && !hideRemaining) {
        columnDefinitions.push({
            id: 'remaining',
            name: messages.remainingColumnName({
                currency,
            }),
            value: (item) => intl.formatNumber(item.remainingBalance || 0),
        });
    }

    return columnDefinitions;
};
