import { domHelpers, hooks } from '@approvalmax/utils';
import {
    Children,
    cloneElement,
    forwardRef,
    type ForwardRefExoticComponent,
    isValidElement,
    type KeyboardEventHandler,
    memo,
    type MemoExoticComponent,
    type RefAttributes,
    useCallback,
    useMemo,
    useRef,
} from 'react';

import { Box } from '../Box/Box';
import { Item, ItemProps, List } from './components';
import { MenuContext } from './Menu.context';
import { computeTabIndex } from './Menu.helpers';
import { ChildrenComponents, MenuProps } from './Menu.types';

/**
 * The Menu allows you to create lists with items that, when clicked, perform certain actions
 */
export const Menu = memo(
    forwardRef<HTMLUListElement, MenuProps>((props, ref) => {
        const {
            items,
            size,
            divider,
            color,
            children,
            qa,
            wrap,
            disableFocusVisible,
            direction = 'column',
            minWidth,
            ...restProps
        } = props;

        const rootRef = useRef<HTMLUListElement | null>(null);
        const composedRefs = hooks.useComposedRefs(ref, rootRef);
        const dataQa = domHelpers.generateDataQa(qa, 'menu');

        const enrichedItems = useMemo(
            () =>
                (items || []).map((item, index) => ({
                    ...item,
                    disableFocusVisible,
                    tabIndex: computeTabIndex({ ...item, index }),
                    subItems: item.subItems
                        ? {
                              ...item.subItems,
                              items: (item.subItems.items || []).map((subItem) => ({
                                  ...subItem,
                                  disableFocusVisible,
                                  tabIndex: computeTabIndex({ ...item, index: -1 }),
                              })),
                          }
                        : undefined,
                })),
            [disableFocusVisible, items]
        );

        const arrayChildren = Children.toArray(children);
        const enrichedChildren = Children.map(arrayChildren, (child, index) => {
            if (isValidElement<Pick<ItemProps, 'tabIndex' | 'active' | 'selected' | 'disabled'>>(child)) {
                return cloneElement<
                    Pick<ItemProps, 'tabIndex' | 'active' | 'selected' | 'disabled' | 'disableFocusVisible'>
                >(child, {
                    disableFocusVisible,
                    tabIndex: computeTabIndex({ ...child.props, index }),
                });
            }

            return child;
        });

        const handleKeyDown = useCallback<KeyboardEventHandler<HTMLUListElement>>(
            (event) => {
                if (!rootRef.current || disableFocusVisible) {
                    return;
                }

                const currentFocus = event.target as Element;

                switch (event.key) {
                    case 'ArrowUp':
                    case 'ArrowLeft': {
                        event.preventDefault();
                        domHelpers.moveFocus(rootRef.current, currentFocus, false, domHelpers.previousFocusTreeItem);
                        break;
                    }

                    case 'ArrowDown':
                    case 'ArrowRight': {
                        event.preventDefault();
                        domHelpers.moveFocus(rootRef.current, currentFocus, false, domHelpers.nextFocusTreeItem);
                        break;
                    }

                    default:
                        break;
                }
            },
            [disableFocusVisible]
        );

        return (
            <MenuContext.Provider value={{ size, divider, color, minWidth }}>
                {items?.length ? (
                    <List
                        {...restProps}
                        wrap={wrap}
                        items={enrichedItems}
                        ref={composedRefs}
                        qa={dataQa}
                        tabIndex={-1}
                        onKeyDown={handleKeyDown}
                        direction={direction}
                    />
                ) : (
                    <Box
                        {...restProps}
                        as='ul'
                        ref={composedRefs}
                        role='menu'
                        data-qa={dataQa}
                        tabIndex={-1}
                        onKeyDown={handleKeyDown}
                    >
                        {enrichedChildren}
                    </Box>
                )}
            </MenuContext.Provider>
        );
    })
) as MemoExoticComponent<ForwardRefExoticComponent<MenuProps & RefAttributes<HTMLUListElement>>> & ChildrenComponents;

Menu.displayName = 'Menu';
Menu.Item = Item;
Menu.List = List;

export default Menu;
