import { openSplitViewState } from '@approvalmax/ui/src/components';
import { useAtomValue } from 'jotai';
import { useResetAtom } from 'jotai/utils';
import { useCallback } from 'react';
import { splitViewGoToBarSettingsState } from 'shared/components';
import { ContentSplitViewMode, contentSplitViewState } from 'shared/states';

export const useResetSplitView = (params: { views: ContentSplitViewMode[] }) => {
    const { views } = params;

    const openSplitView = useAtomValue(openSplitViewState);
    const contentSplitView = useAtomValue(contentSplitViewState);
    const resetContentSplitView = useResetAtom(contentSplitViewState);
    const resetSplitViewGoToBarSettings = useResetAtom(splitViewGoToBarSettingsState);

    return useCallback(() => {
        if (!openSplitView || !views.includes(contentSplitView.mode)) {
            return;
        }

        resetSplitViewGoToBarSettings();
        resetContentSplitView();
    }, [openSplitView, views, contentSplitView, resetContentSplitView, resetSplitViewGoToBarSettings]);
};
