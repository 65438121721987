import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('modules.FirstStartModule.AccountSetupPopup', {
    accountTargetInternalAccounting: 'For my own internal accounting',
    accountTargetProvideServices: 'To provide services for my clients',
    connectDescription: `
        Create your first ApprovalMax organisation to connect it to your file in the Accounting system.
        You can create more Organisations later if required.
        If you are using Xero you can connect either to your production Organisation or try connecting to Demo first
        (this action would require creating a new Organisation later).`,
    orSeparatorText: 'Or',
    skipOrganisationCreationButton: 'Skip organisation creation for now',
    nextButtonText: 'Next',
    validationError: 'Please provide the name of your practice.',
    infoTitle: 'ApprovalMax account setup',
    infoNavBarTitle: 'Your business details',
    infoAccountPartner: `I'm going to use this account:`,
    companyCount: 'How many legal entities do you have (how many accounts in your accounting software do you manage)?',
    clientCount: 'For how many clients are you planning to use ApprovalMax?',
    practiceName: 'Please provide the name of your practice.',
    practiceNamePlaceholder: 'Enter the practice name...',
    connectTitle: 'Create your first Organisation.',
    connectNavBarTitle: 'Connect your accounting software',
});
