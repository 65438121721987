import { mixins, mods } from '@approvalmax/theme';
import { StyledMods } from '@styled-kit/mods';
import styled from 'styled-components';

import { BaseItem, TableProps } from './Table.types';

export const defaultSpacing = '12 8' as const;
export const checkboxSpacing = '4 4' as const;

export const Root = styled.table<
    StyledMods<Pick<TableProps<BaseItem>, 'fontSize' | 'virtualized'>> &
        StyledMods<{ reorderableRows?: boolean; hasRowActionMenu?: boolean }>
>`
    width: 100%;
    border-collapse: collapse;

    ${mods('reorderableRows', true)`
        width: calc(100% + 30px);
        margin-left: -30px;
    `};

    ${mods('hasRowActionMenu', true)`
        width: calc(100% + 30px);
    `};

    ${mods(['reorderableRows', 'hasRowActionMenu'], true)`
        width: calc(100% + 60px);
        margin-left: -30px;
    `};

    ${mods('virtualized', true)`
        margin-left: 0;
    `};

    ${mods.fontSize((value) => mixins.font('body', value))};
`;
