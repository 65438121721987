import { Reference } from '@approvalmax/types';
import { typeGuardHelpers } from '@approvalmax/utils';
import * as Sentry from '@sentry/browser';
import { immutable } from 'modules/immutable';

import {
    cryptoCurrencyFullTextMessages,
    currencyFullTextMessages,
    currencyFullTextWithoutCodeMessages,
    currencyTextMessages,
} from './currency.messages';

export const defaultCurrency = 'USD';

export const currencyReferences = immutable<Reference>(
    Object.entries(currencyFullTextMessages).map(
        ([key, message]): Reference => ({
            id: key.toUpperCase(),
            text: message,
        })
    )
);

export const currencyShortReferences = immutable<Reference>(
    Object.entries(currencyTextMessages).map(
        ([key, message]): Reference => ({
            id: key.toUpperCase(),
            text: message,
        })
    )
);

export const cryptoCurrencyReferences = immutable<Reference>(
    Object.entries(cryptoCurrencyFullTextMessages).map(
        ([key, message]): Reference => ({
            id: key.toUpperCase(),
            text: message,
        })
    )
);

export const findCurrencyNameByCode = (code: string) => {
    const normalizedCode = code.toLowerCase();

    if (typeGuardHelpers.isKeyOfObject(normalizedCode, currencyFullTextWithoutCodeMessages)) {
        return currencyFullTextWithoutCodeMessages[normalizedCode] ?? null;
    }

    return null;
};

export function findCurrencyByCode(code: string): Reference | undefined {
    const normalizedCode = code.toUpperCase();

    return currencyReferences.find((x) => x.id === normalizedCode);
}

export const findCryptoCurrencyByCode = (code: string): Reference | undefined => {
    const normalizedCode = code.toUpperCase();

    return cryptoCurrencyReferences.find((x) => x.id === normalizedCode);
};

export function findShortCurrencyByCode(code: string): Reference | undefined {
    const normalizedCode = code.toUpperCase();

    return currencyShortReferences.find((x) => x.id === normalizedCode);
}

export function findCurrencyShortText(code: string): string | undefined {
    return (currencyTextMessages as any)[code.toLowerCase()];
}

export function getCurrencyShortText(code: string): string {
    const result = findCurrencyShortText(code);

    if (!result) {
        Sentry.captureMessage(`Currency not found. Currency code: ${code}.`, 'warning');

        return findCurrencyShortText(defaultCurrency)!;
    }

    return result;
}

export function getCurrencyFullText(code: string): string {
    const result = findCurrencyByCode(code);

    if (!result) {
        Sentry.captureMessage(`Currency not found. Currency code: ${code}.`, 'warning');

        return getCurrencyFullText(defaultCurrency);
    }

    return result.text;
}
