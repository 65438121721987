import { RequestMutationOptions } from '@approvalmax/data';
import { useMutate } from 'shared/data';

import { requestsApiPaths } from '../paths';
import { CreateRequest } from './useCreateRequest.types';

export const useCreateRequest = (mutationOptions?: RequestMutationOptions<CreateRequest>) => {
    return useMutate(requestsApiPaths.create, {
        mutationOptions,
        apiVersion: 'v1',
        method: 'post',
        mapToCamelCase: true,
    });
};
