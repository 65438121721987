import { Text } from '@approvalmax/ui/src/components';
import { memo, useState } from 'react';

import FixedStepContainer from '../FixedStepContainer/FixedStepContainer';
import { StepSubmitterSection } from '../StepSubmitterSection/StepSubmitterSection';
import { messages } from './XeroBillBatchPaymentStartStep.messages';

const XeroBillBatchPaymentStartStep = memo(() => {
    const [collapsed, setCollapsed] = useState<boolean>();

    return (
        <FixedStepContainer bubbleText={messages.bubbleText}>
            <Text font='body' fontSize='small' color='midnight70'>
                {messages.title}
            </Text>

            <StepSubmitterSection
                cacheId='XeroBillBatchPaymentStartStepSubmitter'
                headerText={messages.paymentCreation}
                description={messages.description}
                required
                collapsed={collapsed}
                onCollapse={setCollapsed}
            />
        </FixedStepContainer>
    );
});

export default XeroBillBatchPaymentStartStep;
