import { font } from 'modules/styles';
import emojiSadness from 'pages/requestList/resources/emoji-sadness.png';
import styled from 'styled-components';

export const Container = styled.div`
    margin-top: 30px;
`;

export const Title = styled.div`
    margin-bottom: 10px;
    ${font(13, '#000', 'semibold')}
    text-transform: uppercase;
`;

export const EmptyContainer = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 150px;
`;

export const EmptyContent = styled.div`
    width: 240px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const EmptyIcon = styled.div`
    width: 80px;
    height: 80px;
    margin-bottom: -10px;
    background: url(${emojiSadness}) no-repeat;
    background-size: contain;
`;

export const EmptyMessage = styled.div`
    text-align: center;
    ${font(16, '#000', 'semibold')}
`;
