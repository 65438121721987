import { RequestQueryOptions } from '@approvalmax/data';
import { useLazyGet } from 'shared/data';

import { integrationApiPaths } from '../paths';
import { GetNetSuiteAccounts } from './useLazyGetNetSuiteAccounts.types';

export const useLazyGetNetSuiteAccounts = (queryOptions?: RequestQueryOptions<GetNetSuiteAccounts>) => {
    return useLazyGet<GetNetSuiteAccounts>(integrationApiPaths.getNetSuiteAccounts, {
        queryOptions,
        apiVersion: 'v1',
        method: 'get',
        mapToCamelCase: true,
    });
};
