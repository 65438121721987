export const iconSize = 12;

/**
 * Base classes for calendar
 */
export const baseClasses = {
    calendar: '.react-calendar',
    navigation: '.react-calendar__navigation',
};

/**
 * Configuration for different calendar views
 */
export const viewConfig = {
    month: {
        viewClass: `${baseClasses.calendar}__month-view`,
        buttonClass: `${baseClasses.calendar}__month-view__days__day`,
        buttonsInRow: 7,
    },
    year: {
        viewClass: `${baseClasses.calendar}__year-view`,
        buttonClass: `${baseClasses.calendar}__year-view__months__month`,
        buttonsInRow: 3,
    },
    decade: {
        viewClass: `${baseClasses.calendar}__decade-view`,
        buttonClass: `${baseClasses.calendar}__decade-view__years__year`,
        buttonsInRow: 3,
    },
    century: {
        viewClass: `${baseClasses.calendar}__century-view`,
        buttonClass: `${baseClasses.calendar}__century-view__decades__decade`,
        buttonsInRow: 2,
    },
};
