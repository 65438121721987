const amaxPayOpenBankingBankAccounts = 'paymentservices/companies/:companyId/amaxPay/openBanking/bankAccounts';

export const paymentServicesApiPaths = {
    disconnectFromReceiptBank: 'receiptBank/disconnect',
    getBillLinkedToBatchPayment: 'paymentservices/airwallex/xero/getBillLinkedToBatchPayment',
    reconnectAmaxPayOpenBankingBankAccounts: `${amaxPayOpenBankingBankAccounts}/:bankAccountId/reconnect`,
    finishConnectionToAirwallex: 'paymentservices/airwallex/finishIntegration',
    disconnectFromAirwallex: 'paymentservices/airwallex/disconnect',
    crudAmaxPayOpenBankingBankAccounts: `${amaxPayOpenBankingBankAccounts}/:bankAccountId`,
    connectToReceiptBank: 'receiptBank/connect',
    connectToAirwallex: 'paymentservices/airwallex/enable',
    matchAmaxPayOpenBankingBankAccountXeroBankAccount: `${amaxPayOpenBankingBankAccounts}/:bankAccountId/matchToXeroBankAccount`,
    amaxPayOpenBankingBanks: 'paymentservices/companies/:companyId/amaxPay/openBanking/banks',
    amaxPayOpenBankingBankAccounts,
    amaxPayConsent: 'paymentservices/companies/:companyId/amaxPay/openBanking/consents/:consentId',
    getBeneficiarySchema: 'paymentservices/airwallex/getBeneficiarySchema',
    createBeneficiary: 'paymentservices/airwallex/createBeneficiary',
};
