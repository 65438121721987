import { useAtom } from 'jotai';
import { useMount } from 'react-use';

import { useStartTwoFaEnabling } from '../../data/webApp/v1';
import { twoFaWizardControllerState, twoFaWizardStepState } from './TwoFaEnablingWizard.states';
import { TwoFaEnablingWizardProps } from './TwoFaEnablingWizard.types';

export const useTwoFaEnablingWizard = (props: TwoFaEnablingWizardProps) => {
    const { onFinish } = props;

    const { setWizardController } = useTwoFaWizardController();

    const { mutate: startTwoFaEnabling } = useStartTwoFaEnabling({
        onSuccess: (response) => {
            setWizardController((prev) => ({
                ...prev,
                stateId: response.stateId,
                setupCode: response.code,
                onFinish,
            }));
        },
    });

    useMount(() => {
        startTwoFaEnabling({ body: {} });
    });
};

export const useTwoFaWizardStep = () => {
    const [wizardStep, setWizardStep] = useAtom(twoFaWizardStepState);

    return {
        wizardStep,
        setWizardStep,
    };
};

export const useTwoFaWizardController = () => {
    const [wizardController, setWizardController] = useAtom(twoFaWizardControllerState);

    return {
        wizardController,
        setWizardController,
    };
};
