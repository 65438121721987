import { atom } from 'jotai';
import { atomWithReset } from 'jotai/utils';
import { ReactElement } from 'react';

import { BreadcrumbsProps } from '../Breadcrumbs/Breadcrumbs.types';

export const dividerHeaderState = atom<boolean>(true);

export const backHeaderState = atom<(() => void | Promise<void>) | undefined>(undefined);

export const breadcrumbsHeaderState = atom<BreadcrumbsProps['items']>([]);

export const slotHeaderState = atomWithReset<ReactElement | null>(null);

export const companyHeaderState = atom(true);

export const displayActionsHeaderState = atomWithReset(true);
