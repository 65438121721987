import { Box, Flex, Form, Radio, Spacing, Text } from '@approvalmax/ui/src/components';
import { FC, memo } from 'react';
import { useFormContext } from 'react-hook-form';
import { OcrFileContentType } from 'shared/data/webApp/v2';

import { FormValues } from '../BulkCreationPopupContent.types';
import { messages } from './FileContentType.messages';
import { FileContentTypeProps } from './FileContentType.types';

export const FileContentType: FC<FileContentTypeProps> = memo((props) => {
    const { disabled } = props;

    const form = useFormContext<FormValues>();

    const fileContentType = form.watch('fileContentType');

    return (
        <Form.Part>
            <Flex spacing='16' size={14} wrap={false} direction='column'>
                <Text font='headline' fontSize='xsmall' fontWeight='medium'>
                    {messages.fileToUploadButtonSectionTitle}
                </Text>

                <Radio.Group name='fileContentType' size='medium' width='100%' disabled={disabled}>
                    <Flex width='100%' spacing='8' direction='column'>
                        <Box
                            spacing='16'
                            radius='small'
                            color={
                                fileContentType === OcrFileContentType.DocumentPerFile ? 'midnight20' : 'transparent'
                            }
                        >
                            <Radio value={OcrFileContentType.DocumentPerFile}>
                                <Text font='body' fontSize='medium' fontWeight='medium'>
                                    {messages.oneDocumentPerFileButtonTitle}
                                </Text>

                                <Spacing height={4} />

                                <Text font='body' fontSize='small' color='midnight70'>
                                    {messages.oneDocumentPerFileDescription}
                                </Text>
                            </Radio>
                        </Box>

                        <Box
                            spacing='16'
                            radius='small'
                            color={
                                fileContentType === OcrFileContentType.MultiDocumentFile ? 'midnight20' : 'transparent'
                            }
                        >
                            <Radio value={OcrFileContentType.MultiDocumentFile}>
                                <Text font='body' fontSize='medium' fontWeight='medium'>
                                    {messages.oneDocumentPerPageButtonTitle}
                                </Text>

                                <Spacing height={4} />

                                <Text font='body' fontSize='small' color='midnight70'>
                                    {messages.oneDocumentPerPageButtonDescription}
                                </Text>
                            </Radio>
                        </Box>
                    </Flex>
                </Radio.Group>
            </Flex>
        </Form.Part>
    );
});

FileContentType.displayName = 'FileContentType';
