import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages(
    'pages.RequestList.XeroMatchingV2Section.BillView.ChangeMatchingPopup.ChangeMatchingPopupHeader',
    {
        remaining: 'Remaining amount:',
        allocatedAmountToBilledPOs: 'Allocations to Billed POs:',
        allocatedAmount: 'Allocations to not Billed POs:',
        otherAllocatedBills: 'Allocations to Bills on Approval',
        allocatedAmountToNotBilledPOs: 'Allocations to Approved Bills',
        grossAmount: 'Total (Gross):',
        netAmount: 'Total (Net):',
    }
);
