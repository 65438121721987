import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('pages.RequestFormPage.selectors.xero.lineItemSelectors', {
    invalidAmountText: 'Amount {amount} exceeds the maximum amount: 999,999,999,999,999.99',
    splitLineItemsAmountMismatch: `
        *Total Amount of any split or changed line items (now {received}) must remain the original
        Total Amount (was {expected}).
        Please correct the line items: {affected}.`,
    noTaxIsInvalidText: 'You cannot select "No tax" amounts for this request.',
    invalidAccountText: `
        According to the rules, this Account is not allowed.
        Please select a different Account.`,
    invalidTrackingText: `
        According to the rules, this {category} is not allowed.
        Please select a different {category}.`,
    invalidAccountTaxMatchText: 'The Tax "{tax}" cannot be used with the Account "{account}".',
});
