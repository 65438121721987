import { createPageReducer } from 'modules/react-redux';

import { Action, LOAD_PAGE_DATA } from '../actions';
import activePopup from './page/activePopupReducer';
import airwallexContext, { AirwallexContextType } from './page/airwallexContextReducer';
import context, { ContextType } from './page/contextReducer';
import netSuiteContext, { NetSuiteContextType } from './page/netSuiteContextReducer';
import newAttachments, { NewAttachmentsType } from './page/newAttachmentsReducer';
import newSupplierAttachments, { NewSupplierAttachmentsType } from './page/newSupplierAttachmentsReducer';
import qbooksContext, { QBooksContextType } from './page/qbooksContextReducer';
import request, { RequestType } from './page/requestReducer';
import showValidationErrors, { ShowValidationErrorsType } from './page/showValidationErrorsReducer';
import supplierAttachments, { SupplierAttachmentsType } from './page/supplierAttachmentsReducer';
import xeroContext, { XeroContextType } from './page/xeroContextReducer';
import xeroLineItemExtra, { XeroLineItemExtraType } from './page/xeroLineItemExtraReducer';

export interface Page {
    request: RequestType;
    activePopup: any;
    context: ContextType;
    xeroContext: XeroContextType;
    airwallexContext: AirwallexContextType;
    qbooksContext: QBooksContextType;
    netSuiteContext: NetSuiteContextType;
    showValidationErrors: ShowValidationErrorsType;
    newAttachments: NewAttachmentsType;
    newSupplierAttachments: NewSupplierAttachmentsType;
    xeroLineItemExtra: XeroLineItemExtraType;
    supplierAttachments: SupplierAttachmentsType;
}

export default createPageReducer<Page, Action>(
    {
        request,
        activePopup,
        context,
        xeroContext,
        qbooksContext,
        netSuiteContext,
        newAttachments,
        newSupplierAttachments,
        showValidationErrors,
        xeroLineItemExtra,
        airwallexContext,
        supplierAttachments,
    },
    LOAD_PAGE_DATA
);
