import { RequestMutationOptions } from '@approvalmax/data';
import { useMutate } from 'shared/data';
import { useInvalidateGetCompanies } from 'shared/data/webApp/v1';

import { paymentServicesApiPaths } from '../paths';
import { DisconnectFromAirwallex } from './useDisconnectFromAirwallex.types';

export const useDisconnectFromAirwallex = (mutationOptions?: RequestMutationOptions<DisconnectFromAirwallex>) => {
    const invalidateGetCompanies = useInvalidateGetCompanies();

    return useMutate(paymentServicesApiPaths.disconnectFromAirwallex, {
        mutationOptions: {
            ...mutationOptions,
            onSuccess: (response, requestData, ...args) => {
                invalidateGetCompanies({ query: { companyId: requestData.body?.companyId } });

                mutationOptions?.onSuccess?.(response, requestData, ...args);
            },
        },
        method: 'post',
        apiVersion: 'v1',
    });
};
