import { mods, theme } from '@approvalmax/theme';
import { StyledMods } from '@styled-kit/mods';
import styled from 'styled-components';

import { Text } from '../Text/Text';
import { LabelProps } from './Label.types';

export const Sub = styled.sub`
    vertical-align: top;
    color: ${theme.color.red100};
`;

export const Root = styled(Text)<StyledMods<Pick<LabelProps, 'wrap'>>>`
    ${mods.wrap.false`
        white-space: nowrap;
    `};

    label {
        display: block;
    }
`;
