import { QueryOptions, RequestParams, RequestQueryOptions } from '@approvalmax/data';
import { useGet, useLazyGet } from 'shared/data';

import { requestsApiPaths } from '../paths';
import { GetNetSuitePayees } from './useGetNetSuitePayees.types';

export const useGetNetSuitePayees = (
    params: RequestParams<GetNetSuitePayees>,
    queryOptions?: QueryOptions<GetNetSuitePayees>
) => {
    return useGet(requestsApiPaths.netSuitePayees, {
        params,
        queryOptions,
        apiVersion: 'v1',
        mapToCamelCase: true,
    });
};

export const useLazyGetNetSuitePayees = (queryOptions?: RequestQueryOptions<GetNetSuitePayees>) => {
    return useLazyGet<GetNetSuitePayees>(requestsApiPaths.netSuitePayees, {
        queryOptions,
        apiVersion: 'v1',
        mapToCamelCase: true,
    });
};
