import { domain } from 'modules/data';

export const isCurrencyDisclaimerVisible = (request: domain.Request) => {
    switch (request.integrationCode) {
        case domain.IntegrationCode.XeroBill:
        case domain.IntegrationCode.XeroPo:
        case domain.IntegrationCode.XeroQuote:
        case domain.IntegrationCode.XeroInvoice:
        case domain.IntegrationCode.QBooksBill:
        case domain.IntegrationCode.QBooksExpense:
        case domain.IntegrationCode.QBooksPo:
        case domain.IntegrationCode.QBooksInvoice:
            return request.currency !== request.companyCurrency;

        default: {
            return false;
        }
    }
};
