import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('app.WorkflowId.selectors.stepSelectors', {
    participantRulesPreviewAmountPattern: 'Amount is {amount}',
    participantRulesPreviewNumberFieldPattern: '{fieldName} is {amount}',
    participantRulesPreviewAmountPatternNet: 'Net amount is {amount}',
    participantRulesPreviewAmountPatternGross: 'Total amount is {amount}',
    participantRulesPreviewAmountPatternAbove: 'over or equal to {greaterEq} {currency}',
    participantRulesPreviewAmountPatternBelow: 'under {less} {currency}',
    participantRulesPreviewAmountPatternWithin: 'over or equal to {greaterEq} and under {less} {currency}',
    participantRulesPreviewFieldValuePattern: '{value}',
    participantRulesPreviewFieldGroupValuePattern: `Group "{name}"(#{versionNumber})`,
    participantRulesPreviewNotFieldPattern: '{fieldName} does not match {values}',
    participantRulesPreviewFieldPattern: '{fieldName} matches {values}',
    participantBooleanRuleEnabled: '{fieldName} enabled',
    participantBooleanRuleDisabled: '{fieldName} disabled',
    participantRulesPreviewAndText: '{left} and {right}',
    participantRulesPreviewOrText: '{left} or {right}',
    editorRulesPreviewNotFieldPattern: '{fieldName} to any value except {values}',
    editorRulesPreviewFieldPattern: '{fieldName} to {values}',
    editorRulesPreviewAlwaysApprove: 'can set any values',
    editorRulesPreviewAndText: '{left} and {right}',
    editorRulesPreviewOrText: '{left} or {right}',
    editorRulesPreviewCanSet: 'can set {rules}',
    participantRulesPreviewApprovesWhen: 'should approve if {rules}',
    participantRulesPreviewAlwaysApprove: 'should approve everything',
    participantRulesPreviewReviewWhen: 'should review if {rules}',
    participantRulesPreviewAlwaysReview: 'should review everything',
});
