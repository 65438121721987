import { openSplitViewState } from '@approvalmax/ui/src/components';
import { useAtomValue } from 'jotai';
import { selectors } from 'modules/common';
import { domain } from 'modules/data';
import { useSelector } from 'modules/react-redux';
import {
    getIsAuditor,
    getIsManager,
    getIsParticipant,
    getIsWatcher,
} from 'pages/requestList/selectors/requestSelectors';
import { useMemo } from 'react';
import { ContentSplitViewMode, contentSplitViewState } from 'shared/states';

export const useShowLinkToRequest = (request: selectors.types.ExpandedRequest) => {
    const me = useSelector((state) =>
        request.company.allMembers.find((user) => user.id === selectors.profile.getProfileUser(state)?.id)
    );
    const openSplitView = useAtomValue(openSplitViewState);
    const contentSplitView = useAtomValue(contentSplitViewState);

    return useMemo(() => {
        return (
            me &&
            request.integrationCode === domain.IntegrationCode.XeroBill &&
            openSplitView &&
            contentSplitView.mode === ContentSplitViewMode.BillLinkedToBatchPayment &&
            contentSplitView.billId === request.id &&
            (request.author.databaseId === me.databaseId ||
                getIsManager(me.databaseId || '', me.userEmail, request.company) ||
                getIsAuditor(me.databaseId || '', me.userEmail, request.company) ||
                getIsParticipant(me.databaseId || '', me.userEmail, request) ||
                getIsWatcher(me.databaseId || '', request))
        );
    }, [contentSplitView, me, openSplitView, request]);
};
