import { domain } from 'modules/data';
import { useMemo } from 'react';
import { findDocumentFieldState } from 'shared/helpers';

export const useNetSuiteInvoiceFieldSettings = (documentFields: domain.DocumentField[] | undefined) =>
    useMemo(
        () => ({
            currency: findDocumentFieldState(documentFields, 'Currency'),
            customer: findDocumentFieldState(documentFields, 'Customer'),
            reference: findDocumentFieldState(documentFields, 'Reference'),
            exchangeRate: findDocumentFieldState(documentFields, 'ExchangeRate'),
            billingAddress: findDocumentFieldState(documentFields, 'BillingAddress'),
            transactionNumber: findDocumentFieldState(documentFields, 'TransactionNumber'),
            vatRegistration: findDocumentFieldState(documentFields, 'VatRegistrationNumber'),
            dueDate: findDocumentFieldState(documentFields, 'DueDate'),
            terms: findDocumentFieldState(documentFields, 'Terms'),
            department: findDocumentFieldState(documentFields, 'Department'),
            class: findDocumentFieldState(documentFields, 'Class'),
            location: findDocumentFieldState(documentFields, 'Location'),
            memo: findDocumentFieldState(documentFields, 'Memo'),
            transactionDate: findDocumentFieldState(documentFields, 'TransactionDate'),
            postingPeriod: findDocumentFieldState(documentFields, 'PostingPeriod'),
            startDate: findDocumentFieldState(documentFields, 'StartDate'),
            endDate: findDocumentFieldState(documentFields, 'EndDate'),
            discountAmount: findDocumentFieldState(documentFields, 'DiscountAmount'),
            discountDate: findDocumentFieldState(documentFields, 'DiscountDate'),
            discountItem: findDocumentFieldState(documentFields, 'DiscountItem'),
            discountRate: findDocumentFieldState(documentFields, 'DiscountRate'),
            opportunity: findDocumentFieldState(documentFields, 'Opportunity'),
            excludeCommissions: findDocumentFieldState(documentFields, 'ExcludeCommissions'),
            partner: findDocumentFieldState(documentFields, 'Partner'),
            salesRep: findDocumentFieldState(documentFields, 'SalesRep'),
            leadSource: findDocumentFieldState(documentFields, 'LeadSource'),
            salesEffectiveDate: findDocumentFieldState(documentFields, 'SalesEffectiveDate'),
            shippingDate: findDocumentFieldState(documentFields, 'ShippingDate'),
            shippingMethod: findDocumentFieldState(documentFields, 'ShippingMethod'),
            shippingAddress: findDocumentFieldState(documentFields, 'ShippingAddress'),
            itemsAmount: findDocumentFieldState(documentFields, 'ItemsAmount'),
            itemsClass: findDocumentFieldState(documentFields, 'ItemsClass'),
            itemsDepartment: findDocumentFieldState(documentFields, 'ItemsDepartment'),
            itemsDescription: findDocumentFieldState(documentFields, 'ItemsDescription'),
            itemsItem: findDocumentFieldState(documentFields, 'ItemsItem'),
            itemsLocation: findDocumentFieldState(documentFields, 'ItemsLocation'),
            itemsPriceLevel: findDocumentFieldState(documentFields, 'ItemsPriceLevel'),
            itemsQuantity: findDocumentFieldState(documentFields, 'ItemsQuantity'),
            itemsTaxAmount: findDocumentFieldState(documentFields, 'ItemsTaxAmount'),
            itemsTaxCode: findDocumentFieldState(documentFields, 'ItemsTaxCode'),
            itemsTaxRate: findDocumentFieldState(documentFields, 'ItemsTaxRate'),
            itemsUnit: findDocumentFieldState(documentFields, 'ItemsUnit'),
            itemsUnitPrice: findDocumentFieldState(documentFields, 'ItemsUnitPrice'),
        }),
        [documentFields]
    );
