import { SearchIcon } from '@approvalmax/ui';
import { Box, Flex, Popup, Table, Text, TextField } from '@approvalmax/ui/src/components';
import { dateTimeHelpers } from '@approvalmax/utils';
import maxBy from 'lodash/maxBy';
import { FC, memo, useCallback, useMemo, useState } from 'react';
import { useDebounce } from 'react-use';
import { VersionItem } from 'shared/components';
import { useGetGroupOfXeroAccountsVersions } from 'shared/data/webApp/v2';

import { tableDefinition } from './VersionHistoryPopupContent.constants';
import { mapAccountValues } from './VersionHistoryPopupContent.helpers';
import { Loading } from './VersionHistoryPopupContent.loading';
import { messages } from './VersionHistoryPopupContent.messages';
import {
    AccountValueWithId,
    GroupOfAccountsVersion,
    VersionHistoryPopupContentProps,
} from './VersionHistoryPopupContent.types';

export const VersionHistoryPopupContent: FC<VersionHistoryPopupContentProps> = memo((props) => {
    const { companyId, groupOfAccounts } = props;

    const [selectedVersion, setSelectedVersion] = useState<GroupOfAccountsVersion | null>(null);
    const [searchValue, setSearchValue] = useState('');
    const [filteredVersionValues, setFilteredVersionValues] = useState<AccountValueWithId[]>([]);
    const { data, isLoading } = useGetGroupOfXeroAccountsVersions(
        { path: { companyId, groupOfXeroAccountsId: groupOfAccounts.id } },
        {
            onSuccess: (data) => {
                const maxVersion = maxBy(data.items, (version) => version.versionNumber);

                if (maxVersion) {
                    setSelectedVersion(maxVersion);
                }

                setFilteredVersionValues((maxVersion?.values || []).map(mapAccountValues));
            },
        }
    );

    const versions = useMemo(() => data?.items.sort((a, b) => b.versionNumber - a.versionNumber) || [], [data?.items]);

    const getVersionTitle = useCallback((version: GroupOfAccountsVersion) => {
        return version.author
            ? `${messages.version} #${version.versionNumber} ${messages.by} ${version.author.firstName} ${version.author.lastName}`
            : `${messages.version} #${version.versionNumber}`;
    }, []);

    const allVersionValues = useMemo(() => (selectedVersion?.values || []).map(mapAccountValues), [selectedVersion]);

    const filterAndSetVersionValues = useCallback(
        (values: AccountValueWithId[]) => {
            const newFilteredVersionValues = values.filter((versionValue) =>
                versionValue.value?.toLocaleLowerCase().includes(searchValue.trim().toLocaleLowerCase())
            );

            setFilteredVersionValues(newFilteredVersionValues);
        },
        [searchValue]
    );

    const handleSelectVersion = useCallback(
        (versionNumber: number) => {
            const newSelectedVersion = versions.find((version) => version.versionNumber === versionNumber);

            setSelectedVersion(newSelectedVersion || null);

            if (newSelectedVersion) {
                filterAndSetVersionValues(newSelectedVersion.values.map(mapAccountValues));
            }
        },
        [filterAndSetVersionValues, versions]
    );

    useDebounce(() => filterAndSetVersionValues(allVersionValues), 600, [searchValue, allVersionValues]);

    return (
        <>
            <Popup.Header title={messages.popupTitle({ groupName: groupOfAccounts.name })} />

            <Popup.Body>
                <Box height={400}>
                    <Flex spacing='40' height='100%'>
                        <Flex grow={1} height='100%'>
                            <Box
                                width='100%'
                                height='100%'
                                radius='small'
                                bordered
                                borderColor='midnight50'
                                spacing='12'
                            >
                                <Flex height='100%' direction='column' spacing='12'>
                                    <Text font='label' fontSize='small' fontWeight='bold' color='midnight80'>
                                        {messages.accountsLength({ length: filteredVersionValues.length })}
                                    </Text>

                                    <TextField
                                        size='small'
                                        startIcon={<SearchIcon size={8} />}
                                        placeholder={messages.search}
                                        onChange={setSearchValue}
                                        value={searchValue}
                                    />

                                    {isLoading ? (
                                        <Loading />
                                    ) : (
                                        <Flex grow={1} block>
                                            {filteredVersionValues.length > 0 ? (
                                                <Table
                                                    headerColor='white100'
                                                    data={filteredVersionValues}
                                                    columns={tableDefinition}
                                                    virtualized
                                                />
                                            ) : (
                                                <Flex justifyContent='center' alignItems='center' height='100%'>
                                                    <Text
                                                        font='headline'
                                                        fontSize='xsmall'
                                                        color='midnight80'
                                                        fontWeight='medium'
                                                    >
                                                        {allVersionValues.length > 0
                                                            ? messages.noValuesFound
                                                            : messages.noValues}
                                                    </Text>
                                                </Flex>
                                            )}
                                        </Flex>
                                    )}
                                </Flex>
                            </Box>
                        </Flex>

                        <Box spacing='16 24' width={320} overflowY='auto' height='100%'>
                            {isLoading ? (
                                <Loading />
                            ) : (
                                versions.map((version, index) => (
                                    <VersionItem
                                        key={version.id}
                                        title={getVersionTitle(version)}
                                        date={dateTimeHelpers.formatDateTime(version.createdAt, 'DateAndTime')}
                                        version={version.versionNumber}
                                        active={index === 0}
                                        checked={selectedVersion?.versionNumber === version.versionNumber}
                                        isFirstInList={index === 0}
                                        isLastInList={index === versions.length - 1}
                                        comment={version.comment}
                                        onSetVersion={handleSelectVersion}
                                    />
                                ))
                            )}
                        </Box>
                    </Flex>
                </Box>
            </Popup.Body>
        </>
    );
});

VersionHistoryPopupContent.displayName = 'VersionHistoryPopupContent';
