import { Button, Flex, Popup, Spacing } from '@approvalmax/ui/src/components';
import { errorHelpers } from '@approvalmax/utils';
import { useAtomValue } from 'jotai';
import { selectors } from 'modules/common';
import { domain } from 'modules/data';
import { useSelector } from 'modules/react-redux';
import { memo, useCallback, useState } from 'react';

import {
    qBooksMatchingPopupAccountLineItemState,
    qBooksMatchingPopupLineItemState,
} from '../../QBooksMatchingPopup.states';
import { PurchaseOrdersList } from '../PurchaseOrdersList/PurchaseOrdersList';
import { SelectedLineInformation } from '../SelectedLineInformation/SelectedLineInformation';
import { messages } from './QBooksMatchingPopupContent.messages';
import { QBooksMatchingPopupContentProps } from './QBooksMatchingPopupContent.types';

export const QBooksMatchingPopupContent = memo<QBooksMatchingPopupContentProps>((props) => {
    const { requestId, vendorId, billDate, isLoading, onMatch, onClose } = props;

    const [selectedLineItemId, setSelectedLineItemId] = useState('');
    const request = useSelector((state) =>
        selectors.request.getRequestById<
            selectors.types.QBooksBillExpandedRequest | selectors.types.QBooksPoExpandedRequest
        >(state, requestId)
    );
    const accountLineItem = useAtomValue(qBooksMatchingPopupAccountLineItemState);
    const lineItem = useAtomValue(qBooksMatchingPopupLineItemState);

    const isQBOMatchingOnEditingBetaSupported = request.company.betaFeatures.includes(
        domain.CompanyBetaFeature.QBOMatchingOnEditing
    );

    if (request.integrationType !== domain.IntegrationType.QBooks) {
        throw errorHelpers.invalidOperationError();
    }

    const onSave = useCallback(async () => {
        const lineItemId = accountLineItem?.id || lineItem?.id;

        if (!lineItemId) {
            throw errorHelpers.notFoundError('`lineItemId` not found');
        }

        await onMatch(
            { companyId: request.companyId, lineItemId, matchedLineItemId: selectedLineItemId },
            Boolean(accountLineItem?.id)
        );
        onClose();
    }, [accountLineItem?.id, lineItem?.id, onClose, onMatch, request.companyId, selectedLineItemId]);

    return (
        <>
            <Popup.Header
                title={messages.matchLines}
                actionsSize='xsmall'
                actions={
                    <Button
                        title={messages.matchTooltip}
                        color='blue80'
                        size='small'
                        onClick={onSave}
                        disabled={!selectedLineItemId || isLoading}
                    >
                        {messages.matchTooltip}
                    </Button>
                }
            />

            <Popup.Body spacing='20 40'>
                <Flex direction='column' height='100%'>
                    <Spacing height={4} />

                    <SelectedLineInformation
                        accountLineItem={accountLineItem}
                        lineItem={lineItem}
                        requestId={requestId}
                    />

                    <PurchaseOrdersList
                        isQBOMatchingOnEditingBetaSupported={isQBOMatchingOnEditingBetaSupported}
                        lineItemId={accountLineItem?.id || lineItem?.id}
                        accountLineItemAccountId={accountLineItem?.account?.id}
                        lineItemItemId={lineItem?.item?.id}
                        companyId={request.companyId}
                        requestId={requestId}
                        vendorId={vendorId}
                        billDate={billDate}
                        selectedLineItemId={selectedLineItemId}
                        setSelectedLineItemId={setSelectedLineItemId}
                    />
                </Flex>
            </Popup.Body>
        </>
    );
});

QBooksMatchingPopupContent.displayName = 'QBooksMatchingPopupContent';
