import { Button, Checkbox, DateTimeEditor, NumberEditor, TextEditor } from '@approvalmax/ui';
import { domain } from 'modules/data';
import React, { FC, memo, useCallback, useState } from 'react';
import styled from 'styled-components/macro';

import { messages } from './ChangeMatchingPopupFilter.messages';
import CollapsibleSection from './components/CollapsibleSection/CollapsibleSection';

const DefaultContent = styled.div`
    border-bottom: 1px solid #cacccd;
`;

const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
`;

interface RowProps {
    marginBottom?: number;
}

const Row = styled.div<RowProps>`
    display: flex;
    line-height: 30px;
    margin-bottom: ${({ marginBottom }) => (marginBottom ? marginBottom + 'px' : '0')};
`;

interface StyledNumberEditorProps {
    withMargin?: boolean;
}

const StyledNumberEditor = styled(NumberEditor)<StyledNumberEditorProps>`
    width: 120px;
    height: 30px;
    margin: ${({ withMargin }) => (withMargin ? '0 10px' : 'auto')};
`;

const StyledCheckbox = styled(Checkbox)`
    margin: 0 8px;
`;

const CheckboxContainer = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
`;

const StyledTextEditor = styled(TextEditor)`
    width: 120px;
    height: 30px;
    margin: 0 8px;
`;

const StyledDateTimeEditor = styled(DateTimeEditor)`
    width: 120px;
    margin: 0 8px;
`;

interface FilterFieldTitleProps {
    marginLeft?: number;
}

const FilterFieldTitle = styled.div<FilterFieldTitleProps>`
    margin-bottom: 6px;
    margin-left: ${({ marginLeft }) => (marginLeft ? marginLeft + 'px' : 0)};
    font-size: 12px;
    line-height: 1;
    color: #5e5c5c;
    font-weight: 600;
`;

const ClearButton = styled(Button)`
    width: 66px;
    margin-left: 150px;
    padding: 0 15px;
`;

const FilterButton = styled(Button)`
    width: 96px;
    margin-left: 15px;
    padding: 0 30px;
`;

interface ChangeMatchingPopupFilterProps {
    appliedPOFilter: domain.XeroMatchingV2POFilter;
    setAppliedPOFilter: React.Dispatch<React.SetStateAction<domain.XeroMatchingV2POFilter>>;
}

const ChangeMatchingPopupFilter: FC<ChangeMatchingPopupFilterProps> = (props) => {
    const { appliedPOFilter, setAppliedPOFilter } = props;

    const [POFilter, setPOFilter] = useState<domain.XeroMatchingV2POFilter>(appliedPOFilter);

    const createSetFilterHandler = useCallback(
        (key: keyof domain.XeroMatchingV2POFilter) => (value: string | number | null) => {
            setPOFilter((prevState: domain.XeroMatchingV2POFilter) => ({
                ...prevState,
                [key]: value,
            }));
        },
        [setPOFilter]
    );

    const onlyMatchedCheckboxHandler = useCallback(() => {
        setPOFilter((prevState: domain.XeroMatchingV2POFilter) => ({
            ...prevState,
            onlyMatched: !prevState.onlyMatched,
        }));
    }, []);

    const clearFilter = useCallback(() => {
        setPOFilter({});
        setAppliedPOFilter({});
    }, [setAppliedPOFilter, setPOFilter]);

    const applyFilter = useCallback(() => setAppliedPOFilter({ ...POFilter }), [setAppliedPOFilter, POFilter]);

    const filterValues = Object.values(appliedPOFilter);

    let hasAppliedFilter =
        Boolean(filterValues.length) &&
        filterValues.some((filterValue) => filterValue !== undefined && filterValue !== null && filterValue !== false);

    return (
        <DefaultContent>
            <CollapsibleSection title={messages.sectionTitle} hasAppliedFilter={hasAppliedFilter}>
                <Container>
                    <Row>
                        <FilterFieldTitle>{messages.amountGross}</FilterFieldTitle>

                        <FilterFieldTitle marginLeft={250}>{messages.PONumber}</FilterFieldTitle>
                    </Row>

                    <Row marginBottom={20}>
                        <div>{messages.amountFromText}</div>

                        <StyledNumberEditor
                            value={POFilter.amountGreaterEquals || null}
                            min={1}
                            max={POFilter.amountLessEquals || 99999999999}
                            placeholder={messages.enterTheAmount}
                            onChange={createSetFilterHandler('amountGreaterEquals')}
                            withMargin
                        />

                        <div>{messages.amountToText}</div>

                        <StyledNumberEditor
                            value={POFilter.amountLessEquals || null}
                            min={POFilter.amountGreaterEquals || 1}
                            placeholder={messages.enterTheAmount}
                            onChange={createSetFilterHandler('amountLessEquals')}
                            withMargin
                        />

                        <StyledTextEditor
                            value={POFilter.number || ''}
                            onChange={createSetFilterHandler('number')}
                            placeholder={messages.enterNumber}
                        />

                        <CheckboxContainer onClick={onlyMatchedCheckboxHandler}>
                            <StyledCheckbox checked={POFilter.onlyMatched} />

                            {messages.showOnlyMatched}
                        </CheckboxContainer>
                    </Row>

                    <Row>
                        <FilterFieldTitle>{messages.dateRange}</FilterFieldTitle>
                    </Row>

                    <Row marginBottom={20}>
                        <div>{messages.dateFromText}</div>

                        <StyledDateTimeEditor
                            placeholder={messages.enterTheDate}
                            value={POFilter.dateGreaterEquals || null}
                            onChange={createSetFilterHandler('dateGreaterEquals')}
                        />

                        <div>{messages.dateToText}</div>

                        <StyledDateTimeEditor
                            placeholder={messages.enterTheDate}
                            value={POFilter.dateLessEquals || null}
                            onChange={createSetFilterHandler('dateLessEquals')}
                        />

                        <ClearButton
                            title={messages.clearFilterValues}
                            preset='small'
                            execute={clearFilter}
                            colorTheme='grey'
                        >
                            {messages.clearButtonText}
                        </ClearButton>

                        <FilterButton title={messages.applyFilter} preset='small' execute={applyFilter}>
                            {messages.filterButtonText}
                        </FilterButton>
                    </Row>
                </Container>
            </CollapsibleSection>
        </DefaultContent>
    );
};

export default memo(ChangeMatchingPopupFilter);
