import { Button } from '@approvalmax/ui/src/components';
import { selectors } from 'modules/common';
import { domain } from 'modules/data';
import { useMemo } from 'react';

import XeroAirwallexBatchPaymentStartStep from '../XeroAirwallexBatchPaymentStartStep/XeroAirwallexBatchPaymentStartStep';
import { XeroAmaxPayBatchPaymentStartStep } from '../XeroAmaxPayBatchPaymentStartStep/XeroAmaxPayBatchPaymentStartStep';
import XeroBillBatchPaymentStartStep from '../XeroBillBatchPaymentStartStep/XeroBillBatchPaymentStartStep';
import XeroBillStartStep from '../XeroBillStartStep/XeroBillStartStep';
import XeroContactStartStep from '../XeroContactStartStep/XeroContactStartStep';
import XeroCreditNoteStep from '../XeroCreditNoteStep/XeroCreditNoteStep';
import XeroInvoiceStartStep from '../XeroInvoiceStartStep/XeroInvoiceStartStep';
import XeroManualJournalStartStep from '../XeroManualJournalStartStep/XeroManualJournalStartStep';
import XeroPoStartStep from '../XeroPoStartStep/XeroPoStartStep';
import { XeroQuoteStartStep } from '../XeroQuoteStartStep/XeroQuoteStartStep';
import { messages } from './XeroWorkflowDetails.messages';

export const useXeroWorkflowDetailsData = (integrationCode?: domain.IntegrationCode | null) => {
    const pluralTemplateName = useMemo(
        () => selectors.template.getTemplateDisplayNameByCode(integrationCode ?? null, true),
        [integrationCode]
    );

    switch (integrationCode) {
        case domain.IntegrationCode.XeroPo:
            return {
                startStep: <XeroPoStartStep />,
                endStepBubbleText: messages.endStepBubbleTextPo,
                endStepDescription: messages.endStepDescriptionPo,
            };

        case domain.IntegrationCode.XeroBill:
            return {
                startStep: <XeroBillStartStep />,
                endStepBubbleText: messages.endStepBubbleTextApproveInXero,
                endStepDescription: messages.endStepDescriptionApproveInXero({ pluralTemplateName }),
            };

        case domain.IntegrationCode.XeroQuote:
            return {
                startStep: <XeroQuoteStartStep />,
                endStepBubbleText: messages.endStepBubbleTextQuote,
                endStepDescription: messages.endStepDescriptionQuote,
            };

        case domain.IntegrationCode.XeroContact:
            return {
                startStep: <XeroContactStartStep />,
                endStepBubbleText: messages.endStepBubbleTextApproveInXero,
                endStepDescription: messages.endStepDescriptionApproveInXero({ pluralTemplateName }),
            };

        case domain.IntegrationCode.XeroInvoice:
            return {
                startStep: <XeroInvoiceStartStep />,
                endStepBubbleText: messages.endStepBubbleTextApproveInXero,
                endStepDescription: messages.endStepDescriptionApproveInXero({ pluralTemplateName }),
            };

        case domain.IntegrationCode.XeroAmaxPayBatchPayment:
            return {
                startStep: <XeroAmaxPayBatchPaymentStartStep />,
                endStepBubbleText: messages.endStepBubbleTextAmaxPayXeroBatchPayment,
                endStepDescription: messages.endStepDescriptionAmaxPayXeroBatchPayment,
                actions: (
                    <Button
                        color='blue80'
                        size='xsmall'
                        as='a'
                        href='https://go.xero.com/Bank/BankAccounts.aspx'
                        target='_blank'
                        rel='noopener noreferrer'
                    >
                        {messages.accountTransactionsButtonText}
                    </Button>
                ),
            };

        case domain.IntegrationCode.XeroBillBatchPayment:
            return {
                startStep: <XeroBillBatchPaymentStartStep />,
                endStepBubbleText: messages.endStepBubbleTextBillBatchPayment,
                endStepDescription: messages.endStepDescriptionBillBatchPayment,
                actions: (
                    <Button
                        color='blue80'
                        size='xsmall'
                        as='a'
                        href='https://go.xero.com/Bank/BankAccounts.aspx'
                        target='_blank'
                        rel='noopener noreferrer'
                    >
                        {messages.accountTransactionsButtonText}
                    </Button>
                ),
            };

        case domain.IntegrationCode.XeroAirwallexBatchPayment:
            return {
                startStep: <XeroAirwallexBatchPaymentStartStep />,
                endStepBubbleText: messages.endStepBubbleTextAirwalletBatchPayment,
                endStepDescription: messages.endStepDescriptionAirwallexBatchPayment,
                actions: (
                    <Button
                        color='blue80'
                        size='xsmall'
                        as='a'
                        href='https://go.xero.com/Bank/BankAccounts.aspx'
                        target='_blank'
                        rel='noopener noreferrer'
                    >
                        {messages.accountTransactionsButtonText}
                    </Button>
                ),
            };

        case domain.IntegrationCode.XeroManualJournal:
            return {
                startStep: <XeroManualJournalStartStep />,
                endStepBubbleText: messages.endStepBubbleTextManualJournal,
                endStepDescription: messages.endStepDescriptionManualJournal,
            };

        case domain.IntegrationCode.XeroCreditNotesPayable:
        case domain.IntegrationCode.XeroCreditNotesReceivable:
        default:
            return {
                startStep: <XeroCreditNoteStep />,
                endStepBubbleText: messages.endStepBubbleTextApproveInXero,
                endStepDescription: messages.endStepDescriptionApproveInXero({ pluralTemplateName }),
            };
    }
};
