import { TextButton } from '@approvalmax/ui';
import { selectors } from 'modules/common';
import { useSelector } from 'modules/react-redux';
import { OracleNetsuiteLogo } from 'modules/sprites';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { usePrevious } from 'react-use';
import { useGetNetSuiteCacheStatus, useRefreshNetSuiteCache } from 'shared/data/webApp/v1';

import { tableColumns } from './NetSuiteCacheManagementPanel.config';
import { messages } from './NetSuiteCacheManagementPanel.messages';
import { Header, HeaderLeft, HeaderTitle, StyledTable, TableContainer } from './NetSuiteCacheManagementPanel.styles';
import { CacheObjectsTableMeta, NetSuiteCacheManagementPanelProps } from './NetSuiteCacheManagementPanel.types';

const NetSuiteCacheManagementPanel = memo<NetSuiteCacheManagementPanelProps>((props) => {
    const { companyId } = props;

    const company = useSelector((state) => selectors.company.getCompanyById(state, companyId));

    const { integrationId } = company;

    const [localIsLoading, setLocalIsLoading] = useState(false);
    const [localCacheTypesDisabled, setLocalCacheTypesDisabled] = useState<string[]>([]);

    const {
        data: netSuiteCacheStatus,
        isFetching: isFetchingGetNetSuiteCacheStatus,
        refetch: refetchNetSuiteCacheStatus,
    } = useGetNetSuiteCacheStatus(
        {
            query: {
                companyId,
                integrationId: integrationId,
            },
        },
        {
            refetchInterval: (queryData) => {
                const hasPullingInProgress =
                    queryData?.data.cacheObjects.some((cacheObject) => cacheObject.isPulling) ?? false;

                if (hasPullingInProgress || localIsLoading) {
                    return 2500;
                }

                return false;
            },
        }
    );

    const cacheObjectsWithId = useMemo(
        () =>
            (netSuiteCacheStatus?.data.cacheObjects || []).map((cacheObject) => ({
                ...cacheObject,
                id: cacheObject.refDataType,
            })),
        [netSuiteCacheStatus?.data.cacheObjects]
    );

    const { mutateAsync: refreshNetSuiteCache, isLoading: isLoadingRefreshNetSuiteCache } = useRefreshNetSuiteCache();

    const syncCacheType = useCallback(
        (cacheType: string) => {
            setLocalIsLoading(true);

            refreshNetSuiteCache(
                {
                    body: {
                        companyId,
                        integrationId: integrationId || '',
                        cacheTypes: [cacheType],
                    },
                },
                {
                    onSuccess: () => {
                        refetchNetSuiteCacheStatus();
                    },
                }
            );

            setLocalCacheTypesDisabled([cacheType]);
        },
        [refreshNetSuiteCache, companyId, integrationId, refetchNetSuiteCacheStatus]
    );

    const syncAllCacheTypes = useCallback(() => {
        setLocalIsLoading(true);

        const cacheTypes = cacheObjectsWithId.map((cacheObject) => cacheObject.refDataType) ?? [];

        refreshNetSuiteCache({
            body: {
                companyId,
                integrationId: integrationId || '',
                cacheTypes,
            },
        });

        setLocalCacheTypesDisabled(cacheTypes);
    }, [cacheObjectsWithId, refreshNetSuiteCache, companyId, integrationId]);

    const tableMeta = useMemo<CacheObjectsTableMeta>(
        () => ({
            syncCacheType,
            cacheTypesDisabled: localCacheTypesDisabled,
        }),
        [syncCacheType, localCacheTypesDisabled]
    );

    const hasPullingInProgress = useMemo(() => {
        return cacheObjectsWithId.some((cacheObject) => cacheObject.isPulling) ?? false;
    }, [cacheObjectsWithId]);

    const prevHasPullingInProgress = usePrevious(hasPullingInProgress);

    useEffect(() => {
        if (prevHasPullingInProgress && !hasPullingInProgress) {
            setLocalIsLoading(false);
            setLocalCacheTypesDisabled([]);
        }
    }, [prevHasPullingInProgress, hasPullingInProgress]);

    const isLoading =
        isFetchingGetNetSuiteCacheStatus || isLoadingRefreshNetSuiteCache || hasPullingInProgress || localIsLoading;

    return (
        <div>
            <Header>
                <HeaderLeft>
                    <OracleNetsuiteLogo width={30} height={30} />

                    <HeaderTitle>{messages.headerText}</HeaderTitle>
                </HeaderLeft>

                <div>
                    <TextButton disabled={isLoading} execute={syncAllCacheTypes}>
                        {messages.syncAll}
                    </TextButton>
                </div>
            </Header>

            <TableContainer>
                <StyledTable data={cacheObjectsWithId} columns={tableColumns} meta={tableMeta} />
            </TableContainer>
        </div>
    );
});

export default NetSuiteCacheManagementPanel;
