import { domain } from 'modules/data';

import { messages } from './getContactDisplayNameByIntegrationCode.messages';

export const getContactDisplayNameByIntegrationCode = (integrationCode: domain.IntegrationCode | null) => {
    switch (integrationCode) {
        case domain.IntegrationCode.XeroInvoice:
            return messages.customer;
        case domain.IntegrationCode.XeroPo:
            return messages.contact;
        case domain.IntegrationCode.QBooksBill:
        case domain.IntegrationCode.NetSuiteBill:
            return messages.vendor;
        default:
            return messages.supplier;
    }
};
