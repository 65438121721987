export interface AllFeatureTrialPaywallPopupProps {
    /**
     * Is the popup open
     */
    isOpen: boolean;

    /**
     * Callbak when the popup is closed
     */
    onClose: () => void;

    /**
     * List of companies
     */
    companies: TrialCompany[];

    /**
     * Initial company
     */
    initialCompanyId?: string;

    /**
     * Initial feature
     */
    initialFeature?: TrialFeature;

    /**
     * Show loading indicator and disable buttons
     */
    loading?: boolean;

    /** Timeouts for notifying admins */
    notifyAdminTimeoutsByCompanyId?: Record<string, string>;

    /**
     * Callback when user wants to upgrade subcription
     */
    onUpgradeSubcription: (companyId: string) => void;

    /**
     * Callback when user wants to start trial
     */
    onStartTrial: (companyId: string) => void;

    /**
     * Callback when user wants to notify admins
     */
    onNotifyAdmins?: (companyId: string) => void;
}

export enum TrialIntegrationType {
    Xero = 'Xero',
    Quickbooks = 'Quickbooks',
}

export enum AllFeaturesTrialStatus {
    Active = 'Active',
    InActive = 'InActive',
    Expired = 'Expired',
    AwaitingActivation = 'AwaitingActivation',
}

export enum CompanyPlan {
    Trial = 'AMAT',
    PaymentRequired = 'AMPR',
    Partner = 'AMPE',
    Xero = 'AMXO',
    QBooks = 'AMQB',
    NetSuite = 'AMNS',
    XeroAdvanced = 'AMXV',
    QBooksAdvanced = 'AMQV',
    NetSuiteAdvanced = 'AMNV',
    XeroPremium = 'AMXP',
    QBooksPremium = 'AMQP',
    NetSuitePremium = 'AMNP',
    Professional = 'AMAL',
    Retired = 'AMRE',
    ReceiptBankProfessional = 'RBAL',
    ReceiptBankQuickBooksOnlineStandard = 'RBQB',
    ReceiptBankQuickBooksOnlineAdvanced = 'RBQV',
    ReceiptBankQuickBooksOnlinePremium = 'RBQP',
    ReceiptBankXeroStandard = 'RBXO',
    ReceiptBankXeroAdvanced = 'RBXV',
    ReceiptBankXeroPremium = 'RBXP',
    SmallBusinessPackage = 'AMBB',
    Cin7CorePremium = 'AMCP',
}

export type CompanyPlans = 'standard' | 'advanced' | 'premium';

export interface TrialCompany {
    id: string;
    name: string;
    integrationType: TrialIntegrationType;
    plan?: CompanyPlans;
    isUpgradeSubcriptionVisible: boolean;
    isNotManager?: boolean;
    isSubscriptionActive?: boolean;
    allFeaturesTrialStatus?: AllFeaturesTrialStatus;
    licenseProductPlan?: CompanyPlan;
    canBuyAddons: Partial<Record<TrialFeature.AddonsPay | TrialFeature.AddonsCapture, boolean>>;
}

export enum TrialFeature {
    XeroAdvancedBillToPOMatching = 'XeroAdvancedBillToPOMatching',
    XeroAdvancedBudgetChecking = 'XeroAdvancedBudgetChecking',
    XeroAdvancedSupplierOnboarding = 'XeroAdvancedSupplierOnboarding',
    XeroAdvancedAutoApprovals = 'XeroAdvancedAutoApprovals',
    XeroAdvancedSalesQuoteApprovals = 'XeroAdvancedSalesQuoteApprovals',
    XeroAdvancedManualJournalApprovals = 'XeroAdvancedManualJournalApprovals',
    XeroAdvancedGoodsReceivedNotes = 'XeroAdvancedGoodsReceivedNotes',
    XeroAdvancedWorkflowVersionHistory = 'XeroAdvancedWorkflowVersionHistory',
    XeroAdvancedRequestReviewStep = 'XeroAdvancedRequestReviewStep',
    XeroAdvancedAccrualReports = 'XeroAdvancedAccrualReports',
    XeroAdvancedEditingOnApproval = 'XeroAdvancedEditingOnApproval',
    XeroAdvancedRequestVersionHistory = 'XeroAdvancedRequestVersionHistory',
    XeroAdvancedOnHoldStatus = 'XeroAdvancedOnHoldStatus',

    XeroPremiumBatchPaymentApprovals = 'XeroPremiumBatchPaymentApprovals',
    XeroPremiumPaymentsWithAirwallex = 'XeroPremiumPaymentsWithAirwallex',
    XeroPremiumStandAloneWorkflows = 'XeroPremiumStandAloneWorkflows',
    XeroPremiumPublicAPI = 'XeroPremiumPublicAPI',
    XeroPremiumPrioritySupport = 'XeroPremiumPrioritySupport',

    QboAdvancedBillToPOMatching = 'QboAdvancedBillToPOMatching',
    QboAdvancedVendorOnboarding = 'QboAdvancedVendorOnboarding',
    QboAdvancedAutoApprovals = 'QboAdvancedAutoApprovals',
    QboAdvancedJournalEntryApprovals = 'QboAdvancedJournalEntryApprovals',
    QboAdvancedGoodsReceivedNotes = 'QboAdvancedGoodsReceivedNotes',
    QboAdvancedWorkflowVersionHistory = 'QboAdvancedWorkflowVersionHistory',
    QboAdvancedRequestReviewStep = 'QboAdvancedRequestReviewStep',
    QboAdvancedRequestVersionHistory = 'QboAdvancedRequestVersionHistory',
    QboAdvancedOnHoldStatus = 'QboAdvancedOnHoldStatus',

    QboPremiumStandAloneWorkflows = 'QboPremiumStandAloneWorkflows',
    QboPremiumPrioritySupport = 'QboPremiumPrioritySupport',
    QboPremiumPublicAPI = 'QboPremiumPublicAPI',

    AddonsCapture = 'AddonsCapture',
    AddonsPay = 'AddonsPay',
}
