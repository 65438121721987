import { RequestMutationOptions } from '@approvalmax/data';
import { useMutate } from 'shared/data';

import { requestsApiPaths } from '../paths';
import { ChangeGrnStatus } from './useChangeGrnStatus.types';

export const useChangeGrnStatus = (mutationOptions?: RequestMutationOptions<ChangeGrnStatus>) => {
    return useMutate(requestsApiPaths.changeGrnStatus, {
        mutationOptions,
        apiVersion: 'v1',
        method: 'post',
    });
};
