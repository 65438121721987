import { stateTree } from 'modules/data';

import { selectors } from '..';

export function getCreatableTemplates(state: stateTree.State) {
    return state.meta.creatableTemplates;
}

export const getPendingInvitationsLength = (state: stateTree.State) => state.meta.pendingInvitationsLength;

export function getViewableTemplatesByCompanyId(state: stateTree.State, companyId: string) {
    return state.meta.viewableTemplates.filter((t) => t.companyId === companyId);
}

export function getCreatableTemplatesByCompanyId(state: stateTree.State, companyId?: string) {
    const creatableTemplates = getCreatableTemplates(state);

    return creatableTemplates.reduce<selectors.types.ExpandedTemplate[]>((acc, creatableTemplateId) => {
        const template = selectors.template.findTemplateById(state, creatableTemplateId);

        if (template && (!companyId || template.companyId === companyId)) {
            acc.push(template);
        }

        return acc;
    }, []);
}
