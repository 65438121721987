import { useAtomValue } from 'jotai';
import { selectors } from 'modules/common';
import { domain, stateTree } from 'modules/data';
import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { amplitudeService } from 'services/amplitude';
import { routingService } from 'services/routing';
import { useReverseRequest } from 'shared/data/webApp/v1';
import { activeAttachmentIdState } from 'shared/states';
import { getPath, Path } from 'urlBuilder';

import {
    cloneRequest,
    deleteRequest,
    showCancelRequestPopup,
    showRevokeDecisionPopup,
    showStartOverPopup,
} from '../../../../../actions';

export const useSecondaryActions = (request: selectors.types.ExpandedRequest, hasMatchingValidationError: boolean) => {
    const dispatch = useDispatch();
    const templates = useSelector((state: stateTree.State) =>
        selectors.template.getTemplatesByCompanyId(state, request.companyId)
    );

    const activeAttachmentId = useAtomValue(activeAttachmentIdState);
    const isXeroMatchingV2 = request.company.flags.isXeroMatchingV2;

    const { mutateAsync: reverseRequest, isLoading: isLoadingReverseRequest } = useReverseRequest();

    const requestActionAmplitudeEvent = useCallback((actionType: string) => {
        amplitudeService.sendData('requests: make request action', {
            'action type': actionType,
        });
    }, []);

    const startOver = useCallback(() => {
        dispatch(showStartOverPopup(request.id));

        requestActionAmplitudeEvent('start over workflow');
    }, [dispatch, request.id, requestActionAmplitudeEvent]);

    const edit = useCallback(() => {
        routingService.push(getPath(Path.editRequest, request.id, request.companyId, { activeAttachmentId }));

        requestActionAmplitudeEvent('edit');
    }, [request, requestActionAmplitudeEvent, activeAttachmentId]);

    const revoke = useCallback(() => {
        dispatch(showRevokeDecisionPopup(request.id));

        requestActionAmplitudeEvent('revoke');
    }, [dispatch, request.id, requestActionAmplitudeEvent]);

    const remove = useCallback(() => {
        dispatch(deleteRequest(request.id));

        requestActionAmplitudeEvent('remove');
    }, [dispatch, request.id, requestActionAmplitudeEvent]);

    const cancel = useCallback(() => {
        dispatch(showCancelRequestPopup(request.id));

        requestActionAmplitudeEvent('cancel');
    }, [dispatch, request.id, requestActionAmplitudeEvent]);

    const reverse = useCallback(() => {
        amplitudeService.sendData('requests: make request action', {
            'action type': 'reverse',
        });

        reverseRequest(
            {
                body: {
                    companyId: request.companyId,
                    requestId: request.id,
                    targetTemplateId: request.template.id,
                },
            },
            {
                onSuccess: (data) => {
                    routingService.push(getPath(Path.editRequest, data.requestId, request.companyId));
                },
            }
        );
    }, [request, reverseRequest]);

    const editCommand = useMemo(() => {
        const command = { ...request.commands.editSecondary };

        if (isXeroMatchingV2 && request.integrationCode === domain.IntegrationCode.XeroBill) {
            const createdInApprovalMax = request.origin === domain.RequestOrigin.ApprovalMax;

            if (!command.hidden && !(request.flags.isAuthor && createdInApprovalMax)) {
                // for Xero matching v2
                // If the user is a reviewer, extra check:
                // cannot edit if matching is violated OR has validation errors
                command.hidden = hasMatchingValidationError;
            }
        }

        return command;
    }, [request, isXeroMatchingV2, hasMatchingValidationError]);

    const allocateToCreditNote = useCallback(() => {
        const isBill = request.integrationCode === domain.IntegrationCode.XeroBill;
        const isInvoice = request.integrationCode === domain.IntegrationCode.XeroInvoice;

        if (!isBill && !isInvoice) {
            return;
        }

        const allocationTemplate = isBill
            ? domain.IntegrationCode.XeroCreditNotesPayable
            : domain.IntegrationCode.XeroCreditNotesReceivable;
        const templateId = templates.find((template) => template.integrationCode === allocationTemplate)?.id || '';

        dispatch(cloneRequest(request.id, templateId, request.companyId));
    }, [dispatch, request.companyId, request.id, request.integrationCode, templates]);

    const requestCommands = useMemo(
        () => [
            editCommand,
            request.commands.startOver,
            request.commands.clone,
            request.commands.clonePOToBill,
            request.commands.reverse,
            request.commands.revoke,
            request.commands.delete,
            request.commands.cancel,
            request.commands.allocateToCreditNote,
        ],
        [editCommand, request.commands]
    );

    const templateName =
        selectors.template.getTemplateDisplayNameByCode(request.integrationCode) || request.template.text;

    const isCompanyReadonly = request.company.isReadonly;

    const isDisconnected =
        request.company.integration && request.integrationCode
            ? request.company.integration.status === domain.IntegrationStatus.Disconnected
            : false;

    return {
        request,
        startOver,
        edit,
        revoke,
        remove,
        cancel,
        reverse,
        editCommand,
        requestCommands,
        isReverseRequestLoading: isLoadingReverseRequest,
        templateName,
        isCompanyReadonly,
        isDisconnected,
        allocateToCreditNote,
    };
};
