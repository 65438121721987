import { ExtractComponentProp } from '@approvalmax/types';
import { AmCaptureImage } from '@approvalmax/ui';
import {
    Box,
    Dropzone,
    Flex,
    getErrorMessage,
    Popup,
    Radio,
    SourceFileRecord,
    Spacing,
    Text,
    toast,
    useExtensions,
} from '@approvalmax/ui/src/components';
import { fileHelpers, miscHelpers } from '@approvalmax/utils';
import { constants, selectors } from 'modules/common';
import { domain } from 'modules/data';
import { useSelector } from 'modules/react-redux';
import { FC, memo, useCallback, useState } from 'react';
import { ErrorCode } from 'react-dropzone';
import { commonErrorMessages } from 'services/error/messages';
import { getAllowedFileExtenstions } from 'shared/helpers';

import { getContext } from '../../../../selectors/pageSelectors';
import { ChooseFilePopupContent } from '../ChooseFilePopupContent/ChooseFilePopupContent';
import { UploadType } from '../UploadPlaceholder/UploadPlaceholder.types';
import { messages } from './OcrUploadPlaceholder.messages';
import { OcrUploadPlaceholderProps, UploadOptions } from './OcrUploadPlaceholder.types';

const { commonConstants } = constants;

export const OcrUploadPlaceholder: FC<OcrUploadPlaceholderProps> = memo((props) => {
    const {
        request,
        onDrop,
        isLoadingNewAttachments,
        readOnly,
        isLoadingNewOcrAttachments,
        filterFiles,
        files,
        setFiles,
    } = props;

    const context = useSelector(getContext);
    const extensionsOcr = useExtensions(commonConstants.ocrAllowedFileExtensions);
    const extensionsCommonAttachment = useExtensions(getAllowedFileExtenstions(domain.IntegrationType.Xero));
    const [uploadOption, setUploadOption] = useState<UploadOptions>(UploadOptions.UploadWithOcr);
    const [chooseFilePopupOpen, setChooseFilePopupOpen] = useState(false);
    const [chooseFilePopupItems, setChooseFilePopupItems] = useState<SourceFileRecord[]>([]);
    const isUploadWithOcr = uploadOption === UploadOptions.UploadWithOcr;
    const isLoading = isLoadingNewAttachments || isLoadingNewOcrAttachments;

    const templateName = selectors.template.getTemplateDisplayNameByCode(request.integrationCode)?.toLowerCase();

    const onChangeUploadOption = useCallback<ExtractComponentProp<typeof Radio.Group, 'onChange'>>((uploadOption) => {
        if (miscHelpers.isEnumValue(UploadOptions, uploadOption)) {
            setUploadOption(uploadOption);
        }
    }, []);

    const closePopup = useCallback(() => {
        setChooseFilePopupOpen(false);
    }, []);

    const onDropAccepted = useCallback<ExtractComponentProp<typeof Dropzone, 'onDropAccepted'>>(
        (files) => {
            if (isUploadWithOcr) {
                if (files.length > 1) {
                    setFiles([]);
                    setChooseFilePopupOpen(true);
                    setChooseFilePopupItems(files);
                } else {
                    setFiles(files);
                    onDrop(files, UploadType.Ocr);
                }
            } else {
                setFiles(files);
                onDrop(files, UploadType.General);
            }
        },
        [isUploadWithOcr, onDrop, setFiles]
    );

    const onDropRejected = useCallback<ExtractComponentProp<typeof Dropzone, 'onDropRejected'>>(
        (files) => {
            files.forEach(({ file, errors }) =>
                errors.forEach((error) => {
                    if (isUploadWithOcr && error.code === ErrorCode.FileInvalidType) {
                        toast.error(
                            <>
                                <Text font='body' fontSize='large' fontWeight='medium' spacing='0 0 8'>
                                    {commonErrorMessages.ocrUnsupportedFileErrorMessage({
                                        extension: fileHelpers.getFileExtension(files[0].file.name),
                                    })}
                                </Text>

                                <Text font='body'>
                                    {commonErrorMessages.ocrUnsupportedFileErrorDescription({
                                        filename: files[0].file.name,
                                    })}
                                </Text>
                            </>
                        );
                    } else {
                        toast.error(
                            <>
                                <Text font='label' fontSize='large' fontWeight='medium' spacing='0 0 8' ellipsis={2}>
                                    {file.name}
                                </Text>

                                {getErrorMessage(error, { maxSize: context.attachmentMaxSize, minSize: 0 })}
                            </>
                        );
                    }
                })
            );
        },
        [context.attachmentMaxSize, isUploadWithOcr]
    );

    const onToggle = useCallback((open: boolean) => {
        !open && setChooseFilePopupOpen(false);
    }, []);

    return (
        <>
            <Flex spacing='24' height='100%' direction='column' wrap={false}>
                {!isLoading && (
                    <Box spacing='16 24 0'>
                        <Radio.Group
                            name='uploadOptions'
                            size='medium'
                            value={UploadOptions.UploadWithOcr}
                            onChange={onChangeUploadOption}
                        >
                            <Flex container width='100%' spacing='8' height='100%'>
                                <Flex size={12} height={92} grow={1} minWidth={300}>
                                    <Box
                                        spacing='8'
                                        bordered={!isUploadWithOcr}
                                        radius='small'
                                        width='100%'
                                        height='100%'
                                        color={isUploadWithOcr ? 'midnight20' : 'transparent'}
                                    >
                                        <Radio value={UploadOptions.UploadWithOcr}>
                                            <Text font='body' fontSize='medium' fontWeight='medium'>
                                                {messages.uploadOcrButtonTitle}
                                            </Text>

                                            <Spacing height={4} />

                                            <Text font='body' fontSize='small' color='midnight70'>
                                                {messages.uploadOcrButtonDescription({
                                                    templateName,
                                                })}
                                            </Text>
                                        </Radio>
                                    </Box>
                                </Flex>

                                <Flex size={12} height={92} grow={1} minWidth={300}>
                                    <Box
                                        spacing='8'
                                        bordered={isUploadWithOcr}
                                        radius='small'
                                        width='100%'
                                        height='100%'
                                        color={!isUploadWithOcr ? 'midnight20' : 'transparent'}
                                    >
                                        <Radio value={UploadOptions.UploadWithoutOcr}>
                                            <Text font='body' fontSize='medium' fontWeight='medium'>
                                                {messages.uploadButtonTitle}
                                            </Text>

                                            <Spacing height={4} />

                                            <Text font='body' fontSize='small' color='midnight70'>
                                                {messages.uploadButtonDescription({
                                                    templateName,
                                                })}
                                            </Text>
                                        </Radio>
                                    </Box>
                                </Flex>
                            </Flex>
                        </Radio.Group>
                    </Box>
                )}

                <Box height='100%' width='100%' spacing='0 32 24 24'>
                    <Flex height='100%' justifyContent='center' alignItems='center' minWidth={300}>
                        <Dropzone
                            disabled={readOnly}
                            onDropAccepted={onDropAccepted}
                            hideErrorToast
                            accept={isUploadWithOcr ? extensionsOcr : extensionsCommonAttachment}
                            value={files}
                            hideAcceptedExtensions={!isUploadWithOcr}
                            onDropRejected={onDropRejected}
                            multiple
                            height='100%'
                            width={isLoading ? '400px' : '100%'}
                            progress={isLoading}
                            hideButton
                            filterFiles={filterFiles}
                            maxSize={context.attachmentMaxSize}
                            maxFilesInList={context.attachmentMaxCount}
                            dragFileIcon={isUploadWithOcr ? <AmCaptureImage height={48} width={126} /> : undefined}
                            dragFileMessage={
                                isUploadWithOcr ? messages.fileToOcrDragMessage : messages.filesDragMessage
                            }
                        />
                    </Flex>
                </Box>
            </Flex>

            <Popup size='small' open={chooseFilePopupOpen} closable onToggle={onToggle}>
                <ChooseFilePopupContent
                    files={chooseFilePopupItems}
                    closePopup={closePopup}
                    onDrop={onDrop}
                    setFiles={setFiles}
                />
            </Popup>
        </>
    );
});

OcrUploadPlaceholder.displayName = 'OcrUploadPlaceholder';
