import React from 'react';

const LoginPage = React.lazy(() => import(/* webpackChunkName: "manager-pages" */ './pages/LoginPage/LoginPage'));
const SignUpPage = React.lazy(() => import(/* webpackChunkName: "manager-pages" */ './pages/SignUpPage/SignUpPage'));
const EmailConfirmationPage = React.lazy(
    () => import(/* webpackChunkName: "manager-pages" */ './pages/EmailConfirmationPage/EmailConfirmationPage')
);
const ResetPasswordPage = React.lazy(
    () => import(/* webpackChunkName: "manager-pages" */ './pages/ResetPasswordPage/ResetPasswordPage')
);
const VerifyEmailPage = React.lazy(
    () => import(/* webpackChunkName: "manager-pages" */ './pages/VerifyEmailPage/VerifyEmailPage')
);
const ResetPasswordFinalStepPage = React.lazy(
    () =>
        import(/* webpackChunkName: "manager-pages" */ './pages/ResetPasswordFinalStepPage/ResetPasswordFinalStepPage')
);
const MobileInfoPage = React.lazy(
    () => import(/* webpackChunkName: "manager-pages" */ './pages/MobileInfoPage/MobileInfoPage')
);

const ReceiptBankAuthPage = React.lazy(
    () => import(/* webpackChunkName: "manager-pages" */ './pages/ReceiptBankAuthPage/ReceiptBankAuthPage')
);
const ProfileSetupEmailPage = React.lazy(
    () => import(/* webpackChunkName: "manager-pages" */ './pages/ProfileSetupEmailPage/ProfileSetupEmailPage')
);
const ProfileSetupSsoPage = React.lazy(
    () => import(/* webpackChunkName: "manager-pages" */ './pages/ProfileSetupSsoPage/ProfileSetupSsoPage')
);
const SignInWithStartFinalizeQboPage = React.lazy(
    () =>
        import(
            /* webpackChunkName: "manager-pages" */ './pages/SignInWithStartFinalizeQboPage/SignInWithStartFinalizeQboPage'
        )
);

export default [
    {
        path: '/login',
        component: LoginPage,
    },
    {
        path: '/register',
        component: SignUpPage,
    },
    {
        path: '/emailConfirmation',
        component: EmailConfirmationPage,
    },
    {
        path: '/resetPassword',
        component: ResetPasswordPage,
    },
    {
        path: '/resetPassVerifyEmail',
        component: ResetPasswordFinalStepPage,
    },
    {
        path: '/verifyEmail',
        component: VerifyEmailPage,
    },
    {
        path: '/mobile',
        component: MobileInfoPage,
    },
    {
        path: '/receiptBank/auth',
        component: ReceiptBankAuthPage,
    },
    {
        path: '/profileSetup/email',
        component: ProfileSetupEmailPage,
    },
    {
        path: '/profileSetup/sso',
        component: ProfileSetupSsoPage,
    },
    {
        path: '/oauth2/startFinalizeQbo',
        component: SignInWithStartFinalizeQboPage,
    },
];
