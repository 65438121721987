import { Button, Flex, Form, Spacing, Text, TextField } from '@approvalmax/ui/src/components';
import { memo } from 'react';
import bemFactory from 'react-bem-factory';

import { fieldNames } from '../../BackupEmailUpdatePopup.constants';
import { useBackupMethodsUpdateEmailFormContext } from '../../BackupEmailUpdatePopup.hooks';
import { messages } from './StepEmailConfirm.messages';
import { StepEmailConfirmProps } from './StepEmailConfirm.types';

const qa = bemFactory.qa('pro-two-fa-setup-email-confirm');

export const StepEmailConfirm = memo<StepEmailConfirmProps>((props) => {
    const { progress, onPrev, onResendCode } = props;

    const form = useBackupMethodsUpdateEmailFormContext();

    const fieldEmail = form.watch(fieldNames.email);
    const fieldVerificationCode = form.watch(fieldNames.verificationCode);

    const canNext = Boolean(fieldVerificationCode.trim()) && !progress;

    return (
        <Form.Part>
            <Flex direction='column' spacing='24' data-qa={qa()}>
                <Flex direction='column' spacing='16'>
                    <Flex direction='column' spacing='8'>
                        <Text font='label' fontSize='large' fontWeight='regular'>
                            {messages.subTitle}
                        </Text>

                        <Flex direction='row' spacing='8'>
                            <Text font='headline' fontSize='xsmall' fontWeight='medium' spacing='0'>
                                {fieldEmail}
                            </Text>

                            <Button
                                noPadding
                                noUppercase
                                fontWeight='regular'
                                color='blue100'
                                size='medium'
                                variant='text'
                                disabled={progress}
                                onClick={onPrev}
                            >
                                {messages.changeEmailButton}
                            </Button>
                        </Flex>
                    </Flex>

                    <Text font='body' fontSize='medium' fontWeight='regular'>
                        {messages.description}
                    </Text>
                </Flex>

                <Flex direction='row' spacing='32'>
                    <TextField
                        width={240}
                        name={fieldNames.verificationCode}
                        required
                        initFocus
                        disabled={progress}
                        label={messages.codeFieldTitle}
                        placeholder={messages.emailCodePlaceholder}
                    />

                    <Flex direction='column' spacing='0' alignItems='center'>
                        <Spacing height={20} />

                        <Button size='medium' variant='text' color='blue100' disabled={progress} onClick={onResendCode}>
                            {messages.resendCodeButton}
                        </Button>
                    </Flex>
                </Flex>

                <Flex direction='column' spacing='16'>
                    <Button type='submit' size='large' color='blue80' progress={progress} disabled={!canNext}>
                        {messages.nextButton}
                    </Button>
                </Flex>
            </Flex>
        </Form.Part>
    );
});

StepEmailConfirm.displayName = 'StepEmailConfirm';
