import { Guid } from '@approvalmax/types';
import { errorHelpers } from '@approvalmax/utils';
import templateAutomation from 'app/(workspace)/[companyId]/workflows/[workflowId]/resources/automation';
import { dataApi, domain, schemas } from 'modules/data';
import { createAction, createAsyncAction, createErrorAction, ExtractActions } from 'modules/react-redux';
import { api } from 'services/api';
import { notificationService } from 'services/notification';

import { messages } from './messages';

export const SHOW_DEV_TOOLS_POPUP = 'AUTOMATION/SHOW_DEV_TOOLS_POPUP';
export const showDevToolsPopup = () => createAction(SHOW_DEV_TOOLS_POPUP, {});

export const CLOSE_ACTIVE_POPUP = 'AUTOMATION/CLOSE_ACTIVE_POPUP';
export const closeActivePopup = () => createAction(CLOSE_ACTIVE_POPUP, {});

export const LOAD_COMPANY_TEMPLATES = 'AUTOMATION/LOAD_COMPANY_TEMPLATES';
export const LOAD_COMPANY_TEMPLATES_RESPONSE = 'AUTOMATION/LOAD_COMPANY_TEMPLATES_RESPONSE';
export const LOAD_COMPANY_TEMPLATES_FAILURE = 'AUTOMATION/LOAD_COMPANY_TEMPLATES_FAILURE';
export const loadCompanyTemplates = ({ companyId }: { companyId: string }) =>
    createAsyncAction({
        request: () => createAction(LOAD_COMPANY_TEMPLATES, {}),
        /* response: async request => {
            const response = await (api.getEditableTemplates({ companyId }));
            return createAction(LOAD_COMPANY_TEMPLATES_RESPONSE, {
                request,
                raw: response
            });
        }, */
        response: async (request) => {
            const response = await dataApi.templates.select({ companyId });

            return createAction(LOAD_COMPANY_TEMPLATES_RESPONSE, {
                request,
                raw: response,
            });
        },
        failure: (error) => createErrorAction(LOAD_COMPANY_TEMPLATES_FAILURE, error, {}),
        schema: { raw: { Templates: [schemas.templateSchema] } },
    });

export const COPY_WORKFLOW = 'AUTOMATION/COPY_WORKFLOW';
export const COPY_WORKFLOW_RESPONSE = 'AUTOMATION/COPY_WORKFLOW_RESPONSE';
export const COPY_WORKFLOW_FAILURE = 'AUTOMATION/COPY_WORKFLOW_FAILURE';
export const copyWorkflow = (options: {
    fromCompany: domain.Company;
    fromTemplate: domain.Template;
    toCompany: domain.Company | null;
    target: Array<{ company: domain.Company; template: domain.Template | null }> | null;
    toTemplate: domain.Template | null;
    logger: {
        info(message: string): void;
        warn(message: string): void;
        error(message: string): void;
    };
    copySettings: boolean;
    deactivateOnCopy: boolean;
    showDoneToastNotification: boolean;
}) =>
    createAsyncAction({
        request: () => createAction(COPY_WORKFLOW, {}),

        response: async (request) => {
            if (options.target && options.target.length > 0) {
                await templateAutomation.cloneTemplateBulk({
                    fromCompany: options.fromCompany,
                    fromTemplate: options.fromTemplate,
                    target: options.target,
                    logger: options.logger,
                    copySettings: options.copySettings,
                    deactivateOnCopy: options.deactivateOnCopy,
                });
            } else if (options.toCompany) {
                await templateAutomation.cloneTemplate({
                    fromCompany: options.fromCompany,
                    fromTemplate: options.fromTemplate,
                    toCompany: options.toCompany,
                    toTemplate: options.toTemplate,
                    logger: options.logger,
                    copySettings: options.copySettings,
                    deactivateOnCopy: options.deactivateOnCopy,
                });
            } else {
                throw errorHelpers.formatError();
            }

            return createAction(COPY_WORKFLOW_RESPONSE, {
                request,
            });
        },

        failure: (error) => createErrorAction(COPY_WORKFLOW_FAILURE, error, {}),

        didDispatchResponse: () => {
            if (options.showDoneToastNotification) {
                notificationService.showInfoToast('DONE: workflow copy finished');
            }
        },

        willDispatchError: (request, error) => {
            options.logger.error(error.message);
        },
    });

export const COPY_RULES = 'AUTOMATION/COPY_RULES';
export const COPY_RULES_RESPONSE = 'AUTOMATION/COPY_RULES_RESPONSE';
export const COPY_RULES_FAILURE = 'AUTOMATION/COPY_RULES_FAILURE';
export const copyRules = (options: {
    fromCompany: domain.Company;
    fromTemplate: domain.Template;
    fromStepIndex: number | null;
    fromUser: Guid;
    fromFields: domain.Field[];
    targetUserIds: Guid[];
    logger: {
        info(message: string): void;
        warn(message: string): void;
        error(message: string): void;
    };
    mergeExistingValues: boolean;
}) =>
    createAsyncAction({
        request: () => createAction(COPY_RULES, {}),

        response: async (request) => {
            await templateAutomation.copyRules({
                fromCompany: options.fromCompany,
                fromTemplate: options.fromTemplate,
                fromStepIndex: options.fromStepIndex,
                fromUser: options.fromUser,
                fromFields: options.fromFields,
                targetUserIds: options.targetUserIds,
                logger: options.logger,
                mergeExistingValues: options.mergeExistingValues,
            });

            return createAction(COPY_RULES_RESPONSE, {
                request,
            });
        },

        failure: (error) => createErrorAction(COPY_RULES_FAILURE, error, {}),

        didDispatchResponse: () => {
            notificationService.showInfoToast('DONE: rules copy finished');
        },

        willDispatchError: (request, error) => {
            options.logger.error(error.message);
        },
    });

export const RESTORE_WORKFLOW = 'AUTOMATION/RESTORE_WORKFLOW';
export const RESTORE_WORKFLOW_RESPONSE = 'AUTOMATION/RESTORE_WORKFLOW_RESPONSE';
export const RESTORE_WORKFLOW_FAILURE = 'AUTOMATION/RESTORE_WORKFLOW_FAILURE';
export const restoreWorkflow = (template: domain.Template) =>
    createAsyncAction({
        request: () => createAction(RESTORE_WORKFLOW, {}),

        response: async (request) => {
            const response = await templateAutomation.restoreTemplate(template);

            return createAction(RESTORE_WORKFLOW_RESPONSE, {
                request,
                raw: response,
            });
        },

        failure: (error) => createErrorAction(RESTORE_WORKFLOW_FAILURE, error),
    });

export const DELETE_COMPANIES = 'AUTOMATION/DELETE_COMPANIES';
export const DELETE_COMPANIES_RESPONSE = 'AUTOMATION/DELETE_COMPANIES_RESPONSE';
export const DELETE_COMPANIES_FAILURE = 'AUTOMATION/DELETE_COMPANIES_FAILURE';
export const deleteCompanies = (companies: string[]) =>
    createAsyncAction({
        request: () =>
            createAction(DELETE_COMPANIES, {
                companies,
            }),

        response: async (request) => {
            for (const companyId of companies) {
                await api.companies.delete({ companyId });
                notificationService.showInfoToast(messages.deleteCompanySuccess);
            }

            return createAction(DELETE_COMPANIES_RESPONSE, {
                request,
            });
        },

        failure: (error) => createErrorAction(DELETE_COMPANIES_FAILURE, error, {}),
    });

export type Action = ExtractActions<
    | typeof closeActivePopup
    | typeof copyRules
    | typeof copyWorkflow
    | typeof deleteCompanies
    | typeof loadCompanyTemplates
    | typeof restoreWorkflow
    | typeof showDevToolsPopup
>;
