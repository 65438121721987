import { Reference } from '@approvalmax/types';

import { messages } from './account.messages';

export const allCompanyCountOptions: Reference[] = [
    {
        id: 'companyCount_1',
        text: messages.companyCount1,
    },
    {
        id: 'companyCount_2-5',
        text: messages.companyCount2_5,
    },
    {
        id: 'companyCount_6-10',
        text: messages.companyCount6_10,
    },
    {
        id: 'companyCount_11-',
        text: messages.companyCount11,
    },
];

export interface AccountInfo {
    isPartner: boolean;
    practiceName: string;
    companyCount: string;
    clientCount: string;
}
