import { browserHelpers, constants, dateTimeHelpers, domHelpers, hooks } from '@approvalmax/utils';
import { forwardRef, ForwardRefExoticComponent, memo, MemoExoticComponent, RefAttributes, useRef } from 'react';

import { CalendarIcon } from '../../icons';
import Box from '../Box/Box';
import { Calendar } from '../Calendar/Calendar';
import Dropdown from '../Dropdown/Dropdown';
import TextField from '../TextField/TextField';
import type { HTMLTextFieldElement } from '../TextField/TextField.types';
import { Controller } from './components';
import { exceedMinMax, getFocusedInputType } from './DatePicker.helpers';
import { useCallbackActions, useDatePickerValue, useTextFieldValue } from './DatePicker.hooks';
import { ChildrenComponents, DatePickerProps } from './DatePicker.types';

const isSafari = browserHelpers.isSafari();
const isFirefox = browserHelpers.isFirefox();

/**
 * DatePicker allows you to select a date in an easy-to-use interface
 */
export const DatePicker = memo(
    forwardRef((props, ref) => {
        const {
            placement = 'bottom-start',
            onOpen,
            initOpen,
            value,
            onChange,
            onFocus,
            onBlur,
            onClear,
            initFocus,
            view = 'month',
            clearable = true,
            minDate: minDateProp,
            maxDate: maxDateProp,
            qa: qaProp,
            invalid: invalidProp,
            defaultView,
            activeStartDate,
            disabled,
            readOnly,
            ...restProps
        } = props;

        const dropdownRef = useRef<HTMLDivElement>(null);
        const activatorRef = useRef<HTMLDivElement>(null);
        const inputRef = useRef<HTMLTextFieldElement>(null);
        const composedInputRefs = hooks.useComposedRefs(ref, inputRef);

        const { datePickerValue, changeDatePickerValue } = useDatePickerValue({ value, onChange, inputRef });

        const qa = domHelpers.generateDataQa(qaProp, 'date-picker');
        const minDate = dateTimeHelpers.convertLocalToUTC(minDateProp);
        const maxDate = dateTimeHelpers.convertLocalToUTC(maxDateProp);
        const invalid = exceedMinMax(datePickerValue, minDate, maxDate) || invalidProp;

        const {
            open,
            focus,
            hover,
            handleOpen,
            handleChange,
            handleClick,
            handleKeyDown,
            handleClear,
            handleMouseEnter,
            handleMouseLeave,
            toggleFocus,
            toggleOpen,
        } = useCallbackActions({
            onOpen,
            onClear,
            initOpen,
            initFocus,
            changeDatePickerValue,
        });

        const { handleFocus } = hooks.useOpenControl({
            inputRef,
            toggleFocus,
            toggleOpen,
            focus,
            open,
            initFocus,
            disabled,
            readOnly,
            onFocus,
        });

        const { handleBlur } = hooks.useCloseControl({
            activatorRef,
            dropdownRef,
            toggleFocus,
            toggleOpen,
            focus,
            open,
            onBlur,
        });

        const { textFieldValue, handleTextFieldChange } = useTextFieldValue({
            datePickerValue,
            changeDatePickerValue,
            view,
            isHumanReadable: !(focus || open),
        });

        const isShowClearIcon = clearable && Boolean(textFieldValue) && hover;

        return (
            <Dropdown
                activator={
                    <TextField
                        {...restProps}
                        value={textFieldValue}
                        onKeyDown={handleKeyDown}
                        onChange={handleTextFieldChange}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        onClick={handleClick}
                        onClear={handleClear}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                        focus={focus}
                        invalid={invalid}
                        clearable={isShowClearIcon}
                        disabled={disabled}
                        readOnly={readOnly || (view === 'year' && (isSafari || isFirefox))}
                        type={focus || open ? getFocusedInputType(view) : 'text'}
                        endIcon={isShowClearIcon ? undefined : <CalendarIcon color='midnight100' pointer />}
                        qa={qa}
                        inputRef={composedInputRefs}
                        rootRef={activatorRef}
                    />
                }
                placement={placement}
                open={open}
                onOpen={handleOpen}
                maxHeight={0}
                width='max-content'
                isClicking={false}
                display='block'
                floatingPortalProps={constants.removeDropdownFocus.defaultFloatingPortalProps}
                floatingFocusManagerProps={constants.removeDropdownFocus.defaultFloatingFocusManagerProps}
                ref={dropdownRef}
            >
                <Box spacing='16' width={224 + 32} data-qa={domHelpers.generateDataQa(qa, 'popup')}>
                    <Calendar
                        onChange={handleChange}
                        value={datePickerValue}
                        maxDetail={view}
                        minDate={minDate}
                        maxDate={maxDate}
                        defaultView={defaultView}
                        activeStartDate={activeStartDate}
                    />
                </Box>
            </Dropdown>
        );
    })
) as MemoExoticComponent<ForwardRefExoticComponent<DatePickerProps & RefAttributes<HTMLTextFieldElement>>> &
    ChildrenComponents;

DatePicker.displayName = 'DatePicker';
DatePicker.Controller = Controller;
