import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('pages.RequestList.CopyOperation', {
    cloneButtonTitle: 'Copy request',
    cloneButtonText: 'Copy',
    cloneToDropDownButtonText: 'Copy to',
    cloneToPurchaseOrderButtonText: 'Copy to Purchase Order',
    cloneToXeroCreditNotesPayable: 'Credit Note AP',
    cloneToXeroCreditNotesReceivable: 'Credit Note AR',
    cloneToBillButtonText: 'Copy to Bill',
    cloneToQuoteButtonText: 'Copy to Quote',
    cloneToSalesInvoiceButtonText: 'Copy to Sales Invoice',
});
