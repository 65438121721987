import { ExtractComponentProp } from '@approvalmax/types';
import { SearchIcon } from '@approvalmax/ui';
import { Button } from '@approvalmax/ui/src/components';
import { useSelector } from 'modules/react-redux';
import { getPagination } from 'pages/requestList/selectors/pageSelectors';
import { memo, useCallback } from 'react';
import bemFactory from 'react-bem-factory';
import { Link, matchPath, useLocation } from 'react-router-dom';
import { getPath, Path } from 'urlBuilder';

const qa = bemFactory.qa('reql-page');

const Search = memo(() => {
    const location = useLocation();
    const isSearchPage = matchPath(location.pathname, getPath(Path.searchRequests));
    const isNoCompaniesPage = matchPath(location.pathname, getPath(Path.noCompanies));
    const { loadingMore } = useSelector(getPagination);

    const handleClick = useCallback(
        (event: ExtractComponentProp<typeof Button, 'event'>) => {
            if (loadingMore) {
                event.preventDefault();
            }
        },
        [loadingMore]
    );

    if (isSearchPage || isNoCompaniesPage) return null;

    return (
        <Button
            disabled={loadingMore}
            onClick={handleClick}
            icon
            noPadding
            as={Link}
            to={getPath(Path.searchRequests)}
            data-qa={qa('new-search-button')}
            variant='text'
            color='blue100'
        >
            <SearchIcon size={24} />
        </Button>
    );
});

export default Search;
