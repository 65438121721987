import { atom } from 'jotai';

import { MatrixCopyRuleState } from './CopyRulePopup.types';

export const matrixCopyRuleState = atom<MatrixCopyRuleState>({
    isOpen: false,
    fromUser: null,
    addedUsers: [],
    matrixType: null,
});
