import { NetSuiteExpenseLineExtendedExpenseReport, NetSuiteFieldsExpenseReport } from '../../../data/types';

export const checkAccountForCorporateCardIsInvalid = (
    fieldValues: Partial<NetSuiteFieldsExpenseReport>,
    expenseLines: NetSuiteExpenseLineExtendedExpenseReport[]
) => {
    const hasCorporateCardChecked = expenseLines.some((line) => line.isCorporateCreditCard);

    return hasCorporateCardChecked && !fieldValues.accountForCorporateCardExpenses;
};
