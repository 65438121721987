import { RequestMutationOptions } from '@approvalmax/data';
import { useMutate } from 'shared/data';

import { companiesApiPaths } from '../paths';
import { useInvalidateGetNetSuiteCustomCompanyFields } from '../useGetNetSuiteCustomCompanyFields/useGetNetSuiteCustomCompanyFields';
import { UpdateNetSuiteFields } from './useUpdateNetSuiteFields.types';

export const useUpdateNetSuiteFields = (mutationOptions?: RequestMutationOptions<UpdateNetSuiteFields>) => {
    const invalidateGetNetSuiteCustomCompanyFields = useInvalidateGetNetSuiteCustomCompanyFields();

    return useMutate(companiesApiPaths.updateNetSuiteFields, {
        mutationOptions: {
            ...mutationOptions,
            onSuccess: async (response, variables, context) => {
                await invalidateGetNetSuiteCustomCompanyFields({ query: { companyId: variables.body?.companyId } });

                mutationOptions?.onSuccess?.(response, variables, context);
            },
        },
    });
};
