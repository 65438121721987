import { memo } from 'react';

import {
    StepBackupCodes,
    StepBackupEmail,
    StepBackupEmailVerify,
    StepBackupMethods,
    StepCodeScan,
    StepCodeVerify,
} from './components';
import { useTwoFaEnablingWizard, useTwoFaWizardStep } from './TwoFaEnablingWizard.hooks';
import { TwoFaEnablingWizardProps, WizardStep } from './TwoFaEnablingWizard.types';

export const TwoFaEnablingWizard = memo<TwoFaEnablingWizardProps>((props) => {
    useTwoFaEnablingWizard(props);

    const { wizardStep } = useTwoFaWizardStep();

    return (
        <>
            {wizardStep === WizardStep.codeScan && <StepCodeScan />}

            {wizardStep === WizardStep.codeVerify && <StepCodeVerify />}

            {wizardStep === WizardStep.backupMethods && <StepBackupMethods />}

            {wizardStep === WizardStep.backupCodes && <StepBackupCodes />}

            {wizardStep === WizardStep.backupEmailEnter && <StepBackupEmail />}

            {wizardStep === WizardStep.backupEmailVerify && <StepBackupEmailVerify />}
        </>
    );
});

TwoFaEnablingWizard.displayName = 'TwoFaEnablingWizard';
