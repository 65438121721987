import { FC, memo } from 'react';
import { useNetSuiteExpensesFieldSettings } from 'shared/hooks';

import { NetSuiteLineTable } from '../NetSuiteLineTable/NetSuiteLineTable';
import { useTableDefinitions } from './NetSuiteExpenses.hooks';
import { messages } from './NetSuiteExpenses.messages';
import { NetSuiteExpensesProps } from './NetSuiteExpenses.types';

const NetSuiteExpenses: FC<NetSuiteExpensesProps> = (props) => {
    const { expenseLines, companyCustomFields, integrationCode, documentFields, exchangeRate } = props;

    const expensesFieldSettings = useNetSuiteExpensesFieldSettings(documentFields);

    const tableDef = useTableDefinitions(
        expenseLines,
        companyCustomFields,
        integrationCode,
        expensesFieldSettings,
        exchangeRate
    );

    if (expenseLines.length === 0) {
        return null;
    }

    return (
        <NetSuiteLineTable title={messages.sectionHeaderText} columnDefinitions={tableDef} lineItems={expenseLines} />
    );
};

export default memo(NetSuiteExpenses);
