import { AddIcon } from '@approvalmax/ui';
import { Box, Button, Select } from '@approvalmax/ui/src/components';
import { FC, memo, useCallback, useState } from 'react';

import { messages } from './AddFieldHeaderColumn.messages';
import { AddFieldHeaderColumnProps } from './AddFieldHeaderColumn.types';

const AddFieldHeaderColumn: FC<AddFieldHeaderColumnProps> = memo((props) => {
    const { unusedFields, onAddField, onCreateField, canCreateField } = props;

    const [inEdit, setInEdit] = useState(false);

    const beginEdit = useCallback(() => setInEdit(true), []);
    const endEdit = useCallback(() => setInEdit(false), []);

    const onChange = useCallback(
        (fieldId: string) => {
            const field = unusedFields.find((field) => field.id === fieldId);

            endEdit();

            if (field) {
                onAddField(field);
            } else if (onCreateField && fieldId) {
                onCreateField(fieldId);
            }
        },
        [endEdit, onAddField, onCreateField, unusedFields]
    );

    return (
        <Box width='max-content' spacing='12 0' height={50}>
            {inEdit ? (
                <Select
                    onBlur={endEdit}
                    placeholder={messages.addFieldPlaceholder}
                    value=''
                    onChange={onChange}
                    creatable={canCreateField}
                    items={unusedFields}
                    size='xsmall'
                    autocomplete
                    initFocus
                />
            ) : (
                <Button
                    title={messages.addFieldTitle}
                    onClick={beginEdit}
                    color='blue80'
                    startIcon={<AddIcon />}
                    size='xsmall'
                    variant='outline'
                >
                    {messages.addField}
                </Button>
            )}
        </Box>
    );
});

export default AddFieldHeaderColumn;
