import { LoadingSpinner } from '@approvalmax/ui';
import { Box, openSplitViewState } from '@approvalmax/ui/src/components';
import { intl } from '@approvalmax/utils';
import { useAtomValue, useSetAtom } from 'jotai';
import { domain } from 'modules/data';
import EmailToSupplier from 'pages/requestList/components/EmailToSupplier/EmailToSupplier';
import { FC, memo, useCallback, useMemo } from 'react';
import bemFactory from 'react-bem-factory';
import { useGetWorkflowVersionDocumentFields } from 'shared/data/webApp/v1';
import { useNetSuitePurchaseOrderFieldSettings } from 'shared/hooks';
import { activeAttachmentIdState, ContentSplitViewMode, contentSplitViewState } from 'shared/states';

import FieldsSection from '../../../../../components/FieldsSection';
import ActivitySection from '../../../../card/ActivitySection';
import AddCommentSection from '../../../../card/AddCommentSection/AddCommentSection';
import AttachmentsSection from '../../../../card/AttachmentsSection';
import Toolbar from '../../../../card/Toolbar/Toolbar';
import { WorkflowSection } from '../../../../card/WorkflowSection';
import { getNetSuiteAmountText } from '../../NetSuiteRequestCard.helpers';
import { useDisplayCurrency, useGetExchangeInfo, useNetSuiteCustomFields } from '../../NetSuiteRequestCard.hooks';
import {
    CurrencySwitcherContainer,
    FieldsSectionWrap,
    Root,
    StyledField,
    StyledScrollableArea,
} from '../../NetSuiteRequestCard.styles';
import CurrencySwitcher from '../CurrencySwitcher/CurrencySwitcher';
import NetSuiteCustomFields from '../NetSuiteCustomFields/NetSuiteCustomFields';
import NetSuiteExpenses from '../NetSuiteExpenses/NetSuiteExpenses';
import NetSuiteItemLines from '../NetSuiteItemLines/NetSuiteItemLines';
import { NetSuitePurchaseOrderFooterFields } from '../NetSuitePurchaseOrderFooterFields/NetSuitePurchaseOrderFooterFields';
import NetSuiteRequestFooter from '../NetSuiteRequestFooter/NetSuiteRequestFooter';
import { messages } from './RequestCardPurchaseOrder.messages';
import { RequestCardPurchaseOrderProps } from './RequestCardPurchaseOrder.types';

const qa = bemFactory.qa('netsuite-po-fields-section');

export const RequestCardPurchaseOrder: FC<RequestCardPurchaseOrderProps> = memo((props) => {
    const { request, className } = props;

    const setOpenSplitView = useSetAtom(openSplitViewState);
    const setContentSplitView = useSetAtom(contentSplitViewState);
    const activeAttachmentId = useAtomValue(activeAttachmentIdState);

    const { data: workflowVersionDocumentFields, isFetching: isFetchingGetWorkflowVersionDocumentFields } =
        useGetWorkflowVersionDocumentFields({
            query: {
                companyId: request.companyId,
                workflowVersionIds: [request.workflowVersionId],
            },
        });

    const { data: companyCustomFields } = useNetSuiteCustomFields(request.companyId);
    const details = request.details;

    const fieldSettings = useNetSuitePurchaseOrderFieldSettings(workflowVersionDocumentFields?.data);

    const expenseLines = useMemo(() => {
        return [...details.expenseLines].sort((a, b) => a.lineNumber - b.lineNumber);
    }, [details.expenseLines]);

    const itemLines = useMemo(() => {
        return [...details.itemLines].sort((a, b) => a.lineNumber - b.lineNumber);
    }, [details.itemLines]);

    const onEmailToSupplierAttachmentOpen = useCallback(
        (attachment: domain.RequestAttachment) => {
            setOpenSplitView(true);
            setContentSplitView({
                attachmentId: attachment.id,
                mode: ContentSplitViewMode.AttachmentsToEmailToSupplier,
            });
        },
        [setContentSplitView, setOpenSplitView]
    );

    const emailToSupplierReplacements = useMemo(
        () => ({
            [domain.SupplierEmailReplacements.poNumber]: request.details.reference,
            [domain.SupplierEmailReplacements.totalAmount]: intl.formatCurrency(
                request.amount,
                request.details.currency?.text || request.currency
            ),
            [domain.SupplierEmailReplacements.companyName]: request.company.displayName,
            [domain.SupplierEmailReplacements.supplierName]: request.details.vendor?.text || null,
            [domain.SupplierEmailReplacements.customerName]: null,
            [domain.SupplierEmailReplacements.contactPersonName]: null,
        }),
        [request]
    );

    const { displayCurrency, onDisplayCurrencyChange } = useDisplayCurrency();

    const { exchangeRate } = useGetExchangeInfo(request.companyId, request.details.exchangeRate, displayCurrency?.id);

    return (
        <Root className={className}>
            <Toolbar
                request={request}
                showVatRegistration={fieldSettings.vatRegistration !== domain.DocumentFieldState.Hidden}
                amountText={getNetSuiteAmountText(
                    request.amount,
                    exchangeRate,
                    request.currency || '',
                    displayCurrency?.isoCode
                )}
            />

            <StyledScrollableArea scrollResetKey={request.id}>
                {isFetchingGetWorkflowVersionDocumentFields ? (
                    <Box spacing='20'>
                        <LoadingSpinner />
                    </Box>
                ) : (
                    <>
                        <FieldsSectionWrap>
                            {fieldSettings.reference !== domain.DocumentFieldState.Hidden && (
                                <StyledField
                                    qa={qa('vendorReference')}
                                    title={messages.nsVendorReferenceFieldText}
                                    valueType={FieldsSection.Field.ValueType.String}
                                    value={details.vendorReference}
                                    hideIfEmpty
                                />
                            )}

                            <StyledField
                                qa={qa('reference')}
                                title={messages.nsPOReferenceFieldText}
                                valueType={FieldsSection.Field.ValueType.String}
                                value={details.reference}
                                hideIfEmpty
                            />

                            {fieldSettings.terms !== domain.DocumentFieldState.Hidden && (
                                <StyledField
                                    qa={qa('terms')}
                                    title={messages.nsTermsFieldText}
                                    valueType={FieldsSection.Field.ValueType.String}
                                    value={details.terms}
                                    hideIfEmpty
                                />
                            )}

                            <StyledField
                                qa={qa('date')}
                                title={messages.nsDateFieldText}
                                valueType={FieldsSection.Field.ValueType.Date}
                                value={details.transactionDate}
                            />

                            {fieldSettings.dueDate !== domain.DocumentFieldState.Hidden && (
                                <StyledField
                                    qa={qa('due-date')}
                                    title={messages.nsDueDateFieldText}
                                    valueType={FieldsSection.Field.ValueType.Date}
                                    value={details.dueDate}
                                    hideIfEmpty
                                />
                            )}

                            {fieldSettings.employee !== domain.DocumentFieldState.Hidden && (
                                <StyledField
                                    qa={qa('employee')}
                                    title={messages.nsEmployeeFieldText}
                                    valueType={FieldsSection.Field.ValueType.String}
                                    value={details.employee}
                                    hideIfEmpty
                                />
                            )}

                            {fieldSettings.incoterm !== domain.DocumentFieldState.Hidden && (
                                <StyledField
                                    qa={qa('incoterms')}
                                    title={messages.nsIncotermsFieldText}
                                    valueType={FieldsSection.Field.ValueType.String}
                                    value={details.incoterm}
                                    hideIfEmpty
                                />
                            )}

                            {fieldSettings.department !== domain.DocumentFieldState.Hidden && (
                                <StyledField
                                    qa={qa('department')}
                                    title={messages.nsDepartmentFieldText}
                                    valueType={FieldsSection.Field.ValueType.String}
                                    value={details.department}
                                    hideIfEmpty
                                />
                            )}

                            {fieldSettings.class !== domain.DocumentFieldState.Hidden && (
                                <StyledField
                                    qa={qa('class')}
                                    title={messages.nsClassFieldText}
                                    valueType={FieldsSection.Field.ValueType.String}
                                    value={details.class}
                                    hideIfEmpty
                                />
                            )}

                            {fieldSettings.location !== domain.DocumentFieldState.Hidden && (
                                <StyledField
                                    qa={qa('location')}
                                    title={messages.nsLocationFieldText}
                                    valueType={FieldsSection.Field.ValueType.String}
                                    value={details.location}
                                    hideIfEmpty
                                />
                            )}

                            {fieldSettings.memo !== domain.DocumentFieldState.Hidden && (
                                <StyledField
                                    qa={qa('memo')}
                                    title={messages.nsMemoFieldText}
                                    valueType={FieldsSection.Field.ValueType.String}
                                    value={details.memo}
                                    hideIfEmpty
                                />
                            )}

                            <NetSuiteCustomFields
                                customFields={details.customFields}
                                companyCustomFields={companyCustomFields?.Fields ?? []}
                            />
                        </FieldsSectionWrap>

                        <NetSuiteExpenses
                            expenseLines={expenseLines}
                            companyCustomFields={companyCustomFields?.Fields ?? []}
                            integrationCode={request.integrationCode}
                            documentFields={workflowVersionDocumentFields?.data}
                            exchangeRate={
                                displayCurrency && displayCurrency?.isoCode !== request.currency && exchangeRate !== 1
                                    ? exchangeRate
                                    : null
                            }
                        />

                        <NetSuiteItemLines
                            itemLines={itemLines}
                            companyCustomFields={companyCustomFields?.Fields ?? []}
                            integrationCode={request.integrationCode}
                            documentFields={workflowVersionDocumentFields?.data}
                            exchangeRate={
                                displayCurrency && displayCurrency?.isoCode !== request.currency && exchangeRate !== 1
                                    ? exchangeRate
                                    : null
                            }
                        />

                        {typeof exchangeRate === 'number' && (
                            <CurrencySwitcherContainer>
                                <CurrencySwitcher
                                    request={request}
                                    selectedCurrency={displayCurrency}
                                    requestCurrency={request.details.currency}
                                    onCurrencyChange={onDisplayCurrencyChange}
                                    companyId={request.companyId}
                                />
                            </CurrencySwitcherContainer>
                        )}
                    </>
                )}

                <NetSuiteRequestFooter
                    request={request}
                    displayCurrency={displayCurrency}
                    exchangeRate={exchangeRate}
                />

                <NetSuitePurchaseOrderFooterFields request={request} />

                <AttachmentsSection request={request} />

                <EmailToSupplier
                    request={request}
                    placeholderReplacements={emailToSupplierReplacements}
                    activeAttachmentId={activeAttachmentId}
                    onAttachmentOpen={onEmailToSupplierAttachmentOpen}
                />

                <WorkflowSection request={request} />

                <ActivitySection request={request} />

                {!request.flags.status.isDraft && <AddCommentSection request={request} />}
            </StyledScrollableArea>
        </Root>
    );
});

RequestCardPurchaseOrder.displayName = 'RequestCardPurchaseOrder';
