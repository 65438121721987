import { RequestParams, RequestQueryOptions } from '@approvalmax/data';
import { useGet, useLazyGet } from 'shared/data';

import { netSuiteApiPaths } from '../paths';
import { GetNetSuiteCustomers } from './useGetNetSuiteCustomers.types';

export const useGetNetSuiteCustomers = (
    params?: RequestParams<GetNetSuiteCustomers>,
    queryOptions?: RequestQueryOptions<GetNetSuiteCustomers>
) => {
    return useGet(netSuiteApiPaths.customers, {
        params,
        queryOptions,
        apiVersion: 'v2',
    });
};

export const useLazyGetNetSuiteCustomers = (queryOptions?: RequestQueryOptions<GetNetSuiteCustomers>) => {
    return useLazyGet<GetNetSuiteCustomers>(netSuiteApiPaths.customers, {
        queryOptions,
        apiVersion: 'v2',
    });
};
