import { Flex } from '@approvalmax/ui/src/components';
import { intl, mathService, numberHelpers } from '@approvalmax/utils';
import { domain } from 'modules/data';
import { PriceCheckIndicator } from 'modules/request';
import { DoneIcon } from 'modules/sprites';
import moment from 'moment';

import { isFieldVisible } from '../../NetSuiteRequestCard.helpers';
import { PriceIndicator } from '../../NetSuiteRequestCard.styles';
import { messages } from './NetSuiteExpenses.messages';
import { ExpensesColumnDefinition, ExpensesFieldSettings } from './NetSuiteExpenses.types';

export const getColumnDefs = (
    integrationCode: domain.IntegrationCode,
    fieldSettings: ExpensesFieldSettings,
    exchangeRate?: number | null
): ExpensesColumnDefinition[] => {
    const columnDef: ExpensesColumnDefinition[] = [
        {
            id: 'category',
            name: messages.categoryColumnName,
            value: (li) => (li.category ? li.category.text : null),
            minWidth: 160,
            isHidden: !isFieldVisible(fieldSettings.category),
        },
        {
            id: 'account',
            name: messages.accountColumnName,
            value: (li) => (li.account ? li.account.text : null),
            minWidth: 150,
        },
        {
            id: 'amount',
            name: messages.amountColumnName,
            value: (li) => {
                let amount = li.amount ?? 0;

                if (numberHelpers.isNumber(exchangeRate)) {
                    amount = mathService.multiply(amount, exchangeRate);
                    amount = mathService.round(amount, 2);
                }

                return intl.formatNumber(amount, 'auto');
            },
            alignRight: true,
            cell: (value, li) => {
                let amount = numberHelpers.isNumber(li.amount) ? li.amount : undefined;
                let defaultRate = numberHelpers.isNumber(li.defaultRate) ? li.defaultRate : undefined;

                if (numberHelpers.isNumber(exchangeRate)) {
                    amount = numberHelpers.isNumber(amount)
                        ? mathService.round(mathService.multiply(amount, exchangeRate), 2)
                        : undefined;
                    defaultRate = numberHelpers.isNumber(defaultRate)
                        ? mathService.round(mathService.multiply(defaultRate, exchangeRate), 2)
                        : undefined;
                }

                return (
                    <PriceCheckIndicator
                        actualPrice={amount}
                        expectedPrice={defaultRate}
                        integrationType={domain.IntegrationType.NetSuite}
                        integrationCode={integrationCode}
                    >
                        {({ warningLevel }) => (
                            <Flex alignItems='center' justifyContent='end' spacing='0' wrap={false}>
                                {value}

                                {warningLevel !== PriceCheckIndicator.WarningLevel.None && (
                                    <PriceIndicator warningLevel={warningLevel} />
                                )}
                            </Flex>
                        )}
                    </PriceCheckIndicator>
                );
            },
        },
        {
            id: 'taxCode',
            name: messages.taxCodeColumnName,
            value: (li) => (li.taxCode ? li.taxCode.text : null),
            minWidth: 100,
            isHidden: !isFieldVisible(fieldSettings.taxCode),
        },
        {
            id: 'taxRate',
            name: messages.taxRateColumnName,
            value: (li) => (numberHelpers.isNumber(li.taxRate) ? `${intl.formatNumber(li.taxRate, 'auto')}%` : null),
            alignRight: true,
            minWidth: 60,
            isHidden: !isFieldVisible(fieldSettings.taxRate),
        },
        {
            id: 'taxAmount',
            name: messages.taxAmountColumnName,
            value: (li) => {
                let taxAmount = li.taxAmount ?? 0;

                if (numberHelpers.isNumber(exchangeRate)) {
                    taxAmount = mathService.multiply(taxAmount, exchangeRate);
                    taxAmount = mathService.round(taxAmount, 2);
                }

                return intl.formatNumber(taxAmount, 'auto');
            },
            alignRight: true,
            minWidth: 100,
            isHidden: !isFieldVisible(fieldSettings.taxAmount),
        },
        {
            id: 'grossAmount',
            name: messages.grossAmountColumnName,
            value: (li) => {
                let { grossAmount } = li;

                if (!grossAmount) {
                    return null;
                }

                if (numberHelpers.isNumber(exchangeRate)) {
                    grossAmount = mathService.multiply(grossAmount, exchangeRate);
                    grossAmount = mathService.round(grossAmount, 2);
                }

                return intl.formatNumber(grossAmount, 'auto');
            },
            alignRight: true,
            minWidth: 100,
        },
        {
            id: 'memo',
            name: messages.memoColumnName,
            value: (li) => li.memo,
            minWidth: 115,
            isHidden: !isFieldVisible(fieldSettings.memo),
        },
        {
            id: 'department',
            name: messages.departmentColumnName,
            value: (li) => (li.department ? li.department.text : null),
            minWidth: 150,
            isHidden: !isFieldVisible(fieldSettings.department),
        },
        {
            id: 'class',
            name: messages.classColumnName,
            value: (li) => (li.class ? li.class.text : null),
            minWidth: 100,
            isHidden: !isFieldVisible(fieldSettings.class),
        },
        {
            id: 'location',
            name: messages.locationColumnName,
            value: (li) => (li.location ? li.location.text : null),
            minWidth: 100,
            isHidden: !isFieldVisible(fieldSettings.location),
        },
        {
            id: 'customer',
            name: messages.customerColumnName,
            value: (li) => (li.customer ? li.customer.text : null),
            minWidth: 100,
            isHidden: !isFieldVisible(fieldSettings.customer),
        },
        {
            id: 'projectTask',
            name: messages.projectTaskColumnName,
            value: (li) => (li.projectTask ? li.projectTask.text : null),
            minWidth: 100,
            isHidden: !isFieldVisible(fieldSettings.projectTask),
        },
        {
            id: 'isBillable',
            name: messages.billableColumnName,
            value: (li) => {
                return li.isBillable ? messages.billableColumnTooltip : null;
            },
            width: 50,
            cell: (val) => val && <DoneIcon width={13} height={10} />,
            isHidden: !isFieldVisible(fieldSettings.isBillable),
        },
        {
            id: 'amortizationSchedule',
            name: messages.amortizationSchedule,
            value: (li) => (li.amortizationSchedule ? li.amortizationSchedule.text : null),
            minWidth: 100,
            isHidden: !isFieldVisible(fieldSettings.amortizationSchedule),
        },
        {
            id: 'amortizationStartDate',
            name: messages.amortizationStartDate,
            value: (li) => (li.amortizationStartDate ? moment.utc(li.amortizationStartDate).format('ll') : null),
            minWidth: 100,
            isHidden: !isFieldVisible(fieldSettings.amortizationStartDate),
        },
        {
            id: 'amortizationEndDate',
            name: messages.amortizationEndDate,
            value: (li) => (li.amortizationEndDate ? moment.utc(li.amortizationEndDate).format('ll') : null),
            minWidth: 100,
            isHidden: !isFieldVisible(fieldSettings.amortizationEndDate),
        },
        {
            id: 'amortizationResidual',
            name: messages.amortizationResidual,
            value: (li) => {
                if (numberHelpers.isNumber(li.amortizationResidual)) {
                    return intl.formatNumber(li.amortizationResidual, 'auto');
                }

                return null;
            },
            alignRight: true,
            minWidth: 120,
            isHidden: !isFieldVisible(fieldSettings.amortizationSchedule),
        },
    ];

    return columnDef.filter((col) => !col.isHidden);
};
