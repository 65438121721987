import { IdentifiableWithParentId } from './sortReferencesWithNesting.types';

export const sortReferencesWithNesting = <T extends IdentifiableWithParentId>(
    items: T[]
): (T & { nestingLevel: number })[] => {
    const result: (T & { nestingLevel: number })[] = [];

    const itemsMap: Record<
        string,
        {
            children: T[];
        }
    > = {};

    items.forEach((item) => {
        if (!itemsMap[item.id]) {
            itemsMap[item.id] = {
                children: [],
            };
        }
    });

    items.forEach((item) => {
        if (item.parentId) {
            const isParentIdExists = Boolean(itemsMap[item.parentId]);

            if (isParentIdExists) {
                itemsMap[item.parentId].children.push(item);

                return;
            }
        }
    });

    const addItemWithChildren = (item: T, nestingLevel: number) => {
        const itemWithNesting = { ...item, nestingLevel };

        result.push(itemWithNesting);

        const children = itemsMap[item.id].children;

        if (children) {
            children.forEach((child) => {
                addItemWithChildren(child, nestingLevel + 1);
            });
        }
    };

    items.forEach((item) => {
        const isRootLevelItem = !item.parentId || !itemsMap[item.parentId];

        if (isRootLevelItem) {
            addItemWithChildren(item, 0);
        }
    });

    return result;
};
