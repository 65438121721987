import { NumberField } from '@approvalmax/ui/src/components';
import { constants } from 'modules/common';
import { memo, useCallback } from 'react';

import { SettingsSection } from '../SettingsSection/SettingsSection';
import SettingsSubsection from '../SettingsSubsection/SettingsSubsection';
import { messages } from './SectionNetSuiteCustomFormId.messages';
import { SectionNetSuiteCustomFormIdProps } from './SectionNetSuiteCustomFormId.types';

const { netSuiteConstants } = constants;

export const SectionNetSuiteCustomFormId = memo<SectionNetSuiteCustomFormIdProps>((props) => {
    const { templateSettings, setTemplateSettings, readonly } = props;

    const handleChange = useCallback(
        (newValue: number) => {
            setTemplateSettings((current) => ({
                ...current,
                netSuiteCustomFormId: newValue,
            }));
        },
        [setTemplateSettings]
    );

    return (
        <SettingsSection title={messages.title}>
            <SettingsSubsection subtitle={messages.subtitle}>
                <NumberField
                    placeholder={messages.placeholder}
                    value={templateSettings?.netSuiteCustomFormId}
                    max={netSuiteConstants.customFormIdMaxValue}
                    onChange={handleChange}
                    readOnly={readonly}
                />
            </SettingsSubsection>
        </SettingsSection>
    );
});

SectionNetSuiteCustomFormId.displayName = 'SectionNetSuiteCustomFormId';
