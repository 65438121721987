import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { useMount } from 'react-use';
import { z } from 'zod';

import { BackupMethodOptions } from '../../TwoFaEnablingWizard.constants';
import { useTwoFaWizardController, useTwoFaWizardStep } from '../../TwoFaEnablingWizard.hooks';
import { WizardStep } from '../../TwoFaEnablingWizard.types';
import { messages } from './StepBackupMethods.messages';
import { schema } from './StepBackupMethods.schema';

export const useStepBackupMethods = () => {
    const { setWizardStep } = useTwoFaWizardStep();
    const { setWizardController } = useTwoFaWizardController();

    const form = useForm<z.infer<typeof schema>>({
        defaultValues: {
            backupMethod: undefined,
        },
        resolver: zodResolver(schema),
    });

    const handleSubmit = form.handleSubmit((data) => {
        const { backupMethod } = data;

        switch (backupMethod) {
            case BackupMethodOptions.backupCodes:
                setWizardStep(WizardStep.backupCodes);
                break;

            case BackupMethodOptions.backupEmailAddress:
                setWizardStep(WizardStep.backupEmailEnter);
                break;
        }
    });

    useMount(() => {
        setWizardController((prev) => ({
            ...prev,
            title: messages.title,
            onPrev: undefined,
            onNext: handleSubmit,
        }));
    });

    return {
        form,
        handleSubmit,
    };
};
