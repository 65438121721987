import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('pages.RequestList.WorkflowSection', {
    active: 'ACTIVE',
    editParticipantsTitleText: 'Change Approvers in this step',
    reassignRequestTitleText: 'Reassign the request to another Approver',
    reviewStep: 'Review step',
    reviewed: 'REVIEWED',
    sectionText: 'Approval workflow',
    stepActiveText: 'Active',
    stepAutoApproved: 'There are no Approvers in this step.{br}It has been skipped.',
    stepNotStartedText: 'Upcoming',
    stepTooltipStatusActive: 'ACTIVE STEP',
    stepTooltipStatusApproved: 'APPROVED',
    stepTooltipStatusLocked: 'LOCKED ({reason})',
    stepTooltipStatusLockedReasonApproved: 'request approved',
    stepTooltipStatusLockedReasonApprovedByAdmin: 'request force approved by Company Administrator',
    stepTooltipStatusLockedReasonApprovedExternally: 'request approved in {integrationName}',
    stepTooltipStatusLockedReasonCancelled: 'request cancelled',
    stepTooltipStatusLockedReasonCancelledByAdmin: 'request cancelled by Company Administrator',
    stepTooltipStatusLockedReasonCancelledExternally: 'request cancelled in {integrationName}',
    stepTooltipStatusLockedReasonRejected: 'request rejected',
    stepTooltipStatusLockedReasonRejectedByAdmin: 'request force rejected by Company Administrator',
    stepTooltipStatusLockedReasonRejectedExternally: 'request rejected in {integrationName}',
    stepTooltipStatusRejected: 'REJECTED',
    stepTooltipStatusUpcoming: 'UPCOMING',
    stepTypeAnyOf: 'Any of',
    stepTypeAnyOfCount: 'Any {count} of selected',
    stepTypeDefault: 'All',
    stepTypeText: `Approval condition: {type}`,
});
