import { CloseIcon } from '@approvalmax/ui';
import { Box, Button, Flex, Spacing, Text } from '@approvalmax/ui/src/components';
import { errorHelpers } from '@approvalmax/utils';
import { constants } from 'modules/common';
import { domain } from 'modules/data';
import { actions } from 'modules/profile';
import { useSelector } from 'modules/react-redux';
import { memo, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { getActiveTemplate } from '../../../../selectors/pageSelectors';
import { messages } from './SingletonStepPromotion.messages';
import { SingletonStepPromotionProps } from './SingletonStepPromotion.types';

const { dearConstants, xeroConstants, qBooksConstants, netSuiteConstants } = constants;

export const SingletonStepPromotion = memo<SingletonStepPromotionProps>((props) => {
    const { company, hide } = props;

    const dispatch = useDispatch();
    const template = useSelector(getActiveTemplate);

    const isReviewStepInfoHidden =
        (company.integration?.integrationType !== domain.IntegrationType.QBooks &&
            company.integration?.integrationType !== domain.IntegrationType.Xero) ||
        template?.integrationCode === domain.IntegrationCode.XeroCreditNotesPayable ||
        template?.integrationCode === domain.IntegrationCode.XeroCreditNotesReceivable;

    const tryNow = useCallback(() => {
        dispatch(actions.openConsentToStartTrialPopup(company, 'auto_approvals_wf_builder'));
    }, [company, dispatch]);

    const learnMoreLinkAutoApprovalStep = useMemo(() => {
        switch (company.integration?.integrationType) {
            case domain.IntegrationType.Xero:
                return xeroConstants.XERO_AUTO_APPROVAL_LEARN_MORE_LINK;

            case domain.IntegrationType.QBooks:
                return qBooksConstants.QBO_AUTO_APPROVAL_LEARN_MORE_LINK;

            case domain.IntegrationType.NetSuite:
                return netSuiteConstants.NS_AUTO_APPROVAL_LEARN_MORE_LINK;

            case domain.IntegrationType.Dear:
                return dearConstants.DEAR_AUTO_APPROVAL_LEARN_MORE_LINK;

            case domain.IntegrationType.None:
                throw errorHelpers.notSupportedError('Do not show promotion for companies without integration');

            default:
                throw errorHelpers.assertNever(company.integration!.integrationType);
        }
    }, [company.integration]);

    const learnMoreLinkReviewStep = useMemo(() => {
        switch (company.integration?.integrationType) {
            case domain.IntegrationType.Xero:
                return xeroConstants.XERO_REVIEW_STEP_LEARN_MORE_LINK;

            case domain.IntegrationType.QBooks:
                return qBooksConstants.QBO_REVIEW_STEP_LEARN_MORE_LINK;

            default:
                return null;
        }
    }, [company.integration]);

    return (
        <Box color='mint70' spacing='8' width={220} height='fit-content' radius='xsmall'>
            <Flex justifyContent='space-between' alignItems='center' wrap={false}>
                <Text font='label' fontSize='small' color='midnight80'>
                    {isReviewStepInfoHidden ? messages.enableAutoApprovalStep : messages.enableAutoApprovalOrReviewStep}
                </Text>

                <Button icon noPadding onClick={hide} variant='text' color='blue100'>
                    <CloseIcon size={12} color='midnight80' />
                </Button>
            </Flex>

            <Spacing height={8} />

            {!isReviewStepInfoHidden && (
                <>
                    <Text font='body' fontSize='small' color='midnight80'>
                        <strong>{messages.descriptionReviewLabel}</strong>

                        {messages.descriptionReview({ templateName: template?.displayName })}
                    </Text>

                    <Spacing height={8} />

                    {learnMoreLinkReviewStep && (
                        <>
                            <Text font='label' fontSize='small'>
                                <a href={learnMoreLinkReviewStep} target='_blank' rel='noopener noreferrer'>
                                    {messages.learnMore}
                                </a>
                            </Text>

                            <Spacing height={8} />
                        </>
                    )}
                </>
            )}

            <Text font='body' fontSize='small' color='midnight80'>
                <strong>{messages.descriptionAutoApprovalLabel}</strong>

                {messages.descriptionAutoApproval({ templateName: template?.displayName })}
            </Text>

            <Spacing height={8} />

            <Text font='label' fontSize='small'>
                <a href={learnMoreLinkAutoApprovalStep} target='_blank' rel='noopener noreferrer'>
                    {messages.learnMore}
                </a>
            </Text>

            <Spacing height={4} />

            <Flex spacing='8' justifyContent='end'>
                <Button color='white100' size='xsmall' onClick={tryNow}>
                    {messages.tryNow}
                </Button>
            </Flex>
        </Box>
    );
});

SingletonStepPromotion.displayName = 'SingletonStepPromotion';
