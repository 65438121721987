import { RequestMutationOptions } from '@approvalmax/data';
import { useMutate } from 'shared/data';

import { budgetsApiPaths } from '../paths';
import { useInvalidateGetXeroBudgets } from '../useGetXeroBudgets/useGetXeroBudgets';
import { UpdateXeroBudget } from './useUpdateXeroBudget.types';

export const useUpdateXeroBudget = (mutationOptions?: RequestMutationOptions<UpdateXeroBudget>) => {
    const invalidateGetXeroBudgets = useInvalidateGetXeroBudgets();

    return useMutate(budgetsApiPaths.updateXeroBudget, {
        mutationOptions: {
            ...mutationOptions,
            onSettled: (data, error, variables, context) => {
                invalidateGetXeroBudgets({ query: { companyId: variables.body?.companyId } });
                mutationOptions?.onSettled?.(data, error, variables, context);
            },
        },
        apiVersion: 'v1',
    });
};
