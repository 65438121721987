import { ExpandedCompany } from 'modules/common/selectors/types';
import { domain } from 'modules/data';

export const isOcrEnableForCompany = (company: ExpandedCompany) => {
    const ocrEnabled =
        company.licenseFeatures.includes(domain.CompanyLicenseFeature.Capture) ||
        company.allFeaturesTrialStatus === domain.AllFeaturesTrialStatus.Active ||
        company.licenseProductPlan === domain.CompanyPlan.Trial;

    if (
        company.integration?.integrationType === domain.IntegrationType.NetSuite &&
        company.betaFeatures.includes(domain.CompanyBetaFeature.CaptureNetSuiteBill)
    ) {
        return true;
    }

    if (
        company.integration?.integrationType === domain.IntegrationType.Xero &&
        (ocrEnabled ||
            company.betaFeatures.includes(domain.CompanyBetaFeature.XeroOcr) ||
            company.betaFeatures.includes(domain.CompanyBetaFeature.CaptureXeroPurchaseOrder) ||
            company.betaFeatures.includes(domain.CompanyBetaFeature.CaptureXeroSalesInvoice) ||
            company.betaFeatures.includes(domain.CompanyBetaFeature.CaptureXeroAPCreditNote))
    ) {
        return true;
    }

    if (
        company.integration?.integrationType === domain.IntegrationType.QBooks &&
        (ocrEnabled || company.betaFeatures.includes(domain.CompanyBetaFeature.CaptureQBOBill))
    ) {
        return true;
    }

    return false;
};
