export const netSuiteApiPaths = <const>{
    customers: ':companyId/netsuite/:documentType/customers',
    customer: ':companyId/netsuite/:documentType/customers/:customerId',
    discountItems: ':companyId/netsuite/discount-items',
    opportunities: ':companyId/netsuite/:documentType/opportunities',
    partners: ':companyId/netsuite/:documentType/partners',
    leadSources: ':companyId/netsuite/:documentType/lead-sources',
    shippingMethods: ':companyId/netsuite/:documentType/shipping-methods',
    employees: ':companyId/netsuite/:documentType/employees',
    priceLevels: ':companyId/netsuite/:documentType/price-levels',
    projectTasks: ':companyId/netsuite/customers/:customerId/project-tasks',
};
