import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('pages.RequestList.BillBatchPaymentRequestCard.MainInformationSection', {
    paymentDate: 'Payment date',
    bankAccount: 'Bank account',
    narrative: 'Narrative',
    details: 'Details',
    particulars: 'Particulars',
    code: 'Code',
    reference: 'Reference',
});
