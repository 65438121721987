import { toast } from '@approvalmax/ui/src/components';
import intersection from 'lodash/intersection';
import { selectors } from 'modules/common';
import { backend, domain, schemas, State } from 'modules/data';
import { createAction, createAsyncAction, createErrorAction, ExtractActions, ThunkAction } from 'modules/react-redux';
import { api } from 'services/api';

import { getActiveTemplate } from '../selectors/pageSelectors';
import { isNewUser } from '../selectors/userSelectors';
import { messages } from './inviteUsers.messages';

export const OPEN_INVITE_USERS_POPUP = 'WORKFLOW_TEMPLATES/OPEN_INVITE_USERS_POPUP';

interface OpenInviteUsersPopupAction {
    type: typeof OPEN_INVITE_USERS_POPUP;
    users: string[];
    companyId: string;
    preselectedUsers: string[];
}

export function openInviteUsersPopup(preselectedUsers: string[] = []): ThunkAction<State> {
    return (dispatch, getState) => {
        const state = getState();
        const template = getActiveTemplate(state);

        if (!template) {
            throw new Error('Active workflow template missed!');
        }

        const company = selectors.company.getCompanyById(state, template.companyId);
        const companyTeam = selectors.company.getCompanyTeam(state, company);
        const notActiveTeamMembers = companyTeam
            .filter((u) => u.status !== domain.CompanyUserStatus.Active)
            .map((u) => u.id);
        const templateUsers = selectors.template.getTemplateUsers(state, template);
        const notInvitedTemplateUsers = intersection(notActiveTeamMembers, templateUsers);

        if (notInvitedTemplateUsers.length > 0) {
            dispatch<OpenInviteUsersPopupAction>({
                type: OPEN_INVITE_USERS_POPUP,
                users: notInvitedTemplateUsers,
                companyId: template.companyId,
                preselectedUsers,
            });
        }
    };
}

export const INVITE_USERS = 'WORKFLOW_TEMPLATES/INVITE_USERS';
export const INVITE_USERS_RESPONSE = 'WORKFLOW_TEMPLATES/INVITE_USERS_RESPONSE';
export const INVITE_USERS_FAILURE = 'WORKFLOW_TEMPLATES/INVITE_USERS_FAILURE';
export const inviteUsers = ({
    company,
    invitationMessage,
    users,
    userEmailsWithRoles,
}: {
    company: domain.Company;
    invitationMessage: string;
    users: selectors.types.ExpandedUser[];
    userEmailsWithRoles: Record<string, domain.CompanyUserRole>;
}) =>
    createAsyncAction({
        request: () =>
            createAction(INVITE_USERS, {
                company,
                users,
            }),

        response: async (request) => {
            let response: { Participants: backend.CompanyParticipantAnswer[] } = {
                Participants: [],
            };

            const newUsers = users.filter((user) => isNewUser(user, request.company));
            const existingUsers = users.filter((user) => !isNewUser(user, request.company));

            if (newUsers.length > 0) {
                const result = await api.companies.addParticipants({
                    companyId: company.id,
                    userEmailsWithRoles: Object.fromEntries(
                        newUsers.map((user) => [user.userEmail, userEmailsWithRoles[user.userEmail]])
                    ),
                    doInvite: true,
                    invitationText: invitationMessage,
                });

                response.Participants.push(...result.Participants);
            }

            if (existingUsers.length > 0) {
                const result = await api.companies.inviteParticipants({
                    companyId: company.id,
                    participantUserIdsWithRoles: Object.fromEntries(
                        existingUsers.map((user) => [user.databaseId, userEmailsWithRoles[user.userEmail]])
                    ),
                    invitationText: invitationMessage,
                });

                response.Participants.push(...result.Participants);
            }

            return createAction(INVITE_USERS_RESPONSE, {
                request,
                company,
                raw: response,
                userStatuses: schemas.company.extractUserStatusFromAnswerArray(response.Participants, company.author),
            });
        },

        failure: (error) => createErrorAction(INVITE_USERS_FAILURE, error, {}),

        schema: {
            raw: { Participants: [schemas.userSchema] },
        },

        didDispatchResponse: () => {
            toast.success(
                messages.inviteUsersSuccess({
                    count: users.length,
                })
            );
        },
    });

export const DISCARD_INVITE_USERS_POPUP = 'WORKFLOW_TEMPLATES/DISCARD_INVITE_USERS_POPUP';
export const discardInviteUsersPopup = () => createAction(DISCARD_INVITE_USERS_POPUP, {});

export type Action = ExtractActions<typeof discardInviteUsersPopup | typeof inviteUsers> | OpenInviteUsersPopupAction;
