import { createContext, FC, PropsWithChildren, useContext, useMemo, useState } from 'react';

import { TableContextValue } from './Table.types';

export const TableContext = createContext<TableContextValue>({
    isLeftStickyShadow: false,
    isRightStickyShadow: false,
    setIsLeftStickyShadow: () => undefined,
    setIsRightStickyShadow: () => undefined,
});

export const TableProvider: FC<PropsWithChildren> = (props) => {
    const { children } = props;

    const [isLeftStickyShadow, setIsLeftStickyShadow] = useState(false);
    const [isRightStickyShadow, setIsRightStickyShadow] = useState(false);

    const value = useMemo(
        () => ({
            isLeftStickyShadow,
            isRightStickyShadow,
            setIsLeftStickyShadow,
            setIsRightStickyShadow,
        }),
        [isLeftStickyShadow, isRightStickyShadow]
    );

    return <TableContext.Provider value={value}>{children}</TableContext.Provider>;
};

export const useTableContext = () => useContext(TableContext);
