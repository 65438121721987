import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('components.ProfileEditPopup.Content', {
    title: 'Edit profile',
    buttonSaveText: 'Save changes',
    buttonSaveTitle: 'Save profile changes',
    messageProfileSaved: 'Your profile was saved.',
    tabGeneral: 'General',
    tabNotifications: 'Notifications',
    tabSecurity: 'Security',
    tabActiveSessions: 'Active sessions',
});
