import { domain } from 'modules/data';

import { netSuiteDocumentStatusColumnMessages } from './columnDefinitions.messages';

export const netSuiteDocumentStatusesByIntegrationCode: {
    [key in domain.IntegrationCode]?: Set<keyof typeof netSuiteDocumentStatusLabels>;
} = {
    [domain.IntegrationCode.NetSuiteBill]: new Set(Object.values(domain.NetSuiteVendorBillObjectStatusFilter)),
    [domain.IntegrationCode.NetSuitePO]: new Set(Object.values(domain.NetSuitePurchaseOrderObjectStatusFilter)),
    [domain.IntegrationCode.NetSuiteSalesOrder]: new Set(Object.values(domain.NetSuiteSalesOrderStatusFilter)),
    [domain.IntegrationCode.NetSuiteVRA]: new Set(Object.values(domain.NetSuiteVRAStatusFilter)),
    [domain.IntegrationCode.NetSuiteJournalEntry]: new Set(Object.values(domain.NetSuiteJournalEntryStatusFilter)),
    [domain.IntegrationCode.NetSuiteInvoice]: new Set(Object.values(domain.NetSuiteInvoiceStatusFilter)),
    [domain.IntegrationCode.NetSuiteExpenseReport]: new Set(Object.values(domain.NetSuiteExpenseReportStatusFilter)),
    [domain.IntegrationCode.NetSuiteBillPayment]: new Set(Object.values(domain.NetSuiteBillPaymentStatusFilter)),
};

export const requestdNetSuiteDocumentStatuses = {
    [domain.NetSuiteVendorBillObjectStatusFilter.VendorBillUnknown]: 'vb-0',
    [domain.NetSuiteVendorBillObjectStatusFilter.VendorBillPendingApproval]: 'vb-1',
    [domain.NetSuiteVendorBillObjectStatusFilter.VendorBillRejected]: 'vb-2',
    [domain.NetSuiteVendorBillObjectStatusFilter.VendorBillOpen]: 'vb-3',
    [domain.NetSuiteVendorBillObjectStatusFilter.VendorBillPaidInFull]: 'vb-4',
    [domain.NetSuiteVendorBillObjectStatusFilter.VendorBillCancelled]: 'vb-5',

    [domain.NetSuitePurchaseOrderObjectStatusFilter.PurchaseOrderUnknown]: 'po-0',
    [domain.NetSuitePurchaseOrderObjectStatusFilter.PurchaseOrderClosed]: 'po-1',
    [domain.NetSuitePurchaseOrderObjectStatusFilter.PurchaseOrderFullyBilled]: 'po-2',
    [domain.NetSuitePurchaseOrderObjectStatusFilter.PurchaseOrderPartiallyReceived]: 'po-3',
    [domain.NetSuitePurchaseOrderObjectStatusFilter.PurchaseOrderPendingBillingPartiallyReceived]: 'po-4',
    [domain.NetSuitePurchaseOrderObjectStatusFilter.PurchaseOrderPendingBilling]: 'po-5',
    [domain.NetSuitePurchaseOrderObjectStatusFilter.PurchaseOrderPendingReceipt]: 'po-6',
    [domain.NetSuitePurchaseOrderObjectStatusFilter.PurchaseOrderPendingSupervisorApproval]: 'po-7',
    [domain.NetSuitePurchaseOrderObjectStatusFilter.PurchaseOrderRejectedBySupervisor]: 'po-8',

    [domain.NetSuiteSalesOrderStatusFilter.SalesOrderUnknown]: 'so-unknown',
    [domain.NetSuiteSalesOrderStatusFilter.SalesOrderPendingApproval]: 'so-pending-approval',
    [domain.NetSuiteSalesOrderStatusFilter.SalesOrderPendingFulfillment]: 'so-pending-fulfillment',
    [domain.NetSuiteSalesOrderStatusFilter.SalesOrderPartiallyFulfilled]: 'so-partially-fulfilled',
    [domain.NetSuiteSalesOrderStatusFilter.SalesOrderPendingBilling]: 'so-pending-billing',
    [domain.NetSuiteSalesOrderStatusFilter.SalesOrderPendingBillingPartFulfilled]: 'so-pending-billing-part-fulfilled',
    [domain.NetSuiteSalesOrderStatusFilter.SalesOrderClosed]: 'so-closed',
    [domain.NetSuiteSalesOrderStatusFilter.SalesOrderBilled]: 'so-fully-billed',
    [domain.NetSuiteSalesOrderStatusFilter.SalesOrderCancelled]: 'so-cancelled',
    [domain.NetSuiteSalesOrderStatusFilter.SalesOrderUndefined]: 'so-undefined',

    [domain.NetSuiteVRAStatusFilter.VRAPendingReturn]: 'vra-pending-return',
    [domain.NetSuiteVRAStatusFilter.VRACancelled]: 'vra-cancelled',
    [domain.NetSuiteVRAStatusFilter.VRAClosed]: 'vra-closed',
    [domain.NetSuiteVRAStatusFilter.VRACredited]: 'vra-credited',
    [domain.NetSuiteVRAStatusFilter.VRAPartiallyReturned]: 'vra-partially-returned',
    [domain.NetSuiteVRAStatusFilter.VRAPendingApproval]: 'vra-pending-approval',
    [domain.NetSuiteVRAStatusFilter.VRAPendingCredit]: 'vra-pending-credit',
    [domain.NetSuiteVRAStatusFilter.VRAPendingCreditPartiallyReturned]: 'vra-pending-credit-partially-returned',
    [domain.NetSuiteVRAStatusFilter.VRAUndefined]: 'vra-undefined',

    [domain.NetSuiteJournalEntryStatusFilter.JournalEntryPendingApproval]: 'je-pendingApproval',
    [domain.NetSuiteJournalEntryStatusFilter.JournalEntryRejected]: 'je-rejected',
    [domain.NetSuiteJournalEntryStatusFilter.JournalEntryVoided]: 'je-voided',
    [domain.NetSuiteJournalEntryStatusFilter.JournalEntryApprovedForPosting]: 'je-approved for posting',
    [domain.NetSuiteJournalEntryStatusFilter.JournalEntryUndefined]: 'je-undefined',

    [domain.NetSuiteInvoiceStatusFilter.InvoiceUndefined]: 'i-undefined',
    [domain.NetSuiteInvoiceStatusFilter.InvoiceOpen]: 'i-open',
    [domain.NetSuiteInvoiceStatusFilter.InvoicePaidInFull]: 'i-paid-in-full',
    [domain.NetSuiteInvoiceStatusFilter.InvoicePendingApproval]: 'i-pending-approval',
    [domain.NetSuiteInvoiceStatusFilter.InvoiceVoided]: 'i-voided',
    [domain.NetSuiteInvoiceStatusFilter.InvoiceRejected]: 'i-rejected',

    [domain.NetSuiteExpenseReportStatusFilter.ExpenseReportUnknown]: 'er-unknown',
    [domain.NetSuiteExpenseReportStatusFilter.ExpenseReportApprovedByAccounting]: 'er-approved-by-accounting',
    [domain.NetSuiteExpenseReportStatusFilter.ExpenseReportApprovedOverriddenByAccounting]:
        'er-approved-overridden-by-accounting',
    [domain.NetSuiteExpenseReportStatusFilter.ExpenseReportInProgress]: 'er-in-progress',
    [domain.NetSuiteExpenseReportStatusFilter.ExpenseReportInTransit]: 'er-in-transit',
    [domain.NetSuiteExpenseReportStatusFilter.ExpenseReportPaidInFull]: 'er-paid-in-full',
    [domain.NetSuiteExpenseReportStatusFilter.ExpenseReportPendingAccountingApproval]: 'er-pending-accounting-approval',
    [domain.NetSuiteExpenseReportStatusFilter.ExpenseReportPendingSupervisorApproval]: 'er-pending-supervisor-approval',
    [domain.NetSuiteExpenseReportStatusFilter.ExpenseReportRejectedByAccounting]: 'er-rejected-by-accounting',
    [domain.NetSuiteExpenseReportStatusFilter.ExpenseReportRejectedBySupervisor]: 'er-rejected-by-supervisor',
    [domain.NetSuiteExpenseReportStatusFilter.ExpenseReportRejectedOverriddenByAccounting]:
        'er-rejected-overridden-by-accounting',
    [domain.NetSuiteExpenseReportStatusFilter.ExpenseReportVoided]: 'er-voided',

    [domain.NetSuiteBillPaymentStatusFilter.BillPaymentUnknown]: 'bp-unknown',
    [domain.NetSuiteBillPaymentStatusFilter.BillPaymentApproved]: 'bp-approved',
    [domain.NetSuiteBillPaymentStatusFilter.BillPaymentConfirmed]: 'bp-confirmed',
    [domain.NetSuiteBillPaymentStatusFilter.BillPaymentDeclined]: 'bp-declined',
    [domain.NetSuiteBillPaymentStatusFilter.BillPaymentInTransit]: 'bp-in-transit',
    [domain.NetSuiteBillPaymentStatusFilter.BillPaymentOnlineBillPayPendingAccountingApproval]:
        'bp-online-bill-pay-pending-accounting-approval',
    [domain.NetSuiteBillPaymentStatusFilter.BillPaymentPendingApproval]: 'bp-pending-approval',
    [domain.NetSuiteBillPaymentStatusFilter.BillPaymentRejected]: 'bp-rejected',
    [domain.NetSuiteBillPaymentStatusFilter.BillPaymentVoided]: 'bp-voided',
};

export const invertedRequestNetSuiteDocumentStatuses = Object.fromEntries(
    Object.entries(requestdNetSuiteDocumentStatuses).map(([key, value]) => [value, key])
) as { [key: string]: keyof typeof netSuiteDocumentStatusLabels };

export const netSuiteDocumentStatusLabels: { [key in domain.NetSuiteDocumentStatusFilter]: string } = {
    [domain.NetSuiteVendorBillObjectStatusFilter.VendorBillUnknown]:
        netSuiteDocumentStatusColumnMessages.vendorBillUnknown,
    [domain.NetSuiteVendorBillObjectStatusFilter.VendorBillPendingApproval]:
        netSuiteDocumentStatusColumnMessages.vendorBillPendingApproval,
    [domain.NetSuiteVendorBillObjectStatusFilter.VendorBillRejected]:
        netSuiteDocumentStatusColumnMessages.vendorBillRejected,
    [domain.NetSuiteVendorBillObjectStatusFilter.VendorBillOpen]: netSuiteDocumentStatusColumnMessages.vendorBillOpen,
    [domain.NetSuiteVendorBillObjectStatusFilter.VendorBillPaidInFull]:
        netSuiteDocumentStatusColumnMessages.vendorBillPaidInFull,
    [domain.NetSuiteVendorBillObjectStatusFilter.VendorBillCancelled]:
        netSuiteDocumentStatusColumnMessages.vendorBillCancelled,

    [domain.NetSuitePurchaseOrderObjectStatusFilter.PurchaseOrderUnknown]:
        netSuiteDocumentStatusColumnMessages.purchaseOrderUnknown,
    [domain.NetSuitePurchaseOrderObjectStatusFilter.PurchaseOrderClosed]:
        netSuiteDocumentStatusColumnMessages.purchaseOrderClosed,
    [domain.NetSuitePurchaseOrderObjectStatusFilter.PurchaseOrderFullyBilled]:
        netSuiteDocumentStatusColumnMessages.purchaseOrderFullyBilled,
    [domain.NetSuitePurchaseOrderObjectStatusFilter.PurchaseOrderPartiallyReceived]:
        netSuiteDocumentStatusColumnMessages.purchaseOrderPartiallyReceived,
    [domain.NetSuitePurchaseOrderObjectStatusFilter.PurchaseOrderPendingBillingPartiallyReceived]:
        netSuiteDocumentStatusColumnMessages.purchaseOrderPendingBillingPartiallyReceived,
    [domain.NetSuitePurchaseOrderObjectStatusFilter.PurchaseOrderPendingBilling]:
        netSuiteDocumentStatusColumnMessages.purchaseOrderPendingBilling,
    [domain.NetSuitePurchaseOrderObjectStatusFilter.PurchaseOrderPendingReceipt]:
        netSuiteDocumentStatusColumnMessages.purchaseOrderPendingReceipt,
    [domain.NetSuitePurchaseOrderObjectStatusFilter.PurchaseOrderPendingSupervisorApproval]:
        netSuiteDocumentStatusColumnMessages.purchaseOrderPendingSupervisorApproval,
    [domain.NetSuitePurchaseOrderObjectStatusFilter.PurchaseOrderRejectedBySupervisor]:
        netSuiteDocumentStatusColumnMessages.purchaseOrderRejectedBySupervisor,

    [domain.NetSuiteSalesOrderStatusFilter.SalesOrderUnknown]: netSuiteDocumentStatusColumnMessages.salesOrderUnknown,
    [domain.NetSuiteSalesOrderStatusFilter.SalesOrderPendingApproval]:
        netSuiteDocumentStatusColumnMessages.salesOrderPendingApproval,
    [domain.NetSuiteSalesOrderStatusFilter.SalesOrderPendingFulfillment]:
        netSuiteDocumentStatusColumnMessages.salesOrderPendingFulfillment,
    [domain.NetSuiteSalesOrderStatusFilter.SalesOrderPartiallyFulfilled]:
        netSuiteDocumentStatusColumnMessages.salesOrderPartiallyFulfilled,
    [domain.NetSuiteSalesOrderStatusFilter.SalesOrderPendingBilling]:
        netSuiteDocumentStatusColumnMessages.salesOrderPendingBilling,
    [domain.NetSuiteSalesOrderStatusFilter.SalesOrderPendingBillingPartFulfilled]:
        netSuiteDocumentStatusColumnMessages.salesOrderPendingBillingPartFulfilled,
    [domain.NetSuiteSalesOrderStatusFilter.SalesOrderClosed]: netSuiteDocumentStatusColumnMessages.salesOrderClosed,
    [domain.NetSuiteSalesOrderStatusFilter.SalesOrderBilled]: netSuiteDocumentStatusColumnMessages.salesOrderBilled,
    [domain.NetSuiteSalesOrderStatusFilter.SalesOrderCancelled]:
        netSuiteDocumentStatusColumnMessages.salesOrderCancelled,
    [domain.NetSuiteSalesOrderStatusFilter.SalesOrderUndefined]:
        netSuiteDocumentStatusColumnMessages.salesOrderUndefined,

    [domain.NetSuiteVRAStatusFilter.VRAPendingReturn]: netSuiteDocumentStatusColumnMessages.vraPendingReturn,
    [domain.NetSuiteVRAStatusFilter.VRACancelled]: netSuiteDocumentStatusColumnMessages.vraCancelled,
    [domain.NetSuiteVRAStatusFilter.VRAClosed]: netSuiteDocumentStatusColumnMessages.vraClosed,
    [domain.NetSuiteVRAStatusFilter.VRACredited]: netSuiteDocumentStatusColumnMessages.vraCredited,
    [domain.NetSuiteVRAStatusFilter.VRAPartiallyReturned]: netSuiteDocumentStatusColumnMessages.vraPartiallyReturned,
    [domain.NetSuiteVRAStatusFilter.VRAPendingApproval]: netSuiteDocumentStatusColumnMessages.vraPendingApproval,
    [domain.NetSuiteVRAStatusFilter.VRAPendingCredit]: netSuiteDocumentStatusColumnMessages.vraPendingCredit,
    [domain.NetSuiteVRAStatusFilter.VRAPendingCreditPartiallyReturned]:
        netSuiteDocumentStatusColumnMessages.vraPendingCreditPartiallyReturned,
    [domain.NetSuiteVRAStatusFilter.VRAUndefined]: netSuiteDocumentStatusColumnMessages.vraUndefined,

    [domain.NetSuiteJournalEntryStatusFilter.JournalEntryPendingApproval]:
        netSuiteDocumentStatusColumnMessages.journalEntryPendingApproval,
    [domain.NetSuiteJournalEntryStatusFilter.JournalEntryRejected]:
        netSuiteDocumentStatusColumnMessages.journalEntryRejected,
    [domain.NetSuiteJournalEntryStatusFilter.JournalEntryVoided]:
        netSuiteDocumentStatusColumnMessages.journalEntryVoided,
    [domain.NetSuiteJournalEntryStatusFilter.JournalEntryApprovedForPosting]:
        netSuiteDocumentStatusColumnMessages.journalEntryApprovedForPosting,
    [domain.NetSuiteJournalEntryStatusFilter.JournalEntryUndefined]:
        netSuiteDocumentStatusColumnMessages.journalEntryUndefined,

    [domain.NetSuiteInvoiceStatusFilter.InvoiceUndefined]: netSuiteDocumentStatusColumnMessages.invoiceUndefined,
    [domain.NetSuiteInvoiceStatusFilter.InvoiceOpen]: netSuiteDocumentStatusColumnMessages.invoiceOpen,
    [domain.NetSuiteInvoiceStatusFilter.InvoicePaidInFull]: netSuiteDocumentStatusColumnMessages.invoicePaidInFull,
    [domain.NetSuiteInvoiceStatusFilter.InvoicePendingApproval]:
        netSuiteDocumentStatusColumnMessages.invoicePendingApproval,
    [domain.NetSuiteInvoiceStatusFilter.InvoiceVoided]: netSuiteDocumentStatusColumnMessages.invoiceVoided,
    [domain.NetSuiteInvoiceStatusFilter.InvoiceRejected]: netSuiteDocumentStatusColumnMessages.invoiceRejected,

    [domain.NetSuiteExpenseReportStatusFilter.ExpenseReportUnknown]:
        netSuiteDocumentStatusColumnMessages.expenseReportUnknown,
    [domain.NetSuiteExpenseReportStatusFilter.ExpenseReportApprovedByAccounting]:
        netSuiteDocumentStatusColumnMessages.expenseReportApprovedByAccounting,
    [domain.NetSuiteExpenseReportStatusFilter.ExpenseReportApprovedOverriddenByAccounting]:
        netSuiteDocumentStatusColumnMessages.expenseReportApprovedOverriddenByAccounting,
    [domain.NetSuiteExpenseReportStatusFilter.ExpenseReportInProgress]:
        netSuiteDocumentStatusColumnMessages.expenseReportInProgress,
    [domain.NetSuiteExpenseReportStatusFilter.ExpenseReportInTransit]:
        netSuiteDocumentStatusColumnMessages.expenseReportInTransit,
    [domain.NetSuiteExpenseReportStatusFilter.ExpenseReportPaidInFull]:
        netSuiteDocumentStatusColumnMessages.expenseReportPaidInFull,
    [domain.NetSuiteExpenseReportStatusFilter.ExpenseReportPendingAccountingApproval]:
        netSuiteDocumentStatusColumnMessages.expenseReportPendingAccountingApproval,
    [domain.NetSuiteExpenseReportStatusFilter.ExpenseReportPendingSupervisorApproval]:
        netSuiteDocumentStatusColumnMessages.expenseReportPendingSupervisorApproval,
    [domain.NetSuiteExpenseReportStatusFilter.ExpenseReportRejectedByAccounting]:
        netSuiteDocumentStatusColumnMessages.expenseReportRejectedByAccounting,
    [domain.NetSuiteExpenseReportStatusFilter.ExpenseReportRejectedBySupervisor]:
        netSuiteDocumentStatusColumnMessages.expenseReportRejectedBySupervisor,
    [domain.NetSuiteExpenseReportStatusFilter.ExpenseReportRejectedOverriddenByAccounting]:
        netSuiteDocumentStatusColumnMessages.expenseReportRejectedOverriddenByAccounting,
    [domain.NetSuiteExpenseReportStatusFilter.ExpenseReportVoided]:
        netSuiteDocumentStatusColumnMessages.expenseReportVoided,

    [domain.NetSuiteBillPaymentStatusFilter.BillPaymentUnknown]:
        netSuiteDocumentStatusColumnMessages.billPaymentUnknown,
    [domain.NetSuiteBillPaymentStatusFilter.BillPaymentApproved]:
        netSuiteDocumentStatusColumnMessages.billPaymentApproved,
    [domain.NetSuiteBillPaymentStatusFilter.BillPaymentConfirmed]:
        netSuiteDocumentStatusColumnMessages.billPaymentConfirmed,
    [domain.NetSuiteBillPaymentStatusFilter.BillPaymentDeclined]:
        netSuiteDocumentStatusColumnMessages.billPaymentDeclined,
    [domain.NetSuiteBillPaymentStatusFilter.BillPaymentInTransit]:
        netSuiteDocumentStatusColumnMessages.billPaymentInTransit,
    [domain.NetSuiteBillPaymentStatusFilter.BillPaymentOnlineBillPayPendingAccountingApproval]:
        netSuiteDocumentStatusColumnMessages.billPaymentOnlineBillPayPendingAccountingApproval,
    [domain.NetSuiteBillPaymentStatusFilter.BillPaymentPendingApproval]:
        netSuiteDocumentStatusColumnMessages.billPaymentPendingApproval,
    [domain.NetSuiteBillPaymentStatusFilter.BillPaymentRejected]:
        netSuiteDocumentStatusColumnMessages.billPaymentRejected,
    [domain.NetSuiteBillPaymentStatusFilter.BillPaymentVoided]: netSuiteDocumentStatusColumnMessages.billPaymentVoided,
};
