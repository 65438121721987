import ImageSleep from 'modules/components/ImageIcons/ImageSleep';
import { FC, memo } from 'react';
import styled from 'styled-components/macro';

import { messages } from './PanelEmpty.messages';

const NoPOMatchingFilterContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 30px 20px;
`;

const NoPOMatchingFilterText = styled.div`
    margin-top: 10px;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
`;

const PanelEmpty: FC = () => (
    <NoPOMatchingFilterContainer>
        <ImageSleep />

        <NoPOMatchingFilterText>{messages.noPOMatchingFilter}</NoPOMatchingFilterText>
    </NoPOMatchingFilterContainer>
);

export default memo(PanelEmpty);
