import { selectors } from 'modules/common';
import { domain } from 'modules/data';
import { useMemo } from 'react';
import { useGetBudgetImpactsRaw } from 'shared/data/webApp/v1';

import { getBudgetImpactsData, getShowBudgeting } from './XeroBudgetingSection.selectors';

export const useGetBudgets = (request: selectors.types.ExpandedRequest) => {
    const showBudgeting = getShowBudgeting(request);

    const { data, ...params } = useGetBudgetImpactsRaw(
        {
            query: {
                companyId: request.companyId,
                requestId: request.id,
            },
        },
        { enabled: showBudgeting }
    );

    const budgetImpactsData = useMemo(() => data && domain.schemas.mapBudgetImpactData(data), [data]);
    const mappedData = useMemo(
        () => budgetImpactsData && getBudgetImpactsData(budgetImpactsData?.budgetImpacts, budgetImpactsData?.currency),
        [budgetImpactsData]
    );

    return {
        data: mappedData,
        ...params,
    };
};
