import { Flex, openSplitViewState, ToggleButton } from '@approvalmax/ui/src/components';
import { useAtom } from 'jotai';
import { memo, useCallback } from 'react';

import { messages } from './PreviewToggleButton.messages';

export const PreviewToggleButton = memo(() => {
    const [openSplitView, setOpenSplitView] = useAtom(openSplitViewState);

    const toggleOpenSplitViewState = useCallback(() => {
        setOpenSplitView((currValue) => !currValue);
    }, [setOpenSplitView]);

    return (
        <Flex alignItems='center' shrink={0} grow={0} spacing='16'>
            <ToggleButton.Group
                size='xsmall'
                name='toggleButton'
                value={openSplitView.toString()}
                onChange={toggleOpenSplitViewState}
            >
                <ToggleButton value='true'>{messages.showPreview}</ToggleButton>

                <ToggleButton value='false'>{messages.hidePreview}</ToggleButton>
            </ToggleButton.Group>
        </Flex>
    );
});

PreviewToggleButton.displayName = 'PreviewToggleButton';
