import { RequestMutationOptions } from '@approvalmax/data';
import { useMutate } from 'shared/data';

import { companiesApiPaths } from '../paths';
import { useInvalidateGetCompanies } from '../useGetCompanies/useGetCompanies';
import { UpdateParticipantPermissions } from './useUpdateParticipantPermissions.types';

export const useUpdateParticipantPermissions = (
    mutationOptions?: RequestMutationOptions<UpdateParticipantPermissions>
) => {
    const invalidateGetCompanies = useInvalidateGetCompanies();

    return useMutate(companiesApiPaths.updateParticipantPermissions, {
        mutationOptions: {
            ...mutationOptions,
            onSuccess: async (data, variables, context) => {
                await invalidateGetCompanies({ query: { companyId: variables.params?.path?.companyId } });
                mutationOptions?.onSuccess?.(data, variables, context);
            },
        },
        method: 'put',
        apiVersion: 'v1',
    });
};
