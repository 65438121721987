import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('pages.RequestList.XeroBudgetingSection.BudgetImpactDetailsPopup', {
    description: `
        Please see below line items of this Bill that affect the {budgetName} budget,
        {account} account for {budgetPeriod} period.`,
    popupTitle: 'Budget check for “{budgetName}” budget',
    totalBudgetImpact: 'Total budget impact:',
    warningOfExceed: `
        Please note that with these line items and respective line items of other Bills and Purchase Orders,
        the budget for the defined period is exceeded`,
    overbudgetText: `
        Please note that with these line items and respective line items of other Bills (not counting Purchase Orders),
        the budget for the defined period is exceeded`,
});
