import { AmaxPayShortLogo } from '@approvalmax/ui';
import { Collapse, Flex, List, Spacing } from '@approvalmax/ui/src/components';
import { useAtomValue } from 'jotai';
import { selectors } from 'modules/common';
import { domain } from 'modules/data';
import { useSelector } from 'modules/react-redux';
import { memo } from 'react';
import bemFactory from 'react-bem-factory';
import { IntegrationHeader } from 'shared/components';
import { isPayAvailableViaLicense } from 'shared/helpers';
import { activeCompanyIdState } from 'shared/states';

import { useWorkflowWizard } from '../../hooks/useWorkflowWizard';
import PromotionListItem from '../PromotionListItem/PromotionListItem';
import { usePromotionListItem } from '../PromotionListItem/PromotionListItem.hooks';
import WorkflowListItem from '../WorkflowListItem/WorkflowListItem';
import { useWorkflowListItem } from '../WorkflowListItem/WorkflowListItem.hooks';
import { addonPayValidCurrencies } from './XeroWorkflowList.constants';
import { messages } from './XeroWorkflowList.messages';

const qa = bemFactory.qa('wfl-xero-card');

const XeroWorkflowList = memo(() => {
    const companyId = useAtomValue(activeCompanyIdState);
    const company = useSelector((state) => selectors.company.getCompanyById(state, companyId));
    const workflows = useSelector((state) => selectors.template.getIntegrationTemplatesByCompanyId(state, companyId));
    const subscription = useSelector((state) => selectors.subscription.findCompanySubscription(state, company.id));
    const {
        isAuditor,
        isManager,
        isWorkflowManager,
        isAllFeaturesTrialActive,
        isTrialLicense,
        isAirwallexPaymentAvailable,
    } = company.flags;
    const isValidRole = isAuditor || isManager || isWorkflowManager;
    const isPremium = isAllFeaturesTrialActive || isTrialLicense;
    const isAddonPayAllowedCurrency = addonPayValidCurrencies.includes(subscription?.nextBillingCurrency ?? '');
    const isAFTAvailable = company.allFeaturesTrialStatus === domain.AllFeaturesTrialStatus.AwaitingActivation;

    const isAmaxPayBatchPaymentAvailable =
        company.betaFeatures.includes(domain.CompanyBetaFeature.PayOpenBankingUK) || isPayAvailableViaLicense(company);
    const isAmaxPayBatchPaymentPromotionAvailable =
        isAddonPayAllowedCurrency && isAFTAvailable && !isAmaxPayBatchPaymentAvailable;
    const isXeroBatchPaymentAvailable = company.licenseFeatures.includes(
        domain.CompanyLicenseFeature.XeroBillBatchPayments
    );
    const isQuoteAvailable = company.licenseFeatures.includes(domain.CompanyLicenseFeature.XeroQuotes);
    const isContactAvailable = company.licenseFeatures.includes(domain.CompanyLicenseFeature.XeroContactWorkflows);
    const isManualJournalAvailable =
        company.licenseFeatures.includes(domain.CompanyLicenseFeature.XeroManualJournals) ||
        company.betaFeatures.includes(domain.CompanyBetaFeature.XeroManualJournal);

    const payableWorkflows = useWorkflowListItem(workflows, [
        {
            code: domain.IntegrationCode.XeroPo,
        },
        {
            code: domain.IntegrationCode.XeroBill,
        },
        {
            code: domain.IntegrationCode.XeroCreditNotesPayable,
        },
        {
            code: domain.IntegrationCode.XeroAmaxPayBatchPayment,
            available: isAmaxPayBatchPaymentAvailable && isValidRole,
            icon: <AmaxPayShortLogo color='midnight100' width='max-content' height={16} />,
        },
        {
            code: domain.IntegrationCode.XeroBillBatchPayment,
            available: isXeroBatchPaymentAvailable && isValidRole,
            isPremium,
        },
        {
            code: domain.IntegrationCode.XeroAirwallexBatchPayment,
            available: isAirwallexPaymentAvailable,
            isPremium,
        },
    ]);

    const promotionPayableWorkflows = usePromotionListItem(company, [
        {
            code: domain.IntegrationCode.XeroAmaxPayBatchPayment,
            available: isAmaxPayBatchPaymentPromotionAvailable,
            icon: <AmaxPayShortLogo color='midnight100' width={44} height={16} />,
        },
        {
            code: domain.IntegrationCode.XeroBillBatchPayment,
            available: !isXeroBatchPaymentAvailable,
        },
        {
            code: domain.IntegrationCode.XeroAirwallexBatchPayment,
            available:
                !isAirwallexPaymentAvailable &&
                selectors.company.getAirwallexIsAvailableByCountryCode(company.integration?.countryCode),
        },
    ]);

    const receivableWorkflows = useWorkflowListItem(workflows, [
        {
            code: domain.IntegrationCode.XeroInvoice,
        },
        {
            code: domain.IntegrationCode.XeroCreditNotesReceivable,
        },
        {
            code: domain.IntegrationCode.XeroQuote,
            available: isQuoteAvailable,
        },
    ]);

    const promotionReceivableWorkflows = usePromotionListItem(company, [
        {
            code: domain.IntegrationCode.XeroQuote,
            available: !isQuoteAvailable,
        },
    ]);

    const otherWorkflows = useWorkflowListItem(workflows, [
        {
            code: domain.IntegrationCode.XeroContact,
            available: isContactAvailable,
        },
        {
            code: domain.IntegrationCode.XeroManualJournal,
            available: isManualJournalAvailable,
        },
    ]);

    const promotionOtherWorkflows = usePromotionListItem(company, [
        {
            code: domain.IntegrationCode.XeroContact,
            available: !isContactAvailable,
        },
        {
            code: domain.IntegrationCode.XeroManualJournal,
            available: !isManualJournalAvailable,
        },
    ]);

    useWorkflowWizard(
        [...payableWorkflows, ...receivableWorkflows, ...otherWorkflows].map((el) => el.workflow),
        company
    );

    return (
        <>
            <IntegrationHeader company={company} />

            <Flex direction='column' spacing='16'>
                {(payableWorkflows.length > 0 || promotionPayableWorkflows.length > 0) && (
                    <Collapse
                        name={messages.payableGroup}
                        spacing='0 24 0 52'
                        localStorageKey={`${company.id}-payable`}
                        data-qa={qa('list-group-item-payable')}
                        radius='small'
                    >
                        <List divider removeLastDivider>
                            {payableWorkflows.map((workflowItemProps) => (
                                <List.Item key={workflowItemProps.workflow?.integrationCode}>
                                    <WorkflowListItem
                                        data-qa={qa(`list-item-${workflowItemProps.workflow?.integrationCode}`)}
                                        {...workflowItemProps}
                                    />
                                </List.Item>
                            ))}

                            {promotionPayableWorkflows.map(({ code, icon }) => (
                                <List.Item key={code}>
                                    <PromotionListItem company={company} integrationCode={code} icon={icon} />
                                </List.Item>
                            ))}
                        </List>
                    </Collapse>
                )}

                {(receivableWorkflows.length > 0 || promotionReceivableWorkflows.length > 0) && (
                    <Collapse
                        name={messages.receivableGroup}
                        spacing='0 24 0 52'
                        localStorageKey={`${company.id}-receivable`}
                        data-qa={qa('list-group-item-receivable')}
                        radius='small'
                    >
                        <List divider removeLastDivider>
                            {receivableWorkflows.map((workflowItemProps) => (
                                <List.Item key={workflowItemProps.workflow?.integrationCode}>
                                    <WorkflowListItem
                                        data-qa={qa(`list-item-${workflowItemProps.workflow?.integrationCode}`)}
                                        {...workflowItemProps}
                                    />
                                </List.Item>
                            ))}

                            {promotionReceivableWorkflows.map(({ code }) => (
                                <List.Item key={code}>
                                    <PromotionListItem company={company} integrationCode={code} />
                                </List.Item>
                            ))}
                        </List>
                    </Collapse>
                )}

                {(otherWorkflows.length > 0 || promotionOtherWorkflows.length > 0) && (
                    <Collapse
                        name={messages.otherGroup}
                        spacing='0 24 0 52'
                        localStorageKey={`${company.id}-other`}
                        data-qa={qa('list-group-item-other')}
                        radius='small'
                    >
                        <List divider removeLastDivider>
                            {otherWorkflows.map((workflowItemProps) => (
                                <List.Item key={workflowItemProps.workflow?.integrationCode}>
                                    <WorkflowListItem
                                        data-qa={qa(`list-item-${workflowItemProps.workflow?.integrationCode}`)}
                                        {...workflowItemProps}
                                    />
                                </List.Item>
                            ))}

                            {promotionOtherWorkflows.map(({ code }) => (
                                <List.Item key={code}>
                                    <PromotionListItem company={company} integrationCode={code} />
                                </List.Item>
                            ))}
                        </List>
                    </Collapse>
                )}
            </Flex>

            <Spacing height={16} />
        </>
    );
});

export default XeroWorkflowList;
