import { domain } from 'modules/data';

export const getGroupOfValuesAvailable = (template: domain.Template, company: domain.Company) => {
    const hasBetaFeature = company?.betaFeatures.includes(domain.CompanyBetaFeature.XeroGroupOfAccounts);
    const hasLicenseFeature = company?.licenseFeatures.includes(domain.CompanyLicenseFeature.XeroGroupOfAccounts);
    const isXero = domain.getIntegrationTypeByCode(template.integrationCode) === domain.IntegrationType.Xero;
    const isXeroContact = template.integrationCode === domain.IntegrationCode.XeroContact;

    return isXero && !isXeroContact && (hasBetaFeature || hasLicenseFeature);
};
