import { Mods, mods, theme } from '@approvalmax/theme';
import { StyledMods } from '@styled-kit/mods';
import styled, { keyframes } from 'styled-components';

import { Progress } from '../../../Progress/Progress';
import { HeaderProps } from './Header.types';

const showProgressAnimation = keyframes`
    from {
        opacity: 0;
        transform: translateY(100%);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
`;

export const StyledProgress = styled(Progress)`
    position: absolute;
    top: 0;
    left: 1px;
    right: 1px;
    animation: ${showProgressAnimation} ${theme.duration.medium}ms ease-in-out;
`;

export const ActionsShiftedContainer = styled.div<
    StyledMods<{ hasActions: boolean; actionsSize?: HeaderProps['actionsSize'] }>
>`
    ${mods('hasActions', true)`
        margin-top: 8px;

        ${mods('actionsSize', 'xsmall')`
            margin-top: -2px;
        `};

        ${mods('actionsSize', 'small')`
            margin-top: 2px;
        `};

        ${mods('actionsSize', 'medium')`
            margin-top: 7px;
        `};

        ${mods('actionsSize', 'large')`
            margin-top: 13px;
        `};
    `};
`;

export const Root = styled.div<StyledMods<Mods<'sticky'>>>`
    padding: 32px 32px 16px 32px;
    background-color: ${theme.color.white100};
    position: sticky;
    top: 0;
    border-top-left-radius: ${theme.radius.medium};
    border-top-right-radius: ${theme.radius.medium};
    transition: box-shadow ${theme.duration.medium}ms ease-in-out;
    overflow: hidden;
    z-index: 10;

    ${mods.sticky.true`
        box-shadow: ${theme.shadow.small};
    `};
`;
