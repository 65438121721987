import { domain } from 'modules/data';
import { FC, memo } from 'react';
import styled from 'styled-components';

import FieldEntry from '../../../../../components/FieldEntry';
import FieldEntryWrapper from '../../../../../components/FieldEntryWrapper/FieldEntryWrapper';
import SectionSeparator from '../../../../../components/SectionSeparator/SectionSeparator';
import SectionHeaderText from '../SectionHeaderText/SectionHeaderText';
import { messages } from './TaxSection.messages';

interface TaxSectionProps {
    contact: domain.XeroContact;
}

const Content = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

const StyledFieldEntryWrapper = styled(FieldEntryWrapper)`
    width: 20%;
`;

const TaxSection: FC<TaxSectionProps> = (props) => {
    const { contact } = props;

    if (
        !contact.taxNumber &&
        !contact.salesTax &&
        !contact.purchaseTax &&
        !contact.companyNumber &&
        !contact.defaultCurrency
    ) {
        return null;
    }

    return (
        <>
            <div>
                <SectionHeaderText>{messages.tax}</SectionHeaderText>

                <Content>
                    <StyledFieldEntryWrapper>
                        <FieldEntry title={messages.taxId} value={contact.taxNumber} />
                    </StyledFieldEntryWrapper>

                    <StyledFieldEntryWrapper>
                        <FieldEntry title={messages.defaultSalesTax} value={contact.salesTax} />
                    </StyledFieldEntryWrapper>

                    <StyledFieldEntryWrapper>
                        <FieldEntry title={messages.defaultPurchaseTax} value={contact.purchaseTax} />
                    </StyledFieldEntryWrapper>

                    <StyledFieldEntryWrapper>
                        <FieldEntry title={messages.companyNumber} value={contact.companyNumber} />
                    </StyledFieldEntryWrapper>
                </Content>
            </div>

            <SectionSeparator />
        </>
    );
};

export default memo(TaxSection);
