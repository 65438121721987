import './pendingPracticeInvitationRow.scss';

import { RadioGroupEditor } from '@approvalmax/ui';
import { selectors } from 'modules/common';
import { domain } from 'modules/data';
import { FC, memo } from 'react';
import bemFactory from 'react-bem-factory';

import { messages } from './PendingPracticeInvitationRow.messages';

interface PendingPracticeInvitationListProps {
    item: domain.PracticeInvitation;
    className?: string;
    disabled?: boolean;
}

const bem = bemFactory.block('pending-practice-invitation-row');

const PendingPracticeInvitationRow: FC<PendingPracticeInvitationListProps> = (props) => {
    const { item, className, disabled } = props;

    return (
        <RadioGroupEditor.Item disabled={disabled} className={bem.add(className)()} id={item.id}>
            <div className={bem('text', { disabled })}>
                {!!item.practiceName && <div className={bem('company-name')}>{item.practiceName}</div>}

                <div className={bem('invitation-owner')}>
                    {messages.invitationFrom({
                        name: selectors.user.getDisplayName(
                            item.inviter.firstName,
                            item.inviter.lastName,
                            item.inviter.userEmail
                        ),
                        email: item.inviter.userEmail,
                    })}
                </div>
            </div>
        </RadioGroupEditor.Item>
    );
};

export default memo(PendingPracticeInvitationRow);
