import './addCommentEditor.scss';

import { RichEditor } from '@approvalmax/ui/src/components';
import { RichEditorProps } from '@approvalmax/ui/src/components/RichEditor/RichEditor.types';
import { selectors } from 'modules/common';
import { stateTree } from 'modules/data';
import { ConnectedProps } from 'modules/react-redux';
import React from 'react';
import bemFactory from 'react-bem-factory';
import { connect } from 'react-redux';

import Avatar from '../avatar/Avatar';
import { messages } from './AddCommentEditor.messages';

type State = stateTree.State;

function mapStateToProps(state: State) {
    return {
        me: selectors.profile.getProfileUser(state),
    };
}

const mapDispatchToProps = {};

interface OwnProps extends Pick<RichEditorProps, 'allowMention' | 'mentionItems' | 'customMentionConfig'> {
    className?: string;
    qa?: string;
    value: string;
    onChange: (value: string) => void;
    placeholder?: string;
    focusOnMount?: boolean;
    maxLength?: number;
    changeOnBlur?: boolean;
}

interface OwnState {
    focused: boolean;
}

type Props = ConnectedProps<OwnProps, typeof mapStateToProps, typeof mapDispatchToProps>;

class AddCommentEditor extends React.Component<Props, OwnState> {
    public state = {
        focused: false,
    };

    public render() {
        const {
            className,
            qa,
            value,
            onChange,
            me,
            maxLength,
            changeOnBlur,
            allowMention,
            mentionItems,
            customMentionConfig,
        } = this.props;

        const bem = bemFactory.block('common-ui-add-comment-editor');

        return (
            <div className={bem.add(className)()}>
                <div className={bem('comment-avatar-wrp')}>
                    <Avatar user={me} size={30} />
                </div>

                <div className={bem('comment-editor-wrp', { active: this.state.focused })}>
                    <RichEditor
                        className={bem('comment-editor')}
                        allowTextFormatting={false}
                        restrictNewLine
                        value={value}
                        allowMention={allowMention}
                        mentionItems={mentionItems}
                        customMentionConfig={customMentionConfig}
                        onChange={onChange}
                        onFocus={this._onCommentEditorFocus}
                        onBlur={this._onCommentEditorBlur}
                        placeholder={messages.addCommentPlaceholder}
                        minHeight={1}
                        maxLength={maxLength}
                        changeOnBlur={changeOnBlur}
                        dataQa={qa}
                    />
                </div>
            </div>
        );
    }

    private _onCommentEditorFocus = () => {
        this.setState({
            focused: true,
        });
    };

    private _onCommentEditorBlur = () => {
        this.setState({
            focused: false,
        });
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddCommentEditor);
