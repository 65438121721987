import { constants } from 'modules/common';
import { domain } from 'modules/data';

import { validateCustomFields } from '../../../helpers';
import { messages } from '../../NetSuiteExpenseReportRequestForm.messages';
import { checkExpenseLineIsEmpty } from '../checkExpenseLineIsEmpty/checkExpenseLineIsEmpty';

export const validateExpenseLines = (
    expenseLines: domain.NetSuiteExpenseLineExpenseReport[],
    customFieldsList: domain.Field[],
    workflowId: string
) => {
    const errors: string[] = [];

    const expenseLineWithInvalidTaxAmount = expenseLines.find((line) => {
        if (typeof line.taxAmount === 'number') {
            if (
                line.taxAmount > constants.netSuiteConstants.taxAmountMaxValue ||
                line.taxAmount < -constants.netSuiteConstants.taxAmountMaxValue
            ) {
                return true;
            } else {
                const taxAmountDigitsCount = line.taxAmount.toString().replace(/[^\d]/g, '').length;

                if (taxAmountDigitsCount > constants.netSuiteConstants.taxAmountMaxDigitsCount) {
                    return true;
                }
            }
        }

        return false;
    });

    if (expenseLineWithInvalidTaxAmount) {
        errors.push(
            messages.errorExpenseLineTaxAmountExceed({
                maxValue: constants.netSuiteConstants.taxAmountMaxValue,
                maxDigitsCount: constants.netSuiteConstants.taxAmountMaxDigitsCount,
            })
        );
    }

    expenseLines.forEach((expenseLine) => {
        if (checkExpenseLineIsEmpty(expenseLine)) {
            return;
        }

        validateCustomFields({
            customFieldsValues: expenseLine.customFields || [],
            customFieldsLevels: ['Lines', 'Header And Lines'],
            customFieldsList,
            workflowId,
            errors,
        });
    });

    return errors;
};
