import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('pages.RequestFormPage.actions', {
    deleteRequestSuccess: 'The request has been deleted.',
    uploadErrorDuplicateName:
        'Please re-upload the file with a different name, as a file with "{fileName}" name is already added.',
    uploadErrorRestrictedFileType: 'Sorry, the file type of "{fileName}" cannot be uploaded due to security reasons.',
    uploadErrorTooBig: 'Sorry, the file "{fileName}" exceeds the maximum size of {maxSize}MB.',
    uploadErrorVirusFound:
        'Sorry, the file "{fileName}" cannot be uploaded; our antivirus software indicates that it contains a virus.',
    uploadErrorAttachmentMaxCountExceeded: 'Sorry, you cannot attach more than {count} documents to each request.',
    uploadErrorGeneric: 'Oops, something went wrong.',
    resubmitRequestSuccess: 'The request has been updated and resubmitted for approval.',
    saveUpdatedRequestSuccess: 'The request has been updated.',
    saveDraftRequestSuccess: 'The request has been updated.',
    saveAndApproveRequestSuccess: 'The request has been approved.',
    saveAndSubmitRequestSuccess: 'The request is submitted for approval.',
    checkYourMatchingAllocations: 'Check your matching allocations',
    filenameContainsRestrictedWords: 'Attachment "{fileName}" file name contains restricted substring "{restriction}"',
    qbooksCustomerCreationSuccess: 'Customer created',
    xeroContactCreationSuccess: 'The Contact has been created.',
    qbooksVendorCreationSuccess: 'The Vendor has been created.',
});
