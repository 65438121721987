import { StartTrialPopupOrigin } from 'modules/profile/actions';
import { CompanyRequestListFilter, RequestListFilter, TemplateRequestListFilter } from 'pages/requestList/config';
import { stringifyUrl } from 'query-string';

import * as domain from './modules/data/domain';

const MY_ACCOUNT_URL = `${window.location.protocol}//${window.ApprovalMax.accounthost}`;
const DEVELOPER_PORTAL_URL = `${window.location.protocol}//${window.ApprovalMax.devportalhost}`;
const PARTNER_URL = `${window.location.protocol}//${window.ApprovalMax.partnerhost}`;
const WEBSITE_URL = 'https://www.approvalmax.com/';
const SUPPORT_SITE_URL = 'https://support.approvalmax.com/';
const WEBAPP_URL = window.location.origin;

export type Workspace = 'account' | 'partner' | 'devportal';

export const enum WorkflowsGettingStarted {
    wizard = 'wizard',
    tour = 'tour',
}

export enum Path {
    root = 'root',
    mobile = 'mobile',
    register = 'register',
    emailConfirmation = 'emailConfirmation',
    resetPassword = 'resetPassword',
    resetPassVerifyEmail = 'resetPassVerifyEmail',
    login = 'login',
    request = 'request',
    requestMyList = 'requestMyList',
    requestCompanyList = 'requestCompanyList',
    requestTemplateList = 'requestTemplateList',
    editRequest = 'editRequest',
    newRequest = 'newRequest',
    workflow = 'workflow',
    newWorkflow = 'newWorkflow',
    companyWorkflows = 'companyWorkflows',
    noCompanies = 'noCompanies',
    reportsDashboard = 'reportsDashboard',
    report = 'report',
    companyInfo = 'companyInfo',
    companyUsers = 'companyUsers',
    companyBudgeting = 'companyBudgeting',
    companyBudgetingV2 = 'companyBudgetingV2',
    oauth2Redirect = 'oauth2/redirect',
    signInWithStartFinalizeQBooks = 'signInWithStartFinalizeQBooks',
    signUpWithFinalize = 'signUpWith/finalize',
    signInWithStart = 'signInWith/start',
    signInWithDisconnect = 'signInWith/disconnect',
    xeroSignIn = 'xero-sign-in',
    customerFeedback = 'customerFeedback',
    customerFeedbackBoard = 'customerFeedbackBoard',
    customerDecision = 'customerDecision',
    /** @deprecated Use {@link profileSetupSso} */
    profileSetupSsoOld = 'profileSetupSsoOld',
    /** @deprecated Use {@link profileSetupEmail} */
    profileSetupEmailOld = 'profileSetupEmailOld',
    profileSetupSso = 'profileSetup/sso',
    profileSetupEmail = 'profileSetup/email',
    mobileDecision = 'mobileDecision',
    searchRequests = 'searchRequests',
    searchRequestsWithCompany = 'searchRequestsWithCompany',
    requestMyListWithCompany = 'requestMyListWithCompany',
    airwallexAuth = 'awx_oauth_redir',
    customFields = '/:companyId/customFields',
    slackAuthConnect = 'slack/auth/connect-to-am',
    slackAuthInstall = 'slack/auth/install-to-ws',
    slackAuthConnectUser = 'slack/auth/connect-user-to-am',
    twoFaHardEnforcement = '2fa-hard-enforcement',
    bankAccounts = 'bankAccounts',
    connectBankAccountSuccess = 'connectBankAccount/success',
    connectBankAccountFail = 'connectBankAccount/fail',
    reconnectBankAccountSuccess = 'reconnectBankAccount/success',
    contactPaymentDetails = 'contactPaymentDetails',
    verifyEmail = 'verifyEmail',
    amaxPaymentSuccess = 'amaxPayment/success',
    amaxPaymentFail = 'amaxPayment/fail',
    receiptBankAuth = 'receiptBank/auth',
    completeSetup = 'completeSetup',
    wallets = 'wallets',
    walletsKyc = 'wallets/kyc',
    walletsKycWizard = 'wallets/kyc/wizard',
    walletsKycApproved = 'wallets/kyc/approved',
    walletsKycIncomplete = 'wallets/kyc/incomplete',
    walletsKycSubmitted = 'wallets/kyc/submitted',
    walletsKycRejected = 'wallets/kyc/rejected',
    walletsKycDetails = 'wallets/kycDetails',
    walletsKycDetailsSubmitted = 'wallets/kycDetails/submitted',
    gettingStarted = 'gettingStarted',
    loginWithPasskey = 'loginWithPasskey',
}

const pathsMap = {
    [Path.root]: () => '',
    [Path.mobile]: () => 'mobile',
    [Path.mobileDecision]: () => 'mobileDecision',
    [Path.searchRequests]: (options?: { requestId?: string }) =>
        stringifyUrl({ url: 'requests/search', query: { requestId: options?.requestId } }),
    [Path.searchRequestsWithCompany]: (companyId: string, options?: { requestId?: string }) =>
        stringifyUrl({ url: `requests/search/${companyId}`, query: { requestId: options?.requestId } }),

    [Path.register]: (options?: {
        app?: Workspace;
        utmSource?: string;
        utmCampaign?: string;
        utmMedium?: string;
        utmContent?: string;
        email?: string;
        consentMarketingAccepted?: boolean;
    }) =>
        stringifyUrl(
            {
                url: 'register',
                query: {
                    app: options?.app || undefined,
                    email: options?.email || undefined,
                    consentMarketingAccepted: options?.consentMarketingAccepted ? 'true' : undefined,
                    utm_source: options?.utmSource || undefined,
                    utm_medium: options?.utmMedium || undefined,
                    utm_campaign: options?.utmCampaign || undefined,
                    utm_content: options?.utmContent || undefined,
                },
            },
            {
                sort: false,
            }
        ),
    [Path.emailConfirmation]: (options?: { app?: Workspace; email?: string; consentMarketingAccepted?: boolean }) =>
        stringifyUrl({
            url: 'emailConfirmation',
            query: {
                app: options?.app || undefined,
                email: options?.email || undefined,
                consentMarketingAccepted: options?.consentMarketingAccepted ? 'true' : undefined,
            },
        }),
    [Path.resetPassword]: (email?: string) => `resetPassword${email ? '?email=' + email : ''}`,
    [Path.resetPassVerifyEmail]: (linkCode: string, postAction?: string) =>
        stringifyUrl({ url: Path.resetPassVerifyEmail, query: { linkCode, postAction } }),
    [Path.login]: (options: { redirectPath?: string; workspace?: Workspace; email?: string } = {}) => {
        const { redirectPath, workspace, email } = options;

        return stringifyUrl({
            url: 'login',
            query: {
                app: workspace || undefined,
                postAction: redirectPath || undefined,
                email: email || undefined,
            },
        });
    },
    [Path.request]: (
        requestId: string,
        companyId: string,
        params?: {
            attachmentId?: string;
            auditReport?: boolean;
            /**
             * Flag to show payment summary popup
             * where PayWithAmax button is available
             * (for example XeroAmaxPayBatchPayment requests)
             */
            showPaymentSummary?: boolean;
        }
    ) =>
        stringifyUrl(
            {
                url: `${companyId}/requests/${CompanyRequestListFilter.CompanyAll}`,
                query: {
                    requestId: requestId,
                    attachmentId: params?.attachmentId || undefined,
                    auditReport: params?.auditReport ? 'true' : undefined,
                    showPaymentSummary: params?.showPaymentSummary ? 'true' : undefined,
                },
            },
            { encode: false }
        ),
    [Path.requestMyList]: (
        filter: RequestListFilter,
        params?: {
            attachmentId?: string;
            requestId?: string;
            decision?: string;
            requestVersion?: string;
            showStartTrialPopupForCompanyId?: string;
            startTrialPopupOrigin?: StartTrialPopupOrigin;
        }
    ) =>
        stringifyUrl(
            {
                url: `requests/${filter}`,
                query: {
                    attachmentId: params?.attachmentId || undefined,
                    requestId: params?.requestId || undefined,
                    decision: params?.decision || undefined,
                    requestVersion: params?.requestVersion || undefined,
                    showStartTrialPopupForCompanyId: params?.showStartTrialPopupForCompanyId || undefined,
                    startTrialPopupOrigin: params?.startTrialPopupOrigin || undefined,
                },
            },
            { encode: false }
        ),
    [Path.requestMyListWithCompany]: (
        filter: RequestListFilter,
        companyId: string,
        params?: {
            attachmentId?: string;
            requestId?: string;
            decision?: string;
            requestVersion?: string;
        }
    ) =>
        stringifyUrl(
            {
                url: `requests/${filter}/${companyId}`,
                query: {
                    attachmentId: params?.attachmentId || undefined,
                    requestId: params?.requestId || undefined,
                    decision: params?.decision || undefined,
                    requestVersion: params?.requestVersion || undefined,
                },
            },
            { encode: false }
        ),
    [Path.requestCompanyList]: (
        companyId: string,
        filter: CompanyRequestListFilter,
        params?: {
            attachmentId?: string;
            auditReport?: boolean;
            requestId?: string;
            decision?: string;
            requestVersion?: string;
            /**
             * Flag to show payment summary popup
             * where PayWithAmax button is available
             * (for example XeroAmaxPayBatchPayment requests)
             */
            showPaymentSummary?: boolean;
            gettingStarted?: boolean;
        }
    ) =>
        stringifyUrl(
            {
                url: `${companyId}/requests/${filter}`,
                query: {
                    attachmentId: params?.attachmentId || undefined,
                    auditReport: params?.auditReport ? 'true' : undefined,
                    requestId: params?.requestId || undefined,
                    decision: params?.decision || undefined,
                    requestVersion: params?.requestVersion || undefined,
                    showPaymentSummary: params?.showPaymentSummary ? 'true' : undefined,
                    gettingStarted: params?.gettingStarted ? 'true' : undefined,
                },
            },
            { encode: false }
        ),
    [Path.requestTemplateList]: (
        companyId: string,
        workflowId: string,
        status: TemplateRequestListFilter,
        params?: {
            attachmentId?: string;
            auditReport?: boolean;
            requestId?: string;
            decision?: string;
            requestVersion?: string;
            /**
             * Flag to show payment summary popup
             * where PayWithAmax button is available
             * (for example XeroAmaxPayBatchPayment requests)
             */
            showPaymentSummary?: boolean;
        }
    ) =>
        stringifyUrl(
            {
                url: `${companyId}/requests/${workflowId}/${status}`,
                query: {
                    attachmentId: params?.attachmentId || undefined,
                    auditReport: params?.auditReport ? 'true' : undefined,
                    requestId: params?.requestId || undefined,
                    decision: params?.decision || undefined,
                    requestVersion: params?.requestVersion || undefined,
                    showPaymentSummary: params?.showPaymentSummary ? 'true' : undefined,
                },
            },
            { encode: false }
        ),
    [Path.editRequest]: (
        requestId: string,
        companyId: string,
        params?: {
            activeAttachmentId?: string;
            /**
             * Flag to show select bank account popup on edit request page
             */
            showBankAccountsPopup?: boolean;
        }
    ) =>
        stringifyUrl(
            {
                url: `${companyId}/requests/${requestId}/edit`,
                query: {
                    activeAttachmentId: params?.activeAttachmentId,
                    showBankAccountsPopup: params?.showBankAccountsPopup ? 'true' : undefined,
                },
            },
            { encode: false }
        ),
    [Path.newRequest]: (workflowId: string, companyId: string) =>
        `${companyId}/requests/create?workflowId=${workflowId}`,

    [Path.workflow]: (companyId: string, templateId: string) => `${companyId}/workflows/${templateId}`,
    [Path.newWorkflow]: (companyId: string) => `${companyId}/workflows/new`,
    [Path.companyWorkflows]: (
        companyId: string,
        options?: {
            gettingStarted?: WorkflowsGettingStarted;
        }
    ) =>
        stringifyUrl({
            url: `${companyId}/workflows`,
            query: {
                gettingStarted: options?.gettingStarted,
            },
        }),
    [Path.noCompanies]: () => `noCompanies`,

    [Path.reportsDashboard]: (companyId: string, type: domain.ReportType) => {
        if (type === domain.ReportType.Request) {
            return `${companyId}/reports/request`;
        }

        return `${companyId}/reports/lineItem`;
    },

    [Path.report]: (reportId: string, companyId: string, reportType: domain.ReportType) => {
        if (reportType === domain.ReportType.Request) {
            return `${companyId}/reports/request/${reportId}`;
        }

        return `${companyId}/reports/lineItem/${reportId}`;
    },

    [Path.companyInfo]: (
        companyId: string,
        params?: { state?: string; code?: string; matchingMigrationPopup?: string }
    ) =>
        stringifyUrl(
            {
                url: `${companyId}/companyInfo`,
                query: {
                    state: params?.state || undefined,
                    code: params?.code || undefined,
                    matchingMigrationPopup: params?.matchingMigrationPopup || undefined,
                },
            },
            { encode: false }
        ),
    [Path.companyUsers]: (
        companyId: string,
        options?: {
            addAsAdmin?: boolean;
            addWithInvite?: boolean;
            openAddPopup?: boolean;
        }
    ) =>
        stringifyUrl({
            url: `${companyId}/users`,
            query: {
                addAsAdmin: options?.addAsAdmin ? 'true' : undefined,
                addWithInvite: options?.addWithInvite ? 'true' : undefined,
                openAddPopup: options?.openAddPopup ? 'true' : undefined,
            },
        }),

    [Path.companyBudgeting]: (companyId: string) => `budgeting?companyId=${companyId}`,

    [Path.companyBudgetingV2]: (companyId: string) => `${companyId}/budgets`,

    [Path.oauth2Redirect]: () => `oauth2/redirect`,
    [Path.signInWithStartFinalizeQBooks]: () => `oauth2/startFinalizeQbo`,
    [Path.signUpWithFinalize]: (oauthProvider: domain.OAuthProvider) =>
        `signUpWith/finalize?oauthProvider=${oauthProvider}`,
    [Path.signInWithStart]: (oauthProvider: domain.OAuthProvider) =>
        `${Path.signInWithStart}?oauthProvider=${oauthProvider}`,
    [Path.signInWithDisconnect]: (oauthProvider: domain.OAuthProvider) =>
        `${Path.signInWithDisconnect}?oauthProvider=${oauthProvider}`,
    [Path.xeroSignIn]: (tenantId: string) => `${Path.xeroSignIn}?tenantId=${tenantId}`,
    [Path.customerFeedback]: () => `customerFeedback`,
    [Path.customerFeedbackBoard]: (boardId: string) => `customerFeedback/${boardId}`,
    [Path.customerDecision]: () => `customerDecision`,
    [Path.profileSetupSsoOld]: () => 'profileSetup/sso',
    [Path.profileSetupEmailOld]: () => 'profileSetup/email',
    [Path.airwallexAuth]: () => 'awx_oauth_redir',

    [Path.customFields]: (companyId: string) => `${companyId}/customFields`,

    [Path.slackAuthConnect]: (params?: { state?: string; code?: string }) =>
        stringifyUrl(
            {
                url: '/slack/auth/connect-to-am',
                query: {
                    state: params?.state || undefined,
                    code: params?.code || undefined,
                },
            },
            { encode: false }
        ),
    [Path.slackAuthConnectUser]: (params?: { state?: string; code?: string }) =>
        stringifyUrl(
            {
                url: '/slack/auth/connect-user-to-am',
                query: {
                    state: params?.state || undefined,
                    code: params?.code || undefined,
                },
            },
            { encode: false }
        ),
    [Path.slackAuthInstall]: (params?: { state?: string; code?: string }) =>
        stringifyUrl(
            {
                url: '/slack/auth/install-to-ws',
                query: {
                    state: params?.state || undefined,
                    code: params?.code || undefined,
                },
            },
            { encode: false }
        ),

    [Path.twoFaHardEnforcement]: () => Path.twoFaHardEnforcement,
    [Path.bankAccounts]: (companyId: string) => `${companyId}/${Path.bankAccounts}`,
    [Path.connectBankAccountSuccess]: () => Path.connectBankAccountSuccess,
    [Path.connectBankAccountFail]: () => Path.connectBankAccountFail,
    [Path.reconnectBankAccountSuccess]: () => Path.reconnectBankAccountSuccess,
    [Path.contactPaymentDetails]: (companyId: string) => `${companyId}/${Path.contactPaymentDetails}`,
    [Path.verifyEmail]: () => `/${Path.verifyEmail}`,
    [Path.amaxPaymentSuccess]: (callbackUrl: string) =>
        stringifyUrl({ url: Path.amaxPaymentSuccess, query: { callbackUrl } }),
    [Path.amaxPaymentFail]: (callbackUrl: string) =>
        stringifyUrl({ url: Path.amaxPaymentFail, query: { callbackUrl } }),
    [Path.receiptBankAuth]: () => Path.receiptBankAuth,
    [Path.completeSetup]: () => Path.completeSetup,
    [Path.profileSetupSso]: () => Path.profileSetupSso,
    [Path.profileSetupEmail]: () => Path.profileSetupEmail,
    [Path.wallets]: (companyId: string) => `${companyId}/${Path.wallets}`,
    [Path.walletsKyc]: (companyId: string) => `${companyId}/${Path.walletsKyc}`,
    [Path.walletsKycWizard]: (companyId: string, step: string) => `${companyId}/${Path.walletsKycWizard}/${step}`,
    [Path.walletsKycApproved]: (companyId: string) => `${companyId}/${Path.walletsKycApproved}`,
    [Path.walletsKycIncomplete]: (companyId: string) => `${companyId}/${Path.walletsKycIncomplete}`,
    [Path.walletsKycRejected]: (companyId: string) => `${companyId}/${Path.walletsKycRejected}`,
    [Path.walletsKycSubmitted]: (companyId: string) => `${companyId}/${Path.walletsKycSubmitted}`,
    [Path.walletsKycDetails]: () => Path.walletsKycDetails,
    [Path.walletsKycDetailsSubmitted]: () => Path.walletsKycDetailsSubmitted,
    [Path.gettingStarted]: (companyId: string) => `${companyId}/${Path.gettingStarted}`,
    [Path.loginWithPasskey]: () => Path.loginWithPasskey,
};

type PathsMap = typeof pathsMap;

export function getPath(route: Path, ...params: Parameters<PathsMap[Path]>) {
    const pathCb: (...args: any[]) => string = pathsMap[route];

    return '/' + pathCb(...params);
}

export const getDefaultPath = () => getPath(Path.requestMyList, RequestListFilter.MyDecisionRequired);

export function getUrl(route: Path, ...params: Parameters<PathsMap[Path]>) {
    const path = getPath(route, ...params);

    if (!path) {
        return WEBAPP_URL;
    }

    return `${WEBAPP_URL}${path}`;
}

export enum AccountPath {
    root = 'root',
    subscriptions = 'subscriptions',
    upgradeSubscription = 'upgradeSubscription',
    buyNow = 'buyNow',
    practiceStaff = 'practiceStaff',
    addToSubscription = 'addToSubscription',
    publicApiConnectionsList = 'publicApiConnectionsList',
}

type Origins =
    | 'wa_standalone_upgrade'
    | 'wa_pricing_link_upgrade'
    | 'wa_pricing_link_buy'
    | 'wa_select_existing_subscription'
    | 'wa_select_new_subscription'
    | 'wa_standalone_upgrade_card'
    | 'wa_expiration_action'
    | 'wa_subscription_promotion'
    | 'wa_company_alerts'
    | 'wa_integration_connection'
    | 'wa_license_on_grace_popup'
    | 'wa_grace_subscriptions'
    | 'wa_upgrade_expired_plan'
    | 'wa_integration_empty_card'
    | 'wa_integration_empty_mini_card'
    | 'wa_menu_items'
    | 'wa_aft_paywall'
    | 'wa_aft_expiration_popup'
    | 'wa_trial_expiration_toast'
    | 'wa_small_trial_exp_popup'
    | 'wa_big_trial_exp_popup'
    | 'starterguide'
    | 'billing: land on all feature trial paywall';

type UpgradeSubscriptionOptions = {
    subscriptionId?: string | null;
    companyId?: string | null;
    plan?: string | null;
    quantity?: number | null;
    action?: string | null;
    origin: Origins;
};

type BuySubscriptionOptions = {
    companyId?: string | null;
    plan?: string;
    action?: string | null;
    origin: Origins;
};

const accountPathsMap = {
    [AccountPath.root]: ({ origin }: { origin?: Origins } = {}) => (origin ? `?origin=${origin}` : ''),
    [AccountPath.subscriptions]: ({ origin }: { origin?: Origins } = {}) => {
        let url = `account?view=subscriptions`;

        if (origin) {
            url += `&origin=${origin}`;
        }

        return url;
    },
    [AccountPath.upgradeSubscription]: (
        options: UpgradeSubscriptionOptions & {
            subscriptionId: string;
        }
    ) => {
        return buySubscriptionMap(options);
    },
    [AccountPath.buyNow]: ({ companyId, plan, action, origin }: BuySubscriptionOptions) => {
        return buySubscriptionMap({ subscriptionId: null, companyId, plan, quantity: null, action, origin });
    },
    [AccountPath.practiceStaff]: () => 'practiceStaff',
    [AccountPath.addToSubscription]: ({ companyId, origin }: { companyId: string; origin: Origins }) => {
        let url = `account?addToSubscription=true&companyId=${companyId}`;

        if (origin) {
            url += `&origin=${origin}`;
        }

        return url;
    },
    [AccountPath.publicApiConnectionsList]: () => 'connections-list',
};

function buySubscriptionMap(options: UpgradeSubscriptionOptions) {
    const params = new URLSearchParams();

    let url = `account/subscription/buy`;

    if (options.subscriptionId) {
        params.set('subscriptionId', options.subscriptionId);
    }

    if (options.companyId) {
        params.set('companyId', options.companyId);
    }

    if (options.plan) {
        params.set('planPrivilege', options.plan);
    }

    if (options.quantity) {
        params.set('quantity', options.quantity.toString());
    }

    if (options.action) {
        params.set('actionPurchasePage', options.action);
    }

    if (options.origin) {
        params.set('origin', options.origin);
    }

    if (params.toString()) {
        url += `?${params.toString()}`;
    }

    return url;
}

type AccountPathsMap = typeof accountPathsMap;

export const getAccountPath = (route: AccountPath, ...params: Parameters<AccountPathsMap[AccountPath]>) => {
    const pathCb: (...args: any[]) => string = accountPathsMap[route];

    return pathCb(...params);
};

export const getAccountUrl = (route: AccountPath, ...params: Parameters<AccountPathsMap[AccountPath]>) => {
    const path = getAccountPath(route, ...params);

    if (!path) {
        return MY_ACCOUNT_URL;
    }

    return `${MY_ACCOUNT_URL}/${path}`;
};

export const getDeveloperPortalUrl = () => {
    return DEVELOPER_PORTAL_URL;
};

export const getPartnerUrl = () => {
    return PARTNER_URL;
};

export const getWebsiteUrl = (stay = true) => {
    return stay ? `${WEBSITE_URL}?stay` : WEBSITE_URL;
};

export const getSupportSiteUrl = () => {
    return SUPPORT_SITE_URL;
};
