import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('modules.IntegrationModule.NetSuiteIntegrationPopup', {
    popupTitle: 'Oracle NetSuite account connection',
    connectButton: 'Connect',
    connectionError: 'Failed to connect to Oracle NetSuite',
    connectionTitle: 'ORACLE NETSUITE CONNECTION',
    saveTimeZoneError: 'Failed to save company time zone',
    accountIdFieldTitle: 'Oracle NetSuite Account ID',
    accountIdFieldTooltipText: 'Copy the Account ID from Setup > Company > Company Information',
    accountIdWarning: '{fieldName} should include only capital latin letters, numbers and underscore',
    consumerKeyFieldTitle: 'Consumer Key',
    consumerKeyFieldTooltipText: `
        Create integration in Setup > Integration > Manage Integrations > New.
        Copy the Consumer Key and save it separately because this value won't be shown again.
    `,
    consumerSecretFieldTitle: 'Consumer Secret',
    consumerSecretFieldTooltipText: `
        Create integration in Setup > Integration > Manage Integrations > New.
        Copy the Consumer Secret and save it separately because this value won't be shown again.
    `,
    tokenIdFieldTitle: 'Token ID',
    tokenIdFieldTooltipText: `
        Create new token in Setup > Users/Roles > Access Tokens > New.
        Copy the Token Id and save it separately because this value won't be shown again.
    `,
    tokenSecretFieldTitle: 'Token Secret',
    tokenSecretFieldTooltipText: `
        Copy Token Secret from Setup > Users/Roles > Access Tokens > New.
        Copy the Token Secret and save it separately because this value won't be shown again.
    `,
    subsidiaryIdFieldTitle: 'Subsidiary ID',
    subsidiaryIdFieldTooltipText: `
        Copy the Internal Id of the subsidiary you are connecting to from Setup > Company > Subsidiaries
    `,
    subsidiaryIdWarning: '{fieldName} should be a single int number',
    timeZoneFieldTitle: 'Time zone',
    timeZoneFieldTooltipText: `
        Copy the Timezone of the NetSuite user who generated access token from Home > Set Preferences
    `,
    timeZonePlaceholder: 'Time zone',
    companyCreationError: 'Failed to create company',
    suiteAppConnection: 'SuiteApp connection',
    learnMore: 'Learn more',
    alphaNumericWarning: '{fieldName} should include only latin letters and numbers',
    requiredWarning: '{fieldName} is required',
    imageAltText: '{fieldName} setup instructions',
    isSuiteAppWarning: `
        Activate this option only if you have installed ApprovalMax SuiteApp into your NetSuite account.
        This allows you to skip entering Consumer Key and Consumer secret keys.
    `,
});
