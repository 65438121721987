import { memo, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { initializeModule } from '../../actions';
import AddCommentPopup from '../AddCommentPopup/AddCommentPopup';
import CopyWorkflowPopup from '../CopyWorkflowPopup/CopyWorkflowPopup';
import { FieldSettingsPopup } from '../FieldSettingsPopup/FieldSettingsPopup';
import { GroupOfValuesPopup } from '../GroupOfValuesPopup/GroupOfValuesPopup';
import HappyWorkflowActivationPopup from '../HappyWorkflowActivationPopup/HappyWorkflowActivationPopup';
import InviteUsersPopup from '../InviteUsersPopup/InviteUsersPopup';
import MatrixPopup from '../MatrixPopup/MatrixPopup';
import { StartOverPopup } from '../StartOverPopup/StartOverPopup';
import VersionConflictPopup from '../VersionConflictPopup/VersionConflictPopup';
import { WorkflowSettingsPopup } from '../WorkflowSettingsPopup/WorkflowSettingsPopup';

const Popups = memo(() => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(initializeModule());
    }, [dispatch]);

    return (
        <>
            <MatrixPopup />

            <StartOverPopup />

            <InviteUsersPopup />

            <WorkflowSettingsPopup />

            <HappyWorkflowActivationPopup />

            <CopyWorkflowPopup />

            <AddCommentPopup />

            <VersionConflictPopup />

            <FieldSettingsPopup />

            <GroupOfValuesPopup />
        </>
    );
});

export default Popups;
