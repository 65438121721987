import { domain } from 'modules/data';

import { CompanyInfo } from './isOcrEnabledForTemplate.types';

export const isOcrEnabledForTemplate = (
    companyInfo: CompanyInfo,
    templateIntegrationCode?: domain.IntegrationCode | null
) => {
    if (
        templateIntegrationCode === domain.IntegrationCode.NetSuiteBill &&
        companyInfo.betaFeatures.includes(domain.CompanyBetaFeature.CaptureNetSuiteBill)
    ) {
        return true;
    }

    if (
        templateIntegrationCode === domain.IntegrationCode.XeroBill &&
        (companyInfo.betaFeatures.includes(domain.CompanyBetaFeature.XeroOcr) ||
            companyInfo.licenseFeatures.includes(domain.CompanyLicenseFeature.Capture))
    ) {
        return true;
    }

    if (
        templateIntegrationCode === domain.IntegrationCode.XeroInvoice &&
        (companyInfo.licenseFeatures.includes(domain.CompanyLicenseFeature.Capture) ||
            companyInfo.betaFeatures.includes(domain.CompanyBetaFeature.CaptureXeroSalesInvoice))
    ) {
        return true;
    }

    if (
        templateIntegrationCode === domain.IntegrationCode.XeroPo &&
        (companyInfo.licenseFeatures.includes(domain.CompanyLicenseFeature.Capture) ||
            companyInfo.betaFeatures.includes(domain.CompanyBetaFeature.CaptureXeroPurchaseOrder))
    ) {
        return true;
    }

    if (
        templateIntegrationCode === domain.IntegrationCode.XeroCreditNotesPayable &&
        companyInfo.betaFeatures.includes(domain.CompanyBetaFeature.CaptureXeroAPCreditNote)
    ) {
        return true;
    }

    if (
        templateIntegrationCode === domain.IntegrationCode.QBooksBill &&
        (companyInfo.betaFeatures.includes(domain.CompanyBetaFeature.CaptureQBOBill) ||
            companyInfo.licenseFeatures.includes(domain.CompanyLicenseFeature.Capture))
    ) {
        return true;
    }

    return false;
};
