import './xeroPendingStatusToolbarSection.scss';

import { ExternalLink } from '@approvalmax/ui';
import { selectors } from 'modules/common';
import { getLockDateInEffect } from 'modules/common/selectors/requestSelectors.Xero';
import { domain } from 'modules/data';
import { NotSyncedIcon } from 'modules/sprites';
import moment from 'moment';
import { FC, memo, useCallback, useMemo } from 'react';
import bemFactory from 'react-bem-factory';

import { messages } from './XeroPendingStatusToolbarSection.messages';

const DEFAULT_URL_IN_LOCK_MSG = 'https://go.xero.com/Setup/FinancialSettings.aspx';
const bem = bemFactory.block('reql-xero-pending-status-toolbar-section');
const qa = bemFactory.qa('reql-xero-pending-status-toolbar-section');

interface XeroPendingStatusToolbarSectionProps {
    className?: string;
    request:
        | selectors.types.XeroBillExpandedRequest
        | selectors.types.XeroInvoiceExpandedRequest
        | selectors.types.XeroBillBatchPaymentExpandedRequest
        | selectors.types.XeroCreditNotesPayableExpandedRequest
        | selectors.types.XeroCreditNotesReceivableExpandedRequest
        | selectors.types.XeroManualJournalExpandedRequest
        | selectors.types.XeroQuoteExpandedRequest;
}

const XeroPendingStatusToolbarSection: FC<XeroPendingStatusToolbarSectionProps> = (props) => {
    const { className, request } = props;

    const getPendingStatusReason = useCallback(() => {
        const details = request.details;
        const lockDateInEffect = getLockDateInEffect(request.details);

        function formatIssues(issues: string[]) {
            if (isManager) {
                if (request.origin === domain.RequestOrigin.ApprovalMax && !details.url) {
                    return messages.requestAMBlockedValidationErrorManager({
                        br: <br />,
                        issues: issues.join(', '),
                        xeroLink: (
                            <ExternalLink href={DEFAULT_URL_IN_LOCK_MSG!}>
                                {request.company.integration!.displayName}
                            </ExternalLink>
                        ),
                    });
                } else {
                    return messages.requestBlockedValidationErrorManager({
                        issues: issues.join(', '),
                        br: <br />,
                        xeroLink: (
                            <ExternalLink href={details.editUrl!}>
                                {request.company.integration!.displayName}
                            </ExternalLink>
                        ),
                        xeroLinkToEditFinancialSettings: (
                            <ExternalLink href={DEFAULT_URL_IN_LOCK_MSG}>
                                {request.company.integration!.displayName}
                            </ExternalLink>
                        ),
                    });
                }
            } else {
                return messages.requestBlockedValidationErrorUser({
                    issues: issues.join(', '),
                    br: <br />,
                });
            }
        }

        const isManager = request.company.flags.isManager;
        const hasIssue =
            lockDateInEffect &&
            details.lockDatePolicy === domain.TemplateSettingsLockDatePolicy.LockApproval &&
            [
                domain.RequestStatusV2.Rejected,
                domain.RequestStatusV2.OnApproval,
                domain.RequestStatusV2.OnHold,
            ].includes(request.statusV2);

        if (hasIssue) {
            const issueText = messages.validationErrorTextLockDateInEffect({
                lockDate: moment(details.lockDate!).format('LL'),
            });

            return formatIssues([issueText]);
        }

        return null;
    }, [
        request.company.flags.isManager,
        request.company.integration,
        request.details,
        request.origin,
        request.statusV2,
    ]);

    const reason = useMemo(() => getPendingStatusReason(), [getPendingStatusReason]);

    if (!reason) {
        return null;
    }

    return (
        <div className={bem.add(className)()} data-qa={qa()}>
            <NotSyncedIcon className={bem('icon')} width={21} height={22} />

            <div className={bem('text')} data-qa={qa('text')}>
                {reason}
            </div>
        </div>
    );
};

export default memo(XeroPendingStatusToolbarSection);
