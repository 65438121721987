import { enabledByParams, RequestParams, RequestQueryOptions } from '@approvalmax/data';
import { useGet, useLazyGet } from 'shared/data';

import { netSuiteApiPaths } from '../paths';
import { GetNetSuiteShippingMethods } from './useGetNetSuiteShippingMethods.types';

export const useGetNetSuiteShippingMethods = (
    params?: RequestParams<GetNetSuiteShippingMethods>,
    queryOptions?: RequestQueryOptions<GetNetSuiteShippingMethods>
) => {
    return useGet(netSuiteApiPaths.shippingMethods, {
        params,
        queryOptions: {
            ...queryOptions,
            enabled: enabledByParams<GetNetSuiteShippingMethods['params']['query']>(
                {
                    customerId: params?.query?.customerId,
                },
                queryOptions
            ),
        },
        apiVersion: 'v2',
    });
};

export const useLazyGetNetSuiteShippingMethods = (queryOptions?: RequestQueryOptions<GetNetSuiteShippingMethods>) => {
    return useLazyGet<GetNetSuiteShippingMethods>(netSuiteApiPaths.shippingMethods, {
        queryOptions,
        apiVersion: 'v2',
    });
};
