import { DotsFilledIcon } from '@approvalmax/ui';
import { Button, Dropdown, Menu, Progress, toast } from '@approvalmax/ui/src/components';
import { FC, memo, useCallback } from 'react';
import { useToggle } from 'react-use';
import { ConfirmationDropdown } from 'shared/components';
import { useDeleteGroupOfXeroAccounts, useUpdateGroupOfXeroAccountsIsArchived } from 'shared/data/webApp/v2';

import { messages } from './ActionsMenu.messages';
import { ActionsMenuProps } from './ActionsMenu.types';

export const ActionsMenu: FC<ActionsMenuProps> = memo((props) => {
    const { group, companyId, onEdit, onViewHistory, isReadonly } = props;

    const { isArchived, id, name: groupName } = group;

    const { mutate: deleteGroupOfXeroAccounts, isLoading: isLoadingDeleteGroupOfXeroAccounts } =
        useDeleteGroupOfXeroAccounts();
    const { mutate: updateGroupOfXeroAccountsIsArchived, isLoading: isLoadingUpdateGroupOfXeroAccountsIsArchived } =
        useUpdateGroupOfXeroAccountsIsArchived();
    const [open, toggleOpen] = useToggle(false);

    const handleEdit = useCallback(() => {
        onEdit(group.id);
    }, [group, onEdit]);

    const handleViewHistory = useCallback(() => {
        onViewHistory(group.id);
    }, [group, onViewHistory]);

    const handleDelete = useCallback(() => {
        deleteGroupOfXeroAccounts(
            { params: { path: { groupOfXeroAccountsId: id, companyId } } },
            {
                onSuccess: () => {
                    toast.success(messages.deleteSuccess({ groupName }));
                    toggleOpen();
                },
            }
        );
    }, [deleteGroupOfXeroAccounts, id, companyId, groupName, toggleOpen]);

    const handleToggleIsArchived = useCallback(() => {
        updateGroupOfXeroAccountsIsArchived(
            {
                params: { path: { groupOfXeroAccountsId: id, companyId } },
                body: { isArchived: !isArchived },
            },
            {
                onSuccess: () => {
                    isArchived
                        ? toast.success(messages.activateSuccess({ groupName }))
                        : toast.success(messages.archiveSuccess({ groupName }));

                    toggleOpen();
                },
            }
        );
    }, [updateGroupOfXeroAccountsIsArchived, id, companyId, isArchived, groupName, toggleOpen]);

    if (isLoadingDeleteGroupOfXeroAccounts || isLoadingUpdateGroupOfXeroAccountsIsArchived) {
        return <Progress shape='circle' size='xxsmall' />;
    }

    return (
        <Dropdown
            open={open}
            onOpen={toggleOpen}
            activator={
                <Button noPadding icon variant='text' color='blue100'>
                    <DotsFilledIcon size={24} />
                </Button>
            }
            width='max-content'
        >
            <Menu>
                {!isArchived && !isReadonly && <Menu.Item name={messages.edit} onClick={handleEdit} />}

                <Menu.Item name={messages.viewHistory} onClick={handleViewHistory} />

                {!group.workflows?.length && !isArchived && !isReadonly && (
                    <Menu.Item name={messages.archive} onClick={handleToggleIsArchived} />
                )}

                {isArchived && !isReadonly && <Menu.Item name={messages.activate} onClick={handleToggleIsArchived} />}

                {!group.workflows?.length && !isReadonly && (
                    <ConfirmationDropdown title={messages.areYouSure} display='block' onConfirm={handleDelete}>
                        <Menu.Item name={messages.delete} />
                    </ConfirmationDropdown>
                )}
            </Menu>
        </Dropdown>
    );
});

ActionsMenu.displayName = 'ActionsMenu';
