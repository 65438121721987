import { Mods, mods, theme } from '@approvalmax/theme';
import { ChevronDownIcon } from '@approvalmax/ui/src/icons';
import { StyledMods } from '@styled-kit/mods';
import styled from 'styled-components';

export const Icon = styled(ChevronDownIcon)<StyledMods<Mods<'open' | 'hide'>>>`
    transition: ${theme.duration.medium}ms;
    cursor: pointer;

    ${mods.open.true`
        transform: rotate(180deg);
    `};

    ${mods.hide.true`
        visibility: hidden;
    `};
`;
