import { CloseIcon } from '@approvalmax/ui';
import { Box, Button, Flex, Radio, Text, toast } from '@approvalmax/ui/src/components';
import { domain } from 'modules/data';
import { FC, memo, useCallback } from 'react';

import SelectorDeadline from '../SelectorDeadline/SelectorDeadline';
import { useDropdownValue } from './DeadlinePanel.hooks';
import { messages } from './DeadlinePanel.messages';
import { DeadlinePanelProps } from './DeadlinePanel.types';

export const DeadlinePanel: FC<DeadlinePanelProps> = memo((props) => {
    const {
        value: defaultValue,
        type: defaultType,
        stepType = domain.StepType.Approval,
        onChange,
        onClose,
        readonly,
    } = props;

    const { type, value, onTypeChange, onValueChange, isError, setIsError } = useDropdownValue(
        defaultValue,
        defaultType || 'none'
    );

    const onConfirm = useCallback(() => {
        if (value || type === 'none') {
            onChange(value, type === 'none' ? null : type);

            onClose();
        } else {
            setIsError(true);

            toast.error(messages.errorEmpty);
        }
    }, [onChange, onClose, setIsError, type, value]);

    return (
        <Box spacing='16' width={238}>
            <Flex spacing='12' direction='column'>
                <Flex direction='column' spacing='12' wrap={false}>
                    <Flex wrap={false} justifyContent='space-between' alignItems='center'>
                        <Text font='headline' fontSize='xxsmall' fontWeight='medium' color='midnight80'>
                            {messages.title}
                        </Text>

                        <Button icon noPadding onClick={onClose} variant='text' color='blue100'>
                            <CloseIcon size={20} />
                        </Button>
                    </Flex>
                </Flex>

                <Radio.Group
                    value={type}
                    onChange={onTypeChange}
                    name='deadlineRuleType'
                    size='small'
                    block
                    spacing='8'
                    disabled={readonly}
                >
                    <Radio value='none'>{messages.optionNotSet}</Radio>

                    {stepType === domain.StepType.Approval && (
                        <>
                            <Radio value={domain.DeadlineRuleType.onSubmission}>
                                {messages.optionBasedOnSubmission}
                            </Radio>

                            <Radio value={domain.DeadlineRuleType.onApproval}>{messages.optionBasedOnApproval}</Radio>
                        </>
                    )}

                    {stepType === domain.StepType.Review && (
                        <Radio value={domain.DeadlineRuleType.onStartOfReview}>{messages.optionBasedOnReview}</Radio>
                    )}
                </Radio.Group>

                {type !== 'none' && (
                    <SelectorDeadline value={value} onChange={onValueChange} isError={isError} readonly={readonly} />
                )}

                <Button color='blue80' onClick={onConfirm} size='small' disabled={readonly}>
                    {messages.confirm}
                </Button>

                <Flex>
                    {stepType === domain.StepType.Approval && (
                        <>
                            <Text font='body' fontSize='small' color='midnight70'>
                                {messages.descriptionBasedOnSubmission}
                            </Text>

                            <Text font='body' fontSize='small' color='midnight70'>
                                {messages.descriptionBasedOnApproval}
                            </Text>
                        </>
                    )}

                    {stepType === domain.StepType.Review && (
                        <Text font='body' fontSize='small' color='midnight70'>
                            {messages.descriptionBasedOnReview}
                        </Text>
                    )}
                </Flex>
            </Flex>
        </Box>
    );
});

DeadlinePanel.displayName = 'DeadlinePanel';
