import { ExtractComponentProp } from '@approvalmax/types';
import { AddUserIcon, CloseCircleFilledIcon } from '@approvalmax/ui';
import { Box, Button, Flex, Select, Text } from '@approvalmax/ui/src/components';
import { FC, memo, useCallback, useRef } from 'react';
import { useHoverDirty, useToggle } from 'react-use';
import { useGetUserWithProfileInfo } from 'shared/hooks/useGetUserWithProfileInfo';

import { SelectUserMenuItem } from '../SelectUserMenuItem/SelectUserMenuItem';
import { SelectUserMenuItemProps } from '../SelectUserMenuItem/SelectUserMenuItem.types';
import { UserAvatar } from '../UserAvatar/UserAvatar';
import { UserCard } from '../UserCard/UserCard';
import { useValue } from './UserPicker.hooks';
import { messages } from './UserPicker.messages';
import { UserPickerProps } from './UserPicker.types';

export const UserPicker: FC<UserPickerProps> = memo((props) => {
    const { value, onChange, users, invalid = false } = props;

    const { selectedUser, handleSelectUser } = useValue({ value, onChange, users });

    const [openList, toggleOpenList] = useToggle(false);

    const ref = useRef(null);
    const isHovering = useHoverDirty(ref);

    const onUserItemClick = useCallback<ExtractComponentProp<typeof Select, 'onChange'>>(
        (id) => {
            const user = users.find((user) => user.id === id);

            if (user) {
                handleSelectUser(user);
                toggleOpenList();
            }
        },
        [users, handleSelectUser, toggleOpenList]
    );
    const handleUserRemove = useCallback(() => {
        handleSelectUser(null);
    }, [handleSelectUser]);

    const { getUserWithProfileInfo, loading } = useGetUserWithProfileInfo(selectedUser);

    return (
        <Box ref={ref}>
            {selectedUser ? (
                <Flex alignItems='center' spacing='8' wrap={false}>
                    <UserCard getUser={getUserWithProfileInfo} loading={loading}>
                        <Button noPadding noUppercase icon variant='text' color='blue100'>
                            <Flex alignItems='center' spacing='8' wrap={false}>
                                <UserAvatar size={40} user={selectedUser} />

                                <Flex grow={1} block>
                                    <Text fontWeight='regular' font='body' ellipsis={1}>
                                        {selectedUser.displayName}
                                    </Text>
                                </Flex>
                            </Flex>
                        </Button>
                    </UserCard>

                    {isHovering && (
                        <Flex grow={1} justifyContent='end'>
                            <Button onClick={handleUserRemove} icon noPadding variant='text' color='blue100'>
                                <CloseCircleFilledIcon size={12} />
                            </Button>
                        </Flex>
                    )}
                </Flex>
            ) : openList ? (
                <Select
                    size='small'
                    bordered={false}
                    clearable={false}
                    open={openList}
                    onOpen={toggleOpenList}
                    placeholder={messages.selectPlaceholderText}
                    autocomplete
                    onChange={onUserItemClick}
                    items={users}
                    itemNameKey='displayName'
                    customMenuItem={({ key, ...item }: SelectUserMenuItemProps & { key: string }) => (
                        <SelectUserMenuItem key={key} {...item} />
                    )}
                />
            ) : (
                <Button onClick={toggleOpenList} noPadding noUppercase variant='text' color='blue100'>
                    <Flex alignItems='center' spacing='8'>
                        <Box spacing='8'>
                            <AddUserIcon size={24} color={invalid ? 'red100' : 'blue100'} />
                        </Box>

                        <Text color={invalid ? 'red100' : 'blue100'} font='body'>
                            {messages.buttonText}
                        </Text>
                    </Flex>
                </Button>
            )}
        </Box>
    );
});

UserPicker.displayName = 'UserPicker';
