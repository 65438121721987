import { Reference } from '@approvalmax/types';
import capitalize from 'lodash/capitalize';
import lowerCase from 'lodash/lowerCase';
import { domain } from 'modules/data';
import { PropsWithChildren, useCallback, useMemo, useState } from 'react';
import { useGetFields } from 'shared/data/webApp/v1';

const halfAnHour = 30 * 60 * 1000;

const notSupportedFieldSystemPurposes = [
    domain.FieldSystemPurpose.QBooksCustom,
    domain.FieldSystemPurpose.QBooksInvoiceCustom,
    domain.FieldSystemPurpose.QBooksTerm,
    domain.FieldSystemPurpose.Amount,
    domain.FieldSystemPurpose.XeroManualExchangeRate,
    domain.FieldSystemPurpose.QBooksManualExchangeRate,
    domain.FieldSystemPurpose.Requester,
    domain.FieldSystemPurpose.General,
];

interface Props extends PropsWithChildren {
    field: domain.Field;
    staticValues?: Reference[];
    integrationCode?: domain.IntegrationCode | null;
    query?: string;
}

export const useFieldData = (props: Props) => {
    const { field, integrationCode = null, staticValues, query } = props;

    const [enabled, setEnabled] = useState(false);

    const { data, isFetching } = useGetFields(
        {
            query: {
                companyId: field.companyId,
                integrationCode,
                query,
                withValues: true,
                fieldsId: [field.id],
                startFrom: 0,
                rowNum: 116,
            },
        },
        {
            enabled: !notSupportedFieldSystemPurposes.includes(field.systemPurpose) && enabled,
            cacheTime: halfAnHour,
            staleTime: halfAnHour,
        }
    );

    const items = useMemo(() => {
        if (data) {
            const exactValues = data.fields[0]?.exactValues || [];

            const newItems = exactValues.map((exactValue) => {
                if (
                    field.systemPurpose === domain.FieldSystemPurpose.AirwallexPaymentPurpose ||
                    field.systemPurpose === domain.FieldSystemPurpose.AirwallexBatchPaymentStatus
                ) {
                    return {
                        id: exactValue.fieldValueId,
                        text: capitalize(lowerCase(exactValue.value)),
                    };
                }

                return {
                    id: exactValue.fieldValueId,
                    text: exactValue.value,
                };
            });

            return [...newItems, ...(staticValues || [])];
        }

        return [];
    }, [data, field.systemPurpose, staticValues]);

    const trigger = useCallback(() => {
        setEnabled(true);
    }, []);

    return {
        items,
        isFetching,
        trigger,
    };
};
