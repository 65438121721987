import { openSplitViewState } from '@approvalmax/ui/src/components';
import { useSetAtom } from 'jotai';
import { useCallback } from 'react';
import { ContentSplitViewMode, contentSplitViewState } from 'shared/states';

export const useNetSuiteExpenseLinesPreviewAttachment = () => {
    const setOpenSplitView = useSetAtom(openSplitViewState);
    const setContentSplitView = useSetAtom(contentSplitViewState);

    const onPreviewAttachment = useCallback(
        (attachmentId: string) => {
            setOpenSplitView(true);
            setContentSplitView({
                mode: ContentSplitViewMode.AttachmentsToExpenseLineItem,
                attachmentId,
            });
        },
        [setContentSplitView, setOpenSplitView]
    );

    return { onPreviewAttachment };
};
