import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('pages.RequestList.selectors.requestActivitySelectors', {
    unknownStepName: 'Unknown Step',
    headerRequesterSubmitted: 'Submitted request for approval.',
    headerApproverApproved: 'Approved request in {step} step.',
    headerApproverApprovedStepApproved: 'Approved request in {step} step.',
    headerApproverApprovedRequestApproved: 'Approved request in {step} step.',
    headerApproverRejected: 'Rejected request in {step} step.',
    headerApproverRevoked: 'Revoked their vote from {step} step.',
    headerApproverRevokedStepChanged: 'Revoked their vote. Request returned back to {nextStep}.',
    headerRequesterClosed: 'Closed request with reject resolution.',
    headerRequesterCancelled: 'Cancelled request.',
    headerRequesterReopened: 'Reopened request with following changes:',
    headerRequesterReopenedWithResetNoChanges: 'Reopened request.',
    headerRequesterReopenedWithReset: 'Reopened request with following changes:',
    headerRequesterReopenedStepChangedNoChanges: 'Reopened request.',
    headerRequesterReopenedStepChanged: 'Requester reopened request with following changes:',
    headerRequesterReopenedRequestApprovedNoChanges: 'Reopened request.',
    headerRequesterReopenedRequestApproved: 'Requester reopened request with following changes:',
    headerRequesterUpdated: 'Updated request with following changes:',
    headerRequesterUpdatedWithResetNoChanges: 'Updated request.',
    headerRequesterUpdatedWithReset: 'Updated request with following changes:',
    headerRequesterUpdatedStepChangedNoChanges: 'Updated request.',
    headerRequesterUpdatedStepChanged: 'Updated request with following changes:',
    headerRequesterUpdatedRequestApprovedNoChanges: 'Updated request.',
    headerRequesterUpdatedRequestApproved: 'Updated request with following changes:',
    headerStepSkipped: 'Step {step} has been skipped since there were no approvers.',
    headerForcedApproval: 'Forced approval of this request',
    headerForcedReject: 'Forced reject of this request',
    headerRequesterResetTemplate: 'Started over approval workflow for this request',
    headerEditorEdited: 'Modified request in {step} step.',
    headerEditorEditedStepApproved: 'Modified and approved request in {step} step.',
    headerEditorEditedRequestApproved: 'Modified and approved request in {step} step.',
    headerPulledFromSourceRB: 'Created in Dext Prepare',
    headerPulledFromSource: 'Pulled request from {external}.',
    headerPushedToSource: 'Push decision for {templateName} to {external}.',
    headerDocumentClosed: 'Marked the Purchase Order as "Closed"',
    headerDocumentOpened: 'Marked the Purchase Order as "Open"',
    headerDocumentClosedStatusSynced: 'The “Closed” status has been synced to QuickBooks Online.',
    headerDocumentOpenedStatusSynced: 'The “Open” status has been synced to QuickBooks Online.',
    headerAirwallexBatchPaymentRequestPaid: 'Sent the request to payment.',
    headerAirwallexRequestScheduledForPayment: 'Scheduled a payment for the request.',
    headerAirwallexScheduledPaymentCancelled: 'Canceled the payment schedule for the request.',
    headerAirwallexScheduledPaymentFailedDueToPaymentServiceIntegrationDisconnected:
        'Failed to send scheduled payment due to disconnection with Airwallex.',
    headerAirwallexScheduledPaymentFailedDueToExceededAmountDue:
        'Failed to send scheduled payment because the amount due for some bills is less than the specified payment amount.',
    headerAirwallexScheduledPaymentFailedDueToBeneficiaryDiscrepancy:
        'Failed to send scheduled payment because the beneficiary bank details have been updated in Airwallex.',
    headerAirwallexScheduledPaymentFailedDueToInsufficientFunds:
        'Failed to send scheduled payment due to lack of available funds into Airwallex.',
    headerAirwallexScheduledPaymentFailedDueToCompanyIntegrationDisabled:
        'Failed to send scheduled payment due to disconnection with Xero.',
    headerMarkedAsSentSynced: 'The Quote status has been changed to Sent.',
    headerAttachmentsAdded: 'New files have been attached via Public API.',
    headerAttachmentDeleted: 'A file has been deleted via Public API.',
    headerReviewersChanged: 'Changed the Reviewers.',
    headerReviewForcedByAdmin: 'Forced the review of the request.',
    headerReviewCompleted: 'Completed the review and submitted the request for approval.',
    headerReturnedToReview: 'Returned the request to Review.',
    headerReviewerEdited: 'Edited the request in Review step.',
    headerApproverEdited: 'Edited the request in {step}.',
    headerRequestPutOnHold: 'Has put request on hold with the following comment:',
    headerRequestTakenOffHold: 'Has returned request to approval with the following comment:',
    headerRequestApproverNudgedReviewStep: 'All reviewers are mentioned',
    headerRequestApproverNudgedApprovalStep: 'Approvers of the current step who have not yet voted were nudged.',

    changeNewName: 'Changed name',
    changeChangedDescription: 'Changed description',
    changeRemovedDescription: 'Removed description',
    changeChangedAmount: 'Changed Amount: {amount}',
    changeChangedField: 'Changed {name}: {value}',
    changeAddedAttachments: 'Added documents: {attachments}',
    changeRemovedAttachments: 'Removed documents: {attachments}',
    stepChangeAddedApprover: 'Added {approver} as approver to the "{stepName}" step',
    stepChangeRemovedApprover: 'Removed {approver} from the "{stepName}" step approvers',
    stepChangeChangedDeadline: 'Set {deadline} deadline for {stepName}',
    stepChangeRemovedDeadline: 'Removed deadline for {stepName}',
    stepChangeAddedReviewer: 'Added {reviewer} as Reviewer.',
    stepChangeRemovedReviewer: 'Removed {reviewer} from Reviewers.',

    footerApproverApprovedStepApproved: 'All approvals for the step are now collected, next step - {nextStep}.',
    footerApproverApprovedRequestApprovedAirwallex:
        'All approvals for the request have now been collected and the request is awaiting payment.',
    footerApproverApprovedRequestApproved: 'All approvals for the request are now collected and request is closed.',
    footerApproverRevokedStepChanged: 'Request returned back to {nextStep} step.',
    footerRequesterReopenedWithReset: 'Request is now in {nextStep} step.',
    footerRequesterReopenedStepChanged: 'Because approvers were changed, request moved to {nextStep} step.',
    footerRequesterReopenedRequestApproved:
        'Because some approvers were removed, all approval for the request are now collected and request is closed.',
    footerRequesterUpdatedWithReset: 'Request is now in {nextStep} step.',
    footerRequesterUpdatedStepChanged: 'Because some approvers were removed, request moved to {nextStep} step.',
    footerRequesterUpdatedRequestApproved:
        'Because some approvers were removed, all approval for the request are now collected and request is closed.',
    footerStepSkipped: 'Request is now in {nextStep} step.',
    footerStepSkippedRequestApproved:
        'This request has been approved automatically according to the approval workflow.',
    footerParticipantsWereChangedRequestApproved:
        'All approvals for the request are now collected and request is closed.',
    footerEditorEditedStepApproved: 'All approvals for the step are now collected, next step - {nextStep}.',
    footerEditorEditedRequestApproved: 'All approvals for the request are now collected and request is closed.',
    footerStepSkippedRequestRejected:
        'This request has been auto-rejected by ApprovalMax because the request did not meet the conditions of the approval steps.',
    footerReviewSkipped:
        'Review step has been skipped by ApprovalMax because the request did not meet the conditions of the review step.',
});
