import { ColumnDefinition } from '@approvalmax/ui/src/components';
import { formatTextFieldValueToHumanReadable } from '@approvalmax/ui/src/components/DatePicker/DatePicker.helpers';
import { GetAmaxPayXeroBillTransaction } from 'shared/data/webApp/v2';

import { messages } from './TransactionDetailsPopupContent.messages';

export const tableColumns: ColumnDefinition<
    Omit<Required<GetAmaxPayXeroBillTransaction['response']>['history'][number], 'status'> & {
        id: string;
        status: string;
    }
>[] = [
    {
        name: messages.date,
        id: 'date',
        value: (item) => formatTextFieldValueToHumanReadable(item.date),
    },
    { name: messages.status, id: 'status', value: (item) => item.status },
    { name: messages.source, id: 'source', value: (item) => item.source },
];
