import { RequestInfiniteQueryOptions, RequestParams, RequestQueryOptions } from '@approvalmax/data';
import { useGet, useInfiniteGet } from 'shared/data/api';

import { integrationApiPaths } from '../paths';
import { GetXeroCounterparties } from './useGetXeroCounterparties.types';

export const useGetXeroCounterpartiess = (
    params: RequestParams<GetXeroCounterparties>,
    queryOptions?: RequestQueryOptions<GetXeroCounterparties>
) => {
    return useGet(integrationApiPaths.xeroCounterparties, {
        params,
        queryOptions,
        apiVersion: 'v1',
        method: 'get',
    });
};

export const useInfiniteGetXeroCounterparties = (
    params: RequestParams<GetXeroCounterparties>,
    queryOptions?: RequestInfiniteQueryOptions<GetXeroCounterparties<true>>
) => {
    return useInfiniteGet(integrationApiPaths.xeroCounterparties, {
        params,
        queryOptions: {
            getNextPageParam: (lastPage, pages) => {
                if (!lastPage.hasMore) {
                    return;
                }

                const allItemsCount = pages.reduce((count, page) => count + page.items.length, 0);

                return {
                    params: {
                        query: {
                            ...params.query,
                            offset: allItemsCount,
                        },
                        path: params.path,
                    },
                };
            },
            ...queryOptions,
        },
        apiVersion: 'v1',
        method: 'get',
    });
};
