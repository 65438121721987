import { FC, memo } from 'react';

import { Navigation } from '../Navigation/Navigation';
import { useGettingStartedLinkItems } from './GettingStartedMenu.hooks';
import { GettingStartedMenuProps } from './GettingStartedMenu.types';

export const GettingStartedMenu: FC<GettingStartedMenuProps> = memo((props) => {
    const { company, onClick } = props;

    const gettingStartedLinkItems = useGettingStartedLinkItems(company);

    return <Navigation items={gettingStartedLinkItems} onClick={onClick} />;
});

GettingStartedMenu.displayName = 'GettingStartedMenu';
