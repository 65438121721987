import { RequestType } from '@approvalmax/data';

export enum ConsentStatus {
    Authorized = 'Authorized',
    AwaitingAuthorization = 'AwaitingAuthorization',
    Expired = 'Expired',
    Rejected = 'Rejected',
    Revoked = 'Revoked',
}

export type Consent = {
    id: string;
    /**
     * A link to a bank web application
     */
    authorisationUrl: string;

    /**
     * A datetime string that represents a time then user scans a QR code and opens the link within
     */
    authorisationUrlOpenedAt?: string;

    createdAt: string;
    status: ConsentStatus;
};

export type GetAmaxPayConsent = RequestType<
    {
        params: {
            path: {
                companyId: string;
                consentId: string;
            };
        };
        response: {
            data: Consent;
        };
    },
    {
        apiSource: 'webApp';
        apiVersion: 'v1';
        method: 'get';
    }
>;
