import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages(
    'pages.RequestList.XeroMatchingV2Section.BillView.ChangeMatchingPopup.ChangeMatchingPopupBody.ChangeMatchingPopupItem.AdditionalInformation',
    {
        remaining: '{amount} remaining',
        extraAmount: '+ Extra {extraAmountText} are allowed',
        exceededAmount: 'PO balance is exceeded by {exceededAmount}',
        allocatedAmount: 'Allocated amount:',
        notAllocatedAmount: '(remaining amount: {amount} in this Bill)',
        insertRemainingAmount: 'INSERT {amount}',
        totalOfAllocatedBills: 'Total of allocated Bills:',
        matchedBills: 'Matched Bills',
    }
);
