import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('pages.RequestList.XeroReceiptBankPaymentDetailsSection', {
    sectionHeaderText: 'Payment details',
    warningText: `
        This bill was published as paid which means that this request would be created as “Paid” in Xero
        and a respective Bank Transaction would be created as well`,
    dateField: 'Date',
    accountField: 'Account',
    amountField: 'Amount',
});
