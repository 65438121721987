import { arrayHelpers, compareHelpers, hooks } from '@approvalmax/utils';
import { useCallback, useMemo, useState } from 'react';

import { ColumnDefinition, SortOrder } from '../Table/Table.types';
import { messages } from './Transfer.messages';
import { BaseItem, UseTransferPanelParams } from './Transfer.types';

export const useTransferPanel = <Item extends BaseItem>(params: UseTransferPanelParams<Item>) => {
    const { items, itemNameKey, itemsPerPage, panelTitle, panelDescription, columnName, onSearch, preventSearch } =
        params;

    const [search, setSearch] = useState('');
    const [sortOrder, setSortOrder] = useState<SortOrder>();
    const [checkedItems, setCheckedItems] = useState<string[]>([]);
    const [currentPageIndex, setCurrentPageIndex] = useState(0);

    const onColumnSort = useCallback((_columnId: string, newSortOrder: SortOrder) => {
        setSortOrder(newSortOrder);
    }, []);

    const columnsDefinition = useMemo<ColumnDefinition<Item>[]>(() => {
        return [
            {
                id: itemNameKey,
                name: columnName ?? messages.defaultName,
                sortable: true,
                sortOrder,
                spacing: '8 8 8 0',
            },
        ];
    }, [columnName, itemNameKey, sortOrder]);

    const onSearchChange = useCallback(
        (search: string) => {
            setSearch(search);

            onSearch?.(search);
            setCurrentPageIndex(0);
        },
        [onSearch]
    );

    const onPageChange = useCallback((pageIndex: number) => {
        setCurrentPageIndex(pageIndex);
    }, []);

    const filteredItems = hooks.useSearchWithFuse(items, preventSearch ? '' : search.trim(), { keys: [itemNameKey] });

    const { data, isLastPage, filteredItemsCount } = useMemo(() => {
        const filteredItemsCount = filteredItems.length;

        let sorted = filteredItems;

        if (sortOrder) {
            const comparator = compareHelpers.comparatorFor<Item>(
                sortOrder === SortOrder.asc
                    ? compareHelpers.stringComparator2Asc
                    : compareHelpers.stringComparator2Desc,
                itemNameKey
            );

            sorted = arrayHelpers.arraySort(filteredItems, comparator);
        }

        if (itemsPerPage) {
            const startIndex = currentPageIndex * itemsPerPage;
            const endIndex = startIndex + itemsPerPage;

            return {
                data: sorted.slice(startIndex, endIndex),
                isLastPage: endIndex >= sorted.length,
                filteredItemsCount,
            };
        }

        return {
            data: sorted,
            isLastPage: true,
            filteredItemsCount,
        };
    }, [filteredItems, sortOrder, itemsPerPage, itemNameKey, currentPageIndex]);

    const handleCheckedItemsMoved = useCallback(() => {
        setCheckedItems([]);

        const movedItemsCount = checkedItems.length;
        const newItemsCount = filteredItemsCount - movedItemsCount;

        let newMaxPageIndex = itemsPerPage ? Math.ceil(newItemsCount / itemsPerPage) - 1 : 0;

        if (newMaxPageIndex < 0) {
            newMaxPageIndex = 0;
        }

        if (newMaxPageIndex < currentPageIndex) {
            setCurrentPageIndex(newMaxPageIndex);
        }
    }, [checkedItems.length, currentPageIndex, filteredItemsCount, itemsPerPage]);

    const description = useMemo(
        () => panelDescription?.(checkedItems.length, items.length),
        [checkedItems.length, items.length, panelDescription]
    );

    return {
        search,
        onSearchChange,
        isLastPage,
        currentPageIndex,
        onPageChange,
        onColumnSort,
        columnsDefinition,
        data,
        checkedItems,
        onCheckedItemsChange: setCheckedItems,
        handleCheckedItemsMoved,
        title: panelTitle,
        description,
        withPagination: items.length > 0 && !!itemsPerPage,
        isEmpty: !data.length,
    };
};
