import { backend, domain, State } from 'modules/data';
import { Module } from 'modules/page';
import { ThunkAction } from 'modules/react-redux';

import { createCompany, fetchActionsForNewCompany, showOrganisationPostCreationWizard } from './actions';
import ModuleComponent from './ModuleComponent';
import entitiesReducer from './reducers/entitiesReducer';
import moduleReducer from './reducers/moduleReducer';

export * as actions from './actions';

export function loadOrganisationPostCreationWizard(options: { companyId: string }): ThunkAction {
    return (dispatch) => {
        dispatch(showOrganisationPostCreationWizard(options));
    };
}

export function loadFetchActionsForNewCompany(options: { companyId: string }): ThunkAction {
    return async (dispatch) => {
        await dispatch(fetchActionsForNewCompany(options.companyId));
    };
}

export function createOrganisation(
    integrationType: domain.IntegrationType | null
): ThunkAction<State, Promise<backend.CompanyCreatedInfo | null>> {
    return async (dispatch) => {
        let companyCreatedInfo: backend.CompanyCreatedInfo | null = null;

        await dispatch(
            createCompany(integrationType, (companyInfo) => {
                companyCreatedInfo = companyInfo;
            })
        );

        return companyCreatedInfo;
    };
}

export const moduleDef: Module = {
    id: 'company',
    moduleReducer: moduleReducer,
    moduleComponent: ModuleComponent,
    reducers: {
        entities: entitiesReducer,
    },
};
