import { Flex, Form, Link, Text, TextField } from '@approvalmax/ui/src/components';
import { constants } from 'modules/common';
import { memo } from 'react';
import bemFactory from 'react-bem-factory';

import { StepperTwoFaWizard } from '../StepperTwoFaWizard/StepperTwoFaWizard';
import { useStepCodeVerify } from './StepCodeVerify.hooks';
import { messages } from './StepCodeVerify.messages';

const qa = bemFactory.qa('pro-two-fa-setup-code-enter');

export const StepCodeVerify = memo(() => {
    const { form, handleSubmit } = useStepCodeVerify();

    return (
        <Form form={form}>
            <Flex direction='column' spacing='24' data-qa={qa()}>
                <StepperTwoFaWizard activeStep={1} />

                <Flex direction='column' spacing='16'>
                    <Text font='headline' fontSize='xsmall' fontWeight='medium'>
                        {messages.subtitle}
                    </Text>

                    <Text font='body' fontSize='medium' fontWeight='regular'>
                        {messages.description({
                            br: <br />,
                            supportlink: (chunks) => (
                                <Link
                                    font='body'
                                    fontSize='medium'
                                    fontWeight='regular'
                                    href={constants.xeroConstants.XERO_TWO_FA_SUPPORT_LINK}
                                >
                                    {chunks}
                                </Link>
                            ),
                        })}
                    </Text>
                </Flex>

                <TextField
                    name='setupVerificationCode'
                    required
                    width={240}
                    initFocus
                    onEnter={handleSubmit}
                    label={messages.authenticatorCodeLabel}
                    placeholder={messages.authenticatorCodePlaceholder}
                />
            </Flex>
        </Form>
    );
});

StepCodeVerify.displayName = 'StepCodeVerify';
