import { Reference } from '@approvalmax/types';
import { EditIcon } from '@approvalmax/ui';
import { Box, Button, Dropdown, Flex, Popup, Spacing, Text, toast } from '@approvalmax/ui/src/components';
import { useAtomValue } from 'jotai';
import { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useToggle } from 'react-use';
import { UpdateGroupOfXeroAccountsVersions, useUpdateGroupOfXeroAccountsVersions } from 'shared/data/webApp/v2';

import { useConfirmationPopup } from '../../GroupOfValuesPopup.hooks';
import { groupOfValuesSelectionState } from '../../GroupOfValuesPopup.states';
import { AddCommentPopupContent } from '../AddCommentPopupContent/AddCommentPopupContent';
import { EditGroupName } from '../EditGroupName/EditGroupName';
import { TransferAccount } from '../TransferAccount/TransferAccount';
import { VersionConflictPopupContent } from '../VersionConflictPopupContent/VersionConflictPopupContent';
import { getNoChanges } from './EditingPopupContent.helpers';
import { messages } from './EditingPopupContent.messages';
import { EditingPopupContentProps } from './EditingPopupContent.types';

export const EditingPopupContent: FC<EditingPopupContentProps> = memo((props) => {
    const { onClose, onSuccess, companyId, integrationCode, groupOfAccounts, setIsModified } = props;

    const [commentPopupOpen, toggleCommentPopupOpen] = useToggle(false);
    const [forceUpdateData, setForceUpdateData] = useState<UpdateGroupOfXeroAccountsVersions['body'] | null>(null);
    const [selectedItems, setSelectedItems] = useState<Reference[]>(
        groupOfAccounts.latestVersion?.values.map((value) => ({
            id: value.fieldValueId || '',
            text: value.value || '',
        })) || []
    );
    const { mutate, isLoading } = useUpdateGroupOfXeroAccountsVersions();
    const [editNameOpen, setEditNameOpen] = useState(false);
    const hasGroupOfValuesSelection = useAtomValue(groupOfValuesSelectionState);
    const { confirmation } = useConfirmationPopup();

    const noChanges = useMemo(() => getNoChanges(groupOfAccounts, selectedItems), [groupOfAccounts, selectedItems]);

    useEffect(() => {
        setIsModified(!noChanges);
    }, [noChanges, setIsModified]);

    const formSubmit = useCallback(() => {
        if (noChanges) {
            const { name } = groupOfAccounts;

            toast.success(messages.noChanges({ groupName: name }));
            onClose();
        } else {
            toggleCommentPopupOpen(true);
        }
    }, [groupOfAccounts, noChanges, onClose, toggleCommentPopupOpen]);

    const onSave = useCallback(() => {
        if (hasGroupOfValuesSelection) {
            void confirmation()
                .then(onClose)
                .catch(() => {});
        } else {
            formSubmit();
        }
    }, [confirmation, formSubmit, hasGroupOfValuesSelection, onClose]);

    const updateGroupOfAccounts = useCallback(
        (body: UpdateGroupOfXeroAccountsVersions['body']) => {
            const { id, name } = groupOfAccounts;

            mutate(
                { body, params: { path: { companyId: companyId, groupOfXeroAccountsId: id } } },
                {
                    onSuccess: () => {
                        if (groupOfAccounts.workflows.length === 0) {
                            toast.success(messages.successMessage({ groupName: name }));
                        }

                        setForceUpdateData(null);
                        onSuccess();
                    },
                    onError: (error, variables) => {
                        if (error.status === 409) {
                            setForceUpdateData(variables.body || null);
                        }
                    },
                }
            );
        },
        [groupOfAccounts, mutate, companyId, onSuccess]
    );

    const onSubmitComment = useCallback(
        (params: { comment: string }) => {
            const { comment } = params;
            const { latestVersion } = groupOfAccounts;

            toggleCommentPopupOpen(false);

            const preparedValues = selectedItems.map((selectedItem) => ({
                fieldValueId: selectedItem.id,
                value: selectedItem.text,
            }));

            updateGroupOfAccounts({
                comment,
                forceCreate: false,
                values: preparedValues,
                prevVersionNumber: latestVersion?.versionNumber,
            });
        },
        [groupOfAccounts, selectedItems, updateGroupOfAccounts, toggleCommentPopupOpen]
    );

    const handleClearValues = useCallback(() => {
        setSelectedItems([]);
    }, []);

    const closeOverwritePopup = useCallback(() => {
        setForceUpdateData(null);
    }, []);

    const onOverwrite = useCallback(() => {
        forceUpdateData && updateGroupOfAccounts({ ...forceUpdateData, forceCreate: true });
        setForceUpdateData(null);
    }, [forceUpdateData, updateGroupOfAccounts]);

    const onEditGroupNameSuccess = useCallback(() => {
        setEditNameOpen(false);
    }, []);

    return (
        <>
            <Popup.Header
                title={messages.popupTitle({ groupName: groupOfAccounts.name })}
                actions={
                    <Flex spacing='16' wrap={false}>
                        <Button
                            size='medium'
                            color='blue10'
                            disabled={selectedItems.length === 0 || isLoading}
                            onClick={handleClearValues}
                        >
                            {messages.clear}
                        </Button>

                        <Button size='medium' color='blue80' onClick={onSave} progress={isLoading}>
                            {messages.save}
                        </Button>
                    </Flex>
                }
            />

            <Popup.Body>
                <Box height={500} overflowY='auto'>
                    <Flex justifyContent='space-between'>
                        <Flex spacing='0' alignItems='center' wrap={false} maxWidth={400}>
                            <Text
                                color='midnight100'
                                font='label'
                                fontSize='medium'
                                fontWeight='medium'
                                spacing='0 12 0 0'
                                ellipsis={1}
                                title={groupOfAccounts.name}
                            >
                                {groupOfAccounts.name}
                            </Text>

                            <Dropdown
                                placement='bottom'
                                width='max-content'
                                onOpen={setEditNameOpen}
                                open={editNameOpen}
                                activator={
                                    <Button startIcon={<EditIcon />} color='blue10' size='xsmall'>
                                        {messages.edit}
                                    </Button>
                                }
                            >
                                <EditGroupName
                                    name={groupOfAccounts.name}
                                    companyId={companyId}
                                    groupOfXeroAccountsId={groupOfAccounts.id}
                                    onSuccess={onEditGroupNameSuccess}
                                />
                            </Dropdown>
                        </Flex>

                        <Flex spacing='8'>
                            <Text font='label' fontSize='medium' color='midnight70'>
                                {messages.fieldType}
                            </Text>

                            <Text font='label' fontSize='medium' color='midnight100'>
                                {messages.account}
                            </Text>
                        </Flex>
                    </Flex>

                    <Spacing height={24} />

                    <TransferAccount
                        companyId={companyId}
                        integrationCode={integrationCode}
                        groupOfAccounts={groupOfAccounts}
                        isLoading={isLoading}
                        onChange={setSelectedItems}
                        selectedItems={selectedItems}
                    />
                </Box>
            </Popup.Body>

            <Popup open={commentPopupOpen} preventAutoClose onToggle={toggleCommentPopupOpen}>
                <AddCommentPopupContent onSubmit={onSubmitComment} />
            </Popup>

            <Popup closable={false} open={!!forceUpdateData} size='small' preventAutoClose>
                <VersionConflictPopupContent onClose={closeOverwritePopup} onOverwrite={onOverwrite} />
            </Popup>
        </>
    );
});

EditingPopupContent.displayName = 'EditingPopupContent';
