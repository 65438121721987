import { actions } from 'modules/common';
import { State } from 'modules/data';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

/**
 * This is used for displaying My Profile toggler in a correct state right after popup opened.
 * Better to use Jotai or something the same to get rid of this code
 *
 * @see AM-14443
 */
export const useEnableProfileTwoFaSetting = () => {
    const dispatch = useDispatch();
    const profile = useSelector((state: State) => state.profile);

    return useCallback(() => {
        if (!profile?.email) {
            return;
        }

        dispatch(actions.updateProfile(profile.email, {}, { is2faEnabled: true }));
    }, [dispatch, profile?.email]);
};
