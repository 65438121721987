import { toast } from '@approvalmax/ui/src/components';
import { zodResolver } from '@hookform/resolvers/zod';
import { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useMount } from 'react-use';
import { useAddTwoFaEnablingAlternativeEmail, useFinishTwoFaEnablingWithEmailCode } from 'shared/data/webApp/v1';
import { z } from 'zod';

import { useTwoFaWizardController, useTwoFaWizardStep } from '../../TwoFaEnablingWizard.hooks';
import { WizardStep } from '../../TwoFaEnablingWizard.types';
import { messages } from './StepBackupEmailVerify.messages';
import { schema } from './StepBackupEmailVerify.schema';

export const useStepBackupEmail = () => {
    const { setWizardStep } = useTwoFaWizardStep();
    const { wizardController, setWizardController } = useTwoFaWizardController();

    const { stateId, email, onFinish } = wizardController;

    const form = useForm<z.infer<typeof schema>>({
        defaultValues: {
            emailVerificationCode: '',
        },
        resolver: zodResolver(schema),
    });

    const { mutate: requestCode, isLoading: isLoadingRequestCode } = useAddTwoFaEnablingAlternativeEmail({
        onSuccess: () => {
            toast.success(messages.notificationCodeResend);

            form.setValue('emailVerificationCode', '');

            setWizardStep(WizardStep.backupEmailVerify);
            // setWizardController((prev) => ({ ...prev, email: undefined }));
        },
    });

    const { mutate: finishTwoFaEnabling, isLoading: isLoadingFinishTwoFaEnabling } =
        useFinishTwoFaEnablingWithEmailCode({
            onSuccess: () => {
                onFinish?.('backupEmailAddress');
            },
            onError: () => {
                form.setError('emailVerificationCode', { message: messages.errorEmailVerificationCodeRequired });
            },
        });

    const handleSubmit = form.handleSubmit((data) => {
        const { emailVerificationCode } = data;

        if (stateId) {
            finishTwoFaEnabling({
                body: {
                    stateId,
                    emailCode: emailVerificationCode.trim() || '',
                },
            });
        }
    });

    const handleRequestCode = useCallback(() => {
        if (stateId && email) {
            requestCode({ body: { stateId, email } });
        }
    }, [email, requestCode, stateId]);

    const handleGoStepEmail = useCallback(() => {
        form.setValue('emailVerificationCode', '');

        setWizardStep(WizardStep.backupEmailEnter);
    }, [form, setWizardStep]);

    useMount(() => {
        setWizardController((prev) => ({
            ...prev,
            title: messages.title,
            onPrev: undefined,
            onNext: undefined,
        }));
    });

    const isDisabled = !form.watch('emailVerificationCode').trim();

    return {
        form,
        email,
        handleSubmit,
        handleGoStepEmail,
        handleRequestCode,
        isDisabled,
        isLoading: isLoadingRequestCode || isLoadingFinishTwoFaEnabling,
    };
};
