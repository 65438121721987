import { Checkbox } from '@approvalmax/ui/src/components';
import { domain } from 'modules/data';
import { FC, memo, useCallback } from 'react';

import { SettingsSection } from '../SettingsSection/SettingsSection';
import SettingsSubsection from '../SettingsSubsection/SettingsSubsection';
import { checkBoxQaPrefix, learnMoreLink } from './SectionNetSuiteFraudChangesAfter.constants';
import {
    getNetSuiteFraudChangesAfterFieldCheckBoxLabel,
    getNetSuiteFraudChangesAfterFields,
} from './SectionNetSuiteFraudChangesAfter.helpers';
import { messages } from './SectionNetSuiteFraudChangesAfter.messages';
import { SectionNetSuiteFraudChangesAfterProps } from './SectionNetSuiteFraudChangesAfter.types';

const SectionNetSuiteFraudChangesAfter: FC<SectionNetSuiteFraudChangesAfterProps> = memo((props) => {
    const { company, integrationCode, templateSettings, setTemplateSettings, readonly, qa } = props;

    const settings = templateSettings?.netSuitePostApprovalChangeDetectionSettings;

    const targetFields = getNetSuiteFraudChangesAfterFields(integrationCode);

    const handleChange = useCallback(
        (newTargets: domain.TemplateSettingsNetSuitePostApprovalChangeDetectionTarget[]) => {
            setTemplateSettings((prevTemplateSettings) => ({
                ...prevTemplateSettings,
                netSuitePostApprovalChangeDetectionSettings:
                    prevTemplateSettings.netSuitePostApprovalChangeDetectionSettings
                        ? {
                              ...prevTemplateSettings.netSuitePostApprovalChangeDetectionSettings,
                              targets: newTargets,
                          }
                        : undefined,
            }));
        },
        [setTemplateSettings]
    );

    if (!settings) {
        return null;
    }

    return (
        <SettingsSection title={messages.title} learnMoreLink={learnMoreLink}>
            <SettingsSubsection subtitle={messages.subtitle}>
                <Checkbox.Group
                    name='detectionChangesAfterApproval'
                    disabled={readonly}
                    onChange={handleChange}
                    value={settings.targets}
                    block
                >
                    {targetFields.map((targetField) => (
                        <Checkbox
                            key={targetField}
                            value={targetField}
                            data-qa={qa(`${checkBoxQaPrefix}-${targetField}`)}
                        >
                            {getNetSuiteFraudChangesAfterFieldCheckBoxLabel(
                                targetField,
                                company.defaultCurrencyText,
                                integrationCode
                            )}
                        </Checkbox>
                    ))}
                </Checkbox.Group>
            </SettingsSubsection>
        </SettingsSection>
    );
});

SectionNetSuiteFraudChangesAfter.displayName = 'SectionNetSuiteFraudChangesAfter';

export default SectionNetSuiteFraudChangesAfter;
