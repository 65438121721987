import { Box, Spacing, Table, Text } from '@approvalmax/ui/src/components';
import { errorHelpers } from '@approvalmax/utils';
import { selectors } from 'modules/common';
import { domain } from 'modules/data';
import { useSelector } from 'modules/react-redux';
import { memo, useMemo } from 'react';

import { getColumnDefinitions } from '../../QBooksMatchingPopup.config';
import { messages } from './SelectedLineInformation.messages';
import { SelectedLineInformationProps } from './SelectedLineInformation.types';

export const SelectedLineInformation = memo<SelectedLineInformationProps>((props) => {
    const { accountLineItem, lineItem, requestId } = props;

    const request = useSelector((state) =>
        selectors.request.getRequestById<
            selectors.types.QBooksBillExpandedRequest | selectors.types.QBooksPoExpandedRequest
        >(state, requestId)
    );

    if (request.integrationType !== domain.IntegrationType.QBooks) {
        throw errorHelpers.invalidOperationError();
    }

    const columnDefinitions = useMemo(
        () => getColumnDefinitions(request, Boolean(accountLineItem), true),
        [accountLineItem, request]
    );

    const item = accountLineItem || lineItem;

    if (!item) {
        return null;
    }

    return (
        <Box color='midnight20' spacing='16 24 0' radius='small'>
            <Text font='headline' fontSize='xxsmall' fontWeight='medium' color='midnight80'>
                {messages.title}
            </Text>

            <Spacing height={16} />

            <Table
                columns={columnDefinitions}
                data={[item]}
                hideBorderLastRow
                headerColor='transparent'
                hideEmptyColumns
                hoverable={false}
            />
        </Box>
    );
});

SelectedLineInformation.displayName = 'SelectedLineInformation';
