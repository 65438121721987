import { domHelpers } from '@approvalmax/utils';
import {
    ComponentProps,
    forwardRef,
    ForwardRefExoticComponent,
    memo,
    MemoExoticComponent,
    RefAttributes,
    useCallback,
    useMemo,
} from 'react';

import { Flex } from '../../../Flex/Flex';
import { Grid } from '../../../Grid/Grid';
import { Text } from '../../../Text/Text';
import Radio from '../../Radio';
import GroupController from '../GroupController/GroupController';
import { GroupContext } from './Group.context';
import { useCheckedValues } from './Group.hooks';
import { ChildrenComponents, GroupProps } from './Group.types';

/**
 * The `Radio.Group` component allows to use `Radio` components as a group.
 * Use the `Radio` component only with `Radio.Group`
 * You can also pass some properties from `Radio` to `Radio.Group` to apply to all radios.
 */
const Group = memo(
    forwardRef<HTMLInputElement, GroupProps>((props, ref) => {
        const {
            children,
            spacing,
            width,
            direction,
            hint,
            invalid,
            onChange: onChangeSrc,
            value,
            options,
            qa,
            ...restProps
        } = props;

        const { toggleCheckedValue, getCheckedStatus } = useCheckedValues(value);

        const onChangeGroup = useCallback<Required<ComponentProps<typeof Radio>>['onChange']>(
            (_, event) => {
                toggleCheckedValue(event.target.value);

                onChangeSrc?.(event.target.value, event);
            },
            [onChangeSrc, toggleCheckedValue]
        );

        const contextValue = useMemo(() => {
            return {
                contextProps: {
                    ...restProps,
                    invalid,
                    onChangeGroup,
                    getCheckedStatus,
                },
                ref,
            };
        }, [getCheckedStatus, invalid, onChangeGroup, ref, restProps]);

        return (
            <Grid width={width} gap={8}>
                <Flex spacing={spacing} direction={direction} data-qa={domHelpers.generateDataQa(qa, 'radio-group')}>
                    <GroupContext.Provider value={contextValue}>
                        {options?.map((option) => <Radio {...option} key={option.value} />)}

                        {children}
                    </GroupContext.Provider>
                </Flex>

                {hint && (
                    <Text font='body' fontSize='xsmall' fontWeight='regular' color={invalid ? 'red100' : undefined}>
                        {hint}
                    </Text>
                )}
            </Grid>
        );
    })
) as MemoExoticComponent<ForwardRefExoticComponent<GroupProps & RefAttributes<HTMLInputElement>>> & ChildrenComponents;

Group.Controller = GroupController;

export default Group;
