import { selectors } from 'modules/common';
import { domain } from 'modules/data';
import { Dispatch, SetStateAction } from 'react';
import { ElementFactory } from 'react-bem-factory';

export enum SettingTabs {
    General = 'General',
    Matching = 'Matching',
    FraudDetection = 'FraudDetection',
    EmailToSupplier = 'EmailToSupplier',
    Capture = 'Capture',
}

export enum ESettingsSection {
    LockDate = 'LockDate',
    FraudBypassing = 'FraudBypassing',
    FraudChangesAfter = 'FraudChangesAfter',
    XeroBillMatching = 'XeroBillMatching',
    XeroPOMatching = 'XeroPOMatching',
    GoodsReceivedNote = 'GoodsReceivedNote',
    PriceChecker = 'PriceChecker',
    Receipting = 'Receipting',
    Instruction = 'Instruction',
    LineItemSettings = 'LineItemSettings',
    DecisionPolicy = 'DecisionPolicy',
    OverrideGoToBtnInXero = 'OverrideGoToBtnInXero',
    QBooksBillMatching = 'QBooksBillMatching',
    NetSuiteFraudBypassing = 'NetSuiteFraudBypassing',
    NetSuiteFraudChangesAfter = 'NetSuiteFraudChangesAfter',
    NetSuiteExpenseReportFraudChangesAfter = 'NetSuiteExpenseReportFraudChangesAfter',
    EmailToSupplierSetting = 'EmailToSupplierSetting',
    QBooksFraudBypassing = 'QBooksFraudBypassing',
    QBooksFraudChangesAfter = 'QBooksFraudChangesAfter',
    DearFraudBypassing = 'DearFraudBypassing',
    DearFraudChangesAfter = 'DearFraudChangesAfter',
    DearPORejectedNoteToMemo = 'DearPORejectedNoteToMemo',
    SupplierBankAccountRequired = 'SupplierBankAccountRequired',
    NetSuiteAvailableLineItemType = 'NetSuiteAvailableLineItemType',
    NetSuiteCustomFormId = 'NetSuiteCustomFormId',
    PostingPreferencesType = 'PostingPreferencesType',
    UseRejectedPrefix = 'UseRejectedPrefix',
    Terms = 'Terms',
    PushApprovalMaxUrlToNetSuite = 'PushApprovalMaxUrlToNetSuite',
    HistoryPushingType = 'HistoryPushingType',
    CaptureViaEmail = 'CaptureViaEmail',
    BalanceControlCheck = 'BalanceControlCheck',
}

export type SettingsSchemeType = {
    [key in domain.IntegrationCode | 'null']: { [key in SettingTabs]?: ESettingsSection[] };
};

export interface CheckValidFraudBypassing {
    (integrationType: domain.IntegrationType): boolean;
}

export interface UseEffectiveDateLimits {
    (templateSettings: domain.TemplateSettings | null): selectors.types.EffectiveDateLimits | null;
}

export interface SetWorkflowSettingsHandler extends Dispatch<SetStateAction<domain.TemplateSettings>> {}

export interface WorkflowSettingsPopupSectionProps {
    templateSettings: domain.TemplateSettings | null;
    setTemplateSettings: SetWorkflowSettingsHandler;
    readonly?: boolean;
    qa: ElementFactory;
}

export interface DefaultCcOption {
    id: string;
    text: string;
}

export interface CopyToClipboardHandler<TValue extends string> {
    (value: TValue): void;
}

export type WorkflowSettingsSections = {
    [key in ESettingsSection]?: JSX.Element;
};
