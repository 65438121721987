import { Radio } from '@approvalmax/ui/src/components';
import { selectors } from 'modules/common';
import { FC, memo, useCallback } from 'react';

import { SettingsSection } from '../SettingsSection/SettingsSection';
import SettingsSubsection from '../SettingsSubsection/SettingsSubsection';
import { messages } from './SectionUseRejectedPrefix.messages';
import { RejectedPrefixOption, SectionUseRejectedPrefixProps } from './SectionUseRejectedPrefix.types';

const SectionUseRejectedPrefix: FC<SectionUseRejectedPrefixProps> = memo((props) => {
    const { templateSettings, setTemplateSettings, readonly, links, qa } = props;

    const value = templateSettings?.useRejectedPrefix ? RejectedPrefixOption.yes : RejectedPrefixOption.no;

    const onChangeUseRejectedPrefix = useCallback(
        (newValue: boolean) => {
            setTemplateSettings((current) => ({
                ...current,
                useRejectedPrefix: newValue,
            }));
        },
        [setTemplateSettings]
    );

    const handleChange = useCallback(
        (selectedValue: RejectedPrefixOption) => {
            switch (selectedValue) {
                case RejectedPrefixOption.yes:
                    onChangeUseRejectedPrefix(true);
                    break;

                case RejectedPrefixOption.no:
                    onChangeUseRejectedPrefix(false);
                    break;
            }
        },
        [onChangeUseRejectedPrefix]
    );

    return (
        <SettingsSection title={messages.title} learnMoreLink={links[selectors.types.Link.AddRejectedPrefix]}>
            <SettingsSubsection subtitle={messages.subtitle}>
                <Radio.Group
                    disabled={readonly}
                    value={value}
                    onChange={handleChange}
                    name='rejectedPrefix'
                    block
                    data-qa={qa('rejected-prefix')}
                >
                    <Radio value={RejectedPrefixOption.yes}>{messages.optionYes}</Radio>

                    <Radio value={RejectedPrefixOption.no}>{messages.optionNo}</Radio>
                </Radio.Group>
            </SettingsSubsection>
        </SettingsSection>
    );
});

export default SectionUseRejectedPrefix;
