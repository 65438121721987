import { NetSuiteExpenseLineExtendedExpenseReport, NetSuiteFieldsExpenseReport } from '../../../data/types';
import { messages } from '../../NetSuiteExpenseReportRequestForm.messages';
import { checkAccountForCorporateCardIsInvalid } from '../checkAccountForCorporateCardIsInvalid/checkAccountForCorporateCardIsInvalid';

export const validateAccountForCorporateCard = (
    fieldValues: Partial<NetSuiteFieldsExpenseReport>,
    expenseLines: NetSuiteExpenseLineExtendedExpenseReport[]
) => {
    const errors: string[] = [];

    if (checkAccountForCorporateCardIsInvalid(fieldValues, expenseLines)) {
        errors.push(messages.errorCorporateCardMandatory);
    }

    return errors;
};
