import { Box, Button, Grid, Link, List, Text } from '@approvalmax/ui/src/components';
import { FC, memo, useMemo } from 'react';

import { benefits } from './ConfirmSkipContent.constants';
import { getGlLogo, getGlName } from './ConfirmSkipContent.helpers';
import { messages } from './ConfirmSkipContent.messages';
import { ConfirmSkipContentProps } from './ConfirmSkipContent.types';

export const ConfirmSkipContent: FC<ConfirmSkipContentProps> = memo((props) => {
    const { onBack, onSkip, isLoading, prevStep } = props;

    const GlLogo = useMemo(() => getGlLogo(prevStep), [prevStep]);
    const glName = useMemo(() => getGlName(prevStep), [prevStep]);

    return (
        <Grid gap={32}>
            <Grid gap={24}>
                <Text font='headline' fontSize='xxsmall' color='midnight100'>
                    {messages.title({
                        glName,
                    })}
                </Text>

                <Grid gridTemplateColumns='max-content auto' gap={12}>
                    <GlLogo size={40} />

                    <Grid gap={4}>
                        <Text font='body' fontSize='medium' fontWeight='medium' color='midnight80'>
                            {messages.benefitsTitle}
                        </Text>

                        <Text font='body' fontSize='medium'>
                            <List listStyle='inside'>
                                {benefits.map((item, index) => (
                                    <List.Item key={index}>{item}</List.Item>
                                ))}
                            </List>
                        </Text>
                    </Grid>
                </Grid>

                <Box radius='small' color='midnight20' spacing='16 24'>
                    <Text font='body' fontSize='small' color='midnight100'>
                        {messages.trust1}
                    </Text>

                    <Text font='body' fontSize='small' color='midnight100' as='span'>
                        {messages.trust2}{' '}
                    </Text>

                    <Link font='body' fontSize='small' href='https://security.approvalmax.com' external>
                        {messages.learnMore}
                    </Link>
                </Box>
            </Grid>

            <Grid gap={16} gridTemplateColumns='max-content max-content' justifyContent='end'>
                <Button variant='outline' color='midnight40' onClick={onSkip} progress={isLoading}>
                    {messages.skip}
                </Button>

                <Button color='blue80' onClick={onBack} disabled={isLoading}>
                    {messages.connectButton({ glName })}
                </Button>
            </Grid>
        </Grid>
    );
});

ConfirmSkipContent.displayName = 'ConfirmSkipContent';
