import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('pages.RequestList.Toolbar.SecondaryActions', {
    editButtonTitle: 'Edit',
    editButtonText: 'Edit',
    startOverButtonTitle: 'Restart the approval workflow for this request',
    startOverButtonText: 'Start over the workflow',
    revokeButtonTitle: 'Revoke the previous decision',
    revokeButtonText: 'Revoke',
    deleteButtonTitle: 'Remove the request',
    deleteButtonText: 'Remove',
    cancelButtonTitle: 'Cancel the request',
    cancelButtonTitleScheduledReason: 'You cannot cancel the request as payment is scheduled.',
    cancelButtonText: 'Cancel',
    deleteConfirmationText: 'Sure?',
    secondaryActions: '{templateName} actions: ',
    reverseButtonTitle: 'Reverse',
    reverseButtonText: 'Reverse',
    allocateToXeroCreditNote: 'Add to credit note',
});
