import { Guid } from '@approvalmax/types';
import { schemas } from 'modules/data';
import { normalize } from 'normalizr';
import { api } from 'services/api';

export async function connectToIntegration(options: { callbackUrl: string }) {
    const response = await api.companies.enableXeroOAuth2Integration({
        callbackUrl: options.callbackUrl,
    });

    return {
        redirectUrl: response.AuthorizeUrl,
    };
}

type PollXeroFieldsSyncCompletionParams = { companyId: string; integrationId: string };

export const pollXeroFieldsSyncCompletion = async ({
    companyId,
    integrationId,
}: PollXeroFieldsSyncCompletionParams) => {
    while (true) {
        const cacheStatus = await api.integration.getIntegrationCacheStatus({
            companyId,
            integrationId,
        });

        const syncingFinished = cacheStatus.data.cacheObjects.every(
            (cache) => cache.hasSomeData || (cache.lastStartDate && cache.lastEndDate)
        );

        if (syncingFinished) {
            break;
        } else {
            await new Promise((resolve) => setTimeout(resolve, 5000));
        }
    }
};

export async function completeIntegrationAuth(options: { companyId: Guid; state: string; tenantId: string }) {
    const result = await api.companies.finishXeroIntegration({
        companyId: options.companyId,
        state: options.state,
        realmId: options.tenantId,
    });
    const entities = normalize(result.Companies[0], schemas.companySchemaLegacy).entities as any;

    return {
        entities,
    };
}
