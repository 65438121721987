import { Mods, mods, theme } from '@approvalmax/theme';
import { StyledMods } from '@styled-kit/mods';
import { ReactElement } from 'react';
import styled from 'styled-components';

import { BaseItem } from '../../Table.types';
import { BodyCell } from '../BodyCell/BodyCell';
import { BodyCellProps } from '../BodyCell/BodyCell.types';

export const CellStyled = styled(BodyCell)<StyledMods<Mods<'invalid'>>>`
    ${mods.invalid.true`
        border: 1px solid ${theme.color.red100};
        background-color: ${theme.color.red20};
    `};

    ${mods.readOnly.true`
        color: ${theme.color.midnight70};
        background-color: ${theme.color.midnight20};
    `};

    &:hover {
        border: 1px solid ${theme.color.blue100};
    }
` as <Item extends BaseItem>(props: BodyCellProps<Item> & { $invalid?: boolean }) => ReactElement;
