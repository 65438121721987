import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('app.WorkflowIdPage.SingletonSteps.AutoApprovalStep', {
    header: 'Auto-Approval Step',
    removeStepTitle: 'Remove Auto-Approval step',
    settingsButton: 'Auto-Approval settings',
    note: 'Please note: the Auto-Approval step is the first step that’s triggered. So, if a request matches one of the rules specified in this step, it will be approved automatically – and bypass all further approval steps.',
    willBeApprovedAutomaticallyIf: '{requestType} will be approved automatically if ',
    and: 'and',
    or: 'or',
});
