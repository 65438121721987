import { Modifier } from '@dnd-kit/core';
import range from 'lodash/range';
import { CSSProperties, memo } from 'react';

import { BaseItem, TableDraggableItem, TableProps } from './Table.types';

// todo change to genericMemo from componentHelpers
export const typedMemo: <T>(c: T) => T = memo;

export const checkboxColumnId = '__checkbox__';

export const retrieveRowId = <Item extends BaseItem>(item: Item, getRowId: TableProps<Item>['getRowId']) => {
    if (getRowId) {
        return getRowId(item);
    }

    return item.id;
};

export const reorderModifierSnapToAxis = <Item extends BaseItem>(args: Parameters<Modifier>[0]) => {
    const { transform, active } = args;
    const transformResult = { ...transform };

    if (!active) {
        return transformResult;
    }

    const activeData = active.data.current as TableDraggableItem<Item>;

    if (activeData.type === 'column') {
        transformResult.y = 0;
    }

    if (activeData.type === 'row') {
        transformResult.x = 0;
    }

    return transformResult;
};

export const generateMockedDataItemsForSkeleton = <Item extends BaseItem>(rowsCount: number) => {
    return range(1, rowsCount + 1).map((index) => ({ id: String(index) }) as Item);
};

/**
 * If table rows are reorderable or have action menu
 * we need to adjust table width and shift it for proper layout
 */
export const getStylesForVirtuoso = (isReorderableRows: boolean, hasRowActionMenu: boolean): CSSProperties => {
    if (isReorderableRows && hasRowActionMenu) {
        return {
            width: 'calc(100% + 60px)',
            marginLeft: '-30px',
        };
    }

    if (isReorderableRows) {
        return {
            width: 'calc(100% + 30px)',
            marginLeft: '-30px',
        };
    }

    if (hasRowActionMenu) {
        return {
            width: 'calc(100% + 30px)',
        };
    }

    return {};
};
