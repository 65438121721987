import { Reference } from '@approvalmax/types';
import { arrayHelpers, mathService, numberHelpers } from '@approvalmax/utils';
import { backend } from 'modules/data';

import {
    NetSuiteExpenseLineExpanded,
    NetSuiteItemLineExpanded,
    NetSuiteSalesPrice,
    RequestFormLineExtender,
} from '../../data/types';
import { setCurrencyPrecision } from '../amounts/amounts';

export const setItemLineCurrencyPrecision = (line: NetSuiteItemLineExpanded, currencyPrecision: number) => ({
    ...line,
    amount: setCurrencyPrecision(line.amount, currencyPrecision),
    grossAmount: setCurrencyPrecision(line.grossAmount, currencyPrecision),
    taxAmount: setCurrencyPrecision(line.taxAmount, currencyPrecision),
    amortizationResidual: setCurrencyPrecision(line.amortizationResidual, currencyPrecision),
    unitPrice: setCurrencyPrecision(line.unitPrice, currencyPrecision),
    defaultRate: setCurrencyPrecision(line.defaultRate, currencyPrecision),
});

export const setExpenseLineCurrencyPrecision = (line: NetSuiteExpenseLineExpanded, currencyPrecision: number) => ({
    ...line,
    amount: setCurrencyPrecision(line.amount, currencyPrecision),
    grossAmount: setCurrencyPrecision(line.grossAmount, currencyPrecision),
    taxAmount: setCurrencyPrecision(line.taxAmount, currencyPrecision),
    amortizationResidual: setCurrencyPrecision(line.amortizationResidual, currencyPrecision),
});

export const mapItemSalesPrices = (salesPrices: backend.NetSuiteSalesPricesAnswer[] | null) => {
    if (!salesPrices) {
        return [];
    }

    const mappedSalesPrices: NetSuiteSalesPrice[] = [];

    salesPrices.forEach((salesPrice) => {
        const currencyId = salesPrice.CurrencyId;
        const priceLevelId = salesPrice.PriceLevelId;
        const price = salesPrice.Value;

        if (!currencyId || !priceLevelId || !numberHelpers.isNumber(price)) {
            return;
        }

        const priceLevel = {
            id: priceLevelId,
            price,
        };

        const addedSalesPrice = mappedSalesPrices.find(
            (mappedSalesPrice) => mappedSalesPrice.currencyId === currencyId
        );

        if (!addedSalesPrice) {
            mappedSalesPrices.push({
                currencyId,
                priceLevels: [priceLevel],
            });
        } else {
            addedSalesPrice.priceLevels.push(priceLevel);
        }
    });

    return mappedSalesPrices;
};

const calculateLineTaxAmount = (amount: number | null, taxRate: number | null, currencyPrecision: number) => {
    if (!numberHelpers.isNumber(amount) || !numberHelpers.isNumber(taxRate)) {
        return null;
    }

    return setCurrencyPrecision(mathService.multiply(amount, taxRate) / 100, currencyPrecision);
};

const calculateLineAmount = (grossAmount: number | null, taxRate: number | null, currencyPrecision: number) => {
    if (!numberHelpers.isNumber(grossAmount)) {
        return null;
    }

    return setCurrencyPrecision(grossAmount / mathService.add(1, (taxRate ?? 0) / 100), currencyPrecision);
};

const calculateLineGrossAmount = (amount: number | null, taxAmount: number | null, currencyPrecision: number) => {
    if (!numberHelpers.isNumber(amount)) {
        return null;
    }

    return setCurrencyPrecision(mathService.add(amount, taxAmount ?? 0), currencyPrecision);
};

const getLineWithUpdatedAmounts = <T extends Record<string, any>>(
    line: T,
    amounts: { taxAmount: number | null; amount: number | null; grossAmount: number | null },
    currencyPrecision: number
) => {
    if (
        'quantity' in line &&
        numberHelpers.isNumber(amounts.amount) &&
        numberHelpers.isNumber(line.quantity) &&
        line.quantity > 0
    ) {
        const unitPrice = setCurrencyPrecision(amounts.amount / line.quantity, currencyPrecision);

        return {
            ...line,
            ...amounts,
            unitPrice,
        };
    }

    return {
        ...line,
        ...amounts,
    };
};

export const calculateLineAmountsBasedOnAmount = (
    amount: number | null,
    taxRate: number | null,
    currencyPrecision: number
) => {
    const taxAmount = calculateLineTaxAmount(amount, taxRate, currencyPrecision);

    return {
        taxAmount,
        amount: setCurrencyPrecision(amount, currencyPrecision),
        grossAmount: calculateLineGrossAmount(amount, taxAmount, currencyPrecision),
    };
};

export const calculateLineAmountsBasedOnGrossAmount = (
    grossAmount: number | null,
    taxRate: number | null,
    currencyPrecision: number
) => {
    const amount = calculateLineAmount(grossAmount, taxRate, currencyPrecision);

    return {
        amount,
        taxAmount: calculateLineTaxAmount(amount, taxRate, currencyPrecision),
        grossAmount: setCurrencyPrecision(grossAmount, currencyPrecision),
    };
};

export const updateLineOnTaxCodeChange = <T extends { amount: number | null }>(
    line: T,
    taxCode: Reference | null,
    currencyPrecision: number
) => {
    const taxRate = taxCode && 'rate' in taxCode && numberHelpers.isNumber(taxCode.rate) ? taxCode.rate : null;

    return {
        ...line,
        taxCode,
        taxRate,
        ...calculateLineAmountsBasedOnAmount(line.amount, taxRate, currencyPrecision),
    };
};

export const updateLineOnAmountChange = <T extends { taxRate: number | null }>(
    line: T,
    amount: number | null,
    currencyPrecision: number
) => {
    const amounts = calculateLineAmountsBasedOnAmount(amount, line.taxRate, currencyPrecision);

    return getLineWithUpdatedAmounts(line, amounts, currencyPrecision);
};

export const updateLineOnGrossAmountChange = <T extends { taxRate: number | null }>(
    line: T,
    grossAmount: number | null,
    currencyPrecision: number
) => {
    const amounts = calculateLineAmountsBasedOnGrossAmount(grossAmount, line.taxRate, currencyPrecision);

    return getLineWithUpdatedAmounts(line, amounts, currencyPrecision);
};

export const updateLineOnQuantityOrUnitPriceChange = <T extends { taxRate: number | null }>(
    line: T,
    quantity: number | null,
    unitPrice: number | null,
    currencyPrecision: number
) => {
    const amount =
        numberHelpers.isNumber(quantity) && numberHelpers.isNumber(unitPrice)
            ? mathService.multiply(quantity, unitPrice)
            : null;

    return {
        ...line,
        ...calculateLineAmountsBasedOnAmount(amount, line.taxRate, currencyPrecision),
        unitPrice: setCurrencyPrecision(unitPrice, currencyPrecision),
        quantity,
    };
};

export const reorderLines = <T extends RequestFormLineExtender & { lineNumber: number }>(
    lines: T[],
    oldIndex: number,
    newIndex: number
) => {
    const reorderedLines = arrayHelpers.arrayMove(lines, oldIndex, newIndex).map((line, index) => ({
        ...line,
        lineNumber: lines[index].lineNumber,
    }));

    return reorderedLines;
};
