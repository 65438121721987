import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { useMount } from 'react-use';
import { useAddTwoFaEnablingAlternativeEmail } from 'shared/data/webApp/v1';
import { z } from 'zod';

import { useTwoFaWizardController, useTwoFaWizardStep } from '../../TwoFaEnablingWizard.hooks';
import { WizardStep } from '../../TwoFaEnablingWizard.types';
import { messages } from '../StepBackupCodes/StepBackupCodes.messages';
import { schema } from './StepBackupEmail.schema';

export const useStepBackupEmail = () => {
    const { setWizardStep } = useTwoFaWizardStep();
    const { wizardController, setWizardController } = useTwoFaWizardController();

    const { stateId } = wizardController;

    const form = useForm<z.infer<typeof schema>>({
        defaultValues: {
            email: '',
        },
        resolver: zodResolver(schema),
    });

    const { mutate, isLoading } = useAddTwoFaEnablingAlternativeEmail({
        onSuccess: () => {
            const email = form.getValues('email');

            setWizardController((prev) => ({ ...prev, email }));
            setWizardStep(WizardStep.backupEmailVerify);
        },
    });

    const handleSubmit = form.handleSubmit((data) => {
        const { email } = data;

        if (stateId && email) {
            mutate({ body: { stateId, email } });
        }
    });

    useMount(() => {
        setWizardController((prev) => ({
            ...prev,
            title: messages.title,
            onPrev: undefined,
            onNext: undefined,
        }));
    });

    const isDisabled = !form.watch('email').trim();

    return {
        form,
        handleSubmit,
        isLoading,
        isDisabled,
    };
};
