import { browserHelpers, dateTimeHelpers } from '@approvalmax/utils';
import { KeyboardEventHandler, RefObject, useCallback } from 'react';
import type { CalendarProps } from 'react-calendar';
import { useToggle } from 'react-use';

import { textFieldClearButtonName } from '../TextField/TextField.constants';
import type { HTMLTextFieldElement, TextFieldProps } from '../TextField/TextField.types';
import {
    formatTextFieldValue,
    formatTextFieldValueToHumanReadable,
    normalizeDatePickerValue,
    parseEnteredTextFieldValue,
    parseValueToDate,
} from './DateTimePicker.helpers';
import { DateTimePickerProps } from './DateTimePicker.types';

const isSafari = browserHelpers.isSafari();

export const useTextFieldValue = (
    props: Pick<ReturnType<typeof useCalendarValue>, 'changeDatePickerValue' | 'calendarValue'> &
        Partial<Pick<DateTimePickerProps, 'minDate' | 'maxDate'>> & { isHumanReadable: boolean }
) => {
    const { calendarValue, changeDatePickerValue, minDate, maxDate, isHumanReadable } = props;

    const textFieldValue = isHumanReadable
        ? formatTextFieldValueToHumanReadable(calendarValue)
        : formatTextFieldValue(calendarValue);

    const handleTextFieldChange = useCallback<NonNullable<TextFieldProps['onChange']>>(
        (enteredValue) => {
            const parsedTextFieldValue = parseEnteredTextFieldValue(enteredValue);
            const parsedDate = parsedTextFieldValue ? new Date(parsedTextFieldValue) : undefined;

            let resultDate = parsedDate;

            if (parsedDate && minDate && parsedDate < minDate) {
                resultDate = new Date(minDate);
            }

            if (parsedDate && maxDate && parsedDate > maxDate) {
                resultDate = new Date(maxDate);
            }

            changeDatePickerValue(resultDate);
        },
        [changeDatePickerValue, maxDate, minDate]
    );

    return {
        textFieldValue,
        handleTextFieldChange,
    };
};

export const useCalendarValue = (
    props: Pick<DateTimePickerProps, 'value' | 'noUtc'> &
        Required<Pick<DateTimePickerProps, 'onChange'>> & { inputRef: RefObject<HTMLTextFieldElement> }
) => {
    const { value, onChange, noUtc, inputRef } = props;

    // no need to memoize, because ANY action within will change the value
    const calendarValue = noUtc ? parseValueToDate(value) : dateTimeHelpers.convertLocalToUTC(value);

    const changeDatePickerValue = useCallback(
        (changeValue: Date | undefined) => {
            inputRef.current?.focus();
            onChange(noUtc ? changeValue : dateTimeHelpers.convertUTCToLocal(changeValue));
        },
        [onChange, noUtc, inputRef]
    );

    return {
        calendarValue,
        changeDatePickerValue,
    };
};

export const useCallbackActions = (
    props: Pick<DateTimePickerProps, 'onOpen' | 'onClear' | 'initOpen' | 'initFocus'> &
        Pick<ReturnType<typeof useCalendarValue>, 'changeDatePickerValue' | 'calendarValue'>
) => {
    const { calendarValue, changeDatePickerValue, onOpen, onClear, initOpen, initFocus } = props;

    const [open, toggleOpen] = useToggle(Boolean(initOpen));
    const [focus, toggleFocus] = useToggle(Boolean(initFocus));
    const [hover, toggleHover] = useToggle(false);

    const handleOpen = useCallback<Required<DateTimePickerProps>['onOpen']>(
        (nextOpen, event) => {
            // prevent open a Picker on clearing
            if ((event?.target as Element | null | undefined)?.closest(`button[name="${textFieldClearButtonName}"]`)) {
                return;
            }

            toggleOpen(nextOpen);
            onOpen?.(nextOpen);
        },
        [onOpen, toggleOpen]
    );

    const handleMouseEnter = useCallback(() => toggleHover(true), [toggleHover]);
    const handleMouseLeave = useCallback(() => toggleHover(false), [toggleHover]);

    const handleClick = useCallback<Required<DateTimePickerProps>['onClick']>((event) => {
        // this prevents opening native Safari date picker
        if (isSafari) {
            event.preventDefault();
        }
    }, []);

    const handleKeyDown = useCallback<KeyboardEventHandler<HTMLTextFieldElement>>(
        (event) => {
            if (event.key === ' ') {
                // prevent open browser's calendar
                event.preventDefault();

                if (!open) {
                    handleOpen(true);
                }
            }
        },
        [handleOpen, open]
    );

    const handleClear = useCallback<Required<DateTimePickerProps>['onClear']>(
        (event) => {
            changeDatePickerValue(undefined);
            onClear?.(event);
        },
        [changeDatePickerValue, onClear]
    );

    const handleChangeValue: DateTimePickerProps['onChange'] = (value) => {
        changeDatePickerValue(value);
    };

    const handleCalendarChange: NonNullable<CalendarProps['onChange']> = (value) => {
        handleChangeValue(normalizeDatePickerValue(value, calendarValue));
    };

    return {
        open,
        handleOpen,
        focus,
        handleClick,
        handleCalendarChange,
        handleChangeValue,
        handleKeyDown,
        handleClear,
        hover,
        handleMouseEnter,
        handleMouseLeave,
        toggleFocus,
        toggleOpen,
    };
};
