import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('pages.RequestList.XeroLineItemsSection', {
    subTotalText: 'Subtotal:',
    taxAdjustmentsText: 'Includes adjustments to tax',
    totalAmountText: 'TOTAL:',
    taxTypeTextNoTax: 'Amounts do not include Tax',
    taxTypeTextTaxInclusive: 'Amounts are Tax inclusive',
    taxTypeTextTaxExclusive: 'Amounts are Tax exclusive',
    invoiceTaxTextTaxInclusive: 'Includes Tax ({percent}%):',
    invoiceTaxTextTaxExclusive: 'Total Tax ({percent}%):',
    cisTaxAmount: 'CIS deduction ({percent}%):',
    remainingCreditAmountText: 'Remaining Credit:',
    allocationText: 'Allocated to {url}',
});
