import { Button, Flex, Popup, Text } from '@approvalmax/ui/src/components';
import { FC, memo } from 'react';

import { messages } from './MigrationFromReviewerV1ClosingPopupContent.messages';
import { MigrationFromReviewerV1ClosingPopupContentProps } from './MigrationFromReviewerV1ClosingPopupContent.types';

export const MigrationFromReviewerV1ClosingPopupContent: FC<MigrationFromReviewerV1ClosingPopupContentProps> = memo(
    (props) => {
        const { onClose } = props;

        return (
            <Popup.Body>
                <Flex direction='column' spacing='24' alignItems='center'>
                    <Text font='headline' fontSize='xsmall' fontWeight='medium' textAlign='center' spacing='12 0'>
                        {messages.closingPopupMessage}
                    </Text>

                    <Button color='green80' size='large' block onClick={onClose}>
                        {messages.closeButtonText}
                    </Button>
                </Flex>
            </Popup.Body>
        );
    }
);

MigrationFromReviewerV1ClosingPopupContent.displayName = 'MigrationFromReviewerV1ClosingPopupContent';
