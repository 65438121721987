import { AmCaptureSmallImage, AmPaySmallImage, CloseIcon } from '@approvalmax/ui';
import { Box, Button, Flex, Spacing, Text } from '@approvalmax/ui/src/components';
import { useSetAtom } from 'jotai';
import {
    tryPayOrCapturePopupInfoState,
    tryPayOrCapturePopupOpenState,
    TryPayOrCapturePopupOrigin,
} from 'modules/profile';
import { FC, memo, useCallback } from 'react';
import { useAddonPromotions } from 'shared/hooks/useAddonPromotions';
import { AddonPromotionType } from 'shared/states';

import { messages } from './DrawerPayAndCapturePromotion.messages';
import { DrawerPayAndCapturePromotionProps } from './DrawerPayAndCapturePromotion.types';

export const DrawerPayAndCapturePromotion: FC<DrawerPayAndCapturePromotionProps> = memo((props) => {
    const { type } = props;

    const { closePromotion } = useAddonPromotions();
    const setTryPayOrCapturePopupOpen = useSetAtom(tryPayOrCapturePopupOpenState);
    const setTryPayOrCapturePopupInfo = useSetAtom(tryPayOrCapturePopupInfoState);

    const onClose = useCallback(() => {
        closePromotion(type);
    }, [closePromotion, type]);

    const onTry = useCallback(() => {
        setTryPayOrCapturePopupOpen(true);
        setTryPayOrCapturePopupInfo({ type, origin: TryPayOrCapturePopupOrigin.SideNavigation });
    }, [setTryPayOrCapturePopupInfo, setTryPayOrCapturePopupOpen, type]);

    return (
        <>
            <Box color={type === AddonPromotionType.Pay ? 'cobalt100' : 'mint100'} spacing='12' radius='xsmall'>
                <Flex direction='column' spacing='12'>
                    <Flex justifyContent='space-between' alignItems='center'>
                        {type === AddonPromotionType.Pay ? <AmPaySmallImage /> : <AmCaptureSmallImage />}

                        <Flex alignItems='center'>
                            <Button color='white100' size='small' onClick={onTry}>
                                {messages.tryNowButtonText}
                            </Button>

                            <Button icon variant='text' color='blue100' noPadding onClick={onClose}>
                                <CloseIcon size={16} color='white100' />
                            </Button>
                        </Flex>
                    </Flex>

                    <Text font='body' fontSize='small'>
                        {type === AddonPromotionType.Pay ? messages.payDescription : messages.captureDescription}
                    </Text>
                </Flex>
            </Box>

            <Spacing height={8} />
        </>
    );
});

DrawerPayAndCapturePromotion.displayName = 'DrawerPayAndCapturePromotion';
