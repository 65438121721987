import { Menu } from '@approvalmax/ui/src/components';
import { domain } from 'modules/data';
import { FC, memo, useMemo } from 'react';

import { UserAvatar } from '../UserAvatar/UserAvatar';
import { messages } from './SelectUserMenuItem.messages';
import { SelectUserMenuItemProps } from './SelectUserMenuItem.types';

export const SelectUserMenuItem: FC<SelectUserMenuItemProps> = memo((props) => {
    const { status, displayName, size } = props;

    const badgeText = useMemo(() => {
        let badgeText;

        switch (status) {
            case domain.CompanyUserStatus.Invited:
                badgeText = messages.invitedText;
                break;

            case domain.CompanyUserStatus.Rejected:
            case domain.CompanyUserStatus.NotInvited:
                badgeText = messages.notInvitedText;
                break;

            default:
                break;
        }

        return badgeText;
    }, [status]);

    return (
        <Menu.Item
            {...props}
            size={size}
            name={displayName}
            startIcon={<UserAvatar size={size === 'small' ? 16 : 24} user={props} />}
            endText={badgeText}
        />
    );
});

SelectUserMenuItem.displayName = 'SelectUserMenuItem';
