import { useTheme } from '@approvalmax/theme';
import { FC } from 'react';
import { useMedia } from 'react-use';

import { Content, Sidebar } from './components';
import { Root } from './LayoutWithSidebar.styles';
import { ChildrenComponents, LayoutWithSidebarProps } from './LayoutWithSidebar.types';

/**
 * Renders a layout with the menu sidebar separated from content by a border
 * Optionally you may pass custom content for the sidebar
 */
export const LayoutWithSidebar: FC<LayoutWithSidebarProps> & ChildrenComponents = (props) => {
    const { bordered = false, children, ...restProps } = props;

    const { theme } = useTheme();
    const isXSmall = useMedia(`(max-width: ${theme.container.breakpoint.xsmall})`);

    return (
        <Root $bordered={bordered} $orientation={isXSmall ? 'vertical' : 'horizontal'} {...restProps}>
            {children}
        </Root>
    );
};

LayoutWithSidebar.Content = Content;
LayoutWithSidebar.Sidebar = Sidebar;

LayoutWithSidebar.displayName = 'LayoutWithSidebar';
