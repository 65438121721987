import { Popup } from '@approvalmax/ui';
import { miscHelpers } from '@approvalmax/utils';
import { FC, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { cancelActivePopup } from './actions';
import AccountSetupPopup from './containers/AccountSetupPopup/AccountSetupPopup';
import CreateOrJoinPopup from './containers/CreateOrJoinPopup/CreateOrJoinPopup';
import ReloginAcceptInvitationPopup from './containers/ReloginAcceptInvitationPopup/ReloginAcceptInvitationPopup';
import { ActivePopup } from './reducers/moduleReducer';
import { getActivePopup } from './selectors/moduleSelectors';

const FirstStart: FC = () => {
    const dispatch = useDispatch();
    const activePopup = useSelector(getActivePopup);

    const handleCancelActivePopup = () => {
        dispatch(cancelActivePopup());
    };

    return (
        <>
            <Popup
                isOpen={activePopup === ActivePopup.CreateOrJoinPopup}
                onRequestClose={miscHelpers.noop}
                disableAutoClose
            >
                <CreateOrJoinPopup />
            </Popup>

            <Popup isOpen={activePopup === ActivePopup.AccountSetupPopup} onRequestClose={handleCancelActivePopup}>
                <AccountSetupPopup />
            </Popup>

            <Popup
                isOpen={activePopup === ActivePopup.ReloginAcceptInvitation}
                onRequestClose={handleCancelActivePopup}
                disableAutoClose
            >
                <ReloginAcceptInvitationPopup onClose={handleCancelActivePopup} />
            </Popup>
        </>
    );
};

export default memo(FirstStart);
