import { Box, Tooltip } from '@approvalmax/ui/src/components';
import { FC, isValidElement } from 'react';

import { WarningTooltipProps } from './WarningTooltip.types';

export const WarningTooltip: FC<WarningTooltipProps> = (props) => {
    const { open, activator, children, ...tooltipProps } = props;

    return (
        <Tooltip disabled={!open} placement='top' width='max-content' {...tooltipProps} activator={activator}>
            {isValidElement(children) ? children : <Box spacing='8'>{children}</Box>}
        </Tooltip>
    );
};

WarningTooltip.displayName = 'WarningTooltip';
