import { useAtom } from 'jotai';
import {
    capturePromotionResetPeriodInMonths,
    payPromotionResetPeriodInMonths,
} from 'modules/common/constants/commonConstants';
import moment from 'moment';
import { useMount } from 'react-use';
import { addonPromotionInfoState, AddonPromotionType, defaultPromotionInfo } from 'shared/states';

export const usePromotionReset = () => {
    const [addonPromotionInfo, setAddonPromotionInfo] = useAtom(addonPromotionInfoState);

    useMount(() => {
        const payInfo = addonPromotionInfo[AddonPromotionType.Pay];
        const captureInfo = addonPromotionInfo[AddonPromotionType.Capture];
        const now = moment();

        let shouldUpdate = false;

        const newState = { ...addonPromotionInfo };

        if (payInfo.forcePopupShownDate) {
            const payDate = moment(payInfo.forcePopupShownDate);

            if (now.diff(payDate, 'months') >= payPromotionResetPeriodInMonths) {
                newState[AddonPromotionType.Pay] = defaultPromotionInfo;
                shouldUpdate = true;
            }
        }

        if (captureInfo.forcePopupShownDate) {
            const captureDate = moment(captureInfo.forcePopupShownDate);

            if (now.diff(captureDate, 'months') >= capturePromotionResetPeriodInMonths) {
                newState[AddonPromotionType.Capture] = defaultPromotionInfo;
                shouldUpdate = true;
            }
        }

        if (shouldUpdate) {
            setAddonPromotionInfo(newState);
        }
    });
};
