import { RequestParams, RequestQueryOptions } from '@approvalmax/data';
import { useGet, useLazyGet } from 'shared/data';

import { requestsApiPaths } from '../paths';
import { GetSearchRequests } from './useGetSearchRequests.types';

export const useLazyGetSearchRequests = (queryOptions?: RequestQueryOptions<GetSearchRequests>) =>
    useLazyGet<GetSearchRequests>(requestsApiPaths.searchRequests, {
        queryOptions,
        apiVersion: 'v1',
    });

export const useGetSearchRequests = (
    params: RequestParams<GetSearchRequests>,
    queryOptions?: RequestQueryOptions<GetSearchRequests>
) =>
    useGet(requestsApiPaths.searchRequests, {
        params,
        queryOptions,
        apiVersion: 'v1',
    });
