import { ActionMenu, TextButton } from '@approvalmax/ui';
import { selectors } from 'modules/common';
import { domain, stateTree } from 'modules/data';
import { FC, memo, useCallback } from 'react';
import bemFactory from 'react-bem-factory';
import { useDispatch, useSelector } from 'react-redux';
import { amplitudeService } from 'services/amplitude';

import { cloneRequest } from '../../../actions';
import { messages } from './CopyOperation.messages';
import { CloneButton, TringleIcon } from './CopyOperation.styles';
import { ToolbarAddressProps } from './CopyOperation.types';

const qa = bemFactory.qa('reql-toolbar-copy-operation');

const CopyOperation: FC<ToolbarAddressProps> = memo((props) => {
    const { request, disabled } = props;

    const dispatch = useDispatch();
    const templates = useSelector((state: stateTree.State) =>
        selectors.template.getTemplatesByCompanyId(state, request.companyId)
    );

    const isQuote = request.integrationCode === domain.IntegrationCode.XeroQuote;
    const isXeroInvoice = request.integrationCode === domain.IntegrationCode.XeroInvoice;

    const isPO =
        request.integrationCode === domain.IntegrationCode.XeroPo ||
        request.integrationCode === domain.IntegrationCode.QBooksPo ||
        request.integrationCode === domain.IntegrationCode.NetSuitePO;
    const isXeroBill = request.integrationCode === domain.IntegrationCode.XeroBill;
    const isStandardClone = request.commands.clonePOToBill?.hidden && request.commands.cloneQuoteToSalesInvoice?.hidden;

    const standardClone = useCallback(() => {
        amplitudeService.sendData('requests: make request action', {
            'action type': 'copy',
        });

        dispatch(cloneRequest(request.id, request.template.id, request.companyId));
    }, [dispatch, request.companyId, request.id, request.template.id]);

    const clonePOToBill = useCallback(() => {
        let templateId;

        if (request.integrationCode === domain.IntegrationCode.QBooksPo) {
            templateId = templates.find(
                (template) => template.integrationCode === domain.IntegrationCode.QBooksBill
            )?.id;
        }

        if (request.integrationCode === domain.IntegrationCode.XeroPo) {
            templateId = templates.find((template) => template.integrationCode === domain.IntegrationCode.XeroBill)?.id;
        }

        if (request.integrationCode === domain.IntegrationCode.NetSuitePO) {
            templateId = templates.find(
                (template) => template.integrationCode === domain.IntegrationCode.NetSuiteBill
            )?.id;
        }

        if (templateId) {
            dispatch(cloneRequest(request.id, templateId, request.companyId));
        }
    }, [dispatch, request.companyId, request.id, request.integrationCode, templates]);

    const cloneQuoteToSalesInvoice = useCallback(() => {
        const templateId =
            templates.find((template) => template.integrationCode === domain.IntegrationCode.XeroInvoice)?.id || '';

        dispatch(cloneRequest(request.id, templateId, request.companyId));
    }, [dispatch, request.companyId, request.id, templates]);

    return isStandardClone ? (
        <TextButton
            qa={qa('clone-button')}
            execute={standardClone}
            disabled={disabled}
            command={request.commands.clone}
            title={messages.cloneButtonTitle}
        >
            {messages.cloneButtonText}
        </TextButton>
    ) : (
        <ActionMenu
            panelFlow='to-left'
            button={
                <CloneButton disabled={disabled} qa={qa('clone-button')}>
                    {messages.cloneToDropDownButtonText}

                    <TringleIcon width={10} height={6} />
                </CloneButton>
            }
        >
            {isPO && (
                <>
                    {request.commands.clone && !request.commands.clone.hidden && (
                        <ActionMenu.Item
                            execute={standardClone}
                            disabled={disabled}
                            qa={qa('open-for-editing-menu-item')}
                        >
                            {messages.cloneToPurchaseOrderButtonText}
                        </ActionMenu.Item>
                    )}

                    {request.commands.clonePOToBill && !request.commands.clonePOToBill.hidden && (
                        <ActionMenu.Item
                            execute={clonePOToBill}
                            disabled={disabled}
                            qa={qa('open-for-editing-menu-item')}
                        >
                            {messages.cloneToBillButtonText}
                        </ActionMenu.Item>
                    )}
                </>
            )}

            {isQuote && (
                <>
                    {request.commands.clone && !request.commands.clone.hidden && (
                        <ActionMenu.Item
                            execute={standardClone}
                            disabled={disabled}
                            qa={qa('open-for-editing-menu-item')}
                        >
                            {messages.cloneToQuoteButtonText}
                        </ActionMenu.Item>
                    )}

                    {request.commands.cloneQuoteToSalesInvoice && !request.commands.cloneQuoteToSalesInvoice.hidden && (
                        <ActionMenu.Item
                            execute={cloneQuoteToSalesInvoice}
                            disabled={disabled}
                            qa={qa('open-for-editing-menu-item')}
                        >
                            {messages.cloneToSalesInvoiceButtonText}
                        </ActionMenu.Item>
                    )}
                </>
            )}

            {isXeroBill && (
                <>
                    {request.commands.clone && !request.commands.clone.hidden && (
                        <ActionMenu.Item
                            execute={standardClone}
                            disabled={disabled}
                            qa={qa('open-for-editing-menu-item')}
                        >
                            {messages.cloneToBillButtonText}
                        </ActionMenu.Item>
                    )}
                </>
            )}

            {isXeroInvoice && (
                <>
                    {request.commands.clone && !request.commands.clone.hidden && (
                        <ActionMenu.Item
                            execute={standardClone}
                            disabled={disabled}
                            qa={qa('open-for-editing-menu-item')}
                        >
                            {messages.cloneToSalesInvoiceButtonText}
                        </ActionMenu.Item>
                    )}
                </>
            )}
        </ActionMenu>
    );
});

export default CopyOperation;
