import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('modules.IntegrationModule.XeroIntegrationSelectPopup', {
    doneButtonText: 'Confirm',
    message: 'Please confirm your selection of Xero organisation to be connected to ApprovalMax.',
    warningText:
        'If you change the selected organisation later, you will have to create a new ApprovalMax organisation to connect it to another Xero organisation.',
    yourXeroOrg: 'Select Xero organisation:',
    popupTitle: 'Select Xero organisation',
});
