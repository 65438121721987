import { enabledByParams, RequestParams, RequestQueryOptions, useInvalidateQuery } from '@approvalmax/data';
import { useGet } from 'shared/data';

import { companiesApiPaths } from '../paths';
import { GetNetSuiteCustomCompanyFields } from './useGetNetSuiteCustomCompanyFields.types';

export const useGetNetSuiteCustomCompanyFields = (
    params?: RequestParams<GetNetSuiteCustomCompanyFields<true>>,
    queryOptions?: RequestQueryOptions<GetNetSuiteCustomCompanyFields<true>>
) => {
    return useGet(companiesApiPaths.getNetSuiteCustomCompanyFields, {
        params,
        queryOptions: {
            ...queryOptions,
            enabled: enabledByParams<GetNetSuiteCustomCompanyFields<true>['params']['query']>(
                {
                    companyId: params?.query?.companyId,
                },
                queryOptions
            ),
        },
        apiVersion: 'v1',
        mapToCamelCase: true,
    });
};

export const useGetNetSuiteCustomCompanyFieldsRaw = (
    params?: RequestParams<GetNetSuiteCustomCompanyFields>,
    queryOptions?: RequestQueryOptions<GetNetSuiteCustomCompanyFields>
) => {
    return useGet(companiesApiPaths.getNetSuiteCustomCompanyFields, {
        params,
        queryOptions: {
            ...queryOptions,
            enabled: enabledByParams<GetNetSuiteCustomCompanyFields['params']['query']>(
                {
                    companyId: params?.query?.companyId,
                },
                queryOptions
            ),
        },
        apiVersion: 'v1',
    });
};

export const useInvalidateGetNetSuiteCustomCompanyFields = () => {
    return useInvalidateQuery<RequestParams<GetNetSuiteCustomCompanyFields> | void>(
        companiesApiPaths.getNetSuiteCustomCompanyFields
    );
};
