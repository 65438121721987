import { RequestMutationOptions } from '@approvalmax/data';
import { useMutate } from 'shared/data';

import { delegationsApiPaths } from '../paths';
import { useInvalidateGetCompanyDelegations } from '../useGetCompanyDelegations/useGetCompanyDelegations';
import { CreateDelegations } from './useCreateDelegations.types';

export const useCreateDelegations = (mutationOptions?: RequestMutationOptions<CreateDelegations>) => {
    const invalidateGetCompanyDelegations = useInvalidateGetCompanyDelegations();

    return useMutate(delegationsApiPaths.delegations, {
        mutationOptions: {
            ...mutationOptions,
            onSuccess: (data, variables, context) => {
                (variables.body || []).forEach(({ companyId }) => {
                    invalidateGetCompanyDelegations({ path: { companyId } });
                });

                mutationOptions?.onSuccess?.(data, variables, context);
            },
        },
        apiVersion: 'v2',
    });
};
