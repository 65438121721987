import { atomHelpers } from '@approvalmax/utils';
import { atom } from 'jotai';
import difference from 'lodash/difference';
import moment from 'moment';

export const fileUploadTimestampsStorageKey = 'fileDateUploads';

// Create base atom with storage
export const baseFileUploadTimestampsAtom = atomHelpers.createAtomWithStorage<string[]>(
    fileUploadTimestampsStorageKey,
    []
);

// Create derived atom with the cleanup logic
export const fileUploadTimestampsState = atom(
    (get) => get(baseFileUploadTimestampsAtom),
    (get, set, newValueFn: (prevValue: string[]) => string[]) => {
        const oldValue = get(baseFileUploadTimestampsAtom);
        const newValue = newValueFn(oldValue);

        set(baseFileUploadTimestampsAtom, newValue);

        // Handle newly added files
        const recentlyUploadedFiles = difference(newValue, oldValue);

        if (recentlyUploadedFiles.length > 0) {
            setTimeout(() => {
                set(baseFileUploadTimestampsAtom, (prev) =>
                    prev.filter((upload) => !recentlyUploadedFiles.includes(upload))
                );
            }, 60000);
        }

        // Clean up expired timestamps
        const currentTime = moment();
        const validTimestamps = newValue.filter((timestamp) => {
            const uploadTime = moment(timestamp);
            const secondsSinceUpload = currentTime.diff(uploadTime, 'seconds');

            if (secondsSinceUpload <= 60) {
                setTimeout(
                    () => {
                        set(baseFileUploadTimestampsAtom, (prev) => prev.filter((upload) => upload !== timestamp));
                    },
                    (60 - secondsSinceUpload) * 1000
                );

                return true;
            }

            return false;
        });

        if (validTimestamps.length !== newValue.length) {
            set(baseFileUploadTimestampsAtom, validTimestamps);
        }
    }
);
