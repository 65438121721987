import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('pages.RequestList.Toolbar.IntegrationHeader', {
    submittedViaXero: 'Created in Xero',
    submittedViaQuickBooks: 'Created in QuickBooks Online',
    receiptBank: 'Created in Dext Prepare',
    netSuite: 'Created in Oracle NetSuite',
    dear: 'Created in Cin7 Core',
    submittedBy: 'Submitted by {userName}',
    email: 'Submitted via email',
});
