import './otherSubscriptionOptions.scss';

import { RadioGroupEditor } from '@approvalmax/ui';
import React, { FC } from 'react';
import bemFactory from 'react-bem-factory';

import { SelectSubscriptionOption } from '../../actions';
import { messages } from './OtherSubscriptionOptions.messages';

const bem = bemFactory.block('company-select-subscription-other-subscriptions');

interface OtherSubscriptionOptionsProps {
    option: SelectSubscriptionOption;
    setOption: (value: SelectSubscriptionOption) => void;
    onChangeSelect: () => void;
    showTitle: boolean;
    showNewOrganisationOption?: boolean;
    trialText: React.ReactNode;
    showTrialOption?: boolean;
}

const OtherSubscriptionOptions: FC<OtherSubscriptionOptionsProps> = (props) => {
    const { option, setOption, onChangeSelect, showTitle, showNewOrganisationOption, trialText, showTrialOption } =
        props;

    return (
        <div>
            {showTitle && <div className={bem('subscriptions-other-options')}>{messages.otherOptions}</div>}

            <RadioGroupEditor
                value={option}
                onChange={(value) => {
                    setOption(value);
                    onChangeSelect();
                }}
            >
                {showNewOrganisationOption && (
                    <RadioGroupEditor.Item id={SelectSubscriptionOption.NewSubscription}>
                        {messages.newSubscriptionOption}
                    </RadioGroupEditor.Item>
                )}

                {showTrialOption && (
                    <RadioGroupEditor.Item id={SelectSubscriptionOption.Trial}>{trialText}</RadioGroupEditor.Item>
                )}
            </RadioGroupEditor>
        </div>
    );
};

export default OtherSubscriptionOptions;
