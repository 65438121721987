import { Popup } from '@approvalmax/ui';
import { GlobalLoadingBar } from 'modules/page';
import { font } from 'modules/styles';
import { FC, memo, useMemo, useState } from 'react';
import { amplitudeService } from 'services/amplitude';
import styled from 'styled-components';

import { LineItemsTable } from '../../../components/lineItemsSection/LineItemsTable';
import BudgetImpactDetailsPopup from './components/BudgetImpactDetailsPopup/BudgetImpactDetailsPopup';
import CollapsibleSection from './components/CollapsibleSection/CollapsibleSection';
import { getColumnDefinitions } from './XeroBudgetingSection.config';
import { useGetBudgets } from './XeroBudgetingSection.hooks';
import { messages } from './XeroBudgetingSection.messages';
import { getShowBudgeting } from './XeroBudgetingSection.selectors';
import { BudgetImpactsLineItem, XeroBudgetingSectionProps } from './XeroBudgetingSection.types';

const Description = styled.div`
    ${font(12, '#565656')}
`;

const XeroBudgetingSection: FC<XeroBudgetingSectionProps> = memo((props) => {
    const { request } = props;
    const showBudgeting = getShowBudgeting(request);
    const [budgetImpactDetails, setBudgetImpactDetails] = useState<BudgetImpactsLineItem | null>(null);

    const closeBudgetImpactDetailsPopup = () => {
        setBudgetImpactDetails(null);
    };

    const openBudgetImpactDetailsPopup = (details: BudgetImpactsLineItem) => {
        amplitudeService.sendData('requests: open budget details');
        setBudgetImpactDetails(details);
    };

    const { data, isInitialLoading } = useGetBudgets(request);

    const columnDefinitions = useMemo(
        () => getColumnDefinitions(data?.allCategories || [], data?.currency || '', openBudgetImpactDetailsPopup),
        [data?.allCategories, data?.currency]
    );

    if (isInitialLoading) return <GlobalLoadingBar isLoading={true} />;

    if (!data || data.lineItems.length === 0 || !showBudgeting) return null;

    return (
        <>
            <CollapsibleSection title='Budget' defaultExpanded>
                <Description>{messages.description}</Description>

                <LineItemsTable columnDefinitions={columnDefinitions} lineItems={data.lineItems} />
            </CollapsibleSection>

            <Popup isOpen={Boolean(budgetImpactDetails)} onRequestClose={closeBudgetImpactDetailsPopup}>
                <BudgetImpactDetailsPopup data={budgetImpactDetails!} currency={data?.currency} />
            </Popup>
        </>
    );
});

export default XeroBudgetingSection;
