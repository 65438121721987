/**
 * Add `px` to the value if it is a number
 */
export const addPx = (value?: number | string) => {
    if (typeof value === 'number') {
        return `${value}px`;
    }

    return value;
};
