import { miscHelpers } from '@approvalmax/utils';
import { GetFido2CredentialOptions } from 'shared/data/webApp/v2/queries';

import { messages } from './useCreatePasskey.messages';

export const getPublicKeyCreationOptions = (credentialOptions: GetFido2CredentialOptions['response']) => {
    const { relayingParty, timeoutInMilliseconds, attestationConveyance, ...restCredentialOptions } = credentialOptions;

    return {
        ...restCredentialOptions,
        attestation: attestationConveyance,
        authenticatorSelection: {
            authenticatorAttachment: credentialOptions.authenticatorSelection?.authenticatorAttachment ?? undefined,
            requireResidentKey: credentialOptions.authenticatorSelection?.requireResidentKey ?? undefined,
            residentKey: credentialOptions.authenticatorSelection?.requireResidentKey ? 'required' : 'preferred',
            userVerification: credentialOptions.authenticatorSelection?.userVerification ?? undefined,
        },
        rp: relayingParty,
        challenge: miscHelpers.base64ToBytes(credentialOptions.challenge),
        user: {
            ...credentialOptions.user,
            id: miscHelpers.base64ToBytes(credentialOptions.user.id),
        },
        pubKeyCredParams: credentialOptions.pubKeyCredParams.map((param) => ({
            type: param.credentialType,
            alg: param.algorithm,
        })),
        excludeCredentials: credentialOptions.excludeCredentials?.map((credential) => ({
            ...credential,
            type: credential.type,
            id: miscHelpers.base64ToBytes(credential.id),
            transports: (credential.transports as AuthenticatorTransport[]) ?? undefined,
        })),
        extensions: {
            credProps: true,
        },
        timeout: timeoutInMilliseconds,
    };
};

/**
 * This function can cause an error. Please take a look the article below to save your time
 * @link https://approvalmax.atlassian.net/wiki/spaces/AP/pages/3240099890/Working+with+Passkeys
 *
 * To make long story short - passkeys should use a domain that comes from backend, and you need:
 *  - to add record to the HOSTS file
 *  - to run webpack on 443 port (sudo)
 */
export const createPublicKey = async (credentialOptions: GetFido2CredentialOptions['response']) => {
    const publicKeyOptions = getPublicKeyCreationOptions(credentialOptions);

    const credential = (await navigator.credentials.create({
        publicKey: publicKeyOptions as PublicKeyCredentialCreationOptions,
    })) as PublicKeyCredential | null;

    if (!credential) {
        throw new Error('Failed to create public key');
    }

    return credential;
};

export const getAttestationResponseTransfer = (
    credential: PublicKeyCredential,
    credentialOptions: GetFido2CredentialOptions['response']
) => {
    const response = credential.response as AuthenticatorAttestationResponse;

    return {
        id: credential.id,
        optionsId: credentialOptions.id,
        type: credential.type,
        rawId: miscHelpers.arrayBufferToBase64(credential.rawId),
        response: {
            attestationObject: miscHelpers.arrayBufferToBase64(response.attestationObject),
            clientDataJson: miscHelpers.arrayBufferToBase64(response.clientDataJSON),
        },
        extensions: {
            isAppIdExtension: false,
            isAuthenticatorSelectionEnabled: false,
        },
    };
};

export const mapErrorTextsToHumanReadable = (errorText: string) => {
    switch (errorText) {
        case 'At least one credential matches an entry of the excludeCredentials list in the platform attached authenticator.':
        case 'The user attempted to register an authenticator that contains one of the credentials already registered with the relying party.':
            return messages.alreadyAddedError;

        default:
            return messages.errorText;
    }
};
