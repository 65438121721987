import { numberHelpers } from '@approvalmax/utils';
import { domain } from 'modules/data';
import { useNetSuiteExpenseReportExpenseLinesFieldSettings } from 'shared/hooks';

import {
    isFieldMandatory,
    isFieldVisible,
    isMandatoryFieldEmpty,
} from '../../../NetSuiteRequestForm/NetSuiteRequestForm.helpers';
import { checkExpenseLineIsEmpty } from '../checkExpenseLineIsEmpty/checkExpenseLineIsEmpty';

export const checkExpenseLineIsInvalid = (
    expenseLine: domain.NetSuiteExpenseLineExpenseReport,
    fieldsSettings: ReturnType<typeof useNetSuiteExpenseReportExpenseLinesFieldSettings>
) => {
    if (checkExpenseLineIsEmpty(expenseLine)) {
        return true;
    }

    const { isNumber } = numberHelpers;

    if (
        isMandatoryFieldEmpty(expenseLine.category, fieldsSettings.category) ||
        (!expenseLine.expenseAccount && isFieldVisible(fieldsSettings.account))
    ) {
        return true;
    }

    const isInvalidAccount = !expenseLine.category
        ? !expenseLine.expenseAccount && isFieldVisible(fieldsSettings.account)
        : false;

    if (isInvalidAccount) {
        return true;
    }

    if (!isNumber(expenseLine.amount)) {
        return true;
    }

    if (!isNumber(expenseLine.foreignAmount) && isFieldVisible(fieldsSettings.foreignAmount)) {
        return true;
    }

    if (
        isMandatoryFieldEmpty(expenseLine.expenseDate, fieldsSettings.date) ||
        isMandatoryFieldEmpty(expenseLine.expenseAccount, fieldsSettings.account) ||
        isMandatoryFieldEmpty(expenseLine.currency, fieldsSettings.currency) ||
        (isFieldMandatory(fieldsSettings.exchangeRate) && !fieldsSettings.exchangeRate) ||
        isMandatoryFieldEmpty(expenseLine.taxCode, fieldsSettings.taxCode) ||
        (isFieldMandatory(fieldsSettings.taxRate) && !isNumber(expenseLine.taxRate)) ||
        (isFieldMandatory(fieldsSettings.taxAmount) && !isNumber(expenseLine.taxAmount)) ||
        ((isFieldMandatory(fieldsSettings.grossAmount) || expenseLine.taxCode) && !isNumber(expenseLine.grossAmount)) ||
        isMandatoryFieldEmpty(expenseLine.memo, fieldsSettings.memo) ||
        isMandatoryFieldEmpty(expenseLine.department, fieldsSettings.department) ||
        isMandatoryFieldEmpty(expenseLine.class, fieldsSettings.class) ||
        isMandatoryFieldEmpty(expenseLine.location, fieldsSettings.location) ||
        isMandatoryFieldEmpty(expenseLine.customer, fieldsSettings.customer) ||
        isMandatoryFieldEmpty(expenseLine.attachment, fieldsSettings.attachment)
    ) {
        return true;
    }

    return false;
};
