import { CircleFilledIcon, CopyIcon } from '@approvalmax/ui';
import { Box, Button, Collapse, Flex, Link, Text, toast, Tooltip } from '@approvalmax/ui/src/components';
import { domHelpers } from '@approvalmax/utils';
import { useSetAtom } from 'jotai';
import { selectors } from 'modules/common';
import { domain, State } from 'modules/data';
import { useSelector } from 'modules/react-redux';
import { FC, memo, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useCopyToClipboard } from 'react-use';
import { amplitudeService } from 'services/amplitude';

import {
    addEmailExternalSubmitter,
    addTempUser,
    FETCH_WORKFLOW_WITH_VERSION,
    openInviteUsersPopup,
    openSettingsPopup,
    removeEmailExternalSubmitter,
    saveTemplate,
} from '../../actions';
import { getAllCommands } from '../../selectors/cardCommandSelectors';
import { getActiveTemplate, isPageReadonly, showCardValidationErrors } from '../../selectors/pageSelectors';
import { getTemplateEmailExternalSubmitter } from '../../selectors/templateSelectors';
import { ExpandedTemplateUser } from '../../types/selectors';
import { addUserEvent, removeUserEvent } from '../../utils/events';
import AddUserButton from '../AddUserButton/AddUserButton';
import { StepUser } from '../StepUser/StepUser';
import { workflowSettingsPopupDefaultTabState } from '../WorkflowSettingsPopupContent/WorkflowSettingsPopupContent.states';
import { SettingTabs } from '../WorkflowSettingsPopupContent/WorkflowSettingsPopupContent.types';
import { learnMoreLink } from './ReceiveOverEmailSection.constants';
import { messages } from './ReceiveOverEmailSection.messages';
import { ReceiveOverEmailSectionProps } from './ReceiveOverEmailSection.types';

export const ReceiveOverEmailSection: FC<ReceiveOverEmailSectionProps> = memo((props) => {
    const { collapsed, onCollapse, cacheId, description, descriptionEmpty, title } = props;

    const dispatch = useDispatch();
    const template = useSelector(getActiveTemplate)!;
    const company = useSelector((state) => selectors.company.getCompanyById(state, template.companyId));
    const readonly = useSelector(isPageReadonly);
    const emailExternalSubmitter = useSelector(getTemplateEmailExternalSubmitter);
    const showValidationErrors = useSelector(showCardValidationErrors);
    const team = useSelector((state: State) => selectors.company.getCompanyTeam(state, company));
    const activeTemplate = useSelector(getActiveTemplate)!;
    const commands = useSelector(getAllCommands);
    const isFetchingWorkflow = useSelector((state) =>
        selectors.ui.isOperationInProgress(state, FETCH_WORKFLOW_WITH_VERSION)
    );
    const [_copiedText, setCopiedText] = useCopyToClipboard();

    const invalid = showValidationErrors && Boolean(template.ocrEmailAddress) && !emailExternalSubmitter;

    const setWorkflowSettingsPopupDefaultTab = useSetAtom(workflowSettingsPopupDefaultTabState);

    const onAddTempUser = useCallback(
        (value: domain.User) => {
            dispatch(addTempUser(value));

            addUserEvent('requester', template.integrationCode, true);
        },
        [dispatch, template.integrationCode]
    );

    const onInviteUser = useCallback(
        (user: ExpandedTemplateUser) => {
            dispatch(openInviteUsersPopup([user.id]));
        },
        [dispatch]
    );

    const onAddEmailExternalSubmitter = useCallback(
        (user: domain.User) => {
            dispatch(addEmailExternalSubmitter(user.id));

            if (user.databaseId) {
                addUserEvent('requester', template.integrationCode);
            }
        },
        [dispatch, template.integrationCode]
    );
    const onCopyOcrEmailAddress = useCallback(() => {
        if (template.ocrEmailAddress) {
            setCopiedText(template.ocrEmailAddress);
            toast.success(messages.copiedToClipboard);
        }
    }, [setCopiedText, template.ocrEmailAddress]);
    const onRemoveEmailExternalSubmitter = useCallback(() => {
        dispatch(removeEmailExternalSubmitter());

        removeUserEvent('requester', template.integrationCode);
    }, [dispatch, template.integrationCode]);

    const openTemplateSettings = useCallback(() => {
        amplitudeService.sendData('workflow: open settings', {
            workflow: activeTemplate.integrationCode?.toLocaleLowerCase() || 'standalone',
        });

        setWorkflowSettingsPopupDefaultTab(SettingTabs.Capture);

        if (activeTemplate.isNew) {
            (dispatch(saveTemplate(activeTemplate)) as any).then(() => {
                dispatch(openSettingsPopup());
            });
        } else {
            dispatch(openSettingsPopup());
        }
    }, [activeTemplate, dispatch, setWorkflowSettingsPopupDefaultTab]);

    const collapseColor = useMemo(() => {
        if (readonly) return 'silver80';

        if (invalid) return 'red30';

        return undefined;
    }, [invalid, readonly]);

    const disabledSettings =
        isFetchingWorkflow ||
        commands.openTemplateSettings.disabled ||
        activeTemplate.isNew ||
        commands.openTemplateSettings.hidden;

    const initialStatusText =
        template.ocrRequestInitialStatus == domain.RequestStatusV2.Draft
            ? messages.requestDraftStatusText
            : messages.requestOnApprovalAndReviewStatusText;

    return (
        <Collapse
            name={messages.bubbleText}
            spacing='0 8'
            localStorageKey={cacheId}
            radius='xsmall'
            alignCollapseIcon='right'
            size='small'
            onCollapse={onCollapse}
            collapsed={collapsed && !invalid}
            bordered
            startIcon={
                template.ocrEmailAddress ? (
                    <CircleFilledIcon size={12} color={readonly ? 'silver100' : 'brandXero100'} />
                ) : undefined
            }
            headerContent={
                <>
                    {title && (
                        <Text font='body' fontSize='small' color='midnight100'>
                            {title}
                        </Text>
                    )}

                    <Text font='body' fontSize='small' color='midnight100'>
                        {emailExternalSubmitter ? description : descriptionEmpty || description}
                    </Text>

                    <Link
                        font='label'
                        fontSize='small'
                        href={learnMoreLink}
                        target='_blank'
                        rel='noopener noreferrer'
                        onClick={domHelpers.stopPropagation}
                    >
                        {messages.learnMore}
                    </Link>
                </>
            }
            color={collapseColor}
        >
            {template.ocrEmailAddress && (
                <Flex container direction='column' spacing='8'>
                    <Flex direction='column' spacing='0' wrap={false}>
                        <Text font='body' fontSize='small' color='midnight70' wrap={false}>
                            {messages.emailAddressLabel}
                        </Text>

                        <Button
                            noPadding
                            noUppercase
                            justifyContent='start'
                            size='small'
                            disabled={disabledSettings}
                            onClick={onCopyOcrEmailAddress}
                            title={template.ocrEmailAddress}
                            variant='text'
                            color='blue100'
                        >
                            <Text font='body'>
                                {`${template.ocrEmailAddress} `}

                                <CopyIcon size={12} />
                            </Text>
                        </Button>
                    </Flex>

                    {template.ocrRequestInitialStatus && (
                        <Flex direction='column' justifyContent='center' spacing='0' wrap={false}>
                            <Text font='body' fontSize='small' color='midnight70' wrap={false}>
                                {messages.requestStatusLabel}
                            </Text>

                            <Text fontSize='small' font='body'>
                                {initialStatusText}
                            </Text>
                        </Flex>
                    )}

                    {emailExternalSubmitter ? (
                        <Flex direction='column' spacing='4'>
                            <Text font='body' fontSize='small' color='midnight70' wrap={false}>
                                {'Default submitter:'}
                            </Text>

                            <StepUser
                                team={team}
                                user={emailExternalSubmitter}
                                onRemove={onRemoveEmailExternalSubmitter}
                                readonly={readonly}
                                onInvite={onInviteUser}
                                isCompanyMember={team.some((t) => t.id === emailExternalSubmitter.id)}
                            />
                        </Flex>
                    ) : (
                        <Flex height={40} spacing='8' alignItems='center'>
                            <Tooltip
                                open={invalid && !collapsed}
                                color='cobalt100'
                                activator={
                                    <AddUserButton
                                        users={team}
                                        disabled={readonly}
                                        onAddNew={onAddTempUser}
                                        onSelect={onAddEmailExternalSubmitter}
                                        type='step'
                                        title={messages.chooseSubmitter}
                                        invalid={invalid}
                                    />
                                }
                            >
                                {messages.chooseSubmitter}
                            </Tooltip>
                        </Flex>
                    )}
                </Flex>
            )}

            <Box spacing={template.ocrEmailAddress ? '4 0 4' : '4 0 8'}>
                <Button
                    fontWeight='regular'
                    size='xsmall'
                    color='blue80'
                    onClick={openTemplateSettings}
                    disabled={readonly}
                    variant='outline'
                >
                    {messages.setupButtonText}
                </Button>
            </Box>
        </Collapse>
    );
});

ReceiveOverEmailSection.displayName = 'ReceiveOverEmailSection';
