import { oauthHelpers } from '@approvalmax/utils';
import Cookies from 'js-cookie';
import { domain } from 'modules/data';

import {
    forceTrackingUsingKey,
    globalFeaturesKey,
    isTwoFaHardEnforcementKey,
    practiceInvitationDataKey,
    startupDataKey,
} from './constatnts';
import { getAMCookieOptions } from './helpers';

class StorageService {
    private _authenticated: boolean;
    private _isTwoFaHardEnforcement: boolean;
    private _globalFeatures: domain.GlobalFeature[];

    public constructor() {
        this._authenticated = this._loadAuthenticated();
        this._isTwoFaHardEnforcement = this._loadIsTwoFaHardEnforcement();
        this._globalFeatures = this._loadGlobalFeatures();
    }

    public getAuthenticatedKey(): string {
        return oauthHelpers.getAuthenticatedCookieKey(window.ApprovalMax.environment);
    }

    public setAuthenticated(authenticated: boolean) {
        const authenticatedKey = this.getAuthenticatedKey();

        this._authenticated = authenticated;

        if (authenticated) {
            Cookies.set(authenticatedKey, 'true', getAMCookieOptions());
        } else {
            /* login flags */
            // this._authData = null; // <--
            // we cannot remove this._authData as views might need it to render
            // e.g., the user rejected reloading the page by pressing NO in 'unsaved changes' popup
            // Note: consider login logic without a page reload.
            Cookies.remove(authenticatedKey, getAMCookieOptions());
            /* startup data */
            localStorage.removeItem(startupDataKey);
        }
    }

    public authenticated(): boolean {
        return this._authenticated;
    }

    public hasGlobalFeature(globalFeature: domain.GlobalFeature) {
        return this._globalFeatures.includes(globalFeature);
    }

    public getGlobalFeatures() {
        return this._globalFeatures;
    }

    private _loadAuthenticated(): boolean {
        return Cookies.get(this.getAuthenticatedKey()) === 'true';
    }

    private _loadGlobalFeatures(): domain.GlobalFeature[] {
        return Object.entries(JSON.parse(Cookies.get(globalFeaturesKey) || '{}')).reduce((m, [k, v]) => {
            if (v) {
                m.push(k as domain.GlobalFeature);
            }

            return m;
        }, [] as domain.GlobalFeature[]);
    }

    public setViewedPracticeInvitationKeys(ids: string[]) {
        localStorage.setItem(practiceInvitationDataKey, ids.toString());
    }

    public getViewedPracticeInvitationKeys() {
        return localStorage.getItem(practiceInvitationDataKey)?.split(',') || [];
    }

    public resetViewedPracticeInvitationKeys() {
        localStorage.setItem(practiceInvitationDataKey, '');
    }

    public getForceTrackingState() {
        return localStorage.getItem(forceTrackingUsingKey);
    }

    private _loadIsTwoFaHardEnforcement(): boolean {
        return this.getIsTwoFaHardEnforcement();
    }

    public setIsTwoFaHardEnforcement(isTwoFaHardEnforcement: boolean) {
        localStorage.setItem(isTwoFaHardEnforcementKey, isTwoFaHardEnforcement.toString());
    }

    public resetIsTwoFaHardEnforcement() {
        localStorage.removeItem(isTwoFaHardEnforcementKey);
    }

    public getIsTwoFaHardEnforcement() {
        return !!localStorage.getItem(isTwoFaHardEnforcementKey);
    }
}

export const storageService = new StorageService();
