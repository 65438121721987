import { componentHelpers, miscHelpers } from '@approvalmax/utils';
import { ElementType } from 'react';

import { Root } from './Text.styles';
import { TextProps } from './Text.types';

/**
 * The Text component allows you to create a customizable text block with a headline, label or body type.
 * By default, it's a regular body type text.
 */
export const Text = componentHelpers.genericMemo(
    <Component extends ElementType = 'div'>(props: TextProps<Component>) => {
        const {
            children,
            ellipsis,
            textAlign,
            fontWeight,
            font,
            fontSize = 'medium',
            color,
            spacing,
            as,
            wrap = true,
            hoverColor,
            wordBreak = 'break-word',
            whiteSpace = 'normal',
            uppercase,
            lineThrough,
            ...restProps
        } = props;

        return (
            <Root
                as={as ?? 'div'}
                $font={font}
                $fontSize={fontSize}
                $color={color}
                $spacing={miscHelpers.spacingPropToCss(spacing)}
                $textAlign={textAlign}
                $fontWeight={fontWeight}
                $ellipsis={ellipsis}
                $wrap={wrap}
                $hoverColor={hoverColor}
                $wordBreak={wordBreak}
                $whiteSpace={whiteSpace}
                $uppercase={uppercase}
                $lineThrough={lineThrough}
                {...restProps}
            >
                {children}
            </Root>
        );
    }
);

Text.displayName = 'Text';
