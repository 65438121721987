import { Popup } from '@approvalmax/ui';
import { errorHelpers, intl } from '@approvalmax/utils';
import { domain, State } from 'modules/data';
import { FailedListIcon, ToastRejectedIcon } from 'modules/sprites';
import { font } from 'modules/styles';
import { FC, memo } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import LineItemFooterTotal from '../../../../../components/lineItemsSection/LineItemFooterTotal';
import { LineItemsTable } from '../../../../../components/lineItemsSection/LineItemsTable';
import { getActiveRequest } from '../../../../../selectors/pageSelectors';
import { getXeroLineItemsTableDef } from '../../../../../selectors/xeroRequestSelectors';
import { BudgetImpactsLineItem } from '../../XeroBudgetingSection.types';
import { messages } from './BudgetImpactDetailsPopup.messages';
import BreakDownsSection from './components/BreakDownsSection/BreakDownsSection';

const StyledDefaultContent = styled(Popup.DefaultContent)`
    min-width: 900px;
    min-height: 400px;
`;

const PopupContent = styled.div`
    padding: 0 60px 0 80px;
    overflow: auto;
`;

const PopupTitle = styled.div`
    font-size: 12px;
`;

const BoldText = styled.span`
    font-weight: bolder;
`;

const FooterTotal = styled(LineItemFooterTotal)`
    display: flex;
    justify-content: flex-end;
`;

const Warning = styled.div`
    display: flex;
    align-items: center;
    padding: 10px 15px;
    margin-bottom: 15px;
    border: solid 1px #d6da92;
    background-color: #fff6e9;
`;

const WarningText = styled.span`
    margin-left: 10px;
    ${font(12, '#000')}
`;

const Overbudget = styled.div`
    display: flex;
    align-items: center;
    padding: 10px 15px;
    margin-bottom: 15px;
    border: solid 1px #ba3e21;
    background-color: #fef1ee;
`;

const OverbudgetText = styled.span`
    margin-left: 10px;
    ${font(12, '#a3341a')}
`;

interface BudgetImpactDetailsPopupProps {
    data: BudgetImpactsLineItem;
    currency: string;
}

const BudgetImpactDetailsPopup: FC<BudgetImpactDetailsPopupProps> = (props) => {
    const {
        data: { detailsLineItems, budgetName, budgetPeriod, account, totalBudgetImpact, status },
        currency,
    } = props;

    const request = useSelector(getActiveRequest);
    const tableDef = useSelector((state: State) => getXeroLineItemsTableDef(state, request));

    let messageBlock;

    switch (status) {
        case domain.BudgetCheckStatus.Warning:
            messageBlock = (
                <Warning>
                    <ToastRejectedIcon width={20} height={20} />

                    <WarningText>{messages.warningOfExceed}</WarningText>
                </Warning>
            );
            break;

        case domain.BudgetCheckStatus.Overbudget:
            messageBlock = (
                <Overbudget>
                    <FailedListIcon width={20} height={20} />

                    <OverbudgetText>{messages.overbudgetText}</OverbudgetText>
                </Overbudget>
            );
            break;

        case domain.BudgetCheckStatus.Ok:
            messageBlock = null;
            break;

        default:
            throw errorHelpers.assertNever(status);
    }

    return (
        <StyledDefaultContent title={messages.popupTitle({ budgetName })}>
            <PopupContent>
                {messageBlock}

                <PopupTitle>
                    {messages.description({
                        budgetName: <BoldText>“{budgetName}”</BoldText>,
                        account: <BoldText>“{account}”</BoldText>,
                        budgetPeriod: <BoldText>“{budgetPeriod}”</BoldText>,
                    })}
                </PopupTitle>

                <LineItemsTable columnDefinitions={tableDef} lineItems={detailsLineItems} />

                <FooterTotal
                    label={messages.totalBudgetImpact}
                    value={intl.formatCurrency(totalBudgetImpact, currency)}
                />

                <BreakDownsSection breakDowns={props.data.budgetBreakdowns} currency={currency} />
            </PopupContent>
        </StyledDefaultContent>
    );
};

export default memo(BudgetImpactDetailsPopup);
