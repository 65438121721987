import { componentHelpers } from '@approvalmax/utils';
import { useCallback } from 'react';

import { EmptyBoxImage } from '../../../../images';
import { Box } from '../../../Box/Box';
import { Grid } from '../../../Grid/Grid';
import { Paginator } from '../../../Paginator/Paginator';
import Table from '../../../Table/Table';
import { Text } from '../../../Text/Text';
import { BaseItem } from '../../Transfer.types';
import { PanelHeader } from '../PanelHeader/PanelHeader';
import { checkboxColumnCover } from './TransferPanel.constants';
import { messages } from './TransferPanel.messages';
import { TransferPanelProps } from './TransferPanel.types';

const TransferPanelBase = <Item extends BaseItem>(props: TransferPanelProps<Item>) => {
    const {
        checkedItems,
        onCheckedItemsChange,
        title,
        description,
        search,
        onSearchChange,
        onColumnSort,
        data,
        columnsDefinition,
        isLastPage,
        currentPageIndex,
        onPageChange,
        withPagination,
        isEmpty,
        progress,
    } = props;

    const handleRowClick = useCallback(
        (item: Item) => {
            const checked = new Set(checkedItems);

            if (checked.has(item.id)) {
                checked.delete(item.id);
            } else {
                checked.add(item.id);
            }

            onCheckedItemsChange?.([...checked]);
        },
        [checkedItems, onCheckedItemsChange]
    );

    return (
        <Box radius='small' borderColor='midnight50' bordered spacing='0 0 8'>
            <Grid gridTemplateRows='auto 1fr auto' height='100%'>
                <Box spacing='12 12 4'>
                    <PanelHeader
                        title={title}
                        description={description}
                        search={search}
                        onSearchChange={onSearchChange}
                    />
                </Box>

                <Grid gridTemplateRows='1fr auto' overflowY='auto'>
                    <Grid alignItems='start' overflowY='auto'>
                        <Table<Item>
                            columns={columnsDefinition}
                            data={data}
                            onSort={onColumnSort}
                            checkedItems={checkedItems}
                            onCheckedItemsChange={onCheckedItemsChange}
                            onRowClick={handleRowClick}
                            headerColor='white100'
                            checkboxColumnCover={checkboxColumnCover}
                            hideBorderLastRow
                            hideHeaderBottomBorder
                            stickyHeader
                            progress={progress}
                            progressRowCount={5}
                        />

                        {isEmpty && !progress && (
                            <Grid justifyItems='center' gap={24}>
                                <EmptyBoxImage height={100} width={100} />

                                <Text font='headline' fontSize='small' fontWeight='medium' color='midnight80'>
                                    {search ? messages.emptySearchMessage : messages.empty}
                                </Text>
                            </Grid>
                        )}
                    </Grid>

                    {withPagination && (
                        <Grid justifyContent='center' padding='8 0 0'>
                            <Paginator
                                currentPageIndex={currentPageIndex}
                                isLastPage={isLastPage}
                                onPageChange={onPageChange}
                            />
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </Box>
    );
};

TransferPanelBase.displayName = 'TransferPanel';

export const TransferPanel = componentHelpers.genericMemo(TransferPanelBase);
