import { ErrorCode } from '@approvalmax/types';
import { browserHelpers, errorHelpers } from '@approvalmax/utils';
import { factories, selectors } from 'modules/common';
import { backend, domain, Entities, schemas, State } from 'modules/data';
import { createAction, createAsyncAction, createErrorAction, ExtractActions } from 'modules/react-redux';
import { normalize } from 'normalizr';
import { api } from 'services/api';
import { gaService } from 'services/ga';
import { routingService } from 'services/routing';
import { storageService } from 'services/storage';
import { getDefaultPath, getPartnerUrl, getPath, Path } from 'urlBuilder';

import { encodeSignInUiState } from '../helpers';
import {
    MobileDeviceId,
    SignupModuleData,
    VerifyEmailCodeData,
    VerifyEmailLinkCodeData,
} from '../reducers/page/moduleReducer';
import { getModule } from '../selectors/pageSelectors';

export const SHOW_SIGNUP_MODULE = 'AUTH/SHOW_SIGNUP_MODULE';
export const showSignupModule = (
    email?: string,
    token?: string,
    app?: domain.SsoLoginApplication,
    innerPageId?: string
) =>
    createAction(SHOW_SIGNUP_MODULE, {
        email,
        token,
        app,
        innerPageId,
    });

export const SHOW_EMAIL_CONFIRMATION_MODULE = 'AUTH/SHOW_EMAIL_CONFIRMATION_MODULE';
export const showEmailConfirmationModule = (innerPageId?: string) =>
    createAction(SHOW_EMAIL_CONFIRMATION_MODULE, {
        innerPageId,
    });

export const SHOW_PASSWORD_RESET_MODULE = 'AUTH/SHOW_PASSWORD_RESET_MODULE';
export const showPasswordResetModule = (options: { email?: string; innerPageId?: string }) =>
    createAction(SHOW_PASSWORD_RESET_MODULE, {
        ...options,
    });

export const SHOW_MOBILE_INFO_MODULE = 'AUTH/SHOW_MOBILE_INFO_MODULE';

export const showMobileInfoModule = (innerPageId?: string) => {
    let deviceId = MobileDeviceId.Android;

    if (browserHelpers.isIphone()) {
        deviceId = MobileDeviceId.IPhone;
    }

    return createAction(SHOW_MOBILE_INFO_MODULE, {
        device: deviceId,
        innerPageId,
    });
};

export const SHOW_FIRST_TIME_SETUP_MODULE = 'AUTH/SHOW_FIRST_TIME_SETUP_MODULE';

export const showFirstTimeSetupModule = (innerPageId?: string) => {
    return createAction(SHOW_FIRST_TIME_SETUP_MODULE, {
        innerPageId,
    });
};

export const SHOW_PROFILE_SETUP_MODULE = 'AUTH/SHOW_PROFILE_SETUP_MODULE';
export const showProfileSetupModule = (innerPageId?: string) =>
    createAction(SHOW_PROFILE_SETUP_MODULE, {
        innerPageId,
    });

export const SIGNUP = 'AUTH/SIGNUP';
export const SIGNUP_RESPONSE = 'AUTH/SIGNUP_RESPONSE';
export const SIGNUP_FAILURE = 'AUTH/SIGNUP_FAILURE';
export const signup = (email: string, acceptedProductNewsEmails: boolean, app?: domain.SsoLoginApplication) =>
    createAsyncAction({
        request: (state: State) => {
            const module = getModule(state) as SignupModuleData;
            const oneTimeToken = module.email === email ? module.token : undefined;
            const name = selectors.user.expandUser(factories.user.createUser(email)).displayName;

            return createAction(SIGNUP, {
                email,
                name,
                oneTimeToken,
                acceptedProductNewsEmails,
                app,
            });
        },

        response: async (request) => {
            const response = await api.auth.register({
                email: request.email,
                uiState: encodeSignInUiState({
                    app: request.app,
                }),
                sendConfirmationLink: true,
                timeZoneForEmail: window.ApprovalMax.userTimeZone,
                acceptedProductNewsEmails: request.acceptedProductNewsEmails,
                oneTimeToken: request.oneTimeToken,
                isMobileFlow: browserHelpers.isMobile(),
            } as backend.transfers.AuthRegisterTransfer);

            return createAction(SIGNUP_RESPONSE, {
                request,
                raw: response,
            });
        },

        failure: (error) => createErrorAction(SIGNUP_FAILURE, error, {}),

        willDispatchResponse: async (request) => {
            await gaService.sendEvent('sign_up', {
                method: 'direct',
            });

            if (request.oneTimeToken) {
                storageService.setAuthenticated(true);
                routingService.reloadToDefaultPath();
            }
        },
    });

export const RESET_PASSWORD = 'AUTH/RESET_PASSWORD';
export const RESET_PASSWORD_RESPONSE = 'AUTH/RESET_PASSWORD_RESPONSE';
export const RESET_PASSWORD_FAILURE = 'AUTH/RESET_PASSWORD_FAILURE';
export const resetPassword = (email: string) =>
    createAsyncAction({
        request: () => createAction(RESET_PASSWORD, {}),

        response: async (request) => {
            await api.auth.resetPwd({
                email,
                sendConfirmationLink: true,
            });

            return createAction(RESET_PASSWORD_RESPONSE, {
                request,
            });
        },

        failure: (error) => createErrorAction(RESET_PASSWORD_FAILURE, error, {}),
    });

export const SHOW_VERIFY_EMAIL_MODULE = 'AUTH/SHOW_VERIFY_EMAIL_MODULE';
export const showVerifyEmailModule = (data: VerifyEmailCodeData | VerifyEmailLinkCodeData) =>
    createAction(SHOW_VERIFY_EMAIL_MODULE, {
        data,
        innerPageId: data.innerPageId,
    });

export const SHOW_PASSWORD_RESET_FINAL_STEP_MODULE = 'AUTH/SHOW_PASSWORD_RESET_FINAL_STEP_MODULE';
export const showPasswordResetFinalStepModule = (options: {
    stateId: string;
    tfaEnabled: boolean;
    postAction?: string;
    innerPageId?: string;
    isTFAAlternativeEmailEnabled: boolean;
    isTFABackupCodesEnabled: boolean;
}) =>
    createAction(SHOW_PASSWORD_RESET_FINAL_STEP_MODULE, {
        ...options,
    });

export const SHOW_RECEIPT_BANK_AUTH_MODULE = 'AUTH/SHOW_RECEIPT_BANK_AUTH_MODULE';
export const showReceiptBankAuthModule = (options: { callbackUrl: string; innerPageId?: string }) =>
    createAction(SHOW_RECEIPT_BANK_AUTH_MODULE, {
        ...options,
    });

export const SHOW_SLACK_AUTH_MODULE = 'AUTH/SHOW_SLACK_AUTH_MODULE';
export const showSlackAuthModule = (options: { innerPageId?: string }) =>
    createAction(SHOW_SLACK_AUTH_MODULE, {
        ...options,
    });

export const VERIFY_EMAIL = 'AUTH/VERIFY_EMAIL';
export const VERIFY_EMAIL_RESPONSE = 'AUTH/VERIFY_EMAIL_RESPONSE';
export const VERIFY_EMAIL_FAILURE = 'AUTH/VERIFY_EMAIL_FAILURE';

interface CodeVerifyEmailOptions {
    email: string;
    code: string;
    app?: domain.SsoLoginApplication;
    postAction?: string;
}

function isCodeVerifyEmailOptions(params: any): params is CodeVerifyEmailOptions {
    return params.email !== undefined;
}

interface LinkCodeVerifyEmailOptions {
    linkCode: string;
    app?: domain.SsoLoginApplication;
    postAction?: string;
}

export const verifyEmail = (options: CodeVerifyEmailOptions | LinkCodeVerifyEmailOptions) => {
    storageService.setAuthenticated(false);

    let apiOptions: any = {};

    apiOptions.eternal = true;

    if (isCodeVerifyEmailOptions(options)) {
        apiOptions.email = options.email;
        apiOptions.verificationCode = options.code;
    } else {
        apiOptions.linkCode = options.linkCode;
    }

    return createAsyncAction({
        request: () =>
            createAction(VERIFY_EMAIL, {
                ...options,
            }),

        response: async (request) => {
            const response = await api.auth.verify(apiOptions);
            const context = await api.companies.getUserContext({});

            let entities: Entities = normalize(response, { User: schemas.userSchema }).entities as any;

            const user = Object.values(entities.users)[0];

            return createAction(VERIFY_EMAIL_RESPONSE, {
                request,
                user,
                context,
            });
        },

        failure: (error) => createErrorAction(VERIFY_EMAIL_FAILURE, error),

        willDispatchResponse: (
            request,
            response: { user: domain.User; context?: backend.CompaniesUserCompaniesContextAnswer }
        ) => {
            storageService.setAuthenticated(true);

            if (options.app) {
                let appUrl;

                switch (options.app) {
                    case domain.SsoLoginApplication.Account:
                        routingService.reloadToUrl(getDefaultPath());

                        return;

                    case domain.SsoLoginApplication.Partner:
                        if (!response.context?.PracticeInvitations.length && !response.context?.Invitations?.length) {
                            appUrl = getPartnerUrl();
                        } else {
                            routingService.reloadToUrl(getDefaultPath());

                            return;
                        }

                        break;

                    default:
                        throw errorHelpers.assertNever(options.app);
                }

                if (options.postAction) {
                    appUrl += options.postAction.startsWith('/') ? options.postAction : `/${options.postAction}`;
                }

                routingService.reloadToUrl(appUrl);
            } else {
                routingService.reloadToUrl(options.postAction || getDefaultPath());
            }
        },

        willDispatchError: (request, error) => {
            const errorCode = errorHelpers.getErrorCode(error);

            switch (errorCode) {
                case ErrorCode.E4002_WRONG_CONFIRMATION_CODE:
                    routingService.replace(
                        getPath(Path.login, {
                            workspace: options.app,
                            redirectPath: options.postAction,
                        })
                    );

                    return;

                case ErrorCode.E4081_CONFIRMATION_CODE_EXPIRED:
                    routingService.replace(
                        getPath(Path.login, {
                            workspace: options.app,
                            redirectPath: options.postAction,
                        })
                    );

                    return;

                default:
                    routingService.replace(
                        getPath(Path.login, {
                            workspace: options.app,
                            redirectPath: options.postAction,
                        })
                    );

                    return;
            }
        },
    });
};

export type Action = ExtractActions<
    | typeof resetPassword
    | typeof showEmailConfirmationModule
    | typeof showFirstTimeSetupModule
    | typeof showMobileInfoModule
    | typeof showPasswordResetFinalStepModule
    | typeof showPasswordResetModule
    | typeof showProfileSetupModule
    | typeof showReceiptBankAuthModule
    | typeof showSignupModule
    | typeof showSlackAuthModule
    | typeof showVerifyEmailModule
    | typeof signup
    | typeof verifyEmail
>;
