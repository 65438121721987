import { LoadingSpinner } from '@approvalmax/ui';
import { Box } from '@approvalmax/ui/src/components';
import { intl, mathService } from '@approvalmax/utils';
import { domain } from 'modules/data';
import { FC, memo, useMemo } from 'react';
import bemFactory from 'react-bem-factory';
import { useGetWorkflowVersionDocumentFields } from 'shared/data/webApp/v1';
import { useNetSuiteBillFieldSettings } from 'shared/hooks';

import FieldsSection from '../../../../../components/FieldsSection';
import ActivitySection from '../../../../card/ActivitySection';
import AddCommentSection from '../../../../card/AddCommentSection/AddCommentSection';
import AttachmentsSection from '../../../../card/AttachmentsSection';
import Toolbar from '../../../../card/Toolbar/Toolbar';
import { WorkflowSection } from '../../../../card/WorkflowSection';
import { getNetSuiteAmountText } from '../../NetSuiteRequestCard.helpers';
import { useDisplayCurrency, useGetExchangeInfo, useNetSuiteCustomFields } from '../../NetSuiteRequestCard.hooks';
import {
    CurrencySwitcherContainer,
    FieldsSectionWrap,
    Root,
    StyledField,
    StyledScrollableArea,
} from '../../NetSuiteRequestCard.styles';
import CurrencySwitcher from '../CurrencySwitcher/CurrencySwitcher';
import NetSuiteCustomFields from '../NetSuiteCustomFields/NetSuiteCustomFields';
import NetSuiteExpenses from '../NetSuiteExpenses/NetSuiteExpenses';
import NetSuiteItemLines from '../NetSuiteItemLines/NetSuiteItemLines';
import NetSuiteRequestFooter from '../NetSuiteRequestFooter/NetSuiteRequestFooter';
import { messages } from './RequestCardBill.messages';
import { RequestCardBillProps } from './RequestCardBill.types';

const qa = bemFactory.qa('netsuite-bill-fields-section');

export const RequestCardBill: FC<RequestCardBillProps> = memo((props) => {
    const { request, className, preloadedRequestDetails, readonly } = props;

    const { data: companyCustomFields } = useNetSuiteCustomFields(request.companyId);

    const { data: workflowVersionDocumentFields, isFetching: isFetchingGetWorkflowVersionDocumentFields } =
        useGetWorkflowVersionDocumentFields({
            query: {
                companyId: request.companyId,
                workflowVersionIds: [request.workflowVersionId],
            },
        });

    const details = request.details;

    const fieldSettings = useNetSuiteBillFieldSettings(workflowVersionDocumentFields?.data);

    const expenseLines = useMemo(() => {
        return [...details.expenseLines].sort((a, b) => a.lineNumber - b.lineNumber);
    }, [details.expenseLines]);

    const itemLines = useMemo(() => {
        return [...details.itemLines].sort((a, b) => a.lineNumber - b.lineNumber);
    }, [details.itemLines]);

    const { displayCurrency, onDisplayCurrencyChange } = useDisplayCurrency();
    const { exchangeRate } = useGetExchangeInfo(request.companyId, request.details.exchangeRate, displayCurrency?.id);

    const discountAmount = useMemo(() => {
        let value = details.discountAmount;

        if (typeof value !== 'number') {
            return null;
        }

        if (displayCurrency && displayCurrency?.isoCode !== request.currency && typeof exchangeRate === 'number') {
            value = mathService.multiply(value, exchangeRate);
            value = mathService.round(value, 2);
        }

        return intl.formatNumber(value, 'auto');
    }, [details.discountAmount, displayCurrency, exchangeRate, request.currency]);

    return (
        <Root className={className}>
            <Toolbar
                request={request}
                preloadedRequestDetails={preloadedRequestDetails}
                showVatRegistration={fieldSettings.vatRegistration !== domain.DocumentFieldState.Hidden}
                amountText={getNetSuiteAmountText(
                    request.amount,
                    exchangeRate,
                    request.currency || '',
                    displayCurrency?.isoCode
                )}
                readonly={readonly}
            />

            <StyledScrollableArea scrollResetKey={request.id}>
                {isFetchingGetWorkflowVersionDocumentFields ? (
                    <Box spacing='20'>
                        <LoadingSpinner />
                    </Box>
                ) : (
                    <>
                        <FieldsSectionWrap>
                            {fieldSettings.reference !== domain.DocumentFieldState.Hidden && (
                                <StyledField
                                    qa={qa('reference')}
                                    title={messages.nsReferenceFieldText}
                                    valueType={FieldsSection.Field.ValueType.String}
                                    value={details.reference}
                                    hideIfEmpty
                                />
                            )}

                            <StyledField
                                qa={qa('transaction-number')}
                                title={messages.nsTransactionNumberFieldText}
                                valueType={FieldsSection.Field.ValueType.String}
                                value={details.transactionNumber}
                                hideIfEmpty
                            />

                            {fieldSettings.terms !== domain.DocumentFieldState.Hidden && (
                                <StyledField
                                    qa={qa('terms')}
                                    title={messages.nsTermsFieldText}
                                    valueType={FieldsSection.Field.ValueType.String}
                                    value={details.terms}
                                    hideIfEmpty
                                />
                            )}

                            <StyledField
                                qa={qa('date')}
                                title={messages.nsDateFieldText}
                                valueType={FieldsSection.Field.ValueType.Date}
                                value={details.transactionDate}
                            />

                            {fieldSettings.dueDate !== domain.DocumentFieldState.Hidden && (
                                <StyledField
                                    qa={qa('due-date')}
                                    title={messages.nsDueDateFieldText}
                                    valueType={FieldsSection.Field.ValueType.Date}
                                    value={details.dueDate}
                                    hideIfEmpty
                                />
                            )}

                            {fieldSettings.discountAmount !== domain.DocumentFieldState.Hidden && (
                                <StyledField
                                    qa={qa('discount-amount')}
                                    title={messages.nsDiscountAmountFieldText}
                                    valueType={FieldsSection.Field.ValueType.String}
                                    value={discountAmount}
                                    hideIfEmpty
                                />
                            )}

                            {fieldSettings.discountDate !== domain.DocumentFieldState.Hidden && (
                                <StyledField
                                    qa={qa('discount-date')}
                                    title={messages.nsDiscountDateFieldText}
                                    valueType={FieldsSection.Field.ValueType.Date}
                                    value={details.discountDate}
                                    hideIfEmpty
                                />
                            )}

                            {fieldSettings.paymentHold !== domain.DocumentFieldState.Hidden && (
                                <StyledField
                                    qa={qa('is-payment-hold')}
                                    title={messages.nsIsPaymentHoldFieldText}
                                    valueType={FieldsSection.Field.ValueType.String}
                                    value={details.isPaymentHold ? messages.paymentHoldPositiveValue : null}
                                    hideIfEmpty
                                />
                            )}

                            <StyledField
                                qa={qa('posting-period')}
                                title={messages.nsPostingPeriodFieldText}
                                valueType={FieldsSection.Field.ValueType.String}
                                value={details.postingPeriod}
                                hideIfEmpty
                            />

                            {fieldSettings.account !== domain.DocumentFieldState.Hidden && (
                                <StyledField
                                    qa={qa('account')}
                                    title={messages.nsAccountFieldText}
                                    valueType={FieldsSection.Field.ValueType.String}
                                    value={details.account}
                                    hideIfEmpty
                                />
                            )}

                            {fieldSettings.department !== domain.DocumentFieldState.Hidden && (
                                <StyledField
                                    qa={qa('department')}
                                    title={messages.nsDepartmentFieldText}
                                    valueType={FieldsSection.Field.ValueType.String}
                                    value={details.department}
                                    hideIfEmpty
                                />
                            )}

                            {fieldSettings.class !== domain.DocumentFieldState.Hidden && (
                                <StyledField
                                    qa={qa('class')}
                                    title={messages.nsClassFieldText}
                                    valueType={FieldsSection.Field.ValueType.String}
                                    value={details.class}
                                    hideIfEmpty
                                />
                            )}

                            {fieldSettings.location !== domain.DocumentFieldState.Hidden && (
                                <StyledField
                                    qa={qa('location')}
                                    title={messages.nsLocationFieldText}
                                    valueType={FieldsSection.Field.ValueType.String}
                                    value={details.location}
                                    hideIfEmpty
                                />
                            )}

                            {fieldSettings.memo !== domain.DocumentFieldState.Hidden && (
                                <StyledField
                                    qa={qa('memo')}
                                    title={messages.nsMemoFieldText}
                                    valueType={FieldsSection.Field.ValueType.String}
                                    value={details.memo}
                                    hideIfEmpty
                                />
                            )}

                            <NetSuiteCustomFields
                                customFields={details.customFields}
                                companyCustomFields={companyCustomFields?.Fields ?? []}
                            />
                        </FieldsSectionWrap>

                        <NetSuiteExpenses
                            expenseLines={expenseLines}
                            companyCustomFields={companyCustomFields?.Fields ?? []}
                            integrationCode={request.integrationCode}
                            documentFields={workflowVersionDocumentFields?.data}
                            exchangeRate={
                                displayCurrency && displayCurrency?.isoCode !== request.currency && exchangeRate !== 1
                                    ? exchangeRate
                                    : null
                            }
                        />

                        <NetSuiteItemLines
                            itemLines={itemLines}
                            companyCustomFields={companyCustomFields?.Fields ?? []}
                            integrationCode={request.integrationCode}
                            documentFields={workflowVersionDocumentFields?.data}
                            exchangeRate={
                                displayCurrency && displayCurrency?.isoCode !== request.currency && exchangeRate !== 1
                                    ? exchangeRate
                                    : null
                            }
                        />

                        {typeof exchangeRate === 'number' && (
                            <CurrencySwitcherContainer>
                                <CurrencySwitcher
                                    request={request}
                                    selectedCurrency={displayCurrency}
                                    requestCurrency={request.details.currency}
                                    onCurrencyChange={onDisplayCurrencyChange}
                                    companyId={request.companyId}
                                />
                            </CurrencySwitcherContainer>
                        )}
                    </>
                )}

                <NetSuiteRequestFooter
                    request={request}
                    displayCurrency={displayCurrency}
                    exchangeRate={exchangeRate}
                />

                <AttachmentsSection request={request} />

                <WorkflowSection request={request} readonly={readonly} />

                <ActivitySection request={request} />

                {!request.flags.status.isDraft && !readonly && <AddCommentSection request={request} />}
            </StyledScrollableArea>
        </Root>
    );
});

RequestCardBill.displayName = 'RequestCardBill';
