import { enabledByParams, RequestParams, RequestQueryOptions, useInvalidateQuery } from '@approvalmax/data';
import { useGet } from 'shared/data';

import { reportsApiPaths } from '../paths';
import { GetReportSchedule } from './useGetReportSchedule.types';

export const useGetReportSchedule = (
    params?: RequestParams<GetReportSchedule>,
    queryOptions?: RequestQueryOptions<GetReportSchedule>
) => {
    return useGet(reportsApiPaths.reportSchedule, {
        params,
        queryOptions: {
            ...queryOptions,
            enabled: enabledByParams<GetReportSchedule['params']['query']>(
                {
                    reportId: params?.query?.reportId,
                    companyId: params?.query?.companyId,
                    type: params?.query?.type,
                },
                queryOptions
            ),
        },
        apiVersion: 'v1',
    });
};

export const useInvalidateGetReportSchedule = () => {
    return useInvalidateQuery<RequestParams<GetReportSchedule> | void>(reportsApiPaths.reportSchedule);
};
