import { RequestMutationOptions } from '@approvalmax/data';
import { useMutate } from 'shared/data';

import { paymentServicesApiPaths } from '../paths';
import { CreateBeneficiaryRequest } from './useCreateBeneficiary.types';

export const useCreateBeneficiary = (mutationOptions?: RequestMutationOptions<CreateBeneficiaryRequest>) => {
    return useMutate(paymentServicesApiPaths.createBeneficiary, {
        mutationOptions,
        mapToCamelCase: true,
        apiVersion: 'v1',
    });
};
