import { AlertIcon } from '@approvalmax/ui';
import { Alert, Box, Button, Flex, Link, List, Popup, Table, Text } from '@approvalmax/ui/src/components';
import { integrationActions } from 'modules/integration';
import { useDispatch } from 'modules/react-redux';
import { FC, memo, ReactNode, useCallback } from 'react';
import { useMount, useToggle } from 'react-use';

import { MigrationFromReviewerV1ClosingPopupContent } from './components/MigrationFromReviewerV1ClosingPopupContent/MigrationFromReviewerV1ClosingPopupContent';
import {
    columnsDefinition,
    tableData,
    whatIsTheOldReviewFunctionalityUrl,
} from './MigrationFromReviewerV1PopupContent.constants';
import { useMigrate } from './MigrationFromReviewerV1PopupContent.hooks';
import { messages } from './MigrationFromReviewerV1PopupContent.messages';
import { MigrationFromReviewerV1PopupContentProps } from './MigrationFromReviewerV1PopupContent.types';

export const MigrationFromReviewerV1PopupContent: FC<MigrationFromReviewerV1PopupContentProps> = memo((props) => {
    const { onClose, onDone, template } = props;

    const dispatch = useDispatch();
    const [isFetchingFields, setIsFetchingFields] = useToggle(true);
    const [isClosePopupOpen, setIsClosePopupOpen] = useToggle(false);
    const { migrate, reviewersToMigrate } = useMigrate(template);

    useMount(() => {
        dispatch(
            integrationActions.loadAllCompanyFields({
                companyId: template.companyId,
                callback: () => setIsFetchingFields(false),
            })
        );
    });

    const handleMigrate = useCallback(async () => {
        try {
            await migrate();
            onDone();
        } catch {
            onClose();
        }
    }, [migrate, onDone, onClose]);

    return (
        <>
            <Popup.Header title={messages.title} />

            <Popup.Body>
                <Flex spacing='40' wrap={false} direction='column'>
                    {reviewersToMigrate.length === 0 ? (
                        <Flex spacing='16' wrap={false} direction='column'>
                            <Text font='body' fontSize='large'>
                                {messages.noReviewersDescription}
                            </Text>

                            <Alert size='small' invalid color='midnight20' startIcon={<AlertIcon />}>
                                {messages.warning}
                            </Alert>

                            <Text font='headline' fontSize='xxsmall' fontWeight='medium'>
                                {messages.doYouWantToReplaceOldReviewerByEditingOnApproval}
                            </Text>
                        </Flex>
                    ) : (
                        <>
                            <Flex spacing='16' wrap={false} direction='column'>
                                <Box spacing='0 52'>
                                    <Flex spacing='16'>
                                        <Text font='body' fontSize='medium'>
                                            {messages.discontinuedDescription({
                                                b: (chunks: ReactNode) => <b>{chunks}</b>,
                                            })}
                                        </Text>

                                        <Text font='body' fontSize='medium'>
                                            {messages.migrationDescription({
                                                url: (
                                                    <Link
                                                        font='body'
                                                        fontSize='medium'
                                                        href={whatIsTheOldReviewFunctionalityUrl}
                                                        external
                                                    >
                                                        {messages.migrationDescriptionLink}
                                                    </Link>
                                                ),
                                            })}
                                        </Text>

                                        <List listStyle='inside' spacing='8'>
                                            <List.Item>
                                                {messages.countReviewersDescription({
                                                    count: reviewersToMigrate.length,
                                                })}
                                            </List.Item>

                                            <List.Item>{messages.irreversibleDescription}</List.Item>
                                        </List>

                                        <Text font='body' fontSize='medium'>
                                            {messages.importantDescription({
                                                icon: <AlertIcon size='16' />,
                                                br: <br />,
                                            })}
                                        </Text>
                                    </Flex>
                                </Box>

                                <Table columns={columnsDefinition} data={tableData} />
                            </Flex>

                            <Alert size='small' invalid color='midnight20' startIcon={<AlertIcon />}>
                                {messages.warning}
                            </Alert>

                            <Text font='headline' fontSize='xxsmall' fontWeight='medium'>
                                {messages.doYouWantToReplaceOldReviewerByEditingOnApproval}
                            </Text>
                        </>
                    )}

                    <Flex spacing='24' wrap={false}>
                        <Button
                            color='midnight40'
                            size='large'
                            onClick={setIsClosePopupOpen}
                            disabled={isFetchingFields}
                            wrap={false}
                        >
                            {messages.no}
                        </Button>

                        <Button color='green80' size='large' block onClick={handleMigrate} disabled={isFetchingFields}>
                            {messages.yes}
                        </Button>
                    </Flex>
                </Flex>

                <Popup size='xsmall' open={isClosePopupOpen} onToggle={setIsClosePopupOpen} preventAutoClose>
                    <MigrationFromReviewerV1ClosingPopupContent onClose={onClose} />
                </Popup>
            </Popup.Body>
        </>
    );
});

MigrationFromReviewerV1PopupContent.displayName = 'MigrationFromReviewerV1PopupContent';
