import { ErrorCode } from '@approvalmax/types';
import { selectors } from 'modules/common';
import { domain } from 'modules/data';
import { useCurrentUser } from 'modules/utils';
import { reloadRequest } from 'pages/requestList/actions';
import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useNudgeApprovers } from 'shared/data/webApp/v2';
import { useCompanyBetaFeatures, useNudgeLocalStorage, useTimer } from 'shared/hooks';

import { getCooldownTimeDueToRequest, getCooldownTimeDueToSubmit } from './NudgeAction.helpers';

export const useNudgeAction = (request: selectors.types.ExpandedRequest) => {
    const dispatch = useDispatch();
    const isTimerHidden = getCooldownTimeDueToSubmit(request) > 0;

    const getCooldown = useNudgeCooldown(request);
    const approversToNudge = useGetApproversIdsToNudge(request);
    const { setCooldownTime } = useNudgeLocalStorage(request.companyId);

    const { timerFormatted: timer, startTimer: updateTimer, isTimerActive: isLocked } = useTimer();

    const { mutate: nudgeApprovers, isLoading: isLoadingNudgeApprovers } = useNudgeApprovers({
        onSuccess: () => {
            dispatch(reloadRequest(request.id, request.companyId));
        },
        onError: ({ error }) => {
            if (error && error.code === ErrorCode.LimitReached && 'timeLeftSeconds' in error) {
                startCountdown(request.id, error.timeLeftSeconds ?? 0);
            }
        },
    });

    const startCountdown = useCallback(
        (requestId: selectors.types.ExpandedRequest['id'], seconds: number = 0) => {
            setCooldownTime(requestId, seconds);

            updateTimer({
                seconds,
                onFinish: () => {
                    setCooldownTime(requestId, 0);
                },
            });
        },
        [setCooldownTime, updateTimer]
    );

    const handleNudge = useCallback(() => {
        nudgeApprovers({
            params: {
                path: {
                    companyId: request.companyId,
                    requestId: request.id,
                },
            },
        });
    }, [nudgeApprovers, request.companyId, request.id]);

    useEffect(() => {
        const cooldown = getCooldown();

        startCountdown(request.id, cooldown);
    }, [getCooldown, request.id, startCountdown]);

    return {
        approversToNudge,
        handleNudge,
        timer,
        isLocked,
        isTimerHidden,
        isLoading: isLoadingNudgeApprovers,
    };
};

export const useNudgeCooldown = (request: selectors.types.ExpandedRequest) => {
    const { getCooldownTime } = useNudgeLocalStorage(request.companyId);

    return useCallback(() => {
        const cooldownLocalStorage = getCooldownTime(request.id);
        const cooldownRequest = getCooldownTimeDueToRequest(request);

        return Math.max(cooldownLocalStorage, cooldownRequest, 0);
    }, [getCooldownTime, request]);
};

export const useCheckNudgeActionAvailable = (request: selectors.types.ExpandedRequest) => {
    const { isUserNudge } = useCompanyBetaFeatures(request.companyId);

    const isRequestNudgeable =
        request.statusV2 === domain.RequestStatusV2.OnReview ||
        request.statusV2 === domain.RequestStatusV2.OnApproval ||
        request.statusV2 === domain.RequestStatusV2.OnHold;

    return isUserNudge && isRequestNudgeable;
};

export const useGetApproversIdsToNudge = (request: selectors.types.ExpandedRequest) => {
    const me = useCurrentUser();

    const { activeStep, reviewStep } = request;

    return useMemo(() => {
        const isReviewStep = reviewStep && !reviewStep.isCompleted;

        if (isReviewStep) {
            const reviewers = reviewStep.reviewers ?? [];

            return reviewers.filter((reviewer) => reviewer.id !== me.id).map((reviewer) => reviewer.id);
        } else {
            const participants = activeStep?.participants ?? [];

            return participants
                .filter(
                    (participant) =>
                        participant.userId !== me.id &&
                        participant.decision === domain.RequestStepParticipantDecision.NoResponse
                )
                .map((participant) => participant.userId);
        }
    }, [activeStep?.participants, me.id, reviewStep]);
};
