import { arrayHelpers } from '@approvalmax/utils';
import take from 'lodash/take';
import uniqBy from 'lodash/uniqBy';
import { stateTree } from 'modules/data';
import React, { PureComponent, ReactElement } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import { loadItems } from '../actions';
import { DataProviderStorage, getCachedData, getStorage } from '../reducers';
import { DataItem } from '../typings/DataItem';

function withoutExcluded(data: DataItem[], excludedIds: string[]) {
    return data.filter((x) => !excludedIds.includes(x.id));
}

function limitPage(data: DataItem[], pageSize: number) {
    return take(data, pageSize);
}

interface InjectedProps {
    storage: DataProviderStorage;
}

interface OwnProps {
    cacheStorageId: string;
    pageSize: number;
    onLoad: (filterText: string | null, startFrom: number, count: number) => Promise<DataItem[]>;
    children: ReactElement | any;
    staticValues?: DataItem[];
}

type AsyncDataProviderProps = OwnProps & InjectedProps & typeof mapDispatchToProps;

interface OwnState {
    items: DataItem[];
}

const mapStateToProps = (state: stateTree.State, props: OwnProps) => {
    return {
        storage: getStorage(state.dataProviders, props.cacheStorageId),
    };
};

const mapDispatchToProps = {
    loadItems,
};

class AsyncDataProvider extends PureComponent<AsyncDataProviderProps, OwnState> {
    private _excludedIds: string[] = [];
    private _filterText: string | null = null;
    private _getItems: (props: AsyncDataProviderProps, excludedIds: string[], filterText: string | null) => DataItem[];

    constructor(props: AsyncDataProviderProps) {
        super(props);
        this._getItems = createSelector(
            (props: AsyncDataProviderProps, excludedIds: string[], filterText: string | null) =>
                getCachedData(props.storage, filterText).items,
            (props: AsyncDataProviderProps, excludedIds: string[]) => excludedIds,
            (props: AsyncDataProviderProps) => props.pageSize,
            (props: AsyncDataProviderProps, excludedIds: string[], filterText: string | null) =>
                (props.staticValues || []).filter((value) =>
                    value.text ? value.text.toUpperCase().includes(filterText?.toUpperCase() || '') : true
                ),
            (items: DataItem[], excludedIds: string[], pageSize: number, staticValues: DataItem[]) => {
                const filteredStaticValues = staticValues.filter(
                    (value) => !items.some((item) => item.id === value.id)
                );
                const result = limitPage(withoutExcluded([...filteredStaticValues, ...items], excludedIds), pageSize);

                return uniqBy(result, 'id');
            }
        );
        this.state = {
            items: this._getItems(props, this._excludedIds, this._filterText),
        };
    }

    public UNSAFE_componentWillReceiveProps(nextProps: AsyncDataProviderProps) {
        if (
            getCachedData(this.props.storage, this._filterText).items !==
            getCachedData(nextProps.storage, this._filterText).items
        ) {
            this.__updateStateItems(nextProps, this.props.cacheStorageId !== nextProps.cacheStorageId);
        }
    }

    public render() {
        const data = getCachedData(this.props.storage, this._filterText);

        return React.cloneElement(this.props.children, {
            loading: data.loading === null ? true : data.loading,
            hasMore: data.hasMore,
            items: this.state.items,
            onLoadItems: this._onLoadItems,
            dataMode: 'async',
        });
    }

    private _onLoadItems = (filterText: string, excludedIds: string[]) => {
        this._filterText = filterText;

        if (!arrayHelpers.shallowEqualArraysUnordered(this._excludedIds, excludedIds)) {
            this._excludedIds = excludedIds;
        }

        this.__updateStateItems(this.props);

        this.props.loadItems({
            onLoad: this.props.onLoad,
            filterText,
            excludedIds: this._excludedIds,
            pageSize: this.props.pageSize,
            cacheStorageId: this.props.cacheStorageId,
        });
    };

    private __updateStateItems(props: AsyncDataProviderProps, shouldResetItems?: boolean) {
        // Remove items if cacheStorageId has changed
        if (shouldResetItems) {
            this.setState({
                items: [],
            });
        }

        // We only update the items once they've been loaded, so the user won't see them disappear before new items arrive
        const nextData = getCachedData(props.storage, this._filterText);

        if (nextData.loading === false) {
            const newItems = this._getItems(props, this._excludedIds, this._filterText);

            if (newItems !== this.state.items) {
                this.setState({
                    items: newItems,
                });
            }
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AsyncDataProvider);
