import { atomHelpers } from '@approvalmax/utils';

import { AddonPromotionType } from './pageVisiblePromotions';

interface PromotionInfo {
    timesClosed: number;
    lastCloseDate: string | null;
    forcePopupHasBeenShown: boolean;
    forcePopupShownDate: string | null;
}

interface AddonPromotionInfoState {
    [AddonPromotionType.Pay]: PromotionInfo;
    [AddonPromotionType.Capture]: PromotionInfo;
}

export const defaultPromotionInfo: PromotionInfo = {
    timesClosed: 0,
    lastCloseDate: null,
    forcePopupHasBeenShown: false,
    forcePopupShownDate: null,
};

const defaultAddonPromotionInfoState: AddonPromotionInfoState = {
    [AddonPromotionType.Pay]: defaultPromotionInfo,
    [AddonPromotionType.Capture]: defaultPromotionInfo,
};

export const addonPromotionInfoState = atomHelpers.createAtomWithStorage<AddonPromotionInfoState>(
    'addonPromotionInfo',
    defaultAddonPromotionInfoState
);
