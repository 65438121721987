import { domain } from 'modules/data';
import { useMemo } from 'react';
import { findDocumentFieldState } from 'shared/helpers';

export const useNetSuiteExpenseReportExpenseLinesFieldSettings = (documentFields: domain.DocumentField[] | undefined) =>
    useMemo(
        () => ({
            account: findDocumentFieldState(documentFields, 'ExpensesAccount'),
            amount: findDocumentFieldState(documentFields, 'ExpensesAmount'),
            attachment: findDocumentFieldState(documentFields, 'ExpensesAttachment'),
            category: findDocumentFieldState(documentFields, 'ExpensesCategory'),
            class: findDocumentFieldState(documentFields, 'ExpensesClass'),
            currency: findDocumentFieldState(documentFields, 'ExpensesCurrency'),
            customer: findDocumentFieldState(documentFields, 'ExpensesCustomer'),
            date: findDocumentFieldState(documentFields, 'ExpensesDate'),
            department: findDocumentFieldState(documentFields, 'ExpensesDepartment'),
            exchangeRate: findDocumentFieldState(documentFields, 'ExpensesExchangeRate'),
            foreignAmount: findDocumentFieldState(documentFields, 'ExpensesForeignAmount'),
            grossAmount: findDocumentFieldState(documentFields, 'ExpensesGrossAmount'),
            billable: findDocumentFieldState(documentFields, 'ExpensesIsBillable'),
            corporateCreditCard: findDocumentFieldState(documentFields, 'ExpensesIsCorporateCreditCard'),
            nonReimbursable: findDocumentFieldState(documentFields, 'ExpensesIsNonReimbursable'),
            receipt: findDocumentFieldState(documentFields, 'ExpensesIsReceipt'),
            location: findDocumentFieldState(documentFields, 'ExpensesLocation'),
            memo: findDocumentFieldState(documentFields, 'ExpensesMemo'),
            refLineNumber: findDocumentFieldState(documentFields, 'ExpensesRefLineNumber'),
            taxAmount: findDocumentFieldState(documentFields, 'ExpensesTaxAmount'),
            taxCode: findDocumentFieldState(documentFields, 'ExpensesTaxCode'),
            taxRate: findDocumentFieldState(documentFields, 'ExpensesTaxRate'),
        }),
        [documentFields]
    );
