import { font, Popup } from '@approvalmax/ui';
import ImageXeroMatchingEmptyState from 'modules/components/ImageIcons/ImageXeroMatchingEmptyState';
import styled from 'styled-components';

import { messages } from './ChangeMatchingPopupEmpty.messages';

const DefaultContent = styled(Popup.DefaultContent)`
    width: 800px;
    min-width: 700px;
    min-height: 400px;
    height: 70%;
`;

const EmptyContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 30px;
`;

const EmptyText = styled.span`
    margin-top: 25px;
    ${font(16, '#a0a1a1', 'bold')}
`;

const ChangeMatchingPopupEmpty = () => {
    return (
        <DefaultContent title={messages.emptyStateTitle}>
            <EmptyContainer>
                <ImageXeroMatchingEmptyState />

                <EmptyText>{messages.emptyStateText}</EmptyText>
            </EmptyContainer>
        </DefaultContent>
    );
};

export default ChangeMatchingPopupEmpty;
