import { mixins, Mods, mods, theme } from '@approvalmax/theme';
import { StyledMods } from '@styled-kit/mods';
import styled from 'styled-components';

import { verticalAlign } from '../../Table.mixins';
import { BaseItem, ColumnDefinition, SortOrder } from '../../Table.types';

export const iconSize = 16;

export const Root = styled.div<
    StyledMods<
        Pick<ColumnDefinition<BaseItem>, 'sortable' | 'textAlign' | 'verticalAlign'> &
            Mods<'color', 'silver80' | 'white100' | 'transparent'> &
            Mods<'bordered'> & {
                spacing?: string;
            }
    >
>`
    background-color: ${theme.color.silver80};
    padding: ${({ $spacing }) => $spacing};
    text-align: start;
    font-weight: ${theme.fontWeight.medium};
    user-select: none;
    position: relative;

    ${mods.color.white100`
        background-color: ${theme.color.white100};
    `};

    ${mods.color.transparent`
        background-color: transparent;
    `};

    ${mods('sortable', true)`
        cursor: pointer;

        &:hover {
            color: ${theme.color.blue100};
        }
    `};

    ${mods.textAlign(mixins.textAlign)};

    ${mods('verticalAlign')(verticalAlign)};
`;

export const SortIcon = styled.div<StyledMods<Pick<ColumnDefinition<BaseItem>, 'sortOrder'>>>`
    width: ${iconSize}px;
    height: ${iconSize}px;
    transition: all ${theme.duration.medium}ms ease-in-out;
    transition-property: transform, opacity;

    ${mods('sortOrder', SortOrder.desc)`
        transform: rotate(180deg);
    `};
`;

export const Content = styled.div`
    display: inline-flex;
    align-items: center;
`;
