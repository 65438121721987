import { toast } from '@approvalmax/ui/src/components';
import { commonConstants } from 'modules/common/constants';
import moment from 'moment';
import { commonErrorMessages } from 'services/error/messages';
import { fileUploadTimestampsState } from 'shared/states';
import { jotaiStore } from 'shared/store';

export const handleFileUploadLimits = (files: File[]) => {
    const fileUploadTimestamps = jotaiStore.get(fileUploadTimestampsState);

    if (files.length + fileUploadTimestamps.length > commonConstants.maxUploadFilesPerMinute) {
        const uploadTime = moment(fileUploadTimestamps[0]);
        const currentTime = moment();
        const secondsSinceUpload = currentTime.diff(uploadTime, 'seconds');

        toast.error(commonErrorMessages.fileUploadLimitError({ seconds: 60 - secondsSinceUpload }));

        return files.slice(0, commonConstants.maxUploadFilesPerMinute - fileUploadTimestamps.length);
    }

    return files;
};
