import { ExtractComponentProp } from '@approvalmax/types';
import { Button, Flex, Link, NumberField, Popup, Radio, Spacing, Text } from '@approvalmax/ui/src/components';
import { selectors } from 'modules/common';
import { domain } from 'modules/data';
import { useSelector } from 'modules/react-redux';
import { FC, memo, useCallback, useEffect, useState } from 'react';
import bemFactory from 'react-bem-factory';
import { amplitudeService } from 'services/amplitude';

import { getActiveTemplate } from '../../../../selectors/pageSelectors';
import { messages } from './ChoiceTypePopupContent.messages';
import { Card } from './ChoiceTypePopupContent.styles';
import { ChoiceTypePopupContentProps } from './ChoiceTypePopupContent.types';

const qa = bemFactory.qa('wort-step-type-popup');

const ChoiceTypePopupContent: FC<ChoiceTypePopupContentProps> = memo((props) => {
    const { defaultStepType, defaultNumberOfApprovers, onSelect } = props;

    const workflow = useSelector(getActiveTemplate)!;
    const integrationType = domain.getIntegrationTypeByCode(workflow.integrationCode);
    const links = selectors.links.getLinksByIntegration(integrationType, workflow.integrationCode);

    const [stepType, setStepType] = useState(defaultStepType);
    const [numberOfApprovers, setNumberOfApprovers] = useState<number | null>(defaultNumberOfApprovers ?? 1);

    useEffect(() => {
        amplitudeService.sendData('workflow: change approval condition', {
            workflow: workflow.integrationCode?.toLocaleLowerCase() || 'standalone',
            'approval condition type': stepType === domain.TemplateStepType.DecisionStep ? 'all' : 'any of',
        });
    }, [stepType, workflow.integrationCode]);

    const onDone = useCallback(() => {
        onSelect(stepType, stepType === domain.TemplateStepType.AnyOfDecisionStep ? numberOfApprovers : null);
    }, [numberOfApprovers, onSelect, stepType]);

    const onNumberOfApproversChange = useCallback<ExtractComponentProp<typeof NumberField, 'onChange'>>((value) => {
        setNumberOfApprovers(value ?? null);
    }, []);

    return (
        <>
            <Popup.Header
                title={messages.title}
                actions={
                    <Button size='medium' color='blue10' onClick={onDone} data-qa={qa('done-button')}>
                        {messages.doneButton}
                    </Button>
                }
            />

            <Popup.Body>
                <Text font='body' spacing='0 0 24'>
                    {messages.description({
                        url: (
                            <Link
                                external
                                font='label'
                                fontSize='medium'
                                textAlign='center'
                                href={links[selectors.types.Link.ApprovalConditions]}
                            >
                                {messages.learnMore}
                            </Link>
                        ),
                    })}
                </Text>

                <Flex container>
                    <Flex size={12}>
                        <Card
                            $active={stepType === domain.TemplateStepType.DecisionStep}
                            data-qa={qa('step-type-all')}
                            onClick={() => setStepType(domain.TemplateStepType.DecisionStep)}
                        >
                            <Flex justifyContent='space-between'>
                                <Text font='headline' fontSize='xsmall'>
                                    {messages.stepTypeDefaultTitle}
                                </Text>

                                <Radio.Group>
                                    <Radio checked={stepType === domain.TemplateStepType.DecisionStep} size='large' />
                                </Radio.Group>
                            </Flex>

                            <Spacing height={16} />

                            <Text font='body' fontSize='small' color='midnight80'>
                                {messages.stepTypeDefaultDescription}
                            </Text>
                        </Card>
                    </Flex>

                    <Flex size={12}>
                        <Card
                            $active={stepType === domain.TemplateStepType.AnyOfDecisionStep}
                            data-qa={qa('step-type-anyof')}
                            onClick={() => setStepType(domain.TemplateStepType.AnyOfDecisionStep)}
                        >
                            <Flex justifyContent='space-between'>
                                <Text font='headline' fontSize='xsmall'>
                                    {messages.stepTypeAnyOfTitle}
                                </Text>

                                <Radio.Group>
                                    <Radio
                                        checked={stepType === domain.TemplateStepType.AnyOfDecisionStep}
                                        size='large'
                                    />
                                </Radio.Group>
                            </Flex>

                            <Spacing height={16} />

                            <Text font='body' fontSize='small' color='midnight80' spacing='0 0 16'>
                                {messages.stepTypeAnyOfDescription}
                            </Text>

                            <Text font='body' fontSize='small' color='midnight80' spacing='0 0 16'>
                                <b>{messages.stepTypeAnyOfDescriptionImportantLabel}</b>

                                {messages.stepTypeAnyOfDescriptionImportantNote}
                            </Text>

                            <Flex alignItems='center'>
                                <Text font='body' fontSize='small' color='midnight80'>
                                    {messages.numberOfApproversLabel}
                                </Text>

                                <NumberField
                                    size='small'
                                    disabled={stepType !== domain.TemplateStepType.AnyOfDecisionStep}
                                    max={99}
                                    min={1}
                                    onChange={onNumberOfApproversChange}
                                    value={numberOfApprovers}
                                    width={64}
                                    clearable={false}
                                />
                            </Flex>
                        </Card>
                    </Flex>
                </Flex>
            </Popup.Body>
        </>
    );
});

export default ChoiceTypePopupContent;
