import { RequestMutationOptions } from '@approvalmax/data';
import { useMutate } from 'shared/data';
import { useInvalidateGetInvitations } from 'shared/data/webApp/v2';

import { companiesApiPaths } from '../paths';
import { RespondToInvitation } from './useRespondToInvitation.types';

export const useRespondToInvitation = (mutationOptions?: RequestMutationOptions<RespondToInvitation>) => {
    const invalidateGetInvitations = useInvalidateGetInvitations();

    return useMutate(companiesApiPaths.respondToInvitation, {
        mutationOptions: {
            ...mutationOptions,
            onSuccess: (data, variables, context) => {
                invalidateGetInvitations({});
                mutationOptions?.onSuccess?.(data, variables, context);
            },
        },
        apiVersion: 'v1',
    });
};
