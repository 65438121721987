import { useTheme } from '@approvalmax/theme';
import { Button, Flex, Grid, Progress } from '@approvalmax/ui/src/components';
import { FC, memo } from 'react';
import { useMedia } from 'react-use';

import { ssoItems } from './SsoPanel.constants';
import { useSsoAuth } from './SsoPanel.hooks';
import { SsoPanelProps } from './SsoPanel.types';

export const SsoPanel: FC<SsoPanelProps> = memo((props) => {
    const { disabled } = props;

    const { progress, onLogin } = useSsoAuth(props);

    const { theme } = useTheme();
    const isMobileScreen = useMedia(`(max-width: ${theme.container.breakpoint.xsmall})`);

    return (
        <>
            {progress ? (
                <Flex justifyContent='center' alignItems='center' height={120}>
                    <Progress shape='circle' />
                </Flex>
            ) : (
                <Grid gridTemplateColumns={isMobileScreen ? '1fr' : 'repeat(2, 1fr)'} gap={16}>
                    {ssoItems.map(({ provider, label, color }) => (
                        <Button
                            onClick={() => onLogin(provider)}
                            disabled={disabled}
                            color={color}
                            noPadding
                            key={provider}
                            variant='outline'
                        >
                            {label}
                        </Button>
                    ))}
                </Grid>
            )}
        </>
    );
});

SsoPanel.displayName = 'SsoPanel';
