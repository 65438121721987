import { FormEvent, ForwardedRef, forwardRef, useCallback } from 'react';
import { FieldValues, FormProvider } from 'react-hook-form';

import { Part } from './components';
import { prepareChildren } from './Form.helpers';
import { useFormChange } from './Form.hooks';
import { StyledForm } from './Form.styles';
import { ChildrenComponents, FormBaseType, FormProps } from './Form.types';

/**
 * The Form component allows you to provide a form context for nested controls.
 * Also change all the components with subcomponent `Controller` to `Component.Controller`.
 */
const FormBase = forwardRef(
    <FormValues extends FieldValues = FieldValues>(
        props: FormProps<FormValues>,
        ref: ForwardedRef<HTMLFormElement>
    ) => {
        const { onSubmit, onChange, children, form, height, ...restProps } = props;

        useFormChange<FormValues>(form, onChange);

        const handleSubmit = useCallback(
            (event: FormEvent<HTMLFormElement>) => {
                event.preventDefault();

                onSubmit?.(event);
            },
            [onSubmit]
        );

        return (
            <FormProvider {...form}>
                <StyledForm onSubmit={handleSubmit} $height={height} {...restProps} ref={ref}>
                    {prepareChildren(children, form)}
                </StyledForm>
            </FormProvider>
        );
    }
) as FormBaseType;

export const Form = FormBase as FormBaseType & ChildrenComponents & { displayName: string };

Form.displayName = 'Form';
Form.Part = Part;
