import { Mods, mods } from '@approvalmax/theme';
import { StyledMods } from '@styled-kit/mods';
import styled from 'styled-components';

export const RowActionMenuCell = styled.td<StyledMods<Mods<'open'>>>`
    box-sizing: border-box;
    width: 29px;
    padding-left: 2px;
    border-top: 1px solid transparent;
    border-right: 1px solid transparent;
    border-bottom: 1px solid transparent;
    opacity: 0.001;

    ${mods.open.true`
        background-color: transparent;
        opacity: 1;
    `}
`;
