import { Guid, Reference } from '@approvalmax/types';
import { arrayHelpers, compareHelpers, errorHelpers } from '@approvalmax/utils';
import { produce } from 'immer';
import cloneDeep from 'lodash/cloneDeep';
import union from 'lodash/union';
import unionBy from 'lodash/unionBy';
import uniqBy from 'lodash/uniqBy';
import uniqWith from 'lodash/uniqWith';
import { dataProviders, selectors } from 'modules/common';
import { domain } from 'modules/data';
import { immutable, ImmutableObject, merge, set, update } from 'modules/immutable';

import {
    Action,
    ADD_AUTO_APPROVAL_STEP,
    ADD_EMAIL_EXTERNAL_SUBMITTER,
    ADD_NEW_STANDALONE_TEMPLATE,
    ADD_PAYER,
    ADD_RB_SUBMITTER,
    ADD_REVIEW_STEP,
    ADD_REVIEWER,
    ADD_STEP,
    ADD_STEP_EDITOR,
    ADD_STEP_PARTICIPANT,
    ADD_SUBMITTER,
    APPLY_FIELD_SETTINGS,
    APPLY_MATRIX,
    ASSIGN_EXTERNAL_REQUESTER,
    CHANGE_APPROVAL_STEP_DURATION,
    CHANGE_EXTERNAL_SUBMITTER_ENABLED,
    CHANGE_OCR_CAPTURE_VIA_EMAIL_SETTINGS,
    CHANGE_REVIEW_STEP_DURATION,
    CHANGE_TEMPLATE_STEP_APPROVAL_COUNT,
    CHANGE_TEMPLATE_STEP_TYPE,
    COPY_RULES_TO_ANOTHER_STEP_USERS,
    DELETE_AUTO_APPROVAL_STEP,
    DELETE_REVIEW_STEP,
    DELETE_TEMPLATE_RESPONSE,
    DISCARD_PAGE_STATE,
    DISCARD_TEMPLATE_CHANGES,
    FETCH_WORKFLOW_WITH_VERSION_RESPONSE,
    REMOVE_EMAIL_EXTERNAL_SUBMITTER,
    REMOVE_EXTERNAL_REQUESTER,
    REMOVE_PAYER,
    REMOVE_RB_SUBMITTER,
    REMOVE_REVIEWER,
    REMOVE_STEP,
    REMOVE_STEP_EDITOR,
    REMOVE_STEP_PARTICIPANT,
    REMOVE_SUBMITTER,
    RENAME_STEP,
    RENAME_TEMPLATE,
    REORDER_STEPS,
    SAVE_TEMPLATE_RESPONSE,
    SHOW_WORKFLOW_PAGE,
    START_OVER_RESPONSE,
    UPDATE_FIELD_ACCESS_TYPE_IN_ACTIVE_MATRIX,
} from '../../actions';
import { convertIsoToStringLine } from '../../components/StepDeadline/StepDeadline.helpers';
import { AccessType, MatrixType } from '../../types/matrix';

function updateStep(
    template: ImmutableObject<domain.Template>,
    stepIndex: number,
    updaterFn: (step: domain.TemplateStep) => domain.TemplateStep
): ActiveTemplate {
    return update(template, 'steps', (steps) =>
        steps.map((s, i) => {
            if (i !== stepIndex) {
                return s;
            }

            return updaterFn(s);
        })
    );
}

function getOptimizedMatrix(
    matrixType: MatrixType,
    newMatrix: domain.MatrixLine[],
    amountType: domain.AmountType,
    defaultApproverOrReviewer: Guid | null
): domain.MatrixLine[] {
    // The following optimisations are performed:
    // 1. Remove invalid conditions (=convert them to always-true)
    // 2. Remove always-true conditions
    // 3. Sort values in *ExactValuesCondition
    // 4. Set amount type in *ExactValuesCondition
    // 5. Remove rules without conditions
    const optimizedNewMatrix = newMatrix.map((line): domain.MatrixLine => {
        const rules = line.rules
            .map((r) => {
                return {
                    ...r,
                    conditions: r.conditions
                        .filter((c) => {
                            const isAlwaysTrueCondition = selectors.matrix.isAlwaysTrueCondition(c);
                            const isValidCondition = selectors.matrix.isValidCondition(c);
                            const hasBooleanField = c.allowCreation || c.allowEditing;

                            return (hasBooleanField || !isAlwaysTrueCondition) && isValidCondition;
                        })
                        .map((c) => {
                            if (
                                c.conditionType === domain.ConditionType.ExactValuesCondition ||
                                c.conditionType === domain.ConditionType.NegativeExactValuesCondition
                            ) {
                                const exactValues = arrayHelpers.arraySort(
                                    c.exactValues,
                                    compareHelpers.comparatorFor<Reference>(
                                        compareHelpers.stringComparator2AscI,
                                        'text'
                                    )
                                );

                                c = {
                                    ...c,
                                    exactValues,
                                };
                            }

                            if (c.conditionType === domain.ConditionType.NumericRangeCondition) {
                                c = set(c, 'amountType', amountType);
                            }

                            return c;
                        }),
                };
            })
            .filter((r) => r.conditions.length > 0);

        return {
            lineId: line.lineId,
            isBackup: line.isBackup,
            rules,
        };
    });

    // Add backup approver if needed
    if (matrixType === MatrixType.Approval) {
        const canAssignDefaultApprover = selectors.templateStep.canAssignDefaultApprover(optimizedNewMatrix);

        if (defaultApproverOrReviewer) {
            if (canAssignDefaultApprover) {
                const defaultApproverMatrix = {
                    lineId: defaultApproverOrReviewer,
                    isBackup: true,
                    rules: [],
                };

                return uniqWith(
                    [defaultApproverMatrix, ...optimizedNewMatrix],
                    (a, b) => a.lineId === b.lineId && a.isBackup === b.isBackup
                );
            } else {
                return uniqBy(
                    optimizedNewMatrix.map((matrix) => ({
                        ...matrix,
                        isBackup: false,
                    })),
                    (m) => m.lineId
                );
            }
        }
    }

    if (matrixType === MatrixType.Reviewer) {
        const canAssignDefaultReviewer = selectors.templateStep.canAssignDefaultReviewer(optimizedNewMatrix);

        if (defaultApproverOrReviewer) {
            if (canAssignDefaultReviewer) {
                const defaultReviewer = {
                    lineId: defaultApproverOrReviewer,
                    isBackup: true,
                    rules: [],
                };

                return uniqWith(
                    [defaultReviewer, ...optimizedNewMatrix],
                    (a, b) => a.lineId === b.lineId && a.isBackup === b.isBackup
                );
            } else {
                return uniqBy(
                    optimizedNewMatrix.map((matrix) => ({
                        ...matrix,
                        isBackup: false,
                    })),
                    (m) => m.lineId
                );
            }
        }
    }

    return optimizedNewMatrix;
}

function getSortedGeneralFieldOrder(fieldIds: Guid[], allFields: domain.Field[]) {
    return arrayHelpers
        .arraySort(
            fieldIds
                .filter((fId) => allFields.some((f) => f.id === fId))
                .map((fId) => allFields.find((f) => f.id === fId)!),
            compareHelpers.comparatorFor<domain.Field>(compareHelpers.stringComparator2AscI, 'name')
        )
        .map((f) => f.id);
}

export type ActiveTemplate = ImmutableObject<
    domain.Template & { showAutoApprovalStepWithoutRules?: boolean; showReviewStepWithoutReviewers?: boolean }
> | null;

export default function activeTemplateReducer(state: ActiveTemplate = null, action: Action): ActiveTemplate {
    switch (action.type) {
        case ADD_NEW_STANDALONE_TEMPLATE:
            return immutable(action.newTemplate);

        case DISCARD_PAGE_STATE:
        case DELETE_TEMPLATE_RESPONSE:
            return null;

        case SHOW_WORKFLOW_PAGE:
            return {
                ...immutable(cloneDeep(action.template)),
                showAutoApprovalStepWithoutRules: action.template.autoApprovalRules.length > 0,
                showReviewStepWithoutReviewers: action.template.reviewStep.reviewers.length > 0,
            };

        case FETCH_WORKFLOW_WITH_VERSION_RESPONSE:
            return immutable(cloneDeep(action.payload.template));

        case COPY_RULES_TO_ANOTHER_STEP_USERS: {
            const { fromUser, mapStepsToUser, matrixActive, defaultApprover, template, checkedColumns } =
                action.payload;

            const dataSource = matrixActive.data.find((line) => line.lineId === fromUser.id);

            const sourceGeneralFieldOrder = matrixActive.generalFieldOrder;

            if (!state || !dataSource) {
                return state;
            }

            const usersTargetIds = Object.keys(mapStepsToUser);

            const updatedStepsList = state.steps.map((step) => {
                const usersIds = usersTargetIds.filter((userId) => mapStepsToUser[userId].includes(step.id));

                if (usersIds.length > 0) {
                    const updatedStep = { ...step };

                    const templateStep = template.steps.find((tStep) => tStep.id === step.id);
                    const initialDefaultApprover = templateStep?.participantMatrix.find(
                        (participant) => participant.isBackup
                    );

                    const newParticipantMatrix = updatedStep.participantMatrix.map((line) => {
                        const rules = usersIds.includes(line.lineId) ? [...dataSource.rules] : line.rules;
                        const selectedRules = rules.map((rule) => {
                            return {
                                ...rule,
                                conditions: rule.conditions.filter((condition) =>
                                    checkedColumns.includes(condition.fieldId)
                                ),
                            };
                        });

                        const finalRules = selectedRules.map((rule, index) => {
                            if (line.rules[index]) {
                                return {
                                    ...rule,
                                    conditions: unionBy(
                                        rule.conditions,
                                        line.rules[index].conditions.filter(
                                            (condition) => !checkedColumns.includes(condition.fieldId)
                                        ),
                                        'fieldId'
                                    ),
                                };
                            }

                            return rule;
                        });

                        return {
                            ...line,
                            rules: finalRules,
                        };
                    });

                    const optimizedNewParticipantMatrix = getOptimizedMatrix(
                        matrixActive.type,
                        newParticipantMatrix,
                        matrixActive.amountType,
                        initialDefaultApprover?.lineId || defaultApprover
                    );

                    updatedStep.participantMatrix = optimizedNewParticipantMatrix;

                    updatedStep.generalFieldOrder = union(updatedStep.generalFieldOrder, sourceGeneralFieldOrder);

                    return updatedStep;
                }

                return step;
            });

            return set(state, 'steps', updatedStepsList);
        }

        case CHANGE_OCR_CAPTURE_VIA_EMAIL_SETTINGS:
            return merge(state, action.payload);

        case RENAME_TEMPLATE:
            return set(state, 'templateName', action.payload.newName);

        case SAVE_TEMPLATE_RESPONSE:
            return immutable(cloneDeep(Object.values(action.entities!.templates)?.[0]));

        case DISCARD_TEMPLATE_CHANGES:
            return immutable(cloneDeep(action.untouchedTemplate));

        case ADD_STEP_PARTICIPANT:
            return updateStep(state!, action.stepIndex, (s) => {
                const newParticipantMatrix = s.participantMatrix.concat({
                    lineId: action.user.id,
                    isBackup: action.isBackup,
                    rules: [],
                });
                const canAssignDefaultApprover = selectors.templateStep.canAssignDefaultApprover(newParticipantMatrix);

                return {
                    ...s,
                    participantMatrix: newParticipantMatrix.filter((p) => !p.isBackup || canAssignDefaultApprover),
                };
            });

        case REMOVE_STEP_PARTICIPANT:
            return updateStep(state!, action.payload.stepIndex, (s) => {
                const newParticipantMatrix = s.participantMatrix.filter(
                    (x) => !(x.lineId === action.payload.user.id && x.isBackup === action.payload.isBackup)
                );
                const canAssignDefaultApprover = selectors.templateStep.canAssignDefaultApprover(newParticipantMatrix);

                return {
                    ...s,
                    participantMatrix: newParticipantMatrix.filter((p) => !p.isBackup || canAssignDefaultApprover),
                };
            });

        case REMOVE_REVIEWER:
            return update(state!, 'reviewStep', (reviewStep) => {
                const newReviewers = reviewStep.reviewers.filter(
                    (reviewer) =>
                        !(reviewer.lineId === action.payload.user.id && reviewer.isBackup === action.payload.isBackup)
                );
                const canAssignDefaultReviewer = selectors.templateStep.canAssignDefaultReviewer(newReviewers);

                return {
                    ...reviewStep,
                    reviewers: newReviewers.filter((p) => !p.isBackup || canAssignDefaultReviewer),
                };
            });

        case ADD_REVIEWER:
            return update(state!, 'reviewStep', (reviewStep) => {
                const { user, isBackup } = action.payload;
                const newReviewers = reviewStep.reviewers.concat({
                    lineId: user.id,
                    rules: [],
                    isBackup,
                });
                const canAssignDefaultReviewer = selectors.templateStep.canAssignDefaultReviewer(newReviewers);

                return {
                    ...reviewStep,
                    reviewers: newReviewers.filter((p) => !p.isBackup || canAssignDefaultReviewer),
                };
            });

        case ADD_STEP_EDITOR:
            return updateStep(state!, action.payload.stepIndex, (s) => ({
                ...s,
                editorMatrix: s.editorMatrix.concat({
                    lineId: action.payload.user.id,
                    rules: [],
                }),
            }));

        case REMOVE_STEP_EDITOR:
            return updateStep(state!, action.payload.stepIndex, (s) => {
                let editorMatrix = s.editorMatrix.filter((x) => x.lineId !== action.payload.user.id);

                return {
                    ...s,
                    editorMatrix,
                };
            });

        case REORDER_STEPS: {
            const editorMatrix = state!.steps[0].editorMatrix;
            const steps: domain.TemplateStep[] = arrayHelpers
                .arrayMove(state!.steps, action.payload.oldIndex, action.payload.newIndex)
                .map((step, i) => {
                    // Update step 0 with new editor mx
                    if (i === 0 && step.editorMatrix !== editorMatrix) {
                        return {
                            ...step,
                            editorMatrix: editorMatrix.slice(0),
                        };
                    }

                    // Clean editor mx in consequent steps
                    if (i !== 0 && step.editorMatrix.length > 0) {
                        return {
                            ...step,
                            editorMatrix: [],
                        };
                    }

                    return step;
                });

            return set(state, 'steps', steps);
        }

        case ADD_STEP:
            return set(state, 'steps', state!.steps.concat([action.newStep]));

        case ADD_AUTO_APPROVAL_STEP:
            return set(
                set(state, 'autoApprovalRules', [] as domain.MatrixLine[]),
                'showAutoApprovalStepWithoutRules',
                true
            );

        case DELETE_AUTO_APPROVAL_STEP:
            return merge(state, {
                autoApprovalRules: [],
                showAutoApprovalStepWithoutRules: false,
            });

        case ADD_REVIEW_STEP:
            return set(state, 'showReviewStepWithoutReviewers', true);

        case DELETE_REVIEW_STEP:
            return merge(state, {
                reviewStep: {
                    readonlyFieldIds: [],
                    requiredFieldIds: [],
                    reviewers: [],
                    deadlineRule: null,
                },
                showReviewStepWithoutReviewers: false,
            });

        case REMOVE_STEP: {
            const newSteps = [...state!.steps];

            newSteps.splice(action.payload.stepIndex, 1);

            return set(state, 'steps', newSteps);
        }

        case RENAME_STEP:
            return updateStep(state!, action.payload.stepIndex, (s) => ({
                ...s,
                name: action.payload.newName,
            }));

        case CHANGE_APPROVAL_STEP_DURATION: {
            const calculator = action.payload.newDeadlineCalculator;

            return updateStep(state!, action.payload.stepIndex, (s) => ({
                ...s,
                defaultDuration: action.payload.newDuration,
                deadlineRule: calculator
                    ? {
                          calculator,
                          duration: convertIsoToStringLine(action.payload.newDuration),
                      }
                    : null,
            }));
        }

        case CHANGE_REVIEW_STEP_DURATION: {
            const { newDeadlineCalculator, newDuration } = action.payload;

            return update(state!, 'reviewStep', (reviewStep) => ({
                ...reviewStep,
                deadlineRule: newDeadlineCalculator
                    ? {
                          calculator: newDeadlineCalculator,
                          duration: convertIsoToStringLine(newDuration),
                      }
                    : null,
            }));
        }

        case CHANGE_TEMPLATE_STEP_TYPE:
            return updateStep(state!, action.payload.stepIndex, (s) => ({
                ...s,
                type: action.payload.newStepType,
            }));

        case CHANGE_TEMPLATE_STEP_APPROVAL_COUNT:
            return updateStep(state!, action.payload.stepIndex, (s) => ({
                ...s,
                approvalCount: action.payload.newStepApprovalCount,
            }));

        case ADD_SUBMITTER:
            return set(
                state,
                'submitterMatrix',
                state!.submitterMatrix.concat([
                    {
                        lineId: action.payload.userId,
                        rules: [],
                    },
                ])
            );

        case REMOVE_SUBMITTER: {
            return produce(state!, (draft) => {
                const submitterMatrix = state!.submitterMatrix.filter((s) => s.lineId !== action.payload.userId);
                const deletedUserId = action.payload.userDatabaseId || action.payload.userId;

                draft.submitterMatrix = submitterMatrix;

                if (draft.autoApprovalRules) {
                    draft.autoApprovalRules.forEach((element) => {
                        element.rules.forEach((rule) => {
                            rule.conditions.forEach((condition) => {
                                if (condition.fieldSystemPurpose === domain.FieldSystemPurpose.Requester) {
                                    (condition as domain.ExactValuesCondition).exactValues = (
                                        condition as domain.ExactValuesCondition
                                    ).exactValues.filter((v) => v.id !== deletedUserId);
                                }
                            });

                            rule.conditions = rule.conditions.filter((condition) => {
                                if (
                                    condition.fieldSystemPurpose === domain.FieldSystemPurpose.Requester &&
                                    !(condition as domain.ExactValuesCondition).exactValues.length
                                ) {
                                    return false;
                                }

                                return true;
                            });
                        });

                        element.rules = element.rules.filter((rule) => !!rule.conditions.length);
                    });
                }

                draft.autoApprovalRules = draft.autoApprovalRules.filter((d) => !!d.rules.length);
            });
        }

        case ADD_PAYER:
            return set(
                state,
                'payerMatrix',
                state!.payerMatrix?.concat([
                    {
                        lineId: action.payload.userId,
                        rules: [],
                    },
                ]) || [
                    {
                        lineId: action.payload.userId,
                        rules: [],
                    },
                ]
            );

        case REMOVE_PAYER: {
            return set(
                state,
                'payerMatrix',
                state!.payerMatrix ? state!.payerMatrix.filter((s) => s.lineId !== action.payload.userId) : []
            );
        }

        case ASSIGN_EXTERNAL_REQUESTER:
            return set(state, 'externalSubmitter', action.payload.userId);

        case REMOVE_EXTERNAL_REQUESTER:
            return set(state, 'externalSubmitter', null);

        case ADD_EMAIL_EXTERNAL_SUBMITTER:
            return set(state, 'emailExternalSubmitter', action.payload.userId);

        case REMOVE_EMAIL_EXTERNAL_SUBMITTER:
            return set(state, 'emailExternalSubmitter', null);

        case ADD_RB_SUBMITTER:
            return set(state, 'receiptBankExternalSubmitter', action.payload.userId);

        case REMOVE_RB_SUBMITTER:
            return set(state, 'receiptBankExternalSubmitter', null);

        case UPDATE_FIELD_ACCESS_TYPE_IN_ACTIVE_MATRIX:
            if (
                action.payload.matrixType === MatrixType.Requester &&
                action.payload.newAccessType === AccessType.Mandatory
            ) {
                const newSteps = state!.steps.map((step) => ({
                    ...step,
                    participantMatrix: step.participantMatrix.map((participant) => ({
                        ...participant,
                        rules: participant.rules.map((rule) => ({
                            ...rule,
                            conditions: rule.conditions.map((condition) => {
                                if (condition.fieldId === action.payload.fieldId && 'exactValues' in condition) {
                                    const newCondition = {
                                        ...condition,
                                    };

                                    newCondition.exactValues = newCondition.exactValues.filter(
                                        (value) => value.id !== dataProviders.FieldDataProvider.EmptyValue.id
                                    );

                                    return newCondition;
                                }

                                return condition;
                            }),
                        })),
                    })),
                }));

                return set(state, 'steps', newSteps);
            }

            return state;

        case APPLY_MATRIX: {
            const matrixType = action.payload.matrix.type;

            if (!action.payload.matrix.modified) {
                // do not apply changes if we haven't modified the mx
                return state;
            }

            switch (matrixType) {
                case MatrixType.Approval: {
                    const newMatrix = action.payload.matrix.data;
                    const defaultApprover = action.payload.matrix.defaultApprover;

                    return updateStep(state!, action.payload.matrix.stepIndex, (s) => ({
                        ...s,
                        participantMatrix: getOptimizedMatrix(
                            MatrixType.Approval,
                            newMatrix,
                            action.payload.matrix.amountType,
                            defaultApprover
                        ),
                        generalFieldOrder: getSortedGeneralFieldOrder(
                            action.payload.matrix.generalFieldOrder,
                            action.payload.fields
                        ),
                    }));
                }

                case MatrixType.Editor: {
                    const requiredFieldIds = action.payload.matrix.requiredFieldIds;
                    const readonlyFieldIds = action.payload.matrix.readonlyFieldIds;

                    return updateStep(state!, action.payload.matrix.stepIndex, (s) => ({
                        ...s,
                        editorMatrix: getOptimizedMatrix(
                            MatrixType.Editor,
                            action.payload.matrix.data,
                            action.payload.matrix.amountType,
                            null
                        ),
                        requiredFieldIds,
                        readonlyFieldIds,
                    }));
                }

                case MatrixType.Requester: {
                    return merge(state, {
                        submitterMatrix: getOptimizedMatrix(
                            MatrixType.Requester,
                            action.payload.matrix.data,
                            action.payload.matrix.amountType,
                            null
                        ),
                        requiredFieldIds: action.payload.matrix.requiredFieldIds,
                        submitterRuleOrders: action.payload.matrix.generalFieldOrder,
                    });
                }

                case MatrixType.AutoApproval: {
                    return merge(state, {
                        autoApprovalRules: getOptimizedMatrix(
                            MatrixType.AutoApproval,
                            action.payload.matrix.data,
                            action.payload.matrix.amountType,
                            null
                        ),
                        requiredFieldIds: action.payload.matrix.requiredFieldIds,
                    });
                }

                case MatrixType.Reviewer: {
                    const defaultReviewer = action.payload.matrix.defaultReviewer;

                    return merge(state, {
                        reviewStep: {
                            requiredFieldIds: action.payload.matrix.requiredFieldIds,
                            readonlyFieldIds: action.payload.matrix.readonlyFieldIds,
                            reviewers: getOptimizedMatrix(
                                MatrixType.Reviewer,
                                action.payload.matrix.data,
                                action.payload.matrix.amountType,
                                defaultReviewer
                            ),
                        },
                    });
                }

                case MatrixType.Editing: {
                    const newMatrix = action.payload.matrix.data;

                    return updateStep(state!, action.payload.matrix.stepIndex, (s) => ({
                        ...s,
                        editPermissionsRequiredFieldIds: action.payload.matrix.requiredFieldIds,
                        editingMatrix: getOptimizedMatrix(
                            MatrixType.Editing,
                            newMatrix,
                            action.payload.matrix.amountType,
                            null
                        ),
                    }));
                }

                default:
                    throw errorHelpers.assertNever(matrixType);
            }
        }

        case CHANGE_EXTERNAL_SUBMITTER_ENABLED:
            if (!action.payload.enabled && state && state.externalSubmitter) {
                return set(state, 'externalSubmitter', null);
            } else {
                return state;
            }

        case START_OVER_RESPONSE:
            return set(state, 'hasOutdatedRequests', action.payload.hasOutdatedRequests);

        case APPLY_FIELD_SETTINGS:
            return set(state, 'documentFields', action.payload.fieldsSettings);

        default:
            return state;
    }
}
