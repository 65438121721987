import { intl } from '@approvalmax/utils';
import { useAtomValue } from 'jotai';
import { schemas } from 'modules/data';
import { GlobalLoadingBar } from 'modules/page';
import moment from 'moment';
import { FC, memo, useMemo } from 'react';
import { useGetQBooksMatchingContext } from 'shared/data/webApp/v1';
import { useGetAccountLineItemMatchingSuggestions, useGetLineItemMatchingSuggestions } from 'shared/data/webApp/v2';

import {
    qBooksMatchingPopupAccountLineItemNormalizerState,
    qBooksMatchingPopupLineItemNormalizerState,
} from '../../../QBooksMatchingPopup';
import CollapsibleSection from './components/CollapsibleSection/CollapsibleSection';
import { MatchingLineItems } from './components/MatchingLineItems/MatchingLineItems';
import { messages } from './ListOfPurchaseOrders.messages';
import { Container, EmptyContainer, EmptyContent, EmptyIcon, EmptyMessage, Title } from './ListOfPurchaseOrders.styles';
import type { ListOfPurchaseOrdersProps } from './ListOfPurchaseOrders.types';

export const ListOfPurchaseOrders: FC<ListOfPurchaseOrdersProps> = memo((props) => {
    const {
        isQBOMatchingOnEditingBetaSupported,
        lineItemId,
        accountLineItemAccountId,
        lineItemItemId,
        companyId,
        vendorId,
        billDate,
        selectedLineItemId,
        setSelectedLineItemId,
        requestId,
    } = props;

    const accountLineItemNormalizer = useAtomValue(qBooksMatchingPopupAccountLineItemNormalizerState);
    const lineItemNormalizer = useAtomValue(qBooksMatchingPopupLineItemNormalizerState);

    const { data: qBooksMatchingContext, isFetching: isFetchingQBooksMatchingContext } = useGetQBooksMatchingContext(
        {
            query: {
                companyId,
                lineItemId,
            },
        },
        { enabled: !isQBOMatchingOnEditingBetaSupported }
    );

    const { data: lineItemMatchingSuggestions, isFetching: isFetchingLineItemMatchingSuggestions } =
        useGetLineItemMatchingSuggestions(
            {
                path: { companyId },
                query: {
                    requestId,
                    vendorId,
                    billDate,
                    itemId: lineItemItemId,
                },
            },
            { enabled: isQBOMatchingOnEditingBetaSupported }
        );

    const { data: accountLineItemMatchingSuggestions, isFetching: isFetchingAccountLineItemMatchingSuggestions } =
        useGetAccountLineItemMatchingSuggestions(
            {
                path: { companyId },
                query: {
                    requestId,
                    vendorId,
                    billDate,
                    accountItemId: accountLineItemAccountId,
                },
            },
            { enabled: isQBOMatchingOnEditingBetaSupported }
        );

    const items = useMemo(
        () =>
            (
                qBooksMatchingContext?.data?.purchaseOrders ||
                lineItemMatchingSuggestions?.purchaseOrders ||
                accountLineItemMatchingSuggestions?.purchaseOrders
            )?.map((purchaseOrder) => {
                return {
                    name: purchaseOrder.friendlyName,
                    date: moment.utc(purchaseOrder.date).format('ll'),
                    amount: intl.formatCurrency(purchaseOrder.totalAmount, purchaseOrder.currency),
                    id: purchaseOrder.id,
                    currency: purchaseOrder.currency,
                    accountLineItems: (purchaseOrder.accountBasedLines || []).map((accountBasedLine) =>
                        // TODO: BE don't have time to support correct types between v2 and v1, and we don't have time
                        //  to wait, so as a temporary solution we casting types
                        //  @see https://approvalmax.atlassian.net/browse/AM-20926
                        accountLineItemNormalizer(
                            schemas.mapAccountLineItem(
                                accountBasedLine as unknown as Parameters<typeof schemas.mapAccountLineItem>[0]
                            )
                        )
                    ),
                    lineItems: (purchaseOrder.itemBasedLines || []).map((itemBasedLine) =>
                        // TODO: BE don't have time to support correct types between v2 and v1, and we don't have time
                        //  to wait, so as a temporary solution we casting types
                        //  @see https://approvalmax.atlassian.net/browse/AM-20926
                        lineItemNormalizer(
                            schemas.mapLineItem(itemBasedLine as unknown as Parameters<typeof schemas.mapLineItem>[0])
                        )
                    ),
                };
            }),
        [
            accountLineItemMatchingSuggestions?.purchaseOrders,
            accountLineItemNormalizer,
            lineItemMatchingSuggestions?.purchaseOrders,
            lineItemNormalizer,
            qBooksMatchingContext?.data?.purchaseOrders,
        ]
    );

    if (
        isFetchingQBooksMatchingContext ||
        isFetchingLineItemMatchingSuggestions ||
        isFetchingAccountLineItemMatchingSuggestions
    ) {
        return <GlobalLoadingBar isLoading />;
    }

    if ((items || []).length === 0) {
        return (
            <EmptyContainer>
                <EmptyContent>
                    <EmptyIcon />

                    <EmptyMessage>{messages.emptyMessage}</EmptyMessage>
                </EmptyContent>
            </EmptyContainer>
        );
    }

    return (
        <Container>
            <Title>{messages.title}</Title>

            {(items || []).map((purchaseOrder) => (
                <CollapsibleSection
                    key={purchaseOrder.id}
                    title={purchaseOrder.name}
                    date={purchaseOrder.date}
                    amount={purchaseOrder.amount}
                >
                    <MatchingLineItems
                        purchaseOrder={purchaseOrder}
                        selectedLineItemId={selectedLineItemId}
                        setSelectedLineItemId={setSelectedLineItemId}
                        requestId={requestId}
                    />
                </CollapsibleSection>
            ))}
        </Container>
    );
});

ListOfPurchaseOrders.displayName = 'ListOfPurchaseOrders';
