import { FC, memo, useCallback } from 'react';

import Button from '../Button/Button';
import { Flex } from '../Flex/Flex';
import Popup from '../Popup/Popup';
import Spacing from '../Spacing/Spacing';
import { Text } from '../Text/Text';
import { messages } from './ConfirmationPopup.messages';
import type { ConfirmationPopupProps } from './ConfirmationPopup.types';

export const ConfirmationPopup: FC<ConfirmationPopupProps> = memo((props) => {
    const {
        type = 'positive',
        confirmTitle,
        confirmMessage,
        confirmButtonMessage,
        confirmButtonColor,
        cancelButtonMessage,
        cancelButtonColor,
        onToggle,
        onConfirm,
        confirmProgress,
        preventAutoClose,
        ...restProps
    } = props;

    const handleCancel = useCallback(() => {
        onToggle?.(false);
    }, [onToggle]);

    const isNegative = type === 'negative';
    const defaultConfirmColor = isNegative ? 'red40' : 'blue80';
    const defaultCancelColor = 'midnight40';

    return (
        <Popup
            size='small'
            preventAutoClose={confirmProgress || preventAutoClose}
            qa='confirmation'
            closable={false}
            {...restProps}
            onToggle={onToggle}
        >
            <Popup.Header title={confirmTitle ?? messages.title} />

            <Popup.Body spacing='20 32 32 32'>
                <Spacing height={4} />

                <Flex direction='column' spacing='16'>
                    <Text font='body' fontSize='medium' fontWeight='regular'>
                        {confirmMessage ?? messages.areYouSure}
                    </Text>

                    <Flex spacing='16' justifyContent='end' direction={isNegative ? 'row' : 'row-reverse'}>
                        <Button
                            color={confirmButtonColor ?? defaultConfirmColor}
                            size='large'
                            progress={confirmProgress}
                            onClick={onConfirm}
                        >
                            {confirmButtonMessage ?? messages.buttonConfirm}
                        </Button>

                        <Button
                            color={defaultCancelColor}
                            size='large'
                            disabled={confirmProgress}
                            onClick={handleCancel}
                        >
                            {cancelButtonMessage ?? messages.buttonCancel}
                        </Button>
                    </Flex>
                </Flex>
            </Popup.Body>
        </Popup>
    );
});

ConfirmationPopup.displayName = 'ConfirmationPopup';
