import { FC, memo } from 'react';

import { SearchIcon } from '../../../../icons';
import { Grid } from '../../../Grid/Grid';
import { Text } from '../../../Text/Text';
import { TextField } from '../../../TextField/TextField';
import { messages } from './PanelHeader.messages';
import { PanelHeaderProps } from './PanelHeader.types';

export const PanelHeader: FC<PanelHeaderProps> = memo((props) => {
    const { search, onSearchChange, title, description } = props;

    return (
        <Grid gap={12}>
            {!!title && (
                <Text font='label' fontSize='large' fontWeight='medium' color='midnight100'>
                    {title}
                </Text>
            )}

            {!!description && (
                <Text font='label' fontSize='small' fontWeight='medium' color='midnight70'>
                    {description}
                </Text>
            )}

            <TextField
                size='small'
                placeholder={messages.placeholder}
                startIcon={<SearchIcon />}
                value={search}
                onChange={onSearchChange}
            />
        </Grid>
    );
});

PanelHeader.displayName = 'PanelHeader';
