import { Popup, ToolbarButton } from '@approvalmax/ui';
import { errorHelpers } from '@approvalmax/utils';
import { useAtomValue } from 'jotai';
import { actions, selectors } from 'modules/common';
import { domain } from 'modules/data';
import { useDispatch, useSelector } from 'modules/react-redux';
import { TbHelpColorlessIcon } from 'modules/sprites';
import { QBOOKS_MATCHING_HELP_ITEM_ID } from 'pages/requestList/constants';
import { memo, useCallback, useState } from 'react';

import {
    qBooksMatchingPopupAccountLineItemState,
    qBooksMatchingPopupLineItemState,
} from '../../../QBooksMatchingPopup';
import { ListOfPurchaseOrders } from '../ListOfPurchaseOrders/ListOfPurchaseOrders';
import { MatchingPopupLinks } from '../MatchingPopupLinks/MatchingPopupLinks';
import { SelectedLineInformation } from '../SelectedLineInformation/SelectedLineInformation';
import { messages } from './Content.messages';
import { ContentContainer, DefaultContent } from './Content.styles';
import { ContentProps } from './Content.types';

export const Content = memo<ContentProps>((props) => {
    const { requestId, vendorId, billDate, isLoading, onMatch, onClose } = props;

    const [selectedLineItemId, setSelectedLineItemId] = useState('');
    const dispatch = useDispatch();
    const request = useSelector((state) =>
        selectors.request.getRequestById<
            selectors.types.QBooksBillExpandedRequest | selectors.types.QBooksPoExpandedRequest
        >(state, requestId)
    );
    const accountLineItem = useAtomValue(qBooksMatchingPopupAccountLineItemState);
    const lineItem = useAtomValue(qBooksMatchingPopupLineItemState);

    const hiddenHelpItems = useSelector(selectors.userPreferences.getHiddenHelpItems);

    const showHelpButton = hiddenHelpItems.includes(QBOOKS_MATCHING_HELP_ITEM_ID);

    const isQBOMatchingOnEditingBetaSupported = request.company.betaFeatures.includes(
        domain.CompanyBetaFeature.QBOMatchingOnEditing
    );

    if (request.integrationType !== domain.IntegrationType.QBooks) {
        throw errorHelpers.invalidOperationError();
    }

    const onSave = async () => {
        const lineItemId = accountLineItem?.id || lineItem?.id;

        if (!lineItemId) {
            throw errorHelpers.notFoundError('`lineItemId` not found');
        }

        await onMatch(
            { companyId: request.companyId, lineItemId, matchedLineItemId: selectedLineItemId },
            Boolean(accountLineItem?.id)
        );
        onClose();
    };

    const onHelpButtonClick = useCallback(() => {
        dispatch(actions.updateHiddenHelpItem(QBOOKS_MATCHING_HELP_ITEM_ID, false));
    }, [dispatch]);

    return (
        <DefaultContent
            title={messages.matchLines}
            buttons={
                <>
                    {showHelpButton && (
                        <ToolbarButton title={messages.showHelp} execute={onHelpButtonClick} theme='dark' size='small'>
                            <TbHelpColorlessIcon width={20} height={20} />
                        </ToolbarButton>
                    )}

                    <Popup.DefaultContent.Button
                        execute={onSave}
                        disabled={!selectedLineItemId || isLoading}
                        title={messages.matchTooltip}
                    >
                        {messages.matchTooltip}
                    </Popup.DefaultContent.Button>
                </>
            }
        >
            <>
                <MatchingPopupLinks />

                <ContentContainer>
                    <SelectedLineInformation
                        accountLineItem={accountLineItem}
                        lineItem={lineItem}
                        requestId={requestId}
                    />

                    <ListOfPurchaseOrders
                        isQBOMatchingOnEditingBetaSupported={isQBOMatchingOnEditingBetaSupported}
                        lineItemId={accountLineItem?.id || lineItem?.id}
                        accountLineItemAccountId={accountLineItem?.account?.id}
                        lineItemItemId={lineItem?.item?.id}
                        companyId={request.companyId}
                        requestId={requestId}
                        vendorId={vendorId}
                        billDate={billDate}
                        selectedLineItemId={selectedLineItemId}
                        setSelectedLineItemId={setSelectedLineItemId}
                    />
                </ContentContainer>
            </>
        </DefaultContent>
    );
});

Content.displayName = 'Content';
