import { openSplitViewState } from '@approvalmax/ui/src/components';
import { useAtomValue } from 'jotai';
import { hooks as pageHooks } from 'modules/page';
import { FC, memo, PropsWithChildren } from 'react';

import { Content, ContentBody, ContentBodyInner } from './DefaultPageLayout.styles';

export interface DefaultPageLayoutProps extends PropsWithChildren {
    fixedWidth?: boolean;
    documentTitle?: string | null;
    className?: string;
}

const DefaultPageLayout: FC<DefaultPageLayoutProps> = memo((props) => {
    const { children, fixedWidth = false, documentTitle, className } = props;

    const openSplitView = useAtomValue(openSplitViewState);

    pageHooks.useDocumentTitle(documentTitle);

    return (
        <Content className={className}>
            <ContentBody $zIndex={openSplitView ? 'initial' : 0}>
                <ContentBodyInner fixedWidth={fixedWidth}>{children}</ContentBodyInner>
            </ContentBody>
        </Content>
    );
});

export default DefaultPageLayout;
