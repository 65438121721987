import './forceDecisionButton.scss';

import { combineCommandsAnd, Command } from '@approvalmax/types';
import { AutoDropdown, Button, TransparentButton } from '@approvalmax/ui';
import { eventHelpers } from '@approvalmax/utils';
import { domain } from 'modules/data';
import { BubbleCloseIcon, ForceIcon } from 'modules/sprites';
import { Component } from 'react';
import bemFactory from 'react-bem-factory';

import { messages } from './ForceDecisionButton.messages';

interface Props {
    className?: string;
    disabled?: boolean;
    small: boolean;
    hideTip: boolean;
    forceApproveCommand: Command;
    forceRejectCommand: Command;
    onForceDecision: (decision: domain.RequestStepParticipantDecision) => void;
    onHideTip: () => void;
}

class ForceDecisionButton extends Component<Props> {
    public render() {
        const {
            className,
            small,
            forceApproveCommand,
            forceRejectCommand,
            onForceDecision,
            disabled: disabled$,
            hideTip,
            onHideTip,
        } = this.props;
        const bem = bemFactory.block('reql-force-decision-button');
        const qa = bemFactory.qa('reql-force-decision-button');

        return (
            <AutoDropdown
                panelFlow='to-left'
                className={bem.add(className)()}
                button={
                    small ? (
                        <TransparentButton
                            qa={qa('force-decision-menu-button')}
                            title={messages.forceDecisionMenuButtonTitle}
                            disabled={disabled$}
                        >
                            {({ elementProps }) => (
                                <button className={bem('small-button')} {...elementProps}>
                                    <ForceIcon width={18} height={24} />
                                </button>
                            )}
                        </TransparentButton>
                    ) : (
                        <Button
                            qa={qa('force-decision-menu-button')}
                            title={messages.forceDecisionMenuButtonTitle}
                            disabled={disabled$}
                            command={combineCommandsAnd(forceApproveCommand, forceRejectCommand)}
                            className={bem('large-button')}
                            padding='narrow20'
                            colorTheme='grey'
                        >
                            <ForceIcon width={14} height={18} className={bem('large-button-icon')} />

                            {messages.forceDecisionMenuButtonText}
                        </Button>
                    )
                }
            >
                {() => (
                    <div className={bem('panel')} onMouseDown={eventHelpers.preventDefaultCallback}>
                        {!hideTip && (
                            <div className={bem('tip-panel')}>
                                <div
                                    onMouseDown={() => {
                                        onHideTip();
                                    }}
                                    className={bem('close-button')}
                                    data-qa={qa('close-button')}
                                >
                                    <BubbleCloseIcon width={9} height={9} />
                                </div>

                                <div className={bem('tip-panel-title')}>{messages.title}</div>

                                <div className={bem('tip-panel-description')}>
                                    {messages.description({
                                        br: <br />,
                                    })}
                                </div>
                            </div>
                        )}

                        <div className={bem('menu-list')}>
                            <TransparentButton
                                qa={qa('force-decision-approve-menu-item')}
                                execute={() => onForceDecision(domain.RequestStepParticipantDecision.Approve)}
                                command={forceApproveCommand}
                                title={messages.forceApprovalButtonTitle}
                            >
                                {({ elementProps }) => (
                                    <button
                                        {...elementProps}
                                        className={bem('menu-item', {
                                            regular: hideTip,
                                        })}
                                    >
                                        {messages.forceApprovalButtonText}
                                    </button>
                                )}
                            </TransparentButton>

                            <TransparentButton
                                qa={qa('force-decision-reject-menu-item')}
                                execute={() => onForceDecision(domain.RequestStepParticipantDecision.Reject)}
                                command={forceRejectCommand}
                                title={messages.forceRejectionButtonTitle}
                            >
                                {({ elementProps }) => (
                                    <button
                                        {...elementProps}
                                        className={bem('menu-item', {
                                            regular: hideTip,
                                        })}
                                    >
                                        {messages.forceRejectionButtonText}
                                    </button>
                                )}
                            </TransparentButton>
                        </div>
                    </div>
                )}
            </AutoDropdown>
        );
    }
}

export default ForceDecisionButton;
