import { Text } from '@approvalmax/ui/src/components';
import { domain } from 'modules/data';
import { useSelector } from 'modules/react-redux';
import moment from 'moment';
import { requestSelector } from 'pages/requestForm/selectors/pageSelectors';
import { memo } from 'react';
import { getExchangeRateSourceName } from 'shared/helpers/exchangeRate';

import { isCurrencyDisclaimerVisible } from './CurrencyDisclaimer.helpers';
import { messages } from './CurrencyDisclaimer.messages';

export const CurrencyDisclaimer = memo(() => {
    const request = useSelector(requestSelector);
    const showDisclaimer = isCurrencyDisclaimerVisible(request);

    if (!showDisclaimer) {
        return null;
    }

    const sourceName = getExchangeRateSourceName(request.exchangeRateSource);

    const showXeroSyncDisclaimer =
        request.integrationType === domain.IntegrationType.Xero &&
        request.exchangeRateSource &&
        ![domain.ExchangeRateSource.Xero, domain.ExchangeRateSource.Manual, domain.ExchangeRateSource.None].includes(
            request.exchangeRateSource
        );

    const isManual = request.exchangeRateSource === domain.ExchangeRateSource.Manual;

    const sourceInfo =
        sourceName && request.exchangeRateDate
            ? `${moment.utc(request.exchangeRateDate).format('ll')}, ${sourceName}`
            : sourceName;

    return (
        <Text font='label' fontSize='small' color='midnight70'>
            {isManual && messages.manual}

            {messages.exchangeRateText({
                currency: request.currency,
                baseCurrency: request.companyCurrency,
                rate: request.exchangeRate,
            })}

            {sourceInfo && !isManual && ` (${sourceInfo})`}

            {showXeroSyncDisclaimer && `. ${messages.xeroSyncDisclaimer}`}
        </Text>
    );
});

CurrencyDisclaimer.displayName = 'CurrencyDisclaimer';
