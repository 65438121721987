import {
    ArrowLeftIcon,
    ArrowRightIcon,
    AttachmentAddIcon,
    DeleteIcon,
    DotsFilledIcon,
    DownloadIcon,
    OcrIcon,
    OcrSuccessIcon,
} from '@approvalmax/ui';
import {
    Box,
    Button,
    ConditionWrapper,
    Dropdown,
    Flex,
    Menu,
    SplitView,
    Text,
    toast,
} from '@approvalmax/ui/src/components';
import { fileHelpers } from '@approvalmax/utils';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { constants, selectors } from 'modules/common';
import { domain } from 'modules/data';
import { useDispatch, useSelector } from 'modules/react-redux';
import { getRequestEditMode, RequestEditMode } from 'pages/requestForm/selectors/requestSelectors';
import { FC, useCallback, useMemo } from 'react';
import { commonErrorMessages } from 'services/error/messages';
import { splitViewGoToBarSettingsState } from 'shared/components';
import { isOcrEnabledForTemplate } from 'shared/helpers';
import { ContentSplitViewMode, contentSplitViewState, ocrIsLoadingState } from 'shared/states';

import { removeAttachment } from '../../../../actions';
import { ocrAttachmentIdState } from '../OcrUploadPanel/OcrUploadPanel.states';
import { useRemoveNetSuiteExpenseReportExpenseLineAttachment } from './AttachmentToolbar.hooks';
import { messages } from './AttachmentToolbar.messages';
import { AttachmentToolbarProps } from './AttachmentToolbar.types';

const { commonConstants } = constants;

export const AttachmentToolbar: FC<AttachmentToolbarProps> = (props) => {
    const { request, activeAttachment, goToNextDocument, goToPrevDocument, attachmentIndex, attachments, collapsed } =
        props;

    const dispatch = useDispatch();
    const [ocrAttachmentId, setOcrAttachmentId] = useAtom(ocrAttachmentIdState);
    const ocrIsLoading = useAtomValue(ocrIsLoadingState);
    const [contentSplitView, setContentSplitView] = useAtom(contentSplitViewState);
    const company = useSelector((state) => selectors.company.getCompanyById(state, request.companyId));
    const setSplitViewGoToBarSettings = useSetAtom(splitViewGoToBarSettingsState);
    const removeNetSuiteExpenseReportExpenseLineAttachment = useRemoveNetSuiteExpenseReportExpenseLineAttachment();
    const editMode = useSelector((state) => getRequestEditMode(state, request));

    const capturedActiveAttachment = request.attachments.some((attachment) => {
        return (
            activeAttachment &&
            activeAttachment.id === attachment.id &&
            attachment.ocrStatus === domain.OcrStatus.Succeeded
        );
    });

    const isDeleteAttachmentButtonDisabled = useMemo(() => {
        const currentAttachment = attachments.find((attachment) => attachment.id === activeAttachment.id);

        if (!currentAttachment) {
            return false;
        }

        return (
            (ocrAttachmentId === activeAttachment.id && ocrIsLoading) ||
            (!currentAttachment.isNew && (editMode === RequestEditMode.Editor || editMode === RequestEditMode.Approver))
        );
    }, [activeAttachment.id, attachments, editMode, ocrAttachmentId, ocrIsLoading]);

    const onCapture = useCallback(() => {
        const extension = fileHelpers.getFileExtension(activeAttachment.name);

        if (!(commonConstants.ocrAllowedFileExtensions as string[]).includes(extension)) {
            toast.error(
                <>
                    <Text font='body' fontSize='large' fontWeight='medium' spacing='0 0 8'>
                        {commonErrorMessages.ocrUnsupportedFileErrorMessage({ extension })}
                    </Text>

                    <Text font='body'>
                        {commonErrorMessages.ocrUnsupportedFileErrorDescription({
                            filename: activeAttachment.name,
                        })}
                    </Text>
                </>
            );
        } else {
            setOcrAttachmentId(activeAttachment.id);
        }
    }, [activeAttachment.id, activeAttachment.name, setOcrAttachmentId]);

    const onAddAttachment = useCallback(() => {
        setSplitViewGoToBarSettings({
            show: true,
            targetContent: contentSplitView,
            targetContentName: activeAttachment.name,
        });
        setContentSplitView({ mode: ContentSplitViewMode.Empty });
    }, [activeAttachment.name, contentSplitView, setContentSplitView, setSplitViewGoToBarSettings]);

    const onDeleteAttachment = useCallback(() => {
        if (request.integrationCode === domain.IntegrationCode.NetSuiteExpenseReport) {
            removeNetSuiteExpenseReportExpenseLineAttachment(activeAttachment.id);
        }

        dispatch(removeAttachment(activeAttachment.id));
    }, [activeAttachment.id, request.integrationCode, removeNetSuiteExpenseReportExpenseLineAttachment, dispatch]);

    const isBatchPayment =
        request.integrationCode &&
        [
            domain.IntegrationCode.XeroBillBatchPayment,
            domain.IntegrationCode.XeroAirwallexBatchPayment,
            domain.IntegrationCode.XeroAmaxPayBatchPayment,
        ].includes(request.integrationCode);

    const isOcrButtonVisible =
        request.integrationCode &&
        request.statusV2 === domain.RequestStatusV2.Draft &&
        isOcrEnabledForTemplate(company, request.integrationCode);

    const currentIndex = (attachmentIndex || 0) + 1;

    return (
        <>
            <ConditionWrapper
                condition={collapsed && !isBatchPayment}
                wrapperIf={() => (
                    <Dropdown
                        width='max-content'
                        closeOnClickInnerButton
                        activator={
                            <Button icon variant='text' color='blue100'>
                                <DotsFilledIcon />
                            </Button>
                        }
                    >
                        <Box spacing='8'>
                            <Menu size='small' divider>
                                {isOcrButtonVisible && (
                                    <Menu.Item
                                        disabled={ocrIsLoading || ocrAttachmentId === activeAttachment.id}
                                        name={messages.captureButtonText}
                                        title={messages.captureButtonText}
                                        startIcon={<OcrIcon size='16' />}
                                        onClick={onCapture}
                                    />
                                )}

                                <Menu.Item
                                    disabled={ocrIsLoading}
                                    name={messages.addButtonText}
                                    title={messages.addButtonText}
                                    startIcon={<AttachmentAddIcon size='16' />}
                                    onClick={onAddAttachment}
                                />

                                <Menu.Item
                                    disabled={isDeleteAttachmentButtonDisabled}
                                    name={messages.deleteButtonText}
                                    title={messages.deleteButtonText}
                                    startIcon={<DeleteIcon size='16' />}
                                    onClick={onDeleteAttachment}
                                />

                                <Menu.Item
                                    name={messages.downloadButtonText}
                                    title={messages.downloadButtonText}
                                    startIcon={<DownloadIcon size='16' />}
                                    as='a'
                                    target='_blank'
                                    rel='noopener noreferrer'
                                    href={activeAttachment?.url || ''}
                                />

                                <Menu.Item
                                    name={messages.goToPrevDocumentButtonText}
                                    title={messages.goToPrevDocumentButtonText}
                                    disabled={currentIndex === 1}
                                    startIcon={<ArrowLeftIcon size='16' />}
                                    onClick={goToPrevDocument}
                                />

                                <Menu.Item
                                    name={messages.goToNextDocument}
                                    title={messages.goToNextDocument}
                                    disabled={currentIndex === attachments.length}
                                    startIcon={<ArrowRightIcon size='16' />}
                                    onClick={goToNextDocument}
                                />
                            </Menu>
                        </Box>
                    </Dropdown>
                )}
            >
                {isOcrButtonVisible &&
                    (capturedActiveAttachment ? (
                        <OcrSuccessIcon />
                    ) : (
                        <Button
                            disabled={ocrIsLoading || ocrAttachmentId === activeAttachment.id}
                            title={messages.captureButtonText}
                            icon
                            onClick={onCapture}
                            variant='text'
                            color='blue100'
                        >
                            <OcrIcon />
                        </Button>
                    ))}

                {!isBatchPayment && (
                    <>
                        <Button
                            disabled={ocrIsLoading}
                            title={messages.addButtonText}
                            icon
                            onClick={onAddAttachment}
                            variant='text'
                            color='blue100'
                        >
                            <AttachmentAddIcon />
                        </Button>

                        <Dropdown
                            width='max-content'
                            closeOnClickInnerButton
                            activator={
                                <Button
                                    disabled={isDeleteAttachmentButtonDisabled}
                                    icon
                                    title={messages.deleteButtonText}
                                    variant='text'
                                    color='blue100'
                                >
                                    <DeleteIcon />
                                </Button>
                            }
                        >
                            <Box spacing='16'>
                                <Flex direction='column' spacing='12'>
                                    <Text font='body'>{messages.deleteFileDescription}</Text>

                                    <Flex>
                                        <Button size='small' color='red40' onClick={onDeleteAttachment}>
                                            {messages.yesDeleteButtonText}
                                        </Button>

                                        <Button size='small' color='midnight40'>
                                            {messages.noDeleteButtonText}
                                        </Button>
                                    </Flex>
                                </Flex>
                            </Box>
                        </Dropdown>
                    </>
                )}

                <SplitView.Addons.DownloadButton url={activeAttachment?.url || ''} />

                <SplitView.Addons.Pagination
                    currentIndex={currentIndex}
                    total={attachments.length}
                    goToNextDocument={goToNextDocument}
                    goToPrevDocument={goToPrevDocument}
                />
            </ConditionWrapper>
        </>
    );
};

AttachmentToolbar.displayName = 'AttachmentToolbar';
