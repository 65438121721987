import { CloseIcon } from '@approvalmax/ui';
import { Box, Button, Dropdown, Flex, Menu, Spacing, Text } from '@approvalmax/ui/src/components';
import { useAtom } from 'jotai';
import { FC, memo, MemoExoticComponent, useCallback, useMemo } from 'react';
import { amplitudeService } from 'services/amplitude';

import { Activator, ToggleButton, TrainingContent } from './components';
import { data } from './HelpStripe.constants';
import { useAnimation } from './HelpStripe.hooks';
import { messages } from './HelpStripe.messages';
import { hideHelpStripeState } from './HelpStripe.states';
import { AnimatedBox, Root } from './HelpStripe.styles';
import { ChildrenComponents, HelpStripeProps } from './HelpStripe.types';

const HelpStripe = memo((props) => {
    const { id, hideTraining, spacingTop = 12, spacingBottom = 12, ...restProps } = props;

    const [hideHelpStripeIds, setHideHelpStripeIds] = useAtom(hideHelpStripeState);
    const currentHelpData = data[id.toLowerCase()];

    const isHideHelpStripe = useMemo(() => hideHelpStripeIds.includes(id), [hideHelpStripeIds, id]);

    const hideHelpStripe = useCallback(
        () =>
            setHideHelpStripeIds((ids) => {
                if (ids.includes(id)) {
                    return ids;
                }

                return [...ids, id];
            }),
        [id, setHideHelpStripeIds]
    );

    const { animatedStyles, animatedRef } = useAnimation(!isHideHelpStripe);

    const sendAmplitudeEvent = useCallback(
        (name: string, type: string, url?: string) => () => {
            const actionSource = id.split('-')[0];

            amplitudeService.sendData('help: click on context help item', {
                url,
                'item name': name,
                'item type': type,
                'action source': actionSource,
            });
        },
        [id]
    );

    const getItems = useCallback(
        (target: keyof typeof currentHelpData, type: string) =>
            currentHelpData?.[target]?.map(({ href, text }) => ({
                name: text,
                href,
                key: href,
                as: 'a',
                target: '_blank',
                rel: 'noopener noreferrer',
                onClick: sendAmplitudeEvent(text, type, href),
            })) || [],
        [currentHelpData, sendAmplitudeEvent]
    );

    const linksItems = useMemo(() => getItems('links', 'kb article'), [getItems]);
    const videosItems = useMemo(() => getItems('videos', 'video'), [getItems]);

    return (
        <AnimatedBox style={animatedStyles}>
            <Root ref={animatedRef}>
                {spacingTop > 0 && <Spacing height={spacingTop} />}

                <Box color='midnight40' radius='xsmall' spacing='0 16' data-qa='help-stripe' {...restProps}>
                    <Flex justifyContent='space-between' alignItems='center'>
                        <Flex alignItems='center' spacing='16'>
                            {linksItems.length > 0 && (
                                <Dropdown activator={<Activator name={messages.knowledgeBaseActivator} />} width={260}>
                                    <Menu size='small' items={getItems('links', 'kb article')} />
                                </Dropdown>
                            )}

                            {videosItems.length > 0 && (
                                <Dropdown activator={<Activator name={messages.videosActivator} />} width={260}>
                                    <Menu size='small' items={getItems('videos', 'video')} />
                                </Dropdown>
                            )}

                            {!hideTraining && (
                                <>
                                    <Text font='label' color='midnight60'>
                                        |
                                    </Text>

                                    <Dropdown
                                        activator={<Activator name={messages.trainingActivator} />}
                                        width={260}
                                        maxHeight={0}
                                    >
                                        <TrainingContent />
                                    </Dropdown>
                                </>
                            )}
                        </Flex>

                        <Button
                            size='small'
                            variant='text'
                            color='blue100'
                            onClick={hideHelpStripe}
                            startIcon={<CloseIcon />}
                        >
                            {messages.hideButton}
                        </Button>
                    </Flex>
                </Box>

                {spacingBottom > 0 && <Spacing height={spacingBottom} />}
            </Root>
        </AnimatedBox>
    );
}) as MemoExoticComponent<FC<HelpStripeProps>> & ChildrenComponents;

HelpStripe.ToggleButton = ToggleButton;

export default HelpStripe;
