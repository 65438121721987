import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('pages.RequestList.NetSuiteRequestCard.RequestCardExpenseReport', {
    fieldTransactionNumber: 'Exp. Rept. #',
    fieldEmployee: 'Employee',
    fieldAccount: 'Account',
    fieldCurrency: 'Currency',
    fieldAdvance: 'Advance to Apply',
    fieldMemo: 'Purpose',
    fieldDate: 'Date',
    fieldPostingPeriod: 'Posting Period',
    fieldDueDate: 'Due Date',
    fieldClass: 'Class',
    fieldDepartment: 'Department',
    fieldLocation: 'Location',
    fieldAccountCorporateCard: 'Account for Corporate Card Expenses',

    sectionExpensesTitle: 'Expenses',
    openInNetSuiteLinkText: 'Open in NetSuite',

    columnRefLineNumber: 'Ref no.',
    columnExpenseDate: 'Date',
    columnCategory: 'Category',
    columnExpenseAccount: 'Expense account',
    columnQuantity: 'Quantity',
    columnUnitPrice: 'Rate',
    columnForeignAmount: 'Foreign amount',
    columnCurrency: 'Currency',
    columnExchangeRate: 'Exchange rate',
    columnAmount: 'Amount',
    columnTaxCode: 'Tax code',
    columnTaxRate: 'Tax rate',
    columnTaxAmount: 'Tax amount',
    columnGrossAmount: 'Gross amount',
    columnMemo: 'Memo',
    columnDepartment: 'Department',
    columnClass: 'Class',
    columnLocation: 'Location',
    columnCustomer: 'Customer: Project',
    columnIsBillable: 'Is billable',
    columnIsBillableTooltip: 'Is billable',
    columnIsNonReimbursable: 'Non-reimbursable',
    columnIsNonReimbursableTooltip: 'Non-reimbursable',
    columnLineAttachment: 'Attach file',
    columnIsReceipt: 'Receipt',
    columnIsReceiptTooltip: 'Receipt',
    columnIsCorporateCreditCard: 'Corporate card',
    columnIsCorporateCreditCardTooltip: 'Corporate card',
});
