import { atomHelpers } from '@approvalmax/utils';
import { atom } from 'jotai';

export const pinDrawerStorage = atomHelpers.createAtomWithStorage<boolean | undefined>('pinnedDrawer', false);

export const pinDrawerState = atom(
    (get) => {
        const pinned = get(pinDrawerStorage);
        const open = get(openDrawerStorage);

        return pinned && !open;
    },
    (get, set, newValue: boolean | undefined) => {
        if (newValue ?? true) {
            set(openDrawerStorage, false);
        }

        set(pinDrawerStorage, newValue);
    }
);

const openDrawerStorage = atom(false);

export const openDrawerState = atom(
    (get) => {
        const pinned = get(pinDrawerState);
        const open = get(openDrawerStorage);

        return open && !pinned;
    },
    (get, set, newValue: boolean | undefined) => {
        if (newValue ?? true) {
            set(pinDrawerState, false);
        }

        set(openDrawerStorage, Boolean(newValue));
    }
);

export const displayingDrawerState = atom(false);

export const collapseDrawerState = atom((get) => {
    const pinned = get(pinDrawerState);
    const open = get(openDrawerState);

    return !open && !pinned;
});
