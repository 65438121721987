import { useAtomValue } from 'jotai';
import { useMemo } from 'react';

import { profileFormModifiedState } from '../ProfileEditPopup.states';

export const useCheckIsModifiedProfileForm = () => {
    const modificators = useAtomValue(profileFormModifiedState);

    return useMemo(() => Object.values(modificators).some((el) => el), [modificators]);
};
