import { TransparentButton } from '@approvalmax/ui';
import { font } from 'modules/styles';
import styled from 'styled-components';
import { VelocityTransitionGroup } from 'velocity-react';

export const Container = styled.div`
    position: relative;
    margin-bottom: 20px;
    padding: 15px 60px 15px 80px;
    ${font(12)}
    background-color: #f5f5f5;
`;

export const CloseBtnContainer = styled.div`
    position: absolute;
    top: 50%;
    right: 60px;
    transform: translateY(-50%);
`;

export const CloseBtn = styled(TransparentButton)`
    ${font(12, '#384650')}
    fill: #777777;

    &:hover {
        fill: #d43f3a;
        color: #d43f3a;
    }
`;

export const StyledVelocityTransitionGroup = styled(VelocityTransitionGroup)`
    display: block;

    &:empty {
        position: relative;
        top: -1px;
        border-bottom: 1px solid #caccce;
    }
`;
