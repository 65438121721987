import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('pages.RequestList.NetSuiteRequestCard.NetSuiteItemLines', {
    sectionHeaderText: 'Items',
    itemColumnName: 'Item',
    descriptionColumnName: 'Description',
    qtyColumnName: 'QTY',
    unitPriceColumnName: 'Rate',
    amountColumnName: 'Amount',
    taxCodeColumnName: 'Tax code',
    taxRateColumnName: 'Tax rate',
    taxAmountColumnName: 'Tax amount',
    grossAmountColumnName: 'Gross amount',
    memoColumnName: 'Memo',
    departmentColumnName: 'Department',
    classColumnName: 'Class',
    locationColumnName: 'Location',
    customerColumnName: 'Customer:Project',
    projectTaskColumnName: 'Project Task',
    billableColumnName: 'Billable',
    billableColumnTooltip: 'This line is billable',
    unitsColumnName: 'Units',
    vendorNameColumnName: 'Vendor Name',
    closedColumnName: 'Closed',
    closedColumnTooltip: 'This line is closed',
    expectedReceiptDate: 'Expected Receipt Date',
    amortizationSchedule: 'Amortization Schedule',
    amortizationStartDate: 'Amortization Start Date',
    amortizationEndDate: 'Amortization End Date',
    amortizationResidual: 'Amortization Residual',
});
