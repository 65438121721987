import { domain } from 'modules/data';
import { FC, memo, useMemo } from 'react';
import bemFactory from 'react-bem-factory';
import { useGetWorkflowVersionDocumentFields } from 'shared/data/webApp/v1';
import { useNetSuiteExpenseReportExpenseLinesFieldSettings, useNetSuiteExpenseReportFieldSettings } from 'shared/hooks';

import FieldsSection from '../../../../../components/FieldsSection';
import ActivitySection from '../../../../card/ActivitySection';
import AddCommentSection from '../../../../card/AddCommentSection/AddCommentSection';
import AttachmentsSection from '../../../../card/AttachmentsSection';
import Toolbar from '../../../../card/Toolbar/Toolbar';
import { WorkflowSection } from '../../../../card/WorkflowSection';
import { getNetSuiteAmountText } from '../../NetSuiteRequestCard.helpers';
import { useDisplayCurrency, useGetExchangeInfo, useNetSuiteCustomFields } from '../../NetSuiteRequestCard.hooks';
import {
    CurrencySwitcherContainer,
    FieldsSectionWrap,
    Footer,
    Root,
    StyledExchangeRate,
    StyledField,
    StyledScrollableArea,
} from '../../NetSuiteRequestCard.styles';
import CurrencySwitcher from '../CurrencySwitcher/CurrencySwitcher';
import ExpenseReportAmountInfo from '../ExpenseReportAmountInfo/ExpenseReportAmountInfo';
import NetSuiteCustomFields from '../NetSuiteCustomFields/NetSuiteCustomFields';
import { NetSuiteLineTable } from '../NetSuiteLineTable/NetSuiteLineTable';
import NetSuiteLink from '../NetSuiteLink/NetSuiteLink';
import { useTableDefinitions } from './RequestCardExpenseReport.hooks';
import { messages } from './RequestCardExpenseReport.messages';
import { RequestCardExpenseReportProps } from './RequestCardExpenseReport.types';

const qa = bemFactory.qa('netsuite-expense-report-fields-section');

export const RequestCardExpenseReport: FC<RequestCardExpenseReportProps> = memo((props) => {
    const { request, className, readonly } = props;

    const { data: companyCustomFields } = useNetSuiteCustomFields(request.companyId);

    const { data: workflowVersionDocumentFields } = useGetWorkflowVersionDocumentFields({
        query: {
            companyId: request.companyId,
            workflowVersionIds: [request.workflowVersionId],
        },
    });

    const fieldSettings = useNetSuiteExpenseReportFieldSettings(workflowVersionDocumentFields?.data);
    const fieldSettingsExpenseLines = useNetSuiteExpenseReportExpenseLinesFieldSettings(
        workflowVersionDocumentFields?.data
    );

    const expenseLines = useMemo(() => {
        return [...request.details.expenseLines].sort((a, b) => Number(a.refLineNumber) - Number(b.refLineNumber));
    }, [request.details.expenseLines]);

    const requestCurrency = request.details.currency?.text || request.currency;

    const { displayCurrency, onDisplayCurrencyChange } = useDisplayCurrency();

    const { exchangeRate } = useGetExchangeInfo(request.companyId, request.details.exchangeRate, displayCurrency?.id);

    const tableDef = useTableDefinitions(
        expenseLines,
        companyCustomFields?.Fields,
        fieldSettingsExpenseLines,
        displayCurrency && displayCurrency?.isoCode !== request.currency && exchangeRate !== 1 ? exchangeRate : null
    );

    return (
        <Root className={className}>
            <Toolbar
                request={request}
                amountText={getNetSuiteAmountText(
                    request.amount,
                    exchangeRate,
                    request.currency || '',
                    displayCurrency?.isoCode
                )}
                readonly={readonly}
            />

            <StyledScrollableArea scrollResetKey={request.id}>
                <FieldsSectionWrap>
                    {fieldSettings.transactionNumber !== domain.DocumentFieldState.Hidden && (
                        <StyledField
                            qa={qa('transaction-number')}
                            title={messages.fieldTransactionNumber}
                            valueType={FieldsSection.Field.ValueType.String}
                            value={request.details.transactionNumber}
                            hideIfEmpty
                        />
                    )}

                    {fieldSettings.employee !== domain.DocumentFieldState.Hidden && (
                        <StyledField
                            qa={qa('employee')}
                            title={messages.fieldEmployee}
                            valueType={FieldsSection.Field.ValueType.String}
                            value={request.details.employee}
                            hideIfEmpty
                        />
                    )}

                    {fieldSettings.currency !== domain.DocumentFieldState.Hidden && (
                        <StyledField
                            qa={qa('currency')}
                            title={messages.fieldCurrency}
                            valueType={FieldsSection.Field.ValueType.String}
                            value={request.details.currency}
                            hideIfEmpty
                        />
                    )}

                    {fieldSettings.date !== domain.DocumentFieldState.Hidden && (
                        <StyledField
                            qa={qa('date')}
                            title={messages.fieldDate}
                            valueType={FieldsSection.Field.ValueType.Date}
                            value={request.details.date}
                            hideIfEmpty
                        />
                    )}

                    {fieldSettings.postingPeriod !== domain.DocumentFieldState.Hidden && (
                        <StyledField
                            qa={qa('posting-period')}
                            title={messages.fieldPostingPeriod}
                            valueType={FieldsSection.Field.ValueType.String}
                            value={request.details.postingPeriod}
                            hideIfEmpty
                        />
                    )}

                    {fieldSettings.dueDate !== domain.DocumentFieldState.Hidden && (
                        <StyledField
                            qa={qa('due-date')}
                            title={messages.fieldDueDate}
                            valueType={FieldsSection.Field.ValueType.Date}
                            value={request.details.dueDate}
                            hideIfEmpty
                        />
                    )}

                    {fieldSettings.class !== domain.DocumentFieldState.Hidden && (
                        <StyledField
                            qa={qa('class')}
                            title={messages.fieldClass}
                            valueType={FieldsSection.Field.ValueType.String}
                            value={request.details.class}
                            hideIfEmpty
                        />
                    )}

                    {fieldSettings.department !== domain.DocumentFieldState.Hidden && (
                        <StyledField
                            qa={qa('department')}
                            title={messages.fieldDepartment}
                            valueType={FieldsSection.Field.ValueType.String}
                            value={request.details.department}
                            hideIfEmpty
                        />
                    )}

                    {fieldSettings.location !== domain.DocumentFieldState.Hidden && (
                        <StyledField
                            qa={qa('location')}
                            title={messages.fieldLocation}
                            valueType={FieldsSection.Field.ValueType.String}
                            value={request.details.location}
                            hideIfEmpty
                        />
                    )}

                    {fieldSettings.accountForCorporateCardExpenses !== domain.DocumentFieldState.Hidden && (
                        <StyledField
                            qa={qa('account-corporate-card')}
                            title={messages.fieldAccountCorporateCard}
                            valueType={FieldsSection.Field.ValueType.String}
                            value={request.details.accountForCorporateCardExpenses}
                            hideIfEmpty
                        />
                    )}

                    {fieldSettings.memo !== domain.DocumentFieldState.Hidden && (
                        <StyledField
                            qa={qa('memo')}
                            title={messages.fieldMemo}
                            valueType={FieldsSection.Field.ValueType.String}
                            value={request.details.memo}
                            hideIfEmpty
                        />
                    )}

                    <NetSuiteCustomFields
                        customFields={request.details.customFields}
                        companyCustomFields={companyCustomFields?.Fields ?? []}
                    />
                </FieldsSectionWrap>

                <NetSuiteLineTable
                    title={messages.sectionExpensesTitle}
                    columnDefinitions={tableDef}
                    lineItems={expenseLines}
                />

                {typeof exchangeRate === 'number' && (
                    <CurrencySwitcherContainer>
                        <CurrencySwitcher
                            request={request}
                            selectedCurrency={displayCurrency}
                            requestCurrency={request.details.currency}
                            onCurrencyChange={onDisplayCurrencyChange}
                            companyId={request.companyId}
                        />
                    </CurrencySwitcherContainer>
                )}

                <FieldsSection>
                    <Footer>
                        <div>
                            <StyledExchangeRate request={request} />

                            {request.details.url && (
                                <NetSuiteLink url={request.details.url} qa={qa('external-url-link')} />
                            )}
                        </div>

                        <div>
                            <ExpenseReportAmountInfo
                                currency={
                                    displayCurrency &&
                                    displayCurrency?.isoCode !== request.currency &&
                                    exchangeRate !== 1
                                        ? displayCurrency.currencyName
                                        : requestCurrency
                                }
                                totalExpenses={request.details.totalAmount}
                                advance={request.details.advance}
                                reimbursableAmount={request.details.reimbursableAmount}
                                exchangeRate={
                                    displayCurrency &&
                                    displayCurrency?.isoCode !== request.currency &&
                                    exchangeRate !== 1
                                        ? exchangeRate
                                        : null
                                }
                                qa={qa}
                            />
                        </div>
                    </Footer>
                </FieldsSection>

                <AttachmentsSection request={request} />

                <WorkflowSection request={request} readonly={readonly} />

                <ActivitySection request={request} />

                {!request.flags.status.isDraft && !readonly && <AddCommentSection request={request} />}
            </StyledScrollableArea>
        </Root>
    );
});

RequestCardExpenseReport.displayName = 'RequestCardExpenseReport';
