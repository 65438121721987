import './helpSection.scss';

import { UrlReference } from '@approvalmax/types';
import { ActionMenu, AutoDropdown, ExternalLink, TransparentButton } from '@approvalmax/ui';
import { eventHelpers } from '@approvalmax/utils';
import { BubbleCloseIcon } from 'modules/sprites';
import { memo, useEffect, useState } from 'react';
import bemFactory from 'react-bem-factory';
import { useDispatch } from 'react-redux';
import { amplitudeService } from 'services/amplitude';

import { updateHiddenHelpItem } from '../../actions';
import { messages } from './HelpSection.messages';
import { HelpData, HelpSectionProps } from './HelpSection.types';
import helpData from './resource/helpData.json';

const bem = bemFactory.block('common-ui-help-section');

export const HelpSection = memo<HelpSectionProps>((props) => {
    const { helpItemId, hideProfessionalServices, className } = props;

    const dispatch = useDispatch();
    const [links, setLinks] = useState<UrlReference[]>([]);
    const [videos, setVideos] = useState<UrlReference[]>([]);
    const [hasData, setHasData] = useState(false);

    const fetchHelpData = async (helpItemId: string) => {
        const helpItem = (helpData as HelpData)[helpItemId.toLowerCase()];

        let links: UrlReference[] = [];
        let videos: UrlReference[] = [];
        let hasData = Boolean(helpItem);

        if (helpItem) {
            if (helpItem.links) {
                links = helpItem.links.map((x) => ({
                    id: x.href,
                    text: x.text,
                    url: x.href,
                }));
            }

            if (helpItem.videos) {
                videos = helpItem.videos.map((x) => ({
                    id: x.href,
                    text: x.text,
                    url: x.href,
                }));
            }
        }

        setLinks(links);
        setVideos(videos);
        setHasData(hasData);
    };

    useEffect(() => {
        fetchHelpData(helpItemId);
    }, [helpItemId]);

    const hideLocation = () => {
        dispatch(updateHiddenHelpItem(helpItemId, true));
    };

    const helpSectionAmplitudeEvent = (itemName: string, itemType: string, url?: string) => {
        const actionSource = helpItemId.split('-')[0];

        amplitudeService.sendData('help: click on context help item', {
            url,
            'item name': itemName,
            'item type': itemType,
            'action source': actionSource,
        });
    };

    if (!hasData || (links.length === 0 && videos.length === 0)) {
        return <div className={bem.add(className)()} />;
    }

    return (
        <div className={bem.add(className)()}>
            {links.length > 0 && (
                <ActionMenu
                    button={
                        <ActionMenu.TextButton className={bem('dropdown-button')}>
                            {messages.kbDropdownText}
                        </ActionMenu.TextButton>
                    }
                >
                    {links.map((ref) => {
                        return (
                            <ActionMenu.Item key={ref.id} title={ref.text}>
                                <a
                                    href={ref.url}
                                    onClick={() => helpSectionAmplitudeEvent(ref.text, 'kb article', ref.url)}
                                    target='_blank'
                                    rel='noopener noreferrer'
                                    className={bem('link-item')}
                                >
                                    {ref.text}
                                </a>
                            </ActionMenu.Item>
                        );
                    })}
                </ActionMenu>
            )}

            {videos.length > 0 && (
                <ActionMenu
                    button={
                        <ActionMenu.TextButton className={bem('dropdown-button')}>
                            {messages.videosDropdownText}
                        </ActionMenu.TextButton>
                    }
                >
                    {videos.map((ref) => {
                        return (
                            <ActionMenu.Item key={ref.id} title={ref.text}>
                                <a
                                    href={ref.url}
                                    onClick={() => helpSectionAmplitudeEvent(ref.text, 'video', ref.url)}
                                    target='_blank'
                                    rel='noopener noreferrer'
                                    className={bem('link-item')}
                                >
                                    {ref.text}
                                </a>
                            </ActionMenu.Item>
                        );
                    })}
                </ActionMenu>
            )}

            {!hideProfessionalServices && (
                <AutoDropdown
                    button={
                        <ActionMenu.TextButton className={bem('support-dropdown-button')}>
                            {messages.trainingDropdownText}
                        </ActionMenu.TextButton>
                    }
                >
                    <div className={bem('training-dropdown-panel')} onMouseDown={eventHelpers.preventDefaultCallback}>
                        <div className={bem('training-dropdown-panel-title')}>{messages.trainingTitle}</div>

                        <div className={bem('training-dropdown-panel-description')}>
                            {messages.trainingDescription({
                                br: <br />,
                            })}
                        </div>

                        <ExternalLink href='mailto:sales@approvalmax.com'>{messages.contactSales}</ExternalLink>
                    </div>
                </AutoDropdown>
            )}

            <div className={bem('close-button-wrp')}>
                <TransparentButton className={bem('close-button')} execute={hideLocation}>
                    <BubbleCloseIcon className={bem('close-button-icon')} width={9} height={9} />

                    <div className={bem('close-button-text')}>{messages.hideButtonText}</div>
                </TransparentButton>
            </div>
        </div>
    );
});

HelpSection.displayName = 'HelpSection';
