import { useTheme } from '@approvalmax/theme';
import { FC } from 'react';
import { useMedia } from 'react-use';

import { ScrollableContainer } from '../ScrollableContainer/ScrollableContainer';
import { Root } from './Content.styles';
import { ContentProps } from './Content.types';

export const Content: FC<ContentProps> = (props) => {
    const { children, spacingTop, spacingRight, spacingBottom, spacingLeft, ...restProps } = props;

    const { theme } = useTheme();
    const isXSmall = useMedia(`(max-width: ${theme.container.breakpoint.xsmall})`);

    const orientation = isXSmall ? 'horizontal' : 'vertical';

    return (
        <Root {...restProps}>
            <ScrollableContainer
                orientation={orientation}
                spacingTop={spacingTop ?? 16}
                spacingBottom={spacingBottom ?? 40}
                spacingLeft={spacingLeft ?? 40}
                spacingRight={spacingRight ?? 40}
            >
                {children}
            </ScrollableContainer>
        </Root>
    );
};

Content.displayName = 'Content';
