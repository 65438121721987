import { openSplitViewState } from '@approvalmax/ui/src/components';
import { intl } from '@approvalmax/utils';
import { useAtomValue, useSetAtom } from 'jotai';
import { selectors } from 'modules/common';
import { backend, domain } from 'modules/data';
import { font } from 'modules/styles';
import { LineItemsTable } from 'pages/requestList/components/lineItemsSection/LineItemsTable';
import OpenInSourceLink from 'pages/requestList/components/OpenInSourceLink/OpenInSourceLink';
import { FC, memo, useCallback, useMemo } from 'react';
import { splitViewGoToBarDefaultSettings, splitViewGoToBarSettingsState } from 'shared/components';
import { useGetBatchPaymentsRequestAmounts } from 'shared/data/webApp/v2';
import { useCompanyBetaFeatures } from 'shared/hooks';
import { activeAttachmentIdState, ContentSplitViewMode, contentSplitViewState } from 'shared/states';
import styled from 'styled-components';

import { messages } from './BillsInformationSection.messages';
import { getColumnDefinitions } from './tableConfig';

const Root = styled.div``;

const SectionHeaderText = styled.div`
    ${font(14, '#000000', 'semibold')}
    margin-bottom: 12px;
`;

const Footer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
`;

const LinkContainer = styled.div``;

const TotalContainer = styled.div``;

const TotalText = styled.span`
    margin-right: 50px;
    ${font(11, '#565656', 'semibold')}
`;

const TotalAmount = styled.span`
    ${font(13, '#000', 'semibold')}
`;

interface BillsInformationSectionProps {
    billBatchPayment: domain.XeroBillBatchPaymentDetails;
    currency: string;
    amount: number;
    countryCode: backend.OrganisationVersion;
    request: selectors.types.ExpandedRequest;
}

const BillsInformationSection: FC<BillsInformationSectionProps> = (props) => {
    const { billBatchPayment, currency, amount, countryCode, request } = props;

    const setOpenSplitView = useSetAtom(openSplitViewState);
    const setContentSplitView = useSetAtom(contentSplitViewState);
    const activeAttachmentId = useAtomValue(activeAttachmentIdState);
    const setSplitViewGoToBarSettings = useSetAtom(splitViewGoToBarSettingsState);

    const { isBatchPaymentAmountsV2 } = useCompanyBetaFeatures(request.companyId);

    const { data: batchPaymentsRequestAmounts, isFetching: isFetchingGetBatchPaymentsRequestAmounts } =
        useGetBatchPaymentsRequestAmounts(
            {
                path: {
                    companyId: request.companyId,
                    requestId: request.id,
                },
            },
            {
                enabled: isBatchPaymentAmountsV2,
            }
        );

    const onSelectAttachment = useCallback(
        (attachmentId: string, xeroBatchPaymentInvoiceId: string, xeroBatchPaymentInvoiceName: string) => {
            setOpenSplitView(true);
            setContentSplitView({
                mode: ContentSplitViewMode.AttachmentsToBillLinkedToBatchPayment,
                attachmentId,
                billId: xeroBatchPaymentInvoiceId,
                billFriendlyName: xeroBatchPaymentInvoiceName,
            });
            setSplitViewGoToBarSettings({
                show: true,
                targetContent: {
                    mode: ContentSplitViewMode.BillLinkedToBatchPayment,
                    billId: xeroBatchPaymentInvoiceId,
                },
                targetContentName: xeroBatchPaymentInvoiceName,
            });
        },
        [setContentSplitView, setSplitViewGoToBarSettings, setOpenSplitView]
    );

    const handleSelectBill = useCallback(
        (xeroBatchPaymentInvoiceId: string) => {
            setSplitViewGoToBarSettings(splitViewGoToBarDefaultSettings);
            setContentSplitView({
                mode: ContentSplitViewMode.BillLinkedToBatchPayment,
                billId: xeroBatchPaymentInvoiceId,
            });
            setOpenSplitView(true);
        },
        [setContentSplitView, setSplitViewGoToBarSettings, setOpenSplitView]
    );

    const columnDefinitions = useMemo(
        () =>
            getColumnDefinitions({
                currency,
                countryCode,
                activeAttachmentId,
                onSelectAttachment,
                onSelectBill: handleSelectBill,
                isAmountsLoading: isFetchingGetBatchPaymentsRequestAmounts,
                amounts: batchPaymentsRequestAmounts?.items,
                isBatchPaymentAmountsV2,
            }),
        [
            activeAttachmentId,
            countryCode,
            currency,
            handleSelectBill,
            onSelectAttachment,
            isFetchingGetBatchPaymentsRequestAmounts,
            batchPaymentsRequestAmounts,
            isBatchPaymentAmountsV2,
        ]
    );

    return (
        <Root>
            <SectionHeaderText>{messages.headerText}</SectionHeaderText>

            <LineItemsTable columnDefinitions={columnDefinitions} lineItems={billBatchPayment.items} />

            <Footer>
                <LinkContainer>
                    <OpenInSourceLink
                        url={billBatchPayment.url}
                        editUrl={billBatchPayment.editUrl}
                        statusV2={request.statusV2}
                        integrationType={request.integrationType}
                        integrationCode={request.integrationCode}
                    />
                </LinkContainer>

                <TotalContainer>
                    <TotalText>{messages.total}</TotalText>

                    <TotalAmount>{intl.formatCurrency(amount, currency)}</TotalAmount>
                </TotalContainer>
            </Footer>
        </Root>
    );
};

export default memo(BillsInformationSection);
