import { AmCaptureSmallImage, AmPayMediumImage, CloseIcon } from '@approvalmax/ui';
import { Box, Button, Flex, Text } from '@approvalmax/ui/src/components';
import { useSetAtom } from 'jotai';
import {
    tryPayOrCapturePopupInfoState,
    tryPayOrCapturePopupOpenState,
    TryPayOrCapturePopupOrigin,
} from 'modules/profile';
import { FC, memo, useCallback } from 'react';
import { useAddonPromotions } from 'shared/hooks/useAddonPromotions';
import { AddonPromotionType } from 'shared/states';

import { messages } from './RequestListPayAndCapturePromotion.messages';
import { RequestListPayAndCapturePromotionProps } from './RequestListPayAndCapturePromotion.types';

export const RequestListPayAndCapturePromotion: FC<RequestListPayAndCapturePromotionProps> = memo((props) => {
    const { type } = props;

    const { closePromotion } = useAddonPromotions();
    const setTryPayOrCapturePopupOpen = useSetAtom(tryPayOrCapturePopupOpenState);
    const setTryPayOrCapturePopupInfo = useSetAtom(tryPayOrCapturePopupInfoState);

    const onClose = useCallback(() => {
        closePromotion(type);
    }, [closePromotion, type]);

    const onTry = useCallback(() => {
        setTryPayOrCapturePopupOpen(true);
        setTryPayOrCapturePopupInfo({ type, origin: TryPayOrCapturePopupOrigin.ViewForm });
    }, [setTryPayOrCapturePopupInfo, setTryPayOrCapturePopupOpen, type]);

    return (
        <Box spacing='16 40'>
            <Box color={type === AddonPromotionType.Pay ? 'cobalt100' : 'mint100'} spacing='16 24' radius='small'>
                <Flex justifyContent='space-between' wrap={true}>
                    <Flex wrap={false} grow={1} alignItems='center' basis={260}>
                        <Text wordBreak='break-word' font='headline' fontSize='xxsmall'>
                            {type === AddonPromotionType.Pay
                                ? messages.payDescription
                                : messages.captureDescription({ br: <br /> })}
                        </Text>
                    </Flex>

                    <Flex wrap={false} alignItems='center'>
                        {type === AddonPromotionType.Pay ? <AmPayMediumImage /> : <AmCaptureSmallImage />}

                        <Button
                            wrap={false}
                            color={type === AddonPromotionType.Pay ? 'mint80' : 'white100'}
                            size='small'
                            onClick={onTry}
                        >
                            {messages.tryNowButtonText}
                        </Button>

                        <Button icon variant='text' color='blue100' noPadding onClick={onClose}>
                            <CloseIcon size={20} color='white100' />
                        </Button>
                    </Flex>
                </Flex>
            </Box>
        </Box>
    );
});

RequestListPayAndCapturePromotion.displayName = 'RequestListPayAndCapturePromotion';
