import { memo } from 'react';

import { CloseButton } from './components';
import { Root } from './Toast.styles';

/**
 * The Toast component provides brief notifications. Based on lib: https://fkhadra.github.io/react-toastify/introduction
 */
const Toast = memo(() => {
    return (
        <Root
            position='bottom-center'
            limit={3}
            autoClose={5000}
            newestOnTop={false}
            rtl={false}
            draggable={false}
            pauseOnFocusLoss
            pauseOnHover
            closeButton={CloseButton}
        />
    );
});

export default Toast;
