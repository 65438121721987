import { atom } from 'jotai';
import { useLocation } from 'react-router-dom';

export const routeState = atom<Partial<ReturnType<typeof useLocation>>>({
    pathname: '',
    search: '',
    hash: '',
});

export const routeParamsState = atom<Record<string, any>>({});
