import './xeroReceiptBankPaymentDetailsSection.scss';

import { Field } from '@approvalmax/ui';
import { intl } from '@approvalmax/utils';
import { domain } from 'modules/data';
import { AlertSmallIcon } from 'modules/sprites';
import moment from 'moment';
import { FC, memo } from 'react';
import bemFactory from 'react-bem-factory';

import SectionContainer from '../../components/SectionContainer';
import { messages } from './XeroReceiptBankPaymentDetailsSection.messages';

const bem = bemFactory.block('reql-xero-receipt-bank-payment-details-section');

interface XeroReceiptBankPaymentDetailsSectionProps {
    className?: string;
    paymentDetails: domain.XeroInvoicePaymentDetails;
}

const XeroReceiptBankPaymentDetailsSection: FC<XeroReceiptBankPaymentDetailsSectionProps> = (props) => {
    const { className, paymentDetails: d } = props;

    return (
        <div className={bem.add(className)()}>
            <SectionContainer noPadding text={messages.sectionHeaderText}>
                <div className={bem('warning')}>
                    <AlertSmallIcon />

                    <div className={bem('warning-text')}>{messages.warningText}</div>
                </div>

                <div className={bem('fields')}>
                    <Field className={bem('field-date')} title={messages.dateField}>
                        <div className={bem('field-value')}>{moment(d.date).format('ll')}</div>
                    </Field>

                    <Field className={bem('field-account')} title={messages.accountField}>
                        <div className={bem('field-value')}>{`${d.account.text}: ${d.account.number}`}</div>
                    </Field>

                    <Field className={bem('field-amount')} title={messages.amountField}>
                        <div className={bem('field-value')}>
                            <b>{intl.formatNumber(d.amount)}</b>
                        </div>
                    </Field>
                </div>
            </SectionContainer>
        </div>
    );
};

export default memo(XeroReceiptBankPaymentDetailsSection);
