import { RequestMutationOptions } from '@approvalmax/data';
import { useMutate } from 'shared/data';

import { companiesApiPaths } from '../paths';
import { useInvalidateGetCompanies } from '../useGetCompanies/useGetCompanies';
import { UpdateCompany } from './useUpdateCompany.types';

export const useUpdateCompany = (mutationOptions?: RequestMutationOptions<UpdateCompany>) => {
    const invalidateGetCompanies = useInvalidateGetCompanies();

    return useMutate(companiesApiPaths.edit, {
        mutationOptions: {
            ...mutationOptions,
            onSuccess: (response, variables, context) => {
                void invalidateGetCompanies({ query: { companyId: variables.body?.companyId } });

                mutationOptions?.onSuccess?.(response, variables, context);
            },
        },
        mapToCamelCase: true,
    });
};
