import { RequestParams, RequestQueryOptions } from '@approvalmax/data';
import { useGet } from 'shared/data';

import { requestsApiPaths } from '../paths';
import { GetAmaxPayXeroBankAccounts } from './useGetAmaxPayXeroBankAccounts.types';

export const useGetAmaxPayXeroBankAccounts = (
    params: RequestParams<GetAmaxPayXeroBankAccounts>,
    queryOptions?: RequestQueryOptions<GetAmaxPayXeroBankAccounts>
) =>
    useGet(requestsApiPaths.amaxPayXeroBankAccounts, {
        params,
        queryOptions,
        method: 'get',
        apiVersion: 'v1',
    });
