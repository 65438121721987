import { ReactNode } from 'react';
import { toast as toastify } from 'react-toastify';

import { AlertFilledIcon, CheckCircleFilledIcon, InfoCircleFilledIcon } from '../../icons';
import { ToastBody } from './Toast.styles';
import { ExtendedToastOptions, ToastId } from './Toast.types';

const showToast = (message: ReactNode | ReactNode[], options: ExtendedToastOptions) => {
    return toastify(<ToastBody>{message}</ToastBody>, {
        className: (params) => `${params?.defaultClassName} Toastify__toast--size-${options?.size || 'medium'}`,
        ...options,
    });
};

export const toast = {
    default(message: ReactNode | ReactNode[], options?: ExtendedToastOptions) {
        return showToast(message, {
            type: 'default',
            ...options,
        });
    },
    success(message: ReactNode | ReactNode[], options?: ExtendedToastOptions) {
        return showToast(message, {
            type: 'success',
            icon: <CheckCircleFilledIcon />,
            role: 'alert',
            ...options,
        });
    },
    error(message: ReactNode | ReactNode[], options?: ExtendedToastOptions) {
        return showToast(message, {
            type: 'error',
            icon: <AlertFilledIcon />,
            role: 'alert',
            ...options,
        });
    },
    info(message: ReactNode | ReactNode[], options?: ExtendedToastOptions) {
        return showToast(message, {
            type: 'info',
            icon: <InfoCircleFilledIcon />,
            role: 'alert',
            ...options,
        });
    },
    dismiss(toastId: ToastId) {
        return toastify.dismiss(toastId);
    },
};
