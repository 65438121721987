import { ValueOf } from '@approvalmax/types';
import { toast } from '@approvalmax/ui/src/components';
import { zodResolver } from '@hookform/resolvers/zod';
import { actions as actionsProfile } from 'modules/profile';
import { useCallback, useState } from 'react';
import { useForm, useFormContext } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useFinishAddingTwoFaAlternativeEmail, useStartAddingTwoFaAlternativeEmail } from 'shared/data/webApp/v1';

import { fieldNames } from './BackupEmailUpdatePopup.constants';
import { schema } from './BackupEmailUpdatePopup.schema';
import { BackupEmailUpdateForm, BackupEmailUpdatePopupProps, Stages } from './BackupEmailUpdatePopup.types';
import { messages } from './components/StepEmailConfirm/StepEmailConfirm.messages';

export const useBackupEmailUpdateForm = (props: BackupEmailUpdatePopupProps) => {
    const { onClose } = props;

    const dispatch = useDispatch();
    const [stage, setStage] = useState<ValueOf<Stages>>('emailEnter');

    const gotoEmailEnter = useCallback(() => {
        setStage('emailEnter');
    }, []);

    const gotoEmailVerify = useCallback(() => {
        setStage('emailVerify');
    }, []);

    const form = useForm<BackupEmailUpdateForm>({
        defaultValues: {
            [fieldNames.stateId]: null,
            [fieldNames.email]: '',
            [fieldNames.verificationCode]: '',
        },
        resolver: zodResolver(schema[stage]),
    });

    const fieldEmail = form.watch(fieldNames.email);

    const { mutate: startAdding, isLoading: isLoadingStartAdding } = useStartAddingTwoFaAlternativeEmail({
        onSuccess: (data) => {
            form.setValue(fieldNames.stateId, data.stateId);
            gotoEmailVerify();
        },
    });

    const { mutate: finishAdding, isLoading: isLoadingFinishAdding } = useFinishAddingTwoFaAlternativeEmail({
        onSuccess: () => {
            dispatch(actionsProfile.changeTwoFaAlternativeEmailIsEnabled(true));
            toast.success(messages.onSuccess);
            onClose();
        },
    });

    const formSubmit = form.handleSubmit((data) => {
        switch (stage) {
            case 'emailEnter':
                startAdding({ body: { alternativeEmail: data.email } });

                break;
            case 'emailVerify':
                if (data.stateId) {
                    finishAdding({ body: { stateId: data.stateId, alternativeEmailCode: data.verificationCode } });
                }

                break;
        }
    });

    const resendCode = useCallback(() => {
        startAdding({ body: { alternativeEmail: fieldEmail } });
    }, [fieldEmail, startAdding]);

    return {
        form,
        formSubmit,
        stage,
        gotoEmailEnter,
        gotoEmailVerify,
        resendCode,
        progress: isLoadingStartAdding || isLoadingFinishAdding,
    };
};

export const useBackupMethodsUpdateEmailFormContext = () => {
    return useFormContext<BackupEmailUpdateForm>();
};
