import { Guid, Reference } from '@approvalmax/types';
import { domain } from 'modules/data';

import { XeroContext } from './xero/XeroContext';

export function getConditionsByFieldSystemPurposeOrId(
    authorRules: domain.MatrixCondition[],
    fieldSystemPurposeOrId: domain.FieldSystemPurpose | Guid
) {
    return authorRules.filter((rule) => {
        return rule.fieldSystemPurpose === fieldSystemPurposeOrId || rule.fieldId === fieldSystemPurposeOrId;
    });
}

export const getExactValuesFromConditions = (
    conditions: domain.MatrixCondition[],
    conditionType: domain.ConditionType.ExactValuesCondition | domain.ConditionType.NegativeExactValuesCondition
): Reference[] => {
    if (!Array.isArray(conditions)) return [];

    return conditions
        .filter((condition) => condition.conditionType === conditionType)
        .flatMap((condition: domain.ExactValuesCondition) => condition.exactValues);
};

export const getGroupOfXeroAccountsFromConditions = (
    conditions: domain.MatrixCondition[],
    conditionType: domain.ConditionType.ExactValuesCondition | domain.ConditionType.NegativeExactValuesCondition
): domain.ExactValuesGroup[] => {
    if (!Array.isArray(conditions)) return [];

    return conditions
        .filter((condition) => condition.conditionType === conditionType)
        .flatMap((condition: domain.ExactValuesCondition) => condition.groupOfXeroAccounts || []);
};

export function checkFieldValueWithRules(params: {
    rules: domain.MatrixCondition[];
    fieldSystemPurposeOrId: domain.FieldSystemPurpose | Guid;
    fieldValueId: Guid;
    groupOfXeroAccountsVersions?: XeroContext['groupOfXeroAccountsVersions'];
}): boolean {
    const { rules, fieldSystemPurposeOrId, fieldValueId, groupOfXeroAccountsVersions = [] } = params;
    const conditions = getConditionsByFieldSystemPurposeOrId(rules, fieldSystemPurposeOrId);
    const isXeroAccount = fieldSystemPurposeOrId === domain.FieldSystemPurpose.XeroAccount;

    if (conditions.length === 0) {
        // No conditions default to valid
        return true;
    }

    const positiveExactValues = getExactValuesFromConditions(conditions, domain.ConditionType.ExactValuesCondition);
    const negativeExactValues = getExactValuesFromConditions(
        conditions,
        domain.ConditionType.NegativeExactValuesCondition
    );
    const positiveGroupOfXeroAccounts = getGroupOfXeroAccountsFromConditions(
        conditions,
        domain.ConditionType.ExactValuesCondition
    );
    const negativeGroupOfXeroAccounts = getGroupOfXeroAccountsFromConditions(
        conditions,
        domain.ConditionType.NegativeExactValuesCondition
    );

    const negativeConditions = conditions.filter(
        (c) => c.conditionType === domain.ConditionType.NegativeExactValuesCondition
    );

    if (positiveExactValues.some(({ id }) => id === fieldValueId)) {
        // Positive match overrides negative
        return true;
    }

    if (isXeroAccount && positiveGroupOfXeroAccounts.length > 0) {
        const hasMatchingAccount = positiveGroupOfXeroAccounts.some((group) => {
            const matchingVersion = groupOfXeroAccountsVersions.find(
                (version) => version.groupOfXeroAccountsId === group.id
            );

            return matchingVersion?.values.some((value) => value.fieldValueId === fieldValueId);
        });

        if (hasMatchingAccount) {
            return true;
        }
    }

    const hasNoMatchingNegativeExactValues =
        negativeConditions.length > 0 && negativeExactValues.every(({ id }) => id !== fieldValueId);

    const hasNoMatchingNegativeGroups =
        !isXeroAccount ||
        (negativeGroupOfXeroAccounts.length > 0
            ? negativeGroupOfXeroAccounts.every((group) => {
                  const matchingVersion = groupOfXeroAccountsVersions.find(
                      (version) => version.groupOfXeroAccountsId === group.id
                  );

                  return matchingVersion?.values.every((value) => value.fieldValueId !== fieldValueId);
              })
            : true);

    if (hasNoMatchingNegativeExactValues && hasNoMatchingNegativeGroups) {
        // No matches found in any negative conditions, so the value is valid
        return true;
    }

    return false;
}
