import { intl, mathService } from '@approvalmax/utils';
import { backend, domain } from 'modules/data';
import moment from 'moment';

export const sortCustomFields = <T extends domain.NetSuiteCustomField>(
    customFields: T[],
    companyCustomFields: backend.NetSuiteCompanyCustomField[]
) => {
    return [...customFields].sort((field1, field2) => {
        const fieldReference1 = companyCustomFields.find(
            (companyField) => companyField.NetSuiteField.Field.ScriptId === field1.scriptId
        );

        const fieldReference2 = companyCustomFields.find(
            (companyField) => companyField.NetSuiteField.Field.ScriptId === field2.scriptId
        );

        if (fieldReference1 && fieldReference2) {
            // Custom segments fields first
            if (
                fieldReference1.NetSuiteField.Field.ScriptId.startsWith('cseg') &&
                !fieldReference2.NetSuiteField.Field.ScriptId.startsWith('cseg')
            ) {
                return -1;
            }

            if (
                !fieldReference1.NetSuiteField.Field.ScriptId.startsWith('cseg') &&
                fieldReference2.NetSuiteField.Field.ScriptId.startsWith('cseg')
            ) {
                return 1;
            }

            return fieldReference1.NetSuiteField.Order - fieldReference2.NetSuiteField.Order;
        }

        return 0;
    });
};

export const getNetSuiteAmountText = (
    amount: number,
    exchangeRate: number | null,
    requestCurrency: string,
    displayCurrency?: string
) => {
    if (!exchangeRate) {
        return '';
    }

    const amountText = intl.formatCurrency(
        mathService.multiply(amount, exchangeRate),
        displayCurrency || requestCurrency,
        2,
        false
    );

    if (exchangeRate !== 1 && displayCurrency && displayCurrency !== requestCurrency) {
        const amountRequestCurrency = intl.formatCurrency(amount, requestCurrency, 2);

        return `${amountText} (${amountRequestCurrency})`;
    }

    return amountText;
};

export const getCustomFieldValue = (field: domain.NetSuiteCustomField, shouldFormatDate = false) => {
    if (field.fieldType === 'TimeOfDay') {
        return moment.utc(field.valueDate).format('HH:mm:ss');
    }

    if (field.fieldType === 'Date') {
        return moment.utc(field.valueDate).format('ll');
    }

    if (field.fieldType === 'Datetime') {
        return moment.utc(field.valueDate).format('lll');
    }

    if (field.fieldType === 'Hyperlink') {
        return `<a href="${field.valueString}" target="_blank" rel="noopener noreferrer">${field.valueString}</a>`;
    }

    if (typeof field.valueBoolean === 'boolean') {
        return field.valueBoolean;
    }

    if (typeof field.valueDate === 'string') {
        return shouldFormatDate ? moment.utc(field.valueDate).format('ll') : field.valueDate;
    }

    if (typeof field.valueDecimal === 'number') {
        return field.fieldType === 'Percent' ? `${field.valueDecimal.toString()}%` : field.valueDecimal.toString();
    }

    if (typeof field.valueLong === 'number') {
        return field.valueLong.toString();
    }

    if (field.valueRef !== null) {
        return field.valueRef.text;
    }

    if (Array.isArray(field.valueRefs)) {
        return field.valueRefs.map((ref) => ref.text).join('\n');
    }

    return field.valueString || null;
};

export const isFieldVisible = (documentFieldState: domain.DocumentFieldState) =>
    documentFieldState !== domain.DocumentFieldState.Hidden;
