import { useApiContext } from '@approvalmax/data';
import { selectors } from 'modules/common';
import { FC, memo, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-use';
import { useApiRequestSuccess } from 'services/api/hooks';
import { errorService } from 'services/error';
import { storageService } from 'services/storage';
import { useExtendSession } from 'shared/data/webApp/v1';

import { excludedPaths } from './AuthErrorPopup.constants';
import { useAuthDeadlines, useTime } from './AuthModule.hooks';
import AuthErrorPopup from './components/AuthErrorPopup/AuthErrorPopup';
import AuthWarningPopup from './components/AuthWarningPopup/AuthWarningPopup';

const AuthModule: FC = memo(() => {
    const { pathname } = useLocation();
    const { authErrorDeadlineMs, authWarningDeadlineMs } = useAuthDeadlines();
    const { apiError, setSessionExpirationDate, setWebAppVersion } = useApiContext();
    const isExcludedPath = pathname && excludedPaths.some((path) => pathname.includes(path));
    const isAuthError = !isExcludedPath && apiError && errorService.isAuthError(apiError);
    const authenticated = useSelector(selectors.session.authenticated);
    const profile = useSelector(selectors.profile.findProfile);

    const [popup, setPopup] = useState<'warning' | 'error' | null>(null);

    const isModuleDisabled = !authenticated || !profile || isExcludedPath;
    const nowMs = useTime(!isModuleDisabled);
    const remainingTimeMs = authErrorDeadlineMs - nowMs;

    useApiRequestSuccess(
        useCallback(
            ({ sessionExpirationDate, webAppVersion }) => {
                if (sessionExpirationDate) {
                    setSessionExpirationDate(sessionExpirationDate);
                }

                if (webAppVersion) {
                    setWebAppVersion(webAppVersion);
                }
            },
            [setSessionExpirationDate, setWebAppVersion]
        )
    );

    const { mutate: extendSession, isLoading: isLoadingExtendSession } = useExtendSession();

    useEffect(() => {
        if (isAuthError) {
            setPopup('error');
        }
    }, [isAuthError]);

    const handleExtendSession = useCallback(() => {
        extendSession({});
    }, [extendSession]);

    useEffect(() => {
        if (!authErrorDeadlineMs || isAuthError) {
            return;
        }

        if (nowMs >= authErrorDeadlineMs) {
            setPopup((prevPopUp) => {
                if (prevPopUp !== 'error') {
                    storageService.setAuthenticated(false);

                    return 'error';
                }

                return prevPopUp;
            });
        } else if (nowMs >= authWarningDeadlineMs) {
            setPopup((prevPopUp) => (prevPopUp !== 'error' ? 'warning' : prevPopUp));
        } else {
            setPopup(null);
        }
    }, [authErrorDeadlineMs, authWarningDeadlineMs, isAuthError, nowMs]);

    const handleCloseErrorPopup = useCallback(() => {
        setPopup(null);
    }, []);

    if (isModuleDisabled) return null;

    return (
        <>
            {/* edge-case because `remainingTimeMs` is a timer and because
             of it we have popup internal rerender every second across all web-app */}
            {popup === 'warning' && (
                <AuthWarningPopup
                    open
                    onRequestClose={handleExtendSession}
                    remainingTimeMs={remainingTimeMs}
                    loading={isLoadingExtendSession}
                />
            )}

            {popup === 'error' && <AuthErrorPopup open onRequestClose={handleCloseErrorPopup} />}
        </>
    );
});

AuthModule.displayName = 'AuthModule';

export default AuthModule;
