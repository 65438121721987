import type { ApiError } from '@approvalmax/types';
import { routerHelpers } from '@approvalmax/utils';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { Env } from '../configs';
import { getApiInstance, getApiUrl } from '../helpers/getApi';
import { getAuthorization } from '../helpers/getAuthorization';
import { getQueryKey } from '../helpers/getQueryKey';
import { useHandleApiCall } from '../hooks/useHandleApiCall';
import { ApiBaseAnswer, ApiSource, MutateDataParams, RequestHeaders, UseMutateOptions } from '../types';

export const createUseMutate = (defaultApiSource: ApiSource, defaultEnv: Env, getHeaders?: () => RequestHeaders) => {
    return <RequestData extends MutateDataParams, ResponseData extends Record<string, any> | unknown = ApiBaseAnswer>(
        path: string,
        options: UseMutateOptions<ResponseData, RequestData> & {
            // TODO: Move skipInvalidateQueries to the UseMutateOptions
            /**
             * If you would like to perform manual cache update, you might want to skip
             * a fetching relative query
             */
            skipInvalidateQueries?: true;
            formData?: boolean;
        } = {}
    ) => {
        const {
            apiSource = defaultApiSource,
            apiVersion = 'v1',
            method = 'post',
            mutationOptions,
            mapToCamelCase,
            skipToastForErrorCodes,
            formData,
            accessToken,
        } = options;

        const queryClient = useQueryClient();
        const handleApiCall = useHandleApiCall<ResponseData>({ mapToCamelCase, skipToastForErrorCodes });

        return useMutation<ResponseData, ApiError, RequestData>(
            async ({ params, body }) => {
                const baseURL = getApiUrl(apiSource, defaultEnv, apiVersion);
                const url = routerHelpers.pathToUrl(path, params?.path);
                const Authorization = getAuthorization(defaultApiSource, defaultEnv) ?? accessToken;
                const isFormData = formData || body instanceof FormData;
                const contentTypeHeader = { 'Content-Type': isFormData ? 'multipart/form-data' : 'application/json' };
                const apiFn = getApiInstance(apiSource, apiVersion, getHeaders?.());

                return handleApiCall(
                    apiFn({
                        baseURL,
                        url,
                        method,
                        headers: {
                            ...(Authorization && { Authorization }),
                            ...contentTypeHeader,
                        },
                        params: params?.query,
                        data: body,
                    })
                );
            },
            {
                mutationKey: [path],
                ...mutationOptions,
                onSuccess: async (data, variables, context) => {
                    if (!options.skipInvalidateQueries) {
                        const url = routerHelpers.pathToUrl(path, variables.params?.path);

                        await queryClient.invalidateQueries({
                            queryKey: getQueryKey(url, variables.params?.query),
                        });
                    }

                    mutationOptions?.onSuccess?.(data, variables, context);
                },
            }
        );
    };
};
