import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('pages.RequestList.XeroBilledStatusSection.XeroMarkAsBilledPopup', {
    markPopupTitle: 'Mark PO as Billed',
    markButtonText: 'Mark as billed',
    markButtonTitle: 'Mark Purchase Order as Billed',
    markDescription: 'Please provide a comment (optional) to let everyone know why this PO is marked as billed.',
    unmarkPopupTitle: 'Unmark PO as Billed',
    unmarkButtonText: 'Unmark as Billed',
    unmarkButtonTitle: 'Unmark Purchase Order as Billed',
    unmarkDescription: 'Please provide a comment (optional) to let everyone know why this PO is unmarked as billed.',
    markedSuccessToastText: 'Purchase Order has been marked as billed',
    unmarkedSuccessToastText: 'Purchase Order has been unmarked as billed',
    goTo: 'Go to {displayName}',
});
