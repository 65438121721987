import { RequestParams, RequestQueryOptions } from '@approvalmax/data';
import { useGet } from 'shared/data';

import { requestsApiPaths } from '../paths';
import { GetAmaxPayXeroPaymentRefresh } from './useGetAmaxPayXeroPaymentRefresh.types';

export const useGetAmaxPayXeroPaymentRefresh = (
    params: RequestParams<GetAmaxPayXeroPaymentRefresh>,
    queryOptions?: RequestQueryOptions<GetAmaxPayXeroPaymentRefresh>
) => {
    return useGet(requestsApiPaths.amaxPayXeroPaymentRefresh, {
        params,
        queryOptions,
        apiVersion: 'v1',
    });
};
