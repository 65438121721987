import { RequestMutationOptions } from '@approvalmax/data';
import { useMutate } from 'shared/data';

import { budgetsApiPaths } from '../paths';
import { UploadXeroBudget } from './useUploadXeroBudget.types';

export const useUploadXeroBudget = (mutationOptions?: RequestMutationOptions<UploadXeroBudget>) => {
    return useMutate(budgetsApiPaths.uploadXeroBudget, {
        mutationOptions: {
            ...mutationOptions,
            onSettled: async (data, error, variables, context) => {
                mutationOptions?.onSettled?.(data, error, variables, context);
            },
        },
        apiVersion: 'v1',
    });
};
