import { backend, domain } from 'modules/data';

import { getCustomFieldsTransfer } from '../../../helpers';

export const getTransferExpenseLine = (
    expenseLine: domain.NetSuiteExpenseLineExpenseReport
): backend.transfers.IntegrationNetSuiteExpenseLineExpenseReport => ({
    lineNumber: expenseLine.lineNumber,
    amount: expenseLine.amount,
    categoryId: expenseLine.category?.id || null,
    currencyId: expenseLine.currency?.id || null,
    customerId: expenseLine.customer?.id || null,
    departmentId: expenseLine.department?.id || null,
    classId: expenseLine.class?.id || null,
    locationId: expenseLine.location?.id || null,
    exchangeRate: expenseLine.exchangeRate || null,
    expenseAccountId: expenseLine.expenseAccount?.id || null,
    expenseDate: expenseLine.expenseDate || null,
    foreignAmount: expenseLine.foreignAmount,
    grossAmount: expenseLine.grossAmount,
    isBillable: expenseLine.customer ? Boolean(expenseLine.isBillable) : null,
    isCorporateCreditCard: expenseLine.isCorporateCreditCard || null,
    isNonReimbursable: expenseLine.isNonReimbursable || null,
    isReceipt: expenseLine.isReceipt || null,
    memo: expenseLine.memo || null,
    quantity: expenseLine.quantity || null,
    refLineNumber: expenseLine.refLineNumber || null,
    taxCodeId: expenseLine.taxCode?.id ?? null,
    taxRate: expenseLine.taxRate || null,
    taxAmount: expenseLine.taxAmount,
    unitPrice: expenseLine.unitPrice || null,
    fileRecordId: expenseLine.attachment?.id || null,
    customFields: getCustomFieldsTransfer(expenseLine.customFields),
});
