import { AmIcon, FileSearchIcon } from '@approvalmax/ui';
import { constants } from '@approvalmax/utils';
import {
    AutoApprovalIcon,
    BillToPoIcon,
    NavContactsIcon,
    NavManualJournalIcon,
    VersionControlIcon,
} from 'modules/sprites';

// Vendor
export const DISPLAY_NAME_MAX_LENGTH = 500;
export const COMPANY_NAME_MAX_LENGTH = 100;
export const TITLE_MAX_LENGTH = 16;
export const FIRST_NAME_MAX_LENGTH = 100;
export const MIDDLE_NAME_MAX_LENGTH = 100;
export const LAST_NAME_MAX_LENGTH = 100;
export const SUFFIX_MAX_LENGTH = 16;
export const EMAIL_ADDRESS_MAX_LENGTH = 100;
export const WEBSITE_MAX_LENGTH = 75;
export const PHONE_MAX_LENGTH = 30;
export const MOBILE_MAX_LENGTH = 30;
export const FAX_MAX_LENGTH = 30;
export const MAILING_ADDRESS_MAX_LENGTH = 2000;
export const BUSINESS_NUMBER_MAX_LENGTH = 100;

// Address
export const ADDRESS_STREET_MAX_LENGTH = 255;
export const ADDRESS_REGION_MAX_LENGTH = 255;
export const ADDRESS_POSTCODE_MAX_LENGTH = 30;
export const ADDRESS_CITY_MAX_LENGTH = 255;
export const ADDRESS_COUNTRY_MAX_LENGTH = 255;

// Bill
export const BILL_NUMBER_MAX_LENGTH = 21;

// Payments
export const BANK_ACCOUNT_NAME_MAX_LENGTH = 100;
export const BANK_BRANCH_IDENTIFIER_MAX_LENGTH = 7;
export const BANK_ACCOUNT_NUMBER_MAX_LENGTH = 9;
export const STATEMENT_MAX_LENGTH = 18;

// Line items
export const LINE_ITEM_DESCRIPTION_MAX_LENGTH = 4000;

// Email To Vendor
export const VENDOR_EMAIL_MAX_LENGTH = 100;
export const VENDOR_EMAIL_CC_MAX_LENGTH = 200;

// Email To Customer
export const CUSTOMER_EMAIL_MAX_LENGTH = 100;
export const CUSTOMER_EMAIL_CC_MAX_LENGTH = 200;

// Expense
export const REFERENCE_MAX_LENGTH = 21;

// Memo
export const MEMO_MAX_LENGTH = 4000;
export const VENDOR_MESSAGE_MAX_LENGTH = 1000;
export const CUSTOMER_MEMO_MAX_LENGTH = 1000;

// Shipping
export const SHIPPING_METHOD_MAX_LENGTH = 31;
export const SHIP_VIA_MAX_LENGTH = 31;
export const TRACKING_NUMBER_MAX_LENGTH = 31;
export const SHIPPING_ADDRESS_MAX_LENGTH = 2000;

// Numeric
export const TOTAL_MAX_AMOUNT = 99999999999;
export const TOTAL_MIN_AMOUNT = 0;
export const MATRIX_TOTAL_MAX_AMOUNT = 100000000000;
export const MAX_AMOUNT = 99999999999;
export const MIN_AMOUNT = -MAX_AMOUNT;
export const PRECISION_SMALL = 2;
export const PRECISION_BIG = 4;
export const PRECISION_TO_SERVER_STRING = 7;
export const DISCOUNT_MIN = 0;
export const DISCOUNT_VALUE_MAX = 99_999_999_999;
export const DISCOUNT_PERCENT_MAX = 100;

// Advanced plan
export const QBO_AUTO_APPROVAL_LEARN_MORE_LINK =
    'https://support.approvalmax.com/portal/en/kb/articles/what-is-auto-approval-step-quickbooks-online';

export const QBO_REVIEW_STEP_LEARN_MORE_LINK =
    'https://support.approvalmax.com/portal/en/kb/articles/what-is-the-review-step-quickbooks-online';

export const QBO_AMQV_FEATURES = [
    {
        href: 'https://support.approvalmax.com/portal/en/kb/approvalmax-1/approvalmax-standalone-solution',
        label: 'Stand-alone workflows',
        icon: AmIcon,
    },
];

export const QBO_AMQB_FEATURES = [
    {
        href: 'https://support.approvalmax.com/portal/en/kb/approvalmax-1/approvalmax-for-quickbooks-online/matching',
        label: 'Bill-to-PO matching',
        icon: BillToPoIcon,
    },
    {
        href: QBO_AUTO_APPROVAL_LEARN_MORE_LINK,
        label: 'Auto-Approval step',
        icon: AutoApprovalIcon,
    },
    {
        href: 'https://support.approvalmax.com/portal/en/kb/approvalmax-1/approvalmax-for-quickbooks-online/vendor-workflow',
        label: 'Vendor workflow',
        icon: NavContactsIcon,
    },
    {
        href: 'https://support.approvalmax.com/portal/en/kb/approvalmax-1/approvalmax-for-quickbooks-online/journal-entry-workflow',
        label: 'Journal Entry workflow',
        icon: NavManualJournalIcon,
    },
    {
        href: 'https://support.approvalmax.com/portal/en/kb/articles/workflow-version-control-quickbooks-online',
        label: 'Workflow history',
        icon: VersionControlIcon,
    },
    {
        href: QBO_REVIEW_STEP_LEARN_MORE_LINK,
        label: 'Request Review Step',
        icon: FileSearchIcon,
    },
    ...QBO_AMQV_FEATURES,
];

export const QBO_DEXT_LEARN_MORE_LINK =
    'https://support.approvalmax.com/portal/en/kb/articles/how-can-i-establish-a-connection-between-receipt-bank-approvalmax-and-quickbooks-online';

export const QBO_ALLOWED_FILE_EXTENSIONS = [
    '.pdf',
    '.jpeg',
    '.jpg',
    '.png',
    '.doc',
    '.docx',
    '.xlsx',
    '.xls',
    '.csv',
    '.tiff',
    '.gif',
    '.xml',
    '.eps',
    '.ai',
    '.ods',
    '.rtf',
    '.txt',
];

export const qboAllowedFileExtensions: (keyof typeof constants.extensions)[] = [
    'pdf',
    'jpg',
    'png',
    'doc',
    'docx',
    'xlsx',
    'xls',
    'csv',
    'tiff',
    'gif',
    'xml',
    'postscript',
    'ods',
    'rtf',
    'text',
];
