import { forwardRef, memo, useCallback, useContext } from 'react';
import { useController } from 'react-hook-form';

import Checkbox from '../../Checkbox';
import { CheckboxProps } from '../../Checkbox.types';
import { GroupContext } from '../Group/Group.context';
import { ControllerProps } from './Controller.types';

/**
 * The `Checkbox.Controller` allows you to use the `Checkbox` with the `react-hook-form` library.
 * They take the same properties as the `Checkbox` component,
 * plus the `control` property to bind it with the library and `rules` for validation.
 *
 * You can use control without the `Controller` subcomponent if it is in a `Form` or `Form.Part` component.
 */
const Controller = memo(
    forwardRef<HTMLInputElement, ControllerProps>((props, ref) => {
        const { control, name, rules, children, onChange: onChangeProp, ...restProps } = props;

        const { contextProps } = useContext(GroupContext);

        const {
            field: { onChange, ...field },
            fieldState,
        } = useController({
            control,
            name: name || contextProps.name || 'name',
            rules: { required: restProps.required },
        });
        const { value, ...restField } = field;

        const handleChange = useCallback<NonNullable<CheckboxProps['onChange']>>(
            (value, event) => {
                onChangeProp?.(value, event);
                onChange(value);
            },
            [onChange, onChangeProp]
        );

        return (
            <Checkbox {...restField} {...fieldState} {...restProps} ref={ref} checked={value} onChange={handleChange}>
                {children}
            </Checkbox>
        );
    })
);

export default Controller;
