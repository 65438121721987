import Cookies from 'js-cookie';

import { sessionNotificationOptions, sessionNotificationPrefix } from './useSessionNotification.constants';

export const createExpiresCookie = (hoursMaxAge: number): Cookies.CookieAttributes => ({
    ...sessionNotificationOptions,
    expires: hoursMaxAge / 24, // convert to days
});

export const clearSessionNotifications = () => {
    const cookiesKeys = Object.keys(Cookies.get());

    cookiesKeys.map((item) => {
        if (item.startsWith(sessionNotificationPrefix)) {
            Cookies.remove(item, sessionNotificationOptions);
        }
    });
};
