import { FC, memo } from 'react';

import { Root, Sub } from './Label.styles';
import { LabelProps } from './Label.types';

/**
 * A short text description for the form field
 */
const Label: FC<LabelProps> = memo((props) => {
    const {
        children,
        size = 'small',
        spacing = '0 0 4',
        color = 'midnight70',
        required,
        fontWeight,
        font = 'label',
        wrap,
        ...restProps
    } = props;

    return (
        <Root color={color} fontSize={size} spacing={spacing} fontWeight={fontWeight} font={font} wrap={wrap}>
            <label {...restProps}>
                {children}

                {required && <Sub> *</Sub>}
            </label>
        </Root>
    );
});

export default Label;
