import { domHelpers, hooks } from '@approvalmax/utils';
import { FC, KeyboardEventHandler, memo, useCallback, useEffect, useId, useState } from 'react';

import { Box } from '../Box/Box';
import { Flex } from '../Flex/Flex';
import { useLocalStorageSave } from './Collapse.hooks';
import { Actions, AnimatedBox, Header, Icon, Name } from './Collapse.styles';
import { CollapseProps } from './Collapse.types';

/**
 * The Collapse component allows the user to collapse and expand sections of content.
 */
export const Collapse: FC<CollapseProps> = memo((props) => {
    const {
        localStorageKey,
        name,
        headerContent,
        headerJustifyContent = 'space-between',
        actions,
        collapsed,
        bordered,
        radius,
        spacing,
        onCollapse,
        children,
        alignCollapseIcon = 'left',
        size = 'large',
        startIcon,
        color,
        headerInRow,
        headerSpacing,
    } = props;

    const [collapsedState, setCollapsedState] = useState(collapsed);
    const { animatedStyles, animatedRef } = hooks.useAnimatedHeight(!collapsedState);
    const { saveToLocalStorage } = useLocalStorageSave({ localStorageKey, collapsed, setCollapsedState });
    const { inputRef, parentProps } = hooks.useCaptureFocus();
    const id = useId();
    const headerId = `${id}-header`;
    const contentId = `${id}-content`;

    const defaultHeaderSpacing = size === 'large' ? '16 24' : '8';

    useEffect(() => {
        setCollapsedState((state) => {
            if (collapsed === undefined || collapsed === state) return state;

            return collapsed;
        });
    }, [collapsed, setCollapsedState]);

    useEffect(() => {
        onCollapse?.(collapsedState);
    }, [collapsedState, onCollapse]);

    const changeCollapse = useCallback(() => {
        setCollapsedState(!collapsedState);
        saveToLocalStorage(!collapsedState);
    }, [collapsedState, saveToLocalStorage]);

    const handleKeyDown = useCallback<KeyboardEventHandler<HTMLDivElement>>(
        (event) => {
            if (['Enter', ' '].includes(event.key)) {
                changeCollapse();
            }
        },
        [changeCollapse]
    );

    return (
        <Box bordered={color ? undefined : bordered} radius={radius} borderInside color={color} data-qa='collapse'>
            <Header
                id={headerId}
                spacing={headerSpacing || defaultHeaderSpacing}
                $bgColor={color}
                radius={radius}
                $collapsed={collapsedState}
                onClick={changeCollapse}
                onKeyDown={handleKeyDown}
                role='button'
                data-qa='collapse-button'
                tabIndex={0}
                aria-expanded={!collapsedState}
                aria-controls={contentId}
                {...parentProps}
                ref={inputRef}
            >
                <Flex
                    spacing={size === 'large' ? '24' : '8'}
                    justifyContent={headerJustifyContent}
                    alignItems='center'
                    wrap={headerInRow ? false : undefined}
                >
                    <Flex
                        spacing='8'
                        alignItems='center'
                        width='max-content'
                        grow={headerJustifyContent === 'space-between' ? 1 : 0}
                        shrink={0}
                        wrap={false}
                    >
                        {alignCollapseIcon === 'left' && <Icon size={size === 'large' ? 20 : 12} />}

                        {startIcon}

                        <Name
                            color='midnight80'
                            {...(size === 'large'
                                ? { font: 'headline', fontSize: 'xxsmall', fontWeight: 'medium' }
                                : { font: 'label', fontSize: 'small', fontWeight: 'regular' })}
                        >
                            {name}
                        </Name>

                        {alignCollapseIcon === 'right' && <Icon size={size === 'large' ? 20 : 12} />}
                    </Flex>

                    {(actions || headerContent) && (
                        <Flex spacing='8' alignItems='center'>
                            {actions && <Actions onClick={domHelpers.stopPropagation}>{actions}</Actions>}

                            {headerContent}
                        </Flex>
                    )}
                </Flex>
            </Header>

            <AnimatedBox style={animatedStyles}>
                <div ref={animatedRef}>
                    <Box
                        id={contentId}
                        spacing={spacing}
                        role='region'
                        aria-labelledby={headerId}
                        data-qa='collapse-content'
                    >
                        {children}
                    </Box>
                </div>
            </AnimatedBox>
        </Box>
    );
});

Collapse.displayName = 'Collapse';
