import { AmCaptureImage, CheckIcon } from '@approvalmax/ui';
import { Box, Button, Flex, Text, toast } from '@approvalmax/ui/src/components';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { selectors } from 'modules/common';
import { domain } from 'modules/data';
import { useSelector } from 'modules/react-redux';
import { FC, useCallback } from 'react';
import { commonErrorMessages } from 'services/error/messages';
import { isValidOcrImage } from 'shared/helpers';
import { ocrIsLoadingState } from 'shared/states';

import { useEditRequestForm } from '../../../../hooks/useEditRequestForm';
import { getRequestTransfer } from '../../../../selectors/requestSelectors';
import { customGetRequestTransferState } from '../../../../states';
import { useWrappedCheckOcrStatus } from './OcrUploadPanel.hooks';
import { messages } from './OcrUploadPanel.messages';
import { ocrAttachmentIdState } from './OcrUploadPanel.states';
import { OcrUploadPanelProps } from './OcrUploadPanel.types';

export const OcrUploadPanel: FC<OcrUploadPanelProps> = (props) => {
    const { request, attachment } = props;

    const [ocrIsLoading, setOcrIsLoading] = useAtom(ocrIsLoadingState);
    const { mutateAsync: editRequest } = useEditRequestForm();

    const setOcrAttachmentId = useSetAtom(ocrAttachmentIdState);
    const customGetRequestTransfer = useAtomValue(customGetRequestTransferState);

    const requestTransfer = useSelector((state) => {
        if (request.integrationType !== domain.IntegrationType.NetSuite) {
            return getRequestTransfer(state, request);
        }

        return null;
    });
    const { checkOcrStatus } = useWrappedCheckOcrStatus(request, attachment.id);

    const onUpload = useCallback(async () => {
        if (attachment.fileType == selectors.types.FileType.Image) {
            const isValidImage = await isValidOcrImage(attachment.url);

            if (!isValidImage) {
                toast.error(commonErrorMessages.ocrImageResolutionError({ fileName: attachment.name }));

                return;
            }
        }

        let newRequestTransfer = requestTransfer;

        if (customGetRequestTransfer && !requestTransfer) {
            newRequestTransfer = customGetRequestTransfer();
        }

        try {
            setOcrIsLoading(true);
            await editRequest({
                body: { ...newRequestTransfer, ocrAttachment: attachment.id, currency: request.companyCurrency },
            });
            await checkOcrStatus(4000);
        } catch {
            setOcrIsLoading(false);
        }
    }, [
        attachment.fileType,
        attachment.id,
        attachment.name,
        attachment.url,
        checkOcrStatus,
        customGetRequestTransfer,
        editRequest,
        request.companyCurrency,
        requestTransfer,
        setOcrIsLoading,
    ]);

    const onCancel = useCallback(() => {
        setOcrAttachmentId(null);
    }, [setOcrAttachmentId]);

    return (
        <Box spacing='16 24 0'>
            <Flex container justifyContent='space-between' alignItems='center' wrap={true} minWidth={300}>
                <Flex wrap={false} spacing='16' alignItems='center' grow={1} basis={260}>
                    <Box width={126}>
                        <AmCaptureImage height={48} width={126} />
                    </Box>

                    <Text wrap wordBreak='break-word' font='headline' fontSize='xsmall' fontWeight='medium'>
                        {messages.description}
                    </Text>
                </Flex>

                <Flex spacing='16' wrap={false} alignItems='center'>
                    <Button
                        size='medium'
                        color='midnight80'
                        disabled={ocrIsLoading}
                        onClick={onCancel}
                        variant='outline'
                    >
                        {messages.cancelButtonTitle}
                    </Button>

                    <Button
                        size='medium'
                        startIcon={<CheckIcon />}
                        onClick={onUpload}
                        color='green80'
                        progress={ocrIsLoading}
                    >
                        {messages.confirmButtonTitle}
                    </Button>
                </Flex>
            </Flex>
        </Box>
    );
};

OcrUploadPanel.displayName = 'OcrUploadPanel';
