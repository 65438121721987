import moment from 'moment';

import { NetSuiteExpenseLineExtendedExpenseReport } from '../../../data/types';
import { extendExpenseLine } from '../extendExpenseLine/extendExpenseLine';

export const createEmptyExpenseLineExtended = (
    lineNumber: number,
    refLineNumber: number = 1
): NetSuiteExpenseLineExtendedExpenseReport => {
    return extendExpenseLine({
        lineNumber,
        refLineNumber,
        amount: null,
        class: null,
        customer: null,
        department: null,
        grossAmount: null,
        isBillable: false,
        location: null,
        taxAmount: null,
        taxCode: null,
        taxRate: null,
        category: null,
        memo: null,
        currency: null,
        foreignAmount: null,
        unitPrice: null,
        quantity: null,
        isReceipt: false,
        isNonReimbursable: false,
        isCorporateCreditCard: false,
        expenseAccount: null,
        expenseDate: moment().toISOString(),
        exchangeRate: null,
        customFields: [],
        attachment: null,
    });
};
