import { Flex, Grid } from '@approvalmax/ui/src/components';
import { Children, FC, PropsWithChildren } from 'react';

export const SummarySection: FC<PropsWithChildren> = (props) => {
    const { children } = props;

    const elements = Children.toArray(children).filter(Boolean);
    const count = elements.length;

    return (
        <Grid gridAutoFlow='column' gridAutoColumns='1fr' gap='24px'>
            {Children.map(elements, (child, index) => (
                <Flex justifyContent={count === index + 1 ? 'end' : 'start'} key={index}>
                    {child}
                </Flex>
            ))}
        </Grid>
    );
};

SummarySection.displayName = 'SummarySection';
