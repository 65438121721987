import { Cin7Logo, NetSuiteLogo, QBooksLogo, XeroLogo } from '@approvalmax/ui';
import { domain } from 'modules/data';
import { ConnectQboMedium, ConnectQboMediumDisabled } from 'modules/sprites';
import { useMemo } from 'react';
import { useUserBetaFeatures } from 'shared/hooks';

import { integrationDocumentationUrls } from './IntegrationCard.constants';
import { messages } from './IntegrationCard.messages';
import { Meta } from './IntegrationCard.types';

export const useMeta = () => {
    const { isCin7 } = useUserBetaFeatures();

    return useMemo(() => {
        const baseMeta: Meta = {
            [domain.IntegrationType.Xero]: {
                logo: XeroLogo,
                title: messages.xeroTitle,
                description: messages.xeroDescription,
                button: {
                    title: messages.xeroTooltip,
                    label: messages.connect,
                    color: 'brandXero100',
                },
                learnMoreUrl: integrationDocumentationUrls.xero,
            },
            [domain.IntegrationType.QBooks]: {
                logo: QBooksLogo,
                title: messages.qboTitle,
                description: messages.qboDescription,
                button: {
                    title: messages.qboTooltip,
                    component: <ConnectQboMedium height='40px' color='brandQBO80' />,
                    componentDisabled: <ConnectQboMediumDisabled height='40px' />,
                    color: 'brandQBO80',
                },
                learnMoreUrl: integrationDocumentationUrls.quickbooks,
            },
            [domain.IntegrationType.NetSuite]: {
                logo: NetSuiteLogo,
                title: messages.netSuiteTitle,
                description: messages.netSuiteDescription,
                button: {
                    title: messages.netSuiteTooltip,
                    label: messages.connect,
                    color: 'midnight80',
                },
                learnMoreUrl: integrationDocumentationUrls.netsuite,
            },
            [domain.IntegrationType.Dear]: isCin7
                ? {
                      logo: Cin7Logo,
                      title: messages.cin7Title,
                      description: messages.cin7Description,
                      button: {
                          title: messages.cin7Tooltip,
                          label: messages.connect,
                          color: 'brandCin780',
                      },
                      learnMoreUrl: integrationDocumentationUrls.cin7,
                  }
                : null,
            [domain.IntegrationType.None]: null,
        };

        return baseMeta;
    }, [isCin7]);
};
