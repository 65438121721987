import { RequestMutationOptions } from '@approvalmax/data';
import { useMutate } from 'shared/data';

import { requestsApiPaths } from '../paths';
import { ResendQuoteForCustomerDecision } from './useResendQuoteForCustomerDecision.types';

export const useResendQuoteForCustomerDecision = (
    mutationOptions?: RequestMutationOptions<ResendQuoteForCustomerDecision>
) => {
    return useMutate(requestsApiPaths.resendQuoteForCustomerDecision, {
        mutationOptions,
        apiVersion: 'v1',
        method: 'post',
    });
};
