import { LinkIcon } from '@approvalmax/ui';
import { ColumnDefinition, Table } from '@approvalmax/ui/src/components';

import { messages } from './MatchingLineItems.messages';
import { LineItemType } from './MatchingLineItems.types';

export const checkboxColumnCover = {
    id: 'checkbox',
    spacing: '4 0',
    width: 28,
} as const;

export const createLinkedColumn: ColumnDefinition<LineItemType> = {
    id: 'linked',
    name: messages.linkedColumnName,
    value: () => true,
    cellComponent: (props) => {
        const remainingBalance = props.item.remainingBalance || 0;

        const disabled = remainingBalance <= 0;

        return (
            <Table.Cell {...props}>
                {props.item.isMatched ? <LinkIcon color={disabled ? 'midnight70' : 'blue100'} /> : null}
            </Table.Cell>
        );
    },
    width: 40,
    textAlign: 'right',
    spacing: '0 8',
};
