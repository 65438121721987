import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('pages.RequestFormPage.selectors.standalone.validationSelectors', {
    mandatoryFieldsErrorText: 'Please fill in the mandatory fields',
    understatedAmountErrorText: 'Amount cannot be less than 0.',
    exceededAmountErrorText: 'Amount cannot be more than 999,999,999,999,999.',
    mandatoryNameErrorText: 'Name is mandatory.',
    mandatoryDescriptionErrorText: 'Description is mandatory.',
    exceededNameLengthErrorText: 'Max length of name is 255.',
});
