import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('pages.RequestList.GoodsReceivedNotes.EditPopup', {
    title: 'Goods received notes',
    description: 'Select status and leave comment.',
    buttonUpdate: 'Update',
    buttonUpdateTitle: 'Update',
    fieldStatus: 'Status',
    fieldStatusPlaceholder: 'Select status',
    fieldComment: 'Comment',
    fieldCommentPlaceholder: 'Leave comment...',
    fieldAttachments: 'Attachments',
    statusNotReceived: 'Not received',
    statusPartiallyReceived: 'Partially received',
    statusFullyReceived: 'Fully received',
    errorFilesCount: 'Sorry, you cannot attach more than {maxFilesCount} files to a comment.',
    errorFileSize: 'Sorry, the file "{fileName}" exceeds the maximum size of {maxSize}.',
    errorFileType: 'Sorry, the file type of "{fileName}" cannot be uploaded due to security reasons.',
    grnStatusWasChanged: 'GRN status was changed ',
    goTo: 'Go to {displayName}',
});
