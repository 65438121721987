import { MatrixIcon } from '@approvalmax/ui';
import { Box, Button, Flex, Text, Tooltip } from '@approvalmax/ui/src/components';
import { arrayHelpers } from '@approvalmax/utils';
import { selectors } from 'modules/common';
import { domain, State } from 'modules/data';
import { FC, memo, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
    addStepParticipant,
    addTempUser,
    changeApprovalStepDuration,
    changeTemplateStepApprovalCount,
    changeTemplateStepType,
    openApprovalMatrix,
    openInviteUsersPopup,
    removeStepParticipant,
} from '../../actions';
import { getActiveTemplate, isPageReadonly, showCardValidationErrors } from '../../selectors/pageSelectors';
import { getStepApprovers } from '../../selectors/stepSelectors';
import { isMatrixUnavailable } from '../../selectors/templateSelectors';
import { ExpandedTemplateUser } from '../../types/selectors';
import { addDefaultApprover, addUserEvent, removeUserEvent } from '../../utils/events';
import { createUserComparator } from '../../utils/helpers';
import AddUserButton from '../AddUserButton/AddUserButton';
import { StepDeadline } from '../StepDeadline/StepDeadline';
import StepTypeEditor from '../StepTypeEditor/StepTypeEditor';
import { StepUser } from '../StepUser/StepUser';
import { messages } from './ApprovalStepContent.messages';
import { ApprovalStepContentProps } from './ApprovalStepContent.types';

const ApprovalStepContent: FC<ApprovalStepContentProps> = memo((props) => {
    const { stepIndex, step } = props;

    const dispatch = useDispatch();

    const template = useSelector(getActiveTemplate)!;
    const company = useSelector((state: State) => selectors.company.getCompanyById(state, template.companyId));
    const readonly = useSelector(isPageReadonly);
    const matrixUnavailable = useSelector(isMatrixUnavailable);
    const showValidationErrors = useSelector(showCardValidationErrors);
    const defaultApprover = useSelector((state: State) => selectors.templateStep.getStepDefaultApprover(state, step));
    const approvers = useSelector((state: State) => getStepApprovers(state, template, step));
    const team = useSelector((state: State) => selectors.company.getCompanyTeam(state, company));
    const canAssignDefaultApprover = selectors.templateStep.canAssignDefaultApprover(step.participantMatrix);

    const onAddTempUser = useCallback(
        (value: domain.User) => {
            dispatch(addTempUser(value));

            addUserEvent('approver', template.integrationCode, true);
        },
        [dispatch, template.integrationCode]
    );

    const onInviteUser = useCallback(
        (user: ExpandedTemplateUser) => {
            dispatch(openInviteUsersPopup([user.id]));
        },
        [dispatch]
    );

    const onAddParticipant = useCallback(
        (value: selectors.types.ExpandedCompanyUser) => {
            dispatch(addStepParticipant(stepIndex, value, false));

            if (value.databaseId) {
                addUserEvent('approver', template.integrationCode);
            }
        },
        [dispatch, template.integrationCode, stepIndex]
    );

    const onRemoveParticipant = useCallback(
        (user: domain.User) => {
            dispatch(removeStepParticipant(stepIndex, user, false));

            removeUserEvent('approver', template.integrationCode);
        },
        [dispatch, template.integrationCode, stepIndex]
    );

    const onRemoveDefaultApprover = useCallback(
        (user: domain.User) => {
            dispatch(removeStepParticipant(stepIndex, user, true));

            removeUserEvent('approver', template.integrationCode);
        },
        [dispatch, stepIndex, template.integrationCode]
    );

    const onAddDefaultApprover = useCallback(
        (value: selectors.types.ExpandedCompanyUser) => {
            dispatch(addStepParticipant(stepIndex, value, true));

            addDefaultApprover(template.integrationCode);
        },
        [dispatch, template.integrationCode, stepIndex]
    );

    const onOpenApprovalMatrix = useCallback(() => {
        if (matrixUnavailable) return;
        dispatch(openApprovalMatrix(stepIndex));
    }, [dispatch, stepIndex, matrixUnavailable]);

    const onUserOpenApprovalMatrix = useCallback(
        (userId?: string) => {
            if (matrixUnavailable) return;
            dispatch(openApprovalMatrix(stepIndex, userId));
        },
        [dispatch, stepIndex, matrixUnavailable]
    );

    const onChangeDeadLine = useCallback(
        (value: string, calculator?: domain.DeadlineRuleType | null) =>
            dispatch(changeApprovalStepDuration(stepIndex, value, calculator)),
        [dispatch, stepIndex]
    );

    const onConditionChange = useCallback(
        (type: domain.TemplateStepType, approvalCount: number | null) => {
            dispatch(changeTemplateStepType(stepIndex, type));
            dispatch(changeTemplateStepApprovalCount(stepIndex, approvalCount));
        },
        [dispatch, stepIndex]
    );

    const sortedApprovers = useMemo(
        () => arrayHelpers.arraySort<ExpandedTemplateUser>(approvers, createUserComparator(team)),
        [approvers, team]
    );

    return (
        <>
            {approvers.length === 0 ? (
                <>
                    <Text font='body' fontSize='small' color='midnight70'>
                        {messages.emptyStepDescription}
                    </Text>

                    <Flex grow={1} shrink={0}>
                        <Tooltip
                            display='block'
                            open={showValidationErrors}
                            color='cobalt100'
                            activator={
                                <AddUserButton
                                    users={team}
                                    disabled={readonly}
                                    onAddNew={onAddTempUser}
                                    onSelect={onAddParticipant}
                                    title={messages.addApprover}
                                    type='step'
                                    invalid={showValidationErrors}
                                    dataStarterGuide='wf_set_up_add_approver'
                                />
                            }
                        >
                            {messages.errorMessage}
                        </Tooltip>
                    </Flex>
                </>
            ) : (
                <>
                    <Flex spacing='8' alignItems='center' wrap={false}>
                        <Flex shrink={0} grow={1}>
                            <AddUserButton
                                users={team}
                                excludedUsers={approvers}
                                disabled={readonly}
                                onAddNew={onAddTempUser}
                                onSelect={onAddParticipant}
                                title={messages.addApprover}
                                type='step'
                                dataStarterGuide='wf_set_up_add_approver'
                            />
                        </Flex>

                        <Button
                            icon
                            noPadding
                            onClick={onOpenApprovalMatrix}
                            disabled={matrixUnavailable}
                            title={messages.openApprovalMatrixButtonTitle}
                            data-starter-guide='wf_set_up_approval_matrix'
                            variant='text'
                            color='blue100'
                        >
                            <MatrixIcon size={20} color={matrixUnavailable ? 'midnight80' : 'blue80'} />
                        </Button>

                        <StepDeadline
                            readonly={readonly}
                            value={step.deadlineRule?.duration || ''}
                            type={step.deadlineRule?.calculator || null}
                            onChange={onChangeDeadLine}
                        />
                    </Flex>

                    <StepTypeEditor
                        condition={step.type}
                        approvalCount={step.approvalCount}
                        company={company}
                        disabled={readonly}
                        onConditionChange={onConditionChange}
                    />

                    {approvers.length > 0 && (
                        <Box width='100%'>
                            <Flex direction='column' spacing='8'>
                                {sortedApprovers.map((approver) => (
                                    <StepUser
                                        team={team}
                                        key={approver.id}
                                        user={approver}
                                        isCompanyMember={team.some((t) => t.id === approver.id)}
                                        onRemove={onRemoveParticipant}
                                        readonly={readonly}
                                        onInvite={onInviteUser}
                                        onOpenMatrix={onUserOpenApprovalMatrix}
                                    />
                                ))}
                            </Flex>
                        </Box>
                    )}

                    {canAssignDefaultApprover && (
                        <Box width='100%'>
                            <Text font='body' fontSize='small' color='midnight80' fontWeight='medium'>
                                {messages.defaultApprover}
                            </Text>

                            <Text font='body' fontSize='small' color='midnight70' spacing='4 0 8'>
                                {messages.defaultApproverDescription}
                            </Text>

                            {defaultApprover ? (
                                <StepUser
                                    team={team}
                                    key={defaultApprover.id}
                                    user={defaultApprover}
                                    isCompanyMember={team.some((t) => t.id === defaultApprover.id)}
                                    onRemove={onRemoveDefaultApprover}
                                    readonly={readonly}
                                    onInvite={onInviteUser}
                                />
                            ) : (
                                <AddUserButton
                                    users={team}
                                    disabled={readonly}
                                    onAddNew={onAddTempUser}
                                    onSelect={onAddDefaultApprover}
                                    title={messages.addApprover}
                                    type='step'
                                />
                            )}
                        </Box>
                    )}
                </>
            )}
        </>
    );
});

export default ApprovalStepContent;
