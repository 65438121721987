import { RequestParams, RequestQueryOptions } from '@approvalmax/data';
import { useGet, useLazyGet } from 'shared/data';

import { netSuiteApiPaths } from '../paths';
import { GetNetSuiteDiscountItems } from './useGetNetSuiteDiscountItems.types';

export const useGetNetSuiteDiscountItems = (
    params?: RequestParams<GetNetSuiteDiscountItems>,
    queryOptions?: RequestQueryOptions<GetNetSuiteDiscountItems>
) => {
    return useGet(netSuiteApiPaths.discountItems, {
        params,
        queryOptions,
        apiVersion: 'v2',
    });
};

export const useLazyGetNetSuiteDiscountItems = (queryOptions?: RequestQueryOptions<GetNetSuiteDiscountItems>) => {
    return useLazyGet<GetNetSuiteDiscountItems>(netSuiteApiPaths.discountItems, {
        queryOptions,
        apiVersion: 'v2',
    });
};
