import { RequestParams, RequestQueryOptions } from '@approvalmax/data';
import { useGet } from 'shared/data';

import { paymentServicesApiPaths } from '../paths';
import { GetBeneficiarySchema } from './useGetBeneficiarySchema.types';

export const useGetBeneficiarySchema = (
    params: RequestParams<GetBeneficiarySchema>,
    queryOptions?: RequestQueryOptions<GetBeneficiarySchema>
) => {
    return useGet(paymentServicesApiPaths.getBeneficiarySchema, {
        params,
        queryOptions,
        apiVersion: 'v1',
    });
};
