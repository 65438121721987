import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('pages.RequestList.NetSuiteRequestCard.NetSuiteExpenses', {
    sectionHeaderText: 'Expenses',
    categoryColumnName: 'Category',
    accountColumnName: 'Account',
    amountColumnName: 'Amount',
    taxCodeColumnName: 'Tax code',
    taxRateColumnName: 'Tax rate',
    taxAmountColumnName: 'Tax amount',
    grossAmountColumnName: 'Gross amount',
    memoColumnName: 'Memo',
    departmentColumnName: 'Department',
    classColumnName: 'Class',
    locationColumnName: 'Location',
    customerColumnName: 'Customer:Project',
    projectTaskColumnName: 'Project Task',
    billableColumnName: 'Billable',
    billableColumnTooltip: 'This line is billable',
    amortizationSchedule: 'Amortization Schedule',
    amortizationStartDate: 'Amortization Start Date',
    amortizationEndDate: 'Amortization End Date',
    amortizationResidual: 'Amortization Residual',
});
