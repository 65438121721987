import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('pages.RequestList.Toolbar.ToolbarNotifications', {
    approverApproved: 'This request was previously approved by you',
    requesterUpdatedWithReset:
        'This request was previously approved by you and later it was edited and resubmitted by the requester',
    requesterResetTemplate: 'This request was previously approved by you and later the approval process was restarted',
    updatedExternallyResetTemplate:
        'This request was previously approved by you and later the approval process was restarted in the accounting system',
    editRequestButtonText: 'Edit the Bill',
    editRequestNoticeText: 'Please fill in the missing information before you approve this {requestName}{endAdornment}',
    dextNewSupplierWarning: `
        Please note: this request contains a new contact that was created in Dext.
        Please review contact details carefully before approving this request`,
    ocrInProgressText: 'Capturing in progress. It may take up to 5 minutes',
});
