import { errorHelpers } from '@approvalmax/utils';
import * as Sentry from '@sentry/browser';
import { selectors } from 'modules/common';
import { stateTree } from 'modules/data';
import { store } from 'modules/store';
import { amplitudeService } from 'services/amplitude';
import { chameleonService } from 'services/chameleon';
import { facebookService } from 'services/facebook';
import { fullstoryService } from 'services/fullstory';
import { gaService } from 'services/ga';
import { intercomService } from 'services/intercom';
import { linkedInService } from 'services/linkedIn';
import { refinerService } from 'services/refiner';
import { salesiqService } from 'services/salesiq';
import { storageService } from 'services/storage';

const initSalesIqChat = (state: stateTree.State) => {
    const { isTrialUser, email, name } = selectors.profile.getProfile(state);
    const isAdmin = selectors.profile.isManagerAtAnyOrganization(state);

    if (isTrialUser && isAdmin) {
        salesiqService.init({ email, name });
    }
};

const configureSentryScope = (state: stateTree.State) => {
    const me = selectors.profile.getProfileUser(state);

    Sentry.getCurrentScope().setUser({ id: me.databaseId, email: me.userEmail });
};

export const initExternalServices = () => {
    try {
        const state = store.getState();
        const flags = window.ApprovalMax?.flags || {};

        configureSentryScope(state);

        amplitudeService.init(state);
        gaService.init(state);
        refinerService.init();

        if (flags.useExternalTutorials) {
            chameleonService.init();
        }

        if (flags.useSalesIqChat) {
            initSalesIqChat(state);
        }

        fullstoryService.init(state);

        const isProd = window.ApprovalMax.environment === 'production';
        const forceTracking = storageService.getForceTrackingState();

        if (isProd || forceTracking) {
            facebookService.init();
            linkedInService.init();
        }

        intercomService.init(state);
    } catch (error) {
        errorHelpers.captureException(error);
    }
};
