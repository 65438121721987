import { WorkflowSettingsPopupSectionProps } from '../../WorkflowSettingsPopupContent.types';

export interface SectionNetSuiteAmaxUrlProps extends WorkflowSettingsPopupSectionProps {
    learnMoreLink?: string;
}

export enum NetSuiteAmaxUrlOption {
    AfterApproval = 'AfterApproval',
    AfterSync = 'AfterSync',
}
