import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('modules.TwoFaEnablingWizard.StepCodeEnter', {
    popupTitle: 'Two-factor authentication setup',
    title: 'Enter the code from the Authenticator app',
    description: `Enter the 6-digit code generated by the Authenticator app. {br}
    If you experience problems with entering this code, please refer to our <supportlink>support article</supportlink>.`,
    codeFieldTitle: 'Authenticator code',
    wrongCodeErrorText: 'Wrong code',
    userCodePlaceholder: 'Enter the Authenticator code...',
    nextButton: 'Continue',
});
