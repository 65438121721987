import { errorHelpers } from '@approvalmax/utils';
import { useCallback, useState } from 'react';
import { useCreateFido2Credential, useLazyGetFido2CredentialOptions } from 'shared/data/webApp/v2/queries';

import { usePasskeyErrorToast } from '../usePasskeyErrorToast/usePasskeyErrorToast';
import {
    createPublicKey,
    getAttestationResponseTransfer,
    mapErrorTextsToHumanReadable,
} from './useCreatePasskey.helpers';

export const useCreatePasskey = (options: { onSuccess?: VoidFunction } = {}) => {
    const { onSuccess } = options;

    const [isCreatingPasskey, setIsCreatingPasskey] = useState(false);

    const [getCredentialOptions] = useLazyGetFido2CredentialOptions();

    const { mutate: createFido2Credential } = useCreateFido2Credential({
        onSuccess,
    });

    const { showErrorToast } = usePasskeyErrorToast();

    const createPasskey = useCallback(
        async (label: string) => {
            setIsCreatingPasskey(true);

            try {
                const credentialOptions = await getCredentialOptions({ params: {} });

                const credential = await createPublicKey(credentialOptions);

                createFido2Credential({
                    body: {
                        attestationResponse: getAttestationResponseTransfer(credential, credentialOptions),
                        label,
                    },
                });
            } catch (err) {
                if (!errorHelpers.isApiError(err)) {
                    /**
                     * Do not remove this line cuz it writes errors
                     * which are generated by Passkeys, and it helps during a debugging
                     */
                    console.error('[Credentials create error]', err);

                    const errorText = mapErrorTextsToHumanReadable(err.message);

                    showErrorToast(errorText);
                }
            }

            setIsCreatingPasskey(false);
        },
        [getCredentialOptions, createFido2Credential, showErrorToast]
    );

    return {
        isCreatingPasskey,
        createPasskey,
    };
};
