import { useAtom, useAtomValue } from 'jotai';
import { useUpdateEffect } from 'react-use';

import { openingDuration } from './Drawer.constants';
import { collapseDrawerState, displayingDrawerState } from './Drawer.states';

export const useDisplaying = (): [boolean, (newValue: boolean) => void] => {
    const isDrawerCollapsed = useAtomValue(collapseDrawerState);
    const [isDrawerDisplaying, setIsDrawerDisplaying] = useAtom(displayingDrawerState);

    useUpdateEffect(() => {
        setIsDrawerDisplaying(true);

        setTimeout(() => {
            setIsDrawerDisplaying(false);
        }, openingDuration);
    }, [isDrawerCollapsed]);

    return [isDrawerDisplaying, setIsDrawerDisplaying];
};
