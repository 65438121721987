import { EditIcon } from '@approvalmax/ui';
import { Box, Button, Flex, Form, PasswordField, PasswordValidation, Text } from '@approvalmax/ui/src/components';
import { memo, useCallback } from 'react';
import bemFactory from 'react-bem-factory';

import { PropertyRowWithButton } from '../../../PropertyRowWithButton/PropertyRowWithButton';
import { fieldNamePasswordForm } from './SubsectionPassword.constants';
import { useSubsectionPassword } from './SubsectionPassword.hooks';
import { messages } from './SubsectionPassword.messages';
import { SubsectionPasswordProps } from './SubsectionPassword.types';

const qa = bemFactory.qa('profile-popup-change-password-section');

export const SubsectionPassword = memo<SubsectionPasswordProps>((props) => {
    const { profile } = props;

    const { form, formSubmit, isEditing, handleShowForm, handleHideForm } = useSubsectionPassword(profile);

    const fieldNewPassword = form.watch(fieldNamePasswordForm.newPassword);

    const renderAction = useCallback(() => {
        if (isEditing) {
            return null;
        }

        return profile.isPasswordSet ? (
            <Button size='small' color='blue80' onClick={handleShowForm} startIcon={<EditIcon size={12} />}>
                {messages.buttonChange}
            </Button>
        ) : (
            <Button size='small' color='blue80' onClick={handleShowForm}>
                {messages.buttonSetup}
            </Button>
        );
    }, [handleShowForm, isEditing, profile.isPasswordSet]);

    return (
        <>
            <PropertyRowWithButton
                title={messages.optionPassword}
                statusEnabledText={messages.statusEnabled}
                statusDisabledText={messages.statusDisabled}
                active={profile.isPasswordSet}
                renderAction={renderAction}
            />

            {isEditing && (
                <Flex direction='column' spacing='12 0'>
                    <Form form={form} onSubmit={formSubmit}>
                        <Box spacing='16 24' bordered borderColor='midnight50' radius='small'>
                            <Flex spacing='16'>
                                <Flex grow={1} alignItems='center' justifyContent='space-between' spacing='24'>
                                    <Flex grow={1}>
                                        <Text font='headline' fontSize='xxsmall' fontWeight='medium'>
                                            {profile.isPasswordSet
                                                ? messages.headlineChangePassword
                                                : messages.headlineSetPassword}
                                        </Text>
                                    </Flex>

                                    <Flex alignItems='center' justifyContent='space-between' spacing='8'>
                                        <Button
                                            size='xsmall'
                                            color='red40'
                                            data-qa={qa('cancel-change-password-button')}
                                            onClick={handleHideForm}
                                            variant='outline'
                                        >
                                            {messages.buttonCancel}
                                        </Button>

                                        <Button
                                            size='xsmall'
                                            color='blue80'
                                            type='submit'
                                            data-qa={qa('do-change-password-button')}
                                        >
                                            {messages.buttonChange}
                                        </Button>
                                    </Flex>
                                </Flex>

                                <Flex container wrap width={480}>
                                    {profile.isPasswordSet && (
                                        <Flex size={12}>
                                            <PasswordField
                                                name={fieldNamePasswordForm.oldPassword}
                                                label={messages.fieldOldPassword}
                                                size='small'
                                                hint=''
                                                required
                                            />
                                        </Flex>
                                    )}

                                    <Flex size={profile.isPasswordSet ? 12 : 24}>
                                        <PasswordField
                                            name={fieldNamePasswordForm.newPassword}
                                            label={messages.fieldNewPassword}
                                            size='small'
                                            hint=''
                                            required
                                        />
                                    </Flex>

                                    <Flex size={24}>
                                        <PasswordValidation value={fieldNewPassword} />
                                    </Flex>
                                </Flex>
                            </Flex>
                        </Box>
                    </Form>
                </Flex>
            )}
        </>
    );
});

SubsectionPassword.displayName = 'SubsectionPassword';
