import { RequestMutationOptions } from '@approvalmax/data';
import { useMutate } from 'shared/data';

import { groupsOfXeroAccountsApiPaths } from '../paths';
import { useInvalidateGetGroupsOfXeroAccounts } from '../useGetGroupsOfXeroAccounts/useGetGroupsOfXeroAccounts';
import { DeleteGroupOfXeroAccounts } from './useDeleteGroupOfXeroAccounts.types';

export const useDeleteGroupOfXeroAccounts = (mutationOptions?: RequestMutationOptions<DeleteGroupOfXeroAccounts>) => {
    const invalidateGetGroupsOfXeroAccounts = useInvalidateGetGroupsOfXeroAccounts();

    return useMutate(groupsOfXeroAccountsApiPaths.groupOfXeroAccounts, {
        mutationOptions: {
            ...mutationOptions,
            onSuccess: async (data, variables, context) => {
                invalidateGetGroupsOfXeroAccounts({ path: { companyId: variables.params?.path?.companyId } });

                mutationOptions?.onSuccess?.(data, variables, context);
            },
        },
        method: 'delete',
        apiVersion: 'v2',
    });
};
