import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('pages.RequestFormPage.selectors.xero.validationSelectors', {
    mandatoryFieldsErrorText: 'Please fill in the mandatory fields',
    amountMismatchErrorText: `
        The total amount of the {requestName} doesn't match the total amount of its line items after editing.`,
    noTaxIsInvalidErrorText: '"No tax" amounts cannot be set for this request according to the rules',
    lineItemsValidationErrorText: 'Please fix validation errors.',
    attachmentRequiredErrorText: 'At least one attachment is mandatory.',
    poNegativeAmountErrorText: 'The total amount of the Purchase Order cannot be negative.',
    invoiceNegativeAmountErrorText: 'The total amount of the Sales Invoice cannot be negative.',
    quoteNegativeAmountErrorText: 'The total amount of the Quote cannot be negative.',
    billNegativeAmountErrorText: 'The total amount of the Bill cannot be negative.',
    referencePOLengthErrorText: 'Reference number cannot be longer than 4000 characters.',
    numberQuoteLengthErrorText: 'Quote number cannot be longer than 100 characters.',
    titleQuoteLengthErrorText: 'Quote title cannot be longer than 100 characters.',
    summaryQuoteLengthErrorText: 'Quote summary cannot be longer than 3000 characters.',
    referenceBillLengthErrorText: 'Reference number cannot be longer than 255 characters.',
    numberInvoiceLengthErrorText: 'Invoice number cannot be longer than 255 characters.',
    deliveryAddressLengthErrorText: 'Delivery address cannot be longer than 3000 characters.',
    deliveryPhoneLengthErrorText: 'Telephone cannot be longer than 80 characters.',
    deliveryPhoneInvalidErrorText: 'Phone format invalid.',
    deliveryAttentionToLengthErrorText: 'Attention To cannot be longer than 255 characters.',
    deliveryInstructionsLengthErrorText: 'Delivery instructions cannot be longer than 500 characters.',
    exceededMaxValueAmountErrorText: 'Amount cannot exceed 999,999,999,999.',
    exceededMaxValueTotalAmountErrorText: 'Total amount cannot exceed 999,999,999,999.',
    emptyBrandingThemeErrorText: 'Branding theme cannot be empty.',
    contactLengthErrorText: 'Contact name cannot be longer than 255 characters.',
    firstNameLengthErrorText: 'First name cannot be longer than 255 characters.',
    lastNameLengthErrorText: 'Last name cannot be longer than 255 characters.',
    emailLengthErrorText: 'Email cannot be longer than 255 characters.',
    phoneCountryCodeLengthErrorText: 'Country code cannot be longer than 20 characters.',
    phoneAreaCodeLengthErrorText: 'Area code cannot be longer than 10 characters.',
    phoneNumberLengthErrorText: 'Phone number cannot be longer than 50 characters.',
    skypeLengthErrorText: 'Skype cannot be longer than 255 characters.',
    addressAttentionToLengthErrorText: 'Attention To cannot be longer than 500 characters.',
    addressAddressLengthErrorText: 'Address cannot be longer than 255 characters.',
    addressCityLengthErrorText: 'City/town cannot be longer than 255 characters.',
    addressRegionLengthErrorText: 'State/region cannot be longer than 255 characters.',
    addressPostCodeLengthErrorText: 'Postal code/ZIP code cannot be longer than 50 characters.',
    addressCountryLengthErrorText: 'Country cannot be longer than 50 characters.',
    taxNumberLengthErrorText: 'Tax ID number cannot be longer than 15 characters.',
    bankAccountNumberLengthErrorText: 'Bank account number cannot be longer than 50 characters.',
    accountNumberLengthErrorText: 'Account number cannot be longer than 50 characters.',
    batchPaymentReferenceLengthErrorText: 'Reference cannot be longer than 12 characters.',
    batchPaymentNarrativeLengthErrorText: 'Narrative cannot be longer than 18 characters.',
    batchPaymentDetailsLengthErrorText: 'Details cannot be longer than 18 characters.',
    batchPaymentParticularsLengthErrorText: 'Particulars cannot be longer than 12 characters.',
    batchPaymentCodeLengthErrorText: 'Code cannot be longer than 12 characters.',
    batchPaymentBankAccountLengthErrorText: 'Bank Account cannot be longer than 50 characters.',
    companyRegistrationNumberLengthErrorText: 'Company Registration Number cannot be longer than 50 characters.',
    anotherPersonFirstNameLengthErrorText: 'Additional Person First Name cannot exceed 80 characters.',
    anotherPersonLastNameLengthErrorText: 'Additional Person Last Name cannot exceed 80 characters.',
    anotherPersonEmailLengthErrorText: 'Additional Person Email cannot exceed 255 characters.',
    anotherPersonWrongEmailErrorText: 'Additional Person Email cannot exceed 255 characters.',
    wrongEmail: 'Incorrect email format.',
    emailToSupplierToEmpty: 'Please specify Email to address',
    fillInMandatoryFields: 'Please fill in the mandatory fields.',
    noBills: 'Please add bills',
    amaxPayBatchPaymentReferenceLengthErrorText: 'Reference cannot be longer than {maxLength} characters.',
    nonExistCurrencyErrorText: 'Bill currency doesn’t exist in Xero',
    contactNameIsEmpty: 'Please fill in the contact name field.',
    invalidContactName: `Contact name cannot contain the following symbols: "<". ">", "&#".`,
    noEmojiInContactName: 'Emojis are not allowed in the Contact name',
    emailAddressIsEmpty: 'Additional people cannot be added when the primary person has no email address set.',
    sortCodeError: 'Sort code should be {length} digits.',
    bankAccountWithSortCodeError: 'Bank account number should be 8 digits.',
    itemWithEmptyAmount: 'The payment amount can not be equal to 0',
    bankAccountIsEmpty: 'Please fill in the bank account field',
    paymentDateIsEmpty: 'Please fill in the payment date field',
    forbidModifiedBankAccount: `
        Approval workflow rules do not allow you to submit a Xero Batch Payment
        with the modified supplier's bank account.`,
});
