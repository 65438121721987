import { stateTree } from 'modules/data';
import { mergeDeep, mergeIn, setIn, update } from 'modules/immutable';

import {
    Action,
    LOAD_INITIAL_APP_DATA,
    LOAD_USER_PROFILE_DATA_RESPONSE,
    UPDATE_ENTITIES,
    UPDATE_PROFILE,
    UPDATE_PROFILE_DELEGATES,
} from '../actions';

export default function (state: stateTree.Entities, action: Action): stateTree.Entities {
    switch (action.type) {
        case LOAD_INITIAL_APP_DATA:
            state = mergeDeep(state, action.payload.entities);
            state = {
                ...state,
                practiceInvitations: action.payload.entities.practiceInvitations || {},
            };

            return state;

        case UPDATE_PROFILE:
            return mergeIn(state, ['users', action.payload.profileUserId], action.payload.userPartial);

        case UPDATE_PROFILE_DELEGATES:
            return update(state, 'companies', (companies) => {
                return Object.values(companies).reduce((m, c) => {
                    let newDelegates = c.delegates.filter((d) => d.userId !== action.payload.profileUserId);

                    const delegate = action.payload.delegates.find((d) => d.companyId === c.id);

                    if (delegate && delegate.delegateUserId) {
                        newDelegates = newDelegates.concat({
                            userId: action.payload.profileUserId,
                            delegateUserId: delegate.delegateUserId,
                        });
                    }

                    m[c.id] = {
                        ...c,
                        delegates: newDelegates,
                    };

                    return m;
                }, {} as any);
            });

        case LOAD_USER_PROFILE_DATA_RESPONSE:
            return setIn(state, ['users', action.payload.userId, 'profileInfo'], action.payload.profileInfo);

        case UPDATE_ENTITIES:
            return mergeDeep(state, action.payload.entities);

        default:
            return state;
    }
}
