import { errorHelpers, intl, mathService } from '@approvalmax/utils';
import { useQuery } from '@tanstack/react-query';
import { QueryKeys } from 'modules/common';
import { domain } from 'modules/data';
import { FC, memo } from 'react';
import { api } from 'services/api';
import styled from 'styled-components';

import SectionContainer from '../../../../../components/SectionContainer';
import { getPOMatchingData } from '../../selectors';
import CollapsibleSection from '../CollapsibleSection/CollapsibleSection';
import ColorBarSection from '../ColorBarSection/ColorBarSection';
import Legend from '../Legend/Legend';
import PurchaseOrderMatchingTable from './components/PurchaseOrderMatchingTable/PurchaseOrderMatchingTable';
import { messages } from './PurchaseOrderView.messages';
import { PurchaseOrderViewProps } from './PurchaseOrderView.types';

const StyledSectionContainer = styled(SectionContainer)`
    margin: 30px 0;
`;

const NoMatchedBills = styled.div`
    width: 100%;
    margin-bottom: 30px;
    color: #5e5c5c;
`;

const Header = styled.div`
    width: 100%;
    display: inline-flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
`;

const HeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
`;

const Title = styled.span`
    line-height: 35px;
`;

const getExtraAmountText = (
    billMatchingSettings: domain.CompanyBillMatchingSettings | undefined,
    extraAmount: number,
    currency: string
): string => {
    if (!billMatchingSettings?.allowApprovalOfOverbudgetBills) return '';

    switch (billMatchingSettings.allowApprovalOfOverbudgetBills) {
        case domain.CompanyMatchingSettingsBillApprovalPolicy.WithThreshold:
            return intl.formatCurrency(extraAmount || 0, currency);

        case domain.CompanyMatchingSettingsBillApprovalPolicy.WithPercentageThreshold: {
            const currencyAmountText = intl.formatCurrency(extraAmount || 0, currency);
            const percentageAmountText = intl.formatCurrency(
                billMatchingSettings.overbudgetBillsApprovalPercentageThreshold,
                '%',
                0
            );

            return `${percentageAmountText} (${currencyAmountText})`;
        }

        case domain.CompanyMatchingSettingsBillApprovalPolicy.Always:
        case domain.CompanyMatchingSettingsBillApprovalPolicy.Never:
            return '';

        default:
            throw errorHelpers.assertNever(billMatchingSettings.allowApprovalOfOverbudgetBills);
    }
};

const PurchaseOrderView: FC<PurchaseOrderViewProps> = memo((props) => {
    const { request } = props;

    const { data } = useQuery(
        [QueryKeys.XER0_PO_MATCHING_DATA, request.id],
        async () => {
            const response = await api.xeroMatching.getPOMatchingInfo({
                purchaseOrderId: request.id,
                companyId: request.companyId,
            });

            return getPOMatchingData(response.data);
        },
        {
            refetchOnMount: 'always',
        }
    );

    if (!data || (request.company.flags.isXeroMatchingV2ReadOnly && data.tableData.length === 0)) return null;

    const {
        tableData,
        totalAllocatedAmountToOtherBills,
        totalAmount,
        totalAllocatedAmountToApprovedBills,
        purchaseOrderTotalAmount,
        currency,
        remaining,
    } = data;

    const noMatchedBills = tableData.length === 0;

    const billMatchingSettings = request.company.billMatchingSettings;

    let remainingAmount = remaining;
    let totalBarValue = purchaseOrderTotalAmount;
    let extraAmount: number | undefined;
    let warningRemaining = false;

    switch (billMatchingSettings?.allowApprovalOfOverbudgetBills) {
        case domain.CompanyMatchingSettingsBillApprovalPolicy.WithThreshold:
        case domain.CompanyMatchingSettingsBillApprovalPolicy.WithPercentageThreshold:
            extraAmount =
                billMatchingSettings.allowApprovalOfOverbudgetBills ===
                domain.CompanyMatchingSettingsBillApprovalPolicy.WithPercentageThreshold
                    ? purchaseOrderTotalAmount * (billMatchingSettings.overbudgetBillsApprovalPercentageThreshold / 100)
                    : billMatchingSettings.overbudgetBillsApprovalThreshold;

            totalBarValue = mathService.add(totalBarValue, extraAmount);

            if (remainingAmount < 0) {
                extraAmount = mathService.subtract(extraAmount, Math.abs(remainingAmount));

                if (extraAmount < 0) {
                    warningRemaining = true;
                } else {
                    remainingAmount = 0;
                }
            }

            break;

        case domain.CompanyMatchingSettingsBillApprovalPolicy.Never:
            if (remainingAmount < 0) {
                warningRemaining = true;
            }
    }

    const extraAmountText = getExtraAmountText(billMatchingSettings, extraAmount || 0, currency);

    return (
        <StyledSectionContainer
            text={
                <HeaderContainer>
                    <Title>{messages.sectionHeaderText}</Title>
                </HeaderContainer>
            }
        >
            <Header>
                {noMatchedBills ? (
                    <NoMatchedBills>{messages.noMatchedBills}</NoMatchedBills>
                ) : (
                    <>
                        <Legend
                            legends={[
                                {
                                    color: '#000',
                                    title: messages.allocationsToApprovedBills,
                                    amount: intl.formatCurrency(totalAllocatedAmountToApprovedBills, currency),
                                },
                                {
                                    color: '#4c8a5c',
                                    title: messages.allocatedAmount,
                                    amount: intl.formatCurrency(totalAllocatedAmountToOtherBills, currency),
                                },
                                {
                                    color: '#e9eef0',
                                    title: messages.remaining,
                                    warning: warningRemaining,
                                    amount:
                                        (extraAmount || extraAmount === 0) && !warningRemaining
                                            ? messages.extraAmount({
                                                  remaining: intl.formatCurrency(remainingAmount, currency),
                                                  extraAmount: extraAmountText,
                                              })
                                            : intl.formatCurrency(remainingAmount, currency),
                                },
                            ]}
                        />

                        <ColorBarSection
                            blackBarValue={totalAllocatedAmountToApprovedBills}
                            darkGreenBarValue={totalAllocatedAmountToOtherBills}
                            totalAmount={totalBarValue}
                            isOverflowed={warningRemaining}
                            currency={currency}
                        />
                    </>
                )}
            </Header>

            {tableData.length !== 0 && (
                <CollapsibleSection title={messages.showTable}>
                    <PurchaseOrderMatchingTable
                        lineItems={tableData}
                        totalAllocatedAmount={totalAllocatedAmountToOtherBills + totalAllocatedAmountToApprovedBills}
                        totalAmount={totalAmount}
                        currency={currency}
                        companyId={request.companyId}
                    />
                </CollapsibleSection>
            )}
        </StyledSectionContainer>
    );
});

export default PurchaseOrderView;
