import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('pages.RequestList.GoodsReceivedNotes', {
    title: 'Goods received Notes',
    updateStatus: 'Update status',
    lastNote: 'Last note',

    statusNotReceived: 'Not received',
    statusPartiallyReceived: 'Partially received',
    statusFullyReceived: 'Fully received',

    fieldDate: 'Date',
    fieldAuthor: 'Author',
    fieldComment: 'Comment',
});
