import { Flex, Form, Radio, Text } from '@approvalmax/ui/src/components';
import { memo } from 'react';
import bemFactory from 'react-bem-factory';

import { BackupMethodOptions } from '../../TwoFaEnablingWizard.constants';
import { StepperTwoFaWizard } from '../StepperTwoFaWizard/StepperTwoFaWizard';
import { useStepBackupMethods } from './StepBackupMethods.hooks';
import { messages } from './StepBackupMethods.messages';

const qa = bemFactory.qa('pro-two-fa-setup-backup-methods');

export const StepBackupMethods = memo(() => {
    const { form } = useStepBackupMethods();

    return (
        <Form form={form}>
            <Flex direction='column' spacing='24' data-qa={qa()}>
                <StepperTwoFaWizard activeStep={2} />

                <Flex direction='column' spacing='16'>
                    <Text font='headline' fontSize='xsmall' fontWeight='medium'>
                        {messages.subtitle}
                    </Text>

                    <Text font='body' fontSize='medium' fontWeight='regular'>
                        {messages.description}
                    </Text>
                </Flex>

                <Radio.Group name='backupMethod' direction='column'>
                    <Radio value={BackupMethodOptions.backupCodes}>{messages.methodBackupCodes}</Radio>

                    <Radio value={BackupMethodOptions.backupEmailAddress}>{messages.methodBackupEmail}</Radio>
                </Radio.Group>
            </Flex>
        </Form>
    );
});

StepBackupMethods.displayName = 'StepBackupMethods';
