import { ForwardedRef, forwardRef, memo, useCallback } from 'react';
import { useController } from 'react-hook-form';

import Select from '../../Select';
import { SelectProps } from '../../Select.types';
import { ControllerProps } from './Controller.types';

/**
 * The `Select.Controller` allows you to use the `Select` with the `react-hook-form` library.
 * They take the same properties as the `Select` component,
 * plus the `control` property to bind it with the library and `rules` for validation.
 *
 * You can use control without the `Controller` subcomponent if it is in a `Form` or `Form.Part` component.
 */
const Controller = memo(
    forwardRef(
        <Item extends Record<string, any>, Multiple extends boolean | undefined = undefined>(
            props: ControllerProps<Item, Multiple>,
            ref: ForwardedRef<HTMLDivElement>
        ) => {
            const { control, name, rules, defaultValue, validate, onChange: onChangeProp, ...restProps } = props;

            const {
                field: { onChange, ...field },
                fieldState,
            } = useController({
                control,
                name: name || 'select',
                rules: { required: validate ? undefined : restProps.required, validate, ...rules },
                defaultValue,
            });

            const hintPlaceholder = validate ? <span>&nbsp;</span> : undefined;

            const handleChange = useCallback<NonNullable<SelectProps<Item, Multiple>['onChange']>>(
                (value, items) => {
                    onChangeProp?.(value, items);
                    /*
                     * Need to pass `null` instead of `undefined` in order to clear the value.
                     */
                    onChange(value ?? null);
                },
                [onChange, onChangeProp]
            );

            return (
                <Select
                    {...field}
                    onChange={handleChange}
                    {...fieldState}
                    {...restProps}
                    ref={ref}
                    hint={fieldState.error?.message || restProps.hint || hintPlaceholder}
                />
            );
        }
    )
);

export default Controller;
