import { defineMessages } from '@approvalmax/utils';

export const currencyFullTextMessages = defineMessages('modules.common.currency.fullText', {
    usd: 'USD United States Dollar',
    aud: 'AUD Australian Dollar',
    cad: 'CAD Canadian Dollar',
    eur: 'EUR Euro',
    gbp: 'GBP British Pound',
    inr: 'INR Indian Rupee',
    jpy: 'JPY Japanese Yen',
    nzd: 'NZD New Zealand Dollar',
    aed: 'AED United Arab Emirates Dirham',
    afn: 'AFN Afghan Afghani',
    all: 'ALL Albanian Lek',
    amd: 'AMD Armenian Dram',
    ang: 'ANG Netherlands Antillean Guilder',
    aoa: 'AOA Angolan Kwanza',
    ars: 'ARS Argentine Peso',
    awg: 'AWG Aruban Guilder',
    azn: 'AZN Azerbaijani Manat',
    bam: 'BAM Bosnia and Herzegovina Convertible Mark',
    bbd: 'BBD Barbadian Dollar',
    bdt: 'BDT Bangladeshi Taka',
    bgn: 'BGN Bulgarian Lev',
    bhd: 'BHD Bahraini Dinar',
    bif: 'BIF Burundian Franc',
    bmd: 'BMD Bermudian Dollar',
    bnd: 'BND Brunei Dollar',
    bob: 'BOB Bolivian Boliviano',
    brl: 'BRL Brazilian Real',
    bsd: 'BSD Bahamian Dollar',
    btn: 'BTN Bhutanese Ngultrum',
    bwp: 'BWP Botswana Pula',
    byr: 'BYR Belarusian Ruble',
    bzd: 'BZD Belize Dollar',
    cdf: 'CDF Congolese Franc',
    chf: 'CHF Swiss Franc',
    clp: 'CLP Chilean Peso',
    cny: 'CNY Chinese Yuan',
    cop: 'COP Colombian Peso',
    crc: 'CRC Costa Rican Colón',
    cuc: 'CUC Cuban Convertible Peso',
    cve: 'CVE Cape Verdean Escudo',
    czk: 'CZK Czech Koruna',
    djf: 'DJF Djiboutian Franc',
    dkk: 'DKK Danish Krone',
    dop: 'DOP Dominican Peso',
    dzd: 'DZD Algerian Dinar',
    eek: 'EEK Estonian Kroon',
    egp: 'EGP Egyptian Pound',
    ern: 'ERN Eritrean Nakfa',
    etb: 'ETB Ethiopian Birr',
    fjd: 'FJD Fijian Dollar',
    fkp: 'FKP Falkland Island Pound',
    gel: 'GEL Georgian Lari',
    ghs: 'GHS Ghanaian Cedi',
    gip: 'GIP Gibraltar Pound',
    gmd: 'GMD Gambian Dalasi',
    gnf: 'GNF Guinean Franc',
    gtq: 'GTQ Guatemalan Quetzal',
    gyd: 'GYD Guyanese Dollar',
    hkd: 'HKD Hong Kong Dollar',
    hnl: 'HNL Honduran Lempira',
    hrk: 'HRK Croatian Kuna',
    htg: 'HTG Haitian Gourde',
    huf: 'HUF Hungarian Forint',
    idr: 'IDR Indonesian Rupiah',
    ils: 'ILS Israeli New Sheqel',
    iqd: 'IQD Iraqi Dinar',
    irr: 'IRR Iranian Rial',
    isk: 'ISK Icelandic Króna',
    jmd: 'JMD Jamaican Dollar',
    jod: 'JOD Jordanian Dinar',
    kes: 'KES Kenyan Shilling',
    kgs: 'KGS Kyrgyzstani Som',
    khr: 'KHR Cambodian Riel',
    kmf: 'KMF Comorian Franc',
    kpw: 'KPW North Korean Won',
    krw: 'KRW South Korean Won',
    kwd: 'KWD Kuwaiti Dinar',
    kyd: 'KYD Cayman Islands Dollar',
    kzt: 'KZT Kazakhstani Tenge',
    lak: 'LAK Lao Kip',
    lbp: 'LBP Lebanese Pound',
    lkr: 'LKR Sri Lankan Rupee',
    lrd: 'LRD Liberian Dollar',
    lsl: 'LSL Lesotho Loti',
    lyd: 'LYD Libyan Dinar',
    mad: 'MAD Moroccan Dirham',
    mdl: 'MDL Moldovan Leu',
    mga: 'MGA Malagasy Ariary',
    mkd: 'MKD Macedonian Denar',
    mmk: 'MMK Myanmar Kyat',
    mnt: 'MNT Mongolian Tugrik',
    mop: 'MOP Macanese Pataca',
    mro: 'MRO Mauritanian Ouguiya',
    mur: 'MUR Mauritian Rupee',
    mvr: 'MVR Maldivian Rufiyaa',
    mwk: 'MWK Malawian Kwacha',
    mxn: 'MXN Mexican Peso',
    myr: 'MYR Malaysian Ringgit',
    mzn: 'MZN Mozambican Metical',
    nad: 'NAD Namibian Dollar',
    ngn: 'NGN Nigerian Naira',
    nio: 'NIO Nicaraguan Córdoba',
    nok: 'NOK Norwegian Krone',
    npr: 'NPR Nepalese Rupee',
    omr: 'OMR Omani Rial',
    pab: 'PAB Panamanian Balboa',
    pen: 'PEN Peruvian Nuevo Sol',
    pgk: 'PGK Papua New Guinean Kina',
    php: 'PHP Philippine Peso',
    pkr: 'PKR Pakistani Rupee',
    pln: 'PLN Polish Zloty',
    pyg: 'PYG Paraguayan Guaraní',
    qar: 'QAR Qatari Riyal',
    ron: 'RON Romanian Leu',
    rsd: 'RSD Serbian Dinar',
    rub: 'RUB Russian Ruble',
    rwf: 'RWF Rwandan Franc',
    sar: 'SAR Saudi Riyal',
    sbd: 'SBD Solomon Islands Dollar',
    scr: 'SCR Seychellois Rupee',
    sdg: 'SDG Sudanese Pound',
    sek: 'SEK Swedish Krona',
    sgd: 'SGD Singapore Dollar',
    shp: 'SHP Saint Helenian Pound',
    sll: 'SLL Sierra Leonean Leone',
    sos: 'SOS Somali Shilling',
    srd: 'SRD Surinamese Dollar',
    std: 'STD São Tomé and Príncipe Dobra',
    svc: 'SVC Salvadoran Colón',
    syp: 'SYP Syrian Pound',
    szl: 'SZL Swazi Lilangeni',
    thb: 'THB Thai Baht',
    tjs: 'TJS Tajikistani Somoni',
    tmt: 'TMT Turkmenistani Manat',
    tnd: 'TND Tunisian Dinar',
    top: "TOP Tongan Pa'anga",
    try: 'TRY Turkish Lira',
    ttd: 'TTD Trinidad and Tobago Dollar',
    twd: 'TWD Taiwanese New Dollar',
    tzs: 'TZS Tanzanian Shilling',
    uah: 'UAH Ukrainian Hryvnia',
    ugx: 'UGX Ugandan Shilling',
    uyu: 'UYU Uruguayan Peso',
    uzs: 'UZS Uzbekistani Som',
    vef: 'VEF Venezuelan Bolívar Fuertes',
    vnd: 'VND Vietnamese Dong',
    vuv: 'VUV Vanuatu Vatu',
    wst: 'WST Samoan Tala',
    xaf: 'XAF Central African CFA Franc',
    xcd: 'XCD East Caribbean Dollar',
    xof: 'XOF West African CFA Franc',
    xpf: 'XPF CFP Franc',
    yer: 'YER Yemeni Rial',
    zar: 'ZAR South African Rand',
    zmk: 'ZMK Zambian Kwacha (expiring)',
    zmw: 'ZMW Zambian Kwacha',
    byn: 'BYN Belarussian Ruble',
    clf: 'CLF Unidad de Fomento',
    cou: 'COU Unidad de Valor Real',
    cup: 'CUP Cuban Peso',
    ktd: 'KTD Cayman Islands Dollar',
    mru: 'MRU Ouguiya',
    mxv: 'MXV Mexican Unidad de Inversion',
    skk: 'SKK - Slovak Koruna',
    sle: 'SLE Leone',
    ssp: 'SSP South Sudanese Pound',
    stn: 'STN Dobra',
    ves: 'VES Venezualan Bolivar',
    zwl: 'ZWL Zimbabwe Dollar',
});

export const currencyTextMessages = defineMessages('modules.common.currency.text', {
    usd: 'USD',
    aud: 'AUD',
    cad: 'CAD',
    eur: 'EUR',
    gbp: 'GBP',
    inr: 'INR',
    jpy: 'JPY',
    nzd: 'NZD',
    aed: 'AED',
    afn: 'AFN',
    all: 'ALL',
    amd: 'AMD',
    ang: 'ANG',
    aoa: 'AOA',
    ars: 'ARS',
    awg: 'AWG',
    azn: 'AZN',
    bam: 'BAM',
    bbd: 'BBD',
    bdt: 'BDT',
    bgn: 'BGN',
    bhd: 'BHD',
    bif: 'BIF',
    bmd: 'BMD',
    bnd: 'BND',
    bob: 'BOB',
    brl: 'BRL',
    bsd: 'BSD',
    btn: 'BTN',
    bwp: 'BWP',
    byr: 'BYR',
    bzd: 'BZD',
    cdf: 'CDF',
    chf: 'CHF',
    clp: 'CLP',
    cny: 'CNY',
    cop: 'COP',
    crc: 'CRC',
    cuc: 'CUC',
    cve: 'CVE',
    czk: 'CZK',
    djf: 'DJF',
    dkk: 'DKK',
    dop: 'DOP',
    dzd: 'DZD',
    eek: 'EEK',
    egp: 'EGP',
    ern: 'ERN',
    etb: 'ETB',
    fjd: 'FJD',
    fkp: 'FKP',
    gel: 'GEL',
    ghs: 'GHS',
    gip: 'GIP',
    gmd: 'GMD',
    gnf: 'GNF',
    gtq: 'GTQ',
    gyd: 'GYD',
    hkd: 'HKD',
    hnl: 'HNL',
    hrk: 'HRK',
    htg: 'HTG',
    huf: 'HUF',
    idr: 'IDR',
    ils: 'ILS',
    iqd: 'IQD',
    irr: 'IRR',
    isk: 'ISK',
    jmd: 'JMD',
    jod: 'JOD',
    kes: 'KES',
    kgs: 'KGS',
    khr: 'KHR',
    kmf: 'KMF',
    kpw: 'KPW',
    krw: 'KRW',
    kwd: 'KWD',
    kyd: 'KYD',
    kzt: 'KZT',
    lak: 'LAK',
    lbp: 'LBP',
    lkr: 'LKR',
    lrd: 'LRD',
    lsl: 'LSL',
    lyd: 'LYD',
    mad: 'MAD',
    mdl: 'MDL',
    mga: 'MGA',
    mkd: 'MKD',
    mmk: 'MMK',
    mnt: 'MNT',
    mop: 'MOP',
    mro: 'MRO',
    mur: 'MUR',
    mvr: 'MVR',
    mwk: 'MWK',
    mxn: 'MXN',
    myr: 'MYR',
    mzn: 'MZN',
    nad: 'NAD',
    ngn: 'NGN',
    nio: 'NIO',
    nok: 'NOK',
    npr: 'NPR',
    omr: 'OMR',
    pab: 'PAB',
    pen: 'PEN',
    pgk: 'PGK',
    php: 'PHP',
    pkr: 'PKR',
    pln: 'PLN',
    pyg: 'PYG',
    qar: 'QAR',
    ron: 'RON',
    rsd: 'RSD',
    rub: 'RUB',
    rwf: 'RWF',
    sar: 'SAR',
    sbd: 'SBD',
    scr: 'SCR',
    sdg: 'SDG',
    sek: 'SEK',
    sgd: 'SGD',
    shp: 'SHP',
    sll: 'SLL',
    sos: 'SOS',
    srd: 'SRD',
    std: 'STD',
    svc: 'SVC',
    syp: 'SYP',
    szl: 'SZL',
    thb: 'THB',
    tjs: 'TJS',
    tmt: 'TMT',
    tnd: 'TND',
    top: 'TOP',
    try: 'TRY',
    ttd: 'TTD',
    twd: 'TWD',
    tzs: 'TZS',
    uah: 'UAH',
    ugx: 'UGX',
    uyu: 'UYU',
    uzs: 'UZS',
    vef: 'VEF',
    vnd: 'VND',
    vuv: 'VUV',
    wst: 'WST',
    xaf: 'XAF',
    xcd: 'XCD',
    xof: 'XOF',
    xpf: 'XPF',
    yer: 'YER',
    zar: 'ZAR',
    zmk: 'ZMK',
    zmw: 'ZMW',
    byn: 'BYN',
    clf: 'CLF',
    cou: 'COU',
    cup: 'CUP',
    ktd: 'KTD',
    mru: 'MRU',
    mxv: 'MXV',
    skk: 'SKK',
    sle: 'SLE',
    ssp: 'SSP',
    stn: 'STN',
    ves: 'VES',
    zwl: 'ZWL',
    btc: 'BTC',
    eth: 'ETH',
    ltc: 'LTC',
    usdt: 'USDT',
    bnb: 'BNB',
    xrp: 'XRP',
    doge: 'DOGE',
    ada: 'ADA',
    trx: 'TRX',
    bch: 'BCH',
    leo: 'LEO',
    xlm: 'XLM',
    link: 'LINK',
    xmr: 'XMR',
    etc: 'ETC',
});

export const cryptoCurrencyFullTextMessages = defineMessages('modules.common.currency.crypto.fullText', {
    btc: 'BTC Bitcoin',
    eth: 'ETH Ethereum',
    ltc: 'LTC Litecoin',
    usdt: 'USDT Tether',
    bnb: 'BNB Binance Coin',
    xrp: 'XRP Ripple',
    doge: 'DOGE Dogecoin',
    ada: 'ADA Cardano',
    trx: 'TRX Tron',
    bch: 'BCH Bitcoin Cash',
    leo: 'LEO UNUS SED LEO',
    xlm: 'XLM Stellar',
    link: 'LINK Chainlink',
    xmr: 'XMR Monero',
    etc: 'ETC Ethereum Classic',
});

export const currencyFullTextWithoutCodeMessages = defineMessages(
    'modules.common.currency.currencyFullTextWithoutCode',
    {
        usd: 'United States Dollar',
        aud: 'Australian Dollar',
        cad: 'Canadian Dollar',
        eur: 'Euro',
        gbp: 'British Pound',
        inr: 'Indian Rupee',
        jpy: 'Japanese Yen',
        nzd: 'New Zealand Dollar',
        aed: 'United Arab Emirates Dirham',
        afn: 'Afghan Afghani',
        all: 'Albanian Lek',
        amd: 'Armenian Dram',
        ang: 'Netherlands Antillean Guilder',
        aoa: 'Angolan Kwanza',
        ars: 'Argentine Peso',
        awg: 'Aruban Guilder',
        azn: 'Azerbaijani Manat',
        bam: 'Bosnia and Herzegovina Convertible Mark',
        bbd: 'Barbadian Dollar',
        bdt: 'Bangladeshi Taka',
        bgn: 'Bulgarian Lev',
        bhd: 'Bahraini Dinar',
        bif: 'Burundian Franc',
        bmd: 'Bermudian Dollar',
        bnd: 'Brunei Dollar',
        bob: 'Bolivian Boliviano',
        brl: 'Brazilian Real',
        bsd: 'Bahamian Dollar',
        btn: 'Bhutanese Ngultrum',
        bwp: 'Botswana Pula',
        byr: 'Belarusian Ruble',
        bzd: 'Belize Dollar',
        cdf: 'Congolese Franc',
        chf: 'Swiss Franc',
        clp: 'Chilean Peso',
        cny: 'Chinese Yuan',
        cop: 'Colombian Peso',
        crc: 'Costa Rican Colón',
        cuc: 'Cuban Convertible Peso',
        cve: 'Cape Verdean Escudo',
        czk: 'Czech Koruna',
        djf: 'Djiboutian Franc',
        dkk: 'Danish Krone',
        dop: 'Dominican Peso',
        dzd: 'Algerian Dinar',
        eek: 'Estonian Kroon',
        egp: 'Egyptian Pound',
        ern: 'Eritrean Nakfa',
        etb: 'Ethiopian Birr',
        fjd: 'Fijian Dollar',
        fkp: 'Falkland Island Pound',
        gel: 'Georgian Lari',
        ghs: 'Ghanaian Cedi',
        gip: 'Gibraltar Pound',
        gmd: 'Gambian Dalasi',
        gnf: 'Guinean Franc',
        gtq: 'Guatemalan Quetzal',
        gyd: 'Guyanese Dollar',
        hkd: 'Hong Kong Dollar',
        hnl: 'Honduran Lempira',
        hrk: 'Croatian Kuna',
        htg: 'Haitian Gourde',
        huf: 'Hungarian Forint',
        idr: 'Indonesian Rupiah',
        ils: 'Israeli New Sheqel',
        iqd: 'Iraqi Dinar',
        irr: 'Iranian Rial',
        isk: 'Icelandic Króna',
        jmd: 'Jamaican Dollar',
        jod: 'Jordanian Dinar',
        kes: 'Kenyan Shilling',
        kgs: 'Kyrgyzstani Som',
        khr: 'Cambodian Riel',
        kmf: 'Comorian Franc',
        kpw: 'North Korean Won',
        krw: 'South Korean Won',
        kwd: 'Kuwaiti Dinar',
        kyd: 'Cayman Islands Dollar',
        kzt: 'Kazakhstani Tenge',
        lak: 'Lao Kip',
        lbp: 'Lebanese Pound',
        lkr: 'Sri Lankan Rupee',
        lrd: 'Liberian Dollar',
        lsl: 'Lesotho Loti',
        lyd: 'Libyan Dinar',
        mad: 'Moroccan Dirham',
        mdl: 'Moldovan Leu',
        mga: 'Malagasy Ariary',
        mkd: 'Macedonian Denar',
        mmk: 'Myanmar Kyat',
        mnt: 'Mongolian Tugrik',
        mop: 'Macanese Pataca',
        mro: 'Mauritanian Ouguiya',
        mur: 'Mauritian Rupee',
        mvr: 'Maldivian Rufiyaa',
        mwk: 'Malawian Kwacha',
        mxn: 'Mexican Peso',
        myr: 'Malaysian Ringgit',
        mzn: 'Mozambican Metical',
        nad: 'Namibian Dollar',
        ngn: 'Nigerian Naira',
        nio: 'Nicaraguan Córdoba',
        nok: 'Norwegian Krone',
        npr: 'Nepalese Rupee',
        omr: 'Omani Rial',
        pab: 'Panamanian Balboa',
        pen: 'Peruvian Nuevo Sol',
        pgk: 'Papua New Guinean Kina',
        php: 'Philippine Peso',
        pkr: 'Pakistani Rupee',
        pln: 'Polish Zloty',
        pyg: 'Paraguayan Guaraní',
        qar: 'Qatari Riyal',
        ron: 'Romanian Leu',
        rsd: 'Serbian Dinar',
        rub: 'Russian Ruble',
        rwf: 'Rwandan Franc',
        sar: 'Saudi Riyal',
        sbd: 'Solomon Islands Dollar',
        scr: 'Seychellois Rupee',
        sdg: 'Sudanese Pound',
        sek: 'Swedish Krona',
        sgd: 'Singapore Dollar',
        shp: 'Saint Helenian Pound',
        sll: 'Sierra Leonean Leone',
        sos: 'Somali Shilling',
        srd: 'Surinamese Dollar',
        std: 'São Tomé and Príncipe Dobra',
        svc: 'Salvadoran Colón',
        syp: 'Syrian Pound',
        szl: 'Swazi Lilangeni',
        thb: 'Thai Baht',
        tjs: 'Tajikistani Somoni',
        tmt: 'Turkmenistani Manat',
        tnd: 'Tunisian Dinar',
        top: "Tongan Pa'anga",
        try: 'Turkish Lira',
        ttd: 'Trinidad and Tobago Dollar',
        twd: 'Taiwanese New Dollar',
        tzs: 'Tanzanian Shilling',
        uah: 'Ukrainian Hryvnia',
        ugx: 'Ugandan Shilling',
        uyu: 'Uruguayan Peso',
        uzs: 'Uzbekistani Som',
        vef: 'Venezuelan Bolívar Fuertes',
        vnd: 'Vietnamese Dong',
        vuv: 'Vanuatu Vatu',
        wst: 'Samoan Tala',
        xaf: 'Central African CFA Franc',
        xcd: 'East Caribbean Dollar',
        xof: 'West African CFA Franc',
        xpf: 'CFP Franc',
        yer: 'Yemeni Rial',
        zar: 'South African Rand',
        zmk: 'Zambian Kwacha (expiring)',
        zmw: 'Zambian Kwacha',
        byn: 'Belarussian Ruble',
        clf: 'Unidad de Fomento',
        cou: 'Unidad de Valor Real',
        cup: 'Cuban Peso',
        ktd: 'Cayman Islands Dollar',
        mru: 'Ouguiya',
        mxv: 'Mexican Unidad de Inversion',
        skk: 'Slovak Koruna',
        sle: 'Leone',
        ssp: 'South Sudanese Pound',
        stn: 'Dobra',
        ves: 'Venezualan Bolivar',
        zwl: 'Zimbabwe Dollar',
    }
);
