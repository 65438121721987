import { Popup } from '@approvalmax/ui/src/components';
import { selectors } from 'modules/common';
import { useSelector } from 'modules/react-redux';
import { memo } from 'react';

import { Content as ProfileEditPopupContent } from './components/Content/Content';
import { useProfileEditPopup } from './ProfileEditPopup.hooks';

export const ProfileEditPopup = memo(() => {
    const profile = useSelector(selectors.profile.findProfile);

    const { isOpen, handleClose } = useProfileEditPopup(profile);

    if (!profile) {
        return null;
    }

    return (
        <Popup
            open={isOpen}
            onToggle={handleClose}
            size='xsmall'
            fullScreen
            closable
            preventAutoClose
            overflowY='hidden'
        >
            <ProfileEditPopupContent profile={profile} />
        </Popup>
    );
});

ProfileEditPopup.displayName = 'ProfileEditPopup';
