import { Flex } from '@approvalmax/ui/src/components';
import { useAtomValue } from 'jotai';
import { selectors, ui } from 'modules/common';
import { domain } from 'modules/data';
import { useSelector } from 'modules/react-redux';
// FIXME: We can't import from other pages.
import { NetSuiteBillLinkedToBillPaymentRequestCardWrapper } from 'pages/requestList/containers/netsuite/NetSuiteBillLinkedToBillPaymentRequestCardWrapper/NetSuiteBillLinkedToBillPaymentRequestCardWrapper';
import { XeroBillLinkedToBatchPaymentRequestCardWrapper } from 'pages/requestList/containers/xero/XeroBillLinkedToBatchPaymentRequestCardWrapper/XeroBillLinkedToBatchPaymentRequestCardWrapper';
import { FC, memo, useEffect } from 'react';
import { isOcrEnabledForTemplate } from 'shared/helpers';
import { ContentSplitViewMode, contentSplitViewState } from 'shared/states';

import { OcrUploadPanel } from '../OcrUploadPanel/OcrUploadPanel';
import { ocrAttachmentIdState } from '../OcrUploadPanel/OcrUploadPanel.states';
import { UploadPlaceholder } from '../UploadPlaceholder/UploadPlaceholder';
import { LeftItemContentProps } from './LeftItemContent.types';

export const LeftItemContent: FC<LeftItemContentProps> = memo((props) => {
    const {
        activeAttachment,
        goToNextDocument,
        goToPrevDocument,
        setSplitViewFirstItemTitle,
        splitViewFirstItemTitle,
        activeRequest,
    } = props;

    const ocrAttachmentId = useAtomValue(ocrAttachmentIdState);
    const contentSplitView = useAtomValue(contentSplitViewState);

    const company = useSelector((state) => selectors.company.getCompanyById(state, activeRequest.companyId));

    useEffect(() => {
        if (activeAttachment && setSplitViewFirstItemTitle && splitViewFirstItemTitle !== activeAttachment.name) {
            setSplitViewFirstItemTitle(activeAttachment.name);
        }
    }, [activeAttachment, splitViewFirstItemTitle, setSplitViewFirstItemTitle]);

    if (activeAttachment) {
        const showOcrUploadPanel =
            activeRequest.integrationCode &&
            isOcrEnabledForTemplate(company, activeRequest.integrationCode) &&
            activeAttachment.id === ocrAttachmentId;

        return (
            <Flex direction='column' spacing='0' height='100%' wrap={false}>
                <Flex overflowY='hidden' direction='column' grow={1} spacing='16'>
                    {showOcrUploadPanel && <OcrUploadPanel request={activeRequest} attachment={activeAttachment} />}

                    <Flex grow={1}>
                        <ui.DocumentPreviewPanel
                            document={activeAttachment}
                            onRequestBack={goToPrevDocument}
                            onRequestNext={goToNextDocument}
                        />
                    </Flex>
                </Flex>
            </Flex>
        );
    }

    if (
        activeRequest.integrationCode === domain.IntegrationCode.NetSuiteBillPayment &&
        (contentSplitView.mode === ContentSplitViewMode.AttachmentsToNetSuiteBillLinkedToBillPayment ||
            contentSplitView.mode === ContentSplitViewMode.NetSuiteBillLinkedToBillPayment)
    ) {
        return (
            <NetSuiteBillLinkedToBillPaymentRequestCardWrapper
                setContainerTitle={setSplitViewFirstItemTitle}
                billPaymentRequest={activeRequest}
            />
        );
    }

    switch (activeRequest.integrationCode) {
        case domain.IntegrationCode.XeroBillBatchPayment:
        case domain.IntegrationCode.XeroAirwallexBatchPayment:
        case domain.IntegrationCode.XeroAmaxPayBatchPayment:
            return (
                <XeroBillLinkedToBatchPaymentRequestCardWrapper
                    setContainerTitle={setSplitViewFirstItemTitle}
                    batchPaymentRequest={activeRequest}
                />
            );

        default:
            return <UploadPlaceholder request={activeRequest} setContainerTitle={setSplitViewFirstItemTitle} />;
    }
});

LeftItemContent.displayName = 'LeftItemContent';
