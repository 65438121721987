import { domain } from 'modules/data';
import { FC, memo } from 'react';
import styled from 'styled-components';

import FieldEntry from '../../../../../components/FieldEntry';
import FieldEntryWrapper from '../../../../../components/FieldEntryWrapper/FieldEntryWrapper';
import SectionHeaderText from '../SectionHeaderText/SectionHeaderText';
import { messages } from './PurchaseSettingsSection.messages';

interface PurchaseSettingsSectionProps {
    contact: domain.XeroContact;
}

const Root = styled.div`
    display: flex;
    flex-direction: column;
    width: calc(50% - 30px);
`;

const PurchaseSettingsSection: FC<PurchaseSettingsSectionProps> = (props) => {
    const { contact } = props;

    if (!contact.purchasesAccount && !contact.purchasesTrackingCategories.length) {
        return null;
    }

    return (
        <Root>
            <SectionHeaderText>{messages.header}</SectionHeaderText>

            <FieldEntryWrapper>
                <FieldEntry title={messages.defaultAccount} value={contact.purchasesAccount} />
            </FieldEntryWrapper>

            {contact.purchasesTrackingCategories.map((tc, index) => (
                <FieldEntryWrapper key={tc.category.id}>
                    <FieldEntry title={tc.category.text || messages.trackingOption({ index })} value={tc.value} />
                </FieldEntryWrapper>
            ))}
        </Root>
    );
};

export default memo(PurchaseSettingsSection);
