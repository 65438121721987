import { Checkbox } from '@approvalmax/ui/src/components';
import { domain } from 'modules/data';
import { FC, memo, useCallback } from 'react';

import { SettingsSection } from '../SettingsSection/SettingsSection';
import SettingsSubsection from '../SettingsSubsection/SettingsSubsection';
import { messages } from './SectionFraudChangesAfter.messages';
import { SectionFraudChangesAfterProps } from './SectionFraudChangesAfter.types';

const SectionFraudChangesAfter: FC<SectionFraudChangesAfterProps> = memo((props) => {
    const { company, templateSettings, setTemplateSettings, qa, readonly } = props;

    const settings = templateSettings?.postApprovalChangeDetectionSettings;

    const onChangePostApprovalTarget = useCallback(
        (newTargets: domain.TemplateSettingsPostApprovalChangeDetectionTarget[]) => {
            setTemplateSettings((prevTemplateSettings) => ({
                ...prevTemplateSettings,
                postApprovalChangeDetectionSettings: {
                    ...prevTemplateSettings.postApprovalChangeDetectionSettings!,
                    targets: newTargets,
                },
            }));
        },
        [setTemplateSettings]
    );

    if (!settings) {
        return null;
    }

    return (
        <SettingsSection
            title={messages.title}
            learnMoreLink='https://support.approvalmax.com/portal/kb/articles/what-is-the-fraud-detection-changes-after-approval-setting'
        >
            <SettingsSubsection subtitle={messages.subtitle}>
                <Checkbox.Group
                    name='detectionChangesAfterApproval'
                    disabled={readonly}
                    onChange={onChangePostApprovalTarget}
                    value={settings.targets}
                    block
                >
                    <Checkbox
                        value={domain.TemplateSettingsPostApprovalChangeDetectionTarget.ContactId}
                        data-qa={qa(
                            `FraudDetectionChangesAfterSection-${domain.TemplateSettingsPostApprovalChangeDetectionTarget.ContactId}`
                        )}
                    >
                        {messages.contactIdLabel}
                    </Checkbox>

                    <Checkbox
                        value={domain.TemplateSettingsPostApprovalChangeDetectionTarget.NetAmount}
                        data-qa={qa(
                            `FraudDetectionChangesAfterSection-${domain.TemplateSettingsPostApprovalChangeDetectionTarget.NetAmount}`
                        )}
                    >
                        {messages.netAmountLabel({
                            companyCurrencyCode: company.defaultCurrencyText,
                        })}
                    </Checkbox>

                    <Checkbox
                        value={domain.TemplateSettingsPostApprovalChangeDetectionTarget.TrackingCategories}
                        data-qa={qa(
                            `FraudDetectionChangesAfterSection-${domain.TemplateSettingsPostApprovalChangeDetectionTarget.TrackingCategories}`
                        )}
                    >
                        {messages.trackingCategoriesLabel}
                    </Checkbox>

                    <Checkbox
                        value={domain.TemplateSettingsPostApprovalChangeDetectionTarget.LineItemAccountCode}
                        data-qa={qa(
                            `FraudDetectionChangesAfterSection-${domain.TemplateSettingsPostApprovalChangeDetectionTarget.LineItemAccountCode}`
                        )}
                    >
                        {messages.lineItemAccountCodeLabel}
                    </Checkbox>

                    <Checkbox
                        value={domain.TemplateSettingsPostApprovalChangeDetectionTarget.LineItemCode}
                        data-qa={qa(
                            `FraudDetectionChangesAfterSection-${domain.TemplateSettingsPostApprovalChangeDetectionTarget.LineItemCode}`
                        )}
                    >
                        {messages.lineItemCodeLabel}
                    </Checkbox>

                    <Checkbox
                        value={domain.TemplateSettingsPostApprovalChangeDetectionTarget.LineItemDescription}
                        data-qa={qa(
                            `FraudDetectionChangesAfterSection-${domain.TemplateSettingsPostApprovalChangeDetectionTarget.LineItemDescription}`
                        )}
                    >
                        {messages.lineItemDescriptionLabel}
                    </Checkbox>

                    <Checkbox
                        value={domain.TemplateSettingsPostApprovalChangeDetectionTarget.Currency}
                        data-qa={qa(
                            `FraudDetectionChangesAfterSection-${domain.TemplateSettingsPostApprovalChangeDetectionTarget.Currency}`
                        )}
                    >
                        {messages.currencyLabel}
                    </Checkbox>
                </Checkbox.Group>
            </SettingsSubsection>
        </SettingsSection>
    );
});

export default SectionFraudChangesAfter;
