import { domain } from 'modules/data';
import { useMemo } from 'react';
import { findDocumentFieldState } from 'shared/helpers';

export const useNetSuiteBillFieldSettings = (documentFields: domain.DocumentField[] | undefined) =>
    useMemo(
        () => ({
            account: findDocumentFieldState(documentFields, 'Account'),
            billingAddress: findDocumentFieldState(documentFields, 'BillingAddress'),
            class: findDocumentFieldState(documentFields, 'Class'),
            currency: findDocumentFieldState(documentFields, 'Currency'),
            department: findDocumentFieldState(documentFields, 'Department'),
            discountAmount: findDocumentFieldState(documentFields, 'DiscountAmount'),
            discountDate: findDocumentFieldState(documentFields, 'DiscountDate'),
            dueDate: findDocumentFieldState(documentFields, 'DueDate'),
            paymentHold: findDocumentFieldState(documentFields, 'IsPaymentHold'),
            location: findDocumentFieldState(documentFields, 'Location'),
            memo: findDocumentFieldState(documentFields, 'Memo'),
            exchangeRate: findDocumentFieldState(documentFields, 'OriginalExchangeRate'),
            postingPeriod: findDocumentFieldState(documentFields, 'PostingPeriod'),
            reference: findDocumentFieldState(documentFields, 'Reference'),
            terms: findDocumentFieldState(documentFields, 'Terms'),
            transactionDate: findDocumentFieldState(documentFields, 'TransactionDate'),
            transactionNumber: findDocumentFieldState(documentFields, 'TransactionNumber'),
            vatRegistration: findDocumentFieldState(documentFields, 'VatRegistrationNumber'),
            vendor: findDocumentFieldState(documentFields, 'Vendor'),
        }),
        [documentFields]
    );
