import { useAtomValue } from 'jotai';
import { FC, memo, useEffect } from 'react';
import { ContentSplitViewMode, contentSplitViewState } from 'shared/states';

import RequestCardSkeleton from '../../../components/RequestCard/RequestCard.skeleton';
import { XeroRequestCard } from '../XeroRequestCard/XeroRequestCard';
import { useBillByIntegration } from './XeroBillLinkedToBatchPaymentRequestCardWrapper.hooks';
import { XeroBillLinkedToBatchPaymentRequestCardWrapperProps } from './XeroBillLinkedToBatchPaymentRequestCardWrapper.types';

export const XeroBillLinkedToBatchPaymentRequestCardWrapper: FC<XeroBillLinkedToBatchPaymentRequestCardWrapperProps> =
    memo((props) => {
        const { batchPaymentRequest, setContainerTitle } = props;

        const contentSplitView = useAtomValue(contentSplitViewState);

        const batchPaymentRequestId = batchPaymentRequest?.id;
        const { companyId } = batchPaymentRequest || {};

        const billLinkedToBatchPaymentId =
            (contentSplitView.mode === ContentSplitViewMode.BillLinkedToBatchPayment && contentSplitView.billId) ||
            null;

        const batchPaymentHasBill =
            batchPaymentRequest &&
            'items' in batchPaymentRequest.details &&
            batchPaymentRequest.details.items.some(
                (item) => item.xeroBillInvoiceRequestId === billLinkedToBatchPaymentId
            );

        const { request, requestDetails, matchingData } = useBillByIntegration({
            companyId,
            billId: billLinkedToBatchPaymentId || '',
            batchPaymentRequestId: batchPaymentRequestId,
            integrationCode: batchPaymentRequest?.integrationCode,
            enabled: !!(batchPaymentRequestId && billLinkedToBatchPaymentId && companyId && batchPaymentHasBill),
        });

        useEffect(() => {
            if (request && request.displayName) {
                setContainerTitle(request.displayName);
            }
        }, [request, setContainerTitle]);

        if (!request) return <RequestCardSkeleton />;

        return (
            <XeroRequestCard
                request={request}
                preloadedRequestDetails={requestDetails}
                preloadedMatchingData={matchingData}
                readonly
            />
        );
    });

XeroBillLinkedToBatchPaymentRequestCardWrapper.displayName = 'XeroBillLinkedToBatchPaymentRequestCardWrapper';
