import { RequestParams, RequestQueryOptions } from '@approvalmax/data';
import { useGet } from 'shared/data';

import { requestsApiPaths } from '../paths';
import { GetBatchPaymentsRequestAmounts } from './useGetBatchPaymentsRequestAmounts.types';

export const useGetBatchPaymentsRequestAmounts = (
    params?: RequestParams<GetBatchPaymentsRequestAmounts>,
    queryOptions?: RequestQueryOptions<GetBatchPaymentsRequestAmounts>
) => {
    return useGet(requestsApiPaths.batchPaymentsRequestAmounts, {
        params,
        queryOptions,
        apiVersion: 'v2',
    });
};
