import { produce } from 'immer';
import { domain, stateTree } from 'modules/data';
import { setIn, update } from 'modules/immutable';

import {
    Action,
    COMPLETE_INTEGRATION_AUTH_RESPONSE,
    INVALIDATE_FIELDS,
    LOAD_FIELDS,
    LOAD_FIELDS_RESPONSE,
} from '../actions';

export default function (state: stateTree.Meta, action: Action): stateTree.Meta {
    switch (action.type) {
        case INVALIDATE_FIELDS: {
            return update(state, 'loadedFields', (lls: stateTree.FieldSetMeta[]) =>
                lls.filter((x) => x.companyId !== action.payload.companyId)
            );
        }

        case LOAD_FIELDS: {
            const companyId = action.payload.companyId;
            const templateIntegrationCode = action.payload.templateIntegrationCode;
            const type = action.payload.type;

            return update(state, 'loadedFields', (lls: stateTree.FieldSetMeta[]) =>
                lls.map((x) => {
                    if (
                        !(
                            x.companyId === companyId &&
                            x.templateIntegrationCode === templateIntegrationCode &&
                            x.type === type
                        )
                    ) {
                        return x;
                    }

                    return {
                        ...x,
                        loading: true,
                    };
                })
            );
        }

        case LOAD_FIELDS_RESPONSE: {
            const companyId = action.payload.request.companyId;
            const templateIntegrationCode = action.payload.request.templateIntegrationCode;
            const type = action.payload.request.type;

            let newState = update(state, 'loadedFields', (fieldSetMetas: stateTree.FieldSetMeta[]) =>
                fieldSetMetas
                    .filter((x) => {
                        /**
                         * for NetSuite keep only last field set
                         * because of dynamic NetSuite custom fields
                         */
                        if (
                            templateIntegrationCode === domain.IntegrationCode.NetSuiteBill ||
                            templateIntegrationCode === domain.IntegrationCode.NetSuitePO
                        ) {
                            return !(x.companyId === companyId && x.type === type);
                        }

                        return !(
                            x.companyId === companyId &&
                            x.templateIntegrationCode === templateIntegrationCode &&
                            x.type === type
                        );
                    })
                    .concat({
                        type,
                        companyId,
                        templateIntegrationCode,
                        loading: false,
                        stamp: action.payload.stamp,
                    })
            );

            // updating trackingFieldsOrder if XeroTracking fields have been requested
            if (
                type === stateTree.FieldSetType.AllIntegrationFields ||
                (type === stateTree.FieldSetType.TemplateFields &&
                    templateIntegrationCode &&
                    [
                        domain.IntegrationCode.XeroPo,
                        domain.IntegrationCode.XeroBill,
                        domain.IntegrationCode.XeroInvoice,
                        domain.IntegrationCode.XeroCreditNotesPayable,
                        domain.IntegrationCode.XeroCreditNotesReceivable,
                    ].includes(templateIntegrationCode))
            ) {
                newState = setIn(
                    newState,
                    ['trackingFieldsOrder', companyId],
                    Object.values(action.entities!.fields || {})
                        .filter((f) => f.systemPurpose === domain.FieldSystemPurpose.XeroTracking)
                        .map((f) => f.id)
                );
            }

            return newState;
        }

        case COMPLETE_INTEGRATION_AUTH_RESPONSE:
            return produce(state, (draft) => {
                for (let template of Object.values(action.payload.entities.templates)) {
                    if (!draft.viewableTemplates.some((t) => t.id === template.id)) {
                        draft.viewableTemplates.push({
                            companyId: template.companyId,
                            enabled: template.enabled,
                            id: template.id,
                            integrationCode: template.integrationCode,
                            name: template.templateName,
                        });
                    }
                }
            });

        default:
            return state;
    }
}
