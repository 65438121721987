import { miscHelpers } from '@approvalmax/utils';

import { CaretUpIcon } from '../../../../icons';
import { typedMemo } from '../../Table.helpers';
import { defaultSpacing } from '../../Table.styles';
import type { BaseItem } from '../../Table.types';
import { useTableSort } from './ColumnHeader.hooks';
import { Content, iconSize, Root, SortIcon } from './ColumnHeader.styles';
import type { ColumnHeaderProps } from './ColumnHeader.types';

const ColumnHeader = typedMemo(<Item extends BaseItem>(props: ColumnHeaderProps<Item>) => {
    const { children, columnDefinition, columnSpacing, onSort, color, bordered, reorderable, ...otherProps } = props;

    const handleClick = useTableSort(columnDefinition, onSort);

    const { name, sortable, spacing } = columnDefinition;

    return (
        <Root
            title={name}
            $sortable={sortable}
            $color={color}
            $bordered={bordered}
            $spacing={miscHelpers.spacingPropToCss(spacing || columnSpacing || defaultSpacing)}
            $textAlign={columnDefinition.headerTextAlign}
            $verticalAlign={columnDefinition.headerVerticalAlign}
            onClick={handleClick}
            {...otherProps}
        >
            {children ?? (
                <Content>
                    <div>{columnDefinition.name}</div>

                    {columnDefinition.sortable && columnDefinition.sortOrder && (
                        <SortIcon $sortOrder={columnDefinition.sortOrder}>
                            <CaretUpIcon size={iconSize} />
                        </SortIcon>
                    )}
                </Content>
            )}
        </Root>
    );
});

export default ColumnHeader;
