import { Mods, mods, theme } from '@approvalmax/theme';
import { StyledMods } from '@styled-kit/mods';
import styled from 'styled-components';

import { sidebarWidth } from './Sidebar.constants';

export const Root = styled.div<StyledMods<Mods<'orientation'>>>`
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    flex-grow: 0;
    overflow: auto;
    height: 100%;
    border-right: 1px solid ${theme.color.midnight50};
    width: calc(${sidebarWidth}px + ${theme.container.spacing.large});

    ${mods.orientation.horizontal`
        width: 100%;
        height: auto;
        border: none;
        flex-direction: row;
    `}
`;
