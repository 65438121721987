import uniqueId from 'lodash/uniqueId';
import { domain } from 'modules/data';

import { NetSuiteExpenseLineExtendedExpenseReport } from '../../../data/types';
import { checkExpenseLineIsEmpty } from '../checkExpenseLineIsEmpty/checkExpenseLineIsEmpty';

export const extendExpenseLine = (
    item: domain.NetSuiteExpenseLineExpenseReport
): NetSuiteExpenseLineExtendedExpenseReport => ({
    ...item,
    id: uniqueId('netSuiteExpenseLine-'),
    isEmpty: checkExpenseLineIsEmpty(item),
});
