import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('modules.TwoFaEnablingWizard.StepBackupMethods', {
    popupTitle: 'Two-factor authentication setup',
    title: 'Add another option to fall back on',
    description:
        "For using the Authenticator app, you'll be prompted to provide an alternative 2FA option so that we can verify it's you",
    backupCodes: 'Backup codes',
    backupEmail: 'Backup email',
});
