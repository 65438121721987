import { useState } from 'react';
import { useController } from 'react-hook-form';

import { FocusEmitter } from '../../../FocusEmitter/FocusEmitter';
import { Form } from '../../../Form/Form';
import { BaseItem } from '../../Table.types';
import BodyCell from '../BodyCell/BodyCell';
import { BodyFormCellProps } from './BodyFormCell.types';

/**
 * BodyFormCell is a component that renders a form field inside a table cell with a focus emitter.
 * It is also used to connect form fields to react-hook-form and synchronize the field state with the cell state.
 */
export const BodyFormCell = <Item extends BaseItem>(props: BodyFormCellProps<Item>) => {
    const { children, name, ...restProps } = props;

    const [isFocused, setIsFocused] = useState(false);

    const { fieldState } = useController({ name });

    /**
     * Prevents focus if the target is not a cell.
     * This is needed because we have buttons, options and other elements inside controls,
     * and we don't want to focus when clicking on them.
     */
    const shouldPreventFocus = (target: EventTarget | null) => {
        return target instanceof HTMLElement && target.tagName !== 'TD';
    };

    return (
        <FocusEmitter
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            shouldPreventFocus={shouldPreventFocus}
        >
            {({ ref, current }) => (
                <BodyCell
                    {...restProps}
                    invalid={fieldState.invalid}
                    disabled={Boolean(current && 'disabled' in current ? current.disabled : false)}
                    focus={isFocused}
                    cursor='text'
                    hover
                >
                    <Form.Part>{children({ ref, name })}</Form.Part>
                </BodyCell>
            )}
        </FocusEmitter>
    );
};
