import { Box, Button, Dropdown, Flex, Text } from '@approvalmax/ui/src/components';
import { domHelpers } from '@approvalmax/utils';
import { FC, memo, useCallback, useState } from 'react';

import { messages } from './ConfirmationDropdown.messages';
import { ConfirmationDropdownProps } from './ConfirmationDropdown.types';

export const ConfirmationDropdown: FC<ConfirmationDropdownProps> = memo((props) => {
    const { children, onConfirm, onCancel, title, confirmText, cancelText, display } = props;

    const [open, setOpen] = useState(false);

    const handleConfirm = useCallback(() => {
        onConfirm();
        setOpen(false);
    }, [onConfirm]);

    const handleCancel = useCallback(() => {
        onCancel?.();
        setOpen(false);
    }, [onCancel]);

    return (
        <Dropdown onOpen={setOpen} open={open} width='max-content' display={display} activator={children}>
            <Box width={172} spacing='8' onClick={domHelpers.stopPropagation}>
                <Flex direction='column' spacing='12'>
                    <Text font='label' fontSize='medium' color='midnight80'>
                        {title || messages.defaultTitle}
                    </Text>

                    <Flex spacing='12' wrap={false}>
                        <Button color='red40' size='small' onClick={handleConfirm} block>
                            {confirmText || messages.defaultConfirmText}
                        </Button>

                        <Button color='midnight40' size='small' onClick={handleCancel} block variant='outline'>
                            {cancelText || messages.defaultCancelText}
                        </Button>
                    </Flex>
                </Flex>
            </Box>
        </Dropdown>
    );
});
