import './connectToPanel.scss';

import { Button, ExternalLink, LoadingBar, QBooksLogo, TransparentButton } from '@approvalmax/ui';
import { domain } from 'modules/data';
import { integrationActions } from 'modules/integration';
import { useSelector } from 'modules/react-redux';
import { QboConnectIcon, XeroIcon } from 'modules/sprites';
import { FC, memo, useState } from 'react';
import bemFactory from 'react-bem-factory';
import { useDispatch } from 'react-redux';

import { getLoading } from '../../../../selectors/moduleSelectors';
import { messages } from './ConnectToPanel.messages';

const bem = bemFactory.block('first-start-connect-to-panel');

interface ConnectToPanelProps {
    className?: string;
}

const ConnectToPanel: FC<ConnectToPanelProps> = (props) => {
    const { className } = props;

    const dispatch = useDispatch();

    const isLoading = useSelector(getLoading);
    const [activeIntegrationType, setActiveIntegrationType] = useState<domain.IntegrationType | null>(null);

    function onConnect(integrationType: domain.IntegrationType) {
        setActiveIntegrationType(integrationType);
        dispatch(
            integrationActions.connectToIntegration({
                integrationType,
                companyId: null,
                finalRedirectToPage: 'workflow',
                showGlobalProgress: false,
            })
        );
    }

    const config = {
        [domain.IntegrationType.Xero]: {
            IntegratedSystemIcon: XeroIcon,
            connectButton: () => (
                <Button
                    title={messages.xeroConnectButtonTitle}
                    colorTheme='aqua'
                    preset='compact'
                    disabled={isLoading}
                    execute={() => onConnect(domain.IntegrationType.Xero)}
                >
                    {messages.connectButtonText}
                </Button>
            ),
            loadingBarTheme: 'aqua' as const,
            title: messages.xeroTitle,
            description: messages.xeroDescription,
            learnMoreUrl: 'https://support.approvalmax.com/portal/kb/approvalmax-1/approvalmax-for-xero',
        },
        [domain.IntegrationType.QBooks]: {
            IntegratedSystemIcon: QBooksLogo,
            connectButton: () => (
                <TransparentButton
                    title={messages.qbConnectButtonTitle}
                    disabled={isLoading}
                    execute={() => onConnect(domain.IntegrationType.QBooks)}
                >
                    {({ elementProps }) => (
                        <button className={bem('qbo-connect-button')} {...elementProps}>
                            <QboConnectIcon width={88} height={24} />
                        </button>
                    )}
                </TransparentButton>
            ),
            loadingBarTheme: 'brand' as const,
            title: messages.qbTitle,
            description: messages.qbDescription,
            learnMoreUrl: 'https://support.approvalmax.com/portal/kb/approvalmax-1/approvalmax-for-quickbooks-online',
        },
    };

    function renderTile(integrationType: keyof typeof config) {
        const data = config[integrationType];

        return (
            <li className={bem('tile')}>
                <LoadingBar
                    className={bem('tile-loading-bar', {
                        visible: isLoading && activeIntegrationType === integrationType,
                    })}
                    theme={data.loadingBarTheme}
                />

                <div className={bem('tile-content')}>
                    <div className={bem('tile-title')}>
                        <data.IntegratedSystemIcon className={bem('tile-image')} width={40} height={40} />

                        <div className={bem('tile-title-text')}>{data.title}</div>

                        <div>{data.connectButton()}</div>
                    </div>

                    <div className={bem('tile-description')}>{data.description}</div>

                    <ExternalLink href={data.learnMoreUrl}>{messages.learnMoreText}</ExternalLink>
                </div>
            </li>
        );
    }

    return (
        <section className={bem.add(className)()}>
            <h2>{messages.sectionHeader} </h2>

            <ul className={bem('list')}>
                {renderTile(domain.IntegrationType.Xero)}

                {renderTile(domain.IntegrationType.QBooks)}
            </ul>
        </section>
    );
};

export default memo(ConnectToPanel);
