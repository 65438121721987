import { errorHelpers } from '@approvalmax/utils';
import { domain } from 'modules/data';

export const getLineItemTypeByIntegrationCode = (integrationCode: domain.IntegrationCode) => {
    switch (integrationCode) {
        case domain.IntegrationCode.XeroBill:
            return domain.ReportCode.XeroBillInvoiceLine;

        case domain.IntegrationCode.XeroInvoice:
            return domain.ReportCode.XeroSalesInvoiceLine;

        case domain.IntegrationCode.XeroPo:
            return domain.ReportCode.XeroPurchaseOrderLine;

        case domain.IntegrationCode.XeroQuote:
            return domain.ReportCode.XeroQuoteLine;

        case domain.IntegrationCode.XeroCreditNotesPayable:
            return domain.ReportCode.XeroAPCreditNoteLine;

        case domain.IntegrationCode.XeroCreditNotesReceivable:
            return domain.ReportCode.XeroARCreditNoteLine;

        case domain.IntegrationCode.QBooksPo:
            return domain.ReportCode.QBooksPurchaseOrderLine;

        case domain.IntegrationCode.QBooksBill:
            return domain.ReportCode.QBooksBillInvoiceLine;

        case domain.IntegrationCode.QBooksExpense:
            return domain.ReportCode.QBooksExpenseLine;

        case domain.IntegrationCode.QBooksInvoice:
            return domain.ReportCode.QBooksSalesInvoiceLine;

        case domain.IntegrationCode.QBooksVendor:
        case domain.IntegrationCode.XeroBillBatchPayment:
        case domain.IntegrationCode.XeroContact:
            return domain.ReportCode.Request;

        case domain.IntegrationCode.QBooksJournalEntry:
            return domain.ReportCode.QBooksJournalEntryLine;

        case domain.IntegrationCode.XeroManualJournal:
            return domain.ReportCode.XeroManualJournalLine;

        case domain.IntegrationCode.NetSuiteBill:
            return domain.ReportCode.NetSuiteBillLine;

        case domain.IntegrationCode.NetSuitePO:
            return domain.ReportCode.NetSuitePurchaseOrderLine;

        case domain.IntegrationCode.NetSuiteSalesOrder:
        case domain.IntegrationCode.NetSuiteInvoice:
            return domain.ReportCode.NetSuiteSalesOrderLine;

        case domain.IntegrationCode.DearPo:
            return domain.ReportCode.DearPurchaseOrderLine;

        case domain.IntegrationCode.XeroAirwallexBatchPayment:
            return domain.ReportCode.AirwallexXeroBatchPaymentLine;

        case domain.IntegrationCode.XeroAmaxPayBatchPayment:
            return domain.ReportCode.XeroAmaxPayBatchPaymentLine;

        case domain.IntegrationCode.NetSuiteBillPayment:
        case domain.IntegrationCode.NetSuiteExpenseReport:
        case domain.IntegrationCode.NetSuiteRA:
        case domain.IntegrationCode.NetSuiteVRA:
        case domain.IntegrationCode.NetSuiteJournalEntry:
        case domain.IntegrationCode.NetSuiteVendor:
            return null;

        default:
            throw errorHelpers.assertNever(integrationCode);
    }
};
