import { useAtomValue, useSetAtom } from 'jotai';
import { useCallback } from 'react';

import { ConfirmationPopup } from '../ConfirmationPopup/ConfirmationPopup';
import type { ConfirmationPopupProps } from '../ConfirmationPopup/ConfirmationPopup.types';
import { closeConfirmationCallback } from './ConfirmationManager.helpers';
import { activeConfirmationState, confirmationManagerState } from './ConfirmationManager.states';

export const ConfirmationManager = () => {
    const activeConfirmation = useAtomValue(activeConfirmationState);
    const setConfirmations = useSetAtom(confirmationManagerState);

    const handleConfirm = useCallback(() => {
        activeConfirmation?.payload.resolve();
        setConfirmations(closeConfirmationCallback);
    }, [activeConfirmation?.payload, setConfirmations]);

    const handleToggle = useCallback<ConfirmationPopupProps['onToggle']>(
        (toggle) => {
            if (!toggle) {
                activeConfirmation?.payload.reject();
                setConfirmations(closeConfirmationCallback);
            }
        },
        [activeConfirmation?.payload, setConfirmations]
    );

    return (
        <ConfirmationPopup
            open={Boolean(activeConfirmation?.open)}
            {...activeConfirmation?.payload.params}
            onConfirm={handleConfirm}
            onToggle={handleToggle}
        />
    );
};
