import { genVariables } from './helpers';

export const variables = {
    theme: {
        light: {
            color: {
                transparent: 'rgba(255, 255, 255, 0)',
                transparentRed: 'rgba(255, 255, 255, 0)',

                white100: '#FFFFFF',

                black100: '#000000',

                mint100: '#36CF8A',
                mint90: '#5ED9A1',
                mint80: '#86E2B9',
                mint70: '#AFECD0',
                mint60: '#D7F5E8',
                mint50: '#EBFAF3',
                mint40: '#F5FDF9',

                silver100: '#E5E6EF',
                silver90: '#EEEFF5',
                silver80: '#F2F3F9',

                green100: '#4C8B5D',
                green90: '#5E976D',
                green80: '#70A27D',
                green70: '#82AE8E',
                green60: '#94B99E',
                green50: '#A5C5AE',
                green40: '#B7D1BE',
                green30: '#C9DCCE',
                green20: '#DBE8DF',

                midnight100: '#04052E',
                midnight90: '#1D1E43',
                midnight80: '#363758',
                midnight70: '#686982',
                midnight60: '#9B9BAB',
                midnight50: '#CACAD5',
                midnight40: '#E3E3EA',
                midnight30: '#F0F0F5',
                midnight20: '#F7F7F9',

                blue100: '#2C4DF9',
                blue90: '#4F6BFD',
                blue80: '#637BFD',
                blue70: '#768CFD',
                blue60: '#8A9CFE',
                blue50: '#9DADFE',
                blue40: '#B1BDFE',
                blue30: '#C4CEFE',
                blue20: '#D8DEFF',
                blue10: '#ECEFFF',

                cobalt100: '#1B2FD5',
                cobalt90: '#3244D9',
                cobalt80: '#4959DD',
                cobalt70: '#5F6DE2',
                cobalt60: '#7682E6',
                cobalt50: '#8D97EA',

                red100: '#D5162D',
                red90: '#DE283E',
                red80: '#DE4054',
                red70: '#DE5969',
                red60: '#EA8C98',
                red50: '#F3C2C8',
                red40: '#F9DCDF',
                red30: '#FBE8EA',
                red20: '#FFF4F5',

                brandXero100: '#17B2E8',
                brandXero90: '#2EBAEA',
                brandXero80: '#45C1ED',
                brandXero70: '#5DC9EF',
                brandXero60: '#74D1F1',

                brandQBO100: '#2CA01C',
                brandQBO90: '#41AA33',
                brandQBO80: '#56B349',
                brandQBO70: '#6BBD60',

                brandIntuit100: '#0277C5',

                brandCin7100: '#0023C9',
                brandCin790: '#0028E4',
                brandCin780: '#002DFF',
                brandCin770: '#163FFE',
                brandCin760: '#4363FC',

                brandDext100: '#D05818',
                brandDext90: '#FF5A02',
                brandDext60: '#FF9C67',

                brandDextMustard100: '#F8BD5A',
                brandDextMustard90: '#FACF88',
                brandDextMustard80: '#FBD595',
                brandDextMustard70: '#FBDAA2',

                brandAWX100: '#FF6E3F',
                brandAWX90: '#FF7F55',
                brandAWX80: '#FF8B65',
                brandAWX60: '#FFB58B',

                brandNetSuite100: '#000000',
            },

            gradient: {
                green100: 'linear-gradient(292.78deg, #4ABC86 14.79%, #146C78 85.21%)',
                green70: 'linear-gradient(292.78deg, #8FE4BC 14.79%, #4CA0BA 85.21%)',
                green50: 'linear-gradient(292.78deg, #B0FED9 14.79%, #89DBD3 50.39%, #A8C8E2 85.21%)',
                green20: 'linear-gradient(292.78deg, #D3FFEA 14.79%, #C8E2F6 85.21%)',
            },

            shadow: {
                xxsmall: '0px 1px 4px rgba(4, 5, 46, 0.07), 0px 1px 8px rgba(4, 5, 46, 0.08)',
                xsmall: '0px 2px 6px rgba(4, 5, 46, 0.07), 0px 2px 18px rgba(4, 5, 46, 0.08)',
                small: '0px 4px 10px rgba(4, 5, 46, 0.09), 0px 4px 30px rgba(4, 5, 46, 0.12)',
                medium: '0px 6px 16px 4px rgba(4, 5, 46, 0.1), 0px 8px 40px 8px rgba(4, 5, 46, 0.16)',
            },
        },

        dark: {
            color: {
                transparent: 'rgba(0, 0, 0, 0)',

                white100: '#292A33',

                black100: '#F7F7F9',

                mint100: '#36CF8A',
                mint90: '#5ED9A1',
                mint80: '#86E2B9',
                mint70: '#AFECD0',
                mint60: '#D7F5E8',
                mint50: '#EBFAF3',
                mint40: '#F5FDF9',

                silver100: '#393A47',
                silver90: '#373841',
                silver80: '#31323B',

                green100: '#99D0A8',
                green90: '#8DBD99',
                green80: '#85B190',
                green70: '#79A184',
                green60: '#6E9479',
                green50: '#597762',
                green40: '#475F4E',
                green30: '#37483C',
                green20: '#2C3A30',

                midnight100: '#F7F7F9',
                midnight90: '#E0E2EE',
                midnight80: '#B9BDD1',
                midnight70: '#868A9E',
                midnight60: '#5B5F73',
                midnight50: '#414456',
                midnight40: '#353848',
                midnight30: '#30323F',
                midnight20: '#2C2E38',

                blue100: '#ACB9FF',
                blue90: '#9CABFA',
                blue80: '#8F9FEE',
                blue70: '#8A9AED',
                blue60: '#7B88CD',
                blue50: '#6974AC',
                blue40: '#535A80',
                blue30: '#41455A',
                blue20: '#353847',
                blue10: '#2E303E',

                cobalt100: '#1B2FD5',
                cobalt90: '#3244D9',
                cobalt80: '#4959DD',
                cobalt70: '#5F6DE2',
                cobalt60: '#7682E6',
                cobalt50: '#8D97EA',

                red100: '#FF9DA8',
                red90: '#F88E99',
                red80: '#DB838D',
                red70: '#B77B82',
                red60: '#966972',
                red50: '#72565C',
                red40: '#564347',
                red30: '#47383B',
                red20: '#3D3234',

                brandXero100: '#17B2E8',
                brandXero90: '#2EBAEA',
                brandXero80: '#45C1ED',
                brandXero70: '#5DC9EF',
                brandXero60: '#74D1F1',

                brandQBO100: '#2CA01C',
                brandQBO90: '#41AA33',
                brandQBO80: '#56B349',
                brandQBO70: '#6BBD60',

                brandIntuit100: '#0277C5',

                brandCin7100: '#0023C9',
                brandCin790: '#0028E4',
                brandCin780: '#002DFF',
                brandCin770: '#163FFE',
                brandCin760: '#4363FC',

                brandDext100: '#D05818',
                brandDext90: '#FF5A02',
                brandDext60: '#FF9C67',

                brandDextMustard100: '#F8BD5A',
                brandDextMustard90: '#FACF88',
                brandDextMustard80: '#FBD595',
                brandDextMustard70: '#FBDAA2',

                brandAWX100: '#FF6E3F',
                brandAWX90: '#FF7F55',
                brandAWX80: '#FF8B65',
                brandAWX60: '#FFB58B',

                brandNetSuite100: '#000000',
            },

            gradient: {
                green100: 'linear-gradient(292.78deg, #4ABC86 14.79%, #146C78 85.21%)',
                green70: 'linear-gradient(292.78deg, #8FE4BC 14.79%, #4CA0BA 85.21%)',
                green50: 'linear-gradient(292.78deg, #B0FED9 14.79%, #89DBD3 50.39%, #A8C8E2 85.21%)',
                green20: 'linear-gradient(292.78deg, #D3FFEA 14.79%, #C8E2F6 85.21%)',
            },

            shadow: {
                xsmall: '0px 2px 6px rgba(0, 0, 0, 0.18), 0px 2px 18px rgba(0, 0, 0, 0.18)',
                small: '0px 4px 10px rgba(0, 0, 0, 0.2), 0px 4px 30px rgba(0, 0, 0, 0.22)',
                medium: '0px 6px 16px 4px rgba(0, 0, 0, 0.1), 0px 8px 40px 8px rgba(0, 0, 0, 0.16)',
            },
        },
    },

    fontSize: {
        headline: {
            xxsmall: '16px',
            xsmall: '18px',
            small: '22px',
            medium: '26px',
            large: '32px',
        },
        body: {
            xxsmall: '8px',
            xsmall: '10px',
            small: '12px',
            medium: '14px',
            large: '16px',
        },
        label: {
            xxsmall: '8px',
            xsmall: '10px',
            small: '12px',
            medium: '14px',
            large: '16px',
        },
    },

    lineHeight: {
        headline: {
            xxsmall: '20px',
            xsmall: '24px',
            small: '28px',
            medium: '32px',
            large: '36px',
        },
        body: {
            xxsmall: '12px',
            xsmall: '12px',
            small: '16px',
            medium: '20px',
            large: '24px',
        },
        label: {
            xxsmall: '8px',
            xsmall: '12px',
            small: '12px',
            medium: '16px',
            large: '20px',
        },
    },

    fontWeight: {
        regular: '400',
        medium: '500',
        bold: '700',
    },

    fontFamily: {
        inter: "'Inter', sans-serif, Arial",
        poppins: "'Poppins', sans-serif, Arial",
        roboto: "'Roboto', sans-serif, Arial",
    },

    radius: {
        xxsmall: '2px',
        xsmall: '4px',
        small: '8px',
        medium: '12px',
        large: '16px',
    },

    duration: {
        fast: '100',
        medium: '200',
        slow: '400',
    },

    container: {
        spacing: {
            xsmall: '16px',
            small: '24px',
            medium: '40px',
            large: '60px',
        },
        viewport: {
            xxsmall: '320px',
            xsmall: '768px',
            small: '960px',
            medium: '1280px',
            large: '1680px',
        },
        breakpoint: {
            xxsmall: '539px',
            xsmall: '767px',
            small: '959px',
            medium: '1279px',
            large: '1679px',
        },
        content: {
            xxsmall: '100%',
            xsmall: '720px',
            small: '912px',
            medium: '1232px',
            large: '1632px',
        },
    },
} as const;

export const variablesLightTheme = {
    ...variables,
    ...variables.theme.light,
    theme: {
        ...variables.theme,
    },
} as const;

export const theme = genVariables(variablesLightTheme);
