import { selectors } from 'modules/common';
import { backend, domain } from 'modules/data';
import { useSelector } from 'modules/react-redux';
import OpenInSourceLink from 'pages/requestList/components/OpenInSourceLink/OpenInSourceLink';
import { FC, memo } from 'react';
import styled from 'styled-components';

import SectionSeparator from '../../../components/SectionSeparator/SectionSeparator';
import { FinancialDetailsSection } from './components/FinancialDetailsSection/FinancialDetailsSection';
import PurchaseSettingsSection from './components/PurchaseSettingsSection/PurchaseSettingsSection';
import RequestContactAddressSection from './components/RequestContactAddressSection/RequestContactAddressSection';
import RequestContactInformationSection from './components/RequestContactInformationSection/RequestContactInformationSection';
import RequestContactPersonSection from './components/RequestContactPersonSection/RequestContactPersonSection';
import SalesSettingsSection from './components/SalesSettingsSection/SalesSettingsSection';
import TaxSection from './components/TaxSection/TaxSection';
import { messages } from './ContactRequestCard.messages';
import { isAddressEmpty, isPurchaseSettingsEmpty, isSalesSettingsEmpty } from './ContactRequestCard.utils';

interface ContactRequestCardProps {
    request: domain.XeroContactRequest;
}

const Root = styled.div`
    padding: 20px 60px;
`;

const SettingContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;

const ContactRequestCard: FC<ContactRequestCardProps> = (props) => {
    const { request } = props;

    const contact = request.details || {};

    const company = useSelector((state) => selectors.company.findCompanyById(state, request.companyId));

    const isUKCompany = company?.integration?.countryCode === backend.OrganisationVersion.UK;

    const isBillingAddressEmpty = isAddressEmpty(contact.billingAddress);
    const isDeliveryAddressEmpty = isAddressEmpty(contact.deliveryAddress);

    const showDeliveryAddress =
        !isDeliveryAddressEmpty || (!isBillingAddressEmpty && contact.deliveryAddress?.sameAsBillingAddress);

    const hasSalesSettings = !isSalesSettingsEmpty(contact);
    const hasPurchaseSettings = !isPurchaseSettingsEmpty(contact);

    const showSettings = hasSalesSettings || hasPurchaseSettings;

    return (
        <Root>
            <RequestContactPersonSection contact={contact} />

            <RequestContactInformationSection contact={contact} />

            {!isBillingAddressEmpty && (
                <>
                    <RequestContactAddressSection address={contact.billingAddress} title={messages.billingAddress} />

                    <SectionSeparator />
                </>
            )}

            {showDeliveryAddress && (
                <>
                    <RequestContactAddressSection
                        sameAsBillingAddress={contact.deliveryAddress?.sameAsBillingAddress}
                        address={contact.deliveryAddress}
                        title={messages.deliveryAddress}
                    />

                    <SectionSeparator />
                </>
            )}

            {showSettings && (
                <>
                    <SettingContainer>
                        {hasSalesSettings && <SalesSettingsSection contact={contact} />}

                        {hasPurchaseSettings && <PurchaseSettingsSection contact={contact} />}
                    </SettingContainer>

                    <SectionSeparator />
                </>
            )}

            <TaxSection contact={contact} />

            <FinancialDetailsSection contact={contact} isUKCompany={isUKCompany} />

            <OpenInSourceLink
                url={contact.url}
                integrationType={contact.integrationType}
                integrationCode={contact.integrationCode}
            />
        </Root>
    );
};

export default memo(ContactRequestCard);
