import { z } from 'zod';

export const select = {
    string: z.string().nullable().optional(),
    number: z.number().nullable().optional(),
};

export const textField = {
    string: z.string().trim().optional(),
    email: (max?: number) => {
        let emailRule = z.string().trim().email();

        if (max) {
            emailRule = emailRule.max(max);
        }

        /**
         * Email validator mark empty string is invalid
         * As it's optional we need to handle empty string as valid value
         */
        return z.union([z.literal(''), emailRule.optional()]);
    },
    url: z.union([z.literal(''), z.string().trim().url()]),
};

export const numberField = {
    number: z.number().nullish(),
};
