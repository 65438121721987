/**
 * Developer: Stepan Burguchev
 * Date: 6/16/2017
 * Copyright: 2015-2017 ApprovalMax
 *       All Rights Reserved
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF ApprovalMax
 *       The copyright notice above does not evidence any
 *       actual or intended publication of such source code.
 */

import { Guid } from '@approvalmax/types';
import { errorHelpers, objectHelpers } from '@approvalmax/utils';
import { domain, stateTree } from 'modules/data';
import { getSelectArgument1, getSelectArgument2, getSelectArgument3 } from 'modules/react-redux';
import moment from 'moment';
import createCachedSelector from 're-reselect';
import { createSelector } from 'reselect';

import { getCompanies, getExpiredTrialCompanies, getTrialCompaniesExpiringIn14Days } from './companySelectors';
import { messages } from './subscriptionSelectors.messages';
import { ExpandedCompany, ExpandedSubscription, ExpandedUser } from './types';
import { getUsers } from './userSelectors';

type State = stateTree.State;

function getNextBillingInDays(subscription: domain.Subscription) {
    if (!subscription.nextBillingDate) {
        return null;
    }

    const nextBillingDate = moment.utc(subscription.nextBillingDate);
    const now = moment.utc();
    const days = Math.floor(moment.duration(nextBillingDate.diff(now)).as('days')) + 1;

    return Math.max(0, days);
}

function getGraceEndsInDays(subscription: domain.Subscription) {
    if (!subscription.dueDate || subscription.status !== domain.SubscriptionStatus.Grace) {
        return null;
    }

    const GRACE_DURATION = 14;
    const nextBillingDate = moment.utc(subscription.dueDate).add(GRACE_DURATION, 'days');
    const now = moment.utc();
    const days = Math.floor(moment.duration(nextBillingDate.diff(now)).as('days')) + 1;

    return Math.max(0, days);
}

function getBillingCycleName(billing: domain.SubscriptionBillingCycle) {
    switch (billing) {
        case domain.SubscriptionBillingCycle.Annually:
            return messages.billingNameAnnual;

        case domain.SubscriptionBillingCycle.Monthly:
            return messages.billingNameMonthly;

        case domain.SubscriptionBillingCycle.OneTime:
            return messages.billingNameOneTime;

        case domain.SubscriptionBillingCycle.BiAnnually:
            return messages.billingNameBiAnnually;

        case domain.SubscriptionBillingCycle.Quarterly:
            return messages.billingNameQuarterly;

        default:
            return errorHelpers.assertNever(billing);
    }
}

export function getPlanShortName(plan: domain.CompanyPlan) {
    switch (plan) {
        case domain.CompanyPlan.Professional:
            return messages.planNameProfessional;

        case domain.CompanyPlan.XeroPremium:
            return messages.planNameXeroPremium;

        case domain.CompanyPlan.QBooksPremium:
            return messages.planNameQBooksPremium;

        case domain.CompanyPlan.NetSuitePremium:
            return messages.planNameNetSuitePremium;

        case domain.CompanyPlan.XeroAdvanced:
            return messages.planNameXeroAdvanced;

        case domain.CompanyPlan.QBooksAdvanced:
            return messages.planNameQBooksAdvanced;

        case domain.CompanyPlan.NetSuiteAdvanced:
            return messages.planNameNetSuiteAdvanced;

        case domain.CompanyPlan.Xero:
            return messages.planNameXero;

        case domain.CompanyPlan.QBooks:
            return messages.planNameQBooks;

        case domain.CompanyPlan.NetSuite:
            return messages.planNameNetSuite;

        case domain.CompanyPlan.Trial:
            return messages.planNameTrial;

        case domain.CompanyPlan.PaymentRequired:
            return messages.planNamePaymentRequired;

        case domain.CompanyPlan.Partner:
            return messages.planNamePartner;

        case domain.CompanyPlan.Retired:
            return messages.planNameRetired;

        case domain.CompanyPlan.ReceiptBankProfessional:
            return messages.receiptBankProfessional;

        case domain.CompanyPlan.ReceiptBankQuickBooksOnlineStandard:
            return messages.receiptBankQuickBooksOnlineStandard;

        case domain.CompanyPlan.ReceiptBankQuickBooksOnlineAdvanced:
            return messages.receiptBankQuickBooksOnlineAdvanced;

        case domain.CompanyPlan.ReceiptBankQuickBooksOnlinePremium:
            return messages.receiptBankQuickBooksOnlinePremium;

        case domain.CompanyPlan.ReceiptBankXeroStandard:
            return messages.receiptBankXeroStandard;

        case domain.CompanyPlan.ReceiptBankXeroAdvanced:
            return messages.receiptBankXeroAdvanced;

        case domain.CompanyPlan.ReceiptBankXeroPremium:
            return messages.receiptBankXeroPremium;

        case domain.CompanyPlan.SmallBusinessPackage:
            return messages.smallBusinessPackage;

        case domain.CompanyPlan.Cin7CorePremium:
            return messages.cin7CorePremium;

        case domain.CompanyPlan.BookkeepersAndAccountantsPackage:
            return messages.bookkeepersAndAccountantPackage;

        default:
            return errorHelpers.assertNever(plan);
    }
}

export function getSubscriptionDisplayName(subscription: domain.Subscription, systemName: string) {
    let name = getPlanShortName(subscription.plan);
    let billing = getBillingCycleName(subscription.billingCycle);

    return `${name} ${billing} (${systemName})`;
}

export function isActivePaidSubscription(subscription: domain.Subscription) {
    return (
        (subscription.status === domain.SubscriptionStatus.Active ||
            subscription.status === domain.SubscriptionStatus.Grace ||
            subscription.status === domain.SubscriptionStatus.PendingCancellation) &&
        subscription.plan !== domain.CompanyPlan.Retired
    );
}

const expandSubscription: (
    subscription: domain.Subscription,
    companies: ExpandedCompany[],
    users: ExpandedUser[]
) => ExpandedSubscription = createCachedSelector(
    getSelectArgument1<domain.Subscription>(),
    getSelectArgument2<ExpandedCompany[]>(),
    getSelectArgument3<ExpandedUser[]>(),
    (subscription, companies, users) => {
        const isActivePaid = isActivePaidSubscription(subscription);
        const accountOwner = users.find((u) => u.id === subscription.accountOwnerId)!;
        const systemName = `AMS-${subscription.systemId}`;
        const shortName = getPlanShortName(subscription.plan);
        const displayName = getSubscriptionDisplayName(subscription, systemName);
        const graceEndsInDays = getGraceEndsInDays(subscription);
        const nextBillingInDays = getNextBillingInDays(subscription);
        const companies2 = subscription.companyIds.map((cId) => companies.find((c) => c.id === cId)!);
        const result: ExpandedSubscription = {
            ...subscription,
            systemName,
            displayName,
            accountOwner,
            graceEndsInDays,
            shortName,
            nextBillingInDays,
            isGrace: subscription.status === domain.SubscriptionStatus.Grace,
            isActivePaid,
            companies: companies2,
        };

        return result;
    }
)((license: domain.Subscription) => license.id);

export function findCompanySubscription(state: State, companyId: Guid): ExpandedSubscription | null {
    return getAllSubscriptions(state).find((s) => s.companyIds.includes(companyId)) || null;
}

export const getAllSubscriptions: (state: State) => ExpandedSubscription[] = createSelector(
    (state: State) => state.entities.subscriptions || objectHelpers.emptyObject(),
    (state: State) => getCompanies(state),
    (state: State) => getUsers(state),
    (subscriptions, companies, users) => {
        return Object.values(subscriptions).map((l) => expandSubscription(l, companies, users));
    }
);

export function getSubscriptionById(state: State, id: string): ExpandedSubscription | null {
    const subscription = getAllSubscriptions(state).find((s) => s.id === id);

    if (!subscription) {
        return null;
    }

    return subscription;
}

/**
 * Checks if there is an active paid subscription that:
 * - can be used to add new companies
 * - can be prolongated
 */
export function hasActivePaidSubscription(state: State) {
    return getAllSubscriptions(state).some((s) => s.isActivePaid);
}

/**
 * Returns grace subscriptions which are related to companies where I'm a manager.
 */
export function getGraceSubscriptions(state: State) {
    return getAllSubscriptions(state).filter(
        (l) => l.status === domain.SubscriptionStatus.Grace && l.companies.some((c) => c.flags.isManager)
    );
}

/**
 * Returns expired subscriptions which are related to companies where I'm a manager.
 */
export function getExpiredSubscriptions(state: State) {
    return getAllSubscriptions(state).filter(
        (l) => l.status === domain.SubscriptionStatus.Expired && l.companies.some((c) => c.flags.isManager)
    );
}

export function hasLicensingProblems(state: State) {
    return (
        getTrialCompaniesExpiringIn14Days(state).length > 0 ||
        getExpiredTrialCompanies(state).length > 0 ||
        [...getGraceSubscriptions(state), ...getExpiredSubscriptions(state)].some((l) => l.companies.length > 0)
    );
}
