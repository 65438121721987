import { enabledByParams, RequestParams, RequestQueryOptions } from '@approvalmax/data';
import { useGet } from 'shared/data';

import { integrationApiPaths } from '../paths';
import { GetNetSuiteAccounts } from './useGetNetSuiteAccounts.types';

export const useGetNetSuiteAccounts = (
    params?: RequestParams<GetNetSuiteAccounts>,
    queryOptions?: RequestQueryOptions<GetNetSuiteAccounts>
) => {
    return useGet(integrationApiPaths.getNetSuiteAccounts, {
        params,
        queryOptions: {
            ...queryOptions,
            enabled: enabledByParams<GetNetSuiteAccounts['params']['query']>(
                {
                    companyId: params?.query?.companyId,
                    group: params?.query?.group,
                },
                queryOptions
            ),
        },
        apiVersion: 'v1',
        method: 'get',
        mapToCamelCase: true,
    });
};
