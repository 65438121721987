import { Button } from '@approvalmax/ui/src/components';
import { memo, useCallback } from 'react';

import { useTwoFaWizardStep } from '../../TwoFaEnablingWizard.hooks';
import { WizardStep } from '../../TwoFaEnablingWizard.types';
import { messages } from './AnotherMethodButton.messages';

export const AnotherMethodButton = memo(() => {
    const { setWizardStep } = useTwoFaWizardStep();

    const handleGoBackupMethods = useCallback(() => {
        setWizardStep(WizardStep.backupMethods);
    }, [setWizardStep]);

    return (
        <Button type='button' size='large' color='midnight80' variant='outline' onClick={handleGoBackupMethods}>
            {messages.anotherMethodButton}
        </Button>
    );
});

AnotherMethodButton.displayName = 'AnotherMethodButton';
