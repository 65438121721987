import { messages } from './Content.messages';

export enum TabsId {
    general = 'general',
    notifications = 'notifications',
    security = 'security',
}

export const tabs = [
    {
        id: TabsId.general,
        name: messages.tabGeneral,
    },
    {
        id: TabsId.notifications,
        name: messages.tabNotifications,
    },
    {
        id: TabsId.security,
        name: messages.tabSecurity,
    },
];
