import { domain } from 'modules/data';

import { RenderCellOptions } from '../../../../config/matrixDefinitions.shared';
import XeroSupplierCreationConditionCell from '../XeroSupplierCreationConditionCell/XeroSupplierCreationConditionCell';

export function XeroSupplierRequesterCell({
    rule,
    field,
    readonly,
    integrationCode,
    matrixType,
    lineId,
    condition,
    onConditionChange,
}: RenderCellOptions) {
    return (
        <XeroSupplierCreationConditionCell
            rule={rule}
            field={field}
            readonly={readonly}
            integrationCode={integrationCode}
            lineId={lineId}
            onConditionChange={onConditionChange}
            condition={condition as domain.ExactValuesCondition | domain.ServerCondition | domain.AlwaysTrueCondition}
            matrixType={matrixType}
        />
    );
}
