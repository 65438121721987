import { useCallback } from 'react';

import { ActivatorProps } from './Activator.types';

/**
 * Select query text and reset dropdown list filtration
 */
export const useSelectQuery = (
    props: Pick<
        ActivatorProps<Record<string, any>, boolean, boolean>,
        'selectOnFocus' | 'autocomplete' | 'inputRef' | 'setInputValuePristine'
    >
) => {
    const { selectOnFocus, autocomplete, inputRef, setInputValuePristine } = props;

    return useCallback(() => {
        // mark input as a pristine to avoid further dropdown list filtration
        setInputValuePristine(true);

        if (selectOnFocus && autocomplete) {
            inputRef.current?.select();
        }
    }, [selectOnFocus, autocomplete, inputRef, setInputValuePristine]);
};
