import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('modules.TwoFaActionWizard.ScreenAppCode', {
    description:
        'Enter the 6-digit authentication code provided by the Authentication app connected to your ApprovalMax account.',
    supportText: 'If you have problems, refer to this <supportlink>support article</supportlink>.',
    codeFieldTitle: 'Enter the Authenticator code',
    wrongCodeErrorText: 'Wrong code',
    userCodePlaceholder: 'Enter the Authenticator code...',
    confirmButton: 'Confirm',
    anotherMethodButton: 'Use another authentication method',
    trustThisDevice: 'Trust this device',
});
