import { RequestMutationOptions } from '@approvalmax/data';
import { useMutate } from 'shared/data';

import { budgetsApiPaths } from '../paths';
import { useInvalidateGetQBooksBudgets } from '../useGetQBooksBudgets/useGetQBooksBudgets';
import { SyncQBooksBudget } from './useSyncQBooksBudget.types';

export const useSyncQBooksBudget = (mutationOptions?: RequestMutationOptions<SyncQBooksBudget>) => {
    const invalidateGetQBooksBudgets = useInvalidateGetQBooksBudgets();

    return useMutate(budgetsApiPaths.qBooksBudgetSync, {
        mutationOptions: {
            ...mutationOptions,
            onSuccess: async (data, variables, context) => {
                invalidateGetQBooksBudgets({ path: { companyId: variables.params?.path?.companyId } });

                mutationOptions?.onSuccess?.(data, variables, context);
            },
        },
        apiVersion: 'v2',
    });
};
