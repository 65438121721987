import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('pages.auth.pageSelectors', {
    receiptBankAuthPageTitle: 'Authentication',
    loginPageTitle: 'Login',
    passwordResetPageTitle: 'Login',
    signupPageTitle: 'Sign up',
    emailConfirmationPageTitle: 'Email Confirmation',
    profileSetupModuleTitle: 'Profile Setup',
    verifyEmailPageTitle: 'Login',
    slackAuthPageTitle: 'Authentication',
    firstTimeSetupPageTitle: 'First Setup',
    passwordResetFinalStepTitle: 'Password reset',
});
