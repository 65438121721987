import { intl, mathService, numberHelpers } from '@approvalmax/utils';
import { domain } from 'modules/data';
import { DoneIcon } from 'modules/sprites';
import moment from 'moment';

import { ColumnDefinition } from '../../../../../selectors/types/ColumnDefinition';
import { isFieldVisible } from '../../NetSuiteRequestCard.helpers';
import { messages } from './NetSuiteSalesOrderItemLines.messages';
import { NoWrapCell } from './NetSuiteSalesOrderItemLines.styles';

export const getColumnDefs = (
    exchangeRate?: number | null
): ColumnDefinition<domain.NetSuiteSalesOrderItemWithId>[] => [
    {
        id: 'item',
        name: messages.itemColumnName,
        value: (li) => (li.item ? li.item.text : null),
        minWidth: 100,
    },
    {
        id: 'description',
        name: messages.description,
        value: (li) => li.description,
        minWidth: 210,
    },
    {
        id: 'quantityCommitted',
        name: messages.quantityCommitted,
        value: (li) => (li.quantityCommitted ? intl.formatNumber(li.quantityCommitted, 'auto') : null),
        alignRight: true,
        cell: (value) => <NoWrapCell>{value}</NoWrapCell>,
    },
    {
        id: 'quantityFulfilled',
        name: messages.quantityFulfilled,
        value: (li) => (li.quantityFulfilled ? intl.formatNumber(li.quantityFulfilled || 0, 'auto') : null),
        alignRight: true,
        cell: (value) => <NoWrapCell>{value}</NoWrapCell>,
    },
    {
        id: 'quantityBilled',
        name: messages.quantityBilled,
        value: (li) => (li.quantityBilled ? intl.formatNumber(li.quantityBilled, 'auto') : null),
        alignRight: true,
        cell: (value) => <NoWrapCell>{value}</NoWrapCell>,
    },
    {
        id: 'quantityBackOrdered',
        name: messages.quantityBackOrdered,
        value: (li) => (li.quantityBackOrdered ? intl.formatNumber(li.quantityBackOrdered, 'auto') : null),
        alignRight: true,
        cell: (value) => <NoWrapCell>{value}</NoWrapCell>,
    },
    {
        id: 'quantity',
        name: messages.quantity,
        value: (li) => intl.formatNumber(li.quantity || 0, 'auto'),
        alignRight: true,
        cell: (value) => <NoWrapCell>{value}</NoWrapCell>,
    },
    {
        id: 'units',
        name: messages.units,
        value: (li) => (li.units ? li.units.text : null),
        alignRight: true,
        cell: (value) => <NoWrapCell>{value}</NoWrapCell>,
    },
    {
        id: 'serialNumbers',
        name: messages.serialNumbers,
        value: (li) => li.serialNumbers,
        minWidth: 100,
    },
    {
        id: 'priceLevel',
        name: messages.priceLevel,
        value: (li) => li.priceLevel?.text ?? null,
        minWidth: 100,
    },
    {
        id: 'unitPrice',
        name: messages.unitPrice,
        value: (li) => {
            let unitPrice = li.unitPrice || 0;

            if (typeof exchangeRate === 'number') {
                unitPrice = mathService.multiply(unitPrice, exchangeRate);
                unitPrice = mathService.round(unitPrice, 8);
            }

            return intl.formatNumber(unitPrice, 'auto');
        },
        alignRight: true,
        cell: (value) => <NoWrapCell>{value}</NoWrapCell>,
    },
    {
        id: 'amount',
        name: messages.amount,
        value: (li) => {
            let amount = li.amount || 0;

            if (typeof exchangeRate === 'number') {
                amount = mathService.multiply(amount, exchangeRate);
                amount = mathService.round(amount, 2);
            }

            return intl.formatNumber(amount, 'auto');
        },
        alignRight: true,
        cell: (value) => <NoWrapCell>{value}</NoWrapCell>,
    },
    {
        id: 'taxCode',
        name: messages.taxCode,
        value: (li) => li.taxCode?.text ?? null,
        minWidth: 100,
    },
    {
        id: 'taxRate',
        name: messages.taxRate,
        value: (li) => (typeof li.taxRate === 'number' ? intl.formatNumber(li.taxRate || 0, 'auto') : li.taxRate),
        alignRight: true,
        cell: (value) => <NoWrapCell>{value}%</NoWrapCell>,
    },
    {
        id: 'taxAmount',
        name: messages.taxAmount,
        value: (li) => {
            let taxAmount = li.taxAmount || 0;

            if (typeof exchangeRate === 'number') {
                taxAmount = mathService.multiply(taxAmount, exchangeRate);
                taxAmount = mathService.round(taxAmount, 2);
            }

            return intl.formatNumber(taxAmount, 'auto');
        },
        alignRight: true,
        cell: (value) => <NoWrapCell>{value}</NoWrapCell>,
    },
    {
        id: 'grossAmount',
        name: messages.grossAmount,
        value: (li) => {
            let { grossAmount } = li;

            if (!grossAmount) {
                return null;
            }

            if (numberHelpers.isNumber(exchangeRate)) {
                grossAmount = mathService.multiply(grossAmount, exchangeRate);
                grossAmount = mathService.round(grossAmount, 2);
            }

            return intl.formatNumber(grossAmount, 'auto');
        },
        alignRight: true,
        minWidth: 100,
    },
    {
        id: 'department',
        name: messages.department,
        value: (li) => li.department?.text ?? null,
        minWidth: 100,
    },
    {
        id: 'class',
        name: messages.class,
        value: (li) => li.class?.text ?? null,
        minWidth: 100,
    },
    {
        id: 'location',
        name: messages.location,
        value: (li) => li.location?.text ?? null,
        minWidth: 100,
    },
    {
        id: 'orderPriority',
        name: messages.orderPriority,
        value: (li) => (typeof li.orderPriority === 'number' ? intl.formatNumber(li.orderPriority, 'auto') : null),
        alignRight: true,
        cell: (value) => <NoWrapCell>{value}</NoWrapCell>,
    },
    {
        id: 'revenueRecognitionSchedule',
        name: messages.revenueRecognitionSchedule,
        value: (li) => li.revenueRecognitionSchedule?.text ?? null,
        minWidth: 100,
    },
    {
        id: 'revenueRecognitionStartDate',
        name: messages.revenueRecognitionStartDate,
        value: (li) =>
            li.revenueRecognitionStartDate ? moment.utc(li.revenueRecognitionStartDate).format('ll') : null,
    },
    {
        id: 'revenueRecognitionEndDate',
        name: messages.revenueRecognitionEndDate,
        value: (li) => (li.revenueRecognitionEndDate ? moment.utc(li.revenueRecognitionEndDate).format('ll') : null),
    },
    {
        id: 'excludeFromRateRequest',
        name: messages.excludeFromRateRequest,
        value: (li) => {
            return li.excludeFromRateRequest ? messages.excludeFromRateRequestTooltip : null;
        },
        width: 50,
        cell: (val) => val && <DoneIcon width={13} height={10} />,
    },
    {
        id: 'isClosed',
        name: messages.closedColumnName,
        value: (li) => {
            return li.isClosed ? messages.closedColumnTooltip : null;
        },
        width: 50,
        cell: (val) => val && <DoneIcon width={13} height={10} />,
    },
];

export const getNetSuiteSalesOrderLineItemsColumnDefenitions = (
    itemsFieldSettings: Record<string, domain.DocumentFieldState>,
    exchangeRate?: number | null
): ColumnDefinition<domain.NetSuiteSalesOrderItemWithId>[] =>
    getColumnDefs(exchangeRate).filter(({ id }) => {
        if (id in itemsFieldSettings) {
            return isFieldVisible(itemsFieldSettings[id as keyof typeof itemsFieldSettings]);
        }

        return false;
    });
