import type { ApiVersion } from '@approvalmax/types';
import { errorHelpers } from '@approvalmax/utils';
import { isBrowser } from '@approvalmax/utils/src/helpers/browser/isBrowser';
import Cookies from 'js-cookie';

import { Env, getAppConfig } from '../configs';
import rootApi from '../rootApi';
import rootApiLegacy from '../rootApiLegacy';
import { ApiSource, ApiVersionQueryMethod, RequestHeaders } from '../types';
import { ApiPathPrefix } from '../types/path';

export const getDefaultApiUrl = (apiSource: ApiSource) => {
    const apiUrlPrefix = Cookies.get(apiSource);

    return apiUrlPrefix || null;
};

export const getApiInstance = (apiSource: ApiSource, apiVersion: ApiVersion, headers?: RequestHeaders) => {
    const getInstance = (apiSource: ApiSource) => {
        switch (apiSource) {
            case 'myAccount':
            case 'webApp':
                return {
                    v1: rootApiLegacy,
                    v2: rootApi,
                };

            case 'devPortal':
            case 'identity':
            case 'adminPanel':
            case 'wallets':
            case 'walletsKyc':
            case 'slack':
                return {
                    v1: rootApi,
                    v2: rootApi,
                };

            default:
                throw errorHelpers.assertNever(apiSource);
        }
    };

    const instance = getInstance(apiSource)[apiVersion];

    if (headers) {
        Object.entries(headers).forEach(([key, value]) => {
            instance.defaults.headers.common[key] = value;
        });
    }

    return instance;
};

export const getApiQueryMethod = <T extends ApiSource>(apiSource: T) => {
    const queryMethod: ApiVersionQueryMethod[T] = (<const>{
        slack: { v1: 'get', v2: 'get' },
        webApp: { v1: 'post', v2: 'get' },
        walletsKyc: { v1: 'get', v2: 'get' },
        wallets: { v1: 'get', v2: 'get' },
        myAccount: { v1: 'post', v2: 'get' },
        adminPanel: { v1: 'get', v2: 'get' },
        devPortal: { v1: 'get', v2: 'get' },
        identity: { v1: 'get', v2: 'get' },
    })[apiSource];

    return queryMethod;
};

export const setApiUrlPrefixes = (apiUrl: string, apiSource: ApiSource, apiVersion: ApiVersion): string => {
    const prefixes: ApiPathPrefix<typeof apiVersion> = {
        slack: '/',
        identity: '/connect/',
        webApp: `/api/${apiVersion}/`,
        walletsKyc: `/api/${apiVersion}/`,
        wallets: `/api/${apiVersion}/`,
        myAccount: `/api/${apiVersion}/`,
        adminPanel: `/${apiVersion}/`,
        devPortal: `/api/${apiVersion}/`,
    };

    return `https://${apiUrl}${prefixes[apiSource]}`;
};

export const getApiUrl = (apiSource: ApiSource, defaultEnv: Env, apiVersion: ApiVersion) => {
    const appConfig = getAppConfig(defaultEnv);
    const defaultApiUrl = getDefaultApiUrl(apiSource);

    return setApiUrlPrefixes(defaultApiUrl || appConfig[apiSource], apiSource, apiVersion);
};

export const setApiUrl = (apiUrl: string | null | undefined, apiSource: ApiSource, reload: boolean) => {
    if (!isBrowser()) return;

    const published = window.location.hostname.includes('approvalmax.com');
    const cookieOptions = {
        domain: published ? window.location.hostname : undefined,
        expires: 365,
        path: '/',
    };

    if (apiUrl) {
        Cookies.set(apiSource, apiUrl, cookieOptions);
    } else {
        Cookies.remove(apiSource, cookieOptions);
    }

    if (reload) {
        window.location.reload();
    }
};

export const setBackendApiUrl = (apiUrl: string | null = null, reload = true) => {
    setApiUrl(apiUrl, 'webApp', reload);
};

export const setMyAccountApiUrl = (apiUrl: string | null = null, reload = true) => {
    setApiUrl(apiUrl, 'myAccount', reload);
};

export const setAdminPanelApiUrl = (apiUrl: string | null = null, reload = true) => {
    setApiUrl(apiUrl, 'adminPanel', reload);
};

export const setDevPortalApiUrl = (apiUrl: string | null = null, reload = true) => {
    setApiUrl(apiUrl, 'devPortal', reload);
};

export const setIdentityApiUrl = (apiUrl: string | null = null, reload = true) => {
    setApiUrl(apiUrl, 'identity', reload);
};

export const setSlackApiUrl = (apiUrl: string | null = null, reload = true) => {
    setApiUrl(apiUrl, 'slack', reload);
};

export const resetBackendApiUrl = (reload = true) => {
    setApiUrl(null, 'webApp', reload);
};

export const resetMyAccountApiUrl = (reload = true) => {
    setApiUrl(null, 'myAccount', reload);
};

export const resetAdminPanelApiUrl = (reload = true) => {
    setApiUrl(null, 'adminPanel', reload);
};

export const resetDevPortalApiUrl = (reload = true) => {
    setApiUrl(null, 'devPortal', reload);
};

export const resetIdentityApiUrl = (reload = true) => {
    setApiUrl(null, 'identity', reload);
};

export const resetSlackApiUrl = (reload = true) => {
    setApiUrl(null, 'slack', reload);
};

if (isBrowser() && window.ApprovalMax) {
    window.ApprovalMax.setBackendApiUrl = setBackendApiUrl;
    window.ApprovalMax.setMyAccountApiUrl = setMyAccountApiUrl;
    window.ApprovalMax.setAdminPanelApiUrl = setAdminPanelApiUrl;
    window.ApprovalMax.setDevPortalApiUrl = setDevPortalApiUrl;
    window.ApprovalMax.setIdentityApiUrl = setIdentityApiUrl;
    window.ApprovalMax.setSlackApiUrl = setSlackApiUrl;
    window.ApprovalMax.resetBackendApiUrl = resetBackendApiUrl;
    window.ApprovalMax.resetMyAccountApiUrl = resetMyAccountApiUrl;
    window.ApprovalMax.resetAdminPanelApiUrl = resetAdminPanelApiUrl;
    window.ApprovalMax.resetDevPortalApiUrl = resetDevPortalApiUrl;
    window.ApprovalMax.resetIdentityApiUrl = resetIdentityApiUrl;
    window.ApprovalMax.resetSlackApiUrl = resetSlackApiUrl;
}
