import { PasskeysFilledIcon } from '@approvalmax/ui';
import { Button } from '@approvalmax/ui/src/components';
import { finishLoginFlow } from 'pages/auth/actions';
import { memo, useCallback, useState } from 'react';
import { useLazyGetFido2AssertionOptions, useSetFido2Assertion } from 'shared/data/webApp/v2';
import { usePasskeyErrorToast } from 'shared/hooks';

import { getAssertionTranfer, getCredentials } from './PasskeyAuthButton.helpers';
import { messages } from './PasskeyAuthButton.messages';
import { PasskeyAuthButtonProps } from './PasskeyAuthButton.types';

export const PasskeyAuthButton = memo<PasskeyAuthButtonProps>((props) => {
    const { className, postAction, block, noUppercase, outline, shouldReturnToCurrentPage, size, text } = props;

    const [isLoading, setIsLoading] = useState(false);

    const [triggerGetFido2AssertionOptions] = useLazyGetFido2AssertionOptions();

    const { mutateAsync: loginWithFido2 } = useSetFido2Assertion();

    const { showErrorToast } = usePasskeyErrorToast();

    const handleLogin = useCallback(async () => {
        setIsLoading(true);

        try {
            const assertionOptions = await triggerGetFido2AssertionOptions({ params: {} });

            const credentials = await getCredentials(assertionOptions);

            await loginWithFido2({
                body: getAssertionTranfer(credentials, assertionOptions),
            });

            finishLoginFlow({
                postAction: shouldReturnToCurrentPage ? window.location.href : postAction,
            });
        } catch {
            showErrorToast(messages.errorText);
        }

        setIsLoading(false);
    }, [triggerGetFido2AssertionOptions, loginWithFido2, postAction, shouldReturnToCurrentPage, showErrorToast]);

    return (
        <Button
            className={className}
            color='midnight80'
            startIcon={<PasskeysFilledIcon />}
            progress={isLoading}
            block={block}
            noUppercase={noUppercase}
            variant={outline ? 'outline' : 'filled'}
            size={size}
            onClick={handleLogin}
        >
            {text ?? messages.title}
        </Button>
    );
});

PasskeyAuthButton.displayName = 'PasskeyAuthButton';
