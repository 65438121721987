import { mixins, mods, RequiredMods, theme } from '@approvalmax/theme';
import { StyledMods } from '@styled-kit/mods';
import styled, { css } from 'styled-components';

import { TagProps } from './Tag.types';

const rootColorMixin = (
    bg: RequiredMods['color'],
    text: RequiredMods['color'],
    border: RequiredMods['color'],
    outlineText: RequiredMods['color']
) => css`
    color: ${theme.color[text]};
    background-color: ${theme.color[bg]};
    box-shadow: inset 0 0 0 1px ${theme.color[border]};

    ${mods.outline.true`
        background-color: transparent;
        color: ${theme.color[outlineText]};
    `};
`;

const rootSizeMixin = (
    paddingVertical: RequiredMods['spacing'],
    paddingHorizontal: RequiredMods['spacing'],
    iconSize: RequiredMods['iconSize'],
    iconShift: RequiredMods['spacing'],
    gap: RequiredMods['spacing']
) => css`
    padding: ${paddingVertical}px ${paddingHorizontal}px;
    column-gap: ${gap}px;

    svg {
        ${mixins.iconSize(iconSize)};
    }

    button {
        margin-right: -${iconShift}px;
        display: inline-flex;
    }
`;

export const Root = styled.div<
    StyledMods<Pick<TagProps, 'color' | 'size' | 'backgroundColor' | 'rounded' | 'outline'>>
>`
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    border-radius: ${theme.radius.xsmall};
    max-width: 100%;

    button {
        padding: 0;
        display: inline-flex;
        color: inherit;
        transition: transform ${theme.duration.medium}ms;

        &:hover,
        &:focus {
            transform: scale(1.5);
            color: inherit;
        }
    }

    ${mods.rounded.true`
        border-radius: 16px;
    `};

    ${mods.color.midnight40(rootColorMixin('midnight40', 'midnight80', 'midnight40', 'midnight70'))};
    ${mods.color.midnight80(rootColorMixin('midnight80', 'white100', 'midnight80', 'midnight80'))};
    ${mods.color.blue20(rootColorMixin('blue20', 'midnight80', 'blue40', 'blue80'))};
    ${mods.color.blue80(rootColorMixin('blue80', 'white100', 'blue80', 'blue80'))};
    ${mods.color.green20(rootColorMixin('green20', 'midnight80', 'green40', 'green80'))};
    ${mods.color.green100(rootColorMixin('green100', 'white100', 'green100', 'green100'))};
    ${mods.color.red100(rootColorMixin('red100', 'white100', 'red100', 'red100'))};
    ${mods.color.brandDext90(rootColorMixin('brandDext90', 'white100', 'brandDext90', 'brandDext90'))};
    ${mods.color.midnight70(rootColorMixin('midnight70', 'white100', 'midnight70', 'midnight70'))};

    ${mods.size.xsmall(rootSizeMixin(2, 4, 8, 0, 4))};
    ${mods.size.small(rootSizeMixin(4, 8, 12, 4, 8))};
    ${mods.size.medium(rootSizeMixin(4, 8, 16, 4, 8))};

    ${mods('backgroundColor')(
        (value) => css`
            background-color: ${theme.color[value]};
        `
    )}
`;
