import { RocketIcon } from '@approvalmax/ui';
import { selectors } from 'modules/common';
import { useMemo } from 'react';
import bemFactory from 'react-bem-factory';
import { getPath, Path } from 'urlBuilder';

import { messages } from './GettingStartedMenu.messages';

const qa = bemFactory.qa('drawer-getting-started');

export const useGettingStartedLinkItems = (company: selectors.types.ExpandedCompany) => {
    const companyId = company.id;

    return useMemo(
        () => [
            {
                id: 'gettingStarted',
                name: messages.gettingStartedName,
                to: getPath(Path.gettingStarted, companyId),
                startIcon: RocketIcon,
                dataQa: qa(),
                inactiveColor: 'mint60' as const,
            },
        ],
        [companyId]
    );
};
