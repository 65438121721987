import './selectRBPlanPopup.scss';

import { Popup, RadioGroupEditor, ScrollableArea } from '@approvalmax/ui';
import { selectors } from 'modules/common';
import { domain } from 'modules/data';
import { useSelector } from 'modules/react-redux';
import { FC, memo, useMemo, useState } from 'react';
import bemFactory from 'react-bem-factory';
import { useDispatch } from 'react-redux';

import { commitSubscriptionData, SelectSubscriptionOption } from '../../actions';
import NoSubscription from '../../components/GrayArea/NoSubscription';
import OtherSubscriptionOptions from '../../components/OtherSubscriptionOptions/OtherSubscriptionOptions';
import SubscriptionsHeader from '../../components/SubscriptionsHeader/SubscriptionsHeader';
import { useDextSuggestedPlansQuery } from './hooks/useDextSuggestedPlansQuery';
import { messages } from './SelectRBPlanPopup.messages';

const bem = bemFactory.block('company-select-rb-subscription-popup');
const qa = bemFactory.qa('company-select-rb-subscription-popup');

interface SelectRBPlanPopupProps {
    className?: string;
}

const SelectRBPlanPopup: FC<SelectRBPlanPopupProps> = (props) => {
    const { className } = props;

    const { accountId, companyId } = useSelector((state) => {
        const account = selectors.profile.getProfileAccount(state);
        const company = selectors.navigation.getActiveCompany(state);

        return { accountId: account.accountId, companyId: company.id };
    });

    const { suggestedPlanIds } = useDextSuggestedPlansQuery(accountId, companyId);

    const plans = useMemo(() => {
        if (!suggestedPlanIds) {
            return [];
        }

        return suggestedPlanIds.map((id) => ({
            id,
            text: selectors.subscription.getPlanShortName(id),
        }));
    }, [suggestedPlanIds]);

    const dispatch = useDispatch();

    const hasApplicableSubscriptions = plans.length > 0;
    const [option, setOption] = useState<SelectSubscriptionOption>(
        hasApplicableSubscriptions ? SelectSubscriptionOption.ExistingPlan : SelectSubscriptionOption.Trial
    );
    const [planId, setPlanId] = useState<domain.CompanyPlan | null>(hasApplicableSubscriptions ? plans[0].id : null);

    function onNext() {
        dispatch(
            commitSubscriptionData({
                option,
                planId,
                isPartnerAccount: true,
            })
        );
    }

    return (
        <Popup.EmptyContent className={bem.add(className)()} qa={qa()}>
            <SubscriptionsHeader header={messages.header} onNext={onNext} isDisabledButton={false} />

            <ScrollableArea className={bem('body-wrp')}>
                <div className={bem('body')}>
                    <div className={bem('subscriptions-title')}>{messages.subscriptionsTitle}</div>

                    {hasApplicableSubscriptions ? (
                        <div className={bem('subscriptions-area')} data-qa={qa('subscriptions-area')}>
                            <RadioGroupEditor
                                value={option === SelectSubscriptionOption.ExistingPlan ? planId : null}
                                onChange={(value: domain.CompanyPlan) => {
                                    setOption(SelectSubscriptionOption.ExistingPlan);
                                    setPlanId(value);
                                }}
                            >
                                {plans.map((s) => (
                                    <RadioGroupEditor.Item key={s.id} id={s.id}>
                                        {s.text}
                                    </RadioGroupEditor.Item>
                                ))}
                            </RadioGroupEditor>
                        </div>
                    ) : (
                        <NoSubscription />
                    )}

                    <OtherSubscriptionOptions
                        showTrialOption
                        trialText={messages.newTrialOption}
                        showTitle={hasApplicableSubscriptions}
                        option={option}
                        setOption={setOption}
                        onChangeSelect={() => {
                            // do nothing.
                        }}
                    />
                </div>
            </ScrollableArea>
        </Popup.EmptyContent>
    );
};

export default memo(SelectRBPlanPopup);
