import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('pages.RequestList.AirwallexBatchPaymentRequestCard.BillsInformationSection', {
    airwallexBatchPaymentBills: 'Airwallex batch payment bills',
    total: 'Total:',
    items: 'Bill',
    date: 'Date',
    paymentMethod: 'Payment method',
    sourceCurrency: 'Source currency',
    amountDue: 'Amount due',
    paymentAmount: 'Payment amount',
    purpose: 'Purpose',
    paymentReference: 'Reference',
    xeroBankAccount: 'Xero bank account',
    beneficiary: 'Beneficiary',
    billCurrency: 'Bill currency',
    attachments: 'Attachments',
    dueDate: 'Due date',
    fee: 'Fee',
    amount: 'Amount',
    sourceCurrencyAmount: 'Amount{br}(source{br}currency)',
    feeAmount: 'Fee',
    airwallexPayment: 'Airwallex Payment',
    openInAirwallex: 'Open in Airwallex',
    xeroPayment: 'Xero Payment',
    paymentStatus: 'Status',
    att: 'Att.',
    openInXero: 'Open in Xero',
    links: 'Links',
    bankDetailsChanged: 'Bank details have changed',
});
