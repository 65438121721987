import { Reference } from '@approvalmax/types';
import { arrayHelpers, errorHelpers } from '@approvalmax/utils';
import uniq from 'lodash/uniq';
import zip from 'lodash/zip';
import { domain } from 'modules/data';
import {
    addArrayItem,
    ImmutableObject,
    insertArrayItem,
    mergeIn,
    removeArrayItem,
    set,
    setIn,
    updateArrayItem,
} from 'modules/immutable';

import {
    Action,
    ADD_XERO_LINE_ITEM,
    CHANGE_XERO_DELIVERY_DETAILS,
    CHANGE_XERO_LINE_AMOUNT_TYPE,
    CHANGE_XERO_LINE_ITEM_ALL_CHECKED,
    CHANGE_XERO_LINE_ITEM_CHECKED,
    CHANGE_XERO_LINE_ITEM_DESCRIPTION,
    CHANGE_XERO_LINE_ITEM_DISCOUNT,
    CHANGE_XERO_LINE_ITEM_DISCOUNT_AMOUNT,
    CHANGE_XERO_LINE_ITEM_DISCOUNT_TYPE,
    CHANGE_XERO_LINE_ITEM_QTY,
    CHANGE_XERO_LINE_ITEM_TAX_AMOUNT,
    CHANGE_XERO_LINE_ITEM_UNIT_PRICE,
    CHANGE_XERO_LINE_ITEMS_ACCOUNT,
    CHANGE_XERO_LINE_ITEMS_ITEM,
    CHANGE_XERO_LINE_ITEMS_TAX,
    CHANGE_XERO_LINE_ITEMS_TRACKING_CATEGORY_VALUE,
    CHANGE_XERO_SUPPLIER,
    CHANGE_XERO_TERMS,
    CLONE_XERO_LINE_ITEMS,
    CLONE_XERO_LINE_ITEMS_ALL,
    CREATE_XERO_CONTACT_RESPONSE,
    REMOVE_ATTACHMENT,
    REMOVE_XERO_LINE_ITEMS,
    REMOVE_XERO_LINE_ITEMS_ALL,
    REORDER_XERO_LINE_ITEMS,
    SELECT_XERO_DELIVERY_ADDRESS,
    UPDATE_XERO_AIRWALLEX_BP_DETAILS,
    UPDATE_XERO_AMAXPAY_BP_DETAILS,
    UPDATE_XERO_BP_DETAILS,
    UPDATE_XERO_CONTACT_BANK_ACCOUNT_WITH_SORT_CODE,
    XERO_EMAIL_TO_SUPPLIER_CHANGE_DATA,
    XERO_EMAIL_TO_SUPPLIER_INIT,
    XERO_EMAIL_TO_SUPPLIER_TOGGLE,
} from '../../actions';
import { XeroContact } from '../../data/xero/XeroContact';
import { XeroContext } from '../../data/xero/XeroContext';
import { RequestEditMode } from '../../selectors/requestSelectors';
import { isEmailToSupplierEmpty } from '../../utils/supplierEmailUtils';

export type RequestType = ImmutableObject<domain.Request>;

const getSupplierToEmails = (supplier: XeroContact | null) => {
    return (
        supplier?.contactPersons
            ?.filter((person) => person.emailAddress && person.includeInEmails)
            .map((person) => person.emailAddress) || []
    );
};

const getEmailDetails = (details: domain.XeroPurchaseOrderDetails | domain.XeroQuoteDetails, context: XeroContext) => {
    const { contact, emailToSupplier } = details;
    const contactEmails = getSupplierToEmails(contact);

    const { supplierEmailSettings: emailSettings } = context;

    const to = contactEmails || [];
    const cc = uniq<string>(emailSettings?.cc || []);
    const subject = emailToSupplier?.subject || emailSettings?.subject || '';
    const body = emailToSupplier?.body || emailSettings?.body || '';

    return { to, subject, body, cc };
};

const updateLineItem = (
    state: RequestType,
    lineItemId: string,
    mutator: (li: domain.XeroLineItem, details: domain.XeroSharedDetails) => domain.XeroLineItem
) => {
    const details = state.details as domain.XeroSharedDetails;

    let lineItem = details.lineItems.find((li) => li.id === lineItemId);

    if (!lineItem) {
        throw errorHelpers.invalidOperationError(`Failed to find line item with id ${lineItemId}`);
    }

    return setIn(
        state,
        ['details', 'lineItems'],
        updateArrayItem(
            details.lineItems,
            (li) => li.id === lineItemId,
            mutator(
                {
                    ...lineItem,
                },
                details
            )
        )
    );
};

const getAdjustedTax = (newTax: domain.XeroTaxCode | null, lineItem: domain.XeroLineItem, request: domain.Request) => {
    const details = request.details as domain.XeroSharedDetails;

    if (details.lineAmountType === domain.LineAmountType.NoTax) {
        return undefined;
    }

    return newTax || undefined;
};

const resetAmountFields = (li: domain.XeroLineItem) => {
    li.taxAmount = undefined;
    li.amount = undefined;
};

export default function (state: RequestType, action: Action): RequestType {
    switch (action.type) {
        case SELECT_XERO_DELIVERY_ADDRESS: {
            const details = state.details as domain.XeroPurchaseOrderDetails;
            const address = action.payload.address;
            const addressText = [action.payload.contact ? action.payload.contact.text : undefined, address.text]
                .filter((x) => x)
                .join('\n');

            return mergeIn(state, ['details', 'delivery'], {
                address: addressText,
                attentionTo: address.attentionTo || details.delivery.attentionTo,
            } as domain.XeroPurchaseOrderDetails['delivery']);
        }

        case CHANGE_XERO_DELIVERY_DETAILS:
            return setIn(state, ['details', 'delivery'], action.payload.deliveryDetails);

        case XERO_EMAIL_TO_SUPPLIER_INIT: {
            const { context, author, details, isNew, initialSendToSupplier } = action.payload;

            const emailSettings = context.supplierEmailSettings;

            const emailDetails: domain.EmailToSupplierDetails = isNew
                ? getEmailDetails(details, context)
                : details.emailToSupplier || { to: [], cc: [], subject: '', body: '' };

            const sendToSupplier = isNew
                ? emailSettings?.state === domain.TemplateSettingsEmailToSupplierState.EnabledAndActive
                : details.sendToSupplier || initialSendToSupplier;

            let emailToSupplier: domain.EmailToSupplier = {
                from: author.displayName,
                to: uniq<string>([...emailDetails.to, ...(details.emailToSupplier?.to || [])]),
                cc: uniq<string>(
                    isNew
                        ? [author.userEmail, ...emailDetails.cc, ...(details.emailToSupplier?.cc || [])]
                        : emailDetails.cc
                ),
                replyTo: isNew ? author.userEmail : details.emailToSupplier?.replyTo || '',
                subject: emailDetails.subject,
                body: emailDetails.body,
                attachments: details.emailToSupplier?.attachments || [],
            };

            const isEmailToSupplierDisabled =
                context.supplierEmailSettings?.state === domain.TemplateSettingsEmailToSupplierState.Disabled;

            if (isEmailToSupplierEmpty(emailToSupplier) && details.contact && !isEmailToSupplierDisabled) {
                const supplierEmailDetails = getEmailDetails(details, context);

                emailToSupplier = {
                    ...emailToSupplier,
                    ...supplierEmailDetails,
                };
            }

            return mergeIn(state, ['details'], { sendToSupplier, emailToSupplier });
        }

        case XERO_EMAIL_TO_SUPPLIER_TOGGLE:
            return setIn(state, ['details', 'sendToSupplier'], action.payload.sendToSupplier);

        case XERO_EMAIL_TO_SUPPLIER_CHANGE_DATA:
            return setIn(state, ['details', 'emailToSupplier', action.payload.key], action.payload.value);

        case REMOVE_ATTACHMENT: {
            let xeroPurchaseOrder = state.details as domain.XeroPurchaseOrderDetails;

            if (xeroPurchaseOrder && xeroPurchaseOrder.emailToSupplier) {
                let attachments: domain.RequestAttachment[] = removeArrayItem(
                    xeroPurchaseOrder.emailToSupplier.attachments || [], // INFO: somehow attachments can be undefined, but types said not (AM-10481) (temporary fix)
                    (a) => a.id === action.payload.attachmentId
                );

                return setIn(state, ['details', 'emailToSupplier', 'attachments'], attachments);
            }

            return state;
        }

        case CREATE_XERO_CONTACT_RESPONSE:
        case CHANGE_XERO_SUPPLIER: {
            const { supplier, details, context } = action.payload;

            const supplierPrev = details.contact ? { ...(details.contact as XeroContact) } : null;

            const isEmailToSupplierDisabled =
                context.supplierEmailSettings?.state === domain.TemplateSettingsEmailToSupplierState.Disabled;

            if (!supplier) {
                let stateNext = { ...state };

                const emailToSupplier = {
                    ...(stateNext.details as domain.XeroPurchaseOrderDetails).emailToSupplier,
                    to: [],
                };

                if (!isEmailToSupplierDisabled) {
                    stateNext = setIn(stateNext, ['details', 'emailToSupplier'], emailToSupplier);
                }

                stateNext = setIn(stateNext, ['details', 'contact'], null);

                return stateNext;
            }

            const detailsNext = { ...(state.details as domain.XeroPurchaseOrderDetails), contact: supplier };
            const supplierEmailDetails = getEmailDetails(detailsNext, context);

            if (
                state.integrationCode &&
                [
                    domain.IntegrationCode.XeroPo,
                    domain.IntegrationCode.QBooksPo,
                    domain.IntegrationCode.XeroQuote,
                    domain.IntegrationCode.XeroInvoice,
                ].includes(state.integrationCode)
            ) {
                if (detailsNext.emailToSupplier) {
                    const emailToSupplier = { ...detailsNext.emailToSupplier };

                    const toExcluded = getSupplierToEmails(supplierPrev);
                    const toEmails = [...emailToSupplier.to, ...supplierEmailDetails.to].filter(
                        (email) => !toExcluded.includes(email)
                    );

                    const ccEmails = [...emailToSupplier.cc];

                    detailsNext.emailToSupplier = {
                        ...emailToSupplier,
                        to: uniq<string>(toEmails),
                        cc: uniq<string>(ccEmails),
                    };
                }

                if (supplier.brandingTheme) {
                    detailsNext.brandingTheme = supplier.brandingTheme;
                }
            }

            if (supplier?.purchase?.lineAmountType) {
                detailsNext.lineAmountType = supplier.purchase.lineAmountType;
                detailsNext.lineItems = detailsNext.lineItems.map((li) => {
                    li = { ...li };
                    resetAmountFields(li);

                    return li;
                });
            }

            const defaultCurrency = state.companyCurrency;
            const currentRequestCurrency = state.currency;
            const newCurrency = supplier.currency || defaultCurrency;

            if (currentRequestCurrency !== newCurrency) {
                state = set(state, 'currency', newCurrency);
                state = set(state, 'currencyExchangeRate', null);
            }

            return set(state, 'details', detailsNext);
        }

        case CHANGE_XERO_TERMS: {
            const { terms } = action.payload;

            const detailsNext = { ...(state.details as domain.XeroPurchaseOrderDetails), terms: terms };

            return set(state, 'details', detailsNext);
        }

        case CHANGE_XERO_LINE_ITEMS_ITEM: {
            switch (action.payload.editMode) {
                case RequestEditMode.Reviewer:
                case RequestEditMode.Submitter:
                case RequestEditMode.ExternalSubmitter:
                    return action.payload.lineItemIds.reduce<RequestType>((prev, lineItemId) => {
                        return updateLineItem(prev, lineItemId, (li) => {
                            const details = state.details as domain.XeroSharedDetails;
                            const newItem = action.payload.newItem;

                            li.item = newItem || undefined;

                            if (!newItem) return li;

                            const contact = details.contact as XeroContact;
                            const itemPurchaseDetails = newItem.purchaseDetails;
                            const itemSalesDetails = newItem.salesDetails;
                            const contactPurchaseDetails = contact?.purchase;
                            const contactSalesDetails = contact?.sales;

                            let account;
                            let taxCode;

                            switch (state.integrationCode) {
                                case domain.IntegrationCode.XeroPo: {
                                    account = itemPurchaseDetails?.account || contactPurchaseDetails?.account;
                                    taxCode =
                                        itemPurchaseDetails?.taxCode ||
                                        contactPurchaseDetails?.taxCode ||
                                        account?.taxCode;
                                    break;
                                }

                                case domain.IntegrationCode.XeroQuote:
                                case domain.IntegrationCode.XeroInvoice:
                                case domain.IntegrationCode.XeroCreditNotesReceivable: {
                                    account = itemSalesDetails?.account || contactSalesDetails?.account;
                                    taxCode =
                                        itemSalesDetails?.taxCode || contactSalesDetails?.taxCode || account?.taxCode;

                                    break;
                                }

                                case domain.IntegrationCode.XeroBill:
                                case domain.IntegrationCode.XeroCreditNotesPayable: {
                                    account = itemPurchaseDetails?.account || contactPurchaseDetails?.account;
                                    taxCode =
                                        contactPurchaseDetails?.taxCode ||
                                        itemPurchaseDetails?.taxCode ||
                                        account?.taxCode;
                                    break;
                                }

                                default:
                                    throw errorHelpers.notSupportedError();
                            }

                            switch (state.integrationCode) {
                                case domain.IntegrationCode.XeroQuote:
                                case domain.IntegrationCode.XeroInvoice:
                                case domain.IntegrationCode.XeroCreditNotesReceivable: {
                                    if (itemSalesDetails) {
                                        li.description = itemSalesDetails?.description;
                                        li.unitPrice = itemSalesDetails?.unitPrice;
                                        li.qty = itemSalesDetails?.unitPrice !== null ? 1 : undefined;
                                        resetAmountFields(li);
                                    }

                                    break;
                                }
                                default: {
                                    if (itemPurchaseDetails) {
                                        li.description = itemPurchaseDetails?.description;
                                        li.unitPrice = itemPurchaseDetails?.unitPrice;
                                        li.qty = itemPurchaseDetails?.unitPrice !== null ? 1 : undefined;
                                        li.discount = undefined;
                                        resetAmountFields(li);
                                    }
                                }
                            }

                            li.account = account;

                            if (taxCode) {
                                li.tax = getAdjustedTax(taxCode, li, state);
                                resetAmountFields(li);
                            }

                            return li;
                        });
                    }, state);

                case RequestEditMode.Editor:
                case RequestEditMode.Approver:
                    return action.payload.lineItemIds.reduce<RequestType>((prev, lineItemId) => {
                        return updateLineItem(prev, lineItemId, (li) => {
                            const newItem = action.payload.newItem;

                            li.item = newItem || undefined;

                            return li;
                        });
                    }, state);

                default:
                    throw errorHelpers.assertNever(action.payload.editMode);
            }
        }

        case CHANGE_XERO_LINE_ITEMS_ACCOUNT:
            return action.payload.lineItemIds.reduce<RequestType>((prev, lineItemId) => {
                return updateLineItem(prev, lineItemId, (li) => {
                    const newAccount = action.payload.newAccount || undefined;

                    li.account = newAccount;

                    if (action.payload.recalculateTax && newAccount) {
                        const details = state.details as domain.XeroSharedDetails;

                        let itemDetails = li.item && li.item.purchaseDetails;

                        const contact = details.contact as XeroContact;

                        let contactTaxCode = contact?.purchase?.taxCode;

                        if (
                            state.integrationCode === domain.IntegrationCode.XeroQuote ||
                            state.integrationCode === domain.IntegrationCode.XeroInvoice
                        ) {
                            itemDetails = li.item && li.item.salesDetails;
                            contactTaxCode = contact?.sales?.taxCode;
                        }

                        const taxCode =
                            contactTaxCode || (itemDetails && itemDetails.taxCode) || newAccount.taxCode || null;

                        li.tax = getAdjustedTax(taxCode, li, state);
                        resetAmountFields(li);
                    }

                    return li;
                });
            }, state);

        case CHANGE_XERO_LINE_ITEMS_TAX:
            return action.payload.lineItemIds.reduce<RequestType>((prev, lineItemId) => {
                return updateLineItem(prev, lineItemId, (li) => {
                    li.tax = action.payload.newTax || undefined;
                    resetAmountFields(li);

                    return li;
                });
            }, state);

        case CHANGE_XERO_LINE_ITEM_DESCRIPTION:
            return updateLineItem(state, action.payload.lineItemId, (li) => {
                li.description = action.payload.newDescription;

                return li;
            });

        case CHANGE_XERO_LINE_ITEM_QTY:
            return updateLineItem(state, action.payload.lineItemId, (li) => {
                li.qty = action.payload.newQty != null ? action.payload.newQty : undefined;
                resetAmountFields(li);

                return li;
            });

        case CHANGE_XERO_LINE_ITEM_UNIT_PRICE:
            return updateLineItem(state, action.payload.lineItemId, (li) => {
                li.unitPrice = action.payload.newUnitPrice != null ? action.payload.newUnitPrice : undefined;
                resetAmountFields(li);

                return li;
            });

        case CHANGE_XERO_LINE_ITEM_DISCOUNT:
            return updateLineItem(state, action.payload.lineItemId, (li) => {
                li.discount = action.payload.newDiscount != null ? action.payload.newDiscount : undefined;
                resetAmountFields(li);

                return li;
            });

        case CHANGE_XERO_LINE_ITEM_DISCOUNT_AMOUNT:
            return updateLineItem(state, action.payload.lineItemId, (li) => {
                li.discountAmount =
                    action.payload.newDiscountAmount != null ? action.payload.newDiscountAmount : undefined;
                resetAmountFields(li);

                return li;
            });

        case CHANGE_XERO_LINE_ITEM_DISCOUNT_TYPE:
            return updateLineItem(state, action.payload.lineItemId, (li) => {
                li.discountType = action.payload.newDiscountType;
                resetAmountFields(li);

                return li;
            });

        case CHANGE_XERO_LINE_ITEM_TAX_AMOUNT:
            return updateLineItem(state, action.payload.lineItemId, (li) => {
                li.taxAmount = action.payload.newTaxAmount || undefined;

                return li;
            });

        case CHANGE_XERO_LINE_ITEMS_TRACKING_CATEGORY_VALUE:
            return action.payload.lineItemIds.reduce<RequestType>((prev, lineItemId) => {
                return updateLineItem(prev, lineItemId, (li) => {
                    // - update tracking category with new value
                    // - remove tracking categories which are missing in XeroContext (have been disabled in Xero)
                    li.tracking = action.payload.xeroContext.trackingCategories
                        .map((trackingCategory) => {
                            const oldTrackingItem = li.tracking.find(
                                (item) => item.category.id === trackingCategory.category.id
                            );
                            const newValue =
                                trackingCategory.category.id === action.payload.trackingCategoryId
                                    ? action.payload.newValue
                                    : oldTrackingItem
                                      ? oldTrackingItem.value
                                      : null;

                            return {
                                category: trackingCategory.category,
                                value: newValue as Reference,
                            };
                        })
                        .filter((item) => item.value);

                    return li;
                });
            }, state);

        case CHANGE_XERO_LINE_ITEM_CHECKED:
            return updateLineItem(state, action.payload.lineItemId, (li) => {
                li.checked = action.payload.checked;

                return li;
            });

        case CHANGE_XERO_LINE_ITEM_ALL_CHECKED: {
            const details = state.details as domain.XeroSharedDetails;

            return setIn(
                state,
                ['details', 'lineItems'],
                details.lineItems.map((lineItem) => ({ ...lineItem, checked: action.payload }))
            );
        }

        case CHANGE_XERO_LINE_AMOUNT_TYPE: {
            const newLineAmountType = action.payload.newLineAmountType;

            if (newLineAmountType === domain.LineAmountType.NoTax) {
                const details = state.details as domain.XeroSharedDetails;

                return mergeIn(state, ['details'], {
                    lineAmountType: newLineAmountType,
                    lineItems: details.lineItems.map((li) => {
                        li = { ...li };
                        li.tax = undefined;
                        resetAmountFields(li);

                        return li;
                    }),
                } as domain.XeroSharedDetails);
            } else {
                const details = state.details as domain.XeroSharedDetails;

                return mergeIn(state, ['details'], {
                    lineAmountType: newLineAmountType,
                    lineItems: details.lineItems.map((li) => {
                        li = { ...li };
                        resetAmountFields(li);

                        return li;
                    }),
                } as domain.XeroSharedDetails);
            }
        }

        case REORDER_XERO_LINE_ITEMS: {
            const details = state.details as domain.XeroSharedDetails;

            return setIn(
                state,
                ['details', 'lineItems'],
                arrayHelpers.arrayMove(details.lineItems, action.payload.oldIndex, action.payload.newIndex)
            );
        }

        case REMOVE_XERO_LINE_ITEMS: {
            const details = state.details as domain.XeroSharedDetails;

            let lineItems = action.payload.lineItemIds.reduce<domain.XeroLineItem[]>((prev, lineItemId) => {
                return removeArrayItem(prev, (li) => li.id === lineItemId);
            }, details.lineItems);

            if (lineItems.length === 0) {
                lineItems = [action.payload.newLineItem];
            }

            return setIn(state, ['details', 'lineItems'], lineItems);
        }

        case REMOVE_XERO_LINE_ITEMS_ALL: {
            return setIn(state, ['details', 'lineItems'], [action.payload.newLineItem]);
        }

        case CLONE_XERO_LINE_ITEMS: {
            const details = state.details as domain.XeroSharedDetails;
            const newLineItems = action.payload.newLineItems;

            return setIn(
                state,
                ['details', 'lineItems'],
                newLineItems.reduce<domain.XeroLineItem[]>((prev, lineItem, index) => {
                    return insertArrayItem(
                        prev,
                        lineItem,
                        prev.findIndex((li) => li.id === action.payload.lineItemIds[index]) + 1
                    );
                }, details.lineItems)
            );
        }

        case CLONE_XERO_LINE_ITEMS_ALL: {
            const details = state.details as domain.XeroSharedDetails;
            const newLineItems = action.payload.newLineItems;

            return setIn(state, ['details', 'lineItems'], zip(details.lineItems, newLineItems).flat());
        }

        case ADD_XERO_LINE_ITEM: {
            const details = state.details as domain.XeroSharedDetails;

            return setIn(state, ['details', 'lineItems'], addArrayItem(details.lineItems, action.payload.newLineItem));
        }

        case UPDATE_XERO_AMAXPAY_BP_DETAILS:
        case UPDATE_XERO_AIRWALLEX_BP_DETAILS:
        case UPDATE_XERO_BP_DETAILS: {
            return mergeIn(state, ['details'], action.payload.details);
        }

        case UPDATE_XERO_CONTACT_BANK_ACCOUNT_WITH_SORT_CODE: {
            const { bankAccountNumber, sortCode } = action.payload;

            const newState = setIn(state, ['details', 'bankAccountDetails'], bankAccountNumber);

            return setIn(newState, ['details', 'sortCode'], sortCode);
        }

        default:
            return state;
    }
}
