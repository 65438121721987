import { useAtom, useAtomValue } from 'jotai';
import { useCallback } from 'react';

import { twoFaProfileDisablingState } from './TwoFaProfileDisabling.states';

export const useTwoFaProfileDisabling = () => {
    const [popup, setPopup] = useAtom(twoFaProfileDisablingState);

    const handleOpen = useCallback(
        (options: { onFinish?: VoidFunction }) => {
            setPopup((state) => ({
                ...state,
                stage: 'wizard',
                onFinish: options.onFinish,
            }));
        },
        [setPopup]
    );

    const handleClose = useCallback(() => {
        setPopup(null);
    }, [setPopup]);

    return {
        handleOpen,
        handleClose,
        stage: popup?.stage ?? null,
    };
};

export const useCheckTwoFaProfileDisablingIsOpen = () => {
    const popup = useAtomValue(twoFaProfileDisablingState);

    return popup !== null;
};

export const useGoToTwoFaProfileDisablingStage = () => {
    const [popup, setPopup] = useAtom(twoFaProfileDisablingState);

    const onFinish = popup?.onFinish;

    const gotoWizard = useCallback(() => {
        setPopup((state) => ({ ...state, stage: 'wizard', selectedBackupMethod: null }));
    }, [setPopup]);

    const gotoSuccess = useCallback(() => {
        setPopup((state) => ({ ...state, stage: 'success' }));
    }, [setPopup]);

    const handleFinish = useCallback(() => {
        onFinish?.();
        setPopup(null);
    }, [onFinish, setPopup]);

    return {
        gotoWizard,
        gotoSuccess,
        handleFinish,
    };
};
