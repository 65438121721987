import { useTheme } from '@approvalmax/theme';
import { useSpring } from '@react-spring/web';
import { useMeasure } from 'react-use';

export const useAnimation = (isOpen: boolean) => {
    const { theme } = useTheme();
    const [animatedRef, { height }] = useMeasure<HTMLDivElement>();

    const animatedStyles = useSpring({
        config: {
            duration: +theme.duration.medium,
        },
        from: {
            height: 0,
            opacity: 0,
            visibility: 'hidden',
        },
        to: {
            height: isOpen ? height : 0,
            opacity: Number(isOpen),
            overflow: isOpen ? 'initial' : 'hidden',
            visibility: isOpen ? 'visible' : 'hidden',
        },
    });

    return {
        animatedRef,
        animatedStyles,
    };
};
