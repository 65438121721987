import { RequestMutationOptions } from '@approvalmax/data';
import { useMutate } from 'shared/data';

import { authApiPaths } from '../paths';
import { VerifyTwoFaEnablingCode } from './useVerifyTwoFaEnablingCode.types';

export const useVerifyTwoFaEnablingCode = (mutationOptions?: RequestMutationOptions<VerifyTwoFaEnablingCode>) => {
    return useMutate(authApiPaths.verifyTwoFaEnablingCode, {
        mutationOptions,
        apiVersion: 'v1',
        mapToCamelCase: true,
    });
};
