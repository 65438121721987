import { Popup } from '@approvalmax/ui';
import { GlobalLoadingBar } from 'modules/page';
import { AlertNotificationIcon } from 'modules/sprites';
import { font } from 'modules/styles';
import { FC, memo } from 'react';
import styled from 'styled-components';

import { XeroDataCreationStatus } from '../../hooks/useXeroDemoDataCreator';
import Bullet from './components/Bullet/Bullet';
import { messages } from './ProcessingPopup.messages';

const StyledPopup = styled(Popup.EmptyContent)`
    width: 500px;
    height: 480px;
    padding: 60px 100px;
`;

const Title = styled.div`
    flex: 1;
    margin-bottom: 10px;
    ${font(18, '#333', 'semibold')}
`;

const Description = styled.div`
    margin-bottom: 15px;
`;

const Warning = styled.div`
    display: flex;
    margin: 15px 0 30px;
    padding: 10px 20px 10px 12px;
    border: solid 1px #ba3e21;
    background-color: #fff8f7;
    color: #000;
    align-items: center;
`;

const WarningText = styled.div`
    flex: 1;
    margin-left: 10px;
`;

interface ProcessingPopupProps {
    status: XeroDataCreationStatus;
}

const ProcessingPopup: FC<ProcessingPopupProps> = (props) => {
    const { status } = props;

    return (
        <StyledPopup>
            <GlobalLoadingBar
                isLoading={status !== XeroDataCreationStatus.Done && status !== XeroDataCreationStatus.Error}
            />

            <Title>{messages.title}</Title>

            <Description>{messages.description}</Description>

            <Warning>
                <AlertNotificationIcon width={23} height={19} />

                <WarningText>{messages.warning}</WarningText>
            </Warning>

            <Bullet checked text={messages.pullCatalogs} />

            <Bullet checked={status !== XeroDataCreationStatus.CreatingWorkflows} text={messages.creatingWorkflows} />

            <Bullet checked={status === XeroDataCreationStatus.Done} text={messages.creatingRequests} />
        </StyledPopup>
    );
};

export default memo(ProcessingPopup);
