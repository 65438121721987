import { domain } from 'modules/data';
import { useMemo } from 'react';
import { findDocumentFieldState } from 'shared/helpers';

export const useNetSuiteBillPaymentFieldSettings = (documentFields: domain.DocumentField[] | undefined) =>
    useMemo(
        () => ({
            account: findDocumentFieldState(documentFields, 'Account'),
            class: findDocumentFieldState(documentFields, 'Class'),
            currency: findDocumentFieldState(documentFields, 'Currency'),
            department: findDocumentFieldState(documentFields, 'Department'),
            location: findDocumentFieldState(documentFields, 'Location'),
            memo: findDocumentFieldState(documentFields, 'Memo'),
            exchangeRate: findDocumentFieldState(documentFields, 'ExchangeRate'),
            payee: findDocumentFieldState(documentFields, 'Payee'),
            payeeAddress: findDocumentFieldState(documentFields, 'PayeeAddress'),
            postingPeriod: findDocumentFieldState(documentFields, 'PostingPeriod'),
            printVoucher: findDocumentFieldState(documentFields, 'PrintVoucher'),
            toBePrinted: findDocumentFieldState(documentFields, 'ToBePrinted'),
            transactionDate: findDocumentFieldState(documentFields, 'Date'),
            transactionNumber: findDocumentFieldState(documentFields, 'TransactionNumber'),
        }),
        [documentFields]
    );
