import { routerHelpers } from '@approvalmax/utils';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

import { getQueryKey } from '../helpers/getQueryKey';
import { RequestParams } from '../types';

export const useInvalidateQuery = <T extends RequestParams | void = void>(path: string) => {
    const queryClient = useQueryClient();

    return useCallback(
        async (requestParams: T) => {
            const url = routerHelpers.testPathByParams(path, requestParams?.path)
                ? routerHelpers.pathToUrl(path, requestParams?.path)
                : '';

            await queryClient.invalidateQueries({
                queryKey: getQueryKey(url, requestParams?.query),
            });
        },
        [path, queryClient]
    );
};
