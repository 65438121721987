import './licensingNotification.scss';

import { selectors, useUserPreferences } from 'modules/common';
import { State } from 'modules/data';
import { useShallowEqualSelector } from 'modules/react-redux';
import { PAGE_ID as AUTH_PAGE_ID } from 'pages/auth/config';
import { memo } from 'react';
import bemFactory from 'react-bem-factory';
import { useSelector } from 'react-redux';

import {
    renderExpiredOrganisations,
    renderExpiredSubscriptions,
    renderExpiredTrialOfAdvancedOrganisations,
    renderExpiringTrialOrganisations,
    renderGraceSubscriptions,
    renderTrialOfAdvancedOrganisations,
} from './LicensingNotification.helpers';

const bem = bemFactory.block('app-licensing-notification');

const LicensingNotification = memo(() => {
    const {
        profileEmail,
        profileCreatedDate,
        expiringTrialCompanies,
        expiredTrialCompanies,
        expiredTrialOfAdvancedFeaturesCompanies,
        trialOfAdvancedFeaturesCompanies,
        graceSubscriptions,
        expiredSubscriptions,
        hasActivePaidSubscription,
    } = useShallowEqualSelector((state: State) => {
        const profile = selectors.profile.findProfile(state);

        return {
            profileEmail: profile ? profile.email : null,
            profileCreatedDate: profile?.createdDate,
            expiringTrialCompanies: selectors.company.getTrialCompaniesExpiringIn14Days(state),
            expiredTrialCompanies: selectors.company.getExpiredTrialCompanies(state),
            expiredTrialOfAdvancedFeaturesCompanies: selectors.company
                .getExpiredTrialOfAdvancedFeaturesCompanies(state)
                .filter(({ flags }) => flags.canManageAccount),
            trialOfAdvancedFeaturesCompanies: selectors.company.getTrialOfAdvancedFeaturesCompanies(state),
            graceSubscriptions: selectors.subscription.getGraceSubscriptions(state),
            expiredSubscriptions: selectors.subscription.getExpiredSubscriptions(state),
            hasActivePaidSubscription: selectors.subscription.hasActivePaidSubscription(state),
        };
    });

    const [isPinned] = useUserPreferences<boolean>('global-pin-drawer', false);

    const isSubscriptionOwner = (license: selectors.types.ExpandedSubscription) => {
        return profileEmail === license.accountOwnerId;
    };

    const isNotSubscriptionOwner = (license: selectors.types.ExpandedSubscription) => {
        return !isSubscriptionOwner(license);
    };

    const isCompanyOwner = (company: selectors.types.ExpandedCompany) => {
        return profileEmail === company.author;
    };

    const isNotCompanyOwner = (company: selectors.types.ExpandedCompany) => {
        return !isCompanyOwner(company);
    };

    const isAuthPage = useSelector((state: State) => {
        return selectors.navigation.getActivePageId(state) === AUTH_PAGE_ID;
    });

    if (
        !profileEmail ||
        isAuthPage ||
        (!expiredTrialCompanies.length &&
            !expiringTrialCompanies.length &&
            !graceSubscriptions.length &&
            !expiredSubscriptions.length &&
            !expiredTrialOfAdvancedFeaturesCompanies.length &&
            !trialOfAdvancedFeaturesCompanies.length)
    ) {
        return null;
    }

    return (
        <div
            className={bem(null, {
                'drawer-pinned': isPinned,
            })}
        >
            {renderExpiringTrialOrganisations(
                expiringTrialCompanies.filter(isCompanyOwner),
                true,
                hasActivePaidSubscription
            )}

            {renderExpiringTrialOrganisations(
                expiringTrialCompanies.filter(isNotCompanyOwner),
                false,
                hasActivePaidSubscription
            )}

            {renderExpiredOrganisations(
                expiredTrialCompanies.filter(isCompanyOwner),
                true,
                hasActivePaidSubscription,
                profileCreatedDate
            )}

            {renderExpiredTrialOfAdvancedOrganisations(expiredTrialOfAdvancedFeaturesCompanies)}

            {renderTrialOfAdvancedOrganisations(trialOfAdvancedFeaturesCompanies)}

            {renderExpiredOrganisations(
                expiredTrialCompanies.filter(isNotCompanyOwner),
                false,
                hasActivePaidSubscription,
                profileCreatedDate
            )}

            {renderExpiredSubscriptions(
                expiredSubscriptions.filter(isSubscriptionOwner),
                true,
                hasActivePaidSubscription
            )}

            {renderExpiredSubscriptions(
                expiredSubscriptions.filter(isNotSubscriptionOwner),
                false,
                hasActivePaidSubscription
            )}

            {renderGraceSubscriptions(graceSubscriptions.filter(isSubscriptionOwner), true)}

            {renderGraceSubscriptions(graceSubscriptions.filter(isNotSubscriptionOwner), false)}
        </div>
    );
});

export default LicensingNotification;
