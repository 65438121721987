import { RequestParams, RequestQueryOptions } from '@approvalmax/data';
import { useGet, useLazyGet } from 'shared/data';

import { netSuiteApiPaths } from '../paths';
import { GetNetSuiteLeadSources } from './useGetNetSuiteLeadSources.types';

export const useGetNetSuiteLeadSources = (
    params?: RequestParams<GetNetSuiteLeadSources>,
    queryOptions?: RequestQueryOptions<GetNetSuiteLeadSources>
) => {
    return useGet(netSuiteApiPaths.leadSources, {
        params,
        queryOptions,
        apiVersion: 'v2',
    });
};

export const useLazyGetNetSuiteLeadSources = (queryOptions?: RequestQueryOptions<GetNetSuiteLeadSources>) => {
    return useLazyGet<GetNetSuiteLeadSources>(netSuiteApiPaths.leadSources, {
        queryOptions,
        apiVersion: 'v2',
    });
};
