import { useCallback, useState } from 'react';
import { useGetFido2Credentials } from 'shared/data/webApp/v2';
import { useCreatePasskey } from 'shared/hooks';

import { generatePasskeyLabel } from '../../SectionSecurity.helpers';
import { PasskeyType } from '../PasskeyItem/PasskeyItem.types';

export const useSubsectionPasskeys = () => {
    const {
        data: credentials,
        refetch: refetchCredentials,
        isLoading: isLoadingCredentials,
    } = useGetFido2Credentials({});

    const [newPasskeyType, setNewPasskeyType] = useState<PasskeyType | null>(null);

    const { createPasskey, isCreatingPasskey } = useCreatePasskey();

    const handleShowPasskeyCreate = useCallback(() => {
        void createPasskey(generatePasskeyLabel());
    }, [createPasskey]);

    const handleCancelCreate = useCallback(() => {
        setNewPasskeyType(null);
    }, []);

    const hasCredentials = Boolean(credentials?.length);

    return {
        credentials,
        isCreatingPasskey,
        isLoadingCredentials,
        refetchCredentials,
        handleShowPasskeyCreate,
        handleCancelCreate,
        newPasskeyType,
        showSetup: !hasCredentials && !newPasskeyType,
    };
};
