import { atom } from 'jotai';

import { BulkCreationPopupContentState } from './BulkCreationPopupContent.types';

export const bulkCreationPopupOpenState = atom(false);

export const bulkCreationPopupContentState = atom<BulkCreationPopupContentState>({
    companyId: '',
    templateIntegrationCode: null,
});
