import { errorHelpers, intl, mathService } from '@approvalmax/utils';
import { domain } from 'modules/data';
import { useMemo } from 'react';

import { messages } from './ChangeMatchingPopupItem.messages';

export const useAmountTypeLabel = (
    amountType: domain.MatchingAmountType | undefined,
    totalAmount: number,
    currency: string
) => {
    const amount = intl.formatCurrency(totalAmount, currency);

    if (!amountType) return amount;

    switch (amountType) {
        case domain.MatchingAmountType.Gross:
            return messages.gross({ amount });

        case domain.MatchingAmountType.Net:
            return messages.net({ amount });

        default: {
            throw errorHelpers.assertNever(amountType);
        }
    }
};

export const useBillRemaining = (
    billTotalAmount: number,
    totalAllocatedAmount: number,
    totalAllocatedAmountToBilledPOs: number
) => {
    return useMemo(() => {
        return mathService.subtract(
            billTotalAmount,
            mathService.add(totalAllocatedAmountToBilledPOs, totalAllocatedAmount)
        );
    }, [billTotalAmount, totalAllocatedAmount, totalAllocatedAmountToBilledPOs]);
};
