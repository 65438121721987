import { RequestQueryOptions } from '@approvalmax/data';
import { useLazyGet } from 'shared/data';

import { integrationApiPaths } from '../paths';
import { GetNetSuiteEmployee } from './useLazyGetNetSuiteEmployee.types';

export const useLazyGetNetSuiteEmployee = (queryOptions?: RequestQueryOptions<GetNetSuiteEmployee<true>>) => {
    return useLazyGet<GetNetSuiteEmployee<true>>(integrationApiPaths.getNetSuiteEmployee, {
        queryOptions,
        apiVersion: 'v1',
        mapToCamelCase: true,
    });
};
