import { selectors } from 'modules/common';
import { State } from 'modules/data';

import { moduleDef } from '..';
import { ProfileModuleData } from '../reducers/moduleReducer';

function getModule(state: State) {
    return selectors.module.getModule<ProfileModuleData>(state, moduleDef.id);
}

export function getMyDelegatesPopupData(state: State) {
    return getModule(state).myDelegatesPopup;
}

export function getConsentToStartTrialPeriodPopupData(state: State) {
    return getModule(state).consentToStartTrialPeriodPopup;
}

export function getActivePopups(state: State) {
    const module = getModule(state);

    return {
        myDelegates: Boolean(module.myDelegatesPopup),
        consentToStartTrialPeriodPopup: Boolean(module.consentToStartTrialPeriodPopup),
        trialPeriodStartedPopup: Boolean(module.trialPeriodStartedPopup),
        contactSalesPopup: Boolean(module.contactSalesPopup),
        contactSupportPopup: Boolean(module.contactSupportPopup),
        twoFaSoftEnforcementPopup: Boolean(module.twoFaSoftEnforcementPopup),
        pendingPracticeInvitation: Boolean(module.pendingPracticeInvitationPopup),
    };
}
