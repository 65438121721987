import { useSetAtom } from 'jotai';
import { useCallback, useLayoutEffect } from 'react';

import { defaultLayoutState } from './Layout.constants';
import { layoutState } from './Layout.states';
import { LayoutState } from './Layout.types';

export const useLayout = (props?: LayoutState) => {
    const setLayoutState = useSetAtom(layoutState);

    useLayoutEffect(() => {
        setLayoutState(props ? { ...defaultLayoutState, ...props } : defaultLayoutState);
    }, [props, setLayoutState]);

    return useCallback(
        (newState: LayoutState) => {
            setLayoutState({ ...defaultLayoutState, ...newState });
        },
        [setLayoutState]
    );
};
