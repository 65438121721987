import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('modules.StatusBadge', {
    onReviewBadgeText: 'On Review',
    approvedBadgeText: 'Approved',
    paidBadgeText: 'Paid',
    cancelledBadgeText: 'Cancelled',
    rejectedBadgeText: 'Rejected',
    pendingPushTitle: 'Waiting to be synced to {integrationName}',
    acceptedBadgeText: 'Accepted',
    declinedBadgeText: 'Declined',
    awaitingPaymentBadgeText: 'Awaiting payment',
    processingBadgeText: 'Processing',
    partiallyPaidBadgeText: 'Partially paid',
    failedBadgeText: 'Failed',
    inProgressBadgeText: 'In progress',
    paidInFullBadgeText: 'Paid in Full',
});
