import { Button, Flex, Form, Text, TextField } from '@approvalmax/ui/src/components';
import { memo } from 'react';
import bemFactory from 'react-bem-factory';

import { AnotherMethodButton } from '../AnotherMethodButton/AnotherMethodButton';
import { StepperTwoFaWizard } from '../StepperTwoFaWizard/StepperTwoFaWizard';
import { useStepBackupEmail } from './StepBackupEmail.hooks';
import { messages } from './StepBackupEmail.messages';

const qa = bemFactory.qa('pro-two-fa-setup-email-enter');

export const StepBackupEmail = memo(() => {
    const { form, handleSubmit, isDisabled, isLoading } = useStepBackupEmail();

    return (
        <Form form={form} onSubmit={handleSubmit}>
            <Flex direction='column' spacing='24' data-qa={qa()}>
                <StepperTwoFaWizard activeStep={2} />

                <Flex direction='column' spacing='16'>
                    <Text font='headline' fontSize='xsmall' fontWeight='medium'>
                        {messages.title}
                    </Text>

                    <Text font='body' fontSize='medium' fontWeight='regular'>
                        {messages.description}
                    </Text>
                </Flex>

                <TextField
                    type='email'
                    name='email'
                    required
                    initFocus
                    label={messages.emailLabel}
                    placeholder={messages.emailPlaceholder}
                />

                <Flex direction='column' spacing='16'>
                    <Button type='submit' size='large' color='blue80' disabled={isDisabled} progress={isLoading}>
                        {messages.nextButton}
                    </Button>

                    <AnotherMethodButton />
                </Flex>
            </Flex>
        </Form>
    );
});

StepBackupEmail.displayName = 'StepEmailEnter';
