import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('pages.RequestList.XeroPendingStatusToolbarSection', {
    requestAMBlockedValidationErrorManager: `This request cannot be created in Xero upon final approval due to the following issue:
       {issues}.{br}Please update the date of the request or update the lock date in {xeroLink}`,
    requestBlockedValidationErrorManager: `This request cannot be approved in Xero due to the following
       issue: {issues}.{br}Please change the document date in {xeroLink} or update the lock date in {xeroLinkToEditFinancialSettings}.`,
    requestBlockedValidationErrorUser: `This request can not be approved in Xero due to the following
       issue: {issues}.{br}Organisation administrators have been notified about this.`,
    validationErrorTextLockDateInEffect: 'document date cannot be before the lock date, currently set at {lockDate}',
});
