import { Divider, Grid } from '@approvalmax/ui/src/components';
import moment from 'moment';
import { memo, useMemo } from 'react';
import { PaymentDetailsAudit, PaymentDetailsBankAccountInfo, PaymentDetailsStatus } from 'shared/components';
import { noValue } from 'shared/constants';
import { useGetBillRequestPaymentDetails, useGetXeroCounterpartyPaymentDetailsForRequest } from 'shared/data/webApp/v1';

import { PaymentDetailsInfoProps } from './PaymentDetailsInfo.types';

export const PaymentDetailsInfo = memo<PaymentDetailsInfoProps>((props) => {
    const {
        status,
        companyId,
        requestId,
        billRequestId,
        counterpartyId,
        paymentDetailsId,
        skipBillRequest,
        paymentDetailsDiscrepancy,
    } = props;

    const { data: billRequestPaymentDetails, isFetching: isFetchingGetBillRequestPaymentDetails } =
        useGetBillRequestPaymentDetails(
            {
                path: {
                    companyId,
                    requestId,
                    billRequestId,
                },
            },
            {
                enabled:
                    !paymentDetailsDiscrepancy?.paymentDetailsDiscrepancy.lastUsedPaymentDetailsProperties &&
                    // skip request until batch is created
                    !skipBillRequest,
            }
        );

    const {
        data: xeroCounterpartyPaymentDetailsForRequest,
        isFetching: isFetchingGetXeroCounterpartyPaymentDetailsForRequest,
    } = useGetXeroCounterpartyPaymentDetailsForRequest({
        path: {
            companyId,
            requestId,
            counterpartyId,
        },
    });

    const isFetching = isFetchingGetBillRequestPaymentDetails || isFetchingGetXeroCounterpartyPaymentDetailsForRequest;

    const paymentDetailsByCounterpartyId = useMemo(
        () => xeroCounterpartyPaymentDetailsForRequest?.items.find((item) => item.id === paymentDetailsId),
        [xeroCounterpartyPaymentDetailsForRequest, paymentDetailsId]
    );

    const bankAccountDetails =
        billRequestPaymentDetails?.data.bankAccountDetails || paymentDetailsByCounterpartyId?.bankAccount;

    const normalizedPaymentDetailsAudit = useMemo(() => {
        const createdBy =
            billRequestPaymentDetails?.data.createdBy || paymentDetailsByCounterpartyId?.createdBy || noValue;
        const createdAt =
            (billRequestPaymentDetails?.data.createdAt &&
                moment(billRequestPaymentDetails.data.createdAt).format('lll')) ||
            (paymentDetailsByCounterpartyId?.createdAt &&
                moment(paymentDetailsByCounterpartyId.createdAt).format('lll')) ||
            noValue;
        const modifiedBy = billRequestPaymentDetails?.data.modifiedBy || paymentDetailsByCounterpartyId?.modifiedBy;
        const modifiedAt =
            (billRequestPaymentDetails?.data.modifiedAt &&
                moment(billRequestPaymentDetails.data.modifiedAt).format('lll')) ||
            (paymentDetailsByCounterpartyId?.modifiedAt &&
                moment(paymentDetailsByCounterpartyId.modifiedAt).format('lll')) ||
            noValue;

        return {
            createdBy,
            createdAt,
            modifiedBy,
            modifiedAt,
        };
    }, [billRequestPaymentDetails, paymentDetailsByCounterpartyId]);

    return (
        <Grid gap={12} padding='16'>
            <PaymentDetailsStatus status={status} />

            <PaymentDetailsBankAccountInfo
                status={status}
                bankAccount={bankAccountDetails}
                lastUsedPaymentDetailsProperties={
                    paymentDetailsDiscrepancy?.paymentDetailsDiscrepancy.lastUsedPaymentDetailsProperties ||
                    billRequestPaymentDetails?.data.lastUsedPaymentDetailsProperties
                }
            />

            <Divider spacing='2' />

            {isFetching ? <PaymentDetailsAudit.Loading /> : <PaymentDetailsAudit {...normalizedPaymentDetailsAudit} />}
        </Grid>
    );
});

PaymentDetailsInfo.displayName = 'PaymentDetailsInfo';
