import './integrationErrorPopup.scss';

import { Link, Popup, TextButton } from '@approvalmax/ui';
import { selectors } from 'modules/common';
import { State } from 'modules/data';
import { ConnectedProps } from 'modules/react-redux';
import { BigWarnIcon } from 'modules/sprites';
import React from 'react';
import bemFactory from 'react-bem-factory';
import { connect } from 'react-redux';
import { getPath, Path } from 'urlBuilder';

import { cancelActivePopup } from '../actions';
import { IntegrationErrorPopupData } from '../reducers/module/activePopup/integrationErrorPopupReducer';
import { getActivePopup } from '../selectors/moduleSelectors';
import { messages } from './IntegrationErrorPopup.messages';

function mapStateToProps(state: State) {
    const companyId = getActivePopup<IntegrationErrorPopupData>(state).companyId;
    const company = selectors.company.getCompanyById(state, companyId);

    return {
        company,
    };
}

const mapDispatchToProps = { cancelActivePopup };

interface OwnProps {
    className?: string;
}

type Props = ConnectedProps<OwnProps, typeof mapStateToProps, typeof mapDispatchToProps>;

class IntegrationErrorPopup extends React.Component<Props> {
    public render() {
        const { className, company } = this.props;
        const bem = bemFactory.block('reqf-integration-error-popup');
        const qa = bemFactory.qa('reqf-integration-error-popup');

        const isManager = company.flags.isManager;
        const isConnected = company.flags.hasActiveIntegration;

        let title;
        let description;

        if (isConnected) {
            title = messages.connectedTitle({
                integrationName: company.integration?.displayName,
            });

            if (isManager) {
                description = messages.connectedManagerDescription({
                    supportLink: (chunks: any) => <a href='mailto:support@approvalmax.com'>{chunks}</a>,
                });
            } else {
                description = messages.connectedUserDescription;
            }
        } else {
            title = messages.disconnectedTitle({
                integrationName: company.integration?.displayName,
            });

            if (isManager) {
                description = messages.disconnectedManagerDescription({
                    integrationName: company.integration?.displayName,
                    link: (
                        <Link href={getPath(Path.companyWorkflows, company.id)} onClick={this.props.cancelActivePopup}>
                            {messages.reconnectLinkText({
                                integrationName: company.integration?.displayName,
                            })}
                        </Link>
                    ),
                });
            } else {
                description = messages.disconnectedUserDescription({
                    integrationName: company.integration?.displayName,
                });
            }
        }

        return (
            <Popup.EmptyContent className={bem.add(className)()} qa={qa()}>
                <BigWarnIcon className={bem('icon')} width={70} height={60} />

                <div className={bem('title')}>{title}</div>

                <div className={bem('description')}>{description}</div>

                <div className={bem('close-button-wrp')}>
                    <TextButton execute={this.props.cancelActivePopup} qa={qa('close-button')}>
                        {messages.closePopup}
                    </TextButton>
                </div>
            </Popup.EmptyContent>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(IntegrationErrorPopup);
