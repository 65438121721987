import { Box, Checkbox, DatePicker, Radio, Spacing, Text } from '@approvalmax/ui/src/components';
import { dateTimeHelpers } from '@approvalmax/utils';
import { selectors } from 'modules/common';
import { domain } from 'modules/data';
import { FC, memo, useCallback } from 'react';

import { SettingsSection } from '../SettingsSection/SettingsSection';
import SettingsSubsection from '../SettingsSubsection/SettingsSubsection';
import { messages } from './SectionDearFraudBypassing.messages';
import { FraudBypassingOption, SectionDearFraudBypassingProps } from './SectionDearFraudBypassing.types';

const SectionDearFraudBypassing: FC<SectionDearFraudBypassingProps> = memo((props) => {
    const {
        template,
        templateSettings,
        savedTemplateSettings,
        effectiveDateLimits,
        checkValidFraudBypassing,
        setTemplateSettings,
        readonly,
        links,
        qa,
    } = props;

    const settings = templateSettings?.dearApprovalDisregardDetectionSettings;

    const handleEnabledChange = useCallback(
        (enabled: boolean) => {
            setTemplateSettings((prevTemplateSettings) => ({
                ...prevTemplateSettings,
                dearApprovalDisregardDetectionSettings: prevTemplateSettings.dearApprovalDisregardDetectionSettings
                    ? {
                          ...prevTemplateSettings.dearApprovalDisregardDetectionSettings,
                          enabled,
                      }
                    : undefined,
            }));
        },
        [setTemplateSettings]
    );

    const handleEffectiveDateChange = useCallback(
        (newValue: Date | undefined) => {
            setTemplateSettings((prevTemplateSettings) => ({
                ...prevTemplateSettings,
                dearApprovalDisregardDetectionSettings: prevTemplateSettings.dearApprovalDisregardDetectionSettings
                    ? {
                          ...prevTemplateSettings.dearApprovalDisregardDetectionSettings,
                          effectiveDate:
                              dateTimeHelpers
                                  .getMaxDate(
                                      dateTimeHelpers.getMinDate(newValue, effectiveDateLimits?.minDate),
                                      effectiveDateLimits?.maxDate
                                  )
                                  ?.toISOString() ?? null,
                      }
                    : undefined,
            }));
        },
        [effectiveDateLimits?.maxDate, effectiveDateLimits?.minDate, setTemplateSettings]
    );

    const handleNotifyAdminsChange = useCallback(
        (notifyAdmins: boolean) => {
            setTemplateSettings((prevTemplateSettings) => ({
                ...prevTemplateSettings,
                dearApprovalDisregardDetectionSettings: prevTemplateSettings.dearApprovalDisregardDetectionSettings
                    ? { ...prevTemplateSettings.dearApprovalDisregardDetectionSettings, notifyAdmins }
                    : undefined,
            }));
        },
        [setTemplateSettings]
    );

    const handleChangeApprovalDisregardEnabled = useCallback(
        (checkedValue?: FraudBypassingOption) => {
            switch (checkedValue) {
                case FraudBypassingOption.Pull:
                    handleEnabledChange(true);
                    break;

                case FraudBypassingOption.DoNotPull:
                    handleEnabledChange(false);
                    break;
            }
        },
        [handleEnabledChange]
    );

    const disabled = !template.externalSubmitter;
    const dateTimeDisabled =
        disabled ||
        !settings?.enabled ||
        Boolean(savedTemplateSettings.dearApprovalDisregardDetectionSettings?.effectiveDate);

    if (!settings) {
        return null;
    }

    return (
        <SettingsSection title={messages.title} learnMoreLink={links[selectors.types.Link.FraudDetectionBypassing]}>
            <SettingsSubsection subtitle={messages.description} warning={disabled ? messages.warning : undefined}>
                <Radio.Group
                    disabled={disabled || readonly}
                    value={settings.enabled ? FraudBypassingOption.Pull : FraudBypassingOption.DoNotPull}
                    onChange={handleChangeApprovalDisregardEnabled}
                    name='changeApprovalDisregardEnabled'
                    block
                    data-qa={qa('DearFraudDetectionBypassing-enabled')}
                >
                    <Radio value={FraudBypassingOption.DoNotPull}>{messages.doNotPullDocumentLabel}</Radio>

                    <Radio value={FraudBypassingOption.Pull}>{messages.pullDocumentLabel}</Radio>
                </Radio.Group>

                <Box spacing='0 0 0 28'>
                    <Text font='body' color='midnight70' fontSize='medium'>
                        {messages.pullDocumentDescription({
                            templateName: template.displayName,
                        })}
                    </Text>

                    <Spacing height={12} />

                    <Box width={150}>
                        <DatePicker
                            value={settings.effectiveDate ? new Date(settings.effectiveDate) : undefined}
                            invalid={!checkValidFraudBypassing(domain.IntegrationType.Dear)}
                            minDate={effectiveDateLimits?.minDate ? new Date(effectiveDateLimits.minDate) : undefined}
                            maxDate={effectiveDateLimits?.maxDate ? new Date(effectiveDateLimits.maxDate) : undefined}
                            onChange={handleEffectiveDateChange}
                            disabled={dateTimeDisabled || readonly}
                            placeholder={messages.datePlaceholder}
                            data-qa={qa('DearFraudDetectionBypassing-effective-date')}
                        />
                    </Box>
                </Box>

                <Checkbox
                    disabled={!settings.enabled || disabled || readonly}
                    checked={Boolean(settings.notifyAdmins)}
                    onChange={handleNotifyAdminsChange}
                    data-qa={qa('FraudDetectionBypassing-notify-admins')}
                >
                    {messages.notifyCheckboxText}
                </Checkbox>
            </SettingsSubsection>
        </SettingsSection>
    );
});

SectionDearFraudBypassing.displayName = 'SectionDearFraudBypassing';

export default SectionDearFraudBypassing;
