import { domain } from 'modules/data';

import { GetMatrixDefinitionOptions, MatrixType } from '../types/matrix';
import { messages } from './matrixDefinitions.dear.messages';
import {
    getFilteredDefinitions,
    InternalMatrixDefinitionMap,
    MatrixDefinition,
    renderAmountCell,
    renderDefaultHeader,
    renderExactAsyncCell,
} from './matrixDefinitions.shared';

const PurchaseOrderFields: MatrixDefinition['columns'] = [
    {
        systemPurpose: domain.FieldSystemPurpose.Amount,
        renderHeader: (options) =>
            renderDefaultHeader(options, {
                fieldName: messages.approvalMatrixAmountHeaderText,
            }),
        renderCell: renderAmountCell,
    },
    {
        systemPurpose: domain.FieldSystemPurpose.DearSupplier,
        renderHeader: renderDefaultHeader,
        renderCell: renderExactAsyncCell,
    },
    {
        systemPurpose: domain.FieldSystemPurpose.DearAccount,
        renderHeader: renderDefaultHeader,
        renderCell: renderExactAsyncCell,
    },
    {
        systemPurpose: domain.FieldSystemPurpose.DearProduct,
        renderHeader: renderDefaultHeader,
        renderCell: renderExactAsyncCell,
    },
    {
        systemPurpose: domain.FieldSystemPurpose.DearProductCategory,
        renderHeader: renderDefaultHeader,
        renderCell: renderExactAsyncCell,
    },
    {
        systemPurpose: domain.FieldSystemPurpose.DearProductFamily,
        renderHeader: renderDefaultHeader,
        renderCell: renderExactAsyncCell,
    },
    {
        systemPurpose: domain.FieldSystemPurpose.DearBrand,
        renderHeader: renderDefaultHeader,
        renderCell: renderExactAsyncCell,
    },
    {
        systemPurpose: domain.FieldSystemPurpose.DearLocation,
        renderHeader: renderDefaultHeader,
        renderCell: renderExactAsyncCell,
    },
];

export function getDearMatrixDefinitions(options: GetMatrixDefinitionOptions) {
    const matrixDefinitions: InternalMatrixDefinitionMap = {
        [domain.IntegrationCode.DearPo]: {
            [MatrixType.Approval]: { columns: PurchaseOrderFields },
            [MatrixType.AutoApproval]: { columns: PurchaseOrderFields },
        },
    };

    return getFilteredDefinitions(matrixDefinitions, options);
}
