import { RequestQueryOptions } from '@approvalmax/data';
import { useGet } from 'shared/data';

import { usersApiPaths } from '../paths';
import { GetIsWorkflowPayer } from './useGetIsWorkflowPayer.types';

export const useGetIsWorkflowPayer = (queryOptions?: RequestQueryOptions<GetIsWorkflowPayer>) => {
    return useGet(usersApiPaths.isWorkflowPayer, {
        queryOptions,
        apiVersion: 'v2',
        method: 'get',
    });
};
