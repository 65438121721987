import { ActionMenu, QBooksLogo, TransparentButton } from '@approvalmax/ui';
import { errorHelpers } from '@approvalmax/utils';
import { selectors } from 'modules/common';
import { domain } from 'modules/data';
import { Cin7Logo, DropdownTriangleIcon, OracleNetsuiteLogo, XeroIcon } from 'modules/sprites';
import { font, uTransparentLink } from 'modules/styles';
import { FC, memo } from 'react';
import styled from 'styled-components/macro';

import { messages } from './OpenInSourceLink.messages';

const Root = styled.div`
    display: flex;
    align-items: center;
`;

const Link = styled.a`
    display: flex;
    align-items: center;
    ${uTransparentLink}
`;

const LinkText = styled.div`
    margin-left: 10px;
    ${font(13, '#000')}
`;

const UrlMenuButton = styled(TransparentButton)`
    padding: 6px;
    transform: rotate(180deg);
    fill: $color-forest-green;
    line-height: 0;
`;

const canEditRequestInXero = (
    integrationCode: domain.IntegrationCode | null,
    statusV2: domain.RequestStatusV2 | undefined
) => {
    const requestIsApproved = statusV2 === domain.RequestStatusV2.Approved;

    switch (integrationCode) {
        case domain.IntegrationCode.XeroBill:
        case domain.IntegrationCode.XeroCreditNotesPayable:
        case domain.IntegrationCode.XeroCreditNotesReceivable:
        case domain.IntegrationCode.XeroInvoice:
            return !requestIsApproved;

        default:
            return true;
    }
};

interface OpenInSourceLinkProps {
    url: string | null;
    editUrl?: string | null;
    integrationType: domain.IntegrationType;
    integrationCode: domain.IntegrationCode | null;
    statusV2?: domain.RequestStatusV2;
}

const OpenInSourceLink: FC<OpenInSourceLinkProps> = (props) => {
    const { url, editUrl, integrationCode, integrationType, statusV2 } = props;
    const integrationName = selectors.integration.getIntegrationTypeName(integrationType);

    let IntegrationIcon;
    let canEdit = !!editUrl;

    switch (integrationType) {
        case domain.IntegrationType.Xero:
            IntegrationIcon = XeroIcon;
            canEdit = canEdit && canEditRequestInXero(integrationCode, statusV2);
            break;

        case domain.IntegrationType.QBooks:
            IntegrationIcon = QBooksLogo;
            canEdit = false;
            break;

        case domain.IntegrationType.NetSuite:
            IntegrationIcon = OracleNetsuiteLogo;
            break;

        case domain.IntegrationType.Dear:
            IntegrationIcon = Cin7Logo;
            break;

        case domain.IntegrationType.None:
            errorHelpers.notSupportedError();

            return null;

        default: {
            throw errorHelpers.assertNever(integrationType);
        }
    }

    const openRequestForEditing = () => {
        if (editUrl) {
            const newWindow = window.open(editUrl, '_blank');

            if (newWindow) {
                newWindow.focus();
            }
        }
    };

    if (!url) {
        return null;
    }

    return (
        <Root>
            <Link target='_blank' rel='noopener noreferrer' href={url}>
                <IntegrationIcon width={30} height={30} />

                <LinkText>{messages.openInSourceLinkText({ integrationName })}</LinkText>
            </Link>

            {canEdit && (
                <ActionMenu
                    panelFlow='to-left'
                    button={
                        <UrlMenuButton>
                            <DropdownTriangleIcon width={10} height={6} />
                        </UrlMenuButton>
                    }
                >
                    <ActionMenu.Item execute={openRequestForEditing}>{messages.editInSourceLinkText}</ActionMenu.Item>
                </ActionMenu>
            )}
        </Root>
    );
};

export default memo(OpenInSourceLink);
