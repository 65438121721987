import { Text } from '@approvalmax/ui/src/components';
import { selectors } from 'modules/common';
import { domain } from 'modules/data';
import { useSelector } from 'modules/react-redux';
import { memo, useCallback, useState } from 'react';
import { isOcrEnabledForTemplate } from 'shared/helpers';
import { useCompanyBetaFeatures } from 'shared/hooks';

import { getActiveTemplate } from '../../selectors/pageSelectors';
import CollapseAllButton from '../CollapseAllButton/CollapseAllButton';
import FixedStepContainer from '../FixedStepContainer/FixedStepContainer';
import { ReceiveOverEmailSection } from '../ReceiveOverEmailSection/ReceiveOverEmailSection';
import { StepSubmitterSection } from '../StepSubmitterSection/StepSubmitterSection';
import XeroExternalSubmitterSection from '../XeroExternalSubmitterSection/XeroExternalSubmitterSection';
import { mapTemplateToLearnMoreLink } from './XeroCreditNoteStep.constants';
import { messages } from './XeroCreditNoteStep.messages';

const XeroCreditNoteStep = memo(() => {
    const [collapsedPullingSection, setCollapsedPullingSection] = useState<boolean>();
    const [collapsedSubmitterSection, setCollapsedSubmitterSection] = useState<boolean>();
    const [collapsedReceiveOverEmailSection, setCollapsedReceiveOverEmailSection] = useState<boolean>();

    const template = useSelector(getActiveTemplate)!;
    const pluralTemplateName = selectors.template.getTemplateDisplayNameByCode(template.integrationCode, true);
    const company = useSelector((state) => selectors.company.getCompanyById(state, template.companyId));

    const onCollapse = useCallback((collapsed: boolean) => {
        setCollapsedSubmitterSection(collapsed);
        setCollapsedPullingSection(collapsed);
        setCollapsedReceiveOverEmailSection(collapsed);
    }, []);

    const { isXeroCreditNoteAPcreation, isXeroCreditNoteARcreation } = useCompanyBetaFeatures(template.companyId);
    const learnMoreLink = template.integrationCode ? mapTemplateToLearnMoreLink[template.integrationCode] : undefined;

    const showStepSubmitter =
        (template.integrationCode === domain.IntegrationCode.XeroCreditNotesPayable && isXeroCreditNoteAPcreation) ||
        (template.integrationCode === domain.IntegrationCode.XeroCreditNotesReceivable && isXeroCreditNoteARcreation);

    const receiveOverEmailSection =
        template.integrationCode === domain.IntegrationCode.XeroCreditNotesPayable &&
        isOcrEnabledForTemplate(company, domain.IntegrationCode.XeroCreditNotesPayable);

    return (
        <FixedStepContainer bubbleText={messages.bubbleText({ templateName: template.displayName })}>
            <Text font='body' fontSize='small' color='midnight70'>
                {messages.title({ pluralTemplateName })}
            </Text>

            <CollapseAllButton
                collapsed={Boolean(collapsedSubmitterSection && collapsedPullingSection)}
                onCollapse={onCollapse}
            />

            {showStepSubmitter && (
                <StepSubmitterSection
                    cacheId='XeroCreditNoteStartStepSubmitter'
                    headerText={messages.createInApprovalMax}
                    description={messages.description({ pluralTemplateName })}
                    descriptionEmpty={messages.descriptionEmpty({ pluralTemplateName })}
                    collapsed={collapsedSubmitterSection}
                    onCollapse={setCollapsedSubmitterSection}
                    required
                />
            )}

            <XeroExternalSubmitterSection
                cacheId='XeroCreditNoteStepSubmitter'
                description={messages.externalSubmitterDescriptionNonEmpty({ pluralTemplateName })}
                descriptionEmpty={messages.externalSubmitterDescriptionEmpty({ templateName: template.displayName })}
                title={messages.externalSubmitterTitle({ pluralTemplateName })}
                learnMoreLink={learnMoreLink}
                collapsed={collapsedPullingSection}
                onCollapse={setCollapsedPullingSection}
            />

            {receiveOverEmailSection && (
                <ReceiveOverEmailSection
                    collapsed={collapsedReceiveOverEmailSection}
                    onCollapse={setCollapsedReceiveOverEmailSection}
                    cacheId='XeroCreditNoteStartStepReceiveOverEmail'
                    description={messages.emailExternaSubmitterDescriptionNonEmpty({ pluralTemplateName })}
                    descriptionEmpty={messages.emailExternalSubmitterDescriptionEmpty({
                        templateName: template.displayName,
                    })}
                    title={messages.emailExternalSubmitterTitle}
                />
            )}
        </FixedStepContainer>
    );
});

export default XeroCreditNoteStep;
