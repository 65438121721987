import { domain } from 'modules/data';
import { createPageReducer } from 'modules/react-redux';

import { LOAD_PAGE_DATA } from '../actions';
import activeCardCommand, { ActiveCardCommandType } from './page/activeCardCommandReducer';
import activeModule, { ActiveModuleType } from './page/activeModuleReducer';
import activePopup, { ActivePopupType } from './page/activePopupReducer';
import activeReportConfig, { ActiveReportConfigType } from './page/activeReportConfigReducer';
import activeReport, { ActiveReportType } from './page/activeReportReducer';
import documentPreview, { DocumentPreviewType } from './page/documentPreviewReducer';
import reportType from './page/reportTypeReducer';

export interface Page {
    activeCardCommand: ActiveCardCommandType;
    activeModule: ActiveModuleType;
    activePopup: ActivePopupType;
    activeReportConfig: ActiveReportConfigType;
    activeReport: ActiveReportType;
    reportType: domain.ReportType;
    documentPreview: DocumentPreviewType;
}

export default createPageReducer(
    {
        activeCardCommand,
        activeModule,
        activePopup,
        activeReportConfig,
        activeReport,
        documentPreview,
        reportType,
    },
    LOAD_PAGE_DATA
);
