import { atomWithStorage } from 'jotai/utils';
import { SyncStorage } from 'jotai/vanilla/utils/atomWithStorage';

export const createAtomWithStorage = <T>(
    key: string,
    initialValue: T,
    storage?: SyncStorage<T>,
    options?: {
        getOnInit?: boolean;
    }
) => {
    return atomWithStorage<T>(key, initialValue, storage, {
        getOnInit: true,
        ...options,
    });
};
