import { LoadingBar, Popup, ToolbarButton } from '@approvalmax/ui';
import { mathService } from '@approvalmax/utils';
import { actions as actionsCommon, selectors } from 'modules/common';
import { domain } from 'modules/data';
import { GlobalLoadingBar } from 'modules/page';
import { useDispatch, useSelector } from 'modules/react-redux';
import { TbHelpColorlessIcon } from 'modules/sprites';
import { XERO_MATCHING_V2_HELP_ITEM_ID } from 'pages/requestList/constants';
import { getActiveRequest } from 'pages/requestList/selectors/pageSelectors';
import React, { FC, memo, useCallback, useMemo } from 'react';
import { notificationService } from 'services/notification';
import styled from 'styled-components';

import { useChangeMatchingPopupContext } from '../../ChangeMatchingPopupContext';
import ChangeMatchingPopupBody from '../ChangeMatchingPopupBody/ChangeMatchingPopupBody';
import ChangeMatchingPopupFilter from '../ChangeMatchingPopupFilter/ChangeMatchingPopupFilter';
import ChangeMatchingPopupHeader from '../ChangeMatchingPopupHeader/ChangeMatchingPopupHeader';
import MatchingPopupLinks from '../MatchingPopupLinks/MatchingPopupLinks';
import { messages } from './ChangeMatchingPopupContent.messages';

const StyledDefaultContent = styled(Popup.DefaultContent)`
    min-width: 700px;
    min-height: 400px;
    width: 800px;
    height: 95%;
`;

const PopupContent = styled.div`
    display: flex;
    flex-direction: column;
    height: 90%;
    overflow: hidden;
`;

interface ChangeMatchingPopupContentProps {
    isFetching: boolean;
    appliedPOFilter: domain.XeroMatchingV2POFilter;
    setAppliedPOFilter: React.Dispatch<React.SetStateAction<domain.XeroMatchingV2POFilter>>;
}

const ChangeMatchingPopupContent: FC<ChangeMatchingPopupContentProps> = (props) => {
    const { isFetching, appliedPOFilter, setAppliedPOFilter } = props;

    const dispatch = useDispatch();

    const billMatchingSettings = useSelector(getActiveRequest).company.billMatchingSettings;
    const hiddenHelpItems = useSelector(selectors.userPreferences.getHiddenHelpItems);

    const {
        matchingData: { totalAllocatedAmountToBilledPOs, totalAmount, currency, purchaseOrders },
        totalAllocatedAmount,
        save,
        isSaving,
        companyId,
    } = useChangeMatchingPopupContext();

    const isOverflowed = mathService.add(totalAllocatedAmount, totalAllocatedAmountToBilledPOs) > totalAmount;

    const showHelpButton = useMemo(() => hiddenHelpItems.includes(XERO_MATCHING_V2_HELP_ITEM_ID), [hiddenHelpItems]);

    const handleSaveMatching = () => {
        if (isOverflowed) {
            notificationService.showErrorToast(messages.excessAmountWarning);
        } else {
            save();
        }
    };

    const onHelpButtonClick = useCallback(() => {
        dispatch(actionsCommon.updateHiddenHelpItem(XERO_MATCHING_V2_HELP_ITEM_ID, false));
    }, [dispatch]);

    return (
        <>
            <GlobalLoadingBar isLoading={isSaving} />

            <StyledDefaultContent
                title={messages.popupTitle}
                buttons={
                    <>
                        {showHelpButton && (
                            <ToolbarButton
                                title='Show the context help'
                                execute={onHelpButtonClick}
                                theme='dark'
                                size='small'
                            >
                                <TbHelpColorlessIcon width={20} height={20} />
                            </ToolbarButton>
                        )}

                        <Popup.DefaultContent.Button disabled={isSaving} execute={handleSaveMatching}>
                            {messages.saveButtonText}
                        </Popup.DefaultContent.Button>
                    </>
                }
            >
                <PopupContent>
                    <MatchingPopupLinks />

                    <ChangeMatchingPopupHeader
                        totalAmount={totalAmount}
                        totalAllocatedAmountToBilledPOs={totalAllocatedAmountToBilledPOs}
                        totalAllocatedAmount={totalAllocatedAmount}
                        currency={currency}
                        isOverflowed={isOverflowed}
                        amountType={billMatchingSettings?.amountType}
                    />

                    <ChangeMatchingPopupFilter
                        appliedPOFilter={appliedPOFilter}
                        setAppliedPOFilter={setAppliedPOFilter}
                    />

                    {isFetching ? (
                        <LoadingBar />
                    ) : (
                        <ChangeMatchingPopupBody purchaseOrders={purchaseOrders} companyId={companyId} />
                    )}
                </PopupContent>
            </StyledDefaultContent>
        </>
    );
};

export default memo(ChangeMatchingPopupContent);
