import { atomWithReset } from 'jotai/utils';
import { domain } from 'modules/data';

export type NetSuiteBillPaymentFields = Omit<
    domain.NetSuiteBillPaymentDetails,
    'applyList' | 'creditList' | 'payee'
> & {
    payee: domain.NetSuitePayee | null;
};

export const netSuiteBillPaymentFieldValuesState = atomWithReset<Partial<NetSuiteBillPaymentFields>>({});
