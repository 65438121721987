import { Box, Dropdown, Flex, Label, Progress, Text } from '@approvalmax/ui/src/components';
import { selectors } from 'modules/common';
import { FC, memo, useEffect, useState } from 'react';

import { UserAvatar } from '../UserAvatar/UserAvatar';
import { useOpen } from './UserCard.hooks';
import { messages } from './UserCard.messages';
import { UserCardProps } from './UserCard.types';

export const UserCard: FC<UserCardProps> = memo((props) => {
    const { children, open, onOpen, loading = false, getUser } = props;

    const { isOpen, setOpen } = useOpen({ open, onOpen });
    const [user, setUser] = useState<selectors.types.ExpandedUser | null>(null);

    useEffect(() => {
        if (isOpen) {
            setUser(getUser());
        }
    }, [getUser, isOpen, setUser]);

    const profileInfo = user?.profileInfo;

    return (
        <Dropdown open={isOpen} onOpen={setOpen} hasArrow={false} width={350} display='flex' activator={children}>
            <Box spacing='16' className='fs-mask'>
                {loading ? (
                    <Flex justifyContent='center' alignItems='center' height={112}>
                        <Progress shape='circle' />
                    </Flex>
                ) : (
                    user && (
                        <Flex direction='column'>
                            <Flex alignItems='center' spacing='8' wrap={false}>
                                <UserAvatar size={40} user={user} />

                                <Text color='black100' fontWeight='regular' font='body'>
                                    {user.displayName}
                                </Text>
                            </Flex>

                            <Flex alignItems='center' spacing='8' wrap={false}>
                                <Label spacing='0'>{messages.emailLabelText}</Label>

                                <Text color='black100' fontWeight='regular' font='body'>
                                    <a
                                        href={`mailto:${user.userEmail}`}
                                        target='_blank'
                                        rel='noopener noreferrer'
                                        title={user.userEmail}
                                    >
                                        {user.userEmail}
                                    </a>
                                </Text>
                            </Flex>

                            {profileInfo?.skype && (
                                <Flex alignItems='center' spacing='8' wrap={false}>
                                    <Label spacing='0'>{messages.skypeLabelText}</Label>

                                    <Text color='black100' fontWeight='regular' font='body'>
                                        <a
                                            href={`skype:${profileInfo.skype}?chat`}
                                            target='_blank'
                                            rel='noopener noreferrer'
                                            title={profileInfo.skype}
                                        >
                                            {profileInfo.skype}
                                        </a>
                                    </Text>
                                </Flex>
                            )}

                            {profileInfo?.phone && (
                                <Flex alignItems='center' spacing='8' wrap={false}>
                                    <Label spacing='0'>{messages.phoneLabelText}</Label>

                                    <Text color='black100' fontWeight='regular' font='body'>
                                        <a
                                            href={`tel:${profileInfo.phone}`}
                                            target='_blank'
                                            rel='noopener noreferrer'
                                            title={profileInfo.phone}
                                        >
                                            {profileInfo.phone}
                                        </a>
                                    </Text>
                                </Flex>
                            )}
                        </Flex>
                    )
                )}
            </Box>
        </Dropdown>
    );
});

UserCard.displayName = 'UserCard';
