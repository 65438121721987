import { DataIcon } from '@approvalmax/ui';
import { Menu } from '@approvalmax/ui/src/components';
import { FC, memo, useMemo } from 'react';

import { ExactAsyncConditionSelectMenuItemProps } from './ExactAsyncConditionSelectMenuItem.types';

export const ExactAsyncConditionSelectMenuItem: FC<ExactAsyncConditionSelectMenuItemProps> = memo((props) => {
    const { item } = props;

    const menuItems = useMemo(() => {
        if (!item.groupValues?.length) return [];

        return item.groupValues.map(({ id, text }) => ({ id, name: text }));
    }, [item.groupValues]);

    return (
        <Menu.Item
            {...item}
            tooltip={item.group}
            title={menuItems.length ? <Menu size='xsmall' divider items={menuItems} /> : undefined}
            tooltipProps={{
                width: 'fit-content',
                display: 'list-item',
                placement: 'left',
                safePolygon: true,
            }}
            endIcon={item.group ? <DataIcon size={16} /> : undefined}
        />
    );
});

ExactAsyncConditionSelectMenuItem.displayName = 'ExactAsyncConditionSelectMenuItem';
