import { RequestMutationOptions } from '@approvalmax/data';
import { useMutate } from 'shared/data/api';

import { paymentServicesApiPaths } from '../paths';
import { useInvalidateGetAmaxPayOpenBankingBankAccounts } from '../useGetAmaxPayOpenBankingBankAccounts/useGetAmaxPayOpenBankingBankAccounts';
import type { DeleteAmaxPayOpenBankingBankAccounts } from './useDeleteAmaxPayOpenBankingBankAccounts.types';

export const useDeleteAmaxPayOpenBankingBankAccounts = (
    mutationOptions?: RequestMutationOptions<DeleteAmaxPayOpenBankingBankAccounts>
) => {
    const invalidateGetAmaxPayOpenBankingBankAccounts = useInvalidateGetAmaxPayOpenBankingBankAccounts();

    return useMutate(paymentServicesApiPaths.crudAmaxPayOpenBankingBankAccounts, {
        mutationOptions: {
            ...mutationOptions,
            onSuccess: (response, requestData, ...args) => {
                mutationOptions?.onSuccess?.(response, requestData, ...args);

                invalidateGetAmaxPayOpenBankingBankAccounts({
                    path: { companyId: requestData.params?.path?.companyId },
                });
            },
        },
        method: 'delete',
        apiVersion: 'v1',
    });
};
