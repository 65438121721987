import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('modules.common.WeekPickerEditor', {
    dayOfWeekMonday: 'Mo',
    dayOfWeekTuesday: 'Tu',
    dayOfWeekWednesday: 'We',
    dayOfWeekThursday: 'Th',
    dayOfWeekFriday: 'Fr',
    dayOfWeekSaturday: 'Sa',
    dayOfWeekSunday: 'Su',
});
