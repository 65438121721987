import { RequestMutationOptions } from '@approvalmax/data';
import { useMutate } from 'shared/data';

import { requestsApiPaths } from '../paths';
import { CheckAirwallexBeneficiaries } from './useCheckAirwallexBeneficiaries.types';

export const useCheckAirwallexBeneficiaries = (
    mutationOptions?: RequestMutationOptions<CheckAirwallexBeneficiaries>
) => {
    return useMutate(requestsApiPaths.checkAirwallexBeneficiaries, {
        mutationOptions,
        apiVersion: 'v1',
        method: 'post',
        mapToCamelCase: true,
    });
};
