import { useAtomValue } from 'jotai';
import { FC, memo } from 'react';

import { layoutState } from './Layout.states';
import { Main, Root } from './Layout.styles';
import { LayoutProps } from './Layout.types';

/**
 * Base layout with header and drawer
 */
const Layout: FC<LayoutProps> = memo((props) => {
    const { children, drawer, header, ...restProps } = props;

    const { hasHeader, hasDrawer, containerMinSize } = useAtomValue(layoutState);

    return (
        <Root $containerMinSize={containerMinSize} {...restProps}>
            {hasDrawer && drawer}

            <Main>
                {hasHeader && header}

                {children}
            </Main>
        </Root>
    );
});

export default Layout;
