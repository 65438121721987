import { Radio } from '@approvalmax/ui/src/components';
import { selectors } from 'modules/common';
import { memo, useCallback } from 'react';

import { SettingsSection } from '../SettingsSection/SettingsSection';
import SettingsSubsection from '../SettingsSubsection/SettingsSubsection';
import { messages } from './SectionPriceChecker.messages';
import { PriceCheckerOption, SectionPriceCheckerProps } from './SectionPriceChecker.types';

const SectionPriceChecker = memo<SectionPriceCheckerProps>((props) => {
    const { templateSettings, readonly, setTemplateSettings, qa, links } = props;

    const handleChange = useCallback(
        (value: PriceCheckerOption) => {
            setTemplateSettings((prevState) => ({
                ...prevState,
                isPriceCheckerEnabled: value === PriceCheckerOption.yes,
            }));
        },
        [setTemplateSettings]
    );

    return (
        <SettingsSection title={messages.title} learnMoreLink={links[selectors.types.Link.PriceChecker]}>
            <SettingsSubsection subtitle={messages.subtitle}>
                <Radio.Group
                    disabled={readonly}
                    value={templateSettings?.isPriceCheckerEnabled ? PriceCheckerOption.yes : PriceCheckerOption.no}
                    onChange={handleChange}
                    name='priceChecker'
                    block
                    data-qa={qa('price-checker')}
                >
                    <Radio value={PriceCheckerOption.yes}>{messages.optionTrue}</Radio>

                    <Radio value={PriceCheckerOption.no}>{messages.optionFalse}</Radio>
                </Radio.Group>
            </SettingsSubsection>
        </SettingsSection>
    );
});

export default SectionPriceChecker;
