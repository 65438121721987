import { domain } from 'modules/data';
import moment from 'moment';

import { ExpandedCompany, ExpandedRequest } from './types';

const getLockDateInEffect = (request: domain.Request, details: domain.DearRequestDetails) => {
    if (!details.lockDate) {
        return false;
    }

    const date = moment.utc(details.transactionDate);
    const lockDate = moment.utc(details.lockDate);
    const newDate = moment(lockDate).add(1, 'd');

    return date.isBefore(newDate);
};

export const getDearCommands = (params: {
    request: domain.DearRequest;
    company: ExpandedCompany;
    myDecisions: ExpandedRequest['myDecisions'];
    flags: ExpandedRequest['flags'];
}): ExpandedRequest['commands'] => {
    const { request, company, myDecisions, flags } = params;

    const { isActiveApprover, isApprover, status } = flags;
    const details = request.details;
    const isManager = company.flags.isManager;
    const isOnHold = request.statusV2 === domain.RequestStatusV2.OnHold;
    const canForceDecision =
        isManager &&
        (request.statusV2 === domain.RequestStatusV2.OnApproval ||
            isOnHold ||
            (request.statusV2 === domain.RequestStatusV2.Rejected &&
                request.origin === domain.RequestOrigin.ApprovalMax));
    const hasActiveIntegration = company.flags.hasActiveIntegration;

    const lockDatePreventsApprove =
        getLockDateInEffect(request, details) &&
        details.lockDatePolicy === domain.TemplateSettingsLockDatePolicy.LockApproval;

    return {
        approve: {
            hidden: (isOnHold ? !(isManager || isApprover) : !isActiveApprover) || lockDatePreventsApprove,
            disabled: lockDatePreventsApprove || isOnHold,
        },
        reject: {
            hidden: isOnHold ? !(isManager || isApprover) : !isActiveApprover,
            disabled: isOnHold,
        },
        forceApprove: {
            hidden: !canForceDecision,
            disabled: lockDatePreventsApprove,
        },
        forceReject: {
            hidden: !canForceDecision,
            disabled: request.statusV2 === domain.RequestStatusV2.Rejected,
        },
        revoke: {
            hidden: status.isClosed || myDecisions.length === 0 || isOnHold,
            disabled: false,
        },
        submit: {
            hidden: true,
            disabled: false,
        },
        startOver: {
            hidden:
                !(hasActiveIntegration && isManager && status.isOpen && request.templateVersionIsObsolete) || isOnHold,
            disabled: false,
        },
        editSecondary: {
            hidden: true,
            disabled: true,
        },
        delete: {
            hidden: true,
            disabled: false,
        },
        cancel: {
            hidden: true,
            disabled: true,
        },
        clone: {
            hidden: true,
            disabled: true,
        },
        clonePOToBill: {
            hidden: true,
            disabled: true,
        },
        cloneQuoteToSalesInvoice: {
            hidden: true,
            disabled: true,
        },
        allocateToCreditNote: {
            hidden: true,
            disabled: true,
        },
        reverse: {
            hidden: true,
            disabled: true,
        },
        editPrimary: {
            hidden: true,
            disabled: true,
        },
        completeReview: {
            hidden: true,
            disabled: true,
        },
        forceReview: {
            hidden: true,
            disabled: true,
        },
        returnToReview: {
            hidden: true,
            disabled: true,
        },
    };
};
