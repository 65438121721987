import { backend, domain } from 'modules/data';
import { useMemo } from 'react';
import {
    useNetSuiteExpenseLinesPreviewAttachment,
    useNetSuiteExpenseReportExpenseLinesFieldSettings,
} from 'shared/hooks';

import { useLineCustomFieldsDefinition } from '../../NetSuiteRequestCard.hooks';
import { getColumnDefs } from './RequestCardExpenseReport.config';

export const useTableDefinitions = (
    expenseLines: domain.NetSuiteExpenseLineExpenseReport[] = [],
    companyCustomFields: backend.SelectNetSuiteCustomCompanyFieldsAnswer['Fields'] = [],
    fieldSettings: ReturnType<typeof useNetSuiteExpenseReportExpenseLinesFieldSettings>,
    exchangeRate: number | null
) => {
    const customFieldsColumnDefinition = useLineCustomFieldsDefinition(expenseLines, companyCustomFields);

    const { onPreviewAttachment } = useNetSuiteExpenseLinesPreviewAttachment();

    const columnDefinition = useMemo(
        () => [...getColumnDefs(fieldSettings, exchangeRate, onPreviewAttachment), ...customFieldsColumnDefinition],
        [fieldSettings, exchangeRate, customFieldsColumnDefinition, onPreviewAttachment]
    );

    return columnDefinition;
};
