import { memo } from 'react';

import { Box } from '../Box/Box';
import { Flex } from '../Flex/Flex';
import { Text } from '../Text/Text';
import { NoContentProps } from './NoContent.types';

/**
 * The NoContent component represents a UI state for scenarios where the content is empty.
 */
export const NoContent = memo<NoContentProps>((props) => {
    const { icon, heading, description, button, width } = props;

    return (
        <Box spacing='40 32' width={width ?? 480}>
            <Flex direction='column' spacing='24' alignItems='center'>
                {icon}

                {heading && (
                    <Text font='headline' fontSize='small'>
                        {heading}
                    </Text>
                )}

                {description && (
                    <Text font='body' fontSize='medium' textAlign='center'>
                        {description}
                    </Text>
                )}

                {button}
            </Flex>
        </Box>
    );
});

NoContent.displayName = 'NoContent';
