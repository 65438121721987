import { Mods } from '@approvalmax/theme';
import { SourceFileRecord } from '@approvalmax/ui/src/components';
import { domain } from 'modules/data';

import { UploadType } from '../UploadPlaceholder/UploadPlaceholder.types';

export interface OcrUploadPlaceholderProps extends Mods<'readOnly'> {
    request: domain.Request;
    onDrop: (files: SourceFileRecord[], uploadType: UploadType) => void;
    filterFiles: (files: File[]) => File[];
    isLoadingNewAttachments: boolean;
    isLoadingNewOcrAttachments: boolean;
    files: SourceFileRecord[];
    setFiles: (files: SourceFileRecord[]) => void;
}

export enum UploadOptions {
    UploadWithOcr = 'UploadWithOcr',
    UploadWithoutOcr = 'UploadWithoutOcr',
}
