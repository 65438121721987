import { Env } from '../configs';

export const getEmailDomain = (env: Env) => {
    switch (env) {
        case 'production':
            return '@mail.approvalmax.com';

        case 'staging':
            return '@mail.amaxinternalstage.com';

        case 'local':
            return '@dev04-mail.amaxinternal.com ';

        default:
            return `@${env}-mail.amaxinternal.com`;
    }
};
