import { Link, Text, toast, ToastId } from '@approvalmax/ui/src/components';
import { useCallback, useRef } from 'react';
import { useUnmount } from 'react-use';

import { messages } from './usePasskeyErrorToast.messages';

export const usePasskeyErrorToast = () => {
    const passkeyToastId = useRef<ToastId>();

    const showErrorToast = useCallback((errorText: string) => {
        const toastContent = (
            <>
                <Text font='body'>{errorText}</Text>

                <Link
                    external
                    font='label'
                    href='https://support.approvalmax.com/portal/en/kb/articles/what-is-a-passkey'
                >
                    {messages.whatIsPasskey}
                </Link>

                <br />

                <Link
                    external
                    font='label'
                    href='https://support.approvalmax.com/portal/en/kb/articles/how-to-set-up-a-passkey'
                >
                    {messages.howToPasskey}
                </Link>
            </>
        );

        if (!passkeyToastId.current) {
            passkeyToastId.current = toast.error(toastContent, {
                autoClose: false,
                onClose: () => {
                    passkeyToastId.current = undefined;
                },
            });
        }
    }, []);

    useUnmount(() => passkeyToastId.current && toast.dismiss(passkeyToastId.current));

    return {
        showErrorToast,
    };
};
