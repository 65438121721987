import { RequestMutationOptions } from '@approvalmax/data';
import { useMutate } from 'shared/data';

import { integrationApiPaths } from '../paths';
import { useInvalidateGetNetSuiteApiFilter } from '../useGetNetSuiteApiFilter/useGetNetSuiteApiFilter';
import { UpdateNetSuiteFilter } from './useUpdateNetSuiteFilter.types';

export const useUpdateNetSuiteFilter = (mutationOptions?: RequestMutationOptions<UpdateNetSuiteFilter>) => {
    const invalidateGetNetSuiteApiFilter = useInvalidateGetNetSuiteApiFilter();

    return useMutate(integrationApiPaths.updateNetSuiteFilter, {
        mutationOptions: {
            ...mutationOptions,
            onSuccess: async (response, variables, context) => {
                await invalidateGetNetSuiteApiFilter({ query: { companyId: variables.body?.companyId } });

                mutationOptions?.onSuccess?.(response, variables, context);
            },
        },
        apiVersion: 'v1',
    });
};
