import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('app.WorkflowIdPage.MigrationFromReviewerV1PopupContent', {
    title: 'Action Needed: Migrate to Editing on Approval',
    discontinuedDescription:
        'The old Reviewer feature in your Bill workflow will be <b>discontinued on 1 June 2025.</b>',
    migrationDescription: 'To keep your review capabilities, transition to the new {url} feature today.',
    migrationDescriptionLink: 'Editing on Approval',
    importantDescription:
        '{icon} Important:{br}The old Review feature will be discontinued soon.{br}Migrate now to retain review functionality or set up Editing on Approval manually later.',
    role: 'Role',
    migrationResults: 'Migration results',
    approverAndReviewer: '<b>Approver AND Reviewer</b>',
    onlyReviewerAndNotApprover: 'Only <b>Reviewer and NOT Approver</b>',
    approverAndReviewerMigrationResult1:
        'Can edit <b>Description, Quantity, Unit Price, Tax Rate, Tax Amount and Attachment</b>',
    approverAndReviewerMigrationResult2:
        'Can edit <b>Item, Accounts and Tracking categories if those are not marked as Read-only</b>',
    approverAndReviewerMigrationResult3:
        'Can set values <b>with same restrictions as Old Reviewer step for Item, Accounts and Tracking categories</b>',
    onlyReviewerAndNotApproverMigrationResult:
        'No editing permissions, as only Approvers are assigned Editing permissions',
    noReviewersDescription: 'No Reviewers are set, no migration is required.',
    warning: 'Irreversible change: Once you migrate, you cannot return to the old Reviewer feature.',
    countReviewersDescription:
        '{count} reviewer(s) will automatically have their data migrated to Editing on Approval.',
    irreversibleDescription: 'Irreversible change: Once you migrate, you cannot return to the old Reviewer feature.',
    doYouWantToReplaceOldReviewerByEditingOnApproval: 'Would you like to migrate now to ensure uninterrupted access?',
    yes: 'Yes, migrate now',
    no: 'Skip for now',
});
