import { SpacingProp } from '@approvalmax/types';

export const defaultTransform = (value: string) => value;

export const calculateLinesByScrollHeight = (args: {
    height: number;
    paddingTop: number;
    paddingBottom: number;
    lineHeight: number;
}) => {
    const { height, lineHeight } = args;
    const paddingTop = Number.isNaN(args.paddingTop) ? 0 : args.paddingTop;
    const paddingBottom = Number.isNaN(args.paddingBottom) ? 0 : args.paddingBottom;

    return (height - paddingTop - paddingBottom) / lineHeight;
};

export const getRightPadding = (spacing: SpacingProp['spacing']) => {
    if (!spacing) return undefined;

    const splitted = spacing.split(' ');

    const value = splitted.length > 1 ? splitted[1] : splitted[0];

    return `${value}px`;
};
