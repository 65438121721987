import { InfoCircleIcon } from '@approvalmax/ui';
import { Box, Button, Checkbox, Flex, Form, TextField, Tooltip } from '@approvalmax/ui/src/components';
import { zodResolver } from '@hookform/resolvers/zod';
import { memo, useCallback } from 'react';
import { useForm } from 'react-hook-form';

import { useTwoFaActionWizardContext } from '../../TwoFaActionWizard.context';
import { fieldNameCode, fieldNameIsTrustedDevice } from './EnterCodeForm.constants';
import { messages } from './EnterCodeForm.messages';
import { schema } from './EnterCodeForm.schema';
import { EmailCodeFormProps, EnterCodeFormData } from './EnterCodeForm.types';

export const EnterCodeForm = memo<EmailCodeFormProps>((props) => {
    const { isLoading, fieldLabel, fieldPlaceholder, isTrustedDeviceEnabled, isAlternativeMethodEnabled, onSubmit } =
        props;

    const { setActiveScreen } = useTwoFaActionWizardContext();

    const form = useForm<EnterCodeFormData>({
        defaultValues: {
            [fieldNameCode]: '',
            [fieldNameIsTrustedDevice]: false,
        },
        resolver: zodResolver(schema),
    });

    const fieldCode = form.watch(fieldNameCode);

    const handleSubmit = form.handleSubmit((data) => {
        onSubmit(data[fieldNameCode], data[fieldNameIsTrustedDevice]);
    });

    const handleGoToMethodSelect = useCallback(() => {
        setActiveScreen('methodSelect');
    }, [setActiveScreen]);

    return (
        <Form form={form} onSubmit={handleSubmit}>
            <Flex direction='column' spacing='24'>
                <Box maxWidth={240}>
                    <TextField
                        required
                        size='medium'
                        name={fieldNameCode}
                        label={fieldLabel}
                        disabled={isLoading}
                        placeholder={fieldPlaceholder}
                    />
                </Box>

                {isTrustedDeviceEnabled && (
                    <Flex spacing='16'>
                        <Checkbox name={fieldNameIsTrustedDevice} size='large'>
                            {messages.trustThisDevice}
                        </Checkbox>

                        <Tooltip isHovering activator={<InfoCircleIcon />} maxWidth={450}>
                            {messages.trustThisDeviceTooltip}
                        </Tooltip>
                    </Flex>
                )}

                <Flex direction='column' spacing='16'>
                    <Button type='submit' size='large' color='blue80' disabled={!fieldCode.trim()} progress={isLoading}>
                        {messages.confirmButton}
                    </Button>

                    {isAlternativeMethodEnabled && (
                        <Button
                            size='large'
                            color='midnight80'
                            disabled={isLoading}
                            onClick={handleGoToMethodSelect}
                            variant='outline'
                        >
                            {messages.anotherMethodButton}
                        </Button>
                    )}
                </Flex>
            </Flex>
        </Form>
    );
});

EnterCodeForm.displayName = 'EmailCodeForm';
