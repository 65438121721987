import { Reference } from '@approvalmax/types';
import { errorHelpers, mathService } from '@approvalmax/utils';
import { selectors } from 'modules/common';
import { backend, domain, State } from 'modules/data';
import moment from 'moment';
import { createSelector } from 'reselect';

import * as qbooksLineItem from '../../data/qbooks/QBooksLineItem';
import { Context } from '../../reducers/page/contextReducer';
import { isSalesInvoiceDetails } from '../../utils/typeGuards';
import { getContext } from '../pageSelectors';
import { getRequestEditMode, getRequiredFields, RequestEditMode } from '../requestSelectors';
import { BillableAccountType } from '../types/ExpandedQBooksAccountLineItem';
import { ExpandedQBooksLineItem } from '../types/ExpandedQBooksLineItem';
import { getQBooksContext } from './qbooksSelectors';
import {
    calculateLineItemsTaxSummary,
    calculateLineItemsTaxSummaryUS,
    calculateShippingTaxSummary,
    calculateTotalTaxSummary,
    getTaxCodeEffectiveRates,
} from './taxSelectors';

export function isEmptyQBooksLineItem(li: domain.QBooksLineItem): boolean {
    return (
        !li.item &&
        li.qty == null &&
        li.unitPrice == null &&
        !li.customer &&
        !li.description &&
        !li.class &&
        !li.taxCode
    );
}

function isValidQBooksLineItem(
    li: domain.QBooksLineItem,
    lineAmountType: domain.LineAmountType,
    context: Context,
    qbooksContext: domain.QBooksContext,
    requiredFields: ReturnType<typeof getRequiredFields>
): boolean {
    if (isEmptyQBooksLineItem(li)) {
        return true;
    }

    let hasAllMandatoryFields = Boolean(li.qty != null && li.unitPrice != null && li.item);

    if (lineAmountType !== domain.LineAmountType.NoTax && qbooksContext.isTrackingTaxesEnabled) {
        hasAllMandatoryFields = hasAllMandatoryFields && Boolean(li.taxCode);
    }

    if (
        qbooksContext.hasClassFeature &&
        qbooksContext.classField &&
        requiredFields.fieldIds.includes(qbooksContext.classField.id)
    ) {
        hasAllMandatoryFields = hasAllMandatoryFields && Boolean(li.class);
    }

    const customerField = context.fields.find((f) => f.systemPurpose === domain.FieldSystemPurpose.QBooksCustomer);

    if (qbooksContext.hasCustomerFeature && customerField && requiredFields.fieldIds.includes(customerField.id)) {
        hasAllMandatoryFields = hasAllMandatoryFields && Boolean(li.customer);
    }

    return hasAllMandatoryFields;
}

export function getComputedLineAmount(li: domain.QBooksLineItem, lineAmountType: domain.LineAmountType) {
    // unitPrice * qty

    const qty = li.qty;
    const unitPrice = lineAmountType === domain.LineAmountType.TaxInclusive ? li.unitPriceGross : li.unitPrice;

    if (unitPrice == null || qty == null) {
        return undefined;
    }

    const isNegativeUnitPrice = unitPrice < 0;
    const absLineAmount = mathService.round(mathService.multiply(Math.abs(unitPrice), qty), 2);

    return isNegativeUnitPrice ? -absLineAmount : absLineAmount;
}

export interface GetComputedPriceParams {
    price?: number | null;
    taxCode?: Reference;
    qbooksContext: domain.QBooksContext;
    lineAmountType: domain.LineAmountType;
    date: string;
    taxApplicableOnType: domain.TaxApplicableOnType;
}

export const getComputedPrice = ({
    price,
    taxCode,
    qbooksContext,
    lineAmountType,
    date,
    taxApplicableOnType,
}: GetComputedPriceParams) => {
    let computedPriceNet;
    let computedPriceGross;

    if (price != null) {
        switch (lineAmountType) {
            case domain.LineAmountType.NoTax:
                computedPriceNet = computedPriceGross = price;
                break;

            case domain.LineAmountType.TaxExclusive:
            case domain.LineAmountType.TaxInclusive: {
                const isNegativeUnitPrice = Number(price) < 0;

                let effectiveTaxRate = 0;

                if (taxCode) {
                    effectiveTaxRate = getTaxCodeEffectiveRates(
                        taxCode.id,
                        date,
                        qbooksContext,
                        taxApplicableOnType
                    ).effectiveRate;
                }

                if (lineAmountType === domain.LineAmountType.TaxInclusive) {
                    // li.unitPrice == Gross
                    computedPriceGross = price;

                    computedPriceNet = mathService.round(
                        mathService.divide(
                            mathService.multiply(Math.abs(price), 100),
                            mathService.add(100, effectiveTaxRate) || 1
                        ),
                        4
                    ); // (price * 100) / (100 + effectiveTaxRate || 1)
                    computedPriceNet = isNegativeUnitPrice ? -computedPriceNet : computedPriceNet;
                } else {
                    // li.unitPrice == Net
                    computedPriceNet = price;

                    computedPriceGross = mathService.round(
                        mathService.divide(
                            mathService.multiply(Math.abs(price), mathService.add(100, effectiveTaxRate)),
                            100
                        ),
                        4
                    ); // (price * (100 + effectiveTaxRate)) / 100
                    computedPriceGross = isNegativeUnitPrice ? -computedPriceGross : computedPriceGross;
                }

                break;
            }

            default:
                throw errorHelpers.assertNever(lineAmountType);
        }
    }

    return {
        computedPriceNet,
        computedPriceGross,
    };
};

export const expandQBooksLineItem = (options: {
    lineAmountType: domain.LineAmountType;
    editMode: RequestEditMode;
    lineItem: domain.QBooksLineItem;
    context: Context;
    qbooksContext: domain.QBooksContext;
    requiredFields: ReturnType<typeof getRequiredFields>;
}) => {
    const { lineAmountType, editMode, lineItem, context, qbooksContext, requiredFields } = options;

    const isNew = qbooksLineItem.isNewQBooksLineItem(lineItem);
    const isEditorMode = editMode === RequestEditMode.Editor;
    const isApproverMode = editMode === RequestEditMode.Approver;
    const accountType = qbooksContext.accounts.find((account) => account.id === lineItem.account?.id)?.type || '';

    return {
        ...lineItem,
        canBeBillable: accountType in BillableAccountType,
        isNew,
        empty: isEmptyQBooksLineItem(lineItem),
        valid: isValidQBooksLineItem(lineItem, lineAmountType, context, qbooksContext, requiredFields),
        computedAmount: getComputedLineAmount(lineItem, lineAmountType),
        hideRemove: (isApproverMode && !isNew) || (isEditorMode && !isNew),
        hideClone: isApproverMode,
    };
};

/**
 * @deprecated consider use {@link getQBooksLineItemsV2} instead because
 * this version reruns on every rerender
 */
export const getQBooksLineItems: (state: State, request: domain.Request) => ExpandedQBooksLineItem[] = createSelector(
    (state: State, request: domain.Request) => request,
    (state: State) => getContext(state),
    (state: State) => getQBooksContext(state),
    (state: State, request: domain.Request) => getRequiredFields(state, request),
    (state: State, request: domain.Request) => getRequestEditMode(state, request),
    (request, context, qbooksContext, requiredFields, editMode) => {
        const isPo = request.integrationCode === domain.IntegrationCode.QBooksPo;
        const isBill = request.integrationCode === domain.IntegrationCode.QBooksBill;
        const isExpense = request.integrationCode === domain.IntegrationCode.QBooksExpense;
        const isSalesInvoice = request.integrationCode === domain.IntegrationCode.QBooksInvoice;

        if (!isPo && !isBill && !isExpense && !isSalesInvoice) {
            console.warn('getQBooksLineItems applied to unsupported request type');

            return [];
        }

        return request.details.lineItems.map((li) =>
            expandQBooksLineItem({
                lineAmountType: request.details.lineAmountType,
                editMode,
                lineItem: li,
                context,
                qbooksContext,
                requiredFields,
            })
        );
    }
);

/**
 * The original {@link getQBooksLineItems} isn't memoized and called on every rerender.
 * This one does if it is used in conjunction with `useMemo`.
 *
 * @example
 * ```ts
 *     const selectLineItems = useMemo(() => getQBooksLineItemsV2(request), [request]);
 *     const lineItems = useSelector(selectLineItems);
 * ```
 */
export const getQBooksLineItemsV2 = (request: domain.Request) =>
    createSelector(
        (state: State) => getContext(state),
        (state: State) => getQBooksContext(state),
        (state: State) => getRequiredFields(state, request),
        (state: State) => getRequestEditMode(state, request),
        (context, qbooksContext, requiredFields, editMode) => {
            const isPo = request.integrationCode === domain.IntegrationCode.QBooksPo;
            const isBill = request.integrationCode === domain.IntegrationCode.QBooksBill;
            const isExpense = request.integrationCode === domain.IntegrationCode.QBooksExpense;
            const isSalesInvoice = request.integrationCode === domain.IntegrationCode.QBooksInvoice;

            if (!isPo && !isBill && !isExpense && !isSalesInvoice) {
                console.warn('getQBooksLineItems applied to unsupported request type');

                return [];
            }

            return request.details.lineItems.map((li) =>
                expandQBooksLineItem({
                    lineAmountType: request.details.lineAmountType,
                    editMode,
                    lineItem: li,
                    context,
                    qbooksContext,
                    requiredFields,
                })
            );
        }
    );

export const getQBooksLineItemById = (state: State, request: domain.Request, lineItemId: string) => {
    const lineItem = getQBooksLineItems(state, request).find((li) => li.id === lineItemId);

    if (!lineItem) {
        throw errorHelpers.notFoundError(`Line item ${lineItemId}`);
    }

    return lineItem;
};

export interface QBooksLineItemsSummary {
    subtotalAmount: number;
    taxComponents: domain.QBooksTaxComponent[];
    totalAmount: number;
    defaultCurrency: string;
    nonDefaultCurrency: boolean;
    totalInDefaultCurrency: number;
    discountAmount?: number;
}

export function getQBooksLineItemsSummary(state: State, request: domain.QBooksRequest): QBooksLineItemsSummary {
    const isPo = request.integrationCode === domain.IntegrationCode.QBooksPo;
    const isBill = request.integrationCode === domain.IntegrationCode.QBooksBill;
    const isExpense = request.integrationCode === domain.IntegrationCode.QBooksExpense;
    const isSalesInvoice = request.integrationCode === domain.IntegrationCode.QBooksInvoice;
    const company = selectors.company.getCompanyById(state, request.companyId);
    const isUSOrg = company?.integration?.countryCode === backend.OrganisationVersion.QBO_US;

    if (!isPo && !isBill && !isExpense && !isSalesInvoice) {
        console.warn('getQBooksLineItemsSummary applied to unsupported request type');

        return {
            subtotalAmount: 0,
            taxComponents: [],
            totalAmount: 0,
            defaultCurrency: 'USD',
            nonDefaultCurrency: false,
            totalInDefaultCurrency: 0,
        };
    }

    const qbooksContext = getQBooksContext(state);
    const lineItems = getQBooksLineItems(state, request);

    const { details } = request;
    const lineAmountType = details.lineAmountType;
    const date = details.date || moment.utc().startOf('day').toISOString();

    let subtotalAmount = 0;
    let discountableAmount = 0;

    lineItems.forEach((li) => {
        if (li.computedAmount) {
            subtotalAmount = mathService.add(subtotalAmount, li.computedAmount);
        }

        if (li.unitPrice && li.qty) {
            discountableAmount = mathService.add(discountableAmount, mathService.multiply(li.unitPrice, li.qty));
        }
    });

    let taxSummary = isUSOrg
        ? calculateLineItemsTaxSummaryUS({
              date,
              lineItems,
              qbooksContext,
              ...(isSalesInvoiceDetails(details)
                  ? {
                        discount: details.discount,
                        discountType: details.discountType,
                        taxCode: details.taxCode,
                        applyTaxAfterDiscount: details.applyTaxAfterDiscount,
                    }
                  : {}),
          })
        : calculateLineItemsTaxSummary({
              lineAmountType,
              date,
              lineItems,
              qbooksContext,
              ...(isSalesInvoiceDetails(details)
                  ? {
                        discount: details.discount,
                        discountType: details.discountType,
                        taxApplicableOnType: domain.TaxApplicableOnType.Sales,
                    }
                  : {}),
          });

    let discountAmount = 0;

    let totalAmount =
        lineAmountType === domain.LineAmountType.TaxInclusive
            ? subtotalAmount
            : mathService.add(subtotalAmount, taxSummary.totalTax);

    if (isSalesInvoiceDetails(details)) {
        let shippingAmount = details.shipping.price || 0;

        const shippingSummary = calculateShippingTaxSummary(details, qbooksContext);

        taxSummary = calculateTotalTaxSummary(taxSummary.taxComponents, shippingSummary.taxComponents);

        if (details.discount) {
            discountAmount = mathService.round(
                details.discountType === domain.QBooksDiscountType.Amount
                    ? details.discount
                    : mathService.divide(mathService.multiply(discountableAmount, details.discount), 100),
                2
            );

            totalAmount = mathService.subtract(
                mathService.add(discountableAmount, taxSummary.totalTax),
                discountAmount
            );
        } else {
            shippingAmount = mathService.add(shippingAmount, shippingSummary.totalTax);
        }

        totalAmount = mathService.add(totalAmount, shippingAmount);
    }

    const defaultCurrency = selectors.company.getCompanyById(state, request.companyId).defaultCurrency;
    const nonDefaultCurrency = request.currency !== defaultCurrency;
    const exchangeRate = request.currencyExchangeRate || request.exchangeRate;

    const totalInDefaultCurrency = nonDefaultCurrency && exchangeRate ? totalAmount / exchangeRate : totalAmount;

    return {
        subtotalAmount,
        taxComponents: taxSummary.taxComponents,
        totalAmount,
        defaultCurrency,
        nonDefaultCurrency,
        totalInDefaultCurrency,
        discountAmount,
    };
}

export function mergeSummaries(
    summary1: QBooksLineItemsSummary,
    summary2: QBooksLineItemsSummary
): QBooksLineItemsSummary {
    let taxComponents: domain.QBooksTaxComponent[] = [];

    [...summary2.taxComponents, ...summary1.taxComponents].forEach((c) => {
        const existing = taxComponents.find((x) => x.taxRateId === c.taxRateId);

        if (!existing) {
            taxComponents.push({
                ...c,
            });
        } else {
            existing.taxAmount += c.taxAmount;
            existing.taxableAmount += c.taxableAmount;
        }
    });

    return {
        defaultCurrency: summary1.defaultCurrency,
        nonDefaultCurrency: summary1.nonDefaultCurrency,
        subtotalAmount: mathService.add(summary1.subtotalAmount, summary2.subtotalAmount),
        totalAmount: mathService.add(summary1.totalAmount, summary2.totalAmount),
        totalInDefaultCurrency: mathService.add(summary1.totalInDefaultCurrency, summary2.totalInDefaultCurrency),
        taxComponents,
    };
}
