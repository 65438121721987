import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('pages.RequestFormPage.PageContent.ChooseFilePopupContent', {
    title: 'Choose file',
    uploadButtonTitle: 'Upload',
    uploadAsAttachmentsText: 'I want to upload these files as attachments',
    ocrUploadText: 'Choose the only one file for the ApprovalMax Capture:',
    ocrUnsupportedFormatText: 'Files that we do not support',
    attachemntsUploadText: 'Choose the files:',
});
