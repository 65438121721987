import { selectors } from 'modules/common';

import { WorkflowSettingsPopupSectionProps } from '../../WorkflowSettingsPopupContent.types';

export interface SectionSupplierBankAccountRequiredProps extends WorkflowSettingsPopupSectionProps {
    links: selectors.types.LinksCollection;
}

export enum SupplierBankAccountRequiredOption {
    Filled = 'Filled',
    Empty = 'Empty',
}
