import { domain } from 'modules/data';

export const disabledAddOnsBillingSystems = [
    domain.SubscriptionBillingSystem.XeroAppStore,
    domain.SubscriptionBillingSystem.Direct,
    domain.SubscriptionBillingSystem.None,
];

/**
 * The add-on feature is disabled in these products:
 */
export const disabledIntegrationsForAddonsByTypes = {
    [domain.AddonTypes.Capture]: [
        domain.IntegrationType.Dear,
        domain.IntegrationType.NetSuite,
        domain.IntegrationType.None,
    ],
    [domain.AddonTypes.Pay]: [
        domain.IntegrationType.QBooks,
        domain.IntegrationType.Dear,
        domain.IntegrationType.NetSuite,
        domain.IntegrationType.None,
    ],
};

/**
 * Pay add-on feature available only with these currencies:
 */
export const addonPayAvailableCurrencies = [domain.NextBillingCurrency.GBP];
