import { RequestParams, RequestQueryOptions } from '@approvalmax/data';
import { useGet } from 'shared/data';

import { paymentServicesApiPaths } from '../paths';
import { GetBillLinkedToBatchPayment } from './useGetBillLinkedToBatchPayment.types';

export const useGetBillLinkedToBatchPayment = (
    params: RequestParams<GetBillLinkedToBatchPayment>,
    queryOptions?: RequestQueryOptions<GetBillLinkedToBatchPayment>
) =>
    useGet(paymentServicesApiPaths.getBillLinkedToBatchPayment, {
        params,
        queryOptions,
        apiVersion: 'v1',
        method: 'post',
    });
