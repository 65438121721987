import { enabledByParams, RequestParams, RequestQueryOptions } from '@approvalmax/data';
import { useGet } from 'shared/data';

import { integrationApiPaths } from '../paths';
import { GetQBooksCustomers } from './useGetQBooksCustomers.types';

export const useGetQBooksCustomers = (
    params?: RequestParams<GetQBooksCustomers<true>>,
    queryOptions?: RequestQueryOptions<GetQBooksCustomers<true>>
) => {
    return useGet(integrationApiPaths.getQBooksCustomers, {
        params,
        queryOptions: {
            ...queryOptions,
            enabled: enabledByParams<GetQBooksCustomers['params']['query']>(
                {
                    companyId: params?.query?.companyId,
                    requestId: params?.query?.requestId,
                    ruleType: params?.query?.ruleType,
                },
                queryOptions
            ),
        },
        apiVersion: 'v1',
        mapToCamelCase: true,
    });
};

export const useGetQBooksCustomersRaw = (
    params?: RequestParams<GetQBooksCustomers>,
    queryOptions?: RequestQueryOptions<GetQBooksCustomers>
) => {
    return useGet(integrationApiPaths.getQBooksCustomers, {
        params,
        queryOptions: {
            ...queryOptions,
            enabled: enabledByParams<GetQBooksCustomers['params']['query']>(
                {
                    companyId: params?.query?.companyId,
                    requestId: params?.query?.requestId,
                    ruleType: params?.query?.ruleType,
                },
                queryOptions
            ),
        },
        apiVersion: 'v1',
    });
};
