import { Guid } from '@approvalmax/types';
import { domain } from 'modules/data';
import { Module } from 'modules/page';
import { ThunkAction } from 'modules/react-redux';
import { notificationService } from 'services/notification';

import * as actions from './actions';
import CacheManagementPanel from './containers/xero/CacheManagementPanel/CacheManagementPanel';
import { messages } from './index.messages';
import { ModuleComponent } from './ModuleComponent';
import entitiesReducer from './reducers/entitiesReducer';
import metaReducer from './reducers/metaReducer';
import moduleReducer from './reducers/moduleReducer';

export * as integrationActions from './actions';
export { default as NetSuiteCacheManagementPanel } from './containers/netsuite/NetSuiteCacheManagementPanel/NetSuiteCacheManagementPanel';
export * from './types/IntegrationSuccessRedirectPage';

export const xero = {
    CacheManagementPanel,
};

export function loadXeroIntegrationStep2Sequence(options: {
    companyId: Guid | null;
    tenants: domain.XeroTenantInfo[];
    users: domain.User[];
    backendState: string;
}): ThunkAction {
    return async (dispatch) => {
        // 1. new company & no new tenants
        // 2. company without integration & no new tenants
        // => show toast "The selected Xero company has been connected to another AM company"
        const newTenants = options.tenants.filter((t) => !t.connectedIntegrationId);

        if (newTenants.length === 0) {
            /* TODO: probably should be displayed as a popup. Should we reconnect the existing AM org instead of showing this (if possible)? */
            notificationService.showErrorToast(messages.noNewTenantsError);

            return;
        }

        dispatch(actions.showXeroIntegrationSelectPopup(options));
    };
}

export const moduleDef: Module = {
    id: 'integration',
    moduleReducer: moduleReducer,
    moduleComponent: ModuleComponent,
    reducers: {
        entities: entitiesReducer,
        meta: metaReducer,
    },
};
