import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages(
    'pages.RequestList.NetSuiteRequestCard.NetSuiteInvoicePurchaseOrderFooterFields',
    {
        shippingDetailsSection: 'Shipping Details',
        shipTo: 'Ship to',
        shippingAddress: 'Shipping address',
        shipDate: 'Shipping date',
        shipMethod: 'Shipping method',
        fob: 'FOB',
        trackingNumbers: 'Tracking numbers',
    }
);
