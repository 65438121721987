import { Box, Popup, Text } from '@approvalmax/ui/src/components';
import { useAtomValue } from 'jotai';
import { integrationPopupPreviewImageState } from 'modules/integration/ModuleComponent.states';

import { StyledImage } from './PreviewImagePopup.styles';

export const PreviewImagePopup = () => {
    const { title, text, src, alt } = useAtomValue(integrationPopupPreviewImageState);

    return (
        <>
            <Popup.Header title={title} />

            <Popup.Body>
                <Box spacing='32 0 20'>
                    <Text font='body'>{text}</Text>
                </Box>

                <StyledImage src={src} alt={alt} />
            </Popup.Body>
        </>
    );
};

PreviewImagePopup.displayName = 'PreviewImagePopup';
