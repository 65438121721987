import { isEnvironment } from './isEnvironment';
import { isKeyOfObject } from './isKeyOfObject';
import { isReference } from './isReference';
import { isTruthy } from './isTruthy';
import { isValueOfObject } from './isValueOfObject';

export const typeGuardHelpers = {
    isKeyOfObject,
    isEnvironment,
    isTruthy,
    isValueOfObject,
    isReference,
};
