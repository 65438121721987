import { enabledByParams, RequestParams, RequestQueryOptions } from '@approvalmax/data';
import { useGet } from 'shared/data';

import { requestsApiPaths } from '../paths';
import { GetQuoteForCustomerDecisionPdf } from './useGetQuoteForCustomerDecisionPdf.types';

export const useGetQuoteForCustomerDecisionPdf = (
    params?: RequestParams<GetQuoteForCustomerDecisionPdf>,
    queryOptions?: RequestQueryOptions<GetQuoteForCustomerDecisionPdf>
) => {
    return useGet(requestsApiPaths.getQuoteForCustomerDecisionPdf, {
        params,
        queryOptions: {
            ...queryOptions,
            enabled: enabledByParams<GetQuoteForCustomerDecisionPdf['params']['query']>(
                {
                    companyId: params?.query?.companyId,
                    requestId: params?.query?.requestId,
                    code: params?.query?.code,
                    inline: params?.query?.inline,
                },
                queryOptions
            ),
        },
        apiVersion: 'v1',
        responseType: 'blob',
    });
};
