import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('components.TwoFaEnablingWizard.StepCodeScan', {
    title: 'Install Authentication app to log in',
    description: `When you want to log in with two-factor authentication enabled, you’ll need to enter an additional code generated by the Google Authenticator app.{br}{br}
    1) From the App Store (for iPhones) or Google Play (for Android smartphones), download the Google Authenticator app to your smartphone.{br}{br}
    2) Use that app to scan the QR code displayed below, or type in the key. If you don’t have a smartphone or need assistance, please refer to our <supportlink>support article</supportlink>.`,
    codeImageAlt: 'QR Code',
    buttonCopy: 'Copy to clipboard',
    notificationCodeCopied: 'Code copied to clipboard',
    errorStateIdEmpty: 'State ID is empty',
    errorSetupCodeEmpty: 'Setup code is empty',
});
