import { errorHelpers } from '@approvalmax/utils';
import { selectors } from 'modules/common';
import { State } from 'modules/data';
import { ImmutableObject } from 'modules/immutable';

import { PAGE_ID } from '../config';
import { ActiveModule, PageActiveModule } from '../reducers/page/moduleReducer';
import { Page } from '../reducers/pageReducer';
import { messages } from './pageSelectors.messages';

export function getPage(state: State): Page {
    return selectors.page.getPage(state, PAGE_ID);
}

export function checkLoaded(state: State, ...moduleTypes: PageActiveModule[]): boolean {
    const isActive = selectors.navigation.getActivePageId(state) === PAGE_ID;

    if (!isActive) {
        return false;
    }

    return moduleTypes.includes(getModule(state).type);
}

export function getDocumentTitle(state: State) {
    const type = getModule(state).type;

    switch (type) {
        case PageActiveModule.ReceiptBankAuthModule:
            return messages.receiptBankAuthPageTitle;

        case PageActiveModule.LoginModule:
        case PageActiveModule.TwoFaLoginModuleData:
            return messages.loginPageTitle;

        case PageActiveModule.PasswordResetModule:
            return messages.passwordResetPageTitle;

        case PageActiveModule.PasswordResetFinalStepModule:
            return messages.passwordResetFinalStepTitle;

        case PageActiveModule.SignupModule:
            return messages.signupPageTitle;

        case PageActiveModule.EmailConfirmationModule:
            return messages.emailConfirmationPageTitle;

        case PageActiveModule.ProfileSetupModule:
            return messages.profileSetupModuleTitle;

        case PageActiveModule.VerifyEmailModule:
            return messages.verifyEmailPageTitle;

        case PageActiveModule.SlackAuthModule:
            return messages.slackAuthPageTitle;

        case PageActiveModule.FirstTimeSetupModule:
            return messages.firstTimeSetupPageTitle;

        case PageActiveModule.MobileInfoModule:
        case PageActiveModule.None:
            return null;

        default:
            throw errorHelpers.assertNever(type);
    }
}

export function getModule<T extends ActiveModule = ActiveModule>(state: State): ImmutableObject<T> {
    return getPage(state).module as ImmutableObject<T>;
}

export function getLoading(state: State): boolean {
    return getPage(state).loading;
}
