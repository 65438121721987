import { RequestParams, RequestQueryOptions } from '@approvalmax/data';
import { GetNetSuiteBillPaymentBill, useGetNetSuiteBillPaymentBill } from 'shared/data/webApp/v2';

import { useNormalizedLinkedNetSuiteBillResponse } from './useNormalizedLinkedNetSuiteBillResponse';

export const useBillLinkedToNetSuiteBillPayment = (
    params?: RequestParams<GetNetSuiteBillPaymentBill>,
    queryOptions?: RequestQueryOptions<GetNetSuiteBillPaymentBill>
) => {
    return useNormalizedLinkedNetSuiteBillResponse(
        params?.path?.lineRequestId,
        useGetNetSuiteBillPaymentBill(params, queryOptions)
    );
};
