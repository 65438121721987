import { enabledByParams, RequestParams, RequestQueryOptions } from '@approvalmax/data';
import { useGet } from 'shared/data';

import { requestsApiPaths } from '../paths';
import { GetRequest } from './useGetRequest.types';

export const useGetRequest = (
    params?: RequestParams<GetRequest>,
    queryOptions?: RequestQueryOptions<GetRequest<true>>
) => {
    return useGet(requestsApiPaths.get, {
        params,
        queryOptions: {
            ...queryOptions,
            enabled: enabledByParams<GetRequest['params']['query']>(
                {
                    requestId: params?.query?.requestId,
                    companyId: params?.query?.companyId,
                },
                queryOptions
            ),
        },
        apiVersion: 'v1',
        mapToCamelCase: true,
    });
};
