import { RequestMutationOptions } from '@approvalmax/data';
import { useMutate } from 'shared/data';

import { groupsOfXeroAccountsApiPaths } from '../paths';
import { useInvalidateGetGroupsOfXeroAccounts } from '../useGetGroupsOfXeroAccounts/useGetGroupsOfXeroAccounts';
import { UpdateGroupOfXeroAccountsVersions } from './useUpdateGroupOfXeroAccountsVersions.types';

export const useUpdateGroupOfXeroAccountsVersions = (
    mutationOptions?: RequestMutationOptions<UpdateGroupOfXeroAccountsVersions>
) => {
    const invalidateGetGroupsOfXeroAccounts = useInvalidateGetGroupsOfXeroAccounts();

    return useMutate(groupsOfXeroAccountsApiPaths.groupOfXeroAccountsVersions, {
        mutationOptions: {
            ...mutationOptions,
            onSuccess: async (data, variables, context) => {
                invalidateGetGroupsOfXeroAccounts({ path: { companyId: variables.params?.path?.companyId } });

                mutationOptions?.onSuccess?.(data, variables, context);
            },
        },
        method: 'post',
        apiVersion: 'v2',
    });
};
