import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('modules.company.CollectAccountNurturingDataPopup', {
    title: 'Fill in the information',
    sendButton: 'Send',
    greeting: 'Dear {userName},',
    chooseOption: 'Choose option',
    mainMessage1: 'We always strive to improve the quality of our customer service.',
    mainMessage2:
        'To help us provide you with the most suitable support and assistance, please answer the following question:',
    question: 'How many organisations do you currently manage? (regardless of how many are created in ApprovalMax)',
    lessThanThree: 'Less than 3',
    threeToTen: '3-10',
    moreThanTen: '10+',
});
