import { Popup, Tabs } from '@approvalmax/ui/src/components';
import { pageConfig as workflowPageConfig } from 'app/(workspace)/[companyId]/workflows/[workflowId]/config';
import { getActiveTemplate } from 'app/(workspace)/[companyId]/workflows/[workflowId]/resources/selectors/pageSelectors';
import { pageConfig as standaloneWorkflowPageConfig } from 'app/(workspace)/[companyId]/workflows/new/config';
import { useAtom } from 'jotai';
import { selectors } from 'modules/common';
import { State } from 'modules/data';
import { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import {
    ApprovalStepsStep,
    ConditionsStep,
    InitialStep,
    PreviewStep,
    RequestersStep,
    TypeOfDocsStep,
    UsersStep,
} from './components';
import { tabName } from './WorkflowWizardPopup.constants';
import { getPossibleSourceOfDocument } from './WorkflowWizardPopup.helpers';
import { useOpenStorageKey } from './WorkflowWizardPopup.hooks';
import { messages } from './WorkflowWizardPopup.messages';
import { workflowsWizardAllowedTemplatesState } from './WorkflowWizardPopup.states';
import { ApprovalStep, SourceOfDocs, WizardStep, WorkflowWizardPopupProps } from './WorkflowWizardPopup.types';

export const WorkflowWizardPopup: FC<WorkflowWizardPopupProps> = memo((props) => {
    const { onClose } = props;

    const [enabledStep, setEnabledSteps] = useState<WizardStep[]>([]);
    const [steps, setSteps] = useState<ApprovalStep[] | undefined>();
    const [requesters, setRequesters] = useState<string[] | undefined>();
    const [step, setStep] = useState(WizardStep.Initial);
    const [visible, setVisible] = useState(false);
    const [addedUserCount, setAddedUserCount] = useState(0);
    const [stepsWithConditions, setStepsWithConditions] = useState<number>(0);

    const history = useHistory();
    const template = useSelector(getActiveTemplate);
    const [integrateWorkflows, setIntegrateWorkflows] = useAtom(workflowsWizardAllowedTemplatesState);
    const innerActivePageId = useSelector(selectors.navigation.getInnerActivePageId);
    const { companyId } = useParams<{ companyId: string }>();
    const company = useSelector((state: State) => selectors.company.findCompanyById(state, companyId));
    const profile = useSelector(selectors.profile.findProfile);
    const { removeStorageKey, isOpenedTemplate, saveOpenTemplateId } = useOpenStorageKey();

    const isInitial = step === WizardStep.Initial;

    const visibleStepsByTemplate = useMemo(() => {
        if (!template || !company) {
            return [];
        }

        const possibleSourceOfDocument = getPossibleSourceOfDocument(template, company);
        const showRequesters = possibleSourceOfDocument.includes(SourceOfDocs.ApprovalMax);

        return [
            WizardStep.TypeOfDocs,
            WizardStep.ApprovalSteps,
            WizardStep.Users,
            ...(showRequesters ? [WizardStep.Requesters] : []),
            WizardStep.Conditions,
            WizardStep.Preview,
        ];
    }, [company, template]);

    const tabItems = useMemo(() => {
        if (step === WizardStep.Initial) {
            return [];
        } else if (step === WizardStep.TypeOfDocs) {
            return [{ value: WizardStep.TypeOfDocs, label: tabName[WizardStep.TypeOfDocs] }];
        }

        return visibleStepsByTemplate.map((el) => ({
            value: el,
            label: tabName[el],
            disabled: el !== step && !enabledStep.includes(el),
        }));
    }, [step, visibleStepsByTemplate, enabledStep]);

    const handleClose = useCallback(() => {
        removeStorageKey();
        setIntegrateWorkflows([]);
        setVisible(false);
        onClose?.();
    }, [onClose, removeStorageKey, setIntegrateWorkflows]);

    const handleToggle = useCallback(
        (value: boolean) => {
            if (value) {
                setVisible(true);
            } else {
                handleClose();
            }
        },
        [handleClose]
    );

    const setNextStep = useCallback(
        (nextStep: WizardStep) => {
            setStep(nextStep);

            if (!enabledStep.includes(nextStep)) {
                setEnabledSteps([...enabledStep, nextStep]);
            }
        },
        [enabledStep]
    );

    const handleNext = useCallback(
        (step: WizardStep) => {
            const index = visibleStepsByTemplate.findIndex((el) => el === step);

            setNextStep(visibleStepsByTemplate[index + 1]);
        },
        [setNextStep, visibleStepsByTemplate]
    );

    const handleBack = useCallback(
        (step: WizardStep) => {
            const index = visibleStepsByTemplate.findIndex((el) => el === step);

            setNextStep(visibleStepsByTemplate[index - 1]);
        },
        [setNextStep, visibleStepsByTemplate]
    );

    useEffect(() => {
        if (integrateWorkflows?.length > 0) {
            setStep(WizardStep.Initial);
            setEnabledSteps([]);
            setSteps(undefined);
            setRequesters(undefined);
            setVisible(true);
        }
    }, [integrateWorkflows]);

    useEffect(() => {
        if (
            (innerActivePageId === workflowPageConfig.innerActivePageId ||
                innerActivePageId === standaloneWorkflowPageConfig.innerActivePageId) &&
            template &&
            !visible &&
            step === WizardStep.Initial &&
            isOpenedTemplate(template)
        ) {
            handleNext(WizardStep.TypeOfDocs);
            setVisible(true);
        }
    }, [handleNext, innerActivePageId, isOpenedTemplate, step, template, visible]);

    useEffect(() => {
        const unlisten = history.listen(() => {
            if (visible && step !== WizardStep.TypeOfDocs) {
                handleClose();
            }
        });

        return () => unlisten();
    });

    const content = useMemo(() => {
        switch (step) {
            case WizardStep.Initial:
                return (
                    <InitialStep
                        onNext={() => setStep(WizardStep.TypeOfDocs)}
                        amplitudeParams={{
                            org_id: company?.id,
                            gl_type: company?.integration?.integrationType,
                            user_id: profile?.id,
                        }}
                    />
                );

                return null;

            case WizardStep.TypeOfDocs:
                return (
                    <TypeOfDocsStep
                        integrateWorkflows={integrateWorkflows}
                        onNext={(template) => {
                            saveOpenTemplateId(template);
                            handleNext(WizardStep.TypeOfDocs);
                        }}
                        amplitudeParams={{
                            org_id: company?.id,
                            gl_type: company?.integration?.integrationType,
                            user_id: profile?.id,
                        }}
                    />
                );

                return null;

            case WizardStep.ApprovalSteps:
                if (template) {
                    return (
                        <ApprovalStepsStep
                            defaultSteps={steps}
                            template={template}
                            onNext={(values) => {
                                setSteps(values);
                                handleNext(WizardStep.ApprovalSteps);
                            }}
                            amplitudeParams={{
                                org_id: company?.id,
                                gl_type: company?.integration?.integrationType,
                                user_id: profile?.id,
                                wf_type: template?.integrationCode,
                                wf_id: template?.id,
                            }}
                        />
                    );
                }

                return null;

            case WizardStep.Users:
                if (template) {
                    return (
                        <UsersStep
                            template={template}
                            onNext={(addedUserCount) => {
                                setAddedUserCount(addedUserCount);
                                handleNext(WizardStep.Users);
                            }}
                            onBack={() => handleBack(WizardStep.Users)}
                            amplitudeParams={{
                                org_id: company?.id,
                                gl_type: company?.integration?.integrationType,
                                user_id: profile?.id,
                                wf_type: template?.integrationCode,
                                wf_id: template?.id,
                                steps_count: steps?.length,
                            }}
                        />
                    );
                }

                return null;

            case WizardStep.Requesters:
                if (template) {
                    return (
                        <RequestersStep
                            template={template}
                            defaultRequesters={requesters}
                            onNext={(values) => {
                                setRequesters(values);
                                handleNext(WizardStep.Requesters);
                            }}
                            onBack={() => handleBack(WizardStep.Requesters)}
                            amplitudeParams={{
                                org_id: company?.id,
                                gl_type: company?.integration?.integrationType,
                                user_id: profile?.id,
                                wf_type: template?.integrationCode,
                                wf_id: template?.id,
                                steps_count: steps?.length,
                                users: addedUserCount,
                            }}
                        />
                    );
                }

                return null;

            case WizardStep.Conditions:
                if (template) {
                    return (
                        <ConditionsStep
                            template={template}
                            onNext={(stepsWithConditions) => {
                                setStepsWithConditions(stepsWithConditions);
                                handleNext(WizardStep.Conditions);
                            }}
                            amplitudeParams={{
                                org_id: company?.id,
                                gl_type: company?.integration?.integrationType,
                                user_id: profile?.id,
                                wf_type: template?.integrationCode,
                                wf_id: template?.id,
                                steps_count: steps?.length,
                                users: addedUserCount,
                                conditions_type: template?.integrationCode === null ? 'standalone' : 'general',
                                requesters: requesters?.length,
                            }}
                        />
                    );
                }

                return null;

            case WizardStep.Preview:
                if (template) {
                    return (
                        <PreviewStep
                            template={template}
                            onClose={handleClose}
                            amplitudeParams={{
                                org_id: company?.id,
                                gl_type: company?.integration?.integrationType,
                                user_id: profile?.id,
                                wf_type: template?.integrationCode,
                                wf_id: template?.id,
                                steps_count: steps?.length,
                                users: addedUserCount,
                                conditions_type: template?.integrationCode === null ? 'standalone' : 'general',
                                requesters: requesters?.length,
                                steps_with_conditions: stepsWithConditions,
                            }}
                        />
                    );
                }

                return null;

            default: {
                const _exhaustiveCheck: never = step;

                return null;
            }
        }
    }, [
        addedUserCount,
        company?.id,
        company?.integration?.integrationType,
        handleBack,
        handleClose,
        handleNext,
        integrateWorkflows,
        stepsWithConditions,
        profile?.id,
        requesters,
        saveOpenTemplateId,
        step,
        steps,
        template,
    ]);

    if (!content) {
        return null;
    }

    return (
        <Popup
            open={visible}
            onToggle={handleToggle}
            size={isInitial ? 'xsmall' : 'large'}
            preventAutoClose
            qa='workflow-wizard'
        >
            <Popup.Header title={isInitial ? '' : messages.title} />

            <Popup.Body spacing='32'>
                {isInitial ? (
                    content
                ) : (
                    <>
                        <Tabs
                            value={step}
                            items={tabItems}
                            bordered={false}
                            onChange={setStep as (value: string) => void}
                        />

                        {content}
                    </>
                )}
            </Popup.Body>
        </Popup>
    );
});

WorkflowWizardPopup.displayName = 'WorkflowWizardPopup';
