import { FieldSettingsLearnMoreLinksData } from '../selectors/types';

const standardNetSuiteFieldsSettingsLink =
    'https://support.approvalmax.com/portal/en/kb/articles/how-to-customize-netsuite-request-form-in-approvalmax';

export const fieldSettingsLearnMoreLinks: FieldSettingsLearnMoreLinksData = {
    NetSuite: {
        'NetSuite-PO': {
            ExpensesFields: standardNetSuiteFieldsSettingsLink,
            HeaderFields: standardNetSuiteFieldsSettingsLink,
            ItemsFields: standardNetSuiteFieldsSettingsLink,
            AttachmentsFields: standardNetSuiteFieldsSettingsLink,
        },
        'NetSuite-vendor-bill': {
            ExpensesFields: standardNetSuiteFieldsSettingsLink,
            HeaderFields: standardNetSuiteFieldsSettingsLink,
            ItemsFields: standardNetSuiteFieldsSettingsLink,
            AttachmentsFields: standardNetSuiteFieldsSettingsLink,
        },
        'NetSuite-bill-payment': {
            ApplyLineFields: standardNetSuiteFieldsSettingsLink,
            HeaderFields: standardNetSuiteFieldsSettingsLink,
            CreditLineFields: standardNetSuiteFieldsSettingsLink,
            AttachmentsFields: standardNetSuiteFieldsSettingsLink,
        },
        'NetSuite-invoice': {
            HeaderFields: standardNetSuiteFieldsSettingsLink,
            ItemsFields: standardNetSuiteFieldsSettingsLink,
            AttachmentsFields: standardNetSuiteFieldsSettingsLink,
        },
        'NetSuite-journal-entry': {
            HeaderFields: standardNetSuiteFieldsSettingsLink,
            ItemsFields: standardNetSuiteFieldsSettingsLink,
            AttachmentsFields: standardNetSuiteFieldsSettingsLink,
        },
        'NetSuite-vendor': {
            HeaderFields: standardNetSuiteFieldsSettingsLink,
            AddressLineFields: standardNetSuiteFieldsSettingsLink,
            AttachmentsFields: standardNetSuiteFieldsSettingsLink,
        },
    },
    Dear: {},
    None: {},
    QBooks: {},
    Xero: {},
};
