import { PartyImage } from '@approvalmax/ui';
import { Button, Flex, Popup, Text } from '@approvalmax/ui/src/components';
import { FC, memo } from 'react';

import { messages } from './ReviewerV1MigrationSuccessPopupContent.messages';
import { ReviewerV1MigrationSuccessPopupContentProps } from './ReviewerV1MigrationSuccessPopupContent.types';

export const ReviewerV1MigrationSuccessPopupContent: FC<ReviewerV1MigrationSuccessPopupContentProps> = memo((props) => {
    const { onClose } = props;

    return (
        <>
            <Popup.Header />

            <Popup.Body>
                <Flex direction='column' spacing='24' alignItems='center'>
                    <PartyImage />

                    <Text font='headline' fontSize='small' fontWeight='medium' textAlign='center'>
                        {messages.successPopupMessage}
                    </Text>

                    <Text font='headline' fontSize='xxsmall' fontWeight='medium' textAlign='center'>
                        {messages.description}
                    </Text>

                    <Button color='green80' size='large' block onClick={onClose}>
                        {messages.great}
                    </Button>
                </Flex>
            </Popup.Body>
        </>
    );
});

ReviewerV1MigrationSuccessPopupContent.displayName = 'ReviewerV1MigrationSuccessPopupContent';
