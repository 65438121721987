import { AmCaptureImage } from '@approvalmax/ui';
import { Box, Button, Flex, Text } from '@approvalmax/ui/src/components';
import { useSetAtom } from 'jotai';
import { selectors } from 'modules/common';
import { bulkCreationPopupContentState, bulkCreationPopupOpenState } from 'modules/request';
import { closeActivePopup } from 'modules/request/actions';
import { FC, memo, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { messages } from './BulkCreationBanner.messages';
import { BulkCreationBannerProps } from './BulkCreationBanner.types';

export const BulkCreationBanner: FC<BulkCreationBannerProps> = memo((props) => {
    const { companyId, templateIntegrationCode } = props;

    const dispatch = useDispatch();

    const templateName = selectors.template.getTemplateDisplayNameByCode(templateIntegrationCode, true)?.toLowerCase();

    const setBulkCreationPopupOpen = useSetAtom(bulkCreationPopupOpenState);
    const setBulkCreationPopupContent = useSetAtom(bulkCreationPopupContentState);

    const onCreate = useCallback(() => {
        dispatch(closeActivePopup());
        setBulkCreationPopupContent({ companyId, templateIntegrationCode });
        setBulkCreationPopupOpen(true);
    }, [companyId, dispatch, setBulkCreationPopupContent, setBulkCreationPopupOpen, templateIntegrationCode]);

    return (
        <Box width={280} height='fit-content' spacing='24' color='midnight20'>
            <Flex direction='column' alignItems='center' spacing='16'>
                <AmCaptureImage height={48} width={126} />

                <Text textAlign='center' font='headline' fontSize='xxsmall' fontWeight='medium'>
                    {messages.description({ templateName })}
                </Text>

                <Button onClick={onCreate} color='mint80'>
                    {messages.uploadButtonText}
                </Button>
            </Flex>
        </Box>
    );
});

BulkCreationBanner.displayName = 'BulkCreationBanner';
