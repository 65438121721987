import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('app.WorkflowIdPage.GroupOfValuesPopup.GroupEditSuccessPopupContent', {
    title: 'Group "{groupName}" has been successfully updated!',
    subTitle: 'The following workflows are using this group:',
    description:
        'Changes may take a few minutes to process. Please wait for updates to complete before making manual adjustments. Once the changes have been processed, refresh the page and restart affected requests by clicking Restart',
    imageAlt: 'Restart workflow',
    confirmButton: 'Ok',
});
