import { ExtractComponentProp } from '@approvalmax/types';
import { Button } from '@approvalmax/ui';
import { Form, Popup, useConfirmation } from '@approvalmax/ui/src/components';
import { hooks } from '@approvalmax/utils';
import { selectors } from 'modules/common';
import { domain } from 'modules/data';
import { useDispatch, useSelector } from 'modules/react-redux';
import { useUserProfile } from 'modules/utils';
import { refreshAmaxPayXeroBatchPaymentStatuses, reloadRequest } from 'pages/requestList/actions';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useToggle } from 'react-use';
import { useGetAmaxPayXeroRequestDetails, useRefreshAmaxPayXero } from 'shared/data/webApp/v1';
import { useGetBatchPaymentsRequestAmounts } from 'shared/data/webApp/v2';
import { useCompanyBetaFeatures } from 'shared/hooks';

import { useBankAccountData } from '../../../../xero/XeroAmaxPayBatchPaymentRequestCard/XeroAmaxPayBatchPaymentRequestCard.hooks';
import { PaymentCanceledPopup } from './components/PaymentCanceledPopup/PaymentCanceledPopup';
import { PaymentPopupContent } from './components/PaymentPopupContent/PaymentPopupContent';
import { PaymentSummaryPopupContent } from './components/PaymentSummaryPopupContent/PaymentSummaryPopupContent';
import { PaymentSummaryPopupContentLoading } from './components/PaymentSummaryPopupContent/PaymentSummaryPopupContent.loading';
import { SchedulePaymentPopup } from './components/SchedulePaymentPopup/SchedulePaymentPopup';
import { usePayForm } from './PayWithAmax.hooks';
import { messages } from './PayWithAmax.messages';
import { PaymentData, PayWithAmaxProps } from './PayWithAmax.types';

export const PayWithAmax = memo<PayWithAmaxProps>((props) => {
    const { request } = props;

    const { companyId, id: requestId, displayName, details, payers, statusV2 } = request;

    const [showPaymentSummary, setShowPaymentSummary] = hooks.useQueryParam('showPaymentSummary');
    // we have to use internal state to control popup, because of the SplitView which creates a second instance
    // of this component and since we use global URL to control popup, two popups open which leads to overlays
    // conflict
    const [open, setOpen] = useState(showPaymentSummary === 'true');

    const [paymentData, setPaymentData] = useState<PaymentData | null>(null);

    const [isPaymentCanceledPopupOpened, toggleIsPaymentCanceledPopupOpened] = useToggle(false);
    const [isSchedulePaymentPopupOpened, toggleIsSchedulePaymentPopupOpened] = useToggle(false);

    const { data: amaxPayXeroRequestDetails } = useGetAmaxPayXeroRequestDetails(
        {
            path: {
                companyId,
                requestId,
            },
        },
        {
            staleTime: 30 * 1000,
        }
    );

    const { mutate: refreshAmaxPayXero, isLoading: isLoadingRefreshAmaxPayXero } = useRefreshAmaxPayXero();

    const company = useSelector((state) => selectors.company.findCompanyById(state, companyId));

    const dispatch = useDispatch();

    const { isBatchPaymentAmountsV2 } = useCompanyBetaFeatures(request.companyId);

    const { data: batchPaymentsRequestAmounts, isFetching: isFetchingGetBatchPaymentsRequestAmounts } =
        useGetBatchPaymentsRequestAmounts(
            {
                path: {
                    companyId: request.companyId,
                    requestId: request.id,
                },
            },
            {
                enabled: isBatchPaymentAmountsV2,
            }
        );

    useEffect(() => {
        if (open) {
            refreshAmaxPayXero(
                {
                    params: {
                        path: { companyId, requestId },
                    },
                },
                {
                    onSuccess: (data) => {
                        const { data: refreshData } = data;

                        dispatch(
                            refreshAmaxPayXeroBatchPaymentStatuses(
                                requestId,
                                refreshData.batchPaymentStatus,
                                refreshData.billPaymentStatuses
                            )
                        );
                        // This covers the case the user located on `myReadyToPay` page and pay from there
                        // After redirecting back, the BE return the same `ready-to-pay` status although request
                        // already paid and after updating it via `refreshAmaxPayXeroBatchPaymentStatuses` in a
                        // line above, we also need to reload the list of all filtered requests to exclude this
                        // one from it and automatically switch to sibling
                        dispatch(reloadRequest(requestId, companyId));
                    },
                }
            );
        }
    }, [open, companyId, requestId, refreshAmaxPayXero, dispatch]);

    const profile = useUserProfile();

    const isApproved = statusV2 === domain.RequestStatusV2.Approved;

    const hasPayableBill = useMemo(() => {
        return details.items.some(
            (item) =>
                item.paymentStatus === domain.AmaxPayPaymentStatus.ReadyToPay ||
                item.paymentStatus === domain.AmaxPayPaymentStatus.Failed
        );
    }, [details.items]);

    const isPayer = useMemo(() => payers.some((payer) => payer.userEmail === profile.email), [payers, profile]);

    const { confirmation } = useConfirmation({
        confirmMessage: messages.areYouSure,
        confirmButtonMessage: messages.yesStop,
    });

    const openShowPaymentSummary = useCallback(() => {
        setOpen(true);
        setShowPaymentSummary('true');
    }, [setShowPaymentSummary]);

    const handleToggleShowPaymentSummary = useCallback<ExtractComponentProp<typeof Popup, 'onToggle'>>(
        (open: boolean) => {
            setOpen(open);
            setShowPaymentSummary(open ? 'true' : 'false');
        },
        [setShowPaymentSummary]
    );

    const handleTogglePaymentPopup = useCallback<ExtractComponentProp<typeof Popup, 'onToggle'>>(
        (open) => {
            if (!open) {
                void confirmation()
                    .then(() => {
                        setPaymentData(null);
                        openShowPaymentSummary();
                    })
                    .catch(() => {});
            }
        },
        [setPaymentData, confirmation, openShowPaymentSummary]
    );

    const handleToggleSchedulePaymentPopup = useCallback<ExtractComponentProp<typeof Popup, 'onToggle'>>(
        (open) => {
            if (!open) {
                toggleIsSchedulePaymentPopupOpened();
                setOpen(true);
            }
        },
        [toggleIsSchedulePaymentPopupOpened]
    );

    const handleOpenPaymentCanceledPopup = useCallback(() => {
        setPaymentData(null);

        toggleIsPaymentCanceledPopupOpened(true);
    }, [toggleIsPaymentCanceledPopupOpened]);

    const handleClosePaymentCanceledPopup = useCallback(() => {
        toggleIsPaymentCanceledPopupOpened(false);

        openShowPaymentSummary();
    }, [toggleIsPaymentCanceledPopupOpened, openShowPaymentSummary]);

    const handleOpenSchedulePaymentPopup = useCallback(() => {
        toggleIsSchedulePaymentPopupOpened();
        setOpen(false);
    }, [toggleIsSchedulePaymentPopupOpened]);

    const bankAccountData = useBankAccountData(details.items, amaxPayXeroRequestDetails);

    const isBankAccountDisconnected = !bankAccountData?.isConnected;

    const { form, onSubmit, isPayBillLoading } = usePayForm({
        request,
        setPaymentData,
        handleToggleShowPaymentSummary,
        toggleIsSchedulePaymentPopupOpened,
        bankAccountBankName: bankAccountData?.bank.name ?? '',
        isBatchPaymentAmountsV2,
        amounts: batchPaymentsRequestAmounts?.items ?? [],
    });

    const hideButton = !isApproved || !hasPayableBill || !isPayer;

    useEffect(() => {
        if (hideButton) {
            handleToggleShowPaymentSummary(false);
        }
    }, [hideButton, handleToggleShowPaymentSummary]);

    if (hideButton) {
        return null;
    }

    return (
        <>
            <Button
                execute={openShowPaymentSummary}
                title={isBankAccountDisconnected ? messages.disabledTooltip : messages.pay}
                disabled={isBankAccountDisconnected || company?.isReadonly}
            >
                {messages.pay}
            </Button>

            <Form form={form} onSubmit={onSubmit}>
                <Popup open={open} size='large' onToggle={handleToggleShowPaymentSummary}>
                    {isLoadingRefreshAmaxPayXero ? (
                        <PaymentSummaryPopupContentLoading requestDisplayName={displayName} />
                    ) : (
                        <PaymentSummaryPopupContent
                            request={request}
                            isPayBillLoading={isPayBillLoading}
                            onPay={onSubmit}
                            showSchedulePaymentPopup={handleOpenSchedulePaymentPopup}
                            isBatchPaymentAmountsV2={isBatchPaymentAmountsV2}
                            isBatchPaymentAmountsLoading={isFetchingGetBatchPaymentsRequestAmounts}
                            batchPaymentAmounts={batchPaymentsRequestAmounts?.items ?? []}
                        />
                    )}
                </Popup>

                <Popup open={isSchedulePaymentPopupOpened} size='small' onToggle={handleToggleSchedulePaymentPopup}>
                    <SchedulePaymentPopup isLoading={isPayBillLoading} onPay={onSubmit} />
                </Popup>
            </Form>

            <Popup open={paymentData !== null} size='small' onToggle={handleTogglePaymentPopup}>
                <PaymentPopupContent
                    companyId={companyId}
                    requestId={requestId}
                    paymentData={paymentData}
                    showPaymentCanceledPopup={handleOpenPaymentCanceledPopup}
                />
            </Popup>

            <Popup open={isPaymentCanceledPopupOpened} size='xsmall' onToggle={toggleIsPaymentCanceledPopupOpened}>
                <PaymentCanceledPopup onClose={handleClosePaymentCanceledPopup} />
            </Popup>
        </>
    );
});

PayWithAmax.displayName = 'PayWithAmax';
