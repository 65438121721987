import { atomHelpers } from '@approvalmax/utils';
import { atom } from 'jotai';
import { setChameleonOrgId } from 'modules/utils/helpers/chameleon';

import { routeParamsState, routeState } from './routerParams';

const storageKey = 'activeCompanyId';

const activeCompanyIdWithLocalStoreAtom = atomHelpers.createAtomWithStorage<string>(
    'activeCompanyIdWithLocalStoreState',
    ''
);

const activeCompanyIdWithLocalStoreState = atom(
    (get) => get(activeCompanyIdWithLocalStoreAtom),
    (get, set, orgId: string) => {
        setChameleonOrgId(orgId);
        set(activeCompanyIdWithLocalStoreAtom, orgId);
    }
);

export const activeCompanyIdState = atom(
    (get) => {
        const { pathname } = get(routeState);
        const { companyId } = get<{ companyId?: string }>(routeParamsState);

        const regExp = RegExp(`^/${companyId}`);
        const isRootCompanyId = regExp.test(pathname || '');

        if (isRootCompanyId && companyId) {
            localStorage.setItem(storageKey, JSON.stringify(companyId));

            return companyId;
        }

        return get(activeCompanyIdWithLocalStoreState);
    },
    (get, set, newValue) => {
        set(activeCompanyIdWithLocalStoreState, newValue);
    }
);
