import { toast } from '@approvalmax/ui/src/components';
import { routingService } from 'services/routing';
import { getDefaultPath } from 'urlBuilder';

import { messages } from './redirectAndNotify.messages';

export const redirectAndNotify = (options?: { pathToRedirect?: string; errorMessage?: string }) => {
    toast.error(options?.errorMessage || messages.pageError);

    routingService.push(options?.pathToRedirect || getDefaultPath());
};
