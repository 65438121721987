import { FC, memo } from 'react';

import { ChevronLeftIcon, ChevronRightIcon } from '../../../../icons';
import { Button } from '../../../Button/Button';
import { Grid } from '../../../Grid/Grid';
import { TransferButtonsProps } from './TransferButtons.types';

export const TransferButtons: FC<TransferButtonsProps> = memo((props) => {
    const { toLeftDisabled, toRightDisabled, onToLeftClick, onToRightClick } = props;

    return (
        <Grid justifyContent='center' alignContent='center' gap={8}>
            <Button size='small' color='blue80' disabled={toRightDisabled} onClick={onToRightClick}>
                <ChevronRightIcon size={12} />
            </Button>

            <Button size='small' color='blue80' disabled={toLeftDisabled} onClick={onToLeftClick}>
                <ChevronLeftIcon size={12} />
            </Button>
        </Grid>
    );
});

TransferButtons.displayName = 'TransferButtons';
