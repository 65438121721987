import { Popup, useConfirmation } from '@approvalmax/ui/src/components';
import { useAtomValue } from 'jotai';
import isEqual from 'lodash/isEqual';
import { useDispatch, useSelector } from 'modules/react-redux';
import { memo, useCallback } from 'react';

import { discardSettingsPopup } from '../../actions';
import { getActiveSettings } from '../../selectors/pageSelectors';
import { WorkflowSettingsPopupContent } from '../WorkflowSettingsPopupContent/WorkflowSettingsPopupContent';
import { workflowSettingsPopupActiveSettings } from '../WorkflowSettingsPopupContent/WorkflowSettingsPopupContent.states';
import { confirmationPopupProps } from './WorkflowSettingsPopup.constants';

export const WorkflowSettingsPopup = memo(() => {
    const dispatch = useDispatch();
    const { confirmation } = useConfirmation(confirmationPopupProps);
    const savedSettings = useSelector(getActiveSettings);
    const activeWorkflowSettings = useAtomValue(workflowSettingsPopupActiveSettings);

    const closeSettingsPopup = useCallback(() => {
        const isActiveWorkflowUnchanged = isEqual(activeWorkflowSettings, savedSettings?.data);

        if (isActiveWorkflowUnchanged) {
            dispatch(discardSettingsPopup());

            return;
        }

        confirmation()
            .then(() => dispatch(discardSettingsPopup()))
            .catch(() => {});
    }, [activeWorkflowSettings, confirmation, dispatch, savedSettings]);

    return (
        <Popup
            open={Boolean(savedSettings)}
            onClose={closeSettingsPopup}
            size='large'
            preventAutoClose
            fullScreen
            overflowY='hidden'
        >
            <WorkflowSettingsPopupContent />
        </Popup>
    );
});

WorkflowSettingsPopup.displayName = 'WorkflowSettingsPopup';
