import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('modules.ProfileModule.PendingPracticeInvitationPopup', {
    followingBusinessAccountsText: "You've been invited to the following Business Accounts:",
    whatHappenedAfterAcceptingText: `
        After accepting the invitation you’ll have access to the Business Account.
        Your profile data will be visible to the Account Owner and the Managers.`,
    accept: 'ACCEPT',
    declineAllInvitations: 'Decline all invitations',
    alreadyAddedToAccount: `
        You have already been added to the {practiceName}.
        To accept one of the following Business Account invitations, please leave {practiceName}.`,
    alreadyAddedToAccountWithoutPracticeName: `
        You have already been added to the Business Account.
        To accept one of the following Business Account invitations, please leave your current Business Account.`,
    acceptTitle: 'Pending invitations to Business Accounts',
    acceptBtnTitle: 'ACCEPT',
});
