import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('pages.RequestList.XeroBudgetingSection', {
    account: 'Account',
    amount: 'Amount ({ currency })',
    budgetName: 'Budget name',
    budgetPeriod: 'Budget period',
    description: 'The following budgets will be affected by the approval of this request:',
    fullPeriod: 'Full period',
    remainingBudget: 'Remaining budget ({ currency })',
    status: 'Status',
    ytd: ' (YTD)',
});
