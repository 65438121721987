import { Text } from '@approvalmax/ui/src/components';
import { useSelector } from 'modules/react-redux';
import { memo, useState } from 'react';

import { getActiveTemplate } from '../../selectors/pageSelectors';
import FixedStepContainer from '../FixedStepContainer/FixedStepContainer';
import { StepSubmitterSection } from '../StepSubmitterSection/StepSubmitterSection';
import { messages } from './XeroQuoteStartStep.messages';

export const XeroQuoteStartStep = memo(() => {
    const [collapsed, setCollapsed] = useState<boolean>();
    const template = useSelector(getActiveTemplate)!;
    const pluralTemplateName = template.displayNamePlural;

    return (
        <FixedStepContainer bubbleText={messages.bubbleText({ templateName: template.displayName })}>
            <Text font='body' fontSize='small' color='midnight70'>
                {messages.title({ pluralTemplateName })}
            </Text>

            <StepSubmitterSection
                headerText={messages.createInApprovalMax}
                cacheId='XeroQuoteStartStepSubmitter'
                description={messages.description({ pluralTemplateName })}
                required={false}
                collapsed={collapsed}
                onCollapse={setCollapsed}
            />
        </FixedStepContainer>
    );
});

XeroQuoteStartStep.displayName = 'XeroQuoteStartStep';
