import { PolymorphicProps } from '@approvalmax/types';
import { componentHelpers, domHelpers, hooks, miscHelpers } from '@approvalmax/utils';
import { type ElementType, KeyboardEvent, Ref, useCallback, useEffect, useId, useRef } from 'react';

import { CloseCircleFilledIcon, LockIcon } from '../../icons';
import { Button } from '../Button/Button';
import { FocusEmitter } from '../FocusEmitter/FocusEmitter';
import Label from '../Label/Label';
import { Controller } from './components';
import { textFieldClearButtonName, textFieldQa } from './TextField.constants';
import { defaultTransform, getRightPadding } from './TextField.helpers';
import { useClearable, useFocus, useResizeHeight, useValidate, useValue } from './TextField.hooks';
import { Clear, Control, DisabledCover, EndIcon, Hint, Input, Root, StartIcon, Textarea } from './TextField.styles';
import { HTMLTextFieldElement, TextFieldProps } from './TextField.types';

/**
 * Text Fields let users enter and edit text.
 */
export const TextField = <Component extends ElementType = 'input'>(
    props: PolymorphicProps<Component, TextFieldProps>
) => {
    const {
        id: idProp,
        onChange,
        onClear,
        onClick,
        bordered = true,
        size = 'medium',
        clearable = true,
        startIcon,
        endIcon,
        invalid,
        focus,
        initFocus,
        onFocus,
        onBlur,
        autoComplete = 'off',
        value,
        multiline,
        required,
        disabled,
        label,
        hint,
        minLength,
        onMouseEnter,
        onMouseLeave,
        width = '100%',
        height,
        fullHeight,
        onEnter,
        onKeyDown,
        transform = defaultTransform,
        className,
        autoHeight,
        maxHeight = multiline && autoHeight ? '400px' : undefined,
        minHeight,
        cursor = 'text',
        qa,
        controlled,
        spacing,
        changeOnBlur,
        inputRef,
        rootRef,
        ...restProps
    } = props;

    const fieldRef = useRef<HTMLTextFieldElement>(null);
    const composedRefs = hooks.useComposedRefs(inputRef, fieldRef);
    const generatedId = useId();
    const id = idProp || generatedId;
    const hintId = `${id}-hint`;

    const { handleChange, fieldValue } = useValue({ value, onChange, transform, controlled, changeOnBlur });
    const { handleClear } = useClearable({ handleChange, fieldRef, onClear });
    const { handleBlur, handleFocus, isFocus } = useFocus({
        fieldRef,
        onFocus,
        onBlur,
        onChange,
        fieldValue,
        changeOnBlur,
        focus,
        initFocus,
    });
    const { invalidValue } = useValidate({ invalid, minLength, fieldValue, isFocus });

    const fieldProps = {
        ...restProps,
        value: fieldValue,
        onChange: handleChange,
        onBlur: handleBlur,
        onFocus: handleFocus,
        autoComplete,
        disabled,
        pattern: undefined,
        validate: undefined,
        deps: undefined,
    };

    const handleKeyDown = useCallback(
        (e: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            onKeyDown?.(e);

            if (e.key === 'Enter') {
                onEnter?.(e);
            }
        },
        [onKeyDown, onEnter]
    );

    const resizeHeight = useResizeHeight(fieldRef);

    useEffect(() => {
        if (multiline && autoHeight) {
            resizeHeight();
        }
    }, [multiline, autoHeight, resizeHeight, fieldValue]);

    return (
        <FocusEmitter>
            {({ ref: emitterRef }) => (
                <Root
                    $width={width}
                    $fullHeight={fullHeight}
                    data-qa={domHelpers.generateDataQa(qa, textFieldQa)}
                    ref={rootRef}
                >
                    {label && (
                        <Label
                            htmlFor={id}
                            size={size === 'xsmall' ? size : 'small'}
                            required={required}
                            spacing={bordered ? undefined : '0'}
                        >
                            {label}
                        </Label>
                    )}

                    <Control
                        className={className}
                        onClick={onClick}
                        onMouseEnter={onMouseEnter}
                        onMouseLeave={onMouseLeave}
                        $invalid={invalidValue}
                        $bordered={bordered}
                        $size={size}
                        $focus={isFocus}
                        $disabled={disabled}
                        $multiline={multiline}
                        $cursor={cursor}
                        $spacing={miscHelpers.spacingPropToCss(spacing)}
                        $rightPadding={getRightPadding(spacing)}
                        $hasValue={Boolean(fieldValue)}
                    >
                        {startIcon && <StartIcon>{startIcon}</StartIcon>}

                        {multiline ? (
                            <Textarea
                                data-qa={domHelpers.generateDataQa(qa, `${textFieldQa}-textarea`)}
                                {...fieldProps}
                                id={id}
                                aria-describedby={hint && hintId}
                                $height={height}
                                $maxHeight={maxHeight}
                                $minHeight={minHeight}
                                onKeyDown={handleKeyDown}
                                ref={componentHelpers.mergeRefs(composedRefs, emitterRef) as Ref<HTMLTextAreaElement>}
                            />
                        ) : (
                            <Input
                                data-qa={domHelpers.generateDataQa(qa, `${textFieldQa}-input`)}
                                {...fieldProps}
                                id={id}
                                aria-describedby={hint && hintId}
                                onKeyDown={handleKeyDown}
                                ref={componentHelpers.mergeRefs(composedRefs, emitterRef) as Ref<HTMLInputElement>}
                                $hasValue={Boolean(fieldValue)}
                            />
                        )}

                        {!disabled && !multiline && clearable && (
                            <Clear>
                                <Button
                                    icon
                                    noPadding
                                    tabIndex={-1}
                                    name={textFieldClearButtonName}
                                    data-qa={domHelpers.generateDataQa(qa, `${textFieldQa}-clear-button`)}
                                    onClick={handleClear}
                                >
                                    <CloseCircleFilledIcon color='midnight60' />
                                </Button>
                            </Clear>
                        )}

                        {(endIcon || disabled) && <EndIcon>{disabled ? <LockIcon /> : endIcon}</EndIcon>}

                        {disabled && <DisabledCover disabled />}
                    </Control>

                    {hint && (
                        <Hint id={hintId} $invalid={invalidValue}>
                            {hint}
                        </Hint>
                    )}
                </Root>
            )}
        </FocusEmitter>
    );
};

TextField.displayName = 'TextField';
TextField.Controller = Controller;

export default TextField;
