import { openSplitViewState } from '@approvalmax/ui/src/components';
import { browserHelpers } from '@approvalmax/utils';
import { useAtomValue } from 'jotai';
import { memo } from 'react';

import { AddonsWarningBanner } from '../AddonsWarningBanner/AddonsWarningBanner';
import Announcement from '../Announcement/Announcement';
import LicensingNotification from '../LicensingNotification/LicensingNotification';

const Notifications = memo(() => {
    const openSplitView = useAtomValue(openSplitViewState);

    if (browserHelpers.isMobile() || openSplitView) return null;

    return (
        <div>
            <Announcement />

            <AddonsWarningBanner />

            <LicensingNotification />
        </div>
    );
});

export default Notifications;
