import { selectors } from 'modules/common';
import { PriceOption } from 'modules/company/selectors/moduleSelectors';
import { domain } from 'modules/data';
import { notUpgradableCompanyPlans } from 'shared/constants';

import { AvailableBillingSystems } from './Content.constants';

const getPriceOptionForSubscription = (
    subscription: domain.Subscription,
    pricingOptions: PriceOption[],
    qty: number
) => {
    if (!subscription) {
        return null;
    }

    const desiredPlan =
        subscription.plan === domain.CompanyPlan.Professional ? domain.CompanyPlan.XeroPremium : subscription.plan;

    const pricingOption = pricingOptions.find(
        (option) =>
            option.edition === subscription?.edition &&
            option.cycle === subscription?.billingCycle &&
            option.plan === desiredPlan &&
            option.currency === (subscription.nextBillingCurrency ?? domain.NextBillingCurrency.USD)
    );

    if (pricingOption) {
        const subscriptionPrice = pricingOption.ranges.find(
            (range) => range.minOrganisations <= qty && range.maxOrganisations > qty
        );

        if (subscriptionPrice) {
            return {
                price: subscriptionPrice.pricePerOrganisation,
                currency: pricingOption.currency,
            };
        }
    }

    return null;
};

export const getSubscriptionOptionsAfterUpgrade = (
    subscription: domain.Subscription | null,
    pricingOptions: PriceOption[]
) => {
    if (!subscription) {
        return null;
    }

    const qty = subscription.quantity + 1;
    const priceOption = getPriceOptionForSubscription(subscription, pricingOptions, qty);

    if (priceOption) {
        return { qty, price: priceOption.price, currency: priceOption.currency };
    }

    return null;
};

export const isAvailableForUpgrade = (subscription: domain.Subscription) => {
    return subscription.capacity === 0;
};

// TODO: [AM-23986] remove this filtration:
export const fitlerPredicateAvailableForUpgrade = (subscription: selectors.types.ExpandedSubscription) => {
    if (subscription.isFrozenForUpgrade && subscription.capacity === 0) {
        return false;
    }

    if (notUpgradableCompanyPlans.includes(subscription.plan) && subscription.capacity === 0) {
        return false;
    }

    return subscription.capacity > 0 || AvailableBillingSystems.includes(subscription.billingSystem);
};
