import { Field } from '@approvalmax/ui';
import { Button, Grid, mentionHelpers, Popup, RichEditor, Text } from '@approvalmax/ui/src/components';
import { useSetAtom } from 'jotai';
import { selectors } from 'modules/common';
import { useSelector } from 'modules/react-redux';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useCancelAmaxPayBill } from 'shared/data/webApp/v1';
import { useCompanyMentionItems } from 'shared/hooks/useCompanyMentionItems';

import { addAsWatcherPopupState } from '../../../../../components/AddAsWatchersPopup/AddAsWatchersPopup.states';
import { getMentionedUsersWithoutAccessToRequest } from '../../../../../utils/getUsersWithoutAccessToRequest';
import { messages } from './CancelPopupContent.messages';
import { RichEditorWrapper } from './CancelPopupContent.styles';
import { CancelPopupContentProps } from './CancelPopupContent.types';

export const CancelPopupContent = memo<CancelPopupContentProps>((props) => {
    const { companyId, requestId, billRequestIdList, onBillCancel } = props;

    const [commentText, setCommentText] = useState('');
    const setWatchersPopup = useSetAtom(addAsWatcherPopupState);

    const company = useSelector((state) => selectors.company.getCompanyById(state, companyId));
    const request = useSelector((state) => selectors.request.getRequestById(state, requestId));
    const { handleGetMentionItems } = useCompanyMentionItems(company);
    const { mentionedUserIds } = useMemo(() => mentionHelpers.prepareCommentPayload(commentText), [commentText]);

    const { mutate: cancelAmaxPayBill, isLoading: isLoadingCancelAmaxPayBill } = useCancelAmaxPayBill();

    const handleCancel = useCallback(() => {
        cancelAmaxPayBill(
            {
                body: {
                    commentText,
                    mentionedUserIds,
                    billInvoiceRequestIds: billRequestIdList,
                },
                params: { path: { companyId, requestId } },
            },
            {
                onSuccess: () => {
                    onBillCancel();
                },
            }
        );
    }, [cancelAmaxPayBill, mentionedUserIds, commentText, billRequestIdList, companyId, requestId, onBillCancel]);

    const handleSubmit = useCallback(() => {
        const usersWithoutAccess = getMentionedUsersWithoutAccessToRequest(request, mentionedUserIds);

        if (usersWithoutAccess.length) {
            setWatchersPopup((state) => ({ ...state, usersWithoutAccess }));
        } else {
            handleCancel();
        }
    }, [request, mentionedUserIds, setWatchersPopup, handleCancel]);

    useEffect(() => {
        setWatchersPopup((state) => ({ ...state, onSubmit: handleCancel }));
    }, [handleCancel, setWatchersPopup]);

    return (
        <>
            <Popup.Header progress={isLoadingCancelAmaxPayBill} />

            <Popup.Body spacing='16 32 32 32'>
                <Grid rowGap={24}>
                    <Text font='headline' fontSize='small' fontWeight='medium'>
                        {messages.title}
                    </Text>

                    <Text font='body' fontSize='medium'>
                        {messages.description}
                    </Text>

                    <RichEditorWrapper>
                        <Field title={messages.comment} required>
                            <RichEditor
                                allowMention
                                mentionItems={handleGetMentionItems}
                                allowTextFormatting={false}
                                placeholder={messages.commentPlaceholder}
                                maxLength={1000}
                                minHeight={200}
                                onChange={setCommentText}
                            />
                        </Field>
                    </RichEditorWrapper>

                    <Button
                        color='red40'
                        size='large'
                        block
                        disabled={!commentText || isLoadingCancelAmaxPayBill}
                        onClick={handleSubmit}
                    >
                        {messages.confirm}
                    </Button>
                </Grid>
            </Popup.Body>
        </>
    );
});

CancelPopupContent.displayName = 'CancelPopupContent';
