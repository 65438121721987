import { CloseIcon } from '@approvalmax/ui';
import { Box, Button, Flex, Link, Table, Text } from '@approvalmax/ui/src/components';
import { selectors } from 'modules/common';
import { domain } from 'modules/data';
import { useUserProfile } from 'modules/utils';
import { checkAccessOnlyAsWatcher } from 'pages/requestList/selectors/requestSelectors';
import { FC, memo, useCallback, useMemo } from 'react';
import { getPath, Path } from 'urlBuilder';

import QBooksPOLineRemaining from '../QBooksPOLineRemaining/QBooksPOLineRemaining';
import { getColumnDefinitions } from './QBooksMatchingInfoContent.config';
import { messages } from './QBooksMatchingInfoContent.messages';
import { QBooksMatchingInfoContentProps, QBooksMatchingLineItem } from './QBooksMatchingInfoContent.types';

export const QBooksMatchingInfoContent: FC<QBooksMatchingInfoContentProps> = memo((props) => {
    const {
        request,
        matchingInfo,
        lineItemId,
        onDropdownClose,
        onRemoveMatching,
        isRemoveMatchingLoading,
        isAccountLineItem,
    } = props;

    const isBill = request.integrationCode === domain.IntegrationCode.QBooksBill;

    const profile = useUserProfile();
    const me = useMemo(
        () => request.company.allMembers.find((user) => user.id === profile?.id),
        [profile?.id, request.company.allMembers]
    );

    const hasAccessOnlyAsWatcher = useMemo(
        () => !!me && checkAccessOnlyAsWatcher(me.databaseId || '', me.userEmail, request.company, request),
        [me, request]
    );

    const matchingCommands = useMemo(
        () => selectors.request.getQBooksMatchingCommands(request, profile.id, hasAccessOnlyAsWatcher),
        [hasAccessOnlyAsWatcher, request, profile.id]
    );

    const [initialMatchingInfo] = matchingInfo;

    const handleMatchingRemove = useCallback(async () => {
        await onRemoveMatching(lineItemId);
        onDropdownClose();
    }, [lineItemId, onDropdownClose, onRemoveMatching]);

    const columnDefinitions = useMemo(
        () =>
            getColumnDefinitions({
                request,
                handleMatchingRemove: matchingCommands.removeMatching.hidden ? null : handleMatchingRemove,
                matchingRemoveButtonDisabled: !!isRemoveMatchingLoading,
                isAccountLineItem,
            }),
        [
            handleMatchingRemove,
            isAccountLineItem,
            isRemoveMatchingLoading,
            matchingCommands.removeMatching.hidden,
            request,
        ]
    );

    const extendedLineItems = useMemo(
        () =>
            matchingInfo.reduce<QBooksMatchingLineItem[]>(
                (accum, { name, date, lineItem, accountLineItem, amount, id }) => {
                    if (accountLineItem) {
                        return [
                            ...accum,
                            {
                                ...accountLineItem,
                                lineItemId: id,
                                name,
                                date,
                                totalAmount: amount,
                            },
                        ];
                    }

                    if (lineItem) {
                        return [
                            ...accum,
                            {
                                ...lineItem,
                                lineItemId: id,
                                name,
                                date,
                                totalAmount: amount,
                            },
                        ];
                    }

                    return accum;
                },
                []
            ),
        [matchingInfo]
    );

    return (
        <Box spacing='24'>
            <Flex justifyContent='end'>
                <Button icon noPadding onClick={onDropdownClose} variant='text' color='blue100'>
                    <CloseIcon size={16} color='midnight80' />
                </Button>
            </Flex>

            <Flex direction='column' spacing='16'>
                <Table
                    hoverable={false}
                    headerColor='transparent'
                    columns={columnDefinitions}
                    data={extendedLineItems}
                />

                {isBill ? (
                    <Flex>
                        <Link
                            font='label'
                            fontSize='small'
                            fontWeight='medium'
                            href={getPath(Path.request, initialMatchingInfo.id, request.companyId)}
                            external
                        >
                            {initialMatchingInfo.name}
                        </Link>

                        <Flex spacing='4'>
                            <Text font='label' fontSize='small'>
                                {messages.date}
                            </Text>

                            <Text font='label' fontSize='small' fontWeight='medium'>
                                {initialMatchingInfo.date}
                            </Text>
                        </Flex>

                        <Flex spacing='4'>
                            <Text font='label' fontSize='small'>
                                {messages.amount}
                            </Text>

                            <Text font='label' fontSize='small' fontWeight='medium'>
                                {initialMatchingInfo.amount}
                            </Text>
                        </Flex>
                    </Flex>
                ) : (
                    <QBooksPOLineRemaining
                        isAccountLineItem={isAccountLineItem}
                        matchingInfo={matchingInfo}
                        lineItemId={lineItemId}
                        request={request}
                    />
                )}
            </Flex>
        </Box>
    );
});

QBooksMatchingInfoContent.displayName = 'QBooksMatchingInfoContent';
