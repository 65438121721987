import { Alert, Box, Button, Flex, Grid, List, TextField } from '@approvalmax/ui/src/components';
import { stringHelpers } from '@approvalmax/utils';
import { selectors } from 'modules/common';
import { State } from 'modules/data';
import { FC, memo, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { useKey, useToggle } from 'react-use';
import { useCompanyBasedOnUrl } from 'shared/hooks';

import { messages } from './AddUsersList.messages';
import { InputBox } from './AddUsersList.styles';
import { AddUsersListProps } from './AddUsersList.types';
import { maxAlertItemsCount } from './components/AddUsersList.constants';
import { EmailListItem } from './components/EmailListItem/EmailListItem';

export const AddUsersList: FC<AddUsersListProps> = memo((props) => {
    const { items, readOnlyItems, withStickyInput, noRoles, onAddEmails, onRoleChange, onDeleteEmail } = props;

    const [inputText, setInputText] = useState('');
    const [showInvalidAlert, setShowInvalidAlert] = useToggle(false);
    const [showBelongsToATeamMemberAlert, setShowBelongsToATeamMemberAlert] = useToggle(false);
    const [invalidEmails, setInvalidEmail] = useState<string[]>([]);
    const [emailsBelongToATeamMember, setEmailsBelongToATeamMember] = useState<string[]>([]);

    const company = useCompanyBasedOnUrl();
    const team = useSelector((state: State) => selectors.company.getCompanyTeam(state, company));

    const addToList = useCallback(() => {
        const emails = inputText.split(/[\s,;]+/).filter((x) => Boolean(x));

        const valid: string[] = [];
        const invalid: string[] = [];
        const emailsBelongToATeamMember: string[] = [];

        emails.forEach((text) => {
            const email = stringHelpers.parseEmail(text);

            if (email) {
                if (team.some((user) => user.id === email)) {
                    emailsBelongToATeamMember.push(email);

                    return;
                }

                if (!valid.includes(email)) {
                    valid.push(email);
                }
            } else if (!invalid.includes(text)) {
                invalid.push(text);
            }
        });

        onAddEmails(valid);
        setInvalidEmail(invalid);
        setEmailsBelongToATeamMember(emailsBelongToATeamMember);

        if (invalid.length > 0) {
            setShowInvalidAlert(true);
        }

        if (emailsBelongToATeamMember.length > 0) {
            setShowBelongsToATeamMemberAlert(true);
        }

        setInputText('');
    }, [inputText, onAddEmails, setShowBelongsToATeamMemberAlert, setShowInvalidAlert, team]);

    useKey('Enter', addToList, undefined, [addToList]);

    const onCloseAlert = useCallback(() => {
        setShowInvalidAlert(false);
        setShowBelongsToATeamMemberAlert(false);
    }, [setShowBelongsToATeamMemberAlert, setShowInvalidAlert]);

    return (
        <Grid gap={16} className='fs-mask'>
            <InputBox $sticky={withStickyInput} gridTemplateColumns='auto max-content' gap={8} width='100%'>
                <TextField
                    size='large'
                    value={inputText}
                    onChange={setInputText}
                    placeholder={messages.inputPlaceholder}
                />

                <Button color='blue10' size='large' onClick={addToList}>
                    {messages.addButtonText}
                </Button>
            </InputBox>

            {invalidEmails.length > 0 && showInvalidAlert && (
                <Flex size={24} block>
                    <Alert
                        color='midnight20'
                        invalid
                        size='small'
                        onClose={onCloseAlert}
                        closable
                        open={showInvalidAlert}
                    >
                        {messages.invalidEmailsDescription}

                        {invalidEmails.slice(0, maxAlertItemsCount).map((email) => {
                            return <div key={email}>{email}</div>;
                        })}

                        <div>
                            {invalidEmails.length > maxAlertItemsCount &&
                                messages.invalidEmailsText({ count: invalidEmails.length - 2 })}
                        </div>
                    </Alert>
                </Flex>
            )}

            {emailsBelongToATeamMember.length > 0 && showBelongsToATeamMemberAlert && (
                <Flex size={24} block>
                    <Alert
                        color='midnight20'
                        invalid
                        size='small'
                        onClose={onCloseAlert}
                        closable
                        open={showBelongsToATeamMemberAlert}
                    >
                        {messages.emailsBelongsToATeamMember}

                        {emailsBelongToATeamMember.slice(0, maxAlertItemsCount).map((email) => {
                            return (
                                <div key={email} title={emailsBelongToATeamMember.join(',')}>
                                    {email}
                                </div>
                            );
                        })}

                        <div>
                            {emailsBelongToATeamMember.length > maxAlertItemsCount &&
                                messages.invalidEmailsText({ count: emailsBelongToATeamMember.length - 2 })}
                        </div>
                    </Alert>
                </Flex>
            )}

            {((readOnlyItems && readOnlyItems.length > 0) || items.length > 0) && (
                <Flex size={24} block>
                    <Box bordered radius='small' height='100%' spacing='4'>
                        <List>
                            {(readOnlyItems || []).map(({ email, role, disabled }) => {
                                return (
                                    <EmailListItem
                                        key={email}
                                        email={email}
                                        role={role}
                                        disabled={disabled}
                                        noRole={noRoles}
                                    />
                                );
                            })}

                            {items.map(({ email, role, disabled }) => {
                                return (
                                    <EmailListItem
                                        key={email}
                                        email={email}
                                        role={role}
                                        disabled={disabled}
                                        noRole={noRoles}
                                        onRoleChange={(role) => onRoleChange?.(role, email)}
                                        deleteEmail={onDeleteEmail}
                                    />
                                );
                            })}
                        </List>
                    </Box>
                </Flex>
            )}
        </Grid>
    );
});

AddUsersList.displayName = 'AddUsersList';
