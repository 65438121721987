import { Text } from '@approvalmax/ui/src/components';
import { getCompanyById } from 'modules/common/selectors/companySelectors';
import { domain } from 'modules/data';
import { useSelector } from 'modules/react-redux';
import { isFieldVisible } from 'pages/requestForm/containers/netsuite/NetSuiteRequestForm/NetSuiteRequestForm.helpers';
import { getNetSuiteContext } from 'pages/requestForm/selectors/netsuite/netSuiteSelectors';
import { FC, memo, useEffect } from 'react';
import { isOcrEnabledForTemplate } from 'shared/helpers';
import { useNetSuiteAttachmentsFieldSettings } from 'shared/hooks';

import { OcrUploadPlaceholder } from '../OcrUploadPlaceholder/OcrUploadPlaceholder';
import { RequestUploadPlaceholder } from '../RequestUploadPlaceholder/RequestUploadPlaceholder';
import { useFilesUploading } from './UploadPlaceholder.hooks';
import { messages } from './UploadPlaceholder.messages';
import { UploadPlaceholderProps } from './UploadPlaceholder.types';

export const UploadPlaceholder: FC<UploadPlaceholderProps> = memo((props) => {
    const { request, setContainerTitle } = props;

    const company = useSelector((state) => getCompanyById(state, request.companyId));

    const netSuiteContext = useSelector((state) =>
        request.integrationType === domain.IntegrationType.NetSuite ? getNetSuiteContext(state) : null
    );
    const attachmentsFieldSettings = useNetSuiteAttachmentsFieldSettings(netSuiteContext?.documentFields);
    const readOnly = Boolean(netSuiteContext && !isFieldVisible(attachmentsFieldSettings.attachments));

    const {
        onDrop,
        isLoadingNewAttachments,
        isLoadingNewOcrAttachments,
        isLoadingNewSupplierAttachments,
        filterFiles,
        setFiles,
        files,
    } = useFilesUploading(request);

    useEffect(() => {
        if (request.integrationCode && isOcrEnabledForTemplate(company, request.integrationCode)) {
            setContainerTitle(messages.uploadDisplayOcrTitle);
        } else {
            setContainerTitle(messages.uploadDisplayDefaultTitle);
        }
    }, [company, company.betaFeatures, company.licenseFeatures, request.integrationCode, setContainerTitle]);

    const showOcrUploadPlaceholder =
        request.integrationCode &&
        isOcrEnabledForTemplate(company, request.integrationCode) &&
        request.statusV2 === domain.RequestStatusV2.Draft;

    return (
        <>
            {(isLoadingNewAttachments || isLoadingNewSupplierAttachments || isLoadingNewOcrAttachments) && (
                <Text textAlign='center' spacing='16 0' fontSize='small' fontWeight='regular' font='headline'>
                    {messages.title}
                </Text>
            )}

            {showOcrUploadPlaceholder ? (
                <OcrUploadPlaceholder
                    request={request}
                    onDrop={onDrop}
                    isLoadingNewAttachments={isLoadingNewAttachments}
                    isLoadingNewOcrAttachments={isLoadingNewOcrAttachments}
                    filterFiles={filterFiles}
                    files={files}
                    setFiles={setFiles}
                    readOnly={readOnly}
                />
            ) : (
                <RequestUploadPlaceholder
                    request={request}
                    onDrop={onDrop}
                    files={files}
                    setFiles={setFiles}
                    isLoadingNewAttachments={isLoadingNewAttachments}
                    isLoadingNewSupplierAttachments={isLoadingNewSupplierAttachments}
                    filterFiles={filterFiles}
                    readOnly={readOnly}
                />
            )}
        </>
    );
});

UploadPlaceholder.displayName = 'UploadPlaceholder';
