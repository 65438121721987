import { selectors } from 'modules/common';
import { backend, schemas, stateTree } from 'modules/data';
import { useSelector } from 'modules/react-redux';
import { normalize } from 'normalizr';
import { getXeroBillMatchingData } from 'pages/requestList/utils/helpers';
import { useMemo } from 'react';
import { formatResponse } from 'services/api/methods/XeroMatchingApi';
import { useGetBillLinkedToBatchPayment } from 'shared/data/webApp/v1';
import { useGetBatchPaymentAmaxPayXeroBill, useGetBatchPaymentXeroBill } from 'shared/data/webApp/v2';

export const useNormalizedLinkedBillResponse = (
    billId: string,
    {
        data,
    }: ReturnType<
        | typeof useGetBatchPaymentXeroBill
        | typeof useGetBillLinkedToBatchPayment
        | typeof useGetBatchPaymentAmaxPayXeroBill
    >
) => {
    const entities = useMemo(
        () =>
            data
                ? normalize<backend.RequestAnswer, stateTree.Entities>(data.data?.Request, schemas.requestSchema)
                      .entities
                : null,
        [data]
    );

    const request = entities ? entities.requests[billId] : null;

    const expandedRequest = useSelector((state) => (request ? selectors.request.expandRequest(state, request) : null));

    const matchingData = useMemo(() => {
        const matchingInfo = data?.data?.MatchingInfo
            ? formatResponse({
                  data: data.data?.MatchingInfo,
              }).data
            : null;

        return matchingInfo ? getXeroBillMatchingData(matchingInfo) : undefined;
    }, [data]);

    const requestDetails = useMemo(
        () =>
            data
                ? {
                      possibleDuplicates: data.data?.PossibleDuplicates?.map(schemas.request.mapPossibleDuplicateItem),
                      counterparty: data.data?.Counterparty,
                  }
                : undefined,
        [data]
    );

    return {
        request: expandedRequest,
        requestDetails,
        matchingData,
    };
};
