import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('modules.ProfileModule.ContactSalesPopup', {
    doneButtonText: 'Send',
    phoneTitle: 'Phone number',
    descriptionTitle: 'Description',
    popupTitle: 'Contact Sales',
    doneButtonTitle: 'Send the sales request',
    validationErrorRequired: 'Please fill in the required fields',
    createTicketSuccess: 'Your request has been submitted, our sales representative will contact you shortly.',
    createTicketFail: 'Oops... something went wrong',
    phonePlaceholder: 'Enter phone number...',
    descriptionPlaceholder: 'Please submit your request to a sales representative',
});
