import { RequestMutationOptions } from '@approvalmax/data';
import { useMutate } from 'shared/data';

import { requestsApiPaths } from '../paths';
import { useInvalidateGetQuoteForCustomerDecision } from '../useGetQuoteForCustomerDecision/useGetQuoteForCustomerDecision';
import { SetQuoteDecisionByCustomer } from './useSetQuoteDecisionByCustomer.types';

export const useSetQuoteDecisionByCustomer = (mutationOptions?: RequestMutationOptions<SetQuoteDecisionByCustomer>) => {
    const invalidateGetQuoteForCustomerDecision = useInvalidateGetQuoteForCustomerDecision();

    return useMutate(requestsApiPaths.setQuoteDecisionByCustomer, {
        mutationOptions: {
            ...mutationOptions,
            onSettled: (data, error, requestData, context) => {
                invalidateGetQuoteForCustomerDecision({
                    query: {
                        companyId: requestData.body?.companyId,
                        requestId: requestData.body?.requestId,
                        code: requestData.body?.code,
                    },
                });

                mutationOptions?.onSettled?.(data, error, requestData, context);
            },
        },
        apiVersion: 'v1',
        method: 'post',
    });
};
