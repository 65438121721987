import { RequestParams, RequestQueryOptions } from '@approvalmax/data';
import { useGet } from 'shared/data';

import { requestsApiPaths } from '../paths';
import { GetBillRequestPaymentDetails } from './useGetBillRequestPaymentDetails.types';

export const useGetBillRequestPaymentDetails = (
    params: RequestParams<GetBillRequestPaymentDetails>,
    queryOptions?: RequestQueryOptions<GetBillRequestPaymentDetails>
) => {
    return useGet(requestsApiPaths.billRequestPaymentDetails, {
        params,
        queryOptions,
        method: 'get',
        apiVersion: 'v1',
    });
};
