import { constants, dateTimeHelpers, domHelpers, errorHelpers, hooks } from '@approvalmax/utils';
import { forwardRef, ForwardRefExoticComponent, memo, MemoExoticComponent, RefAttributes, useRef } from 'react';

import { CalendarIcon } from '../../icons';
import { Button } from '../Button/Button';
import { DigitalClockProps } from '../DigitalClock/DigitalClock.types';
import Divider from '../Divider/Divider';
import Dropdown from '../Dropdown/Dropdown';
import { Grid } from '../Grid/Grid';
import { TextField } from '../TextField/TextField';
import { HTMLTextFieldElement } from '../TextField/TextField.types';
import { Controller, PopupContent } from './components';
import { useCalendarValue, useCallbackActions, useTextFieldValue } from './DateTimePicker.hooks';
import { ChildrenComponents, DateTimePickerProps } from './DateTimePicker.types';

export const DateTimePicker = memo(
    forwardRef((props, ref) => {
        const {
            placement = 'bottom-start',
            onOpen,
            initOpen,
            value,
            onChange,
            onFocus,
            onBlur,
            onClear,
            initFocus,
            clearable = true,
            minDate: minDateProp,
            maxDate: maxDateProp,
            noUtc,
            qa: qaProp,
            disabled,
            readOnly,
            ...restProps
        } = props;

        const dropdownRef = useRef<HTMLDivElement>(null);
        const activatorRef = useRef<HTMLDivElement>(null);
        const inputRef = useRef<HTMLTextFieldElement>(null);
        const composedInputRefs = hooks.useComposedRefs(ref, inputRef);

        if (typeof onChange !== 'function') {
            throw errorHelpers.notSupportedError(
                'DateTimePicker: Component not suppose to be used as uncontrollable. `onChange` callback is required'
            );
        }

        const minDate = noUtc ? minDateProp : dateTimeHelpers.convertLocalToUTC(minDateProp);
        const maxDate = noUtc ? maxDateProp : dateTimeHelpers.convertLocalToUTC(maxDateProp);
        const qa = domHelpers.generateDataQa(qaProp, 'date-time-picker');

        const { calendarValue, changeDatePickerValue } = useCalendarValue({
            value,
            onChange,
            noUtc,
            inputRef,
        });

        const {
            handleOpen,
            open,
            handleCalendarChange,
            handleChangeValue,
            handleClick,
            handleKeyDown,
            focus,
            handleClear,
            hover,
            handleMouseEnter,
            handleMouseLeave,
            toggleFocus,
            toggleOpen,
        } = useCallbackActions({
            onOpen,
            onClear,
            initOpen,
            initFocus,
            calendarValue,
            changeDatePickerValue,
        });

        const { handleFocus } = hooks.useOpenControl({
            inputRef,
            toggleFocus,
            toggleOpen,
            focus,
            open,
            initFocus,
            disabled,
            readOnly,
            onFocus,
        });

        const { handleBlur } = hooks.useCloseControl({
            activatorRef,
            dropdownRef,
            toggleFocus,
            toggleOpen,
            focus,
            open,
            onBlur,
        });

        const { textFieldValue, handleTextFieldChange } = useTextFieldValue({
            calendarValue,
            changeDatePickerValue,
            minDate,
            maxDate,
            isHumanReadable: !(focus || open),
        });

        const handleDigitClockChange: DigitalClockProps['onChange'] = (value, _, isLast) => {
            handleChangeValue(value);

            if (isLast) {
                handleOpen(false);
            }
        };

        const isShowClearIcon = clearable && Boolean(textFieldValue) && hover;

        return (
            <Dropdown
                activator={
                    <TextField
                        {...restProps}
                        value={textFieldValue}
                        onKeyDown={handleKeyDown}
                        onChange={handleTextFieldChange}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        onClick={handleClick}
                        onClear={handleClear}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                        focus={focus}
                        clearable={isShowClearIcon}
                        type={focus || open ? 'datetime-local' : 'text'}
                        disabled={disabled}
                        readOnly={readOnly}
                        endIcon={isShowClearIcon ? undefined : <CalendarIcon color='midnight100' pointer />}
                        qa={qa}
                        inputRef={composedInputRefs}
                        rootRef={activatorRef}
                    />
                }
                placement={placement}
                open={open}
                onOpen={handleOpen}
                maxHeight={0}
                width='max-content'
                isClicking={false}
                display='block'
                floatingPortalProps={constants.removeDropdownFocus.defaultFloatingPortalProps}
                floatingFocusManagerProps={constants.removeDropdownFocus.defaultFloatingFocusManagerProps}
                ref={dropdownRef}
            >
                <Grid padding='16' data-qa={domHelpers.generateDataQa(qa, 'popup')}>
                    <PopupContent
                        calendarValue={calendarValue}
                        minDate={minDate}
                        maxDate={maxDate}
                        qa={qa}
                        onCalendarChange={handleCalendarChange}
                        onDigitalClockChange={handleDigitClockChange}
                    />

                    <Divider />

                    <Grid padding='12 0 0 0' justifyItems='end'>
                        <Button color='blue10' size='small' onClick={() => handleOpen(false)}>
                            OK
                        </Button>
                    </Grid>
                </Grid>
            </Dropdown>
        );
    })
) as MemoExoticComponent<ForwardRefExoticComponent<DateTimePickerProps & RefAttributes<HTMLInputElement>>> &
    ChildrenComponents;

DateTimePicker.displayName = 'DateTimePicker';
DateTimePicker.Controller = Controller;
