import { mods } from '@approvalmax/theme';
import { Button } from '@approvalmax/ui/src/components';
import { intl, mathService } from '@approvalmax/utils';
import { StyledMods } from '@styled-kit/mods';
import { selectors } from 'modules/common';
import AttachmentsDropdown from 'modules/components/Attachment/AttachmentsDropdown';
import { backend, domain } from 'modules/data';
import { AmountDueIndicator, BankAccountCheckIndicator } from 'modules/request';
import moment from 'moment';
import { ColumnDefinition } from 'pages/requestList/selectors/types/ColumnDefinition';
import Skeleton from 'react-loading-skeleton';
import { GetBatchPaymentsRequestAmounts } from 'shared/data/webApp/v2';
import { calculateBillAmountDue } from 'shared/helpers';
import styled from 'styled-components';

import { messages } from './tableConfig.messages';

const TextBlock = styled.div`
    max-width: 200px;
    white-space: break-spaces;
`;

const BankAccount = styled.div<StyledMods<{ hasWarning: boolean }>>`
    display: flex;
    align-items: center;
    max-width: 200px;
    word-break: break-word;

    ${mods('hasWarning', true)`
        font-weight: bold;
        color: #a3341a;
    `}
`;

interface ColumnOptions {
    currency: string;
    countryCode: backend.OrganisationVersion;
    onSelectBill: (xeroBatchPaymentInvoiceId: string) => void;
    onSelectAttachment: (
        attachmentId: string,
        xeroBatchPaymentInvoiceId: string,
        xeroBatchPaymentInvoiceName: string
    ) => void;
    activeAttachmentId?: string;
    isAmountsLoading?: boolean;
    amounts?: GetBatchPaymentsRequestAmounts['response']['items'];
    isBatchPaymentAmountsV2: boolean;
}

export const getColumnDefinitions = ({
    currency,
    countryCode,
    onSelectBill,
    onSelectAttachment,
    activeAttachmentId,
    isAmountsLoading,
    amounts,
    isBatchPaymentAmountsV2,
}: ColumnOptions): ColumnDefinition<domain.PaymentItem>[] => {
    const columns: ColumnDefinition<domain.PaymentItem>[] = [
        {
            id: 'item',
            name: messages.itemName,
            value: (li) => li.friendlyName,
            minWidth: 150,
            cell: (value, li) => (
                <Button
                    noUppercase
                    size='small'
                    variant='text'
                    color='blue100'
                    onClick={() => onSelectBill(li.xeroBillInvoiceRequestId)}
                >
                    {li.friendlyName}
                </Button>
            ),
        },
        {
            id: 'supplier',
            name: messages.supplierName,
            minWidth: 150,
            value: (li) => li.contact.text,
            cell: (value) => <TextBlock>{value}</TextBlock>,
        },
        {
            id: 'billsReference',
            name: messages.billsReferenceName,
            value: (li) => li.xeroBillInvoiceReference || '—',
            cell: (value) => <TextBlock>{value}</TextBlock>,
        },
        {
            id: 'date',
            name: messages.dateName,
            minWidth: 80,
            value: (li) => moment.utc(li.date).format('ll'),
        },
        {
            id: 'dueDate',
            name: messages.dueDateName,
            minWidth: 80,
            value: (li) => moment.utc(li.dueDate).format('ll'),
        },
        {
            id: 'amount',
            name: messages.amountDueName,
            value: (li) => {
                if (isBatchPaymentAmountsV2) {
                    const amountDue = calculateBillAmountDue(li, amounts);

                    return intl.formatNumber(amountDue);
                }

                return intl.formatNumber(
                    mathService.subtract(
                        mathService.subtract(
                            mathService.subtract(li.amountDue, li.amountOnApproval),
                            li.amountAwaitingPayment
                        ),
                        li.amountInProcessing
                    )
                );
            },
            alignRight: true,
            hideTitle: true,
            cell: (value, li) => {
                const billAmounts = amounts?.find((amount) => amount.invoiceRequestId === li.xeroBillInvoiceRequestId);

                return isAmountsLoading ? (
                    <Skeleton />
                ) : (
                    <AmountDueIndicator
                        currency={li.currency}
                        amountOnApproval={billAmounts?.amountOnApproval ?? li.amountOnApproval}
                        amountInProcessing={billAmounts?.amountInProcessing ?? li.amountInProcessing}
                        amountAwaitingPayment={billAmounts?.amountAwaitingPayment ?? li.amountAwaitingPayment}
                        amountOnReview={billAmounts?.amountOnReview ?? li.amountOnReview}
                    >
                        {value}
                    </AmountDueIndicator>
                );
            },
        },
        {
            id: 'payment',
            name: messages.payment({ currency }),
            value: (li) => intl.formatNumber(li.amount) || '—',
            alignRight: true,
        },
    ];

    if (selectors.field.getSupportRegionalFieldByCountryCode(countryCode, domain.RegionalField.Details)) {
        columns.push({
            id: 'details',
            name: messages.details,
            value: (li) => li.details || '—',
        });
    }

    if (selectors.field.getSupportRegionalFieldByCountryCode(countryCode, domain.RegionalField.Particulars)) {
        columns.push({
            id: 'particulars',
            name: messages.particulars,
            value: (li) => li.particulars || '—',
        });
    }

    if (selectors.field.getSupportRegionalFieldByCountryCode(countryCode, domain.RegionalField.Code)) {
        columns.push({
            id: 'code',
            name: messages.code,
            value: (li) => li.code || '—',
        });
    }

    if (selectors.field.getSupportRegionalFieldByCountryCode(countryCode, domain.RegionalField.Reference)) {
        columns.push({
            id: 'reference',
            name: messages.reference,
            value: (li) => li.reference || '—',
        });
    }

    columns.push(
        {
            id: 'bankAccount',
            name: messages.bankAccountName,
            minWidth: 100,
            value: (li) => li.bankAccount || '—',
            cell: (val, li) => (
                <BankAccountCheckIndicator
                    actualBankAccount={li.bankAccount || '—'}
                    expectedBankAccount={li.defaultBankAccount || '—'}
                >
                    {(hasWarning) => <BankAccount $hasWarning={hasWarning}>{val}</BankAccount>}
                </BankAccountCheckIndicator>
            ),
        },
        {
            id: 'attachments',
            name: '',
            value: (li) => String(li.attachments.length),
            cell: (val, li) =>
                li.attachments.length > 0 ? (
                    <AttachmentsDropdown
                        attachments={li.attachments}
                        activeAttachmentId={activeAttachmentId}
                        onSelectAttachment={(attachmentId: string) =>
                            onSelectAttachment(attachmentId, li.xeroBillInvoiceRequestId, li.friendlyName)
                        }
                    />
                ) : null,
        }
    );

    return columns;
};
