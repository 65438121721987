import { compareHelpers, objectHelpers } from '@approvalmax/utils';
import { backend, domain, du, schemas } from 'modules/data';

import { parseQBooksAccount } from './QBooksAccount';
import { parseQBooksContact } from './QBooksContact';
import { parseQBooksPayee } from './QBooksPayee';
import { parseQBooksPayeeType } from './QBooksPayeeType';
import { parseQBooksPaymentAccount } from './QBooksPaymentAccount';
import { parseQBooksPaymentMethod } from './QBooksPaymentMethod';
import { parseQBooksPaymentType } from './QBooksPaymentType';
import { parseQBooksTaxCode } from './QBooksTaxCode';
import { parseQBooksTaxRate } from './QBooksTaxRate';

type BackendAccount = backend.IntegrationsQBooksAccount & { ParentId?: string | undefined };

export function mapAccounts(accounts: BackendAccount[], textWithCurrency?: boolean): domain.QBooksAccount[] {
    let firstLevel: domain.QBooksAccount[] = [];

    const idToChildren: { [key: string]: domain.QBooksAccount[] } = {};

    accounts.forEach((item) => {
        if (item.ParentId) {
            idToChildren[item.ParentId] = idToChildren[item.ParentId] || [];
            idToChildren[item.ParentId].push(parseQBooksAccount(item, textWithCurrency));
        } else {
            firstLevel.push(parseQBooksAccount(item, textWithCurrency));
        }
    });

    // The tree might be inconsistent (parentId not on the list). Then consider the parent null.
    Object.keys(idToChildren).forEach((parentId) => {
        const parentAccount = accounts.find((account) => account.Id === parentId);

        if (!parentAccount) {
            const accountsWithoutParent = idToChildren[parentId];

            delete idToChildren[parentId];
            firstLevel.push(...accountsWithoutParent);
        }
    });

    const result: domain.QBooksAccount[] = [];

    firstLevel.sort(compareHelpers.comparatorFor<domain.QBooksAccount>(compareHelpers.stringComparator2Asc, 'text'));

    const recurseAddToResult = (root: domain.QBooksAccount, nestingLevel: number) => {
        result.push(root);
        root.nestingLevel = nestingLevel;

        const children = idToChildren[root.id];

        if (children) {
            children.sort(
                compareHelpers.comparatorFor<domain.QBooksAccount>(compareHelpers.stringComparator2Asc, 'text')
            );

            children.forEach((item) => {
                recurseAddToResult(item, nestingLevel + 1);
            });
        }
    };

    firstLevel.forEach((item) => recurseAddToResult(item, 0));

    return result;
}

export const parseQBooksTerm = (value: backend.IntegrationsQBooksTermDetails): domain.QBooksTerm => {
    return {
        id: value.Id,
        text: value.Name,
        dueTerms: value.DueTerms.map(({ Type, Value }) => ({
            type: Type,
            value: Value,
        })),
    };
};

export function parseQBooksContext(
    value: backend.RequestsQBooksEditingContext,
    hasValidLineAmountType: boolean
): domain.QBooksContext {
    const isMultiCurrencyEnabled = Boolean(value.isMultiCurrencyEnabled);

    return {
        accounts: mapAccounts(value.Accounts || [], isMultiCurrencyEnabled),
        classField: value.classFieldId
            ? {
                  id: value.classFieldId,
                  text: value.classFieldId,
              }
            : undefined,
        classTrackingPerLineEnabled: value.classTrackingPerLineEnabled,
        classTrackingPerTransactionEnabled: value.classTrackingPerTransactionEnabled,
        classes: (value.classes || []).map((item) => ({
            ...du.parseServerReference(item),
            parentId: item.ParentId || null,
        })),
        companyAddresses: [],
        currencies: (value.currencies || []).map((currency) => ({
            id: currency.Id,
            text: `${currency.Id} ${currency.Name}`,
        })),
        customerField: value.customerFieldId
            ? {
                  id: value.customerFieldId,
                  text: value.customerFieldId,
              }
            : undefined,
        defaultLineAmountType:
            (value.defaultLineAmountType as domain.LineAmountType) ?? domain.LineAmountType.TaxExclusive,
        defaultPurchaseTermId: value.defaultPurchaseTermId,
        defaultSalesTermId: value.defaultSalesTermId,
        departmentField: value.departmentFieldId
            ? {
                  id: value.departmentFieldId,
                  text: value.departmentTerminology,
              }
            : undefined,
        departments: (value.departments || []).map((item) => ({
            ...du.parseServerReference(item),
            parentId: item.ParentId || null,
        })),
        hasBillableFeature: Boolean(value.isBillableTrackingEnabled),
        hasClassFeature: Boolean(value.classTrackingEnabled),
        hasCustomerFeature: Boolean(value.customerTrackingEnabled),
        hasDepartmentFeature: Boolean(value.departmentTrackingEnabled),
        hasTaxableFeature: Boolean(value.isUsingSalesTaxEnabled),
        hasTaxesFeature: Boolean(value.hasTaxes),
        hasValidLineAmountType,
        invoiceCustomer: value.invoiceCustomer ? parseQBooksContact(value.invoiceCustomer) : null,
        isAutoTaxesEnabled: Boolean(value.isAutoTaxesEnabled),
        isMultiCurrencyEnabled,
        isTrackingTaxesEnabled: Boolean(value.isTrackingTaxesEnabled),
        payee: value.payee && parseQBooksPayee(value.payee),
        payeeTypes: (value.payeeTypes || []).map(parseQBooksPayeeType),
        paymentAccount: value.paymentAccount && parseQBooksPaymentAccount(value.paymentAccount),
        paymentMethodField: value.paymentMethodFieldId
            ? {
                  id: value.paymentMethodFieldId,
                  text: value.paymentMethodFieldId,
              }
            : undefined,
        paymentMethods: (value.paymentMethods || []).map(parseQBooksPaymentMethod),
        paymentTypes: (value.paymentTypes || []).map(parseQBooksPaymentType),
        qbooksEnabledLineItemType: schemas.template.mapQBooksEnabledLineItemType(value.availableLineItemType),
        qbooksItems: undefined,
        salesFormOptionalFields: objectHelpers.pascalCaseToCamelCase(value.salesFormOptionalFields),
        taxCodes: (value.taxCodes || []).map(parseQBooksTaxCode),
        taxRates: (value.taxRates || []).map(parseQBooksTaxRate),
        termFieldId: value.termFieldId,
        terms: (value.terms || []).map(parseQBooksTerm),
        vendor: value.vendor ? parseQBooksContact(value.vendor) : null,
    };
}
