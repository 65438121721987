import './grayArea.scss';

import { FC, memo } from 'react';
import bemFactory from 'react-bem-factory';

import { messages } from './NoSubscription.messages';

const bem = bemFactory.block('company-select-subscription-gray-area');
const qa = bemFactory.qa('company-select-subscription-gray-area');

const NoSubscription: FC = () => {
    return (
        <section className={bem()} data-qa={qa()}>
            <h4 className={bem('text')}>{messages.emptySubscriptions}</h4>
        </section>
    );
};

export default memo(NoSubscription);
