import { Reference } from '@approvalmax/types';
import { BaseEditorProps, DropdownEditor } from '@approvalmax/ui';
import { PlainDataProvider } from 'modules/data-providers';
import React, { FC } from 'react';

import { messages } from './MonthPickerEditor.messages';

export interface MonthReference extends Reference {
    monthIndex: number;
}

const monthNames = [
    messages.monthJanuary,
    messages.monthFebruary,
    messages.monthMarch,
    messages.monthApril,
    messages.monthMay,
    messages.monthJune,
    messages.monthJuly,
    messages.monthAugust,
    messages.monthSeptember,
    messages.monthOctober,
    messages.monthNovember,
    messages.monthDecember,
];

export const allMonthReferences: MonthReference[] = monthNames.map((name, i) => ({
    id: String(i + 1),
    monthIndex: i + 1,
    text: name,
}));

interface MonthPickerEditorProps extends BaseEditorProps<MonthReference | null> {
    buttonComponent?: React.ComponentType<any>;
}

export const MonthPickerEditor: FC<MonthPickerEditorProps> = (props) => {
    return (
        <PlainDataProvider items={allMonthReferences}>
            <DropdownEditor css='width: 100px' placeholder={messages.placeholder} {...props} />
        </PlainDataProvider>
    );
};
