import { ApiError, Command, ErrorCode } from '@approvalmax/types';
import { errorHelpers } from '@approvalmax/utils';
import { domain, stateTree } from 'modules/data';
import createCachedSelector from 're-reselect';

import * as companySelectors from './companySelectors';
import {
    dearMessages,
    messages,
    netSuiteMessages,
    qbooksMessages,
    xeroMessages,
} from './integrationSelectors.messages';
import { ExpandedIntegration, IntegrationError, IntegrationState } from './types';

const xeroErrorMessages = {
    errorText5003: xeroMessages.errorText5003,
    errorText5004: xeroMessages.errorText5004,
    errorText5006: xeroMessages.errorText5006,
    errorText5013: xeroMessages.errorText5013,
    errorText5014: xeroMessages.errorText5014,
    errorText5015: xeroMessages.errorText5015,
    errorText4050: xeroMessages.errorText4050,
};

const dearErrorMessages = {
    errorText7504: dearMessages.errorText7504,
};

const netSuiteErrorMessages = {
    errorText7504: netSuiteMessages.errorText7504,
};

function getIntegrationError(integration: domain.Integration | null): IntegrationError | null {
    if (!integration) {
        return null;
    }

    if (integration.status !== domain.IntegrationStatus.Connected && integration.errorStatusCode) {
        let description;

        switch (integration.integrationType) {
            case domain.IntegrationType.Xero: {
                const errorKey = `errorText${integration.errorStatusCode}` as keyof typeof xeroErrorMessages;

                description = xeroErrorMessages[errorKey] || xeroMessages.errorTextGeneric;
                break;
            }

            case domain.IntegrationType.QBooks:
                description = qbooksMessages.errorTextGeneric;
                break;

            case domain.IntegrationType.Dear: {
                const errorKey = `errorText${integration.errorStatusCode}` as keyof typeof dearErrorMessages;

                description = dearErrorMessages[errorKey] || dearMessages.integrationGenericErrorText;
                break;
            }

            case domain.IntegrationType.NetSuite: {
                const errorKey = `errorText${integration.errorStatusCode}` as keyof typeof netSuiteErrorMessages;

                description = netSuiteErrorMessages[errorKey] || netSuiteMessages.integrationGenericErrorText;
                break;
            }

            default:
                throw errorHelpers.notSupportedError(
                    `IntegrationType '${integration.integrationType}' is not supported.`
                );
        }

        return {
            statusCode: integration.errorStatusCode,
            description,
        };
    }

    return null;
}

export function isIntegrationError(error?: Error): error is ApiError {
    const errorCode = errorHelpers.getErrorCode(error);

    if (errorCode) {
        return false;
    }

    const integrationErrorCodes: string[] = [
        ErrorCode.E5001_XERO_GENERAL_INTEGRATION_ERROR,
        ErrorCode.E5002_XERO_API_CLIENT_MUST_BE_AUTHORIZED,
        ErrorCode.E5003_XERO_OAUTH_TOKEN_EXPIRED,
        ErrorCode.E5005_XERO_REQUEST_TOKEN_NOT_FOUND,
        ErrorCode.E5006_XERO_REQUEST_TOKEN_KEY_DOESNOT_MATCH,
        ErrorCode.E5014_XERO_OAUTH_TOKEN_REJECTED,
        ErrorCode.E5015_XERO_GENERAL_INTEGRATION_AUTHENTICATION_ERROR,
        ErrorCode.E4123_ANOTHER_COMPANY_IS_ALREADY_INTEGRATED,
        ErrorCode.E4043_INTEGRATION_MUST_BE_ACTIVE,
        ErrorCode.E4063_UNSUPPORTED_INTEGRATION_TYPE,
    ];

    return integrationErrorCodes.includes(errorCode);
}

export const expandIntegration: (integration: domain.Integration) => ExpandedIntegration = createCachedSelector(
    (integration: domain.Integration) => integration,
    (integration) => {
        const displayName = getIntegrationTypeName(integration.integrationType);

        return {
            ...integration,
            displayName,
            error: getIntegrationError(integration),
        };
    }
)((integration: domain.Integration) => integration.id);

export function findIntegrationById(
    integrations: stateTree.State['entities']['integrations'],
    integrationId: string | null
): ExpandedIntegration | null {
    if (!integrationId) {
        return null;
    }

    const integration = integrations[integrationId];

    return integration ? expandIntegration(integration) : null;
}

export function getIntegrationById(state: stateTree.State, integrationId: string): ExpandedIntegration {
    const integration = state.entities.integrations[integrationId];

    if (!integration) {
        throw errorHelpers.notFoundError(`Integration ${integrationId} not found.`);
    }

    return expandIntegration(integration);
}

export function getIntegrationByCompanyId(state: stateTree.State, companyId: string): ExpandedIntegration | null {
    const integration = Object.values(state.entities.integrations).find((i) => i.companyId === companyId);

    return integration ? expandIntegration(integration) : null;
}

export function hasActiveIntegration(state: stateTree.State, companyId: string): boolean {
    const integration = getIntegrationByCompanyId(state, companyId);

    return Boolean(integration && integration.status === domain.IntegrationStatus.Connected);
}

export function getConnectedIntegrations(state: stateTree.State): ExpandedIntegration[] {
    return Object.values(state.entities.integrations)
        .filter((integration) => integration.status === domain.IntegrationStatus.Connected)
        .map(expandIntegration);
}

export function getIntegrations(state: stateTree.State): ExpandedIntegration[] {
    return Object.values(state.entities.integrations).map(expandIntegration);
}

export function getIntegrationType(integrationCode: domain.IntegrationCode | null): domain.IntegrationType {
    switch (integrationCode) {
        case domain.IntegrationCode.XeroBill:
        case domain.IntegrationCode.XeroCreditNotesPayable:
        case domain.IntegrationCode.XeroCreditNotesReceivable:
        case domain.IntegrationCode.XeroInvoice:
        case domain.IntegrationCode.XeroContact:
        case domain.IntegrationCode.XeroPo:
        case domain.IntegrationCode.XeroBillBatchPayment:
        case domain.IntegrationCode.XeroAirwallexBatchPayment:
        case domain.IntegrationCode.XeroAmaxPayBatchPayment:
        case domain.IntegrationCode.XeroQuote:
        case domain.IntegrationCode.XeroManualJournal:
            return domain.IntegrationType.Xero;

        case domain.IntegrationCode.QBooksBill:
        case domain.IntegrationCode.QBooksExpense:
        case domain.IntegrationCode.QBooksInvoice:
        case domain.IntegrationCode.QBooksJournalEntry:
        case domain.IntegrationCode.QBooksPo:
        case domain.IntegrationCode.QBooksVendor:
            return domain.IntegrationType.QBooks;

        case domain.IntegrationCode.NetSuiteBill:
        case domain.IntegrationCode.NetSuitePO:
        case domain.IntegrationCode.NetSuiteSalesOrder:
        case domain.IntegrationCode.NetSuiteInvoice:
        case domain.IntegrationCode.NetSuiteExpenseReport:
        case domain.IntegrationCode.NetSuiteVRA:
        case domain.IntegrationCode.NetSuiteBillPayment:
        case domain.IntegrationCode.NetSuiteRA:
        case domain.IntegrationCode.NetSuiteJournalEntry:
        case domain.IntegrationCode.NetSuiteVendor:
            return domain.IntegrationType.NetSuite;

        case domain.IntegrationCode.DearPo:
            return domain.IntegrationType.Dear;

        case null:
            return domain.IntegrationType.None;

        default:
            throw errorHelpers.assertNever(integrationCode);
    }
}

export function getIntegrationTypeName(integrationType: domain.IntegrationType): string {
    switch (integrationType) {
        case domain.IntegrationType.Xero:
            return messages.xeroIntegrationDisplayName;

        case domain.IntegrationType.QBooks:
            return messages.qbooksIntegrationDisplayName;

        case domain.IntegrationType.NetSuite:
            return messages.netsuiteIntegrationDisplayName;

        case domain.IntegrationType.Dear:
            return messages.dearIntegrationDisplayName;

        case domain.IntegrationType.None:
            return '';

        default:
            throw errorHelpers.notImplementedError();
    }
}

export function getExplainedIntegrationState(integration: domain.Integration | null): IntegrationState {
    const neverConnectedBefore = Boolean(!integration || integration.isNew);

    let integrationStatus;

    if (!integration) {
        integrationStatus = domain.IntegrationStatus.Disconnected;
    } else {
        integrationStatus = integration.status;
    }

    const isConnected = integrationStatus === domain.IntegrationStatus.Connected;

    return {
        neverConnectedBefore,
        isDisconnected: integrationStatus === domain.IntegrationStatus.Disconnected,
        isDisconnecting: integrationStatus === domain.IntegrationStatus.Disconnecting,
        isConnecting: integrationStatus === domain.IntegrationStatus.Connecting,
        isConnectingFinalizing: integrationStatus === domain.IntegrationStatus.ConnectingFinalizing,
        isConnected,
        isConnectedFirstTime: isConnected && integration!.firstTimeConnection,
    };
}

function isReadonly(state: stateTree.State, companyId: string): boolean {
    const company = companySelectors.getCompanyById(state, companyId);

    return !companySelectors.canUpdateCompanySettings(state, company) || company.isReadonly;
}

export function getChangeIntegrationCommand(state: stateTree.State, companyId: string): Command {
    if (isReadonly(state, companyId)) {
        return {
            disabled: true,
        };
    }

    const integration = getIntegrationByCompanyId(state, companyId);
    const st = getExplainedIntegrationState(integration);
    const enabled = st.isConnected || st.isDisconnected;

    return {
        disabled: !enabled,
    };
}

export function getIntegrationCacheItems(
    state: stateTree.State,
    integration: domain.Integration
): domain.IntegrationCacheItem[] {
    return integration.cacheItems || [];
}
