import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('pages.RequestList.actions', {
    startOverSuccessText: `The approval workflow for "{requestName}" has restarted.`,
    editStepParticipantsSuccessText: `The Approvers for the "{stepName}" step have been changed.`,
    editStepReviewersSuccessText: 'Reviewers changed for review step',
    reassignRequestSuccessText: 'The request has been reassigned to {displayName}.',
    auditReportNotFoundNotificationText: 'The audit report was not found.',
    attachmentNotFoundNotificationText: 'The document was not found.',
    cancelRequestSuccessText: 'The request has been cancelled.',
    makeForceDecisionApprovedSuccess: 'The request has been approved.',
    makeForceDecisionDeclinedSuccess: 'The request has been rejected.',
    makeDecisionApprovedSuccess: 'The request has been approved.',
    completeReviewSuccess: 'The review has been completed, the request has been submitted for approval.',
    sendToReviewSuccess: 'The request has been returned to review.',
    makeDecisionDeclinedSuccess: 'The request has been rejected.',
    revokeRequestSuccess: 'The approval decision has been revoked.',
    submitRequestSuccess: 'The request has been submitted for approval.',
    deleteRequestSuccess: 'The request has been deleted.',
});
