import { Mods, mods, theme } from '@approvalmax/theme';
import { StyledMods } from '@styled-kit/mods';
import styled from 'styled-components';

export const Root = styled.tr<StyledMods<Mods<'bordered'> & { hideHeaderBottomBorder?: boolean }>>`
    ${mods.bordered.false`
        ${mods('hideHeaderBottomBorder', false)`
            border-bottom: 1px solid ${theme.color.midnight50};
        `};
    `};

    ${mods.bordered.true`
        border: 1px solid ${theme.color.midnight50};
    `};
`;

export const DragHandler = styled.div<StyledMods<Mods<'hide'>>>`
    background-color: transparent;
    border-top: 1px solid transparent;
    border-left: 1px solid transparent;
    border-bottom: 1px solid transparent;

    ${mods.hide.true`
        display: none;
    `};
`;

export const DragHandlerTh = styled.th`
    border-top: 1px solid transparent;
    border-left: 1px solid transparent;
`;

export const ActionMenuTh = styled.th`
    background-color: transparent;
    border-top: 1px solid transparent;
    border-right: 1px solid transparent;
    border-bottom: 1px solid transparent;
`;
