import { Flex, Form, Grid, Select, Text, TextField } from '@approvalmax/ui/src/components';
import { statics } from 'modules/common';
import { memo } from 'react';
import bemFactory from 'react-bem-factory';
import { ProfileAvatar } from 'shared/components/ProfileEditPopup/components/ProfileAvatar/ProfileAvatar';

import { checkIsAdministratorUserProfile } from '../../ProfileEditPopup.helpers';
import { fieldNamesSectionGeneral, firstNameMaxLength, lastNameMaxLength } from './SectionGeneral.constants';
import { useSectionGeneral, useSectionGeneralForm } from './SectionGeneral.hooks';
import { messages } from './SectionGeneral.messages';
import { SectionGeneralProps } from './SectionGeneral.types';

const qa = bemFactory.qa('profile-popup-profile-information-section');

export const SectionGeneral = memo<SectionGeneralProps>((props) => {
    const { profile, progress } = props;

    const { form } = useSectionGeneralForm(profile);
    const { handleTransformPhone } = useSectionGeneral();

    const isAdministrator = checkIsAdministratorUserProfile(profile);

    return (
        <Form form={form}>
            <Flex direction='column' spacing='24'>
                <Text font='headline' fontSize='small' fontWeight='medium'>
                    {messages.title}
                </Text>

                <Flex spacing='40 40' wrap={false}>
                    <Grid gridTemplateColumns='105px'>
                        <ProfileAvatar avatarUrl={profile?.avatar} disabled={progress} />
                    </Grid>

                    <Flex container grow={1} spacing='16'>
                        <Flex size={12}>
                            <TextField
                                qa={qa('first-name-editor')}
                                name={fieldNamesSectionGeneral.firstName}
                                label={messages.firstNameLabel}
                                placeholder={messages.firstNamePlaceholder}
                                hint={profile.email}
                                maxLength={firstNameMaxLength}
                                disabled={progress}
                                clearable={false}
                                required
                            />
                        </Flex>

                        <Flex size={12}>
                            <TextField
                                qa={qa('last-name-editor')}
                                name={fieldNamesSectionGeneral.lastName}
                                label={messages.lastNameLabel}
                                placeholder={messages.lastNamePlaceholder}
                                maxLength={lastNameMaxLength}
                                disabled={progress}
                                clearable={false}
                                required
                            />
                        </Flex>

                        <Flex size={12}>
                            <TextField
                                qa={qa('phone-editor')}
                                transform={handleTransformPhone}
                                name={fieldNamesSectionGeneral.phone}
                                label={messages.phoneLabel}
                                placeholder={messages.phonePlaceholder}
                                disabled={progress}
                                clearable={false}
                                required={isAdministrator}
                            />
                        </Flex>

                        <Flex size={12} block>
                            <Select
                                qa={qa('timezone-editor')}
                                name={fieldNamesSectionGeneral.timeZoneForEmail}
                                label={messages.timeZoneLabel}
                                placeholder={messages.timeZonePlaceholder}
                                hint={messages.timeZoneHint}
                                size='medium'
                                itemIdKey='id'
                                itemNameKey='text'
                                items={statics.timeZone.timeZoneReferences}
                                disabled={progress}
                                clearable={false}
                                required
                                autocomplete
                            />
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>
        </Form>
    );
});

SectionGeneral.displayName = 'SectionGeneral';
