import { errorHelpers } from '@approvalmax/utils';
import unionBy from 'lodash/unionBy';
import { dataProviders } from 'modules/common';
import { domain } from 'modules/data';
import { addArrayItem, immutable, ImmutableObject, merge, removeArrayItem, set, update } from 'modules/immutable';
import { integrationActions } from 'modules/integration';

import {
    Action,
    ADD_APPROVAL_RULE_TO_ACTIVE_MATRIX,
    ADD_FIELD_TO_ACTIVE_TEMPLATE,
    ADD_RULE_TO_ACTIVE_MATRIX,
    ADD_USER_TO_ACTIVE_MATRIX,
    APPLY_MATRIX,
    COPY_RULES_TO_SAME_STEP_USERS,
    CREATE_FIELD_RESPONSE,
    DISCARD_OPEN_MATRIX,
    OPEN_APPROVAL_MATRIX,
    OPEN_AUTO_APPROVER_MATRIX,
    OPEN_EDITORS_MATRIX,
    OPEN_REVIEWER_MATRIX,
    OPEN_SUBMITTER_MATRIX,
    REMOVE_FIELD_FROM_ACTIVE_TEMPLATE,
    REMOVE_LINE_FROM_ACTIVE_MATRIX,
    REMOVE_LINE_FROM_ACTIVE_MATRIX_AUTO_APPROVAL,
    REMOVE_RULE_FROM_ACTIVE_MATRIX,
    RENAME_FIELD_RESPONSE,
    RENAME_LINE_OF_ACTIVE_MATRIX,
    SET_CONDITION,
    SET_DEFAULT_APPROVER_TO_ACTIVE_MATRIX,
    SET_DEFAULT_REVIEWER_TO_ACTIVE_MATRIX,
    UPDATE_AMOUNT_TYPE_IN_ACTIVE_MATRIX,
    UPDATE_FIELD_ACCESS_TYPE_IN_ACTIVE_MATRIX,
} from '../../actions';
import { ActiveMatrixData } from '../../types/activeMatrixData';
import { AccessType, MatrixType } from '../../types/matrix';
import { getAutoApprovalMatrixData, getMatrixAmountType, getMatrixData } from '../../utils/helpers';

export type ActiveMatrix = ImmutableObject<ActiveMatrixData> | null;

function activeMatrixReducerInternal(
    state: ActiveMatrix = null,
    action: Action | integrationActions.Action
): ActiveMatrix {
    switch (action.type) {
        case OPEN_APPROVAL_MATRIX: {
            const step = action.payload.template.steps[action.payload.stepIndex];
            const defaultApproverEntry = step.participantMatrix.find((p) => Boolean(p.isBackup));
            const defaultApprover = defaultApproverEntry ? defaultApproverEntry.lineId : null;
            const lines = getMatrixData(step.participantMatrix, action.payload.users, action.payload.team);
            const amountType = getMatrixAmountType(lines);

            return immutable<ActiveMatrixData>({
                type: MatrixType.Approval,
                modified: false,
                data: lines,
                amountType,
                defaultApprover,
                requiredFieldIds: step.requiredFieldIds,
                readonlyFieldIds: step.readonlyFieldIds,
                generalFieldOrder: step.generalFieldOrder,
                stepIndex: action.payload.stepIndex,
                highlightLineId: action.payload.highlightLineId,
                onApplyAndClose: action.payload.onApplyAndClose,
                onClose: action.payload.onClose,
            });
        }

        case OPEN_REVIEWER_MATRIX: {
            const reviewStep = action.payload.template.reviewStep;
            const lines = getMatrixData(reviewStep.reviewers, action.payload.users, action.payload.team);
            const amountType = getMatrixAmountType(lines);
            const defaultReviewerEntry = reviewStep.reviewers.find((p) => Boolean(p.isBackup));
            const defaultReviewer = defaultReviewerEntry ? defaultReviewerEntry.lineId : null;
            const generalFieldOrder = action.payload.template.reviewStep.reviewers
                .flatMap((reviewer) => reviewer.rules)
                .flatMap((rule) => rule.conditions)
                .reduce<string[]>((fieldIds, { fieldId, fieldSystemPurpose }) => {
                    if (
                        !fieldIds.includes(fieldId) &&
                        fieldSystemPurpose === domain.FieldSystemPurpose.NetSuiteCustom
                    ) {
                        fieldIds.push(fieldId);
                    }

                    return fieldIds;
                }, []);

            return immutable<ActiveMatrixData>({
                type: MatrixType.Reviewer,
                modified: false,
                data: lines,
                amountType,
                requiredFieldIds: reviewStep.requiredFieldIds,
                readonlyFieldIds: reviewStep.readonlyFieldIds,
                generalFieldOrder,
                defaultReviewer,
                highlightLineId: action.payload.highlightLineId,
            });
        }

        case OPEN_EDITORS_MATRIX: {
            const step = action.payload.template.steps[action.payload.stepIndex];
            const lines = getMatrixData(step.editorMatrix, action.payload.users, action.payload.team);
            const amountType = getMatrixAmountType(lines);

            return immutable<ActiveMatrixData>({
                type: MatrixType.Editor,
                modified: false,
                data: lines,
                amountType,
                requiredFieldIds: step.requiredFieldIds,
                readonlyFieldIds: step.readonlyFieldIds,
                generalFieldOrder: step.generalFieldOrder,
                stepIndex: action.payload.stepIndex,
                highlightLineId: action.payload.highlightLineId,
            });
        }

        case OPEN_SUBMITTER_MATRIX: {
            const lines = getMatrixData(
                action.payload.template.submitterMatrix,
                action.payload.users,
                action.payload.team
            );
            const amountType = getMatrixAmountType(lines);

            return immutable<ActiveMatrixData>({
                type: MatrixType.Requester,
                modified: false,
                data: lines,
                amountType,
                requiredFieldIds: action.payload.template.requiredFieldIds,
                readonlyFieldIds: [],
                generalFieldOrder: action.payload.template.submitterRuleOrders || [],
                highlightLineId: action.payload.highlightLineId,
            });
        }

        case OPEN_AUTO_APPROVER_MATRIX: {
            const lines = getAutoApprovalMatrixData(action.payload.template.autoApprovalRules);
            const amountType = getMatrixAmountType(lines);
            const generalFieldOrder = action.payload.template.autoApprovalRules
                .flatMap((rule) => rule.rules)
                .flatMap((rule) => rule.conditions)
                .filter(
                    (rule) =>
                        rule.fieldSystemPurpose === domain.FieldSystemPurpose.General ||
                        rule.fieldSystemPurpose === domain.FieldSystemPurpose.NetSuiteCustom
                )
                .map((rule) => rule.fieldId);

            return immutable<ActiveMatrixData>({
                type: MatrixType.AutoApproval,
                modified: false,
                data: lines,
                amountType,
                requiredFieldIds: action.payload.template.requiredFieldIds,
                readonlyFieldIds: [],
                generalFieldOrder,
                highlightLineId: action.payload.highlightLineId,
            });
        }

        case UPDATE_FIELD_ACCESS_TYPE_IN_ACTIVE_MATRIX: {
            if (action.payload.matrixType !== state?.type) {
                return state;
            }

            let requiredFieldIds = state.requiredFieldIds;
            let readonlyFieldIds = state.readonlyFieldIds;
            let newData = state.data;

            switch (action.payload.newAccessType) {
                case AccessType.Mandatory:
                    requiredFieldIds = addArrayItem(requiredFieldIds, action.payload.fieldId);
                    readonlyFieldIds = removeArrayItem(readonlyFieldIds, action.payload.fieldId);
                    break;

                case AccessType.Optional:
                    requiredFieldIds = removeArrayItem(requiredFieldIds, action.payload.fieldId);
                    readonlyFieldIds = removeArrayItem(readonlyFieldIds, action.payload.fieldId);
                    break;

                case AccessType.Readonly:
                    requiredFieldIds = removeArrayItem(requiredFieldIds, action.payload.fieldId);
                    readonlyFieldIds = addArrayItem(readonlyFieldIds, action.payload.fieldId);
                    break;

                default:
                    throw errorHelpers.assertNever(action.payload.newAccessType);
            }

            if (state.type === MatrixType.Requester && action.payload.newAccessType === AccessType.Mandatory) {
                newData = newData.map((matrixLine) => ({
                    ...matrixLine,
                    rules: matrixLine.rules.map((rule) => ({
                        ...rule,
                        conditions: rule.conditions.map((condition: domain.MatrixCondition) => {
                            if (condition.fieldId === action.payload.fieldId && 'exactValues' in condition) {
                                const newCondition = {
                                    ...condition,
                                };

                                newCondition.exactValues = newCondition.exactValues.filter(
                                    (value) => value.id !== dataProviders.FieldDataProvider.EmptyValue.id
                                );

                                return newCondition;
                            }

                            return condition;
                        }),
                    })),
                }));
            }

            return merge(state, {
                requiredFieldIds,
                readonlyFieldIds,
                data: newData,
            });
        }

        case SET_CONDITION: {
            if (action.payload.matrixType !== state?.type) {
                return state;
            }

            const data = state.data.map((matrixLine, lineIndex) => {
                if (matrixLine.lineId !== action.payload.lineId || lineIndex !== action.payload.lineIndex) {
                    return matrixLine;
                }

                return {
                    ...matrixLine,
                    rules: matrixLine.rules.map((rule, ruleIndex) => {
                        if (ruleIndex !== action.payload.ruleIndex) {
                            return rule;
                        }

                        const conditions = rule.conditions
                            .filter((c) => c.fieldId !== action.payload.field.id)
                            .concat(action.payload.newCondition);

                        return {
                            ...rule,
                            conditions,
                        };
                    }),
                };
            });

            return set(state, 'data', data);
        }

        case ADD_USER_TO_ACTIVE_MATRIX: {
            const thisState = state;

            if (thisState?.type === MatrixType.Approval || thisState?.type === MatrixType.Reviewer) {
                return update(thisState, 'data', (x: domain.MatrixLine[]) =>
                    x.concat({
                        lineId: action.payload.user.id,
                        isBackup: false,
                        rules: [
                            {
                                conditions: [],
                            },
                        ],
                    })
                );
            } else {
                return update(thisState, 'data', (x: domain.MatrixLine[]) =>
                    x.concat({
                        lineId: action.payload.user.id,
                        rules: [
                            {
                                conditions: [],
                            },
                        ],
                    })
                );
            }
        }

        case ADD_APPROVAL_RULE_TO_ACTIVE_MATRIX: {
            return update(state, 'data', (x: domain.MatrixLine[]) =>
                x.concat({
                    lineId: action.payload.ruleName,
                    rules: [
                        {
                            conditions: [],
                        },
                    ],
                })
            );
        }

        case REMOVE_LINE_FROM_ACTIVE_MATRIX: {
            let newState = state;
            let newData = newState?.data.filter((line) => !(line.lineId === action.payload.lineId));

            if (newData?.length === 0 && newState?.type === MatrixType.Approval && newState.defaultApprover) {
                // Backup approver must be removed if he is the only one
                newState = set(newState, 'defaultApprover', null);
            }

            if (newData?.length === 0 && newState?.type === MatrixType.Reviewer && newState.defaultReviewer) {
                // Backup approver must be removed if he is the only one
                newState = set(newState, 'defaultReviewer', null);
            }

            return set(newState, 'data', newData);
        }

        case REMOVE_LINE_FROM_ACTIVE_MATRIX_AUTO_APPROVAL: {
            return update(state, 'data', (matrixLines: domain.MatrixLine[]) =>
                matrixLines.filter((line, i) => i !== action.payload.lineIndex)
            );
        }

        case RENAME_LINE_OF_ACTIVE_MATRIX: {
            return update(state, 'data', (matrixLines: domain.MatrixLine[]) =>
                matrixLines.map((matrixLine, index) => ({
                    ...matrixLine,
                    lineId: index === action.payload.lineIndex ? action.payload.newLineId : matrixLine.lineId,
                }))
            );
        }

        case SET_DEFAULT_APPROVER_TO_ACTIVE_MATRIX:
            return set(state, 'defaultApprover', action.payload.userId);

        case SET_DEFAULT_REVIEWER_TO_ACTIVE_MATRIX:
            return set(state, 'defaultReviewer', action.payload.userId);

        case ADD_RULE_TO_ACTIVE_MATRIX:
            return set(
                state,
                'data',
                (state?.data as domain.MatrixLine[]).map((line) => {
                    if (line.lineId !== action.payload.user.id) {
                        return line;
                    }

                    return {
                        ...line,
                        rules: line.rules.concat({
                            conditions: [],
                        }),
                    };
                })
            );

        case REMOVE_RULE_FROM_ACTIVE_MATRIX:
            return set(
                state,
                'data',
                state?.data.map((line) => {
                    if (line.lineId !== action.payload.user.id) {
                        return line;
                    }

                    return {
                        ...line,
                        rules: line.rules.filter((r) => r !== action.payload.rule),
                    };
                })
            );

        case COPY_RULES_TO_SAME_STEP_USERS: {
            const { fromUser, toUsers, checkedColumns, matrixType } = action.payload;

            if (!state || state.type !== matrixType) {
                return state;
            }

            const fromUserData = state.data.find((matrixLine) => matrixLine.lineId === fromUser.id);

            if (!fromUserData) {
                return state;
            }

            const newData = [...state.data];
            const selectedRules = fromUserData.rules.map((rule) => {
                return {
                    ...rule,
                    conditions: rule.conditions.filter((condition) => checkedColumns.includes(condition.fieldId)),
                };
            });

            toUsers.forEach((user) => {
                const currentUserDataIndex = newData.findIndex((item) => item.lineId === user.id);

                if (currentUserDataIndex !== -1) {
                    const finalRules = selectedRules.map((rule, index) => {
                        if (newData[currentUserDataIndex].rules[index]) {
                            return {
                                ...rule,
                                conditions: unionBy(
                                    rule.conditions,
                                    newData[currentUserDataIndex].rules[index].conditions.filter(
                                        (condition) => !checkedColumns.includes(condition.fieldId)
                                    ),
                                    'fieldId'
                                ),
                            };
                        }

                        return rule;
                    });

                    newData[currentUserDataIndex] = {
                        ...newData[currentUserDataIndex],
                        rules: finalRules,
                    };
                } else {
                    newData.push({
                        lineId: user.id,
                        rules: selectedRules,
                        isBackup: false,
                    });
                }
            });

            return set(state, 'data', newData);
        }

        case ADD_FIELD_TO_ACTIVE_TEMPLATE:
            if (!state) {
                return state;
            }

            return set(state, 'generalFieldOrder', addArrayItem(state.generalFieldOrder, action.payload.field.id));

        case REMOVE_FIELD_FROM_ACTIVE_TEMPLATE:
            if (!state) {
                return state;
            }

            state = set(state, 'generalFieldOrder', removeArrayItem(state.generalFieldOrder, action.payload.fieldId));

            return set(
                state,
                'data',
                state.data.map((x) => {
                    return {
                        ...x,
                        rules: x.rules.map((r) => {
                            return {
                                ...r,
                                conditions: r.conditions.filter((c) => c.fieldId !== action.payload.fieldId),
                            };
                        }),
                    };
                })
            );

        case UPDATE_AMOUNT_TYPE_IN_ACTIVE_MATRIX:
            return set(state, 'amountType', action.payload.amountType);

        case CREATE_FIELD_RESPONSE:
            if (!state) {
                return state;
            }

            return set(
                state,
                'generalFieldOrder',
                addArrayItem(state.generalFieldOrder, Object.keys(action.entities!.fields!)[0])
            );

        case DISCARD_OPEN_MATRIX:
            return null;

        case APPLY_MATRIX:
            return action.payload.matrix.type === MatrixType.Editing ? state : null;

        case integrationActions.LOAD_FIELDS_FAILURE:
            if (!state) {
                return state;
            }

            return set(state, 'error', action.error);

        case RENAME_FIELD_RESPONSE:
            if (!state) {
                // Popup is already closed
                return state;
            }

            return set(state, 'modified', true);

        default:
            return state;
    }
}

export default function activeMatrixReducer(state: ActiveMatrix = null, action: Action): ActiveMatrix {
    const newState = activeMatrixReducerInternal(state, action);

    if (state && newState && state !== newState) {
        // modification of the current object (matrix)
        return set(newState, 'modified', true);
    }

    return newState;
}
