import React, { memo, useCallback, useMemo } from 'react';

import { ChevronLeftIcon, ChevronRightIcon, DotsFilledIcon } from '../../icons';
import Button from '../Button/Button';
import { Flex } from '../Flex/Flex';
import { PaginatorPage, PaginatorProps } from './Paginator.types';

export const Paginator = memo<PaginatorProps>((props) => {
    const { currentPageIndex, isLastPage, disabled, onPageChange } = props;

    const handleChangePage = useCallback(
        (pageIndex: number) => {
            if (disabled || typeof pageIndex === 'undefined') {
                return;
            }

            const isMinPage = pageIndex < 0;
            const isMaxPage = isLastPage && pageIndex > currentPageIndex;

            if (isMinPage || isMaxPage) {
                return;
            }

            onPageChange(pageIndex);
        },
        [disabled, isLastPage, onPageChange, currentPageIndex]
    );

    const pages = useMemo(() => {
        const pageArray: PaginatorPage[] = [];
        const indexLast = isLastPage ? currentPageIndex : currentPageIndex + 1;

        let indexCurrent = 0;

        if (indexLast > 4) {
            pageArray.push({ pageIndex: indexCurrent });
            pageArray.push({ ellipsis: true });

            indexCurrent = currentPageIndex - 1;
        }

        for (let index = indexCurrent; index <= indexLast; index += 1) {
            pageArray.push({
                pageIndex: index,
                active: currentPageIndex === index,
            });
        }

        if (!isLastPage) {
            pageArray.push({ ellipsis: true });
        }

        return pageArray;
    }, [isLastPage, currentPageIndex]);

    return (
        <Flex direction='row' alignItems='center' spacing='12' data-qa='paginator'>
            <Button
                size='small'
                color='blue10'
                disabled={currentPageIndex === 0 || disabled}
                tabIndex={disabled ? undefined : 0}
                data-qa='paginator-prev-page'
                onClick={() => handleChangePage(currentPageIndex - 1)}
            >
                <ChevronLeftIcon size={12} />
            </Button>

            <Flex direction='row' alignItems='center' spacing='4'>
                {pages.map(({ active, pageIndex }, index) => {
                    if (typeof pageIndex === 'number') {
                        return (
                            <Button
                                size='small'
                                key={pageIndex}
                                color={active ? 'blue80' : 'blue100'}
                                variant={active ? 'filled' : 'text'}
                                data-qa={`paginator-${pageIndex}-page`}
                                data-qa-name={active ? 'active' : undefined}
                                onClick={!active ? () => handleChangePage(pageIndex) : undefined}
                            >
                                {pageIndex + 1}
                            </Button>
                        );
                    }

                    return (
                        <Button key={`ellipsis-${index}`} size='small' variant='text' color='blue100'>
                            <DotsFilledIcon size={12} />
                        </Button>
                    );
                })}
            </Flex>

            <Button
                size='small'
                color='blue10'
                disabled={isLastPage || disabled}
                tabIndex={disabled ? undefined : 0}
                data-qa='paginator-next-page'
                onClick={() => handleChangePage(currentPageIndex + 1)}
            >
                <ChevronRightIcon size={12} />
            </Button>
        </Flex>
    );
});

Paginator.displayName = 'Paginator';
