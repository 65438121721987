import * as amplitude from '@amplitude/analytics-browser';
import { errorHelpers } from '@approvalmax/utils';
import { selectors } from 'modules/common';
import { domain, State } from 'modules/data';

class AmplituteService {
    public init(state: State) {
        try {
            const apiKey = window.ApprovalMax.amplitudeApiKey;

            if (!apiKey) {
                return;
            }

            const authenticated = selectors.session.authenticated(state);

            if (!authenticated) {
                return;
            }

            const profile = selectors.profile.findProfile(state);

            /*
             * Amplitude tracking is initialized for all authenticated users.
             * For authenticated users with a profile, we track additional properties including:
             * - User roles (Admin, User, Workflow Manager, Auditor)
             * - Integration types (Xero, QBooks)
             * - Account information
             * - Profile details
             */

            amplitude.init(apiKey, {
                autocapture: false,
            });

            if (authenticated && profile) {
                const companies = selectors.company.getCompanies(state);
                const integrations = selectors.integration.getIntegrations(state);
                const hasXeroIntegrationType = integrations.some(
                    (item) => item.integrationType === domain.IntegrationType.Xero
                );
                const hasQBooksIntegrationType = integrations.some(
                    (item) => item.integrationType === domain.IntegrationType.QBooks
                );

                const isOwner = companies.some((company) => company.author === profile.email);
                const isAdmin = companies.some((company) => company.managers.includes(profile.email));
                const isUser = companies.some((company) => company.participants.includes(profile.email));
                const isWorkflowManager = companies.some((company) => company.workflowManagers.includes(profile.email));
                const isAuditor = companies.some((company) => company.auditors.includes(profile.email));

                const userProperties = {
                    userProfileId: profile.id,
                    userProfileCreatedDate: profile.createdDate,
                    userRole: isOwner ? 'Owner' : 'Administrator',
                    accountType: profile.account?.accountType,
                    hasXeroConnection: hasXeroIntegrationType,
                    hasQBooksConnection: hasQBooksIntegrationType,
                    lifecycle: profile.isTrialUser ? 'trial' : 'paid',
                    accountId: profile.account?.accountId,
                    admin: isAdmin,
                    user: isUser,
                    workflowManager: isWorkflowManager,
                    auditor: isAuditor,
                };
                const identifyEvent = new amplitude.Identify();

                Object.entries(userProperties).forEach(([key, value]) => {
                    if (!value) return;
                    identifyEvent.set(key, value);
                });
                amplitude.identify(identifyEvent);
                amplitude.setUserId(profile.id);
            }
        } catch (error) {
            errorHelpers.captureException(error);
        }
    }

    public sendData(eventType: string, eventProperties?: any) {
        amplitude.track(eventType, eventProperties);
    }
}

export const amplitudeService = new AmplituteService();
