import { Button, Flex, LayoutWithSidebar, Popup } from '@approvalmax/ui/src/components';
import { useAtom } from 'jotai';
import { Fragment, memo } from 'react';
import bemFactory from 'react-bem-factory';

import {
    useApplyAndClose,
    useInitialTemplateSettings,
    useInitSections,
    useWorkflowSettingsPopupData,
    useWorkflowSettingsPopupTabs,
    useWorkflowSettingsTemplate,
} from './WorkflowSettingsPopupContent.hooks';
import { messages } from './WorkflowSettingsPopupContent.messages';
import { workflowSettingsPopupActiveSettings } from './WorkflowSettingsPopupContent.states';

const qa = bemFactory.qa('wfc-settings-popup');

export const WorkflowSettingsPopupContent = memo(() => {
    const template = useWorkflowSettingsTemplate();
    const { readonly, isSaveDisabled, savedTemplateSettings } = useWorkflowSettingsPopupData();

    const [templateSettings, setTemplateSettings] = useAtom(workflowSettingsPopupActiveSettings);

    useInitialTemplateSettings(savedTemplateSettings, setTemplateSettings);

    const settingSections = useInitSections(templateSettings, {
        templateSettings,
        setTemplateSettings,
        readonly,
        qa,
    });

    const { tabs, settingsSelected } = useWorkflowSettingsPopupTabs(settingSections);

    const applyAndClose = useApplyAndClose(templateSettings);

    return (
        <>
            <Popup.Header
                title={messages.title}
                actions={
                    <Button
                        color='blue10'
                        title={messages.saveButtonTitle({
                            templateName: template?.displayName,
                        })}
                        onClick={applyAndClose}
                        disabled={readonly || isSaveDisabled}
                        size='medium'
                    >
                        {messages.saveButton}
                    </Button>
                }
            />

            <Popup.Body spacing='0'>
                <LayoutWithSidebar bordered>
                    <LayoutWithSidebar.Sidebar items={tabs} />

                    <LayoutWithSidebar.Content>
                        <Flex spacing='32'>
                            {settingsSelected?.map((section) => {
                                return <Fragment key={section}>{settingSections[section]}</Fragment>;
                            })}
                        </Flex>
                    </LayoutWithSidebar.Content>
                </LayoutWithSidebar>
            </Popup.Body>
        </>
    );
});

WorkflowSettingsPopupContent.displayName = 'WorkflowSettingsPopupContent';
