import { Mods, mods, theme } from '@approvalmax/theme';
import { StyledMods } from '@styled-kit/mods';
import styled, { css } from 'styled-components';

import { ScrollableContainerProps } from './ScrollableContainer.types';

export const Root = styled.div<
    StyledMods<Mods<'orientation'>> &
        StyledMods<Pick<ScrollableContainerProps, 'spacingTop' | 'spacingRight' | 'spacingBottom' | 'spacingLeft'>>
>`
    min-width: fit-content;
    padding: ${theme.container.spacing.xsmall} ${theme.container.spacing.medium} ${theme.container.spacing.medium};
    flex-grow: 1;
    overflow-y: auto;

    ${mods.orientation.horizontal`
        width: 100%;
        overflow-y: visible;
        overflow-x: auto;
    `}

    ${mods('spacingTop')(
        (value) => css`
            padding-top: ${value}px;
        `
    )};

    ${mods('spacingRight')(
        (value) => css`
            padding-right: ${value}px;
        `
    )};

    ${mods('spacingBottom')(
        (value) => css`
            padding-bottom: ${value}px;
        `
    )};

    ${mods('spacingLeft')(
        (value) => css`
            padding-left: ${value}px;
        `
    )};
`;
