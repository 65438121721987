import { RequestMutationOptions } from '@approvalmax/data';
import { useMutate } from 'shared/data';

import { requestsApiPaths } from '../paths';
import { CancelAirwallexScheduledPayment } from './useCancelAirwallexScheduledPayment.types';

export const useCancelAirwallexScheduledPayment = (
    mutationOptions?: RequestMutationOptions<CancelAirwallexScheduledPayment>
) => {
    return useMutate(requestsApiPaths.cancelAirwallexScheduledPayment, { mutationOptions, apiVersion: 'v1' });
};
