import { domain } from 'modules/data';
import { TotalLine } from 'pages/requestList/types';
import { GetBatchPaymentsRequestAmounts } from 'shared/data/webApp/v2';
import { calculateBillAmountDue } from 'shared/helpers';

import { initialTotalForSupplier } from './XeroAmaxPayBatchPaymentRequestCard.constants';
import { messages } from './XeroAmaxPayBatchPaymentRequestCard.messages';
import { Supplier } from './XeroAmaxPayBatchPaymentRequestCard.types';

export const getUniqueSuppliers = (items: domain.AmaxPayPaymentItem[]): Supplier[] => {
    const supplierSet = new Set<string>();
    const suppliers: Supplier[] = [];

    items.forEach((item) => {
        const key = `${item.contact?.id},${item.paymentDetails?.id}`;

        if (item.contact && item.paymentDetails && !supplierSet.has(key)) {
            supplierSet.add(key);
            suppliers.push({
                key,
                contactName: item.contact.text,
                paymentDetailsName: item.paymentDetails.bankAccount.name,
            });
        }
    });

    return suppliers;
};

export const sortSuppliers = (suppliers: Supplier[]): Supplier[] => {
    return suppliers.sort((a, b) => {
        if (a.contactName !== b.contactName) {
            return a.contactName.localeCompare(b.contactName);
        }

        return a.paymentDetailsName.localeCompare(b.paymentDetailsName);
    });
};

export const groupAndSummarizeItemsBySupplier = <T extends domain.AmaxPayPaymentItem>(
    items: T[],
    suppliers: Supplier[],
    amounts: GetBatchPaymentsRequestAmounts['response']['items'] | undefined
): Array<TotalLine | T> => {
    let result: Array<TotalLine | T> = [];

    suppliers.forEach((supplier) => {
        const itemsForSupplier = items.reduce<T[]>((acc, item) => {
            if (supplier.key === `${item.contact?.id},${item.paymentDetails?.id}`) {
                const amountDue = amounts ? calculateBillAmountDue(item, amounts) : item.amountDue;

                acc.push({
                    ...item,
                    amountDue,
                });
            }

            return acc;
        }, []);

        const totalForSupplier = { ...initialTotalForSupplier } as TotalLine;

        itemsForSupplier.forEach((item) => {
            totalForSupplier.friendlyName = messages.billsTotalName({ contactName: item.contact?.text || '' });
            totalForSupplier.amount += item.amount;
            totalForSupplier.amountDue += item.amountDue;
            totalForSupplier.currency = item.currency;
            totalForSupplier.dueDate = item.dueDate;
            totalForSupplier.scheduledDate = item.scheduledDate;
            totalForSupplier.paymentReference = item.paymentReference || '';
            totalForSupplier.paymentStatus = item.paymentStatus;
            totalForSupplier.statusUpdatedAt = item.statusUpdatedAt;
            totalForSupplier.paymentDetailsPaymentStatus = item.paymentDetailsPaymentStatus;
            totalForSupplier.bankAccountName = item.paymentDetails?.bankAccount.name || '';
            totalForSupplier.amaxPayBankAccountId = item.amaxPayBankAccount?.id || '';
            totalForSupplier.xeroUrl = item.xeroUrl;
            totalForSupplier.id = `${item.contact?.id},${item.paymentDetails?.id}`;
            totalForSupplier.billRequestIdList = [...totalForSupplier.billRequestIdList, item.xeroBillInvoiceRequestId];
            totalForSupplier.oneOfBillRequestsId = item.xeroBillInvoiceRequestId;
        });

        result = [...result, totalForSupplier, ...itemsForSupplier];
    });

    return result;
};
