import { produce } from 'immer';
import { domain } from 'modules/data';
import { formatDelegateFrom, formatDelegateTo } from 'modules/delegates';
import { immutable } from 'modules/immutable';
import moment from 'moment';
import { shallowEqual } from 'react-redux';

import {
    Action,
    ADD_BETA_FEATURE,
    APPLY_MY_DELEGATES_RESPONSE,
    APPLY_SAVED_PROFILE_CHANGES,
    CHANGE_PASSWORD_RESPONSE,
    CHANGE_TWO_FA_ALTERNATIVE_EMAIL_IS_ENABLED,
    CHANGE_TWO_FA_BACKUP_CODES_IS_ENABLED,
    CHANGE_TWO_FA_IS_ENABLED,
    REMOVE_BETA_FEATURE,
} from '../actions';

const PROFILE_BETA_FEATURES = 'PROFILE_BETA_FEATURES';

export default function (state: domain.Profile, action: Action): domain.Profile {
    switch (action.type) {
        case APPLY_SAVED_PROFILE_CHANGES:
            if (!shallowEqual(action.payload.newProfile, state)) {
                return immutable(action.payload.newProfile);
            }

            return state;

        case APPLY_MY_DELEGATES_RESPONSE: {
            return produce(state, (draft) => {
                draft.delegates = action.payload.request.delegates
                    .filter((delegate) => !!delegate.delegateId)
                    .map((delegate) => ({
                        companyId: delegate.companyId,
                        delegateUserId: delegate.delegateId,
                        delegateFrom: delegate.delegateFrom
                            ? formatDelegateFrom(moment(delegate.delegateFrom))
                            : formatDelegateFrom(moment()),
                        ...(delegate.delegateTo && {
                            delegateTo: formatDelegateTo(moment(delegate.delegateTo)),
                        }),
                    }));

                return draft;
            });
        }

        case CHANGE_PASSWORD_RESPONSE:
            return produce(state, (draft) => {
                draft.isPasswordSet = true;

                return draft;
            });

        case CHANGE_TWO_FA_IS_ENABLED:
            return produce(state, (draft) => {
                draft.is2faEnabled = action.payload.twoFaIsEnabled;

                return draft;
            });

        case ADD_BETA_FEATURE:
            return produce(state, (draft) => {
                if (!draft.betaFeatures.includes(action.payload.newBetaFeature)) {
                    const updatedBetaFeatures = [...draft.betaFeatures, action.payload.newBetaFeature];

                    localStorage.setItem(PROFILE_BETA_FEATURES, JSON.stringify(updatedBetaFeatures));
                    draft.betaFeatures = updatedBetaFeatures;
                }

                return draft;
            });

        case REMOVE_BETA_FEATURE:
            return produce(state, (draft) => {
                const updatedBetaFeatures = draft.betaFeatures.filter(
                    (feature) => feature !== action.payload.featureToRemove
                );

                localStorage.setItem(PROFILE_BETA_FEATURES, JSON.stringify(updatedBetaFeatures));
                draft.betaFeatures = updatedBetaFeatures;

                return draft;
            });

        case CHANGE_TWO_FA_BACKUP_CODES_IS_ENABLED:
            return produce(state, (draft) => {
                draft.isTFABackupCodesEnabled = action.payload.isEnabled;

                return draft;
            });

        case CHANGE_TWO_FA_ALTERNATIVE_EMAIL_IS_ENABLED:
            return produce(state, (draft) => {
                draft.isTFAAlternativeEmailEnabled = action.payload.isEnabled;

                return draft;
            });

        default:
            return state;
    }
}
