import { ApiError, ErrorCode } from '@approvalmax/types';
import { useQuery } from '@tanstack/react-query';
import { constants, QueryKeys, selectors } from 'modules/common';
import { domain, stateTree } from 'modules/data';
import { reloadRequestList } from 'pages/requestList/actions';
import { PAGE_ID } from 'pages/requestList/config';
import { getPagination, isInSearchMode } from 'pages/requestList/selectors/pageSelectors';
import { useDispatch, useSelector } from 'react-redux';
import { api } from 'services/api';
import { notificationService } from 'services/notification';

import { isSyncInProgress } from './SyncDropdownContent.helpers';
import { messages } from './SyncDropdownContent.messages';

const { TEMPORARY_ID_PREFIX, SYNC_DATA_API_CALL_INTERVAL } = constants.commonConstants;

export const useSyncData = (companies: selectors.types.ExpandedCompany[]) => {
    const dispatch = useDispatch();

    const readyToReloadRequestList = useSelector((state: stateTree.State) => {
        const inRequestListPage = selectors.navigation.getActivePageId(state) === PAGE_ID;

        const inRequestListPageSearchMode = inRequestListPage ? isInSearchMode(state) : false;

        const alreadyReloading = inRequestListPage && getPagination(state).reloading;

        return !inRequestListPageSearchMode && inRequestListPage && !alreadyReloading;
    });

    const allIntegrationIds = companies.reduce(
        (accumData, { integrationId }) =>
            integrationId && !integrationId.startsWith(TEMPORARY_ID_PREFIX) ? [...accumData, integrationId] : accumData,
        []
    );

    const {
        data = [],
        isInitialLoading,
        refetch,
        isFetching,
    } = useQuery(
        [QueryKeys.INTEGRATION_SYNC_PROGRESS],
        async () => {
            const syncAnswer = await api.companies.getIntegrationSyncProgress({
                integrationIds: allIntegrationIds,
            });

            return syncAnswer.Integrations.map(domain.schemas.mapExtendedIntegration);
        },
        {
            onError: (e: ApiError) => {
                const errorCode = e?.code;

                if (errorCode === ErrorCode.E2014_COMPANY_NOT_FOUND) {
                    window.location.reload();
                }
            },
            onSuccess: (newData) => {
                const previewSuccessTemplateId = data
                    .flatMap((item) => item.syncProgress)
                    .filter((item) => item.status === domain.IntegrationSyncStatus.Done)
                    .map((item) => item.templateId);

                const currentSuccessTemplates = newData
                    .flatMap((item) => item.syncProgress)
                    .filter((item) => item.status === domain.IntegrationSyncStatus.Done);

                const finishedTemplates = currentSuccessTemplates.filter(
                    (item) => !previewSuccessTemplateId.includes(item.templateId)
                );

                if (data.length !== 0) {
                    for (const template of finishedTemplates) {
                        const templateName = selectors.template.getTemplateDisplayNameByCode(
                            template.templateIntegrationCode,
                            true
                        );
                        const integrationName = selectors.integration.getIntegrationTypeName(
                            selectors.integration.getIntegrationType(template.templateIntegrationCode)
                        );
                        const integration = newData.find((integration) =>
                            integration.syncProgress
                                .map((syncState) => syncState.templateId)
                                .includes(template.templateId)
                        );

                        notificationService.showInfoToast(
                            messages.syncFinishedMessage({
                                templateName,
                                companyName: integration?.integratedCompanyName,
                                integrationName,
                            })
                        );
                    }
                }

                const syncInProgress = isSyncInProgress(newData);

                if (syncInProgress) {
                    setTimeout(() => {
                        refetch();
                    }, SYNC_DATA_API_CALL_INTERVAL);
                } else if (isSyncInProgress(data)) {
                    if (readyToReloadRequestList && !syncInProgress) {
                        dispatch(reloadRequestList());
                    }
                }
            },
            staleTime: Infinity,
            enabled: allIntegrationIds.length > 0,
        }
    );

    return { data, isInitialLoading, refetch, isFetching };
};
