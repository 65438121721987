import { FC, memo, useCallback } from 'react';

import { Button, Flex } from '../../../../components';
import {
    TextEditBoldIcon,
    TextEditEraserIcon,
    TextEditItalicIcon,
    TextEditListIcon,
    TextEditNumListIcon,
    TextEditUnderlineIcon,
} from '../../../../icons';
import { messages } from './Menubar.messages';
import { MenuBarProps } from './MenuBar.types';

export const MenuBar: FC<MenuBarProps> = memo((props) => {
    const { editor } = props;

    const toggleBold = useCallback(() => {
        editor.chain().focus().toggleBold().run();
    }, [editor]);

    const toggleItalic = useCallback(() => {
        editor.chain().focus().toggleItalic().run();
    }, [editor]);

    const toggleUnderline = useCallback(() => {
        editor.chain().focus().toggleUnderline().run();
    }, [editor]);

    const toggleOrderedList = useCallback(() => {
        editor.chain().focus().toggleOrderedList().run();
    }, [editor]);

    const toggleBulletList = useCallback(() => {
        editor.chain().focus().toggleBulletList().run();
    }, [editor]);

    const clearFormatting = useCallback(() => {
        editor.chain().focus().unsetAllMarks().run();
        editor.chain().focus().clearNodes().run();
    }, [editor]);

    const getIconColor = useCallback(
        (name: string) => (editor.isActive(name) ? 'midnight100' : 'midnight70'),
        [editor]
    );

    return (
        <Flex spacing='8'>
            <Button icon title={messages.boldTitle} noPadding onClick={toggleBold} variant='text' color='blue100'>
                <TextEditBoldIcon color={getIconColor('bold')} size={20} />
            </Button>

            <Button icon title={messages.italicTitle} noPadding onClick={toggleItalic} variant='text' color='blue100'>
                <TextEditItalicIcon color={getIconColor('italic')} size={20} />
            </Button>

            <Button
                icon
                title={messages.underlineTitle}
                noPadding
                onClick={toggleUnderline}
                variant='text'
                color='blue100'
            >
                <TextEditUnderlineIcon color={getIconColor('underline')} size={20} />
            </Button>

            <Button
                icon
                title={messages.orderedListTitle}
                noPadding
                onClick={toggleOrderedList}
                variant='text'
                color='blue100'
            >
                <TextEditNumListIcon color={getIconColor('orderedList')} size={20} />
            </Button>

            <Button
                icon
                title={messages.bulletListTitle}
                noPadding
                onClick={toggleBulletList}
                variant='text'
                color='blue100'
            >
                <TextEditListIcon color={getIconColor('bulletList')} size={20} />
            </Button>

            <Button
                icon
                title={messages.eraserTitle}
                noPadding
                onClick={clearFormatting}
                variant='text'
                color='blue100'
            >
                <TextEditEraserIcon color='midnight70' size={20} />
            </Button>
        </Flex>
    );
});

MenuBar.displayName = 'MenuBar';
