import { Flex, toast } from '@approvalmax/ui/src/components';
import { useSetAtom } from 'jotai';
import { domain, factories } from 'modules/data';
import { FC, Fragment, memo, useCallback, useMemo, useRef } from 'react';
import { useDispatch } from 'react-redux';

import { addRuleToActiveMatrix, removeLineFromActiveMatrix, removeRuleFromActiveMatrix } from '../../../../actions';
import { MatrixType } from '../../../../types/matrix';
import { checkValidMatrixConditions } from '../../../../utils/helpers';
import { matrixCopyRuleState } from '../CopyRulePopup/CopyRulePopup.states';
import { EditingPermissionCell } from '../EditingPermissionCell/EditingPermissionCell';
import { FirstUserCell } from '../FirstUserCell/FirstUserCell';
import { MatrixLineCellsContainer } from '../MatrixLineContainer/MatrixLineCellsContainer';
import { OrUserCell } from '../OrUserCell/OrUserCell';
import { useMatrixLine } from './MatrixLine.hooks';
import { messages } from './MatrixLine.messages';
import { MatrixLineProps } from './MatrixLine.types';

export const MatrixLine: FC<MatrixLineProps> = memo((props) => {
    const {
        team,
        line,
        readonly,
        definition,
        cellFields,
        matrixType,
        templateSubmitters,
        company,
        onCreateFieldOption,
        createOnConditionChange,
        requiredFieldIds,
        matrix,
        hasAccessToEditingMatrix,
        template,
        highlight,
    } = props;

    const dispatch = useDispatch();
    const stepIndex = matrix.type === MatrixType.Approval ? matrix.stepIndex : 0;
    const conditionCache = useRef<{ [fieldId: string]: domain.MatrixCondition }>({});
    const teamIds = useMemo(() => team.map((user) => user.id), [team]);
    const {
        ifText,
        orIfText,
        removeUserText,
        removeUserTitle,
        showRemoveUser,
        showRemoveRuleAction,
        allowMultipleRules,
        showCopyRule,
    } = useMatrixLine({ matrix, line, template });
    const setMatrixCopyRule = useSetAtom(matrixCopyRuleState);

    const editingMatrixLine = useMemo(
        () => template.steps[stepIndex]?.editingMatrix.find((editingLine) => editingLine.lineId === line.lineId),
        [line.lineId, stepIndex, template.steps]
    );

    const onCopyRule = useCallback(
        (user: domain.User) => {
            const isValidMatrixConditions = checkValidMatrixConditions(matrix, user.id);

            if (isValidMatrixConditions) {
                setMatrixCopyRule(() => ({
                    isOpen: true,
                    fromUser: user,
                    addedUsers: [],
                    matrixType: matrix.type,
                }));
            } else {
                toast.error(messages.invalidConditions);
            }
        },
        [matrix, setMatrixCopyRule]
    );

    const removeUser = useCallback(
        (user: domain.User) => {
            dispatch(removeLineFromActiveMatrix(user.id));
        },
        [dispatch]
    );

    const addRule = useCallback(
        (user: domain.User) => {
            dispatch(addRuleToActiveMatrix(user));
        },
        [dispatch]
    );

    const removeRule = useCallback(
        (user: domain.User, rule: domain.MatrixRule) => {
            dispatch(removeRuleFromActiveMatrix(user, rule));
        },
        [dispatch]
    );

    const ruleLines = useMemo(
        () =>
            line.rules.map((rule, ruleIndex) => {
                const isFirstRule = ruleIndex === 0;
                const isLastRule = ruleIndex === line.rules.length - 1;
                const userCell = isFirstRule ? (
                    <FirstUserCell
                        team={team}
                        user={line.user}
                        isCompanyMember={teamIds.includes(line.user.id)}
                        rule={rule}
                        readonly={readonly}
                        ifText={ifText}
                        removeUserText={removeUserText}
                        removeUserTitle={removeUserTitle}
                        onAddRule={addRule}
                        showRemoveRuleAction={showRemoveRuleAction}
                        showRemoveUserAction={showRemoveUser}
                        showAddRuleAction={allowMultipleRules}
                        showCopyRuleAction={showCopyRule}
                        onRemoveRule={removeRule}
                        onRemoveUser={removeUser}
                        onCopyRule={onCopyRule}
                    />
                ) : (
                    <OrUserCell
                        user={line.user}
                        rule={rule}
                        readonly={readonly}
                        orIfText={orIfText}
                        onRemoveRule={removeRule}
                    />
                );

                const cells = definition.columns.flatMap((colDef) =>
                    cellFields
                        .filter((field) => field.systemPurpose === colDef.systemPurpose)
                        .map((field) => {
                            let condition = rule.conditions.find((c) => c.fieldId === field.id);

                            if (!condition) {
                                condition = conditionCache.current[field.id];

                                if (!condition) {
                                    conditionCache.current[field.id] = condition =
                                        factories.condition.createCondition(field);
                                }
                            }

                            return (
                                <Fragment key={field.id}>
                                    {colDef.renderCell({
                                        field,
                                        integrationCode: template.integrationCode,
                                        matrixType,
                                        readonly,
                                        company,
                                        condition,
                                        rule,
                                        lineId: line.lineId,
                                        templateSubmitters,
                                        onConditionChange: createOnConditionChange(ruleIndex),
                                        onCreateFieldOption,
                                        requiredFieldIds,
                                    })}
                                </Fragment>
                            );
                        })
                );

                return (
                    <MatrixLineCellsContainer divider={isLastRule} key={ruleIndex} highlight={highlight}>
                        {userCell}

                        {hasAccessToEditingMatrix && (
                            <EditingPermissionCell
                                stepIndex={stepIndex}
                                participantMatrix={matrix.data}
                                editingMatrixLine={editingMatrixLine}
                                lineId={line.lineId}
                            />
                        )}

                        {cells}
                    </MatrixLineCellsContainer>
                );
            }),
        [
            highlight,
            line.rules,
            line.user,
            line.lineId,
            team,
            teamIds,
            readonly,
            ifText,
            removeUserText,
            removeUserTitle,
            addRule,
            showRemoveRuleAction,
            showRemoveUser,
            allowMultipleRules,
            showCopyRule,
            removeRule,
            removeUser,
            onCopyRule,
            orIfText,
            definition.columns,
            hasAccessToEditingMatrix,
            stepIndex,
            matrix.data,
            editingMatrixLine,
            cellFields,
            template.integrationCode,
            matrixType,
            company,
            templateSubmitters,
            createOnConditionChange,
            onCreateFieldOption,
            requiredFieldIds,
        ]
    );

    return (
        <td>
            <Flex direction='column'>{ruleLines}</Flex>
        </td>
    );
});

MatrixLine.displayName = 'MatrixLine';
