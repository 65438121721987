import { CheckIcon, ClockIcon } from '@approvalmax/ui';
import { Box, ColumnDefinition, Flex, Radio, Table, Text } from '@approvalmax/ui/src/components';
import { intl } from '@approvalmax/utils';
import { domain } from 'modules/data';
import { AmountDueIndicator } from 'modules/request';
import moment from 'moment';
import { isTotalLine, TotalLine } from 'pages/requestList/types';
import Skeleton from 'react-loading-skeleton';
import { AmaxPayPaymentStatusTag } from 'shared/components';
import { GetBatchPaymentsRequestAmounts } from 'shared/data/webApp/v2';

import { messages } from './PaymentSummaryPopupContent.messages';
import { AmountDueWarning, StyledArrowReplayIcon } from './PaymentSummaryPopupContent.styles';
import { AmaxPayPaymentItemWithId } from './PaymentSummaryPopupContent.types';

export const getTableColumns = (
    isMergedBySupplier: boolean,
    isBatchPaymentAmountsLoading: boolean,
    batchPaymentAmounts: GetBatchPaymentsRequestAmounts['response']['items']
): ColumnDefinition<AmaxPayPaymentItemWithId | TotalLine>[] => [
    {
        name: messages.order,
        id: 'xeroBillInvoiceRequestId',
        title: () => '',
        cellComponent: (props) => {
            const { item } = props;

            if (isMergedBySupplier && !isTotalLine(item)) {
                return <Table.Cell {...props}> </Table.Cell>;
            }

            const canPay =
                item.paymentStatus === domain.AmaxPayPaymentStatus.ReadyToPay ||
                item.paymentStatus === domain.AmaxPayPaymentStatus.Failed;

            const showClockIcon =
                item.paymentStatus &&
                [domain.AmaxPayPaymentStatus.Processing, domain.AmaxPayPaymentStatus.Scheduled].includes(
                    item.paymentStatus
                );

            const icon = showClockIcon ? (
                <ClockIcon size={16} color='blue100' />
            ) : (
                <CheckIcon size={16} color='green100' />
            );

            return (
                <Table.Cell {...props}>
                    {canPay ? <Radio value={isTotalLine(item) ? item.id : item.xeroBillInvoiceRequestId} /> : icon}
                </Table.Cell>
            );
        },
    },
    {
        name: messages.bill,
        id: 'friendlyName',
        cellComponent: (props) => {
            const { item } = props;

            return (
                <Table.Cell {...props}>
                    <Flex spacing='8' wrap={false} alignItems='center'>
                        {isMergedBySupplier && !isTotalLine(item) && (
                            <Box width={12}>
                                <StyledArrowReplayIcon size={12} color='midnight60' />
                            </Box>
                        )}

                        <Text
                            font='label'
                            color={isMergedBySupplier && !isTotalLine(item) ? 'midnight70' : 'midnight100'}
                        >
                            {item.friendlyName}
                        </Text>
                    </Flex>
                </Table.Cell>
            );
        },
    },
    {
        name: messages.dueDate,
        id: 'dueDate',
        cellComponent: (props) => {
            const { item } = props;

            const value = moment(item.dueDate).format('ll');

            return (
                <Table.Cell {...props}>
                    <Text font='label' color={isMergedBySupplier && !isTotalLine(item) ? 'midnight70' : 'midnight100'}>
                        {value}
                    </Text>
                </Table.Cell>
            );
        },
    },
    {
        name: messages.amountDue,
        id: 'amountDue',
        headerTextAlign: 'right',
        textAlign: 'right',
        cellComponent: (props) => {
            const { item } = props;

            if (isBatchPaymentAmountsLoading) {
                return (
                    <Table.Cell {...props}>
                        <Skeleton />
                    </Table.Cell>
                );
            }

            const amountDueFormatted = intl.formatCurrency(item.amountDue, item.currency);

            const billAmounts = !isTotalLine(item)
                ? batchPaymentAmounts?.find((amount) => amount.invoiceRequestId === item.xeroBillInvoiceRequestId)
                : undefined;

            if (isMergedBySupplier && !isTotalLine(item)) {
                return (
                    <Table.Cell {...props}>
                        <AmountDueIndicator
                            currency={item.currency}
                            amountAwaitingPayment={billAmounts?.amountAwaitingPayment}
                            amountInProcessing={billAmounts?.amountInProcessing}
                            amountOnApproval={billAmounts?.amountOnApproval}
                            amountOnReview={billAmounts?.amountOnReview}
                        >
                            <Text font='label' color='midnight70'>
                                {amountDueFormatted}
                            </Text>
                        </AmountDueIndicator>
                    </Table.Cell>
                );
            }

            return (
                <Table.Cell {...props}>
                    <AmountDueIndicator
                        currency={item.currency}
                        amountAwaitingPayment={billAmounts?.amountAwaitingPayment}
                        amountInProcessing={billAmounts?.amountInProcessing}
                        amountOnApproval={billAmounts?.amountOnApproval}
                        amountOnReview={billAmounts?.amountOnReview}
                    >
                        {item.amountDue < item.amount && item.paymentStatus !== domain.AmaxPayPaymentStatus.Paid ? (
                            <AmountDueWarning>{amountDueFormatted}</AmountDueWarning>
                        ) : (
                            amountDueFormatted
                        )}
                    </AmountDueIndicator>
                </Table.Cell>
            );
        },
    },
    {
        name: messages.amount,
        id: 'amount',
        headerTextAlign: 'right',
        textAlign: 'right',
        value: (item) => intl.formatCurrency(item.amount, item.currency),
        cellComponent: (props) => {
            const { item } = props;

            const value = intl.formatCurrency(item.amount, item.currency);

            return (
                <Table.Cell {...props}>
                    <Text font='label' color={isMergedBySupplier && !isTotalLine(item) ? 'midnight70' : 'midnight100'}>
                        {value}
                    </Text>
                </Table.Cell>
            );
        },
    },
    {
        name: messages.status,
        id: 'status',
        title: () => '',
        cellComponent: (props) => {
            const { item } = props;

            if (isMergedBySupplier && !isTotalLine(item)) {
                return <Table.Cell {...props}> </Table.Cell>;
            }

            return (
                <Table.Cell {...props}>
                    <AmaxPayPaymentStatusTag paymentStatus={item.paymentStatus} />
                </Table.Cell>
            );
        },
    },
];
