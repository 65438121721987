import { RequestMutationOptions } from '@approvalmax/data';
import { useMutate } from 'shared/data';

import { requestsApiPaths } from '../paths';
import { CompleteReview } from './useCompleteReview.types';

export const useCompleteReview = (mutationOptions?: RequestMutationOptions<CompleteReview>) => {
    return useMutate(requestsApiPaths.completeReview, {
        mutationOptions,
        apiVersion: 'v1',
        method: 'post',
    });
};
