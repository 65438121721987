import { produce } from 'immer';
import { stateTree } from 'modules/data';

import { Action, LOAD_INITIAL_APP_DATA, RESPOND_TO_INVITATION_RESPONSE } from '../actions';

export default produce((draft: stateTree.Meta, action: Action): stateTree.Meta => {
    switch (action.type) {
        case LOAD_INITIAL_APP_DATA:
        case RESPOND_TO_INVITATION_RESPONSE:
            draft.creatableTemplates = action.payload.creatableTemplates;
            draft.viewableTemplates = action.payload.viewableTemplates;
            draft.pendingInvitationsLength = action.payload.pendingInvitationsLength;

            return draft;

        default:
            return draft;
    }
});
