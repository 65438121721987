import { useConfirmation } from '@approvalmax/ui/src/components';
import { MouseEventHandler, useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDeleteFido2Credential, useUpdateFido2CredentialLabel } from 'shared/data/webApp/v2';
import { useCreatePasskey } from 'shared/hooks';

import { generatePasskeyLabel } from '../../SectionSecurity.helpers';
import { fieldNamePasskeyForm } from './PasskeyItem.constants';
import { messages } from './PasskeyItem.messages';
import { PasskeyItemFormState, PasskeyItemProps } from './PasskeyItem.types';

export const usePasskeyItemForm = (props: PasskeyItemProps) => {
    const { credential, onChange, onCancel, onDelete } = props;

    const [isEditable, setIsEditable] = useState(!credential);

    const { confirmation } = useConfirmation({
        confirmMessage: messages.areYouSure,
        confirmButtonMessage: messages.yes,
    });

    const form = useForm<PasskeyItemFormState>({
        values: {
            [fieldNamePasskeyForm.label]: credential?.label ?? generatePasskeyLabel() ?? '',
            [fieldNamePasskeyForm.newKey]: 'none',
        },
    });

    const { deleteCredential, isDeletingCredential } = useDeleteCredentials({
        onSuccess: onDelete,
    });

    const { updateCredential, isUpdatingCredential } = useUpdateCredentials({
        onSuccess: () => {
            onChange();
            setIsEditable(false);
        },
    });

    const { createPasskey, isCreatingPasskey } = useCreatePasskey({
        onSuccess: () => {
            setIsEditable(false);
            onCancel?.();

            form.reset();
        },
    });

    const handleSubmitForm = form.handleSubmit((data) => {
        if (credential?.credentialId === undefined) {
            void createPasskey(data[fieldNamePasskeyForm.label]);
        } else {
            updateCredential({
                body: {
                    credentialId: credential.credentialId,
                    newLabel: data[fieldNamePasskeyForm.label],
                },
            });
        }
    });

    const handleEdit: MouseEventHandler<HTMLButtonElement> = useCallback((event) => {
        event.preventDefault();

        setIsEditable(true);
    }, []);

    const handleEditCancel: MouseEventHandler<HTMLButtonElement> = useCallback(
        (event) => {
            event.preventDefault();

            setIsEditable(false);
            onCancel?.();
        },
        [onCancel]
    );

    const handleDelete: MouseEventHandler<HTMLButtonElement> = useCallback(
        (event) => {
            event.preventDefault();

            confirmation()
                .then(() => {
                    credential?.credentialId && deleteCredential({ body: { credentialId: credential.credentialId } });
                })
                .catch(() => {});
        },
        [deleteCredential, credential?.credentialId, confirmation]
    );

    return {
        form,
        handleSubmitForm,
        handleEdit,
        handleEditCancel,
        handleDelete,
        isEditable,
        isDeletingCredential,
        isUpdatingCredential,
        isCreatingPasskey,
    };
};

const useUpdateCredentials = (options?: { onSuccess?: VoidFunction }) => {
    const { mutate, isLoading } = useUpdateFido2CredentialLabel({
        onSuccess: options?.onSuccess,
    });

    return {
        updateCredential: mutate,
        isUpdatingCredential: isLoading,
    };
};

const useDeleteCredentials = (options?: { onSuccess?: VoidFunction }) => {
    const { mutate, isLoading } = useDeleteFido2Credential({
        onSuccess: options?.onSuccess,
    });

    return {
        deleteCredential: mutate,
        isDeletingCredential: isLoading,
    };
};
