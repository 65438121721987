import { AirwallexLogo, XeroLogo } from '@approvalmax/ui';
import { Button } from '@approvalmax/ui/src/components';
import { intl } from '@approvalmax/utils';
import upperCase from 'lodash/upperCase';
import AttachmentsDropdown from 'modules/components/Attachment/AttachmentsDropdown';
import { domain } from 'modules/data';
import { AmountDueIndicator } from 'modules/request';
import BeneficiaryIndicator from 'modules/request/components/xero/BeneficiaryIndicator/BeneficiaryIndicator';
import PaymentAmountIndicator from 'modules/request/components/xero/PaymentAmountIndicator/PaymentAmountIndicator';
import moment from 'moment';
import { ColumnDefinition } from 'pages/requestList/selectors/types/ColumnDefinition';
import Skeleton from 'react-loading-skeleton';
import { calculateBillAmountDue } from 'shared/helpers';

import PaymentStatusBadge from '../PaymentStatusBadge/PaymentStatusBadge';
import { messages } from './BillsInformationSection.messages';
import { Date, ExternalLink, LinksBlock, TextBlock, WarningBlock } from './BillsInformationSection.styles';
import { ColumnOptions } from './BillsInformationSection.types';

export const getColumnDefinitions = ({
    onSelectAttachment,
    activeAttachmentId,
    openBill,
    amounts,
    isAmountsLoading,
    isBatchPaymentAmountsV2,
}: ColumnOptions): ColumnDefinition<domain.AirwallexPaymentItem>[] => {
    const columns: ColumnDefinition<domain.AirwallexPaymentItem>[] = [
        {
            id: 'item',
            name: messages.items,
            value: (li) => li.friendlyName,
            cell: (value, li) => (
                <TextBlock width={150}>
                    <Button
                        noUppercase
                        size='small'
                        color='blue100'
                        variant='text'
                        onClick={() => openBill(li.xeroBillInvoiceRequestId)}
                    >
                        {li.friendlyName}
                    </Button>
                </TextBlock>
            ),
        },
        {
            id: 'dueDate',
            name: messages.dueDate,
            value: (li) => moment.utc(li.dueDate).format('ll'),
            cell: (value) => <TextBlock width={50}>{value}</TextBlock>,
        },
        {
            id: 'amountDue',
            name: messages.amountDue,
            value: (li) => {
                if (isBatchPaymentAmountsV2) {
                    const amountDue = calculateBillAmountDue(li, amounts);

                    return intl.formatCurrency(amountDue, li.currency);
                }

                return intl.formatCurrency(li.amountDue, li.currency);
            },
            alignRight: true,
            cell: (value, li) => {
                if (isAmountsLoading) {
                    return <Skeleton />;
                }

                const billAmount = amounts?.find((amount) => amount.invoiceRequestId === li.xeroBillInvoiceRequestId);

                return (
                    <AmountDueIndicator
                        currency={li.currency}
                        amountAwaitingPayment={billAmount?.amountAwaitingPayment ?? li.amountAwaitingPayment}
                        amountInProcessing={billAmount?.amountInProcessing ?? li.amountInProcessing}
                        amountOnApproval={billAmount?.amountOnApproval ?? li.amountOnApproval}
                        amountOnReview={billAmount?.amountOnReview ?? li.amountOnReview}
                    >
                        {value}
                    </AmountDueIndicator>
                );
            },
        },
        {
            id: 'paymentAmount',
            name: messages.paymentAmount,
            value: (li) => intl.formatCurrency(li.amount, li.currency) || '—',
            cell: (value, li) =>
                li.amount <= li.amountDue ? (
                    <div>{value}</div>
                ) : (
                    <PaymentAmountIndicator>
                        <WarningBlock>{value}</WarningBlock>
                    </PaymentAmountIndicator>
                ),
            alignRight: true,
        },
        {
            id: 'beneficiary',
            name: messages.beneficiary,
            value: (li) => li.beneficiary?.text || '—',
            cell: (value, li) => (
                <BeneficiaryIndicator
                    beneficiary={li.beneficiary}
                    status={li.beneficiaryPaymentStatus}
                    lastPaymentProperties={li.beneficiaryDiscrepancy?.lastPaymentProperties}
                >
                    <TextBlock width={100}>{value}</TextBlock>
                </BeneficiaryIndicator>
            ),
        },
        {
            id: 'paymentMethod',
            name: messages.paymentMethod,
            value: (li) => upperCase(li.beneficiary?.paymentMethod?.text || ''),
            cell: (value) => <TextBlock width={60}>{value}</TextBlock>,
        },
        {
            id: 'fee',
            name: messages.fee,
            value: (li) => li.paymentFee?.text || '—',
            cell: (value) => <TextBlock width={45}>{value}</TextBlock>,
        },
        {
            id: 'sourceCurrency',
            name: messages.sourceCurrency,
            value: (li) => li.sourceCurrency?.text || '—',
            cell: (value) => <TextBlock width={50}>{value}</TextBlock>,
        },
        {
            id: 'paymentReference',
            name: messages.paymentReference,
            value: (li) => li.paymentReference || '—',
            cell: (value) => <TextBlock width={100}>{value}</TextBlock>,
        },
        {
            id: 'purpose',
            name: messages.purpose,
            value: (li) => li.paymentPurpose?.text || '—',
            cell: (value) => <TextBlock width={100}>{value}</TextBlock>,
        },
        {
            id: 'xeroBankAccount',
            name: messages.xeroBankAccount,
            value: (li) => li.bankAccount?.text || '—',
            cell: (value) => <TextBlock width={80}>{value}</TextBlock>,
        },
        {
            id: 'attachments',
            name: messages.att,
            value: (li) => String(li.attachments?.length),
            alwaysVisible: true,
            cell: (val, li) =>
                li.attachments && li.attachments.length > 0 ? (
                    <AttachmentsDropdown
                        attachments={li.attachments}
                        activeAttachmentId={activeAttachmentId}
                        onSelectAttachment={(attachmentId: string) =>
                            onSelectAttachment({
                                attachmentId,
                                xeroBatchPaymentInvoiceId: li.xeroBillInvoiceRequestId,
                                xeroBatchPaymentInvoiceName: li.friendlyName,
                            })
                        }
                    />
                ) : (
                    '—'
                ),
        },
    ];

    return columns;
};

export const getColumnDefinitionsWithPaymentDetails = ({
    onSelectAttachment,
    activeAttachmentId,
    openBill,
    defaultOpenAttachments,
}: ColumnOptions): ColumnDefinition<domain.AirwallexPaymentItem>[] => {
    const columns: ColumnDefinition<domain.AirwallexPaymentItem>[] = [
        {
            id: 'item',
            name: messages.items,
            value: (li) => li.friendlyName,
            cell: (value, li) => (
                <TextBlock width={80}>
                    <Button
                        noUppercase
                        size='small'
                        color='blue100'
                        variant='text'
                        onClick={() => openBill(li.xeroBillInvoiceRequestId)}
                    >
                        {li.friendlyName}
                    </Button>
                </TextBlock>
            ),
        },
        {
            id: 'amount',
            name: messages.amount,
            value: (li) => intl.formatCurrency(li.amount, li.currency) || '—',
            cell: (value: string) => <TextBlock width={50}>{value}</TextBlock>,
            alignRight: true,
        },
        {
            id: 'sourceCurrencyAmount',
            name: messages.sourceCurrencyAmount({
                br: <br />,
            }),
            value: (li) =>
                (li.sourceCurrency?.id &&
                    !!li.sourceAmount &&
                    intl.formatCurrency(li.sourceAmount, li.sourceCurrency.id)) ||
                '—',
            cell: (value) => <TextBlock width={50}>{value}</TextBlock>,
            alignRight: true,
        },
        {
            id: 'feeAmount',
            name: messages.feeAmount,
            value: (li) =>
                (li.sourceCurrency?.id && !!li.feeAmount && intl.formatCurrency(li.feeAmount, li.sourceCurrency.id)) ||
                '—',
            cell: (value) => <TextBlock width={50}>{value}</TextBlock>,
            alignRight: true,
        },
        {
            id: 'xeroBankAccount',
            name: messages.xeroBankAccount,
            value: (li) => li.bankAccount?.text || '—',
            cell: (value) => <TextBlock width={80}>{value}</TextBlock>,
        },
        {
            id: 'beneficiary',
            name: messages.beneficiary,
            value: (li) => li.beneficiary?.text || '—',
            cell: (value, li) => (
                <BeneficiaryIndicator
                    beneficiary={li.beneficiary}
                    status={li.beneficiaryPaymentStatus}
                    lastPaymentProperties={li.beneficiaryDiscrepancy?.lastPaymentProperties}
                >
                    <TextBlock width={90}>{value}</TextBlock>
                </BeneficiaryIndicator>
            ),
        },
        {
            id: 'paymentReference',
            name: messages.paymentReference,
            value: (li) => li.paymentReference || '—',
            cell: (value) => <TextBlock width={90}>{value}</TextBlock>,
        },
        {
            id: 'purpose',
            name: messages.purpose,
            value: (li) => li.paymentPurpose?.text || '—',
            cell: (value) => <TextBlock width={60}>{value}</TextBlock>,
        },
        {
            id: 'paymentStatus',
            name: messages.paymentStatus,
            value: (li) => li.status || '',
            cell: (value, li) => {
                const date = li.statusUpdatedAt && moment(li.statusUpdatedAt).format('ll');

                return (
                    <TextBlock width={90}>
                        {li.status && <PaymentStatusBadge status={li.status} errorMessage={li.errorMessage} />}

                        {date && <Date>{date}</Date>}
                    </TextBlock>
                );
            },
        },
        {
            id: 'links',
            name: messages.links,
            hideTitle: true,
            value: (li) => (li.airwallexUrl || li.xeroUrl ? 'hasLinks' : null),
            cell: (value, li) => (
                <LinksBlock>
                    {li.xeroUrl && (
                        <ExternalLink href={li.xeroUrl} targetBlank>
                            <div title={messages.openInXero}>
                                <XeroLogo width={16} />
                            </div>
                        </ExternalLink>
                    )}

                    {li.airwallexUrl && (
                        <ExternalLink href={li.airwallexUrl} targetBlank>
                            <div title={messages.openInAirwallex}>
                                <AirwallexLogo width={16} />
                            </div>
                        </ExternalLink>
                    )}
                </LinksBlock>
            ),
        },
        {
            id: 'attachments',
            name: messages.att,
            value: (li) => String(li.attachments?.length),
            alwaysVisible: true,
            cell: (val, li) =>
                li.attachments && li.attachments.length > 0 ? (
                    <AttachmentsDropdown
                        attachments={li.attachments}
                        activeAttachmentId={activeAttachmentId}
                        defaultOpen={defaultOpenAttachments}
                        onSelectAttachment={(attachmentId: string) =>
                            onSelectAttachment({
                                attachmentId,
                                xeroBatchPaymentInvoiceId: li.xeroBillInvoiceRequestId,
                                xeroBatchPaymentInvoiceName: li.friendlyName,
                            })
                        }
                    />
                ) : (
                    '—'
                ),
        },
    ];

    return columns;
};
