import { selectors } from 'modules/common';
import moment from 'moment';

const dateForNewUser = moment('2010-08-14');

export const isNewUserForTrialExperiment = (createDate: string) => dateForNewUser.isSameOrBefore(createDate);

export const getCanShowSubscribeAfterTrialButton = (
    company?: selectors.types.ExpandedCompany,
    profile?: selectors.types.ExpandedProfile | null
) => {
    if (!profile || !company) {
        return false;
    }

    return (
        isNewUserForTrialExperiment(profile.createdDate) &&
        company.isReadonly &&
        company.flags.isAccountOwner &&
        company.flags.isExpired &&
        company.flags.isTrialLicense
    );
};

const pushingConnectGLExperimentStartDate = moment('2024-11-18');

export const isNewUserForPushingConnectGLExperiment = (createDate: string) =>
    pushingConnectGLExperimentStartDate.isSameOrBefore(createDate);

export const getCanShowPushingConnectGLPopup = (
    company?: selectors.types.ExpandedCompany,
    profile?: selectors.types.ExpandedProfile | null
) => {
    if (!profile || !company) {
        return false;
    }

    return (
        isNewUserForPushingConnectGLExperiment(profile.createdDate) &&
        !company.integration &&
        company.flags.isManager &&
        company.flags.isTrialLicense &&
        !company.flags.isExpired
    );
};

const inviteTeammateExperimentStartDate = moment('2024-12-23');

export const isInviteTeammateExperiment = (createDate: string) =>
    inviteTeammateExperimentStartDate.isSameOrBefore(createDate);
