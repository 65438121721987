import { atom } from 'jotai';

import type { ConfirmationManagerState } from './ConfirmationManager.types';

export const confirmationManagerState = atom<Array<ConfirmationManagerState>>([]);

export const activeConfirmationState = atom((get) => {
    const confirmations = get(confirmationManagerState);

    return confirmations.find((confirmation) => confirmation.open);
});
