import { useEffect } from 'react';
import { FieldValues } from 'react-hook-form';

import { FormProps, PathArray } from './Form.types';

/**
 * Hook that subscribes to form field changes and triggers the onChange callback.
 * Watches for individual field changes and provides the updated form values,
 * the root field name that changed, and the full path to the changed field.
 */
export const useFormChange = <FormValues extends FieldValues>(
    form: FormProps<FormValues>['form'],
    onChange?: FormProps<FormValues>['onChange']
) => {
    useEffect(() => {
        if (!onChange) return;

        const subscription = form.watch((values, { name }) => {
            if (!name) return;

            const path = name.split('.') as PathArray<FormValues>;
            const rootName = path[0] as keyof FormValues;

            onChange(values as FormValues, rootName, path);
        });

        return () => subscription.unsubscribe();
    }, [form, onChange]);
};
