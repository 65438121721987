import { toast } from '@approvalmax/ui/src/components';
import { useCallback, useMemo } from 'react';
import { useCopyToClipboard, useMount } from 'react-use';

import { useTwoFaWizardController, useTwoFaWizardStep } from '../../TwoFaEnablingWizard.hooks';
import { WizardStep } from '../../TwoFaEnablingWizard.types';
import { messages } from './StepCodeScan.messages';

export const useStepCodeScan = () => {
    const copyToClipboard = useCopyToClipboard()[1];

    const { setWizardStep } = useTwoFaWizardStep();
    const { wizardController, setWizardController } = useTwoFaWizardController();

    const { stateId, setupCode } = wizardController;

    useMount(() => {
        setWizardController((prev) => ({
            ...prev,
            title: messages.title,
            onNext: () => setWizardStep(WizardStep.codeVerify),
        }));
    });

    const setupCodeFormatted = useCodeFormatter(setupCode || '');

    const handleCopyToClipboard = useCallback(() => {
        if (setupCodeFormatted) {
            copyToClipboard(setupCodeFormatted);

            toast.success(messages.notificationCodeCopied);
        }
    }, [copyToClipboard, setupCodeFormatted]);

    return {
        stateId,
        setupCodeFormatted,
        handleCopyToClipboard,
    };
};

export const useCodeFormatter = (code: string) => {
    return useMemo(() => {
        const chunks = code.match(/.{1,4}/g) || [];

        return chunks.join(' ');
    }, [code]);
};
