const amaxPayXeroRequestContextPrefix = 'requests/companies/:companyId/requests/:requestId/amaxPay/xero';

export const requestsApiPaths = <const>{
    billRequestsForXeroBatchPayment: 'requests/xero/selectBillRequestsForBatchPayment',
    billRequestsForAirwallexBatchPayment: 'requests/airwallex/xero/selectBillRequestsForBatchPayment',
    billRequestsForAmaxPayBatchPayment: 'requests/companies/:companyId/amaxPay/xero/batchPayments/:requestId/bills',
    getQuoteForCustomerDecisionPdf: 'requests/xero/getQuoteForCustomerDecisionPdf',
    setQuoteDecisionByCustomer: 'requests/xero/setQuoteDecisionByCustomer',
    setCustomerDecisionInQuote: 'requests/setCustomerDecisionInQuote',
    reverse: 'requests/reverse',
    retry: 'requests/respond/retry',
    resendQuoteForCustomerDecision: 'requests/resendQuoteForCustomerDecision',
    publish: 'requests/publish',
    importManualJournal: 'requests/xero/importManualJournal',
    get: 'requests/get',
    edit: 'requests/edit',
    delete: 'requests/delete',
    create: 'requests/create',
    attachFile: 'requests/attachFile',
    completeReview: 'requests/completeReview',
    checkAirwallexBeneficiaries: 'requests/airwallex/checkBeneficiaries',
    changeWatchers: 'requests/changeWatchers',
    changeGrnStatus: 'requests/changeGrnStatus',
    amaxPayXeroPay: `${amaxPayXeroRequestContextPrefix}/pay`,
    amaxPayXeroRefresh: `${amaxPayXeroRequestContextPrefix}/payments/refresh`,
    amaxPayPaymentDetailsDiscrepancies: `${amaxPayXeroRequestContextPrefix}/paymentDetails/discrepancies`,
    amaxPayBillCancel: `${amaxPayXeroRequestContextPrefix}/cancel`,
    scheduleAirwallexPayment: 'requests/airwallex/schedulePayment',
    cancelAirwallexScheduledPayment: 'requests/airwallex/cancelScheduledPayment',
    searchRequests: 'search/searchRequests',
    getQuoteForCustomerDecision: 'requests/xero/getQuoteForCustomerDecision',
    netSuitePayees: 'integration/getNetSuitePayees',
    getAuditReportDetails: 'requests/getAuditReportDetails',
    getAttachment: 'requests/getAttachment',
    amaxPayXeroRequestDetails: `${amaxPayXeroRequestContextPrefix}/details`,
    amaxPayXeroBankAccounts: `${amaxPayXeroRequestContextPrefix}/bankAccounts`,
    billRequestPaymentDetails: `${amaxPayXeroRequestContextPrefix}/bills/:billRequestId/paymentDetails`,
    amaxPayXeroPaymentRefresh: 'requests/companies/:companyId/amaxPay/xero/payments/:paymentId/refresh',
};
