import { useCallback, useEffect, useRef } from 'react';
import { useKey } from 'react-use';

import { baseClasses, viewConfig } from './Calendar.constants';
import {
    disableTabNavigation,
    focusHorizontalSibling,
    focusVerticalSibling,
    getAllButtons,
    getFirstButton,
    getFocusedElement,
    isInView,
    setNegativeTabIndex,
} from './Calendar.helpers';

/**
 * Base hook for general keyboard navigation in the calendar
 */
export const useBaseNavigation = () => {
    const calendarRef = useRef<HTMLDivElement>(null);

    // Function to focus on the first button in the calendar
    const focusOnNavigation = useCallback(() => {
        getFirstButton(calendarRef.current, baseClasses.navigation)?.focus();
    }, [calendarRef]);

    /**
     * Determines the current calendar view
     */
    const getCurrentView = useCallback(() => {
        const calendar = calendarRef.current;

        if (!calendar) return null;

        for (const config of Object.values(viewConfig)) {
            if (isInView(calendar, config.viewClass)) {
                return config;
            }
        }

        return null;
    }, [calendarRef]);

    /**
     * Universal function for horizontal navigation
     */
    const handleHorizontalNavigation = useCallback(
        (isNext: boolean) => {
            const focusedElement = getFocusedElement(calendarRef.current);

            focusHorizontalSibling(focusedElement, isNext);
        },
        [calendarRef]
    );

    /**
     * Universal function for vertical navigation
     */
    const handleVerticalNavigation = useCallback(
        (isUp: boolean) => {
            const focusedElement = getFocusedElement(calendarRef.current);
            const currentView = getCurrentView();

            if (!focusedElement || !currentView) return focusOnNavigation();

            const { buttonClass, buttonsInRow } = currentView;
            const allButtons = getAllButtons(calendarRef.current, buttonClass);

            const currentIndex = allButtons.indexOf(focusedElement);

            // Trying to move to the row above/below
            if (focusVerticalSibling(allButtons, currentIndex, isUp, buttonsInRow)) return;

            // If we are on the first row and trying to go up, move to the navigation panel
            if (isUp && currentIndex < buttonsInRow) return focusOnNavigation();
        },
        [calendarRef, focusOnNavigation, getCurrentView]
    );

    // Disable navigation using Tab
    useEffect(() => {
        setNegativeTabIndex(calendarRef.current);

        const handleTabNavigation = (event: KeyboardEvent) => disableTabNavigation(event, calendarRef.current);

        document.addEventListener('keydown', handleTabNavigation);

        return () => {
            document.removeEventListener('keydown', handleTabNavigation);
        };
    }, [calendarRef]);

    return { calendarRef, focusOnNavigation, handleVerticalNavigation, handleHorizontalNavigation };
};

/**
 * Hook for handling keyboard navigation in the calendar
 */
export const useKeyboardNavigation = () => {
    const { calendarRef, handleVerticalNavigation, handleHorizontalNavigation } = useBaseNavigation();

    useKey('ArrowLeft', () => {
        handleHorizontalNavigation(false);
    });

    useKey('ArrowRight', () => {
        handleHorizontalNavigation(true);
    });

    useKey('ArrowUp', (event: KeyboardEvent) => {
        event.preventDefault();
        handleVerticalNavigation(true);
    });

    useKey('ArrowDown', (event: KeyboardEvent) => {
        event.preventDefault();
        handleVerticalNavigation(false);
    });

    return { calendarRef };
};
