import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('modules.RequestModule.BeneficiaryIndicator', {
    accountCurrency: 'Account currency:',
    accountName: 'Account name:',
    accountNumber: 'Account number:',
    iban: 'IBAN:',
    swiftCode: 'Swift code:',
    bankCountryCode: 'Bank country code:',
    bankName: 'Bank name:',
    bankBranch: 'Bank branch:',
    localClearingSystem: 'Local clearing system:',
    accountingRoutingType1: 'Accounting routing type 1:',
    accountingRoutingType2: 'Accounting routing type 2:',
    accountingRoutingValue1: 'Accounting routing value 1:',
    accountingRoutingValue2: 'Accounting routing value 2:',
    greenText: 'You have paid this contact before. No changes in the bank details.',
    yellowText: 'You are paying this contact for the first time.',
    redText: 'You have paid this contact before. Its bank details have changed.',
    previous: 'Previous',
    actual: 'Actual',
});
