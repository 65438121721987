import { defineMessages } from '@approvalmax/utils';

export const commonErrorMessages = defineMessages('application.commonErrorMessages', {
    somethingWentWrong: 'Oops, something went wrong',
    twoFAMustBeEnabled: '2FA must be enabled',
    twoFAIsAlreadyEnabled: '2FA is already enabled.',
    requestHasBeenUpdated: 'The request has been updated. Please review it once again.',
    invalidAttachment: 'Attachment is invalid and cannot be attached',
    invalidFileName: 'Error: the file {fileName} cannot be attached.',
    restrictedFileName: 'Attachment file name contains restricted substring {substring}',
    attachmentExceedsLimit:
        'Error: the maximum number of files that can be attached to a request is exceeded ({cur} of {max}).',
    xeroValidationError: 'Xero validation error',
    xeroError: 'Xero Error: {errorMessage}',
    qboValidationError: 'QuickBooks Online validation error',
    qboError: 'QuickBooks Online validation error: {errorMessage}',
    requiredFieldError: 'Required field is empty.',
    fileUploadLimitError: 'Only 30 files can be uploaded per minute. Please wait {seconds} seconds',
    pdfFileProtectedError:
        'The PDF file {fileName} might be password-protected. Please check that it is not before data extraction.',
    ocrImageResolutionError: 'The image {fileName} height or width is out of range [min 100, max 10000]',
    ocrUnsupportedFileErrorMessage: 'File type (*.{extension}) not supported by ApprovalMax Capture',
    ocrUnsupportedFileErrorDescription: '{filename} is not supported, but you can upload it as an attachment',
    loginWithPasskey: 'Please log in with your passkey.',
});

export const errorMessages = defineMessages('application.errorMessages', {
    error1002: 'Sorry, the file upload has failed.',
    error2001: 'User or email not found',
    error2002: 'User or email not found',
    error2003: 'Error: the approval was not found.',
    error2011: 'Error: the request was not found, or access was denied.',
    error2013: 'You are no longer an Approver in this step.',
    error2014: 'Error: the Organisation was not found.',
    error2016: 'Error: the integration was not found.',
    error2020: 'Error: the report was not found.',
    error3001: "Sorry, there's an authentication error.",
    error3002: 'Email not verified',
    error3003: 'Incorrect email or password.',
    error3004:
        'Sorry, this password is too weak.{br}- Use 8 or more characters{br}- Use upper and lower case letters (e.g. Aa){br}- Use a number (e.g. 1234){br}- Use a symbol (e.g. !@#$)',
    error3005: 'Sorry, this password is too long. Please set a different one.',
    error3007: 'Sorry, the maximum number of login attempts is exceeded. Please try again in 30 minutes.',
    error3009: 'Invalid old password.',
    error4002: 'Wrong confirmation code.',
    error4004: 'Error: the new password field is empty.',
    error4036: 'Error: the approval workflow must have at least one approval step.',
    error4042: 'Administrators cannot remove themselves from the Organisation.',
    error4043: 'The Organisation connection has been disabled. Please reconnect your Organisation.',
    error4045: 'Error: there are no active subscriptions.',
    error4046: 'Error: the subscription expired.',
    error4047: 'The allowed number of Organisations for this subscription has been reached.',
    error4048: 'The request has been changed. Please review and make a decision.',
    error4049: "Error: the Organisation's Owner cannot be deleted from the list of Administrators.",
    error4055: 'Sorry, you have no access to this request.',
    error4067: 'You cannot set a Delegate for a user who is currently a Delegate himself.',
    error4075: 'The request has been rejected by Xero.',
    error4076: 'Sorry, the maximum number of uploaded files (9) is exceeded.',
    error4077: 'Sorry, too many files are being uploaded simultaneously. Please try again in 5 minutes.',
    error4078: 'Sorry, the file "{fileName}" exceeds the maximum size of 3MB.',
    error4079: 'Sorry, the file type of "{fileName}" cannot be uploaded due to security reasons.',
    error4080:
        'Sorry, the file "{fileName}" cannot be uploaded; our antivirus software indicates that it contains a virus.',
    error4081: 'Error: Confirmation code expired.',
    error4082: 'Error: the Integration Effective Date is beyond the limit.',
    error4083: 'Error: the Integration Effective Date has not been set.',
    error4132: '2FA is already enabled.',
    error4181: '2FA must be enabled.',
    error5001: "Error: there's a general integration error.",
    error5002: 'Error: the Xero API must be authorised.',
    error5003: 'Error: the OAuth token has expired.',
    error5004: 'Error: the OAuth token is not authorised.',
    error5005: 'Error: the request token lookup has failed.',
    error5006: 'Error: the request token key does not match.',
    error5007: 'Error: the access token store was not initialised.',
    error5008: 'Error: the access token was not found.',
    error5009: 'Error: the renewal token is prohibited.',
    error5010: 'Error: the callback URL must be specified.',
    error5011: 'Error: the OAuth token must be specified.',
    error5012: 'Error: the OAuth verifier must be specified.',
    error5013: '{errorDetail} Please try again later. <supportlink>Learn more</supportlink>.',
    error5014: 'Error: the OAuth token was rejected.',
    error5015: "Error: there's a general integration authentication error.",
    error5018: 'The request has been updated in Xero. Please review it once again.',
    error5019: 'This document can not be approved as its Date is before the organisation Lock date.',
    error5020: 'Error: Xero is unavailable. Please try again later.',
    error7002:
        "Error: the QuickBooks Online connection was lost. Please reconnect to QuickBooks Online or contact the Organisation's Administrator.",
    error7029:
        'Action unavailable. The account period has been closed and the Purchase Order status cannot be updated.',
    error7030:
        'Action unavailable. The Purchase Order has not been created in QuickBooks Online yet. Please try again later.',
    error4142: 'Some data has changed in the background, please start over the off-boarding process.',
    error4183: 'Debit and credit amounts are not equal.',
    error4177: 'Too many lines were added to the request.',
    error2018: 'Line item is not found.',
    error7031: 'You can only use one currency per journal entry.',
    error4186: 'Accounts Payable (A/P) account needs to have a Vendor in the Type Field.',
    error4187: 'Accounts Receivable (A/R) account needs to have a Customer in the Type Field.',
    error4188: 'The currencies of your account and vendor must be same.',
    error4189: 'The currencies of your account and customer must be same.',
    error6009: 'Audit reports archive is already being prepared.',
    error6010: 'Audit reports archive has just been sent to you by email.',
    error6011: 'There are no audit reports matching the specified filter.',
    error6012: 'The attachment archive has just been sent to you by email.',
    error6013: 'The attachment archive is already being prepared.',
    error4113: 'Organisation is retired.',
    error4008: "You've already signed up with ApprovalMax before. Please log in or reset password.",
    error4001: 'Password is not set',
    error4109: 'Required fields are empty: {fieldList}',
});
