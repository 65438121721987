import { CloseIcon, PasskeysKeyIcon, PasskeysQrIcon } from '@approvalmax/ui';
import { Box, Button, Flex, Form, Grid, Text, TextField } from '@approvalmax/ui/src/components';
import moment from 'moment';
import { memo } from 'react';

import { fieldNamePasskeyForm } from './PasskeyItem.constants';
import { usePasskeyItemForm } from './PasskeyItem.hooks';
import { messages } from './PasskeyItem.messages';
import { PasskeyItemProps } from './PasskeyItem.types';

export const PasskeyItem = memo<PasskeyItemProps>((props) => {
    const { credential, type } = props;

    const {
        form,
        handleSubmitForm,
        handleEdit,
        handleEditCancel,
        handleDelete,
        isEditable,
        isUpdatingCredential,
        isDeletingCredential,
    } = usePasskeyItemForm(props);

    const fieldLabel = form.watch(fieldNamePasskeyForm.label);

    const isLoading = isUpdatingCredential || isDeletingCredential;

    return (
        <Form form={form} onSubmit={handleSubmitForm}>
            <Box color='midnight20' spacing='24' radius='small'>
                <Flex direction='column' spacing='8'>
                    <Grid
                        gridTemplateColumns='max-content auto max-content'
                        gap={16}
                        alignItems='center'
                        minHeight={28}
                    >
                        {type === 'passkey' && <PasskeysQrIcon size={20} />}

                        {type === 'securityKey' && <PasskeysKeyIcon size={20} />}

                        {isEditable ? (
                            <>
                                <TextField
                                    size='small'
                                    name={fieldNamePasskeyForm.label}
                                    placeholder={messages.placeholder}
                                    initFocus
                                />

                                <Flex spacing='16'>
                                    <Button
                                        type='submit'
                                        color='blue80'
                                        size='small'
                                        progress={isUpdatingCredential}
                                        disabled={!fieldLabel}
                                    >
                                        {messages.buttonSave}
                                    </Button>

                                    <Button icon size='small' onClick={handleEditCancel} variant='text' color='blue100'>
                                        <CloseIcon size={24} />
                                    </Button>
                                </Flex>
                            </>
                        ) : (
                            <>
                                <Text color='black100' font='label' fontSize='medium'>
                                    {credential?.label}
                                </Text>

                                <Flex spacing='16'>
                                    <Button
                                        variant='text'
                                        color='blue100'
                                        onClick={handleEdit}
                                        disabled={isLoading}
                                        noPadding
                                    >
                                        {messages.buttonEdit}
                                    </Button>

                                    <Button
                                        variant='text'
                                        color='red100'
                                        onClick={handleDelete}
                                        disabled={isLoading}
                                        noPadding
                                    >
                                        {messages.buttonDelete}
                                    </Button>
                                </Flex>
                            </>
                        )}
                    </Grid>

                    {credential?.createdDate && (
                        <Text color='midnight60' font='body' fontSize='small' fontWeight='regular'>
                            {messages.created({ createdDate: moment(credential.createdDate).format('lll') })}
                        </Text>
                    )}
                </Flex>
            </Box>
        </Form>
    );
});

PasskeyItem.displayName = 'PasskeyItem';
