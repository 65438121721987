import { Field, Popup, TextAreaEditor } from '@approvalmax/ui';
import { LockIcon, UnlockIcon } from '@approvalmax/ui';
import { useDispatch } from 'modules/react-redux';
import { reloadRequest } from 'pages/requestList/actions';
import { FC, memo, useCallback, useState } from 'react';
import { usePutOnHold, useTakeOffHold } from 'shared/data/webApp/v2';

import { useOnHoldActionsFlags } from './OnHoldStatusActions.hooks';
import { messages } from './OnHoldStatusActions.messages';
import { PopupContent, PutOnHoldButton, TakeOffHoldButton } from './OnHoldStatusActions.styles';
import { OnHoldStatusActionsProps } from './OnHoldStatusActions.types';

export const OnHoldStatusActions: FC<OnHoldStatusActionsProps> = memo((props) => {
    const { request } = props;

    const { id: requestId, companyId, version: requestVersion } = request;
    const dispatch = useDispatch();
    const { canPutOnHold, canTakeOffHold } = useOnHoldActionsFlags(request);
    const { mutate: putOnHold, isLoading: isLoadingPutOnHold } = usePutOnHold({
        onSettled: () => {
            dispatch(reloadRequest(requestId, companyId));
            closePopup();
        },
    });
    const { mutate: takeOffHold, isLoading: isLoadingTakeOffHold } = useTakeOffHold({
        onSettled: () => {
            dispatch(reloadRequest(requestId, companyId));
            closePopup();
        },
    });
    const [comment, setComment] = useState('');
    const [open, setOpen] = useState(false);
    const [showValidationErrors, setShowValidationErrors] = useState(false);
    const isLoading = isLoadingPutOnHold || isLoadingTakeOffHold;
    const isInvalid = !comment.trim();

    const openPopup = useCallback(() => {
        setOpen(true);
    }, []);

    const closePopup = useCallback(() => {
        setComment('');
        setShowValidationErrors(false);
        setOpen(false);
    }, []);

    const onDone = useCallback(() => {
        if (isInvalid) {
            setShowValidationErrors(true);

            return;
        }

        const requestData = {
            params: {
                path: { requestId, companyId },
            },
            body: { comment, requestVersion },
        };

        if (canPutOnHold) {
            putOnHold(requestData);
        } else {
            takeOffHold(requestData);
        }
    }, [isInvalid, canPutOnHold, putOnHold, comment, requestVersion, requestId, companyId, takeOffHold]);

    return (
        <>
            {canPutOnHold && (
                <PutOnHoldButton execute={openPopup} title={messages.setOnHold} disabled={isLoading}>
                    <UnlockIcon size={20} />
                </PutOnHoldButton>
            )}

            {canTakeOffHold && (
                <TakeOffHoldButton execute={openPopup} title={messages.returnToApproval} disabled={isLoading}>
                    <LockIcon size={20} />
                </TakeOffHoldButton>
            )}

            {(canPutOnHold || canTakeOffHold) && (
                <Popup isOpen={open} onRequestClose={closePopup}>
                    <Popup.DefaultContent
                        title={canPutOnHold ? messages.setOnHoldPopupTitle : messages.returnToApprovalPopupTitle}
                        buttons={
                            <Popup.DefaultContent.Button disabled={isLoading} execute={onDone} title={messages.save}>
                                {messages.save}
                            </Popup.DefaultContent.Button>
                        }
                    >
                        <PopupContent>
                            <Field required title={messages.comment}>
                                <TextAreaEditor
                                    value={comment}
                                    onChange={setComment}
                                    minHeight={10}
                                    maxHeight={10}
                                    invalid={showValidationErrors && isInvalid}
                                    disabled={isLoading}
                                />
                            </Field>
                        </PopupContent>
                    </Popup.DefaultContent>
                </Popup>
            )}
        </>
    );
});

OnHoldStatusActions.displayName = 'OnHoldStatusActions';
