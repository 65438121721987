import { ChevronRightIcon } from '@approvalmax/ui';
import { Box, Button, Flex, Link, Popup, Spacing, Text, useConfirmation } from '@approvalmax/ui/src/components';
import { useSelector } from 'modules/react-redux';
import { useUserProfile } from 'modules/utils';
import { FC, memo, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { amplitudeService } from 'services/amplitude';

import { discardTemplateChanges } from '../../actions';
import { isPageReadonly } from '../../selectors/pageSelectors';
import { getHasChanges } from '../../selectors/templateSelectors';
import { MigrationFromReviewerV1PopupContent } from '../MigrationFromReviewerV1PopupContent/MigrationFromReviewerV1PopupContent';
import { whatIsTheOldReviewFunctionalityUrl } from '../MigrationFromReviewerV1PopupContent/MigrationFromReviewerV1PopupContent.constants';
import { ReviewerV1MigrationSuccessPopupContent } from '../ReviewerV1MigrationSuccessPopupContent/ReviewerV1MigrationSuccessPopupContent';
import { useIgMigrationAvailable } from './PromotionOfMigrationFromReviewerV1.hooks';
import { messages } from './PromotionOfMigrationFromReviewerV1.messages';
import { PromotionOfMigrationFromReviewerV1Props } from './PromotionOfMigrationFromReviewerV1.types';

export const PromotionOfMigrationFromReviewerV1: FC<PromotionOfMigrationFromReviewerV1Props> = memo((props) => {
    const { company, template } = props;
    const dispatch = useDispatch();
    const me = useUserProfile();
    const hasChanges = useSelector(getHasChanges);
    const isReadonly = useSelector(isPageReadonly);
    const [popup, setPopup] = useState<'migration' | 'success' | null>(null);
    const isMigrationAvailable = useIgMigrationAvailable(template, company);

    const onTogglePopup = useCallback((open?: boolean) => !open && setPopup(null), []);
    const onClosePopup = useCallback(() => setPopup(null), []);
    const openSuccessPopup = useCallback(() => setPopup('success'), []);
    const openMigrationPopup = useCallback(() => setPopup('migration'), []);

    const { confirmation } = useConfirmation({
        confirmMessage: messages.popupDescription,
        confirmButtonMessage: messages.confirmButtonText,
        cancelButtonMessage: messages.cancelButtonText,
    });

    const migrateNow = useCallback(() => {
        amplitudeService.sendData('workflow: clicked on migrate now in editing banner', {
            company_id: company.id,
            user_id: me.id,
        });

        if (hasChanges) {
            confirmation()
                .then(() => {
                    dispatch(discardTemplateChanges());
                    openMigrationPopup();
                })
                .catch(() => {});

            return;
        }

        openMigrationPopup();
    }, [company.id, me.id, hasChanges, openMigrationPopup, confirmation, dispatch]);

    if (!isMigrationAvailable && popup !== 'success') return null;

    return (
        <>
            <Flex height={28} alignItems='center'>
                <ChevronRightIcon size={16} color='midnight60' />
            </Flex>

            <Box color='mint70' spacing='8' width={220} height='fit-content' radius='xsmall'>
                <Flex justifyContent='space-between' alignItems='center' wrap={false}>
                    <Text font='label' fontSize='small' color='midnight80' fontWeight='medium'>
                        {messages.title}
                    </Text>
                </Flex>

                <Spacing height={8} />

                <Flex wrap={false} direction='column' spacing='8'>
                    <Text font='body' fontSize='small' color='midnight80'>
                        {messages.descriptionPartOne({
                            url: (
                                <Link font='body' fontSize='small' href={whatIsTheOldReviewFunctionalityUrl} external>
                                    {messages.descriptionLink}
                                </Link>
                            ),
                        })}
                    </Text>

                    <Text font='body' fontSize='small' color='midnight80'>
                        {messages.descriptionPartTwo}
                    </Text>

                    <Button size='small' onClick={migrateNow} disabled={isReadonly}>
                        {messages.migrateNow}
                    </Button>
                </Flex>
            </Box>

            <Popup open={popup === 'migration'} onToggle={onTogglePopup} size='large'>
                <MigrationFromReviewerV1PopupContent
                    onClose={onClosePopup}
                    onDone={openSuccessPopup}
                    template={template}
                />
            </Popup>

            <Popup size='xsmall' open={popup === 'success'} onToggle={onTogglePopup} preventAutoClose>
                <ReviewerV1MigrationSuccessPopupContent onClose={onClosePopup} />
            </Popup>
        </>
    );
});

PromotionOfMigrationFromReviewerV1.displayName = 'PromotionOfMigrationFromReviewerV1';
