import { ApiCallOptions } from '../types';
import AirwallexApi from './AirwallexApi';
import AuthApi from './AuthApi';
import BaseApiApi from './BaseApiApi';
import CompaniesApi from './CompaniesApi';
import CustomApiApi from './CustomApiApi';
import IntegrationApi from './IntegrationApi';
import ProfileApi from './ProfileApi';
import ReceiptBankApi from './ReceiptBankApi';
import RequestReportsApi from './RequestReportsApi';
import RequestsApi from './RequestsApi';
import SearchApi from './SearchApi';
import SupportApi from './SupportApi';
import TemplatesApi from './TemplatesApi';

export abstract class ApiMethods {
    public auth: AuthApi;
    public baseApi: BaseApiApi;
    public companies: CompaniesApi;
    public customApi: CustomApiApi;
    public integration: IntegrationApi;
    public profile: ProfileApi;
    public receiptBank: ReceiptBankApi;
    public airwallex: AirwallexApi;
    public requestReports: RequestReportsApi;
    public requests: RequestsApi;
    public search: SearchApi;
    public support: SupportApi;
    public templates: TemplatesApi;

    constructor() {
        const handler = {
            doApiCall: this._doApiCall.bind(this),
            getApiUrlPrefix: this._getApiUrlPrefix.bind(this),
        };

        this.auth = new AuthApi(handler);
        this.baseApi = new BaseApiApi(handler);
        this.companies = new CompaniesApi(handler);
        this.customApi = new CustomApiApi(handler);
        this.integration = new IntegrationApi(handler);
        this.profile = new ProfileApi(handler);
        this.receiptBank = new ReceiptBankApi(handler);
        this.airwallex = new AirwallexApi(handler);
        this.requestReports = new RequestReportsApi(handler);
        this.requests = new RequestsApi(handler);
        this.search = new SearchApi(handler);
        this.support = new SupportApi(handler);
        this.templates = new TemplatesApi(handler);
    }

    protected abstract _doApiCall(options: ApiCallOptions): Promise<any>;

    protected abstract _getApiUrlPrefix(): string;
}
