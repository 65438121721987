export const ORIGINAL_DEEP_LINKS = 'ORIGINAL_DEEP_LINKS';
export const CONTACT_INFO = 'CONTACT_INFO';
export const OFFBOARDING_USER_CONTEXT = 'OFFBOARDING_USER_CONTEXT';
export const INTEGRATION_SYNC_PROGRESS = 'INTEGRATION_SYNC_PROGRESS';
export const XERO_BILL_MATCHING_DATA = 'XERO_BILL_MATCHING_DATA';
export const XER0_PO_MATCHING_DATA = 'XER0_PO_MATCHING_DATA';
export const XERO_ALLOCATION_DATA = 'XERO_ALLOCATION_DATA';
export const XERO_MATCHING_CONTEXT = 'XERO_MATCHING_CONTEXT';
export const POTENTIAL_TEAMMATES = 'POTENTIAL_TEAMMATES';
export const QBOOKS_MATCHING_DATA = 'QBOOKS_MATCHING_DATA';
export const QBOOKS_MATCHING_INFO = 'QBOOKS_MATCHING_INFO';
export const NETSUITE_CUSTOM_FIELDS_REFERENCE = 'NETSUITE_CUSTOM_FIELDS_REFERENCE';
export const NETSUITE_COMPANY_CUSTOM_FIELDS = 'NETSUITE_COMPANY_CUSTOM_FIELDS';
export const DEXT_SUGGESTED_PLANS = 'DEXT_SUGGESTED_PLANS';
export const AIRWALLEX_GET_BENEFICIARY_SCHEMA = 'AIRWALLEX_GET_BENEFICIARY_SCHEMA';
export const QBOOKS_JOURNAL_PAYEE_DATA = 'QBOOKS_JOURNAL_PAYEE_DATA';
