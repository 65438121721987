import { Cin7Logo, NetSuiteLogo, QBooksLogo, XeroLogo } from '@approvalmax/ui';

import { CurrentStep } from '../../CreateOrganisationPopup.types';
import { messages } from './ConfirmSkipContent.messages';

export const benefits = [messages.benefit1, messages.benefit2, messages.benefit3];

export const glConfig = {
    [CurrentStep.Xero]: {
        name: messages.xero,
        logo: XeroLogo,
    },
    [CurrentStep.QBooks]: {
        name: messages.quickBooks,
        logo: QBooksLogo,
    },
    [CurrentStep.NetSuite]: {
        name: messages.netSuite,
        logo: NetSuiteLogo,
    },
    [CurrentStep.Cin7]: {
        name: messages.cin7,
        logo: Cin7Logo,
    },
} as const;
