import { Text } from '@approvalmax/ui/src/components';
import { memo } from 'react';

import { StringCellProps } from './StringCell.types';

export const StringCell = memo<StringCellProps>((props) => {
    const { value, className } = props;

    return (
        <Text title={value} font='body' ellipsis={1} className={className}>
            {value}
        </Text>
    );
});

StringCell.displayName = 'StringCell';
