export const companiesApiPaths = <const>{
    companies: 'companies',
    currencies: 'companies/:companyId/currencies',
    xeroCurrencies: 'companies/:companyId/xero/currencies',
    qBooksCurrencies: 'companies/:companyId/qBooks/currencies',
    xeroCounterpartyPaymentDetailsHistory:
        'companies/:companyId/xero/counterparties/:counterpartyId/paymentDetails/history',
    trialRequest: 'companies/:companyId/features/trial/request',
    xeroCounterpartiesPaymentDetailsVerify: 'companies/:companyId/xero/counterparties/payment-details/verify',
    nudgeApprovers: 'companies/:companyId/requests/:requestId/nudge',
};
