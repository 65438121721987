export const integrationApiPaths = <const>{
    getNetSuiteCountries: 'Integration/netsuite/dictionaries/countries',
    getNetSuiteSubsidiaries: 'Integration/netsuite/dictionaries/subsidiaries',
    getNetSuiteDictionariesTaxCodes: 'Integration/netsuite/dictionaries/tax-codes',
    getNetSuiteEmployee: 'Integration/getNetSuiteEmployee',
    getNetSuiteVendor: 'Integration/getNetSuiteVendor',
    checkDearConnection: 'Integration/checkDearConnection',
    checkNetSuiteConnection: 'Integration/checkNetSuiteConnection',
    xeroBankAccounts: 'Integration/companies/:companyId/xero/bankAccounts',
    finishDearIntegration: 'companies/finishDearIntegration',
    xeroCounterpartyPaymentDetails:
        'Integration/companies/:companyId/xero/counterparties/:counterpartyId/paymentDetails',
    xeroCounterpartyPaymentDetail:
        'Integration/companies/:companyId/xero/counterparties/:counterpartyId/paymentDetails/:paymentDetailsId',
    updateNetSuiteFilter: 'Integration/updateNetSuiteFilter',
    refreshNetSuiteCache: 'Integration/refreshNetSuiteCache',
    createNetSuiteVendor: 'Integration/netsuite/vendors',
    getContactCisSettings: 'Integration/getContactCisSettings',
    getContacts: 'Integration/getContacts',
    getAllContacts: 'Integration/getAllContacts',
    getCustomerContacts: 'Integration/getCustomerContacts',
    getNetSuiteApiFilter: 'Integration/getNetSuiteApiFilter',
    getNetSuiteCacheStatus: 'Integration/getNetSuiteCacheStatus',
    getNetSuiteFields: 'integration/getNetSuiteFields',
    getNetSuiteTerms: 'Integration/netsuite/dictionaries/terms',
    getNetSuiteVendorCategories: 'Integration/netsuite/dictionaries/vendor-categories',
    getNetSuiteExchangeRates: 'Integration/getNetSuiteExchangeRates',
    getNetSuiteAmortizationSchedules: 'Integration/getNetSuiteAmortizationSchedules',
    getXeroCounterpartyPaymentDetailsForRequest:
        'Integration/companies/:companyId/requests/:requestId/xero/counterparties/:counterpartyId/paymentDetails',
    getNetSuiteAccounts: 'Integration/netsuite/dictionaries/accounts',
    getNetSuiteTaxCodes: 'Integration/getNetSuiteTaxCodes',
    getNetSuiteExpenseCategories: 'Integration/getNetSuiteExpenseCategories',
    getNetSuiteCustomRecords: 'Integration/getNetSuiteCustomRecords',
    getQBooksCustomers: 'Integration/getQBooksCustomers',
    getNetSuiteCustomers: 'Integration/getNetSuiteCustomers',
    getDefaultTwoFaEnforcementType: 'integration/:integrationType/default-tfa-enforcement-type',
    getNetSuiteIncoterms: 'Integration/getNetSuiteIncoterms',
    xeroCounterparties: 'Integration/companies/:companyId/xero/counterparties',
};
