import uniqBy from 'lodash/uniqBy';
import React, { Component } from 'react';

import { DataItem } from '../typings/DataItem';
import { PlainDataProviderFilterFunction } from '../typings/PlainDataProviderFilterFunction';

function withoutExcluded(data: DataItem[], excludedIds: string[]) {
    return data.filter((x) => !excludedIds.includes(x.id));
}

function filtered(
    data: DataItem[],
    filterText: string | null,
    displayAttribute: string,
    filterFn: ((item: DataItem, filterText: string) => boolean) | undefined
) {
    const uniqData = uniqBy(data, 'id');

    if (!filterText) {
        return uniqData;
    }

    filterText = filterText.toUpperCase();

    if (filterFn) {
        // apply custom filter function provided by the user
        return uniqData.filter((x) => {
            return filterFn(x, filterText!);
        });
    } else {
        // match with display attribute
        return uniqData.filter((x) => {
            const displayText: string | null = (x as any)[displayAttribute];

            return displayText ? displayText.toUpperCase().includes(filterText!) : false;
        });
    }
}

interface PlainDataProviderProps {
    items: DataItem[];
    filterAttribute?: string;
    filterFn?: PlainDataProviderFilterFunction;
    children: JSX.Element | any;
    loading?: boolean;
}

interface OwnState {
    items: DataItem[];
}

class PlainDataProvider extends Component<PlainDataProviderProps, OwnState> {
    public static defaultProps = {
        filterAttribute: 'text',
    };

    public state = {
        items: [],
    };

    private _filterText: string | null = null;
    private _excludedIds: string[] = [];

    public UNSAFE_componentWillReceiveProps(nextProps: PlainDataProviderProps) {
        if (nextProps.items !== this.props.items || nextProps.filterAttribute !== this.props.filterAttribute) {
            this._updateFilteredItems(nextProps.items, nextProps.filterAttribute!, nextProps.filterFn);
        }
    }

    public render() {
        return React.cloneElement(this.props.children, {
            hasMore: false,
            items: this.state.items,
            onLoadItems: this._onLoadItems,
            dataMode: 'sync',
            loading: this.props.loading,
        });
    }

    private _onLoadItems = (filterText: string, excludedIds: string[]) => {
        this._filterText = filterText;
        this._excludedIds = excludedIds;
        this._updateFilteredItems(this.props.items, this.props.filterAttribute!, this.props.filterFn);
    };

    private _updateFilteredItems = (
        items: DataItem[],
        filterAttribute: string,
        filterFn: ((item: DataItem, filterText: string) => boolean) | undefined
    ) => {
        const filteredItems = filtered(
            withoutExcluded(items, this._excludedIds),
            this._filterText,
            filterAttribute,
            filterFn
        );

        this.setState({
            items: filteredItems,
        });
    };
}

export default PlainDataProvider;
