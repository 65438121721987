import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages(
    'pages.RequestList.XeroMatchingV2Section.BillView.ChangeMatchingPopup.ChangeMatchingPopupFilter',
    {
        amountGross: 'Amount (Gross)',
        PONumber: 'PO number',
        amountFromText: 'From',
        amountToText: 'To',
        showOnlyMatched: 'Show only Matched POs',
        dateRange: 'Date range',
        dateFromText: 'From',
        dateToText: 'To',
        clearButtonText: 'CLEAR',
        filterButtonText: 'FILTER',
        sectionTitle: 'Filter',
        enterTheAmount: 'Enter the amount',
        enterTheDate: 'Enter the date',
        enterNumber: 'Enter the number',
        clearFilterValues: 'Clear filter values',
        applyFilter: 'Apply filter',
    }
);
