import { RequestParams, RequestQueryOptions, useInvalidateQuery } from '@approvalmax/data';
import { useGet } from 'shared/data';

import { budgetsApiPaths } from '../paths';
import { GetQBooksBudgets } from './useGetQBooksBudgets.types';

export const useGetQBooksBudgets = (
    params?: RequestParams<GetQBooksBudgets>,
    queryOptions?: RequestQueryOptions<GetQBooksBudgets>
) => {
    return useGet(budgetsApiPaths.qBooksBudgets, {
        params,
        queryOptions,
        apiVersion: 'v2',
    });
};

export const useInvalidateGetQBooksBudgets = () => {
    return useInvalidateQuery<RequestParams<GetQBooksBudgets>>(budgetsApiPaths.qBooksBudgets);
};
