import { Popup } from '@approvalmax/ui';
import { Popup as NewPopup } from '@approvalmax/ui/src/components';
import { useAtom, useAtomValue } from 'jotai';
import { selectors } from 'modules/common';
import { TwoFaEnablingWizard } from 'modules/components';
import { domain } from 'modules/data';
import { useDispatch, useSelector } from 'modules/react-redux';
import { memo, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { storageService } from 'services/storage';
import { AfterAllFeatureTrialPopup, SubscribeAfterTrialPopup, useTwoFaSoftEnforcement } from 'shared/components';
import { useNewAppVersionNotification } from 'shared/hooks';
import { activeCompanyIdState } from 'shared/states';
import { getPath, Path } from 'urlBuilder';

import {
    cancelActivePopup,
    closeOrSkipPracticeInvitation,
    openTwoFaSoftEnforcementPopup,
    showPracticeInvitationPopup,
} from './actions';
import {
    tryPayOrCapturePopupCloseCallbackState,
    TryPayOrCapturePopupContent,
    tryPayOrCapturePopupOpenState,
} from './components';
import { MyDelegatesPopup } from './components/MyDelegatesPopup/MyDelegatesPopup';
import { StartTrialPeriodPopup } from './components/StartTrialPeriodPopup/StartTrialPeriodPopup';
import ContactSalesPopup from './containers/ContactSalesPopup/ContactSalesPopup';
import ContactSupportPopup from './containers/ContactSupportPopup/ContactSupportPopup';
import PendingPracticeInvitationPopup from './containers/pendingPracticeInvitationPopup/PendingPracticeInvitationPopup';
import { TrialPeriodStartedPopup } from './containers/TrialPeriodStartedPopup/TrialPeriodStartedPopup';
import { getActivePopups, getConsentToStartTrialPeriodPopupData } from './selectors/moduleSelectors';
import { needToShowPracticeInvitationPopup } from './selectors/practiceInvitations';

export const ModuleComponent = memo(() => {
    const authenticated = storageService.authenticated();
    const dispatch = useDispatch();
    const history = useHistory();
    const isMobileInfoPage = history.location.pathname === getPath(Path.mobile);
    const activePopups = useSelector(getActivePopups);
    const needToShowPracticeInvitation = useSelector(needToShowPracticeInvitationPopup);
    const [tryPayOrCapturePopupOpen, setTryPayOrCapturePopupOpen] = useAtom(tryPayOrCapturePopupOpenState);
    const consentToStartTrialPeriodPopupData = useSelector(getConsentToStartTrialPeriodPopupData);
    const profile = useSelector(selectors.profile.findProfile);
    const companyId = useAtomValue(activeCompanyIdState);
    const tryPayOrCapturePopupCloseCallback = useAtomValue(tryPayOrCapturePopupCloseCallbackState);

    const { handleOpen: handleOpenTfaSoftEnforcement } = useTwoFaSoftEnforcement();

    useEffect(() => {
        if (needToShowPracticeInvitation) {
            dispatch(showPracticeInvitationPopup());
        }
    }, [dispatch, needToShowPracticeInvitation]);

    useEffect(() => {
        if (profile?.requiredEnforcementTfaType === domain.TwoFaEnforcementType.Soft) {
            handleOpenTfaSoftEnforcement({});
            dispatch(openTwoFaSoftEnforcementPopup());
        }
    }, [dispatch, profile?.requiredEnforcementTfaType, handleOpenTfaSoftEnforcement]);

    useNewAppVersionNotification();

    const onToggleTryPayOrCapturePopup = useCallback(
        (open: boolean) => {
            if (!open) {
                tryPayOrCapturePopupCloseCallback?.();
                setTryPayOrCapturePopupOpen(false);
            }
        },
        [setTryPayOrCapturePopupOpen, tryPayOrCapturePopupCloseCallback]
    );

    const handleRequestClose = useCallback(
        (open?: boolean) => {
            if (!open) {
                dispatch(cancelActivePopup());
            }
        },
        [dispatch]
    );

    if (isMobileInfoPage || !authenticated) return null;

    return (
        <div>
            <NewPopup size='large' open={activePopups.myDelegates} onToggle={handleRequestClose}>
                <MyDelegatesPopup />
            </NewPopup>

            <TwoFaEnablingWizard />

            <Popup
                isOpen={activePopups.pendingPracticeInvitation}
                onRequestClose={() => dispatch(closeOrSkipPracticeInvitation())}
            >
                <PendingPracticeInvitationPopup />
            </Popup>

            {consentToStartTrialPeriodPopupData && (
                <StartTrialPeriodPopup
                    isOpen={activePopups.consentToStartTrialPeriodPopup}
                    data={consentToStartTrialPeriodPopupData}
                    onClose={handleRequestClose}
                />
            )}

            <TrialPeriodStartedPopup isOpen={activePopups.trialPeriodStartedPopup} onClose={handleRequestClose} />

            <Popup isOpen={activePopups.contactSalesPopup} onRequestClose={handleRequestClose}>
                <ContactSalesPopup />
            </Popup>

            <Popup isOpen={activePopups.contactSupportPopup} onRequestClose={handleRequestClose}>
                <ContactSupportPopup />
            </Popup>

            <AfterAllFeatureTrialPopup />

            <NewPopup open={tryPayOrCapturePopupOpen} onToggle={onToggleTryPayOrCapturePopup}>
                <TryPayOrCapturePopupContent />
            </NewPopup>

            {profile && profile.account && (
                <SubscribeAfterTrialPopup
                    profileId={profile?.id}
                    companyId={companyId}
                    accountId={profile.account.accountId}
                />
            )}
        </div>
    );
});

ModuleComponent.displayName = 'ModuleComponent';
