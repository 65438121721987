import { ApiError, ErrorCode } from '@approvalmax/types';
import { errorHelpers } from '@approvalmax/utils';
import { selectors } from 'modules/common';
import { domain } from 'modules/data';
import { mapCompany } from 'modules/data/domain/schemas/companySchema';
import { integrationActions } from 'modules/integration';
import { useDispatch, useSelector } from 'modules/react-redux';
import { sendChameleonEvent } from 'modules/utils/helpers/chameleon';
import { useCallback } from 'react';
import { amplitudeService } from 'services/amplitude';
import { api } from 'services/api';
import { facebookService } from 'services/facebook';
import { gaService } from 'services/ga';
import { linkedInService } from 'services/linkedIn';
import { notificationService } from 'services/notification';
import { routingService } from 'services/routing';
import { useCheckNetSuiteConnection } from 'shared/data/webApp/v1';
import { useCreateCompanyRaw, useFinishNetSuiteIntegrationRaw, useUpdateCompany } from 'shared/data/webApp/v1';
import { getPath, Path } from 'urlBuilder';

import { NetSuiteIntegrationPopupData } from '../../../reducers/module/activePopup/netSuiteIntegrationPopupReducer';
import { getActivePopup } from '../../../selectors/moduleSelectors';
import { messages } from './NetSuiteIntegrationPopup.messages';
import { FormFieldId, FormValues, FormValuesTransfer } from './NetSuiteIntegrationPopup.types';

export const useNetSuiteIntegrationPopup = () => {
    const data = useSelector((state) => getActivePopup<NetSuiteIntegrationPopupData>(state));

    const company = useSelector((state) =>
        data.companyId ? selectors.company.getCompanyById(state, data.companyId) : null
    );

    const { isCreating, onCreateCompany } = useCreateNetSuiteCompany();
    const { isEditing, onEditCompany } = useEditNetSuiteCompany();
    const { isConnecting, connect, isChecking, check } = useConnectToNetSuiteCompany(data.redirectPage);

    const onConnect = useCallback(
        async (values: FormValues) => {
            const transfer: FormValuesTransfer = {
                [FormFieldId.accountId]: values[FormFieldId.accountId],
                [FormFieldId.tokenId]: values[FormFieldId.tokenId],
                [FormFieldId.tokenSecret]: values[FormFieldId.tokenSecret],
                [FormFieldId.subsidiaryId]: values[FormFieldId.subsidiaryId],
                [FormFieldId.timeZone]: values[FormFieldId.timeZone],
            };

            if (!values[FormFieldId.isSuiteApp]) {
                transfer[FormFieldId.consumerKey] = values[FormFieldId.consumerKey];
                transfer[FormFieldId.consumerSecret] = values[FormFieldId.consumerSecret];
            }

            try {
                await check(transfer);
            } catch {
                // Error notification is displayed by react-query
                // from a BE message

                return;
            }

            let companyId = company?.id;

            if (company) {
                await onEditCompany(company, values[FormFieldId.timeZone]);
            } else {
                const data = await onCreateCompany(values[FormFieldId.timeZone]);
                const companyCreated = data ? mapCompany(data?.Companies[0]) : null;

                companyId = companyCreated?.id;
            }

            if (!companyId) {
                return;
            }

            await connect(transfer, companyId);
        },
        [company, check, connect, onCreateCompany, onEditCompany]
    );

    return {
        onConnect,
        isLoading: isConnecting || isEditing || isCreating || isChecking,
    };
};

const useConnectToNetSuiteCompany = (redirectPage: string) => {
    const dispatch = useDispatch();
    const { isLoading: isLoadingFinishNetSuiteIntegration, mutateAsync: finishNetSuiteIntegration } =
        useFinishNetSuiteIntegrationRaw();
    const { mutateAsync: checkNetSuiteConnection, isLoading: isLoadingCheckNetSuiteConnection } =
        useCheckNetSuiteConnection();

    const profile = useSelector(selectors.profile.getProfile);

    const { account } = profile;

    const check = useCallback(
        (formValues: FormValuesTransfer) => {
            return checkNetSuiteConnection({ body: { ...formValues } });
        },
        [checkNetSuiteConnection]
    );

    const connect = useCallback(
        (formValues: FormValuesTransfer, companyId: string) => {
            return finishNetSuiteIntegration(
                { body: { ...formValues, companyId } },
                {
                    onSuccess: async (response, variables) => {
                        const companyId = variables.body?.companyId;

                        if (response.Companies.length > 0) {
                            const currentCompany = response.Companies.find((item) => item.CompanyId === companyId);

                            const integration =
                                currentCompany && currentCompany.Integrations.length > 0
                                    ? currentCompany.Integrations[0]
                                    : null;

                            if (integration) {
                                await api.companies.pullIntegrations({
                                    companyId: companyId || '',
                                    integrationIds: [integration.IntegrationId],
                                });
                            }
                        }

                        amplitudeService.sendData('workflows list: complete connection', {
                            'connection type': 'netsuite',
                        });
                        sendChameleonEvent('connected_gl');

                        if (!account) {
                            facebookService.trackSignUp();
                            linkedInService.trackSignUp();

                            await gaService.sendEvent('org_created', {
                                integration: 'netsuite',
                            });
                        }

                        switch (redirectPage) {
                            case 'company':
                                routingService.reloadToUrl(getPath(Path.companyInfo, companyId));

                                break;

                            case 'workflow':
                                routingService.reloadToUrl(getPath(Path.companyWorkflows, companyId));

                                break;

                            default:
                                throw errorHelpers.assertNever(redirectPage as never);
                        }
                    },
                }
            ).catch((err: ApiError) => {
                if (err?.code === ErrorCode.E4123_ANOTHER_COMPANY_IS_ALREADY_INTEGRATED) {
                    dispatch(integrationActions.showAnotherCompanyIntegratedPopup(domain.IntegrationType.NetSuite));

                    return;
                }

                if (err?.code === ErrorCode.E5016_BOUND_COMPANY_ABOUT_TO_CHANGE) {
                    dispatch(integrationActions.showCompanyMismatchPopup(domain.IntegrationType.NetSuite));

                    return;
                }

                notificationService.showErrorToast(err?.title || err?.detail || messages.connectionError);
            });
        },
        [dispatch, finishNetSuiteIntegration, redirectPage, account]
    );

    return {
        isConnecting: isLoadingFinishNetSuiteIntegration,
        isChecking: isLoadingCheckNetSuiteConnection,
        connect,
        check,
    };
};

export const useCreateNetSuiteCompany = () => {
    const { data, isLoading: isCreating, mutateAsync } = useCreateCompanyRaw();
    const companyCreated = data ? mapCompany(data?.Companies[0]) : null;

    const onCreateCompany = useCallback(
        async (timeZone: string) => {
            return mutateAsync({ body: { timeZone } }).catch(() =>
                notificationService.showErrorToast(messages.companyCreationError)
            );
        },
        [mutateAsync]
    );

    return {
        isCreating,
        onCreateCompany,
        companyCreated,
    };
};

export const useEditNetSuiteCompany = () => {
    const { isLoading: isLoadingUpdateCompany, mutateAsync: updateCompany } = useUpdateCompany();

    const onEditCompany = useCallback(
        async (company: domain.Company, timeZone: string) => {
            const body = selectors.company.getCompanyEditTransfer({ ...company, timeZone });

            try {
                await updateCompany({ body });
            } catch {
                notificationService.showErrorToast(messages.saveTimeZoneError);
            }
        },
        [updateCompany]
    );

    return {
        isEditing: isLoadingUpdateCompany,
        onEditCompany,
    };
};
