import { constants } from 'modules/common';
import { domain, State } from 'modules/data';

import { getStandaloneFields } from './standaloneSelectors';
import { messages } from './validationSelectors.messages';

const { standaloneConstants } = constants;

export const validateStandaloneRequest = (state: State, request: domain.StandaloneRequest): string[] => {
    const mandatoryFieldsError = Boolean(
        request.amount == null || !request.currency || getStandaloneFields(state).some((fv) => !fv.value)
    );

    let errors: string[] = [];

    if (!request.details.name?.trim()) {
        errors.push(messages.mandatoryNameErrorText);
    }

    if (!request.details.description?.value?.trim()) {
        errors.push(messages.mandatoryDescriptionErrorText);
    }

    if (request.details.description.errorMessages) {
        errors.push(...request.details.description.errorMessages);
    }

    if (request.details.name && request.details.name.length > standaloneConstants.REQUEST_NAME_MAX_LENGTH) {
        errors.push(messages.exceededNameLengthErrorText);
    }

    if (request.amount != null && request.amount < standaloneConstants.TOTAL_MIN_AMOUNT) {
        errors.push(messages.understatedAmountErrorText);
    }

    if (request.amount != null && request.amount > standaloneConstants.TOTAL_MAX_AMOUNT) {
        errors.push(messages.exceededAmountErrorText);
    }

    if (mandatoryFieldsError) {
        errors.push(messages.mandatoryFieldsErrorText);
    }

    return errors;
};
