import { SupportIcon } from '@approvalmax/ui';
import { Button, ButtonProps } from '@approvalmax/ui/src/components';
import * as profileModule from 'modules/profile';
import { FC, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { messages } from './ContactSupportButton.messages';

export const ContactSupportButton: FC<ButtonProps> = (props) => {
    const { children, ...restProps } = props;

    const dispatch = useDispatch();

    const handleClick = useCallback(() => {
        dispatch(profileModule.loadContactSupportPopup());
    }, [dispatch]);

    return (
        <Button color='blue80' startIcon={<SupportIcon />} {...restProps} onClick={handleClick}>
            {children || messages.contactSupport}
        </Button>
    );
};

ContactSupportButton.displayName = 'ContactSupportButton';
