import { ConfirmationParams } from '@approvalmax/ui/src/components';

import { messages } from './WorkflowSettingsPopup.messages';

export const confirmationPopupProps: ConfirmationParams = {
    size: 'small',
    cancelButtonColor: 'blue80',
    confirmButtonColor: 'red40',
    confirmTitle: messages.confirmationTitle,
    confirmMessage: messages.confirmationMessage({ br: <br /> }),
    cancelButtonMessage: messages.confirmationButtonCancel,
    confirmButtonMessage: messages.confirmationButtonConfirm,
};
