import { Grid, openSplitViewState, Spacing, Text } from '@approvalmax/ui/src/components';
import { intl } from '@approvalmax/utils';
import { useAtomValue, useSetAtom } from 'jotai';
import { domain } from 'modules/data';
import { BeneficiaryStatusLegend } from 'modules/request/components/xero/BeneficiaryStatusLegend/BeneficiaryStatusLegend';
import { useUserProfile } from 'modules/utils';
import { LineItemsTable } from 'pages/requestList/components/lineItemsSection/LineItemsTable';
import { SectionContainer } from 'pages/requestList/components/SectionContainer/SectionContainer';
import { memo, useCallback, useMemo } from 'react';
import { AmaxPayBankAccountInfoCard, BankLogoImage, splitViewGoToBarSettingsState } from 'shared/components';
import { useGetAmaxPayXeroRequestDetails } from 'shared/data/webApp/v1';
import { useGetBatchPaymentsRequestAmounts } from 'shared/data/webApp/v2';
import { useCompanyBetaFeatures } from 'shared/hooks';
import { activeAttachmentIdState, ContentSplitViewMode, contentSplitViewState } from 'shared/states';

import { getColumnDefinitions } from './XeroAmaxPayBatchPaymentRequestCard.config';
import {
    useAmounts,
    useBankAccountData,
    useGroupedItemsWithSupplierTotals,
} from './XeroAmaxPayBatchPaymentRequestCard.hooks';
import { messages } from './XeroAmaxPayBatchPaymentRequestCard.messages';
import { XeroAmaxPayBatchPaymentRequestCardProps } from './XeroAmaxPayBatchPaymentRequestCard.types';

export const XeroAmaxPayBatchPaymentRequestCard = memo<XeroAmaxPayBatchPaymentRequestCardProps>((props) => {
    const {
        request: { id, companyId, details, statusV2, currency, payers },
    } = props;

    const { isMergedBySupplier } = details;

    const setOpenSplitView = useSetAtom(openSplitViewState);
    const setContentSplitView = useSetAtom(contentSplitViewState);
    const setSplitViewGoToBarSettings = useSetAtom(splitViewGoToBarSettingsState);
    const activeAttachmentId = useAtomValue(activeAttachmentIdState);

    const profile = useUserProfile();

    const { isBatchPaymentAmountsV2 } = useCompanyBetaFeatures(companyId);

    const { data: amaxPayXeroRequestDetails } = useGetAmaxPayXeroRequestDetails(
        {
            path: {
                companyId,
                requestId: id,
            },
        },
        {
            staleTime: 30 * 1000,
        }
    );

    const onSelectAttachment = useCallback(
        (attachmentId: string, xeroBatchPaymentInvoiceId: string, xeroBatchPaymentInvoiceName: string) => {
            setOpenSplitView(true);
            setContentSplitView({
                mode: ContentSplitViewMode.AttachmentsToBillLinkedToBatchPayment,
                attachmentId,
                billId: xeroBatchPaymentInvoiceId,
                billFriendlyName: xeroBatchPaymentInvoiceName,
            });
            setSplitViewGoToBarSettings({
                show: true,
                targetContent: {
                    mode: ContentSplitViewMode.BillLinkedToBatchPayment,
                    billId: xeroBatchPaymentInvoiceId,
                },
                targetContentName: xeroBatchPaymentInvoiceName,
            });
        },
        [setSplitViewGoToBarSettings, setContentSplitView, setOpenSplitView]
    );

    const isRequestApproved = statusV2 === domain.RequestStatusV2.Approved;

    const onSelectBill = useCallback(
        (xeroBatchPaymentInvoiceId: string) => {
            setContentSplitView({
                mode: ContentSplitViewMode.BillLinkedToBatchPayment,
                billId: xeroBatchPaymentInvoiceId,
            });
            setOpenSplitView(true);
        },
        [setContentSplitView, setOpenSplitView]
    );

    const bankAccountData = useBankAccountData(details.items, amaxPayXeroRequestDetails);

    const isPayer = useMemo(() => payers.some((payer) => payer.userEmail === profile.email), [payers, profile]);

    const { data: batchPaymentsRequestAmounts, isFetching: isFetchingGetBatchPaymentsRequestAmounts } =
        useGetBatchPaymentsRequestAmounts(
            {
                path: {
                    companyId,
                    requestId: id,
                },
            },
            {
                enabled: isBatchPaymentAmountsV2,
            }
        );

    const columnDefinitions = useMemo(
        () =>
            getColumnDefinitions({
                companyId,
                requestId: id,
                isRequestApproved,
                isPayer,
                bankAccountData,
                onSelectAttachment,
                activeAttachmentId,
                onSelectBill,
                isMergedBySupplier,
                amounts: batchPaymentsRequestAmounts?.items ?? [],
                isAmountsLoading: isFetchingGetBatchPaymentsRequestAmounts,
            }),
        [
            activeAttachmentId,
            bankAccountData,
            companyId,
            id,
            isMergedBySupplier,
            isPayer,
            isRequestApproved,
            onSelectAttachment,
            onSelectBill,
            batchPaymentsRequestAmounts,
            isFetchingGetBatchPaymentsRequestAmounts,
        ]
    );

    const amounts = useAmounts(details.items);

    const groupedItemsWithSupplierTotals = useGroupedItemsWithSupplierTotals(
        details.items,
        details.isMergedBySupplier,
        batchPaymentsRequestAmounts?.items
    );

    return (
        <SectionContainer title={messages.title}>
            {bankAccountData && (
                <AmaxPayBankAccountInfoCard
                    name={bankAccountData.nickname}
                    bankLogo={
                        <BankLogoImage
                            src={bankAccountData.bank.iconUrl}
                            alt={bankAccountData.bank.fullName}
                            width={36}
                            height={36}
                        />
                    }
                    currencyCode={bankAccountData.currencyCode}
                    bankAccountNumber={bankAccountData.identification.bankAccountNumber}
                    sortCode={bankAccountData.identification.sortCode}
                    iban={bankAccountData.identification.iban}
                    disconnected={!bankAccountData.isConnected}
                />
            )}

            <Spacing height={16} />

            <BeneficiaryStatusLegend />

            <LineItemsTable columnDefinitions={columnDefinitions} lineItems={groupedItemsWithSupplierTotals} />

            <Spacing height={8} />

            <Grid gridTemplateColumns='auto 180px' rowGap={4}>
                <Text font='label' fontSize='small' color='midnight70' textAlign='right'>
                    {messages.total}
                </Text>

                <Text font='label' fontSize='small' fontWeight='bold' color='midnight100' textAlign='right'>
                    {intl.formatCurrency(amounts.total, currency)}
                </Text>

                <Text font='label' fontSize='small' color='midnight70' textAlign='right'>
                    {messages.totalPaid}
                </Text>

                <Text font='label' fontSize='small' fontWeight='bold' color='midnight100' textAlign='right'>
                    {intl.formatCurrency(amounts.totalPaid, currency)}
                </Text>

                <Text font='label' fontSize='small' color='midnight70' textAlign='right'>
                    {messages.remaining}
                </Text>

                <Text font='label' fontSize='small' fontWeight='bold' color='midnight100' textAlign='right'>
                    {intl.formatCurrency(amounts.remaining, currency)}
                </Text>
            </Grid>
        </SectionContainer>
    );
});

XeroAmaxPayBatchPaymentRequestCard.displayName = 'XeroAmaxPayBatchPaymentRequestCard';
