import Cookies from 'js-cookie';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';

import {
    expiresNotificationPrefix,
    sessionNotificationOptions,
    sessionNotificationPrefix,
} from './useSessionNotification.constants';
import { createExpiresCookie } from './useSessionNotification.helpers';

export const useSessionNotification = (
    notificationId: string,
    hoursHideLimit: number = 72,
    isSession: boolean = true
) => {
    const prefix = isSession ? sessionNotificationPrefix : expiresNotificationPrefix;
    const cookieId = `${prefix}.${notificationId}`;

    const [isHidden, setIsHidden] = useState<boolean>(true);

    const handleHide = useCallback(() => {
        const cookieOptions = isSession ? sessionNotificationOptions : createExpiresCookie(hoursHideLimit);

        Cookies.set(cookieId, moment().toString(), cookieOptions);
        setIsHidden(true);
    }, [cookieId, hoursHideLimit, isSession]);

    const handleShow = useCallback(() => {
        Cookies.remove(cookieId, sessionNotificationOptions);
        setIsHidden(false);
    }, [cookieId]);

    useEffect(() => {
        const dateWhenHidden = Cookies.get(cookieId);

        if (dateWhenHidden && moment().diff(moment(dateWhenHidden), 'hours') < hoursHideLimit) {
            setIsHidden(true);
        } else {
            handleShow();
        }
    }, [cookieId, hoursHideLimit, handleShow, isSession]);

    return {
        isHidden,
        hide: handleHide,
        show: handleShow,
    };
};
