import { errorHelpers } from '@approvalmax/utils';
import { PartnerStatus, ProductInterest, QualificationQuestionsAnswers } from 'modules/data/domain';
import {
    isAnswersBusiness,
    isAnswersPartner,
} from 'pages/auth/components/QualificationQuestions/QualificationQuestions.helpers';

const storageKey = 'preferredAccountingSystemSignUp';

export enum PreferredAccountingSystem {
    Xero = 'Xero',
    QBooks = 'QBooks',
    NetSuite = 'NetSuite',
    Cin7 = 'Cin7',
}

export const getPreferredAccountingSystemSignUp = () => {
    const value = localStorage.getItem(storageKey) as PreferredAccountingSystem | null;

    if (!value) {
        return null;
    }

    if (Object.values(PreferredAccountingSystem).includes(value)) {
        return value;
    }

    return null;
};

export const setPreferredAccountingSystemSignUp = (
    qualificationQuestionsAnswers?: QualificationQuestionsAnswers | null
) => {
    if (!qualificationQuestionsAnswers) {
        return;
    }

    let value = null;

    if (isAnswersPartner(qualificationQuestionsAnswers)) {
        const partnerStatus = qualificationQuestionsAnswers.partnerStatus?.[0];

        if (!partnerStatus) {
            return;
        }

        switch (partnerStatus) {
            case PartnerStatus.XeroPlatinum:
            case PartnerStatus.XeroGold:
            case PartnerStatus.XeroOther:
                value = PreferredAccountingSystem.Xero;
                break;

            case PartnerStatus.QBOPlatinum:
            case PartnerStatus.QBOElite:
            case PartnerStatus.QBOOther:
                value = PreferredAccountingSystem.QBooks;
                break;

            case PartnerStatus.OracleNetSuite:
                value = PreferredAccountingSystem.NetSuite;
                break;

            case PartnerStatus.Hybrid:
            case PartnerStatus.Other:
                break;

            default: {
                const _exhaustiveCheck: never = partnerStatus;
            }
        }
    } else if (isAnswersBusiness(qualificationQuestionsAnswers)) {
        const productInterest = qualificationQuestionsAnswers.productInterest?.[0];

        if (!productInterest) {
            return;
        }

        switch (productInterest) {
            case ProductInterest.Xero:
                value = PreferredAccountingSystem.Xero;
                break;

            case ProductInterest.QuickBooksOnline:
                value = PreferredAccountingSystem.QBooks;
                break;

            case ProductInterest.OracleNetSuite:
                value = PreferredAccountingSystem.NetSuite;
                break;

            case ProductInterest.Cin7Core:
                value = PreferredAccountingSystem.Cin7;
                break;

            case ProductInterest.Hybrid:
            case ProductInterest.Other:
                break;

            default: {
                const _exhaustiveCheck: never = productInterest;
            }
        }
    }

    if (!value) {
        return;
    }

    try {
        localStorage.setItem(storageKey, value);
    } catch (error) {
        errorHelpers.captureException(error);
    }
};

export const removePreferredAccountingSystemSignUp = () => localStorage.removeItem(storageKey);
