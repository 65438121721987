import { Reference } from '@approvalmax/types';
import { errorHelpers, intl } from '@approvalmax/utils';
import { selectors } from 'modules/common';
import { domain } from 'modules/data';

import { messages } from './conditionSelector.messages';
import { getExactValueText } from './stepSelectors';

const conditionText = (c: domain.MatrixCondition, integrationCode: domain.IntegrationCode | null) => {
    const isNegativeExactConstraint = c.conditionType === domain.ConditionType.NegativeExactValuesCondition;
    const isPositiveExactConstraint = c.conditionType === domain.ConditionType.ExactValuesCondition;
    const isExactConstraint = isNegativeExactConstraint || isPositiveExactConstraint;

    const exactValues: Reference[] = isExactConstraint ? c.exactValues || [] : [];
    const groupOfXeroAccounts = isExactConstraint ? c.groupOfXeroAccounts || [] : [];

    const values = [...exactValues, ...groupOfXeroAccounts];

    if (values.length === 0) {
        return '';
    }

    const valuesText = values.map(getExactValueText).join(', ');

    const fieldName = selectors.field.getFieldNameBySystemPurpose(c.fieldSystemPurpose, integrationCode, c.fieldName);

    return isNegativeExactConstraint
        ? messages.submitter_rules_preview_not_field_pattern({
              fieldName,
              values: valuesText,
          })
        : messages.submitter_rules_preview_field_pattern({
              fieldName,
              values: valuesText,
          });
};

const numericConditionText = (c: domain.NumericRangeCondition, defaultCurrency: string) => {
    const currency = defaultCurrency;

    const amountCondition = c as domain.NumericRangeCondition;
    const less = intl.formatNumber(amountCondition.numericRangeLess);
    const greaterEq = intl.formatNumber(amountCondition.numericRangeGreaterEquals);

    let amountValueText;

    switch (amountCondition.numericRangeConditionType) {
        case domain.NumericRangeConditionType.Above:
            amountValueText = messages.participant_rules_preview_amount_pattern_above({
                greaterEq,
                currency,
            });
            break;

        case domain.NumericRangeConditionType.Below:
            amountValueText = messages.participant_rules_preview_amount_pattern_below({
                less,
                currency,
            });
            break;

        case domain.NumericRangeConditionType.Within:
            amountValueText = messages.participant_rules_preview_amount_pattern_within({
                less,
                greaterEq,
                currency,
            });
            break;

        case domain.NumericRangeConditionType.Any:
            throw errorHelpers.notSupportedError();

        default:
            throw errorHelpers.assertNever(amountCondition.numericRangeConditionType);
    }

    const netAmountText = messages.participant_rules_preview_net_amount_text;
    const totalAmountText = messages.participant_rules_preview_total_amount_text;

    const amountName = c.amountType === domain.AmountType.Net ? netAmountText : totalAmountText;

    const fieldName = c.fieldSystemPurpose === domain.FieldSystemPurpose.Amount ? amountName : c.fieldName;

    return messages.participant_rules_preview_amount_pattern({
        amount: amountValueText,
        name: fieldName,
    });
};

export const getConditionDislplayText = (
    condition: domain.MatrixCondition,
    defaultCurrency: string,
    integrationCode: domain.IntegrationCode | null
) => {
    switch (condition.conditionType) {
        case domain.ConditionType.NegativeExactValuesCondition:
        case domain.ConditionType.ExactValuesCondition: {
            return conditionText(condition, integrationCode);
        }

        case domain.ConditionType.NumericRangeCondition: {
            return numericConditionText(condition, defaultCurrency);
        }

        case domain.ConditionType.ServerCondition:
        case domain.ConditionType.BoolCondition: {
            throw errorHelpers.notSupportedError();
        }

        case null: {
            throw errorHelpers.notSupportedError();
        }

        default: {
            throw errorHelpers.assertNever(condition);
        }
    }
};
