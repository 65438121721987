import './workflowSection.scss';

import { Guid } from '@approvalmax/types';
import { Tooltip, TransparentButton } from '@approvalmax/ui';
import { errorHelpers, hooks } from '@approvalmax/utils';
import { selectors, ui } from 'modules/common';
import { domain, State } from 'modules/data';
import { asMutable } from 'modules/immutable';
import { useShallowEqualSelector } from 'modules/react-redux';
import {
    AutoApprovedStepIcon,
    BubbleSeparatorIcon,
    CheckIcon,
    UserBadgeApprovedIcon,
    UserBadgeRejectedIcon,
    WorkflowStepEditIcon,
    WorkflowStepReassignIcon,
} from 'modules/sprites';
import { FC, memo, useEffect, useState } from 'react';
import bemFactory from 'react-bem-factory';
import { useDispatch } from 'react-redux';

import { showEditParticipantsPopup, showRequestReassignPopup } from '../../actions';
import SectionContainer from '../../components/SectionContainer';
import { getRequestStepCommands } from '../../selectors/requestSelectors';
import { getMemberOrUser } from '../../selectors/userSelectors';
import { ReviewStep } from './ReviewStep/ReviewStep';
import { StepParticipantHover } from './StepParticipantHover/StepParticipantHover';
import { messages } from './WorkflowSection.messages';

const bem = bemFactory.block('reql-workflow-section');
const qa = bemFactory.qa('reql-workflow-section');

function getLastActiveStep(request: selectors.types.ExpandedRequest) {
    // Select last active/done step
    const step = asMutable(request.steps)
        .reverse()
        .find((s) => s.state !== domain.RequestStepState.NotStarted);

    return step ? step.id : null;
}

function computeSelectedStepId(request: selectors.types.ExpandedRequest) {
    switch (request.statusV2) {
        case domain.RequestStatusV2.Draft:
            return request.steps.length > 0 ? request.steps[0].id : null;

        case domain.RequestStatusV2.OnApproval:
        case domain.RequestStatusV2.OnHold:
            return request.activeStep ? request.activeStep.id : getLastActiveStep(request);

        case domain.RequestStatusV2.OnReview:
            return request.reviewStep?.reviewStepId || getLastActiveStep(request);

        default:
            return getLastActiveStep(request);
    }
}

interface WorkflowSectionProps {
    className?: string;
    request: selectors.types.ExpandedRequest;
    readonly?: boolean;
}

export const WorkflowSection: FC<WorkflowSectionProps> = memo((props) => {
    const { className, request, readonly } = props;
    const dispatch = useDispatch();
    const { me, team, allUsers } = useShallowEqualSelector((state: State) => {
        const me = selectors.profile.getProfileUser(state);

        return {
            me,
            team: selectors.company.getCompanyTeam(state, request.company),
            allUsers: selectors.user.getUsers(state),
        };
    });
    const [selectedStepId, setSelectedStepId] = useState(computeSelectedStepId(request));
    const [requestId, setRequestId] = useState(request.id);
    const [steps, setSteps] = useState<domain.RequestStep[]>([]);

    const { isActualVersion } = request.flags;

    const prevRequestId = hooks.usePrevious(requestId);
    const prevSteps = hooks.usePrevious(steps);

    useEffect(() => {
        const stepsWereChanged =
            prevSteps?.length !== request.steps.length ||
            // steps ids don't change
            prevSteps?.some((step) => !request.steps.find((newStep) => newStep.id === step.id));

        if (request.id !== prevRequestId || stepsWereChanged) {
            setSelectedStepId(computeSelectedStepId(request));
            setRequestId(request.id);
            setSteps(request.steps);
        }
    }, [prevRequestId, prevSteps, request]);

    const getUser = (userId: Guid) => getMemberOrUser(userId, team, allUsers);

    const editStepParticipants = (stepId: Guid) => {
        dispatch(showEditParticipantsPopup(request.id, stepId));
    };

    const reassignRequest = (stepId: Guid) => {
        dispatch(showRequestReassignPopup(request.id, stepId));
    };

    const onSelectStep = (stepId: Guid) => {
        if (selectedStepId === stepId) {
            return;
        }

        setSelectedStepId(stepId);
    };

    const getApprovalConditionMessage = (step: domain.RequestStep) => {
        switch (step.type) {
            case domain.TemplateStepType.DecisionStep:
                return messages.stepTypeDefault;

            case domain.TemplateStepType.AnyOfDecisionStep:
                return step.approvalCount && step.approvalCount > 1
                    ? messages.stepTypeAnyOfCount({ count: step.approvalCount })
                    : messages.stepTypeAnyOf;

            default:
                return errorHelpers.assertNever(step.type);
        }
    };

    const renderStepDetails = (step: domain.RequestStep) => {
        const selected = step.id === selectedStepId;

        let participants;

        if (selected) {
            const autoApproved =
                step.state === domain.RequestStepState.Done &&
                step.resolution === domain.RequestStepResolution.Approved &&
                step.participants.length === 0;

            if (autoApproved) {
                participants = (
                    <div className={bem('step-auto-approved')} data-qa={qa('step-auto-approved')}>
                        <AutoApprovedStepIcon className={bem('step-auto-approved-icon')} width={30} height={30} />

                        {messages.stepAutoApproved({ br: <br /> })}
                    </div>
                );
            } else {
                const single = step.participants.length === 1;

                participants = step.participants.map((p) => {
                    const user = getUser(p.userId);
                    const approved = p.decision === domain.RequestStepParticipantDecision.Approve;
                    const rejected = p.decision === domain.RequestStepParticipantDecision.Reject;

                    return (
                        <StepParticipantHover
                            key={`${p.userId}/${p.delegateFor}/${p.addedBy}`}
                            participant={p}
                            className={bem('step-participant')}
                            qa={qa('step-participant')}
                            request={request}
                        >
                            {approved && (
                                <UserBadgeApprovedIcon
                                    className={bem('step-participant-badge', 'approved')}
                                    width={16}
                                    height={16}
                                />
                            )}

                            {rejected && (
                                <UserBadgeRejectedIcon
                                    className={bem('step-participant-badge', 'rejected')}
                                    width={10}
                                    height={4}
                                />
                            )}

                            <ui.Avatar title={null} size={30} user={user} className={bem('step-participant-avatar')} />

                            <div className={bem('step-participant-name', { hide: !single })}>{user.displayName}</div>
                        </StepParticipantHover>
                    );
                });
            }
        }

        const stepCommands = getRequestStepCommands(request, step, me.id);

        const isCompanyReadonly = request.company.isReadonly;

        return (
            <div className={bem('step-details')}>
                <div className={bem('step-type')}>
                    {messages.stepTypeText({
                        type: getApprovalConditionMessage(step),
                    })}
                </div>

                <div className={bem('step-participant-list')}>
                    {participants}

                    {isActualVersion && (
                        <>
                            <TransparentButton
                                className={bem('step-edit-button')}
                                disabled={isCompanyReadonly || readonly}
                                qa={qa('step-edit-button')}
                                title={messages.editParticipantsTitleText}
                                execute={() => editStepParticipants(step.id)}
                                command={stepCommands.editParticipants}
                            >
                                <WorkflowStepEditIcon width={30} height={30} />
                            </TransparentButton>

                            <TransparentButton
                                className={bem('step-reassign-button')}
                                disabled={isCompanyReadonly || readonly}
                                qa={qa('step-reassign-button')}
                                title={messages.reassignRequestTitleText}
                                execute={() => reassignRequest(step.id)}
                                command={stepCommands.reassignRequest}
                            >
                                <WorkflowStepReassignIcon width={30} height={30} />
                            </TransparentButton>
                        </>
                    )}
                </div>
            </div>
        );
    };

    const renderStep = (step: domain.RequestStep, index: number) => {
        const isLast = request.steps.length === index + 1;
        const selected = step.id === selectedStepId;
        const notStarted = step.state === domain.RequestStepState.NotStarted;
        const active = step.state === domain.RequestStepState.Active;
        const approved = step.resolution === domain.RequestStepResolution.Approved;
        const rejected = step.resolution === domain.RequestStepResolution.Rejected;
        const locked = [
            domain.RequestStatusV2.Approved,
            domain.RequestStatusV2.Rejected,
            domain.RequestStatusV2.Cancelled,
        ].includes(request.statusV2);

        let stepStatus;

        if (approved) {
            stepStatus = messages.stepTooltipStatusApproved;
        } else if (rejected) {
            stepStatus = messages.stepTooltipStatusRejected;
        } else if (locked) {
            let reason;

            switch (request.resolutionOrigin) {
                case domain.RequestResolutionOrigin.EnforcedByAdmin:
                    switch (request.statusV2) {
                        case domain.RequestStatusV2.Approved:
                            reason = messages.stepTooltipStatusLockedReasonApprovedByAdmin;
                            break;

                        case domain.RequestStatusV2.Rejected:
                            reason = messages.stepTooltipStatusLockedReasonRejectedByAdmin;
                            break;

                        case domain.RequestStatusV2.Cancelled:
                            reason = messages.stepTooltipStatusLockedReasonCancelledByAdmin;
                            break;

                        default:
                            throw errorHelpers.invalidOperationError();
                    }

                    break;

                case domain.RequestResolutionOrigin.EnforcedExternally:
                case domain.RequestResolutionOrigin.ResolvedExternally: {
                    const integrationName = request.company.integration!.displayName;

                    switch (request.statusV2) {
                        case domain.RequestStatusV2.Approved:
                            reason = messages.stepTooltipStatusLockedReasonApprovedExternally({
                                integrationName,
                            });
                            break;

                        case domain.RequestStatusV2.Rejected:
                            reason = messages.stepTooltipStatusLockedReasonRejectedExternally({
                                integrationName,
                            });
                            break;

                        case domain.RequestStatusV2.Cancelled:
                            reason = messages.stepTooltipStatusLockedReasonCancelledExternally({
                                integrationName,
                            });
                            break;

                        default:
                            throw errorHelpers.invalidOperationError();
                    }

                    break;
                }

                default:
                    switch (request.statusV2) {
                        case domain.RequestStatusV2.Approved:
                            reason = messages.stepTooltipStatusLockedReasonApproved;
                            break;

                        case domain.RequestStatusV2.Rejected:
                            reason = messages.stepTooltipStatusLockedReasonRejected;
                            break;

                        case domain.RequestStatusV2.Cancelled:
                            reason = messages.stepTooltipStatusLockedReasonCancelled;
                            break;

                        default:
                            throw errorHelpers.invalidOperationError();
                    }

                    break;
            }

            stepStatus = messages.stepTooltipStatusLocked({ reason });
        } else if (active) {
            stepStatus = messages.stepTooltipStatusActive;
        } else {
            stepStatus = messages.stepTooltipStatusUpcoming;
        }

        const stepTooltipContent = (
            <div className={bem('step-tooltip')}>
                <div className={bem('step-tooltip-name')}>{step.name}</div>

                <div className={bem('step-tooltip-status')} data-qa={qa('step-tooltip-status')}>
                    {stepStatus}
                </div>
            </div>
        );

        return (
            <div
                key={step.id}
                className={bem('step-container', { selected })}
                data-qa={qa('step')}
                data-qa-id={step.id}
                data-qa-name={step.name}
            >
                <div className={bem('step-bubble-row')}>
                    <div className={bem('step-bubble-wrp')} onClick={() => onSelectStep(step.id)}>
                        <Tooltip
                            tooltip={stepTooltipContent}
                            className={bem('step-bubble', {
                                selected,
                                'not-started': notStarted,
                                active,
                                approved,
                                rejected,
                                locked,
                            })}
                            qa={qa('step-bubble')}
                        >
                            {approved && <CheckIcon className={bem('step-approved-icon')} width={10} height={7} />}

                            <div className={bem('step-name')}>{step.name}</div>

                            {selected &&
                                active &&
                                (request.statusV2 === domain.RequestStatusV2.OnApproval ||
                                    request.statusV2 === domain.RequestStatusV2.OnHold) && (
                                    <div className={bem('step-active-text')} data-qa={qa('step-active-text')}>
                                        {messages.stepActiveText}
                                    </div>
                                )}

                            {!selected && !locked && active && <div className={bem('step-active-icon')} />}

                            {selected && !locked && notStarted && (
                                <div className={bem('step-not-started-text')} data-qa={qa('step-not-started-text')}>
                                    {messages.stepNotStartedText}
                                </div>
                            )}
                        </Tooltip>
                    </div>

                    {!isLast && <BubbleSeparatorIcon className={bem('step-separator-icon')} width={6} height={12} />}
                </div>

                {selected && renderStepDetails(step)}
            </div>
        );
    };

    const requestSteps = request.steps.map((step, i) => renderStep(step, i));

    return (
        <SectionContainer className={className} text={messages.sectionText}>
            <div className={bem()} data-qa={qa()}>
                <ReviewStep
                    request={request}
                    readonly={readonly}
                    selectedStepId={selectedStepId}
                    setSelectedStepId={setSelectedStepId}
                />

                {requestSteps}
            </div>
        </SectionContainer>
    );
});

WorkflowSection.displayName = 'WorkflowSection';
