import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('modules.common.licenseSelectors', {
    billingNameAnnual: 'Yearly',
    billingNameBiAnnually: 'Bi-Yearly',
    billingNameMonthly: 'Monthly',
    billingNameQuarterly: 'Quarterly',
    billingNameOneTime: 'One time',
    planNameRetired: 'Retired',
    planNameProfessional: 'ApprovalMax Professional',
    planNameXeroPremium: 'ApprovalMax for Xero Premium',
    planNameQBooksPremium: 'ApprovalMax for QuickBooks Online Premium',
    planNameNetSuitePremium: 'ApprovalMax for NetSuite Premium',
    planNameXeroAdvanced: 'ApprovalMax for Xero Advanced',
    planNameQBooksAdvanced: 'ApprovalMax for QuickBooks Online Advanced',
    planNameNetSuiteAdvanced: 'ApprovalMax for NetSuite Advanced',
    planNameXero: 'ApprovalMax for Xero Standard',
    planNameQBooks: 'ApprovalMax for QuickBooks Online Standard',
    planNameNetSuite: 'ApprovalMax for NetSuite Standard',
    planNameTrial: 'ApprovalMax Professional trial',
    planNamePartner: 'ApprovalMax for Partners',
    planNamePaymentRequired: 'ApprovalMax Payment required',
    receiptBankProfessional: 'Dext Prepare Professional',
    receiptBankQuickBooksOnlineStandard: 'Dext Prepare QuickBooks Online Standard',
    receiptBankQuickBooksOnlineAdvanced: 'Dext Prepare QuickBooks Online Advanced',
    receiptBankQuickBooksOnlinePremium: 'Dext Prepare QuickBooks Online Premium',
    receiptBankXeroStandard: 'Dext Prepare Xero Standard',
    receiptBankXeroAdvanced: 'Dext Prepare Xero Advanced',
    receiptBankXeroPremium: 'Dext Prepare Xero Premium',
    smallBusinessPackage: 'ApprovalMax Small Business Package',
    cin7CorePremium: 'ApprovalMax Cin7 Core Premium',
    bookkeepersAndAccountantPackage: 'ApprovalMax for Accountants & Bookkeepers',
});
