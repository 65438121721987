import { domHelpers } from '@approvalmax/utils';
import { memo } from 'react';

import { Flex } from '../Flex/Flex';
import { TimeList } from './components';
import { defaultMinutesStep, timeBlocksInView, timeBlockSize } from './DigitalClock.constants';
import { getHourCycle } from './DigitalClock.helpers';
import { useTimePickerStates } from './DigitalClock.hooks';
import type { DigitalClockProps } from './DigitalClock.types';

export const DigitalClock = memo<DigitalClockProps>((props) => {
    const { ampm: ampmProp, minDate, maxDate, height, qa, value, onChange, step = defaultMinutesStep } = props;

    const ampm = getHourCycle(ampmProp);
    const maxHeight = height || timeBlockSize * timeBlocksInView;

    const { hoursListProps, minutesListProps, ampmListProps } = useTimePickerStates({
        value,
        onChange,
        ampm,
        minDate,
        maxDate,
        step,
    });

    return (
        <Flex spacing='16' role='group' data-qa={domHelpers.generateDataQa(qa, 'digital-clock')}>
            <TimeList maxHeight={maxHeight} {...hoursListProps} />

            <TimeList maxHeight={maxHeight} {...minutesListProps} />

            {ampm && <TimeList maxHeight={maxHeight} {...ampmListProps} />}
        </Flex>
    );
});

DigitalClock.displayName = 'DigitalClock';
