import { ActionMenu, Button, Popup, TransparentButton } from '@approvalmax/ui';
import { selectors } from 'modules/common';
import { domain } from 'modules/data';
import { useSelector } from 'modules/react-redux';
import { DoneIcon, TbEllipsisIcon } from 'modules/sprites';
import { FC, memo, useState } from 'react';
import styled from 'styled-components';

import SectionContainer from '../../../components/SectionContainer';
import { isActiveRequestLocked } from '../../../selectors/pageSelectors';
import { XeroMarkAsBilledPopup } from '../XeroMarkAsBilledPopup/XeroMarkAsBilledPopup';
import { messages } from './XeroBilledStatusSection.messages';
import { XeroBilledStatusSectionProps } from './XeroBilledStatusSection.types';

const StyledSectionContainer = styled(SectionContainer)`
    margin: 30px 0;
`;

const HeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
`;

const Title = styled.span`
    line-height: 35px;
`;

const ButtonsContainer = styled.div`
    display: flex;
    align-items: center;
`;

const ShowMoreButton = styled(TransparentButton)`
    display: flex;
    align-items: center;
    height: 30px;
    padding: 0 3px;

    &:hover {
        background-color: #f3f3f3;
    }
`;

const Billed = styled.div`
    width: 84px;
    height: 30px;
    margin-left: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f3f3f3;

    font-weight: 600;
    color: #477753;
    text-transform: uppercase;
`;

const StyledIcon = styled(DoneIcon)`
    margin-right: 5px;
`;

const XeroBilledStatusSection: FC<XeroBilledStatusSectionProps> = (props) => {
    const { request, readonly } = props;

    const isApproved = request.statusV2 === domain.RequestStatusV2.Approved;

    const [showMarkAsBilledPopup, setShowMarkAsBilledPopup] = useState(false);
    const isLocked = useSelector(isActiveRequestLocked);
    const commands = useSelector((state) => selectors.request.getXeroMatchingCommands(state, request));
    const details = request.details;

    const isCompanyReadonly = request.company.isReadonly;

    const onChangeMarkAsBilled = () => {
        setShowMarkAsBilledPopup(true);
    };

    if (!isApproved) {
        return null;
    }

    return (
        <StyledSectionContainer
            text={
                <HeaderContainer>
                    <Title>{messages.sectionHeaderText}</Title>

                    {details.isBilled ? (
                        <ButtonsContainer>
                            {!readonly && (
                                <ActionMenu
                                    button={
                                        <ShowMoreButton disabled={isLocked}>
                                            <TbEllipsisIcon width={24} height={6} />
                                        </ShowMoreButton>
                                    }
                                >
                                    <ActionMenu.Item
                                        disabled={isLocked}
                                        command={commands.unmarkAsBilled}
                                        execute={onChangeMarkAsBilled}
                                        title={messages.unmarkAsBilledMenuItemTitle}
                                    >
                                        {messages.unmarkAsBilledMenuItemText}
                                    </ActionMenu.Item>
                                </ActionMenu>
                            )}

                            <Billed>
                                <StyledIcon width={13} height={10} />

                                {messages.billedStatus}
                            </Billed>
                        </ButtonsContainer>
                    ) : (
                        <Button
                            disabled={isLocked || isCompanyReadonly || readonly}
                            execute={onChangeMarkAsBilled}
                            command={commands.markAsBilled}
                            title={messages.markAsBilledButtonTitle}
                            colorTheme='grey'
                        >
                            {messages.markAsBilled}
                        </Button>
                    )}
                </HeaderContainer>
            }
        >
            <Popup isOpen={showMarkAsBilledPopup} onRequestClose={() => setShowMarkAsBilledPopup(false)}>
                <XeroMarkAsBilledPopup request={request} />
            </Popup>
        </StyledSectionContainer>
    );
};

export default memo(XeroBilledStatusSection);
