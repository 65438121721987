import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('pages.RequestFormPage.NetSuiteExpenseReportRequestForm', {
    title: 'Expense Report Details',

    sectionDetailsTitle: 'Expense Report Details',
    sectionExpensesTitle: 'Expenses',

    fieldTransactionNumber: 'Exp. Rept. #',
    fieldEmployee: 'Employee',
    fieldExchangeRate: 'Exchange rate',
    fieldAdvance: 'Advance',
    fieldPurpose: 'Purpose',
    fieldTransactionDate: 'Date',
    fieldDueDate: 'Due Date',
    fieldPostingPeriod: 'Posting Period',
    fieldDepartment: 'Department',
    fieldClass: 'Class',
    fieldLocation: 'Location',
    fieldAccountForCorporateCardExpenses: 'Account For Corporate Card Expenses',

    errorRefLineNumbersNotUnique: 'Line reference numbers should be unique',
    errorRequiredFields: 'Please fill all mandatory fields.',
    errorExpenseLineTaxAmountExceed:
        'Expense line tax amount cannot exceed {maxValue} or be less than -{maxValue} and cannot contain more than {maxDigitsCount} digits',
    errorTotalReimbursableAmountLessZero: 'Total reimbursable amount cannot be negative.',
    errorTotalExpensesLessZero: 'Total expenses cannot be negative.',
    errorAdvanceToApplyLessZero: 'Advance to apply cannot be negative.',
    errorMemoMoreThanLimit: 'Purpose cannot contain more than {maxCharsCount} chars',
    errorCorporateCardMandatory: 'Please select an account for corporate card',
    errorExchangeRate:
        'Exchange rate is missing. Either retry loading exchange rates from NetSuite or enter manual exchange rate.',
});
