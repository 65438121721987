import { selectors } from 'modules/common';
import { UpdateProfile } from 'shared/data/webApp/v1';

import { fieldNamesSectionGeneral } from './SectionGeneral.constants';

export const getSectionGeneralSaveToProfileValues = (
    profile: selectors.types.ExpandedProfile,
    data: UpdateProfile['body']
) => {
    return {
        firstName: data[fieldNamesSectionGeneral.firstName] ?? profile.firstName,
        lastName: data[fieldNamesSectionGeneral.lastName] ?? profile.lastName,
        phone: data[fieldNamesSectionGeneral.phone] ?? profile.phone,
        timeZoneForEmail: data[fieldNamesSectionGeneral.timeZoneForEmail] ?? profile.timeZoneForEmail,
    };
};
