import { atom } from 'jotai';
import { schemas } from 'modules/data';

import { defaultLineItemNormalizer } from './PurchaseOrdersList.helpers';
import { LineItemNormalizer } from './PurchaseOrdersList.types';

const qBooksMatchingPopupAccountLineItemNormalizerAtom = atom<{
    normalizer: LineItemNormalizer<ReturnType<typeof schemas.mapAccountLineItem>>;
}>({ normalizer: defaultLineItemNormalizer });

export const qBooksMatchingPopupAccountLineItemNormalizerState = atom(
    (get) => get(qBooksMatchingPopupAccountLineItemNormalizerAtom).normalizer,
    (_get, set, newValue: LineItemNormalizer<ReturnType<typeof schemas.mapAccountLineItem>>) => {
        set(qBooksMatchingPopupAccountLineItemNormalizerAtom, { normalizer: newValue });
    }
);

const qBooksMatchingPopupLineItemNormalizerAtom = atom<{
    normalizer: LineItemNormalizer<ReturnType<typeof schemas.mapLineItem>>;
}>({ normalizer: defaultLineItemNormalizer });

export const qBooksMatchingPopupLineItemNormalizerState = atom(
    (get) => get(qBooksMatchingPopupLineItemNormalizerAtom).normalizer,
    (_get, set, newValue: LineItemNormalizer<ReturnType<typeof schemas.mapLineItem>>) => {
        set(qBooksMatchingPopupLineItemNormalizerAtom, { normalizer: newValue });
    }
);
