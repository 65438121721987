import { RequestParams, RequestQueryOptions } from '@approvalmax/data';
import { useGet } from 'shared/data';

import { integrationApiPaths } from '../paths';
import { GetDefaultTwoFaEnforcementType } from './useGetDefaultTwoFaEnforcementType.types';

export const useGetDefaultTwoFaEnforcementType = (
    params?: RequestParams<GetDefaultTwoFaEnforcementType>,
    queryOptions?: RequestQueryOptions<GetDefaultTwoFaEnforcementType>
) => {
    return useGet(integrationApiPaths.getDefaultTwoFaEnforcementType, {
        params,
        apiVersion: 'v1',
        method: 'get',
        queryOptions: {
            retry: 0,
            ...queryOptions,
        },
    });
};
