import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('modules.company.XeroDemoOrganisationPopup.ProcessingPopup', {
    title: 'We are populating the organisation with demo data',
    description: 'When it’s done, you’ll be redirected to the Decision Required page',
    warning: 'Do not close this browser tab before the task has been completed',
    pullCatalogs: 'Pulling Xero catalogues',
    creatingWorkflows: 'Creating approval workflows',
    creatingRequests: 'Creating Bills and Purchase Orders',
});
