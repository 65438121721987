import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('components.TwoFaEnablingWizard.StepBackupCodes', {
    title: 'Two-factor authentication setup',
    subtitle: 'Backup codes',
    downloadButton: 'Download codes (txt file)',
    checkboxText: 'Yes, I have saved the backup codes',
    buttonDone: 'Done',
    errorCodesAreSaved: 'This checkbox is required',
});
