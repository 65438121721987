import { createBrowserHistory } from 'history';
import { useAtomValue } from 'jotai';
import { actions } from 'modules/common';
import { FC, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import type { BrowserRouterProps } from 'react-router-dom';
import { leaveRouteConfirmationState } from 'shared/states/leaveRouteConfirmation';

import { HistoryProviderProps } from './HistoryProvider.types';

export const HistoryProvider: FC<HistoryProviderProps> = (props) => {
    const { children } = props;

    const dispatch = useDispatch();
    const leaveRouteConfirmation = useAtomValue(leaveRouteConfirmationState);
    const getUserConfirmationRef = useRef<BrowserRouterProps['getUserConfirmation']>();
    const [history] = useState(
        createBrowserHistory({
            getUserConfirmation: (...args) => getUserConfirmationRef.current?.(...args),
        })
    );

    const defaultLeaveRouteConfirmation: BrowserRouterProps['getUserConfirmation'] = (message, callback) => {
        dispatch(
            actions.showDiscardChangesPopup({
                unsavedChangesConfirmationCallback: callback,
            })
        );
    };

    getUserConfirmationRef.current = leaveRouteConfirmation ?? defaultLeaveRouteConfirmation;

    return <>{children({ history })}</>;
};

HistoryProvider.displayName = 'HistoryProvider';
