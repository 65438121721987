import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { useMount } from 'react-use';
import { useAddTwoFaEnablingBackupCodes, useFinishTwoFaEnablingWithBackupCodes } from 'shared/data/webApp/v1';
import { z } from 'zod';

import { useTwoFaWizardController } from '../../TwoFaEnablingWizard.hooks';
import { messages } from './StepBackupCodes.messages';
import { schema } from './StepBackupCodes.schema';

export const useStepBackupCodes = () => {
    const { wizardController, setWizardController } = useTwoFaWizardController();

    const { stateId, onFinish } = wizardController;

    const { mutate: loadBackupCodes, isLoading: isLoadingBackupCodes, data } = useAddTwoFaEnablingBackupCodes();
    const { mutate: finishTwoFaEnabling, isLoading: isLoadingFinishTwoFaEnabling } =
        useFinishTwoFaEnablingWithBackupCodes({
            onSuccess: () => {
                onFinish?.('backupCodes');
            },
        });

    const form = useForm<z.infer<typeof schema>>({
        defaultValues: {
            codesAreSaved: false,
        },
        resolver: zodResolver(schema),
    });

    const handleSubmit = form.handleSubmit(() => {
        if (stateId) {
            finishTwoFaEnabling({ body: { stateId } });
        }
    });

    useMount(() => {
        setWizardController((prev) => ({
            ...prev,
            title: messages.title,
            onPrev: undefined,
            onNext: undefined,
        }));

        if (stateId) {
            loadBackupCodes({ body: { stateId } });
        }
    });

    const codesAreSaved = form.watch('codesAreSaved');

    return {
        form,
        handleSubmit,
        codesAreSaved,
        backupCodes: data?.backupCodes || [],
        isLoading: isLoadingBackupCodes || isLoadingFinishTwoFaEnabling,
    };
};
