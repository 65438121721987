import { Grid, List, Text } from '@approvalmax/ui/src/components';
import uniqBy from 'lodash/uniqBy';
import { selectors } from 'modules/common';
import { useSelector } from 'modules/react-redux';
import { memo, useMemo } from 'react';
import { shallowEqual } from 'react-redux';

import { useCompanies } from '../../NewRequestPopup.hooks';
import { UserItem } from '../UserItem/UserItem';
import { messages } from './ManagersList.messages';

export const ManagersList = memo(() => {
    const { allCompanies, activeCompany } = useCompanies();

    const activeCompanyManagers = useSelector<ReturnType<typeof selectors.company.getCompanyMemberById>[]>(
        (state) =>
            activeCompany
                ? activeCompany.managers.map((user) =>
                      selectors.company.getCompanyMemberById(state, activeCompany, user)
                  )
                : [],
        shallowEqual
    );
    const allCompaniesManagers = useSelector<ReturnType<typeof selectors.company.getCompanyMemberById>[][]>(
        (state) =>
            activeCompany
                ? []
                : allCompanies.map((company) =>
                      company.managers.map((user) => selectors.company.getCompanyMemberById(state, company, user))
                  ),
        (a, b) => a.length === b.length && a.every((item, index) => shallowEqual(item, b[index]))
    );

    const managers = useMemo(() => {
        if (activeCompany) {
            return activeCompanyManagers;
        } else {
            return uniqBy(allCompaniesManagers.flat(), (user) => user.id).sort(selectors.user.userComparatorAsc);
        }
    }, [activeCompany, activeCompanyManagers, allCompaniesManagers]);

    return (
        <Grid gap={16} width='100%'>
            <Text font='body' fontSize='medium' textAlign='center'>
                {messages.description}
            </Text>

            {managers.length > 0 && (
                <List divider spacing='4'>
                    {managers.map((user) => (
                        <List.Item key={user.id}>
                            <UserItem user={user} />
                        </List.Item>
                    ))}
                </List>
            )}
        </Grid>
    );
});

ManagersList.displayName = 'ManagersList';
