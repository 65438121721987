import { useAtomValue } from 'jotai';
import { domain } from 'modules/data';
import { FC, memo, useEffect } from 'react';
import { ContentSplitViewMode, contentSplitViewState, netSuiteBillPaymentFieldValuesState } from 'shared/states';

import RequestCardSkeleton from '../../../components/RequestCard/RequestCard.skeleton';
import { NetSuiteRequestCard } from '../NetSuiteRequestCard/NetSuiteRequestCard';
import { useBillPaymentBill } from './NetSuiteBillLinkedToBillPaymentRequestCardWrapper.hooks';
import { NetSuiteBillLinkedToBillPaymentRequestCardWrapperProps } from './NetSuiteBillLinkedToBillPaymentRequestCardWrapper.types';

export const NetSuiteBillLinkedToBillPaymentRequestCardWrapper: FC<NetSuiteBillLinkedToBillPaymentRequestCardWrapperProps> =
    memo((props) => {
        const { setContainerTitle, billPaymentRequest } = props;

        const { companyId } = billPaymentRequest || {};
        const contentSplitView = useAtomValue(contentSplitViewState);
        const fieldValues = useAtomValue(netSuiteBillPaymentFieldValuesState);

        const billPaymentRequestId = billPaymentRequest?.id;

        const billLinkedToBillPaymentId =
            (contentSplitView.mode === ContentSplitViewMode.NetSuiteBillLinkedToBillPayment &&
                contentSplitView.billId) ||
            null;

        const payeeId = billPaymentRequest.details.payee?.id ?? fieldValues.payee?.id;
        const accountId = billPaymentRequest.details.payablesAccount?.id ?? fieldValues.payablesAccount?.id;
        const currencyId = billPaymentRequest.details.currency?.id ?? fieldValues.currency?.id;

        const billPaymentHasBill = billPaymentRequest.details.applyList.some(
            (item) => item.requestId === billLinkedToBillPaymentId
        );

        const { request } = useBillPaymentBill({
            companyId,
            currencyId,
            billPaymentRequestId,
            accountId,
            payeeId,
            lineRequestId: billLinkedToBillPaymentId || '',
            enabled: billPaymentHasBill,
        });

        useEffect(() => {
            if (request && request.displayName) {
                setContainerTitle(request.displayName);
            }
        }, [request, setContainerTitle]);

        if (!request) return <RequestCardSkeleton />;

        if (request.integrationType !== domain.IntegrationType.NetSuite) {
            return null;
        }

        return <NetSuiteRequestCard request={request} readonly />;
    });

NetSuiteBillLinkedToBillPaymentRequestCardWrapper.displayName = 'NetSuiteBillLinkedToBillPaymentRequestCardWrapper';
