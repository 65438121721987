import { enabledByParams, RequestParams, RequestQueryOptions } from '@approvalmax/data';
import { useGet } from 'shared/data';

import { integrationApiPaths } from '../paths';
import { GetNetSuiteExpenseCategories } from './useGetNetSuiteExpenseCategories.types';

export const useGetNetSuiteExpenseCategories = (
    params?: RequestParams<GetNetSuiteExpenseCategories>,
    queryOptions?: RequestQueryOptions<GetNetSuiteExpenseCategories>
) => {
    return useGet(integrationApiPaths.getNetSuiteExpenseCategories, {
        params,
        queryOptions: {
            ...queryOptions,
            enabled: enabledByParams<GetNetSuiteExpenseCategories['params']['query']>(
                {
                    companyId: params?.query?.companyId,
                    requestId: params?.query?.requestId,
                    ruleType: params?.query?.ruleType,
                },
                queryOptions
            ),
        },
        apiVersion: 'v1',
        mapToCamelCase: true,
    });
};
