import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('components.QBooksMatchingInfo.QBooksMatchingInfoContent', {
    deleteButtonTitle: 'Remove the matching',
    billColumnName: 'Bill',
    totalAmount: 'Total amount: {totalAmount}',
    dateColumnName: 'Date',
    deleteConfirmationText: 'Sure?',
    remove: 'Remove',
    date: 'Date:',
    amount: 'Amount:',
    accountColumnName: 'Category',
    descriptionColumnName: 'Description',
    taxColumnName: 'Tax',
    itemColumnName: 'Product/Service',
    customerColumnName: 'Customer',
    amountColumnName: 'Amount {currency}',
    remainingColumnName: 'Remaining {currency}',
    qtyColumnName: 'Qty',
    unitPriceColumnName: 'Rate',
    classColumnName: 'Class',
});
