import { Link } from '@approvalmax/ui';
import { errorHelpers, intl } from '@approvalmax/utils';
import { domain } from 'modules/data';
import { useSelector } from 'modules/react-redux';
import { DoneIcon } from 'modules/sprites';
import { font } from 'modules/styles';
import { LineItemsTable } from 'pages/requestList/components/lineItemsSection/LineItemsTable';
import { getActiveRequest } from 'pages/requestList/selectors/pageSelectors';
import { ColumnDefinition } from 'pages/requestList/selectors/types/ColumnDefinition';
import { FC, memo, useMemo } from 'react';
import styled from 'styled-components';
import { getPath, Path } from 'urlBuilder';

import { POTableItem } from '../../../../types';
import { messages } from './PurchaseOrderMatchingTable.messages';

const StyledLink = styled(Link)`
    ${font(12, '#477753', 'bold')}
`;

const StyledIconContainer = styled.div`
    display: flex;
    align-items: center;
`;

const getColumnDefinitions = (amountType: domain.MatchingAmountType | undefined, companyId: string) => {
    let totalAmountName = '';

    if (amountType) {
        switch (amountType) {
            case domain.MatchingAmountType.Gross:
                totalAmountName = messages.totalAmountColumnNameWithGross;
                break;

            case domain.MatchingAmountType.Net:
                totalAmountName = messages.totalAmountColumnNameWithNet;
                break;

            default:
                throw errorHelpers.assertNever(amountType);
        }
    }

    const columnDefinitions: ColumnDefinition<POTableItem>[] = [
        {
            id: 'name',
            name: messages.itemColumnName,
            value: (li) => li.name,
            cell: (column, li) => <StyledLink href={getPath(Path.request, li.id, companyId)}>{li.name}</StyledLink>,
        },
        {
            id: 'approved',
            name: messages.approvedColumnName,
            value: (li) => (li.approved ? 'Yes' : 'No'),
            cell: (column, li) =>
                li.approved ? (
                    <StyledIconContainer>
                        <DoneIcon width={13} height={10} />
                    </StyledIconContainer>
                ) : null,
        },
        {
            id: 'matched_by',
            name: messages.matchedByColumnName,
            value: (li) => li.matchedBy,
        },
        {
            id: 'allocated_amount',
            name: messages.allocatedAmountColumnName,
            value: (li) => intl.formatCurrency(li.allocatedAmount, li.currency),
            alignRight: true,
        },
        {
            id: 'total_amount',
            name: totalAmountName,
            value: (li) => intl.formatCurrency(li.totalAmount, li.currency),
            alignRight: true,
            width: 150,
        },
    ];

    return columnDefinitions;
};

const AmountContainer = styled.div`
    display: flex;
    justify-content: flex-end;
`;

const Total = styled.span`
    color: #777d81;
`;

const Amount = styled.span`
    width: 155px;
    text-align: right;
    font-weight: bold;
`;

interface PurchaseOrderMatchingTableProps {
    lineItems: POTableItem[];
    totalAllocatedAmount: number;
    totalAmount: number;
    currency: string;
    companyId: string;
}

const PurchaseOrderMatchingTable: FC<PurchaseOrderMatchingTableProps> = (props) => {
    const { lineItems, totalAllocatedAmount, totalAmount, currency, companyId } = props;

    const billMatchingSettings = useSelector(getActiveRequest).company.billMatchingSettings;

    const columnDefinitions = useMemo(
        () => getColumnDefinitions(billMatchingSettings?.amountType, companyId),
        [billMatchingSettings?.amountType, companyId]
    );

    return (
        <div>
            <LineItemsTable columnDefinitions={columnDefinitions} lineItems={lineItems} />

            <AmountContainer>
                <Total>{messages.totalAllocatedText}</Total>

                <Amount>{intl.formatCurrency(totalAllocatedAmount, currency)}</Amount>

                <Amount>{intl.formatCurrency(totalAmount, currency)}</Amount>
            </AmountContainer>
        </div>
    );
};

export default memo(PurchaseOrderMatchingTable);
