import { RequestMutationOptions } from '@approvalmax/data';
import { useMutate } from 'shared/data';

import { authApiPaths } from '../paths';
import { AddTwoFaEnablingBackupCodes } from './useAddTwoFaEnablingBackupCodes.types';

export const useAddTwoFaEnablingBackupCodes = (
    mutationOptions?: RequestMutationOptions<AddTwoFaEnablingBackupCodes>
) => {
    return useMutate(authApiPaths.addTwoFaEnablingBackupCodes, {
        mutationOptions,
        apiVersion: 'v1',
        mapToCamelCase: true,
    });
};
