import { componentHelpers } from '@approvalmax/utils';
import { useToggle } from 'react-use';

import { Button, Dropdown, Menu } from '../../../../components';
import { DotsFilledIcon } from '../../../../icons';
import { BaseItem } from '../../Table.types';
import { RowActionMenuCell } from './BodyRowActionMenu.styles';
import { BodyRowActionMenuProps } from './BodyRowActionMenu.types';

export const BodyRowActionMenu = componentHelpers.genericMemo(
    <Item extends BaseItem>(props: BodyRowActionMenuProps<Item>) => {
        const { actions, item, progress } = props;

        const [open, toggleOpen] = useToggle(false);

        if (progress) {
            return <RowActionMenuCell />;
        }

        return (
            <RowActionMenuCell $open={open}>
                <Dropdown
                    activator={
                        <Button icon variant='text' color='blue100'>
                            <DotsFilledIcon size={20} />
                        </Button>
                    }
                    width='max-content'
                    height='max-content'
                    open={open}
                    onOpen={toggleOpen}
                >
                    <Menu size='small'>
                        {actions.map((action, index) => (
                            <Menu.Item
                                key={index}
                                name={action.label}
                                title={action.label}
                                onClick={() => {
                                    action.onClick(item);
                                    toggleOpen(false);
                                }}
                                disabled={action.disabled}
                            />
                        ))}
                    </Menu>
                </Dropdown>
            </RowActionMenuCell>
        );
    }
);

BodyRowActionMenu.displayName = 'BodyRowActionMenu';
