import { openSplitViewState } from '@approvalmax/ui/src/components';
import { useSetAtom } from 'jotai';
import { domain } from 'modules/data';
import { useSelector } from 'modules/react-redux';
import { api } from 'services/api';
import { splitViewGoToBarDefaultSettings, splitViewGoToBarSettingsState } from 'shared/components';
import { ContentSplitViewMode, contentSplitViewState } from 'shared/states';

import { getRequest } from '../selectors/pageSelectors';

export const useShowAttachment = (props: { customHandle?: (id: string) => void } = {}) => {
    const { customHandle } = props;

    const request = useSelector(getRequest);
    const setOpenSplitView = useSetAtom(openSplitViewState);
    const setContentSplitView = useSetAtom(contentSplitViewState);
    const setSplitViewGoToBarSettings = useSetAtom(splitViewGoToBarSettingsState);

    const showAttachment = (attachment: domain.RequestAttachment, newTab: boolean) => {
        if (newTab) {
            window.open(
                api.requests.urls.getAttachment({ attachmentId: attachment.id, companyId: request.companyId }),
                '_blank'
            );
        } else {
            if (customHandle) {
                customHandle(attachment.id);
            } else {
                setSplitViewGoToBarSettings(splitViewGoToBarDefaultSettings);
                setOpenSplitView(true);
                setContentSplitView({
                    mode: ContentSplitViewMode.AttachmentsToRequest,
                    attachmentId: attachment.id,
                });
            }
        }
    };

    return {
        showAttachment,
    };
};
