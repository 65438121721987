import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages(
    'pages.RequestList.XeroMatchingV2Section.BillView.ChangeMatchingPopup.MatchingPopupLinks',
    {
        text1: 'ApprovalMax allows to manually match incoming Bills with the Purchase Orders. {learnMoreLink}',
        learnMoreLinkText: 'Learn more',
        text2: 'See {videoLink} on How to match Bills with Purchase Orders',
        videoUrl: 'video',
    }
);
