import { RequestMutationOptions } from '@approvalmax/data';
import { useMutate } from 'shared/data';

import { requestsApiPaths } from '../paths';
import { RetryRequest } from './useRetryRequest.types';

export const useRetryRequest = (mutationOptions?: RequestMutationOptions<RetryRequest>) => {
    return useMutate(requestsApiPaths.retry, {
        mutationOptions,
        apiVersion: 'v1',
        method: 'post',
    });
};
