import { FC } from 'react';

import { Root } from './ScrollableContainer.styles';
import { ScrollableContainerProps } from './ScrollableContainer.types';

export const ScrollableContainer: FC<ScrollableContainerProps> = (props) => {
    const { spacingTop, spacingRight, spacingBottom, spacingLeft, orientation, children } = props;

    return (
        <Root
            $orientation={orientation}
            $spacingTop={spacingTop}
            $spacingBottom={spacingBottom}
            $spacingRight={spacingRight}
            $spacingLeft={spacingLeft}
        >
            {children}
        </Root>
    );
};

ScrollableContainer.displayName = 'ScrollableContainer';
