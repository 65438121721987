import { Button, Popup } from '@approvalmax/ui';
import Dropdown from '@approvalmax/ui/src/old/drop/dropdown/Dropdown';
import { hooks } from '@approvalmax/utils';
import { useSetAtom } from 'jotai';
import { selectors } from 'modules/common';
import { domain } from 'modules/data';
import { useSelector } from 'modules/react-redux';
import { useUserProfile } from 'modules/utils';
import { FC, memo, useCallback, useState } from 'react';

import { schedulePaymentPopupOpenState } from '../../../../xero/XeroRequestCard/components/SchedulePaymentPopup/SchedulePaymentPopup.states';
import CompleteAirwallexPaymentPopup from './components/CompleteAirwallexPaymentPopup/CompleteAirwallexPaymentPopup';
import { messages } from './PayWithAirwallex.messages';
import { DropdownItem, DropdownPanel, Root } from './PayWithAirwallex.styles';
import { PayWithAirwallexProps } from './PayWithAirwallex.types';

const PayWithAirwallex: FC<PayWithAirwallexProps> = memo((props) => {
    const { request } = props;

    const [isOpenDropdown, setIsOpenDropdown] = useState(false);
    const openDropdown = useCallback(() => setIsOpenDropdown(true), []);
    const closeDropDown = useCallback(() => setIsOpenDropdown(false), []);

    const [isOpenCompleteAirwallexPaymentPopup, toggleIsOpenCompleteAirwallexPaymentPopup] = hooks.useToggle(false);
    const setIsOpenSchedulePaymentPopup = useSetAtom(schedulePaymentPopupOpenState);

    const profile = useUserProfile();
    const company = useSelector((state) => selectors.company.findCompanyById(state, request.companyId));

    const details = request.integrationCode === domain.IntegrationCode.XeroAirwallexBatchPayment && request.details;
    const isAwaitingPayment = details && details.paymentStatus === domain.AirwallexPaymentStatus.AwaitingPayment;
    const is2faEnabled = profile.is2faEnabled;
    const isActiveAirwallexIntegration = company?.airwallexIntegration?.isConnected;
    const isActiveXeroIntegration =
        company?.integration?.integrationType === domain.IntegrationType.Xero &&
        company?.integration?.status === domain.IntegrationStatus.Connected;
    const isPayer = Boolean(request.payers.find((p) => p.userEmail === profile.email));
    const hasScheduledPayment = details && Boolean(details.scheduledPaymentDate);

    const onRequestClose = useCallback(() => {
        toggleIsOpenCompleteAirwallexPaymentPopup(false);
    }, [toggleIsOpenCompleteAirwallexPaymentPopup]);

    const openSchedulePaymentPopup = useCallback(
        () => setIsOpenSchedulePaymentPopup(true),
        [setIsOpenSchedulePaymentPopup]
    );
    const openCompleteAirwallexPaymentPopup = useCallback(
        () => toggleIsOpenCompleteAirwallexPaymentPopup(true),
        [toggleIsOpenCompleteAirwallexPaymentPopup]
    );

    if (!details || !isAwaitingPayment || !isPayer) {
        return null;
    }

    const disabled = !is2faEnabled || !isActiveAirwallexIntegration || !isActiveXeroIntegration || hasScheduledPayment;

    let buttonTitle: string;

    if (disabled) {
        if (!isActiveAirwallexIntegration) {
            buttonTitle = messages.disconnectedAirwallex;
        } else if (!isActiveXeroIntegration) {
            buttonTitle = messages.disconnectedXero;
        } else if (hasScheduledPayment) {
            buttonTitle = messages.alreadyScheduled;
        } else {
            buttonTitle = messages.twoFaIsNotEnabled;
        }
    } else {
        buttonTitle = messages.payWithAirwallex;
    }

    return (
        <>
            <Root>
                <Dropdown
                    isOpen={isOpenDropdown}
                    onRequestClose={closeDropDown}
                    button={(buttonRef) => (
                        <div ref={buttonRef}>
                            <Button
                                execute={openDropdown}
                                title={buttonTitle}
                                disabled={disabled}
                                colorTheme='airwallex'
                            >
                                {messages.payWithAirwallex}
                            </Button>
                        </div>
                    )}
                    panelFlow='to-right'
                >
                    <DropdownPanel onClick={closeDropDown}>
                        <DropdownItem onClick={openCompleteAirwallexPaymentPopup}>{messages.payNow}</DropdownItem>

                        <DropdownItem onClick={openSchedulePaymentPopup}>{messages.schedulePayment}</DropdownItem>
                    </DropdownPanel>
                </Dropdown>
            </Root>

            <Popup isOpen={isOpenCompleteAirwallexPaymentPopup} onRequestClose={onRequestClose} disableAutoClose>
                <CompleteAirwallexPaymentPopup onRequestClose={onRequestClose} request={request} />
            </Popup>
        </>
    );
});

PayWithAirwallex.displayName = 'PayWithAirwallex';

export default PayWithAirwallex;
