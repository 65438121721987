import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('pages.RequestList.NetSuiteRequestCard.NetSuiteInvoiceItemList', {
    sectionHeaderText: 'Items',
    item: 'Item',
    units: 'Units',
    priceLevel: 'Price level',
    quantity: 'Quantity',
    description: 'Description',
    rate: 'Rate',
    amount: 'Amount',
    taxCode: 'Tax code',
    taxRate: 'Tax rate',
    taxAmount: 'Tax amount',
    grossAmount: 'Gross amount',
    department: 'Department',
    class: 'Class',
    location: 'Location',
});
