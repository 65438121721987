import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('app.WorkflowIdPage.WorkflowSettingsPopup.SectionNetSuiteFraudChangesAfter', {
    title: 'Fraud detection — changes made after approval',
    subtitle: `
        Which changes made in NetSuite after the approval in ApprovalMax should trigger the marking as
        "Changed after approval" and a notification to the Organisation's Administrators?
    `,
    optionCustomer: 'Customer',
    optionPayee: 'Payee',
    optionVendor: 'Vendor',
    optionTotalAmount: 'Total amount (more than 0.1 {companyCurrencyCode} change)',
    optionDate: 'Date',
    optionMemo: 'Memo',
    optionItem: 'Item',
    optionAccount: 'Account',
    optionExpenseCategory: 'Expense category',
    optionLine: 'Line amounts',
    optionBillPaymentLine: "Line's bill or credit",
});
