import { useSetAtom } from 'jotai';
import { useCallback, useEffect } from 'react';

import { backHeaderState, breadcrumbsHeaderState, dividerHeaderState, slotHeaderState } from './Header.states';
import { HeaderProps } from './Header.types';

export const useResetHeaderStates = (props: Pick<HeaderProps, 'resetState'>) => {
    const { resetState } = props;

    const setHasDividerHeader = useSetAtom(dividerHeaderState);
    const setBackHeaderState = useSetAtom(backHeaderState);
    const setBreadcrumbsHeader = useSetAtom(breadcrumbsHeaderState);
    const setSlotHeader = useSetAtom(slotHeaderState);

    const reset = useCallback(() => {
        setHasDividerHeader(true);
        setBackHeaderState(undefined);
        setBreadcrumbsHeader([]);
        setSlotHeader(null);
    }, [setHasDividerHeader, setBackHeaderState, setBreadcrumbsHeader, setSlotHeader]);

    useEffect(() => {
        resetState?.(reset);
    }, [reset, resetState]);
};
