import { forwardRef, memo } from 'react';
import { useController } from 'react-hook-form';

import NumberField from '../../NumberField';
import { HTMLNumberFieldElement } from '../../NumberField.types';
import { ControllerProps } from './Controller.types';

/**
 * The `NumberField.Controller` allows you to use the `NumberField` with the `react-hook-form` library.
 * They take the same properties as the `NumberField` component,
 * plus the `control` property to bind it with the library and `rules` for validation.
 *
 * You can use control without the `Controller` subcomponent if it is in a `Form` or `Form.Part` component.
 */
const Controller = memo(
    forwardRef<HTMLNumberFieldElement, ControllerProps>((props, ref) => {
        const { control, name, defaultValue, ...restProps } = props;

        const { field, fieldState } = useController({
            control,
            name: name || 'numberField',
            rules: {
                min: restProps.min,
                max: restProps.max,
                required: restProps.required,
            },
            defaultValue,
        });

        return (
            <NumberField
                {...field}
                {...fieldState}
                {...restProps}
                ref={ref}
                hint={fieldState.error?.message || restProps.hint}
            />
        );
    })
);

Controller.displayName = 'Controller';

export default Controller;
