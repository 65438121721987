import { Table } from '@approvalmax/ui/src/components';
import { FC, useMemo } from 'react';
import { useCompanyBetaFeatures } from 'shared/hooks';

import { getColumns } from './BillInvoiceTable.config';
import { BillInvoiceTableProps } from './BillInvoiceTable.types';

export const BillInvoiceTable: FC<BillInvoiceTableProps> = (props) => {
    const { selectableBills, toggleBills, sortingConfig, changeSortingConfig, companyId } = props;

    const { isBatchPaymentAmountsV2 } = useCompanyBetaFeatures(companyId);

    const columnsInScope = useMemo(
        () => getColumns(companyId, sortingConfig, isBatchPaymentAmountsV2),
        [companyId, sortingConfig, isBatchPaymentAmountsV2]
    );

    const tableData = useMemo(() => {
        return selectableBills.map((bill) => ({
            ...bill,
            id: bill.xeroBillInvoiceRequestId,
        }));
    }, [selectableBills]);

    const checkedItems = useMemo(() => {
        return selectableBills
            .filter((selectableBill) => selectableBill.selected)
            .map((selectableBills) => selectableBills.xeroBillInvoiceRequestId);
    }, [selectableBills]);

    if (selectableBills.length === 0) return null;

    return (
        <Table
            columns={columnsInScope}
            data={tableData}
            checkedItems={checkedItems}
            onCheckedItemsChange={toggleBills}
            onSort={changeSortingConfig}
        />
    );
};

BillInvoiceTable.displayName = 'BillInvoiceTable';
