import { Popup } from '@approvalmax/ui/src/components';
import { useAtom } from 'jotai';
import { FC, memo, useCallback, useEffect } from 'react';
import { amplitudeService } from 'services/amplitude';

import { PopupContent } from './components';
import { pushingConnectGLPopupState } from './PushingConnectGLPopup.states';

export const PushingConnectGLPopup: FC = memo(() => {
    const [data, setData] = useAtom(pushingConnectGLPopupState);

    useEffect(() => {
        if (data) {
            amplitudeService.sendData('signup: land on modal that promotes gl connection for stand-alone orgs', {
                user_id: data.profileId,
                org_id: data.companyId,
            });
        }
    }, [data]);

    const handleToggle = useCallback(
        (open: boolean) => {
            if (!open) {
                setData(null);
            }
        },
        [setData]
    );

    const handleClose = useCallback(() => {
        handleToggle(false);
    }, [handleToggle]);

    return (
        <Popup open={!!data} onToggle={handleToggle} size='large'>
            {!!data && <PopupContent profileId={data.profileId} companyId={data.companyId} onClose={handleClose} />}
        </Popup>
    );
});

PushingConnectGLPopup.displayName = 'PushingConnectGLPopup';
