import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('pages.RequestList.NetSuiteRequestCard.NetSuiteInvoiceFooterFields', {
    salesDetailsSection: 'Sales Details',
    shippingDetailsSection: 'Shipping Details',
    salesRep: 'Sales rep',
    opportunity: 'Opportunity',
    salesEffectiveDate: 'Sales effective date',
    excludeCommissions: 'Exclude commissions',
    leadSource: 'Lead source',
    partner: 'Partner',
    shipDate: 'Shipping date',
    shipMethod: 'Shipping method',
    shippingAddress: 'Shipping address',
    positiveBooleanValue: 'Yes',
});
