import { Transfer } from '@approvalmax/ui/src/components';
import { useSetAtom } from 'jotai';
import uniqBy from 'lodash/uniqBy';
import { selectors } from 'modules/common';
import { domain } from 'modules/data';
import { useSelector } from 'modules/react-redux';
import { FC, memo, useCallback, useMemo, useState } from 'react';
import { useDebounce, useMount } from 'react-use';
import { useFieldData } from 'shared/hooks/useFieldData';

import { groupOfValuesSelectionState } from '../../GroupOfValuesPopup.states';
import { messages } from './TransferAccount.messages';
import { TransferAccountProps } from './TransferAccount.types';

export const TransferAccount: FC<TransferAccountProps> = memo((props) => {
    const { companyId, integrationCode, selectedItems, isLoading, onChange } = props;

    const setGroupOfValuesSelection = useSetAtom(groupOfValuesSelectionState);
    const [queryValue, setQueryValue] = useState('');
    const [query, setQuery] = useState('');
    const field = useSelector((state) =>
        selectors.field.getFieldsBySystemPurpose(state, companyId, domain.FieldSystemPurpose.XeroAccount)
    )[0];

    useDebounce(() => setQuery(queryValue), 600, [queryValue]);

    const params = useMemo(() => ({ field, integrationCode, query: query.trim() }), [field, integrationCode, query]);

    const { trigger, items, isFetching } = useFieldData(params);

    useMount(trigger);

    const allItems = useMemo(() => [...selectedItems, ...items], [items, selectedItems]);

    const handleChange = useCallback(
        (selectedItemsIds: string[]) => {
            const newSelectedItems = allItems.filter((item) => selectedItemsIds.includes(item.id));

            onChange(uniqBy(newSelectedItems, 'id'));
        },
        [allItems, onChange]
    );

    const selectedItemsIds = useMemo(() => selectedItems.map((item) => item.id), [selectedItems]);

    const leftPanelDescription = useCallback(
        (_checkedItemsCount: number, totalItemsCount: number) =>
            messages.leftPanelDescription({ count: totalItemsCount }),
        []
    );

    const rightPanelDescription = useCallback(
        (_checkedItemsCount: number, totalItemsCount: number) =>
            messages.rightPanelDescription({ count: totalItemsCount }),
        []
    );

    return (
        <Transfer
            items={allItems}
            selected={selectedItemsIds}
            onChange={handleChange}
            itemNameKey='text'
            height='450px'
            leftPanelTitle={messages.leftPanelTitle}
            rightPanelTitle={messages.rightPanelTitle}
            leftPanelDescription={leftPanelDescription}
            rightPanelDescription={rightPanelDescription}
            progress={isLoading || isFetching}
            preventSearch
            onSearch={setQueryValue}
            onSelectionChange={setGroupOfValuesSelection}
        />
    );
});

TransferAccount.displayName = 'TransferAccount';
