import { DateTimeIsoString, DeepNullablePartial, Guid, Reference } from '@approvalmax/types';
import { backend, domain } from 'modules/data';

import { DeepenObj } from '../utils/types';

export interface AuthFinishTFALoginTransfer {
    stateId: string;
    code: string;
    isDeviceTrusted: boolean;
}

export interface AuthLoginTransfer {
    email: string;
    pwd: string;
}

export interface AuthOAuthGetUrlTransfer {
    redirectUrl: string;
    ssoStartingPoint: 'approvalmax' | 'xero-marketplace' | 'qbooks-marketplace';
    uiState: string;
}

export interface AuthOAuthLoginTransfer {
    state: string;
    code: string;
    emailAddress?: string;
}

export interface RegisterViaOAuthTransfer {
    transientToken: string;
    firstName: string;
    lastName: string;
    email: string;
    phone?: string;
    timeZoneForEmail: string | null;
    sendConfirmationLink?: boolean;
    acceptedProductNewsEmails?: boolean;
    qualificationQuestionAnswers?: object;
    isMobileFlow: boolean;
}

export interface AuthRegisterTransfer {
    email: string;
    timeZoneForEmail: string | null;
    sendConfirmationLink?: boolean | undefined;
    acceptedProductNewsEmails?: boolean | undefined;
    uiState: string;
    qualificationQuestionAnswers?: object;
    isMobileFlow: boolean;
}

export interface AuthResetPwdTransfer {
    email: string;
    sendConfirmationLink?: boolean | undefined;
}

export interface AuthSendTFALoginEmailCodeTransfer {
    stateId: string;
}

export interface AuthSetPwdTransfer {
    pwd: string;
    timeZoneForEmail: string | null;
}

/**
 * @deprecated Not used in a new hook {@link useUpdatePassword}
 */
export interface AuthUpdatePwdTransfer {
    oldPwd: string;
    newPwd: string;
}

export interface AuthVerifyTransfer {
    email?: string;
    verificationCode?: string;
    linkCode: string;
    eternal?: boolean | undefined;
}

export interface AuthCannyIoTokenTransfer {
    Token: string;
}

export interface CommentBaseTransfer {
    requestId: Guid;
    commentText?: string | null;
    commentAttachments?: Guid[];
    mentionedUserIds?: string[];
}

export interface CommentDeleteAttachmentTransfer {
    commentAttachmentId: Guid;
    companyId: Guid;
}

export interface CommentGetAttachmentTransfer {
    attachmentId: Guid;
    inline?: boolean;
    companyId: Guid;
}

export interface CompaniesAddParticipantsTransfer {
    companyId: Guid;
    userEmailsWithRoles: Record<string, domain.CompanyUserRole>;
    doInvite?: boolean | undefined;
    invitationText?: string;
}

export interface CompaniesEditParticipantsTransfer {
    companyId: Guid;
    participants: backend.CompaniesParticipant[];
}

export interface CompaniesIntegrationSyncProgressTransfer {
    integrationIds: Guid[];
}

export interface CompaniesInviteParticipantsTransfer {
    companyId: Guid;
    participantUserIdsWithRoles: Record<string, domain.CompanyUserRole>;
    invitationText?: string;
}

export interface CompaniesRespondToInvitationTransfer {
    responses: backend.CompaniesResponseToParticipantInvitation[];
    companyId?: string;
}

export interface CompanyCreateTransfer {
    name?: string;
    defaultCurrency?: string;
    timeZone: string | null;
    ownerEmail: string;
    integration?: UserCompanyCreateAnswers;
}

export interface CompanyDelegateTransfer extends Omit<backend.CompaniesDelegateInfo, 'delegateEmail'> {
    companyId: Guid;
    delegatorUserProfileId?: Guid;
    delegateUserProfileId?: Guid;
}

export interface CompanyEditTransfer extends CompanyTransfer {
    name: string;
    defaultCurrency?: string;
    timeZone?: string;
    enforcementTfaType: domain.TwoFaEnforcementType;
}

export enum PracticeInvitationResponse {
    Accepted = 'Accepted',
    Rejected = 'Rejected',
}

export interface RespondToPracticeInvitationTransfer {
    invitations: Array<{
        accountId: string;
        response: PracticeInvitationResponse;
    }>;
}

export interface CompanySelectTransfer {
    companyId?: Guid | undefined;
}

export interface CompanyTransfer {
    companyId: Guid;
}

export interface CreateFieldsTransfer {
    companyId: Guid;
    fields: CreateFieldTransfer[];
}

export interface CreateFieldTransfer {
    name: string;
    fieldType: backend.FieldType;
    exactValues: FieldValueTransfer[];
    currencyISOCode?: string;
}

export interface DateRangeTransfer {
    type: backend.DateRangeType;
    custom: RangeTransfer<DateTimeIsoString | undefined>;
}

export interface DeleteFieldsTransfer {
    companyId: Guid;
    fieldIds: Guid[];
}

export interface EditFieldsTransfer {
    companyId: Guid;
    fields: EditFieldTransfer[];
}

export interface EditFieldTransfer extends CreateFieldTransfer {
    fieldId: Guid;
}

export interface ExactConstraintTransfer {
    id: string;
    value: string;
}

export interface ExchangeCodeForAccessTokenTransfer {
    state: string;
    code: string;
}

export interface FieldValueTransfer {
    fieldValueId: string;
    value: string;
}

export interface FilteredRequestReportColumnTransfer<T> extends RequestReportColumnTransfer {
    filteringValue: T;
}

export interface GetIntegrationCacheStatusTransfer {
    integrationId: Guid;
    companyId: Guid;
}

export interface GetIntegrationDataTransfer {
    companyId: string;
    currencyCode?: string;
    query?: string | null;
    requestId: Guid;
    rowNum?: number | null;
    ruleType: backend.EditRoleForRequest;
    startFrom?: number;
    stepId?: Guid;
}

export interface QBooksGetPurchaseItems {
    companyId: Guid;
    requestId: Guid;
    rowNum?: number | null;
    ruleType: backend.EditRoleForRequest;
    searchQuery?: string | null;
    startFrom?: number | null;
    stepId?: Guid | null;
    systemPurpose?: backend.FieldSystemPurpose;
}

export interface QBooksGetSalesItems {
    companyId: Guid;
    requestId: Guid;
    rowNum?: number | null;
    ruleType: backend.EditRoleForRequest;
    searchQuery?: string | null;
    startFrom?: number | null;
    stepId?: Guid | null;
    systemPurpose?: backend.FieldSystemPurpose;
}

export interface GetNetSuiteCustomersTransfer extends GetIntegrationDataTransfer {
    sublistType?: string | null;
    entityId?: string | null;
}

export interface GetIntegrationNetSuiteCustomRecordsTransfer {
    requestId: string;
    fieldId: string;
    ruleType: backend.EditRoleForRequest;
    query?: string;
    startFrom?: number | undefined;
    rowNum?: number | undefined;
    companyId?: string;
    sublistType?: string | null;
}

export interface GetTrackingCategoryTransfer extends GetIntegrationDataTransfer {
    trackingCategoryId: string;
}

export interface IntegrationQBooksQBooksAccountBasedLineTransfer extends QBooksLineTransfer {
    accountId: string;
}

export interface IntegrationQBooksQBooksBillInvoiceAccountBasedLineTransfer
    extends IntegrationQBooksQBooksAccountBasedLineTransfer {
    isBillable?: boolean | undefined;
    isTaxable?: boolean | undefined;
}

export interface IntegrationQBooksQBooksBillInvoiceItemBasedLineTransfer
    extends IntegrationQBooksQBooksItemBasedLineTransfer {
    isBillable?: boolean | undefined;
    isTaxable?: boolean | undefined;
}

export interface IntegrationQBooksQBooksBillInvoiceTransfer {
    date: string;
    dueDate: string;
    billInvoiceNumber: string;
    vendorId: string;
    termId: string;
    departmentId: string;
    mailingAddress: string;
    memo: string;
    subTotal?: number | undefined;
    totalTax?: number | undefined;
    taxComponents: QbooksTaxComponentTransfer[];
    lineAmountType: backend.AccountingDocumentsLineAmountType;
    lineItems: IntegrationQBooksQBooksBillInvoiceItemBasedLineTransfer[];
    accountBasedLines: IntegrationQBooksQBooksBillInvoiceAccountBasedLineTransfer[];
}

export interface IntegrationQBooksQBooksExpenseInvoiceAccountBasedLineTransfer
    extends IntegrationQBooksQBooksAccountBasedLineTransfer {
    isBillable?: boolean | undefined;
    isTaxable?: boolean | undefined;
}

export interface IntegrationQBooksQBooksExpenseInvoiceItemBasedLineTransfer
    extends IntegrationQBooksQBooksItemBasedLineTransfer {
    isBillable?: boolean | undefined;
    isTaxable?: boolean | undefined;
}

export interface IntegrationQBooksQBooksExpenseInvoiceTransfer {
    date: string;
    expenseNumber: string;
    vendorId: string;
    departmentId: string;
    mailingAddress: string;
    memo: string;
    subTotal?: number | undefined;
    totalTax?: number | undefined;
    taxComponents: QbooksTaxComponentTransfer[];
    lineAmountType: backend.AccountingDocumentsLineAmountType;
    lineItems: IntegrationQBooksQBooksExpenseInvoiceItemBasedLineTransfer[];
    accountBasedLines: IntegrationQBooksQBooksExpenseInvoiceAccountBasedLineTransfer[];
}

export interface IntegrationQBooksQBooksItemBasedLineTransfer extends QBooksLineTransfer {
    itemId: string;
    qty: string;
    qtyValue?: number | undefined;
    unitPrice: string;
    unitPriceValue?: number | undefined;
    unitPriceGross: string;
    unitPriceGrossValue?: number | undefined;
}

export interface IntegrationQBooksQBooksPurchaseOrderTransfer {
    vendorId: string;
    departmentId: string;
    mailingAddress: string;
    shippingAddress: string;
    shippingMethod: string;
    date: string;
    memo: string;
    vendorMessage: string;
    lineAmountType: backend.AccountingDocumentsLineAmountType;
    subTotal?: number | undefined;
    totalTax?: number | undefined;
    taxComponents: QbooksTaxComponentTransfer[];
    lineItems: IntegrationQBooksQBooksItemBasedLineTransfer[];
    accountBasedLines: IntegrationQBooksQBooksAccountBasedLineTransfer[];
    customFields: QBooksCustomFieldTransfer[];
}

export interface IntegrationQBooksSalesInvoiceItemBasedLineTransfer
    extends IntegrationQBooksQBooksItemBasedLineTransfer {
    serviceDate?: string;
}

export interface IntegrationQBooksSalesInvoiceTransfer {
    applyTaxAfterDiscount: boolean;
    billingAddress: string;
    customFields: QBooksCustomFieldTransfer[];
    customerId: string;
    customerMemo: string;
    date: string;
    departmentId: string;
    deposit: number;
    discount?: number;
    discountAmount?: number;
    dueDate: string;
    lineAmountType: backend.AccountingDocumentsLineAmountType;
    lineItems: IntegrationQBooksSalesInvoiceItemBasedLineTransfer[];
    memo: string;
    number: string;
    shipping: number;
    shippingAddress: string;
    shippingDate: string;
    shippingFromAddress: string;
    shippingMethod: string;
    shippingTaxCodeId: string;
    subTotal: number;
    taxCodeId: string;
    taxComponents: QbooksTaxComponentTransfer[];
    termId: string;
    trackingNumber: string;
    useAutoTaxes: boolean;
}

export interface IntegrationQBooksQBooksVendorTransfer {
    displayName: string;
    companyName?: string;
    title?: string;
    firstName?: string;
    middleName?: string;
    lastName?: string;
    suffix?: string;
    printOnCheckName?: string;
    phone?: string;
    mobile?: string;
    fax?: string;
    emailAddress?: string;
    webSite?: string;
    address: domain.QBooksVendorAddress;
    accountNumber?: string;
    notes?: string;
    currencyCode: string;
    businessNumber?: string;
}

export interface IntegrationTransfer extends CompanyTransfer {
    integrationId: Guid;
}

export type IntegrationXeroCreateCounterpartyTransfer = DeepNullablePartial<{
    companyId: Guid;
    templateId: Guid;
    name: string;
    firstName: string;
    lastName: string;
    emailAddress: string;
    skypeName: string;
    website: string;
    accountNumber: string;
    phone: backend.XeroDomainPhone;
    fax: backend.XeroDomainPhone;
    mobile: backend.XeroDomainPhone;
    directDial: backend.XeroDomainPhone;
    billingAddress: backend.XeroDomainAddressInfo;
    deliveryAddress: backend.XeroDomainAddressInfo;
    defaultCurrency: string;
    brandingThemeId: string;
    xeroNetworkKey: string;
    bankAccountDetails: string;
    purchaseAccountCode: string;
    salesAccountCode: string;
    taxNumber: string;
    companyNumber: string;
    salesTaxCode: string;
    purchaseTaxCode: string;
    purchasesTrackingCategories: backend.XeroDomainTrackingCategory[];
    salesTrackingCategories: backend.XeroDomainTrackingCategory[];
}>;

export interface IntegrationXeroFinancialDocumentTransfer {
    contactId: string;
    number: string;
    reference: string;
    brandingThemeId: string;
    date: string;
    deliveryDate: string;
    deliveryAddress: string;
    attentionTo: string;
    phone: string;
    deliveryInstructions: string;
    lineAmountType?: domain.LineAmountType;
    lineItems: LineItemTransfer[];
}

export interface XeroCreditNotesTransfer {
    contactId: string | null;
    reference: string | null;
    brandingThemeId: string | null;
    date: string | null;
    lineAmountType: domain.LineAmountType;
    lineItems: LineItemTransfer[] | null;
    number: string | null;
}

export interface IntegrationXeroInvoiceTransfer extends IntegrationXeroFinancialDocumentTransfer {
    dueDate: string;
    cisTaxAmount?: number;
}

export interface IntegrationXeroQuoteTransfer extends IntegrationXeroFinancialDocumentTransfer {
    expiryDate: string;
    title: string;
    summary: string;
    terms: string;
}

export interface ApprovalMaxXeroDomainModelsAddressInfo {
    /**
     *
     * @type {string}
     * @memberof ApprovalMaxXeroDomainModelsAddressInfo
     */
    address?: string;
    /**
     *
     * @type {string}
     * @memberof ApprovalMaxXeroDomainModelsAddressInfo
     */
    city?: string;
    /**
     *
     * @type {string}
     * @memberof ApprovalMaxXeroDomainModelsAddressInfo
     */
    region?: string;
    /**
     *
     * @type {string}
     * @memberof ApprovalMaxXeroDomainModelsAddressInfo
     */
    postalCode?: string;
    /**
     *
     * @type {string}
     * @memberof ApprovalMaxXeroDomainModelsAddressInfo
     */
    country?: string;
    /**
     *
     * @type {string}
     * @memberof ApprovalMaxXeroDomainModelsAddressInfo
     */
    attentionTo?: string;
}

export interface IntegrationXeroDeliveryAddressInfo extends ApprovalMaxXeroDomainModelsAddressInfo {
    sameAsBillingAddress?: boolean;
}

export interface ApprovalMaxXeroDomainModelsPhone {
    /**
     *
     * @type {string}
     * @memberof ApprovalMaxXeroDomainModelsPhone
     */
    countryCode?: string;
    /**
     *
     * @type {string}
     * @memberof ApprovalMaxXeroDomainModelsPhone
     */
    areaCode?: string;
    /**
     *
     * @type {string}
     * @memberof ApprovalMaxXeroDomainModelsPhone
     */
    number?: string;
}

export interface IntegrationsContactPerson {
    /**
     *
     * @type {string}
     * @memberof IntegrationsContactPerson
     */
    firstName?: string;
    /**
     *
     * @type {string}
     * @memberof IntegrationsContactPerson
     */
    lastName?: string;
    /**
     *
     * @type {string}
     * @memberof IntegrationsContactPerson
     */
    emailAddress?: string;
    /**
     *
     * @type {boolean}
     * @memberof IntegrationsContactPerson
     */
    includeInEmails?: boolean;
}

export interface IntegrationXeroContactTransfer {
    /**
     *
     * @type {string}
     * @memberof IntegrationXeroContactTransfer
     */
    contactId?: string;
    /**
     *
     * @type {string}
     * @memberof IntegrationXeroContactTransfer
     */
    number?: string;
    /**
     *
     * @type {string}
     * @memberof IntegrationXeroContactTransfer
     */
    reference?: string;
    /**
     *
     * @type {string}
     * @memberof IntegrationXeroContactTransfer
     */
    brandingThemeId?: string;
    /**
     *
     * @type {string}
     * @memberof IntegrationXeroContactTransfer
     */
    date?: string;
    /**
     *
     * @type {string}
     * @memberof IntegrationXeroContactTransfer
     */
    deliveryDate?: string;
    /**
     *
     * @type {string}
     * @memberof IntegrationXeroContactTransfer
     */
    attentionTo?: string;
    /**
     *
     * @type {string}
     * @memberof IntegrationXeroContactTransfer
     */
    phone?: string;
    /**
     *
     * @type {string}
     * @memberof IntegrationXeroContactTransfer
     */
    deliveryInstructions?: string;
    /**
     *
     * @type {DocumentTaxType}
     * @memberof IntegrationXeroContactTransfer
     */
    taxType?: 0 | 1 | 2;
    /**
     *
     * @type {Array<LineItemTransfer>}
     * @memberof IntegrationXeroContactTransfer
     */
    lineItems?: Array<LineItemTransfer>;
    /**
     *
     * @type {boolean}
     * @memberof IntegrationXeroContactTransfer
     */
    sendToContact?: boolean;
    /**
     *
     * @type {string}
     * @memberof IntegrationXeroContactTransfer
     */
    emailToVendorTo?: string;
    /**
     *
     * @type {string}
     * @memberof IntegrationXeroContactTransfer
     */
    emailToVendorCC?: string;
    /**
     *
     * @type {string}
     * @memberof IntegrationXeroContactTransfer
     */
    emailToVendorBCC?: string;
    /**
     *
     * @type {string}
     * @memberof IntegrationXeroContactTransfer
     */
    emailToVendorFrom?: string;
    /**
     *
     * @type {string}
     * @memberof IntegrationXeroContactTransfer
     */
    emailToVendorReplyTo?: string;
    /**
     *
     * @type {string}
     * @memberof IntegrationXeroContactTransfer
     */
    emailToVendorSubject?: string;
    /**
     *
     * @type {string}
     * @memberof IntegrationXeroContactTransfer
     */
    emailToVendorBody?: string;
    /**
     *
     * @type {Array<string>}
     * @memberof IntegrationXeroContactTransfer
     */
    supplierAttachments?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof IntegrationXeroContactTransfer
     */
    name?: string;
    /**
     *
     * @type {string}
     * @memberof IntegrationXeroContactTransfer
     */
    firstName: string | null;
    /**
     *
     * @type {string}
     * @memberof IntegrationXeroContactTransfer
     */
    lastName: string | null;
    /**
     *
     * @type {string}
     * @memberof IntegrationXeroContactTransfer
     */
    emailAddress: string | null;

    /**
     *
     * @type {string}
     * @memberof IntegrationXeroContactTransfer
     */
    accountNumber: string;
    /**
     *
     * @type {Array<ApprovalMaxXeroDomainEntitiesDocumentsXeroContactPerson>}
     * @memberof IntegrationXeroContactTransfer
     */
    persons?: Array<IntegrationsContactPerson>;
    /**
     *
     * @type {ApprovalMaxXeroDomainModelsPhone}
     * @memberof IntegrationXeroContactTransfer
     */
    phoneNumber?: ApprovalMaxXeroDomainModelsPhone;
    /**
     *
     * @type {ApprovalMaxXeroDomainModelsPhone}
     * @memberof IntegrationXeroContactTransfer
     */
    fax?: ApprovalMaxXeroDomainModelsPhone;
    /**
     *
     * @type {ApprovalMaxXeroDomainModelsPhone}
     * @memberof IntegrationXeroContactTransfer
     */
    mobile?: ApprovalMaxXeroDomainModelsPhone;
    /**
     *
     * @type {ApprovalMaxXeroDomainModelsPhone}
     * @memberof IntegrationXeroContactTransfer
     */
    directDial?: ApprovalMaxXeroDomainModelsPhone;
    /**
     *
     * @type {string}
     * @memberof IntegrationXeroContactTransfer
     */
    skypeName?: string;
    /**
     *
     * @type {ApprovalMaxXeroDomainModelsAddressInfo}
     * @memberof IntegrationXeroContactTransfer
     */
    billingAddress?: ApprovalMaxXeroDomainModelsAddressInfo;
    /**
     *
     * @type {IntegrationXeroDeliveryAddressInfo}
     * @memberof IntegrationXeroContactTransfer
     */
    deliveryAddress?: IntegrationXeroDeliveryAddressInfo;
    /**
     *
     * @type {string}
     * @memberof IntegrationXeroContactTransfer
     */
    bankAccountDetails?: string;
    /**
     *
     * @type {string}
     * @memberof IntegrationXeroContactTransfer
     */
    defaultCurrency?: string;
    /**
     *
     * @type {string}
     * @memberof IntegrationXeroContactTransfer
     */
    taxNumber?: string;
    /**
     *
     * @type {string}
     * @memberof IntegrationXeroContactTransfer
     */
    companyNumber?: string;
    /**
     *
     * @type {string}
     * @memberof IntegrationXeroContactTransfer
     */
    salesTax?: Reference;
    /**
     *
     * @type {string}
     * @memberof IntegrationXeroContactTransfer
     */
    purchaseTax?: Reference;
    /**
     *
     * @type {string}
     * @memberof IntegrationXeroContactTransfer
     */
    salesAccount?: Reference;
    /**
     *
     * @type {string}
     * @memberof IntegrationXeroContactTransfer
     */
    purchaseAccount?: Reference;
    /**
     *
     * @type {Array<ApprovalMaxXeroDomainModelsTrackingCategory>}
     * @memberof IntegrationXeroContactTransfer
     */
    salesTrackingCategories?: Array<backend.XeroDomainTrackingCategory>;
    /**
     *
     * @type {Array<ApprovalMaxXeroDomainModelsTrackingCategory>}
     * @memberof IntegrationXeroContactTransfer
     */
    purchaseTrackingCategories?: Array<backend.XeroDomainTrackingCategory>;
    /**
     *
     * @type {boolean}
     * @memberof IntegrationXeroContactTransfer
     */
    isSupplier?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof IntegrationXeroContactTransfer
     */
    isCustomer?: boolean;

    /**
     *
     * @type {boolean}
     * @memberof IntegrationXeroContactTransfer
     */
    isStatusPushPending: boolean;

    /**
     * A link to contact page within Xero
     * @type {string|null}
     * @memberof IntegrationXeroContactTransfer
     */
    url: string | null;

    salesAccountId?: string;
    purchasesAccountId?: string;
    salesTaxCode?: string;
    purchaseTaxCode?: string;
    purchasesTrackingCategories?: Array<backend.XeroDomainTrackingCategory>;
}

interface XeroBatchPaymentItemTransfer {
    xeroBillInvoiceRequestId: string;
    bankAccountNumber: string | null;
    details: string | null;
    amount: number;
    reference: string | null;
    code: string | null;
    particulars: string | null;
}

export interface IntegrationXeroBillBatchPaymentTransfer {
    /**
     *
     * @type {string}
     * @memberof IntegrationXeroContactTransfer
     */
    xeroBillBatchPaymentId: string;
    /**
     *
     * @type {string}
     * @memberof IntegrationXeroContactTransfer
     */
    paymentDate: string;
    /**
     *
     * @type {string | null}
     * @memberof IntegrationXeroContactTransfer
     */
    narrative: string | null;
    /**
     *
     * @type {string | null}
     * @memberof IntegrationXeroContactTransfer
     */
    code: string | null;
    /**
     *
     * @type {string | null}
     * @memberof IntegrationXeroContactTransfer
     */
    particulars: string | null;
    /**
     *
     * @type {string | null}
     * @memberof IntegrationXeroContactTransfer
     */
    details: string | null;
    /**
     *
     * @type {string}
     * @memberof IntegrationXeroContactTransfer
     */
    bankAccountId: string;
    /**
     *
     * @type {Array<XeroBatchPaymentItemTransfer>}
     * @memberof IntegrationXeroContactTransfer
     */
    items: Array<XeroBatchPaymentItemTransfer>;
    /**
     *
     * @type {string[]}
     * @memberof IntegrationXeroContactTransfer
     */
    reference: string | null;
}

interface XeroAmaxPayBatchPaymentItemTransfer {
    xeroBillInvoiceRequestId: string | null;
    amount: number | null;
    paymentReference: string | null;
    amaxPayBankAccountId: Guid | null;
    paymentDetailsVersionId: Guid | null;
}

interface XeroAirwallexBatchPaymentItemTransfer {
    xeroBillInvoiceRequestId: string | null;
    amount: number | null;
    bankAccountId: string | null;
    beneficiaryId: string | null;
    paymentReference: string | null;
    paymentPurpose: string | null;
    paymentMethod: string | null;
    sourceCurrency: string | null;
    paymentFee: string | null;
    beneficiaryVersion: number | null;
}

export interface AirwallexBeneficiarySchemaTransfer {
    companyId: Guid;
    accountCurrency?: string;
    bankCountryCode?: string;
    entityType?: string;
    paymentMethod?: string;
    localClearingSystem?: string;
}

export interface AirwallexCreateBeneficiaryFields {
    payment_methods: string[];

    [key: string]: string | string[] | DeepenObj | undefined;
}

export interface AirwallexCreateBeneficiaryTransfer {
    companyId: Guid;
    beneficiaryFields: AirwallexCreateBeneficiaryFields;
    oneTimePassword: string;
    requestId: Guid;
}

export interface IntegrationXeroAirwallexBatchPaymentTransfer {
    /**
     *
     * @type {Array<XeroAirwallexBatchPaymentItemTransfer>}
     * @memberof IntegrationXeroContactTransfer
     */
    items: Array<XeroAirwallexBatchPaymentItemTransfer>;
}

export interface IntegrationXeroAmaxPayBatchPaymentTransfer {
    items: Array<XeroAmaxPayBatchPaymentItemTransfer>;
    isMergedBySupplier: boolean;
}

export interface IntegrationXeroRefreshIntegrationCacheTransfer {
    integrationId: Guid;
    cacheTypes: backend.XeroClientCachesCacheType[];
    companyId: Guid;
}

export interface LineItemTransfer {
    id?: Guid | undefined;
    itemId: string;
    description: string;
    qty: string;
    qtyValue?: number | undefined;
    unitPrice: string;
    unitPriceValue?: number | undefined;
    unitPriceGross: string;
    unitPriceGrossValue?: number | undefined;
    discount: string;
    discountAmount: string;
    discountValue?: number | undefined;
    amount: string;
    amountValue?: number | undefined;
    taxAmount: string;
    taxAmountValue?: number | undefined;
    accountId: string;
    taxCode: string;
    trackingCategories: TrackingCategoryTransfer[];
    cisTaxAmount?: number;
    cisTaxRate?: number;
}

export interface OAuthEnableIntegrationTransfer {
    callbackUrl: string;
}

export interface OAuthFinishIntegrationTransfer extends CompanyTransfer {
    oauthToken?: string;
    oauthVerifier?: string;
    org?: string | null;
    realmId: string | null;
    state: string;
    code?: string;
}

export interface ProfileDeletePushConsumerTransfer {
    userPushConsumerId?: Guid | undefined;
    handle: string;
    deviceId: string;
}

export type ProfileEditTransfer = DeepNullablePartial<{
    firstName: string;
    lastName: string;
    defaultEmail: string;
    timeZoneForEmail: string;
    sendApprovalChangeNotifications: boolean;
    sendCommentsNotifications?: boolean;
    sendWorkflowNotifications?: boolean;
    sendNewRequestsNotifications?: boolean;
    sendSummaryNotifications?: boolean | undefined;
    summaryNotificationsSentOnDays?: string[];
    summaryNotificationsSentAtTime?: string;
    useSession: boolean;
    skype: string;
    phone: string;
    qualificationQuestionAnswers?: object;
}>;

export interface ProfileGetTransfer {
    email: string;
}

export interface ProfileRegisterPushConsumerTransfer {
    handle: string;
    deviceName: string;
    platform: backend.PushPlatform;
    deviceId: string;
}

export interface ProfileSetDelegateTranfer {
    delegates: CompanyDelegateTransfer[];
    companyId: string;
}

export interface ProfileSetTimeZoneForEmailTransfer {
    timeZoneForEmail: string;
}

export interface PullIntegrationsTransfer extends CompanyTransfer {
    integrationIds: Guid[];
}

export interface QBooksCustomFieldTransfer {
    id: string;
    name: string;
    value: string;
}

export interface QBooksFinishIntegrationTransfer extends OAuthFinishIntegrationTransfer {}

export interface QBooksLineTransfer {
    id?: Guid | undefined;
    customerId: string;
    classId: string;
    description: string;
    discount: string;
    amount: string;
    amountValue?: number | undefined;
    taxAmount: string;
    taxCode: string;
    matchedLineItemId?: string;
}

export interface QbooksTaxComponentTransfer {
    taxAmount: number;
    taxPercent: number;
    taxableAmount: number;
    taxRateId: string;
    hidden?: boolean | undefined;
}

export interface QBooksJournalEntryTaxComponentTransfer extends QbooksTaxComponentTransfer {
    taxableDebitAmount: number;
    taxableCreditAmount: number;
    taxDebitAmount: number;
    taxCreditAmount: number;
    taxCodeId: string;
    taxCodeName: string;
    taxApplicableOnType: string | null;
}

export interface RangeTransfer<T> {
    greaterOrEqual: T;
    lessOrEqual: T;
}

export interface RangeTransferOptional<T> extends Partial<RangeTransfer<T>> {}

export interface RequestAccrualReportTransfer extends RequestReportExportTransfer {
    narration: string;
    accrualAccountId: Guid;
    forwardDate: DateTimeIsoString;
    reverseDate?: DateTimeIsoString | undefined;
}

export interface RequestAddCommentTransfer extends CommentBaseTransfer {
    text?: string;
    companyId: Guid;
    mentionedUserIds?: Guid[];
}

export interface RequestAuditReportTransfer {
    requestId: Guid;
    companyId: Guid;
}

export interface RequestChangeParticipantsTransfer extends CommentBaseTransfer {
    changes: RequestStepParticipantChange[];
    companyId: Guid;
}

export interface RequestChangeStatusTransfer extends CommentBaseTransfer {
    companyId: Guid;
}

export interface RequestCopyTransfer {
    requestId: Guid;
    targetTemplateId: Guid;
    companyId: Guid;
}

export interface RequestCreateTransfer {
    authorEmail?: string;
    templateId: Guid;
    name?: string;
    description?: string;
    attachments?: Guid[];
    companyId: Guid;
}

export interface RequestDeleteAttachmentTransfer {
    attachmentId: Guid;
}

export interface RequestDeleteCommentTransfer {
    requestId: Guid;
    commentId: Guid;
}

export interface RequestDeleteTransfer {
    requestId: Guid;
    companyId: Guid;
}

export interface GetRequestCurrentBalanceTransfer {
    requestId: Guid;
    companyId: Guid;
}

export interface ChangeWatchersTransfer {
    requestId: Guid;
    companyId: Guid;
    watchersToAdd: Guid[];
    watchersToRemove: Guid[];
}

export interface RequestPayTransfer {
    requestId: Guid;
    companyId: Guid;
    oneTimePassword: string;
    requestVersion: number;
}

export interface IntegrationNetSuiteExpenseLine {
    amount: number | null;
    taxCodeId: string | null;
    taxRate: number | null;
    taxAmount: number | null;
    departmentId: string | null;
    classId: string | null;
    locationId: string | null;
    isBillable: boolean | null;
    categoryId: string | null;
    accountId: string | null;
    memo: string | null;
    customerId: string | null;
    projectTaskId: string | null;
    lineNumber: number;
    amortizationScheduleName?: string | null;
    amortizationStartDate?: string | null;
    amortizationEndDate?: string | null;
    amortizationResidual?: number | null;
    customFields: IntegrationNetSuiteCustomField[] | null;
    orderDocId?: string | null;
    orderLineNumber?: number | null;
}

export interface IntegrationNetSuiteExpenseLineExpenseReport {
    lineNumber: number;
    amount: number | null;
    categoryId: string | null;
    currencyId: string | null;
    customerId: string | null;
    classId: string | null;
    locationId: string | null;
    departmentId: string | null;
    exchangeRate: number | null;
    expenseAccountId: string | null;
    expenseDate: string | null;
    foreignAmount: number | null;
    grossAmount: number | null;
    isBillable: boolean | null;
    isCorporateCreditCard: boolean | null;
    isNonReimbursable: boolean | null;
    isReceipt: boolean | null;
    memo: string | null;
    quantity: number | null;
    refLineNumber: number | null;
    taxAmount: number | null;
    taxCodeId: string | null;
    taxRate: number | null;
    unitPrice: number | null;
    fileRecordId: string | null;
    customFields: IntegrationNetSuiteCustomField[] | null;
}

export interface IntegrationNetSuiteItemLine {
    amount: number | null;
    taxCodeId: string | null;
    taxRate: string | number | null;
    taxAmount: number | null;
    departmentId: string | null;
    classId: string | null;
    locationId: string | null;
    isBillable: boolean;
    isClosed: boolean;
    itemId: string | null;
    vendorName: string | null;
    quantity: number | null;
    unitAbbreviation: string | null;
    unitId: string | null;
    unitPrice: number | null;
    description: string | null;
    customerId: string | null;
    projectTaskId: string | null;
    expectedReceiptDate: string | null;
    lineNumber: number;
    amortizationScheduleName?: string | null;
    amortizationStartDate?: string | null;
    amortizationEndDate?: string | null;
    amortizationResidual?: number | null;
    orderDocId?: string | null;
    orderLineNumber?: number | null;
    customFields: IntegrationNetSuiteCustomField[] | null;
}

export interface IntegrationNetSuiteCustomField {
    scriptId: string;
    internalId: string;
    fieldType: string | null;
    valueString: string | null;
    valueRefId: string | null;
    valueRefIds: string[] | null;
    valueBoolean: boolean | null;
    valueDate: string | null;
    valueDecimal: number | null;
    valueLong: number | null;
}

export interface IntegrationNetSuiteVendorBillDetails {
    transactionNumber: string | null;
    reference: string | null;
    vendorId: string | null;
    totalAmount: number | null;
    currencyId: string | null;
    exchangeRate: number | null;
    taxAmount: number | null;
    discountAmount: number | null;
    discountDate: string | null;
    isPaymentHold: boolean | null;
    dueDate: string | null;
    transactionDate: string | null;
    memo: string | null;
    billingAddressId: string | null;
    postingPeriodId: string | null;
    termId: string | null;
    accountId: string | null;
    departmentId: string | null;
    classId: string | null;
    locationId: string | null;
    itemLines: IntegrationNetSuiteItemLine[];
    expenseLines: IntegrationNetSuiteExpenseLine[];
    customFields: IntegrationNetSuiteCustomField[] | null;
}

export interface IntegrationNetSuiteBillPaymentApplyLinesDetails {
    paymentAmount: number;
    transactionId: string;
    currencyName: string | null;
    discountAvailable: number | null;
    discountDate: string | null;
    amountDue: number | null;
    originalAmount: number | null;
    dateDue: string | null;
    discountAmount: number | null;
    reference: string | null;
}

export interface IntegrationNetSuiteBillPaymentCreditLinesDetails {
    reference: string | null;
    appliedTo: string | null;
    type: string | null;
    dateDue: string | null;
    total: number | null;
    lineNumber: number | null;
    transactionId: string | null;
    currencyName: string | null;
    paymentAmount: number | null;
}

export interface IntegrationNetSuiteBillPaymentDetails {
    payeeId: string | null;
    payeeType: backend.NetSuitePayeeType | null;
    accountId: string | null;
    aPAccountId: string | null;
    balance: number | null;
    amount: number | null;
    currencyId: string | null;
    exchangeRate: number | null;
    toBePrinted: boolean | null;
    printVoucher: boolean | null;
    checkNumber: string | null;
    transactionDate: string | null;
    memo: string | null;
    departmentId: string | null;
    classId: string | null;
    locationId: string | null;
    payeeAddressId: string | null;
    transactionNumber: string | null;
    applyLines: IntegrationNetSuiteBillPaymentApplyLinesDetails[] | null;
    creditLines: IntegrationNetSuiteBillPaymentCreditLinesDetails[] | null;
    customFields: IntegrationNetSuiteCustomField[] | null;
}

export interface IntegrationNetSuitePurchaseOrderDetails {
    vendorReference: string | null;
    vendorId: string | null;
    dueDate: string | null;
    transactionDate: string | null;
    reference: string | null;
    classId: string | null;
    locationId: string | null;
    departmentId: string | null;
    currencyId: string | null;
    originalExchangeRate: number | null;
    billingAddressId: string | null;
    subtotal: number | null;
    taxTotal: number | null;
    total: number | null;
    memo: string | null;
    employeeId: string | null;
    termId: string | null;
    incotermName: string | null;
    shipToId: string | null;
    shippingAddressId: string | null;
    shipDate: string | null;
    shipMethodId: string | null;
    freeOnBoard: string | null;
    trackingNumbers: string | null;
    itemLines: IntegrationNetSuiteItemLine[];
    expenseLines: IntegrationNetSuiteExpenseLine[];
    customFields: IntegrationNetSuiteCustomField[] | null;
}

export interface IntegrationNetSuiteExpenseReportDetails {
    accountId: string | null;
    accountForCorporateCardExpensesId: string | null;
    advance: number | null;
    approvalStatus: string | null;
    createdDate: string | null;
    currencyId: string | null;
    date: string | null;
    dueDate: string | null;
    employeeId: string | null;
    exchangeRate: number | null;
    internalId: string | null;
    modifiedDate: string | null;
    postingPeriodId: string | null;
    reimbursableAmount: number | null;
    status: string | null;
    subsidiaryId: string | null;
    taxAmount: number | null;
    totalAmount: number | null;
    grossAmount: number | null;
    transactionNumber: string | null;
    url: string | null;
    useMultiCurrency: boolean | null;
    classId: string | null;
    locationId: string | null;
    departmentId: string | null;
    memo: string | null;
    expenseLines: IntegrationNetSuiteExpenseLineExpenseReport[] | null;
    customFields: IntegrationNetSuiteCustomField[] | null;
}

export interface IntegrationNetSuiteInvoiceItemLinesItem {
    unitsId: string | null;
    rate: number | null;
    itemId: string | null;
    description: string | null;
    quantity: number | null;
    priceLevelId: string | null;
    amount: number | null;
    taxAmount: number | null;
    tax2Amount: number | null;
    departmentId: string | null;
    classId: string | null;
    locationId: string | null;
    lineNumber: number | null;
    taxCodeId: string | null;
    customFields: IntegrationNetSuiteCustomField[] | null;
}

export interface IntegrationNetSuiteInvoiceDetails {
    billingAddressId: string | null;
    transactionDate: string | null;
    startDate: string | null;
    endDate: string | null;
    currencyId: string | null;
    customerId: string | null;
    classId: string | null;
    locationId: string | null;
    departmentId: string | null;
    discountItemId: string | null;
    discountRate: number | null;
    isDiscountRateAbsolute: boolean | null;
    memo: string | null;
    dueDate: string | null;
    exchangeRate: number | null;
    description: string | null;
    salesRepId: string | null;
    opportunityId: string | null;
    salesEffectiveDate: string | null;
    excludeCommission: boolean | null;
    partnerId: string | null;
    leadSourceId: string | null;
    shippingDate: string | null;
    shippingMethodId: string | null;
    shippingAddressId: string | null;
    termsId: string | null;
    transactionReference: string | null;
    itemLines: IntegrationNetSuiteInvoiceItemLinesItem[] | null;
    customFields: IntegrationNetSuiteCustomField[] | null;
}

export interface IntegrationNetSuiteAddressLine {
    label: string | null;
    defaultBilling: boolean;
    defaultShipping: boolean;
    primaryAddress: string | null;
    secondaryAddress: string | null;
    additionalAddress: string | null;
    addressee: string | null;
    attention: string | null;
    city: string | null;
    country: string | null;
    zip: string | null;
    state: string | null;
    phone: string | null;
    addressRepresentation: string | null;
}

export interface IntegrationNetSuiteSubsidiary {
    creditLimit: number | null;
    taxCodeId: string | null;
    subsidiaryId: string | null;
}

export interface IntegrationNetSuiteVendorDetails {
    subsidiaryId: string;
    vendorId: string;
    email: string;
    is1099Eligible: boolean;
    isJobResource: boolean;
    currencies: string[];
    AddressLines: IntegrationNetSuiteAddressLine[];
    Subsidiaries: IntegrationNetSuiteSubsidiary[];
    type: domain.NetSuiteVendorType;
    companyName: string | null;
    webAddress: string | null;
    salutation: string | null;
    firstName: string | null;
    lastName: string | null;
    middleName: string | null;
    jobTitle: string | null;
    categoryId: string | null;
    comments: string | null;
    phone: string | null;
    altPhone: string | null;
    fax: string | null;
    legalName: string | null;
    accountNumber: string | null;
    defaultExpenseAccountId: string | null;
    defaultPayablesAccountId: string | null;
    currencyId: string | null;
    termsId: string | null;
    taxCodeId: string | null;
    creditLimit: number | null;
    incotermName: string | null;
    taxNumber: string | null;
    CustomFields: IntegrationNetSuiteCustomField[] | null;
}

export interface IntegrationStandaloneDetails {
    name: string;
    date: string | null;
    description: string;
    fieldValues: RequestFieldValueTransfer[] | null;
}

export interface IntegrationQBooksJournalEntryLine {
    id: string | null;
    accountId: string | null;
    amount: number | null;
    amountSide: string | null;
    description: string | null;
    payee: {
        id: string;
        type: string;
    } | null;
    taxCode: string | null;
    taxApplicableOnType: string | null;
    taxRateId: string | null;
    classId: string | null;
    departmentId: string | null;
}

export interface IntegrationQBooksJournalEntryTransfer {
    date: string | null;
    number: string | null;
    memo: string | null;
    lineAmountType: string | null;
    lines: IntegrationQBooksJournalEntryLine[];
    debitSubTotal: number;
    creditSubTotal: number;
    debitTotalTax: number | null;
    creditTotalTax: number | null;
    taxComponents: QBooksJournalEntryTaxComponentTransfer[] | null;
}

export interface IntegrationXeroManualJournalLine {
    accountId: string | null;
    description: string | null;
    amount: number;
    amountSide: keyof typeof domain.XeroManualJournalLineAmountType;
    taxCode: string | null;
    trackingCategories: {
        id: string;
        valueId: string;
    }[];
}

export interface IntegrationXeroManualJournalTransfer {
    date: string | null;
    narration: string | null;
    showOnCashBasisReports: boolean;
    lineAmountType: domain.LineAmountType;
    debitSubTotal: number;
    creditSubTotal: number;
    debitTotalTax: number;
    creditTotalTax: number;
    lineItems: IntegrationXeroManualJournalLine[];
}

export interface EmailToContact {
    emailHasToBeSent: boolean;
    emailTo: string;
    emailCC: string;
    emailFrom: string;
    emailReplyTo: string;
    emailSubject: string;
    emailBody: string;
    attachments: string[];
}

export type RequestEditTransfer = DeepNullablePartial<
    CommentBaseTransfer & {
        airwallexXeroBatchPaymentDetails: IntegrationXeroAirwallexBatchPaymentTransfer;
        amaxPayXeroBatchPaymentDetails: IntegrationXeroAmaxPayBatchPaymentTransfer;
        amount?: number | undefined;
        attachments: Guid[];
        companyId: string;
        currency: string;
        emailToContact?: EmailToContact;
        exchangeRate: number | null;
        exchangeRateDate: string;
        exchangeRateSource?: keyof typeof domain.ExchangeRateSource | null;
        netSuiteExpenseReportDetails: IntegrationNetSuiteExpenseReportDetails;
        netSuitePurchaseOrderDetails: IntegrationNetSuitePurchaseOrderDetails;
        netSuiteVendorBillOrderDetails: IntegrationNetSuiteVendorBillDetails;
        netSuiteBillPaymentOrderDetails: IntegrationNetSuiteBillPaymentDetails;
        netSuiteInvoiceDetails: IntegrationNetSuiteInvoiceDetails;
        netSuiteVendorDetails: IntegrationNetSuiteVendorDetails;
        ocrAttachment?: Guid;
        purchaseOrder: IntegrationXeroFinancialDocumentTransfer;
        qBooksBillInvoiceDetails: IntegrationQBooksQBooksBillInvoiceTransfer;
        qBooksExpenseDetails: IntegrationQBooksQBooksExpenseInvoiceTransfer;
        qBooksJournalEntryDetails: IntegrationQBooksJournalEntryTransfer;
        qBooksPurchaseOrderDetails: IntegrationQBooksQBooksPurchaseOrderTransfer;
        qBooksSalesInvoiceDetails: IntegrationQBooksSalesInvoiceTransfer;
        qBooksVendorDetails: IntegrationQBooksQBooksVendorTransfer;
        requestNote: string;
        requestVersion: number | null;
        resetToStep?: Guid | undefined;
        standaloneDetails: IntegrationStandaloneDetails;
        templateVersion: number;
        xeroBillBatchPaymentDetails: IntegrationXeroBillBatchPaymentTransfer;
        xeroContactDetails: IntegrationXeroContactTransfer;
        xeroInvoiceDetails: IntegrationXeroInvoiceTransfer;
        xeroManualJournalDetails: IntegrationXeroManualJournalTransfer;
        xeroQuoteDetails: IntegrationXeroQuoteTransfer;
        xeroSalesInvoiceDetails: IntegrationXeroInvoiceTransfer;
        xeroReceivableCreditNoteDetails: XeroCreditNotesTransfer;
        xeroPayableCreditNoteDetails: XeroCreditNotesTransfer;
    }
>;

export interface RequestFieldValueTransfer {
    fieldId: Guid;
    fieldValue: FieldValueTransfer;
}

export interface RequestForceDecisionTransfer extends CommentBaseTransfer {
    response: number;
    companyId: Guid;
}

export interface RequestGetAttachmentTransfer {
    attachmentId: Guid;
    inline?: boolean | undefined;
    companyId: Guid;
}

export interface RequestGetAttachmentForBatchPaymentTransfer {
    attachmentId: Guid;
    companyId: Guid;
    batchPaymentRequestId: Guid;
    billRequestId: Guid;
}

export interface RequestGetAttachmentForBillPaymentTransfer {
    attachmentId: Guid;
    companyId: Guid;
    billPaymentRequestId: Guid;
    billRequestId: Guid;
    accountsPayableAccountId: Guid;
    currencyId: Guid;
}

export interface RequestGetCommentsTransfer {
    requestId: Guid;
}

export interface RequestGetTransfer {
    requestId: Guid;
    companyId?: Guid;
}

export interface RequestHistoryTransfer {
    requestId: Guid;
    filter: backend.RequestHistoryEvents[];
}

export interface RequestReportColumnsTransfer {
    name: RequestReportColumnTransfer;
    templateId: FilteredRequestReportColumnTransfer<RequestReportTemplateIdTransfer[]>;
    activeStepName: FilteredRequestReportColumnTransfer<string>;
    resolution: FilteredRequestReportColumnTransfer<backend.RequestsRequestResolution[]>;
    resolutionOrigin: FilteredRequestReportColumnTransfer<backend.RequestsRequestResolutionOrigin[]>;
    resolutionTime: RequestReportColumnTransfer;
    fraudulentActivity: FilteredRequestReportColumnTransfer<Array<backend.RequestFraudulentActivity | undefined>>;
    creationDate: FilteredRequestReportColumnTransfer<DateRangeTransfer>;
    resolutionDate: FilteredRequestReportColumnTransfer<DateRangeTransfer>;
    dueDate: FilteredRequestReportColumnTransfer<DateRangeTransfer>;
    deliveryDate: FilteredRequestReportColumnTransfer<DateRangeTransfer>;
    paymentDate: FilteredRequestReportColumnTransfer<DateRangeTransfer>;
    expectedDate: FilteredRequestReportColumnTransfer<DateRangeTransfer>;
    documentDate: FilteredRequestReportColumnTransfer<DateRangeTransfer>;
    documentNumber: RequestReportColumnTransfer;
    amount: FilteredRequestReportColumnTransfer<RangeTransfer<number | undefined>>;
    netAmount: FilteredRequestReportColumnTransfer<RangeTransfer<number | undefined>>;
    taxAmount: FilteredRequestReportColumnTransfer<RangeTransfer<number | undefined>>;
    currency: FilteredRequestReportColumnTransfer<string[]>;
    exchangeRate: RequestReportColumnTransfer;
    taxType: FilteredRequestReportColumnTransfer<string[]>;
    requesters: FilteredRequestReportColumnTransfer<string[]>;
    participantsApproved: FilteredRequestReportColumnTransfer<string[]>;
    participantsRejected: FilteredRequestReportColumnTransfer<string[]>;
    participantsNonResponded: FilteredRequestReportColumnTransfer<string[]>;
    attachment: RequestReportColumnTransfer;
    contact: FilteredRequestReportColumnTransfer<Array<backend.NamedObjectTransfer<string>>>;
    reference: FilteredRequestReportColumnTransfer<string>;
    branding: FilteredRequestReportColumnTransfer<Array<backend.NamedObjectTransfer<Guid>>>;
    sentToSupplier: FilteredRequestReportColumnTransfer<boolean | undefined>;
    isBilled: FilteredRequestReportColumnTransfer<boolean | undefined>;
    isPaid: FilteredRequestReportColumnTransfer<boolean | undefined>;
    lineItemAccounts: FilteredRequestReportColumnTransfer<Array<backend.NamedObjectTransfer<string>>>;
    qbooksLineItemAccounts: FilteredRequestReportColumnTransfer<Array<backend.NamedObjectTransfer<string>>>;
    lineItemTrackings1: FilteredRequestReportColumnTransfer<Array<backend.NamedObjectTransfer<string>>>;
    lineItemTrackings2: FilteredRequestReportColumnTransfer<Array<backend.NamedObjectTransfer<string>>>;
    lineItemAccrualDescriptions: FilteredRequestReportColumnTransfer<string[]>;
    auditReportStatus: FilteredRequestReportColumnTransfer<backend.RequestAuditReportsRequestAuditReportState[]>;
    matchedBills: FilteredRequestReportColumnTransfer<backend.RequestMatchingStatus>;
    matchedPurchaseOrders: FilteredRequestReportColumnTransfer<backend.RequestMatchingStatus>;
    purchaseOrderBalance: FilteredRequestReportColumnTransfer<RangeTransfer<number | undefined>>;
    retrospectivePurchaseOrder: FilteredRequestReportColumnTransfer<backend.RetrospectivePurchaseOrder>;
    location: FilteredRequestReportColumnTransfer<Array<backend.NamedObjectTransfer<string>>>;
    lineItemClasses: FilteredRequestReportColumnTransfer<Array<backend.NamedObjectTransfer<string>>>;
    lineItemContacts: FilteredRequestReportColumnTransfer<Array<backend.NamedObjectTransfer<string>>>;
    customFields: Array<RequestReportCustomFieldColumnTransfer<Array<backend.NamedObjectTransfer<string>>>>;
    qbooksCustomFields: Array<RequestReportCustomFieldColumnTransfer<boolean | undefined>>;
}

export interface RequestReportColumnTransfer {
    name: string;
    viewable?: boolean | undefined;
    viewingOrder?: number | undefined;
    sortingOrder?: number | undefined;
    sortingType?: backend.SortingType | undefined;
}

export interface RequestReportConfigTransfer {
    name: string;
    columns: RequestReportColumnsTransfer;
}

export interface RequestReportCreateTransfer {
    companyId: Guid;
    config: RequestReportConfigTransfer;
    asExportSource?: boolean | undefined;
    reportConfigId?: Guid | undefined;
}

export interface RequestReportCustomFieldColumnTransfer<T> extends FilteredRequestReportColumnTransfer<T> {
    fieldId: string;
}

export interface RequestReportDeleteTransfer {
    reportConfigId: Guid;
}

export interface RequestReportEditTransfer {
    reportConfigId: Guid;
    config: RequestReportConfigTransfer;
}

export interface RequestReportExportTransfer {
    reportConfigId: Guid;
}

export interface RequestReportGetTransfer {
    reportConfigId: Guid;
}

export interface RequestReportSelectTransfer {
    companyId: Guid;
}

export interface RequestReportTemplateIdTransfer {
    templateId?: Guid | undefined;
    templateName: string;
    integrationCode: string;
}

export interface RequestResetTemplateTransfer {
    requestIds: Guid[];
    templateId: Guid;
    commentText?: string;
    companyId: Guid;
}

export interface RequestResetAllForTemplateTransfer {
    companyId: Guid;
    workflowId: Guid;
    commentText?: string;
    includeActualRequests?: boolean;
}

export interface RequestRespondTransfer extends CommentBaseTransfer {
    requestVersion?: number | undefined;
    stepId: Guid;
    response: number;
    companyId: Guid;
}

export interface RequestComleteReviewTransfer {
    requestId: string;
    requestVersion?: number | undefined;
    companyId: Guid;
}

export interface RequestReturnToReviewTransfer {
    requestId: string;
    requestVersion?: number | undefined;
    companyId: Guid;
}

export interface RequestSelectAllTransfer {
    companyId: Guid | null;
    rowNum?: number | undefined;
    startFrom?: number | undefined;
    filter?: number | undefined;
}

export interface RequestSelectForTemplateResetTransfer {
    templateId?: Guid | undefined;
    companyId?: Guid | undefined;
    templateIntegrationCode: string | null;
    showActualRequestsAsWell?: boolean | undefined;
}

export interface SelectQuantityForTemplateResetTransfer {
    companyId: Guid;
    workflowIntegrationCode?: string | null;
    workflowId?: Guid;
    includeActualRequests?: boolean;
}

export type RequestSelectV2Filter =
    | 'userDecisionRequired'
    | 'userReviewRequired'
    | 'requestsUserCanSee'
    | 'userOnHoldRequests'
    | 'userSubmittedOpenRequests'
    | 'userDraftRequests'
    | 'userOcrRequests'
    | 'userReadyToPayRequests';

export interface RequestSelectV2Transfer {
    continuationToken?: string | undefined;
    pageSize?: number;
    filter?: RequestSelectV2Filter;
}

export interface CompanyRequestSelectV2Transfer extends RequestSelectV2Transfer {
    companyId: string;
}

export interface RequestsMarkAsBilledTransfer {
    requestId: Guid;
    isBilled: boolean;
    commentText: string;
    companyId: Guid;
    mentionedUserIds: string[];
}

export interface RequestStepParticipantChange {
    stepId: Guid;
    participantsToAdd: string[];
    participantsToDelete: string[];
}

export interface RuleConditionTransfer {
    fieldId: Guid;
    name: string;
    amountType?: backend.AmountConditionType;
    conditionType: backend.FieldType;
    allowCreation?: boolean | undefined;
    fieldValuesFilterType?: number | undefined;
    exactConstraint?: ExactConstraintTransfer[];
    groupOfXeroAccountsIds?: string[];
    rangeConstraintLess?: number | undefined;
    rangeConstraintGreaterEquals?: number | undefined;
    allowEditing?: boolean;
    exactBooleanConstraint?: boolean | null;
}

export interface RuleTransfer {
    conditions: RuleConditionTransfer[];
}

export interface SelectFieldsTransfer {
    companyId: Guid;
    integrationCode?: string | null;
    withValues: boolean;
    startFrom?: number | undefined;
    rowNum?: number | undefined;
    fieldsId?: Guid[];
    allFields?: boolean;
    query?: string;
}

export interface SyncRequestsTransfer {
    requestIds: Guid[];
}

export enum SortingDirection {
    Ascending = 'Ascending',
    Descending = 'Descending',
}

export interface BillRequestsForBatchPaymentTransfer {
    xeroBillBatchPaymentRequestId: string;
    companyId: string;
    xeroContactId: {
        filteringValue: string | null;
        sortingType: SortingDirection | null;
    };
    reference: {
        filteringValue: string | null;
    };
    amountDue: {
        filteringValue: {
            greaterOrEqual: number | null;
            lessOrEqual: number | null;
        };
        sortingType: SortingDirection | null;
    };
    date: {
        filteringValue: {
            type: number;
            custom: {
                greaterOrEqual: string | null;
                lessOrEqual: string | null;
            };
        };
        sortingType: SortingDirection | null;
    };
    dueDate: {
        filteringValue: {
            type: number;
            custom: {
                greaterOrEqual: string | null;
                lessOrEqual: string | null;
            };
        };
        sortingType: SortingDirection | null;
    };
    plannedDate: {
        filteringValue: {
            type: number;
            custom: {
                greaterOrEqual: string | null;
                lessOrEqual: string | null;
            };
        };
        sortingType: SortingDirection | null;
    };
    excludeReserved: boolean;
}

export interface BillRequestsForAmaxPayBatchPaymentTransfer {
    companyId: string;
    xeroContactId: {
        filteringValue: string | null;
        sortingType: SortingDirection | null;
    };
    reference: {
        filteringValue: string | null;
    };
    currency: {
        filteringValue: string;
    };
    date: {
        filteringValue: {
            type: number;
            custom: {
                greaterOrEqual: string | null;
                lessOrEqual: string | null;
            };
        };
        sortingType: SortingDirection | null;
    };
    dueDate: {
        filteringValue: {
            type: number;
            custom: {
                greaterOrEqual: string | null;
                lessOrEqual: string | null;
            };
        };
        sortingType: SortingDirection | null;
    };
    plannedDate: {
        filteringValue: {
            type: number;
            custom: {
                greaterOrEqual: string | null;
                lessOrEqual: string | null;
            };
        };
        sortingType: SortingDirection | null;
    };
    amountDue: {
        filteringValue: {
            greaterOrEqual: number | null;
            lessOrEqual: number | null;
        };
        sortingType: SortingDirection | null;
    };
    excludeReserved: boolean;
}

export interface BillRequestsForAirwallexBatchPaymentTransfer {
    airwallexXeroBatchPaymentRequestId: string;
    companyId: string;
    xeroContactId: {
        filteringValue: string | null;
        sortingType: SortingDirection | null;
    };
    reference: {
        filteringValue: string | null;
    };
    date: {
        filteringValue: {
            type: number;
            custom: {
                greaterOrEqual: string | null;
                lessOrEqual: string | null;
            };
        };
        sortingType: SortingDirection | null;
    };
    dueDate: {
        filteringValue: {
            type: number;
            custom: {
                greaterOrEqual: string | null;
                lessOrEqual: string | null;
            };
        };
        sortingType: SortingDirection | null;
    };
    plannedDate: {
        filteringValue: {
            type: number;
            custom: {
                greaterOrEqual: string | null;
                lessOrEqual: string | null;
            };
        };
        sortingType: SortingDirection | null;
    };
    amountDue: {
        filteringValue: {
            greaterOrEqual: number | null;
            lessOrEqual: number | null;
        };
        sortingType: SortingDirection | null;
    };
    excludeReserved: boolean;
}

export interface TemplateReviewerTransfer {
    email: string;
    rules: RuleTransfer[];
    isBackup?: boolean | undefined;
}

export interface TemplateReviewStepTransfer {
    requiredFieldIds: Guid[];
    readonlyFieldIds: Guid[];
    deadlineRule: TemplateStepDeadlineRuleTransfer | null;
    reviewers: TemplateReviewerTransfer[];
}

export interface TemplateCreateTransfer {
    companyId: Guid;
    templateName: string;
    externalSubmitter: string;
    emailExternalSubmitter: string;
    receiptBankExternalSubmitter: string;
    submitters: TemplateSubmitterTransfer[];
    submitterRuleOrders: Guid[] | null;
    payers?: TemplatePayerTransfer[];
    autoapprovalRules: TemplateAutoApproveTransfer[];
    steps: TemplateStepTransfer[];
    reviewStep: TemplateReviewStepTransfer | null;
    integrationCode: string | null;
    enabled?: boolean | undefined;
    requiredFieldIds: Guid[];
    fieldVersions?: {
        purpose: string;
        state: string;
    }[];
}

export interface TemplateDeleteTransfer {
    workflowId: Guid;
    companyId: Guid;
}

export interface SelectWorkflowVersionsTransfer {
    workflowId: Guid;
    companyId: Guid;
    startFrom: number;
    rowNum: number;
}

export interface TemplateEditTransfer extends TemplateCreateTransfer {
    templateId: Guid;
    version?: number;
    forceOverwrite?: boolean;
    comment?: string;
}

export interface TemplateSelectTransfer {
    templateId?: Guid;
    companyId?: Guid;
    version?: number;
}

export interface TemplateStepEditorTransfer {
    email: string;
    rules: RuleTransfer[];
}

export interface TemplateStepParticipantTransfer {
    email: string;
    rules: RuleTransfer[];
    isBackup?: boolean | undefined;
}

export interface TemplateStepDeadlineRuleTransfer {
    calculator: domain.DeadlineRuleType | null;
    duration: string;
}

export interface TemplateStepTransfer {
    name: string;
    stepId?: Guid | undefined;
    type: backend.StepType;
    defaultDuration: string;
    deadlineRule: TemplateStepDeadlineRuleTransfer | null;
    ruleOrder: Guid[];
    participants: TemplateStepParticipantTransfer[];
    editors: TemplateStepEditorTransfer[];
    requiredFieldIds: Guid[];
    readonlyFieldIds: Guid[];
}

export interface TemplateSubmitterTransfer {
    email: string;
    rules: RuleTransfer[];
}

export interface TemplatePayerTransfer {
    email: string;
    rules: RuleTransfer[];
}

export interface TemplateAutoApproveTransfer extends RuleTransfer {
    name: string;
}

export interface TrackingCategoryTransfer {
    id: string;
    valueId: string;
}

export interface TrackingOptionSearchDefinition {
    trackingOptionId: Guid;
    trackingCategoryId: Guid;
}

export interface UserCompanyCreateAnswers {
    accountingPlatform: string;
    otherAccountingPlatformName: string;
    otherWorkflowNames: string;
    industry: string;
    workflows: string[];
}

export interface XeroFinishIntegrationTransfer extends OAuthFinishIntegrationTransfer {}

export interface EditQBODocumentStatusTransfer {
    companyId: Guid;
    requestId: Guid;
    documentStatus: domain.QBooksPurchaseOrderStatus;
    commentText?: string;
    mentionedUserIds: string[];
}

export enum SelectByWorkflowFilter {
    All = 'All',
    OnApproval = 'OnApproval',
    OnReview = 'OnReview',
    Approved = 'Approved',
    Cancelled = 'Cancelled',
    Paid = 'Paid',
    Billed = 'Billed',
    NotBilled = 'NotBilled',
    Rejected = 'Rejected',
    OnHold = 'OnHold',
    AwaitingPayment = 'AwaitingPayment',
    PartiallyPaid = 'PartiallyPaid',
    Failed = 'Failed',
    Processing = 'Processing',
    GrnNotReceived = 'NotReceived',
    GrnFullyReceived = 'FullyReceived',
    Accepted = 'Accepted',
    Declined = 'Declined',
    Credited = 'Credited',
    PartiallyReceived = 'PartiallyReceived',
    PendingReceipt = 'PendingReceipt',
    PendingBill = 'PendingBill',
    Closed = 'Closed',
}

export interface AddOrUpdateNetSuiteFieldsTransfer {
    companyId: string;
    fields: {
        fieldId?: string;
        name?: string;
        fieldType: backend.FieldType;
        ExactValues: [];
        netSuiteField: {
            field: {
                scriptId: string;
                id: string;
                name: string;
            };
            level: number;
            referenceRecordType: {
                id: string;
                name: string;
            } | null;
            order: number;
            type: string;
        };
        fieldWorkflows: {
            workflowId: string;
            isActive: boolean;
            isEditable: boolean;
            isMandatory: boolean;
        }[];
    }[];
}

export enum CompaniesWatchType {
    All = 'All',
    Selected = 'Selected',
}

export type CreateNetSuiteVendorData = Partial<{
    type: backend.NetSuiteVendorType;
    companyName: string | null;
    webAddress: string | null;
    salutation: string | null;
    firstName: string | null;
    lastName: string | null;
    middleName: string | null;
    jobTitle: string | null;
    categoryId: string | null;
    comments: string | null;
    email: string | null;
    phone: string | null;
    altPhone: string | null;
    fax: string | null;
    subsidiaryId: string | null;
    addressLines: backend.transfers.IntegrationNetSuiteAddressLine[];
    legalName: string | null;
    accountNumber: string | null;
    defaultExpenseAccountId: string | null;
    defaultPayablesAccountId: string | null;
    currencyId: string | null;
    termsId: string | null;
    taxCodeId: string | null;
    creditLimit: number | null;
    incotermName: string | null;
    taxNumber: string | null;
    is1099Eligible: boolean;
    isJobResource: boolean;
    currencies: string[];
    subsidiaries: backend.transfers.IntegrationNetSuiteSubsidiary[];
    customFields: backend.transfers.IntegrationNetSuiteCustomField[] | null;
}>;
