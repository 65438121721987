import { useSetAtom } from 'jotai';
import { selectors } from 'modules/common';
import { useSelector } from 'modules/react-redux';
import { getNewRequestPopupData } from 'modules/request';
import { memo, useEffect } from 'react';

import { newRequestPopupSizeState } from '../../NewRequestPopup.states';
import { Content } from '../Content/Content';
import { EmptyContent } from '../EmptyContent/EmptyContent';

export const PopupContent = memo(() => {
    const newRequestPopupData = useSelector(getNewRequestPopupData);

    const creatableTemplates = useSelector((state) => {
        return selectors.meta.getCreatableTemplatesByCompanyId(state, newRequestPopupData?.activeCompanyId);
    });

    const isEmpty = creatableTemplates.length === 0;

    const setSize = useSetAtom(newRequestPopupSizeState);

    useEffect(() => {
        setSize(isEmpty ? 'xsmall' : 'large');
    }, [isEmpty, setSize]);

    return isEmpty ? <EmptyContent /> : <Content />;
});

PopupContent.displayName = 'PopupContent';
