import { CopyIcon } from '@approvalmax/ui';
import { Button, Flex, Link, Text } from '@approvalmax/ui/src/components';
import { constants } from 'modules/common';
import { memo } from 'react';
import bemFactory from 'react-bem-factory';
import { api } from 'services/api';

import { useStepCodeScan } from './StepCodeScan.hooks';
import { messages } from './StepCodeScan.messages';

const qa = bemFactory.qa('pro-two-fa-setup-code-scan');

export const StepCodeScan = memo(() => {
    const { stateId, setupCodeFormatted, handleCopyToClipboard } = useStepCodeScan();

    return (
        <Flex direction='column' spacing='24' data-qa={qa()}>
            <Text font='body' fontSize='large' fontWeight='regular'>
                {messages.description({
                    br: <br />,
                    supportlink: (chunks) => (
                        <Link
                            href={constants.xeroConstants.XERO_TWO_FA_SUPPORT_LINK}
                            font='body'
                            fontSize='large'
                            fontWeight='regular'
                        >
                            {chunks}
                        </Link>
                    ),
                })}
            </Text>

            <Flex direction='column' alignItems='center' minHeight={150}>
                <img
                    src={api.expandUrl(`auth/getTFAEnablingQrCodeImage?stateId=${stateId}`)}
                    width={150}
                    height={150}
                    alt={messages.codeImageAlt}
                />
            </Flex>

            <Flex direction='column' alignItems='center' spacing='16'>
                <Text font='headline' fontSize='small' fontWeight='medium' onClick={handleCopyToClipboard}>
                    {setupCodeFormatted}
                </Text>

                <Button
                    size='large'
                    color='blue10'
                    variant='outline'
                    onClick={handleCopyToClipboard}
                    startIcon={<CopyIcon />}
                >
                    {messages.buttonCopy}
                </Button>
            </Flex>
        </Flex>
    );
});

StepCodeScan.displayName = 'StepCodeScan';
