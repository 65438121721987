import { RequestMutationOptions } from '@approvalmax/data';
import { useMutate } from 'shared/data';

import { requestsApiPaths } from '../paths';
import { DeleteRequest } from './useDeleteRequest.types';

export const useDeleteRequest = (mutationOptions?: RequestMutationOptions<DeleteRequest>) => {
    return useMutate(requestsApiPaths.delete, {
        mutationOptions,
        apiVersion: 'v1',
        method: 'post',
    });
};
