import { selectors } from 'modules/common';
import { useSelector } from 'modules/react-redux';

import { getGroupOfValuesAvailable } from '../helpers/getGroupOfValuesAvailable';
import { getActiveTemplate } from '../selectors/pageSelectors';

export const useIsGetGroupOfValuesAvailable = () => {
    const template = useSelector(getActiveTemplate);
    const company = useSelector((state) => selectors.company.findCompanyById(state, template?.companyId || ''));

    if (!template || !company) return false;

    return getGroupOfValuesAvailable(template, company);
};
