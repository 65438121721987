import { FC, memo } from 'react';
import { useNetSuiteBillPaymentCreditListFieldSettings } from 'shared/hooks';

import { NetSuiteLineTable } from '../NetSuiteLineTable/NetSuiteLineTable';
import { useTableDefinitions } from './NetSuiteCreditList.hooks';
import { messages } from './NetSuiteCreditList.messages';
import { NetSuiteCreditListProps } from './NetSuiteCreditList.types';

export const NetSuiteCreditList: FC<NetSuiteCreditListProps> = memo((props) => {
    const { creditList, companyCustomFields, documentFields, exchangeRate } = props;

    const creditListFieldSettings = useNetSuiteBillPaymentCreditListFieldSettings(documentFields);

    const tableDef = useTableDefinitions(companyCustomFields, creditListFieldSettings, exchangeRate);

    if (creditList.length === 0) {
        return null;
    }

    return <NetSuiteLineTable title={messages.sectionHeaderText} columnDefinitions={tableDef} lineItems={creditList} />;
});

NetSuiteCreditList.displayName = 'NetSuiteCreditList';
