import { useEffect } from 'react';

import { messages } from './useDocumentTitle.messages';

export const useDocumentTitle = (title?: string | null) => {
    useEffect(() => {
        window.document.title = title
            ? messages.documentTitle({
                  title,
              })
            : messages.defaultDocumentTitle;
    }, [title]);
};
