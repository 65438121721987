import { Dropdown } from '@approvalmax/ui/src/components';
import { intl } from '@approvalmax/utils';
import { FC, useMemo } from 'react';

import { messages } from './AmountDueIndicator.messages';
import { AmountDueIndicatorIcon, AmountLine, Description, Panel, Root } from './AmountDueIndicator.styles';
import { AmountDueIndicatorProps } from './AmountDueIndicator.types';

const AmountDueIndicator: FC<AmountDueIndicatorProps> = (props) => {
    const {
        children,
        amountInProcessing = 0,
        amountAwaitingPayment = 0,
        amountOnApproval = 0,
        amountOnReview = 0,
        currency,
        disabled,
    } = props;

    const hasWarning =
        amountOnApproval > 0 || amountInProcessing > 0 || amountAwaitingPayment > 0 || amountOnReview > 0;

    const descriptionText = useMemo(() => {
        const amountOnApprovalText = amountOnApproval ? messages.amountOnApproval : null;
        const amountAwaitingPaymentText = amountAwaitingPayment ? messages.amountAwaitingPayment : null;
        const amountInProcessingText = amountInProcessing ? messages.amountInProcessing : null;
        const amountOnReviewText = amountOnReview ? messages.amountOnReview : null;

        return (
            <>
                <div>{messages.header}</div>

                {amountOnApprovalText && (
                    <AmountLine>
                        {amountOnApprovalText}

                        <b>&nbsp;{intl.formatCurrency(amountOnApproval, currency)}</b>
                    </AmountLine>
                )}

                {amountOnReviewText && (
                    <AmountLine>
                        {amountOnReviewText}

                        <b>&nbsp;{intl.formatCurrency(amountOnReview, currency)}</b>
                    </AmountLine>
                )}

                {amountAwaitingPaymentText && (
                    <AmountLine>
                        {amountAwaitingPaymentText}

                        <b>&nbsp;{intl.formatCurrency(amountAwaitingPayment, currency)}</b>
                    </AmountLine>
                )}

                {amountInProcessingText && (
                    <AmountLine>
                        {amountInProcessingText}

                        <b>&nbsp;{intl.formatCurrency(amountInProcessing, currency)}</b>
                    </AmountLine>
                )}
            </>
        );
    }, [amountAwaitingPayment, amountInProcessing, amountOnApproval, amountOnReview, currency]);

    return (
        <Dropdown
            activator={
                <Root>
                    {children}

                    {hasWarning && <AmountDueIndicatorIcon />}
                </Root>
            }
            disabled={disabled || !hasWarning}
            isHovering
            safePolygon
            width={250}
        >
            <Panel>
                <Description>{descriptionText}</Description>
            </Panel>
        </Dropdown>
    );
};

export default AmountDueIndicator;
