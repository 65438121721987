import { ColumnDefinition, Flex, Text } from '@approvalmax/ui/src/components';
import { domain } from 'modules/data';
import { ReactNode } from 'react';

import { messages } from './MigrationFromReviewerV1PopupContent.messages';
import { DataItem } from './MigrationFromReviewerV1PopupContent.types';

export const tableData: DataItem[] = [
    {
        id: 'role',
        role: messages.approverAndReviewer({ b: (chunks: ReactNode) => <b>{chunks}</b> }),
        migrationResults: (
            <Flex direction='column'>
                <Text font='label'>
                    {messages.approverAndReviewerMigrationResult1({ b: (chunks: ReactNode) => <b>{chunks}</b> })}
                </Text>

                <Text font='label'>
                    {messages.approverAndReviewerMigrationResult2({ b: (chunks: ReactNode) => <b>{chunks}</b> })}
                </Text>

                <Text font='label'>
                    {messages.approverAndReviewerMigrationResult3({ b: (chunks: ReactNode) => <b>{chunks}</b> })}
                </Text>
            </Flex>
        ),
    },
    {
        id: 'migrationResults',
        role: messages.onlyReviewerAndNotApprover({ b: (chunks: ReactNode) => <b>{chunks}</b> }),
        migrationResults: (
            <Text font='label' color='midnight70'>
                {messages.onlyReviewerAndNotApproverMigrationResult}
            </Text>
        ),
    },
];

export const columnsDefinition: ColumnDefinition<DataItem>[] = [
    {
        id: 'role',
        name: messages.role,
        value: (item) => item.role,
        width: 204,
        verticalAlign: 'top',
    },
    {
        id: 'migrationResult',
        name: messages.migrationResults,
        value: (item) => item.migrationResults,
    },
];

export const fieldSystemPurposesToAdd = [
    domain.FieldSystemPurpose.XeroLineDescription,
    domain.FieldSystemPurpose.XeroLineQty,
    domain.FieldSystemPurpose.XeroLineUnitPrice,
    domain.FieldSystemPurpose.XeroTax,
    domain.FieldSystemPurpose.XeroLineTaxAmount,
    domain.FieldSystemPurpose.XeroAttachment,
];

export const fieldSystemPurposesToMigrate = [
    domain.FieldSystemPurpose.XeroItem,
    domain.FieldSystemPurpose.XeroTracking,
    domain.FieldSystemPurpose.XeroAccount,
];

export const whatIsTheOldReviewFunctionalityUrl =
    'https://support.approvalmax.com/portal/en/kb/articles/what-is-happening-with-the-review-functionality#What_is_the_Editing_on_Approval_functionality';
