import { BackupCodes, Button, Checkbox, Flex, Form, Text } from '@approvalmax/ui/src/components';
import { memo } from 'react';
import bemFactory from 'react-bem-factory';

import { AnotherMethodButton } from '../AnotherMethodButton/AnotherMethodButton';
import { StepperTwoFaWizard } from '../StepperTwoFaWizard/StepperTwoFaWizard';
import { useStepBackupCodes } from './StepBackupCodes.hooks';
import { messages } from './StepBackupCodes.messages';

const qa = bemFactory.qa('pro-two-fa-setup-backup-codes');

export const StepBackupCodes = memo(() => {
    const { form, handleSubmit, backupCodes, isLoading, codesAreSaved } = useStepBackupCodes();

    return (
        <Form form={form} onSubmit={handleSubmit}>
            <Flex direction='column' spacing='24' data-qa={qa()}>
                <StepperTwoFaWizard activeStep={2} />

                <Text font='headline' fontSize='xsmall' fontWeight='medium'>
                    {messages.subtitle}
                </Text>

                <Flex direction='column' spacing='40'>
                    <BackupCodes isLoading={isLoading} backupCodes={backupCodes} />

                    <Checkbox disabled={isLoading} name='codesAreSaved'>
                        <Text font='label' fontSize='medium' fontWeight='regular'>
                            {messages.checkboxText}
                        </Text>
                    </Checkbox>
                </Flex>

                <Flex direction='column' spacing='16'>
                    <Button type='submit' color='blue80' size='large' disabled={!codesAreSaved || isLoading}>
                        {messages.buttonDone}
                    </Button>

                    <AnotherMethodButton />
                </Flex>
            </Flex>
        </Form>
    );
});

StepBackupCodes.displayName = 'StepBackupCodes';
