import { ExtractComponentProp } from '@approvalmax/types';
import { AvatarDropzone, toast } from '@approvalmax/ui/src/components';
import isError from 'lodash/isError';
import { actions as actionsCommon } from 'modules/common';
import { useDispatch } from 'modules/react-redux';
import { useCurrentUser } from 'modules/utils';
import { useCallback } from 'react';
import { useSetAvatar } from 'shared/data/webApp/v1';

export const useUploadAvatar = () => {
    const dispatch = useDispatch();
    const user = useCurrentUser();

    const { mutate: setAvatar, isLoading: isLoadingSetAvatar } = useSetAvatar({
        onSuccess: ({ urlHttps: avatar }) => {
            dispatch(actionsCommon.updateProfile(user.id, { avatar }, { avatar }));
        },
    });

    const handleUploadAvatar = useCallback<ExtractComponentProp<typeof AvatarDropzone, 'onDropAccepted'>>(
        async (files) => {
            const body = new FormData();

            try {
                body.append('file', files[0].source);
                setAvatar({ body });
            } catch (error) {
                if (isError(error)) {
                    toast.error(error.message);
                }
            }
        },
        [setAvatar]
    );

    return {
        handleUploadAvatar,
        isLoading: isLoadingSetAvatar,
    };
};
