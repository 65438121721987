import { Box, Flex, Text } from '@approvalmax/ui/src/components';
import { intl } from '@approvalmax/utils';
import { FC, memo } from 'react';

import { messages } from './TwoDifferentValuesWarning.messages';
import { TwoDifferentValuesWarningProps } from './TwoDifferentValuesWarning.types';

/**
 * @deprecated Use redesigned version {@link DifferenceWarning}
 */
export const TwoDifferentValuesWarning: FC<TwoDifferentValuesWarningProps> = memo((props) => {
    const { value1, value2, label1, label2, description } = props;

    return (
        <Box spacing='16' width={230} shadow='small' color='white100'>
            <Flex spacing='16' direction='column'>
                <Text font='body' fontSize='small'>
                    {description}
                </Text>

                <Flex container spacing='4' direction='column'>
                    <Flex justifyContent='space-between' direction='row' wrap={false}>
                        <Text font='body' fontSize='small' color='midnight70'>
                            {label1}
                        </Text>

                        <Text font='body' fontSize='small' fontWeight='bold' wrap={false}>
                            {typeof value1 == 'number' ? intl.formatNumber(value1) : value1}
                        </Text>
                    </Flex>

                    <Flex justifyContent='space-between' direction='row' wrap={false}>
                        <Text font='body' fontSize='small' color='midnight70'>
                            {label2}
                        </Text>

                        <Text font='body' fontSize='small' fontWeight='bold' wrap={false}>
                            {typeof value2 == 'number' ? intl.formatNumber(value2) : value2}
                        </Text>
                    </Flex>

                    {typeof value1 == 'number' && typeof value2 == 'number' && (
                        <Flex justifyContent='space-between' direction='row' wrap={false}>
                            <Text font='body' fontSize='small' color='midnight70'>
                                {messages.differenceText}
                            </Text>

                            <Text font='body' fontSize='small' fontWeight='bold' color='red100' wrap={false}>
                                {intl.formatNumber(value2 - value1)}
                            </Text>
                        </Flex>
                    )}
                </Flex>
            </Flex>
        </Box>
    );
});

TwoDifferentValuesWarning.displayName = 'TwoDifferentValuesWarning';
