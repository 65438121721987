import { ConfirmSkipPrevStep } from '../../CreateOrganisationPopup.types';
import { glConfig } from './ConfirmSkipContent.constants';

export const getGlName = (prevStep: ConfirmSkipPrevStep) => {
    return glConfig[prevStep].name;
};

export const getGlLogo = (prevStep: ConfirmSkipPrevStep) => {
    return glConfig[prevStep].logo;
};
