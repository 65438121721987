import { selectors } from 'modules/common';
import { actions as actionsProfile } from 'modules/profile';
import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useToggle } from 'react-use';
import { useTwoFaProfileDisabling, useTwoFaProfileEnabling, useTwoFaProfileVerifying } from 'shared/components';

export const useSubsectionTwoFa = (profile: selectors.types.ExpandedProfile) => {
    const dispatch = useDispatch();

    const [isVerified, setIsVerified] = useToggle(false);

    const { handleOpen: openTwoFaEnabling } = useTwoFaProfileEnabling();
    const { handleOpen: openTwoFaDisabling } = useTwoFaProfileDisabling();
    const { handleOpen: openTwoFaVerifying } = useTwoFaProfileVerifying();

    useEffect(() => {
        if (!profile.is2faEnabled) {
            setIsVerified(false);
        }
    }, [profile.is2faEnabled, setIsVerified]);

    const handleEnableStart = useCallback(() => {
        openTwoFaEnabling({
            onFinish: (backupMethod) => {
                switch (backupMethod) {
                    case 'backupCodes':
                        dispatch(actionsProfile.changeTwoFaBackupCodesIsEnabled(true));
                        break;

                    case 'backupEmailAddress':
                        dispatch(actionsProfile.changeTwoFaAlternativeEmailIsEnabled(true));
                        break;
                }

                dispatch(actionsProfile.changeTwoFaIsEnabled(true, profile.email));
            },
        });
    }, [dispatch, openTwoFaEnabling, profile.email]);

    const handleDisableStart = useCallback(() => {
        openTwoFaDisabling({
            onFinish: () => {
                dispatch(actionsProfile.changeTwoFaAlternativeEmailIsEnabled(false));
                dispatch(actionsProfile.changeTwoFaBackupCodesIsEnabled(false));
                dispatch(actionsProfile.changeTwoFaIsEnabled(false, profile.email));
            },
        });
    }, [dispatch, openTwoFaDisabling, profile.email]);

    const handleVerificationStart = useCallback(() => {
        openTwoFaVerifying({
            onFinish: () => {
                setIsVerified(true);
            },
        });
    }, [openTwoFaVerifying, setIsVerified]);

    return {
        isVerified,
        handleEnableStart,
        handleDisableStart,
        handleVerificationStart,
        isEnabled: profile.is2faEnabled,
        isAvailable: profile.isPasswordSet,
    };
};
