import { selectors } from 'modules/common';
import { domain } from 'modules/data';
import moment from 'moment';

import { ExpandedCompany, ExpandedRequest, ExpandedTemplate } from './types';

const getLockDateInEffect = (details: domain.NetSuiteRequestDetails) => {
    switch (details.integrationCode) {
        case domain.IntegrationCode.NetSuiteBill:
        case domain.IntegrationCode.NetSuitePO: {
            const isGeneralLodgerDocument = [domain.IntegrationCode.NetSuiteBill].includes(details.integrationCode);

            if (!details.lockDate || !isGeneralLodgerDocument) {
                return false;
            }

            const date = moment.utc(details.transactionDate);
            const lockDate = moment.utc(details.lockDate);
            const newDate = moment(lockDate).add(1, 'd');

            return date.isBefore(newDate);
        }

        case domain.IntegrationCode.NetSuiteExpenseReport:
        case domain.IntegrationCode.NetSuiteSalesOrder:
        default:
            return false;
    }
};

const canCopyPOToBill = (request: domain.Request, companyTemplates: ExpandedTemplate[]) => {
    if (request.integrationCode !== domain.IntegrationCode.NetSuitePO) {
        return false;
    }

    const isApprovedCondition = request.statusV2 === domain.RequestStatusV2.Approved;

    const pushedToSourceCondition = request.history.some(
        (historyItem) => historyItem.type === domain.RequestHistoryEventType.PushedToSource
    );

    const templateCondition = companyTemplates.find(
        (template) => template.integrationCode === domain.IntegrationCode.NetSuiteBill && template.enabled
    );

    return Boolean(isApprovedCondition && pushedToSourceCondition && templateCondition);
};

export const getNetSuiteCommands = (params: {
    request: domain.NetSuiteRequest;
    company: ExpandedCompany;
    hasCreatableTemplate: boolean;
    myDecisions: ExpandedRequest['myDecisions'];
    flags: ExpandedRequest['flags'];
    companyTemplates: ExpandedTemplate[];
    creatableTemplates: string[];
    me: string;
}): ExpandedRequest['commands'] => {
    const { request, company, hasCreatableTemplate, myDecisions, flags, companyTemplates, creatableTemplates, me } =
        params;

    const { isApprover, isActiveApprover, status, isAuthor, isActiveReviewer } = flags;

    const { details } = request;

    const { isManager } = company.flags;

    const isOnHold = request.statusV2 === domain.RequestStatusV2.OnHold;
    const canForceDecision =
        isManager &&
        (request.statusV2 === domain.RequestStatusV2.OnApproval ||
            isOnHold ||
            (request.statusV2 === domain.RequestStatusV2.Rejected &&
                request.origin === domain.RequestOrigin.ApprovalMax));
    const hasActiveIntegration = company.flags.hasActiveIntegration;
    const hasInvoiceEdit = company.betaFeatures.includes(domain.CompanyBetaFeature.NetSuiteInvoiceEdit);

    const lockDatePreventsApprove =
        (details.integrationCode === domain.IntegrationCode.NetSuiteBill ||
            details.integrationCode === domain.IntegrationCode.NetSuitePO) &&
        getLockDateInEffect(details) &&
        details.lockDatePolicy === domain.TemplateSettingsLockDatePolicy.LockApproval;

    const creatableBillTemplateId = companyTemplates.find((companyTemplate) => {
        return companyTemplate.integrationCode === domain.IntegrationCode.NetSuiteBill;
    })?.id;
    const hasCreatableTemplateBill = creatableBillTemplateId
        ? creatableTemplates.includes(creatableBillTemplateId)
        : false;

    const hasCopyToBillOptions = canCopyPOToBill(request, companyTemplates);
    const createdInApprovalMax =
        request.origin === domain.RequestOrigin.ApprovalMax || request.origin === domain.RequestOrigin.Email;
    const canEditAsAuthor = isAuthor && createdInApprovalMax;
    const canEditAsReviewer = selectors.request.canReview(request, me);
    const isMyDraft = isAuthor && status.isDraft;
    const canEdit =
        !status.isClosed &&
        !isOnHold &&
        (request.integrationCode !== domain.IntegrationCode.NetSuiteInvoice || hasInvoiceEdit);
    const canDelete = isMyDraft && createdInApprovalMax && !isOnHold;
    const canCancel = isAuthor && createdInApprovalMax && status.isOpen && !isOnHold;

    const hasWorkflowAccessToReviewStep = selectors.template.getHasAccessToReviewStepByIntegrationCode(
        request.integrationCode
    );

    const canCompleteReview =
        hasWorkflowAccessToReviewStep && isActiveReviewer && request.origin !== domain.RequestOrigin.Email;
    const isReviewer = request.reviewStep?.reviewers.some((reviewer) => reviewer.id === me);
    const canForceReview =
        hasWorkflowAccessToReviewStep &&
        isManager &&
        request.statusV2 === domain.RequestStatusV2.OnReview &&
        request.reviewStep &&
        !isReviewer &&
        request.origin !== domain.RequestOrigin.Email;
    const canReturnToReview =
        hasWorkflowAccessToReviewStep &&
        request.statusV2 === domain.RequestStatusV2.OnApproval &&
        request.reviewStep?.isCompleted &&
        (isManager || isActiveApprover || isReviewer || isAuthor);

    const isCopyUnavailable =
        [
            domain.IntegrationCode.NetSuiteSalesOrder,
            domain.IntegrationCode.NetSuiteVRA,
            domain.IntegrationCode.NetSuiteJournalEntry,
        ].includes(request.integrationCode) ||
        (request.integrationCode === domain.IntegrationCode.NetSuiteInvoice && !hasInvoiceEdit) ||
        (request.integrationCode === domain.IntegrationCode.NetSuiteBillPayment &&
            ![domain.RequestStatusV2.Cancelled, domain.RequestStatusV2.Rejected].includes(request.statusV2));

    return {
        approve: {
            hidden: (isOnHold ? !(isManager || isApprover) : !isActiveApprover) || lockDatePreventsApprove,
            disabled: lockDatePreventsApprove || isOnHold,
        },
        reject: {
            hidden: isOnHold ? !(isManager || isApprover) : !isActiveApprover,
            disabled: isOnHold,
        },
        forceApprove: {
            hidden: !canForceDecision,
            disabled: lockDatePreventsApprove,
        },
        forceReject: {
            hidden: !canForceDecision,
            disabled: request.statusV2 === domain.RequestStatusV2.Rejected,
        },
        revoke: {
            hidden: status.isClosed || myDecisions.length === 0 || isOnHold,
            disabled: false,
        },
        submit: {
            hidden: true,
            disabled: false,
        },
        startOver: {
            hidden:
                !(hasActiveIntegration && isManager && status.isOpen && request.templateVersionIsObsolete) || isOnHold,
            disabled: false,
        },
        editSecondary: {
            hidden: !canEdit || !canEditAsAuthor,
            disabled: false,
        },
        delete: {
            hidden: !canDelete,
            disabled: false,
        },
        cancel: {
            hidden: !canCancel,
            disabled: false,
        },
        clone: {
            hidden: isCopyUnavailable || !hasCreatableTemplate,
            disabled: false,
        },
        clonePOToBill: {
            hidden: !hasCreatableTemplateBill || !hasCopyToBillOptions,
            disabled: false,
        },
        cloneQuoteToSalesInvoice: {
            hidden: true,
            disabled: true,
        },
        allocateToCreditNote: {
            hidden: true,
            disabled: true,
        },
        reverse: {
            hidden: true,
            disabled: true,
        },
        editPrimary: {
            hidden: !canEdit || !canEditAsReviewer || canEditAsAuthor,
            disabled: false,
        },
        completeReview: {
            hidden: !canCompleteReview,
            disabled: false,
        },
        forceReview: {
            hidden: !canForceReview,
            disabled: false,
        },
        returnToReview: {
            hidden: !canReturnToReview,
            disabled: false,
        },
    };
};
