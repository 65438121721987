import { openSplitViewState } from '@approvalmax/ui/src/components';
import { useAtomValue, useSetAtom } from 'jotai';
import { FC, memo, useCallback } from 'react';
import { splitViewGoToBarDefaultSettings, splitViewGoToBarSettingsState } from 'shared/components';
import { useNetSuiteBillPaymentApplyListFieldSettings } from 'shared/hooks';
import { activeAttachmentIdState, ContentSplitViewMode, contentSplitViewState } from 'shared/states';

import { NetSuiteLineTable } from '../NetSuiteLineTable/NetSuiteLineTable';
import { useTableDefinitions } from './NetSuiteApplyList.hooks';
import { messages } from './NetSuiteApplyList.messages';
import { NetSuiteApplyListProps } from './NetSuiteApplyList.types';

export const NetSuiteApplyList: FC<NetSuiteApplyListProps> = memo((props) => {
    const { applyList, companyCustomFields, documentFields, exchangeRate } = props;

    const setOpenSplitView = useSetAtom(openSplitViewState);
    const setContentSplitView = useSetAtom(contentSplitViewState);
    const activeAttachmentId = useAtomValue(activeAttachmentIdState);
    const setSplitViewGoToBarSettings = useSetAtom(splitViewGoToBarSettingsState);

    const onSelectAttachment = useCallback(
        (attachmentId: string, billRequestId: string, billFriendlyName: string) => {
            setOpenSplitView(true);
            setContentSplitView({
                mode: ContentSplitViewMode.AttachmentsToNetSuiteBillLinkedToBillPayment,
                attachmentId,
                billId: billRequestId,
                billFriendlyName,
            });
            setSplitViewGoToBarSettings({
                show: true,
                targetContent: {
                    mode: ContentSplitViewMode.NetSuiteBillLinkedToBillPayment,
                    billId: billRequestId,
                },
                targetContentName: billFriendlyName,
            });
        },
        [setContentSplitView, setOpenSplitView, setSplitViewGoToBarSettings]
    );

    const onSelectBill = useCallback(
        (billRequestId: string) => {
            setContentSplitView({
                mode: ContentSplitViewMode.NetSuiteBillLinkedToBillPayment,
                billId: billRequestId,
            });
            setSplitViewGoToBarSettings(splitViewGoToBarDefaultSettings);
            setOpenSplitView(true);
        },
        [setContentSplitView, setOpenSplitView, setSplitViewGoToBarSettings]
    );

    const applyListFieldSettings = useNetSuiteBillPaymentApplyListFieldSettings(documentFields);

    const tableDef = useTableDefinitions({
        fieldSettings: applyListFieldSettings,
        companyCustomFields,
        exchangeRate,
        activeAttachmentId,
        onSelectBill,
        onSelectAttachment,
    });

    if (applyList.length === 0) {
        return null;
    }

    return <NetSuiteLineTable title={messages.sectionHeaderText} columnDefinitions={tableDef} lineItems={applyList} />;
});

NetSuiteApplyList.displayName = 'NetSuiteApplyList';
