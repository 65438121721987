import { RequestMutationOptions } from '@approvalmax/data';
import { useSetAtom } from 'jotai';
import { EditRequest, useEditRequest } from 'shared/data/webApp/v1';

import { isModifiedRequestState } from '../states';

export const useEditRequestForm = (mutationOptions?: RequestMutationOptions<EditRequest>) => {
    const setIsModifiedRequest = useSetAtom(isModifiedRequestState);

    return useEditRequest({
        ...mutationOptions,
        onSuccess: (...args) => {
            setIsModifiedRequest(true);
            mutationOptions?.onSuccess?.(...args);
        },
    });
};
