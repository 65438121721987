import { domain } from 'modules/data';
import { useMemo } from 'react';
import { findDocumentFieldState } from 'shared/helpers';

export const useNetSuiteExpensesFieldSettings = (documentFields: domain.DocumentField[] | undefined) =>
    useMemo(
        () => ({
            account: findDocumentFieldState(documentFields, 'ExpensesAccount'),
            amortizationEndDate: findDocumentFieldState(documentFields, 'ExpensesAmortizationEndDate'),
            amortizationResidual: findDocumentFieldState(documentFields, 'ExpensesAmortizationResidual'),
            amortizationSchedule: findDocumentFieldState(documentFields, 'ExpensesAmortizationSchedule'),
            amortizationStartDate: findDocumentFieldState(documentFields, 'ExpensesAmortizationStartDate'),
            amount: findDocumentFieldState(documentFields, 'ExpensesAmount'),
            category: findDocumentFieldState(documentFields, 'ExpensesCategory'),
            class: findDocumentFieldState(documentFields, 'ExpensesClass'),
            customer: findDocumentFieldState(documentFields, 'ExpensesCustomer'),
            projectTask: findDocumentFieldState(documentFields, 'ExpensesProjectTask'),
            department: findDocumentFieldState(documentFields, 'ExpensesDepartment'),
            isBillable: findDocumentFieldState(documentFields, 'ExpensesIsBillable'),
            location: findDocumentFieldState(documentFields, 'ExpensesLocation'),
            memo: findDocumentFieldState(documentFields, 'ExpensesMemo'),
            pst: findDocumentFieldState(documentFields, 'ExpensesPST'),
            pstAmount: findDocumentFieldState(documentFields, 'ExpensesPSTAmount'),
            taxAmount: findDocumentFieldState(documentFields, 'ExpensesTaxAmount'),
            taxCode: findDocumentFieldState(documentFields, 'ExpensesTaxCode'),
            taxRate: findDocumentFieldState(documentFields, 'ExpensesTaxRate'),
        }),
        [documentFields]
    );
