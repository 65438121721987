import './pendingPracticeInvitationPopup.scss';

import { Popup, RadioGroupEditor } from '@approvalmax/ui';
import { constants, selectors } from 'modules/common';
import { components } from 'modules/profile';
import { useSelector } from 'modules/react-redux';
import { WarnIcon } from 'modules/sprites';
import { FC, memo, useCallback, useState } from 'react';
import bemFactory from 'react-bem-factory';
import { useDispatch } from 'react-redux';
import { storageService } from 'services/storage';

import { respondToPracticeInvitation } from '../../actions';
import { messages } from './PendingPracticeInvitationPopup.messages';

const bem = bemFactory.block('pending-practice-invitation');
const qa = bemFactory.qa('pending-practice-invitation');

export interface PendingPracticeInvitationPopupData {}

interface HeaderProps {
    disabled: boolean;
    practiceName?: string;
    userRole?: string;
}

const Header: FC<HeaderProps> = memo((props) => {
    const { disabled, practiceName } = props;

    if (disabled) {
        let message;

        if (practiceName) {
            message = messages.alreadyAddedToAccount({
                practiceName,
            });
        } else {
            message = messages.alreadyAddedToAccountWithoutPracticeName;
        }

        return (
            <div className={bem('already-added-to-account')}>
                <div className={bem('warning-icon')}>
                    <WarnIcon width={23} height={19} />
                </div>

                <div>{message}</div>
            </div>
        );
    }

    return messages.followingBusinessAccountsText;
});

const PendingPracticeInvitationPopup: FC = () => {
    const invitations = useSelector(selectors.practiceInvitations.getPracticeInvitations);
    const userRole = useSelector((state) => selectors.profile.getProfile(state).account?.userRole);
    const practiceName = useSelector((state) => selectors.profile.getProfile(state).account?.practiceName);
    const onlyDecline = !!userRole;

    const getFirstInvitation = (): string => {
        if (onlyDecline) {
            return constants.commonConstants.DECLINE_ALL_INVITATIONS_OPTION;
        }

        return invitations[0]?.id || '';
    };

    const firstInvitation = getFirstInvitation();
    const [selectedAccountId, setAccountId] = useState(firstInvitation);
    const dispatch = useDispatch();
    const onAccept = useCallback(async () => {
        try {
            await dispatch(respondToPracticeInvitation(selectedAccountId));
            storageService.resetViewedPracticeInvitationKeys();
        } catch {
            // noop
        }
    }, [dispatch, selectedAccountId]);

    return (
        <Popup.DefaultContent
            qa={qa('step-add-users')}
            className={bem()}
            title={messages.whatHappenedAfterAcceptingText}
            buttons={
                <Popup.DefaultContent.Button
                    qa={qa('popup-next-button')}
                    execute={onAccept}
                    disabled={!selectedAccountId}
                    title={messages.acceptTitle}
                >
                    {messages.accept}
                </Popup.DefaultContent.Button>
            }
        >
            <div className={bem('content')}>
                <Header disabled={onlyDecline} practiceName={practiceName} userRole={userRole} />

                <RadioGroupEditor value={selectedAccountId} onChange={setAccountId}>
                    {invitations.map((item) => (
                        <components.PracticeInvitationRow
                            className={bem('radio_item')}
                            key={item.id}
                            item={item}
                            disabled={onlyDecline}
                        />
                    ))}

                    <RadioGroupEditor.Item
                        className={bem('radio_item', 'decline')}
                        id={constants.commonConstants.DECLINE_ALL_INVITATIONS_OPTION}
                    >
                        <div>{messages.declineAllInvitations}</div>
                    </RadioGroupEditor.Item>
                </RadioGroupEditor>
            </div>
        </Popup.DefaultContent>
    );
};

export default memo(PendingPracticeInvitationPopup);
