import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('pages.RequestList.NetSuiteRequestCard.RequestCardInvoice', {
    transactionNumberFieldText: 'Transaction number',
    transactionReferenceFieldText: 'Reference',
    termsFieldText: 'Terms',
    dueDateFieldText: 'Due date',
    transactionDateFieldText: 'Date',
    postingPeriodFieldText: 'Posting period',
    memoFieldText: 'Memo',
    opportunityFieldText: 'Opportunity',
    excludeCommissionsFieldText: 'Exclude commissions',
    partnerFieldText: 'Partner',
    salesRepFieldText: 'Sales rep',
    leadSourceFieldText: 'Lead source',
    salesEffectiveDateFieldText: 'Sales effective date',
    departmentFieldText: 'Department',
    classFieldText: 'Class',
    locationFieldText: 'Location',
    startDateFieldText: 'Start date',
    endDateFieldText: 'End date',
    discountAmountFieldText: 'Discount amount',
    discountDateFieldText: 'Discount date',
    discountItemFieldText: 'Discount item',
    discountRateFieldText: 'Discount rate',
    excludeCommissionsPositiveValue: 'Yes',
});
