import { useAtom, useAtomValue } from 'jotai';
import { selectors } from 'modules/common';
import { domain } from 'modules/data';
import { useSelector } from 'modules/react-redux';
import { useMount } from 'react-use';
import { activeCompanyIdState, addonPromotionInfoState, AddonPromotionType } from 'shared/states';

const getInitialForcePopupShownDate = (
    lastCloseDate: string | null,
    subscription?: domain.Subscription | null
): string => {
    if (lastCloseDate) {
        return lastCloseDate;
    }

    if (subscription?.billingSystem === domain.SubscriptionBillingSystem.Stripe) {
        return new Date('2024-12-01').toISOString();
    }

    if (subscription?.billingSystem === domain.SubscriptionBillingSystem.Avangate) {
        return new Date('2025-02-14').toISOString();
    }

    return new Date('2025-02-14').toISOString();
};

/**
 * TEMPORARY HACK
 * This hook is a temporary solution to prevent production users from seeing promo banners again.
 * All this initialization logic will be removed later when the promotion system is stabilized.
 */
export const usePromotionInit = () => {
    const [addonPromotionInfo, setAddonPromotionInfo] = useAtom(addonPromotionInfoState);
    const activeCompanyId = useAtomValue(activeCompanyIdState);
    const company = useSelector((state) => selectors.company.findCompanyById(state, activeCompanyId));
    const subscription = useSelector((state) =>
        selectors.subscription.getSubscriptionById(state, company?.subscriptionId || '')
    );

    useMount(() => {
        const payInfo = addonPromotionInfo[AddonPromotionType.Pay];
        const captureInfo = addonPromotionInfo[AddonPromotionType.Capture];

        let shouldUpdate = false;

        const newState = { ...addonPromotionInfo };

        if (!payInfo.forcePopupShownDate) {
            newState[AddonPromotionType.Pay] = {
                ...payInfo,
                forcePopupShownDate: getInitialForcePopupShownDate(payInfo.lastCloseDate, subscription),
            };
            shouldUpdate = true;
        }

        if (!captureInfo.forcePopupShownDate) {
            newState[AddonPromotionType.Capture] = {
                ...captureInfo,
                forcePopupShownDate: getInitialForcePopupShownDate(captureInfo.lastCloseDate, subscription),
            };
            shouldUpdate = true;
        }

        if (shouldUpdate) {
            setAddonPromotionInfo(newState);
        }
    });
};
