import { progress } from '@approvalmax/ui';
import { unmountInitialLoading } from '@approvalmax/ui/src/components';
import { useAtomValue } from 'jotai';
import { useEffect } from 'react';
import { routeState } from 'shared/states';

export const useInitialLoading = () => {
    const router = useAtomValue(routeState);

    useEffect(() => {
        if (router.pathname) {
            progress.reset();
            unmountInitialLoading();
        }
    }, [router]);
};
