import { useSetAtom } from 'jotai';
import { useEffect } from 'react';
import { UseFormReturn } from 'react-hook-form';

import {
    profileFormDataState,
    profileFormInvalidState,
    profileFormModifiedState,
    profileFormResetState,
} from '../ProfileEditPopup.states';

// TODO: Try to find a way to get rid of <any>
export const useUpdateProfileForm = (sectionId: string, form: UseFormReturn<any>) => {
    const setProfileFormData = useSetAtom(profileFormDataState);
    const setProfileFormInvalid = useSetAtom(profileFormInvalidState);
    const setProfileFormModified = useSetAtom(profileFormModifiedState);
    const setProfileFormResets = useSetAtom(profileFormResetState);

    const values = form.watch();
    const errors = Object.keys(form.formState.errors).length;

    useEffect(() => {
        setProfileFormData((prev) => ({ ...prev, ...values }));
    }, [setProfileFormData, values]);

    useEffect(() => {
        setProfileFormModified((prev) => ({ ...prev, [sectionId]: form.formState.isDirty }));
    }, [form.formState.isDirty, sectionId, setProfileFormModified]);

    useEffect(() => {
        setProfileFormInvalid((prev) => ({ ...prev, [sectionId]: errors > 0 }));
    }, [sectionId, errors, setProfileFormInvalid]);

    useEffect(() => {
        setProfileFormResets((prev) => ({ ...prev, [sectionId]: form.reset }));
    }, [form.reset, sectionId, setProfileFormResets]);
};
