import { createContext, FC, PropsWithChildren, useContext, useMemo, useState } from 'react';

import { SelectContextValue } from './Select.types';

export const SelectContext = createContext<SelectContextValue>({
    inputValue: '',
    inputValuePristine: false,
    inputFocus: false,
    menuItems: [],
    createdItems: [],
    dropdownOpen: false,
    setInputValue: () => undefined,
    setInputValuePristine: () => undefined,
    setInputFocus: () => undefined,
    setMenuItems: () => undefined,
    setCreatedItems: () => undefined,
    setDropdownOpen: () => undefined,
});

export const SelectProvider: FC<PropsWithChildren> = (props) => {
    const { children } = props;

    const [inputValue, setInputValue] = useState('');
    const [inputValuePristine, setInputValuePristine] = useState(false);
    const [inputFocus, setInputFocus] = useState(false);
    const [menuItems, setMenuItems] = useState<Record<string, any>[]>([]);
    const [createdItems, setCreatedItems] = useState<Record<string, any>[]>([]);
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const value = useMemo(
        () => ({
            inputValue,
            setInputValue,
            inputValuePristine,
            setInputValuePristine,
            inputFocus,
            setInputFocus,
            menuItems,
            setMenuItems,
            createdItems,
            setCreatedItems,
            dropdownOpen,
            setDropdownOpen,
        }),
        [inputValue, inputValuePristine, inputFocus, menuItems, createdItems, dropdownOpen]
    );

    return <SelectContext.Provider value={value}>{children}</SelectContext.Provider>;
};

export const useSelectContext = <T extends Record<string, any>>() => useContext(SelectContext) as SelectContextValue<T>;
