import { selectors } from 'modules/common';

import { WorkflowSettingsPopupSectionProps } from '../..//WorkflowSettingsPopupContent.types';

export type SectionXeroPOMatchingProps =
    | WorkflowSettingsPopupSectionProps
    | {
          company: selectors.types.ExpandedCompany;
          promoView: true;
      };

export enum AutoMarkingPoOption {
    yes = 'yes',
    no = 'no',
}
