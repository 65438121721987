import { toast } from '@approvalmax/ui/src/components';
import { fileHelpers } from '@approvalmax/utils';
import { commonErrorMessages } from 'services/error/messages';

export const isValidOcrImage = async (url: string) => {
    try {
        const dimensions = await fileHelpers.getImageDimensions(url);

        return (
            dimensions.height >= 100 &&
            dimensions.width >= 100 &&
            dimensions.height <= 10000 &&
            dimensions.width <= 10000
        );
    } catch {
        toast.error(commonErrorMessages.somethingWentWrong);

        return false;
    }
};
