import { toast } from '@approvalmax/ui/src/components';
import { zodResolver } from '@hookform/resolvers/zod';
import { domain } from 'modules/data';
import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useGenerateTwoFaBackupCodes, useGetTwoFaAlternativeMethods } from 'shared/data/webApp/v1';
import { z } from 'zod';

import { fieldNames } from './BackupCodesUpdatePopup.constants';
import { messages } from './BackupCodesUpdatePopup.messages';
import { schema } from './BackupCodesUpdatePopup.schema';
import { BackupCodesUpdatePopupProps } from './BackupCodesUpdatePopup.types';

export const useBackupCodesUpdatePopup = (props: BackupCodesUpdatePopupProps) => {
    const { onClose } = props;

    const form = useForm<z.infer<typeof schema>>({
        defaultValues: {
            [fieldNames.stateId]: null,
            [fieldNames.codesAreSaved]: false,
        },
        resolver: zodResolver(schema),
    });

    const [backupCodes, setBackupCodes] = useState<domain.BackupCode[]>([]);

    const { isLoading: isLoadingGetTwoFaAlternativeMethods } = useGetTwoFaAlternativeMethods(
        {},
        {
            onSuccess: (data) => {
                setBackupCodes(data?.backupCodes || []);
            },
        }
    );
    const { mutate: generateTwoFaBackupCodes, isLoading: isLoadingGenerateTwoFaBackupCodes } =
        useGenerateTwoFaBackupCodes({
            onSuccess: (data) => {
                setBackupCodes(data.backupCodes.map((code) => ({ code, isUsed: false })));
                toast.info(messages.onSuccess);
            },
        });

    const generateCodes = useCallback(() => {
        generateTwoFaBackupCodes({ body: {} });
    }, [generateTwoFaBackupCodes]);

    const formSubmit = form.handleSubmit(() => {
        onClose();
    });

    const fieldCodesAreSaved = form.watch(fieldNames.codesAreSaved);

    return {
        form,
        formSubmit,
        backupCodes,
        generateCodes,
        canSubmit: fieldCodesAreSaved,
        progress: isLoadingGetTwoFaAlternativeMethods || isLoadingGenerateTwoFaBackupCodes,
    };
};
