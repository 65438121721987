import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('modules.FirstStartModule.CreateOrganisationPopup.InviteTeammateContent', {
    title: 'Invite your teammate to connect the accounting system',
    description: "If you can't connect the accounting system, invite your colleague to ApprovalMax as an admin.",
    alert: 'Your colleague will receive a generic invitation letter. Please contact them and explain that you want them to connect ApprovalMax to the accounting system.',
    adminAble: 'The admin will be able to:',
    adminAble1: 'Connect the ApprovalMax org to your GL',
    adminAble2: 'Set up new workflows',
    adminAble3: 'Manage integrations',
    adminAble4: 'Add new users',
    adminAble5: 'View and create request reports',
    adminAble6: 'Submit and approve requests',
    buttonBack: 'Back',
    buttonInvite: 'Invite teammate',
});
