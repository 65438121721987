import { componentHelpers } from '@approvalmax/utils';
import uniqBy from 'lodash/uniqBy';
import { useEffect, useMemo } from 'react';

import { Grid } from '../Grid/Grid';
import { TransferButtons, TransferPanel } from './components';
import { useTransferPanel } from './Transfer.hooks';
import { BaseItem, TransferProps } from './Transfer.types';

const TransferBase = <Item extends BaseItem>(props: TransferProps<Item>) => {
    const {
        items,
        selected = [],
        onChange,
        onSelectionChange,
        itemNameKey = 'name',
        itemIdKey = 'id',
        columnName,
        height,
        itemsPerPage,
        leftPanelTitle,
        rightPanelTitle,
        leftPanelDescription,
        rightPanelDescription,
        progress,
        onSearch,
        preventSearch,
    } = props;

    const { leftItems, rightItems } = useMemo(() => {
        const leftItems: Item[] = [];
        const rightItems: Item[] = [];

        items.forEach((item) => {
            if (selected.includes(item[itemIdKey])) {
                rightItems.push(item);
            } else {
                leftItems.push(item);
            }
        });

        return {
            leftItems: uniqBy(leftItems, 'id'),
            rightItems: uniqBy(rightItems, 'id'),
        };
    }, [itemIdKey, items, selected]);

    const leftTransferPanel = useTransferPanel({
        items: leftItems,
        itemNameKey,
        itemsPerPage,
        panelTitle: leftPanelTitle,
        panelDescription: leftPanelDescription,
        columnName,
        onSearch,
        preventSearch,
    });
    const rightTransferPanel = useTransferPanel({
        items: rightItems,
        itemNameKey,
        itemsPerPage,
        panelTitle: rightPanelTitle,
        panelDescription: rightPanelDescription,
        columnName,
    });

    const handleChoose = () => {
        onChange?.(selected.concat(leftTransferPanel.checkedItems));

        leftTransferPanel.handleCheckedItemsMoved();
    };

    const handleUnchoose = () => {
        const chosen = new Set(selected);

        rightTransferPanel.checkedItems.forEach((item) => chosen.delete(item));

        onChange?.([...chosen]);

        rightTransferPanel.handleCheckedItemsMoved();
    };

    const hasSelection = useMemo(
        () => leftTransferPanel.checkedItems.length > 0 || rightTransferPanel.checkedItems.length > 0,
        [leftTransferPanel.checkedItems, rightTransferPanel.checkedItems]
    );

    useEffect(() => {
        onSelectionChange?.(hasSelection);
    }, [hasSelection, onSelectionChange]);

    return (
        <Grid gridTemplateColumns='1fr auto 1fr' gap={12} gridTemplateRows='100%' height={height}>
            <TransferPanel {...leftTransferPanel} progress={progress} />

            <TransferButtons
                toRightDisabled={leftTransferPanel.checkedItems.length === 0 || progress}
                toLeftDisabled={rightTransferPanel.checkedItems.length === 0 || progress}
                onToRightClick={handleChoose}
                onToLeftClick={handleUnchoose}
            />

            <TransferPanel {...rightTransferPanel} />
        </Grid>
    );
};

TransferBase.displayName = 'Transfer';

export const Transfer = componentHelpers.genericMemo(TransferBase);
