import { errorHelpers } from '@approvalmax/utils';
import { selectors } from 'modules/common';
import { domain } from 'modules/data';
import { useSelector } from 'modules/react-redux';
import { LineItemsTable } from 'pages/requestList/components/lineItemsSection/LineItemsTable';
import { FC, memo, useMemo } from 'react';

import { getColumnDefinitions } from '../../../../QBooksMatchingPopupOld.config';
import { messages } from './MatchingLineItems.messages';
import { Container, RadioContainer, StyledMatchingIcon, StyledRadiobox } from './MatchingLineItems.styles';
import { MatchingLineItemsProps } from './MatchingLineItems.types';

export const MatchingLineItems: FC<MatchingLineItemsProps> = memo((props) => {
    const { purchaseOrder, selectedLineItemId, setSelectedLineItemId, requestId } = props;

    const request = useSelector((state) =>
        selectors.request.getRequestById<
            selectors.types.QBooksBillExpandedRequest | selectors.types.QBooksPoExpandedRequest
        >(state, requestId)
    );

    if (request.integrationType !== domain.IntegrationType.QBooks) {
        throw errorHelpers.invalidOperationError();
    }

    const isAccountLineItem = purchaseOrder.accountLineItems.length > 0;
    const columnDefinitions = useMemo(() => {
        const columnDefinitions = getColumnDefinitions(request, isAccountLineItem);

        columnDefinitions[columnDefinitions.length - 1] = {
            ...columnDefinitions[columnDefinitions.length - 1],
            alignRight: false,
            alwaysVisible: false,
        };

        columnDefinitions.push({
            id: 'linked',
            name: messages.linkedColumnName,
            value: () => null,
            cell: (_val, li) => (li.isMatched ? <StyledMatchingIcon /> : null),
            width: 40,
            alignRight: true,
            alwaysVisible: true,
        });

        columnDefinitions.unshift({
            id: 'selected',
            name: '',
            value: () => '',
            cell: (_val, li) => {
                const remainingBalance = li.remainingBalance || 0;
                const isEnabled = remainingBalance > 0;

                return (
                    <RadioContainer onClick={() => isEnabled && setSelectedLineItemId(li.id)}>
                        <StyledRadiobox checked={li.id === selectedLineItemId} disabled={!isEnabled} />
                    </RadioContainer>
                );
            },
            alwaysVisible: true,
        });

        return columnDefinitions;
    }, [isAccountLineItem, request, selectedLineItemId, setSelectedLineItemId]);

    return (
        <Container>
            <LineItemsTable
                columnDefinitions={columnDefinitions}
                lineItems={isAccountLineItem ? purchaseOrder.accountLineItems : purchaseOrder.lineItems}
            />
        </Container>
    );
});

MatchingLineItems.displayName = 'MatchingLineItems';
