import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('pages.RequestList.XeroMatchingV2Section.BillMatchingTable', {
    itemColumnName: 'Name',
    billedColumnName: 'Billed',
    grnStatusColumnName: 'GRN Status',
    matchedByColumnName: 'Matched by',
    allocatedAmountColumnName: 'Amount allocated to this Bill',
    totalAmountColumnNameWithGross: 'Total amount (Gross)',
    totalAmountColumnNameWithNet: 'Total amount (Net)',
    totalAllocatedText: 'Total:',
});
