import { enabledByParams, RequestParams, RequestQueryOptions } from '@approvalmax/data';
import { useGet, useLazyGet } from 'shared/data';

import { requestsApiPaths } from '../paths';
import { GetAttachment } from './useGetAttachment.types';

export const useGetAttachment = (
    params?: RequestParams<GetAttachment>,
    queryOptions?: RequestQueryOptions<GetAttachment>
) =>
    useGet(requestsApiPaths.getAttachment, {
        params,
        queryOptions: {
            ...queryOptions,
            enabled: enabledByParams<GetAttachment['params']['query']>(
                {
                    companyId: params?.query?.companyId,
                    attachmentId: params?.query?.attachmentId,
                },
                queryOptions
            ),
        },
        method: 'get',
        apiVersion: 'v1',
        responseType: 'blob',
    });

export const useLazyGetAttachment = (queryOptions?: RequestQueryOptions<GetAttachment>) =>
    useLazyGet<GetAttachment>(requestsApiPaths.getAttachment, {
        method: 'get',
        apiVersion: 'v1',
        responseType: 'blob',
        queryOptions,
    });
