import { componentHelpers } from '@approvalmax/utils';
import { FloatingFocusManager, FloatingPortal, useMergeRefs, useTransitionStyles } from '@floating-ui/react';
import { cloneElement, forwardRef, isValidElement, MouseEvent, useMemo } from 'react';
import { isForwardRef } from 'react-is';

import { useFloatingDropdown, useOpen } from './Dropdown.hooks';
import { Arrow, Content, Inner } from './Dropdown.styles';
import { DropdownProps } from './Dropdown.types';

/**
 * The dropdown list allows the user to see and click on an item from the list of items.
 */
const Dropdown = forwardRef<HTMLDivElement, DropdownProps>((props, ref) => {
    const {
        children,
        activator,
        open,
        onOpen,
        width,
        minWidth,
        height,
        display = 'inline-block',
        placement = 'bottom',
        isClicking = true,
        isHovering = false,
        safePolygon = false,
        spacing,
        maxWidth,
        maxHeight = 220,
        initOpen,
        isUnmount = true,
        hasArrow = true,
        disabled,
        closeOnClickInnerButton,
        closeOnClickInnerAnchor,
        color = 'white100',
        floatingPortalProps,
        floatingFocusManagerProps,
        ...restProps
    } = props;

    const { isOpen, setOpen } = useOpen({ open, onOpen, initOpen });
    const { context, getFloatingProps, getReferenceProps, refs, middlewareData } = useFloatingDropdown({
        isOpen,
        setOpen,
        placement,
        isClicking,
        isHovering,
        safePolygon,
        hasArrow,
        width,
        minWidth,
        height,
        disabled,
    });
    const { isMounted, styles } = useTransitionStyles(context, {
        initial: ({ side }) => ({
            opacity: 0,
            transform: componentHelpers.getTransformTranslateBySide(side),
        }),
    });

    const activatorRef = useMergeRefs([refs.setReference, activator?.ref || null]);
    const floatingRef = useMergeRefs([refs.setFloating, ref]);

    const isActivatorValidElement = isValidElement(activator) && isForwardRef(activator);

    const activatorProps = useMemo(
        () =>
            getReferenceProps({
                ref: activatorRef,
                ...restProps,
                ...(isActivatorValidElement ? activator.props || {} : {}),
            }),
        [activator, activatorRef, getReferenceProps, isActivatorValidElement, restProps]
    );

    const contentProps = useMemo(
        () =>
            getFloatingProps({
                onClick(event: MouseEvent<HTMLElement>) {
                    if (
                        event.target instanceof HTMLElement &&
                        ((closeOnClickInnerButton && event.target.closest('button')) ||
                            (closeOnClickInnerAnchor && event.target.closest('a')))
                    ) {
                        setOpen(false);
                    }
                },
                ...restProps,
                style: {
                    position: context.strategy,
                    top: context.y ?? 0,
                    left: context.x ?? 0,
                    ...styles,
                },
            }),
        [
            closeOnClickInnerAnchor,
            closeOnClickInnerButton,
            context.strategy,
            context.x,
            context.y,
            getFloatingProps,
            restProps,
            setOpen,
            styles,
        ]
    );

    const showContent = children && (!isUnmount || (isUnmount && context.open && isMounted));
    const innerMaxHeight = height === undefined ? maxHeight : 'none';

    return (
        <>
            {isActivatorValidElement ? (
                cloneElement(activator, activatorProps)
            ) : (
                <div style={{ display }} {...activatorProps}>
                    {activator}
                </div>
            )}

            <FloatingPortal {...floatingPortalProps}>
                {showContent && (
                    <FloatingFocusManager context={context} {...floatingFocusManagerProps}>
                        <Content
                            ref={floatingRef}
                            $open={(!isUnmount && context.open && isMounted) || isUnmount}
                            $color={color}
                            {...contentProps}
                        >
                            <Inner spacing={spacing} $maxHeight={innerMaxHeight} $maxWidth={maxWidth}>
                                {children}
                            </Inner>

                            {hasArrow && (
                                <Arrow
                                    ref={refs.setArrow}
                                    $placement={context.placement}
                                    $color={color}
                                    style={{
                                        top: middlewareData.arrow?.y,
                                        left: middlewareData.arrow?.x,
                                    }}
                                />
                            )}
                        </Content>
                    </FloatingFocusManager>
                )}
            </FloatingPortal>
        </>
    );
});

Dropdown.displayName = 'Dropdown';

export default Dropdown;
