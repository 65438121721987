import { isBrowser } from '@approvalmax/utils/src/helpers/browser/isBrowser';

import { ApiSource } from './types';

export const apiSources = <const>[
    'webApp',
    'myAccount',
    'adminPanel',
    'devPortal',
    'identity',
    'slack',
    'wallets',
    'walletsKyc',
];

export type Env = 'production' | 'staging' | 'dev01' | 'dev02' | 'dev03' | 'dev04' | 'local';

type EnvConfig = Record<ApiSource, string>;

export const indentityClientId: { [key in ApiSource]?: string } = {
    devPortal: 'dev.portal',
};

const getDevEnvConfig = (env: Env): EnvConfig => {
    const isNewDevDomain = isBrowser() ? window.location?.hostname.endsWith('.amaxinternal.com') : false;
    const envNumber = env.match('[0-9]+$')![0];

    return isNewDevDomain
        ? {
              adminPanel: `admin-api-${env}.amaxinternal.com`,
              devPortal: `developer-api-${env}.amaxinternal.com`,
              identity: `identity-${env}.amaxinternal.com`,
              myAccount: `account-${env}.amaxinternal.com`,
              webApp: `api-${env}.amaxinternal.com`,
              slack: `slack-${env}.amaxinternal.com`,
              wallets: `va-currency-cloud-api-${env}.amaxinternal.com`,
              walletsKyc: `kyc-api-${env}.amaxinternal.com`,
          }
        : {
              adminPanel: `${env}-admin-api.approvalmax.com`,
              devPortal: `developer-api-${env}.approvalmax.com`,
              identity: `identity${env}.approvalmax.com`,
              myAccount: `account${env}.approvalmax.com`,
              webApp: `devapi${envNumber}.approvalmax.com`,
              slack: `slack-${env}.approvalmax.com`,
              wallets: `va-currency-cloud-api-${env}.amaxinternal.com`,
              walletsKyc: `kyc-api-${env}.amaxinternal.com`,
          };
};

const getStageEnvConfig = (): EnvConfig => {
    const isNewStageDomain = isBrowser() ? window.location?.hostname.endsWith('.amaxinternalstage.com') : false;

    return isNewStageDomain || process.env.DEBUG
        ? {
              adminPanel: 'admin-api.amaxinternalstage.com',
              devPortal: 'developer-api.amaxinternalstage.com',
              identity: 'identity.amaxinternalstage.com',
              myAccount: 'account.amaxinternalstage.com',
              webApp: 'api.amaxinternalstage.com',
              slack: 'slack.amaxinternalstage.com',
              wallets: 'va-currency-cloud-api.amaxinternalstage.com',
              walletsKyc: 'kyc-api.amaxinternalstage.com',
          }
        : {
              adminPanel: 'test-admin-api.approvalmax.com',
              devPortal: 'developer-api-test.approvalmax.com',
              identity: 'identity-test.approvalmax.com',
              myAccount: 'accounttest.approvalmax.com',
              webApp: 'testapi.approvalmax.com',
              slack: 'slack-test.approvalmax.com',
              wallets: 'va-currency-cloud-api.amaxinternalstage.com',
              walletsKyc: `kyc-api.amaxinternalstage.com`,
          };
};

const configurationsMap: Record<Env, EnvConfig> = {
    local: {
        adminPanel: 'dev04-admin-api.approvalmax.com',
        devPortal: 'developer-api-dev04.approvalmax.com',
        identity: 'identitydev04.amaxinternal.com',
        myAccount: 'localaccountapi.amaxinternal.com:5052',
        webApp: 'localapi.amaxinternal.com:44390',
        slack: 'slack-dev04.amaxinternal.com',
        wallets: 'va-currency-cloud-api-dev04.amaxinternal.com',
        walletsKyc: 'kyc-api-dev04.amaxinternal.com',
    },
    dev01: getDevEnvConfig('dev01'),
    dev02: getDevEnvConfig('dev02'),
    dev03: getDevEnvConfig('dev03'),
    dev04: getDevEnvConfig('dev04'),
    staging: getStageEnvConfig(),
    production: {
        adminPanel: 'admin-api.approvalmax.com',
        devPortal: 'developer-api.approvalmax.com',
        identity: 'identity.approvalmax.com',
        myAccount: 'account.approvalmax.com',
        webApp: 'api.approvalmax.com',
        slack: 'slack.approvalmax.com',
        wallets: 'va-currency-cloud-api.approvalmax.com',
        walletsKyc: 'kyc-api.approvalmax.com',
    },
};

export const getAppConfig = (env: Env) => {
    return configurationsMap[env];
};
