import { ConfirmationDropdown, Link, TransparentButton } from '@approvalmax/ui';
import { selectors } from 'modules/common';
import { domain } from 'modules/data';
import { useSelector } from 'modules/react-redux';
import { BubbleCloseIcon } from 'modules/sprites';
import { checkAccessOnlyAsWatcher } from 'pages/requestList/selectors/requestSelectors';
import { FC, memo, useMemo } from 'react';
import { getPath, Path } from 'urlBuilder';

import { getColumnDefinitions } from '../../../QBooksMatchingPopupOld/QBooksMatchingPopupOld.config';
import { QBooksMatchingLineItem } from '../../../QBooksMatchingPopupOld/QBooksMatchingPopupOld.types';
import QBooksPOLineRemaining from '../QBooksPOLineRemaining/QBooksPOLineRemaining';
import { messages } from './QBooksMatchingInfoContentOld.messages';
import {
    Container,
    Control,
    Date,
    POInfo,
    StyledLineItemsTable,
    StyledTextButton,
    TableContainer,
    Value,
} from './QBooksMatchingInfoContentOld.styles';
import { QBooksMatchingInfoContentOldProps } from './QBooksMatchingInfoContentOld.types';

export const QBooksMatchingInfoContentOld: FC<QBooksMatchingInfoContentOldProps> = memo((props) => {
    const {
        request,
        matchingInfo,
        lineItemId,
        onDropdownClose,
        onRemoveMatching,
        isRemoveMatchingLoading,
        isAccountLineItem,
    } = props;

    const isBill = request.integrationCode === domain.IntegrationCode.QBooksBill;

    const profile = useSelector(selectors.profile.getProfileUser);
    const me = useMemo(
        () => request.company.allMembers.find((user) => user.id === profile?.id),
        [profile?.id, request.company.allMembers]
    );

    const hasAccessOnlyAsWatcher = useMemo(
        () => !!me && checkAccessOnlyAsWatcher(me.databaseId || '', me.userEmail, request.company, request),
        [me, request]
    );

    const matchingCommands = useMemo(
        () => selectors.request.getQBooksMatchingCommands(request, profile.id, hasAccessOnlyAsWatcher),
        [hasAccessOnlyAsWatcher, request, profile.id]
    );

    const [initialMatchingInfo] = matchingInfo;

    const columnDefinitions = useMemo(
        () => getColumnDefinitions(request, isAccountLineItem),
        [isAccountLineItem, request]
    );

    const extendedLineItems = useMemo(
        () =>
            matchingInfo.reduce<QBooksMatchingLineItem[]>(
                (accum, { name, date, lineItem, accountLineItem, amount, id }) => {
                    if (accountLineItem) {
                        return [
                            ...accum,
                            {
                                ...accountLineItem,
                                lineItemId: id,
                                name,
                                date,
                                totalAmount: amount,
                            },
                        ];
                    }

                    if (lineItem) {
                        return [
                            ...accum,
                            {
                                ...lineItem,
                                lineItemId: id,
                                name,
                                date,
                                totalAmount: amount,
                            },
                        ];
                    }

                    return accum;
                },
                []
            ),
        [matchingInfo]
    );

    const handleMatchingRemove = async () => {
        await onRemoveMatching(lineItemId);
        onDropdownClose();
    };

    return (
        <Container>
            <TableContainer>
                <StyledLineItemsTable columnDefinitions={columnDefinitions} lineItems={extendedLineItems} />

                <Control>
                    <TransparentButton execute={onDropdownClose}>
                        <BubbleCloseIcon width={12} height={12} />
                    </TransparentButton>

                    <ConfirmationDropdown confirmation={messages.deleteConfirmationText}>
                        <StyledTextButton
                            colorTheme='red'
                            size='small1'
                            execute={handleMatchingRemove}
                            command={matchingCommands.removeMatching}
                            disabled={isRemoveMatchingLoading}
                            title={messages.deleteButtonTitle}
                        >
                            {messages.remove}
                        </StyledTextButton>
                    </ConfirmationDropdown>
                </Control>
            </TableContainer>

            {isBill ? (
                <POInfo>
                    <Link targetBlank href={getPath(Path.request, initialMatchingInfo.id, request.companyId)}>
                        {initialMatchingInfo.name}
                    </Link>

                    <Date>
                        {messages.date({
                            date: initialMatchingInfo.date,
                            b: (chunks) => <Value>{chunks}</Value>,
                        })}
                    </Date>

                    <Date>
                        {messages.amount({
                            amount: initialMatchingInfo.amount,
                            b: (chunks) => <Value>{chunks}</Value>,
                        })}
                    </Date>
                </POInfo>
            ) : (
                <QBooksPOLineRemaining
                    isAccountLineItem={isAccountLineItem}
                    matchingInfo={matchingInfo}
                    lineItemId={lineItemId}
                    request={request}
                />
            )}
        </Container>
    );
});

QBooksMatchingInfoContentOld.displayName = 'QBooksMatchingInfoContentOld';
