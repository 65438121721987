import type { ApiError } from '@approvalmax/types';
import { routerHelpers } from '@approvalmax/utils';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback, useEffect, useMemo } from 'react';
import { usePrevious, useUnmount } from 'react-use';

import { Env } from '../configs';
import { getApiInstance, getApiQueryMethod, getApiUrl } from '../helpers/getApi';
import { getAuthorization } from '../helpers/getAuthorization';
import { useHandleApiCall } from '../hooks/useHandleApiCall';
import { ApiSource, LazyGetDataParams, RequestHeaders, UseGetOptions } from '../types';

export const createUseLazyGet = (defaultApiSource: ApiSource, defaultEnv: Env, getHeaders?: () => RequestHeaders) => {
    /**
     * The concept of this hook is partially taken from the following sources:
     *
     * @see https://github.com/apollographql/apollo-client/blob/main/src/react/hooks/useLazyQuery.ts and
     * @see https://github.com/TanStack/query/discussions/1205
     */
    /**
     * Setting default type parameter to 'never' forces developers to explicitly specify the RequestData type
     * when using this hook, preventing TypeScript from inferring potentially incorrect types automatically
     */
    return <RequestData extends LazyGetDataParams = never>(
        path: string,
        options: Omit<UseGetOptions<RequestData['response']>, 'params'> = {}
    ) => {
        const {
            apiSource = defaultApiSource,
            apiVersion = 'v1',
            method,
            queryOptions,
            responseType,
            mapToCamelCase,
            skipToastForErrorCodes,
        } = options;

        const handleApiCall = useHandleApiCall<RequestData['response']>({ mapToCamelCase, skipToastForErrorCodes });
        const queryClient = useQueryClient();
        const queryKey = useMemo(() => ['lazy', path], [path]);

        const queryResult = useQuery<RequestData['response'], ApiError>(queryKey, {
            // enabled is always false, no cache, no refetch, we just need to get results
            enabled: false,
            retry: false,
            cacheTime: 0,
            staleTime: 0,
        });

        const { data: queryResultData, error, status: queryStatus, isFetching } = queryResult;
        const status = isFetching ? 'fetching' : queryStatus;
        const prevStatus = usePrevious(status);

        useEffect(() => {
            if (prevStatus === status) return;

            switch (queryStatus) {
                case 'success':
                    queryOptions?.onSuccess?.(queryResultData);
                    queryOptions?.onSettled?.(queryResultData, null);
                    break;

                case 'error':
                    queryOptions?.onError?.(error);
                    queryOptions?.onSettled?.(undefined, error);
                    break;
            }
        }, [error, queryOptions, queryResultData, status, prevStatus, queryStatus]);

        useUnmount(() => {
            // reset useQuery flags, remove cache
            queryClient.removeQueries({ queryKey });
        });

        const execute = useCallback(
            ({ params }: Pick<RequestData, 'params'>) => {
                const apiFn = getApiInstance(apiSource, apiVersion, getHeaders?.());
                const apiMethod = method ?? getApiQueryMethod(apiSource)[apiVersion];
                const data = method === 'get' || !params?.query ? {} : params?.query;

                const baseURL = getApiUrl(apiSource, defaultEnv, apiVersion);
                const url = routerHelpers.testPathByParams(path, params?.path)
                    ? routerHelpers.pathToUrl(path, params?.path)
                    : '';
                const Authorization = getAuthorization(defaultApiSource, defaultEnv);

                return queryClient.fetchQuery(
                    queryKey,
                    () =>
                        handleApiCall(
                            apiFn({
                                baseURL,
                                url,
                                method: apiMethod,
                                responseType,
                                headers: { ...(Authorization && { Authorization }) },
                                params: params?.query,
                                data,
                            })
                        ),
                    {
                        meta: { meta: queryOptions?.meta, path, params, data },
                    }
                );
            },
            // exclude queryClient
            // eslint-disable-next-line react-hooks/exhaustive-deps
            [apiSource, apiVersion, path, queryKey]
        );

        return <const>[execute, queryResult];
    };
};
