import { AmCaptureImage, CloseIcon } from '@approvalmax/ui';
import { Box, Button, Flex, Text } from '@approvalmax/ui/src/components';
import { useSetAtom } from 'jotai';
import {
    tryPayOrCapturePopupInfoState,
    tryPayOrCapturePopupOpenState,
    TryPayOrCapturePopupOrigin,
} from 'modules/profile';
import { memo, useCallback } from 'react';
import { useAddonPromotions } from 'shared/hooks/useAddonPromotions';
import { AddonPromotionType } from 'shared/states';

import { messages } from './RequestCapturePromotion.messages';

export const RequestCapturePromotion = memo(() => {
    const { closePromotion } = useAddonPromotions();
    const setTryPayOrCapturePopupOpen = useSetAtom(tryPayOrCapturePopupOpenState);
    const setTryPayOrCapturePopupInfo = useSetAtom(tryPayOrCapturePopupInfoState);

    const onClose = useCallback(() => {
        closePromotion(AddonPromotionType.Capture);
    }, [closePromotion]);

    const onTry = useCallback(() => {
        setTryPayOrCapturePopupOpen(true);
        setTryPayOrCapturePopupInfo({ type: AddonPromotionType.Capture, origin: TryPayOrCapturePopupOrigin.EditForm });
    }, [setTryPayOrCapturePopupInfo, setTryPayOrCapturePopupOpen]);

    return (
        <Box spacing='24 24 0 24'>
            <Box spacing='24' radius='small' color='mint50' minWidth={300}>
                <Flex wrap={false} justifyContent='space-between' spacing='16' alignItems='start'>
                    <Flex justifyContent='space-between' alignItems='center' spacing='40'>
                        <Box width={126}>
                            <AmCaptureImage height={48} width={126} />
                        </Box>

                        <Flex direction='column' spacing='12' justifyContent='space-between' basis={200} grow={1}>
                            <Text font='headline' fontSize='small' fontWeight='medium'>
                                {messages.title}
                            </Text>

                            <Button color='mint80' onClick={onTry}>
                                {messages.tryNowButtonText}
                            </Button>

                            <Flex justifyContent='center'>
                                <Text font='label' fontSize='small'>
                                    {messages.description}
                                </Text>
                            </Flex>
                        </Flex>
                    </Flex>

                    <Button icon noPadding onClick={onClose} variant='text' color='blue100'>
                        <CloseIcon size={20} />
                    </Button>
                </Flex>
            </Box>
        </Box>
    );
});

RequestCapturePromotion.displayName = 'RequestCapturePromotion';
