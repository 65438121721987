import { RequestQueryOptions } from '@approvalmax/data';
import { GetBillLinkedToBatchPayment, useGetBillLinkedToBatchPayment } from 'shared/data/webApp/v1';

import type { UseBillLinkedToAirwallexBatchPaymentParams } from './useBillLinkedToAirwallexBatchPayment.types';
import { useNormalizedLinkedBillResponse } from './useNormalizedLinkedBillResponse';

export const useBillLinkedToAirwallexBatchPayment = (
    { companyId, billId, batchPaymentRequestId }: UseBillLinkedToAirwallexBatchPaymentParams,
    queryOptions?: RequestQueryOptions<GetBillLinkedToBatchPayment>
) => {
    return useNormalizedLinkedBillResponse(
        billId,
        useGetBillLinkedToBatchPayment(
            {
                query: {
                    companyId,
                    requestId: billId,
                    batchPaymentRequestId,
                },
            },
            queryOptions
        )
    );
};
