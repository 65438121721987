import type { ApiError } from '@approvalmax/types';
import { objectHelpers, routerHelpers } from '@approvalmax/utils';
import { InfiniteData, useInfiniteQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

import { Env } from '../configs';
import { getApiInstance, getApiQueryMethod, getApiUrl } from '../helpers/getApi';
import { getAuthorization } from '../helpers/getAuthorization';
import { getQueryKey } from '../helpers/getQueryKey';
import { useHandleApiCall } from '../hooks/useHandleApiCall';
import { ApiSource, RequestHeaders, UseInfiniteGetOptions } from '../types';

export const createUseInfiniteGet = (
    defaultApiSource: ApiSource,
    defaultEnv: Env,
    getHeaders?: () => RequestHeaders
) => {
    return <ResponseData>(path: string, options: UseInfiniteGetOptions<ResponseData>) => {
        const {
            apiSource = defaultApiSource,
            apiVersion = 'v1',
            params,
            queryOptions,
            responseType,
            mapToCamelCase,
            skipToastForErrorCodes,
            method = 'get',
        } = options;

        const baseURL = getApiUrl(apiSource, defaultEnv, apiVersion);
        const url = routerHelpers.testPathByParams(path, params?.path)
            ? routerHelpers.pathToUrl(path, params?.path)
            : '';
        const Authorization = getAuthorization(defaultApiSource, defaultEnv);
        const handleApiCall = useHandleApiCall<ResponseData>({ mapToCamelCase, skipToastForErrorCodes });
        const apiFn = getApiInstance(apiSource, apiVersion, getHeaders?.());
        const apiMethod = method ?? getApiQueryMethod(apiSource)[apiVersion];
        const data = method === 'get' || !params?.query ? {} : params?.query;
        const queryKey = getQueryKey(url, params?.query);
        const queryClient = useQueryClient();

        const queryResult = useInfiniteQuery<ResponseData, ApiError>(
            queryKey,
            ({ pageParam }) =>
                handleApiCall(
                    apiFn({
                        baseURL,
                        method: apiMethod,
                        url,
                        headers: {
                            ...(Authorization && { Authorization }),
                        },
                        responseType,
                        params: pageParam?.params?.query || params?.query,
                        data: objectHelpers.has(pageParam, 'data') ? pageParam?.data : data,
                    })
                ),
            {
                enabled: Boolean(url),
                ...queryOptions,
                meta: {
                    meta: queryOptions?.meta,
                    path,
                    params,
                    data,
                },
            }
        );

        const updateCachedData = useCallback(
            (getNewCachedData: (currentData: InfiniteData<ResponseData>) => InfiniteData<ResponseData>) => {
                queryClient.setQueryData(queryKey, (currentData: InfiniteData<ResponseData>) => {
                    return getNewCachedData(currentData);
                });
            },
            [queryClient, queryKey]
        );

        return {
            ...queryResult,
            queryKey,
            updateCachedData,
        };
    };
};
