import { RequestMutationOptions } from '@approvalmax/data';
import { useMutate } from 'shared/data';

import { requestsApiPaths } from '../paths';
import { ImportManualJournal } from './useImportManualJournal.types';

export const useImportManualJournal = (mutationOptions?: RequestMutationOptions<ImportManualJournal>) => {
    return useMutate(requestsApiPaths.importManualJournal, {
        mutationOptions,
        apiVersion: 'v1',
        method: 'post',
    });
};
