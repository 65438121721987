import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('components.IntegrationCard', {
    connect: 'Connect',
    learnMore: 'Learn more',

    xeroTitle: 'Approval workflows {br}for Xero',
    xeroTooltip: 'Connect to your Xero organisation',
    xeroDescription: `
        Connect to your Xero organisation to set up multi-step approval
        workflows for your Xero documents such as Xero Bills, Purchase Orders,
        AP Credit Notes, Sales Invoices, and Contacts.
    `,

    qboTitle: 'Approval workflows {br}for QuickBooks Online',
    qboTooltip: 'Connect to your QuickBooks Online organisation',
    qboDescription: `
        Connect to your QuickBooks Online organisation to set up multi-step
        approval workflows for QuickBooks Online Bills, Purchase Orders,
        Expenses, Vendors, and Journal Entries.
    `,

    netSuiteTitle: 'Approval workflows {br}for Oracle NetSuite',
    netSuiteTooltip: 'Connect to your Oracle NetSuite account',
    netSuiteDescription: `
        Connect to your Oracle NetSuite account to set up multi-step
        approval workflows for Oracle NetSuite Vendor Bills, Purchase Orders,
        Expense Reports, Sales Orders, and Vendor Return Authorizations.
    `,

    cin7Title: 'Approval workflows {br}for Cin7 Core',
    cin7Tooltip: 'Connect to your Cin7 Core account',
    cin7Description: `
        Connect to your Cin7 Core account in order to set up
        multi-step approval workflows for Cin7 Core Bills.
    `,
});
