import type { RequestMutationOptions } from '@approvalmax/data';
import { useMutate } from 'shared/data/api';

import { paymentServicesApiPaths } from '../paths';
import { ReconnectAmaxPayOpenBankingBanks } from './useReconnectAmaxPayOpenBankingBankAccounts.types';

export const useReconnectAmaxPayOpenBankAccounts = (
    mutationOptions?: RequestMutationOptions<ReconnectAmaxPayOpenBankingBanks>
) => {
    return useMutate(paymentServicesApiPaths.reconnectAmaxPayOpenBankingBankAccounts, {
        mutationOptions,
        method: 'post',
        apiVersion: 'v1',
    });
};
