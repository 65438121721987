import { RequestMutationOptions } from '@approvalmax/data';
import { useMutate } from 'shared/data';

import { requestsApiPaths } from '../paths';
import { CheckAmaxPayPaymentDetailsDiscrepancies } from './useCheckAmaxPayPaymentDetailsDiscrepancies.types';

export const useCheckAmaxPayPaymentDetailsDiscrepancies = (
    mutationOptions?: RequestMutationOptions<CheckAmaxPayPaymentDetailsDiscrepancies>
) => {
    return useMutate(requestsApiPaths.amaxPayPaymentDetailsDiscrepancies, {
        mutationOptions,
        apiVersion: 'v1',
    });
};
