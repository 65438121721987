import { zodResolver } from '@hookform/resolvers/zod';
import moment from 'moment';
import { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { usePayAmaxXero } from 'shared/data/webApp/v1';
import { getPath, Path } from 'urlBuilder';

import { getPaymentData } from './components/PaymentSummaryPopupContent/PaymentSummaryPopupContent.helpers';
import { useItems } from './components/PaymentSummaryPopupContent/PaymentSummaryPopupContent.hooks';
import { FormState } from './components/PaymentSummaryPopupContent/PaymentSummaryPopupContent.types';
import { getMaxDate, getMinDate } from './PayWithAmax.helpers';
import { schema } from './PayWithAmax.schema';
import { PaymentData, UsePayFormParams } from './PayWithAmax.types';

export const usePayForm = ({
    request,
    handleToggleShowPaymentSummary,
    toggleIsSchedulePaymentPopupOpened,
    setPaymentData,
    bankAccountBankName,
    isBatchPaymentAmountsV2,
    amounts,
}: UsePayFormParams) => {
    const { items, firstReadyToPayOfFailedItem } = useItems(request, isBatchPaymentAmountsV2, amounts);

    const { mutate: payAmaxXero, isLoading: isLoadingPayAmaxXero } = usePayAmaxXero();

    const form = useForm<FormState>({
        values: {
            selectedLineId: firstReadyToPayOfFailedItem?.id ?? null,
            scheduledDate: null,
        },
        resolver: zodResolver(schema),
    });

    const onSuccessPay = useCallback(
        (data: PaymentData) => {
            handleToggleShowPaymentSummary(false);
            toggleIsSchedulePaymentPopupOpened(false);

            setPaymentData(data);
        },
        [handleToggleShowPaymentSummary, setPaymentData, toggleIsSchedulePaymentPopupOpened]
    );

    const onSubmit = form.handleSubmit((data) => {
        const selectedLine = items.find((item) => item.id === data.selectedLineId);
        const { payeeName, paymentDetailsId, billInvoiceRequestIds } = getPaymentData(items, data) || {};

        if (!payeeName || !paymentDetailsId || !billInvoiceRequestIds) {
            return;
        }

        payAmaxXero(
            {
                body: {
                    billInvoiceRequestIds,
                    scheduledDate: data.scheduledDate?.toISOString(),
                    callbackUrl: `${window.location.protocol}//${window.location.host}${getPath(Path.amaxPaymentSuccess, `${window.location.pathname}${window.location.search}`)}`,
                },
                params: {
                    path: {
                        companyId: request.companyId,
                        requestId: request.id,
                    },
                },
            },
            {
                onSuccess: (payResult) =>
                    onSuccessPay({
                        ...payResult.data,
                        billInvoiceId: billInvoiceRequestIds[0],
                        bankName: bankAccountBankName,
                        paymentCurrency: selectedLine?.currency ?? '',
                        payeeName,
                        paymentTotal: selectedLine?.amount ?? 0,
                        scheduledDate: data.scheduledDate ?? undefined,
                        paymentDetailsId,
                    }),
            }
        );
    });

    form.register('scheduledDate', {
        onChange: (event) => {
            if (event.target.value) {
                if (moment().add(2, 'days').isAfter(event.target.value)) {
                    form.setValue('scheduledDate', getMinDate());
                } else if (moment(getMaxDate()).isBefore(event.target.value)) {
                    form.setValue('scheduledDate', getMaxDate());
                }
            }
        },
    });

    return {
        form,
        isPayBillLoading: isLoadingPayAmaxXero,
        onSubmit,
    };
};
