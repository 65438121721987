import { Popup } from '@approvalmax/ui/src/components';
import { useAtom } from 'jotai';
import { FC, memo, useCallback, useEffect } from 'react';
import { amplitudeService } from 'services/amplitude';

import { PopupContent } from './components';
import { subscribeAfterTrialPopupOpenState } from './SubscribeAfterTrialPopup.states';
import { SubscribeAfterTrialPopupProps } from './SubscribeAfterTrialPopup.types';

export const SubscribeAfterTrialPopup: FC<SubscribeAfterTrialPopupProps> = memo((props) => {
    const { companyId, profileId, accountId } = props;

    const [open, setOpen] = useAtom(subscribeAfterTrialPopupOpenState);

    useEffect(() => {
        if (open) {
            amplitudeService.sendData('billing: land on trial expiration pop-up after URL changes', {
                user_id: profileId,
                org_id: companyId,
            });
        }
    }, [open, profileId, companyId]);

    const handleClose = useCallback(() => {
        setOpen(false);
    }, [setOpen]);

    return (
        <Popup open={open} onToggle={setOpen} size='large'>
            <PopupContent accountId={accountId} companyId={companyId} onClose={handleClose} />
        </Popup>
    );
});

SubscribeAfterTrialPopup.displayName = 'SubscribeAfterTrialPopup';
