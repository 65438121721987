import { Box, Text, Tooltip } from '@approvalmax/ui/src/components';
import { FC, isValidElement } from 'react';

import { Container } from './WarningIndicator.styles';
import { WarningIndicatorProps } from './WarningIndicator.types';

/**
 * @deprecated Use redesigned version {@link WarningTooltip}
 *
 * There are some changes in props to keep component interface more consistent to Tooltip component
 * 1. content prop is replaced with "children"
 * 2. children prop is replaced with "activator"
 * 3. show prop is replaced with "open"
 *
 * Please double-check when use this component
 */
export const WarningIndicator: FC<WarningIndicatorProps> = (props) => {
    const { show, content, children } = props;

    return (
        <Tooltip disabled={!show} placement='top' width='max-content' activator={<Container>{children}</Container>}>
            {isValidElement(content) ? (
                content
            ) : (
                <Box spacing='8'>
                    <Text font='body' fontSize='small'>
                        {content}
                    </Text>
                </Box>
            )}
        </Tooltip>
    );
};

WarningIndicator.displayName = 'WarningIndicator';
