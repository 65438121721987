import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('modules.common.MonthPickerEditor', {
    monthJanuary: 'January',
    monthFebruary: 'February',
    monthMarch: 'March',
    monthApril: 'April',
    monthMay: 'May',
    monthJune: 'June',
    monthJuly: 'July',
    monthAugust: 'August',
    monthSeptember: 'September',
    monthOctober: 'October',
    monthNovember: 'November',
    monthDecember: 'December',
    placeholder: '1 month',
});
