import { RequestMutationOptions } from '@approvalmax/data';
import { useMutate } from 'shared/data';

import { authApiPaths } from '../paths';
import { FinishTwoFaEnablingWithBackupCodes } from './useFinishTwoFaEnablingWithBackupCodes.types';

export const useFinishTwoFaEnablingWithBackupCodes = (
    mutationOptions?: RequestMutationOptions<FinishTwoFaEnablingWithBackupCodes>
) => {
    return useMutate(authApiPaths.finishTwoFaEnablingWithBackupCodes, {
        mutationOptions,
        apiVersion: 'v1',
    });
};
