import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('pages.RequestList.AirwallexBatchPaymentRequestCard.ScheduledPaymentSection', {
    title: 'Scheduled payment',
    cancel: 'cancel',
    edit: 'edit',
    paymentDate: 'Payment date',
    paymentTime: 'Payment time',
    confirmCancellation: 'Confirm cancellation',
    cancelButtonText: 'Discard Changes',
    cancellationPopupTitle: 'Cancel scheduled payment',
    cancellationPopupDescription: 'Please confirm the cancellation of the payment scheduled for {date} at {time}.',
    failedText:
        'Please note that the batch failed to be sent at the scheduled time. You can check the audit trail below for the specific reason. {learnMoreLink}',
    twoFaIsNotEnabled: '2FA is not enabled',
});
