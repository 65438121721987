import { Collapse, Grid, Table, TableCheckType, Text } from '@approvalmax/ui/src/components';
import { errorHelpers } from '@approvalmax/utils';
import { selectors } from 'modules/common';
import { domain } from 'modules/data';
import { useSelector } from 'modules/react-redux';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';

import { getColumnDefinitions } from '../../QBooksMatchingPopup.config';
import { checkboxColumnCover, createLinkedColumn } from './MatchingLineItems.constants';
import { LineItemType, MatchingLineItemsProps } from './MatchingLineItems.types';

export const MatchingLineItems = memo<MatchingLineItemsProps>((props) => {
    const { purchaseOrder, selectedLineItemId, setSelectedLineItemId, requestId } = props;

    const [checkedItems, setCheckedItems] = useState<string[]>([]);

    const request = useSelector((state) =>
        selectors.request.getRequestById<
            selectors.types.QBooksBillExpandedRequest | selectors.types.QBooksPoExpandedRequest
        >(state, requestId)
    );

    if (request.integrationType !== domain.IntegrationType.QBooks) {
        throw errorHelpers.invalidOperationError();
    }

    const isAccountLineItem = purchaseOrder.accountLineItems.length > 0;

    const columnDefinitions = useMemo(() => {
        return [...getColumnDefinitions(request, isAccountLineItem), createLinkedColumn];
    }, [isAccountLineItem, request]);

    const tableData = isAccountLineItem ? purchaseOrder.accountLineItems : purchaseOrder.lineItems;

    const onCheckedItemsChange = useCallback(
        (checkedItems: string[]) => {
            setCheckedItems((prevState) => {
                const prevSet = new Set(prevState);
                const newCheckedItem = checkedItems.find((item) => !prevSet.has(item));

                if (newCheckedItem) {
                    setSelectedLineItemId(newCheckedItem);

                    return [newCheckedItem];
                }

                return [];
            });
        },
        [setSelectedLineItemId]
    );

    const getIsDisabledRow = useCallback((item: LineItemType) => {
        const remainingBalance = item.remainingBalance || 0;

        return remainingBalance <= 0;
    }, []);

    useEffect(() => {
        if (checkedItems.length && selectedLineItemId && checkedItems[0] !== selectedLineItemId) {
            setCheckedItems([]);
        }
    }, [checkedItems, selectedLineItemId]);

    return (
        <Collapse
            name={purchaseOrder.name}
            headerContent={
                <Grid gridTemplateColumns='auto 240px' gap={12} alignItems='center'>
                    <Text font='label' fontSize='large' color='midnight80' spacing='0 12'>
                        {purchaseOrder.date}
                    </Text>

                    <Text font='label' fontSize='large' color='midnight80' textAlign='right'>
                        {purchaseOrder.amount}
                    </Text>
                </Grid>
            }
            collapsed
            color='white100'
            spacing='0 0 8 24'
        >
            <Table
                columns={columnDefinitions}
                data={tableData}
                hideBorderLastRow
                headerColor='transparent'
                hideEmptyColumns
                checkedItems={checkedItems}
                onCheckedItemsChange={onCheckedItemsChange}
                checkType={TableCheckType.radio}
                checkboxColumnCover={checkboxColumnCover}
                hoverable={false}
                getIsDisabledRow={getIsDisabledRow}
                columnSpacing='8'
            />
        </Collapse>
    );
});

MatchingLineItems.displayName = 'MatchingLineItems';
