import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('app.WorkflowIdPage.WorkflowSettingsPopupContent', {
    title: 'Workflow settings',
    saveButton: 'Save changes',
    saveButtonTitle: 'Save the {templateName} workflow settings',
    requiredFieldsNotification: 'Please fill in all required fields.',
    generalTab: 'General',
    matchingTab: 'Matching',
    fraudDetectionTab: 'Fraud Detection',
    emailToSupplierTab: 'Email to Supplier',
    emailToVendorTab: 'Email to Vendor',
    emailToCustomerTab: 'Email to Customer',
    captureTab: 'Capture',
});
