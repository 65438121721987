import './licenseOnGracePopup.scss';

import { Button, Popup, TransparentButton, usePopupContext, WarningAmImage } from '@approvalmax/ui';
import { CloseIcon } from 'modules/sprites';
import { FC, memo } from 'react';
import bemFactory from 'react-bem-factory';
import { useSelector } from 'react-redux';
import { AccountPath, getAccountUrl } from 'urlBuilder';

import { selectors } from '..';
import { ExpandedSubscription } from '../selectors/types';
import { messages } from './LicenseOnGracePopup.messages';

const bem = bemFactory.block('common-ui-license-on-grace-popup');

interface LicenseOnGracePopupProps {
    subscriptions: ExpandedSubscription[];
    className?: string;
}

const LicenseOnGracePopup: FC<LicenseOnGracePopupProps> = (props) => {
    const { className, subscriptions } = props;

    const { onRequestClose } = usePopupContext();

    const me = useSelector(selectors.profile.findProfileUser);

    if (!me) {
        return null;
    }

    const myGraceSubscriptions = subscriptions.filter((l) => l.accountOwnerId === me.id);
    const adminGraceSubscriptions = subscriptions.filter((l) => l.accountOwnerId !== me.id);

    return (
        <Popup.EmptyContent className={bem.add(className)()}>
            <div className={bem('head')}>
                <TransparentButton execute={onRequestClose}>
                    <CloseIcon className={bem('close-icon')} />
                </TransparentButton>

                <WarningAmImage size={52} />

                <div className={bem('title')}>
                    {messages.titleText({
                        br: <br />,
                    })}
                </div>
            </div>

            <div className={bem('body')}>
                <div className={bem('action-section')}>{messages.organisationsSection}</div>

                <div className={bem('action-list')}>
                    {myGraceSubscriptions.length > 0 &&
                        myGraceSubscriptions.map((l) =>
                            l.companies.map((c) => (
                                <div key={c.id} className={bem('action-item')}>
                                    <div className={bem('action-item-text')}>{c.displayName}</div>

                                    <a
                                        className={bem('action-item-link')}
                                        href={getAccountUrl(AccountPath.subscriptions, {
                                            origin: 'wa_license_on_grace_popup',
                                        })}
                                    >
                                        <Button>{messages.goToAccountButton}</Button>
                                    </a>
                                </div>
                            ))
                        )}

                    {adminGraceSubscriptions.length > 0 &&
                        adminGraceSubscriptions.map((l) =>
                            l.companies.map((c) => (
                                <div key={c.id} className={bem('action-item')}>
                                    <div className={bem('action-item-text')}>{c.displayName}</div>

                                    <a className={bem('action-item-link')} href={`mailto:${l.accountOwner.userEmail}`}>
                                        <Button theme='secondary'>{messages.contactOwnerButton}</Button>
                                    </a>
                                </div>
                            ))
                        )}
                </div>
            </div>
        </Popup.EmptyContent>
    );
};

export default memo(LicenseOnGracePopup);
