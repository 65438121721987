import { hooks } from '@approvalmax/utils';
import {
    forwardRef,
    ForwardRefExoticComponent,
    memo,
    MemoExoticComponent,
    RefAttributes,
    useMemo,
    useRef,
} from 'react';
import { NumericFormat } from 'react-number-format';

import TextField from '../TextField/TextField';
import { Controller } from './components';
import { getAllowFloat } from './NumberField.helpers';
import { useKeyEvents, useValue } from './NumberField.hooks';
import { ChildrenComponents, HTMLNumberFieldElement, NumberFieldProps } from './NumberField.types';

/**
 * Number Fields let users enter and edit number.
 */
const NumberField = memo(
    forwardRef((props, ref) => {
        const {
            value,
            onChange,
            changeOnBlur = true,
            precision: precisionProp,
            allowCalculation,
            allowFloat,
            min = 0,
            max = 100_000_000_000_000,
            onEnter,
            resetIfEvaluatedValueExceedsLimit,
            onBlur,
            displayFormat,
            ...restProps
        } = props;

        const fieldRef = useRef<HTMLNumberFieldElement>(null);
        const composedRefs = hooks.useComposedRefs(ref, fieldRef);
        const precision = displayFormat === 'currency' ? 2 : precisionProp;

        const { fieldValue, handleChange, handleBlur } = useValue({
            value,
            onChange,
            changeOnBlur,
            precision,
            allowCalculation,
            allowFloat,
            min,
            max,
            resetIfEvaluatedValueExceedsLimit,
            onBlur,
        });
        const { handleKeyPress, handleKeyDown } = useKeyEvents({ fieldRef, onEnter, allowCalculation });

        const inputMode = useMemo(
            () => (getAllowFloat({ allowFloat, precision }) ? ('decimal' as const) : ('numeric' as const)),
            [allowFloat, precision]
        );

        const fieldProps = useMemo(
            () => ({
                value: fieldValue,
                onKeyPress: handleKeyPress,
                onKeyDown: handleKeyDown,
                onChange: handleChange,
                onBlur: handleBlur,
                inputMode,
            }),
            [fieldValue, handleKeyPress, handleKeyDown, handleChange, handleBlur, inputMode]
        );

        return displayFormat && displayFormat !== 'as-is' ? (
            <TextField
                {...restProps}
                as={NumericFormat}
                inputRef={composedRefs}
                {...fieldProps}
                fixedDecimalScale={displayFormat === 'currency'}
                decimalScale={displayFormat === 'currency' ? 2 : precision}
                thousandSeparator=','
            />
        ) : (
            <TextField {...restProps} inputRef={composedRefs} {...fieldProps} />
        );
    })
) as MemoExoticComponent<ForwardRefExoticComponent<NumberFieldProps & RefAttributes<HTMLNumberFieldElement>>> &
    ChildrenComponents;

NumberField.Controller = Controller;

export default NumberField;
