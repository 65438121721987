import { RequestMutationOptions } from '@approvalmax/data';
import { useMutate } from 'shared/data';

import { requestsApiPaths } from '../paths';
import { ChangeWatchers } from './useChangeWatchers.types';

export const useChangeWatchers = (mutationOptions?: RequestMutationOptions<ChangeWatchers>) => {
    return useMutate(requestsApiPaths.changeWatchers, {
        mutationOptions,
        apiVersion: 'v1',
        method: 'post',
    });
};
