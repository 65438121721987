import { CloseIcon, LockIcon } from '@approvalmax/ui';
import { Box, Button, Flex, Menu, pinDrawerState, Text } from '@approvalmax/ui/src/components';
import { useAtomValue } from 'jotai';
import { selectors } from 'modules/common';
import { domain } from 'modules/data';
import { actions } from 'modules/profile';
import { useDispatch, useSelector } from 'modules/react-redux';
import { FC, memo, useCallback, useMemo } from 'react';
import { isAddonsCanBeAddedToSubscription } from 'shared/helpers/addons';

import { useClosePromotion } from './Promotion.hooks';
import { messages } from './Promotion.messages';
import { PromotionProps } from './Promotion.types';

export const Promotion: FC<PromotionProps> = memo((props) => {
    const { company } = props;

    const subscription = useSelector((state) => selectors.subscription.findCompanySubscription(state, company.id));
    const isDrawerPinned = useAtomValue(pinDrawerState);
    const dispatch = useDispatch();
    const advancedFeatures = selectors.company.getFeaturesByProductPlan(
        company.licenseProductPlan,
        company.integration?.countryCode
    );

    const canBuyAnyAddon = useMemo(() => {
        const allowedAddons = [domain.AddonTypes.Capture, domain.AddonTypes.Pay].filter((addonType) => {
            return isAddonsCanBeAddedToSubscription({
                addonType,
                hasAddon: Boolean(subscription?.addonStatistics.some(({ feature }) => feature === addonType)),
                billingSystem: subscription?.billingSystem,
                integrationType: company.integration?.integrationType || domain.IntegrationType.None,
                currency: subscription?.nextBillingCurrency ?? undefined,
            });
        });

        return Boolean(allowedAddons.length);
    }, [
        company.integration?.integrationType,
        subscription?.addonStatistics,
        subscription?.billingSystem,
        subscription?.nextBillingCurrency,
    ]);

    const tryNow = useCallback(() => {
        dispatch(actions.openConsentToStartTrialPopup(company, 'banner_in_drawer'));
    }, [company, dispatch]);

    const { isClosed, close } = useClosePromotion(company.id);

    if (
        (!advancedFeatures.length && !canBuyAnyAddon) ||
        company.allFeaturesTrialStatus !== domain.AllFeaturesTrialStatus.AwaitingActivation ||
        isClosed
    ) {
        return null;
    }

    return (
        <Box spacing='0' color={isDrawerPinned ? 'white100' : 'silver80'} radius='xsmall'>
            <Box spacing='16 12'>
                <Flex spacing='8' alignItems='center' justifyContent='space-between'>
                    <Text font='headline' fontSize='xxsmall' fontWeight='medium' as='h4'>
                        {messages.heading}
                    </Text>

                    <Button onClick={close} icon variant='text' color='blue100'>
                        <CloseIcon size={20} />
                    </Button>
                </Flex>
            </Box>

            <Menu>
                {advancedFeatures.map(({ label, icon: Icon, href }) => (
                    <Menu.Item
                        name={label}
                        startIcon={<Icon size={24} color='midnight70' />}
                        disabled
                        as='a'
                        href={href}
                        endIcon={<LockIcon size={16} color='midnight100' />}
                        key={label}
                    />
                ))}
            </Menu>

            <Box spacing='16 12'>
                <Box color={isDrawerPinned ? 'silver80' : 'white100'} spacing='8' radius='xsmall'>
                    <Flex container spacing='8' alignItems='center' justifyContent='space-between' wrap={false}>
                        <Text font='label' fontSize='small'>
                            {messages.tryThemFor14DaysForFree}
                        </Text>

                        <Flex shrink={0}>
                            <Button color='blue80' size='small' onClick={tryNow}>
                                {messages.tryNow}
                            </Button>
                        </Flex>
                    </Flex>
                </Box>
            </Box>
        </Box>
    );
});

Promotion.displayName = 'Promotion';
