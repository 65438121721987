import { selectors } from 'modules/common';

import { WorkflowSettingsPopupSectionProps } from '../../WorkflowSettingsPopupContent.types';

export type SectionXeroBillMatchingProps = {
    company: selectors.types.ExpandedCompany;
} & (
    | WorkflowSettingsPopupSectionProps
    | {
          promoView: true;
      }
);

export enum RetrospectivePoMatchingOption {
    True = 'True',
    False = 'False',
}
