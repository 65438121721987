import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('modules.common.integrationSelectors', {
    xeroIntegrationDisplayName: 'Xero',
    qbooksIntegrationDisplayName: 'QuickBooks Online',
    netsuiteIntegrationDisplayName: 'Oracle NetSuite',
    dearIntegrationDisplayName: 'Cin7 Core',
});

export const xeroMessages = defineMessages('modules.common.integrationSelectors.xero', {
    errorText5003: 'Connection error: OAuth token expired. Please reconnect to Xero again.',
    errorText5004: 'Connection error: OAuth token is not authorized. Please reconnect.',
    errorText5006: 'Connection error: Request token key does not match. Please reconnect to Xero again.',
    errorText5013: 'Connection error: Xero API call rate exceeded. Please retry in a minute.',
    errorText5014: 'Connection error: OAuth token rejected. Please reconnect to Xero again.',
    errorText5015: 'Connection error: General integration authentication error. Please reconnect to Xero again.',
    errorText4050: 'Connection error: Generic error during integration',
    errorTextGeneric: 'Connection error: Generic error in Xero connection',
});

export const qbooksMessages = defineMessages('modules.common.integrationSelectors.qbooks', {
    errorTextGeneric: 'Connection error: Generic error in QuickBooks connection',
});

export const dearMessages = defineMessages('modules.common.integrationSelectors.dear', {
    errorText7504: 'Connection error: Invalid login attempt',
    integrationGenericErrorText: 'Connection error: Generic error in Cin7 Core connection',
});

export const netSuiteMessages = defineMessages('modules.common.integrationSelectors.netsuite', {
    errorText7504: 'Connection error: Invalid login attempt',
    integrationGenericErrorText: 'Connection error: Generic error in Oracle NetSuite connection',
});
