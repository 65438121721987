import { ExtractComponentProp } from '@approvalmax/types';
import { Dropzone, Spacing, Text, toast } from '@approvalmax/ui/src/components';
import { schemas } from 'modules/data';
import { FC, memo, useCallback } from 'react';
import { commonErrorMessages } from 'services/error/messages';
import { useUploadAttachment } from 'shared/data/webApp/v1';
import { handleFileUploadLimits, isPdfFileProtected, isValidOcrImage } from 'shared/helpers';

import { messages } from './UploadFiles.messages';
import { UploadFilesProps } from './UploadFiles.types';

export const UploadFiles: FC<UploadFilesProps> = memo((props) => {
    const { attachments, setAttachments, disabled, accept, maxSize } = props;

    const { mutateAsync: uploadAttachment } = useUploadAttachment();

    const onUpload = useCallback<ExtractComponentProp<typeof Dropzone, 'onUpload'>>(
        async (file) => {
            if (file.source.type === 'application/pdf') {
                const isProtected = await isPdfFileProtected(file.source);

                if (isProtected) {
                    toast.info(commonErrorMessages.pdfFileProtectedError({ fileName: file.source.name }));
                }
            }

            if (file.source.type.startsWith('image/')) {
                const url = URL.createObjectURL(file.source);
                const isValidImage = await isValidOcrImage(url);

                if (!isValidImage) {
                    throw new Error(commonErrorMessages.ocrImageResolutionError({ fileName: file.source.name }));
                }

                URL.revokeObjectURL(url);
            }

            const formData = new FormData();

            formData.append('file', file.source);

            const response = await uploadAttachment({ body: formData });

            const attachments = response.Attachments.map(schemas.request.mapAttachment);

            setAttachments((prevAttachments) => [...prevAttachments, { innerId: file.id, ...attachments[0] }]);
        },
        [setAttachments, uploadAttachment]
    );

    const onRemove = useCallback<ExtractComponentProp<typeof Dropzone, 'onRemove'>>(
        (file) => {
            setAttachments((attachments) => attachments.filter((attachment) => attachment.id !== file.id));
        },
        [setAttachments]
    );

    return (
        <>
            <Text font='headline' fontSize='xsmall' fontWeight='medium'>
                {messages.uploadFilesTitle}
            </Text>

            <Spacing height={16} />

            <Dropzone
                accept={accept}
                multiple
                disabled={disabled}
                onRemove={onRemove}
                onUpload={onUpload}
                filterFiles={handleFileUploadLimits}
                value={attachments}
                maxFilesInList={30}
                noDuplicateNames
                maxSize={maxSize}
            />
        </>
    );
});

UploadFiles.displayName = 'UploadFiles';
