import { memo, useState } from 'react';

import FixedStepContainer from '../FixedStepContainer/FixedStepContainer';
import { StepSubmitterSection } from '../StepSubmitterSection/StepSubmitterSection';
import { messages } from './StandaloneStartStep.messages';

const StandaloneStartStep = memo(() => {
    const [collapsed, setCollapsed] = useState<boolean>();

    return (
        <FixedStepContainer bubbleText={messages.bubbleText} color='green80'>
            <StepSubmitterSection
                headerText={messages.createInApprovalMax}
                cacheId='XeroQuoteStartStepSubmitters'
                description={messages.description}
                required
                noMatrix
                collapsed={collapsed}
                onCollapse={setCollapsed}
            />
        </FixedStepContainer>
    );
});

export default StandaloneStartStep;
