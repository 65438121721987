import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('app.WorkflowIdPage.PromotionOfMigrationFromReviewerV1', {
    title: 'Important update',
    descriptionPartOne:
        'We’re replacing the old Reviewer feature with the new {url} feature to improve how you manage approvals.',
    descriptionLink: 'Editing on Approval',
    descriptionPartTwo: 'To keep using this functionality, use the migration tool to transition to the new feature.',
    migrateNow: 'Start Migration',
    popupDescription: 'To continue with migration, you need to rather save the changes or discard them.',
    confirmButtonText: 'Discard changes',
    cancelButtonText: 'Continue editing',
});
