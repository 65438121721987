import { Guid } from '@approvalmax/types';
import { errorHelpers } from '@approvalmax/utils';
import { backend } from 'modules/data';

import { OAuthProvider } from './OAuth';

export enum IntegrationCode {
    DearPo = 'Dear-PO',
    NetSuiteBill = 'NetSuite-vendor-bill',
    NetSuiteBillPayment = 'NetSuite-bill-payment',
    NetSuiteExpenseReport = 'NetSuite-expense-report',
    NetSuitePO = 'NetSuite-PO',
    NetSuiteRA = 'NetSuite-return-authorization',
    NetSuiteSalesOrder = 'NetSuite-sales-order',
    NetSuiteInvoice = 'NetSuite-invoice',
    NetSuiteVRA = 'NetSuite-vendor-return-authorization',
    NetSuiteJournalEntry = 'NetSuite-journal-entry',
    NetSuiteVendor = 'NetSuite-vendor',
    QBooksBill = 'QBooks-bill',
    QBooksExpense = 'QBooks-expense',
    QBooksJournalEntry = 'QBooks-journal-entry',
    QBooksPo = 'QBooks-PO',
    QBooksVendor = 'QBooks-vendor',
    QBooksInvoice = 'QBooks-invoice',
    XeroAirwallexBatchPayment = 'Airwallex-xero-batch-payment',
    XeroAmaxPayBatchPayment = 'AmaxPay-xero-batch-payment',
    XeroBill = 'Xero-bill',
    XeroBillBatchPayment = 'Xero-bill-batch-payment',
    XeroContact = 'Xero-contact',
    XeroCreditNotesPayable = 'Xero-creditnotes-payable',
    XeroCreditNotesReceivable = 'Xero-creditnotes-receivable',
    XeroInvoice = 'Xero-invoice',
    XeroManualJournal = 'Xero-manual-journal',
    XeroPo = 'Xero-PO',
    XeroQuote = 'Xero-quote',
}

export enum IntegrationType {
    Xero = 'Xero',
    QBooks = 'QBooks',
    NetSuite = 'NetSuite',
    Dear = 'Dear',
    None = 'None',
}

export enum IntegrationStatus {
    Connecting = 'Connecting',
    ConnectingFinalizing = 'ConnectingFinalizing',
    Connected = 'Connected',
    Disconnecting = 'Disconnecting',
    Disconnected = 'Disconnected',
}

export enum IntegrationCacheType {
    XeroOrganizations = 'XeroOrganizations',
    XeroItems = 'XeroItems',
    XeroAccounts = 'XeroAccounts',
    XeroTaxRates = 'XeroTaxRates',
    XeroTrackingCategories = 'XeroTrackingCategories',
    XeroBrandingThemes = 'XeroBrandingThemes',
    XeroCurrencies = 'XeroCurrencies',
    XeroCounterparties = 'XeroCounterparties',
}

export interface XeroTenantInfo {
    tenantId: string;
    tenantName: string;
    connectedOrgName?: string;
    connectedIntegrationId?: string;
    isConnected: boolean;
    isMember: boolean;
    isManager: boolean;
    owner?: string;
}

export interface IntegrationCacheItem {
    cacheType: IntegrationCacheType;
    lastStartDate: string | null;
    lastEndDate: string | null;
    loadingInProgress: boolean;
    hasSomeData?: boolean;
}

export enum IntegrationSyncStatus {
    Done = 'Done',
    InProgress = 'InProgress',
    Error = 'Error',
}

export interface IntegrationTemplateSyncProgress {
    templateId: Guid;
    templateIntegrationCode: IntegrationCode;
    status: IntegrationSyncStatus;
    lastSyncDate?: string;
}

export interface Integration {
    id: Guid;
    companyId: Guid;
    integrationType: IntegrationType;
    integratedCompanyId: Guid;
    integratedCompanyName: string;
    externalUrl: string;
    createdDate: string;
    modifiedDate: string;
    lockDate: string;
    status: IntegrationStatus;
    isNew: boolean;
    firstTimeConnection: boolean;
    errorStatusCode?: number;
    errorStatusMessage?: string;
    cacheItems?: IntegrationCacheItem[];
    isBudgetSyncEnabled: boolean;
    isXeroDemo: boolean;
    countryCode: backend.OrganisationVersion;
    financialYearEnd?:
        | {
              day: number;
              month: number;
          }
        | string;
}

export interface ExtendedIntegration extends Integration {
    syncProgress: IntegrationTemplateSyncProgress[];
}

export function getIntegrationTypeByCode(integrationCode: IntegrationCode | null): IntegrationType {
    switch (integrationCode) {
        case IntegrationCode.XeroBill:
        case IntegrationCode.XeroCreditNotesPayable:
        case IntegrationCode.XeroCreditNotesReceivable:
        case IntegrationCode.XeroInvoice:
        case IntegrationCode.XeroPo:
        case IntegrationCode.XeroContact:
        case IntegrationCode.XeroQuote:
        case IntegrationCode.XeroBillBatchPayment:
        case IntegrationCode.XeroAirwallexBatchPayment:
        case IntegrationCode.XeroManualJournal:
        case IntegrationCode.XeroAmaxPayBatchPayment:
            return IntegrationType.Xero;

        case IntegrationCode.QBooksPo:
        case IntegrationCode.QBooksBill:
        case IntegrationCode.QBooksExpense:
        case IntegrationCode.QBooksVendor:
        case IntegrationCode.QBooksJournalEntry:
        case IntegrationCode.QBooksInvoice:
            return IntegrationType.QBooks;

        case IntegrationCode.NetSuiteBill:
        case IntegrationCode.NetSuitePO:
        case IntegrationCode.NetSuiteSalesOrder:
        case IntegrationCode.NetSuiteInvoice:
        case IntegrationCode.NetSuiteExpenseReport:
        case IntegrationCode.NetSuiteVRA:
        case IntegrationCode.NetSuiteBillPayment:
        case IntegrationCode.NetSuiteRA:
        case IntegrationCode.NetSuiteJournalEntry:
        case IntegrationCode.NetSuiteVendor:
            return IntegrationType.NetSuite;

        case IntegrationCode.DearPo:
            return IntegrationType.Dear;

        case null:
            return IntegrationType.None;

        default:
            throw errorHelpers.assertNever(integrationCode);
    }
}

export function getIntegrationTypeByOAuthProvider(oauthProvider: OAuthProvider): IntegrationType | null {
    switch (oauthProvider) {
        case OAuthProvider.Xero:
            return IntegrationType.Xero;

        case OAuthProvider.QBooks:
            return IntegrationType.QBooks;

        case OAuthProvider.Google:
            return null;

        case OAuthProvider.Microsoft:
            return null;

        default:
            throw errorHelpers.assertNever(oauthProvider);
    }
}
