import { toast } from '@approvalmax/ui/src/components';
import { zodResolver } from '@hookform/resolvers/zod';
import { selectors } from 'modules/common';
import { actions } from 'modules/profile';
import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useSetPassword, useUpdatePassword } from 'shared/data/webApp/v1';
import { z } from 'zod';

import { fieldNamePasswordForm } from './SubsectionPassword.constants';
import { messages } from './SubsectionPassword.messages';
import { schemaPasswordForm } from './SubsectionPassword.schema';

export const useSubsectionPassword = (profile: selectors.types.ExpandedProfile) => {
    const dispatch = useDispatch();
    const [isEditing, setIsEditing] = useState(false);

    const form = useForm<z.infer<ReturnType<typeof schemaPasswordForm>>>({
        values: {
            [fieldNamePasswordForm.oldPassword]: profile.isPasswordSet ? '' : null,
            [fieldNamePasswordForm.newPassword]: '',
        },
        resolver: zodResolver(schemaPasswordForm(profile.isPasswordSet)),
    });

    const handleShowForm = useCallback(() => {
        setIsEditing(true);
    }, []);

    const handleHideForm = useCallback(() => {
        setIsEditing(false);
        form.reset();
    }, [form]);

    const onSuccess = useCallback(() => {
        toast.success(messages.successText);
        dispatch(actions.changePasswordIsSet);
        handleHideForm();
    }, [dispatch, handleHideForm]);

    const { mutate: setPassword, isLoading: isLoadingSetPassword } = useSetPassword({ onSuccess });
    const { mutate: updatePassword, isLoading: isLoadingUpdatePassword } = useUpdatePassword({ onSuccess });

    const formSubmit = form.handleSubmit((data) => {
        if (profile.isPasswordSet && data.oldPassword) {
            updatePassword({
                body: {
                    oldPwd: data.oldPassword,
                    newPwd: data.newPassword,
                },
            });
        } else {
            setPassword({
                body: {
                    pwd: data.newPassword,
                    timeZoneForEmail: profile.timeZoneForEmail,
                },
            });
        }
    });

    return {
        form,
        formSubmit,
        isEditing,
        isLoading: isLoadingSetPassword || isLoadingUpdatePassword,
        handleShowForm,
        handleHideForm,
    };
};
