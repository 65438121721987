import { ExternalLink } from '@approvalmax/ui';
import { actions, selectors } from 'modules/common';
import { BubbleCloseIcon } from 'modules/sprites';
import { QBOOKS_MATCHING_HELP_ITEM_ID } from 'pages/requestList/constants';
import { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { messages } from './MatchingPopupLinks.messages';
import { CloseBtn, CloseBtnContainer, Container, StyledVelocityTransitionGroup } from './MatchingPopupLinks.styles';

const QBOOKS_MATCHING_URL =
    'https://support.approvalmax.com/portal/en/kb/approvalmax-1/approvalmax-for-quickbooks-online/matching';
const QBOOKS_MATCHING_VIDEO_URL = 'https://youtu.be/nKAXnNhFGpc';

export const MatchingPopupLinks = memo(() => {
    const dispatch = useDispatch();

    const hiddenHelpItems = useSelector(selectors.userPreferences.getHiddenHelpItems);

    const showHelpSection = !hiddenHelpItems.includes(QBOOKS_MATCHING_HELP_ITEM_ID);

    const onHide = () => {
        dispatch(actions.updateHiddenHelpItem(QBOOKS_MATCHING_HELP_ITEM_ID, true));
    };

    return (
        <StyledVelocityTransitionGroup
            enter={{ animation: 'slideDown', duration: 300 }}
            leave={{ animation: 'slideUp', duration: 300 }}
        >
            {showHelpSection && (
                <Container>
                    {messages.learMoreText({
                        learnMoreLink: (
                            <ExternalLink href={QBOOKS_MATCHING_URL}>{messages.learnMoreLinkText}</ExternalLink>
                        ),
                    })}

                    <br />

                    {messages.videoText({
                        videoLink: <ExternalLink href={QBOOKS_MATCHING_VIDEO_URL}>{messages.videoUrl}</ExternalLink>,
                    })}

                    <CloseBtnContainer>
                        <CloseBtn execute={onHide}>
                            <BubbleCloseIcon width={9} height={9} />
                        </CloseBtn>
                    </CloseBtnContainer>
                </Container>
            )}
        </StyledVelocityTransitionGroup>
    );
});

MatchingPopupLinks.displayName = 'MatchingPopupLinks';
