import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('pages.RequestList.XeroBilledStatusSection', {
    sectionHeaderText: 'Billed status',
    unmarkAsBilledMenuItemText: 'Unmark as Billed',
    billedStatus: 'Billed',
    markAsBilled: 'Mark as billed',
    markAsBilledButtonTitle: 'Mark this Purchase Order as Billed',
    unmarkAsBilledMenuItemTitle: 'Unmark this Purchase Order as Billed',
});
