import { intl } from '@approvalmax/utils';
import { selectors } from 'modules/common';
import { font } from 'modules/styles';
import { FC, memo, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { getActiveRequest } from '../../../../../../../selectors/pageSelectors';
import { BudgetBreakdown } from '../../../../XeroBudgetingSection.types';
import { messages } from './BreakDownsSection.messages';

const BreakDownsContainer = styled.div`
    margin-top: 35px;
    margin-bottom: 40px;
`;

const Header = styled.div`
    min-height: 32px;
    overflow-x: auto;
    background-color: #f5f5f5;
`;

const HeaderSections = styled.div`
    display: flex;
    align-items: center;
`;

const HeaderDivider = styled.div`
    width: 100%;
    height: 1px;
    background-color: #cacccd;
`;

const SectionName = styled.button<{ selected: boolean }>`
    height: 100%;
    padding: 6px 16px;
    border-bottom: 4px solid transparent;
    ${font(12, '#6c6a6a')}
    white-space: nowrap;
    cursor: pointer;

    ${(props) =>
        props.selected
            ? `
        color: #4c8a5c;
        border-bottom-color: #4c8a5c;
    `
            : ''}
`;

const Body = styled.div`
    display: flex;
    flex-wrap: wrap;
    padding: 30px 0;
    background-color: #f5f5f5;
`;

const Column = styled.div<{ noBorder?: boolean }>`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    flex: 1;
    min-height: 80px;
    padding: 0 30px;
    border-right: 1px solid #cacccd;

    ${(props) => (props.noBorder ? 'border: none;' : '')}
`;

const ColumnType = styled.div`
    width: 100%;
    ${font(15, '#000', 'semibold')}
    text-align: center;
`;

const HalfColumn = styled.div`
    width: 48%;
`;

const ValueName = styled.div`
    ${font(12, '#384650')}
    text-align: center;
    margin-bottom: 5px;
`;

const Value = styled.div`
    ${font(14, '#000', 'semibold')}
    text-align: center;
`;

const Foooter = styled.div`
    padding: 15px;
`;

const Note = styled.div`
    ${font(11, '#6c6a6a', 'semibold')}
`;

const FullWidthColumn = styled.div`
    width: 100%;
    padding: 10px 30px 0;
`;

interface BreakDownsSectionProps {
    breakDowns: BudgetBreakdown[];
    currency: string;
}

const BreakDownsSection: FC<BreakDownsSectionProps> = (props) => {
    const { breakDowns, currency } = props;

    const [selectedBreakDownIndex, setSelectedBreakDownIndex] = useState(0);
    const currentBreakDowns = breakDowns[selectedBreakDownIndex];
    const request = useSelector(getActiveRequest);

    let templateDisplayName = selectors.template.getTemplateDisplayNameByCode(request.integrationCode);

    return (
        <BreakDownsContainer>
            <Header>
                <HeaderSections>
                    {breakDowns.map(({ sectionName }, index) => (
                        <SectionName
                            key={sectionName}
                            selected={index === selectedBreakDownIndex}
                            type='button'
                            onClick={() => setSelectedBreakDownIndex(index)}
                        >
                            {sectionName}
                        </SectionName>
                    ))}
                </HeaderSections>

                <HeaderDivider />
            </Header>

            <Body>
                <Column>
                    <ColumnType>{currentBreakDowns.period}</ColumnType>

                    <HalfColumn>
                        <ValueName>{messages.budget}</ValueName>

                        <Value>{intl.formatCurrency(currentBreakDowns.planned, currency)}</Value>
                    </HalfColumn>

                    <HalfColumn>
                        <ValueName>{messages.remaining}</ValueName>

                        <Value>{intl.formatCurrency(currentBreakDowns.remaining, currency)}</Value>
                    </HalfColumn>
                </Column>

                <Column>
                    <ColumnType>{messages.bills}</ColumnType>

                    <HalfColumn>
                        <ValueName>{messages.approved}</ValueName>

                        <Value>{intl.formatCurrency(currentBreakDowns.billsApproved, currency)}</Value>
                    </HalfColumn>

                    <HalfColumn>
                        <ValueName>{messages.onApproval}</ValueName>

                        <Value>{intl.formatCurrency(currentBreakDowns.billsOpen, currency)}</Value>
                    </HalfColumn>
                </Column>

                <Column noBorder>
                    <ColumnType>{messages.purchaseOrders}</ColumnType>

                    <HalfColumn>
                        <ValueName>{messages.approved} *</ValueName>

                        <Value>{intl.formatCurrency(currentBreakDowns.purchaseOrdersApproved, currency)}</Value>
                    </HalfColumn>

                    <HalfColumn>
                        <ValueName>{messages.onApproval}</ValueName>

                        <Value>{intl.formatCurrency(currentBreakDowns.purchaseOrdersOpen, currency)}</Value>
                    </HalfColumn>
                </Column>

                <FullWidthColumn>
                    <Note>{messages.noteOne({ templateDisplayName })}</Note>
                </FullWidthColumn>
            </Body>

            <Foooter>
                <Note>* {messages.noteTwo}</Note>
            </Foooter>
        </BreakDownsContainer>
    );
};

export default memo(BreakDownsSection);
