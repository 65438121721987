import capitalize from 'lodash/capitalize';
import uniqueId from 'lodash/uniqueId';
import moment from 'moment/moment';
import { useMemo } from 'react';
import { ApiAmaxPayTransactionStatus, useGetAmaxPayXeroBillTransaction } from 'shared/data/webApp/v2';

import { messages } from './TransactionDetailsPopupContent.messages';

export const useTransactionHistory = ({
    companyId,
    requestId,
    billRequestId,
}: {
    companyId: string;
    requestId: string;
    billRequestId: string;
}) => {
    const { data, isFetching } = useGetAmaxPayXeroBillTransaction({
        path: {
            companyId,
            requestId,
            billRequestId,
        },
    });

    const transactionHistory = useMemo(() => {
        return (data?.history ?? []).map((item) => {
            const formattedStatus =
                item.status === ApiAmaxPayTransactionStatus.scheduled && item.scheduledDate
                    ? messages.paymentIsScheduled({ date: moment.utc(item.scheduledDate).format('ll') })
                    : capitalize(item.status);

            return {
                id: uniqueId('transaction-history-'),
                ...item,
                status: formattedStatus,
            };
        });
    }, [data]);

    return { isFetching, transactionHistory, friendlyName: data?.friendlyName };
};
