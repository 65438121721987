import { RequestParams, RequestQueryOptions, useInvalidateQuery } from '@approvalmax/data';
import { useGet } from 'shared/data';

import { invitationsApiPaths } from '../paths';
import { GetInvitations } from './useGetInvitations.types';

export const useGetInvitations = (queryOptions?: RequestQueryOptions<GetInvitations>) => {
    return useGet(invitationsApiPaths.invitations, {
        queryOptions,
        apiVersion: 'v2',
        method: 'get',
    });
};

export const useInvalidateGetInvitations = () => {
    return useInvalidateQuery<RequestParams<GetInvitations>>(invitationsApiPaths.invitations);
};
