import { useAtom, useAtomValue } from 'jotai';
import { useCallback } from 'react';

import { twoFaProfileVerifyingState } from './TwoFaProfileVerifying.states';
import { TwoFaProfileVerifyingFinishHandler } from './TwoFaProfileVerifying.types';

export const useTwoFaProfileVerifying = () => {
    const [popup, setPopup] = useAtom(twoFaProfileVerifyingState);

    const handleOpen = useCallback(
        (options: { onFinish?: TwoFaProfileVerifyingFinishHandler }) => {
            setPopup((state) => ({
                ...state,
                stage: 'wizard',
                onFinish: options.onFinish,
            }));
        },
        [setPopup]
    );

    const handleClose = useCallback(() => {
        setPopup(null);
    }, [setPopup]);

    return {
        handleOpen,
        handleClose,
        stage: popup?.stage ?? null,
    };
};

export const useCheckTwoFaProfileVerifyingIsOpen = () => {
    const popup = useAtomValue(twoFaProfileVerifyingState);

    return popup !== null;
};

export const useGoToTwoFaProfileVerifyingStage = () => {
    const [popup, setPopup] = useAtom(twoFaProfileVerifyingState);

    const onFinish = popup?.onFinish;

    const handleFinish = useCallback(() => {
        onFinish?.();
        setPopup(null);
    }, [onFinish, setPopup]);

    return {
        handleFinish,
    };
};
