import { Radio } from '@approvalmax/ui/src/components';
import { selectors } from 'modules/common';
import { FC, memo, useCallback } from 'react';

import { SettingsSection } from '../SettingsSection/SettingsSection';
import SettingsSubsection from '../SettingsSubsection/SettingsSubsection';
import { messages } from './SectionSupplierBankAccountRequired.messages';
import {
    SectionSupplierBankAccountRequiredProps,
    SupplierBankAccountRequiredOption,
} from './SectionSupplierBankAccountRequired.types';

const SectionSupplierBankAccountRequired: FC<SectionSupplierBankAccountRequiredProps> = memo((props) => {
    const { templateSettings, setTemplateSettings, qa, readonly, links } = props;

    const value = templateSettings?.isSupplierBankAccountRequired
        ? SupplierBankAccountRequiredOption.Filled
        : SupplierBankAccountRequiredOption.Empty;

    const onChangeSupplierBankAccountRequired = useCallback(
        (newValue: boolean) => {
            setTemplateSettings((prevTemplateSettings) => ({
                ...prevTemplateSettings,
                isSupplierBankAccountRequired: newValue,
            }));
        },
        [setTemplateSettings]
    );

    const handleChange = useCallback(
        (selectedValue: SupplierBankAccountRequiredOption) => {
            switch (selectedValue) {
                case SupplierBankAccountRequiredOption.Filled:
                    onChangeSupplierBankAccountRequired(true);
                    break;

                case SupplierBankAccountRequiredOption.Empty:
                    onChangeSupplierBankAccountRequired(false);
                    break;
            }
        },
        [onChangeSupplierBankAccountRequired]
    );

    return (
        <SettingsSection title={messages.title} learnMoreLink={links[selectors.types.Link.SupplierBankAccount]}>
            <SettingsSubsection subtitle={messages.subtitle}>
                <Radio.Group
                    disabled={readonly}
                    value={value}
                    onChange={handleChange}
                    name='supplierBankAccountRequired'
                    block
                    data-qa={qa('supplier_bank_account_required')}
                >
                    <Radio value={SupplierBankAccountRequiredOption.Filled}>{messages.optionFilled}</Radio>

                    <Radio value={SupplierBankAccountRequiredOption.Empty}>{messages.optionEmpty}</Radio>
                </Radio.Group>
            </SettingsSubsection>
        </SettingsSection>
    );
});

export default SectionSupplierBankAccountRequired;
