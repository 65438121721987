import { constants } from 'modules/common';
import { domain } from 'modules/data';

import { AllowEditingCell } from '../components/MatrixPopupContent/components/AllowEditingCell/AllowEditingCell';
import { XeroSupplierRequesterCell } from '../components/MatrixPopupContent/components/XeroSupplierRequesterCell/XeroSupplierRequesterCell';
import { AccessType, GetMatrixDefinitionOptions, MatrixType } from '../types/matrix';
import {
    getFilteredDefinitions,
    InternalMatrixDefinitionMap,
    MatrixDefinitionColumn,
    renderAirwallexBenificaryCreationConditionCell,
    renderAmountCell,
    renderAmountHeader,
    renderAmountHeaderWithStaticAmount,
    renderDefaultHeader,
    renderExactAsyncCell,
    renderHeaderWithAccessType,
    renderXeroSupplierEditingConditionCell,
} from './matrixDefinitions.shared';
import { messages } from './matrixDefinitions.xero.messages';

const { xeroConstants } = constants;

export function getXeroMatrixDefinitions(options: GetMatrixDefinitionOptions) {
    const matrixDefinitions: InternalMatrixDefinitionMap = {
        [domain.IntegrationCode.XeroPo]: {
            [MatrixType.Approval]: {
                columns: [
                    {
                        systemPurpose: domain.FieldSystemPurpose.Amount,
                        renderHeader: renderAmountHeader,
                        renderCell: (options) =>
                            renderAmountCell({
                                ...options,
                                minValue: xeroConstants.TOTAL_MIN_AMOUNT,
                                maxValue: xeroConstants.MATRIX_PO_TOTAL_MAX_AMOUNT,
                            }),
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.Requester,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroSupplier,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroTracking,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroAccount,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroTax,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroItem,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },

                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroBranding,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                ],
            },
            [MatrixType.Editing]: {
                columns: [
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroSupplier,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory],
                                defaultAccessType: AccessType.Mandatory,
                            }),
                        renderCell: (options) => <AllowEditingCell {...options} withSelect />,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroDate,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory],
                                defaultAccessType: AccessType.Mandatory,
                            }),
                        renderCell: (options) => <AllowEditingCell {...options} />,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroDeliveryDate,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory, AccessType.Optional],
                                defaultAccessType: AccessType.Optional,
                            }),
                        renderCell: (options) => <AllowEditingCell {...options} />,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroReference,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory, AccessType.Optional],
                                defaultAccessType: AccessType.Optional,
                            }),
                        renderCell: (options) => <AllowEditingCell {...options} />,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroBranding,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory],
                                defaultAccessType: AccessType.Mandatory,
                            }),
                        renderCell: (options) => <AllowEditingCell {...options} withSelect />,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.NoteForApprovers,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory, AccessType.Optional],
                                defaultAccessType: AccessType.Optional,
                            }),
                        renderCell: (options) => <AllowEditingCell {...options} />,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroLineAmountType,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory],
                                defaultAccessType: AccessType.Mandatory,
                            }),
                        renderCell: (options) => <AllowEditingCell {...options} withSelect />,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroItem,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory, AccessType.Optional],
                                defaultAccessType: AccessType.Optional,
                            }),
                        renderCell: (options) => <AllowEditingCell {...options} withSelect />,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroLineDescription,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory],
                                defaultAccessType: AccessType.Mandatory,
                            }),
                        renderCell: (options) => <AllowEditingCell {...options} />,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroLineQty,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory],
                                defaultAccessType: AccessType.Mandatory,
                            }),
                        renderCell: (options) => <AllowEditingCell {...options} />,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroLineUnitPrice,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory],
                                defaultAccessType: AccessType.Mandatory,
                            }),
                        renderCell: (options) => <AllowEditingCell {...options} />,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroLineDiscount,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory, AccessType.Optional],
                                defaultAccessType: AccessType.Optional,
                            }),
                        renderCell: (options) => <AllowEditingCell {...options} />,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroAccount,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory],
                                defaultAccessType: AccessType.Mandatory,
                            }),
                        renderCell: (options) => <AllowEditingCell {...options} withSelect />,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroTax,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory, AccessType.Optional],
                                defaultAccessType: AccessType.Optional,
                            }),
                        renderCell: (options) => <AllowEditingCell {...options} withSelect />,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroTracking,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory, AccessType.Optional],
                                defaultAccessType: AccessType.Optional,
                            }),
                        renderCell: (options) => <AllowEditingCell {...options} withSelect />,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroCurrency,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory],
                                defaultAccessType: AccessType.Mandatory,
                            }),
                        renderCell: (options) => <AllowEditingCell {...options} withSelect />,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroManualExchangeRate,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory, AccessType.Optional],
                                defaultAccessType: AccessType.Optional,
                            }),
                        renderCell: (options) => <AllowEditingCell {...options} />,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroDeliveryAddress,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory, AccessType.Optional],
                                defaultAccessType: AccessType.Optional,
                            }),
                        renderCell: (options) => <AllowEditingCell {...options} />,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.EmailToSupplier,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory, AccessType.Optional],
                                defaultAccessType: AccessType.Optional,
                            }),
                        renderCell: (options) => <AllowEditingCell {...options} />,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroAttachment,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory, AccessType.Optional],
                                defaultAccessType: AccessType.Optional,
                            }),
                        renderCell: (options) => <AllowEditingCell {...options} />,
                    },
                ],
            },
            [MatrixType.Reviewer]: {
                columns: [
                    {
                        systemPurpose: domain.FieldSystemPurpose.Amount,
                        renderHeader: renderAmountHeader,
                        renderCell: (options) =>
                            renderAmountCell({
                                ...options,
                                minValue: xeroConstants.TOTAL_MIN_AMOUNT,
                                maxValue: xeroConstants.MATRIX_PO_TOTAL_MAX_AMOUNT,
                            }),
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.Requester,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroSupplier,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroTracking,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroAccount,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroTax,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroItem,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },

                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroBranding,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                ],
            },
            [MatrixType.Requester]: {
                columns: [
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroSupplier,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory],
                                defaultAccessType: AccessType.Mandatory,
                            }),
                        renderCell: XeroSupplierRequesterCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroTracking,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory, AccessType.Optional],
                                defaultAccessType: AccessType.Optional,
                            }),
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroAccount,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory, AccessType.Optional],
                                defaultAccessType: AccessType.Optional,
                            }),
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroItem,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory, AccessType.Optional],
                                defaultAccessType: AccessType.Optional,
                            }),
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroTax,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory, AccessType.Optional],
                                defaultAccessType: AccessType.Optional,
                            }),
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroBranding,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory],
                                defaultAccessType: AccessType.Mandatory,
                            }),
                        renderCell: renderExactAsyncCell,
                    },
                ],
            },
            [MatrixType.AutoApproval]: {
                columns: [
                    {
                        systemPurpose: domain.FieldSystemPurpose.Requester,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.Amount,
                        renderHeader: renderAmountHeader,
                        renderCell: (options) =>
                            renderAmountCell({
                                ...options,
                                minValue: xeroConstants.TOTAL_MIN_AMOUNT,
                                maxValue: xeroConstants.MATRIX_PO_TOTAL_MAX_AMOUNT,
                            }),
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroSupplier,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroTracking,
                        renderHeader: (options) => renderDefaultHeader(options),
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroAccount,
                        renderHeader: (options) => renderDefaultHeader(options),
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroTax,
                        renderHeader: (options) => renderDefaultHeader(options),
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroItem,
                        renderHeader: (options) => renderDefaultHeader(options),
                        renderCell: renderExactAsyncCell,
                    },
                ],
            },
        },
        [domain.IntegrationCode.XeroQuote]: {
            [MatrixType.Approval]: {
                columns: [
                    {
                        systemPurpose: domain.FieldSystemPurpose.Amount,
                        renderHeader: renderAmountHeader,
                        renderCell: (options) =>
                            renderAmountCell({
                                ...options,
                                minValue: xeroConstants.TOTAL_MIN_AMOUNT,
                                maxValue: xeroConstants.MATRIX_PO_TOTAL_MAX_AMOUNT,
                            }),
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.Requester,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroCustomer,
                        renderHeader: renderDefaultHeader,
                        renderCell: (options) => renderExactAsyncCell({ ...options, noEmptyValue: true }),
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroAccount,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroTax,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroItem,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroTracking,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },

                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroBranding,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                ],
            },
            [MatrixType.Editing]: {
                columns: [
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroCustomer,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory],
                                defaultAccessType: AccessType.Mandatory,
                            }),
                        renderCell: (options) => <AllowEditingCell {...options} withSelect />,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroDate,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory],
                                defaultAccessType: AccessType.Mandatory,
                            }),
                        renderCell: (options) => <AllowEditingCell {...options} />,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroQuoteExpiry,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory, AccessType.Optional],
                                defaultAccessType: AccessType.Optional,
                            }),
                        renderCell: (options) => <AllowEditingCell {...options} />,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroQuoteNumber,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory, AccessType.Optional],
                                defaultAccessType: AccessType.Optional,
                            }),
                        renderCell: (options) => <AllowEditingCell {...options} />,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroReference,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory, AccessType.Optional],
                                defaultAccessType: AccessType.Optional,
                            }),
                        renderCell: (options) => <AllowEditingCell {...options} />,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroQuoteTitle,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory, AccessType.Optional],
                                defaultAccessType: AccessType.Optional,
                            }),
                        renderCell: (options) => <AllowEditingCell {...options} />,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroQuoteSummary,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory, AccessType.Optional],
                                defaultAccessType: AccessType.Optional,
                            }),
                        renderCell: (options) => <AllowEditingCell {...options} />,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroBranding,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory],
                                defaultAccessType: AccessType.Mandatory,
                            }),
                        renderCell: (options) => <AllowEditingCell {...options} withSelect />,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.NoteForApprovers,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory, AccessType.Optional],
                                defaultAccessType: AccessType.Optional,
                            }),
                        renderCell: (options) => <AllowEditingCell {...options} />,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroLineAmountType,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory],
                                defaultAccessType: AccessType.Mandatory,
                            }),
                        renderCell: (options) => <AllowEditingCell {...options} withSelect />,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroItem,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory, AccessType.Optional],
                                defaultAccessType: AccessType.Optional,
                            }),
                        renderCell: (options) => <AllowEditingCell {...options} withSelect />,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroLineDescription,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory],
                                defaultAccessType: AccessType.Mandatory,
                            }),
                        renderCell: (options) => <AllowEditingCell {...options} />,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroLineQty,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory],
                                defaultAccessType: AccessType.Mandatory,
                            }),
                        renderCell: (options) => <AllowEditingCell {...options} />,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroLineUnitPrice,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory],
                                defaultAccessType: AccessType.Mandatory,
                            }),
                        renderCell: (options) => <AllowEditingCell {...options} />,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroLineDiscount,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory, AccessType.Optional],
                                defaultAccessType: AccessType.Optional,
                            }),
                        renderCell: (options) => <AllowEditingCell {...options} />,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroAccount,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory],
                                defaultAccessType: AccessType.Mandatory,
                            }),
                        renderCell: (options) => <AllowEditingCell {...options} withSelect />,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroTax,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory, AccessType.Optional],
                                defaultAccessType: AccessType.Optional,
                            }),
                        renderCell: (options) => <AllowEditingCell {...options} withSelect />,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroTracking,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory, AccessType.Optional],
                                defaultAccessType: AccessType.Optional,
                            }),
                        renderCell: (options) => <AllowEditingCell {...options} withSelect />,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroCurrency,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory],
                                defaultAccessType: AccessType.Mandatory,
                            }),
                        renderCell: (options) => <AllowEditingCell {...options} withSelect />,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroManualExchangeRate,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory, AccessType.Optional],
                                defaultAccessType: AccessType.Optional,
                            }),
                        renderCell: (options) => <AllowEditingCell {...options} />,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroQuoteTerms,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory, AccessType.Optional],
                                defaultAccessType: AccessType.Optional,
                            }),
                        renderCell: (options) => <AllowEditingCell {...options} />,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.EmailToSupplier,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory, AccessType.Optional],
                                defaultAccessType: AccessType.Optional,
                            }),
                        renderCell: (options) => <AllowEditingCell {...options} />,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroAttachment,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory, AccessType.Optional],
                                defaultAccessType: AccessType.Optional,
                            }),
                        renderCell: (options) => <AllowEditingCell {...options} />,
                    },
                ],
            },
            [MatrixType.Reviewer]: {
                columns: [
                    {
                        systemPurpose: domain.FieldSystemPurpose.Amount,
                        renderHeader: renderAmountHeader,
                        renderCell: (options) =>
                            renderAmountCell({
                                ...options,
                                minValue: xeroConstants.TOTAL_MIN_AMOUNT,
                                maxValue: xeroConstants.MATRIX_PO_TOTAL_MAX_AMOUNT,
                            }),
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.Requester,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroCustomer,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroAccount,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroTax,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroItem,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroTracking,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },

                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroBranding,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                ],
            },
            [MatrixType.Requester]: {
                columns: [
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroCustomer,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory],
                                defaultAccessType: AccessType.Mandatory,
                            }),
                        renderCell: XeroSupplierRequesterCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroAccount,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory, AccessType.Optional],
                                defaultAccessType: AccessType.Optional,
                            }),
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroTax,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory, AccessType.Optional],
                                defaultAccessType: AccessType.Optional,
                            }),
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroItem,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory, AccessType.Optional],
                                defaultAccessType: AccessType.Optional,
                            }),
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroTracking,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory, AccessType.Optional],
                                defaultAccessType: AccessType.Optional,
                            }),
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroBranding,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory],
                                defaultAccessType: AccessType.Mandatory,
                            }),
                        renderCell: renderExactAsyncCell,
                    },
                ],
            },
            [MatrixType.AutoApproval]: {
                columns: [
                    {
                        systemPurpose: domain.FieldSystemPurpose.Requester,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.Amount,
                        renderHeader: renderAmountHeader,
                        renderCell: (options) =>
                            renderAmountCell({
                                ...options,
                                minValue: xeroConstants.TOTAL_MIN_AMOUNT,
                                maxValue: xeroConstants.MATRIX_PO_TOTAL_MAX_AMOUNT,
                            }),
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroCustomer,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroAccount,
                        renderHeader: (options) => renderDefaultHeader(options),
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroTax,
                        renderHeader: (options) => renderDefaultHeader(options),
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroItem,
                        renderHeader: (options) => renderDefaultHeader(options),
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroTracking,
                        renderHeader: (options) => renderDefaultHeader(options),
                        renderCell: renderExactAsyncCell,
                    },
                ],
            },
        },
        [domain.IntegrationCode.XeroBill]: {
            [MatrixType.Approval]: {
                columns: [
                    {
                        systemPurpose: domain.FieldSystemPurpose.Amount,
                        renderHeader: renderAmountHeader,
                        renderCell: (options) =>
                            renderAmountCell({
                                ...options,
                                minValue: xeroConstants.TOTAL_MIN_AMOUNT,
                                maxValue: xeroConstants.MATRIX_TOTAL_MAX_AMOUNT,
                            }),
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.Requester,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroSupplier,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroTracking,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroAccount,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroItem,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroTax,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                ],
            },
            [MatrixType.Editing]: {
                columns: [
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroSupplier,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory],
                                defaultAccessType: AccessType.Mandatory,
                            }),
                        renderCell: (options) => <AllowEditingCell {...options} withSelect />,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroDate,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory],
                                defaultAccessType: AccessType.Mandatory,
                            }),
                        renderCell: (options) => <AllowEditingCell {...options} />,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroDueDate,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory],
                                defaultAccessType: AccessType.Mandatory,
                            }),
                        renderCell: (options) => <AllowEditingCell {...options} />,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroReference,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory, AccessType.Optional],
                                defaultAccessType: AccessType.Optional,
                            }),
                        renderCell: (options) => <AllowEditingCell {...options} />,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.NoteForApprovers,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory, AccessType.Optional],
                                defaultAccessType: AccessType.Optional,
                            }),
                        renderCell: (options) => <AllowEditingCell {...options} />,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroLineAmountType,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory],
                                defaultAccessType: AccessType.Mandatory,
                            }),
                        renderCell: (options) => <AllowEditingCell {...options} withSelect />,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroItem,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory, AccessType.Optional],
                                defaultAccessType: AccessType.Optional,
                            }),
                        renderCell: (options) => <AllowEditingCell {...options} withSelect />,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroLineDescription,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory],
                                defaultAccessType: AccessType.Mandatory,
                            }),
                        renderCell: (options) => <AllowEditingCell {...options} />,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroLineQty,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory],
                                defaultAccessType: AccessType.Mandatory,
                            }),
                        renderCell: (options) => <AllowEditingCell {...options} />,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroLineUnitPrice,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory],
                                defaultAccessType: AccessType.Mandatory,
                            }),
                        renderCell: (options) => <AllowEditingCell {...options} />,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroAccount,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory],
                                defaultAccessType: AccessType.Mandatory,
                            }),
                        renderCell: (options) => <AllowEditingCell {...options} withSelect />,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroTax,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory, AccessType.Optional],
                                defaultAccessType: AccessType.Optional,
                            }),
                        renderCell: (options) => <AllowEditingCell {...options} withSelect />,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroLineTaxAmount,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory, AccessType.Optional],
                                defaultAccessType: AccessType.Optional,
                            }),
                        renderCell: (options) => <AllowEditingCell {...options} />,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroTracking,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory, AccessType.Optional],
                                defaultAccessType: AccessType.Optional,
                            }),
                        renderCell: (options) => <AllowEditingCell {...options} withSelect />,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroCurrency,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory],
                                defaultAccessType: AccessType.Mandatory,
                            }),
                        renderCell: (options) => <AllowEditingCell {...options} withSelect />,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroManualExchangeRate,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory, AccessType.Optional],
                                defaultAccessType: AccessType.Optional,
                            }),
                        renderCell: (options) => <AllowEditingCell {...options} />,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroAttachment,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory, AccessType.Optional],
                                defaultAccessType: AccessType.Optional,
                            }),
                        renderCell: (options) => <AllowEditingCell {...options} />,
                    },
                ],
            },
            [MatrixType.Reviewer]: {
                columns: [
                    {
                        systemPurpose: domain.FieldSystemPurpose.Amount,
                        renderHeader: renderAmountHeader,
                        renderCell: (options) =>
                            renderAmountCell({
                                ...options,
                                minValue: xeroConstants.TOTAL_MIN_AMOUNT,
                                maxValue: xeroConstants.MATRIX_TOTAL_MAX_AMOUNT,
                            }),
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.Requester,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroSupplier,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroTracking,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroAccount,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroItem,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroTax,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                ],
            },
            [MatrixType.Editor]: {
                columns: [
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroItem,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Readonly, AccessType.Mandatory, AccessType.Optional],
                                defaultAccessType: AccessType.Optional,
                            }),
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroTracking,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory, AccessType.Readonly, AccessType.Optional],
                                defaultAccessType: AccessType.Optional,
                            }),
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroAccount,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory],
                                defaultAccessType: AccessType.Mandatory,
                            }),
                        renderCell: renderExactAsyncCell,
                    },
                ],
            },
            [MatrixType.Requester]: {
                columns: [
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroSupplier,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory],
                                defaultAccessType: AccessType.Mandatory,
                            }),
                        renderCell: XeroSupplierRequesterCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroTracking,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory, AccessType.Optional],
                                defaultAccessType: AccessType.Optional, // Mandatory by default is not supported by the server
                            }),
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroAccount,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory, AccessType.Optional],
                                defaultAccessType: AccessType.Optional, // Mandatory by default is not supported by the server
                            }),
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroItem,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory, AccessType.Optional],
                                defaultAccessType: AccessType.Optional, // Mandatory by default is not supported by the server
                            }),
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroTax,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory, AccessType.Optional],
                                defaultAccessType: AccessType.Optional, // Mandatory by default is not supported by the server
                            }),
                        renderCell: renderExactAsyncCell,
                    },
                ],
            },

            [MatrixType.AutoApproval]: {
                columns: [
                    {
                        systemPurpose: domain.FieldSystemPurpose.Requester,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.Amount,
                        renderHeader: renderAmountHeader,
                        renderCell: (options) =>
                            renderAmountCell({
                                ...options,
                                minValue: xeroConstants.TOTAL_MIN_AMOUNT,
                                maxValue: xeroConstants.MATRIX_TOTAL_MAX_AMOUNT,
                            }),
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroSupplier,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroTracking,
                        renderHeader: (options) => renderDefaultHeader(options),
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroAccount,
                        renderHeader: (options) => renderDefaultHeader(options),
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroTax,
                        renderHeader: (options) => renderDefaultHeader(options),
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroItem,
                        renderHeader: (options) => renderDefaultHeader(options),
                        renderCell: renderExactAsyncCell,
                    },
                ],
            },
        },
        [domain.IntegrationCode.XeroCreditNotesPayable]: {
            [MatrixType.Approval]: {
                columns: [
                    {
                        systemPurpose: domain.FieldSystemPurpose.Amount,
                        renderHeader: renderAmountHeader,
                        renderCell: (options) =>
                            renderAmountCell({
                                ...options,
                                minValue: xeroConstants.TOTAL_MIN_AMOUNT,
                                maxValue: xeroConstants.MATRIX_TOTAL_MAX_AMOUNT,
                            }),
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroSupplier,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroTracking,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroAccount,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroItem,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroTax,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                ],
            },
            [MatrixType.Reviewer]: {
                columns: [
                    {
                        systemPurpose: domain.FieldSystemPurpose.Amount,
                        renderHeader: renderAmountHeader,
                        renderCell: (options) =>
                            renderAmountCell({
                                ...options,
                                minValue: xeroConstants.TOTAL_MIN_AMOUNT,
                                maxValue: xeroConstants.MATRIX_TOTAL_MAX_AMOUNT,
                            }),
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroSupplier,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroTracking,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroAccount,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroItem,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroTax,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                ],
            },
            [MatrixType.AutoApproval]: {
                columns: [
                    {
                        systemPurpose: domain.FieldSystemPurpose.Amount,
                        renderHeader: renderAmountHeader,
                        renderCell: (options) =>
                            renderAmountCell({
                                ...options,
                                minValue: xeroConstants.TOTAL_MIN_AMOUNT,
                                maxValue: xeroConstants.MATRIX_TOTAL_MAX_AMOUNT,
                            }),
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroSupplier,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroTracking,
                        renderHeader: (options) => renderDefaultHeader(options),
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroAccount,
                        renderHeader: (options) => renderDefaultHeader(options),
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroTax,
                        renderHeader: (options) => renderDefaultHeader(options),
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroItem,
                        renderHeader: (options) => renderDefaultHeader(options),
                        renderCell: renderExactAsyncCell,
                    },
                ],
            },
            [MatrixType.Requester]: {
                columns: [
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroSupplier,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory],
                                defaultAccessType: AccessType.Mandatory,
                            }),
                        renderCell: XeroSupplierRequesterCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroTracking,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory, AccessType.Optional],
                                defaultAccessType: AccessType.Optional,
                            }),
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroAccount,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory, AccessType.Optional],
                                defaultAccessType: AccessType.Optional,
                            }),
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroItem,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory, AccessType.Optional],
                                defaultAccessType: AccessType.Optional,
                            }),
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroTax,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory, AccessType.Optional],
                                defaultAccessType: AccessType.Optional,
                            }),
                        renderCell: renderExactAsyncCell,
                    },
                ],
            },
        },
        [domain.IntegrationCode.XeroInvoice]: {
            [MatrixType.Approval]: {
                columns: [
                    {
                        systemPurpose: domain.FieldSystemPurpose.Amount,
                        renderHeader: renderAmountHeader,
                        renderCell: (options) =>
                            renderAmountCell({
                                ...options,
                                minValue: xeroConstants.TOTAL_MIN_AMOUNT,
                                maxValue: xeroConstants.MATRIX_TOTAL_MAX_AMOUNT,
                            }),
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.Requester,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroCustomer,
                        renderHeader: renderDefaultHeader,
                        renderCell: (options) => renderExactAsyncCell({ ...options, noEmptyValue: true }),
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroTracking,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroAccount,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroItem,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroTax,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                ],
            },
            [MatrixType.Editing]: {
                columns: [
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroCustomer,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory],
                                defaultAccessType: AccessType.Mandatory,
                            }),
                        renderCell: (options) => <AllowEditingCell {...options} withSelect />,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroDate,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory],
                                defaultAccessType: AccessType.Mandatory,
                            }),
                        renderCell: (options) => <AllowEditingCell {...options} />,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroDueDate,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory],
                                defaultAccessType: AccessType.Mandatory,
                            }),
                        renderCell: (options) => <AllowEditingCell {...options} />,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroReference,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory, AccessType.Optional],
                                defaultAccessType: AccessType.Optional,
                            }),
                        renderCell: (options) => <AllowEditingCell {...options} />,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroBranding,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory],
                                defaultAccessType: AccessType.Mandatory,
                            }),
                        renderCell: (options) => <AllowEditingCell {...options} withSelect />,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.NoteForApprovers,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory, AccessType.Optional],
                                defaultAccessType: AccessType.Optional,
                            }),
                        renderCell: (options) => <AllowEditingCell {...options} />,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroLineAmountType,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory],
                                defaultAccessType: AccessType.Mandatory,
                            }),
                        renderCell: (options) => <AllowEditingCell {...options} withSelect />,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroItem,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory, AccessType.Optional],
                                defaultAccessType: AccessType.Optional,
                            }),
                        renderCell: (options) => <AllowEditingCell {...options} withSelect />,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroLineDescription,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory],
                                defaultAccessType: AccessType.Mandatory,
                            }),
                        renderCell: (options) => <AllowEditingCell {...options} />,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroLineQty,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory],
                                defaultAccessType: AccessType.Mandatory,
                            }),
                        renderCell: (options) => <AllowEditingCell {...options} />,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroLineUnitPrice,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory],
                                defaultAccessType: AccessType.Mandatory,
                            }),
                        renderCell: (options) => <AllowEditingCell {...options} />,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroLineDiscount,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory, AccessType.Optional],
                                defaultAccessType: AccessType.Optional,
                            }),
                        renderCell: (options) => <AllowEditingCell {...options} />,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroAccount,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory],
                                defaultAccessType: AccessType.Mandatory,
                            }),
                        renderCell: (options) => <AllowEditingCell {...options} withSelect />,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroTax,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory, AccessType.Optional],
                                defaultAccessType: AccessType.Optional,
                            }),
                        renderCell: (options) => <AllowEditingCell {...options} withSelect />,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroTracking,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory, AccessType.Optional],
                                defaultAccessType: AccessType.Optional,
                            }),
                        renderCell: (options) => <AllowEditingCell {...options} withSelect />,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroCurrency,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory],
                                defaultAccessType: AccessType.Mandatory,
                            }),
                        renderCell: (options) => <AllowEditingCell {...options} withSelect />,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroManualExchangeRate,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory, AccessType.Optional],
                                defaultAccessType: AccessType.Optional,
                            }),
                        renderCell: (options) => <AllowEditingCell {...options} />,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.EmailToSupplier,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory, AccessType.Optional],
                                defaultAccessType: AccessType.Optional,
                            }),
                        renderCell: (options) => <AllowEditingCell {...options} />,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroAttachment,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory, AccessType.Optional],
                                defaultAccessType: AccessType.Optional,
                            }),
                        renderCell: (options) => <AllowEditingCell {...options} />,
                    },
                ],
            },
            [MatrixType.Reviewer]: {
                columns: [
                    {
                        systemPurpose: domain.FieldSystemPurpose.Amount,
                        renderHeader: renderAmountHeader,
                        renderCell: (options) =>
                            renderAmountCell({
                                ...options,
                                minValue: xeroConstants.TOTAL_MIN_AMOUNT,
                                maxValue: xeroConstants.MATRIX_TOTAL_MAX_AMOUNT,
                            }),
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.Requester,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroCustomer,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroTracking,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroAccount,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroItem,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroTax,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                ],
            },
            [MatrixType.Requester]: {
                columns: [
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroCustomer,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory],
                                defaultAccessType: AccessType.Mandatory,
                            }),
                        renderCell: XeroSupplierRequesterCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroAccount,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory, AccessType.Optional],
                                defaultAccessType: AccessType.Optional,
                            }),
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroTax,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory, AccessType.Optional],
                                defaultAccessType: AccessType.Optional,
                            }),
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroItem,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory, AccessType.Optional],
                                defaultAccessType: AccessType.Optional,
                            }),
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroTracking,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory, AccessType.Optional],
                                defaultAccessType: AccessType.Optional,
                            }),
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroBranding,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory],
                                defaultAccessType: AccessType.Mandatory,
                            }),
                        renderCell: renderExactAsyncCell,
                    },
                ],
            },
            [MatrixType.AutoApproval]: {
                columns: [
                    {
                        systemPurpose: domain.FieldSystemPurpose.Amount,
                        renderHeader: renderAmountHeader,
                        renderCell: (options) =>
                            renderAmountCell({
                                ...options,
                                minValue: xeroConstants.TOTAL_MIN_AMOUNT,
                                maxValue: xeroConstants.MATRIX_TOTAL_MAX_AMOUNT,
                            }),
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.Requester,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroCustomer,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroTracking,
                        renderHeader: (options) => renderDefaultHeader(options),
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroAccount,
                        renderHeader: (options) => renderDefaultHeader(options),
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroItem,
                        renderHeader: (options) => renderDefaultHeader(options),
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroTax,
                        renderHeader: (options) => renderDefaultHeader(options),
                        renderCell: renderExactAsyncCell,
                    },
                ],
            },
        },
        [domain.IntegrationCode.XeroCreditNotesReceivable]: {
            [MatrixType.Approval]: {
                columns: [
                    {
                        systemPurpose: domain.FieldSystemPurpose.Amount,
                        renderHeader: renderAmountHeader,
                        renderCell: (options) =>
                            renderAmountCell({
                                ...options,
                                minValue: xeroConstants.TOTAL_MIN_AMOUNT,
                                maxValue: xeroConstants.MATRIX_TOTAL_MAX_AMOUNT,
                            }),
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroTracking,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroAccount,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroItem,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroTax,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroCustomer,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                ],
            },
            [MatrixType.Reviewer]: {
                columns: [
                    {
                        systemPurpose: domain.FieldSystemPurpose.Amount,
                        renderHeader: renderAmountHeader,
                        renderCell: (options) =>
                            renderAmountCell({
                                ...options,
                                minValue: xeroConstants.TOTAL_MIN_AMOUNT,
                                maxValue: xeroConstants.MATRIX_TOTAL_MAX_AMOUNT,
                            }),
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroTracking,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroAccount,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroItem,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroTax,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroCustomer,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                ],
            },
            [MatrixType.AutoApproval]: {
                columns: [
                    {
                        systemPurpose: domain.FieldSystemPurpose.Amount,
                        renderHeader: renderAmountHeader,
                        renderCell: (options) =>
                            renderAmountCell({
                                ...options,
                                minValue: xeroConstants.TOTAL_MIN_AMOUNT,
                                maxValue: xeroConstants.MATRIX_TOTAL_MAX_AMOUNT,
                            }),
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroTracking,
                        renderHeader: (options) => renderDefaultHeader(options),
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroAccount,
                        renderHeader: (options) => renderDefaultHeader(options),
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroItem,
                        renderHeader: (options) => renderDefaultHeader(options),
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroTax,
                        renderHeader: (options) => renderDefaultHeader(options),
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroCustomer,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                ],
            },
            [MatrixType.Requester]: {
                columns: [
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroCustomer,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory],
                                defaultAccessType: AccessType.Mandatory,
                            }),
                        renderCell: XeroSupplierRequesterCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroTracking,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory, AccessType.Optional],
                                defaultAccessType: AccessType.Optional,
                            }),
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroAccount,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory, AccessType.Optional],
                                defaultAccessType: AccessType.Optional,
                            }),
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroItem,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory, AccessType.Optional],
                                defaultAccessType: AccessType.Optional,
                            }),
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroTax,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory, AccessType.Optional],
                                defaultAccessType: AccessType.Optional,
                            }),
                        renderCell: renderExactAsyncCell,
                    },
                ],
            },
        },
        [domain.IntegrationCode.XeroContact]: {
            [MatrixType.Approval]: {
                columns: [
                    {
                        systemPurpose: domain.FieldSystemPurpose.Requester,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                ],
            },
            [MatrixType.Reviewer]: {
                columns: [
                    {
                        systemPurpose: domain.FieldSystemPurpose.Requester,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                ],
            },
            [MatrixType.Requester]: {
                columns: [
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroSupplier,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory],
                                defaultAccessType: AccessType.Mandatory,
                            }),
                        renderCell: XeroSupplierRequesterCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroTracking,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory, AccessType.Optional],
                                defaultAccessType: AccessType.Optional,
                            }),
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroAccount,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory, AccessType.Optional],
                                defaultAccessType: AccessType.Optional,
                            }),
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroItem,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory, AccessType.Optional],
                                defaultAccessType: AccessType.Optional,
                            }),
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroTax,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory, AccessType.Optional],
                                defaultAccessType: AccessType.Optional,
                            }),
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroBranding,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory],
                                defaultAccessType: AccessType.Mandatory,
                            }),
                        renderCell: renderExactAsyncCell,
                    },
                ],
            },
            [MatrixType.AutoApproval]: {
                columns: [
                    {
                        systemPurpose: domain.FieldSystemPurpose.Requester,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                ],
            },
        },
        [domain.IntegrationCode.XeroAmaxPayBatchPayment]: {
            [MatrixType.Approval]: {
                columns: [
                    {
                        systemPurpose: domain.FieldSystemPurpose.Amount,
                        renderHeader: renderAmountHeaderWithStaticAmount,
                        renderCell: (options) =>
                            renderAmountCell({
                                ...options,
                                minValue: xeroConstants.TOTAL_MIN_AMOUNT,
                                maxValue: xeroConstants.MATRIX_TOTAL_MAX_AMOUNT,
                            }),
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.Requester,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroSupplier,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.AmaxPayBankAccount,
                        renderHeader: (options) =>
                            renderDefaultHeader(options, {
                                fieldName: messages.amaxPayBankAccountFieldName,
                            }),
                        renderCell: (options) =>
                            renderExactAsyncCell({
                                ...options,
                                field: {
                                    ...options.field,
                                    name: messages.amaxPayBankAccountFieldName,
                                },
                            }),
                    },
                ],
            },
            [MatrixType.Reviewer]: {
                columns: [
                    {
                        systemPurpose: domain.FieldSystemPurpose.Amount,
                        renderHeader: renderAmountHeaderWithStaticAmount,
                        renderCell: (options) =>
                            renderAmountCell({
                                ...options,
                                minValue: xeroConstants.TOTAL_MIN_AMOUNT,
                                maxValue: xeroConstants.MATRIX_TOTAL_MAX_AMOUNT,
                            }),
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.Requester,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroSupplier,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.AmaxPayBankAccount,
                        renderHeader: (options) =>
                            renderDefaultHeader(options, {
                                fieldName: messages.amaxPayBankAccountFieldName,
                            }),
                        renderCell: (options) =>
                            renderExactAsyncCell({
                                ...options,
                                field: {
                                    ...options.field,
                                    name: messages.amaxPayBankAccountFieldName,
                                },
                            }),
                    },
                ],
            },
            [MatrixType.Requester]: {
                columns: [
                    {
                        systemPurpose: domain.FieldSystemPurpose.AmaxPayBankAccount,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(
                                {
                                    ...options,
                                    field: {
                                        ...options.field,
                                        name: messages.amaxPayBankAccountFieldName,
                                    },
                                },
                                {
                                    accessTypeOptions: [AccessType.Mandatory],
                                    defaultAccessType: AccessType.Mandatory,
                                }
                            ),
                        renderCell: (options) =>
                            renderExactAsyncCell({
                                ...options,
                                field: {
                                    ...options.field,
                                    name: messages.amaxPayBankAccountFieldName,
                                },
                            }),
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroSupplier,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory],
                                defaultAccessType: AccessType.Mandatory,
                            }),
                        renderCell: renderExactAsyncCell,
                    },
                ],
            },
            [MatrixType.AutoApproval]: {
                columns: [
                    {
                        systemPurpose: domain.FieldSystemPurpose.Amount,
                        renderHeader: renderAmountHeaderWithStaticAmount,
                        renderCell: (options) =>
                            renderAmountCell({
                                ...options,
                                minValue: xeroConstants.TOTAL_MIN_AMOUNT,
                                maxValue: xeroConstants.MATRIX_TOTAL_MAX_AMOUNT,
                            }),
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.Requester,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroSupplier,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.AmaxPayBankAccount,
                        renderHeader: (options) =>
                            renderDefaultHeader(options, {
                                fieldName: messages.amaxPayBankAccountFieldName,
                            }),
                        renderCell: (options) =>
                            renderExactAsyncCell({
                                ...options,
                                field: {
                                    ...options.field,
                                    name: messages.amaxPayBankAccountFieldName,
                                },
                            }),
                    },
                ],
            },
        },
        [domain.IntegrationCode.XeroBillBatchPayment]: {
            [MatrixType.Approval]: {
                columns: [
                    {
                        systemPurpose: domain.FieldSystemPurpose.Amount,
                        renderHeader: renderAmountHeaderWithStaticAmount,
                        renderCell: (options) =>
                            renderAmountCell({
                                ...options,
                                minValue: xeroConstants.TOTAL_MIN_AMOUNT,
                                maxValue: xeroConstants.MATRIX_TOTAL_MAX_AMOUNT,
                            }),
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.Requester,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroSupplier,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroBankAccount,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                ],
            },
            [MatrixType.Reviewer]: {
                columns: [
                    {
                        systemPurpose: domain.FieldSystemPurpose.Amount,
                        renderHeader: renderAmountHeaderWithStaticAmount,
                        renderCell: (options) =>
                            renderAmountCell({
                                ...options,
                                minValue: xeroConstants.TOTAL_MIN_AMOUNT,
                                maxValue: xeroConstants.MATRIX_TOTAL_MAX_AMOUNT,
                            }),
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.Requester,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroSupplier,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroBankAccount,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                ],
            },
            [MatrixType.Requester]: {
                columns: [
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroBankAccount,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory],
                                defaultAccessType: AccessType.Mandatory,
                            }),
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroSupplier,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory],
                                defaultAccessType: AccessType.Mandatory,
                            }),
                        renderCell: renderXeroSupplierEditingConditionCell,
                    },
                ],
            },
            [MatrixType.AutoApproval]: {
                columns: [
                    {
                        systemPurpose: domain.FieldSystemPurpose.Amount,
                        renderHeader: renderAmountHeaderWithStaticAmount,
                        renderCell: (options) =>
                            renderAmountCell({
                                ...options,
                                minValue: xeroConstants.TOTAL_MIN_AMOUNT,
                                maxValue: xeroConstants.MATRIX_TOTAL_MAX_AMOUNT,
                            }),
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.Requester,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroSupplier,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroBankAccount,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                ],
            },
            [MatrixType.Editing]: {
                columns: [
                    ...(options.licenseFeatures?.includes(domain.CompanyLicenseFeature.EditingOnApproval)
                        ? [
                              {
                                  systemPurpose: domain.FieldSystemPurpose.XeroBatchPaymentItems,
                                  renderHeader: renderDefaultHeader,
                                  renderCell: (options) => (
                                      <AllowEditingCell {...options} label={messages.allowToRemoveLinesFromBP} />
                                  ),
                              } as MatrixDefinitionColumn,
                          ]
                        : []),
                ],
            },
        },
        [domain.IntegrationCode.XeroAirwallexBatchPayment]: {
            [MatrixType.Approval]: {
                columns: [
                    {
                        systemPurpose: domain.FieldSystemPurpose.Amount,
                        renderHeader: renderAmountHeaderWithStaticAmount,
                        renderCell: (options) =>
                            renderAmountCell({
                                ...options,
                                minValue: xeroConstants.TOTAL_MIN_AMOUNT,
                                maxValue: xeroConstants.MATRIX_TOTAL_MAX_AMOUNT,
                            }),
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.Requester,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroSupplier,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroBankAccount,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                ],
            },
            [MatrixType.Reviewer]: {
                columns: [
                    {
                        systemPurpose: domain.FieldSystemPurpose.Amount,
                        renderHeader: renderAmountHeaderWithStaticAmount,
                        renderCell: (options) =>
                            renderAmountCell({
                                ...options,
                                minValue: xeroConstants.TOTAL_MIN_AMOUNT,
                                maxValue: xeroConstants.MATRIX_TOTAL_MAX_AMOUNT,
                            }),
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.Requester,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroSupplier,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroBankAccount,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                ],
            },
            [MatrixType.Requester]: {
                columns: [
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroBankAccount,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory],
                                defaultAccessType: AccessType.Mandatory,
                            }),
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroSupplier,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory],
                                defaultAccessType: AccessType.Mandatory,
                            }),
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.AirwallexBeneficiary,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderAirwallexBenificaryCreationConditionCell,
                    },
                ],
            },
            [MatrixType.AutoApproval]: {
                columns: [
                    {
                        systemPurpose: domain.FieldSystemPurpose.Amount,
                        renderHeader: renderAmountHeaderWithStaticAmount,
                        renderCell: (options) =>
                            renderAmountCell({
                                ...options,
                                minValue: xeroConstants.TOTAL_MIN_AMOUNT,
                                maxValue: xeroConstants.MATRIX_TOTAL_MAX_AMOUNT,
                            }),
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.Requester,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroSupplier,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroBankAccount,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                ],
            },
            [MatrixType.Editing]: {
                columns: [
                    ...(options.licenseFeatures?.includes(domain.CompanyLicenseFeature.EditingOnApproval)
                        ? [
                              {
                                  systemPurpose: domain.FieldSystemPurpose.XeroBatchPaymentItems,
                                  renderHeader: renderDefaultHeader,
                                  renderCell: (options) => (
                                      <AllowEditingCell {...options} label={messages.allowToRemoveLinesFromBP} />
                                  ),
                              } as MatrixDefinitionColumn,
                          ]
                        : []),
                ],
            },
        },
        [domain.IntegrationCode.XeroManualJournal]: {
            [MatrixType.Approval]: {
                columns: [
                    {
                        systemPurpose: domain.FieldSystemPurpose.Amount,
                        renderHeader: renderAmountHeaderWithStaticAmount,
                        renderCell: (options) =>
                            renderAmountCell({
                                ...options,
                                minValue: xeroConstants.TOTAL_MIN_AMOUNT,
                                maxValue: xeroConstants.MATRIX_TOTAL_MAX_AMOUNT,
                            }),
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.Requester,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroTracking,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroAccount,
                        renderHeader: renderDefaultHeader,
                        renderCell: (options) => renderExactAsyncCell({ ...options, noEmptyValue: true }),
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroTax,
                        renderHeader: renderDefaultHeader,
                        renderCell: (options) => renderExactAsyncCell({ ...options, noEmptyValue: true }),
                    },
                ],
            },
            [MatrixType.Reviewer]: {
                columns: [
                    {
                        systemPurpose: domain.FieldSystemPurpose.Amount,
                        renderHeader: renderAmountHeaderWithStaticAmount,
                        renderCell: (options) =>
                            renderAmountCell({
                                ...options,
                                minValue: xeroConstants.TOTAL_MIN_AMOUNT,
                                maxValue: xeroConstants.MATRIX_TOTAL_MAX_AMOUNT,
                            }),
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.Requester,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroTracking,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroAccount,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroTax,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                ],
            },
            [MatrixType.Requester]: {
                columns: [
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroTracking,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory, AccessType.Optional],
                                defaultAccessType: AccessType.Optional,
                            }),
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroAccount,
                        renderHeader: renderDefaultHeader,
                        renderCell: (options) => renderExactAsyncCell({ ...options, noEmptyValue: true }),
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroTax,
                        renderHeader: renderDefaultHeader,
                        renderCell: (options) => renderExactAsyncCell({ ...options, noEmptyValue: true }),
                    },
                ],
            },
            [MatrixType.AutoApproval]: {
                columns: [
                    {
                        systemPurpose: domain.FieldSystemPurpose.Amount,
                        renderHeader: renderAmountHeaderWithStaticAmount,
                        renderCell: (options) =>
                            renderAmountCell({
                                ...options,
                                minValue: xeroConstants.TOTAL_MIN_AMOUNT,
                                maxValue: xeroConstants.MATRIX_TOTAL_MAX_AMOUNT,
                            }),
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.Requester,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroTracking,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroAccount,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.XeroTax,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                ],
            },
        },
    };

    return getFilteredDefinitions(matrixDefinitions, options);
}
