/**
 * Developer: Stepan Burguchev
 * Date: 6/4/2017
 * Copyright: 2015-2017 ApprovalMax
 *       All Rights Reserved
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF ApprovalMax
 *       The copyright notice above does not evidence any
 *       actual or intended publication of such source code.
 */

import './companyUserListItem.scss';

import { domHelpers, errorHelpers } from '@approvalmax/utils';
import { domain } from 'modules/data';
import React from 'react';
import bemFactory from 'react-bem-factory';

import * as selectors from '../../selectors';
import Avatar from '../avatar/Avatar';
import { messages } from './CompanyUserListItem.messages';

interface OwnProps {
    item: selectors.types.ExpandedCompanyUser;
    displayTextSelector: (item: selectors.types.ExpandedCompanyUser) => string;
    rootRef: (ref: HTMLLIElement) => void;
    active: boolean;
    highlightedText: string | null;
    onSelect: (item: selectors.types.ExpandedCompanyUser) => void;
    onHover: (item: selectors.types.ExpandedCompanyUser) => void;
}

class UserListItem extends React.Component<OwnProps> {
    public render() {
        const { item, displayTextSelector, active, highlightedText, rootRef } = this.props;

        const bem = bemFactory.block('form-dropdown-editor-company-user-list-item');
        const qa = bemFactory.qa('form-dropdown-editor-list-item'); // not a typo, qa block doesn't change

        let text = displayTextSelector(item);
        let badgeText;

        switch (item.status) {
            case domain.CompanyUserStatus.Active:
                break;

            case domain.CompanyUserStatus.Invited:
                badgeText = messages.invitedText;
                break;

            case domain.CompanyUserStatus.Rejected:
            case domain.CompanyUserStatus.NotInvited:
                badgeText = messages.notInvitedText;
                break;

            default:
                throw errorHelpers.notSupportedError();
        }

        let textComponent;

        if (highlightedText) {
            textComponent = (
                <div
                    className={bem('text')}
                    title={text}
                    dangerouslySetInnerHTML={{
                        __html: domHelpers.highlightText(text, highlightedText, true, bem('highlighted-text')),
                    }}
                />
            );
        } else {
            textComponent = (
                <div className={bem('text')} title={text}>
                    {text}
                </div>
            );
        }

        return (
            <li
                className={bem(null, { active })}
                onClick={this._select}
                data-qa={qa()}
                data-qa-id={item.id}
                data-qa-name={text}
                onMouseEnter={this._hover}
                ref={rootRef}
            >
                <Avatar user={item} size={20} />

                {textComponent}

                {badgeText && <div className={bem('invitation-badge')}>{badgeText}</div>}
            </li>
        );
    }

    private _select = () => {
        this.props.onSelect(this.props.item);
    };

    private _hover = () => {
        this.props.onHover(this.props.item);
    };
}

export default UserListItem;
