import { atom } from 'jotai';
import { atomWithReset } from 'jotai/utils';

const checkOcrStatusTimeoutIdState = atom<NodeJS.Timeout | null>(null);

export const checkOcrStatusTimeoutIdSelector = atom(
    (get) => get(checkOcrStatusTimeoutIdState),
    (get, set, newValue: NodeJS.Timeout | null) => {
        const checkOcrStatusId = get(checkOcrStatusTimeoutIdState);

        set(checkOcrStatusTimeoutIdState, newValue);

        if (newValue === null && checkOcrStatusId !== null) {
            clearTimeout(checkOcrStatusId);
        }
    }
);

export const ocrIsLoadingState = atomWithReset(false);
