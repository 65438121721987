import { Button, Flex } from '@approvalmax/ui/src/components';
import { FC, memo } from 'react';

import { messages } from './PromotionButtons.messages';
import { PromotionButtonsProps } from './PromotionButtons.types';

export const PromotionButtons: FC<PromotionButtonsProps> = memo((props) => {
    const { learnMoreLink, onClickTryIt } = props;

    return (
        <Flex>
            <Button
                color='midnight40'
                variant='outline'
                as='a'
                target='_blank'
                rel='noopener noreferrer'
                href={learnMoreLink}
            >
                {messages.learnMore}
            </Button>

            <Button color='mint80' onClick={onClickTryIt}>
                {messages.tryIt}
            </Button>
        </Flex>
    );
});

PromotionButtons.displayName = 'PromotionButtons';
