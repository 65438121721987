import { domain } from 'modules/data';

import { ESettingsSection, SettingsSchemeType, SettingTabs } from './WorkflowSettingsPopupContent.types';

export const settingsScheme: SettingsSchemeType = {
    null: {
        [SettingTabs.General]: [ESettingsSection.Instruction, ESettingsSection.DecisionPolicy],
    },
    [domain.IntegrationCode.XeroBill]: {
        [SettingTabs.General]: [
            ESettingsSection.LockDate,
            ESettingsSection.Instruction,
            ESettingsSection.DecisionPolicy,
            ESettingsSection.OverrideGoToBtnInXero,
            ESettingsSection.HistoryPushingType,
            ESettingsSection.UseRejectedPrefix,
        ],
        [SettingTabs.Capture]: [ESettingsSection.CaptureViaEmail],
        [SettingTabs.Matching]: [ESettingsSection.XeroBillMatching],
        [SettingTabs.FraudDetection]: [ESettingsSection.FraudBypassing, ESettingsSection.FraudChangesAfter],
    },
    [domain.IntegrationCode.XeroPo]: {
        [SettingTabs.General]: [
            ESettingsSection.Instruction,
            ESettingsSection.DecisionPolicy,
            ESettingsSection.GoodsReceivedNote,
            ESettingsSection.HistoryPushingType,
            ESettingsSection.UseRejectedPrefix,
        ],
        [SettingTabs.Capture]: [ESettingsSection.CaptureViaEmail],
        [SettingTabs.Matching]: [ESettingsSection.XeroPOMatching],
        [SettingTabs.FraudDetection]: [ESettingsSection.FraudBypassing, ESettingsSection.FraudChangesAfter],
        [SettingTabs.EmailToSupplier]: [ESettingsSection.EmailToSupplierSetting],
    },
    [domain.IntegrationCode.XeroCreditNotesPayable]: {
        [SettingTabs.General]: [
            ESettingsSection.LockDate,
            ESettingsSection.Instruction,
            ESettingsSection.DecisionPolicy,
            ESettingsSection.HistoryPushingType,
            ESettingsSection.UseRejectedPrefix,
        ],
        [SettingTabs.Capture]: [ESettingsSection.CaptureViaEmail],
        [SettingTabs.FraudDetection]: [ESettingsSection.FraudBypassing, ESettingsSection.FraudChangesAfter],
    },
    [domain.IntegrationCode.XeroBillBatchPayment]: {
        [SettingTabs.General]: [
            ESettingsSection.LockDate,
            ESettingsSection.Instruction,
            ESettingsSection.DecisionPolicy,
            ESettingsSection.SupplierBankAccountRequired,
        ],
    },
    [domain.IntegrationCode.XeroAirwallexBatchPayment]: {
        [SettingTabs.General]: [ESettingsSection.Instruction, ESettingsSection.DecisionPolicy],
    },
    [domain.IntegrationCode.XeroAmaxPayBatchPayment]: {
        [SettingTabs.General]: [
            ESettingsSection.Instruction,
            ESettingsSection.DecisionPolicy,
            ESettingsSection.BalanceControlCheck,
        ],
    },
    [domain.IntegrationCode.XeroInvoice]: {
        [SettingTabs.General]: [
            ESettingsSection.LockDate,
            ESettingsSection.Instruction,
            ESettingsSection.DecisionPolicy,
            ESettingsSection.OverrideGoToBtnInXero,
            ESettingsSection.HistoryPushingType,
            ESettingsSection.UseRejectedPrefix,
        ],
        [SettingTabs.Capture]: [ESettingsSection.CaptureViaEmail],
        [SettingTabs.FraudDetection]: [ESettingsSection.FraudBypassing, ESettingsSection.FraudChangesAfter],
        [SettingTabs.EmailToSupplier]: [ESettingsSection.EmailToSupplierSetting],
    },
    [domain.IntegrationCode.XeroCreditNotesReceivable]: {
        [SettingTabs.General]: [
            ESettingsSection.LockDate,
            ESettingsSection.Instruction,
            ESettingsSection.DecisionPolicy,
            ESettingsSection.HistoryPushingType,
            ESettingsSection.UseRejectedPrefix,
        ],
        [SettingTabs.FraudDetection]: [ESettingsSection.FraudBypassing, ESettingsSection.FraudChangesAfter],
    },
    [domain.IntegrationCode.XeroQuote]: {
        [SettingTabs.General]: [
            ESettingsSection.LockDate,
            ESettingsSection.Instruction,
            ESettingsSection.DecisionPolicy,
            ESettingsSection.Terms,
            ESettingsSection.HistoryPushingType,
        ],
        [SettingTabs.EmailToSupplier]: [ESettingsSection.EmailToSupplierSetting],
    },
    [domain.IntegrationCode.XeroContact]: {
        [SettingTabs.General]: [ESettingsSection.Instruction, ESettingsSection.DecisionPolicy],
    },
    [domain.IntegrationCode.XeroManualJournal]: {
        [SettingTabs.General]: [
            ESettingsSection.LockDate,
            ESettingsSection.PostingPreferencesType,
            ESettingsSection.Instruction,
            ESettingsSection.DecisionPolicy,
        ],
    },
    [domain.IntegrationCode.QBooksPo]: {
        [SettingTabs.General]: [
            ESettingsSection.LockDate,
            ESettingsSection.LineItemSettings,
            ESettingsSection.Instruction,
            ESettingsSection.DecisionPolicy,
            ESettingsSection.GoodsReceivedNote,
            ESettingsSection.PriceChecker,
        ],
        [SettingTabs.FraudDetection]: [ESettingsSection.QBooksFraudBypassing, ESettingsSection.QBooksFraudChangesAfter],
        [SettingTabs.EmailToSupplier]: [ESettingsSection.EmailToSupplierSetting],
    },
    [domain.IntegrationCode.QBooksBill]: {
        [SettingTabs.General]: [
            ESettingsSection.LockDate,
            ESettingsSection.LineItemSettings,
            ESettingsSection.Instruction,
            ESettingsSection.DecisionPolicy,
            ESettingsSection.PriceChecker,
        ],
        [SettingTabs.Capture]: [ESettingsSection.CaptureViaEmail],
        [SettingTabs.FraudDetection]: [ESettingsSection.QBooksFraudBypassing, ESettingsSection.QBooksFraudChangesAfter],
        [SettingTabs.Matching]: [ESettingsSection.QBooksBillMatching],
    },
    [domain.IntegrationCode.QBooksExpense]: {
        [SettingTabs.General]: [
            ESettingsSection.LockDate,
            ESettingsSection.LineItemSettings,
            ESettingsSection.Instruction,
            ESettingsSection.DecisionPolicy,
            ESettingsSection.PriceChecker,
        ],
        [SettingTabs.FraudDetection]: [ESettingsSection.QBooksFraudBypassing, ESettingsSection.QBooksFraudChangesAfter],
    },
    [domain.IntegrationCode.QBooksInvoice]: {
        [SettingTabs.General]: [
            ESettingsSection.LockDate,
            ESettingsSection.Instruction,
            ESettingsSection.DecisionPolicy,
        ],
        [SettingTabs.FraudDetection]: [ESettingsSection.QBooksFraudChangesAfter],
        [SettingTabs.EmailToSupplier]: [ESettingsSection.EmailToSupplierSetting],
    },
    [domain.IntegrationCode.QBooksVendor]: {
        [SettingTabs.General]: [ESettingsSection.Instruction, ESettingsSection.DecisionPolicy],
    },
    [domain.IntegrationCode.QBooksJournalEntry]: {
        [SettingTabs.General]: [
            ESettingsSection.LockDate,
            ESettingsSection.Instruction,
            ESettingsSection.DecisionPolicy,
        ],
    },
    [domain.IntegrationCode.NetSuitePO]: {
        [SettingTabs.General]: [
            ESettingsSection.NetSuiteAvailableLineItemType,
            ESettingsSection.Instruction,
            ESettingsSection.DecisionPolicy,
            ESettingsSection.PushApprovalMaxUrlToNetSuite,
            ESettingsSection.NetSuiteCustomFormId,
        ],
        [SettingTabs.FraudDetection]: [
            ESettingsSection.NetSuiteFraudBypassing,
            ESettingsSection.NetSuiteFraudChangesAfter,
        ],
        [SettingTabs.EmailToSupplier]: [ESettingsSection.EmailToSupplierSetting],
    },
    [domain.IntegrationCode.NetSuiteBill]: {
        [SettingTabs.General]: [
            ESettingsSection.NetSuiteAvailableLineItemType,
            ESettingsSection.Instruction,
            ESettingsSection.DecisionPolicy,
            ESettingsSection.PushApprovalMaxUrlToNetSuite,
            ESettingsSection.NetSuiteCustomFormId,
        ],
        [SettingTabs.Capture]: [ESettingsSection.CaptureViaEmail],
        [SettingTabs.FraudDetection]: [
            ESettingsSection.NetSuiteFraudBypassing,
            ESettingsSection.NetSuiteFraudChangesAfter,
        ],
    },
    [domain.IntegrationCode.NetSuiteExpenseReport]: {
        [SettingTabs.General]: [
            ESettingsSection.Instruction,
            ESettingsSection.DecisionPolicy,
            ESettingsSection.PushApprovalMaxUrlToNetSuite,
            ESettingsSection.NetSuiteCustomFormId,
        ],
        [SettingTabs.FraudDetection]: [
            ESettingsSection.NetSuiteFraudBypassing,
            ESettingsSection.NetSuiteExpenseReportFraudChangesAfter,
        ],
    },
    [domain.IntegrationCode.NetSuiteVRA]: {
        [SettingTabs.General]: [ESettingsSection.DecisionPolicy, ESettingsSection.PushApprovalMaxUrlToNetSuite],
        [SettingTabs.FraudDetection]: [
            ESettingsSection.NetSuiteFraudBypassing,
            ESettingsSection.NetSuiteFraudChangesAfter,
        ],
    },
    [domain.IntegrationCode.NetSuiteBillPayment]: {
        [SettingTabs.General]: [
            ESettingsSection.DecisionPolicy,
            ESettingsSection.PushApprovalMaxUrlToNetSuite,
            ESettingsSection.NetSuiteCustomFormId,
        ],
        [SettingTabs.FraudDetection]: [
            ESettingsSection.NetSuiteFraudBypassing,
            ESettingsSection.NetSuiteFraudChangesAfter,
        ],
    },
    [domain.IntegrationCode.NetSuiteSalesOrder]: {
        [SettingTabs.General]: [ESettingsSection.DecisionPolicy, ESettingsSection.PushApprovalMaxUrlToNetSuite],
        [SettingTabs.FraudDetection]: [
            ESettingsSection.NetSuiteFraudBypassing,
            ESettingsSection.NetSuiteFraudChangesAfter,
        ],
    },
    [domain.IntegrationCode.NetSuiteInvoice]: {
        [SettingTabs.General]: [
            ESettingsSection.DecisionPolicy,
            ESettingsSection.PushApprovalMaxUrlToNetSuite,
            ESettingsSection.NetSuiteCustomFormId,
        ],
        [SettingTabs.FraudDetection]: [
            ESettingsSection.NetSuiteFraudBypassing,
            ESettingsSection.NetSuiteFraudChangesAfter,
        ],
    },
    [domain.IntegrationCode.NetSuiteRA]: {
        [SettingTabs.General]: [ESettingsSection.DecisionPolicy, ESettingsSection.PushApprovalMaxUrlToNetSuite],
        [SettingTabs.FraudDetection]: [
            ESettingsSection.NetSuiteFraudBypassing,
            ESettingsSection.NetSuiteFraudChangesAfter,
        ],
    },
    [domain.IntegrationCode.NetSuiteJournalEntry]: {
        [SettingTabs.General]: [
            ESettingsSection.DecisionPolicy,
            ESettingsSection.PushApprovalMaxUrlToNetSuite,
            ESettingsSection.NetSuiteCustomFormId,
        ],
        [SettingTabs.FraudDetection]: [
            ESettingsSection.NetSuiteFraudBypassing,
            ESettingsSection.NetSuiteFraudChangesAfter,
        ],
    },
    [domain.IntegrationCode.NetSuiteVendor]: {
        [SettingTabs.General]: [
            ESettingsSection.Instruction,
            ESettingsSection.DecisionPolicy,
            ESettingsSection.PushApprovalMaxUrlToNetSuite,
            ESettingsSection.NetSuiteCustomFormId,
        ],
    },
    [domain.IntegrationCode.DearPo]: {
        [SettingTabs.General]: [ESettingsSection.DecisionPolicy, ESettingsSection.DearPORejectedNoteToMemo],
        [SettingTabs.FraudDetection]: [ESettingsSection.DearFraudBypassing, ESettingsSection.DearFraudChangesAfter],
    },
};

export const xeroBillToPoMatchingLearnMore =
    'https://support.approvalmax.com/portal/kb/approvalmax-1/approvalmax-for-xero/bill-to-po-matching';
