import { componentHelpers, domHelpers, objectHelpers } from '@approvalmax/utils';
import { DndContext, pointerWithin } from '@dnd-kit/core';
import { horizontalListSortingStrategy, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { forwardRef, ReactElement, useMemo } from 'react';
import { TableVirtuoso } from 'react-virtuoso';

import {
    BodyCell,
    BodyCellWithState,
    BodyFormCell,
    BodyRow,
    ColumnHeader,
    ColumnsGroup,
    HeaderRow,
} from './components';
import { TableProvider } from './Table.context';
import {
    generateMockedDataItemsForSkeleton,
    getStylesForVirtuoso,
    reorderModifierSnapToAxis,
    retrieveRowId,
} from './Table.helpers';
import { useTableReorder } from './Table.hooks';
import { Root } from './Table.styles';
import { BaseItem, ChildrenComponents, TableCheckType, TableProps } from './Table.types';

/**
 * Renders a table component with the given columns and data.
 */
const Table = <Item extends BaseItem>(props: TableProps<Item>) => {
    const {
        columns: originalColumns,
        data,
        getRowId,
        bordered,
        hideBorderLastRow,
        hideHeaderBottomBorder,
        onSort,
        checkedItems,
        invalidItems,
        checkboxColumnCover,
        onCheckedItemsChange,
        onRowClick,
        reorderable = false,
        onReorderColumns,
        onReorderRows,
        headerColor,
        stickyHeader,
        fontSize = 'small',
        stickyLastRow,
        virtualized,
        progress,
        progressRowCount = 6,
        hideHeader,
        divider = true,
        hoverable = true,
        rounded,
        checkType = TableCheckType.checkbox,
        qa,
        getIsDisabledRow,
        hideEmptyColumns,
        handleEndReached,
        rowActionMenuItems,
        columnSpacing,
        ...otherProps
    } = props;

    const columns = useMemo(() => {
        if (!hideEmptyColumns) {
            return originalColumns;
        }

        return originalColumns.filter((column) => {
            const { id, isEmpty, value } = column;

            if (isEmpty) {
                return data.some((item) => !isEmpty(item));
            }

            return data.some((item) => (value ? value(item) : objectHelpers.has(item, id) && item[id]));
        });
    }, [originalColumns, data, hideEmptyColumns]);

    const isReorderableColumns = reorderable && !!onReorderColumns;
    const isReorderableRows = reorderable && !!onReorderRows;

    const { sensors, handleReordered } = useTableReorder({
        reorderable,
        columns,
        data,
        onReorderColumns,
        onReorderRows,
    });

    const headerRow = !hideHeader && (
        <HeaderRow
            qa={qa}
            bordered={bordered}
            columns={columns}
            columnSpacing={columnSpacing}
            getRowId={getRowId}
            checkedItems={checkedItems}
            onCheckedItemsChange={onCheckedItemsChange}
            headerColor={headerColor}
            stickyHeader={stickyHeader}
            checkboxColumnCover={checkboxColumnCover}
            checkType={checkType}
            onSort={onSort}
            data={data}
            isReorderableColumns={isReorderableColumns}
            isReorderableRows={isReorderableRows}
            hideHeaderBottomBorder={hideHeaderBottomBorder}
            progress={progress}
            rowActionMenuItems={rowActionMenuItems}
        />
    );

    const skeletonData: Item[] = useMemo(
        () => generateMockedDataItemsForSkeleton(progressRowCount),
        [progressRowCount]
    );

    const hasRowActionMenu = Boolean(rowActionMenuItems && rowActionMenuItems.length > 0);

    return (
        <DndContext
            sensors={sensors}
            modifiers={[reorderModifierSnapToAxis]}
            collisionDetection={pointerWithin}
            onDragEnd={handleReordered}
        >
            <TableProvider>
                {virtualized ? (
                    <TableVirtuoso
                        totalCount={data.length}
                        data={data}
                        style={getStylesForVirtuoso(isReorderableRows, hasRowActionMenu)}
                        components={{
                            Table: ({ children }) => {
                                return (
                                    <Root
                                        $fontSize={fontSize}
                                        $reorderableRows={reorderable && !!onReorderRows}
                                        $hasRowActionMenu={hasRowActionMenu}
                                        $virtualized={virtualized}
                                        data-qa={domHelpers.generateDataQa(qa, 'table')}
                                        {...otherProps}
                                    >
                                        <ColumnsGroup
                                            columns={columns}
                                            bordered={bordered}
                                            checkedItems={checkedItems}
                                            checkboxColumnCover={checkboxColumnCover}
                                            isReorderableRows={isReorderableRows}
                                        />

                                        {children}
                                    </Root>
                                );
                            },
                            TableHead: forwardRef((props, ref) => (
                                <SortableContext items={columns} strategy={horizontalListSortingStrategy}>
                                    <thead ref={ref} {...props} />
                                </SortableContext>
                            )),
                            TableBody: forwardRef((props, ref) => (
                                <SortableContext items={data} strategy={verticalListSortingStrategy}>
                                    <tbody ref={ref} {...props} />
                                </SortableContext>
                            )),
                            TableRow: (props) => (
                                <BodyRow
                                    key={retrieveRowId(props.item, getRowId)}
                                    columns={columns}
                                    columnSpacing={columnSpacing}
                                    getRowId={getRowId}
                                    checkboxColumnCover={checkboxColumnCover}
                                    checkType={checkType}
                                    bordered={bordered}
                                    divider={divider}
                                    checkedItems={checkedItems}
                                    invalidItems={invalidItems}
                                    onCheckedItemsChange={onCheckedItemsChange}
                                    onClick={onRowClick}
                                    sticky={stickyLastRow && props['data-index'] === data.length - 1}
                                    reorderable={isReorderableRows}
                                    rounded={rounded}
                                    hover={hoverable}
                                    progress={progress}
                                    qa={qa}
                                    getIsDisabledRow={getIsDisabledRow}
                                    isLastRow={props['data-index'] === data.length - 1}
                                    hideBorderLastRow={hideBorderLastRow}
                                    rowActionMenuItems={rowActionMenuItems}
                                    {...props}
                                />
                            ),
                        }}
                        fixedHeaderContent={() => headerRow}
                        endReached={handleEndReached}
                    />
                ) : (
                    <Root
                        $fontSize={fontSize}
                        $reorderableRows={reorderable && !!onReorderRows}
                        $hasRowActionMenu={hasRowActionMenu}
                        data-qa={domHelpers.generateDataQa(qa, 'table')}
                        {...otherProps}
                    >
                        <ColumnsGroup
                            columns={columns}
                            bordered={bordered}
                            checkedItems={checkedItems}
                            checkboxColumnCover={checkboxColumnCover}
                            isReorderableRows={isReorderableRows}
                        />

                        <SortableContext items={columns} strategy={horizontalListSortingStrategy}>
                            <thead>{headerRow}</thead>
                        </SortableContext>

                        <SortableContext items={data} strategy={verticalListSortingStrategy}>
                            <tbody>
                                {progress
                                    ? skeletonData.map((item, index) => (
                                          <BodyRow
                                              key={item.id}
                                              item={item}
                                              progress
                                              checkType={checkType}
                                              checkboxColumnCover={checkboxColumnCover}
                                              bordered={bordered}
                                              divider={divider}
                                              rounded={rounded}
                                              columns={columns}
                                              columnSpacing={columnSpacing}
                                              checkedItems={checkedItems}
                                              isLastRow={index === data.length - 1}
                                              hideBorderLastRow={hideBorderLastRow}
                                              rowActionMenuItems={rowActionMenuItems}
                                          />
                                      ))
                                    : data.map((item, index) => (
                                          <BodyRow
                                              key={retrieveRowId(item, getRowId)}
                                              item={item}
                                              columns={columns}
                                              columnSpacing={columnSpacing}
                                              getRowId={getRowId}
                                              checkboxColumnCover={checkboxColumnCover}
                                              checkType={checkType}
                                              bordered={bordered}
                                              divider={divider}
                                              rounded={rounded}
                                              hover={hoverable}
                                              checkedItems={checkedItems}
                                              invalidItems={invalidItems}
                                              onCheckedItemsChange={onCheckedItemsChange}
                                              onClick={onRowClick}
                                              sticky={stickyLastRow && index === data.length - 1}
                                              reorderable={isReorderableRows}
                                              qa={qa}
                                              getIsDisabledRow={getIsDisabledRow}
                                              isLastRow={index === data.length - 1}
                                              hideBorderLastRow={hideBorderLastRow}
                                              rowActionMenuItems={rowActionMenuItems}
                                          />
                                      ))}
                            </tbody>
                        </SortableContext>
                    </Root>
                )}
            </TableProvider>
        </DndContext>
    );
};

Table.displayName = 'Table';

const TableWithChildrenComponents = componentHelpers.genericMemo(Table) as unknown as (<Item extends BaseItem>(
    props: TableProps<Item>
) => ReactElement) &
    ChildrenComponents;

TableWithChildrenComponents.Cell = BodyCell;
TableWithChildrenComponents.ColumnHeader = ColumnHeader;
TableWithChildrenComponents.FormCell = BodyFormCell;
TableWithChildrenComponents.CellWithState = BodyCellWithState;

export default TableWithChildrenComponents;
