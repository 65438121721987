import { Button, Dropdown, Menu, Text } from '@approvalmax/ui/src/components';
import { intl } from '@approvalmax/utils';
import { memo, useCallback, useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import { getMinDate } from '../../../PayWithAmax.helpers';
import { containerWidth } from '../PaymentSummaryPopupContent.constants';
import { FormState } from '../PaymentSummaryPopupContent.types';
import { messages } from './PaymentSummarySelectedLine.messages';
import { PaymentSummarySelectedLineProps } from './PaymentSummarySelectedLine.types';

export const PaymentSummarySelectedLine = memo<PaymentSummarySelectedLineProps>((props) => {
    const { disabled, items, showSchedulePaymentPopup, onPay } = props;

    const form = useFormContext<FormState>();

    const selectedLineId = useWatch({ name: 'selectedLineId' });

    const selectedLine = useMemo(() => items.find((item) => item.id === selectedLineId), [items, selectedLineId]);

    const handlePayNow = useCallback(() => {
        form.setValue('scheduledDate', null);
        onPay();
    }, [form, onPay]);

    const handleSchedulePayment = useCallback(() => {
        form.setValue('scheduledDate', getMinDate());
        showSchedulePaymentPopup();
    }, [form, showSchedulePaymentPopup]);

    if (!selectedLine) {
        return null;
    }

    return (
        <>
            <Text font='body' fontSize='xsmall' color='midnight70' textAlign='right'>
                {messages.payInfo({
                    amount: intl.formatCurrency(selectedLine.amount, selectedLine.currency),
                    friendlyName: selectedLine.friendlyName,
                })}
            </Text>

            <Dropdown
                width={containerWidth}
                hasArrow={false}
                activator={
                    <Button color='mint80' size='medium' block disabled={disabled}>
                        {messages.pay({
                            amount: intl.formatCurrency(selectedLine.amount, selectedLine.currency),
                        })}
                    </Button>
                }
            >
                <Menu divider>
                    <Menu.Item disabled={disabled} onClick={handlePayNow} name={messages.payNow} />

                    <Menu.Item disabled={disabled} onClick={handleSchedulePayment} name={messages.schedulePayment} />
                </Menu>
            </Dropdown>
        </>
    );
});

PaymentSummarySelectedLine.displayName = 'PaymentSummarySelectedLine';
