import { RequestMutationOptions } from '@approvalmax/data';
import { useMutate } from 'shared/data';

import { budgetsApiPaths } from '../paths';
import { useInvalidateGetQBooksBudgets } from '../useGetQBooksBudgets/useGetQBooksBudgets';
import { SyncQBooksBudgets } from './useSyncQBooksBudgets.types';

export const useSyncQBooksBudgets = (mutationOptions?: RequestMutationOptions<SyncQBooksBudgets>) => {
    const invalidateGetQBooksBudgets = useInvalidateGetQBooksBudgets();

    return useMutate(budgetsApiPaths.qBooksBudgetsSync, {
        mutationOptions: {
            ...mutationOptions,
            onSuccess: async (data, variables, context) => {
                invalidateGetQBooksBudgets({ path: { companyId: variables.params?.path?.companyId } });

                mutationOptions?.onSuccess?.(data, variables, context);
            },
        },
        apiVersion: 'v2',
    });
};
