import './xeroIntegrationSelectPopup.scss';

import { Reference } from '@approvalmax/types';
import { Button, DropdownEditor, Field, Popup, usePopupContext } from '@approvalmax/ui';
import { selectors } from 'modules/common';
import * as companyModule from 'modules/company';
import { backend, domain } from 'modules/data';
import { PlainDataProvider } from 'modules/data-providers';
import { useSelector } from 'modules/react-redux';
import { WarnIcon } from 'modules/sprites';
import { FC, memo, useCallback, useMemo, useState } from 'react';
import bemFactory from 'react-bem-factory';
import { useDispatch } from 'react-redux';
import { amplitudeService } from 'services/amplitude';
import { routingService } from 'services/routing';
import { useInvalidateGetCompanies } from 'shared/data/webApp/v1';
import { getPath, Path } from 'urlBuilder';

import { completeIntegrationAuth } from '../../actions';
import { XeroIntegrationSelectPopupData } from '../../reducers/module/activePopup/xeroIntegrationSelectPopupReducer';
import { getActivePopup } from '../../selectors/moduleSelectors';
import { messages } from './XeroIntegrationSelectPopup.messages';

const bem = bemFactory.block('int-xero-integration-select-popup');
const qa = bemFactory.qa('int-xero-integration-select-popup');

interface XeroIntegrationSelectPopupProps {
    className?: string;
}

interface TenantReference extends Reference {
    raw: domain.XeroTenantInfo;
}

const XeroIntegrationSelectPopup: FC<XeroIntegrationSelectPopupProps> = (props) => {
    const { className } = props;

    const dispatch = useDispatch();
    const data = useSelector((state) => getActivePopup<XeroIntegrationSelectPopupData>(state));
    const company = useSelector((state) =>
        data.companyId ? selectors.company.getCompanyById(state, data.companyId) : null
    );
    const invalidateGetCompanies = useInvalidateGetCompanies();

    const invalidateSelectCompanyQuery = useCallback(() => {
        if (company) {
            void invalidateGetCompanies({ query: { companyId: company.id } });
        }
    }, [company, invalidateGetCompanies]);
    const isLoading = useSelector((state) => selectors.ui.isOperationInProgress(state));

    const tenantsList: TenantReference[] = useMemo(
        () =>
            data.tenants
                .filter((t) => !t.connectedIntegrationId)
                .map((t) => ({
                    id: t.tenantId,
                    text: t.tenantName,
                    raw: t,
                })),
        [data.tenants]
    );

    const [selectedTenant, setSelectedTenant] = useState(tenantsList[0]);
    const { onRequestClose } = usePopupContext();

    async function onDone() {
        let companyId = data.companyId;

        if (!companyId) {
            const companyCreatedInfo: backend.CompanyCreatedInfo | null = (await dispatch(
                companyModule.createOrganisation(domain.IntegrationType.Xero)
            )) as any;

            if (!companyCreatedInfo) {
                return;
            }

            companyId = companyCreatedInfo.CompanyId;

            routingService.push(getPath(Path.companyWorkflows, companyId));
        }

        amplitudeService.sendData('signup: confirm xero org connection');
        onRequestClose();
        dispatch(
            completeIntegrationAuth(
                {
                    integrationType: domain.IntegrationType.Xero,
                    newCompany: !data.companyId,
                    tenantId: selectedTenant.id,
                    companyId,
                    state: data.backendState,
                },
                invalidateSelectCompanyQuery
            )
        );
    }

    return (
        <Popup.DefaultContent
            className={bem.add(className)()}
            qa={qa()}
            title={messages.popupTitle}
            buttons={
                <Button
                    qa={qa('done-button')}
                    execute={onDone}
                    disabled={isLoading}
                    height='small30'
                    fontSize='small13'
                >
                    {messages.doneButtonText}
                </Button>
            }
        >
            <div className={bem('content')}>
                <div className={bem('message')}>{messages.message}</div>

                <div className={bem('warning')}>
                    <WarnIcon className={bem('warning-icon')} />

                    <div className={bem('warning-text')}>{messages.warningText}</div>
                </div>

                <div className={bem('xero-org-block')}>
                    <Field title={messages.yourXeroOrg} required>
                        <PlainDataProvider items={tenantsList}>
                            <DropdownEditor
                                value={selectedTenant}
                                onChange={(tenant: TenantReference) => setSelectedTenant(tenant)}
                                disabled={isLoading}
                                buttonComponent={DropdownEditor.NoFilterButton}
                            />
                        </PlainDataProvider>
                    </Field>
                </div>
            </div>
        </Popup.DefaultContent>
    );
};

export default memo(XeroIntegrationSelectPopup);
