import { toast } from '@approvalmax/ui/src/components';
import { zodResolver } from '@hookform/resolvers/zod';
import { actions as actionsProfile } from 'modules/profile';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useMount } from 'react-use';
import { useFinishAddingTwoFaBackupCodes, useStartAddingTwoFaBackupCodes } from 'shared/data/webApp/v1';
import { z } from 'zod';

import { fieldNames } from './BackupCodesAddPopup.constants';
import { messages } from './BackupCodesAddPopup.messages';
import { schema } from './BackupCodesAddPopup.schema';
import { BackupCodesAddPopupProps } from './BackupCodesAddPopup.types';

export const useBackupCodesAddPopup = (props: BackupCodesAddPopupProps) => {
    const { onClose } = props;

    const dispatch = useDispatch();

    const form = useForm<z.infer<typeof schema>>({
        defaultValues: {
            [fieldNames.stateId]: null,
            [fieldNames.codesAreSaved]: false,
        },
        resolver: zodResolver(schema),
    });

    const {
        mutate: startAddingTwoFaBackupCodes,
        isLoading: isLoadingStartAddingTwoFaBackupCodes,
        data,
    } = useStartAddingTwoFaBackupCodes({
        onSuccess: (data) => {
            form.setValue(fieldNames.stateId, data.stateId);
        },
    });

    const { mutate: finishAddingTwoFaBackupCodes, isLoading: isLoadingFinishAddingTwoFaBackupCodes } =
        useFinishAddingTwoFaBackupCodes({
            onSuccess: () => {
                toast.info(messages.onSuccess);
                dispatch(actionsProfile.changeTwoFaBackupCodesIsEnabled(true));
                onClose();
            },
        });

    const formSubmit = form.handleSubmit((data) => {
        if (data.stateId) {
            finishAddingTwoFaBackupCodes({ body: { stateId: data.stateId } });
        }
    });

    useMount(() => {
        startAddingTwoFaBackupCodes({ body: {} });
    });

    const fieldCodesAreSaved = form.watch(fieldNames.codesAreSaved);

    return {
        form,
        formSubmit,
        canSubmit: fieldCodesAreSaved,
        backupCodes: data?.backupCodes || [],
        progress: isLoadingStartAddingTwoFaBackupCodes || isLoadingFinishAddingTwoFaBackupCodes,
    };
};
