import { domain } from 'modules/data';

import { messages } from './SectionNetSuiteFraudChangesAfter.messages';

export const getNetSuiteFraudChangesAfterFields = (integrationCode: domain.IntegrationCode | null) => {
    const { Customer, Payee, Vendor, Amount, Date, Category, Memo, Item, Account, Line } =
        domain.TemplateSettingsNetSuitePostApprovalChangeDetectionTarget;

    const commonFraudChangesAfterFields = [Amount, Date, Memo];

    if (integrationCode === domain.IntegrationCode.NetSuiteBillPayment) {
        return [Payee, ...commonFraudChangesAfterFields, Line];
    }

    if (
        integrationCode === domain.IntegrationCode.NetSuiteInvoice ||
        integrationCode === domain.IntegrationCode.NetSuiteSalesOrder
    ) {
        return [Customer, ...commonFraudChangesAfterFields, Item];
    }

    if (integrationCode === domain.IntegrationCode.NetSuiteJournalEntry) {
        return [...commonFraudChangesAfterFields, Account, Line];
    }

    return [Vendor, ...commonFraudChangesAfterFields, Item, Account, Category];
};

export const getNetSuiteFraudChangesAfterFieldCheckBoxLabel = (
    target: domain.TemplateSettingsNetSuitePostApprovalChangeDetectionTarget,
    companyCurrencyCode: string,
    integrationCode: domain.IntegrationCode | null
) => {
    switch (target) {
        case domain.TemplateSettingsNetSuitePostApprovalChangeDetectionTarget.Customer:
            return messages.optionCustomer;

        case domain.TemplateSettingsNetSuitePostApprovalChangeDetectionTarget.Payee:
            return messages.optionPayee;

        case domain.TemplateSettingsNetSuitePostApprovalChangeDetectionTarget.Vendor:
            return messages.optionVendor;

        case domain.TemplateSettingsNetSuitePostApprovalChangeDetectionTarget.Amount:
            return messages.optionTotalAmount({ companyCurrencyCode });

        case domain.TemplateSettingsNetSuitePostApprovalChangeDetectionTarget.Date:
            return messages.optionDate;

        case domain.TemplateSettingsNetSuitePostApprovalChangeDetectionTarget.Category:
            return messages.optionExpenseCategory;

        case domain.TemplateSettingsNetSuitePostApprovalChangeDetectionTarget.Memo:
            return messages.optionMemo;

        case domain.TemplateSettingsNetSuitePostApprovalChangeDetectionTarget.Item:
            return messages.optionItem;

        case domain.TemplateSettingsNetSuitePostApprovalChangeDetectionTarget.Account:
            return messages.optionAccount;

        case domain.TemplateSettingsNetSuitePostApprovalChangeDetectionTarget.Line:
            return integrationCode === domain.IntegrationCode.NetSuiteBillPayment
                ? messages.optionBillPaymentLine
                : messages.optionLine;
    }
};
