import { DeleteIcon } from '@approvalmax/ui';
import { Button, DateTimePicker, Form, Grid, Select, Text } from '@approvalmax/ui/src/components';
import { selectors } from 'modules/common';
import { memo, useMemo } from 'react';
import { SelectUserMenuItem } from 'shared/components';
import { delegationHelpers } from 'shared/helpers';
import { useTimeZoneSelect } from 'shared/hooks/useTimeZoneSelect';

import { messages } from './FormContent.messages';
import type { FormContentProps } from './FormContent.types';

const isDisabledForCompany = (company: selectors.types.ExpandedCompany) => {
    const isExpired = company.flags.isExpired && !company.flags.isGraceSubscription;

    return company.flags.isRetired || isExpired || company.isReadonly;
};

export const FormContent = memo<FormContentProps>((props) => {
    const { delegate, index, onDelete } = props;

    const disabled = !delegate.delegateId || isDisabledForCompany(delegate.company);

    const nowDateWithOffset = useMemo(() => delegationHelpers.getNowAtTimeZone(delegate.timeZone), [delegate.timeZone]);

    const dateFromMinDate = nowDateWithOffset;
    const dateFromMaxDate = delegate.dateTo ?? undefined;
    const dateToMinDate = delegate.dateFrom ?? nowDateWithOffset;

    return (
        <Form.Part>
            <Grid gridTemplateColumns='auto 140px 180px 170px 170px 24px' alignItems='center' gap={8}>
                <Text font='label' fontSize='small'>
                    {delegate.company.displayName}
                </Text>

                <Select
                    size='small'
                    name={`delegates.${index}.delegateId`}
                    itemNameKey='displayName'
                    dropdownWidth='max-content'
                    disabled={isDisabledForCompany(delegate.company)}
                    items={delegate.possibleDelegates}
                    placeholder={messages.selectPlaceholder}
                    customMenuItem={({ key, ...item }) => <SelectUserMenuItem key={key} {...item} />}
                />

                <Select
                    size='small'
                    name={`delegates.${index}.timeZone`}
                    dropdownWidth='max-content'
                    clearable={false}
                    disabled={disabled}
                    {...useTimeZoneSelect(delegate.company.id)}
                />

                <DateTimePicker
                    size='small'
                    required={Boolean(delegate.delegateId)}
                    name={`delegates.${index}.dateFrom`}
                    disabled={disabled}
                    minDate={dateFromMinDate}
                    maxDate={dateFromMaxDate}
                    placeholder={messages.placeholder}
                />

                <DateTimePicker
                    size='small'
                    name={`delegates.${index}.dateTo`}
                    disabled={disabled}
                    minDate={dateToMinDate}
                    placeholder={messages.placeholder}
                />

                <Button
                    noPadding
                    disabled={!delegate.delegateId}
                    onClick={onDelete(index)}
                    variant='text'
                    color='blue100'
                >
                    <DeleteIcon />
                </Button>
            </Grid>
        </Form.Part>
    );
});

FormContent.displayName = 'FormContent';
