import { Popup } from '@approvalmax/ui';
import styled from 'styled-components';

export const DefaultContent = styled(Popup.DefaultContent)`
    min-width: 50%;
    height: 95%;
    width: 95%;
    overflow: auto;
`;

export const ContentContainer = styled.div`
    padding: 0 60px 30px 80px;
`;
