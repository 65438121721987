import { useMemo } from 'react';

import { BaseItem } from '../../Table.types';
import { CellStyled } from './BodyCellWithState.styles';
import { BodyCellWithStateProps } from './BodyCellWithState.types';

export const BodyCellWithState = <Item extends BaseItem>(props: BodyCellWithStateProps<Item>) => {
    const { invalid, children, columnDefinition, spacing, ...otherProps } = props;

    const preparedColumnDefinition = useMemo(
        () =>
            spacing
                ? {
                      ...columnDefinition,
                      spacing,
                  }
                : columnDefinition,
        [columnDefinition, spacing]
    );

    return (
        <CellStyled {...otherProps} columnDefinition={preparedColumnDefinition} $invalid={invalid}>
            {children}
        </CellStyled>
    );
};

BodyCellWithState.displayName = 'BodyCellWithState';
