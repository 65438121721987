import { RequestMutationOptions } from '@approvalmax/data';
import { ErrorCode } from '@approvalmax/types';
import { toast } from '@approvalmax/ui/src/components';
import { notificationService } from 'services/notification';
import { useMutate } from 'shared/data';

import { requestsApiPaths } from '../paths';
import { messages } from './useSetCustomerDecisionInQuote.messages';
import { SetCustomerDecisionInQuote } from './useSetCustomerDecisionInQuote.types';

export const useSetCustomerDecisionInQuote = (mutationOptions?: RequestMutationOptions<SetCustomerDecisionInQuote>) => {
    return useMutate(requestsApiPaths.setCustomerDecisionInQuote, {
        mutationOptions: {
            ...mutationOptions,
            onSuccess: (data, requestParams, context) => {
                if (requestParams.body?.isAccepted) {
                    notificationService.showInfoToast(messages.acceptedRequest);
                } else {
                    notificationService.showInfoToast(messages.declinedRequest);
                }

                mutationOptions?.onSuccess?.(data, requestParams, context);
            },
            onError: (error, requestParams, context) => {
                if (error?.code === ErrorCode.E5043_XERO_QUOTE_UNAVAILABLE_FOR_CUSTOMER_DECISION) {
                    toast.error(messages.unavailableQuoteError);
                } else {
                    toast.error(error.message);
                }

                mutationOptions?.onError?.(error, requestParams, context);
            },
        },
        apiVersion: 'v1',
        method: 'post',
    });
};
