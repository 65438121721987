import { mixins, Mods, mods, theme } from '@approvalmax/theme';
import { StyledMods } from '@styled-kit/mods';
import styled from 'styled-components';

import { drawerWidth, openingDuration } from './Drawer.constants';

export const ExpandLine = styled.button`
    width: 4px;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 1px;
    background-color: ${theme.color.midnight20};
    border: 1px solid ${theme.color.midnight20};
    cursor: pointer;
    transition: ${theme.duration.medium}ms;

    &:hover {
        width: 6px;
        background-color: ${theme.color.blue20};
        border-color: ${theme.color.blue40};
        cursor: pointer;
    }
`;

export const Body = styled.div<StyledMods<Mods<'collapsed'> & { displaying?: boolean }>>`
    width: ${drawerWidth}px;
    padding: 0 8px 48px;
    transition: padding ${openingDuration}ms ease-in-out;

    ${mods.collapsed.true`
        padding: 0 4px 24px;

        ${mods('displaying', false)`
            width: auto;
        `};
    `};
`;

export const Content = styled.div<StyledMods<Mods<'open' | 'pinned' | 'collapsed'> & { displaying?: boolean }>>`
    width: ${drawerWidth}px;
    height: 100%;
    border-right: 1px solid ${theme.color.midnight50};
    overflow-y: auto;
    overflow-x: hidden;
    transition: ${openingDuration}ms ease-in-out;
    transform: translateZ(0);
    background-color: ${theme.color.white100};

    ${mods.pinned.false`
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
    `};

    ${mods.open.true`
        box-shadow: ${theme.shadow.small};
        z-index: 100;
    `};

    ${mods.collapsed.true`
        position: relative;
        width: 56px;
        -ms-overflow-style: none;
        scrollbar-width: none;

        &::-webkit-scrollbar {
            display: none;
        }
    `};

    ${mods('displaying', true)`
        position: fixed;
        z-index: 100;
    `};
`;

export const Overlay = styled.div`
    ${mixins.position.fixedFill};
    z-index: 100;
`;

export const Root = styled.aside<StyledMods<Mods<'pinned'>>>`
    min-width: 56px;
    flex-shrink: 0;
    position: relative;
    height: 100%;

    ${mods.pinned.true`
        width: ${drawerWidth}px;
    `};
`;
