import { errorHelpers } from '@approvalmax/utils';
import { selectors } from 'modules/common';
import { domain } from 'modules/data';
import { useSelector } from 'modules/react-redux';
import { font } from 'modules/styles';
import { LineItemsTable } from 'pages/requestList/components/lineItemsSection/LineItemsTable';
import { memo, useMemo } from 'react';
import styled from 'styled-components';

import { getColumnDefinitions } from '../../QBooksMatchingPopupOld.config';
import { messages } from './SelectedLineInformation.messages';

const Container = styled.div`
    padding: 20px;
    background-color: #f3f3f3;
`;

const Title = styled.div`
    ${font(13, '#000', 'semibold')}
    text-transform: uppercase;
`;

interface SelectedLineInformationProps {
    accountLineItem: domain.QBooksAccountLineItem | null;
    lineItem: domain.QBooksLineItem | null;
    requestId: string;
}

export const SelectedLineInformation = memo<SelectedLineInformationProps>((props) => {
    const { accountLineItem, lineItem, requestId } = props;

    const request = useSelector((state) =>
        selectors.request.getRequestById<
            selectors.types.QBooksBillExpandedRequest | selectors.types.QBooksPoExpandedRequest
        >(state, requestId)
    );

    if (request.integrationType !== domain.IntegrationType.QBooks) {
        throw errorHelpers.invalidOperationError();
    }

    const columnDefinitions = useMemo(
        () => getColumnDefinitions(request, Boolean(accountLineItem), true),
        [accountLineItem, request]
    );

    return (
        <Container>
            <Title>{messages.title}</Title>

            <LineItemsTable columnDefinitions={columnDefinitions} lineItems={[accountLineItem || lineItem]} />
        </Container>
    );
});

SelectedLineInformation.displayName = 'SelectedLineInformation';
