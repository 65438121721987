import { ValueOf } from '@approvalmax/types';

import { BackupMethodOptions } from './TwoFaEnablingWizard.constants';

export enum WizardStep {
    codeScan = 'codeScan',
    codeVerify = 'codeVerify',
    backupMethods = 'backupMethods',
    backupCodes = 'backupCodes',
    backupEmailEnter = 'backupEmailEnter',
    backupEmailVerify = 'backupEmailVerify',
}

export interface TwoFaEnablingWizardProps {
    onFinish: Exclude<TwoFaEnablingWizardController['onFinish'], undefined>;
}

export interface TwoFaEnablingWizardController {
    title?: string;
    stateId?: string;
    setupCode?: string;
    email?: string;
    onNext?: VoidFunction;
    onPrev?: VoidFunction;
    onFinish?: (backupMethod: ValueOf<BackupMethodOptions> | null) => void;
}
