import { arrayHelpers, compareHelpers, hooks } from '@approvalmax/utils';
import { selectors } from 'modules/common';
import { useCallback, useMemo, useState } from 'react';
import { useMount, useToggle } from 'react-use';
import { amplitudeService } from 'services/amplitude';
import { WorkflowsGettingStarted } from 'urlBuilder';

import { UseStarterGuideParams } from './StandaloneWorkflowList.types';

export const useSearch = (workflows: selectors.types.ExpandedTemplate[]) => {
    const [isShownSearch, toggleIsShownSearch] = useToggle(false);
    const [searchText, setSearchText] = useState('');

    const sortedWorkflows = arrayHelpers.arraySort(workflows, (a, b) =>
        compareHelpers.stringComparator2AscI(a.templateName, b.templateName)
    );

    const filteredWorkflows = useMemo(() => {
        if (!searchText) return sortedWorkflows;

        return sortedWorkflows.filter(({ templateName = '' }) =>
            templateName.toLowerCase().includes(searchText.toLowerCase())
        );
    }, [searchText, sortedWorkflows]);

    const toggleSearch = useCallback(() => {
        toggleIsShownSearch();
        setSearchText('');
    }, [toggleIsShownSearch]);

    const onFilterTextChange = useCallback((value: string) => setSearchText(value || ''), [setSearchText]);

    return {
        isShownSearch,
        toggleSearch,
        onFilterTextChange,
        filteredWorkflows,
    };
};

export const useStarterGuide = (params: UseStarterGuideParams) => {
    const { openTour, profileId, companyId, canShow } = params;

    const [gettingStarted, _, cleanGettingStarted] = hooks.useQueryParam('gettingStarted');

    useMount(() => {
        const fromGettingStarted = gettingStarted === WorkflowsGettingStarted.tour;

        if (!fromGettingStarted) {
            return;
        }

        cleanGettingStarted();

        if (!openTour || !canShow) {
            return;
        }

        amplitudeService.sendData('workflow: land on getting started tutorial for stand-alone', {
            user_id: profileId,
            org_id: companyId,
        });
        openTour();
    });
};
