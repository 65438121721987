import { hooks } from '@approvalmax/utils';
import { FC } from 'react';

import { CloseIcon } from '../../icons';
import Button from '../Button/Button';
import { useClosable } from './Alert.hooks';
import { AnimatedBox, Content, Icon, Root } from './Alert.styles';
import { AlertProps } from './Alert.types';

/**
 * An alert displays a short, important message in a way that attracts the user's attention without interrupting the user's task.
 */
const Alert: FC<AlertProps> = (props) => {
    const {
        color = 'transparent',
        size = 'medium',
        startIcon,
        closable,
        onClose,
        open = true,
        children,
        invalid,
        alignItems,
        ...restProps
    } = props;

    const { isOpen, handleClose } = useClosable({ onClose, open });
    const { animatedStyles, animatedRef } = hooks.useAnimatedHeight(isOpen);
    const iconSize = size === 'medium' ? 20 : 16;

    return (
        <AnimatedBox style={animatedStyles}>
            <div ref={animatedRef}>
                <Root $color={color} $size={size} $invalid={invalid} $alignItems={alignItems} {...restProps}>
                    {startIcon && <Icon>{startIcon}</Icon>}

                    <Content>{children}</Content>

                    {closable && (
                        <Button onClick={handleClose} icon variant='text' color='blue100' noPadding>
                            <CloseIcon size={iconSize} />
                        </Button>
                    )}
                </Root>
            </div>
        </AnimatedBox>
    );
};

export default Alert;
