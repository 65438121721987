import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('pages.RequestList.NetSuiteRequestCard.NetSuitePurchaseOrderFields', {
    nsVendorReferenceFieldText: 'Reference',
    nsPOReferenceFieldText: 'Transaction Number',
    nsTermsFieldText: 'Terms',
    nsDateFieldText: 'Date',
    nsDueDateFieldText: 'Receive By',
    nsEmployeeFieldText: 'Employee',
    nsIncotermsFieldText: 'Incoterm',
    nsDepartmentFieldText: 'Department',
    nsClassFieldText: 'Class',
    nsLocationFieldText: 'Location',
    nsMemoFieldText: 'Memo',
});
