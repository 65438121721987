import { Reference } from '@approvalmax/types';
import { errorHelpers } from '@approvalmax/utils';
import { backend, du } from 'modules/data';
import moment from 'moment';

import { messages } from './dateRangeFilter.messages';
import FilterType from './FilterType';

export enum DateRangeFilterRangeType {
    None = 'None',
    Custom = 'Custom',
    Today = 'Today',
    Yesterday = 'Yesterday',
    Tomorrow = 'Tomorrow',
    ThisWeek = 'ThisWeek',
    LastWeek = 'LastWeek',
    NextWeek = 'NextWeek',
    ThisMonth = 'ThisMonth',
    LastMonth = 'LastMonth',
    NextMonth = 'NextMonth',
}

export interface DateRangeFilter {
    type: FilterType.DateRange;
    rangeType: DateRangeFilterRangeType;
    custom: {
        greaterOrEqual: string | null;
        lessOrEqual: string | null;
    };
}

export function createDateRangeFilter(
    rangeType: DateRangeFilterRangeType,
    custom: {
        greaterOrEqual: string | null;
        lessOrEqual: string | null;
    } = { greaterOrEqual: null, lessOrEqual: null }
): DateRangeFilter {
    return {
        type: FilterType.DateRange,
        rangeType,
        custom,
    };
}

export function createAlwaysTrueFilter() {
    return createDateRangeFilter(DateRangeFilterRangeType.None);
}

const mapDateRangeFilterRangeType = du.getForwardEnumMapper(backend.DateRangeType, DateRangeFilterRangeType);

function mapDateRangeFilterRangeTypeBack(value: DateRangeFilterRangeType): backend.DateRangeType {
    switch (value) {
        case DateRangeFilterRangeType.None:
            throw errorHelpers.notSupportedError();

        case DateRangeFilterRangeType.Custom:
            return backend.DateRangeType.Custom;

        case DateRangeFilterRangeType.Today:
            return backend.DateRangeType.Today;

        case DateRangeFilterRangeType.Yesterday:
            return backend.DateRangeType.Yesterday;

        case DateRangeFilterRangeType.Tomorrow:
            return backend.DateRangeType.Tomorrow;

        case DateRangeFilterRangeType.ThisWeek:
            return backend.DateRangeType.ThisWeek;

        case DateRangeFilterRangeType.LastWeek:
            return backend.DateRangeType.LastWeek;

        case DateRangeFilterRangeType.NextWeek:
            return backend.DateRangeType.NextWeek;

        case DateRangeFilterRangeType.ThisMonth:
            return backend.DateRangeType.ThisMonth;

        case DateRangeFilterRangeType.LastMonth:
            return backend.DateRangeType.LastMonth;

        case DateRangeFilterRangeType.NextMonth:
            return backend.DateRangeType.NextMonth;

        default:
            throw errorHelpers.assertNever(value);
    }
}

export function parseFilter(filterAnswer: any): DateRangeFilter {
    let rangeType = DateRangeFilterRangeType.None;

    if (filterAnswer && Number.isInteger(filterAnswer.Type)) {
        rangeType = mapDateRangeFilterRangeType(filterAnswer.Type);
    }

    let greaterOrEqual = null;
    let lessOrEqual = null;

    if (rangeType === DateRangeFilterRangeType.Custom && filterAnswer.Custom) {
        if (filterAnswer.Custom.GreaterOrEqual) {
            greaterOrEqual = moment.utc(filterAnswer.Custom.GreaterOrEqual).toISOString();
        }

        if (filterAnswer.Custom.LessOrEqual) {
            lessOrEqual = moment.utc(filterAnswer.Custom.LessOrEqual).toISOString();
        }
    }

    return {
        type: FilterType.DateRange,
        rangeType,
        custom: {
            greaterOrEqual,
            lessOrEqual,
        },
    };
}

export function getFilterTransfer(filter: DateRangeFilter): any {
    if (filter.rangeType === DateRangeFilterRangeType.None) {
        return null;
    }

    let custom;

    const rangeType = mapDateRangeFilterRangeTypeBack(filter.rangeType);

    if (filter.rangeType === DateRangeFilterRangeType.Custom) {
        custom = {
            lessOrEqual: filter.custom.lessOrEqual ? moment.utc(filter.custom.lessOrEqual).format('YYYY-MM-DD') : null,
            greaterOrEqual: filter.custom.greaterOrEqual
                ? moment.utc(filter.custom.greaterOrEqual).format('YYYY-MM-DD')
                : null,
        };

        return {
            type: rangeType,
            custom,
        };
    }

    return {
        type: rangeType,
    };
}

const rangeTypeOptions: Reference[] = [
    {
        id: DateRangeFilterRangeType.None,
        text: messages.rangeTypeTextNone,
    },
    {
        id: DateRangeFilterRangeType.Custom,
        text: messages.rangeTypeTextCustom,
    },
    {
        id: DateRangeFilterRangeType.Today,
        text: messages.rangeTypeTextToday,
    },
    {
        id: DateRangeFilterRangeType.Yesterday,
        text: messages.rangeTypeTextYesterday,
    },
    {
        id: DateRangeFilterRangeType.Tomorrow,
        text: messages.rangeTypeTextTomorrow,
    },
    {
        id: DateRangeFilterRangeType.ThisWeek,
        text: messages.rangeTypeTextThisWeek,
    },
    {
        id: DateRangeFilterRangeType.LastWeek,
        text: messages.rangeTypeTextLastWeek,
    },
    {
        id: DateRangeFilterRangeType.NextWeek,
        text: messages.rangeTypeTextNextWeek,
    },
    {
        id: DateRangeFilterRangeType.ThisMonth,
        text: messages.rangeTypeTextThisMonth,
    },
    {
        id: DateRangeFilterRangeType.LastMonth,
        text: messages.rangeTypeTextLastMonth,
    },
    {
        id: DateRangeFilterRangeType.NextMonth,
        text: messages.rangeTypeTextNextMonth,
    },
];

export function getRangeTypeOptions() {
    return rangeTypeOptions;
}

export function getFilterPreviewText(filter: DateRangeFilter): string | null {
    switch (filter.rangeType) {
        case DateRangeFilterRangeType.None:
            return null;

        case DateRangeFilterRangeType.Custom: {
            let startDate = filter.custom.greaterOrEqual;
            let endDate = filter.custom.lessOrEqual;

            if (!startDate && !endDate) {
                return null;
            }

            if (startDate && endDate) {
                // full range
                return messages.previewTextCustomFull({
                    startDate: moment.utc(startDate).format('ll'),
                    endDate: moment.utc(endDate).format('ll'),
                });
            } else if (startDate) {
                // start date only
                return messages.previewTextCustomStart({
                    startDate: moment.utc(startDate).format('ll'),
                });
            } else {
                // end date only
                return messages.previewTextCustomEnd({
                    endDate: moment.utc(endDate!).format('ll'),
                });
            }
        }

        default:
            return rangeTypeOptions.find((x) => x.id === filter.rangeType)!.text;
    }
}
