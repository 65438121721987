import { readyFunction, widgetCode } from './helpers';
import { User } from './types';

export const salesiqService = {
    init(user: User) {
        window.$zoho = window.$zoho || {};

        if (!window.$zoho.salesiq) {
            window.$zoho.salesiq = {
                mode: 'async',
                widgetcode: widgetCode,
                values: {},
                ready: readyFunction(user),
            };
        }

        const zohoScriptTag = document.getElementById('zsiqscript');

        if (!zohoScriptTag) {
            const s = document.createElement('script');

            s.type = 'text/javascript';
            s.id = 'zsiqscript';
            s.defer = true;
            s.src = 'https://salesiq.zoho.com/widget';

            const t = document.getElementsByTagName('script')[0];

            if (t && t.parentNode) {
                t.parentNode.insertBefore(s, t);
            }

            const widget = document.createElement('div');

            widget.id = 'zsiqwidget';
            document.body.appendChild(widget);
        } else {
            readyFunction(user)();
        }
    },
};
