import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('modules.common.HelpSection', {
    kbDropdownText: 'Knowledge Base',
    videosDropdownText: 'How-to videos',
    trainingDropdownText: 'Setup and training package',
    trainingTitle: "What's included:",
    trainingDescription: `
       • Requirements and scoping session{br}
       • Help with workflow implementation{br}
       • User and Administrator training{br}
       {br}
       A standard package, yet customised to your needs!`,
    contactSales: 'Contact us',
    hideButtonText: 'Hide',
});
