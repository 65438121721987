import { atom } from 'jotai';
import { selectAtom } from 'jotai/utils';
import { domain } from 'modules/data';

export const userBetaFeaturesArrayState = atom<domain.ProfileBetaFeature[]>([]);

type UserBetaFeaturesStatus = {
    [K in domain.ProfileBetaFeature as `is${Capitalize<K>}`]: boolean;
};

/**
 * @deprecated Direct usage is prohibited. Please use a {@link useUserBetaFeatures} hook instead
 */
export const userBetaFeaturesStatusState = selectAtom(userBetaFeaturesArrayState, (currentBetaFeatures) => {
    // Do not move `allCompanyBetaFeatures` or `initialStatus` to the global scope.
    // Otherwise, errors occur in tests or runtime.
    const allBetaFeatures = Object.values(domain.ProfileBetaFeature);
    const initialStatus = Object.fromEntries(allBetaFeatures.map((feature) => [`is${feature}`, false]));

    currentBetaFeatures.forEach((feature) => {
        const key = `is${feature}`;

        if (key in initialStatus) {
            initialStatus[key] = true;
        }
    });

    return initialStatus as UserBetaFeaturesStatus;
});
