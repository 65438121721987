import { useApiContext } from '@approvalmax/data';
import { Button, Grid, Text, toast } from '@approvalmax/ui/src/components';
import { constants } from '@approvalmax/utils';
import { useEffect, useRef } from 'react';
import { useToggle, useUnmount } from 'react-use';
import { storageService } from 'services/storage';

import { messages } from './useNewAppVersionNotification.messages';

/**
 * @see https://approvalmax.atlassian.net/browse/AM-15134
 */
export const useNewAppVersionNotification = () => {
    const isAuthenticated = storageService.authenticated();

    const [open, toggleOpen] = useToggle(false);
    const { webAppVersion } = useApiContext();
    const lastDisplayTimeReloadPopup = useRef<Date>();
    const hasScheduledToast = useRef<boolean>(false);
    const toastTimeout = useRef<NodeJS.Timeout | null>(null);

    const isCheckToastDisplay =
        isAuthenticated &&
        constants.isProd &&
        webAppVersion &&
        webAppVersion !== window.ApprovalMax.appVersion &&
        !hasScheduledToast.current;

    useUnmount(() => {
        if (toastTimeout.current) {
            clearTimeout(toastTimeout.current);
        }
    });

    useEffect(() => {
        if (!isCheckToastDisplay) return;

        const nextPopupTime = lastDisplayTimeReloadPopup.current
            ? new Date(lastDisplayTimeReloadPopup.current.getTime() + 48 * 60 * 60 * 1000)
            : new Date(0);

        if (new Date() > nextPopupTime) {
            lastDisplayTimeReloadPopup.current = new Date();
            hasScheduledToast.current = true;

            toastTimeout.current = setTimeout(
                () => {
                    toggleOpen(true);
                },
                Math.random() * 8 * 60 * 60 * 1000
            );
        }
    }, [isCheckToastDisplay, toggleOpen]);

    useEffect(() => {
        if (open) {
            toast.default(
                <Grid gap={8}>
                    <Text font='headline' fontSize='xxsmall' fontWeight='medium'>
                        {messages.title}
                    </Text>

                    <Text font='body' fontSize='small'>
                        {messages.description({ br: <br /> })}
                    </Text>

                    <Text font='body' fontSize='small'>
                        {messages.reloadText}
                    </Text>

                    <div>
                        <Button onClick={() => location.reload()} size='small' color='blue80'>
                            {messages.reload}
                        </Button>
                    </div>
                </Grid>,
                {
                    autoClose: false,
                    position: 'bottom-right',
                    onClose: () => {
                        toggleOpen(false);
                        hasScheduledToast.current = false;
                    },
                }
            );
        }
    }, [open, toggleOpen]);
};
