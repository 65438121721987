import { Popup as NewPopup } from '@approvalmax/ui/src/components';
import { useAtom } from 'jotai';
import { useCallback } from 'react';

import { BulkCreationPopupContent } from './components/xero/BulkCreationPopupContent/BulkCreationPopupContent';
import { bulkCreationPopupOpenState } from './components/xero/BulkCreationPopupContent/BulkCreationPopupContent.states';
import { OcrNotificationPopupContent } from './components/xero/OcrNotificationPopupContent/OcrNotificationPopupContent';
import { ocrNotificationPopupOpenState } from './components/xero/OcrNotificationPopupContent/OcrNotificationPopupContent.states';

export const ModuleComponent = () => {
    const [bulkCreationPopupOpen, setBulkCreationPopupOpen] = useAtom(bulkCreationPopupOpenState);
    const [ocrNotificationPopupOpen, setOcrNotificationPopupOpen] = useAtom(ocrNotificationPopupOpenState);

    const closeBulkCreationPopup = useCallback(() => {
        setBulkCreationPopupOpen(false);
    }, [setBulkCreationPopupOpen]);

    const onToggleBulkCreationPopup = useCallback(
        (open: boolean) => {
            !open && setBulkCreationPopupOpen(false);
        },
        [setBulkCreationPopupOpen]
    );

    const onToggleOcrFailedPopup = useCallback(
        (open: boolean) => {
            !open && setOcrNotificationPopupOpen(false);
        },
        [setOcrNotificationPopupOpen]
    );

    return (
        <>
            <NewPopup size='large' open={bulkCreationPopupOpen} closable onToggle={onToggleBulkCreationPopup}>
                <BulkCreationPopupContent closePopup={closeBulkCreationPopup} />
            </NewPopup>

            <NewPopup size='small' open={ocrNotificationPopupOpen} closable onToggle={onToggleOcrFailedPopup}>
                <OcrNotificationPopupContent />
            </NewPopup>
        </>
    );
};

ModuleComponent.displayName = 'ModuleComponent';
