import { Button, Flex, Form, Spacing, Text, TextField } from '@approvalmax/ui/src/components';
import { memo } from 'react';
import bemFactory from 'react-bem-factory';

import { AnotherMethodButton } from '../AnotherMethodButton/AnotherMethodButton';
import { StepperTwoFaWizard } from '../StepperTwoFaWizard/StepperTwoFaWizard';
import { useStepBackupEmail } from './StepBackupEmailVerify.hooks';
import { messages } from './StepBackupEmailVerify.messages';

const qa = bemFactory.qa('pro-two-fa-setup-email-confirm');

export const StepBackupEmailVerify = memo(() => {
    const { form, handleSubmit, email, isLoading, isDisabled, handleGoStepEmail, handleRequestCode } =
        useStepBackupEmail();

    return (
        <Form form={form} onSubmit={handleSubmit}>
            <Flex direction='column' spacing='24' data-qa={qa()}>
                <StepperTwoFaWizard activeStep={2} />

                <Flex direction='column' spacing='16'>
                    <Flex direction='column' spacing='8'>
                        <Text font='label' fontSize='large' fontWeight='regular'>
                            {messages.title}
                        </Text>

                        <Flex direction='row' spacing='8'>
                            <Text font='headline' fontSize='xsmall' fontWeight='medium' spacing='0'>
                                {email}
                            </Text>

                            <Button
                                noPadding
                                noUppercase
                                color='blue100'
                                size='medium'
                                fontWeight='regular'
                                variant='text'
                                disabled={isLoading}
                                onClick={handleGoStepEmail}
                            >
                                {messages.buttonChangeAddress}
                            </Button>
                        </Flex>
                    </Flex>

                    <Text font='body' fontSize='medium' fontWeight='regular'>
                        {messages.description}
                    </Text>
                </Flex>

                <Flex direction='row' spacing='32'>
                    <TextField
                        width={240}
                        name='emailVerificationCode'
                        required
                        initFocus
                        disabled={isLoading}
                        label={messages.emailCodeLabel}
                        placeholder={messages.emailCodePlaceholder}
                    />

                    <Flex direction='column' spacing='0'>
                        <Spacing height={16} />

                        <Button
                            size='medium'
                            variant='text'
                            color='blue100'
                            disabled={isLoading}
                            onClick={handleRequestCode}
                        >
                            {messages.buttonResendCode}
                        </Button>
                    </Flex>
                </Flex>

                <Flex direction='column' spacing='16'>
                    <Button type='submit' size='large' color='blue80' disabled={isDisabled} progress={isLoading}>
                        {messages.buttonNext}
                    </Button>

                    <AnotherMethodButton />
                </Flex>
            </Flex>
        </Form>
    );
});

StepBackupEmailVerify.displayName = 'StepBackupEmailVerify';
