import type { RequestMutationOptions } from '@approvalmax/data';
import { useMutate } from 'shared/data/api';

import { paymentServicesApiPaths } from '../paths';
import { useInvalidateGetAmaxPayOpenBankingBankAccounts } from '../useGetAmaxPayOpenBankingBankAccounts/useGetAmaxPayOpenBankingBankAccounts';
import type { RenameAmaxPayOpenBankingBanks } from './useRenameAmaxPayOpenBankingBankAccounts.types';

export const useRenameAmaxPayOpenBankingBankAccounts = (
    mutationOptions?: RequestMutationOptions<RenameAmaxPayOpenBankingBanks>
) => {
    const invalidateGetAmaxPayOpenBankingBankAccounts = useInvalidateGetAmaxPayOpenBankingBankAccounts();

    return useMutate(paymentServicesApiPaths.crudAmaxPayOpenBankingBankAccounts, {
        mutationOptions: {
            ...mutationOptions,
            onSuccess: async (data, variables, context) => {
                invalidateGetAmaxPayOpenBankingBankAccounts({ path: { companyId: variables.params?.path?.companyId } });

                mutationOptions?.onSuccess?.(data, variables, context);
            },
        },
        method: 'patch',
        apiVersion: 'v1',
    });
};
