import { useAtomValue } from 'jotai';
import { useMemo } from 'react';

import { profileFormInvalidState } from '../ProfileEditPopup.states';

export const useCheckIsInvalidProfileForm = () => {
    const errors = useAtomValue(profileFormInvalidState);

    return useMemo(() => Object.values(errors).some((el) => el), [errors]);
};
