/**
 * Developer: Stepan Burguchev
 * Date: 6/17/2017
 * Copyright: 2015-2017 ApprovalMax
 *       All Rights Reserved
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF ApprovalMax
 *       The copyright notice above does not evidence any
 *       actual or intended publication of such source code.
 */

import { arrayHelpers, compareHelpers, errorHelpers } from '@approvalmax/utils';
import { backend, domain, State, stateTree } from 'modules/data';
import { createSelector } from 'reselect';

import { getCompanies } from './companySelectors';
import { ExpandedProfile, ExpandedUser } from './types';
import { getUserById } from './userSelectors';

export function getProfile(state: State): ExpandedProfile {
    const profile = findProfile(state);

    if (!profile) {
        throw errorHelpers.notFoundError('Profile not found.');
    }

    return profile;
}

export function getProfileAccount(state: State): domain.ProfileAccount {
    const profile = getProfile(state);

    if (!profile.account) {
        throw errorHelpers.notFoundError('Account not found.');
    }

    return profile.account;
}

export const findProfile: (state: State) => ExpandedProfile | null = createSelector(
    (state: State) => state.profile,
    (state: State) => {
        const companies = Object.values(state.entities.companies);
        const noSubscription = Object.values(state.entities.subscriptions).length === 0;

        return (
            noSubscription &&
            (companies.length === 0 || companies.some((c) => c.licenseProductPlan === domain.CompanyPlan.Trial))
        );
    },
    (profile, isTrialUser) => {
        if (!profile) {
            return null;
        }

        return {
            ...profile,
            flags: {
                hasAccessToPartnerPortalPage: hasAccessToPartnerPortalPage(profile),
            },
            isTrialUser,
            lastCertification:
                arrayHelpers.arraySort(
                    profile.certifications,
                    compareHelpers.comparatorFor(compareHelpers.dateComparator2Desc, 'date')
                )[0] || null,
        };
    }
);

export const findMyTimeZone = createSelector(
    (state: State) => state.profile,
    (profile) => {
        if (!profile) {
            throw errorHelpers.notFoundError('Profile not found.');
        }

        return profile.timeZoneForEmail;
    }
);

export function findProfileUser(state: stateTree.State): ExpandedUser | null {
    let profile = findProfile(state);

    if (!profile) {
        return null;
    }

    return getUserById(state, profile.email);
}

/**
 * In a case a state was not initialized yet will throw an error screen.
 * If you need to keep working with layout then use {@link findProfileUser} instead, it will return null in such cases
 */
export function getProfileUser(state: stateTree.State): ExpandedUser {
    const profile = findProfileUser(state);

    if (!profile) {
        throw errorHelpers.throwInvalidOperationError('The property `state.profile` is not yet defined in the store.');
    }

    return profile;
}

export function isManagerAtAnyOrganization(state: stateTree.State): boolean {
    return getCompanies(state).some((c) => c.flags.isManager);
}

export function hasAccessToUserProfileData(state: stateTree.State, userId: string) {
    return getCompanies(state).some((company) =>
        company.allMembers.some((member) => member.id === userId && member.status === domain.CompanyUserStatus.Active)
    );
}

function hasAccessToPartnerPortalPage(profile: domain.Profile) {
    const partnerAccountTypes = [backend.AccountType.Partner, backend.AccountType.ReceiptBankPartner];
    const invalidPartnerStatuses = [domain.ProfilePartnerStatus.Undefined, domain.ProfilePartnerStatus.Rejected];

    if (!profile.account) {
        return false;
    }

    // TODO: dirty hack for task OP-1505
    if (
        ['7442a6c5-0250-4c0a-964a-0d2dea0f6106', 'd67aa8a5-53a9-4012-a9d1-7e2a0bcdd800'].includes(
            profile.account.accountId
        )
    ) {
        return false;
    }

    if (invalidPartnerStatuses.includes(profile.account.partnerStatus)) {
        return false;
    }

    return partnerAccountTypes.includes(profile.account.accountType);
}
