export const defaultFloatingPortalProps = {
    preserveTabOrder: false,
};

export const defaultFloatingFocusManagerProps = {
    returnFocus: false,
    initialFocus: 0,
    order: ['reference' as const],
    modal: false,
    guards: false,
};

export const removeDropdownFocus = {
    defaultFloatingPortalProps,
    defaultFloatingFocusManagerProps,
};
