import { RequestParams, RequestQueryOptions } from '@approvalmax/data';
import { useGet, useLazyGet } from 'shared/data';

import { netSuiteApiPaths } from '../paths';
import { GetNetSuitePartners } from './useGetNetSuitePartners.types';

export const useGetNetSuitePartners = (
    params?: RequestParams<GetNetSuitePartners>,
    queryOptions?: RequestQueryOptions<GetNetSuitePartners>
) => {
    return useGet(netSuiteApiPaths.partners, {
        params,
        queryOptions,
        apiVersion: 'v2',
    });
};

export const useLazyGetNetSuitePartners = (queryOptions?: RequestQueryOptions<GetNetSuitePartners>) => {
    return useLazyGet<GetNetSuitePartners>(netSuiteApiPaths.partners, {
        queryOptions,
        apiVersion: 'v2',
    });
};
