import { RequestParams, RequestQueryOptions } from '@approvalmax/data';
import { useGet } from 'shared/data';

import { netSuiteApiPaths } from '../paths';
import { GetNetSuiteItemPriceLevels } from './useGetNetSuiteItemPriceLevels.types';

export const useGetNetSuiteItemPriceLevels = (
    params?: RequestParams<GetNetSuiteItemPriceLevels>,
    queryOptions?: RequestQueryOptions<GetNetSuiteItemPriceLevels>
) => {
    return useGet(netSuiteApiPaths.priceLevels, {
        params,
        queryOptions,
        apiVersion: 'v2',
    });
};
