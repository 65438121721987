import { RequestMutationOptions } from '@approvalmax/data';
import { useSetAtom } from 'jotai';
import { useMutate } from 'shared/data';
import { fileUploadTimestampsState } from 'shared/states';

import { requestsApiPaths } from '../paths';
import { UploadAttachment } from './useUploadAttachment.types';

export const useUploadAttachment = (mutationOptions?: RequestMutationOptions<UploadAttachment>) => {
    const setFileUploadTimestamps = useSetAtom(fileUploadTimestampsState);

    return useMutate(requestsApiPaths.attachFile, {
        mutationOptions: {
            onSettled: (data, error, variables, context) => {
                setFileUploadTimestamps((value) => [...value, new Date().toISOString()]);

                mutationOptions?.onSettled?.(data, error, variables, context);
            },
            ...mutationOptions,
        },
        apiVersion: 'v1',
        method: 'post',
    });
};
