import {
    addOnsFeatures,
    qboAdvancedFeatures,
    qboPremiumFeatures,
    xeroAdvancedFeatures,
    xeroPremiumFeatures,
} from '../../AllFeatureTrialPaywallPopup.constants';
import { TrialCompany, TrialIntegrationType } from '../../AllFeatureTrialPaywallPopup.types';

export const getAdvancedFeatures = (integrationType?: TrialIntegrationType) => {
    if (!integrationType) return [];

    switch (integrationType) {
        case TrialIntegrationType.Xero:
            return xeroAdvancedFeatures;

        case TrialIntegrationType.Quickbooks:
            return qboAdvancedFeatures;

        default:
            return [];
    }
};

export const getPremiumFeatures = (integrationType?: TrialIntegrationType) => {
    if (!integrationType) return [];

    switch (integrationType) {
        case TrialIntegrationType.Xero:
            return xeroPremiumFeatures;

        case TrialIntegrationType.Quickbooks:
            return qboPremiumFeatures;

        default:
            return [];
    }
};

export const getAddOnsFeatures = ({ company }: { company?: TrialCompany }) => {
    if (!company) return [];

    return addOnsFeatures.filter((addonFeature) => {
        return company.canBuyAddons[addonFeature.id];
    });
};

export const isShowAdvanced = (company?: TrialCompany) => {
    return (
        (company?.plan ? company?.plan === 'standard' : true) &&
        getAdvancedFeatures(company?.integrationType).length > 0
    );
};

export const isShowPremium = (company?: TrialCompany) => {
    return company?.plan !== 'premium' && getPremiumFeatures(company?.integrationType).length > 0;
};

export const getInitialFeature = (company?: TrialCompany) => {
    if (!company?.integrationType) return;

    const showAdvanced = isShowAdvanced(company);
    const showPremium = isShowPremium(company);

    if (showAdvanced) {
        return getAdvancedFeatures(company.integrationType)[0].id;
    }

    if (showPremium) {
        return getPremiumFeatures(company.integrationType)[0].id;
    }

    const addOnsFeatures = getAddOnsFeatures({
        company,
    });

    if (addOnsFeatures.length) {
        return addOnsFeatures[0].id;
    }

    return;
};
