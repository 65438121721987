import { atom } from 'jotai';

export enum AftPromotionType {
    BillToPoMatchingPO = 'BillToPoMatchingPO',
    BillToPoMatchingBill = 'BillToPoMatchingBill',
    XeroBudgetChecking = 'XeroBudgetChecking',
    GoodsReceivedNote = 'GoodsReceivedNote',
}

export enum AddonPromotionType {
    Capture = 'Capture',
    Pay = 'Pay',
}

export const pageVisiblePromotionsState = atom<(AftPromotionType | AddonPromotionType)[] | null>(null);
