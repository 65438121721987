import { Mods, mods } from '@approvalmax/theme';
import { LoadingSpinner } from '@approvalmax/ui';
import { StyledMods } from '@styled-kit/mods';
import { useAtomValue } from 'jotai';
import { domain } from 'modules/data';
import { EyeDocPreviewIcon, PaperClipIcon } from 'modules/sprites';
import { font, uAbsoluteFill, uFlexCenter, uTextEllipsis } from 'modules/styles';
import { getActiveRequestSafe } from 'pages/requestList/selectors/pageSelectors';
import { FC, memo, useCallback } from 'react';
import bemFactory from 'react-bem-factory';
import { useSelector } from 'react-redux';
import { useCheckOcrStatus } from 'shared/hooks/useCheckOcrStatus';
import { ocrIsLoadingState } from 'shared/states';
import styled, { css } from 'styled-components';

import { messages } from './AttachmentItem.messages';

const AttachmentItemWrap = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    flex-shrink: 0;
    height: 36px;
    border: 1px solid #cdd2d2;
    border-radius: 2px;
    cursor: pointer;
    padding-left: 10px;

    &:hover {
        & > * {
            visibility: visible;
        }
    }
`;
const AttachmentItemName = styled.div`
    margin: 0 6px;
    ${font(12, '#384650', 'semibold')}
    ${uTextEllipsis}
    flex: 1;
`;
const AttachmentItemPreviewIcon = styled.div<{ isActive: boolean }>`
    visibility: hidden;
    ${uAbsoluteFill()}
    ${uFlexCenter}
    z-index: 1;
    background: #f2f0f0;
    opacity: 0.85;

    ${(props) =>
        props.isActive &&
        css`
            visibility: visible;
        `}
`;
const AttachmentItemPaperClip = styled(PaperClipIcon)`
    color: #c1c3c4;
    fill: currentColor;
`;

const LoadingPanel = styled.div<StyledMods<Mods<'progress'>>>`
    ${uAbsoluteFill()}
    display: none;
    align-items: center;
    justify-content: flex-end;
    z-index: 1;
    ${mods.progress.true`
        display: flex;
    `}
`;

const LoadingPanelText = styled.div`
    margin-left: 5px;
    margin-right: 10px;
    ${font(13, '#7f7d7d')}
    ${uTextEllipsis}
`;

const qa = bemFactory.qa('attachment-item');

interface AttachmentItemProps {
    attachment: domain.RequestAttachment;
    onOpen: (attachment: domain.RequestAttachment) => void;
    activeAttachmentId?: string;
}

const AttachmentItem: FC<AttachmentItemProps> = (props) => {
    const { attachment, onOpen, activeAttachmentId } = props;

    const request = useSelector(getActiveRequestSafe);
    const ocrIsLoading = useAtomValue(ocrIsLoadingState);

    const { isOcrAttachmentInProgress } = useCheckOcrStatus(request, attachment.id);

    const handleOpen = useCallback(() => {
        onOpen(attachment);
    }, [attachment, onOpen]);

    return (
        <AttachmentItemWrap
            data-qa={qa()}
            data-qa-id={attachment.id}
            data-qa-name={attachment.name}
            title={attachment.name}
            onClick={handleOpen}
        >
            <AttachmentItemPreviewIcon isActive={attachment.id === activeAttachmentId}>
                <EyeDocPreviewIcon width={18} height={12} />
            </AttachmentItemPreviewIcon>

            <AttachmentItemPaperClip width={16} height={15} />

            <AttachmentItemName>{attachment.name}</AttachmentItemName>

            <LoadingPanel $progress={ocrIsLoading && isOcrAttachmentInProgress}>
                <LoadingSpinner size='small12' />

                <LoadingPanelText>{messages.recognitionText}</LoadingPanelText>
            </LoadingPanel>
        </AttachmentItemWrap>
    );
};

export default memo(AttachmentItem);
