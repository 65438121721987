import { browserHelpers } from '@approvalmax/utils';
import { createPath } from 'history';
import { selectors } from 'modules/common';
import { State } from 'modules/data';
import { getPath, Path } from 'urlBuilder';

import { routingService } from '../routing';

const pathsAllowedOnMobile = [
    `/${Path.login}`,
    `/${Path.register}`,
    `/${Path.resetPassword}`,
    `/${Path.resetPassVerifyEmail}`,
    `/${Path.verifyEmail}`,
    `/${Path.emailConfirmation}`,
    `/${Path.profileSetupEmail}`,
    `/${Path.profileSetupSso}`,
    `/${Path.completeSetup}`,
    `/${Path.twoFaHardEnforcement}`,
    `/${Path.receiptBankAuth}`,
    `/${Path.connectBankAccountSuccess}`,
    `/${Path.connectBankAccountFail}`,
    `/${Path.reconnectBankAccountSuccess}`,
    `/${Path.amaxPaymentSuccess}`,
    `/${Path.amaxPaymentFail}`,
].map((el) => el.toLowerCase());

export function isShowCompleteSetup(state: State) {
    const pendingInvitationsLength = selectors.meta.getPendingInvitationsLength(state);
    const practiceInvitations = selectors.practiceInvitations.getPracticeInvitations(state);
    const companies = selectors.company.getCompanies(state);
    const authenticated = selectors.session.authenticated(state);

    return (
        authenticated &&
        browserHelpers.isMobile() &&
        companies?.length === 0 &&
        pendingInvitationsLength === 0 &&
        practiceInvitations?.length === 0
    );
}

export function isShowMobile() {
    if (!browserHelpers.isMobile()) {
        return false;
    }

    // specialCase for mobile setup
    const location = routingService.getLocation();
    const pathname = location.pathname;
    const search = location.search;

    const pathnameLower = pathname.toLowerCase();

    if (pathsAllowedOnMobile.some((path) => pathnameLower.startsWith(path))) {
        return false;
    }

    // specialCase for MyAccount login
    if (createPath(location).includes(getPath(Path.login, { redirectPath: '', workspace: 'account' }))) {
        return false;
    }

    // specialCase for autoapprove
    const inMobileAutoAction =
        pathname.includes('oauth2/') ||
        pathname.includes('autoApprove') ||
        pathname.includes('autoReject') ||
        (pathname.includes('mobileDecision') && search.includes('requestId')) || // for approval and reject from email, hook: useMakeDecision
        (pathname.includes('requests/myDecisionRequired') && search.includes('decision')) || // for approval and reject from email, hook: useMakeDecision
        pathname.includes('acceptCompanyInvitation');

    if (inMobileAutoAction) {
        return false;
    }

    return true;
}
