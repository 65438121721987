import { useTheme } from '@approvalmax/theme';
import { ConfirmationManager, Toast } from '@approvalmax/ui/src/components';
import { browserHelpers } from '@approvalmax/utils';
import PagesRootLayout from 'modules/page/containers/RootLayout/RootLayout';
import { allModuleComponents } from 'modules/store';
import { memo } from 'react';
import { SkeletonTheme } from 'react-loading-skeleton';
import { Redirect, Route, Switch } from 'react-router-dom';
import { errorService } from 'services/error';
import {
    Layout,
    NewOrganisationPopup,
    NewRequestPopup,
    ProfileEditPopup,
    PushingConnectGLPopup,
    SelectSubscriptionPopup,
    TwoFaSoftEnforcement,
    useCheckTwoFaSoftEnforcementIsOpen,
} from 'shared/components';
import { getDefaultPath, getPath, Path } from 'urlBuilder';

import workflowsRoute from './(workspace)/[companyId]/workflows';
import { PageRoute, UnsavedChangesModule, WorkflowWizard } from './resources/components';
import { withProviders } from './resources/hocs/withProviders';
import { useActiveCompanyId } from './resources/hooks/useActiveCompanyId';
import { useFileUploadTimestampsSync } from './resources/hooks/useFileUploadTimestampsSync';
import { useForcePromotionPopupOpen } from './resources/hooks/useForcePromotionPopupOpen';
import { useLoadApplication } from './resources/hooks/useLoadApplication';
import { usePromotionInit } from './resources/hooks/usePromotionInit';
import { usePromotionReset } from './resources/hooks/usePromotionReset';
import { useRoutes } from './resources/hooks/useRoutes';

// export separately for tests
export const RootLayout = memo(() => {
    const isAppLoading = useLoadApplication();
    const isTwoFaSoftEnforcementOpen = useCheckTwoFaSoftEnforcementIsOpen();

    useFileUploadTimestampsSync();
    useActiveCompanyId();
    useForcePromotionPopupOpen();
    usePromotionInit();
    usePromotionReset();
    errorService.useGlobalApiErrors();

    const { routerRedesign, routerBasic, routerSimpleLayout } = useRoutes();
    const { theme } = useTheme();

    if (isAppLoading) return null;

    return (
        <SkeletonTheme baseColor={theme.color.silver80} highlightColor={theme.color.silver100} borderRadius={4}>
            <Switch>
                <Route path={routerSimpleLayout.paths}>
                    <Switch>{routerSimpleLayout.routes.map(PageRoute)}</Switch>
                </Route>

                <Route path={routerRedesign.paths}>
                    <Layout>
                        <Switch>{routerRedesign.routes.map(PageRoute)}</Switch>
                    </Layout>
                </Route>

                <Route path={routerBasic.paths}>
                    <Layout>
                        <PagesRootLayout>
                            <Switch>
                                {routerBasic.routes.map(PageRoute)}

                                <Route path='/'>
                                    <Redirect
                                        to={browserHelpers.isMobile() ? getPath(Path.register) : getDefaultPath()}
                                    />
                                </Route>
                            </Switch>
                        </PagesRootLayout>
                    </Layout>
                </Route>

                <Route path='*'>
                    <Redirect to={getDefaultPath()} />
                </Route>
            </Switch>

            <UnsavedChangesModule />

            {allModuleComponents.map((ModuleComponent, i) => (
                <ModuleComponent key={i} />
            ))}

            <Toast />

            <ConfirmationManager />

            <NewOrganisationPopup />

            <NewRequestPopup />

            <SelectSubscriptionPopup />

            <Route path={workflowsRoute.path}>
                <WorkflowWizard />
            </Route>

            <PushingConnectGLPopup />

            <ProfileEditPopup />

            {isTwoFaSoftEnforcementOpen && <TwoFaSoftEnforcement />}
        </SkeletonTheme>
    );
});

export default withProviders(RootLayout);
