import { Text } from '@approvalmax/ui/src/components';
import { selectors } from 'modules/common';
import { domain } from 'modules/data';
import { useSelector } from 'modules/react-redux';
import { memo, useCallback, useState } from 'react';
import { isOcrEnabledForTemplate } from 'shared/helpers';

import { getActiveTemplate } from '../../selectors/pageSelectors';
import CollapseAllButton from '../CollapseAllButton/CollapseAllButton';
import FixedStepContainer from '../FixedStepContainer/FixedStepContainer';
import { ReceiveOverEmailSection } from '../ReceiveOverEmailSection/ReceiveOverEmailSection';
import { StepSubmitterSection } from '../StepSubmitterSection/StepSubmitterSection';
import XeroExternalSubmitterSection from '../XeroExternalSubmitterSection/XeroExternalSubmitterSection';
import { learnMoreLink } from './XeroPoStartStep.constants';
import { messages } from './XeroPoStartStep.messages';

const XeroPoStartStep = memo(() => {
    const template = useSelector(getActiveTemplate)!;
    const pluralTemplateName = template.displayNamePlural;
    const company = useSelector((state) => selectors.company.getCompanyById(state, template.companyId));

    const [collapsedSubmitterSection, setCollapsedSubmitterSection] = useState<boolean>();
    const [collapsedExternalSubmitterSection, setCollapsedExternalSubmitterSection] = useState<boolean>();
    const [collapsedReceiveOverEmailSection, setCollapsedReceiveOverEmailSection] = useState<boolean>();

    const onCollapse = useCallback((collapsed: boolean) => {
        setCollapsedSubmitterSection(collapsed);
        setCollapsedExternalSubmitterSection(collapsed);
        setCollapsedReceiveOverEmailSection(collapsed);
    }, []);

    const receiveOverEmailSection = isOcrEnabledForTemplate(company, domain.IntegrationCode.XeroPo);

    return (
        <FixedStepContainer bubbleText={messages.bubbleText({ pluralTemplateName })}>
            <Text font='body' fontSize='small' color='midnight70'>
                {messages.title({ pluralTemplateName })}
            </Text>

            <CollapseAllButton
                collapsed={Boolean(collapsedSubmitterSection && collapsedExternalSubmitterSection)}
                onCollapse={onCollapse}
            />

            <StepSubmitterSection
                cacheId='XeroPoStartStepSubmitter'
                headerText={messages.createInApprovalMax}
                description={messages.description({ pluralTemplateName })}
                descriptionEmpty={messages.descriptionEmpty({ pluralTemplateName })}
                collapsed={collapsedSubmitterSection}
                onCollapse={setCollapsedSubmitterSection}
                required
            />

            <XeroExternalSubmitterSection
                onCollapse={setCollapsedExternalSubmitterSection}
                collapsed={collapsedExternalSubmitterSection}
                cacheId='XeroPoStartStepExternalSubmitter'
                description={messages.externalSubmitterDescriptionNonEmpty({ templateName: template.displayName })}
                descriptionEmpty={messages.externalSubmitterDescriptionEmpty({ templateName: template.displayName })}
                learnMoreLink={learnMoreLink}
            />

            {receiveOverEmailSection && (
                <ReceiveOverEmailSection
                    collapsed={collapsedReceiveOverEmailSection}
                    onCollapse={setCollapsedReceiveOverEmailSection}
                    cacheId='XeroPurchaseOrderStartStepReceiveOverEmail'
                    description={messages.emailExternaSubmitterDescriptionNonEmpty({ pluralTemplateName })}
                    descriptionEmpty={messages.emailExternalSubmitterDescriptionEmpty({
                        templateName: template.displayName,
                    })}
                    title={messages.emailExternalSubmitterTitle}
                />
            )}
        </FixedStepContainer>
    );
});

export default XeroPoStartStep;
