import { useTheme } from '@approvalmax/theme';
import { FC } from 'react';
import { useMedia } from 'react-use';

import { Menu } from '../../../Menu/Menu';
import { ScrollableContainer } from '../ScrollableContainer/ScrollableContainer';
import { Root } from './Sidebar.styles';
import { SidebarProps } from './Sidebar.types';

export const Sidebar: FC<SidebarProps> = (props) => {
    const { children, items, spacingTop, spacingRight, spacingBottom, spacingLeft, ...restProps } = props;

    const { theme } = useTheme();
    const isXSmall = useMedia(`(max-width: ${theme.container.breakpoint.xsmall})`);

    const orientation = isXSmall ? 'horizontal' : 'vertical';
    const isHorizontal = orientation === 'horizontal';

    return (
        <Root $orientation={orientation} {...restProps}>
            <ScrollableContainer
                orientation={orientation}
                spacingTop={spacingTop ?? 16}
                spacingBottom={spacingBottom ?? isHorizontal ? 16 : 40}
                spacingLeft={spacingLeft ?? 40}
                spacingRight={spacingRight ?? 40}
            >
                {items ? (
                    <Menu
                        wrap={false}
                        size='medium'
                        items={items}
                        color='midnight80'
                        direction={isXSmall ? 'row' : 'column'}
                        minWidth='max-content'
                    />
                ) : (
                    children
                )}
            </ScrollableContainer>
        </Root>
    );
};

Sidebar.displayName = 'Sidebar';
