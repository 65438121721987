import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages(
    'pages.RequestList.XeroBudgetingSection.BudgetImpactDetailsPopup.BreakDownsSection',
    {
        budget: 'Budget',
        remaining: 'Remaining',
        bills: 'Bills',
        purchaseOrders: 'Purchase Orders',
        approved: 'Approved',
        onApproval: 'On approval',
        noteOne: 'All amounts do not include amounts of the current {templateDisplayName}',
        noteTwo: 'This amount includes not yet fully Billed Purchase Orders',
    }
);
