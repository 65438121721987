import { useAtom } from 'jotai';
import { memo, useCallback } from 'react';

import ToggleButton from '../../../ToggleButton/ToggleButton';
import { fullscreenSplitViewState } from '../../SplitView.states';
import { messages } from './ToggleFullscreen.messages';

const ToggleFullscreen = memo(() => {
    const [fullscreenState, setFullscreenState] = useAtom(fullscreenSplitViewState);

    const toggleFullscreenState = useCallback(() => {
        setFullscreenState((currVal) => !currVal);
    }, [setFullscreenState]);

    return (
        <ToggleButton.Group
            size='xsmall'
            name='toggleButton'
            onChange={toggleFullscreenState}
            value={fullscreenState.toString()}
        >
            <ToggleButton value='true'>{messages.fullscreen}</ToggleButton>

            <ToggleButton value='false'>{messages.sideBySide}</ToggleButton>
        </ToggleButton.Group>
    );
});

export default ToggleFullscreen;
