import { font } from '@approvalmax/ui';
import styled from 'styled-components';

export const Root = styled.div`
    display: inline-flex;
    align-items: center;
`;

export const Panel = styled.div`
    display: flex;
    flex-flow: column;
    padding: 15px;
`;

export const Description = styled.div`
    ${font(12, '#000')}
`;

export const AmountDueIndicatorIcon = styled.span`
    margin-left: 3px;
    border-radius: 50%;
    width: 5px;
    height: 5px;
    background: #f0984f;
    display: inline-block;
    flex-shrink: 0;
`;

export const AmountLine = styled.div`
    margin-top: 5px;
    line-height: 18px;
    padding: 6px 0;

    & + & {
        border-top: 1px solid #ebeaea;
    }
`;
