import { Radio } from '@approvalmax/ui/src/components';
import { selectors } from 'modules/common';
import { domain } from 'modules/data';
import { memo, useCallback } from 'react';

import { SettingsSection } from '../SettingsSection/SettingsSection';
import SettingsSubsection from '../SettingsSubsection/SettingsSubsection';
import { messages } from './SectionPostingPreferencesType.messages';
import { SectionPostingPreferencesTypeProps } from './SectionPostingPreferencesType.types';

const SectionPostingPreferencesType = memo<SectionPostingPreferencesTypeProps>((props) => {
    const { links, templateSettings, setTemplateSettings, readonly, qa } = props;

    const value = templateSettings?.postingPreferencesType || domain.PostingPreferencesType.AsDraft;

    const onChangePostingPreferencesType = useCallback(
        (newValue: domain.PostingPreferencesType) => {
            setTemplateSettings((current) => ({
                ...current,
                postingPreferencesType: newValue,
            }));
        },
        [setTemplateSettings]
    );

    return (
        <SettingsSection
            title={messages.title}
            learnMoreLink={links[selectors.types.Link.PostingPreferences]}
            data-qa={qa('posting-preference-type')}
        >
            <SettingsSubsection subtitle={messages.subtitle}>
                <Radio.Group
                    disabled={readonly}
                    value={value}
                    onChange={onChangePostingPreferencesType}
                    name='postingPreferencesType'
                    block
                >
                    <Radio value={domain.PostingPreferencesType.AsDraft}>{messages.asDraft}</Radio>

                    <Radio value={domain.PostingPreferencesType.AsPosted}>{messages.asPosted}</Radio>
                </Radio.Group>
            </SettingsSubsection>
        </SettingsSection>
    );
});

export default SectionPostingPreferencesType;
