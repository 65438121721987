import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('pages.RequestList.selectors.pageSelectors', {
    pageMyDecisionRequiredTitleText: 'Requires my decision',
    pageMyReviewRequiredTitleText: 'Review required',
    pageMyOcrRequestsTitleText: 'Captured by me',
    pageTitleRequestsOnReview: 'Requests on Review | {companyName}',
    pageMyOpenTitleText: 'Created by me',
    pageMyReadyToPayTitleText: 'Ready to pay',
    pageMyDraftTitleText: 'Drafts',
    pageCompanyDecisionRequiredTitleText: 'Decision required | {companyName}',
    pageCompanyReviewRequiredTitleText: 'Review required | {companyName}',
    pageCompanyMyOpenTitleText: 'My open requests | {companyName}',
    pageCompanyAllOpenTitleText: 'Open requests | {companyName}',
    pageCompanyAllTitleText: 'All requests | {companyName}',
    pageTitleRequestsAll: 'All requests | {companyName}',
    pageTitleRequestsOnApproval: 'Requests on approval | {companyName}',
    pageTitleRequestsOnHold: 'Requests on hold | {companyName}',
    pageTitleApprovedRequests: 'Approved requests | {companyName}',
    pageTitleCancelledRequests: 'Cancelled requests | {companyName}',
    pageTitleRejectedRequests: 'Rejected requests | {companyName}',
    pageTitlePaidRequests: 'Paid requests | {companyName}',
    pageTitleBilledRequests: 'Billed requests | {companyName}',
    pageTitleNotBilledRequests: 'Not Billed requests | {companyName}',
    pageTitleAwaitingPaymentRequests: 'Awaiting payment requests | {companyName}',
    pageTitlePartiallyPaidRequests: 'Partially paid requests | {companyName}',
    pageTitleFailedRequests: 'Failed requests | {companyName}',
    pageTitleProcessingRequests: 'Processing requests | {companyName}',
    pageTitleGrnNotReceivedRequests: 'GRN Not received requests | {companyName}',
    pageTitleGrnPartiallyReceivedRequests: 'GRN Partially received requests | {companyName}',
    pageTitleGrnFullyReceivedRequests: 'GRN Fully received requests | {companyName}',
    pageTitleAcceptedRequests: 'Accepted requests | {companyName}',
    pageTitleDeclinedRequests: 'Declined requests | {companyName}',
    pageTitleCreditedRequests: 'Credited requests | {companyName}',
    pageTitlePartiallyReceivedRequests: 'Partially Received requests | {companyName}',
    pageTitlePendingBillRequests: 'Pending Bill requests | {companyName}',
    pageTitlePendingReceiptRequests: 'Pending Receipt requests | {companyName}',
    pageTitleClosedRequests: 'Closed requests | {companyName}',
    searchModeTitleText: 'Search results',
});
