import { TypeOfBusiness } from 'modules/data/domain';
import { getPreferredAccountingSystemSignUp, getTypeOfBusiness } from 'shared/helpers';
import { PreferredAccountingSystem } from 'shared/helpers/qualificationQuestionsAnswers/preferredAccountingSystemSignUp';

import { CurrentStep } from './CreateOrganisationPopup.types';

export const getBusinessType = () => {
    const storageValue = getTypeOfBusiness();

    switch (storageValue) {
        case TypeOfBusiness.Business:
            return 'business';

        case TypeOfBusiness.Partner:
            return 'partner';

        default:
            return undefined;
    }
};

export const getCurrentStepFromStorage = () => {
    const preferedAccountingSystemSignUp = getPreferredAccountingSystemSignUp();

    if (!preferedAccountingSystemSignUp) {
        return CurrentStep.Initial;
    }

    switch (preferedAccountingSystemSignUp) {
        case PreferredAccountingSystem.Xero:
            return CurrentStep.Xero;
        case PreferredAccountingSystem.QBooks:
            return CurrentStep.QBooks;
        case PreferredAccountingSystem.NetSuite:
            return CurrentStep.NetSuite;
        case PreferredAccountingSystem.Cin7:
            return CurrentStep.Cin7;
        default: {
            const _exhaustiveCheck: never = preferedAccountingSystemSignUp;

            return CurrentStep.Initial;
        }
    }
};
