import uniqBy from 'lodash/uniqBy';
import { MatchingRequester } from 'modules/common/selectors/types';
import { domain } from 'modules/data';
import { GetMatchingSettings } from 'shared/data/webApp/v1';

import { PurchaseOrder } from '../../../../types';

export const getMatchingRequesters = (purchaseOrders: PurchaseOrder[]): MatchingRequester[] => {
    const matchingRequesters = purchaseOrders.map((purchaseOrder) => ({
        ...purchaseOrder.requester,
        requestName: purchaseOrder.name,
        requestId: purchaseOrder.id,
    }));

    return uniqBy(matchingRequesters, 'id');
};

export const getDifferentRequesters = (
    matchingRequesters: MatchingRequester[],
    currentStepApprovers: domain.RequestStepParticipant[]
): MatchingRequester[] => {
    return matchingRequesters.filter(
        (requester) => !currentStepApprovers.find((approver) => approver.userId === requester.userEmail)
    );
};

export const mapData = (data: GetMatchingSettings['response']) => ({
    billMatchingSettings: mapCompanyBillMatchingSettings(data.billMatchingSettings),
    purchaseOrderMatchingSettings: mapCompanyPurchaseOrderMatchingSettings(data.purchaseOrderMatchingSettings),
});

const mapCompanyBillMatchingSettings = (
    value: GetMatchingSettings['response']['billMatchingSettings']
): domain.CompanyBillMatchingSettings => ({
    addMatchedPOsRequestersAsApproversForBill: value.addMatchedPOsRequestersAsApproversForBill,
    autoMatchingEnabled: value.autoMatchingEnabled,
    manualMatchingEnabled: value.manualMatchingEnabled,
    canMatchBillsWithRetrospectivePurchaseOrders: value.retrospectivePOMatchingAllowed || false,
    allowApprovalOfNotMatchedBills: value.notMatchedBillsApprovalAllowed,
    notMatchedBillsApprovalThreshold: value.notMatchedBillsApprovalThreshold,
    allowApprovalOfOverbudgetBills: value.overbudgetMatchBillsApprovalAllowed,
    overbudgetBillsApprovalThreshold: value.overbudgetMatchBillsApprovalThreshold,
    overbudgetBillsApprovalPercentageThreshold: value.overbudgetMatchBillsApprovalPercentageThreshold,
    amountType: value.amountType,
    insufficientBudgetMatchBillsApprovalAllowed: value.insufficientBudgetMatchBillsApprovalAllowed,
    insufficientBudgetMatchBillsApprovalThreshold: value.insufficientBudgetMatchBillsApprovalThreshold,
    insufficientBudgetMatchBillsApprovalPercentageThreshold:
        value.insufficientBudgetMatchBillsApprovalPercentageThreshold,
});

const mapCompanyPurchaseOrderMatchingSettings = (
    value: GetMatchingSettings['response']['purchaseOrderMatchingSettings']
): domain.CompanyPurchaseOrderMatchingSettings => ({
    autoMarkingPurchaseOrderAsBilledEnabled: value.autoMarkingPurchaseOrderAsBilledEnabled,
    autoUnmarkingPurchaseOrderAsBilledEnabled: value.autoUnmarkingPurchaseOrderAsBilledEnabled,
});
