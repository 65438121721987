import { Header as HeaderUI, Select } from '@approvalmax/ui/src/components';
import { memo } from 'react';
import { Link } from 'react-router-dom';

import { useChangeCompany } from '../Drawer/components';
import { useCompanies } from '../Drawer/Drawer.hooks';
import { UserActionsToolbar } from '../UserActionsToolbar/UserActionsToolbar';
import { useResetHeaderStates } from './Header.hooks';
import { messages } from './Header.messages';

const Header = memo(() => {
    const { companies } = useCompanies();
    const { activeCompanyId, changeCompany } = useChangeCompany();

    const { resetState } = useResetHeaderStates();

    return (
        <HeaderUI
            breadcrumbsLinkAs={Link}
            resetState={resetState}
            companyName={
                companies.length > 0 ? (
                    <Select
                        value={activeCompanyId}
                        placeholder={messages.selectCompany}
                        readOnly={companies.length === 1}
                        items={companies}
                        onChange={changeCompany}
                        clearable={false}
                        hideNoData
                        bordered={false}
                        size='xsmall'
                        noInput
                        dropdownWidth={200}
                        placement='bottom-start'
                        textActivatorColor='midnight70'
                        display='flex'
                    />
                ) : undefined
            }
            actions={<UserActionsToolbar />}
        />
    );
});

Header.displayName = 'Header';

export default Header;
