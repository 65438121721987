import { DeloitteLogo, GrantThorntonLogo, KmpgLogo, MazarsLogo, PwcLogo } from '@approvalmax/ui';
import { Button, Flex, Popup, Spacing, Text } from '@approvalmax/ui/src/components';
import { constants } from '@approvalmax/utils';
import { useSetAtom } from 'jotai';
import { FC, memo, useCallback } from 'react';
import { Link } from 'react-router-dom';
import Refiner from 'refiner-js';
import { amplitudeService } from 'services/amplitude';
import { pushingConnectGLPopupHideState } from 'shared/states';
import { getPath, Path } from 'urlBuilder';

import { refineFormNonProd, refineFormProd } from './PopupContent.constants';
import { getTextByUseCase } from './PopupContent.helpers';
import { messages } from './PopupContent.messages';
import { PopupContentProps } from './PopupContent.types';

export const PopupContent: FC<PopupContentProps> = memo((props) => {
    const { profileId, companyId, onClose } = props;

    const companyWorkflowsLink = getPath(Path.companyWorkflows, companyId);

    const setPushingConnectGLPopupHide = useSetAtom(pushingConnectGLPopupHideState);

    const handleNoIntegrationClick = useCallback(() => {
        onClose();
        setPushingConnectGLPopupHide(true);

        amplitudeService.sendData('signup: clicked on integration isn’t available for my gl', {
            user_id: profileId,
            org_id: companyId,
        });

        Refiner('showForm', constants.isProd ? refineFormProd : refineFormNonProd);
    }, [companyId, onClose, profileId, setPushingConnectGLPopupHide]);

    return (
        <>
            <Popup.Header />

            <Popup.Body>
                <Flex direction='column' alignItems='center' spacing='32'>
                    <Text font='headline' fontWeight='medium' textAlign='center' color='midnight80'>
                        {messages.title}
                    </Text>

                    <Text font='headline' fontSize='small' textAlign='center' color='midnight90'>
                        {messages.description({
                            useCase: getTextByUseCase(),
                        })}
                    </Text>

                    <Flex spacing='40' alignItems='center'>
                        <DeloitteLogo />

                        <PwcLogo />

                        <KmpgLogo />

                        <GrantThorntonLogo />

                        <MazarsLogo />
                    </Flex>
                </Flex>

                <Spacing height={56} />

                <Flex direction='column' alignItems='center' spacing='20'>
                    <Button as={Link} to={companyWorkflowsLink} color='mint80' size='large' onClick={onClose}>
                        {messages.buttonText}
                    </Button>

                    <Button
                        noPadding
                        noUppercase
                        size='large'
                        variant='text'
                        color='blue100'
                        onClick={handleNoIntegrationClick}
                    >
                        {messages.buttonNoIntegrationText}
                    </Button>
                </Flex>
            </Popup.Body>
        </>
    );
});

PopupContent.displayName = 'PopupContent';
