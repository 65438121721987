import { Flex, Table } from '@approvalmax/ui/src/components';
import xor from 'lodash/xor';
import { selectors } from 'modules/common';
import { State } from 'modules/data';
import { FC, memo, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { getActiveTemplate } from '../../../../selectors/pageSelectors';
import { getStartOverColumnDefinition } from './RequestList.helpers';
import { RequestListProps } from './RequestList.types';

export const RequestList: FC<RequestListProps> = memo((props) => {
    const { requestIds, checkedRequests, setCheckedRequests } = props;

    const requests = useSelector((state: State) => requestIds.map((id) => selectors.request.getRequestById(state, id)));
    const template = useSelector(getActiveTemplate)!;
    const columnDefinitions = useMemo(
        () => getStartOverColumnDefinition(template.integrationCode),
        [template.integrationCode]
    );

    const onRowClick = useCallback(
        (request: selectors.types.ExpandedRequest) => {
            setCheckedRequests(xor(checkedRequests, [request.id]));
        },
        [checkedRequests, setCheckedRequests]
    );

    return (
        <Flex direction='column'>
            <Table
                checkedItems={checkedRequests}
                onCheckedItemsChange={setCheckedRequests}
                data={requests}
                columns={columnDefinitions}
                headerColor='white100'
                onRowClick={onRowClick}
            />
        </Flex>
    );
});

RequestList.displayName = 'RequestList';
