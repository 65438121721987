import { Reference } from '@approvalmax/types';
import { Box, Checkbox, Spacing } from '@approvalmax/ui/src/components';
import { domain } from 'modules/data';
import { FC, memo, useCallback, useMemo } from 'react';
import bemFactory from 'react-bem-factory';

import { ExactAsyncCondition } from '../ExactAsyncCondition/ExactAsyncCondition';
import { ExactGroupValue } from '../ExactAsyncCondition/ExactAsyncCondition.types';
import { conditionTypeToConditionTypeValue, getConditionItems } from './XeroSupplierCreationConditionCell.helpers';
import { messages } from './XeroSupplierCreationConditionCell.messages';
import {
    ConditionTypeValue,
    conditionTypeValues,
    XeroSupplierCreationConditionCellProps,
} from './XeroSupplierCreationConditionCell.types';

const qa = bemFactory.qa('wfc-xero-supplier-exact-async-condition-cell');

const XeroSupplierCreationConditionCell: FC<XeroSupplierCreationConditionCellProps> = memo((props) => {
    const { lineId, rule, field, integrationCode, condition, onConditionChange, readonly } = props;

    const baseConditionFields = useMemo(
        () => ({
            fieldId: condition.fieldId,
            fieldName: condition.fieldName,
            fieldSystemPurpose: condition.fieldSystemPurpose,
        }),
        [condition.fieldId, condition.fieldName, condition.fieldSystemPurpose]
    );

    const changeConditionType = useCallback(
        (conditionType: ConditionTypeValue, exactValues: Reference[], exactGroupValues: ExactGroupValue[]) => {
            let newCondition: domain.ExactValuesCondition | domain.ServerCondition | domain.AlwaysTrueCondition;

            switch (conditionType) {
                case conditionTypeValues.AllContacts:
                    if (condition.allowCreation) {
                        newCondition = {
                            ...baseConditionFields,
                            allowCreation: false,
                            conditionType: domain.ConditionType.ServerCondition,
                            serverConditionType: domain.ServerConditionType.AllContacts,
                        };
                    } else {
                        newCondition = {
                            ...baseConditionFields,
                            conditionType: null,
                        };
                    }

                    break;

                case conditionTypeValues.SuppliersOnly:
                    newCondition = {
                        ...baseConditionFields,
                        allowCreation: false,
                        conditionType: domain.ConditionType.ServerCondition,
                        serverConditionType: domain.ServerConditionType.SuppliersOnly,
                    };
                    break;

                case conditionTypeValues.CustomersOnly:
                    newCondition = {
                        ...baseConditionFields,
                        allowCreation: false,
                        conditionType: domain.ConditionType.ServerCondition,
                        serverConditionType: domain.ServerConditionType.CustomersOnly,
                    };
                    break;

                case conditionTypeValues.ExactValues:
                    newCondition = {
                        ...baseConditionFields,
                        conditionType: domain.ConditionType.ExactValuesCondition,
                        exactValues,
                        groupOfXeroAccounts: exactGroupValues,
                    };
                    break;

                case conditionTypeValues.NegativeExactValues:
                default:
                    newCondition = {
                        ...baseConditionFields,
                        conditionType: domain.ConditionType.NegativeExactValuesCondition,
                        exactValues,
                        groupOfXeroAccounts: exactGroupValues,
                    };
                    break;
            }

            onConditionChange(lineId, rule, field, newCondition);
        },
        [condition, field, lineId, onConditionChange, rule, baseConditionFields]
    );

    const onChangeAllowCreation = useCallback(
        (checked: boolean) => {
            let newCondition: domain.ServerCondition | domain.AlwaysTrueCondition;

            if (checked) {
                newCondition = {
                    ...baseConditionFields,
                    conditionType: domain.ConditionType.ServerCondition,
                    serverConditionType: domain.ServerConditionType.AllContacts,
                    allowCreation: true,
                } as domain.ServerCondition;
            } else {
                newCondition = {
                    ...baseConditionFields,
                    conditionType: null,
                    allowCreation: false,
                };
            }

            onConditionChange(lineId, rule, field, newCondition);
        },
        [baseConditionFields, field, lineId, onConditionChange, rule]
    );

    const conditionItems = useMemo(() => getConditionItems(integrationCode), [integrationCode]);
    const conditionTypeValue = conditionTypeToConditionTypeValue(condition);

    const messageAllowToCreateNew =
        integrationCode &&
        [domain.IntegrationCode.XeroQuote, domain.IntegrationCode.XeroInvoice].includes(integrationCode)
            ? messages.allowToCreateNewCustomer
            : messages.allowToCreateNewContacts;

    return (
        <Box width='172px'>
            <ExactAsyncCondition
                {...props}
                conditionTypeItems={conditionItems}
                onConditionTypeChange={changeConditionType}
                conditionTypeValue={conditionTypeValue}
                dataQa={qa()}
                noEmptyValue
                conditionTypeAnyValue={conditionTypeValues.AllContacts}
                isNegativeCondition={conditionTypeValue === conditionTypeValues.NegativeExactValues}
            />

            <Spacing height={4} />

            {conditionTypeValue === conditionTypeValues.AllContacts && (
                <Checkbox
                    onChange={onChangeAllowCreation}
                    checked={condition.allowCreation}
                    disabled={readonly}
                    size='xsmall'
                    data-qa={qa('allow-creation')}
                >
                    {messageAllowToCreateNew}
                </Checkbox>
            )}
        </Box>
    );
});

export default XeroSupplierCreationConditionCell;
