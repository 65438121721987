import { enabledByParams, RequestParams, RequestQueryOptions } from '@approvalmax/data';
import { useGet } from 'shared/data/api';

import { integrationApiPaths } from '../paths';
import { GetNetSuiteCustomRecords } from './useGetNetSuiteCustomRecords.types';

export const useGetNetSuiteCustomRecords = (
    params?: RequestParams<GetNetSuiteCustomRecords>,
    queryOptions?: RequestQueryOptions<GetNetSuiteCustomRecords>
) => {
    return useGet(integrationApiPaths.getNetSuiteCustomRecords, {
        params,
        queryOptions: {
            ...queryOptions,
            enabled: enabledByParams<GetNetSuiteCustomRecords['params']['query']>(
                {
                    requestId: params?.query?.requestId,
                    companyId: params?.query?.companyId,
                    fieldId: params?.query?.fieldId,
                    ruleType: params?.query?.ruleType,
                },
                queryOptions
            ),
        },
        apiVersion: 'v1',
        mapToCamelCase: true,
    });
};
