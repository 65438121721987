import { Dropdown as DropdownOld } from '@approvalmax/ui';
import { Button, Dropdown } from '@approvalmax/ui/src/components';
import { selectors } from 'modules/common';
import { GlobalLoadingBar } from 'modules/page';
import { FC, memo } from 'react';
import { useUserBetaFeatures } from 'shared/hooks';

import { QBooksMatchingInfoContent, QBooksMatchingInfoContentOld } from './components';
import { isMatchingInfoFilled } from './QBooksMatchingInfo.helpers';
import { useGetQBooksMatching, useQBooksMatchingInfoDropdown } from './QBooksMatchingInfo.hooks';
import { StyledMatchingIcon, StyledShowMatchingIcon } from './QBooksMatchingInfo.styles';
import { MatchingInfoNormalizer, QBooksMatchingInfoProps } from './QBooksMatchingInfo.types';

// let's leave this func here, because placing it to a helpers will introduce imports cycling
const defaultMatchingInfoNormalizer: MatchingInfoNormalizer = (value) => Promise.resolve(value);

export const QBooksMatchingInfo: FC<QBooksMatchingInfoProps> = memo((props) => {
    const {
        lineItemId,
        request,
        onRemoveMatching,
        isRemoveMatchingLoading,
        isAccountLineItem = false,
        isNewLineItem = false,
        profileId,
        matchingInfoNormalizer = defaultMatchingInfoNormalizer,
    } = props;

    const { isOpened, onDropdownOpen, onDropdownClose, toggleIsOpened } = useQBooksMatchingInfoDropdown(false);

    const { isBelgradeUIQBooksMatchingInfoDropdown } = useUserBetaFeatures();

    const matchingCommands = selectors.request.getQBooksMatchingCommands(request, profileId);
    const enabled =
        isOpened && !matchingCommands.showMatchingInfo.hidden && !matchingCommands.showMatchingInfo.disabled;

    const { data: matchingInfo, isLoading } = useGetQBooksMatching({
        integrationCode: request.integrationCode,
        companyId: request.companyId,
        lineItemId,
        enabled,
        isNewLineItem,
        matchingInfoNormalizer,
    });

    if (matchingCommands.showMatchingInfo.hidden) {
        return null;
    }

    if (matchingCommands.showMatchingInfo.disabled) {
        return <StyledMatchingIcon />;
    }

    if (isBelgradeUIQBooksMatchingInfoDropdown) {
        return (
            <Dropdown
                open={isOpened}
                onOpen={toggleIsOpened}
                width='max-content'
                maxWidth='800'
                activator={
                    <Button icon variant='text' color='blue100'>
                        <StyledShowMatchingIcon />
                    </Button>
                }
            >
                <>
                    <GlobalLoadingBar isLoading={isLoading && isOpened} />

                    {isMatchingInfoFilled(matchingInfo) && (
                        <QBooksMatchingInfoContent
                            matchingInfo={matchingInfo}
                            lineItemId={lineItemId}
                            request={request}
                            onDropdownClose={onDropdownClose}
                            onRemoveMatching={onRemoveMatching}
                            isRemoveMatchingLoading={isRemoveMatchingLoading}
                            isAccountLineItem={isAccountLineItem}
                        />
                    )}
                </>
            </Dropdown>
        );
    }

    return (
        <DropdownOld
            isOpen={isOpened}
            onRequestClose={onDropdownClose}
            panelFlow='to-left'
            button={(buttonRef) => (
                <Button icon ref={buttonRef} onClick={onDropdownOpen} variant='text' color='blue100'>
                    <StyledShowMatchingIcon />
                </Button>
            )}
        >
            <>
                <GlobalLoadingBar isLoading={isLoading && isOpened} />

                {isMatchingInfoFilled(matchingInfo) && (
                    <QBooksMatchingInfoContentOld
                        matchingInfo={matchingInfo}
                        lineItemId={lineItemId}
                        request={request}
                        onDropdownClose={onDropdownClose}
                        onRemoveMatching={onRemoveMatching}
                        isRemoveMatchingLoading={isRemoveMatchingLoading}
                        isAccountLineItem={isAccountLineItem}
                    />
                )}
            </>
        </DropdownOld>
    );
});

QBooksMatchingInfo.displayName = 'QBooksMatchingInfo';
