import { Reference } from '@approvalmax/types';
import { dateTimeHelpers } from '@approvalmax/utils';
import { selectors } from 'modules/common';
import { domain, State } from 'modules/data';
import { DiscountType } from 'modules/data/backend';
import { clearCacheStorage } from 'modules/data-providers';
import {
    createAction,
    createAsyncAction,
    createErrorAction,
    createSelectingAction,
    ExtractActions,
} from 'modules/react-redux';
import { ActionKind } from 'modules/react-redux/types/Action';
import { api } from 'services/api';
import { hasChangesState } from 'shared/states';
import { jotaiStore } from 'shared/store';

import { createEditableXeroContact, EditableXeroContact } from '../data/xero/EditableXeroContact';
import { XeroAddress } from '../data/xero/XeroAddress';
import { parseXeroContact, XeroContact } from '../data/xero/XeroContact';
import * as xeroLineItem from '../data/xero/XeroLineItem';
import { createXeroLineItem } from '../data/xero/XeroLineItem';
import { getRequest } from '../selectors/pageSelectors';
import { getRequestEditMode } from '../selectors/requestSelectors';
import {
    getEditableXeroContactTransfer,
    getXeroContext,
    getXeroLineItemById,
    getXeroLineItems,
} from '../selectors/xero';
import { messages } from './actions.messages';

export const EDIT_REQUEST_FIELD = 'REQUESTFORM/EDIT_REQUEST_FIELD';

export const editRequestField = <TDetails, TKey extends keyof TDetails = keyof TDetails>(
    propName: TKey,
    newValue: TDetails[TKey]
) => {
    jotaiStore.set(hasChangesState, true);

    return createAction(EDIT_REQUEST_FIELD, {
        propName,
        newValue,
    });
};

export const SHOW_XERO_CONTACT_ADDRESS_SEARCH_POPUP = 'REQUESTFORM/SHOW_XERO_CONTACT_ADDRESS_SEARCH_POPUP';
export const showXeroContactAddressSearchPopup = () => createAction(SHOW_XERO_CONTACT_ADDRESS_SEARCH_POPUP, {});

export const SELECT_XERO_DELIVERY_ADDRESS = 'REQUESTFORM/SELECT_XERO_DELIVERY_ADDRESS';

export const selectXeroDeliveryAddress = (address: XeroAddress, contact: XeroContact | null = null) => {
    jotaiStore.set(hasChangesState, true);

    return createAction(SELECT_XERO_DELIVERY_ADDRESS, {
        address,
        contact,
    });
};

export const CHANGE_XERO_DELIVERY_DETAILS = 'REQUESTFORM/CHANGE_XERO_DELIVERY_DETAILS';

export const changeXeroDeliveryDetails = (deliveryDetails: domain.XeroPurchaseOrderDetails['delivery']) => {
    jotaiStore.set(hasChangesState, true);

    return createAction(CHANGE_XERO_DELIVERY_DETAILS, { deliveryDetails });
};

export const XERO_EMAIL_TO_SUPPLIER_INIT = 'REQUESTFORM/XERO_EMAIL_TO_SUPPLIER_INIT';
export const xeroEmailToSupplierInit = (initialSendToSupplier: boolean) =>
    createSelectingAction(XERO_EMAIL_TO_SUPPLIER_INIT, (state) => {
        const request = getRequest<domain.XeroPoRequest>(state);
        const context = getXeroContext(state);
        const author = selectors.profile.getProfileUser(state);
        const company = selectors.company.getCompanyById(state, request.companyId);
        const details = request.details;
        const isNew = !dateTimeHelpers.toDuration(request.createdDate, request.modifiedDate).asSeconds();

        return { request, context, author, company, details, isNew, initialSendToSupplier };
    });

export const XERO_EMAIL_TO_SUPPLIER_TOGGLE = 'REQUESTFORM/XERO_EMAIL_TO_SUPPLIER_TOGGLE';

export const xeroEmailToSupplierToggle = (sendToSupplier: boolean) => {
    jotaiStore.set(hasChangesState, true);

    return createAction(XERO_EMAIL_TO_SUPPLIER_TOGGLE, { sendToSupplier });
};

export const XERO_EMAIL_TO_SUPPLIER_CHANGE_DATA = 'REQUESTFORM/XERO_EMAIL_TO_SUPPLIER_CHANGE_DATA';

export const xeroEmailToSupplierChangeData = (
    value: domain.EmailToSupplier[keyof domain.EmailToSupplier],
    key: keyof domain.EmailToSupplier
) => {
    jotaiStore.set(hasChangesState, true);

    return createAction(XERO_EMAIL_TO_SUPPLIER_CHANGE_DATA, { value, key });
};

export const CHANGE_XERO_SUPPLIER = 'REQUESTFORM/CHANGE_XERO_SUPPLIER';

export const changeXeroSupplier = (supplier: XeroContact | null) => {
    jotaiStore.set(hasChangesState, true);

    return createSelectingAction(CHANGE_XERO_SUPPLIER, (state) => {
        const request = getRequest<domain.XeroPoRequest>(state);
        const context = getXeroContext(state);
        const company = selectors.company.getCompanyById(state, request.companyId);
        const details = request.details;

        return { company, supplier, context, details };
    });
};

export const CHANGE_XERO_TERMS = 'REQUESTFORM/CHANGE_XERO_TERMS';

export const changeXeroTerms = (terms: string) => {
    jotaiStore.set(hasChangesState, true);

    return createSelectingAction(CHANGE_XERO_TERMS, () => {
        return { terms };
    });
};

export const CHANGE_XERO_LINE_ITEMS_ITEM = 'REQUESTFORM/CHANGE_XERO_LINE_ITEMS_ITEM';

export const changeXeroLineItemsItem = (lineItemIds: string[], newItem: domain.XeroItem | null) => {
    jotaiStore.set(hasChangesState, true);

    return createSelectingAction(CHANGE_XERO_LINE_ITEMS_ITEM, (state) => ({
        xeroContext: getXeroContext(state),
        editMode: getRequestEditMode(state, getRequest(state)),
        lineItemIds,
        newItem,
    }));
};

export const CHANGE_XERO_LINE_ITEMS_TAX = 'REQUESTFORM/CHANGE_XERO_LINE_ITEMS_TAX';

export const changeXeroLineItemsTax = (lineItemIds: string[], newTax: domain.XeroTaxCode | null) => {
    jotaiStore.set(hasChangesState, true);

    return createSelectingAction(CHANGE_XERO_LINE_ITEMS_TAX, () => ({
        lineItemIds,
        newTax,
    }));
};

export const CHANGE_XERO_LINE_ITEMS_ACCOUNT = 'REQUESTFORM/CHANGE_XERO_LINE_ITEMS_ACCOUNT';

export const changeXeroLineItemsAccount = (params: {
    lineItemIds: string[];
    newAccount: domain.XeroAccount | null;
    recalculateTax?: boolean;
}) => {
    jotaiStore.set(hasChangesState, true);

    return createSelectingAction(CHANGE_XERO_LINE_ITEMS_ACCOUNT, (state) => ({
        xeroContext: getXeroContext(state),
        ...params,
    }));
};

export const CHANGE_XERO_LINE_ITEM_QTY = 'REQUESTFORM/CHANGE_XERO_LINE_ITEM_QTY';

export const changeXeroLineItemQty = (lineItemId: string, newQty: number | null) => {
    jotaiStore.set(hasChangesState, true);

    return createSelectingAction(CHANGE_XERO_LINE_ITEM_QTY, () => ({
        lineItemId,
        newQty,
    }));
};

export const CHANGE_XERO_LINE_ITEM_UNIT_PRICE = 'REQUESTFORM/CHANGE_XERO_LINE_ITEM_UNIT_PRICE';

export const changeXeroLineItemUnitPrice = (lineItemId: string, newUnitPrice: number | null) => {
    jotaiStore.set(hasChangesState, true);

    return createSelectingAction(CHANGE_XERO_LINE_ITEM_UNIT_PRICE, () => ({
        lineItemId,
        newUnitPrice,
    }));
};

export const CHANGE_XERO_LINE_ITEM_DESCRIPTION = 'REQUESTFORM/CHANGE_XERO_LINE_ITEM_DESCRIPTION';

export const changeXeroLineItemDescription = (lineItemId: string, newDescription: string) => {
    jotaiStore.set(hasChangesState, true);

    return createSelectingAction(CHANGE_XERO_LINE_ITEM_DESCRIPTION, () => ({
        lineItemId,
        newDescription,
    }));
};

export const CHANGE_XERO_LINE_ITEM_DISCOUNT = 'REQUESTFORM/CHANGE_XERO_LINE_ITEM_DISCOUNT';

export const changeXeroLineItemDiscount = (lineItemId: string, newDiscount: number | null) => {
    jotaiStore.set(hasChangesState, true);

    return createSelectingAction(CHANGE_XERO_LINE_ITEM_DISCOUNT, () => ({
        lineItemId,
        newDiscount,
    }));
};

export const CHANGE_XERO_LINE_ITEM_DISCOUNT_TYPE = 'REQUESTFORM/CHANGE_XERO_LINE_ITEM_DISCOUNT_TYPE';

export const changeXeroLineItemDiscountType = (lineItemId: string, newDiscountType: DiscountType) => {
    jotaiStore.set(hasChangesState, true);

    return createSelectingAction(CHANGE_XERO_LINE_ITEM_DISCOUNT_TYPE, () => ({
        lineItemId,
        newDiscountType,
    }));
};

export const CHANGE_XERO_LINE_ITEM_DISCOUNT_AMOUNT = 'REQUESTFORM/CHANGE_XERO_LINE_ITEM_DISCOUNT_AMOUNT';

export const changeXeroLineItemDiscountAmount = (lineItemId: string, newDiscountAmount: number | null) => {
    jotaiStore.set(hasChangesState, true);

    return createSelectingAction(CHANGE_XERO_LINE_ITEM_DISCOUNT_AMOUNT, () => ({
        lineItemId,
        newDiscountAmount,
    }));
};

export const CHANGE_XERO_LINE_ITEM_TAX_AMOUNT = 'REQUESTFORM/CHANGE_XERO_LINE_ITEM_TAX_AMOUNT';

export const changeXeroLineItemTaxAmount = (lineItemId: string, newTaxAmount: number | null) => {
    jotaiStore.set(hasChangesState, true);

    return createSelectingAction(CHANGE_XERO_LINE_ITEM_TAX_AMOUNT, () => ({
        lineItemId,
        newTaxAmount,
    }));
};

export const CHANGE_XERO_LINE_ITEMS_TRACKING_CATEGORY_VALUE =
    'REQUESTFORM/CHANGE_XERO_LINE_ITEMS_TRACKING_CATEGORY_VALUE';

export const changeXeroLineItemsTrackingCategoryValue = (
    lineItemIds: string[],
    trackingCategoryId: string,
    newValue: Reference | null
) => {
    jotaiStore.set(hasChangesState, true);

    return createSelectingAction(CHANGE_XERO_LINE_ITEMS_TRACKING_CATEGORY_VALUE, (state) => ({
        xeroContext: getXeroContext(state),
        lineItemIds,
        trackingCategoryId,
        newValue,
    }));
};

export const CHANGE_XERO_LINE_ITEM_CHECKED = 'REQUESTFORM/CHANGE_XERO_LINE_ITEM_CHECKED';
export const changeXeroLineItemChecked = (lineItemId: string, checked?: boolean) =>
    createSelectingAction(CHANGE_XERO_LINE_ITEM_CHECKED, () => ({
        lineItemId,
        checked,
    }));

export const CHANGE_XERO_LINE_ITEM_ALL_CHECKED = 'REQUESTFORM/CHANGE_XERO_LINE_ITEM_ALL_CHECKED';
export const changeXeroLineItemAllChecked = (checked: boolean) =>
    createSelectingAction(CHANGE_XERO_LINE_ITEM_ALL_CHECKED, () => checked);

export const CHANGE_XERO_LINE_AMOUNT_TYPE = 'REQUESTFORM/CHANGE_XERO_LINE_AMOUNT_TYPE';

export const changeXeroLineAmountType = (newLineAmountType: domain.LineAmountType) => {
    jotaiStore.set(hasChangesState, true);

    return createSelectingAction(CHANGE_XERO_LINE_AMOUNT_TYPE, (state) => ({
        xeroContext: getXeroContext(state),
        newLineAmountType,
    }));
};

export const REORDER_XERO_LINE_ITEMS = 'REQUESTFORM/REORDER_XERO_LINE_ITEMS';

export const reorderXeroLineItems = (oldIndex: number, newIndex: number) => {
    jotaiStore.set(hasChangesState, true);

    return createAction(REORDER_XERO_LINE_ITEMS, {
        oldIndex,
        newIndex,
    });
};

export const REMOVE_XERO_LINE_ITEMS = 'REQUESTFORM/REMOVE_XERO_LINE_ITEMS';

export const removeXeroLineItems = (lineItemIds: string[]) => {
    jotaiStore.set(hasChangesState, true);

    return createAction(REMOVE_XERO_LINE_ITEMS, {
        lineItemIds,
        newLineItem: createXeroLineItem(),
    });
};

export const REMOVE_XERO_LINE_ITEMS_ALL = 'REQUESTFORM/REMOVE_XERO_LINE_ITEMS_ALL';

export const removeXeroLineItemsAll = () => {
    jotaiStore.set(hasChangesState, true);

    return createAction(REMOVE_XERO_LINE_ITEMS_ALL, {
        newLineItem: createXeroLineItem(),
    });
};

export const CLONE_XERO_LINE_ITEMS = 'REQUESTFORM/CLONE_XERO_LINE_ITEMS';

export const cloneXeroLineItems = (lineItemIds: string[]) => {
    jotaiStore.set(hasChangesState, true);

    return createSelectingAction(CLONE_XERO_LINE_ITEMS, (state) => ({
        lineItemIds,
        newLineItems: lineItemIds.map((lineItemId) =>
            xeroLineItem.cloneXeroLineItem(getXeroLineItemById(state, getRequest(state), lineItemId))
        ),
    }));
};

export const CLONE_XERO_LINE_ITEMS_ALL = 'REQUESTFORM/CLONE_XERO_LINE_ITEMS_ALL';

export const cloneXeroLineItemsAll = () => {
    jotaiStore.set(hasChangesState, true);

    return createSelectingAction(CLONE_XERO_LINE_ITEMS_ALL, (state) => {
        const lineItemIds = getXeroLineItems(state, getRequest(state)).map((lineItem) => lineItem.id);

        return {
            lineItemIds,
            newLineItems: lineItemIds.map((lineItemId) =>
                xeroLineItem.cloneXeroLineItem(getXeroLineItemById(state, getRequest(state), lineItemId))
            ),
        };
    });
};

export const ADD_XERO_LINE_ITEM = 'REQUESTFORM/ADD_XERO_LINE_ITEM';

export const addXeroLineItem = () => {
    jotaiStore.set(hasChangesState, true);

    return createAction(ADD_XERO_LINE_ITEM, {
        newLineItem: createXeroLineItem(),
    });
};

export const SHOW_XERO_CONTACT_CREATION_POPUP = 'REQUESTFORM/SHOW_XERO_CONTACT_CREATION_POPUP';
export const showXeroContactCreationPopup = (contactName: string | null) =>
    createAction(SHOW_XERO_CONTACT_CREATION_POPUP, {
        contact: createEditableXeroContact(contactName || ''),
    });

export const CREATE_XERO_CONTACT = 'REQUESTFORM/CREATE_XERO_CONTACT';
export const CREATE_XERO_CONTACT_RESPONSE = 'REQUESTFORM/CREATE_XERO_CONTACT_RESPONSE';
export const CREATE_XERO_CONTACT_FAILURE = 'REQUESTFORM/CREATE_XERO_CONTACT_FAILURE';
export const createXeroContact = (newContact: EditableXeroContact) =>
    createAsyncAction({
        request: (state: State) => {
            const request = getRequest<domain.XeroPoRequest>(state);
            const context = getXeroContext(state);
            const transfer = getEditableXeroContactTransfer(state, newContact);
            const company = selectors.company.getCompanyById(state, request.companyId);
            const details = request.details;

            return createAction(CREATE_XERO_CONTACT, { newContact, transfer, company, context, details });
        },

        response: async (request) => {
            const response = await api.integration.createCounterparty(request.transfer);
            const supplier = parseXeroContact(response.Counterparty);
            const details = request.details;

            jotaiStore.set(hasChangesState, true);

            return createAction(CREATE_XERO_CONTACT_RESPONSE, {
                supplier,
                details,
                company: request.company,
                context: request.context,
            });
        },

        failure: (error) => createErrorAction(CREATE_XERO_CONTACT_FAILURE, error, {}),

        successToast: messages.xeroContactCreationSuccess,

        isBlocking: true,

        didDispatchResponse: (request, response, state, dispatch) => {
            dispatch(clearCacheStorage((storageId) => String(storageId).startsWith('requestOptions')));
        },
    });

export const UPDATE_XERO_BP_DETAILS = 'REQUESTFORM/UPDATE_XERO_BP_DETAILS';
export const updateXeroBPDetails = (details: domain.XeroBillBatchPayment) =>
    createAction(UPDATE_XERO_BP_DETAILS, {
        details,
    });

export const UPDATE_XERO_AIRWALLEX_BP_DETAILS = 'REQUESTFORM/UPDATE_XERO_AIRWALLEX_BP_DETAILS';
export const updateXeroAirwallexBPDetails = (details: domain.XeroAirwallexBatchPayment) =>
    createAction(UPDATE_XERO_AIRWALLEX_BP_DETAILS, {
        details,
    });

export const UPDATE_XERO_AMAXPAY_BP_DETAILS = 'REQUESTFORM/UPDATE_XERO_AMAXPAY_BP_DETAILS';
export const updateXeroAmaxPayBPDetails = (details: domain.XeroAmaxPayBatchPayment) =>
    createAction(UPDATE_XERO_AMAXPAY_BP_DETAILS, {
        details,
    });

export const CHANGE_XERO_MANUAL_JOURNAL_REQUEST = 'REQUESTFORM/CHANGE_XERO_MANUAL_JOURNAL_REQUEST';

export const changeXeroManualJournalRequest = () => {
    jotaiStore.set(hasChangesState, true);

    return createAction(CHANGE_XERO_MANUAL_JOURNAL_REQUEST, {});
};

export const SET_LOAD_INDICATOR_CONTACT_CIS_SETTINGS = 'REQUESTFORM/SET_LOAD_INDICATOR_CONTACT_CIS_SETTINGS';
export const setLoadIndicatorContactCisSettings = (type: 'start' | 'stop') =>
    createAction(SET_LOAD_INDICATOR_CONTACT_CIS_SETTINGS, undefined, undefined, {
        kind: type === 'start' ? ActionKind.AsyncRequest : ActionKind.AsyncResponse,
        operationId: SET_LOAD_INDICATOR_CONTACT_CIS_SETTINGS,
        isBlocking: true,
    });

export const UPDATE_XERO_CONTACT_BANK_ACCOUNT_WITH_SORT_CODE =
    'REQUESTFORM/UPDATE_XERO_CONTACT_BANK_ACCOUNT_WITH_SORT_CODE';
export const updateXeroContactBankAccountWithSortCode = (bankAccountNumber: string, sortCode: string) =>
    createAction(UPDATE_XERO_CONTACT_BANK_ACCOUNT_WITH_SORT_CODE, { bankAccountNumber, sortCode });

export type Action = ExtractActions<
    | typeof addXeroLineItem
    | typeof changeXeroDeliveryDetails
    | typeof changeXeroLineAmountType
    | typeof changeXeroLineItemsAccount
    | typeof changeXeroLineItemChecked
    | typeof changeXeroLineItemAllChecked
    | typeof changeXeroLineItemDescription
    | typeof changeXeroLineItemDiscount
    | typeof changeXeroLineItemDiscountAmount
    | typeof changeXeroLineItemDiscountType
    | typeof changeXeroLineItemsItem
    | typeof changeXeroLineItemQty
    | typeof changeXeroLineItemsTax
    | typeof changeXeroLineItemTaxAmount
    | typeof changeXeroLineItemsTrackingCategoryValue
    | typeof changeXeroLineItemUnitPrice
    | typeof changeXeroManualJournalRequest
    | typeof changeXeroSupplier
    | typeof changeXeroTerms
    | typeof cloneXeroLineItems
    | typeof createXeroContact
    | typeof cloneXeroLineItemsAll
    | typeof editRequestField
    | typeof removeXeroLineItems
    | typeof removeXeroLineItemsAll
    | typeof reorderXeroLineItems
    | typeof selectXeroDeliveryAddress
    | typeof setLoadIndicatorContactCisSettings
    | typeof showXeroContactAddressSearchPopup
    | typeof showXeroContactCreationPopup
    | typeof updateXeroAirwallexBPDetails
    | typeof updateXeroAmaxPayBPDetails
    | typeof updateXeroBPDetails
    | typeof updateXeroContactBankAccountWithSortCode
    | typeof xeroEmailToSupplierChangeData
    | typeof xeroEmailToSupplierInit
    | typeof xeroEmailToSupplierToggle
>;
