import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages(
    'pages.RequestList.XeroMatchingV2Section.BillView.ChangeMatchingPopup.ChangeMatchingPopupContent',
    {
        popupTitle: 'Bill to Purchase Order matching',
        saveButtonText: 'Save',
        excessAmountWarning: `Allocated amounts can't exceed the Bill amount`,
    }
);
