import { domain } from 'modules/data';
import { useMemo } from 'react';
import { findDocumentFieldState } from 'shared/helpers';

export const useNetSuiteBillPaymentApplyListFieldSettings = (documentFields: domain.DocumentField[] | undefined) =>
    useMemo(
        () => ({
            amountDue: findDocumentFieldState(documentFields, 'ApplyLineAmountDue'),
            currency: findDocumentFieldState(documentFields, 'ApplyLineCurrencyName'),
            dateDue: findDocumentFieldState(documentFields, 'ApplyLineDateDue'),
            discountAmount: findDocumentFieldState(documentFields, 'ApplyLineDiscountAmount'),
            discountAvailable: findDocumentFieldState(documentFields, 'ApplyLineDiscountAvailable'),
            discountDate: findDocumentFieldState(documentFields, 'ApplyLineDiscountDate'),
            originalAmount: findDocumentFieldState(documentFields, 'ApplyLineOriginalAmount'),
            paymentAmount: findDocumentFieldState(documentFields, 'ApplyLinePaymentAmount'),
            reference: findDocumentFieldState(documentFields, 'ApplyLineReference'),
            type: findDocumentFieldState(documentFields, 'ApplyLineType'),
        }),
        [documentFields]
    );
