import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages(
    'pages.RequestList.AirwallexBatchPaymentRequestCard.BillsInformationSection.TotalContainer',
    {
        total: 'Total:',
        multicurrencyTotal: 'The total amount is approximate, and the exchange rates are derived from the bills:',
        currencyTotal: 'Final total amount in {currency} including {feeAmount} fee:',
    }
);
