import { Guid } from '@approvalmax/types';
import { backend } from 'modules/data';
import { stringify } from 'query-string';

// TODO: Need to fix in a separate task (refactoring RequestReportsApi class)
// See task: https://approvalmax.atlassian.net/browse/AM-22867
import { getReportConfigTransfer } from '../../../app/(workspace)/[companyId]/reports/resources/selectors/reportConfigSelectors';
import { ApiHandler } from '../types';

class RequestReportsUrls {
    constructor(private _apiHandler: ApiHandler) {}

    public getAccrualReport(params: backend.transfers.RequestAccrualReportTransfer): string {
        return `${this._apiHandler.getApiUrlPrefix()}requestReports/getAccrualReport?${stringify(params)}`;
    }

    public getAuditReportsCollection(companyId: Guid, collectionId: Guid): string {
        return `${this._apiHandler.getApiUrlPrefix()}requestReports/getAuditReportsCollection?${stringify({
            companyId,
            collectionId,
        })}`;
    }
}

export default class RequestReportsApi {
    public urls: RequestReportsUrls;

    constructor(private _apiHandler: ApiHandler) {
        this.urls = new RequestReportsUrls(this._apiHandler);
    }

    public exportAuditReports(
        params: ReturnType<typeof getReportConfigTransfer>
    ): Promise<backend.RequestReportCollectAnswer> {
        return this._apiHandler.doApiCall({
            data: params,
            action: 'requestReports/exportAuditReports',
            method: 'POST',
            allowsAnonymous: false,
        });
    }

    public exportAttachments(params: ReturnType<typeof getReportConfigTransfer>): Promise<backend.BaseAnswer> {
        return this._apiHandler.doApiCall({
            data: params,
            action: 'requestReports/exportAttachments',
            method: 'POST',
            allowsAnonymous: false,
        });
    }

    /**
     * @deprecated
     *
     * @use useGetReportSchedule
     */
    public getReportSchedule(params: { companyId: string; reportId: string; type: string }): Promise<any> {
        return this._apiHandler.doApiCall({
            data: params,
            action: 'reports/schedules/get',
            method: 'POST',
            allowsAnonymous: false,
        });
    }

    /**
     * @deprecated
     *
     * @use useUpdateReportSchedule
     */
    public updateReportSchedule(params: {
        companyId: string;
        reportId: string;
        type: string;
        schedules: any[];
    }): Promise<void> {
        return this._apiHandler.doApiCall({
            data: params,
            action: 'reports/schedules/update',
            method: 'POST',
            allowsAnonymous: false,
        });
    }
}
