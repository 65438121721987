import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('app.WorkflowIdPage.StepTypeEditor.ChoiceTypePopupContent', {
    title: 'Approval condition',
    doneButton: 'Done',
    description:
        'Select whether all Approvers or only a certain number of Approvers are required to complete this step. {url}',
    learnMore: 'Learn more',
    stepTypeDefaultTitle: 'All',
    stepTypeDefaultDescription: 'All assigned Approvers must approve this request.',
    stepTypeAnyOfTitle: 'Any of',
    stepTypeAnyOfDescription: 'Only the specified number of Approvers need to approve this request.',
    stepTypeAnyOfDescriptionImportantLabel: 'Important: ',
    stepTypeAnyOfDescriptionImportantNote:
        'If you don’t set the required number of Approvers, all assigned Approvers will need to approve.',
    numberOfApproversLabel: 'Number of approvers',
});
