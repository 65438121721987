import { ApiError, ErrorCode } from '@approvalmax/types';
import { arrayHelpers, errorHelpers } from '@approvalmax/utils';
import { selectors } from 'modules/common';
import { domain, State } from 'modules/data';
import { clearCacheStorage } from 'modules/data-providers';
import { integrationActions } from 'modules/integration';
import { useDispatch, useSelector, useShallowEqualSelector } from 'modules/react-redux';
import { useEffect, useMemo, useState } from 'react';

import { getMatrixDefinition } from '../config/matrixDefinitions';
import { MatrixDefinition } from '../config/matrixDefinitions.shared';
import { getActiveTemplate, isPageReadonly } from '../selectors/pageSelectors';
import { getTemplateSubmitters } from '../selectors/templateSelectors';
import { ActiveMatrixData } from '../types/activeMatrixData';
import { MatrixType } from '../types/matrix';
import { messages } from './messages';

export const useCellFields = (definition: MatrixDefinition, matrix: ActiveMatrixData) => {
    const dispatch = useDispatch();
    const [loaded, setLoaded] = useState(false);
    const template = useSelector(getActiveTemplate);

    if (!template) {
        throw errorHelpers.notFoundError('Template not found.');
    }

    const { companyId, integrationCode } = template;
    const fields = useSelector((state) => selectors.field.getFieldsByCompanyId(state, companyId));
    const loadedWithError = loaded && !!matrix.error;

    useEffect(() => {
        async function load() {
            dispatch(clearCacheStorage(() => true));
            await dispatch(integrationActions.loadTemplateFieldsByCode({ companyId, integrationCode }));
            setLoaded(true);
        }

        void load();
    }, [dispatch, companyId, integrationCode]);

    const cellFields = useMemo(
        () =>
            !loaded || loadedWithError
                ? arrayHelpers.emptyArray<domain.Field>()
                : definition.columns.flatMap((colDef) => {
                      let targetFields;

                      if (
                          colDef.systemPurpose === domain.FieldSystemPurpose.General ||
                          colDef.systemPurpose === domain.FieldSystemPurpose.NetSuiteCustom
                      ) {
                          if (
                              colDef.systemPurpose === domain.FieldSystemPurpose.General &&
                              matrix.type === MatrixType.Requester
                          ) {
                              throw errorHelpers.notSupportedError(
                                  'General fields are only supported for step-related matrices.'
                              );
                          }

                          targetFields = matrix.generalFieldOrder.map((fId) => {
                              const f = fields.find((f) => f.id === fId);

                              if (!f) {
                                  throw errorHelpers.notFoundError(`Failed to find the field with id ${fId}`);
                              }

                              return f;
                          });
                      } else {
                          // it might be an empty array, e.g.: tracking categories in xero, disabled fields in qbo etc
                          targetFields = fields.filter((field) => field.systemPurpose === colDef.systemPurpose);
                      }

                      return targetFields;
                  }),
        [definition.columns, fields, loaded, loadedWithError, matrix.generalFieldOrder, matrix.type]
    );

    return { loaded, cellFields };
};

export const useLoadingError = (loadedWithError: boolean, error?: ApiError) => {
    if (loadedWithError) {
        switch (error?.code) {
            case ErrorCode.E4046_SUBSCRIPTION_EXPIRED:
                return messages.errorLoadingSubscriptionExpiredText({
                    br: <br />,
                });

            case ErrorCode.E4127_TRIAL_EXPIRED:
                return messages.errorLoadingTrialExpiredText({
                    br: <br />,
                });

            default:
                return messages.errorLoadingText;
        }
    }

    return null;
};

export const useMatrixData = (matrix: ActiveMatrixData) => {
    const {
        definition,
        company,
        team,
        template,
        hiddenHelpItems,
        integrationType,
        fields,
        integrationErrorPreventsWork,
        readonly,
        submitters,
        canAssignDefaultApprover,
        canAssignDefaultReviewer,
        step,
    } = useShallowEqualSelector((state: State) => {
        const template = getActiveTemplate(state)!;
        const companyId = template.companyId;
        const company = selectors.company.getCompanyById(state, companyId);
        const fields = selectors.field.getFieldsByCompanyId(state, companyId);
        const team = selectors.company.getCompanyTeam(state, company);
        const readonly = isPageReadonly(state);

        const definition = getMatrixDefinition({
            betaFeatures: company.betaFeatures,
            licenseFeatures: company.licenseFeatures,
            integrationCode: template.integrationCode,
            matrixType: matrix.type,
        });

        const submitters: selectors.types.ExpandedCompanyUser[] = getTemplateSubmitters(state, company.id).map(
            (user) => {
                return (
                    team.find((t) => t.id === user.id) ||
                    ({
                        ...user,
                        role: domain.CompanyUserRole.None,
                        status: domain.CompanyUserStatus.Active,
                        statusText: selectors.company.getCompanyUserStatusText(domain.CompanyUserStatus.Active),
                    } as selectors.types.ExpandedCompanyUser)
                );
            }
        );
        const integrationErrorPreventsWork = Boolean(!company.flags.hasActiveIntegration && template.integrationCode);

        return {
            definition,
            company,
            team,
            template,
            hiddenHelpItems: selectors.userPreferences.getHiddenHelpItems(state),
            integrationType: selectors.integration.getIntegrationType(template.integrationCode),
            matrix,
            fields,
            integrationErrorPreventsWork,
            readonly,
            submitters,
            canAssignDefaultReviewer:
                matrix.type === MatrixType.Reviewer && selectors.templateStep.canAssignDefaultReviewer(matrix.data),
            canAssignDefaultApprover:
                matrix.type === MatrixType.Approval && selectors.templateStep.canAssignDefaultApprover(matrix.data),
            step:
                matrix.type === MatrixType.Approval || matrix.type === MatrixType.Editing
                    ? template.steps[matrix.stepIndex]
                    : undefined,
        };
    });
    const { loaded, cellFields } = useCellFields(definition, matrix);

    const loadedNoError = loaded && !matrix.error;
    const loadedWithError = loaded && !!matrix.error;
    const errorMessage = useLoadingError(loadedWithError, matrix.error);

    return {
        definition,
        company,
        team,
        template,
        hiddenHelpItems,
        integrationType,
        matrix,
        fields,
        integrationErrorPreventsWork,
        readonly,
        submitters,
        canAssignDefaultApprover,
        step,
        loaded,
        cellFields,
        loadedNoError,
        loadedWithError,
        errorMessage,
        canAssignDefaultReviewer,
    };
};
