import { domain } from '..';
import { SupplierEmailSettings } from '../backend';
import { CompanyBillMatchingSettings, CompanyPurchaseOrderMatchingSettings } from './Company';

export enum TemplateSettingsLockDatePolicy {
    LockApproval = 'LockApproval',
    ApproveWithNextDay = 'ApproveWithNextDay',
}

export enum TemplateSettingsAutoDecisionPolicyType {
    AutoApprove = 'AutoApprove',
    AutoReject = 'AutoReject',
}

export enum TemplateSettingsDecisionPolicyType {
    AllSteps = 'AllSteps',
    OnlyCurrent = 'OnlyCurrent',
}

export enum TemplateSettingsQBooksEnabledLineItemType {
    All = 'All',
    ItemBasedLines = 'ItemBasedLines',
    AccountBasedLines = 'AccountBasedLines',
    None = 'None',
}

export enum TemplateSettingsPostApprovalChangeDetectionTarget {
    ContactId = 'ContactId',
    NetAmount = 'NetAmount',
    TrackingCategories = 'TrackingCategories',
    LineItemAccountCode = 'LineItemAccountCode',
    LineItemCode = 'LineItemCode',
    LineItemDescription = 'LineItemDescription',
    Currency = 'Currency',
}

export enum TemplateSettingsNetSuitePostApprovalChangeDetectionTarget {
    Customer = 'Customer',
    Payee = 'Payee',
    Vendor = 'Vendor',
    Amount = 'Amount',
    Date = 'Date',
    Memo = 'Memo',
    Item = 'Item',
    Account = 'Account',
    Category = 'Category',
    Line = 'Line',
}

export enum TemplateSettingsDearPostApprovalChangeDetectionTarget {
    Supplier = 'Supplier',
    Amount = 'Amount',
    Date = 'Date',
    Location = 'Location',
    Memo = 'Memo',
    ItemLine = 'ItemLine',
    AdditionalChargeLine = 'AdditionalChargeLine',
}

export enum TemplateSettingsQBooksPostApprovalChangeDetectionTarget {
    NetAmount = 'NetAmount',
    VendorId = 'VendorId',
    Payee = 'Payee',
    PaymentDetails = 'PaymentDetails',
    LineItemProduct = 'LineItemProduct',
    LineItemCategory = 'LineItemCategory',
    Location = 'Location',
    LineItemClass = 'LineItemClass',
    LineItemDescription = 'LineItemDescription',
    Currency = 'Currency',
}

export enum TemplateSettingsEmailToSupplierState {
    Disabled = 'Disabled',
    Enabled = 'Enabled',
    EnabledAndActive = 'EnabledAndActive',
}

export enum HistoryPushingType {
    None = 'None',
    Comments = 'OnlyWithComment',
    All = 'All',
}

export enum NetSuiteAvailableLineItemType {
    All = 'All',
    ExpenseLines = 'ExpenseLines',
    ItemLines = 'ItemLines',
}

export enum PostingPreferencesType {
    AsDraft = 'AsDraft',
    AsPosted = 'AsPosted',
}

export interface ApprovalDisregardDetectionSettings {
    effectiveDate: string | null;
    enabled: boolean;
    notifyAdmins?: boolean;
}

export interface TemplateSettings {
    templateId: string;
    lockDatePolicySettings?: {
        lockDatePolicy: TemplateSettingsLockDatePolicy;
    };
    autoDecisionPolicySettings?: {
        autoDecisionPolicy: TemplateSettingsAutoDecisionPolicyType;
    };
    approvalDisregardDetectionSettings?: ApprovalDisregardDetectionSettings;
    isSupplierBankAccountRequired?: boolean;
    postApprovalChangeDetectionSettings?: {
        notifyAdmins: boolean;
        targets: TemplateSettingsPostApprovalChangeDetectionTarget[];
    };
    billMatchingSettings?: CompanyBillMatchingSettings;
    purchaseOrderMatchingSettings?: CompanyPurchaseOrderMatchingSettings;
    requesterInstructionSettings?: {
        instruction: string;
    };
    lineItemSettings?: {
        qbooksEnabledLineItemType: TemplateSettingsQBooksEnabledLineItemType;
    };
    approveAllStepsEnabled: TemplateSettingsDecisionPolicyType;
    appUrlOverridingEnabled: boolean;
    supplierEmailSettings: SupplierEmailSettings;
    qBooksApprovalDisregardDetectionSettings?: ApprovalDisregardDetectionSettings;
    qBooksPostApprovalChangeDetectionSettings?: {
        notifyAdmins: boolean;
        targets: TemplateSettingsQBooksPostApprovalChangeDetectionTarget[];
    };
    netSuiteApprovalDisregardDetectionSettings?: ApprovalDisregardDetectionSettings;
    netSuitePostApprovalChangeDetectionSettings?: {
        notifyAdmins: boolean;
        targets: TemplateSettingsNetSuitePostApprovalChangeDetectionTarget[];
    };
    netSuiteCustomFormId?: number;
    dearApprovalDisregardDetectionSettings?: ApprovalDisregardDetectionSettings;
    dearPostApprovalChangeDetectionSettings?: {
        notifyAdmins: boolean;
        targets: TemplateSettingsDearPostApprovalChangeDetectionTarget[];
    };
    netSuiteAvailableLineItemType?: NetSuiteAvailableLineItemType;
    isGrnEnabled?: boolean;
    isPriceCheckerEnabled?: boolean;
    postingPreferencesType?: PostingPreferencesType;
    historyEventsPushingSettings?: { historyEventsPushingType: HistoryPushingType };
    useRejectedPrefix?: boolean;
    terms?: string;
    sendRejectedNoteToDear?: boolean;
    pushApprovalMaxURLToNetSuite?: boolean;
    ocrEmailAddress?: string;
    ocrRequestInitialStatus?: domain.RequestStatusV2;
    isBalanceControlCheckEnabled?: boolean;
}
