import { domain } from 'modules/data';
import { useMemo } from 'react';
import { findDocumentFieldState } from 'shared/helpers';

export const useNetSuiteBillPaymentCreditListFieldSettings = (documentFields: domain.DocumentField[] | undefined) =>
    useMemo(
        () => ({
            appliedTo: findDocumentFieldState(documentFields, 'CreditLineAppliedTo'),
            currency: findDocumentFieldState(documentFields, 'CreditLineCurrencyName'),
            dateDue: findDocumentFieldState(documentFields, 'CreditLineDateDue'),
            paymentAmount: findDocumentFieldState(documentFields, 'CreditLinePaymentAmount'),
            reference: findDocumentFieldState(documentFields, 'CreditLineReference'),
            type: findDocumentFieldState(documentFields, 'CreditLineType'),
        }),
        [documentFields]
    );
