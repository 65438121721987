import { NumberEditor, TransparentButton } from '@approvalmax/ui';
import { errorHelpers, intl, mathService } from '@approvalmax/utils';
import { useQuery } from '@tanstack/react-query';
import { constants, QueryKeys } from 'modules/common';
import { domain } from 'modules/data';
import { GlobalLoadingBar } from 'modules/page';
import { useSelector } from 'modules/react-redux';
import LineItemFooterTotal from 'pages/requestList/components/lineItemsSection/LineItemFooterTotal';
import { LineItemsTable } from 'pages/requestList/components/lineItemsSection/LineItemsTable';
import { getActiveRequest } from 'pages/requestList/selectors/pageSelectors';
import { ColumnDefinition } from 'pages/requestList/selectors/types/ColumnDefinition';
import { FC, memo, useEffect, useState } from 'react';
import { api } from 'services/api';
import styled, { css } from 'styled-components';
import { VelocityTransitionGroup } from 'velocity-react';

import { getAllocationData } from '../../../../../../../../../../selectors';
import { MatchedBillTableItem } from '../../../../../../../../../../types';
import CollapsibleSection from '../../../../../../../../../CollapsibleSection/CollapsibleSection';
import ColorBar from '../../../../../../../../../ColorBar/ColorBar';
import { messages } from './AdditionalInformation.messages';

const columnDefinitions: ColumnDefinition<MatchedBillTableItem>[] = [
    {
        id: 'name',
        name: '',
        value: (li) => li.name,
    },
    {
        id: 'amount',
        name: '',
        value: (li) => intl.formatCurrency(li.amount, li.currency),
        alignRight: true,
    },
];

const ColorBarContainer = styled.div`
    width: 400px;
`;

const Text = styled.span<{ isBilled?: boolean }>`
    color: #000;
    font-size: 13px;
    white-space: pre-wrap;
    line-height: 16px;
    ${(props) => props.isBilled && 'color: #7f7d7d;'}
`;

const GreyText = styled.span`
    font-size: 12px;
    line-height: 14px;
    color: #5e5c5c;
`;

const RemainingText = styled(GreyText)<{ warning?: boolean }>`
    width: 250px;
    font-weight: 600;
    margin-left: auto;
    text-align: right;

    ${(props) => props.warning && 'color: #ca5c41'}
`;

const AmountContainer = styled.div`
    display: flex;
    justify-content: flex-end;
`;

const AmountEditor = styled(NumberEditor)<{ warning: boolean }>`
    width: 84px;
    margin: 0 8px;
    display: inline;

    ${(props) =>
        props.warning &&
        css`
            border-color: #ca5c41;
            color: #ca5c41;
        `}
`;

const Line = styled.div`
    align-items: center;
    margin-bottom: 15px;
    display: flex;
`;

const LastLine = styled(Line)`
    margin-bottom: 15px;
`;

const AllocatedContainer = styled.div`
    display: flex;
    align-items: center;
`;

const InsertSuggestionButton = styled(TransparentButton)`
    margin-left: auto;
    font-size: 12px;
    font-weight: 600;
    color: #477753;
`;

interface AdditionalInformationProps {
    onChangeAllocatedAmount: (amount: number) => void;
    allocatedAmount: number;
    isMatched: boolean;
    poId: string;
    currency: string;
    totalAmount: number;
    billId: string;
    billRemaining: number;
    isBilled: boolean;
    warning: boolean;
}

const AdditionalInformation: FC<AdditionalInformationProps> = (props) => {
    const {
        isBilled,
        onChangeAllocatedAmount,
        allocatedAmount,
        isMatched,
        poId,
        currency,
        totalAmount,
        billId,
        billRemaining,
        warning,
    } = props;

    const [shouldLoad, setShouldLoad] = useState(isMatched);
    const [localWarning, setLocalWarning] = useState(false);

    const companyId = useSelector(getActiveRequest).companyId;

    const { data, isInitialLoading } = useQuery(
        [QueryKeys.XERO_ALLOCATION_DATA, poId, companyId],
        async () => {
            const response = await api.xeroMatching.getListAllocations(poId, companyId);

            return response.data;
        },
        {
            enabled: shouldLoad,
        }
    );

    const billMatchingSettings = useSelector(getActiveRequest).company.billMatchingSettings;

    useEffect(() => {
        if (isMatched && !shouldLoad) setShouldLoad(true);
    }, [isMatched, shouldLoad]);

    if (isInitialLoading) return <GlobalLoadingBar isLoading={true} />;

    const handleChangeAmount = (amount: number | null) => {
        if (amount) {
            setLocalWarning(false);
        } else {
            setLocalWarning(true);
        }

        onChangeAllocatedAmount(amount || 0);
    };

    const { tableData, allocatedAmountToBilledPOs, allocatedAmountToNotBilledPOs } = getAllocationData(
        data?.allocations || [],
        billId
    );

    const totalAllocatedAmountToPOs = mathService.add(allocatedAmountToBilledPOs, allocatedAmountToNotBilledPOs);

    let remaining = mathService.subtract(totalAmount, mathService.add(totalAllocatedAmountToPOs, allocatedAmount));
    let maxAllocatedAmount: number | undefined = mathService.subtract(
        totalAmount,
        mathService.add(allocatedAmountToBilledPOs, allocatedAmountToNotBilledPOs)
    );
    let totalBarValue = totalAmount;
    let lightGreenBarValue = allocatedAmount;
    let threshold: number | undefined;
    let extraAmount: number | undefined;
    let extraAmountText: string = '';
    let exceededAmount: number | undefined;
    let warningRemaining = false;

    switch (billMatchingSettings?.allowApprovalOfOverbudgetBills) {
        case domain.CompanyMatchingSettingsBillApprovalPolicy.Always:
            if (remaining < 0) {
                exceededAmount = Math.abs(remaining);
                lightGreenBarValue = mathService.subtract(allocatedAmount, Math.abs(remaining));
                remaining = 0;
            }

            maxAllocatedAmount = undefined;
            break;

        case domain.CompanyMatchingSettingsBillApprovalPolicy.WithPercentageThreshold:
        case domain.CompanyMatchingSettingsBillApprovalPolicy.WithThreshold:
            threshold =
                billMatchingSettings.allowApprovalOfOverbudgetBills ===
                domain.CompanyMatchingSettingsBillApprovalPolicy.WithPercentageThreshold
                    ? mathService.round(
                          mathService.multiply(
                              totalAmount,
                              mathService.divide(billMatchingSettings.overbudgetBillsApprovalPercentageThreshold, 100)
                          ),
                          2
                      ) // totalAmount * (billMatchingSettings.overbudgetBillsApprovalPercentageThreshold / 100)
                    : billMatchingSettings.overbudgetBillsApprovalThreshold;

            extraAmount = threshold;

            maxAllocatedAmount = mathService.add(maxAllocatedAmount, threshold);
            totalBarValue = mathService.add(totalBarValue, threshold);

            if (allocatedAmount && allocatedAmount > maxAllocatedAmount) {
                warningRemaining = true;
            }

            if (maxAllocatedAmount < 0) {
                maxAllocatedAmount = 0;
                warningRemaining = true;
            }

            if (remaining < 0) {
                extraAmount = mathService.subtract(threshold, Math.abs(remaining));

                if (extraAmount < 0) {
                    extraAmount = undefined;
                } else {
                    remaining = 0;
                }
            }

            break;

        case domain.CompanyMatchingSettingsBillApprovalPolicy.Never:
            if (allocatedAmount && allocatedAmount > maxAllocatedAmount) {
                warningRemaining = true;
            }

            if (maxAllocatedAmount < 0) {
                maxAllocatedAmount = 0;
            }
    }

    if (billMatchingSettings?.allowApprovalOfOverbudgetBills) {
        switch (billMatchingSettings.allowApprovalOfOverbudgetBills) {
            case domain.CompanyMatchingSettingsBillApprovalPolicy.WithThreshold:
                extraAmountText = intl.formatCurrency(extraAmount || 0, currency);
                break;

            case domain.CompanyMatchingSettingsBillApprovalPolicy.WithPercentageThreshold: {
                const currencyAmountText = intl.formatCurrency(extraAmount || 0, currency);
                const percentageAmountText = intl.formatCurrency(
                    billMatchingSettings.overbudgetBillsApprovalPercentageThreshold,
                    '%',
                    0
                );

                extraAmountText = `${percentageAmountText} (${currencyAmountText})`;
                break;
            }

            case domain.CompanyMatchingSettingsBillApprovalPolicy.Always:
            case domain.CompanyMatchingSettingsBillApprovalPolicy.Never:
                extraAmountText = '';
                break;

            default:
                throw errorHelpers.assertNever(billMatchingSettings.allowApprovalOfOverbudgetBills);
        }
    }

    if (maxAllocatedAmount === undefined) {
        maxAllocatedAmount = constants.xeroConstants.MAX_AMOUNT;
    }

    const showInsertSuggestion = Math.min(remaining, billRemaining) > 0;
    const insertSuggestionValue = Math.min(totalAmount, mathService.add(billRemaining, allocatedAmount));

    const handleInsertSuggestion = () => {
        handleChangeAmount(insertSuggestionValue);
    };

    return (
        <VelocityTransitionGroup
            enter={{ animation: 'slideDown', duration: 200 }}
            leave={{ animation: 'slideUp', duration: 200 }}
        >
            {data && isMatched ? (
                <div>
                    <LastLine>
                        <ColorBarContainer>
                            <ColorBar
                                blackBarValue={allocatedAmountToBilledPOs}
                                grayBarValue={allocatedAmountToNotBilledPOs}
                                totalValue={totalBarValue}
                                dashedGreenBarValue={lightGreenBarValue}
                                isOverflowed={false}
                                currency={currency}
                            />
                        </ColorBarContainer>

                        <RemainingText warning={warningRemaining}>
                            {messages.remaining({
                                amount: intl.formatCurrency(remaining, currency),
                            })}

                            {(extraAmount || extraAmount === 0) && (
                                <>
                                    <br />

                                    {messages.extraAmount({
                                        extraAmountText,
                                    })}
                                </>
                            )}

                            {exceededAmount && (
                                <>
                                    <br />

                                    {messages.exceededAmount({
                                        exceededAmount: intl.formatCurrency(exceededAmount, currency),
                                    })}
                                </>
                            )}
                        </RemainingText>
                    </LastLine>

                    {tableData.length > 0 && (
                        <CollapsibleSection title={messages.matchedBills}>
                            <LineItemsTable columnDefinitions={columnDefinitions} lineItems={tableData} />

                            <AmountContainer>
                                <LineItemFooterTotal
                                    value={intl.formatCurrency(totalAllocatedAmountToPOs, currency)}
                                    label={messages.totalOfAllocatedBills}
                                />
                            </AmountContainer>
                        </CollapsibleSection>
                    )}

                    <VelocityTransitionGroup
                        enter={{ animation: 'slideDown', duration: 200 }}
                        leave={{ animation: 'slideUp', duration: 200 }}
                    >
                        {isMatched ? (
                            <div>
                                <AllocatedContainer>
                                    <GreyText>{messages.allocatedAmount}</GreyText>

                                    <AmountEditor
                                        value={allocatedAmount}
                                        allowFloat
                                        precision={2}
                                        disabled={isBilled}
                                        warning={warning || localWarning}
                                        min={0}
                                        max={maxAllocatedAmount}
                                        onChange={handleChangeAmount}
                                        allowCalculation
                                        resetIfEvaluatedValueExceedsLimit
                                    />

                                    <Text>{currency} </Text>

                                    <GreyText>
                                        {messages.notAllocatedAmount({
                                            amount: intl.formatCurrency(billRemaining, currency),
                                        })}
                                    </GreyText>

                                    {showInsertSuggestion && (
                                        <InsertSuggestionButton execute={handleInsertSuggestion}>
                                            {messages.insertRemainingAmount({
                                                amount: intl.formatCurrency(insertSuggestionValue, currency),
                                            })}
                                        </InsertSuggestionButton>
                                    )}
                                </AllocatedContainer>
                            </div>
                        ) : undefined}
                    </VelocityTransitionGroup>
                </div>
            ) : undefined}
        </VelocityTransitionGroup>
    );
};

export default memo(AdditionalInformation);
