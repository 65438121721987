import { z } from 'zod';

/**
 * Validation schema for {@link FileRecord} type. Used by {@link Dropzone} and {@link AvatarDropzone} components
 */
export const fileRecord = z.object({
    id: z.string(),
    name: z.string().optional(),
    source: z.instanceof(File).optional(),
    preview: z.string().optional(),
});

export const sourceFileRecord = z.object({
    id: z.string(),
    source: z.instanceof(File),
});
