import { RequestMutationOptions } from '@approvalmax/data';
import { useMutate } from 'shared/data';

import { reportsApiPaths } from '../paths';
import { useInvalidateGetReportSchedule } from '../useGetReportSchedule/useGetReportSchedule';
import { UpdateReportSchedule } from './useUpdateReportSchedule.types';

export const useUpdateReportSchedule = (mutationOptions?: RequestMutationOptions<UpdateReportSchedule>) => {
    const invalidateGetReportSchedule = useInvalidateGetReportSchedule();

    return useMutate(reportsApiPaths.reportScheduleUpdate, {
        mutationOptions: {
            ...mutationOptions,
            onSuccess: (data, variables, context) => {
                invalidateGetReportSchedule({
                    query: {
                        companyId: variables.body?.companyId,
                        reportId: variables.body?.reportId,
                        type: variables.body?.type,
                    },
                });

                mutationOptions?.onSuccess?.(data, variables, context);
            },
        },
        apiVersion: 'v1',
    });
};
