import { RequestMutationOptions } from '@approvalmax/data';
import { useMutate } from 'shared/data';

import { companiesApiPaths } from '../paths';
import { useInvalidateGetCompanies } from '../useGetCompanies/useGetCompanies';
import { FinishNetSuiteIntegration } from './useFinishNetSuiteIntegration.types';

export const useFinishNetSuiteIntegration = (
    mutationOptions?: RequestMutationOptions<FinishNetSuiteIntegration<true>>
) => {
    return useMutate(companiesApiPaths.finishNetSuiteIntegration, {
        mutationOptions,
        apiVersion: 'v1',
        mapToCamelCase: true,
    });
};

export const useFinishNetSuiteIntegrationRaw = (
    mutationOptions?: RequestMutationOptions<FinishNetSuiteIntegration>
) => {
    const invalidateGetCompanies = useInvalidateGetCompanies();

    return useMutate(companiesApiPaths.finishNetSuiteIntegration, {
        mutationOptions: {
            ...mutationOptions,
            onSettled: async (data, error, variables, context) => {
                await invalidateGetCompanies({ query: { companyId: variables.body?.companyId } });

                mutationOptions?.onSettled?.(data, error, variables, context);
            },
        },
        apiVersion: 'v1',
    });
};
