import { useAtomValue } from 'jotai';
import { selectors } from 'modules/common';
import { domain } from 'modules/data';
import { useSelector } from 'modules/react-redux';
import { activeCompanyIdState } from 'shared/states';

export const useIsEmailContactLimited = () => {
    const companyId = useAtomValue(activeCompanyIdState);
    const company = useSelector((state) => selectors.company.getCompanyById(state, companyId));

    const isDoNotLimitEmailToContact = company.betaFeatures.includes(
        domain.CompanyBetaFeature.DoNotLimitEmailToContact
    );

    const isEmailContactLimited =
        company.licenseProductPlan === domain.CompanyPlan.Trial ||
        company.licenseProductPlan === domain.CompanyPlan.Retired;

    return isEmailContactLimited && !isDoNotLimitEmailToContact;
};
