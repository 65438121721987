import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('modules.FirstStartModule.CreateOrganisationPopup.ConfirmSkipContent', {
    xero: 'Xero',
    quickBooks: 'QuickBooks Online',
    netSuite: 'NetSuite',
    cin7: 'Cin7',
    title: 'Are you sure you want to skip connecting {glName}?',
    connectButton: 'Connect your {glName} account',
    benefitsTitle: 'Without connecting, you won’t be able to:',
    benefit1: 'Approve bills, purchase orders, or other documents; ',
    benefit2: 'Set up complex approval rules;',
    benefit3: 'Access audit reports.',
    trust1: '17,000+ clients, including KPMG, BDO, and Deloitte, trust us.',
    trust2: 'ApprovalMax is ISO 27001 certified and GDPR compliant.',
    learnMore: 'Learn more',
    skip: 'Skip',
});
