import { Field, Popup, TextAreaEditor, TextEditor } from '@approvalmax/ui';
import { useDispatch } from 'modules/react-redux';
import { useUserProfile } from 'modules/utils';
import { FC, memo, useState } from 'react';
import bemFactory from 'react-bem-factory';
import { amplitudeService } from 'services/amplitude';
import { notificationService } from 'services/notification';
import styled from 'styled-components';

import { cancelActivePopup } from '../../actions';
import { messages } from './ContactSalesPopup.messages';
import { useRequestToSalesMutation } from './hooks';

const PopupContent = styled(Popup.DefaultContent)`
    width: 800px;
`;

const Content = styled.div`
    margin: 0 80px;
    padding-bottom: 40px;
`;

const Row = styled.div`
    margin-top: 20px;
    display: flex;

    & > * {
        flex: 1;
        overflow: hidden;
    }

    & > :not(:last-child) {
        margin-right: 20px;
    }
`;

const qa = bemFactory.qa('contact-sales-popup');

const ContactSalesPopup: FC = () => {
    const dispatch = useDispatch();
    const profile = useUserProfile();
    const [phoneNumber, setPhoneNumber] = useState(profile.phone || '');
    const [description, setDescription] = useState('');
    const [showValidationErrors, setShowValidationErrors] = useState(false);
    const { requestToSales } = useRequestToSalesMutation();

    const onSend = async () => {
        if (!phoneNumber.trim() || !description.trim()) {
            notificationService.showErrorToast(messages.validationErrorRequired);
            setShowValidationErrors(true);

            return;
        }

        if (!profile.account) {
            return;
        }

        amplitudeService.sendData('help: send contact sales request');

        await requestToSales({
            phone: phoneNumber,
            message: description,
            accountId: profile.account.accountId,
        });

        dispatch(cancelActivePopup());
    };

    return (
        <PopupContent
            qa={qa()}
            title={messages.popupTitle}
            buttons={
                <Popup.DefaultContent.Button qa={qa('done-button')} execute={onSend} title={messages.doneButtonTitle}>
                    {messages.doneButtonText}
                </Popup.DefaultContent.Button>
            }
            customCloseHandler={() => {
                dispatch(cancelActivePopup());
            }}
        >
            <Content>
                <Row>
                    <Field required title={messages.phoneTitle}>
                        <TextEditor
                            focusOnMount
                            invalid={showValidationErrors && !phoneNumber}
                            value={phoneNumber}
                            onChange={setPhoneNumber}
                            placeholder={messages.phonePlaceholder}
                        />
                    </Field>
                </Row>

                <Row>
                    <Field required title={messages.descriptionTitle}>
                        <TextAreaEditor
                            value={description}
                            invalid={showValidationErrors && !description}
                            onChange={setDescription}
                            minHeight={4}
                            maxHeight={10}
                            placeholder={messages.descriptionPlaceholder}
                        />
                    </Field>
                </Row>
            </Content>
        </PopupContent>
    );
};

export default memo(ContactSalesPopup);
