import { enabledByParams, RequestParams, RequestQueryOptions } from '@approvalmax/data';
import { useGet, useLazyGet } from 'shared/data';

import { integrationApiPaths } from '../paths';
import { GetNetSuiteDictionariesTaxCodes } from './useGetNetSuiteDictionariesTaxCodes.types';

export const useGetNetSuiteDictionariesTaxCodes = (
    params?: RequestParams<GetNetSuiteDictionariesTaxCodes>,
    queryOptions?: RequestQueryOptions<GetNetSuiteDictionariesTaxCodes>
) => {
    return useGet(integrationApiPaths.getNetSuiteDictionariesTaxCodes, {
        params,
        queryOptions: {
            enabled: enabledByParams<GetNetSuiteDictionariesTaxCodes['params']['query']>(
                {
                    companyId: params?.query?.companyId,
                },
                queryOptions
            ),
            ...queryOptions,
        },
        method: 'get',
        apiVersion: 'v1',
        mapToCamelCase: true,
    });
};

export const useLazyGetNetSuiteDictionariesTaxCodes = (
    queryOptions?: RequestQueryOptions<GetNetSuiteDictionariesTaxCodes>
) => {
    return useLazyGet<GetNetSuiteDictionariesTaxCodes>(integrationApiPaths.getNetSuiteDictionariesTaxCodes, {
        queryOptions,
        method: 'get',
        apiVersion: 'v1',
        mapToCamelCase: true,
    });
};
