import { ExtractComponentProp } from '@approvalmax/types';
import { hooks } from '@approvalmax/utils';
import { forwardRef, RefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { Button } from '../../../Button/Button';
import { Progress } from '../../../Progress/Progress';
import { Text } from '../../../Text/Text';
import TextField from '../../../TextField/TextField';
import { useSelectContext } from '../../Select.context';
import { SelectOnChange } from '../../Select.types';
import { useDropdownOpen } from '../DropdownMenu/DropdownMenu.hooks';
import { useSelectQuery } from './Activator.hooks';
import { Icon } from './Activator.styles';
import { ActivatorProps } from './Activator.types';

/**
 * Activator allows opening the DropdownMenu and selecting items.
 * It Also allows searching items if autocomplete is enabled.
 */
const Activator = forwardRef(
    <Item extends Record<string, any>, Multiple extends boolean, NoInput extends boolean>(
        props: ActivatorProps<Item, Multiple, NoInput>,
        ref: RefObject<HTMLButtonElement & HTMLDivElement>
    ) => {
        const {
            multiple,
            itemNameKey,
            itemIdKey,
            initFocus = false,
            autocomplete,
            placeholder,
            onInputChange,
            onFocus,
            onBlur,
            progress,
            noInput,
            size,
            disabled,
            invalid,
            color,
            uppercase = false,
            readOnly,
            clearable: clearableProp = true,
            bordered,
            openIconOnHover,
            hoverColor,
            selectedItems,
            onChangeSelect,
            items,
            startIcon,
            hideEndIcon,
            open,
            onOpen,
            initOpen,
            buttonColor,
            noPadding = true,
            outline,
            selectOnFocus,
            tabIndex,
            inputRef,
            dropdownRef,
            ...restProps
        } = props;

        const activatorRef = useRef<HTMLDivElement & HTMLButtonElement>(null);
        const rootComposedRefs = hooks.useComposedRefs(ref, activatorRef);

        const { inputValue, setInputValue, setInputValuePristine, inputFocus, setInputFocus } =
            useSelectContext<Item>();
        const [dropdownOpen, setDropdownOpen] = useDropdownOpen({
            open,
            onOpen,
            initOpen,
        });

        const { handleFocus } = hooks.useOpenControl({
            inputRef,
            toggleFocus: setInputFocus,
            toggleOpen: setDropdownOpen,
            focus: inputFocus,
            open: dropdownOpen,
            initFocus,
            disabled,
            readOnly,
            onFocus,
        });

        const { handleBlur } = hooks.useCloseControl({
            activatorRef,
            dropdownRef,
            toggleFocus: setInputFocus,
            toggleOpen: setDropdownOpen,
            focus: inputFocus,
            open: dropdownOpen,
            onBlur,
        });

        const selectQuery = useSelectQuery({
            selectOnFocus,
            autocomplete,
            inputRef,
            setInputValuePristine,
        });

        /**
         * If the dropdown is open and autocomplete is enabled, select input text and reset filtration.
         */
        useEffect(() => {
            if (dropdownOpen && autocomplete) {
                selectQuery();
            }
        }, [autocomplete, dropdownOpen, selectQuery]);

        /**
         * If the activator is clicked, the input is focused, and the dropdown is closed, the dropdown opens.
         */
        const handleClick = useCallback(() => {
            if (inputFocus && !dropdownOpen) {
                setDropdownOpen(true);
            }
        }, [inputFocus, dropdownOpen, setDropdownOpen]);

        /**
         * If Select is not multiple, then we add selected item to the input value.
         */
        useEffect(() => {
            if (!multiple && selectedItems.length && !dropdownOpen) {
                setInputValue(selectedItems[0][itemNameKey]);
            }
        }, [itemNameKey, multiple, selectedItems, setInputValue, dropdownOpen]);

        /**
         * Clear input value if a dropdown is closed and nothing is selected
         * or if a dropdown is closed and Select is multiple.
         */
        useEffect(() => {
            if (!dropdownOpen && (!selectedItems.length || multiple)) {
                setInputValue('');
                onInputChange?.('');
            }
        }, [dropdownOpen, multiple, onInputChange, selectedItems.length, setInputValue]);

        /**
         * Handle clear input and remove selected item if Select is not multiple.
         */
        const handleClear = useCallback<ExtractComponentProp<typeof TextField, 'onClear'>>(() => {
            if (!multiple) {
                onChangeSelect?.(undefined as Parameters<SelectOnChange<Item, Multiple>>[0], items);
            }
        }, [items, multiple, onChangeSelect]);

        /**
         * Handle input change and set inputValue.
         */
        const handleChange = useCallback(
            (value = '') => {
                setInputValuePristine(false);
                setInputValue(value);
                setDropdownOpen(true);
                onInputChange?.(value);
            },
            [onInputChange, setDropdownOpen, setInputValue, setInputValuePristine]
        );

        /**
         * Handle hover on input and set local state
         */
        const [hover, setHover] = useState(false);
        const handleHover = useCallback(() => setHover(true), []);
        const handleLeave = useCallback(() => setHover(false), []);

        /**
         * Input clearable if not in progress and autocomplete is true
         * or if a single Select has a value and is hovered.
         */
        const clearable = Boolean(
            clearableProp && !progress && (autocomplete || (!multiple && selectedItems.length && hover && !noInput))
        );

        /**
         * Input end icon if progress is true or if Select is not clearable.
         */
        const endIcon = useMemo(() => {
            if (progress) return <Progress size='small' shape='circle' />;

            if (!hideEndIcon && !readOnly && !clearable) {
                return (
                    <Icon
                        $open={dropdownOpen}
                        $hide={openIconOnHover && !hover}
                        color={hover ? hoverColor : undefined}
                        size={size === 'xsmall' ? 12 : 16}
                    />
                );
            }

            return;
        }, [progress, openIconOnHover, hover, hideEndIcon, clearable, readOnly, dropdownOpen, size, hoverColor]);

        if (!noInput) {
            return (
                <TextField
                    readOnly={Boolean(readOnly || !autocomplete)}
                    value={inputValue}
                    onChange={handleChange}
                    focus={inputFocus}
                    onClick={handleClick}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    onClear={handleClear}
                    onMouseEnter={handleHover}
                    onMouseLeave={handleLeave}
                    clearable={clearable}
                    endIcon={endIcon}
                    placeholder={placeholder}
                    size={size}
                    disabled={disabled}
                    invalid={invalid}
                    bordered={bordered}
                    aria-autocomplete={autocomplete ? 'list' : undefined}
                    cursor={readOnly || disabled || autocomplete ? undefined : 'pointer'}
                    inputRef={inputRef}
                    rootRef={rootComposedRefs}
                    tabIndex={tabIndex}
                    startIcon={startIcon}
                    {...restProps}
                />
            );
        } else {
            return (
                <Button
                    fontWeight='regular'
                    color={invalid ? 'red40' : buttonColor}
                    size={size}
                    disabled={disabled}
                    noUppercase={!uppercase}
                    variant={outline ? 'outline' : 'text'}
                    endIcon={endIcon}
                    startIcon={startIcon}
                    noPadding={noPadding}
                    tabIndex={tabIndex}
                    ref={rootComposedRefs}
                    onMouseEnter={handleHover}
                    onMouseLeave={handleLeave}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                >
                    <Text {...restProps} ellipsis={1} color={color} hoverColor={hoverColor} fontSize={size}>
                        {inputValue || placeholder}
                    </Text>
                </Button>
            );
        }
    }
);

export default Activator;
