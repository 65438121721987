import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages(
    'pages.RequestList.BillBatchPaymentRequestCard.BillsInformationSection.tableConfig',
    {
        itemName: 'Items',
        supplierName: 'Supplier',
        billsReferenceName: 'Bills reference',
        dateName: 'Date',
        dueDateName: 'Due date',
        amountDueName: 'Amount due',
        payment: 'Payment {currency}',
        code: 'Code',
        particulars: 'Particulars',
        reference: 'Reference',
        details: 'Details',
        bankAccountName: 'Bank account',
    }
);
