import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('modules.RequestModule.BulkCreationPopupContent', {
    title: 'Upload documents',
    uploadButtonTitle: 'Upload',
    fileToUploadButtonSectionTitle: 'File type to upload',
    bulkUploadDocumentSuccess:
        '{numberDocuments} new {templateName} {numberDocuments, plural, one {is} other {are}} being created. Refresh this page shortly if you don`t see them',
    multipleAttachmentsWarning: 'NOTE: ApprovalMax will create multiple {integrationType} {templateName}.',
});
