import { Box, Button, Grid, Text } from '@approvalmax/ui/src/components';
import { memo } from 'react';

import { BackupCodesAddPopup } from '../BackupCodesAddPopup/BackupCodesAddPopup';
import { BackupCodesUpdatePopup } from '../BackupCodesUpdatePopup/BackupCodesUpdatePopup';
import { BackupEmailUpdatePopup } from '../BackupEmailUpdatePopup/BackupEmailUpdatePopup';
import { useBackupMethodsList } from './BackupMethodsList.hooks';
import { messages } from './BackupMethodsList.messages';
import { BackupMethodsListProps } from './BackupMethodsList.types';

export const BackupMethodsList = memo<BackupMethodsListProps>((props) => {
    const { profile } = props;

    const {
        backupCodes,
        backupEmail,
        isBackupEmailOpened,
        isUpdateBackupCodesOpened,
        isAddBackupCodesOpened,
        toggleIsBackupEmailOpened,
        toggleIsAddBackupCodesOpened,
        toggleIsUpdateBackupCodesOpened,
    } = useBackupMethodsList(props);

    return (
        <>
            <Box radius='xsmall' spacing='12 16' color='midnight20'>
                <Grid
                    gridTemplateColumns='max-content auto'
                    columnGap={16}
                    rowGap={16}
                    alignItems='center'
                    justifyContent='start'
                    padding='0'
                >
                    <>
                        <Text font='label' fontSize='medium' fontWeight='regular' color={backupCodes.color}>
                            • {messages.optionBackupCodes}
                        </Text>

                        <Button variant='text' color='blue100' size='medium' onClick={backupCodes.onClick} noPadding>
                            {backupCodes.button}
                        </Button>
                    </>

                    <>
                        <Text font='label' fontSize='medium' fontWeight='regular' color={backupEmail.color}>
                            • {messages.optionBackupEmail}
                        </Text>

                        <Button variant='text' color='blue100' size='medium' onClick={backupEmail.onClick} noPadding>
                            {backupEmail.button}
                        </Button>
                    </>
                </Grid>
            </Box>

            {isBackupEmailOpened && (
                <BackupEmailUpdatePopup
                    title={
                        profile.isTFAAlternativeEmailEnabled
                            ? messages.editBackupEmailTitle
                            : messages.addBackupEmailTitle
                    }
                    onClose={toggleIsBackupEmailOpened}
                />
            )}

            <BackupCodesAddPopup open={isAddBackupCodesOpened} onClose={toggleIsAddBackupCodesOpened} />

            <BackupCodesUpdatePopup open={isUpdateBackupCodesOpened} onClose={toggleIsUpdateBackupCodesOpened} />
        </>
    );
});

BackupMethodsList.displayName = 'BackupMethodsList';
