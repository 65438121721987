import { backend } from 'modules/data';
import { UseGetSearchRequestsParams } from 'shared/data/webApp/v1';

import { ApiHandler } from '../types';

class SearchUrls {
    constructor(private _apiHandler: ApiHandler) {}
}

export default class SearchApi {
    public urls: SearchUrls;

    constructor(private _apiHandler: ApiHandler) {
        this.urls = new SearchUrls(this._apiHandler);
    }

    public getSearchContext(): Promise<backend.SearchSearchContextAnswer> {
        return this._apiHandler.doApiCall({
            data: null,
            action: 'search/getSearchContext',
            method: 'POST',
            allowsAnonymous: false,
        });
    }

    public searchRequests(transfer: UseGetSearchRequestsParams): Promise<backend.RequestSelectAnswer> {
        return this._apiHandler.doApiCall({
            data: transfer,
            action: 'search/searchRequests',
            method: 'POST',
            allowsAnonymous: false,
        });
    }
}
