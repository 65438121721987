import { Button, LayoutWithSidebar, Popup } from '@approvalmax/ui/src/components';
import { memo } from 'react';

import { SectionCertification } from '../SectionCertification/SectionCertification';
import { SectionGeneral } from '../SectionGeneral/SectionGeneral';
import { SectionNotifications } from '../SectionNotifications/SectionNotifications';
import { SectionSecurity } from '../SectionSecurity/SectionSecurity';
import { useProfileEditForm, useProfileEditPopupTabs } from './Content.hooks';
import { messages } from './Content.messages';
import { ContentTab } from './Content.styles';
import { ContentProps } from './Content.types';

export const Content = memo<ContentProps>((props) => {
    const { profile } = props;

    const { handleClickSave, isInvalid, isModified, isSubmitting } = useProfileEditForm(props);
    const { items, itemActive } = useProfileEditPopupTabs();

    return (
        <>
            <Popup.Header
                title={messages.title}
                actionsSize='small'
                actions={
                    <Button
                        color='blue10'
                        size='small'
                        title={messages.buttonSaveTitle}
                        disabled={!isModified || isInvalid}
                        progress={isSubmitting}
                        onClick={handleClickSave}
                    >
                        {messages.buttonSaveText}
                    </Button>
                }
            />

            <Popup.Body spacing='0'>
                <LayoutWithSidebar bordered>
                    <LayoutWithSidebar.Sidebar items={items} />

                    <LayoutWithSidebar.Content>
                        <ContentTab $active={itemActive === 'general'}>
                            <SectionGeneral progress={isSubmitting} profile={profile} />

                            <SectionCertification progress={isSubmitting} profile={profile} />
                        </ContentTab>

                        <ContentTab $active={itemActive === 'notifications'}>
                            <SectionNotifications progress={isSubmitting} profile={profile} />
                        </ContentTab>

                        <ContentTab $active={itemActive === 'security'}>
                            <SectionSecurity progress={isSubmitting} profile={profile} />
                        </ContentTab>
                    </LayoutWithSidebar.Content>
                </LayoutWithSidebar>
            </Popup.Body>
        </>
    );
});

Content.displayName = 'Content';
