import { intl, mathService } from '@approvalmax/utils';
import moment from 'moment';

import { isFieldVisible } from '../../NetSuiteRequestCard.helpers';
import { messages } from './NetSuiteCreditList.messages';
import { CreditListColumnDefinition, CreditListFieldSettings } from './NetSuiteCreditList.types';

export const getColumnDefs = (
    fieldSettings: CreditListFieldSettings,
    exchangeRate?: number | null
): CreditListColumnDefinition[] => {
    const columnDef: CreditListColumnDefinition[] = [
        {
            id: 'dateDue',
            name: messages.dateDue,
            value: (li) => (li.dateDue ? moment.utc(li.dateDue).format('ll') : null),
            minWidth: 100,
            isHidden: !isFieldVisible(fieldSettings.dateDue),
        },
        {
            id: 'type',
            name: messages.type,
            value: (li) => li.type,
            minWidth: 100,
            isHidden: !isFieldVisible(fieldSettings.type),
        },
        {
            id: 'reference',
            name: messages.reference,
            value: (li) => li.reference,
            minWidth: 150,
            isHidden: !isFieldVisible(fieldSettings.reference),
        },
        {
            id: 'appliedTo',
            name: messages.appliedTo,
            value: (li) => li.appliedTo,
            minWidth: 150,
            isHidden: !isFieldVisible(fieldSettings.appliedTo),
        },
        {
            id: 'currency',
            name: messages.currencyName,
            value: (li) => li.currencyName,
            minWidth: 100,
            isHidden: !isFieldVisible(fieldSettings.currency),
        },
        {
            id: 'paymentAmount',
            name: messages.paymentAmount,
            value: (li) => {
                let paymentAmount = li.paymentAmount || 0;

                if (typeof exchangeRate === 'number') {
                    paymentAmount = mathService.multiply(paymentAmount, exchangeRate);
                    paymentAmount = mathService.round(paymentAmount, 2);
                }

                return intl.formatNumber(paymentAmount, 'auto');
            },
            alignRight: true,
            minWidth: 100,
            isHidden: !isFieldVisible(fieldSettings.paymentAmount),
        },
    ];

    return columnDef.filter((col) => !col.isHidden);
};
