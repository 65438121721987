import { mentionHelpers, RichEditor, useExtensions } from '@approvalmax/ui/src/components';
import { domHelpers, hooks } from '@approvalmax/utils';
import { useSetAtom } from 'jotai';
import { actions, constants, selectors } from 'modules/common';
import { backend, domain, schemas, State } from 'modules/data';
import { removeArrayItem } from 'modules/immutable';
import { useDispatch, useSelector } from 'modules/react-redux';
import { AttachmentIcon, SendIcon } from 'modules/sprites';
import moment from 'moment';
import { addRequestComment, reloadRequest } from 'pages/requestList/actions';
import { FC, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import bemFactory from 'react-bem-factory';
import { FileRejection, useDropzone } from 'react-dropzone';
import { useMount } from 'react-use';
import { amplitudeService } from 'services/amplitude';
import { api } from 'services/api';
import { notificationService } from 'services/notification';

import { addAsWatcherPopupState } from '../../../components/AddAsWatchersPopup/AddAsWatchersPopup.states';
import { COMMENT_TEXT_USER_PREFERENCE } from '../../../reducers/userPreferencesReducer';
import { getMentionedUsersWithoutAccessToRequest } from '../../../utils/getUsersWithoutAccessToRequest';
import { messages } from './AddCommentSection.messages';
import {
    AttachButton,
    AttachmentItem,
    AttachmentItemDeleteButton,
    AttachmentItemDeleteButtonWrapper,
    AttachmentItemIcon,
    AttachmentItemText,
    AttachmentsList,
    DragContent,
    DragIcon,
    DragOverlay,
    DragText,
    MainColumn,
    MainRow,
    NewAttachmentItem,
    NewAttachmentItemIcon,
    NewAttachmentItemLoadingIcon,
    NewAttachmentItemLoadingText,
    NewAttachmentItemText,
    Root,
    SendButton,
} from './AddCommentSection.styles';
import { AddCommentSectionProps } from './AddCommentSection.types';

const qa = bemFactory.qa('reql-add-comment-section');

const AddCommentSection: FC<AddCommentSectionProps> = memo((props) => {
    const { request } = props;

    const dispatch = useDispatch();

    const setWatchersPopup = useSetAtom(addAsWatcherPopupState);

    const { company } = request;
    const { allMembers } = company;

    const activeMembers = useMemo(
        () => allMembers.filter((member) => member.status === domain.CompanyUserStatus.Active),
        [allMembers]
    );

    const firstRenderRef = useRef<boolean>(true);

    const commentsById: {
        [requestId: string]: string;
    } = useSelector((state: State) =>
        selectors.userPreferences.getUserPreference(state, COMMENT_TEXT_USER_PREFERENCE, {})
    );

    const isCompanyReadonly = request.company.isReadonly;

    const [sending, setSending] = useState(false);
    const [richEditorDebouncedSending, setRichEditorDebouncedSending] = useState(false);
    const [commentText, setCommentText] = useState(commentsById[request.id] || '');
    const [newAttachments, setNewAttachments] = useState([] as domain.RequestAttachment[]);
    const [uploadedAttachments, setUploadedAttachments] = useState([] as domain.RequestAttachment[]);
    const [richEditorFocused, setRichEditorFocused] = useState(false);

    const uploading = newAttachments.length > 0;
    const hasCommentText = Boolean(commentText.trim());
    const disableSending =
        sending || uploading || (!hasCommentText && uploadedAttachments.length === 0) || isCompanyReadonly;

    const disableAttach = sending || uploading || isCompanyReadonly || richEditorDebouncedSending;
    const isActive = uploading || uploadedAttachments.length > 0 || hasCommentText;

    const prevCommentText = hooks.usePrevious(commentText);
    const prevRequestId = hooks.usePrevious(request.id);

    const extensions = useExtensions([
        'bmp',
        'numbers',
        'rtf',
        'csv',
        'odp',
        'text',
        'doc',
        'ods',
        'tiff',
        'docx',
        'odt',
        'eml',
        'pages',
        'gif',
        'pdf',
        'xls',
        'jpg',
        'png',
        'xlsx',
        'ppt',
        'zip',
        'keynote',
        'pptx',
        '7z',
        'msg',
        'rar',
    ]);

    // Reload saved comment text on request change
    if (prevRequestId != null && prevRequestId !== request.id) {
        const savedCommentDraft = commentsById[request.id] || '';

        if (savedCommentDraft !== commentText) {
            // Note: react never bails out of re-rendering when state change is called from the render function
            setCommentText(savedCommentDraft);
        }
    }

    const updateCommentDraft = useCallback(
        (text: string) => {
            let newValue = {
                ...commentsById,
            };

            if (text) {
                newValue[request.id] = text;
            } else {
                delete newValue[request.id];
            }

            dispatch(actions.updateUserPreference(COMMENT_TEXT_USER_PREFERENCE, newValue));
        },
        [commentsById, dispatch, request.id]
    );

    // Save comment draft in local storage
    useEffect(() => {
        if (prevCommentText == null || prevCommentText === commentText) {
            return;
        }

        const handler = setTimeout(() => updateCommentDraft(commentText), 200);

        return () => {
            clearTimeout(handler);
        };
    }, [commentText, prevCommentText, updateCommentDraft]);

    const handleSendComment = useCallback(async () => {
        const { updatedCommentText, mentionedUserIds } = mentionHelpers.prepareCommentPayload(commentText);

        try {
            await dispatch(
                addRequestComment(
                    request.id,
                    updatedCommentText,
                    uploadedAttachments.map((a) => a.id),
                    mentionedUserIds
                )
            );
            setCommentText('');
            setUploadedAttachments([]);
            updateCommentDraft('');
            dispatch(reloadRequest(request.id, company.id));

            amplitudeService.sendData('requests: left comment');
        } finally {
            setSending(false);
            setRichEditorDebouncedSending(false);
        }
    }, [commentText, company.id, dispatch, request.id, updateCommentDraft, uploadedAttachments]);

    const tryToSendComment = useCallback(() => {
        if (disableSending) {
            return;
        }

        setSending(true);

        const { mentionedUserIds } = mentionHelpers.prepareCommentPayload(commentText);
        const usersWithoutAccess = getMentionedUsersWithoutAccessToRequest(request, mentionedUserIds);

        if (usersWithoutAccess.length) {
            setWatchersPopup((state) => ({ ...state, usersWithoutAccess }));
        } else {
            handleSendComment();
        }
    }, [disableSending, commentText, request, setWatchersPopup, handleSendComment]);

    const richEditorOnChange = useCallback((value: string) => {
        setCommentText(value);
    }, []);

    const onDrop = useCallback(
        async (accepted: File[], rejected: FileRejection[]) => {
            rejected.forEach(({ file }) => {
                if (file.size > constants.uploadsConstants.COMMENT_MAX_ATTACHMENT_FILESIZE) {
                    notificationService.showErrorToast(
                        messages.fileTooBigError({
                            fileName: file.name,
                            maxSize: constants.uploadsConstants.COMMENT_MAX_ATTACHMENT_FILESIZE_TEXT,
                        })
                    );
                } else if (
                    !Object.values(extensions).some((extensionType) =>
                        extensionType.some((ext) => file.name.endsWith(ext))
                    )
                ) {
                    notificationService.showErrorToast(messages.fileExtensionNotSupportedError);
                }
            });

            if (accepted.length === 0) {
                return;
            }

            if (
                accepted.length + uploadedAttachments.length >
                constants.uploadsConstants.COMMENT_MAX_ATTACHMENT_COUNT
            ) {
                notificationService.showErrorToast(messages.maxNumberOfAttachmentsExceeded);

                return;
            }

            const createdDate = moment.utc().toISOString();

            // Overwrite old files with the same names
            setNewAttachments(
                accepted.map(
                    (f): domain.RequestAttachment => ({
                        id: f.name,
                        name: f.name,
                        size: f.size,
                        attachmentType: domain.RequestAttachmentType.General,
                        createdDate,
                    })
                )
            );

            try {
                // Upload
                const responses: backend.AttachmentListAnswer[] = await Promise.all(
                    accepted.map((f) => {
                        let formData = new FormData();

                        formData.append('file', f);

                        return api.postFormData('requests/attachFileToComment', formData);
                    })
                );
                // Parse
                const uploadedFiles: domain.RequestAttachment[] = [];
                const rejectedFiles: Array<{
                    attachmentName: string;
                    reason: backend.FilesFileUploadResult;
                }> = [];

                responses.forEach((r) => {
                    const a = r.Attachments[0];

                    if (a.UploadResult === undefined || a.UploadResult === backend.FilesFileUploadResult.OK) {
                        uploadedFiles.push(schemas.request.mapAttachment(a));
                    } else {
                        rejectedFiles.push({
                            attachmentName: a.Name || '',
                            reason: a.UploadResult,
                        });
                    }
                });
                // Handle rejectedFiles
                rejectedFiles.forEach((f) => {
                    let message;

                    switch (f.reason) {
                        case backend.FilesFileUploadResult.RestrictedFileType:
                            message = messages.uploadErrorRestrictedFileType({
                                fileName: f.attachmentName,
                            });
                            break;

                        case backend.FilesFileUploadResult.TooBig:
                            message = messages.uploadErrorTooBig({
                                fileName: f.attachmentName,
                                maxSize: constants.uploadsConstants.COMMENT_MAX_ATTACHMENT_FILESIZE_TEXT,
                            });
                            break;

                        case backend.FilesFileUploadResult.VirusFound:
                            message = messages.uploadErrorVirusFound({
                                fileName: f.attachmentName,
                            });
                            break;

                        default:
                            message = messages.uploadErrorGeneric;
                            break;
                    }

                    dispatch(actions.addErrorToast(message));
                });
                // Handle uploaded files
                setNewAttachments([]);
                setUploadedAttachments([...uploadedAttachments, ...uploadedFiles]);
            } catch {
                const failedIds = accepted.map((file) => file.name);

                setNewAttachments((attachments) =>
                    attachments.filter((attachment) => !failedIds.includes(attachment.id))
                );
            }
        },
        [dispatch, extensions, uploadedAttachments]
    );

    const { getRootProps, getInputProps, open, isDragActive } = useDropzone({
        maxSize: constants.uploadsConstants.COMMENT_MAX_ATTACHMENT_FILESIZE,
        accept: extensions,
        onDrop,
        noClick: true,
        noKeyboard: true,
        disabled: disableAttach,
    });
    const { isGlobalDragActive } = hooks.useDragAndDropInfo();

    const getMentionItems = useCallback(
        ({ query }: { query: string }) => {
            return activeMembers
                .map((member) => ({
                    name: member.displayName,
                    email: member.userEmail,
                    avatar: member.avatar,
                    userId: member.databaseId,
                }))
                .filter(
                    (item) =>
                        item.name.toLowerCase().startsWith(query.toLowerCase()) ||
                        item.email.toLowerCase().startsWith(query.toLowerCase())
                )
                .slice(0, 100);
        },
        [activeMembers]
    );

    const handleCloseWatchersAddPopup = useCallback(() => {
        setWatchersPopup((state) => ({ ...state, usersWithoutAccess: [] }));
        setRichEditorDebouncedSending(false);
        setSending(false);
    }, [setWatchersPopup]);

    useMount(() => {
        firstRenderRef.current = false;
    });

    useEffect(() => {
        setWatchersPopup((state) => ({
            ...state,
            onClose: handleCloseWatchersAddPopup,
            onSubmit: handleSendComment,
        }));
    }, [handleCloseWatchersAddPopup, handleSendComment, setWatchersPopup]);

    useEffect(() => {
        const handleKeyPress = (e: KeyboardEvent) => {
            if ((e.ctrlKey || e.metaKey) && e.key === 'Enter' && richEditorFocused) {
                if (!richEditorDebouncedSending) {
                    tryToSendComment();
                }

                setRichEditorDebouncedSending(true);
            }
        };

        document.addEventListener('keydown', handleKeyPress);

        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, [richEditorDebouncedSending, richEditorFocused, tryToSendComment]);

    const onRichEditorFocus = useCallback(() => setRichEditorFocused(true), []);
    const onRichEditorBlur = useCallback(() => setRichEditorFocused(false), []);

    return (
        <Root {...getRootProps()} data-qa={qa()}>
            <input {...getInputProps()} />

            <DragOverlay $active={isDragActive} $hide={!isGlobalDragActive || disableAttach}>
                <DragContent>
                    <DragIcon width={39} height={37} />

                    <DragText>{messages.dropHereText}</DragText>
                </DragContent>
            </DragOverlay>

            <MainRow>
                <MainColumn>
                    <RichEditor
                        key={company.id}
                        value={commentText}
                        onChange={richEditorOnChange}
                        changeImmediately
                        placeholder={messages.placeholderText}
                        allowMention
                        allowTextFormatting={false}
                        mentionItems={getMentionItems}
                        readOnly={isCompanyReadonly || sending}
                        onFocus={onRichEditorFocus}
                        onBlur={onRichEditorBlur}
                    />

                    <AttachmentsList>
                        {uploadedAttachments.map((a) => (
                            <AttachmentItem
                                key={a.id}
                                onClick={() =>
                                    domHelpers.downloadUrl(
                                        api.requests.urls.getCommentAttachment({
                                            attachmentId: a.id,
                                            companyId: request.companyId,
                                        })
                                    )
                                }
                            >
                                <AttachmentItemIcon width={16} height={14} />

                                <AttachmentItemText>{a.name}</AttachmentItemText>

                                <AttachmentItemDeleteButtonWrapper
                                    disabled={sending || richEditorDebouncedSending}
                                    execute={() => {
                                        setUploadedAttachments(removeArrayItem(uploadedAttachments, a));
                                    }}
                                >
                                    <AttachmentItemDeleteButton width={9} height={9} />
                                </AttachmentItemDeleteButtonWrapper>
                            </AttachmentItem>
                        ))}

                        {newAttachments.map((a) => (
                            <NewAttachmentItem key={a.id}>
                                <NewAttachmentItemIcon width={16} height={14} />

                                <NewAttachmentItemText>{a.name}</NewAttachmentItemText>

                                <NewAttachmentItemLoadingIcon size='small12' />

                                <NewAttachmentItemLoadingText>{messages.uploadingText}</NewAttachmentItemLoadingText>
                            </NewAttachmentItem>
                        ))}
                    </AttachmentsList>
                </MainColumn>

                <AttachButton
                    $disabled={disableAttach}
                    $active={isActive}
                    data-qa={qa('attach-button')}
                    onMouseDown={(e) => {
                        if (disableAttach) {
                            return;
                        }

                        e.preventDefault();
                        open();
                    }}
                >
                    <AttachmentIcon width={20} height={18} />
                </AttachButton>

                <SendButton
                    $disabled={disableSending || richEditorDebouncedSending}
                    data-qa={qa('send-button')}
                    onMouseDown={(e) => {
                        if (disableSending) {
                            return;
                        }

                        e.preventDefault();
                        tryToSendComment();
                    }}
                >
                    <SendIcon width={18} height={16} />
                </SendButton>
            </MainRow>
        </Root>
    );
});

export default AddCommentSection;
