import { ApiError } from '@approvalmax/types';
import { objectHelpers } from '@approvalmax/utils';
import type { AxiosResponse } from 'axios';

import { HEADER_SESSION_EXPIRATION_DATE, HEADER_WEB_APP_VERSION, useApiContext } from '../../api';
import type { ApiBaseAnswer, UseHandleApiCallOptions } from '../types';

export const useHandleApiCall = <ResponseData>(options: UseHandleApiCallOptions = {}) => {
    const { mapToCamelCase, skipToastForErrorCodes } = options;

    const { setApiError, setSessionExpirationDate, setWebAppVersion } = useApiContext();

    return (promise: Promise<AxiosResponse<ResponseData & ApiBaseAnswer>>): Promise<ResponseData> => {
        return promise
            .then((res) => {
                const newSessionExpirationDate = res.headers[HEADER_SESSION_EXPIRATION_DATE];

                if (newSessionExpirationDate) {
                    setSessionExpirationDate(newSessionExpirationDate);
                }

                const newWebAppVersion = res.headers[HEADER_WEB_APP_VERSION];

                if (newWebAppVersion) {
                    setWebAppVersion(newWebAppVersion);
                }

                return mapToCamelCase ? (objectHelpers.pascalCaseToCamelCase(res.data) as ResponseData) : res.data;
            })
            .catch((error: ApiError) => {
                setApiError({ ...error, ignoreToast: (skipToastForErrorCodes || []).includes(error.code) });

                throw error;
            });
    };
};
