import { Box, Button, Flex, Text } from '@approvalmax/ui/src/components';
import { hooks, intl } from '@approvalmax/utils';
import { domain } from 'modules/data';
import { CompanyRequestListFilter } from 'pages/requestList/config';
import { FC, memo, useCallback } from 'react';
import bemFactory from 'react-bem-factory';
import { notificationService } from 'services/notification';
import { getPath, getUrl, Path } from 'urlBuilder';

import { getShowRequestAmount } from '../../../../../selectors/requestSelectors';
import { useShowLinkToRequest } from './ToolbarHeader.hooks';
import { messages } from './ToolbarHeader.messages';
import { Amount, DraftBadge, StyledLinkIcon, StyledNewTabIcon } from './ToolbarHeader.styles';
import { ToolbarHeaderProps } from './ToolbarHeader.types';

const qa = bemFactory.qa('reql-toolbar');

const ToolbarHeader: FC<ToolbarHeaderProps> = memo((props) => {
    const { request, amountText: amountTextParam } = props;
    const showAmount = getShowRequestAmount(request);
    const displayName = request.displayName || messages.emptyRequestName;
    const isDraft = request.statusV2 === domain.RequestStatusV2.Draft;
    const copyToClipboard = hooks.useCopyToClipboard();

    const onCopyLink = useCallback(() => {
        const url = getUrl(Path.requestCompanyList, request.companyId, CompanyRequestListFilter.CompanyAll, {
            requestId: request.id,
        });

        copyToClipboard(url);
        notificationService.showInfoToast(messages.copiedText);
    }, [copyToClipboard, request.companyId, request.id]);

    const showLinkToRequest = useShowLinkToRequest(request);

    let amountText = intl.formatCurrency(request.amount, request.currency);

    if (request.integrationType === domain.IntegrationType.NetSuite) {
        amountText = amountTextParam || '';
    }

    if (request.integrationType === domain.IntegrationType.None) {
        amountText = intl.formatCurrency(request.amount, request.currency, 'auto');
    }

    const amountTitle = request.exchangeRate
        ? messages.exchangeRateTitle({
              amount: intl.formatNumber(request.amount),
              currency: request.currency,
              baseAmount: intl.formatNumber(request.amount / request.exchangeRate),
              baseCurrency: request.company.defaultCurrency,
          })
        : amountText;

    return (
        <Box spacing='0 60'>
            <Flex wrap={false} alignItems='center' justifyContent='space-between' height={60}>
                <Flex wrap={false} alignItems='center' spacing='8'>
                    {!isDraft && (
                        <Button onClick={onCopyLink} title={messages.copyTitle} variant='text' color='blue100'>
                            <StyledLinkIcon size={20} />
                        </Button>
                    )}

                    <Text
                        font='label'
                        fontSize='large'
                        fontWeight='bold'
                        data-qa={qa('title-text')}
                        title={displayName}
                        ellipsis={1}
                        color={request.displayName ? 'midnight100' : 'midnight70'}
                    >
                        {displayName}
                    </Text>

                    <Flex shrink={0}>
                        {showLinkToRequest && (
                            <Button variant='text' color='blue100'>
                                <a
                                    href={getPath(Path.request, request.id, request.companyId)}
                                    target='_blank'
                                    rel='noreferrer'
                                >
                                    <StyledNewTabIcon />
                                </a>
                            </Button>
                        )}
                    </Flex>

                    <Flex shrink={0}>
                        {isDraft && <DraftBadge data-qa={qa('draft-badge')}>{messages.draftBadgeText}</DraftBadge>}
                    </Flex>
                </Flex>

                {showAmount && (
                    <Amount data-qa={qa('amount')} title={amountTitle}>
                        {amountText}
                    </Amount>
                )}
            </Flex>
        </Box>
    );
});

export default ToolbarHeader;
