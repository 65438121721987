import { Flex, Tag, Text, Tooltip } from '@approvalmax/ui/src/components';
import { arrayHelpers } from '@approvalmax/utils';
import { selectors } from 'modules/common';
import { domain, State } from 'modules/data';
import { memo, useCallback } from 'react';
import bemFactory from 'react-bem-factory';
import { useDispatch, useSelector } from 'react-redux';

import { addPayer, addTempUser, openInviteUsersPopup, removePayer } from '../../actions';
import { getActiveTemplate, isPageReadonly, showCardValidationErrors } from '../../selectors/pageSelectors';
import { getTemplatePayers } from '../../selectors/templateSelectors';
import { isNewUser } from '../../selectors/userSelectors';
import { ExpandedTemplateUser } from '../../types/selectors';
import { addUserEvent, removeUserEvent } from '../../utils/events';
import { createUserComparator } from '../../utils/helpers';
import AddUserButton from '../AddUserButton/AddUserButton';
import FixedStepContainer from '../FixedStepContainer/FixedStepContainer';
import { StepUser } from '../StepUser/StepUser';
import { messages } from './AirwallexPaymentStep.messages';

const qa = bemFactory.qa('wort-xero-airwallex-batch-payment-start-step');

const AirwallexPaymentStep = memo(() => {
    const dispatch = useDispatch();
    const template = useSelector(getActiveTemplate)!;
    const company = useSelector((state: State) => selectors.company.getCompanyById(state, template.companyId));
    const readonly = useSelector(isPageReadonly);
    const team = useSelector((state: State) => selectors.company.getCompanyTeam(state, company));
    const payers = useSelector((state: State) => getTemplatePayers(state, company.id));
    const hasAnyPayers = useSelector((state: State) => selectors.template.getTemplateHasAnyPayers(state, template));
    const showValidationErrors = useSelector(showCardValidationErrors);
    const invalid = showValidationErrors && !hasAnyPayers;

    const onAddTempUser = useCallback(
        (value: domain.User) => {
            dispatch(addTempUser(value));

            addUserEvent('requester', template.integrationCode, true);
        },
        [dispatch, template.integrationCode]
    );

    const onInviteUser = useCallback(
        (user: ExpandedTemplateUser) => {
            dispatch(openInviteUsersPopup([user.id]));
        },
        [dispatch]
    );

    const onAddAirwallexPayer = useCallback(
        (user: domain.User) => {
            dispatch(addPayer(user.id));

            if (user.databaseId) {
                addUserEvent('requester', template.integrationCode);
            }
        },
        [dispatch, template.integrationCode]
    );

    const onRemoveAirwallexPayer = useCallback(
        (user: domain.User) => {
            dispatch(removePayer(user.id, user.databaseId));

            removeUserEvent('requester', template.integrationCode);
        },
        [dispatch, template.integrationCode]
    );

    return (
        <FixedStepContainer bubbleText={messages.bubbleText} color='brandAWX100'>
            <Text font='body' fontSize='small' color='midnight70'>
                {messages.stepDescription}
            </Text>

            <Tooltip
                open={invalid}
                color='cobalt100'
                activator={
                    <AddUserButton
                        disabled={readonly}
                        users={team}
                        excludedUsers={payers}
                        onAddNew={onAddTempUser}
                        onSelect={onAddAirwallexPayer}
                        title={messages.addPayer}
                        invalid={invalid}
                        type='awx'
                        data-qa={qa('add-submitter-button')}
                    />
                }
            >
                {messages.addPayer}
            </Tooltip>

            <Flex direction='column' spacing='8' width='100%' data-qa={qa('add-submitter-button')}>
                {arrayHelpers.arraySort<ExpandedTemplateUser>(payers, createUserComparator(team)).map((payer) => (
                    <StepUser
                        team={team}
                        key={payer.id}
                        user={payer}
                        isCompanyMember={team.some((t) => t.id === payer.id)}
                        readonly={readonly}
                        onRemove={onRemoveAirwallexPayer}
                        onInvite={onInviteUser}
                        tag={
                            !isNewUser(payer, company) && (
                                <Tag
                                    color={payer.isTFAEnabled ? 'green100' : 'midnight40'}
                                    title={payer.isTFAEnabled ? messages.twoFaEnabled : messages.twoFaDisabledTitle}
                                    size='xsmall'
                                >
                                    {payer.isTFAEnabled ? messages.twoFaEnabled : messages.twoFaDisabled}
                                </Tag>
                            )
                        }
                    />
                ))}
            </Flex>
        </FixedStepContainer>
    );
});

export default AirwallexPaymentStep;
