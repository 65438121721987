import { LoadingSpinner } from '@approvalmax/ui';
import { Box } from '@approvalmax/ui/src/components';
import { intl } from '@approvalmax/utils';
import { FC, memo, useMemo } from 'react';
import bemFactory from 'react-bem-factory';
import { useGetWorkflowVersionDocumentFields } from 'shared/data/webApp/v1';
import { useNetSuiteInvoiceFieldSettings } from 'shared/hooks';

import FieldsSection from '../../../../../components/FieldsSection';
import ActivitySection from '../../../../card/ActivitySection';
import AddCommentSection from '../../../../card/AddCommentSection/AddCommentSection';
import AttachmentsSection from '../../../../card/AttachmentsSection';
import Toolbar from '../../../../card/Toolbar/Toolbar';
import { WorkflowSection } from '../../../../card/WorkflowSection';
import { getNetSuiteAmountText, isFieldVisible } from '../../NetSuiteRequestCard.helpers';
import {
    useDiscountRate,
    useDisplayCurrency,
    useGetExchangeInfo,
    useNetSuiteCustomFields,
} from '../../NetSuiteRequestCard.hooks';
import {
    CurrencySwitcherContainer,
    FieldsSectionWrap,
    Root,
    StyledField,
    StyledScrollableArea,
} from '../../NetSuiteRequestCard.styles';
import CurrencySwitcher from '../CurrencySwitcher/CurrencySwitcher';
import NetSuiteCustomFields from '../NetSuiteCustomFields/NetSuiteCustomFields';
import { NetSuiteInvoiceFooterFields } from '../NetSuiteInvoiceFooterFields/NetSuiteInvoiceFooterFields';
import { NetSuiteInvoiceItemList } from '../NetSuiteInvoiceItemList/NetSuiteInvoiceItemList';
import NetSuiteRequestFooter from '../NetSuiteRequestFooter/NetSuiteRequestFooter';
import { messages } from './RequestCardInvoice.messages';
import { RequestCardInvoiceProps } from './RequestCardInvoice.types';

const qa = bemFactory.qa('netsuite-invoice-fields-section');

export const RequestCardInvoice: FC<RequestCardInvoiceProps> = memo((props) => {
    const { request, className } = props;

    const { data: companyCustomFields } = useNetSuiteCustomFields(request.companyId);

    const { data: workflowVersionDocumentFields, isFetching: isFetchingGetWorkflowVersionDocumentFields } =
        useGetWorkflowVersionDocumentFields({
            query: {
                companyId: request.companyId,
                workflowVersionIds: [request.workflowVersionId],
            },
        });

    const { details } = request;

    const fieldSettings = useNetSuiteInvoiceFieldSettings(workflowVersionDocumentFields?.data);
    const discountRate = useDiscountRate(details.discountRate, details.isDiscountRateAbsolute);

    const { displayCurrency, onDisplayCurrencyChange } = useDisplayCurrency();
    const { exchangeRate } = useGetExchangeInfo(request.companyId, details.exchangeRate, displayCurrency?.id);

    const items = useMemo(
        () => [...details.itemList, ...details.itemCostList, ...details.expenseCostList, ...details.invoiceTimeList],
        [details.itemList, details.itemCostList, details.expenseCostList, details.invoiceTimeList]
    );

    return (
        <Root className={className}>
            <Toolbar
                request={request}
                showAddress={isFieldVisible(fieldSettings.billingAddress)}
                showVatRegistration={isFieldVisible(fieldSettings.vatRegistration)}
                amountText={getNetSuiteAmountText(
                    request.amount,
                    exchangeRate,
                    request.currency || '',
                    displayCurrency?.isoCode
                )}
            />

            <StyledScrollableArea scrollResetKey={request.id}>
                {isFetchingGetWorkflowVersionDocumentFields ? (
                    <Box spacing='20'>
                        <LoadingSpinner />
                    </Box>
                ) : (
                    <>
                        <FieldsSectionWrap>
                            {isFieldVisible(fieldSettings.transactionNumber) && (
                                <StyledField
                                    qa={qa('transaction-number')}
                                    title={messages.transactionNumberFieldText}
                                    valueType={FieldsSection.Field.ValueType.String}
                                    value={details.transactionNumber}
                                    hideIfEmpty
                                />
                            )}

                            {isFieldVisible(fieldSettings.reference) && (
                                <StyledField
                                    qa={qa('transaction-reference')}
                                    title={messages.transactionReferenceFieldText}
                                    valueType={FieldsSection.Field.ValueType.String}
                                    value={details.transactionReference}
                                    hideIfEmpty
                                />
                            )}

                            {isFieldVisible(fieldSettings.transactionDate) && (
                                <StyledField
                                    qa={qa('transaction-date')}
                                    title={messages.transactionDateFieldText}
                                    valueType={FieldsSection.Field.ValueType.Date}
                                    value={details.transactionDate}
                                />
                            )}

                            {isFieldVisible(fieldSettings.dueDate) && (
                                <StyledField
                                    qa={qa('due-date')}
                                    title={messages.dueDateFieldText}
                                    valueType={FieldsSection.Field.ValueType.Date}
                                    value={details.dueDate}
                                />
                            )}

                            {isFieldVisible(fieldSettings.startDate) && (
                                <StyledField
                                    qa={qa('start-date')}
                                    title={messages.startDateFieldText}
                                    valueType={FieldsSection.Field.ValueType.Date}
                                    value={details.startDate}
                                    hideIfEmpty
                                />
                            )}

                            {isFieldVisible(fieldSettings.endDate) && (
                                <StyledField
                                    qa={qa('end-date')}
                                    title={messages.endDateFieldText}
                                    valueType={FieldsSection.Field.ValueType.Date}
                                    value={details.endDate}
                                    hideIfEmpty
                                />
                            )}

                            {isFieldVisible(fieldSettings.terms) && (
                                <StyledField
                                    qa={qa('terms')}
                                    title={messages.termsFieldText}
                                    valueType={FieldsSection.Field.ValueType.String}
                                    value={details.terms}
                                    hideIfEmpty
                                />
                            )}

                            {isFieldVisible(fieldSettings.discountAmount) && (
                                <StyledField
                                    qa={qa('discount-amount')}
                                    title={messages.discountAmountFieldText}
                                    valueType={FieldsSection.Field.ValueType.String}
                                    value={
                                        details.discountAmount
                                            ? intl.formatNumber(details.discountAmount, 'auto')
                                            : null
                                    }
                                    hideIfEmpty
                                />
                            )}

                            {isFieldVisible(fieldSettings.discountDate) && (
                                <StyledField
                                    qa={qa('discount-date')}
                                    title={messages.discountDateFieldText}
                                    valueType={FieldsSection.Field.ValueType.Date}
                                    value={details.discountDate}
                                    hideIfEmpty
                                />
                            )}

                            {isFieldVisible(fieldSettings.class) && (
                                <StyledField
                                    qa={qa('class')}
                                    title={messages.classFieldText}
                                    valueType={FieldsSection.Field.ValueType.String}
                                    value={details.class}
                                    hideIfEmpty
                                />
                            )}

                            {isFieldVisible(fieldSettings.department) && (
                                <StyledField
                                    qa={qa('department')}
                                    title={messages.departmentFieldText}
                                    valueType={FieldsSection.Field.ValueType.String}
                                    value={details.department}
                                    hideIfEmpty
                                />
                            )}

                            {isFieldVisible(fieldSettings.location) && (
                                <StyledField
                                    qa={qa('location')}
                                    title={messages.locationFieldText}
                                    valueType={FieldsSection.Field.ValueType.String}
                                    value={details.location}
                                    hideIfEmpty
                                />
                            )}

                            {isFieldVisible(fieldSettings.postingPeriod) && (
                                <StyledField
                                    qa={qa('posting-period')}
                                    title={messages.postingPeriodFieldText}
                                    valueType={FieldsSection.Field.ValueType.String}
                                    value={details.postingPeriod}
                                    hideIfEmpty
                                />
                            )}

                            {isFieldVisible(fieldSettings.discountItem) && (
                                <StyledField
                                    qa={qa('discount-item')}
                                    title={messages.discountItemFieldText}
                                    valueType={FieldsSection.Field.ValueType.String}
                                    value={details.discountItem}
                                    hideIfEmpty
                                />
                            )}

                            {isFieldVisible(fieldSettings.discountRate) && (
                                <StyledField
                                    qa={qa('discount-rate')}
                                    title={messages.discountRateFieldText}
                                    valueType={FieldsSection.Field.ValueType.String}
                                    value={discountRate}
                                    hideIfEmpty
                                />
                            )}

                            {isFieldVisible(fieldSettings.memo) && (
                                <StyledField
                                    qa={qa('memo')}
                                    title={messages.memoFieldText}
                                    valueType={FieldsSection.Field.ValueType.String}
                                    value={details.memo}
                                    hideIfEmpty
                                />
                            )}

                            <NetSuiteCustomFields
                                customFields={details.customFields}
                                companyCustomFields={companyCustomFields?.Fields ?? []}
                            />
                        </FieldsSectionWrap>

                        <NetSuiteInvoiceItemList
                            invoiceItemList={items}
                            companyCustomFields={companyCustomFields?.Fields ?? []}
                            integrationCode={request.integrationCode}
                            fieldSettings={fieldSettings}
                            exchangeRate={
                                displayCurrency && displayCurrency?.isoCode !== request.currency && exchangeRate !== 1
                                    ? exchangeRate
                                    : null
                            }
                        />

                        {typeof exchangeRate === 'number' && (
                            <CurrencySwitcherContainer>
                                <CurrencySwitcher
                                    request={request}
                                    selectedCurrency={displayCurrency}
                                    requestCurrency={details.currency}
                                    onCurrencyChange={onDisplayCurrencyChange}
                                    companyId={request.companyId}
                                />
                            </CurrencySwitcherContainer>
                        )}
                    </>
                )}

                <NetSuiteRequestFooter
                    request={request}
                    displayCurrency={displayCurrency}
                    exchangeRate={exchangeRate}
                />

                <NetSuiteInvoiceFooterFields request={request} />

                <AttachmentsSection request={request} />

                <WorkflowSection request={request} />

                <ActivitySection request={request} />

                {!request.flags.status.isDraft && <AddCommentSection request={request} />}
            </StyledScrollableArea>
        </Root>
    );
});

RequestCardInvoice.displayName = 'RequestCardInvoice';
