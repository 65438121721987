import { QBooksLogo } from '@approvalmax/ui';
import { Flex, Spacing } from '@approvalmax/ui/src/components';
import { Cin7BlueLogo, OracleNetsuiteLogo, XeroIcon } from 'modules/sprites';
import { FC, memo } from 'react';
import { useUserBetaFeatures } from 'shared/hooks';

import { ConnectionButton, PopupFooter, PopupTitle } from '..';
import { messages } from './InitialContent.messages';
import { InitialContentProps } from './InitialContent.types';

const InitialContent: FC<InitialContentProps> = memo((props) => {
    const { isLoading, onConnectXero, onConnectQBooks, onConnectNetSuite, onConnectCin7, onSkip } = props;

    const { isCin7 } = useUserBetaFeatures();

    return (
        <>
            <PopupTitle isInitial />

            <Spacing height={20} />

            <Flex container spacing='12'>
                <Flex size={12}>
                    <ConnectionButton
                        logo={XeroIcon}
                        title={messages.xeroButton}
                        onClick={onConnectXero}
                        isLoading={isLoading}
                    />
                </Flex>

                <Flex size={12}>
                    <ConnectionButton
                        logo={QBooksLogo}
                        title={messages.qboButton}
                        onClick={onConnectQBooks}
                        isLoading={isLoading}
                    />
                </Flex>

                <Flex size={12}>
                    <ConnectionButton
                        logo={OracleNetsuiteLogo}
                        title={messages.netSuiteButton}
                        onClick={onConnectNetSuite}
                        isLoading={isLoading}
                    />
                </Flex>

                {isCin7 && (
                    <Flex size={12}>
                        <ConnectionButton
                            logo={Cin7BlueLogo}
                            title={messages.dearButton}
                            onClick={onConnectCin7}
                            isLoading={isLoading}
                        />
                    </Flex>
                )}
            </Flex>

            <Spacing height={24} />

            <PopupFooter isLoading={isLoading} onSkip={onSkip} />
        </>
    );
});

export default InitialContent;
