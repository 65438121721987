import { getEmailDomain } from '@approvalmax/data';
import { CopyIcon } from '@approvalmax/ui';
import { Button, Flex, Radio, Text, TextField, toast } from '@approvalmax/ui/src/components';
import { escapeSpecificExpressions } from '@approvalmax/utils/src/helpers/string/escapeSpecificExpressions';
import { selectors } from 'modules/common';
import { domain } from 'modules/data';
import { FC, memo, useCallback } from 'react';
import { useCopyToClipboard } from 'react-use';
import { getDefaultEnvironment } from 'shared/data';

import { SettingsSection } from '../SettingsSection/SettingsSection';
import SettingsSubsection from '../SettingsSubsection/SettingsSubsection';
import { messages } from './SectionCaptureViaEmail.messages';
import { SectionCaptureViaEmailProps } from './SectionCaptureViaEmail.types';

export const SectionCaptureViaEmail: FC<SectionCaptureViaEmailProps> = memo((props) => {
    const { templateSettings, links, setTemplateSettings, readonly, qa } = props;

    const [_copiedText, setCopiedText] = useCopyToClipboard();

    const transform = useCallback((value: string) => {
        return escapeSpecificExpressions(value, ['@']);
    }, []);

    const onCopyOcrEmailAddress = useCallback(() => {
        setCopiedText(templateSettings?.ocrEmailAddress || '');
        toast.success(messages.copiedToClipboard);
    }, [setCopiedText, templateSettings?.ocrEmailAddress]);

    const onChangeOcrEmailAddress = useCallback(
        (newValue: string) => {
            setTemplateSettings((current) => ({
                ...current,
                ocrEmailAddress: newValue + getEmailDomain(getDefaultEnvironment()),
            }));
        },
        [setTemplateSettings]
    );

    const onChangeOcrRequestInitialStatus = useCallback(
        (newValue: domain.RequestStatusV2) => {
            setTemplateSettings((current) => ({
                ...current,
                ocrRequestInitialStatus: newValue,
            }));
        },
        [setTemplateSettings]
    );

    return (
        <SettingsSection
            title={messages.title}
            data-qa={qa('capture-via-email')}
            learnMoreLink={links[selectors.types.Link.CaptureViaEmail]}
        >
            <SettingsSubsection subtitle={messages.ocrEmailAddressSubtitle}>
                <TextField
                    disabled={readonly}
                    placeholder={messages.placeholder}
                    onChange={onChangeOcrEmailAddress}
                    value={templateSettings?.ocrEmailAddress?.replace(getEmailDomain(getDefaultEnvironment()), '')}
                    transform={transform}
                    maxLength={64}
                />

                <Flex block>
                    <Button
                        noPadding
                        noUppercase
                        onClick={onCopyOcrEmailAddress}
                        title={templateSettings?.ocrEmailAddress}
                        variant='text'
                        color='blue100'
                    >
                        <Flex spacing='4'>
                            <Text font='body'>{templateSettings?.ocrEmailAddress}</Text>

                            <CopyIcon size={16} />
                        </Flex>
                    </Button>
                </Flex>
            </SettingsSubsection>

            <SettingsSubsection subtitle={messages.ocrRequestInitialStatusSubtitle}>
                <Radio.Group
                    disabled={readonly}
                    value={templateSettings?.ocrRequestInitialStatus}
                    onChange={onChangeOcrRequestInitialStatus}
                    name='ocrRequestInitialStatus'
                    block
                >
                    <Radio value={domain.RequestStatusV2.Draft}>{messages.draftStatusText}</Radio>

                    <Radio value={domain.RequestStatusV2.OnApproval}>{messages.onApprovalStatusText}</Radio>
                </Radio.Group>
            </SettingsSubsection>
        </SettingsSection>
    );
});

SectionCaptureViaEmail.displayName = 'SectionCaptureViaEmail';
