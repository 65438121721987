import './fieldEntry.scss';

import { Reference } from '@approvalmax/types';
import { RichEditorView } from '@approvalmax/ui/src/components';
import { errorHelpers, typeGuardHelpers } from '@approvalmax/utils';
import moment from 'moment';
import React, { FC, PropsWithChildren, ReactNode } from 'react';
import bemFactory from 'react-bem-factory';

enum ValueType {
    String = 'String',
    Date = 'Date',
}

interface ChildrenComponents {
    ValueType: typeof ValueType;
    Empty: (props: { className?: string }) => React.ReactElement<FieldEntryProps>;
}

interface FieldEntryProps extends PropsWithChildren {
    className?: string;
    qa?: string;
    title: ReactNode;
    value?: string | Reference | null;
    valueType?: ValueType;
    forceDisplayValue?: ReactNode;
    warningText?: string | null;
    isHtml?: boolean;
    hideIfEmpty?: boolean;
}

const bem = bemFactory.block('reql-field-entry');
const qa = bemFactory.qa('reql-field-entry');

/**
 * @cloneof Scripts/app/pages/requestForm/components/FieldEntry/FieldEntry.tsx
 */
const FieldEntry: FC<FieldEntryProps> & ChildrenComponents = (props) => {
    const {
        className,
        qa: qa$,
        title,
        value,
        valueType,
        warningText,
        isHtml,
        hideIfEmpty,
        forceDisplayValue,
        children,
    } = props;

    let displayValue: string | null | undefined;
    let valueTypeInner = valueType;
    let valueInner = value;

    if (!valueTypeInner) {
        valueTypeInner = ValueType.String;
    }

    switch (valueTypeInner) {
        case ValueType.String:
            if (typeGuardHelpers.isReference(valueInner)) {
                displayValue = valueInner.text;
            } else {
                displayValue = valueInner;
            }

            break;

        case ValueType.Date:
            if (typeGuardHelpers.isReference(valueInner)) {
                throw errorHelpers.invalidOperationError();
            }

            displayValue = valueInner ? moment.utc(valueInner).format('ll') : null;
            break;

        default:
            throw errorHelpers.notSupportedError();
    }

    if (hideIfEmpty && !displayValue && !children && !forceDisplayValue) {
        return null;
    }

    return (
        <div className={bem.add(className)()} title={displayValue || undefined} data-qa={qa()} data-qa-id={qa$}>
            <div className={bem('field')} data-qa={qa('field')}>
                {title}
            </div>

            <div className={bem('value', { warning: warningText })} data-qa={qa('value')}>
                {children ? (
                    children
                ) : isHtml ? (
                    <RichEditorView className={bem('rich-editor')} html={displayValue || '-'} bordered={false} />
                ) : (
                    forceDisplayValue || displayValue || '-'
                )}
            </div>

            {warningText && (
                <div className={bem('warning-text')} data-qa={qa('warning-text')}>
                    {warningText}
                </div>
            )}
        </div>
    );
};

FieldEntry.ValueType = ValueType;
FieldEntry.Empty = ({ className }) => <div className={className} />;

export default FieldEntry;
