import { Flex } from '@approvalmax/ui/src/components';
import { memo } from 'react';
import Skeleton from 'react-loading-skeleton';

export const WelcomeSkeleton = memo(() => {
    return (
        <Flex direction='column'>
            <Skeleton height={110} />

            <Skeleton height={36} />

            <Skeleton height={36} />
        </Flex>
    );
});

WelcomeSkeleton.displayName = 'WelcomeSkeleton';
