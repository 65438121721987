import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('modules.TwoFaEnablingWizard.StepCodeScan', {
    title: 'Install the Authenticator app for 2FA',
    description: `Use the Authenticator app of your choice to generate codes for two-factor authentication.{br}{br}
    1) Download the Authenticator app to your smartphone.{br}{br}
    2) Scan the QR code below with the Authenticator app or enter the key manually. If you don't have a smartphone or need help, please refer to our <supportlink>support article</supportlink>.`,
    enterManuallyButton: 'Enter the key manually',
    copyCodeButton: 'Copy to clipboard',
    copyCodeResult: 'Code copied to clipboard',
    nextButton: 'Next',
});
