import { Radio } from '@approvalmax/ui/src/components';
import { domain } from 'modules/data';
import { FC, memo, useCallback } from 'react';

import { SettingsSection } from '../SettingsSection/SettingsSection';
import SettingsSubsection from '../SettingsSubsection/SettingsSubsection';
import { messages } from './SectionHistoryPushing.messages';
import { SectionHistoryPushingProps } from './SectionHistoryPushing.types';

const SectionHistoryPushing: FC<SectionHistoryPushingProps> = (props) => {
    const { templateSettings, setTemplateSettings, readonly, qa } = props;

    const value =
        templateSettings?.historyEventsPushingSettings?.historyEventsPushingType || domain.HistoryPushingType.None;

    const onChangeHistoryPushingType = useCallback(
        (newValue: domain.HistoryPushingType) => {
            setTemplateSettings((prevTemplateSettings) => ({
                ...prevTemplateSettings,
                historyEventsPushingSettings: { historyEventsPushingType: newValue },
            }));
        },
        [setTemplateSettings]
    );

    return (
        <SettingsSection title={messages.title} data-qa={qa('history_pushing')}>
            <SettingsSubsection subtitle={messages.subtitle({ br: <br /> })}>
                <Radio.Group
                    disabled={readonly}
                    value={value}
                    onChange={onChangeHistoryPushingType}
                    name='historyPushingType'
                    block
                >
                    <Radio value={domain.HistoryPushingType.None}>{messages.optionNoneText}</Radio>

                    <Radio value={domain.HistoryPushingType.Comments}>{messages.optionCommentsText}</Radio>

                    <Radio value={domain.HistoryPushingType.All}>{messages.optionAllText}</Radio>
                </Radio.Group>
            </SettingsSubsection>
        </SettingsSection>
    );
};

export default memo(SectionHistoryPushing);
