import { ErrorCode, Guid } from '@approvalmax/types';
import { errorHelpers, typeGuardHelpers } from '@approvalmax/utils';
import * as Sentry from '@sentry/browser';
import { actions, selectors } from 'modules/common';
import { backend, domain, schemas, State, stateTree } from 'modules/data';
import { RequestNote, StandaloneDescription } from 'modules/data/domain';
import { integrationActions } from 'modules/integration';
import {
    Action as ActionBase,
    ActionKind,
    ActionMeta,
    createAction,
    createAsyncAction,
    createErrorAction,
    createSelectingAction,
    Dispatch,
    ExtractActions,
    ThunkAction,
} from 'modules/react-redux';
import moment from 'moment';
import { normalize } from 'normalizr';
import { amplitudeService } from 'services/amplitude';
import { api } from 'services/api';
import { notificationService } from 'services/notification';
import { routingService } from 'services/routing';
import { splitViewGoToBarDefaultSettings, splitViewGoToBarSettingsState } from 'shared/components';
import { handleFileUploadLimits } from 'shared/helpers';
import { ContentSplitViewMode, contentSplitViewState, fileUploadTimestampsState, hasChangesState } from 'shared/states';
import { jotaiStore } from 'shared/store';
import { getPath, Path } from 'urlBuilder';

import { ocrAttachmentIdState } from '../components/SplitViewWrapper/components';
import { AirwallexContext, parseAirwallexContext } from '../data/Airwallex/AirwallexContext';
import { AttachmentKind } from '../data/AttachmentKind';
import { NetSuiteContext } from '../data/netsuite/NetSuiteContext';
import { parseXeroContext, XeroContext } from '../data/xero/XeroContext';
import { Context } from '../reducers/page/contextReducer';
import { getContext, getNewAttachments, getNewSupplierAttachments, getRequest } from '../selectors/pageSelectors';
import {
    getAllRequestAttachments,
    getInitialRequest,
    getRequestEditMode,
    getRequestTransfer,
    RequestEditMode,
    validateRequest,
} from '../selectors/requestSelectors';
import { isModifiedRequestState } from '../states';
import { messages } from './actions.messages';
import { loadNetSuiteRequestContext } from './netsuite';
import { loadQBooksRequestContext } from './qbooks';

interface FileError {
    file: File;
    errorMessage: string;
}

export async function loadRequestContext(requestId: Guid, companyId: string, dispatch: Dispatch, state: State) {
    function checkIntegration(r: domain.Request) {
        if (domain.getIntegrationTypeByCode(r.integrationCode) === domain.IntegrationType.None) {
            return true;
        }

        const company = selectors.company.getCompanyById(state, r.companyId);

        if (!company.flags.hasActiveIntegration) {
            dispatch(integrationActions.showIntegrationErrorPopup(company.id));

            return false;
        }

        return true;
    }

    const preloadedRequest = selectors.request.findRequestById(state, requestId);

    if (preloadedRequest && !checkIntegration(preloadedRequest)) {
        // Integration error is found before even trying to load
        return null;
    }

    // TODO: remove this debug code once AM-10049 is resolved.
    const lastCall = localStorage.getItem('debug:last-getEditingContextCall');
    const lastCallWithoutReload = (window as any).debugLastCallWithoutReload;
    const numberOfCallInThisWindow = (window as any).numberOfCallInThisWindow;
    const now = +new Date();

    (window as any).numberOfCallInThisWindow = numberOfCallInThisWindow + 1;
    (window as any).debugLastCallWithoutReload = now;
    localStorage.setItem('debug:last-getEditingContextCall', String(now));

    if (lastCallWithoutReload && now - Number(lastCallWithoutReload) < 5000) {
        Sentry.captureMessage('Call to api.requests.getEditingContext() was done less than 5 seconds ago, no reload.', {
            extra: {
                sinceLastCallMs: now - Number(lastCallWithoutReload),
                numberOfCallInThisWindow,
            },
        });
    }

    if (lastCall && now - Number(lastCall) < 5000) {
        Sentry.captureMessage(
            'Call to api.requests.getEditingContext() was done less than 5 seconds ago, with reload.',
            {
                extra: {
                    sinceLastCallMs: now - Number(lastCallWithoutReload),
                    numberOfCallInThisWindow,
                },
            }
        );
    }

    const result = await api.requests.getEditingContext({
        requestId,
        companyId: preloadedRequest?.companyId || companyId,
    });

    const entities = normalize(result.data.request, schemas.requestSchema).entities as any;
    const request = entities.requests[requestId] as domain.Request;

    await dispatch(
        integrationActions.loadTemplateFieldsByCode({
            companyId: request.companyId,
            integrationCode: request.integrationCode,
        })
    );

    delete entities.requests;

    if (!checkIntegration(request)) {
        // Integration error found after loading
        return null;
    }

    const integrationType = request.integrationType;
    const context: Context = {
        attachmentMaxSize: result.data.attachmentMaxSize,
        attachmentMaxCount: result.data.attachmentMaxCount,
        templateVersion: result.data.templateInstantiationVerison,
        fields: (result.data.fieldHeaders || []).map((f) => ({
            id: f.FieldId!,
            text: f.Name,
            systemPurpose: schemas.field.mapFieldSystemPurpose(f.SystemPurpose),
        })),
        requiredFieldIds: result.data.requiredFieldsIds || [],
        readonlyFieldIds: result.data.readonlyFieldsIds || [],
        requesterInstruction: result.data.requesterInstruction || null,
    };

    let xeroContext: XeroContext | undefined;
    let qbooksContext: domain.QBooksContext | undefined;
    let netSuiteContext: NetSuiteContext | undefined;
    let airwallexContext: AirwallexContext | undefined;

    if (result.data.airwallexContext) {
        airwallexContext = parseAirwallexContext(result.data.airwallexContext);
        airwallexContext.requesterInstruction = result.data.requesterInstruction;
    }

    const editMode = getRequestEditMode(state, request);

    switch (integrationType) {
        case domain.IntegrationType.Xero: {
            if (result.data.xeroContext) {
                xeroContext = parseXeroContext(result.data, request);
            }

            break;
        }

        case domain.IntegrationType.QBooks:
            qbooksContext = await loadQBooksRequestContext(request, result.data, editMode);
            break;

        case domain.IntegrationType.NetSuite:
            netSuiteContext = loadNetSuiteRequestContext(result.data);
            break;

        case domain.IntegrationType.Dear:
            break;

        case domain.IntegrationType.None:
            break;

        default:
            throw errorHelpers.assertNever(integrationType);
    }

    return {
        request,
        entities,
        context,
        xeroContext,
        qbooksContext,
        netSuiteContext,
        airwallexContext,
    };
}

export const LEAVE_PAGE = 'LEAVE_PAGE/LOAD_PAGE_DATA';
export const leavePage = () => createAction(LEAVE_PAGE, {});

export const LOAD_PAGE_DATA = 'REQUESTFORM/LOAD_PAGE_DATA';
export const loadPageData = (options: {
    request: domain.Request;
    entities: stateTree.Entities;
    context: Context;
    xeroContext: XeroContext | undefined;
    qbooksContext: domain.QBooksContext | undefined;
    netSuiteContext: NetSuiteContext | undefined;
    airwallexContext: AirwallexContext | undefined;
    activeAttachmentId?: Guid;
}) =>
    createSelectingAction(
        LOAD_PAGE_DATA,
        (state) => {
            const initialRequest = getInitialRequest(state, options.request, {
                context: options.context,
                xeroContext: options.xeroContext,
                qbooksContext: options.qbooksContext,
                netSuiteContext: options.netSuiteContext,
                airwallexContext: options.airwallexContext,
            });

            return {
                context: options.context,
                xeroContext: options.xeroContext,
                qbooksContext: options.qbooksContext,
                airwallexContext: options.airwallexContext,
                netSuiteContext: options.netSuiteContext,
                initialRequest,
                editMode: getRequestEditMode(state, options.request),
                activeAttachmentId: options.activeAttachmentId,
                request: options.request,
            };
        },
        options.entities
    );

export const CANCEL_ACTIVE_POPUP = 'REQUESTFORM/CANCEL_ACTIVE_POPUP';
export const cancelActivePopup = () => createAction(CANCEL_ACTIVE_POPUP, {});

export const DELETE_REQUEST = 'REQUESTFORM/DELETE_REQUEST';
export const DELETE_REQUEST_RESPONSE = 'REQUESTFORM/DELETE_REQUEST_RESPONSE';
export const DELETE_REQUEST_FAILURE = 'REQUESTFORM/DELETE_REQUEST_FAILURE';
export const deleteRequest = () =>
    createAsyncAction({
        request: (state: State) => {
            const request = getRequest(state);

            return createAction(DELETE_REQUEST, {
                requestId: request.id,
                companyId: request.companyId,
            });
        },

        response: async (request) => {
            jotaiStore.set(isModifiedRequestState, true);
            jotaiStore.set(hasChangesState, false);

            await api.requests.delete({ requestId: request.requestId, companyId: request.companyId });

            return createAction(DELETE_REQUEST_RESPONSE, {
                request,
            });
        },

        failure: (error) => createErrorAction(DELETE_REQUEST_FAILURE, error, {}),

        successToast: messages.deleteRequestSuccess,

        isBlocking: true,

        didDispatchResponse: async () => {
            routingService.pushToDefaultPath();
        },
    });

export const SAVE_DRAFT_REQUEST = 'REQUESTFORM/SAVE_DRAFT_REQUEST';
export const SAVE_DRAFT_REQUEST_RESPONSE = 'REQUESTFORM/SAVE_DRAFT_REQUEST_RESPONSE';
export const SAVE_DRAFT_REQUEST_FAILURE = 'REQUESTFORM/SAVE_DRAFT_REQUEST_FAILURE';
export const saveDraftRequest = (options?: {
    customGetRequestTransfer?: () => backend.Transfer<backend.transfers.RequestEditTransfer>;
}) =>
    createAsyncAction({
        request: (state: State) => {
            const transfer = options?.customGetRequestTransfer
                ? options.customGetRequestTransfer()
                : getRequestTransfer(state, getRequest(state));

            return createAction(SAVE_DRAFT_REQUEST, {
                transfer,
            });
        },

        response: async (request) => {
            jotaiStore.set(isModifiedRequestState, true);
            jotaiStore.set(hasChangesState, false);

            const response = await api.requests.edit(request.transfer);

            return createAction(SAVE_DRAFT_REQUEST_RESPONSE, {
                request,
                raw: response,
            });
        },

        failure: (error) => createErrorAction(SAVE_DRAFT_REQUEST_FAILURE, error, {}),

        isBlocking: true,

        successToast: messages.saveDraftRequestSuccess,
    });

export const UPLOAD_ATTACHMENTS = 'REQUESTFORM/UPLOAD_ATTACHMENTS';
export const UPLOAD_ATTACHMENTS_RESPONSE = 'REQUESTFORM/UPLOAD_ATTACHMENTS_RESPONSE';
export const UPLOAD_ATTACHMENTS_FAILURE = 'REQUESTFORM/UPLOAD_ATTACHMENTS_FAILURE';

export const uploadAttachments = ({
    files,
    attachmentKind = AttachmentKind.General,
    successfulCallback,
    failedCallback,
    expenseLinesAttachments,
}: {
    files: File[];
    attachmentKind?: AttachmentKind;
    successfulCallback?: (attachments: domain.RequestAttachment[]) => void;
    failedCallback?: VoidFunction;
    expenseLinesAttachments?: (domain.RequestAttachment | null)[];
}) => {
    function filterFiles(state: State, company: selectors.types.ExpandedCompany) {
        const request = getRequest(state);

        const attachments = getAllRequestAttachments(state, request);
        const xeroDeprecatedWords = ['alert'];

        const errors: FileError[] = [];

        const existingNames = attachments
            .concat(getNewAttachments(state))
            .concat(getNewSupplierAttachments(state))
            .concat((expenseLinesAttachments || []).filter(typeGuardHelpers.isTruthy))
            .map((a) => a.name);

        const filteredFiles = files.reduce<File[]>((result, file) => {
            if (existingNames.includes(file.name)) {
                errors.push({
                    file,
                    errorMessage: messages.uploadErrorDuplicateName({
                        fileName: file.name,
                    }),
                });

                return result;
            }

            const isXero = company.integration?.integrationType === domain.IntegrationType.Xero;
            const hasDeprecatedWords = file.name.match(new RegExp(xeroDeprecatedWords.join('|'), 'gi'));

            if (isXero && hasDeprecatedWords) {
                errors.push({
                    file,
                    errorMessage: messages.filenameContainsRestrictedWords({
                        fileName: file.name,
                        restriction: hasDeprecatedWords.join('", "'),
                    }),
                });

                return result;
            }

            result.push(file);

            return result;
        }, []);

        return {
            errors,
            filteredFiles,
        };
    }

    return createAsyncAction({
        shouldSendRequest: (state, dispatch) => {
            const request = getRequest(state);
            const company = selectors.company.getCompanyById(state, request.companyId);

            const { filteredFiles, errors } = filterFiles(state, company);

            errors.forEach((fileError) => {
                dispatch(actions.addErrorToast(fileError.errorMessage));
            });

            if (filteredFiles.length === 0) {
                return Promise.reject(false);
            }

            const context = getContext(state);
            const attachments = getAllRequestAttachments(state, request);

            if (attachments.length + files.length > context.attachmentMaxCount) {
                dispatch(
                    actions.addErrorToast(
                        messages.uploadErrorAttachmentMaxCountExceeded({
                            count: context.attachmentMaxCount,
                        })
                    )
                );
                failedCallback?.();

                return Promise.reject(false);
            }

            return Promise.resolve(true);
        },

        request: (state: State) => {
            const request = getRequest(state);
            const company = selectors.company.getCompanyById(state, request.companyId);
            const authorId = selectors.profile.getProfileUser(state).id;
            const createdDate = moment.utc().toISOString();

            let { filteredFiles } = filterFiles(state, company);

            filteredFiles = handleFileUploadLimits(filteredFiles);

            return createAction(UPLOAD_ATTACHMENTS, {
                authorId,
                goodFiles: filteredFiles,
                attachmentKind,
                newAttachments: filteredFiles.map(
                    (file): domain.RequestAttachment => ({
                        id: file.name,
                        name: file.name,
                        size: file.size,
                        attachmentType: domain.RequestAttachmentType.General,
                        createdDate,
                    })
                ),
            });
        },

        response: async (request) => {
            const hasChanges = jotaiStore.get(hasChangesState);
            const responses: backend.AttachmentListAnswer[] = await Promise.all(
                request.goodFiles.map((f) => {
                    let formData = new FormData();

                    formData.append('file', f);

                    jotaiStore.set(fileUploadTimestampsState, (value) => [...value, new Date().toISOString()]);
                    jotaiStore.set(splitViewGoToBarSettingsState, splitViewGoToBarDefaultSettings);

                    return api.postFormData('requests/attachFile', formData);
                })
            );
            const accepted: domain.RequestAttachment[] = [];
            const rejected: Array<{
                attachmentId: string;
                reason: backend.FilesFileUploadResult;
            }> = [];

            responses.forEach((r) => {
                const a = r.Attachments[0];

                if (a.UploadResult === undefined || a.UploadResult === backend.FilesFileUploadResult.OK) {
                    accepted.push(schemas.request.mapAttachment(a));
                } else {
                    rejected.push({
                        attachmentId: a.Name || '',
                        reason: a.UploadResult,
                    });
                }
            });

            if (accepted.length > 0) {
                if (attachmentKind === AttachmentKind.General) {
                    jotaiStore.set(contentSplitViewState, {
                        mode: ContentSplitViewMode.AttachmentsToRequest,
                        attachmentId: accepted[0].id,
                    });
                }

                if (attachmentKind === AttachmentKind.EmailToSupplierAttachment) {
                    jotaiStore.set(contentSplitViewState, {
                        mode: ContentSplitViewMode.AttachmentsToEmailToSupplier,
                        attachmentId: accepted[0].id,
                    });
                }
            }

            successfulCallback?.(accepted);

            jotaiStore.set(hasChangesState, hasChanges || accepted.length > 0);

            return createAction(UPLOAD_ATTACHMENTS_RESPONSE, {
                request,
                attachmentKind,
                accepted,
                rejected,
            });
        },

        failure: (error, request) => {
            failedCallback?.();

            return createErrorAction(UPLOAD_ATTACHMENTS_FAILURE, error, {
                request,
                attachmentKind,
            });
        },

        didDispatchResponse: (request, response, state, dispatch) => {
            const maxSize = getContext(state).attachmentMaxSize;
            const rejected: Array<{
                attachmentId: string;
                reason: backend.FilesFileUploadResult | undefined;
            }> = response.rejected;

            rejected.forEach((f) => {
                const attachment = request.newAttachments.find((a) => a.id === f.attachmentId)!;

                let message;

                switch (f.reason) {
                    case backend.FilesFileUploadResult.RestrictedFileType:
                        message = messages.uploadErrorRestrictedFileType({
                            fileName: attachment.name,
                        });
                        break;

                    case backend.FilesFileUploadResult.TooBig:
                        message = messages.uploadErrorTooBig({
                            fileName: attachment.name,
                            maxSize: Math.floor(maxSize / 1024 / 1024),
                        });
                        break;

                    case backend.FilesFileUploadResult.VirusFound:
                        message = messages.uploadErrorVirusFound({
                            fileName: attachment.name,
                        });
                        break;

                    default:
                        message = messages.uploadErrorGeneric;
                        break;
                }

                dispatch(actions.addErrorToast(message));
            });
        },
    });
};

export const REMOVE_ATTACHMENT = 'REQUESTFORM/REMOVE_ATTACHMENT';

export const removeAttachment = (attachmentId: Guid) => {
    const ocrAttachmentId = jotaiStore.get(ocrAttachmentIdState);

    jotaiStore.set(hasChangesState, true);

    if (ocrAttachmentId === attachmentId) {
        jotaiStore.set(ocrAttachmentIdState, null);
    }

    return createAction(REMOVE_ATTACHMENT, { attachmentId });
};

export const REMOVE_EMAIL_TO_CONTACT_ATTACHMENT = 'REQUESTFORM/REMOVE_EMAIL_TO_CONTACT_ATTACHMENT';
export const removeEmailToContactAttachment = (attachmentId: Guid) =>
    createAction(REMOVE_EMAIL_TO_CONTACT_ATTACHMENT, { attachmentId });

export const CHANGE_CURRENCY = 'REQUESTFORM/CHANGE_CURRENCY';

export const changeCurrency = (newCurrency: string) => {
    jotaiStore.set(hasChangesState, true);

    return createAction(CHANGE_CURRENCY, {
        newCurrency,
    });
};

export const SET_LOAD_INDICATOR_EXCHANGE_RATE = 'REQUESTFORM/SET_LOAD_INDICATOR_EXCHANGE_RATE';
export const setLoadIndicatorExchangeRate = (type: 'start' | 'stop') =>
    createAction(SET_LOAD_INDICATOR_EXCHANGE_RATE, undefined, undefined, {
        kind: type === 'start' ? ActionKind.AsyncRequest : ActionKind.AsyncResponse,
        operationId: SET_LOAD_INDICATOR_EXCHANGE_RATE,
        isBlocking: true,
    });

export interface ExchangeRateParams {
    exchangeRate?: number | null;
    exchangeRateDate?: string | null;
    exchangeRateSource?: keyof typeof domain.ExchangeRateSource | null;
}

export const CHANGE_CURRENCY_EXCHANGE_RATE_PARAMS = 'REQUESTFORM/CHANGE_CURRENCY_EXCHANGE_RATE_PARAMS';

export const changeExchangeRateParams = (exchangeRateParams: ExchangeRateParams) => {
    const hasChanges = jotaiStore.get(hasChangesState);

    const source = exchangeRateParams.exchangeRateSource;
    // setting or clearing a manual course is determined as a change
    const changed = source === domain.ExchangeRateSource.Manual || source === domain.ExchangeRateSource.None;

    jotaiStore.set(hasChangesState, hasChanges || changed);

    return createAction(CHANGE_CURRENCY_EXCHANGE_RATE_PARAMS, {
        exchangeRateParams,
    });
};

export const SHOW_VALIDATION_ERRORS = 'REQUESTFORM/SHOW_VALIDATION_ERRORS';
export type ShowValidationErrorsAction = ActionBase<typeof SHOW_VALIDATION_ERRORS, {}, ActionMeta, any>;

export const showValidationErrors = (validationErrors: string[]): ThunkAction => {
    return (dispatch) => {
        validationErrors.forEach((error) => dispatch(actions.addErrorToast(error)));
        dispatch<ShowValidationErrorsAction>(createAction(SHOW_VALIDATION_ERRORS, {}));
    };
};

export const SHOW_OPTIMISTIC_LOCKING_POPUP = 'REQUESTFORM/SHOW_OPTIMISTIC_LOCKING_POPUP';
export const showOptimisticLockingPopup = () => createAction(SHOW_OPTIMISTIC_LOCKING_POPUP, {});

export const SHOW_SAVE_AND_APPROVE_POPUP = 'REQUESTFORM/SHOW_SAVE_AND_APPROVE_POPUP';
export const showSaveAndApprovePopup = () => createAction(SHOW_SAVE_AND_APPROVE_POPUP, {});

export const SHOW_RESUBMIT_REQUEST_POPUP = 'REQUESTFORM/SHOW_RESUBMIT_REQUEST_POPUP';
export const showResubmitRequestPopup = () => createAction(SHOW_RESUBMIT_REQUEST_POPUP, {});

export const SAVE_AND_APPROVE_REQUEST = 'REQUESTFORM/SAVE_AND_APPROVE_REQUEST';
export const SAVE_AND_APPROVE_REQUEST_RESPONSE = 'REQUESTFORM/SAVE_AND_APPROVE_REQUEST_RESPONSE';
export const SAVE_AND_APPROVE_REQUEST_FAILURE = 'REQUESTFORM/SAVE_AND_APPROVE_REQUEST_FAILURE';
export const saveAndApproveRequest = (options?: {
    skipPreconditions?: boolean;
    commentText?: string;
    isOverwriteRequest?: boolean;
}) =>
    createAsyncAction({
        shouldSendRequest: (state, dispatch) => {
            if (options?.skipPreconditions) {
                return true;
            }

            const request = getRequest(state);
            const validationErrors = validateRequest(state, request);
            const editMode = getRequestEditMode(state, request);
            const isApproverMode = editMode === RequestEditMode.Approver;

            if (validationErrors.length > 0) {
                dispatch(showValidationErrors(validationErrors));

                return false;
            }

            const hasDecisions = request.steps.some((s) =>
                s.participants.some((p) => p.decision !== domain.RequestStepParticipantDecision.NoResponse)
            );

            if (hasDecisions && !isApproverMode) {
                dispatch(showSaveAndApprovePopup());

                return false;
            }

            return true;
        },

        request: (state: State) => {
            const request = getRequest(state);
            const transfer = getRequestTransfer(state, request);

            transfer.resetToStep = request.steps?.[0].id;
            transfer.commentText = options?.commentText || undefined;
            transfer.requestVersion = options?.isOverwriteRequest ? null : request.version;

            return createAction(SAVE_AND_APPROVE_REQUEST, {
                transfer,
                commentText: options?.commentText,
            });
        },

        response: async (request) => {
            jotaiStore.set(isModifiedRequestState, true);
            jotaiStore.set(hasChangesState, false);

            const response = await api.requests.edit(request.transfer);

            return createAction(SAVE_AND_APPROVE_REQUEST_RESPONSE, {
                request,
                raw: response,
            });
        },

        failure: (error) => createErrorAction(SAVE_AND_APPROVE_REQUEST_FAILURE, error, {}),

        isBlocking: true,

        successToast: messages.saveAndApproveRequestSuccess,

        didDispatchResponse: async () => {
            routingService.pushToDefaultPath();
        },

        didDispatchError: async (request, error, _state, dispatch) => {
            const errorCode = errorHelpers.getErrorCode(error);

            if (errorCode === ErrorCode.E4050_RESPONSE_OUTDATED) {
                dispatch(showOptimisticLockingPopup());
            }
        },
    });

export const completeReview = async (requestId: string, companyId: string) => {
    const response = await api.requests.get({ requestId, companyId });
    const entities = normalize<backend.RequestSelectAnswer, stateTree.Entities>(
        response.Requests[0],
        schemas.requestSchema
    ).entities;
    const updatedRequest = Object.values(entities.requests)[0];

    await api.requests.completeReview({
        requestId: updatedRequest.id,
        requestVersion: updatedRequest.version,
        companyId: updatedRequest.companyId,
    });
};

export const SAVE_AND_RESUBMIT_REQUEST = 'REQUESTFORM/SAVE_AND_RESUBMIT_REQUEST';
export const SAVE_AND_RESUBMIT_REQUEST_RESPONSE = 'REQUESTFORM/SAVE_AND_RESUBMIT_REQUEST_RESPONSE';
export const SAVE_AND_RESUBMIT_REQUEST_FAILURE = 'REQUESTFORM/SAVE_AND_RESUBMIT_REQUEST_FAILURE';
export const saveAndResubmitRequest = (options?: {
    commentText: string;
    customGetRequestTransfer?: () => backend.Transfer<backend.transfers.RequestEditTransfer>;
    customRequestValidationFn?: () => Promise<string[]>;
    needToCompleteReview?: boolean;
    isOverwriteRequest?: boolean;
}) =>
    createAsyncAction({
        shouldSendRequest: async (state, dispatch) => {
            const request = getRequest(state);
            const validationErrors = options?.customRequestValidationFn
                ? await options?.customRequestValidationFn()
                : validateRequest(state, request);

            if (validationErrors.length > 0) {
                dispatch(showValidationErrors(validationErrors));

                return false;
            } else {
                return true;
            }
        },

        request: (state: State) => {
            const request = getRequest(state);

            const transfer = options?.customGetRequestTransfer
                ? options.customGetRequestTransfer()
                : getRequestTransfer(state, request);

            transfer.resetToStep = request.reviewStep?.reviewStepId || request.steps?.[0]?.id;
            transfer.commentText = options?.commentText || undefined;
            transfer.requestVersion = options?.isOverwriteRequest ? null : request.version;

            return createAction(SAVE_AND_RESUBMIT_REQUEST, {
                requestId: request.id,
                transfer,
                commentText: options?.commentText,
                companyId: request.companyId,
                needToCompleteReview: options?.needToCompleteReview,
            });
        },

        response: async (request) => {
            jotaiStore.set(isModifiedRequestState, true);
            jotaiStore.set(hasChangesState, false);

            const response = await api.requests.edit(request.transfer);

            if (request.needToCompleteReview) {
                await completeReview(request.requestId, request.companyId);
            }

            return createAction(SAVE_AND_RESUBMIT_REQUEST_RESPONSE, {
                request,
                raw: response,
            });
        },

        failure: (error) => createErrorAction(SAVE_AND_RESUBMIT_REQUEST_FAILURE, error, {}),

        isBlocking: true,

        successToast: options?.needToCompleteReview
            ? messages.resubmitRequestSuccess
            : messages.saveUpdatedRequestSuccess,

        didDispatchResponse: async (request, response, state) => {
            routingService.push(getPath(Path.request, request.requestId, request.companyId));

            const r = getRequest(state);
            const company = selectors.company.getCompanyById(state, r.companyId);
            const isXeroBill = r.integrationCode === domain.IntegrationCode.XeroBill;
            const isAmountDecreased = r.amount > (request.transfer.amount || 0);

            // similar api.xeroMatching.getMatchingInfo call will happen at billView via useQuery, so try to avoid
            // this one as possible
            if (company.flags.isXeroMatchingV2 && isXeroBill && isAmountDecreased) {
                const xeroMatchingResponse = await api.xeroMatching.getBillMatchingInfo({
                    billId: r.id,
                    companyId: company.id,
                });

                const hasMatchedPO = xeroMatchingResponse.data?.documents?.length;

                if (hasMatchedPO) {
                    notificationService.showInfoToast(messages.checkYourMatchingAllocations);
                }
            }
        },

        didDispatchError: async (request, error, state, dispatch) => {
            const errorCode = errorHelpers.getErrorCode(error);

            if (errorCode === ErrorCode.E4050_RESPONSE_OUTDATED) {
                dispatch(showOptimisticLockingPopup());
            }

            if (errorCode === ErrorCode.E4075_OPERATION_FAILED_DUE_TO_EXTERNAL_REJECT) {
                routingService.push(getPath(Path.request, request.requestId, request.companyId));
            }
        },
    });

export const SAVE_AND_SUBMIT_REQUEST = 'REQUESTFORM/SAVE_AND_SUBMIT_REQUEST';
export const SAVE_AND_SUBMIT_REQUEST_RESPONSE = 'REQUESTFORM/SAVE_AND_SUBMIT_REQUEST_RESPONSE';
export const SAVE_AND_SUBMIT_REQUEST_FAILURE = 'REQUESTFORM/SAVE_AND_SUBMIT_REQUEST_FAILURE';
export const saveAndSubmitRequest = (options?: {
    customGetRequestTransfer?: () => backend.Transfer<backend.transfers.RequestEditTransfer>;
    customRequestValidationFn?: () => Promise<string[]>;
}) =>
    createAsyncAction({
        shouldSendRequest: async (state, dispatch) => {
            const request = getRequest(state);
            const validationErrors = options?.customRequestValidationFn
                ? await options?.customRequestValidationFn()
                : validateRequest(state, request);

            if (validationErrors.length > 0) {
                dispatch(showValidationErrors(validationErrors));

                return false;
            }

            return true;
        },

        request: (state: State) => {
            const request = getRequest(state);

            const transfer = options?.customGetRequestTransfer
                ? options.customGetRequestTransfer()
                : getRequestTransfer(state, request);

            return createAction(SAVE_AND_SUBMIT_REQUEST, {
                requestId: request.id,
                transfer,
                companyId: request.companyId,
            });
        },

        response: async (request) => {
            jotaiStore.set(isModifiedRequestState, true);
            jotaiStore.set(hasChangesState, false);

            await api.requests.edit(request.transfer);
            await api.requests.publish({
                requestId: request.requestId,
                companyId: request.companyId,
            });

            return createAction(SAVE_AND_SUBMIT_REQUEST_RESPONSE, {
                request,
            });
        },

        failure: (error) => createErrorAction(SAVE_AND_SUBMIT_REQUEST_FAILURE, error, {}),

        isBlocking: true,

        successToast: messages.saveAndSubmitRequestSuccess,

        didDispatchResponse: async (requestTransfer, response, state) => {
            const request = getRequest(state);

            amplitudeService.sendData('requests: create request', {
                'request type': request.integrationCode?.toLocaleLowerCase() || 'standalone',
            });

            routingService.push(getPath(Path.request, requestTransfer.requestId, requestTransfer.companyId));
        },

        didDispatchError: async (request, error) => {
            const errorCode = errorHelpers.getErrorCode(error);

            if (errorCode === ErrorCode.E4075_OPERATION_FAILED_DUE_TO_EXTERNAL_REJECT) {
                routingService.push(getPath(Path.request, request.requestId, request.companyId));
            }
        },
    });

export const CHANGE_REQUEST_NAME = 'REQUESTFORM/CHANGE_REQUEST_NAME';

export const changeRequestName = (newName: string) => {
    jotaiStore.set(hasChangesState, true);

    return createAction(CHANGE_REQUEST_NAME, {
        newName,
    });
};

export const CHANGE_REQUEST_DESCRIPTION = 'REQUESTFORM/CHANGE_REQUEST_DESCRIPTION';

export const changeRequestDescription = (newDescription: StandaloneDescription) => {
    jotaiStore.set(hasChangesState, true);

    return createAction(CHANGE_REQUEST_DESCRIPTION, {
        newDescription,
    });
};

export const EDIT_REQUEST_NOTE = 'REQUESTFORM/EDIT_REQUEST_NOTE';

export const editRequestNote = (requestNote: RequestNote) => {
    jotaiStore.set(hasChangesState, true);

    return createAction(EDIT_REQUEST_NOTE, {
        requestNote,
    });
};

export type Action =
    | ExtractActions<
          | typeof cancelActivePopup
          | typeof changeCurrency
          | typeof changeExchangeRateParams
          | typeof changeRequestDescription
          | typeof changeRequestName
          | typeof deleteRequest
          | typeof editRequestNote
          | typeof leavePage
          | typeof loadPageData
          | typeof removeAttachment
          | typeof removeEmailToContactAttachment
          | typeof saveAndApproveRequest
          | typeof saveAndResubmitRequest
          | typeof saveAndSubmitRequest
          | typeof saveDraftRequest
          | typeof setLoadIndicatorExchangeRate
          | typeof showOptimisticLockingPopup
          | typeof showResubmitRequestPopup
          | typeof showSaveAndApprovePopup
          | typeof uploadAttachments
      >
    | ShowValidationErrorsAction;
