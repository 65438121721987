import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('pages.Reports.ColumnDefinitions', {
    nameColumnName: 'Name',
    nameColumnTitle:
        'Approval request full name. For Xero and QuickBooks requests ' +
        'it is derived from key request parameters such supplier, number of reference',

    descriptionColumnName: 'Description',
    descriptionColumnTitle: 'Description attached to the request',

    templateIdColumnName: 'Request type',
    templateIdColumnTitle: 'Approval workflow name as defined in Organisation > Approval Workflows',

    activeStepNameColumnName: 'Active step',
    activeStepNameColumnTitle:
        'Where approval request is in terms of the approval workflow. Name of the step as defined in the approval workflow',

    attachmentColumnName: 'Files',
    attachmentColumnTitle: 'Files attached to the request',

    resolutionColumnName: 'Status',
    resolutionColumnTitle: 'Status of approval request in the workflow (open / approved / rejected / cancelled)',

    fraudulentActivityColumnName: 'Fraud risk',
    fraudulentActivityColumnTitle:
        'This field indicates if there is any kind of risk of fraud based on how the decision has been made',

    creationDateColumnName: 'Creation date',
    creationDateColumnTitle: 'When approval for this request has been started in ApprovalMax',

    dateColumnName: 'Date',
    dateColumnTitle: 'Date',

    resolutionDateColumnName: 'Decision date',
    resolutionDateColumnTitle: 'When approval request has been finally approved',

    dueDateColumnName: 'Due date',
    dueDateColumnTitle: 'Document due date as shown in the accounting system',

    shippingDateColumnName: 'Shipping date',
    shippingDateColumnTitle: 'Shipping date',

    deliveryDateColumnName: 'Delivery date',
    deliveryDateColumnTitle: 'Delivery date for the Purchase Order',
    qboPurchaseOrderStatusColumnName: 'Purchase order status',
    qboPurchaseOrderStatusColumnTitle: 'Purchase order status',

    paymentDateColumnName: 'Planned payment date',
    paymentDateColumnTitle: 'Planned payment date set in Xero',

    xeroBPPaymentDateColumnName: 'Payment date',
    xeroBPPaymentDateColumnTitle: 'Date of banking transaction',

    expectedDateColumnName: 'Expected date',
    expectedDateColumnTitle: 'Expected date as defined in Xero',

    documentDateColumnName: 'Date',
    documentDateColumnTitle: 'Document date as shown in the accounting system',

    resolutionTimeColumnName: 'How long decision took',
    resolutionTimeColumnTitle: 'How long it took to process the request: from workflow start to final decision',

    amountColumnName: 'Gross amount',
    amountColumnTitle:
        'Gross amount of the request in the request currency that could be different from base company currency',

    xeroBPAmountColumnName: 'Amount',
    xeroBPAmountColumnTitle: 'Total amount of money payed with Xero batch payment request',

    airwallexBPAmountColumnName: 'Total amount',
    airwallexBPAmountColumnTitle: 'Total amount of money payed with Batch payment request',

    XeroAirwallexBPPaymentStatusColumnName: 'Payment status',
    XeroAirwallexBPPaymentStatusColumnTitle: 'Payment status',

    amaxPayBatchAmountColumnName: 'Total amount',
    amaxPayBatchAmountColumnTitle: 'Total amount of money payed with Batch payment request',
    amaxPayBatchPaymentStatusColumnName: 'Payment status',
    amaxPayBatchPaymentStatusColumnTitle: 'Payment status',
    amaxPayBatchPaymentStatusNone: 'None',
    amaxPayBatchPaymentStatusAwaitingPayment: 'Awaiting payment',
    amaxPayBatchPaymentStatusProcessing: 'Processing',
    amaxPayBatchPaymentStatusPartiallyPaid: 'Partially paid',
    amaxPayBatchPaymentStatusPaid: 'Paid',
    amaxPayBatchPaymentStatusFailed: 'Failed',
    amaxPayBatchPaymentStatusCancelled: 'Cancelled',
    amaxPayBankAccountNamesColumnName: 'Bank account',
    amaxPayBankAccountNamesColumnTitle: 'Bank account',

    netAmountColumnName: 'Net amount',
    netAmountColumnTitle:
        'Net amount of the request in the request currency that could be different from base company currency',

    taxAmountColumnName: 'Total tax',
    taxAmountColumnTitle:
        'Total tax of the request in the request currency that could be different from base company currency',

    currencyColumnName: 'Currency',
    currencyColumnTitle: 'Currency in which request has been created',

    taxTypeColumnName: 'Amounts are (Tax)',
    taxTypeColumnTitle: 'Are amounts tax inclusive / tax exclusive or do not include tax at all',

    documentNumberColumnName: 'Number',
    documentNumberColumnTitle: 'Number of the financial document',

    requestersColumnName: 'Requester',
    requestersColumnTitle:
        'Who was originator of this request. This is either a person who ' +
        'created a request in ApprovalMax or a user selected as "Xero requester" in the workflow settings.',

    participantsApprovedColumnName: 'Approved by',
    participantsApprovedColumnTitle: 'Who has already approved request',

    participantsRejectedColumnName: 'Rejected by',
    participantsRejectedColumnTitle: 'Who has rejected request',

    participantsNonRespondedColumnName: 'Waiting for',
    participantsNonRespondedColumnTitle: 'Who is supposed to make decision in the current approval workflow step',

    contactColumnName: 'Contact',
    contactQBOColumnName: 'Vendor',
    contactColumnTitle:
        'Counterparty for the financial document. ' +
        'Supplier / Vendor in case of accounts payable and Customers in case of accounts receivable.',

    customerColumnName: 'Customer',
    customerColumnTitle: 'Customer',

    qBooksPayeeCustomerColumnName: 'Payee: Customer',
    qBooksPayeeCustomerColumnTitle: 'Payee: Customer',

    qBooksPayeeEmployeeColumnName: 'Payee: Employee',
    qBooksPayeeEmployeeColumnTitle: 'Payee: Employee',

    qBooksPaymentAccountColumnName: 'Payment account',
    qBooksPaymentAccountColumnTitle: 'Payment account',

    qBooksPaymentTypeColumnName: 'Payment type',
    qBooksPaymentTypeColumnTitle: 'Payment type',

    qBooksPaymentMethodColumnName: 'Payment method',
    qBooksPaymentMethodColumnTitle: 'Payment method',

    qBooksTermsColumnName: 'Terms',
    qBooksTermsColumnTitle: 'Terms',

    referenceColumnName: 'Reference',
    referenceColumnTitle: 'Reference of the financial document',

    brandingColumnName: 'Branding (Theme)',
    brandingColumnTitle: 'What document design template will or has already been applied for the document in Xero',
    sentToSupplierColumnName: 'Sent to supplier',
    sentToSupplierColumnTitle: 'Whether Purchase Order has been sent to the supplier',

    auditReportStatusColumnName: 'Audit Report',
    auditReportStatusColumnTitle: 'PDF Audit Report that has been generated upon approval',

    locationColumnName: 'Location',
    locationColumnTitle: 'Location',

    lineItemClassesColumnName: 'Classes',
    lineItemClassesColumnTitle: 'Class used in line items (at least one line item should match)',

    lineItemAccountsColumnName: 'Account',
    lineItemAccountsColumnTitle: 'Account used in line items (at least one line item should match)',

    bankAccountsColumnName: 'Bank account number',
    bankAccountsColumnTitle: 'This field contain Bank Account Numbers which payments were made from',

    qbooksLineItemAccountsColumnName: 'Category',
    qbooksLineItemAccountsColumnTitle: 'Category used in line items (at least one line item should match)',

    purchaseOrderBalanceColumnName: 'PO Balance',
    purchaseOrderBalanceColumnTitle:
        'Amount of the Purchase Order minus amount of all matched open and approved Bills. Indicates what is left to be paid.',

    matchedPurchaseOrdersColumnName: 'Matched Purchase Orders',
    matchedPurchaseOrdersColumnTitle: 'Purchase Orders matched with the Bill',

    matchedBillsColumnName: 'Matched Bills',
    matchedBillsColumnTitle: 'Bills matched with the Purchase Order',

    matchedBillsBalanceName: 'Bill remaining balance',
    matchedBillsBalanceTitle: 'Bill remaining balance',

    sumOfAllocationsName: 'Sum of all Allocations',
    sumOfAllocationsTitle: 'Sum of all Allocations',

    isBilledColumnName: 'Billed',
    isBilledColumnTitle: 'Indicates if Purchase Order has been marked as Fully Billed in ApprovalMax or in Xero',

    isPaidColumnName: 'Paid',
    isPaidColumnTitle: 'Indicates if a Bill has been marked as Paid',

    fullyPaidDateColumnName: 'Paid date',
    fullyPaidDateColumnTitle: 'Paid date',

    retrospectivePurchaseOrderColumnName: 'Is Retrospective',
    retrospectivePurchaseOrderColumnTitle:
        'This field shows if there is at least one Bill matched with the PO that has been dated prior the PO date',

    lineItemTrackings1ColumnName: 'Tracking Category 1',
    lineItemTrackings1ColumnTitle: 'Tracking Category 1',

    lineItemTrackings2ColumnName: 'Tracking Category 2',
    lineItemTrackings2ColumnTitle: 'Tracking Category 2',

    contactNameColumnName: 'Contact name',
    contactNameColumnTitle: 'The contact name used in request',

    taxNumberColumnName: 'TAX ID number',
    taxNumberColumnTitle: 'The TAX ID Number used in request',

    bankAccountDetailsColumnName: 'Bank account number',
    bankAccountDetailsColumnTitle: 'The Bank Account Number used in request',

    salesTaxCodeColumnName: 'Default sales tax',
    salesTaxCodeColumnTitle: 'The Sales tax record specified as default Sales tax for Contact',

    purchaseTaxCodeColumnName: 'Default purchase tax',
    purchaseTaxCodeColumnTitle: 'The Purchase tax record specified as default Purchase tax for Contact',

    vendorNameColumnName: 'Vendor name',
    vendorNameColumnTitle: 'The vendor name used in request',

    businessNumberColumnName: 'Business ID No / Social security number',
    businessNumberColumnTitle: 'The Business ID No or Social Security Number used in request',
    netSuiteCurrencyColumnName: 'Currency Full Name',
    netSuiteCurrencyColumnTitle: 'Currency Full Name',
    netSuiteVendorColumnName: 'Vendor',
    netSuiteVendorColumnTitle: 'Vendor',
    netSuitePayeeColumnName: 'Payee',
    netSuitePayeeColumnTitle: 'Payee',
    netSuiteEmployeeColumnName: 'Employee',
    netSuiteDocumentStatusColumnName: 'Document status',
    netSuiteDocumentStatusColumnTitle: 'Document status',
    netSuiteEmployeeColumnTitle: 'Employee',
    netSuiteCustomerColumnName: 'Customer',
    netSuiteCustomerColumnTitle: 'Customer',
    netSuiteTermsColumnName: 'Terms',
    netSuiteTermsColumnTitle: 'Terms',
    netSuiteExpenseReportExternalStatusColumnName: 'External status',
    netSuiteExpenseReportExternalStatusColumnTitle: 'External status',

    dearSupplierColumnName: 'Supplier',
    dearSupplierColumnTitle: 'Supplier',

    payersColumnName: 'Paid by',
    payersColumnTitle: 'Paid by',

    grnColumnName: 'GRN status',
    grnColumnTitle: 'GRN status',
    grnNotReceived: 'Not received',
    grnPartiallyReceived: 'Partially received',
    grnFullyReceived: 'Fully received',

    isAcceptedColumnName: 'Customer decision',
    isAcceptedColumnTitle: 'Customer decision',
    isAcceptedTrue: 'Accepted',
    isAcceptedFalse: 'Declined',

    customerDecisionDateColumnName: 'Customer decision date',
    customerDecisionDateColumnTitle: 'Customer decision date',

    isSentColumnName: 'Sent to the customer',
    isSentColumnTitle: 'Sent to the customer',

    narrationColumnName: 'Narration',
    narrationColumnTitle: 'Narration',

    totalCreditTaxColumnName: 'Total credit tax',
    totalCreditTaxColumnTitle: 'Total credit tax',

    totalDebitTaxColumnName: 'Total debit tax',
    totalDebitTaxColumnTitle: 'Total debit tax',

    showOnCashBasisReportsColumnName: 'Show journal on cash basis reports',
    showOnCashBasisReportsColumnTitle: 'Show journal on cash basis reports',

    outstandingColumnName: 'Outstanding',
    outstandingColumnTitle: 'Outstanding',

    sentDateColumnName: 'Sent Date',
    sentDateColumnTitle: 'Sent Date',

    yes: 'Yes',
    no: 'No',
});

export const resolutionColumnMessages = defineMessages('pages.Reports.ColumnDefinitions.resolutionColumn', {
    onReview: 'On review',
    onApproval: 'On approval',
    rejected: 'Rejected',
    approved: 'Approved',
    cancelled: 'Cancelled',
    onHold: 'On hold',
});

export const netSuiteDocumentStatusColumnMessages = defineMessages(
    'pages.Reports.ColumnDefinitions.netSuiteDocumentStatusColumn',
    {
        vendorBillUnknown: 'Vendor Bill Unknown',
        vendorBillPendingApproval: 'Vendor Bill Pending Approval',
        vendorBillRejected: 'Vendor Bill Rejected',
        vendorBillOpen: 'Vendor Bill Open',
        vendorBillPaidInFull: 'Vendor Bill Paid In Full',
        vendorBillCancelled: 'Vendor Bill Cancelled',

        purchaseOrderUnknown: 'Purchase Order Unknown',
        purchaseOrderClosed: 'Purchase Order Closed',
        purchaseOrderFullyBilled: 'Purchase Order Fully Billed',
        purchaseOrderPartiallyReceived: 'Purchase Order Partially Received',
        purchaseOrderPendingBillingPartiallyReceived: 'Purchase Order Pending Billing Partially Received',
        purchaseOrderPendingBilling: 'Purchase Order Pending Billing',
        purchaseOrderPendingReceipt: 'Purchase Order Pending Receipt',
        purchaseOrderPendingSupervisorApproval: 'Purchase Order Pending Supervisor Approval',
        purchaseOrderRejectedBySupervisor: 'Purchase Order Rejected By Supervisor',

        salesOrderUnknown: 'Sales Order Unknown',
        salesOrderPendingApproval: 'Sales Order Pending Approval',
        salesOrderPendingFulfillment: 'Sales Order Pending Fulfillment',
        salesOrderPartiallyFulfilled: 'Sales Order Partially Fulfilled',
        salesOrderPendingBilling: 'Sales Order Pending Billing',
        salesOrderPendingBillingPartFulfilled: 'Sales Order Pending Billing Part Fulfilled',
        salesOrderClosed: 'Sales Order Closed',
        salesOrderBilled: 'Sales Order Billed',
        salesOrderCancelled: 'Sales Order Cancelled',
        salesOrderUndefined: 'Sales Order Undefined',

        vraPendingReturn: 'VRA Pending Return',
        vraCancelled: 'VRA Cancelled',
        vraClosed: 'VRA Closed',
        vraCredited: 'VRA Credited',
        vraPartiallyReturned: 'VRA Partially Returned',
        vraPendingApproval: 'VRA Pending Approval',
        vraPendingCredit: 'VRA Pending Credit',
        vraPendingCreditPartiallyReturned: 'VRA Pending Credit Partially Returned',
        vraUndefined: 'VRA Undefined',

        journalEntryPendingApproval: 'Journal Entry Pending Approval',
        journalEntryRejected: 'Journal Entry Rejected',
        journalEntryVoided: 'Journal Entry Voided',
        journalEntryApprovedForPosting: 'Journal Entry Approved For Posting',
        journalEntryUndefined: 'Journal Entry Undefined',

        invoiceUndefined: 'Invoice Undefined',
        invoiceOpen: 'Invoice Open',
        invoicePaidInFull: 'Invoice Paid In Full',
        invoicePendingApproval: 'Invoice Pending Approval',
        invoiceVoided: 'Invoice Voided',
        invoiceRejected: 'Invoice Rejected',

        expenseReportUnknown: 'Expense Report Unknown',
        expenseReportApprovedByAccounting: 'Expense Report Approved By Accounting',
        expenseReportApprovedOverriddenByAccounting: 'Expense Report Approved Overridden By Accounting',
        expenseReportInProgress: 'Expense Report In Progress',
        expenseReportInTransit: 'Expense Report In Transit',
        expenseReportPaidInFull: 'Expense Report Paid In Full',
        expenseReportPendingAccountingApproval: 'Expense Report Pending Accounting Approval',
        expenseReportPendingSupervisorApproval: 'Expense Report Pending Supervisor Approval',
        expenseReportRejectedByAccounting: 'Expense Report Rejected By Accounting',
        expenseReportRejectedBySupervisor: 'Expense Report Rejected By Supervisor',
        expenseReportRejectedOverriddenByAccounting: 'Expense Report Rejected Overridden By Accounting',
        expenseReportVoided: 'Expense Report Voided',

        billPaymentUnknown: 'Bill Payment Unknown',
        billPaymentApproved: 'Bill Payment Approved',
        billPaymentConfirmed: 'Bill Payment Confirmed',
        billPaymentDeclined: 'Bill Payment Declined',
        billPaymentInTransit: 'Bill Payment In Transit',
        billPaymentOnlineBillPayPendingAccountingApproval: 'Bill Payment Online Bill Pay Pending Accounting Approval',
        billPaymentPendingApproval: 'Bill Payment Pending Approval',
        billPaymentRejected: 'Bill Payment Rejected',
        billPaymentVoided: 'Bill Payment Voided',
    }
);

export const fraudulentActivityColumnMessages = defineMessages(
    'pages.Reports.ColumnDefinitions.fraudulentActivityColumn',
    {
        None: 'No fraud detected',
        AdminEnforcedResolving: 'Forced by Administrator',
        AutomaticResolving: 'Autoapproved',
        DroppingOffApprovers: 'Some approvers were removed',
        ExternalResolving: 'Approved directly in {integrationName}',
        PostApprovalChange: 'Changes made in {integrationName} after approval',
        ExternalResolvingNetSuite: 'Resolved directly in NetSuite',
    }
);

export const outstandingColumnMessages = defineMessages('pages.Reports.ColumnDefinitions.outstandingColumn', {
    daysOverdue: '{days} days overdue',
    daysUntilDue: '{days} days until due',
});

export const taxTypeColumnMessages = defineMessages('pages.Reports.ColumnDefinitions.taxTypeColumn', {
    taxExclusive: 'Tax Exclusive',
    taxInclusive: 'Tax Inclusive',
    noTax: 'No Tax',
});

export const auditReportStatusColumnMessages = defineMessages(
    'pages.Reports.ColumnDefinitions.auditReportStatusColumn',
    {
        auditReportStatusColumnValueTextReady: 'Ready',
        auditReportStatusColumnValueTextUnderway: 'Being prepared',
    }
);

export const lineItemCustomersColumnMessages = defineMessages(
    'pages.Reports.ColumnDefinitions.lineItemCustomersColumn',
    {
        columnName: 'Customers',
        columnTitle: 'Customers used in line items (at least one line item should match)',
    }
);

export const matchedPOColumnMessages = defineMessages('pages.Reports.ColumnDefinitions.matchedPOColumn', {
    matched: 'Matched',
    notMatched: 'Not matched',
});

export const matchedBillsColumnMessages = defineMessages('pages.Reports.ColumnDefinitions.matchedBillsColumn', {
    matched: 'Matched',
    notMatched: 'Not matched',
});

export const retrospectivePOMessages = defineMessages('pages.Reports.ColumnDefinitions.retrospectivePO', {
    retrospective: 'Is retrospective',
    notRetrospective: 'Is not retrospective',
});

export const qboPOStatusMessages = defineMessages('pages.Reports.ColumnDefinitions.qboPOStatus', {
    qboPurchaseOrderStatusOptionOpen: 'Open',
    qboPurchaseOrderStatusOptionClosed: 'Closed',
});

export const netSuiteMessages = defineMessages('pages.Reports.ColumnDefinitions.netSuite', {
    externalStatusUnknown: 'Unknown',
    externalStatusApprovedByAccounting: 'Approved by accounting',
    externalStatusApprovedOverriddenByAccounting: 'Approved overridden by accounting',
    externalStatusInProgress: 'In progress',
    externalStatusInTransit: 'In transit',
    externalStatusPaidInFull: 'Paid in full',
    externalStatusPendingAccountingApproval: 'Pending accounting approval',
    externalStatusPendingSupervisorApproval: 'Pending supervisor approval',
    externalStatusRejectedByAccounting: 'Rejected by accounting',
    externalStatusRejectedBySupervisor: 'Rejected by supervisor',
    externalStatusRejectedOverriddenByAccounting: 'Rejected overridden by accounting',
    externalStatusVoided: 'Voided',
});
