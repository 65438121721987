import { toast, useConfirmation } from '@approvalmax/ui/src/components';
import { useAtom, useAtomValue } from 'jotai';
import { selectors } from 'modules/common';
import { domain } from 'modules/data';
import { useCallback } from 'react';
import { commonErrorMessages } from 'services/error/messages';
import { routingService } from 'services/routing';
import { storageService } from 'services/storage';
import { useGetFido2Credentials } from 'shared/data/webApp/v2';
import { getPath, Path } from 'urlBuilder';

import { useExpandedCompany } from '../../hooks';
import { activeCompanyIdState } from '../../states';
import { useCheckIsModifiedProfileForm } from './hooks/useCheckIsModifiedProfileForm';
import { messages } from './ProfileEditPopup.messages';
import { showProfileEditPopupState } from './ProfileEditPopup.states';

export const useOpenProfileEditPopup = () => {
    const [isPopupOpen, setIsPopupOpen] = useAtom(showProfileEditPopupState);

    const handleOpenPopup = useCallback(() => {
        setIsPopupOpen(true);
    }, [setIsPopupOpen]);

    const handleClosePopup = useCallback(() => {
        setIsPopupOpen(false);
    }, [setIsPopupOpen]);

    return {
        isPopupOpen,
        handleOpenPopup,
        handleClosePopup,
    };
};

export const useProfileEditPopupCloseConfirmation = () => {
    return useConfirmation({
        size: 'small',
        confirmTitle: messages.confirmationTitle,
        confirmMessage: messages.confirmationMessage({ br: <br /> }),
        cancelButtonMessage: messages.confirmationButtonCancel,
        confirmButtonMessage: messages.confirmationButtonConfirm,
    });
};

export const useProfileEditPopup = (profile: selectors.types.ExpandedProfile | null) => {
    const profileEditPopup = useOpenProfileEditPopup();
    const isModified = useCheckIsModifiedProfileForm();

    const companyId = useAtomValue(activeCompanyIdState);
    const company = useExpandedCompany(companyId);

    const { confirmation } = useProfileEditPopupCloseConfirmation();

    const authenticated = storageService.authenticated();

    const { data: fido2Credentials } = useGetFido2Credentials(undefined, { enabled: authenticated });

    const handleClose = useCallback(
        (open: boolean) => {
            if (open) {
                return;
            }

            const twoFaEnforcementType = company?.enforcementTfaType;

            if (isModified) {
                confirmation()
                    .then(profileEditPopup.handleClosePopup)
                    .catch(() => {});
            } else {
                profileEditPopup.handleClosePopup();
            }

            if (
                twoFaEnforcementType === domain.TwoFaEnforcementType.Hard &&
                !profile?.is2faEnabled &&
                !profile?.isSsoLogin &&
                !fido2Credentials?.length
            ) {
                toast.error(commonErrorMessages.twoFAMustBeEnabled);

                setTimeout(() => {
                    routingService.reloadToUrl(getPath(Path.twoFaHardEnforcement));
                }, 1000);
            }
        },
        [
            company?.enforcementTfaType,
            confirmation,
            isModified,
            profile?.is2faEnabled,
            profile?.isSsoLogin,
            profileEditPopup,
            fido2Credentials,
        ]
    );

    return {
        handleClose,
        isOpen: profileEditPopup.isPopupOpen,
    };
};
