import { produce } from 'immer';
import * as company from 'modules/company';
import { domain } from 'modules/data';
import { immutable, ImmutableObject, set, setIn } from 'modules/immutable';
import { integrationActions } from 'modules/integration';

import {
    Action,
    CANCEL_ACTIVE_POPUP,
    COMPLETE_FIRST_START_SETUP,
    COMPLETE_FIRST_START_SETUP_FAILURE,
    COMPLETE_FIRST_START_SETUP_RESPONSE,
    SET_RELOGIN_RESULT,
    SHOW_CREATE_OR_JOIN_POPUP,
    SHOW_FIRST_START_SETUP_POPUP,
    SHOW_NEW_ORGANISATION_POPUP,
    SHOW_RELOGIN_ACCEPT_INVITATION_POPUP,
    SHOW_SIGN_UP_CONSENT_POPUP,
    SIGN_UP_WITH_CODE,
    SIGN_UP_WITH_CODE_FAILURE,
    SIGN_UP_WITH_CODE_RESPONSE,
    SKIP_ORGANISATION_CREATION,
    UPDATE_ACCEPTED_INVITATIONS,
    UPDATE_PRACTICE_ACCEPTED_INVITATIONS,
    UPDATE_PROFILE_DATA,
} from '../actions';

export enum ActivePopup {
    CreateOrJoinPopup = 'CreateOrJoinPopup',
    AccountSetupPopup = 'AccountSetupPopup',
    NewOrganisationPopup = 'NewOrganisationPopup',
    ReloginAcceptInvitation = 'ReloginAcceptInvitation',
}

export interface ProfileData {
    firstName: string;
    phone?: string;
    lastName: string;
    password: string;
}

export interface TrialData {
    startOwnTrial: boolean;
    companyName: string;
    oauthProvider?: domain.OAuthProvider;
}

export interface SsoData {
    oauthProvider: domain.OAuthProvider;
    email: string;
    firstName: string;
    lastName: string;
    signUpCode: string;
    app?: domain.SsoLoginApplication;
    startingPoint?: domain.SsoStartingPoint;
    redirectUrl?: string;
}

/**
 * Defines what flow the account setup wizard follows.
 */
export interface AccountSetupData {
    oauthProvider?: domain.OAuthProvider;
    firstStart?: boolean;
}

export enum ReloginResult {
    Initial = 'Initial',
    StayLoggedIn = 'StayLoggedIn',
    AllowRelogin = 'AllowRelogin',
}

export interface FirstStartModuleData {
    activePopup: ActivePopup | null;
    loading: boolean;
    profileData: ProfileData;
    acceptedInvitationIds: string[];
    selectedPracticeInvitation: string;
    trialData: TrialData;
    accountSetupData?: AccountSetupData;
    newOrganisationData?: {
        firstStart?: boolean;
        fromNoCompanies?: boolean;
    };
    separateCompanyCreation: boolean;
    ssoData?: SsoData;
    reloginResult?: ReloginResult;
}

const INITIAL_STATE = immutable<FirstStartModuleData>({
    activePopup: null,
    loading: false,
    profileData: {
        firstName: '',
        lastName: '',
        password: '',
    },
    acceptedInvitationIds: [],
    selectedPracticeInvitation: '',
    trialData: {
        startOwnTrial: false,
        companyName: '',
    },
    separateCompanyCreation: false,
    reloginResult: ReloginResult.Initial,
});

export default function (
    state: ImmutableObject<FirstStartModuleData> = INITIAL_STATE,
    action: Action | integrationActions.Action | company.actions.Action
): ImmutableObject<FirstStartModuleData> {
    switch (action.type) {
        case SHOW_FIRST_START_SETUP_POPUP: {
            let newState;

            newState = set(
                state,
                'acceptedInvitationIds',
                action.payload.invitations.map((invitation) => invitation.companyId)
            );

            if (action.payload.firstName) {
                newState = setIn(newState, ['profileData', 'firstName'], action.payload.firstName);
            }

            if (action.payload.lastName) {
                newState = setIn(newState, ['profileData', 'lastName'], action.payload.lastName);
            }

            return newState;
        }

        case SHOW_SIGN_UP_CONSENT_POPUP:
            return set(state, 'ssoData', {
                ...action.payload.options,
            });

        case CANCEL_ACTIVE_POPUP:
            return set(state, 'activePopup', null);

        case UPDATE_PROFILE_DATA:
            return set(state, 'profileData', action.payload.profileData);

        case UPDATE_ACCEPTED_INVITATIONS:
            return set(state, 'acceptedInvitationIds', action.payload.acceptedInvitationIds);

        case UPDATE_PRACTICE_ACCEPTED_INVITATIONS:
            return set(state, 'selectedPracticeInvitation', action.payload.accountId);

        case integrationActions.CONNECT_TO_INTEGRATION:
        case COMPLETE_FIRST_START_SETUP:
            if (!state.activePopup) {
                return state;
            }

            return set(state, 'loading', true);

        case COMPLETE_FIRST_START_SETUP_FAILURE:
        case integrationActions.CONNECT_TO_INTEGRATION_FAILURE:
            if (!state.activePopup) {
                return state;
            }

            return set(state, 'loading', false);

        case COMPLETE_FIRST_START_SETUP_RESPONSE:
            return produce(state, (draft) => {
                draft.loading = false;

                const selectedPracticeInvitation = action.payload.request.practiceInvitations.find((invite) => {
                    return invite.id === action.payload.request.selectedPracticeInvitation;
                });

                if (
                    action.payload.request.invitations.length === 0 &&
                    (action.payload.request.practiceInvitations.length === 0 || !selectedPracticeInvitation) &&
                    !action.payload.isMobile
                ) {
                    // only show if it's not a "regular" user, assume by the number of invitations
                    draft.activePopup = ActivePopup.CreateOrJoinPopup;
                } else {
                    draft.activePopup = null;
                }
            });

        case SHOW_CREATE_OR_JOIN_POPUP:
            return produce(state, (draft) => {
                draft.activePopup = ActivePopup.CreateOrJoinPopup;
            });

        case SHOW_NEW_ORGANISATION_POPUP:
            return produce(state, (draft) => {
                draft.activePopup = ActivePopup.NewOrganisationPopup;
                draft.newOrganisationData = {
                    firstStart: action.payload.firstStart,
                    fromNoCompanies: action.payload.fromNoCompanies,
                };
                // TODO: The account setup popup is temporarily disabled.
                /* if (action.payload.hasAccount) {
                    draft.activePopup = ActivePopup.NewOrganisationPopup;
                } else {
                    draft.activePopup = ActivePopup.AccountSetupPopup;
                    draft.accountSetupData = {
                        oauthProvider: action.payload.oauthProvider,
                        firstStart: action.payload.firstStart,
                    };
                } */
            });

        case SKIP_ORGANISATION_CREATION:
            return produce(state, (draft) => {
                draft.activePopup = null;
            });

        case SIGN_UP_WITH_CODE:
            return set(state, 'loading', true);

        case SIGN_UP_WITH_CODE_RESPONSE:
        case SIGN_UP_WITH_CODE_FAILURE:
            return set(state, 'loading', false);

        case company.actions.CREATE_COMPANY:
            return produce(state, (draft) => {
                draft.loading = true;
            });

        case company.actions.CREATE_COMPANY_RESPONSE:
            return produce(state, (draft) => {
                draft.loading = false;
                draft.activePopup = null;
            });

        case company.actions.CREATE_COMPANY_FAILURE:
            return produce(state, (draft) => {
                draft.loading = false;
            });

        case SHOW_RELOGIN_ACCEPT_INVITATION_POPUP:
            return produce(state, (draft) => {
                draft.activePopup = ActivePopup.ReloginAcceptInvitation;
            });

        case SET_RELOGIN_RESULT:
            return set(state, 'reloginResult', action.payload.reloginResult);

        default:
            return state;
    }
}
