import { atom } from 'jotai';
import { z } from 'zod';

import { getSchemaSectionGeneral } from './components/SectionGeneral/SectionGeneral.schema';
import { schemaSectionNotifications } from './components/SectionNotifications/SectionNotifications.schema';
import { schemaUseSession } from './components/SectionSecurity/SectionSecurity.schema';

export const showProfileEditPopupState = atom(false);

export const profileFormDataState = atom<
    Partial<
        z.infer<ReturnType<typeof getSchemaSectionGeneral>> &
            z.infer<typeof schemaSectionNotifications> &
            z.infer<typeof schemaUseSession>
    >
>({});

export const profileFormModifiedState = atom<Record<string, boolean>>({});

export const profileFormInvalidState = atom<Record<string, boolean>>({});

export const profileFormResetState = atom<Record<string, VoidFunction>>({});
