import { Alert, Box, Button, Checkbox, Form, Grid, Popup, Radio, Text } from '@approvalmax/ui/src/components';
import { HelpCircleIcon } from '@approvalmax/ui/src/icons';
import * as Sentry from '@sentry/browser';
import { selectors } from 'modules/common';
import { commitSubscriptionData, SelectSubscriptionOption } from 'modules/company/actions';
import { getApplicableSubscriptions, getPricingOptions } from 'modules/company/selectors/moduleSelectors';
import { backend } from 'modules/data';
import { useDispatch, useSelector } from 'modules/react-redux';
import { memo, useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useCompanyBasedOnUrl } from 'shared/hooks/useCompanyBasedOnUrl';

import {
    confirmWarningUpgradeSubscriptionFieldName,
    optionFieldName,
    subscriptionFieldName,
} from './Content.constants';
import {
    fitlerPredicateAvailableForUpgrade,
    getSubscriptionOptionsAfterUpgrade,
    isAvailableForUpgrade,
} from './Content.helpers';
import { messages } from './Content.messages';
import { FormValues } from './Content.types';

export const Content = memo(() => {
    const dispatch = useDispatch();
    const pricingOptions = useSelector(getPricingOptions);
    const account = useSelector(selectors.profile.getProfileAccount);

    const company = useCompanyBasedOnUrl();

    const applicableSubscriptionsFromStore = useSelector(getApplicableSubscriptions);
    const applicableSubscriptions = useMemo(
        () => applicableSubscriptionsFromStore.filter(fitlerPredicateAvailableForUpgrade),
        [applicableSubscriptionsFromStore]
    );

    const hasApplicableSubscriptions = applicableSubscriptions.length > 0;

    const form = useForm<FormValues>({
        defaultValues: {
            [subscriptionFieldName]: null,
            [optionFieldName]: hasApplicableSubscriptions
                ? SelectSubscriptionOption.ExistingSubscription
                : SelectSubscriptionOption.NewSubscription,
            [confirmWarningUpgradeSubscriptionFieldName]: false,
        },
        resolver: (values) => {
            let isValid = true;

            switch (values.option) {
                case SelectSubscriptionOption.NewSubscription:
                case SelectSubscriptionOption.Trial:
                case SelectSubscriptionOption.ExistingPlan:
                    isValid = true;
                    break;

                case SelectSubscriptionOption.ExistingSubscription: {
                    const selectedSubscription =
                        applicableSubscriptions.find((subscription) => subscription.id === values.subscriptionId) ||
                        null;
                    const needsToSubscriptionUpgrade =
                        !!selectedSubscription && isAvailableForUpgrade(selectedSubscription);

                    const showUpgradeWarningMessage = needsToSubscriptionUpgrade && isPartnerAccount;

                    isValid = showUpgradeWarningMessage
                        ? values.confirmWarningUpgradeSubscription
                        : Boolean(selectedSubscription);

                    break;
                }

                default: {
                    Sentry.captureMessage(`[SelectSubscriptionPopup] unknown option: ${option}`);

                    isValid = true;
                    break;
                }
            }

            if (isValid) {
                return {
                    values,
                    errors: {},
                };
            } else {
                return {
                    values: {},
                    errors: {
                        option: {
                            type: 'required',
                        },
                    },
                };
            }
        },
    });

    const { watch, setValue } = form;

    const selectedSubscriptionId = watch(subscriptionFieldName);
    const option = watch(optionFieldName);

    const isPartnerAccount = account.accountType === backend.AccountType.Partner;

    const selectedSubscription = useMemo(
        () => applicableSubscriptions.find((subscription) => subscription.id === selectedSubscriptionId) || null,
        [applicableSubscriptions, selectedSubscriptionId]
    );

    const needsToSubscriptionUpgrade = useMemo(
        () => !!selectedSubscription && isAvailableForUpgrade(selectedSubscription),
        [selectedSubscription]
    );

    const showUpgradeWarningMessage = needsToSubscriptionUpgrade && isPartnerAccount;

    const subscriptionOptionsAfterUpgrade = useMemo(
        () => getSubscriptionOptionsAfterUpgrade(selectedSubscription, pricingOptions),
        [pricingOptions, selectedSubscription]
    );

    const trialOptionText = isPartnerAccount ? messages.newTrialOptionForPartner : messages.newTrialOptionForEndUser;

    const onSelectSubscription = form.handleSubmit((values) => {
        const selectedSubscription =
            applicableSubscriptions.find((subscription) => subscription.id === values.subscriptionId) || null;
        const needsToSubscriptionUpgrade = !!selectedSubscription && isAvailableForUpgrade(selectedSubscription);
        const subscriptionOptionsAfterUpgrade = getSubscriptionOptionsAfterUpgrade(
            selectedSubscription,
            pricingOptions
        );

        const upgradeSubscriptionData = needsToSubscriptionUpgrade
            ? {
                  subscriptionId: selectedSubscription.id,
                  currency: selectedSubscription.nextBillingCurrency,
                  plan: selectedSubscription.plan,
                  billingCycle: selectedSubscription.billingCycle,
                  qty: subscriptionOptionsAfterUpgrade!.qty,
                  organisationIds: [company.id ?? ''],
              }
            : null;

        dispatch(
            commitSubscriptionData({
                option,
                subscriptionId: selectedSubscription?.id,
                billingSystem: selectedSubscription?.billingSystem,
                upgradeSubscriptionData: upgradeSubscriptionData,
                isPartnerAccount: account.isPartnerAccount,
            })
        );
    });

    const onChangeSubscription = useCallback(() => {
        setValue(optionFieldName, SelectSubscriptionOption.ExistingSubscription);
    }, [setValue]);
    const onChangeOtherSubscription = useCallback(() => {
        setValue(subscriptionFieldName, null);
    }, [setValue]);

    if (!company) {
        return null;
    }

    return (
        <>
            <Popup.Header title={messages.title} />

            <Popup.Body>
                <Form form={form} onSubmit={onSelectSubscription}>
                    <Grid gap={32}>
                        {hasApplicableSubscriptions ? (
                            <>
                                <Text font='body' fontSize='large' fontWeight='medium' color='midnight100'>
                                    {messages.subscriptionsTitle}
                                </Text>

                                <Radio.Group
                                    size='medium'
                                    name={subscriptionFieldName}
                                    block
                                    onChange={onChangeSubscription}
                                    data-qa='company-select-subscription-popup__subscriptions-area'
                                >
                                    {applicableSubscriptions.map((subscription) => (
                                        <Grid gap={16} key={subscription.id}>
                                            <Radio key={subscription.id} value={subscription.id}>
                                                <Text as='span' font='label' fontSize='medium' fontWeight='medium'>
                                                    {selectors.subscription.getSubscriptionDisplayName(
                                                        subscription,
                                                        `AMS-${subscription.systemId}`
                                                    )}
                                                </Text>

                                                <Text as='span' font='label' fontSize='medium'>
                                                    {' | '}
                                                </Text>

                                                <Text as='span' font='label' fontSize='medium' color='midnight70'>
                                                    {messages.subscriptionNameMore({
                                                        freeSlots: `${subscription.capacity}`,
                                                        upgradeText: isAvailableForUpgrade(subscription) ? (
                                                            <i>{messages.upgraded}</i>
                                                        ) : (
                                                            ''
                                                        ),
                                                    })}
                                                </Text>
                                            </Radio>

                                            {showUpgradeWarningMessage &&
                                                subscription.id === selectedSubscriptionId && (
                                                    <Box borderColor='midnight40' radius='small' bordered spacing='16'>
                                                        <Grid gap={8}>
                                                            <Text font='label' fontSize='medium'>
                                                                {messages.upgradePanelMessage({
                                                                    qtyOfSlotsAfterUpgrade: (
                                                                        <b>{subscriptionOptionsAfterUpgrade?.qty}</b>
                                                                    ),
                                                                    br: <br />,
                                                                })}
                                                            </Text>

                                                            <Text font='label' fontSize='medium'>
                                                                {messages.upgradePanelMessageAmount({
                                                                    amountStr: (
                                                                        <b>
                                                                            {`${subscriptionOptionsAfterUpgrade?.price} ${subscriptionOptionsAfterUpgrade?.currency}`}
                                                                        </b>
                                                                    ),
                                                                })}
                                                            </Text>

                                                            <Checkbox name={confirmWarningUpgradeSubscriptionFieldName}>
                                                                {messages.confirmText}
                                                            </Checkbox>
                                                        </Grid>
                                                    </Box>
                                                )}
                                        </Grid>
                                    ))}
                                </Radio.Group>
                            </>
                        ) : (
                            <Alert
                                size='small'
                                color='midnight20'
                                startIcon={<HelpCircleIcon />}
                                data-qa='company-select-subscription-gray-area'
                            >
                                {messages.emptySubscriptions}
                            </Alert>
                        )}

                        <Grid gap={16}>
                            {hasApplicableSubscriptions && (
                                <Text font='label' fontSize='medium' color='midnight70'>
                                    {messages.otherOptions}
                                </Text>
                            )}

                            <Radio.Group
                                size='medium'
                                name={optionFieldName}
                                block
                                onChange={onChangeOtherSubscription}
                            >
                                <Radio
                                    key={SelectSubscriptionOption.NewSubscription}
                                    value={SelectSubscriptionOption.NewSubscription}
                                >
                                    {messages.newSubscriptionOption}
                                </Radio>

                                <Radio key={SelectSubscriptionOption.Trial} value={SelectSubscriptionOption.Trial}>
                                    {trialOptionText}
                                </Radio>
                            </Radio.Group>
                        </Grid>
                    </Grid>

                    <Grid justifyContent='end' gridTemplateColumns='minmax(180px, auto)' padding='40 0 0'>
                        <Button type='submit' color='mint80' size='large' disabled={!form.formState.isValid}>
                            {messages.confirm}
                        </Button>
                    </Grid>
                </Form>
            </Popup.Body>
        </>
    );
});

Content.displayName = 'Content';
