import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages(
    'pages.RequestList.XeroMatchingV2Section.PurchaseOrderView.PurchaseOrderMatchingTable',
    {
        totalAllocatedText: 'Total:',
        itemColumnName: 'Name',
        approvedColumnName: 'Approved',
        matchedByColumnName: 'Matched by',
        allocatedAmountColumnName: 'Allocated amount to this PO',
        totalAmountColumnNameWithGross: 'Total amount (Gross)',
        totalAmountColumnNameWithNet: 'Total amount (Net)',
    }
);
