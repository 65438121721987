import { RequestMutationOptions } from '@approvalmax/data';
import { useMutate } from 'shared/data';

import { budgetsApiPaths } from '../paths';
import { useInvalidateGetXeroBudgets } from '../useGetXeroBudgets/useGetXeroBudgets';
import { SetXeroBudgetCheckPeriod } from './useSetXeroBudgetCheckPeriod.types';

export const useSetXeroBudgetCheckPeriod = (mutationOptions?: RequestMutationOptions<SetXeroBudgetCheckPeriod>) => {
    const invalidateGetXeroBudgets = useInvalidateGetXeroBudgets();

    return useMutate(budgetsApiPaths.setXeroBudgetCheckPeriod, {
        mutationOptions: {
            ...mutationOptions,
            onSettled: async (data, error, variables, context) => {
                await invalidateGetXeroBudgets({ query: { companyId: variables.body?.companyId } });

                mutationOptions?.onSettled?.(data, error, variables, context);
            },
        },
        apiVersion: 'v1',
    });
};
