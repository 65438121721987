import { domain } from 'modules/data';
import { useMemo } from 'react';
import { findDocumentFieldState } from 'shared/helpers';

export const useNetSuitePurchaseOrderFieldSettings = (documentFields: domain.DocumentField[] | undefined) =>
    useMemo(
        () => ({
            billingAddress: findDocumentFieldState(documentFields, 'BillingAddress'),
            class: findDocumentFieldState(documentFields, 'Class'),
            currency: findDocumentFieldState(documentFields, 'Currency'),
            department: findDocumentFieldState(documentFields, 'Department'),
            dueDate: findDocumentFieldState(documentFields, 'DueDate'),
            employee: findDocumentFieldState(documentFields, 'Employee'),
            incoterm: findDocumentFieldState(documentFields, 'Incoterm'),
            location: findDocumentFieldState(documentFields, 'Location'),
            memo: findDocumentFieldState(documentFields, 'Memo'),
            exchangeRate: findDocumentFieldState(documentFields, 'OriginalExchangeRate'),
            reference: findDocumentFieldState(documentFields, 'VendorReference'),
            terms: findDocumentFieldState(documentFields, 'Terms'),
            transactionDate: findDocumentFieldState(documentFields, 'TransactionDate'),
            vatRegistration: findDocumentFieldState(documentFields, 'VatRegistrationNumber'),
            vendor: findDocumentFieldState(documentFields, 'Vendor'),
            shipTo: findDocumentFieldState(documentFields, 'ShipTo'),
            shippingAddress: findDocumentFieldState(documentFields, 'ShippingAddress'),
            shipDate: findDocumentFieldState(documentFields, 'ShipDate'),
            shipMethod: findDocumentFieldState(documentFields, 'ShipMethod'),
            fob: findDocumentFieldState(documentFields, 'FOB'),
            trackingNumbers: findDocumentFieldState(documentFields, 'TrackingNumbers'),
        }),
        [documentFields]
    );
