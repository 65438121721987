import { Button, Flex, Text } from '@approvalmax/ui/src/components';
import { FC, memo } from 'react';
import { UserAvatar, UserCard } from 'shared/components';
import { useGetUserWithProfileInfo } from 'shared/hooks/useGetUserWithProfileInfo';

import { UserItemProps } from './UserItem.types';

export const UserItem: FC<UserItemProps> = memo((props) => {
    const { user } = props;

    const { getUserWithProfileInfo, loading } = useGetUserWithProfileInfo(user);

    return (
        <UserCard getUser={getUserWithProfileInfo} loading={loading}>
            <Button link noPadding noUppercase icon variant='text' color='blue100'>
                <Flex alignItems='center' spacing='12'>
                    <UserAvatar size={40} user={user} />

                    <Text font='label' fontSize='small'>
                        {user.displayName}
                    </Text>
                </Flex>
            </Button>
        </UserCard>
    );
});

UserItem.displayName = 'UserItem';
