import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('modules.FirstStartModule.CreateOrganisationPopup', {
    xeroVideoTitle: 'Create and connect a Xero demo organisation to unlock all the trial features',
    xeroVideoDescription:
        'Check out this video to learn how to create a demo organisation and connect it to ApprovalMax',
    xeroVideoCreateDemo: 'Create a demo organisation in Xero',
    xeroVideoConnectDemo: 'Connect a Demo organisation',

    qbooksVideoTitle: 'Create and connect a QuickBooks test organisation to unlock all the trial features',
    qbooksVideoDescription:
        'Check out this video to learn how to create a test organisation and connect it to ApprovalMax',
    qbooksVideoCreateDemo: 'Create a QuickBooks test organisation',
    qbooksVideoConnectDemo: 'Connect a test organisation',
});
