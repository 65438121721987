import type { RequestMutationOptions } from '@approvalmax/data';
import { useMutate } from 'shared/data/api';

import { paymentServicesApiPaths } from '../paths';
import type { MatchAmaxPayOpenBankingXeroBankAccount } from './useMatchAmaxPayOpenBankingXeroBankAccount.types';

export const useMatchAmaxPayOpenBankingXeroBankAccount = (
    mutationOptions?: RequestMutationOptions<MatchAmaxPayOpenBankingXeroBankAccount>
) =>
    useMutate(paymentServicesApiPaths.matchAmaxPayOpenBankingBankAccountXeroBankAccount, {
        mutationOptions,
        apiVersion: 'v1',
        method: 'post',
    });
