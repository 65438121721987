import { Mods, mods, theme } from '@approvalmax/theme';
import { StyledMods } from '@styled-kit/mods';
import styled, { keyframes } from 'styled-components';

import Button from '../../../Button/Button';
import { Flex } from '../../../Flex/Flex';
import { drawerWidth, openingDuration } from '../../Drawer.constants';
import { headerHeight } from './Header.constants';

const fadeIn = keyframes`
  0% { opacity: 0; transform: translateX(-15px); }
  100% { opacity: 1; transform: translateX(0); }
`;

export const ExpandButton = styled(Button)<StyledMods<Mods<'collapsed'>>>`
    padding: 10px 0;
    opacity: 0;
    animation: ${openingDuration}ms ${fadeIn} ${openingDuration / 6}ms both ease-in-out;
    height: 100%;

    ${mods.collapsed.true`
        opacity: 1;
    `};
`;

export const Inner = styled.div<StyledMods<Mods<'collapsed'>>>`
    width: ${drawerWidth}px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 12px 12px 20px;
    animation: ${fadeIn} ${openingDuration}ms both ease-in-out;

    ${mods.collapsed.true`
        opacity: 0;
    `};
`;

export const Root = styled(Flex)<StyledMods<Mods<'sticky'>>>`
    position: sticky;
    top: 0;
    z-index: 2;
    transition: ${openingDuration}ms ease-in-out;
    background-color: ${theme.color.white100};
    height: ${headerHeight}px;

    ${mods.sticky.true`
        box-shadow: ${theme.shadow.small};
    `};
`;
