import { AddIcon } from '@approvalmax/ui';
import { openDrawerState } from '@approvalmax/ui/src/components';
import { useAtom, useSetAtom } from 'jotai';
import * as firstStart from 'modules/first-start';
import { useDispatch } from 'modules/react-redux';
import { CompanyRequestListFilter } from 'pages/requestList/config';
import { useCallback, useMemo } from 'react';
import bemFactory from 'react-bem-factory';
import { useHistory } from 'react-router-dom';
import { amplitudeService } from 'services/amplitude';
import { activeCompanyIdState } from 'shared/states';
import { getPath, Path } from 'urlBuilder';

import { messages } from './CompanySelector.messages';

const qa = bemFactory.qa('company-dropdown');

export const useCreateCompany = (props: { setSelectOpen: (value: boolean) => void }) => {
    const { setSelectOpen } = props;

    const setIsDrawerOpen = useSetAtom(openDrawerState);
    const dispatch = useDispatch();

    /**
     * Show Popup to add a new company and close Drawer
     */
    const createCompany = useCallback(async () => {
        setSelectOpen(false);
        setIsDrawerOpen(false);

        dispatch(firstStart.loadNewOrganisationSetup({}));

        amplitudeService.sendData('navigation: click on add an organisation button', {
            'action source': 'drawer',
        });
    }, [dispatch, setIsDrawerOpen, setSelectOpen]);

    /**
     * Action item props for Select
     */
    const createCompanyButtonProps = useMemo(
        () => ({
            startIcon: <AddIcon size={16} />,
            name: messages.addNewCompany,
            onClick: createCompany,
            'data-qa': qa('add-new-organisation'),
        }),
        [createCompany]
    );

    return {
        createCompany,
        createCompanyButtonProps,
    };
};

export const useChangeCompany = () => {
    const history = useHistory();
    const [activeCompanyId, setActiveCompanyId] = useAtom(activeCompanyIdState);

    /**
     * Set active company id in jotai state and change route
     */
    const changeCompany = useCallback(
        (id: string) => {
            if (!id) return;

            setActiveCompanyId(id);

            history.push(getPath(Path.requestCompanyList, id, CompanyRequestListFilter.CompanyAll));
        },
        [history, setActiveCompanyId]
    );

    return {
        activeCompanyId,
        changeCompany,
    };
};
