import { PasskeysFilledIcon } from '@approvalmax/ui';
import { Button } from '@approvalmax/ui/src/components';
import moment from 'moment';
import { memo, useCallback } from 'react';
import { getBrowserName } from 'shared/helpers';
import { useCreatePasskey } from 'shared/hooks';

import { messages } from './PasskeySetupButton.messages';
import { PasskeySetupButtonProps } from './PasskeySetupButton.types';

export const PasskeySetupButton = memo<PasskeySetupButtonProps>((props) => {
    const { size, block, outline, noUppercase, onSuccess } = props;

    const { isCreatingPasskey, createPasskey } = useCreatePasskey({
        onSuccess,
    });

    const handlePasskeySetup = useCallback(() => {
        const browserName = getBrowserName();
        const now = moment().format('L HH:mm');

        const passkeyLabel = `${browserName} ${now}`;

        createPasskey(passkeyLabel);
    }, [createPasskey]);

    return (
        <div>
            <Button
                color='midnight100'
                startIcon={<PasskeysFilledIcon />}
                progress={isCreatingPasskey}
                block={block}
                size={size}
                noUppercase={noUppercase}
                variant={outline ? 'outline' : 'filled'}
                onClick={handlePasskeySetup}
            >
                {messages.title}
            </Button>
        </div>
    );
});

PasskeySetupButton.displayName = 'PasskeySetupButton';
