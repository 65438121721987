import { ExtractComponentProp } from '@approvalmax/types';
import { Menu, toast } from '@approvalmax/ui/src/components';
import { useAtomValue } from 'jotai';
import { actions } from 'modules/profile';
import { useDispatch } from 'modules/react-redux';
import { useCallback, useMemo, useState } from 'react';
import { useUpdateProfile } from 'shared/data/webApp/v1';

import { useCheckIsInvalidProfileForm } from '../../hooks/useCheckIsInvalidProfileForm';
import { useCheckIsModifiedProfileForm } from '../../hooks/useCheckIsModifiedProfileForm';
import { useOpenProfileEditPopup } from '../../ProfileEditPopup.hooks';
import { profileFormDataState, profileFormInvalidState } from '../../ProfileEditPopup.states';
import { getSectionGeneralSaveToProfileValues } from '../SectionGeneral/SectionGeneral.helpers';
import { getSectionNotificationsSaveToProfileValues } from '../SectionNotifications/SectionNotifications.helpers';
import { getSectionSecuritySaveToProfileValues } from '../SectionSecurity/SectionSecurity.helpers';
import { tabs, TabsId } from './Content.constants';
import { messages } from './Content.messages';
import { ContentProps } from './Content.types';

export const useProfileEditForm = (props: ContentProps) => {
    const { profile } = props;

    const dispatch = useDispatch();
    const profileEditPopup = useOpenProfileEditPopup();
    const isModified = useCheckIsModifiedProfileForm();
    const isInvalid = useCheckIsInvalidProfileForm();
    const formData = useAtomValue(profileFormDataState);

    const { mutate: updateProfile, isLoading: isLoadingUpdateProfile } = useUpdateProfile({
        onSuccess: (_response, data) => {
            if (data.body) {
                const newProfile: typeof profile = {
                    ...profile,
                    ...getSectionGeneralSaveToProfileValues(profile, data.body),
                    ...getSectionNotificationsSaveToProfileValues(profile, data.body),
                    ...getSectionSecuritySaveToProfileValues(profile, data.body),
                };

                profileEditPopup.handleClosePopup();
                dispatch(actions.applySavedProfileChanges({ newProfile }));
            }

            toast.success(messages.messageProfileSaved);
        },
    });

    const handleClickSave = useCallback(() => {
        if (!isInvalid && isModified) {
            const { email, ...body } = formData;

            updateProfile({ body });
        }
    }, [formData, isInvalid, isModified, updateProfile]);

    return {
        handleClickSave,
        isModified,
        isInvalid,
        isSubmitting: isLoadingUpdateProfile,
    };
};

export const useProfileEditPopupTabs = () => {
    const errors = useAtomValue(profileFormInvalidState);

    const [itemActive, setItemActive] = useState(TabsId.general);

    const handleClick = useCallback(
        (sectionId: TabsId) => {
            let isValid = false;

            switch (itemActive) {
                case TabsId.general:
                    isValid = !errors.general;
                    break;

                case TabsId.notifications:
                    isValid = !errors.notifications;
                    break;

                case TabsId.security:
                    isValid = !errors.security && !errors.session;
                    break;
            }

            if (isValid) {
                setItemActive(sectionId);
            }
        },
        [errors, itemActive]
    );

    const items = useMemo<ExtractComponentProp<typeof Menu, 'items'>>(() => {
        return tabs.map((tab) => ({
            ...tab,
            active: tab.id === itemActive,
            onClick: () => handleClick(tab.id),
        }));
    }, [handleClick, itemActive]);

    return {
        items,
        itemActive,
    };
};
