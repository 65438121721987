import { Button } from '@approvalmax/ui';
import { mathService } from '@approvalmax/utils';
import { selectors } from 'modules/common';
import { State } from 'modules/data';
import { useProgressLoading, useUserProfile } from 'modules/utils';
import { memo, useCallback, useMemo } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useSelector } from 'react-redux';
import { routingService } from 'services/routing';
import { useCreateAmaxPayXeroBatchPayments, useGetBillsBatchPaymentAmounts } from 'shared/data/webApp/v2';
import { useCompanyBetaFeatures } from 'shared/hooks';
import { getPath, Path } from 'urlBuilder';

import { useCanCreatePayment } from './CreatePaymentFromBill.hooks';
import { messages } from './CreatePaymentFromBill.messages';
import { CreatePaymentFromBillProps } from './CreatePaymentFromBill.types';

export const CreatePaymentFromBill = memo<CreatePaymentFromBillProps>((props) => {
    const { request } = props;

    const { companyId } = request;

    const profile = useUserProfile();

    const workflows = useSelector((state: State) =>
        selectors.template.getIntegrationTemplatesByCompanyId(state, companyId)
    );

    const { isBatchPaymentAmountsV2 } = useCompanyBetaFeatures(companyId);

    const canCreatePayment = useCanCreatePayment({ request, workflows, userEmail: profile.email });

    const { data: billsBatchPaymentAmounts, isFetching: isFetchingBillsBatchPaymentAmounts } =
        useGetBillsBatchPaymentAmounts(
            {
                path: {
                    companyId,
                    requestId: request.id,
                },
            },
            {
                enabled: isBatchPaymentAmountsV2 && canCreatePayment,
            }
        );

    const { mutate, isLoading } = useCreateAmaxPayXeroBatchPayments({
        onSuccess: (response) => {
            const responseRequestId = response.requestId;
            const pathToCreatedBatchPayment = getPath(Path.editRequest, responseRequestId, request.companyId, {
                showBankAccountsPopup: true,
            });

            routingService.push(pathToCreatedBatchPayment);
        },
    });

    useProgressLoading(isLoading);

    const handleCreateAmaxPayBatchPaymentFromBill = useCallback(() => {
        mutate({
            params: {
                path: {
                    companyId: request.companyId,
                    requestId: request.id,
                },
            },
        });
    }, [mutate, request.companyId, request.id]);

    const hasAmountToPay = useMemo(() => {
        if (!isBatchPaymentAmountsV2) {
            return true;
        }

        if (!billsBatchPaymentAmounts) {
            return false;
        }

        let amountToPay = request.details.amountDue ?? 0;

        amountToPay = mathService.subtract(amountToPay, billsBatchPaymentAmounts.amountAwaitingPayment);
        amountToPay = mathService.subtract(amountToPay, billsBatchPaymentAmounts.amountInProcessing);
        amountToPay = mathService.subtract(amountToPay, billsBatchPaymentAmounts.amountOnApproval);
        amountToPay = mathService.subtract(amountToPay, billsBatchPaymentAmounts.amountOnReview);

        return amountToPay > 0;
    }, [request.details.amountDue, billsBatchPaymentAmounts, isBatchPaymentAmountsV2]);

    if (isFetchingBillsBatchPaymentAmounts) {
        return <Skeleton width={224} height={36} />;
    }

    if (!canCreatePayment || !hasAmountToPay) {
        return null;
    }

    return (
        <Button execute={handleCreateAmaxPayBatchPaymentFromBill} title={messages.createPayment} disabled={isLoading}>
            {messages.createPayment}
        </Button>
    );
});

CreatePaymentFromBill.displayName = 'CreatePaymentFromBill';
