import { FC, memo } from 'react';
import { CalendarProps } from 'react-calendar';

import { ChevronLeftIcon, ChevronRightIcon, DoubleChevronLeftIcon, DoubleChevronRightIcon } from '../../icons';
import { iconSize } from './Calendar.constants';
import { useKeyboardNavigation } from './Calendar.hooks';
import { Root } from './Calendar.styles';

/**
 * Calendar component based on the react-calendar: https://github.com/wojtekmaj/react-calendar
 */
export const Calendar: FC<CalendarProps> = memo((props) => {
    const { calendarRef } = useKeyboardNavigation();

    return (
        <Root
            {...props}
            prevLabel={<ChevronLeftIcon size={iconSize} />}
            prev2Label={<DoubleChevronLeftIcon size={iconSize} />}
            nextLabel={<ChevronRightIcon size={iconSize} />}
            next2Label={<DoubleChevronRightIcon size={iconSize} />}
            inputRef={calendarRef}
        />
    );
});

Calendar.displayName = 'Calendar';
