import { DotsFilledIcon } from '@approvalmax/ui';
import { Button, Dropdown, Menu, Popup } from '@approvalmax/ui/src/components';
import { domain } from 'modules/data';
import { reloadRequest } from 'pages/requestList/actions';
import { memo, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useToggle } from 'react-use';

import { CancelPopupContent } from '../CancelPopupContent/CancelPopupContent';
import { TransactionDetailsPopupContent } from '../TransactionDetailsPopupContent/TransactionDetailsPopupContent';
import { messages } from './MenuCell.messages';
import { MenuCellProps } from './MenuCell.types';

export const MenuCell = memo<MenuCellProps>((props) => {
    const { companyId, requestId, billRequestIdList, paymentStatus, bankAccountData, amount, currency, isPayer } =
        props;

    const [isDropdownOpened, toggleIsDropdownOpened] = useToggle(false);

    const [isCancelPopupOpened, toggleIsCancelPopupOpened] = useToggle(false);

    const [isTransactionDetailsOpened, toggleIsTransactionDetailsOpened] = useToggle(false);

    const dispatch = useDispatch();

    const handleBillCancel = useCallback(() => {
        toggleIsCancelPopupOpened(false);

        dispatch(reloadRequest(requestId, companyId));
    }, [toggleIsCancelPopupOpened, requestId, companyId, dispatch]);

    const canCancel =
        isPayer &&
        (paymentStatus === domain.AmaxPayPaymentStatus.ReadyToPay ||
            paymentStatus === domain.AmaxPayPaymentStatus.Failed);

    return (
        <>
            <Dropdown
                open={isDropdownOpened}
                onOpen={toggleIsDropdownOpened}
                closeOnClickInnerButton
                activator={
                    <Button noPadding icon variant='text' color='blue100'>
                        <DotsFilledIcon />
                    </Button>
                }
                width='max-content'
            >
                <Menu>
                    {canCancel && <Menu.Item name={messages.cancel} onClick={toggleIsCancelPopupOpened} />}

                    <Menu.Item name={messages.transactionDetails} onClick={toggleIsTransactionDetailsOpened} />
                </Menu>
            </Dropdown>

            <Popup
                open={isCancelPopupOpened}
                onToggle={toggleIsCancelPopupOpened}
                preventAutoClose
                size='xsmall'
                zIndex={900}
            >
                <CancelPopupContent
                    companyId={companyId}
                    requestId={requestId}
                    billRequestIdList={billRequestIdList}
                    onBillCancel={handleBillCancel}
                />
            </Popup>

            <Popup open={isTransactionDetailsOpened} onToggle={toggleIsTransactionDetailsOpened} size='large'>
                <TransactionDetailsPopupContent
                    companyId={companyId}
                    requestId={requestId}
                    billRequestId={billRequestIdList[0]} // It's okay to pass any bill ID involved in the transaction
                    bankAccountData={bankAccountData}
                    amount={amount}
                    currency={currency}
                />
            </Popup>
        </>
    );
});

MenuCell.displayName = 'MenuCell';
