import { Reference } from '@approvalmax/types';
import { TextButton } from '@approvalmax/ui';
import { errorHelpers, intl } from '@approvalmax/utils';
import { domain } from 'modules/data';
import { ConfirmationFullIcon, FailedListIcon, ToastRejectedIcon } from 'modules/sprites';
import { font } from 'modules/styles';
import styled from 'styled-components';

import { ColumnDefinition } from '../../../selectors/types/ColumnDefinition';
import { messages } from './XeroBudgetingSection.messages';
import { BudgetImpactsLineItem, TrackingCategory } from './XeroBudgetingSection.types';

const OpenBudgetButton = styled(TextButton)`
    ${font(12, '#477753', 'bold')}
    text-decoration: underline;
`;

const getStatusIcon = (status: domain.BudgetCheckStatus) => {
    let icon;

    switch (status) {
        case domain.BudgetCheckStatus.Ok:
            icon = <ConfirmationFullIcon css='margin-left: 8px' width={20} height={20} />;
            break;

        case domain.BudgetCheckStatus.Warning:
            icon = <ToastRejectedIcon css='margin-left: 8px' width={20} height={20} />;
            break;

        case domain.BudgetCheckStatus.Overbudget:
            icon = <FailedListIcon css='margin-left: 8px' width={20} height={20} />;
            break;

        default:
            throw errorHelpers.assertNever(status);
    }

    return icon;
};

const getOptionName = (trackingCategories: TrackingCategory[], categoryId: string): string | null => {
    const trackingCategory = trackingCategories.find((t) => t.category.id === categoryId);

    return trackingCategory?.value.text || null;
};

export const getColumnDefinitions = (
    allCategories: Reference[],
    currency: string,
    openBudgetImpactDetailsPopup: (details: BudgetImpactsLineItem) => void
): ColumnDefinition<BudgetImpactsLineItem>[] => {
    const trackingCategoriesColumnDefinitions = allCategories.map((category: Reference) => ({
        id: category.id,
        name: category.text,
        value: (li: BudgetImpactsLineItem) => getOptionName(li.trackingCategories, category.id),
    }));

    return [
        {
            id: 'budgetName',
            name: messages.budgetName,
            value: (li) => li.budgetName,
            cell: (column, li: BudgetImpactsLineItem) => (
                <OpenBudgetButton execute={() => openBudgetImpactDetailsPopup(li)} upperCased={false}>
                    {li.budgetName}
                </OpenBudgetButton>
            ),
        },

        ...trackingCategoriesColumnDefinitions,

        {
            id: 'account',
            name: messages.account,
            value: (li) => li.account,
        },
        {
            id: 'budgetPeriod',
            name: messages.budgetPeriod,
            value: (li) => li.budgetPeriod,
        },
        {
            id: 'remainingBudget',
            name: messages.remainingBudget({ currency }),
            value: (li) => intl.formatNumber(li.remainingBudget),
            alignRight: true,
        },
        {
            id: 'amount',
            name: messages.amount({ currency }),
            value: (li) => intl.formatNumber(li.totalBudgetImpact),
            alignRight: true,
        },
        {
            id: 'status',
            name: messages.status,
            value: (li) => li.status,
            cell: (column, li) => getStatusIcon(li.status),
        },
    ];
};
