import { RequestParams, RequestQueryOptions } from '@approvalmax/data';
import { useGet, useLazyGet } from 'shared/data';

import { netSuiteApiPaths } from '../paths';
import { GetNetSuiteProjectTasks } from './useGetNetSuiteProjectTasks.types';

export const useGetProjectTasks = (
    params?: RequestParams<GetNetSuiteProjectTasks>,
    queryOptions?: RequestQueryOptions<GetNetSuiteProjectTasks>
) => {
    return useGet(netSuiteApiPaths.projectTasks, {
        params,
        queryOptions,
        apiVersion: 'v2',
    });
};

export const useLazyGetNetSuiteProjectTasks = (queryOptions?: RequestQueryOptions<GetNetSuiteProjectTasks>) => {
    return useLazyGet<GetNetSuiteProjectTasks>(netSuiteApiPaths.projectTasks, {
        queryOptions,
        apiVersion: 'v2',
    });
};
