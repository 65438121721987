import { LoadingSpinner } from '@approvalmax/ui';
import { Box } from '@approvalmax/ui/src/components';
import { FC, memo, useMemo } from 'react';
import bemFactory from 'react-bem-factory';
import { useGetWorkflowVersionDocumentFields } from 'shared/data/webApp/v1';
import { useNetSuiteBillPaymentFieldSettings } from 'shared/hooks';

import FieldsSection from '../../../../../components/FieldsSection';
import ActivitySection from '../../../../card/ActivitySection';
import AddCommentSection from '../../../../card/AddCommentSection/AddCommentSection';
import AttachmentsSection from '../../../../card/AttachmentsSection';
import Toolbar from '../../../../card/Toolbar/Toolbar';
import { WorkflowSection } from '../../../../card/WorkflowSection';
import { getNetSuiteAmountText, isFieldVisible } from '../../NetSuiteRequestCard.helpers';
import { useDisplayCurrency, useGetExchangeInfo, useNetSuiteCustomFields } from '../../NetSuiteRequestCard.hooks';
import {
    CurrencySwitcherContainer,
    FieldsSectionWrap,
    Root,
    StyledField,
    StyledScrollableArea,
} from '../../NetSuiteRequestCard.styles';
import CurrencySwitcher from '../CurrencySwitcher/CurrencySwitcher';
import { NetSuiteApplyList } from '../NetSuiteApplyList/NetSuiteApplyList';
import { NetSuiteCreditList } from '../NetSuiteCreditList/NetSuiteCreditList';
import NetSuiteCustomFields from '../NetSuiteCustomFields/NetSuiteCustomFields';
import NetSuiteRequestFooter from '../NetSuiteRequestFooter/NetSuiteRequestFooter';
import { messages } from './RequestCardBillPayment.messages';
import { RequestCardBillPaymentProps } from './RequestCardBillPayment.types';

const qa = bemFactory.qa('netsuite-bill-fields-section');

export const RequestCardBillPayment: FC<RequestCardBillPaymentProps> = memo((props) => {
    const { request, className } = props;

    const { data: companyCustomFields } = useNetSuiteCustomFields(request.companyId);

    const { data: workflowVersionDocumentFields, isFetching: isFetchingGetWorkflowVersionDocumentFields } =
        useGetWorkflowVersionDocumentFields({
            query: {
                companyId: request.companyId,
                workflowVersionIds: [request.workflowVersionId],
            },
        });

    const { details } = request;

    const fieldSettings = useNetSuiteBillPaymentFieldSettings(workflowVersionDocumentFields?.data);

    const applyList = useMemo(() => {
        return [...details.applyList].sort((a, b) => (a.lineNumber && b.lineNumber ? a.lineNumber - b.lineNumber : 0));
    }, [details.applyList]);

    const creditList = useMemo(() => {
        return [...details.creditList].sort((a, b) => (a.lineNumber && b.lineNumber ? a.lineNumber - b.lineNumber : 0));
    }, [details.creditList]);

    const { displayCurrency, onDisplayCurrencyChange } = useDisplayCurrency();
    const { exchangeRate } = useGetExchangeInfo(request.companyId, request.details.exchangeRate, displayCurrency?.id);

    return (
        <Root className={className}>
            <Toolbar
                request={request}
                showAddress={isFieldVisible(fieldSettings.payeeAddress)}
                amountText={getNetSuiteAmountText(
                    request.amount,
                    exchangeRate,
                    request.currency || '',
                    displayCurrency?.isoCode
                )}
            />

            <StyledScrollableArea scrollResetKey={request.id}>
                {isFetchingGetWorkflowVersionDocumentFields ? (
                    <Box spacing='20'>
                        <LoadingSpinner />
                    </Box>
                ) : (
                    <>
                        <FieldsSectionWrap>
                            {isFieldVisible(fieldSettings.transactionNumber) && (
                                <StyledField
                                    qa={qa('transaction-number')}
                                    title={messages.nsTransactionNumberFieldText}
                                    valueType={FieldsSection.Field.ValueType.String}
                                    value={details.transactionNumber}
                                    hideIfEmpty
                                />
                            )}

                            <StyledField
                                qa={qa('check-number')}
                                title={messages.nsCheckNumberFieldText}
                                valueType={FieldsSection.Field.ValueType.String}
                                value={details.checkNumber}
                                hideIfEmpty
                            />

                            <StyledField
                                qa={qa('transaction-date')}
                                title={messages.nsTransactionDateFieldText}
                                valueType={FieldsSection.Field.ValueType.Date}
                                value={details.transactionDate}
                            />

                            <StyledField
                                qa={qa('ap-account')}
                                title={messages.nsPayablesAccountFieldText}
                                valueType={FieldsSection.Field.ValueType.String}
                                value={details.payablesAccount}
                                hideIfEmpty
                            />

                            <StyledField
                                qa={qa('account')}
                                title={messages.nsAccountFieldText}
                                valueType={FieldsSection.Field.ValueType.String}
                                value={details.account}
                                hideIfEmpty
                            />

                            {isFieldVisible(fieldSettings.department) && (
                                <StyledField
                                    qa={qa('department')}
                                    title={messages.nsDepartmentFieldText}
                                    valueType={FieldsSection.Field.ValueType.String}
                                    value={details.department}
                                    hideIfEmpty
                                />
                            )}

                            {isFieldVisible(fieldSettings.class) && (
                                <StyledField
                                    qa={qa('class')}
                                    title={messages.nsClassFieldText}
                                    valueType={FieldsSection.Field.ValueType.String}
                                    value={details.class}
                                    hideIfEmpty
                                />
                            )}

                            {isFieldVisible(fieldSettings.location) && (
                                <StyledField
                                    qa={qa('location')}
                                    title={messages.nsLocationFieldText}
                                    valueType={FieldsSection.Field.ValueType.String}
                                    value={details.location}
                                    hideIfEmpty
                                />
                            )}

                            {isFieldVisible(fieldSettings.postingPeriod) && (
                                <StyledField
                                    qa={qa('posting-period')}
                                    title={messages.nsPostingPeriodFieldText}
                                    valueType={FieldsSection.Field.ValueType.String}
                                    value={details.postingPeriod}
                                    hideIfEmpty
                                />
                            )}

                            {isFieldVisible(fieldSettings.toBePrinted) && (
                                <StyledField
                                    qa={qa('toBePrinted')}
                                    title={messages.nsToBePrintedFieldText}
                                    valueType={FieldsSection.Field.ValueType.String}
                                    value={details.toBePrinted ? messages.toBePrintedPositiveValue : null}
                                    hideIfEmpty
                                />
                            )}

                            {isFieldVisible(fieldSettings.printVoucher) && (
                                <StyledField
                                    qa={qa('printVoucher')}
                                    title={messages.nsPrintVoucherFieldText}
                                    valueType={FieldsSection.Field.ValueType.String}
                                    value={details.printVoucher ? messages.printVoucherPositiveValue : null}
                                    hideIfEmpty
                                />
                            )}

                            {isFieldVisible(fieldSettings.memo) && (
                                <StyledField
                                    qa={qa('memo')}
                                    title={messages.nsMemoFieldText}
                                    valueType={FieldsSection.Field.ValueType.String}
                                    value={details.memo}
                                    hideIfEmpty
                                />
                            )}

                            <NetSuiteCustomFields
                                customFields={details.customFields}
                                companyCustomFields={companyCustomFields?.Fields ?? []}
                            />
                        </FieldsSectionWrap>

                        <NetSuiteApplyList
                            applyList={applyList}
                            companyCustomFields={companyCustomFields?.Fields ?? []}
                            integrationCode={request.integrationCode}
                            documentFields={workflowVersionDocumentFields?.data}
                            exchangeRate={
                                displayCurrency && displayCurrency?.isoCode !== request.currency && exchangeRate !== 1
                                    ? exchangeRate
                                    : null
                            }
                        />

                        <NetSuiteCreditList
                            creditList={creditList}
                            companyCustomFields={companyCustomFields?.Fields ?? []}
                            integrationCode={request.integrationCode}
                            documentFields={workflowVersionDocumentFields?.data}
                            exchangeRate={
                                displayCurrency && displayCurrency?.isoCode !== request.currency && exchangeRate !== 1
                                    ? exchangeRate
                                    : null
                            }
                        />

                        {typeof exchangeRate === 'number' && (
                            <CurrencySwitcherContainer>
                                <CurrencySwitcher
                                    request={request}
                                    selectedCurrency={displayCurrency}
                                    requestCurrency={request.details.currency}
                                    onCurrencyChange={onDisplayCurrencyChange}
                                    companyId={request.companyId}
                                />
                            </CurrencySwitcherContainer>
                        )}
                    </>
                )}

                <NetSuiteRequestFooter
                    request={request}
                    displayCurrency={displayCurrency}
                    exchangeRate={exchangeRate}
                />

                <AttachmentsSection request={request} />

                <WorkflowSection request={request} />

                <ActivitySection request={request} />

                {!request.flags.status.isDraft && <AddCommentSection request={request} />}
            </StyledScrollableArea>
        </Root>
    );
});

RequestCardBillPayment.displayName = 'RequestCardBillPayment';
