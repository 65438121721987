import { stateTree } from 'modules/data';
import { formatDelegateFrom, formatDelegateTo } from 'modules/delegates';
import { asMutable, ImmutableObject, setIn } from 'modules/immutable';
import moment from 'moment';

import { Action, APPLY_MY_DELEGATES_RESPONSE, APPLY_SAVED_PROFILE_CHANGES, CHANGE_TWO_FA_IS_ENABLED } from '../actions';

export default function (
    state: ImmutableObject<stateTree.Entities>,
    action: Action
): ImmutableObject<stateTree.Entities> {
    switch (action.type) {
        case APPLY_MY_DELEGATES_RESPONSE: {
            const { request } = action.payload;

            let newState = state;

            request.delegates.forEach((delegate) => {
                // update company delegates if necessary
                const company = state.companies[delegate.companyId];
                const delegates = asMutable(company.delegates);
                const myDelegationPair = delegates.find((x) => x.userId === request.me.id);

                if (!myDelegationPair && !delegate.delegateId) {
                    // no delegate, no change
                    return;
                }

                if (myDelegationPair && myDelegationPair.delegateUserId === delegate.delegateId) {
                    const isSameDelegateFrom = myDelegationPair.delegateFrom === delegate.delegateFrom;
                    const isSameDelegateTo = myDelegationPair.delegateTo === delegate.delegateTo;

                    if (isSameDelegateFrom && isSameDelegateTo) {
                        // has delegate, no change
                        return;
                    }
                }

                if (myDelegationPair) {
                    delegates.splice(delegates.indexOf(myDelegationPair), 1);
                }

                if (delegate.delegateId) {
                    delegates.push({
                        userId: action.payload.request.me.id,
                        delegateUserId: delegate.delegateId,
                        delegateFrom: delegate.delegateFrom
                            ? formatDelegateFrom(moment(delegate.delegateFrom))
                            : formatDelegateFrom(moment()),
                        delegateTo: delegate.delegateTo ? formatDelegateTo(moment(delegate.delegateTo)) : null,
                    });
                }

                newState = setIn(newState, ['companies', delegate.companyId, 'delegates'], delegates);
            });

            return newState;
        }

        case CHANGE_TWO_FA_IS_ENABLED:
            return setIn(state, ['users', action.payload.me, 'isTFAEnabled'], action.payload.twoFaIsEnabled);

        case APPLY_SAVED_PROFILE_CHANGES: {
            const { newProfile } = action.payload;
            const user = state.users[newProfile.email];

            if (newProfile.firstName !== user.firstName) {
                state = setIn(state, ['users', user.id, 'firstName'], newProfile.firstName);
            }

            if (newProfile.lastName !== user.lastName) {
                state = setIn(state, ['users', user.id, 'lastName'], newProfile.lastName);
            }

            return state;
        }

        default:
            return state;
    }
}
