import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('pages.RequestList.selectors.requestActivitySelectors.Xero', {
    postApprovalChangeDetected: 'Changes have been made in Xero after approval.',
    updatedExternallyResetTemplate: 'This request has been updated in Xero and thus the workflow has been restarted.',
    emailToPartnerSentSupplier: '{templateName} has been sent to the Supplier.',
    emailToPartnerSentCustomer: '{templateName} has been sent to the Customer.',
    approvedInXero: 'Request has been approved directly in Xero.',
    rejectedExternally: 'Request has been rejected by Xero.',
    cancelledInXero: 'Request has been cancelled in Xero.',
    markedAsBilled: 'Marked Purchase Order as billed.',
    markAsBilledSynced: '"Billed" status has been synced to Xero.',
    markAsCustomerDecisionSynced: 'Pushed the customer`s decision to Xero',
    unmarkedAsBilled: 'Unmarked Purchase Order as billed',
    requestMatchedByUserPO: 'Matched the Bills with this Purchase Order on Xero Matching V1.',
    requestMatchedByUserBill: 'Matched this Bill with the Purchase Orders on Xero Matching V1.',
    requestMatchedBySystemPO: 'Automatically matched the Bills with this Purchase Order on Xero Matching V1.',
    requestMatchedBySystemBill: 'Automatically matched this Bill with the Purchase Orders on Xero Matching V1.',
    requestUnmatchedByUserPO: 'Unmatched the Bills from this Purchase Order on Xero Matching V1.',
    requestUnmatchedByUserBill: 'Unmatched this Bill from the Purchase Orders on Xero Matching V1.',
    requestUnmatchedBySystemPO: 'Automatically unmatched the Bills from this Purchase Order on Xero Matching V1.',
    requestUnmatchedBySystemBill: 'Automatically unmatched this Bill from the Purchase Orders on Xero Matching V1.',
    airwallexBatchPaymentRequestPaid: 'Sent the request to payment.',
    changesMatchedDocument: '{name} ({amount})',
    changesUnmatchedDocument: '{name} ({amount})',
    stepChangeAddedApproverPOMatching:
        'Added {approver} as an Approver to the "{stepName}" step due to matching the Bill with a PO where they are requester',
});
