import { Guid, Reference } from '@approvalmax/types';
import { backend, domain } from 'modules/data';

import { EmailToSupplier } from './EmailToSupplier';
import { QBooksTerm } from './QBooksContext';
import { QBooksVendor } from './QBooksVendor';
import { GoodsReceivedNotesStatus, LineAmountType } from './Request';
import { BaseRequest } from './RequestBase';
import { TemplateSettingsLockDatePolicy } from './TemplateSettings';

export interface QBooksBaseLineItem {
    id: Guid;
    description: string;
    amount?: number;
    taxCode?: Reference;
    customer?: Reference;
    class?: Reference;
    isBillable?: boolean;
    isTaxable?: boolean;
    /**
     * @deprecated Use {@link matchedLineItemId}
     */
    isMatched?: boolean;
    /**
     * The id of line item within PO with which the matching was made
     */
    matchedLineItemId?: string | null;
    /**
     * The list of matched bill ids for PO.
     *
     * The field received by PO requests
     */
    matchedLineItemIds?: string[];
    remainingBalance?: number;
    /**
     * This is for manual calculation remaining balance on bill editing. in that situation
     * we don't send any changes to the BE side, so they can't say how balance will change
     */
    remainingBalanceForRequest?: number;
    checked?: boolean;
}

export enum QBooksTaxTypeApplicable {
    TaxOnAmount = 'TaxOnAmount',
    TaxOnAmountPlusTax = 'TaxOnAmountPlusTax',
    TaxOnTax = 'TaxOnTax',
}

export interface QBooksTaxRateListItem {
    taxRateId: string;
    taxOnTaxOrder: number;
    taxApplicable: keyof typeof QBooksTaxTypeApplicable;
}

export interface QBooksTaxCode extends Reference {
    taxRateList: QBooksTaxRateListItem[];
    salesTaxRateList: QBooksTaxRateListItem[];
}

export enum QBooksAccountType {
    Expense = 'Expense',
    OtherExpense = 'OtherExpense',
    OtherAsset = 'OtherAsset',
    OtherCurrentAsset = 'OtherCurrentAsset',
    FixedAsset = 'FixedAsset',
    OtherCurrentLiability = 'OtherCurrentLiability',
    CostofGoodsSold = 'CostofGoodsSold',

    Bank = 'Bank',
    Equity = 'Equity',
    Asset = 'Asset',
    CreditCard = 'CreditCard',
    LongTermLiability = 'LongTermLiability',
    Income = 'Income',
    OtherIncome = 'OtherIncome',

    AccountsReceivable = 'AccountsReceivable',
    AccountsPayable = 'AccountsPayable',
}

export enum QBooksAccountTypeNormalized {
    Expense = 'Expense',
    OtherExpense = 'Other Expense',
    OtherAsset = 'Other Asset',
    OtherCurrentAsset = 'Current Asset',
    FixedAsset = 'Fixed Asset',
    OtherCurrentLiability = 'Other Current Liability',
    CostofGoodsSold = 'Cost of Goods Sold',

    Bank = 'Bank',
    Equity = 'Equity',
    Payable = 'Asset',
    CreditCard = 'Credit Card',
    LongTermLiability = 'Long Term Liability',
    Income = 'Income',
    OtherIncome = 'Other Income',
    // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
    Asset = 'Asset',

    AccountsReceivable = 'Accounts Receivable',
    AccountsPayable = 'Accounts Payable',
}

export interface QBooksAccount extends Reference {
    code?: string;
    taxCode?: QBooksTaxCode;
    parent?: QBooksAccount;
    nestingLevel?: number;
    type?: keyof typeof QBooksAccountType;
    classification?: keyof typeof QBooksAccountType;
    currency?: Reference;
    currencyCode?: Reference['id'];
    fullyQualifiedName?: string;
    parentId?: string;
}

export interface QBooksPayee extends Reference {
    currency?: Reference;
    address?: string[];
    email?: string;
    taxId?: string | null;
}

export interface QBooksPayeeType {
    id: keyof typeof backend.IntegrationsQBooksPayeeTypes | string;
    text: string;
}

export interface QBooksPaymentMethod extends Reference {}

export interface QBooksPaymentType extends Reference {
    id: keyof typeof backend.IntegrationQBooksPaymentTypeId | string;
}

export interface QBooksAccountLineItem extends QBooksBaseLineItem {
    account?: Reference;
}

export interface QBooksLineItem extends QBooksBaseLineItem {
    item?: Reference;
    qty?: number;
    unitPrice?: number;
    unitPriceGross?: number;
    originalUnitPrice?: number;
    originalUnitPriceGross?: number;
    isUnitPriceChanged?: boolean;
    isInventory?: boolean;
    defaultRate?: number | null;
    serviceDate?: string | null;
    discount?: number | null;
    discountAmount?: number | null;
    account?: Reference;
}

export interface QBooksTaxComponent {
    taxRateId: string;
    taxPercent: number;
    taxAmount: number;
    taxableAmount: number;
    hidden: boolean;
}

export interface QBooksTaxSummary {
    totalTax: number;
    lineAmountType: LineAmountType;
    taxComponents: QBooksTaxComponent[];
}

export interface QBooksCustomField {
    field: Reference;
    value: string;
}

export interface QBooksSharedDetails {
    accountLineItems: QBooksAccountLineItem[];
    date: string;
    department: Reference | null;
    departmentTerminology: string | null;
    editUrl: string | null;
    isStatusPushPending: boolean;
    lineAmountType: LineAmountType;
    lineItems: QBooksLineItem[];
    lockDate: string | null;
    lockDatePolicy: keyof typeof backend.AccountingTemplatesLockDatePolicyType;
    mailingAddress: string;
    memo: string;
    number?: string;
    subTotal: number;
    taxComponents: QBooksTaxComponent[];
    totalTax: number;
    url: string | null;
    vendor: domain.QBooksContact | null;
}

export enum QBooksDocumentStatus {
    Open = 'Open',
    Closed = 'Closed',
    None = 'None',
}

export enum QBooksPurchaseOrderStatus {
    None = 'None',
    Open = 'Open',
    Closed = 'Closed',
}

export enum QBooksPurchaseOrderStatusNumeric {
    Open = 1,
    Closed = 2,
}

export interface QBooksPurchaseOrderDetails extends QBooksSharedDetails {
    integrationCode: domain.IntegrationCode.QBooksPo;
    integrationType: domain.IntegrationType.QBooks;
    vendorMessage: string;
    customFields: QBooksCustomField[];
    shipping: {
        customer?: Reference;
        method: string;
        address: string;
    };
    sendToSupplier: boolean;
    emailToSupplier: EmailToSupplier | null;
    status: keyof typeof QBooksPurchaseOrderStatus | null;
    grnStatus: keyof typeof GoodsReceivedNotesStatus | null;
}

type QBooksSalesInvoiceSharedDetails = Omit<QBooksSharedDetails, 'mailingAddress' | 'vendor' | 'accountLineItems'>;

export interface QBooksSalesInvoiceDetails extends QBooksSalesInvoiceSharedDetails {
    applyTaxAfterDiscount: boolean;
    balance?: number;
    billingAddress: string;
    customFields: QBooksCustomField[];
    customer: domain.QBooksContact | null;
    customerMemo: string;
    deposit?: number;
    discount?: number;
    discountType: keyof typeof QBooksDiscountType;
    dueDate: string;
    emailToContact: domain.EmailToSupplier;
    integrationCode: domain.IntegrationCode.QBooksInvoice;
    integrationType: domain.IntegrationType.QBooks;
    isPaid: boolean;
    shipping: QBooksSalesInvoiceShipping;
    shippingTaxAmount?: number;
    shippingTaxCode: Reference | null;
    status: keyof typeof QBooksDocumentStatus;
    taxCode: Reference | null;
    term: QBooksTerm | null;
    useAutoTaxes: boolean;
}

export interface QBooksSalesInvoiceShipping {
    method: string;
    address: string;
    date: string;
    price?: number;
    priceGross?: number;
    fromAddress: string;
    trackingNumber: string;
}

export interface QBooksVendorDetails extends QBooksVendor {
    integrationCode: domain.IntegrationCode.QBooksVendor;
    integrationType: domain.IntegrationType.QBooks;
}

export interface QBooksBillDetails extends QBooksSharedDetails {
    integrationCode: domain.IntegrationCode.QBooksBill;
    integrationType: domain.IntegrationType.QBooks;
    dueDate: string;
    term: QBooksTerm | null;
    isPaid: boolean;
}

export interface QBooksExpenseDetails extends QBooksSharedDetails {
    integrationCode: domain.IntegrationCode.QBooksExpense;
    integrationType: domain.IntegrationType.QBooks;
    payee: QBooksPayee | null;
    payeeType: QBooksPayeeType | null;
    paymentType: QBooksPaymentType | null;
    paymentAccount: QBooksAccount | null;
    paymentMethod: QBooksPaymentMethod | null;
    expenseNumber: string;
    newCreatedVendor: QBooksPayee | null;
}

export enum QBooksJournalEntryLineAmountType {
    Debit = 'Debit',
    Credit = 'Credit',
}

export enum TaxApplicableOnType {
    None = 'None',
    Purchases = 'Purchases',
    Sales = 'Sales',
}

export interface QBooksJournalEntryLine {
    id: string;
    account: Reference | null;
    amount: number | null;
    amountType: keyof typeof QBooksJournalEntryLineAmountType | null;
    description: string | null;
    payeeType: QBooksPayeeType | null;
    payee: QBooksPayee | null;
    taxCode: Reference | null;
    taxRateId: string | null;
    taxApplicableOnType: keyof typeof TaxApplicableOnType | null;
    class: Reference | null;
    department: Reference | null;
}

export interface QBooksJournalEntryTaxComponent extends QBooksTaxComponent {
    taxApplicableOnType: keyof typeof TaxApplicableOnType | null;
    taxCodeId: string;
    taxCodeName: string;
    taxDebitAmount: number;
    taxCreditAmount: number;
}

export interface QBooksJournalEntryDetails {
    integrationCode: domain.IntegrationCode.QBooksJournalEntry;
    integrationType: domain.IntegrationType.QBooks;
    date: string | null;
    number: string | null;
    memo: string | null;
    lineAmountType: LineAmountType | null;
    lines: QBooksJournalEntryLine[];
    debitSubTotal: number;
    creditSubTotal: number;
    debitTotalTax: number;
    creditTotalTax: number;
    taxComponents: QBooksJournalEntryTaxComponent[];
    url: string | null;
    editUrl: string | null;
    isStatusPushPending: boolean;
    lockDate: string | null;
    lockDatePolicy: keyof typeof TemplateSettingsLockDatePolicy;
}

export type QBooksRequestDetails =
    | QBooksPurchaseOrderDetails
    | QBooksSalesInvoiceDetails
    | QBooksBillDetails
    | QBooksExpenseDetails
    | QBooksVendorDetails
    | QBooksJournalEntryDetails;

export type QBooksBillRequestSpecifics = {
    integrationType: domain.IntegrationType.QBooks;
    integrationCode: domain.IntegrationCode.QBooksBill;
    details: QBooksBillDetails;
    ocrAttachment?: Guid;
};

export type QBooksBillRequest = BaseRequest & QBooksBillRequestSpecifics;

export type QBooksExpenseRequestSpecifics = {
    integrationType: domain.IntegrationType.QBooks;
    integrationCode: domain.IntegrationCode.QBooksExpense;
    details: QBooksExpenseDetails;
};

export type QBooksExpenseRequest = BaseRequest & QBooksExpenseRequestSpecifics;

export type QBooksJournalEntryRequestSpecifics = {
    integrationType: domain.IntegrationType.QBooks;
    integrationCode: domain.IntegrationCode.QBooksJournalEntry;
    details: QBooksJournalEntryDetails;
};

export type QBooksJournalEntryRequest = BaseRequest & QBooksJournalEntryRequestSpecifics;

export type QBooksPoRequestSpecifics = {
    integrationType: domain.IntegrationType.QBooks;
    integrationCode: domain.IntegrationCode.QBooksPo;
    details: QBooksPurchaseOrderDetails;
};

export type QBooksPoRequest = BaseRequest & QBooksPoRequestSpecifics;

export type QBooksInvoiceRequestSpecifics = {
    integrationType: domain.IntegrationType.QBooks;
    integrationCode: domain.IntegrationCode.QBooksInvoice;
    details: QBooksSalesInvoiceDetails;
};

export type QBooksInvoiceRequest = BaseRequest & QBooksInvoiceRequestSpecifics;

export type QBooksVendorRequestSpecifics = {
    integrationType: domain.IntegrationType.QBooks;
    details: QBooksVendorDetails;
    integrationCode: domain.IntegrationCode.QBooksVendor;
};

export enum QBooksDiscountType {
    Amount = 'Amount',
    Percent = 'Percent',
}

export type QBooksVendorRequest = BaseRequest & QBooksVendorRequestSpecifics;

export type QBooksRequestSpecifics =
    | QBooksPoRequestSpecifics
    | QBooksInvoiceRequestSpecifics
    | QBooksJournalEntryRequestSpecifics
    | QBooksExpenseRequestSpecifics
    | QBooksBillRequestSpecifics
    | QBooksVendorRequestSpecifics;

export type QBooksRequest =
    | QBooksPoRequest
    | QBooksInvoiceRequest
    | QBooksJournalEntryRequest
    | QBooksExpenseRequest
    | QBooksBillRequest
    | QBooksVendorRequest;
