import { DomainEntitiesExchangeRateSource } from 'shared/data/webApp/v2';

import { messages } from './exchangeRate.messages';

export function getExchangeRateSourceName(exchangeRateSource: keyof typeof DomainEntitiesExchangeRateSource | null) {
    if (!DomainEntitiesExchangeRateSource) {
        return null;
    }

    switch (exchangeRateSource) {
        case DomainEntitiesExchangeRateSource.Xero:
            return messages.sourceXero;

        case DomainEntitiesExchangeRateSource.QBooks:
            return messages.sourceQBooks;

        case DomainEntitiesExchangeRateSource.NetSuite:
            return messages.sourceNetSuite;

        case DomainEntitiesExchangeRateSource.OpenExchangeRate:
            return messages.sourceOpenExchangeRate;

        case DomainEntitiesExchangeRateSource.Fixer:
            return messages.sourceFixer;

        case DomainEntitiesExchangeRateSource.CoinLayer:
            return messages.sourceCoinLayer;

        case DomainEntitiesExchangeRateSource.Bills:
            return messages.sourceBills;

        case DomainEntitiesExchangeRateSource.Manual:
            return messages.sourceManual;

        case DomainEntitiesExchangeRateSource.None:
            return null;

        default:
            return null;
    }
}
