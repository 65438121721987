import { RequestParams, RequestQueryOptions, useInvalidateQuery } from '@approvalmax/data';
import { useGet } from 'shared/data';

import { requestsApiPaths } from '../paths';
import { GetAmaxPayXeroRequestDetails } from './useGetAmaxPayXeroRequestDetails.types';

export const useGetAmaxPayXeroRequestDetails = (
    params: RequestParams<GetAmaxPayXeroRequestDetails>,
    queryOptions?: RequestQueryOptions<GetAmaxPayXeroRequestDetails>
) => {
    return useGet(requestsApiPaths.amaxPayXeroRequestDetails, {
        params,
        queryOptions: {
            staleTime: 60000,
            ...queryOptions,
        },
        method: 'get',
        apiVersion: 'v1',
    });
};

export const useInvalidateGetAmaxPayXeroRequestDetails = () => {
    return useInvalidateQuery<RequestParams<GetAmaxPayXeroRequestDetails> | void>(
        requestsApiPaths.amaxPayXeroRequestDetails
    );
};
