import { Guid, PascalCaseToCamelCase } from '@approvalmax/types';
import { GetRequestVersions } from 'shared/data/webApp/v2';

import { ParticipantRole } from '../backend';
import { backend, domain } from '../index';
import { DearRequest, DearRequestDetails, DearRequestSpecifics } from './Request.Dear';
import { NetSuiteRequest, NetSuiteRequestDetails, NetSuiteRequestSpecifics } from './Request.NetSuite';
import { QBooksRequest, QBooksRequestDetails, QBooksRequestSpecifics } from './Request.QBooks';
import { StandaloneRequest, StandaloneRequestDetails, StandaloneRequestSpecifics } from './Request.Standalone';
import { XeroRequest, XeroRequestDetails, XeroRequestSpecifics } from './Request.Xero';

export * from './Request.Dear';
export * from './Request.NetSuite';
export * from './Request.QBooks';
export * from './Request.Standalone';
export * from './Request.Xero';
export * from './RequestBase';
// TODO: this is a quick solution because BE unexpectedly've introduced new `DomainEntitiesGrnStatus` which is the
//  duplication of our old `GoodsReceivedNotesStatus` and here we've decided not to rename all places, but rename here
//  if someone will find powers, they can rename all places and remove this reexport from here
export { DomainEntitiesGrnStatus as GoodsReceivedNotesStatus } from 'shared/data/webApp/v2';

export enum RequestAuditReportStatus {
    Unknown = 'Unknown',
    Error = 'Error',
    ConnectionError = 'ConnectionError',
    Pending = 'Pending',
    Ready = 'Ready',
}

export interface PossibleDuplicateItem {
    friendlyName: string;
    requestId: Guid;
}

export enum DeadlineRuleType {
    onSubmission = 'on-request-submission',
    onApproval = 'on-request-step-change',
    onStartOfReview = 'on-start-of-review',
}

export interface DeadlineRule {
    calculator: DeadlineRuleType | null;
    duration: string;
}

export enum StepType {
    Approval = 'Approval',
    Review = 'Review',
}

export type PossibleDuplicates = PossibleDuplicateItem[];

export enum LineAmountType {
    TaxExclusive = 'Tax Exclusive',
    TaxInclusive = 'Tax Inclusive',
    NoTax = 'No Tax',
}

export type Request = StandaloneRequest | XeroRequest | QBooksRequest | NetSuiteRequest | DearRequest;

export type RequestSpecifics =
    | StandaloneRequestSpecifics
    | XeroRequestSpecifics
    | QBooksRequestSpecifics
    | NetSuiteRequestSpecifics
    | DearRequestSpecifics;

export type RequestDetails =
    | StandaloneRequestDetails
    | XeroRequestDetails
    | QBooksRequestDetails
    | NetSuiteRequestDetails
    | DearRequestDetails;

export enum SupplierEmailReplacements {
    companyName = 'COMPANYNAME',
    contactPersonName = 'CONTACTPERSONNAME',
    customerName = 'CUSTOMERNAME',
    decisionMakingWebPageLink = 'DECISIONMAKINGWEBPAGELINK',
    invoiceNumber = 'INVOICENUMBER',
    poNumber = 'PONUMBER',
    quoteNumber = 'QUOTENUMBER',
    supplierName = 'SUPPLIERNAME',
    terms = 'TERMS',
    totalAmount = 'TOTALAMOUNT',
}

export interface RequestCurrentBalance {
    currencyIsoCode: string;
    availableAmount: number;
    toBePaidAmount: number;
}

export interface ParticipantStep {
    stepNumber: number;
    stepName: string;
    role: ParticipantRole;
    isReplacementRequired: boolean;
}

export interface RequestsParticipant {
    requestId: string;
    name: string;
    amount: string;
    currency: string;
    version: string;
    hasOnlyApprover: boolean;
    replacements: ParticipantStep[];
}

export type RequestVersionExtended = GetRequestVersions['response']['items'][number] & {
    localVersionNumber?: number;
};

export interface RequestVersionSnapshot {
    amount: number;
    attachments?: PascalCaseToCamelCase<backend.AttachmentAnswer>[];
    createdAt: string;
    currency: string;
    friendlyName: string;
    modifiedAt: string;
    requestId: string;
    requestNote: string;
    version: number;
    emailToContact?: PascalCaseToCamelCase<backend.EmailToContact>;
    xeroInvoiceDetails: PascalCaseToCamelCase<backend.IntegrationsXeroInvoiceAnswer>;
    xeroPurchaseOrderDetails: PascalCaseToCamelCase<backend.IntegrationsXeroPurchaseOrderAnswer>;
    xeroSalesInvoiceDetails: PascalCaseToCamelCase<backend.IntegrationsXeroInvoiceAnswer>;
    xeroCreditNoteDetails: PascalCaseToCamelCase<backend.IntegrationsXeroCreditNoteAnswer>;
    xeroBillBatchPaymentDetails: PascalCaseToCamelCase<backend.IntegrationXeroBillBatchPaymentAnswer>;
    xeroContactDetails: domain.XeroContact;
    airwallexXeroBatchPaymentDetails: backend.IntegrationXeroAirwallexBatchPaymentAnswer;
    xeroQuoteDetails: PascalCaseToCamelCase<backend.IntegrationsXeroQuoteAnswer>;
    xeroManualJournalDetails: PascalCaseToCamelCase<backend.IntegrationsXeroManualJournalAnswer>;
    qBooksPurchaseOrderDetails: PascalCaseToCamelCase<backend.IntegrationsQBooksPurchaseOrderAnswer>;
    qBooksBillInvoiceDetails: PascalCaseToCamelCase<backend.IntegrationsQBooksBillInvoiceAnswer>;
    qBooksExpenseDetails: PascalCaseToCamelCase<backend.IntegrationsQBooksExpenseAnswer>;
    exchangeRate: number | null;
    exchangeRateSource: keyof typeof domain.ExchangeRateSource | null;
}

export enum RequestVersionType {
    RequesterSubmitted = 'RequesterSubmitted',
    SubmittedViaExternalSystem = 'SubmittedViaExternalSystem',
    RequesterResubmitted = 'RequesterResubmitted',
    StartOverByExternalUpdate = 'StartOverByExternalUpdate',
    ReviewerEdited = 'ReviewerEdited',
    EditorEdited = 'EditorEdited',
    ApproverEdited = 'ApproverEdited',
    PostApprovalChangesDetected = 'PostApprovalChangesDetected',
}

export interface Address {
    addressType: backend.AddressType;
    addressLine1?: string | null;
    addressLine2?: string | null;
    addressLine3?: string | null;
    addressLine4?: string | null;
    city?: string | null;
    region?: string | null;
    postalCode?: string | null;
    country?: string | null;
    attentionTo?: string | null;
}

export interface RequestBillingAddress {
    addressLine1: string | null;
    addressLine2: string | null;
    addressLine3: string | null;
    addressLine4: string | null;
    city: string | null;
    region: string | null;
    postalCode: string | null;
    country: string | null;
}

export type Currency = {
    code: string;
    isCrypto: boolean;
    isExpired: boolean;
};
