import './xeroLineItemsSection.scss';

import { Link } from '@approvalmax/ui/src/components';
import { errorHelpers, intl } from '@approvalmax/utils';
import { selectors } from 'modules/common';
import { expandXeroLineItem } from 'modules/common/selectors/requestSelectors.Xero';
import { domain, State } from 'modules/data';
import { XeroCreditNoteAllocation } from 'modules/data/domain';
import { useSelector } from 'modules/react-redux';
import ExchangeRate from 'pages/requestList/components/ExchangeRate/ExchangeRate';
import { FC, memo, useMemo } from 'react';
import bemFactory from 'react-bem-factory';
import { Link as RouterLink } from 'react-router-dom';
import { useCompanyBetaFeatures } from 'shared/hooks';
import { getPath, Path } from 'urlBuilder';

import LineItemFooterSeparator from '../../components/lineItemsSection/LineItemFooterSeparator';
import LineItemFooterTotal from '../../components/lineItemsSection/LineItemFooterTotal';
import { LineItemsTable } from '../../components/lineItemsSection/LineItemsTable';
import OpenInSourceLink from '../../components/OpenInSourceLink/OpenInSourceLink';
import RbExternalUrl from '../../components/rbExternalUrl/RbExternalUrl';
import { getXeroLineItemsTableDef } from '../../selectors/xeroRequestSelectors';
import LineItemsSection from '../card/LineItemsSection';
import { messages } from './XeroLineItemsSection.messages';

const bem = bemFactory.block('reql-xero-line-items-section');
const qa = bemFactory.qa('reql-xero-line-items-section');

interface XeroLineItemsSectionProps {
    className?: string;
    request:
        | selectors.types.XeroBillExpandedRequest
        | selectors.types.XeroPoExpandedRequest
        | selectors.types.XeroInvoiceExpandedRequest
        | selectors.types.XeroCreditNotesPayableExpandedRequest
        | selectors.types.XeroCreditNotesReceivableExpandedRequest
        | selectors.types.XeroQuoteExpandedRequest;
}

const XeroLineItemsSection: FC<XeroLineItemsSectionProps> = (props) => {
    const { className, request } = props;
    const tableDef = useSelector((state: State) => getXeroLineItemsTableDef(state, request));
    const { isXeroCreditNoteAllocations } = useCompanyBetaFeatures(request.companyId);

    const details = request.details;

    const expandedLineItems = request.details.lineItems.map(expandXeroLineItem);

    const hasRemainingCredit =
        request.integrationCode === domain.IntegrationCode.XeroCreditNotesPayable ||
        request.integrationCode === domain.IntegrationCode.XeroCreditNotesReceivable;

    let cisTaxAmount: number | null | undefined;
    let hasCisDeduction = false;

    if ('hasCisDeduction' in request.details) {
        cisTaxAmount = request.details.cisTaxAmount;
        hasCisDeduction = Boolean(request.details.hasCisDeduction);
    }

    let allocations: XeroCreditNoteAllocation[] | null = null;

    if ('allocations' in request.details) {
        allocations = request.details.allocations ?? null;
    }

    const cisRate = useMemo(
        () => (hasCisDeduction ? request.details.lineItems.find((line) => line.cisRate != null)?.cisRate : undefined),
        [hasCisDeduction, request.details.lineItems]
    );

    const renderFooter = () => {
        let taxTypeText;

        switch (details.lineAmountType) {
            case domain.LineAmountType.TaxInclusive:
                taxTypeText = messages.taxTypeTextTaxInclusive;
                break;

            case domain.LineAmountType.TaxExclusive:
                taxTypeText = messages.taxTypeTextTaxExclusive;
                break;

            case domain.LineAmountType.NoTax:
                taxTypeText = messages.taxTypeTextNoTax;
                break;

            default:
                throw errorHelpers.notSupportedError();
        }

        let taxAdjustments = 0;

        if (
            request.integrationCode !== domain.IntegrationCode.XeroPo &&
            details.lineAmountType === domain.LineAmountType.TaxExclusive
        ) {
            taxAdjustments =
                expandedLineItems
                    .filter((li) => li.tax && !li.isDescriptionOnly)
                    .reduce((adjustment, li) => {
                        const tax = li.tax!;
                        const diff =
                            (li.taxAmount || 0) * 100 - Math.round((li.amount || 0) * (tax.rateEffective || tax.rate));

                        return adjustment + diff;
                    }, 0) / 100;
        }

        return (
            <div className={bem('footer')}>
                <div className={bem('footer-left-block')}>
                    <ExchangeRate className={bem('exchange-rate')} request={request} />

                    <div className={bem('external-block')}>
                        {request.origin === domain.RequestOrigin.ReceiptBank && (
                            <RbExternalUrl
                                companyId={request.companyId}
                                requestId={request.id}
                                data-qa={qa('rb-external-url-link')}
                            />
                        )}

                        <OpenInSourceLink
                            url={details.url}
                            editUrl={details.editUrl}
                            statusV2={request.statusV2}
                            integrationType={request.integrationType}
                            integrationCode={request.integrationCode}
                        />
                    </div>
                </div>

                <div className={bem('footer-total-block')}>
                    <div className={bem('footer-amounts-tax-type')} data-qa={bem('footer-amounts-tax-type')}>
                        {taxTypeText}
                    </div>

                    <LineItemFooterTotal
                        qa={qa('subtotal')}
                        label={messages.subTotalText}
                        value={intl.formatCurrency(details.subTotal, request.currency)}
                    />

                    {details.lineAmountType !== domain.LineAmountType.NoTax &&
                        details.invoiceTaxes.map((invoiceTax, i) => (
                            <LineItemFooterTotal
                                qa={qa(invoiceTax.taxRateString)}
                                key={i}
                                label={`${invoiceTax.taxRateString}:`}
                                value={intl.formatCurrency(invoiceTax.taxValue, request.currency)}
                            />
                        ))}

                    {Boolean(Math.round(taxAdjustments * 100) / 100) && (
                        // Do not show tax adjustments if < 0.01 after rounding.
                        <LineItemFooterTotal
                            qa={qa('tax-adjustments')}
                            label={messages.taxAdjustmentsText}
                            value={intl.formatCurrency(taxAdjustments, request.currency)}
                        />
                    )}

                    <LineItemFooterSeparator />

                    <LineItemFooterTotal
                        qa={qa('total')}
                        label={messages.totalAmountText}
                        value={intl.formatCurrency(request.amount, request.currency)}
                    />

                    {isXeroCreditNoteAllocations &&
                        allocations?.map(({ requestFriendlyName, requestId, amount }) => (
                            <LineItemFooterTotal
                                key={requestId}
                                qa={qa('allocations-info')}
                                label={messages.allocationText({
                                    url: (
                                        <Link
                                            as={RouterLink}
                                            to={getPath(Path.request, requestId, request.companyId)}
                                            font='label'
                                            fontSize='small'
                                            fontWeight='medium'
                                        >
                                            {requestFriendlyName}
                                        </Link>
                                    ),
                                })}
                                value={intl.formatCurrency(amount, request.currency)}
                            />
                        ))}

                    {hasRemainingCredit && (
                        <LineItemFooterTotal
                            qa={qa('remaining-credit')}
                            label={messages.remainingCreditAmountText}
                            value={intl.formatCurrency(request.details.remainingCredit, request.currency)}
                        />
                    )}

                    {hasCisDeduction && (
                        <LineItemFooterTotal
                            qa={qa('cis-tax-amount')}
                            label={messages.cisTaxAmount({
                                percent: cisRate,
                            })}
                            value={intl.formatCurrency(cisTaxAmount, request.currency)}
                        />
                    )}
                </div>
            </div>
        );
    };

    const renderTable = () => {
        return <LineItemsTable columnDefinitions={tableDef} lineItems={expandedLineItems} />;
    };

    if (expandedLineItems.length === 0) {
        return null;
    }

    return (
        <LineItemsSection
            className={bem.add(className)()}
            renderTable={() => renderTable()}
            renderFooter={() => renderFooter()}
        />
    );
};

export default memo(XeroLineItemsSection);
