import { RequestMutationOptions } from '@approvalmax/data';
import { useMutate } from 'shared/data';

import { requestsApiPaths } from '../paths';
import { PublishRequest } from './usePublishRequest.types';

export const usePublishRequest = (mutationOptions?: RequestMutationOptions<PublishRequest>) => {
    return useMutate(requestsApiPaths.publish, {
        mutationOptions,
        apiVersion: 'v1',
        method: 'post',
    });
};
