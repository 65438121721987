import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('modules.TwoFaEnablingWizard.StepEmailEnter', {
    popupTitle: 'Two-factor authentication setup',
    title: 'Add another email address to fall back on',
    description:
        'For using the Authenticator app, you’ll be prompted to provide an alternative email address so that we can verify it’s you. This address must be different to the one you use for logging into ApprovalMax.',
    codeFieldTitle: 'Alternative email address',
    invalidEmailErrorText: 'This email address is not valid.',
    emailPlaceholder: 'Enter an alternative email address...',
    nextButton: 'Send me the code',
});
