import { mixins, theme } from '@approvalmax/theme';
import { ToastContainer } from 'react-toastify';
import styled from 'styled-components';

export const Root = styled<typeof ToastContainer>(ToastContainer)`
    width: 400px;

    @media (max-width: 400px) {
        width: 100%;
    }

    .Toastify__toast {
        background: ${theme.color.white100};
        border-radius: ${theme.radius.small};
        padding: 16px 24px;
        min-height: auto;
        box-shadow: ${theme.shadow.medium};
        cursor: default;
        align-items: start;
        font-family: inherit;

        ${mixins.font('body', 'medium', 'regular', 'midnight100')};

        &-icon {
            margin-top: 2px;

            svg {
                width: 100%;
                height: 100%;
            }
        }

        &--default {
            .Toastify__toast-icon {
                color: ${theme.color.midnight100};
            }

            .Toastify__progress-bar {
                background: ${theme.color.midnight100};
            }
        }

        &--success {
            .Toastify__toast-icon {
                color: ${theme.color.green100};
            }

            .Toastify__progress-bar {
                background: ${theme.color.green100};
            }
        }

        &--error {
            .Toastify__toast-icon {
                color: ${theme.color.red100};
            }

            .Toastify__progress-bar {
                background: ${theme.color.red100};
            }
        }

        &--info {
            .Toastify__toast-icon {
                color: ${theme.color.blue100};
            }

            .Toastify__progress-bar {
                background: ${theme.color.blue100};
            }
        }

        &--size-small {
            .Toastify__toast-icon {
                width: 20px;
                height: 20px;
                margin-inline-end: 12px;
            }
        }

        &--size-medium {
            .Toastify__toast-icon {
                width: 20px;
                height: 20px;
                margin-inline-end: 12px;
            }
        }
    }

    .Toastify__progress-bar {
        height: 4px;

        &--bg {
            background: transparent;
        }
    }
`;

export const ToastBody = styled.div`
    width: 100%;
    margin-top: 2px;
    overflow: hidden;
    word-break: break-word;
    color: ${theme.color.midnight100};
`;
