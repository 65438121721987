import { SourceFileRecord, toast } from '@approvalmax/ui/src/components';
import { useSetAtom } from 'jotai';
import { domain } from 'modules/data';
import { useDispatch, useSelector } from 'modules/react-redux';
import { ComponentProps, useCallback, useState } from 'react';
import { commonErrorMessages } from 'services/error/messages';
import { handleFileUploadLimits, isPdfFileProtected } from 'shared/helpers';

import { uploadAttachments } from '../../../../actions';
import { getFileNameWithRequestId } from '../../../../containers/netsuite/NetSuiteRequestForm/NetSuiteRequestForm.helpers';
import { AttachmentKind } from '../../../../data/AttachmentKind';
import { getSupplierAttachments } from '../../../../selectors/pageSelectors';
import { ocrAttachmentIdState } from '../OcrUploadPanel/OcrUploadPanel.states';
import { RequestUploadPlaceholder } from '../RequestUploadPlaceholder/RequestUploadPlaceholder';
import { handleDuplicateFileNames } from './UploadPlaceholder.helpers';
import { UploadType } from './UploadPlaceholder.types';

export const useFilesUploading = (request: domain.Request) => {
    const [isLoadingNewAttachments, setIsLoadingNewAttachments] = useState(false);
    const [isLoadingNewSupplierAttachments, setIsLoadingNewSupplierAttachments] = useState(false);
    const [isLoadingNewOcrAttachments, setIsLoadingNewOcrAttachments] = useState(false);
    const [files, setFiles] = useState<SourceFileRecord[]>([]);

    const dispatch = useDispatch();
    const setOcrAttachmentIdState = useSetAtom(ocrAttachmentIdState);

    const supplierAttachments = useSelector(getSupplierAttachments);

    const uploadFiles = useCallback(
        (fileSources: File[], uploadType: UploadType) =>
            uploadAttachments({
                files: fileSources,
                attachmentKind: AttachmentKind.General,
                successfulCallback: (attachments) => {
                    switch (uploadType) {
                        case UploadType.General:
                            setIsLoadingNewAttachments(false);
                            break;

                        case UploadType.EmailToSupplier:
                            setIsLoadingNewSupplierAttachments(false);
                            break;
                        case UploadType.Ocr:
                            setIsLoadingNewOcrAttachments(false);
                            setOcrAttachmentIdState(attachments[0].id);
                            break;
                    }

                    setFiles([]);
                },
                failedCallback: () => {
                    switch (uploadType) {
                        case UploadType.General:
                            setIsLoadingNewAttachments(false);
                            break;
                        case UploadType.EmailToSupplier:
                            setIsLoadingNewSupplierAttachments(false);
                            break;
                        case UploadType.Ocr:
                            setIsLoadingNewOcrAttachments(false);
                            break;
                    }

                    setFiles([]);
                },
            }),
        [setOcrAttachmentIdState]
    );

    const filterFiles = (files: File[]) => {
        let newFiles: File[] = files;

        const attachments = [...request.attachments, ...supplierAttachments];

        if (
            request.integrationCode === domain.IntegrationCode.NetSuitePO ||
            request.integrationCode === domain.IntegrationCode.NetSuiteBill
        ) {
            newFiles = handleDuplicateFileNames(
                newFiles.map((file) => {
                    return new File([file], getFileNameWithRequestId(file.name, request.id), {
                        type: file.type,
                        lastModified: file.lastModified,
                    });
                }),
                attachments
            );
        } else {
            newFiles = handleDuplicateFileNames(newFiles, attachments);
        }

        return handleFileUploadLimits(newFiles);
    };

    const onDrop = useCallback(
        (
            value: Parameters<Required<ComponentProps<typeof RequestUploadPlaceholder>>['onDrop']>[0],
            uploadType: UploadType
        ) => {
            switch (uploadType) {
                case UploadType.General:
                    setIsLoadingNewAttachments(true);
                    break;

                case UploadType.EmailToSupplier:
                    setIsLoadingNewSupplierAttachments(true);
                    break;

                case UploadType.Ocr:
                    setIsLoadingNewOcrAttachments(true);
                    break;
            }

            const fileSources = value.map((file) => file.source);

            if (uploadType === UploadType.Ocr && fileSources[0].type === 'application/pdf') {
                isPdfFileProtected(fileSources[0]).then((isProtected) => {
                    if (isProtected) {
                        toast.info(commonErrorMessages.pdfFileProtectedError({ fileName: fileSources[0].name }));
                    }

                    dispatch(uploadFiles(fileSources, uploadType));
                });
            } else {
                dispatch(uploadFiles(fileSources, uploadType));
            }
        },
        [dispatch, uploadFiles]
    );

    return {
        onDrop,
        filterFiles,
        isLoadingNewAttachments,
        isLoadingNewOcrAttachments,
        isLoadingNewSupplierAttachments,
        setFiles,
        files,
    };
};
