import {
    NumberOfClients,
    NumberOfEntities,
    PartnerStatus,
    ProductInterest,
    QualificationQuestionsAnswers,
    QualificationQuestionsAnswersBusiness,
    QualificationQuestionsAnswersPartner,
    TypeOfBusiness,
    UseCase,
} from 'modules/data/domain';

import { messages } from './QualificationQuestions.messages';
import { ProductInterestItem } from './QualificationQuestions.types';

export const partnerStatusText = (partnerStatus: PartnerStatus) => {
    switch (partnerStatus) {
        case PartnerStatus.XeroPlatinum:
            return messages.partnerStatusXeroPlatinum;

        case PartnerStatus.XeroGold:
            return messages.partnerStatusXeroGold;

        case PartnerStatus.XeroOther:
            return messages.partnerStatusXeroOther;

        case PartnerStatus.QBOPlatinum:
            return messages.partnerStatusQBOPlatinum;

        case PartnerStatus.QBOElite:
            return messages.partnerStatusQBOElite;

        case PartnerStatus.QBOOther:
            return messages.partnerStatusQBOOther;

        case PartnerStatus.OracleNetSuite:
            return messages.partnerStatusOracleNetSuite;

        case PartnerStatus.Hybrid:
            return messages.partnerStatusHybrid;

        case PartnerStatus.Other:
            return messages.partnerStatusOther;

        default: {
            const _exhaustiveCheck: never = partnerStatus;

            return undefined;
        }
    }
};

export const partnerStatusItems = Object.values(PartnerStatus).map((val) => ({
    id: val,
    text: partnerStatusText(val),
}));

export const productInterestText = (productInterest: ProductInterest) => {
    switch (productInterest) {
        case ProductInterest.Xero:
            return messages.productInterestXero;

        case ProductInterest.QuickBooksOnline:
            return messages.productInterestQuickBooksOnline;

        case ProductInterest.OracleNetSuite:
            return messages.productInterestOracleNetSuite;

        case ProductInterest.Cin7Core:
            return messages.productInterestCin7Core;

        case ProductInterest.Hybrid:
            return messages.productInterestHybrid;

        case ProductInterest.Other:
            return messages.productInterestOther;

        default: {
            const _exhaustiveCheck: never = productInterest;

            return undefined;
        }
    }
};

export const numberOfEntitiesText = (numberOfEntities: NumberOfEntities) => {
    switch (numberOfEntities) {
        case NumberOfEntities.Less3:
            return messages.numberOfEntitiesLess3;

        case NumberOfEntities.Beetween3_10:
            return messages.numberOfEntitiesBeetween3_10;

        case NumberOfEntities.More10:
            return messages.numberOfEntitiesMore10;

        default: {
            const _exhaustiveCheck: never = numberOfEntities;

            return undefined;
        }
    }
};

export const numberOfEntitiesItems = Object.values(NumberOfEntities).map((val) => ({
    id: val,
    text: numberOfEntitiesText(val),
}));

export const numberOfClientsText = (numberOfClients: NumberOfClients) => {
    switch (numberOfClients) {
        case NumberOfClients.Beetween1_10:
            return messages.numberOfClientsBeetween1_10;

        case NumberOfClients.Beetween10_50:
            return messages.numberOfClientsBeetween10_50;

        case NumberOfClients.More50:
            return messages.numberOfClientsMore50;

        default: {
            const _exhaustiveCheck: never = numberOfClients;

            return undefined;
        }
    }
};

export const numberOfClientsItems = Object.values(NumberOfClients).map((val) => ({
    id: val,
    text: numberOfClientsText(val),
}));

export const useCaseText = (useCase: UseCase) => {
    switch (useCase) {
        case UseCase.StreamlineAuditProcesses:
            return messages.useCaseStreamlineAuditProcesses;

        case UseCase.EstablishInternalFinancialControls:
            return messages.useCaseEstablishInternalFinancialControls;

        case UseCase.AutomateApprovalWorkflows:
            return messages.useCaseAutomateApprovalWorkflows;

        case UseCase.SpeedupTheApprovalProcess:
            return messages.useCaseSpeedupTheApprovalProcess;

        case UseCase.Other:
            return messages.useCaseOther;

        default: {
            const _exhaustiveCheck: never = useCase;

            return undefined;
        }
    }
};

export const useCaseItems = Object.values(UseCase).map((val) => ({
    id: val,
    text: useCaseText(val),
}));

export const largePartnerByStatus = new Set([
    PartnerStatus.XeroPlatinum,
    PartnerStatus.XeroGold,
    PartnerStatus.QBOPlatinum,
    PartnerStatus.QBOElite,
    PartnerStatus.OracleNetSuite,
]);

export const largeBusinessByNumberOfEntities = new Set([NumberOfEntities.Beetween3_10, NumberOfEntities.More10]);

export function isAnswersPartner(
    answers: QualificationQuestionsAnswers
): answers is QualificationQuestionsAnswersPartner {
    return answers.typeOfBusiness?.[0] === TypeOfBusiness.Partner;
}

export function isAnswersBusiness(
    answers: QualificationQuestionsAnswers
): answers is QualificationQuestionsAnswersBusiness {
    return answers.typeOfBusiness?.[0] === TypeOfBusiness.Business;
}

export const getInitialTypeOfBusiness = (initialData?: QualificationQuestionsAnswers | null) => {
    let typeOfBusiness: TypeOfBusiness | null = null;

    if (initialData) {
        if (isAnswersPartner(initialData)) {
            typeOfBusiness = TypeOfBusiness.Partner;
        } else if (isAnswersBusiness(initialData)) {
            typeOfBusiness = TypeOfBusiness.Business;
        }
    }

    return typeOfBusiness;
};

export const getInitialPartnerStatus = (initialData?: QualificationQuestionsAnswers | null) => {
    let partnerStatus: (typeof partnerStatusItems)[number] | null = null;

    if (initialData && isAnswersPartner(initialData)) {
        const partnerStatusId = initialData.partnerStatus?.[0];

        if (partnerStatusId) {
            partnerStatus = {
                id: partnerStatusId,
                text: partnerStatusText(partnerStatusId),
            };
        }
    }

    return partnerStatus;
};

export const getInitiaProductInterest = (initialData?: QualificationQuestionsAnswers | null) => {
    let productInterest: ProductInterestItem | null = null;

    if (initialData && isAnswersBusiness(initialData)) {
        const productInterestId = initialData.productInterest?.[0];

        if (productInterestId) {
            productInterest = {
                id: productInterestId,
                text: productInterestText(productInterestId),
            };
        }
    }

    return productInterest;
};

export const getInitialNumberOfEntities = (initialData?: QualificationQuestionsAnswers | null) => {
    let numberOfEntities: (typeof numberOfEntitiesItems)[number] | null = null;

    if (initialData && isAnswersBusiness(initialData)) {
        const numberOfEntitiesId = initialData.numberOfEntities?.[0];

        if (numberOfEntitiesId) {
            numberOfEntities = {
                id: numberOfEntitiesId,
                text: numberOfEntitiesText(numberOfEntitiesId),
            };
        }
    }

    return numberOfEntities;
};

export const getInitialNumberOfClients = (initialData?: QualificationQuestionsAnswers | null) => {
    let numberOfClients: (typeof numberOfClientsItems)[number] | null = null;

    if (initialData && isAnswersPartner(initialData)) {
        const numberOfClientsId = initialData.numberOfClients?.[0];

        if (numberOfClientsId) {
            numberOfClients = {
                id: numberOfClientsId,
                text: numberOfClientsText(numberOfClientsId),
            };
        }
    }

    return numberOfClients;
};

export const getInitialuseCase = (initialData?: QualificationQuestionsAnswers | null) => {
    let useCase: typeof useCaseItems | null = null;

    if (initialData && initialData.useCase) {
        useCase = initialData.useCase.map((id) => ({ id, text: useCaseText(id) }));
    }

    return useCase;
};
