import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('modules.TwoFaEnablingWizard.StepEmailConfirm', {
    popupTitle: 'Two-factor authentication setup',
    title: 'We emailed a verification code to the following address:',
    changeEmailButton: 'Change the address',
    description: 'Enter the verification code that was sent to your alternative email address.',
    codeFieldTitle: 'Verification code',
    wrongCode: 'Wrong code',
    emailCodePlaceholder: 'Enter the verification code...',
    resendCodeButton: 'Resend the code',
    nextButton: 'Confirm the code',
});
