import { enabledByParams, RequestParams, RequestQueryOptions } from '@approvalmax/data';
import { useGet } from 'shared/data';

import { requestsApiPaths } from '../paths';
import { GetAuditReportDetails } from './useGetAuditReportDetails.types';

export const useGetAuditReportDetails = (
    params: RequestParams<GetAuditReportDetails>,
    queryOptions?: RequestQueryOptions<GetAuditReportDetails>
) =>
    useGet(requestsApiPaths.getAuditReportDetails, {
        params,
        queryOptions: {
            ...queryOptions,
            enabled: enabledByParams<GetAuditReportDetails['params']['query']>(
                {
                    companyId: params?.query?.companyId,
                    requestId: params?.query?.requestId,
                },
                queryOptions
            ),
        },
        mapToCamelCase: true,
        apiVersion: 'v1',
    });
