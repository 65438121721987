import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('modules.common.companySelectors', {
    defaultDisplayName: 'Unnamed Organisation',
    userRoleAccountOwner: 'Account Owner',
    userRoleParticipant: 'User',
    userRoleAuditor: 'Auditor',
    userRoleManager: 'Administrator',
    userRoleWorkflowManager: 'Workflow Manager',
    userStatusActive: 'Active',
    userStatusInvited: 'Pending',
    userStatusRejected: 'Rejected',
    userStatusNotInvited: 'Not Invited',
});
