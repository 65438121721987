import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('pages.RequestList.NetSuiteRequestCard.RequestCardBill', {
    nsReferenceFieldText: 'Reference',
    nsTransactionNumberFieldText: 'Transaction number',
    nsTermsFieldText: 'Terms',
    nsDateFieldText: 'Date',
    nsDueDateFieldText: 'Due Date',
    nsDiscountAmountFieldText: 'Discount amount',
    nsDiscountDateFieldText: 'Discount date',
    nsIsPaymentHoldFieldText: 'Payment hold',
    paymentHoldPositiveValue: 'Yes',
    paymentHoldNegativeValue: 'No',
    nsPostingPeriodFieldText: 'Posting period',
    nsMemoFieldText: 'Memo',
    nsAccountFieldText: 'Account',
    nsDepartmentFieldText: 'Department',
    nsClassFieldText: 'Class',
    nsLocationFieldText: 'Location',
});
