import { RequestParams, RequestQueryOptions } from '@approvalmax/data';
import { useGet } from 'shared/data/api';

import { paymentServicesApiPaths } from '../paths';
import type { GetAmaxPayConsent } from './useGetAmaxPayConsent.types';

export const useGetAmaxPayConsent = (
    params: RequestParams<GetAmaxPayConsent>,
    queryOptions?: RequestQueryOptions<GetAmaxPayConsent>
) =>
    useGet(paymentServicesApiPaths.amaxPayConsent, {
        params,
        queryOptions,
        apiVersion: 'v1',
        method: 'get',
    });
