import { miscHelpers } from '@approvalmax/utils';

import { useTableContext } from '../../Table.context';
import { typedMemo } from '../../Table.helpers';
import { defaultSpacing } from '../../Table.styles';
import { BaseItem } from '../../Table.types';
import { StyledCell } from './BodyCell.styles';
import { BodyCellProps } from './BodyCell.types';

export const BodyCell = typedMemo(<Item extends BaseItem>(props: BodyCellProps<Item>) => {
    const {
        value,
        children,
        title,
        columnDefinition,
        bordered,
        invalid,
        focus,
        cursor,
        hover,
        disabled,
        columnSpacing,

        ...otherProps
    } = props;

    const spacing = miscHelpers.spacingPropToCss(columnDefinition.spacing ?? columnSpacing ?? defaultSpacing);

    const { isLeftStickyShadow, isRightStickyShadow } = useTableContext();

    const withStickyShadow =
        (columnDefinition.sticky === 'left' && isLeftStickyShadow) ||
        (columnDefinition.sticky === 'right' && isRightStickyShadow);

    return (
        <StyledCell
            title={title}
            $spacing={spacing}
            $sticky={columnDefinition.sticky}
            $withStickyShadow={withStickyShadow}
            $bordered={bordered}
            $textAlign={columnDefinition.textAlign}
            $verticalAlign={columnDefinition.verticalAlign}
            $invalid={invalid}
            $focus={focus}
            $cursor={cursor}
            $hover={hover}
            $disabled={disabled}
            {...otherProps}
        >
            {children || value}
        </StyledCell>
    );
});

export default BodyCell;
