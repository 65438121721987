import { Button, Popup } from '@approvalmax/ui';
import { font } from 'modules/styles';
import React, { FC, memo, useCallback } from 'react';
import { amplitudeService } from 'services/amplitude';
import styled from 'styled-components';

import { messages } from './ChoicePopup.messages';
import Answer from './components/Answer/Answer';

const StyledPopup = styled(Popup.EmptyContent)`
    width: 520px;
`;

const Root = styled.div`
    padding: 60px 100px;
`;

const Title = styled.div`
    margin-bottom: 10px;
    ${font(16, '#000', 'semibold')}
`;

const Description = styled.div`
    margin-bottom: 30px;
    ${font(12, '#5e5c5c')}
`;

const ProceedButton = styled(Button)`
    width: 100%;
    margin-top: 30px;
`;

interface ChoicePopupProps {
    onSubmit: (shouldPrepopulateData: boolean) => void;
}

const ChoicePopup: FC<ChoicePopupProps> = (props) => {
    const { onSubmit } = props;

    const [shouldPrepopulateData, setShouldPrepopulateData] = React.useState(true);

    const onProceed = useCallback(() => {
        amplitudeService.sendData('signup: decide on demo data', {
            decision: shouldPrepopulateData ? 'use demo data' : 'do not use demo data',
        });
        onSubmit(shouldPrepopulateData);
    }, [shouldPrepopulateData, onSubmit]);

    const onAnswerClick = useCallback((value: boolean) => {
        setShouldPrepopulateData(value);
    }, []);

    return (
        <StyledPopup>
            <Root>
                <Title>{messages.title}</Title>

                <Description>{messages.description}</Description>

                <Answer
                    checked={shouldPrepopulateData}
                    text={messages.prepopulateData}
                    value={true}
                    onClick={onAnswerClick}
                />

                <Answer
                    checked={!shouldPrepopulateData}
                    text={messages.notPrepopuluteData}
                    value={false}
                    onClick={onAnswerClick}
                />

                <ProceedButton execute={onProceed}>Proceed</ProceedButton>
            </Root>
        </StyledPopup>
    );
};

export default memo(ChoicePopup);
