import { Radiobox } from '@approvalmax/ui';
import { MatchingIcon } from 'modules/sprites';
import styled from 'styled-components';

export const Container = styled.div`
    margin: 30px 0 0 26px;
`;

export const RadioContainer = styled.label`
    cursor: pointer;
`;

export const StyledRadiobox = styled(Radiobox)`
    margin: 0 5px;
    cursor: pointer;
`;

export const StyledMatchingIcon = styled(MatchingIcon)`
    vertical-align: middle;
`;
