import { validators } from '@approvalmax/ui';
import { errorHelpers } from '@approvalmax/utils';
import { constants, selectors } from 'modules/common';
import { backend, domain, State } from 'modules/data';
import { isSortCodeValid } from 'shared/helpers';

import {
    getFieldsApprovalPermissionBySystemPurpose,
    getIsExchangeRateInputAvailable,
    getRequestEditMode,
    RequestEditMode,
} from '../requestSelectors';
import { getXeroLineItems, getXeroLineItemsSummary } from './lineItemSelectors';
import { excludeEmptyPersons, getXeroBillBatchPaymentTotalAmount } from './transferSelectors';
import { messages } from './validationSelectors.messages';
import { getXeroContext } from './xeroSelectors';

const { xeroConstants } = constants;

const validateXeroPurchaseOrder = (state: State, request: domain.XeroPoRequest): string[] => {
    let errors: string[] = [];

    const xeroContext = getXeroContext(state);
    const d = request.details;
    const lineItems = getXeroLineItems(state, request);
    const editMode = getRequestEditMode(state, request);
    const summary = getXeroLineItemsSummary(state, request);
    const isExchangeRateInputAvailable = getIsExchangeRateInputAvailable(request);
    const isEmailToVendorEnabled =
        xeroContext?.supplierEmailSettings?.state !== domain.TemplateSettingsEmailToSupplierState.Disabled;
    const exchangeRateValue =
        request.exchangeRateSource === domain.ExchangeRateSource.Manual ? request.exchangeRate : null;

    const [
        deliveryDateFieldPermission,
        referenceFieldPermission,
        deliveryAddressFieldPermission,
        emailToSupplierFieldPermission,
        attachmentFieldPermission,
        noteForApproversFieldPermission,
        exchangeRateFieldPermission,
    ] = getFieldsApprovalPermissionBySystemPurpose(state, request, [
        domain.FieldSystemPurpose.XeroDeliveryDate,
        domain.FieldSystemPurpose.XeroReference,
        domain.FieldSystemPurpose.XeroDeliveryAddress,
        domain.FieldSystemPurpose.EmailToSupplier,
        domain.FieldSystemPurpose.XeroAttachment,
        domain.FieldSystemPurpose.NoteForApprovers,
        domain.FieldSystemPurpose.XeroManualExchangeRate,
    ]);

    const validRequiredFields = Boolean(
        d.contact &&
            d.date &&
            (deliveryDateFieldPermission.required ? d.deliveryDate : true) &&
            (referenceFieldPermission.required ? d.reference : true) &&
            (deliveryAddressFieldPermission.required ? d.delivery.address : true) &&
            (emailToSupplierFieldPermission.required && isEmailToVendorEnabled ? d.sendToSupplier : true) &&
            (noteForApproversFieldPermission.required ? request.requestNote.value : true) &&
            (isExchangeRateInputAvailable && exchangeRateFieldPermission.required ? exchangeRateValue : true)
    );

    if (attachmentFieldPermission.required && request.attachments.length === 0) {
        errors.push(messages.attachmentRequiredErrorText);
    }

    if (lineItems.some((li) => li.invalidAccount || li.invalidTrackingCategories.length > 0)) {
        errors.push(messages.lineItemsValidationErrorText);
    }

    if (!d.brandingTheme) {
        errors.push(messages.emptyBrandingThemeErrorText);
    }

    const validLineItems = lineItems.some((li) => !li.empty) && lineItems.every((li) => li.valid);

    lineItems.forEach((li) => {
        if (li.invalidAccountTaxMatch) {
            errors.push(li.invalidAccountTaxMatchText!);
        }
    });

    if (lineItems.some((li) => typeof li.computedAmount === 'number' && li.computedAmount > xeroConstants.MAX_AMOUNT)) {
        errors.push(messages.exceededMaxValueAmountErrorText);
    }

    // Email to supplier section
    let validEmailToSupplierTo = true;
    let validEmailToSupplierBody = true;
    let validEmailToSupplierSubject = true;

    if (d.sendToSupplier) {
        validEmailToSupplierTo = Boolean(d.emailToSupplier && d.emailToSupplier.to.length > 0);
        validEmailToSupplierBody = Boolean(d.emailToSupplier && d.emailToSupplier.body);
        validEmailToSupplierSubject = Boolean(d.emailToSupplier && d.emailToSupplier.subject);
    }

    if (d.lineAmountType === domain.LineAmountType.NoTax && xeroContext.noTaxIsInvalid) {
        errors.push(messages.noTaxIsInvalidErrorText);
    }

    if (d.reference && d.reference.length > xeroConstants.PO_REFERENCE_MAX_LENGTH) {
        errors.push(messages.referencePOLengthErrorText);
    }

    if (d.delivery.address && d.delivery.address.length > xeroConstants.DELIVERY_ADDRESS_MAX_LENGTH) {
        errors.push(messages.deliveryAddressLengthErrorText);
    }

    if (d.delivery.phone && d.delivery.phone.length > xeroConstants.DELIVERY_PHONE_MAX_LENGTH) {
        errors.push(messages.deliveryPhoneLengthErrorText);
    }

    if (d.delivery.phone && !selectors.ui.isValidPhone(d.delivery.phone)) {
        errors.push(messages.deliveryPhoneInvalidErrorText);
    }

    if (d.delivery.attentionTo && d.delivery.attentionTo.length > xeroConstants.DELIVERY_ATTENTION_TO_MAX_LENGTH) {
        errors.push(messages.deliveryAttentionToLengthErrorText);
    }

    if (d.delivery.instructions && d.delivery.instructions.length > xeroConstants.DELIVERY_INSTRUCTIONS_MAX_LENGTH) {
        errors.push(messages.deliveryInstructionsLengthErrorText);
    }

    if (!validRequiredFields || !validLineItems || !validEmailToSupplierBody || !validEmailToSupplierSubject) {
        errors.push(messages.mandatoryFieldsErrorText);
    } else if (!validEmailToSupplierTo) {
        errors.push(messages.emailToSupplierToEmpty);
    }

    if (summary.totalAmount < xeroConstants.TOTAL_MIN_AMOUNT) {
        errors.push(messages.poNegativeAmountErrorText);
    }

    if (summary.totalAmount > xeroConstants.TOTAL_MAX_AMOUNT) {
        errors.push(messages.exceededMaxValueTotalAmountErrorText);
    }

    if (editMode === RequestEditMode.Approver) {
        const totalAmountMatches = request.amount === getXeroLineItemsSummary(state, request).totalAmount;
        const requestName = selectors.template.getTemplateDisplayNameByCode(request.integrationCode);

        if (!totalAmountMatches) {
            errors.push(messages.amountMismatchErrorText({ requestName }));
        }
    }

    return errors;
};

const validateXeroSalesInvoice = (state: State, request: domain.XeroInvoiceRequest): string[] => {
    const [
        referenceFieldPermission,
        emailToSupplierFieldPermission,
        attachmentFieldPermission,
        noteForApproversFieldPermission,
        exchangeRateFieldPermission,
    ] = getFieldsApprovalPermissionBySystemPurpose(state, request, [
        domain.FieldSystemPurpose.XeroReference,
        domain.FieldSystemPurpose.EmailToSupplier,
        domain.FieldSystemPurpose.XeroAttachment,
        domain.FieldSystemPurpose.NoteForApprovers,
        domain.FieldSystemPurpose.XeroManualExchangeRate,
    ]);

    const errors: string[] = [];

    const details = request.details;
    const lineItems = getXeroLineItems(state, request);
    const xeroContext = getXeroContext(state);
    const editMode = getRequestEditMode(state, request);
    const summary = getXeroLineItemsSummary(state, request);
    const isExchangeRateInputAvailable = getIsExchangeRateInputAvailable(request);
    const isEmailToVendorEnabled =
        xeroContext?.supplierEmailSettings?.state !== domain.TemplateSettingsEmailToSupplierState.Disabled;
    const exchangeRateValue =
        request.exchangeRateSource === domain.ExchangeRateSource.Manual ? request.exchangeRate : null;

    const validRequiredFields = Boolean(
        details.contact &&
            details.date &&
            details.dueDate &&
            (referenceFieldPermission.required ? details.reference : true) &&
            (emailToSupplierFieldPermission.required && isEmailToVendorEnabled ? details.sendToSupplier : true) &&
            (noteForApproversFieldPermission.required ? request.requestNote.value : true) &&
            (isExchangeRateInputAvailable && exchangeRateFieldPermission.required ? exchangeRateValue : true)
    );

    if (attachmentFieldPermission.required && request.attachments.length === 0) {
        errors.push(messages.attachmentRequiredErrorText);
    }

    if (lineItems.some((li) => li.invalidAccount || li.invalidTrackingCategories.length > 0)) {
        errors.push(messages.lineItemsValidationErrorText);
    }

    if (!details.brandingTheme) {
        errors.push(messages.emptyBrandingThemeErrorText);
    }

    let validLineItems = lineItems.some((li) => !li.empty) && lineItems.every((li) => li.valid);

    lineItems.forEach((li) => {
        if (li.invalidAccountTaxMatch) {
            errors.push(li.invalidAccountTaxMatchText!);
        }
    });

    if (lineItems.some((li) => typeof li.computedAmount === 'number' && li.computedAmount > xeroConstants.MAX_AMOUNT)) {
        errors.push(messages.exceededMaxValueAmountErrorText);
    }

    // Email to supplier section
    let validEmailToSupplierTo = true;
    let validEmailToSupplierBody = true;
    let validEmailToSupplierSubject = true;

    if (details.sendToSupplier) {
        validEmailToSupplierTo = Boolean(details.emailToSupplier && details.emailToSupplier.to.length);
        validEmailToSupplierBody = Boolean(details.emailToSupplier && details.emailToSupplier.body);
        validEmailToSupplierSubject = Boolean(details.emailToSupplier && details.emailToSupplier.subject);
    }

    if (details.lineAmountType === domain.LineAmountType.NoTax && xeroContext.noTaxIsInvalid) {
        errors.push(messages.noTaxIsInvalidErrorText);
    }

    if (details.reference && details.reference.length > xeroConstants.INVOICE_REFERENCE_MAX_LENGTH) {
        errors.push(messages.referenceBillLengthErrorText);
    }

    if (details.number && details.number.length > xeroConstants.INVOICE_NUMBER_MAX_LENGTH) {
        errors.push(messages.numberInvoiceLengthErrorText);
    }

    if (!validRequiredFields || !validLineItems || !validEmailToSupplierBody || !validEmailToSupplierSubject) {
        errors.push(messages.mandatoryFieldsErrorText);
    } else if (!validEmailToSupplierTo) {
        errors.push(messages.emailToSupplierToEmpty);
    }

    if (summary.totalAmount < xeroConstants.TOTAL_MIN_AMOUNT) {
        errors.push(messages.invoiceNegativeAmountErrorText);
    }

    if (summary.totalAmount > xeroConstants.TOTAL_MAX_AMOUNT) {
        errors.push(messages.exceededMaxValueTotalAmountErrorText);
    }

    if (editMode === RequestEditMode.Approver) {
        const requestName = selectors.template.getTemplateDisplayNameByCode(request.integrationCode);
        const totalAmountMatches = request.amount === getXeroLineItemsSummary(state, request).totalAmount;

        if (!totalAmountMatches) {
            errors.push(messages.amountMismatchErrorText({ requestName }));
        }
    }

    return errors;
};

const validateXeroQuote = (state: State, request: domain.XeroQuoteRequest): string[] => {
    const [
        expiryFieldPermission,
        numberFieldPermission,
        referenceFieldPermission,
        titleFieldPermission,
        summaryFieldPermission,
        exchangeRateFieldPermission,
        noteForApproversFieldPermission,
        termsFieldPermission,
        attachmentFieldPermission,
        emailToSupplierFieldPermission,
    ] = getFieldsApprovalPermissionBySystemPurpose(state, request, [
        domain.FieldSystemPurpose.XeroQuoteExpiry,
        domain.FieldSystemPurpose.XeroQuoteNumber,
        domain.FieldSystemPurpose.XeroReference,
        domain.FieldSystemPurpose.XeroQuoteTitle,
        domain.FieldSystemPurpose.XeroQuoteSummary,
        domain.FieldSystemPurpose.XeroManualExchangeRate,
        domain.FieldSystemPurpose.NoteForApprovers,
        domain.FieldSystemPurpose.XeroQuoteTerms,
        domain.FieldSystemPurpose.XeroAttachment,
        domain.FieldSystemPurpose.EmailToSupplier,
    ]);

    const errors: string[] = [];

    const details = request.details;
    const lineItems = getXeroLineItems(state, request);
    const summary = getXeroLineItemsSummary(state, request);
    const xeroContext = getXeroContext(state);
    const editMode = getRequestEditMode(state, request);
    const isExchangeRateInputAvailable = getIsExchangeRateInputAvailable(request);
    const isEmailToVendorEnabled =
        xeroContext?.supplierEmailSettings?.state !== domain.TemplateSettingsEmailToSupplierState.Disabled;
    const exchangeRateValue =
        request.exchangeRateSource === domain.ExchangeRateSource.Manual ? request.exchangeRate : null;

    const validRequiredFields = Boolean(
        details.contact &&
            details.date &&
            (expiryFieldPermission.required ? details.expiryDate : true) &&
            (numberFieldPermission.required ? details.number : true) &&
            (referenceFieldPermission.required ? details.reference : true) &&
            (titleFieldPermission.required ? details.title : true) &&
            (summaryFieldPermission.required ? details.summary : true) &&
            (noteForApproversFieldPermission.required ? request.requestNote.value : true) &&
            (termsFieldPermission.required ? details.terms : true) &&
            (emailToSupplierFieldPermission.required && isEmailToVendorEnabled ? details.sendToSupplier : true) &&
            (isExchangeRateInputAvailable && exchangeRateFieldPermission.required ? exchangeRateValue : true)
    );

    if (attachmentFieldPermission.required && request.attachments.length === 0) {
        errors.push(messages.attachmentRequiredErrorText);
    }

    if (lineItems.some((li) => li.invalidAccount || li.invalidTrackingCategories.length > 0)) {
        errors.push(messages.lineItemsValidationErrorText);
    }

    if (!details.brandingTheme) {
        errors.push(messages.emptyBrandingThemeErrorText);
    }

    const validLineItems = lineItems.some((li) => !li.empty) && lineItems.every((li) => li.valid);

    lineItems.forEach((li) => {
        if (li.invalidAccountTaxMatch) {
            errors.push(li.invalidAccountTaxMatchText!);
        }
    });

    if (lineItems.some((li) => typeof li.computedAmount === 'number' && li.computedAmount > xeroConstants.MAX_AMOUNT)) {
        errors.push(messages.exceededMaxValueAmountErrorText);
    }

    // Email to supplier section
    let validEmailToSupplierTo = true;
    let validEmailToSupplierBody = true;
    let validEmailToSupplierSubject = true;

    if (details.sendToSupplier) {
        validEmailToSupplierTo = Boolean(details.emailToSupplier && details.emailToSupplier.to.length);
        validEmailToSupplierBody = Boolean(details.emailToSupplier && details.emailToSupplier.body);
        validEmailToSupplierSubject = Boolean(details.emailToSupplier && details.emailToSupplier.subject);
    }

    if (details.lineAmountType === domain.LineAmountType.NoTax && xeroContext.noTaxIsInvalid) {
        errors.push(messages.noTaxIsInvalidErrorText);
    }

    if (details.reference && details.reference.length > xeroConstants.PO_REFERENCE_MAX_LENGTH) {
        errors.push(messages.referencePOLengthErrorText);
    }

    if (details.number && details.number.length > xeroConstants.QUOTE_NUMBER_MAX_LENGTH) {
        errors.push(messages.numberQuoteLengthErrorText);
    }

    if (details.title && details.title.length > xeroConstants.TITLE_MAX_LENGTH) {
        errors.push(messages.titleQuoteLengthErrorText);
    }

    if (details.summary && details.summary.length > xeroConstants.SUMMARY_MAX_LENGTH) {
        errors.push(messages.summaryQuoteLengthErrorText);
    }

    if (!validRequiredFields || !validLineItems || !validEmailToSupplierBody || !validEmailToSupplierSubject) {
        errors.push(messages.mandatoryFieldsErrorText);
    } else if (!validEmailToSupplierTo) {
        errors.push(messages.emailToSupplierToEmpty);
    }

    if (summary.totalAmount < xeroConstants.TOTAL_MIN_AMOUNT) {
        errors.push(messages.quoteNegativeAmountErrorText);
    }

    if (summary.totalAmount > xeroConstants.TOTAL_MAX_AMOUNT) {
        errors.push(messages.exceededMaxValueTotalAmountErrorText);
    }

    if (editMode === RequestEditMode.Approver) {
        const requestName = selectors.template.getTemplateDisplayNameByCode(request.integrationCode);
        const totalAmountMatches = request.amount === getXeroLineItemsSummary(state, request).totalAmount;

        if (!totalAmountMatches) {
            errors.push(messages.amountMismatchErrorText({ requestName }));
        }
    }

    return errors;
};

const validateXeroBill = (state: State, request: domain.XeroBillRequest) => {
    const [
        exchangeRateFieldPermission,
        referenceFieldPermission,
        noteForApproversFieldPermission,
        attachmentFieldPermission,
    ] = getFieldsApprovalPermissionBySystemPurpose(state, request, [
        domain.FieldSystemPurpose.XeroManualExchangeRate,
        domain.FieldSystemPurpose.XeroReference,
        domain.FieldSystemPurpose.NoteForApprovers,
        domain.FieldSystemPurpose.XeroAttachment,
    ]);

    let errors: string[] = [];

    const d = request.details;
    const lineItems = getXeroLineItems(state, request);
    const summary = getXeroLineItemsSummary(state, request);
    const editMode = getRequestEditMode(state, request);
    const isExchangeRateInputAvailable = getIsExchangeRateInputAvailable(request);
    const exchangeRateValue =
        request.exchangeRateSource === domain.ExchangeRateSource.Manual ? request.exchangeRate : null;

    const validRequiredFields = Boolean(
        d.contact &&
            d.date &&
            d.dueDate &&
            (referenceFieldPermission.required ? d.reference : true) &&
            (noteForApproversFieldPermission.required ? request.requestNote.value : true) &&
            (isExchangeRateInputAvailable && exchangeRateFieldPermission.required ? exchangeRateValue : true)
    );

    if (attachmentFieldPermission.required && request.attachments.length === 0) {
        errors.push(messages.attachmentRequiredErrorText);
    }

    if (lineItems.some((li) => li.invalidAccount || li.invalidTrackingCategories.length > 0)) {
        errors.push(messages.lineItemsValidationErrorText);
    }

    const validLineItems =
        lineItems.some((li) => !li.empty) && lineItems.filter((li) => !li.emptyLike).every((li) => li.valid);

    lineItems.forEach((li) => {
        if (li.invalidAccountTaxMatch) {
            errors.push(li.invalidAccountTaxMatchText!);
        }
    });

    if (lineItems.some((li) => typeof li.computedAmount === 'number' && li.computedAmount > 999999999999.99)) {
        errors.push(messages.exceededMaxValueAmountErrorText);
    }

    const xeroContext = getXeroContext(state);

    if (xeroContext.currencies.every((currency) => currency.id !== request.currency)) {
        errors.push(messages.nonExistCurrencyErrorText);
    }

    if (d.lineAmountType === domain.LineAmountType.NoTax && xeroContext.noTaxIsInvalid) {
        errors.push(messages.noTaxIsInvalidErrorText);
    }

    if (!validRequiredFields || !validLineItems) {
        // Assumption: line items are invalid => mandatory fields error
        errors.push(messages.mandatoryFieldsErrorText);
    }

    if (summary.totalAmount < xeroConstants.TOTAL_MIN_AMOUNT) {
        errors.push(messages.billNegativeAmountErrorText);
    }

    if (summary.totalAmount > xeroConstants.TOTAL_MAX_AMOUNT) {
        errors.push(messages.exceededMaxValueTotalAmountErrorText);
    }

    if (editMode === RequestEditMode.Editor || editMode === RequestEditMode.Approver) {
        const totalAmountMatches = request.amount === getXeroLineItemsSummary(state, request).totalAmount;
        const requestName = selectors.template.getTemplateDisplayNameByCode(request.integrationCode);

        if (!totalAmountMatches) {
            errors.push(messages.amountMismatchErrorText({ requestName }));
        }
    }

    if (d.reference && d.reference.length > xeroConstants.INVOICE_REFERENCE_MAX_LENGTH) {
        errors.push(messages.referenceBillLengthErrorText);
    }

    return errors;
};

export function isValidXeroContactName(contactName: string) {
    const regexp = new RegExp('[<>]|(&#)');

    return !regexp.test(contactName);
}

const validateXeroContact = (state: State, request: domain.XeroContactRequest) => {
    let errors: Set<string> = new Set();

    const details = request.details;

    if (!details.name?.trim()) {
        errors.add(messages.contactNameIsEmpty);
    }

    if (details.name && !isValidXeroContactName(details.name)) {
        errors.add(messages.invalidContactName);
    }

    if (details.name && /\p{Extended_Pictographic}/u.test(details.name)) {
        errors.add(messages.noEmojiInContactName);
    }

    if (details.name && details.name.length > xeroConstants.CONTACT_NAME_MAX_LENGTH) {
        errors.add(messages.contactLengthErrorText);
    }

    if (details.firstName && details.firstName.length > xeroConstants.FIRST_NAME_MAX_LENGTH) {
        errors.add(messages.firstNameLengthErrorText);
    }

    if (details.lastName && details.lastName.length > xeroConstants.LAST_NAME_MAX_LENGTH) {
        errors.add(messages.lastNameLengthErrorText);
    }

    if (details.emailAddress && details.emailAddress.length > xeroConstants.EMAIL_ADDRESS_MAX_LENGTH) {
        errors.add(messages.emailLengthErrorText);
    }

    if (details.skypeName && details.skypeName.length > xeroConstants.SKYPE_MAX_LENGTH) {
        errors.add(messages.skypeLengthErrorText);
    }

    if (
        details.bankAccountDetails &&
        details.bankAccountDetails.length > xeroConstants.BANK_ACCOUNT_DETAILS_MAX_LENGTH
    ) {
        errors.add(messages.bankAccountNumberLengthErrorText);
    }

    if (details.taxNumber && details.taxNumber.length > xeroConstants.TAX_NUMBER_MAX_LENGTH) {
        errors.add(messages.taxNumberLengthErrorText);
    }

    if (details.accountNumber && details.accountNumber.length > xeroConstants.ACCOUNT_NUMBER_MAX_LENGTH) {
        errors.add(messages.accountNumberLengthErrorText);
    }

    if (
        details.phoneNumber?.areaCode &&
        details.phoneNumber?.areaCode.length > xeroConstants.PHONE_AREA_CODE_MAX_LENGTH
    ) {
        errors.add(messages.phoneAreaCodeLengthErrorText);
    }

    if (
        details.phoneNumber?.countryCode &&
        details.phoneNumber?.countryCode.length > xeroConstants.PHONE_COUNTRY_CODE_MAX_LENGTH
    ) {
        errors.add(messages.phoneCountryCodeLengthErrorText);
    }

    if (details.phoneNumber?.number && details.phoneNumber?.number.length > xeroConstants.PHONE_NUMBER_MAX_LENGTH) {
        errors.add(messages.phoneNumberLengthErrorText);
    }

    if (details.mobile?.areaCode && details.mobile?.areaCode.length > xeroConstants.PHONE_AREA_CODE_MAX_LENGTH) {
        errors.add(messages.phoneAreaCodeLengthErrorText);
    }

    if (
        details.mobile?.countryCode &&
        details.mobile?.countryCode.length > xeroConstants.PHONE_COUNTRY_CODE_MAX_LENGTH
    ) {
        errors.add(messages.phoneCountryCodeLengthErrorText);
    }

    if (details.mobile?.number && details.mobile?.number.length > xeroConstants.PHONE_NUMBER_MAX_LENGTH) {
        errors.add(messages.phoneNumberLengthErrorText);
    }

    if (details.fax?.areaCode && details.fax?.areaCode.length > xeroConstants.PHONE_AREA_CODE_MAX_LENGTH) {
        errors.add(messages.phoneAreaCodeLengthErrorText);
    }

    if (details.fax?.countryCode && details.fax?.countryCode.length > xeroConstants.PHONE_COUNTRY_CODE_MAX_LENGTH) {
        errors.add(messages.phoneCountryCodeLengthErrorText);
    }

    if (details.fax?.number && details.fax?.number.length > xeroConstants.PHONE_NUMBER_MAX_LENGTH) {
        errors.add(messages.phoneNumberLengthErrorText);
    }

    if (details.directDial?.number && details.directDial?.number.length > xeroConstants.PHONE_NUMBER_MAX_LENGTH) {
        errors.add(messages.phoneNumberLengthErrorText);
    }

    if (details.directDial?.number && details.directDial?.number.length > xeroConstants.PHONE_NUMBER_MAX_LENGTH) {
        errors.add(messages.phoneNumberLengthErrorText);
    }

    if (details.directDial?.number && details.directDial?.number.length > xeroConstants.PHONE_NUMBER_MAX_LENGTH) {
        errors.add(messages.phoneNumberLengthErrorText);
    }

    if (details.billingAddress?.address && details.billingAddress?.address.length > xeroConstants.ADDRESS_MAX_LENGTH) {
        errors.add(messages.addressAddressLengthErrorText);
    }

    if (
        details.billingAddress?.postalCode &&
        details.billingAddress?.postalCode.length > xeroConstants.ADDRESS_POST_CODE_MAX_LENGTH
    ) {
        errors.add(messages.addressPostCodeLengthErrorText);
    }

    if (
        details.billingAddress?.attentionTo &&
        details.billingAddress?.attentionTo.length > xeroConstants.ADDRESS_ATTENTION_TO_MAX_LENGTH
    ) {
        errors.add(messages.addressAttentionToLengthErrorText);
    }

    if (details.billingAddress?.city && details.billingAddress?.city.length > xeroConstants.ADDRESS_CITY_MAX_LENGTH) {
        errors.add(messages.addressCityLengthErrorText);
    }

    if (
        details.billingAddress?.country &&
        details.billingAddress?.country.length > xeroConstants.ADDRESS_COUNTRY_MAX_LENGTH
    ) {
        errors.add(messages.addressCountryLengthErrorText);
    }

    if (
        details.billingAddress?.region &&
        details.billingAddress?.region.length > xeroConstants.ADDRESS_REGION_MAX_LENGTH
    ) {
        errors.add(messages.addressRegionLengthErrorText);
    }

    if (
        details.deliveryAddress?.address &&
        details.deliveryAddress?.address.length > xeroConstants.ADDRESS_MAX_LENGTH
    ) {
        errors.add(messages.addressAddressLengthErrorText);
    }

    if (
        details.deliveryAddress?.postalCode &&
        details.deliveryAddress?.postalCode.length > xeroConstants.ADDRESS_POST_CODE_MAX_LENGTH
    ) {
        errors.add(messages.addressPostCodeLengthErrorText);
    }

    if (
        details.deliveryAddress?.attentionTo &&
        details.deliveryAddress?.attentionTo.length > xeroConstants.ADDRESS_ATTENTION_TO_MAX_LENGTH
    ) {
        errors.add(messages.addressAttentionToLengthErrorText);
    }

    if (details.deliveryAddress?.city && details.deliveryAddress?.city.length > xeroConstants.ADDRESS_CITY_MAX_LENGTH) {
        errors.add(messages.addressCityLengthErrorText);
    }

    if (
        details.deliveryAddress?.country &&
        details.deliveryAddress?.country.length > xeroConstants.ADDRESS_COUNTRY_MAX_LENGTH
    ) {
        errors.add(messages.addressCountryLengthErrorText);
    }

    if (
        details.deliveryAddress?.region &&
        details.deliveryAddress?.region.length > xeroConstants.ADDRESS_REGION_MAX_LENGTH
    ) {
        errors.add(messages.addressRegionLengthErrorText);
    }

    if (details.companyNumber && details.companyNumber.length > xeroConstants.COMPANY_NUMBER_MAX_LENGTH) {
        errors.add(messages.companyRegistrationNumberLengthErrorText);
    }

    details.persons.forEach((person) => {
        if (person.firstName && person.firstName.length > xeroConstants.ANOTHER_PERSON_FIRST_NAME_MAX_LENGTH) {
            errors.add(messages.anotherPersonFirstNameLengthErrorText);
        }

        if (person.lastName && person.lastName.length > xeroConstants.ANOTHER_PERSON_LAST_NAME_MAX_LENGTH) {
            errors.add(messages.anotherPersonLastNameLengthErrorText);
        }

        if (person.emailAddress && person.emailAddress.length > xeroConstants.EMAIL_ADDRESS_MAX_LENGTH) {
            errors.add(messages.anotherPersonEmailLengthErrorText);
        }

        if (person.emailAddress && !validators.isEmail(person.emailAddress)) {
            errors.add(messages.wrongEmail);
        }
    });

    const personsIsNotEmpty = details.persons.filter(excludeEmptyPersons).length > 0;

    if (personsIsNotEmpty && !details.emailAddress) {
        errors.add(messages.emailAddressIsEmpty);
    }

    const hasPersonWithNotValidEmail = details.persons.some(
        (person) => person.emailAddress && !validators.isEmail(person.emailAddress)
    );

    if ((details.emailAddress && !validators.isEmail(details.emailAddress)) || hasPersonWithNotValidEmail) {
        errors.add(messages.wrongEmail);
    }

    if (details.sortCode && !isSortCodeValid(details.sortCode)) {
        errors.add(
            messages.sortCodeError({
                length: xeroConstants.sortCodeMaxLength,
            })
        );
    }

    if (details.sortCode && (!details.bankAccountDetails || !/^\d{8}$/.test(details.bankAccountDetails))) {
        errors.add(messages.bankAccountWithSortCodeError);
    }

    return [...errors];
};

const validateXeroBillBatchPayment = (state: State, request: domain.XeroBillBatchPaymentRequest) => {
    const details = request.details;
    const amount = getXeroBillBatchPaymentTotalAmount(details.items);

    const company = selectors.company.getCompanyById(state, request.companyId);

    const referenceFieldMaxLength =
        company.integration?.countryCode === backend.OrganisationVersion.AU
            ? xeroConstants.BATCH_PAYMENT_REFERENCE_AU_MAX_LENGTH
            : xeroConstants.BATCH_PAYMENT_REFERENCE_DEFAULT_MAX_LENGTH;

    let errors: Set<string> = new Set([]);

    if (details.items.some((item) => !item.amount)) {
        errors.add(messages.itemWithEmptyAmount);
    }

    if (details.workflowIsSupplierBankAccountRequired && details.items.some((item) => !item.bankAccount)) {
        errors.add(messages.bankAccountIsEmpty);
    }

    if (amount > xeroConstants.TOTAL_MAX_AMOUNT) {
        errors.add(messages.exceededMaxValueTotalAmountErrorText);
    }

    if (!details.paymentDate) {
        errors.add(messages.paymentDateIsEmpty);
    }

    if (!details.bankAccount) {
        errors.add(messages.bankAccountIsEmpty);
    }

    if (!details.items.length) {
        errors.add(messages.noBills);
    }

    if (details.reference && details.reference.length > referenceFieldMaxLength) {
        errors.add(messages.batchPaymentReferenceLengthErrorText);
    }

    if (details.narrative && details.narrative.length > xeroConstants.BATCH_PAYMENT_NARRATIVE_MAX_LENGTH) {
        errors.add(messages.batchPaymentNarrativeLengthErrorText);
    }

    if (details.details && details.details.length > xeroConstants.BATCH_PAYMENT_DETAILS_MAX_LENGTH) {
        errors.add(messages.batchPaymentDetailsLengthErrorText);
    }

    if (details.particulars && details.particulars.length > xeroConstants.BATCH_PAYMENT_PARTICULARS_MAX_LENGTH) {
        errors.add(messages.batchPaymentParticularsLengthErrorText);
    }

    if (details.code && details.code.length > xeroConstants.BATCH_PAYMENT_CODE_MAX_LENGTH) {
        errors.add(messages.batchPaymentCodeLengthErrorText);
    }

    details.items.forEach((invoice) => {
        if (invoice.details && invoice.details.length > xeroConstants.BATCH_PAYMENT_DETAILS_MAX_LENGTH) {
            errors.add(messages.batchPaymentDetailsLengthErrorText);
        }

        if (invoice.particulars && invoice.particulars.length > xeroConstants.BATCH_PAYMENT_PARTICULARS_MAX_LENGTH) {
            errors.add(messages.batchPaymentParticularsLengthErrorText);
        }

        if (invoice.code && invoice.code.length > xeroConstants.BATCH_PAYMENT_CODE_MAX_LENGTH) {
            errors.add(messages.batchPaymentCodeLengthErrorText);
        }

        if (invoice.bankAccount && invoice.bankAccount.length > xeroConstants.BATCH_PAYMENT_BANK_ACCOUNT_MAX_LENGTH) {
            errors.add(messages.batchPaymentBankAccountLengthErrorText);
        }
    });

    const workflowAllowEditSupplierBankAccount = request.authorRules.some(
        (rule) => rule.fieldSystemPurpose === domain.FieldSystemPurpose.XeroSupplier && rule.allowEditing
    );

    const hasChangedBankAccount = details.items.some((item) => {
        return item.defaultBankAccount && item.bankAccount !== item.defaultBankAccount;
    });

    if (!workflowAllowEditSupplierBankAccount && hasChangedBankAccount) {
        errors.add(messages.forbidModifiedBankAccount);
    }

    return [...errors];
};

const validateXeroAmaxPayBatchPayment = (request: domain.XeroAmaxPayBatchPaymentRequest) => {
    const details = request.details;

    const errors: string[] = [];

    if (details.items.length === 0) {
        errors.push(messages.noBills);
    }

    const hasNotFilledRequiredField = details.items.some(
        (invoice) => !(invoice.amount && invoice.paymentDetails && invoice.paymentReference?.trim())
    );

    if (hasNotFilledRequiredField) {
        errors.push(messages.fillInMandatoryFields);
    }

    const hasExceededLengthReference = details.items.some((invoice) => {
        return invoice.paymentReference && invoice.paymentReference.length > xeroConstants.amaxPayBatchPaymentReference;
    });

    if (hasExceededLengthReference) {
        errors.push(
            messages.amaxPayBatchPaymentReferenceLengthErrorText({
                maxLength: xeroConstants.amaxPayBatchPaymentReference,
            })
        );
    }

    return errors;
};

const validateXeroAirwallexBatchPayment = (state: State, request: domain.XeroAirwallexBatchPaymentRequest) => {
    const details = request.details;

    let errors: Set<string> = new Set([]);

    if (details.items.length === 0) {
        errors.add(messages.noBills);
    }

    const hasNotFilledRequiredField = details.items.some((invoice) => {
        return !(
            invoice.amount &&
            invoice.beneficiary &&
            invoice.beneficiary.paymentMethod &&
            (invoice.beneficiary.paymentMethod.id === domain.AirwallexPaymentMethod.Local || invoice.paymentFee) &&
            invoice.sourceCurrency &&
            invoice.paymentReference &&
            invoice.paymentPurpose &&
            invoice.bankAccount
        );
    });

    if (hasNotFilledRequiredField) {
        errors.add(messages.fillInMandatoryFields);
    }

    return [...errors];
};

const validateCreditNotes = (
    state: State,
    request: domain.XeroCreditNotesPayableRequest | domain.XeroCreditNotesReceivableRequest
) => {
    const [
        exchangeRateFieldPermission,
        referenceFieldPermission,
        noteForApproversFieldPermission,
        attachmentFieldPermission,
    ] = getFieldsApprovalPermissionBySystemPurpose(state, request, [
        domain.FieldSystemPurpose.XeroManualExchangeRate,
        domain.FieldSystemPurpose.XeroReference,
        domain.FieldSystemPurpose.NoteForApprovers,
        domain.FieldSystemPurpose.XeroAttachment,
    ]);

    let errors: string[] = [];

    const d = request.details;
    const lineItems = getXeroLineItems(state, request);
    const summary = getXeroLineItemsSummary(state, request);
    const editMode = getRequestEditMode(state, request);
    const isExchangeRateInputAvailable = getIsExchangeRateInputAvailable(request);
    const exchangeRateValue =
        request.exchangeRateSource === domain.ExchangeRateSource.Manual ? request.exchangeRate : null;

    const validRequiredFields = Boolean(
        d.contact &&
            d.date &&
            (referenceFieldPermission.required ? d.reference : true) &&
            (noteForApproversFieldPermission.required ? request.requestNote.value : true) &&
            (isExchangeRateInputAvailable && exchangeRateFieldPermission.required ? exchangeRateValue : true)
    );

    if (attachmentFieldPermission.required && request.attachments.length === 0) {
        errors.push(messages.attachmentRequiredErrorText);
    }

    if (lineItems.some((li) => li.invalidAccount || li.invalidTrackingCategories.length > 0)) {
        errors.push(messages.lineItemsValidationErrorText);
    }

    const validLineItems =
        lineItems.some((li) => !li.empty) && lineItems.filter((li) => !li.emptyLike).every((li) => li.valid);

    lineItems.forEach((li) => {
        if (li.invalidAccountTaxMatch) {
            errors.push(li.invalidAccountTaxMatchText!);
        }
    });

    if (lineItems.some((li) => typeof li.computedAmount === 'number' && li.computedAmount > 999999999999.99)) {
        errors.push(messages.exceededMaxValueAmountErrorText);
    }

    const xeroContext = getXeroContext(state);

    if (xeroContext.currencies.every((currency) => currency.id !== request.currency)) {
        errors.push(messages.nonExistCurrencyErrorText);
    }

    if (d.lineAmountType === domain.LineAmountType.NoTax && xeroContext.noTaxIsInvalid) {
        errors.push(messages.noTaxIsInvalidErrorText);
    }

    if (!validRequiredFields || !validLineItems) {
        // Assumption: line items are invalid => mandatory fields error
        errors.push(messages.mandatoryFieldsErrorText);
    }

    if (summary.totalAmount < xeroConstants.TOTAL_MIN_AMOUNT) {
        errors.push(messages.billNegativeAmountErrorText);
    }

    if (summary.totalAmount > xeroConstants.TOTAL_MAX_AMOUNT) {
        errors.push(messages.exceededMaxValueTotalAmountErrorText);
    }

    if (editMode === RequestEditMode.Editor || editMode === RequestEditMode.Approver) {
        const totalAmountMatches = request.amount === getXeroLineItemsSummary(state, request).totalAmount;
        const requestName = selectors.template.getTemplateDisplayNameByCode(request.integrationCode);

        if (!totalAmountMatches) {
            errors.push(messages.amountMismatchErrorText({ requestName }));
        }
    }

    if (d.reference && d.reference.length > xeroConstants.INVOICE_REFERENCE_MAX_LENGTH) {
        errors.push(messages.referenceBillLengthErrorText);
    }

    return errors;
};

export const validateXeroRequest = (state: State, request: domain.XeroRequest) => {
    switch (request.integrationCode) {
        case domain.IntegrationCode.XeroPo:
            return validateXeroPurchaseOrder(state, request);

        case domain.IntegrationCode.XeroQuote:
            return validateXeroQuote(state, request);

        case domain.IntegrationCode.XeroInvoice:
            return validateXeroSalesInvoice(state, request);

        case domain.IntegrationCode.XeroBill:
            return validateXeroBill(state, request);

        case domain.IntegrationCode.XeroContact:
            return validateXeroContact(state, request);

        case domain.IntegrationCode.XeroBillBatchPayment:
            return validateXeroBillBatchPayment(state, request);

        case domain.IntegrationCode.XeroAirwallexBatchPayment:
            return validateXeroAirwallexBatchPayment(state, request);

        case domain.IntegrationCode.XeroAmaxPayBatchPayment:
            return validateXeroAmaxPayBatchPayment(request);

        case domain.IntegrationCode.XeroCreditNotesPayable:
        case domain.IntegrationCode.XeroCreditNotesReceivable:
            return validateCreditNotes(state, request);

        case domain.IntegrationCode.XeroManualJournal:
            return [];

        default:
            throw errorHelpers.notSupportedError();
    }
};
