import { atom } from 'jotai';
import { AddonPromotionType } from 'shared/states';

import { TryPayOrCapturePopupInfoState, TryPayOrCapturePopupOrigin } from './TryPayOrCapturePopupContent.types';

export const tryPayOrCapturePopupOpenState = atom(false);

export const tryPayOrCapturePopupInfoState = atom<TryPayOrCapturePopupInfoState>({
    type: AddonPromotionType.Pay,
    origin: TryPayOrCapturePopupOrigin.ViewForm,
});

export const tryPayOrCapturePopupCloseCallbackState = atom<VoidFunction | null>(null);
