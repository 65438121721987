import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('app.WorkflowIdPage.XeroPoStartStep', {
    bubbleText: 'Creation / Pulling of {pluralTemplateName}',
    title: `
        There are several methods to create or pull {pluralTemplateName} in ApprovalMax.
        Please see the options below and choose as applicable.
    `,
    createInApprovalMax: 'Create in ApprovalMax',
    description: 'Add users authorised to create {pluralTemplateName} and submit them for approval in ApprovalMax.',
    descriptionEmpty:
        'Add at least one user authorised to create {pluralTemplateName} and submit them for approval in ApprovalMax.',
    externalSubmitterDescriptionNonEmpty:
        'All "{templateName}" requests submitted directly in Xero will be created in the name of this ApprovalMax user.',
    externalSubmitterDescriptionEmpty: `
        Please select a Xero Requester.\n
        All "{templateName}" requests submitted directly in Xero will be created in the name of this ApprovalMax user.`,
    emailExternalSubmitterTitle:
        'ApprovalMax will create a new purchase order for each email attachment sent to the email address below.',
    emailExternaSubmitterDescriptionNonEmpty: `
        {pluralTemplateName} will be created in ApprovalMax on behalf of the default requester. 
        Only the default requestor can see the incoming requests and submit them for approval.`,
    emailExternalSubmitterDescriptionEmpty: `
        Enter the user who will be notified about {templateName} status changes in ApprovalMax.\n
        A generic email address, e.g. finance@mycompany.com, can also be used.`,
});
