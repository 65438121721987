import { RequestMutationOptions } from '@approvalmax/data';
import { useMutate } from 'shared/data';

import { companiesApiPaths } from '../paths';
import { useInvalidateGetCompanies } from '../useGetCompanies/useGetCompanies';
import { CreateCompany } from './useCreateCompany.types';

export const useCreateCompany = (mutationOptions?: RequestMutationOptions<CreateCompany<true>>) => {
    const invalidateGetCompanies = useInvalidateGetCompanies();

    return useMutate(companiesApiPaths.create, {
        mutationOptions: {
            ...mutationOptions,
            onSettled: async (data, error, variables, context) => {
                await invalidateGetCompanies({ query: { companyId: data?.companies?.[0]?.companyId } });

                mutationOptions?.onSettled?.(data, error, variables, context);
            },
        },
        apiVersion: 'v1',
        mapToCamelCase: true,
    });
};

export const useCreateCompanyRaw = (mutationOptions?: RequestMutationOptions<CreateCompany>) => {
    const invalidateGetCompanies = useInvalidateGetCompanies();

    return useMutate(companiesApiPaths.create, {
        mutationOptions: {
            ...mutationOptions,
            onSettled: async (data, error, variables, context) => {
                await invalidateGetCompanies({ query: { companyId: data?.Companies?.[0]?.CompanyId } });

                mutationOptions?.onSettled?.(data, error, variables, context);
            },
        },
        apiVersion: 'v1',
    });
};
