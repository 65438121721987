import { ExtractComponentProp } from '@approvalmax/types';
import { Menu, toast } from '@approvalmax/ui/src/components';
import { useAtom, useAtomValue } from 'jotai';
import { selectors } from 'modules/common';
import { domain } from 'modules/data';
import { useDispatch, useSelector } from 'modules/react-redux';
import { isGrnAvailable } from 'modules/utils';
import { useCallback, useMemo, useState } from 'react';
import { useMount, useUnmount } from 'react-use';
import { usePullIntegrations } from 'shared/data/webApp/v1';
import { emailToSupplierHelpers, isAllFeaturesTrialStartAllowed, isOcrEnabledForTemplate } from 'shared/helpers';
import { useCompanyBetaFeatures } from 'shared/hooks';
import { activeCompanyIdState } from 'shared/states';

import { applyTemplateSettings, changeOcrCaptureViaEmailSettings } from '../../actions';
import { getActiveSettings, getActiveTemplate, isPageReadonly } from '../../selectors/pageSelectors';
import { SectionBalanceControlCheck } from './components/SectionBalanceControlCheck/SectionBalanceControlCheck';
import { SectionCaptureViaEmail } from './components/SectionCaptureViaEmail/SectionCaptureViaEmail';
import SectionDearFraudBypassing from './components/SectionDearFraudBypassing/SectionDearFraudBypassing';
import SectionDearFraudChangesAfter from './components/SectionDearFraudChangesAfter/SectionDearFraudChangesAfter';
import SectionDearPORejectedNoteToMemo from './components/SectionDearPORejectedNoteToMemo/SectionDearPORejectedNoteToMemo';
import SectionDecisionPolicy from './components/SectionDecisionPolicy/SectionDecisionPolicy';
import { SectionEmailToSupplier } from './components/SectionEmailToSupplier/SectionEmailToSupplier';
import { SectionFraudBypassing } from './components/SectionFraudBypassing/SectionFraudBypassing';
import SectionFraudChangesAfter from './components/SectionFraudChangesAfter/SectionFraudChangesAfter';
import { SectionGoodsReceivedNote } from './components/SectionGoodsReceivedNote/SectionGoodsReceivedNote';
import SectionHistoryPushing from './components/SectionHistoryPushing/SectionHistoryPushing';
import SectionInstructionForRequester from './components/SectionInstructionForRequester/SectionInstructionForRequester';
import SectionLineItemSettings from './components/SectionLineItemSettings/SectionLineItemSettings';
import { SectionLockDate } from './components/SectionLockDate/SectionLockDate';
import SectionNetSuiteAmaxUrl from './components/SectionNetSuiteAmaxUrl/SectionNetSuiteAmaxUrl';
import SectionNetSuiteAvailableLineItemType from './components/SectionNetSuiteAvailableLineItemType/SectionNetSuiteAvailableLineItemType';
import { SectionNetSuiteCustomFormId } from './components/SectionNetSuiteCustomFormId/SectionNetSuiteCustomFormId';
import SectionNetSuiteExpenseReportFraudChangesAfter from './components/SectionNetSuiteExpenseReportFraudChangesAfter/SectionNetSuiteExpenseReportFraudChangesAfter';
import SectionNetSuiteFraudBypassing from './components/SectionNetSuiteFraudBypassing/SectionNetSuiteFraudBypassing';
import SectionNetSuiteFraudChangesAfter from './components/SectionNetSuiteFraudChangesAfter/SectionNetSuiteFraudChangesAfter';
import SectionPostingPreferencesType from './components/SectionPostingPreferencesType/SectionPostingPreferencesType';
import SectionPriceChecker from './components/SectionPriceChecker/SectionPriceChecker';
import { SectionQboBillMatching } from './components/SectionQboBillMatching/SectionQboBillMatching';
import SectionQboFraudBypassing from './components/SectionQboFraudBypassing/SectionQboFraudBypassing';
import { SectionQboFraudChangesAfter } from './components/SectionQboFraudChangesAfter/SectionQboFraudChangesAfter';
import SectionSupplierBankAccountRequired from './components/SectionSupplierBankAccountRequired/SectionSupplierBankAccountRequired';
import SectionTerms from './components/SectionTerms/SectionTerms';
import SectionUrlOverriding from './components/SectionUrlOverriding/SectionUrlOverriding';
import SectionUseRejectedPrefix from './components/SectionUseRejectedPrefix/SectionUseRejectedPrefix';
import { SectionXeroBillMatching } from './components/SectionXeroBillMatching/SectionXeroBillMatching';
import { SectionXeroPOMatching } from './components/SectionXeroPOMatching/SectionXeroPOMatching';
import { settingsScheme } from './WorkflowSettingsPopupContent.constants';
import { getNewApprovalDisregardDetectionSettings } from './WorkflowSettingsPopupContent.helpers';
import { messages } from './WorkflowSettingsPopupContent.messages';
import { workflowSettingsPopupDefaultTabState } from './WorkflowSettingsPopupContent.states';
import {
    CheckValidFraudBypassing,
    ESettingsSection,
    SettingTabs,
    UseEffectiveDateLimits,
    WorkflowSettingsPopupSectionProps,
    WorkflowSettingsSections,
} from './WorkflowSettingsPopupContent.types';

export const useEffectiveDateLimits: UseEffectiveDateLimits = (templateSettings) => {
    return useMemo(() => {
        if (templateSettings?.approvalDisregardDetectionSettings) {
            return selectors.template.getTemplateSettingsFraudEffectiveDateLimits(
                templateSettings.approvalDisregardDetectionSettings.effectiveDate
            );
        }

        if (templateSettings?.netSuiteApprovalDisregardDetectionSettings) {
            return selectors.template.getTemplateSettingsFraudEffectiveDateLimits(
                templateSettings.netSuiteApprovalDisregardDetectionSettings.effectiveDate
            );
        }

        if (templateSettings?.dearApprovalDisregardDetectionSettings) {
            return selectors.template.getTemplateSettingsFraudEffectiveDateLimits(
                templateSettings.dearApprovalDisregardDetectionSettings.effectiveDate
            );
        }

        if (templateSettings?.qBooksApprovalDisregardDetectionSettings) {
            return selectors.template.getTemplateSettingsFraudEffectiveDateLimits(
                templateSettings.qBooksApprovalDisregardDetectionSettings.effectiveDate
            );
        }

        return null;
    }, [templateSettings]);
};

export const useWorkflowSettingsTemplate = () => {
    const activeTemplate = useSelector(getActiveTemplate);

    return useSelector((state) =>
        activeTemplate ? selectors.template.getTemplateById(state, activeTemplate.id) : null
    );
};

export const useWorkflowSettingsPopupData = () => {
    const activeCompanyId = useAtomValue(activeCompanyIdState);
    const company = useSelector((state) => selectors.company.getCompanyById(state, activeCompanyId));
    const savedTemplateSettings = useSelector((state) => {
        const activeSettings = getActiveSettings(state);

        return activeSettings ? activeSettings.data : null;
    });
    const readonly = useSelector(isPageReadonly);
    const me = useSelector((state) => selectors.profile.getProfileUser(state));
    const role = useSelector(() => (company && me ? selectors.company.getCompanyUserRole(company, me.id) : null));

    const isSaveDisabled = role === domain.CompanyUserRole.Auditor;

    return {
        company,
        savedTemplateSettings,
        readonly,
        isSaveDisabled,
    };
};

export const useTabLabels = (integrationCode: domain.IntegrationCode | null) => {
    return useMemo(() => {
        let emailLabel = '';

        switch (integrationCode) {
            case domain.IntegrationCode.XeroQuote:
            case domain.IntegrationCode.XeroInvoice:
            case domain.IntegrationCode.QBooksInvoice:
                emailLabel = messages.emailToCustomerTab;
                break;
            case domain.IntegrationCode.QBooksPo:
                emailLabel = messages.emailToVendorTab;
                break;

            default:
                emailLabel = messages.emailToSupplierTab;
                break;
        }

        return {
            [SettingTabs.General]: messages.generalTab,
            [SettingTabs.Matching]: messages.matchingTab,
            [SettingTabs.FraudDetection]: messages.fraudDetectionTab,
            [SettingTabs.EmailToSupplier]: emailLabel,
            [SettingTabs.Capture]: messages.captureTab,
        };
    }, [integrationCode]);
};

export const useCheckValidFraudBypassing = (templateSettings: domain.TemplateSettings | null) => {
    const checkValidFraudBypassing: CheckValidFraudBypassing = useCallback(
        (integrationType) => {
            let fraudBypassing;

            switch (integrationType) {
                case domain.IntegrationType.Xero:
                    fraudBypassing = templateSettings?.approvalDisregardDetectionSettings;
                    break;

                case domain.IntegrationType.QBooks:
                    fraudBypassing = templateSettings?.qBooksApprovalDisregardDetectionSettings;
                    break;

                case domain.IntegrationType.NetSuite:
                    fraudBypassing = templateSettings?.netSuiteApprovalDisregardDetectionSettings;
                    break;

                case domain.IntegrationType.Dear:
                    fraudBypassing = templateSettings?.dearApprovalDisregardDetectionSettings;
                    break;
            }

            const invalidFraudBypassing = Boolean(
                fraudBypassing && fraudBypassing.enabled && !fraudBypassing.effectiveDate
            );

            return !invalidFraudBypassing;
        },
        [templateSettings]
    );

    return checkValidFraudBypassing;
};

export const useApplyAndClose = (templateSettings: domain.TemplateSettings | null) => {
    const dispatch = useDispatch();
    const template = useWorkflowSettingsTemplate();

    const { company, savedTemplateSettings } = useWorkflowSettingsPopupData();

    const { mutate: pullIntegrations } = usePullIntegrations();

    const isCaptureViaEmailEnable = isOcrEnabledForTemplate(company, template?.integrationCode);

    const checkValidFraudBypassing = useCheckValidFraudBypassing(templateSettings);

    return useCallback(() => {
        if (
            !checkValidFraudBypassing(domain.IntegrationType.Xero) ||
            !checkValidFraudBypassing(domain.IntegrationType.QBooks) ||
            !checkValidFraudBypassing(domain.IntegrationType.NetSuite)
        ) {
            toast.error(messages.requiredFieldsNotification);

            return;
        }

        if (!templateSettings || !savedTemplateSettings) {
            return;
        }

        const enabledSettings = Object.values(settingsScheme[template?.integrationCode || 'null']).flat();
        // TODO: Refactor this logic
        const newSettings = {
            templateId: template?.id,
            approveAllStepsEnabled: templateSettings.approveAllStepsEnabled,
            appUrlOverridingEnabled: templateSettings.appUrlOverridingEnabled,
        } as domain.TemplateSettings;

        if (enabledSettings.includes(ESettingsSection.LockDate)) {
            newSettings.lockDatePolicySettings = templateSettings.lockDatePolicySettings;
        }

        if (enabledSettings.includes(ESettingsSection.HistoryPushingType)) {
            newSettings.historyEventsPushingSettings = templateSettings.historyEventsPushingSettings;
        }

        if (enabledSettings.includes(ESettingsSection.SupplierBankAccountRequired)) {
            newSettings.isSupplierBankAccountRequired = templateSettings.isSupplierBankAccountRequired;
        }

        if (enabledSettings.includes(ESettingsSection.FraudBypassing)) {
            newSettings.approvalDisregardDetectionSettings = getNewApprovalDisregardDetectionSettings(
                savedTemplateSettings,
                templateSettings,
                domain.IntegrationType.Xero
            );
        }

        if (enabledSettings.includes(ESettingsSection.FraudChangesAfter)) {
            newSettings.postApprovalChangeDetectionSettings = {
                ...templateSettings.postApprovalChangeDetectionSettings!,
                notifyAdmins: true,
            };
        }

        if (enabledSettings.includes(ESettingsSection.XeroBillMatching)) {
            let billMatchingSettings = templateSettings.billMatchingSettings;

            if (billMatchingSettings) {
                const isEnabledInsufficientBudgetMatch =
                    billMatchingSettings.allowApprovalOfNotMatchedBills ===
                        domain.CompanyMatchingSettingsBillApprovalPolicy.WithThreshold ||
                    billMatchingSettings.allowApprovalOfNotMatchedBills ===
                        domain.CompanyMatchingSettingsBillApprovalPolicy.Never;

                if (!isEnabledInsufficientBudgetMatch) {
                    billMatchingSettings = {
                        ...billMatchingSettings,
                        insufficientBudgetMatchBillsApprovalAllowed: domain.ApprovalAllowanceType.Always,
                        insufficientBudgetMatchBillsApprovalThreshold: 0,
                        insufficientBudgetMatchBillsApprovalPercentageThreshold: 0,
                    };
                }
            }

            newSettings.billMatchingSettings = billMatchingSettings;
        }

        if (enabledSettings.includes(ESettingsSection.XeroPOMatching)) {
            newSettings.purchaseOrderMatchingSettings = templateSettings.purchaseOrderMatchingSettings;
        }

        if (enabledSettings.includes(ESettingsSection.QBooksBillMatching)) {
            newSettings.billMatchingSettings = templateSettings.billMatchingSettings;
        }

        if (enabledSettings.includes(ESettingsSection.Instruction)) {
            newSettings.requesterInstructionSettings = templateSettings.requesterInstructionSettings;
        }

        if (enabledSettings.includes(ESettingsSection.EmailToSupplierSetting)) {
            let subject = emailToSupplierHelpers.removeRootParagraph(
                templateSettings.supplierEmailSettings.subject || ''
            );

            subject = emailToSupplierHelpers.cleanupSubjectString(subject);
            subject = emailToSupplierHelpers.returnExpressions(subject);

            newSettings.supplierEmailSettings = {
                state:
                    templateSettings.supplierEmailSettings.state || domain.TemplateSettingsEmailToSupplierState.Enabled,
                cc: templateSettings.supplierEmailSettings.cc || [],
                subject: emailToSupplierHelpers.removeMentionExtensionTags(subject),
                body: emailToSupplierHelpers.removeMentionExtensionTags(
                    templateSettings.supplierEmailSettings.body || ''
                ),
            };
        }

        if (enabledSettings.includes(ESettingsSection.LineItemSettings)) {
            newSettings.lineItemSettings = templateSettings.lineItemSettings;
        }

        if (enabledSettings.includes(ESettingsSection.NetSuiteFraudBypassing)) {
            newSettings.netSuiteApprovalDisregardDetectionSettings = getNewApprovalDisregardDetectionSettings(
                savedTemplateSettings,
                templateSettings,
                domain.IntegrationType.NetSuite
            );
        }

        if (enabledSettings.includes(ESettingsSection.NetSuiteCustomFormId)) {
            newSettings.netSuiteCustomFormId = templateSettings.netSuiteCustomFormId;
        }

        if (enabledSettings.includes(ESettingsSection.NetSuiteFraudChangesAfter)) {
            newSettings.netSuitePostApprovalChangeDetectionSettings = {
                ...templateSettings.netSuitePostApprovalChangeDetectionSettings!,
                notifyAdmins: true,
            };
        }

        if (enabledSettings.includes(ESettingsSection.NetSuiteExpenseReportFraudChangesAfter)) {
            newSettings.netSuitePostApprovalChangeDetectionSettings = {
                ...templateSettings.netSuitePostApprovalChangeDetectionSettings!,
                notifyAdmins: true,
            };
        }

        if (enabledSettings.includes(ESettingsSection.QBooksFraudBypassing)) {
            newSettings.qBooksApprovalDisregardDetectionSettings = getNewApprovalDisregardDetectionSettings(
                savedTemplateSettings,
                templateSettings,
                domain.IntegrationType.QBooks
            );

            const isPullingEnabledOld = savedTemplateSettings.qBooksApprovalDisregardDetectionSettings?.enabled;
            const isPullingEnabledNew = newSettings.qBooksApprovalDisregardDetectionSettings?.enabled;

            if (isPullingEnabledOld !== isPullingEnabledNew && isPullingEnabledNew && company.integrationId)
                pullIntegrations({
                    body: {
                        companyId: company.id,
                        integrationIds: [company.integrationId],
                    },
                });
        }

        if (enabledSettings.includes(ESettingsSection.QBooksFraudChangesAfter)) {
            newSettings.qBooksPostApprovalChangeDetectionSettings = {
                ...templateSettings.qBooksPostApprovalChangeDetectionSettings!,
                notifyAdmins: true,
            };
        }

        if (enabledSettings.includes(ESettingsSection.DearFraudBypassing)) {
            newSettings.dearApprovalDisregardDetectionSettings = getNewApprovalDisregardDetectionSettings(
                savedTemplateSettings,
                templateSettings,
                domain.IntegrationType.Dear
            );
        }

        if (enabledSettings.includes(ESettingsSection.DearFraudChangesAfter)) {
            newSettings.dearPostApprovalChangeDetectionSettings = {
                ...templateSettings.dearPostApprovalChangeDetectionSettings!,
                notifyAdmins: true,
            };
        }

        if (enabledSettings.includes(ESettingsSection.NetSuiteAvailableLineItemType)) {
            newSettings.netSuiteAvailableLineItemType =
                templateSettings.netSuiteAvailableLineItemType || domain.NetSuiteAvailableLineItemType.All;
        }

        if (enabledSettings.includes(ESettingsSection.DearPORejectedNoteToMemo)) {
            newSettings.sendRejectedNoteToDear = templateSettings.sendRejectedNoteToDear;
        }

        if (enabledSettings.includes(ESettingsSection.GoodsReceivedNote)) {
            newSettings.isGrnEnabled = templateSettings.isGrnEnabled;
        }

        if (enabledSettings.includes(ESettingsSection.PostingPreferencesType)) {
            newSettings.postingPreferencesType =
                templateSettings.postingPreferencesType || domain.PostingPreferencesType.AsDraft;
        }

        if (enabledSettings.includes(ESettingsSection.UseRejectedPrefix)) {
            newSettings.useRejectedPrefix = templateSettings.useRejectedPrefix || false;
        }

        if (enabledSettings.includes(ESettingsSection.Terms)) {
            newSettings.terms = templateSettings.terms;
        }

        if (enabledSettings.includes(ESettingsSection.PushApprovalMaxUrlToNetSuite)) {
            newSettings.pushApprovalMaxURLToNetSuite = templateSettings.pushApprovalMaxURLToNetSuite || false;
        }

        if (enabledSettings.includes(ESettingsSection.PriceChecker)) {
            newSettings.isPriceCheckerEnabled = templateSettings.isPriceCheckerEnabled;
        }

        if (enabledSettings.includes(ESettingsSection.CaptureViaEmail)) {
            newSettings.ocrEmailAddress = templateSettings.ocrEmailAddress;
            newSettings.ocrRequestInitialStatus = templateSettings.ocrRequestInitialStatus;
        }

        if (enabledSettings.includes(ESettingsSection.BalanceControlCheck)) {
            newSettings.isBalanceControlCheckEnabled = templateSettings.isBalanceControlCheckEnabled;
        }

        if (isCaptureViaEmailEnable) {
            dispatch(
                changeOcrCaptureViaEmailSettings({
                    ocrEmailAddress: newSettings.ocrEmailAddress,
                    ocrRequestInitialStatus: newSettings.ocrRequestInitialStatus,
                })
            );
        }

        dispatch(applyTemplateSettings(newSettings));
    }, [
        checkValidFraudBypassing,
        templateSettings,
        savedTemplateSettings,
        template?.integrationCode,
        template?.id,
        isCaptureViaEmailEnable,
        dispatch,
        company.integrationId,
        company.id,
        pullIntegrations,
    ]);
};

export const useInitSections = (
    templateSettings: domain.TemplateSettings | null,
    defaultProps: WorkflowSettingsPopupSectionProps
): WorkflowSettingsSections => {
    const template = useWorkflowSettingsTemplate();

    const { company, savedTemplateSettings, readonly } = useWorkflowSettingsPopupData();

    const effectiveDateLimits = useEffectiveDateLimits(savedTemplateSettings);

    const { isXeroCreditNoteAPcreation, isXeroCreditNoteARcreation } = useCompanyBetaFeatures(company.id);

    const links = selectors.links.getLinksByIntegration(
        company.integration?.integrationType,
        template?.integrationCode
    );

    const checkValidFraudBypassing = useCheckValidFraudBypassing(templateSettings);

    if (!template || !savedTemplateSettings || !templateSettings) return {};

    let showInstuctionSetting = true;

    if (template.integrationCode === domain.IntegrationCode.XeroCreditNotesPayable) {
        showInstuctionSetting = isXeroCreditNoteAPcreation;
    }

    if (template.integrationCode === domain.IntegrationCode.XeroCreditNotesReceivable) {
        showInstuctionSetting = isXeroCreditNoteARcreation;
    }

    const canShowFeaturesPromo =
        (company.integration?.integrationType === domain.IntegrationType.Xero ||
            company.integration?.integrationType === domain.IntegrationType.QBooks) &&
        isAllFeaturesTrialStartAllowed(company, company.integration?.integrationType);

    const isQboBillMatchingAvailable = selectors.company.getQBOMatchingIsAvailable(company);
    const isXeroPOMatchingAvailable = company.flags.isXeroMatchingV2;
    const isXeroBillMatchingAvailable = company.flags.isXeroMatchingV2 && templateSettings.billMatchingSettings;
    const isCaptureViaEmailEnable = isOcrEnabledForTemplate(company, template.integrationCode);

    return {
        [ESettingsSection.CaptureViaEmail]: isCaptureViaEmailEnable ? (
            <SectionCaptureViaEmail {...defaultProps} links={links} />
        ) : undefined,
        [ESettingsSection.LockDate]: (
            <SectionLockDate {...defaultProps} company={company} links={links} template={template} {...defaultProps} />
        ),

        [ESettingsSection.PostingPreferencesType]: (
            <SectionPostingPreferencesType {...defaultProps} links={links} readonly={readonly} />
        ),
        [ESettingsSection.LineItemSettings]: <SectionLineItemSettings {...defaultProps} links={links} />,
        [ESettingsSection.QBooksFraudBypassing]: (
            <SectionQboFraudBypassing
                {...defaultProps}
                links={links}
                template={template}
                savedTemplateSettings={savedTemplateSettings}
                effectiveDateLimits={effectiveDateLimits}
                checkValidFraudBypassing={checkValidFraudBypassing}
            />
        ),
        [ESettingsSection.QBooksFraudChangesAfter]: (
            <SectionQboFraudChangesAfter {...defaultProps} company={company} template={template} />
        ),
        [ESettingsSection.FraudBypassing]: (
            <SectionFraudBypassing
                {...defaultProps}
                company={company}
                template={template}
                savedTemplateSettings={savedTemplateSettings}
                effectiveDateLimits={effectiveDateLimits}
                checkValidFraudBypassing={checkValidFraudBypassing}
            />
        ),
        [ESettingsSection.NetSuiteFraudBypassing]: (
            <SectionNetSuiteFraudBypassing
                {...defaultProps}
                template={template}
                savedTemplateSettings={savedTemplateSettings}
                effectiveDateLimits={effectiveDateLimits}
                checkValidFraudBypassing={checkValidFraudBypassing}
            />
        ),
        [ESettingsSection.DearFraudBypassing]: (
            <SectionDearFraudBypassing
                {...defaultProps}
                template={template}
                links={links}
                savedTemplateSettings={savedTemplateSettings}
                effectiveDateLimits={effectiveDateLimits}
                checkValidFraudBypassing={checkValidFraudBypassing}
            />
        ),
        [ESettingsSection.FraudChangesAfter]: <SectionFraudChangesAfter {...defaultProps} company={company} />,
        [ESettingsSection.NetSuiteExpenseReportFraudChangesAfter]:
            templateSettings.netSuitePostApprovalChangeDetectionSettings && (
                <SectionNetSuiteExpenseReportFraudChangesAfter {...defaultProps} company={company} />
            ),
        [ESettingsSection.NetSuiteCustomFormId]: <SectionNetSuiteCustomFormId {...defaultProps} />,
        [ESettingsSection.NetSuiteFraudChangesAfter]: templateSettings.netSuitePostApprovalChangeDetectionSettings && (
            <SectionNetSuiteFraudChangesAfter
                {...defaultProps}
                company={company}
                integrationCode={template.integrationCode}
            />
        ),
        [ESettingsSection.DearFraudChangesAfter]: templateSettings.dearPostApprovalChangeDetectionSettings && (
            <SectionDearFraudChangesAfter {...defaultProps} links={links} company={company} />
        ),
        [ESettingsSection.HistoryPushingType]: <SectionHistoryPushing {...defaultProps} />,
        [ESettingsSection.XeroBillMatching]: (
            <>
                {isXeroBillMatchingAvailable && <SectionXeroBillMatching {...defaultProps} company={company} />}

                {!isXeroBillMatchingAvailable && canShowFeaturesPromo && (
                    <SectionXeroBillMatching promoView company={company} />
                )}
            </>
        ),

        [ESettingsSection.XeroPOMatching]: (
            <>
                {isXeroPOMatchingAvailable && <SectionXeroPOMatching {...defaultProps} />}

                {!isXeroPOMatchingAvailable && canShowFeaturesPromo && (
                    <SectionXeroPOMatching promoView company={company} />
                )}
            </>
        ),
        [ESettingsSection.Instruction]: showInstuctionSetting ? (
            <SectionInstructionForRequester {...defaultProps} template={template} links={links} />
        ) : undefined,
        [ESettingsSection.EmailToSupplierSetting]: (
            <SectionEmailToSupplier {...defaultProps} company={company} links={links} template={template} />
        ),
        [ESettingsSection.Terms]: <SectionTerms {...defaultProps} />,
        [ESettingsSection.DecisionPolicy]: <SectionDecisionPolicy {...defaultProps} links={links} />,
        [ESettingsSection.OverrideGoToBtnInXero]: <SectionUrlOverriding {...defaultProps} />,

        [ESettingsSection.UseRejectedPrefix]: <SectionUseRejectedPrefix {...defaultProps} links={links} />,
        [ESettingsSection.QBooksBillMatching]: (
            <>
                {isQboBillMatchingAvailable && <SectionQboBillMatching {...defaultProps} />}

                {!isQboBillMatchingAvailable && canShowFeaturesPromo && (
                    <SectionQboBillMatching promoView company={company} />
                )}
            </>
        ),
        [ESettingsSection.SupplierBankAccountRequired]: (
            <SectionSupplierBankAccountRequired {...defaultProps} links={links} />
        ),
        [ESettingsSection.NetSuiteAvailableLineItemType]: (
            <SectionNetSuiteAvailableLineItemType
                {...defaultProps}
                learnMoreLink={links[selectors.types.Link.LineItems]}
            />
        ),
        [ESettingsSection.DearPORejectedNoteToMemo]: <SectionDearPORejectedNoteToMemo {...defaultProps} />,
        [ESettingsSection.GoodsReceivedNote]: (
            <>
                {isGrnAvailable(company) && <SectionGoodsReceivedNote {...defaultProps} links={links} />}

                {!isGrnAvailable(company) && canShowFeaturesPromo && (
                    <SectionGoodsReceivedNote promoView company={company} links={links} />
                )}
            </>
        ),
        [ESettingsSection.PushApprovalMaxUrlToNetSuite]: (
            <SectionNetSuiteAmaxUrl {...defaultProps} learnMoreLink={links[selectors.types.Link.AmaxUrlFieldUpdate]} />
        ),
        [ESettingsSection.PriceChecker]: <SectionPriceChecker {...defaultProps} links={links} />,
        [ESettingsSection.BalanceControlCheck]: <SectionBalanceControlCheck {...defaultProps} />,
    };
};

export const useInitialTemplateSettings = (
    savedTemplateSettings: domain.TemplateSettings | null,
    setTemplateSettings: (settings: domain.TemplateSettings | null) => void
) => {
    useMount(() => {
        if (!savedTemplateSettings) return;

        const supplierEmailSettings = { ...savedTemplateSettings.supplierEmailSettings };

        if (!supplierEmailSettings.state) {
            setTemplateSettings(savedTemplateSettings);

            return;
        }

        if (!Object.values(domain.TemplateSettingsEmailToSupplierState).includes(supplierEmailSettings.state)) {
            supplierEmailSettings.state = domain.TemplateSettingsEmailToSupplierState.Enabled;
        }

        setTemplateSettings({ ...savedTemplateSettings, supplierEmailSettings });
    });
};

export const useWorkflowSettingsPopupTabs = (settingSections: WorkflowSettingsSections) => {
    const template = useWorkflowSettingsTemplate();
    const labels = useTabLabels(template?.integrationCode || null);

    const [tabDefault, setTabDefault] = useAtom(workflowSettingsPopupDefaultTabState);
    const [tabSelected, setTabSelected] = useState(tabDefault);
    const index = template?.integrationCode || 'null';
    const settings = settingsScheme[index];

    const settingsSelected = settings[tabSelected];

    const tabs = useMemo<ExtractComponentProp<typeof Menu, 'items'>>(() => {
        const settingsTabs = Object.keys(settings) as SettingTabs[];

        const filteredSettingsTabs = settingsTabs.filter((tab) =>
            settingsScheme[index][tab]?.some((setting) => settingSections[setting])
        );

        return filteredSettingsTabs.map((tab) => ({
            id: tab,
            name: labels[tab],
            color: 'midnight80',
            active: tab === tabSelected,
            onClick: () => setTabSelected(tab),
        }));
    }, [index, labels, settingSections, settings, tabSelected]);

    useUnmount(() => {
        setTabDefault(SettingTabs.General);
    });

    return {
        tabs,
        settingsSelected,
    };
};
