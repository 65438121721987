import { domain } from 'modules/data';
import { useMemo } from 'react';
import { findDocumentFieldState } from 'shared/helpers';

export const useNetSuiteAttachmentsFieldSettings = (documentFields: domain.DocumentField[] | undefined) =>
    useMemo(
        () => ({
            attachments: findDocumentFieldState(documentFields, 'Attachments'),
        }),
        [documentFields]
    );
