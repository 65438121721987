import { Box, Checkbox, ColumnDefinition, Grid, Link, Table, Text } from '@approvalmax/ui/src/components';
import { intl } from '@approvalmax/utils';
import moment from 'moment';
import { noValue } from 'shared/constants';
import { getPath, Path } from 'urlBuilder';

import { OpacityWrapper } from '../OpacityWrapper/OpacityWrapper.styles';
import { messages } from './BillInvoiceTableGroupedBySupplier.messages';
import {
    GetColumnsParams,
    ItemRole,
    SelectableSupplierBillItem,
    TableColumn,
} from './BillInvoiceTableGroupedBySupplier.types';

export const getColumns = ({
    companyId,
    toggleAllBills,
    allBillsAreSelected,
    toggleBill,
    toggleAllBillsBySupplierId,
}: GetColumnsParams): ColumnDefinition<SelectableSupplierBillItem>[] => [
    {
        id: TableColumn.Name,
        sticky: 'left',
        spacing: '12 16',
        minWidth: 220,
        columnComponent: (props) => (
            <Table.ColumnHeader {...props}>
                <Grid maxWidth={300} gridAutoFlow='column' justifyContent='start' columnGap='16px'>
                    <Checkbox
                        onChange={toggleAllBills}
                        checked={allBillsAreSelected}
                        size='small'
                        data-qa='table-row-header-checkbox-grouped'
                    />

                    <Text font='body' ellipsis={1} title={messages.nameColumnName}>
                        {messages.nameColumnName}
                    </Text>
                </Grid>
            </Table.ColumnHeader>
        ),
        cellComponent: (props) => (
            <Table.Cell {...props}>
                <Grid maxWidth={280} gridAutoFlow='column' justifyContent='start' columnGap='16px'>
                    {props.item.itemRole === ItemRole.BillItem && (
                        <>
                            <Box width={20} />

                            <Checkbox
                                onChange={(_checked: boolean) => {
                                    toggleBill(props.item.xeroBillInvoiceRequestId);
                                }}
                                checked={props.item.selected}
                                size='small'
                                data-qa='table-row-checkbox-grouped'
                                data-qa-id={props.item.id}
                            />

                            <OpacityWrapper $shouldApplyOpacity={props.item.amount === 0}>
                                <Link
                                    font='body'
                                    href={getPath(Path.request, props.item.xeroBillInvoiceRequestId, companyId)}
                                    external
                                >
                                    <Text font='body' ellipsis={1}>
                                        {props.item.friendlyName}
                                    </Text>
                                </Link>
                            </OpacityWrapper>
                        </>
                    )}

                    {props.item.itemRole === ItemRole.SupplierName && props.item.contact && (
                        <>
                            <Checkbox
                                onChange={(_checked: boolean) => {
                                    toggleAllBillsBySupplierId(props.item.id);
                                }}
                                checked={props.item.selected}
                                size='small'
                            />

                            <Text font='body' fontSize='small' fontWeight='medium' ellipsis={1}>
                                {props.item.contact.text}
                            </Text>
                        </>
                    )}
                </Grid>
            </Table.Cell>
        ),
    },
    {
        id: TableColumn.Date,
        minWidth: 150,
        value: (item) => (item.itemRole === ItemRole.BillItem ? moment(item.date).format('LL') : ''),
        cellComponent: (props) => (
            <Table.Cell {...props}>
                <OpacityWrapper
                    $shouldApplyOpacity={props.item.amount === 0 && props.item.itemRole === ItemRole.BillItem}
                >
                    <Box maxWidth={280}>
                        <Text font='body' ellipsis={1}>
                            {props.value}
                        </Text>
                    </Box>
                </OpacityWrapper>
            </Table.Cell>
        ),
        columnComponent: (props) => (
            <Table.ColumnHeader {...props}>
                <Box maxWidth={280}>
                    <Text font='body' ellipsis={1}>
                        {messages.dateColumnName}
                    </Text>
                </Box>
            </Table.ColumnHeader>
        ),
    },
    {
        id: TableColumn.DueDate,
        minWidth: 150,
        value: (item) => (item.itemRole === ItemRole.BillItem ? moment(item.dueDate).format('LL') : ''),
        cellComponent: (props) => (
            <Table.Cell {...props}>
                <Box maxWidth={280}>
                    <OpacityWrapper
                        $shouldApplyOpacity={props.item.amount === 0 && props.item.itemRole === ItemRole.BillItem}
                    >
                        <Text font='body' ellipsis={1}>
                            {props.value}
                        </Text>
                    </OpacityWrapper>
                </Box>
            </Table.Cell>
        ),
        columnComponent: (props) => (
            <Table.ColumnHeader {...props}>
                <Box maxWidth={280}>
                    <Text font='body' ellipsis={1}>
                        {messages.dueDateColumnName}
                    </Text>
                </Box>
            </Table.ColumnHeader>
        ),
    },
    {
        id: TableColumn.PlannedDate,
        minWidth: 150,
        value: (item) =>
            item.itemRole === ItemRole.BillItem
                ? item.plannedDate
                    ? moment(item.plannedDate).format('LL')
                    : noValue
                : '',
        cellComponent: (props) => (
            <Table.Cell {...props}>
                <Box maxWidth={280}>
                    <OpacityWrapper
                        $shouldApplyOpacity={props.item.amount === 0 && props.item.itemRole === ItemRole.BillItem}
                    >
                        <Text font='body' ellipsis={1}>
                            {props.value}
                        </Text>
                    </OpacityWrapper>
                </Box>
            </Table.Cell>
        ),

        columnComponent: (props) => (
            <Table.ColumnHeader {...props}>
                <Box maxWidth={280}>
                    <Text font='body' ellipsis={1}>
                        {messages.plannedDateColumnName}
                    </Text>
                </Box>
            </Table.ColumnHeader>
        ),
    },

    {
        id: TableColumn.Current,
        minWidth: 100,
        value: (item) =>
            item.itemRole === ItemRole.BillItem
                ? item.tableColumn === TableColumn.Current
                    ? intl.formatCurrency(item.amountDue, item.currency)
                    : noValue
                : '',
        cellComponent: (props) => (
            <Table.Cell {...props}>
                <Box maxWidth={280}>
                    <OpacityWrapper
                        $shouldApplyOpacity={props.item.amount === 0 && props.item.itemRole === ItemRole.BillItem}
                    >
                        <Text font='body' ellipsis={1}>
                            {props.value}
                        </Text>
                    </OpacityWrapper>
                </Box>
            </Table.Cell>
        ),
        columnComponent: (props) => (
            <Table.ColumnHeader {...props}>
                <Box maxWidth={280}>
                    <Text font='body' ellipsis={1}>
                        {messages.currentColumnName}
                    </Text>
                </Box>
            </Table.ColumnHeader>
        ),
    },
    {
        id: TableColumn.LessThanOneMonth,
        minWidth: 100,
        value: (item) =>
            item.itemRole === ItemRole.BillItem
                ? item.tableColumn === TableColumn.LessThanOneMonth
                    ? intl.formatCurrency(item.amountDue, item.currency)
                    : noValue
                : '',
        cellComponent: (props) => (
            <Table.Cell {...props}>
                <Box maxWidth={280}>
                    <OpacityWrapper
                        $shouldApplyOpacity={props.item.amount === 0 && props.item.itemRole === ItemRole.BillItem}
                    >
                        <Text font='body' ellipsis={1}>
                            {props.value}
                        </Text>
                    </OpacityWrapper>
                </Box>{' '}
            </Table.Cell>
        ),
        columnComponent: (props) => (
            <Table.ColumnHeader {...props}>
                <Box maxWidth={280}>
                    <Text font='body' ellipsis={1}>
                        {messages.lessThanOneMonthColumnName}
                    </Text>
                </Box>
            </Table.ColumnHeader>
        ),
    },
    {
        id: TableColumn.OneMonth,
        minWidth: 100,
        value: (item) =>
            item.itemRole === ItemRole.BillItem
                ? item.tableColumn === TableColumn.OneMonth
                    ? intl.formatCurrency(item.amountDue, item.currency)
                    : noValue
                : '',
        cellComponent: (props) => (
            <Table.Cell {...props}>
                <Box maxWidth={280}>
                    <OpacityWrapper
                        $shouldApplyOpacity={props.item.amount === 0 && props.item.itemRole === ItemRole.BillItem}
                    >
                        <Text font='body' ellipsis={1}>
                            {props.value}
                        </Text>
                    </OpacityWrapper>
                </Box>
            </Table.Cell>
        ),
        columnComponent: (props) => (
            <Table.ColumnHeader {...props}>
                <Box maxWidth={280}>
                    <Text font='body' ellipsis={1}>
                        {messages.oneMonthColumnName}
                    </Text>
                </Box>
            </Table.ColumnHeader>
        ),
    },
    {
        id: TableColumn.TwoMonths,
        minWidth: 100,
        value: (item) =>
            item.itemRole === ItemRole.BillItem
                ? item.tableColumn === TableColumn.TwoMonths
                    ? intl.formatCurrency(item.amountDue, item.currency)
                    : noValue
                : '',
        cellComponent: (props) => (
            <Table.Cell {...props}>
                <Box maxWidth={280}>
                    <OpacityWrapper
                        $shouldApplyOpacity={props.item.amount === 0 && props.item.itemRole === ItemRole.BillItem}
                    >
                        <Text font='body' ellipsis={1}>
                            {props.value}
                        </Text>
                    </OpacityWrapper>
                </Box>
            </Table.Cell>
        ),
        columnComponent: (props) => (
            <Table.ColumnHeader {...props}>
                <Box maxWidth={280}>
                    <Text font='body' ellipsis={1}>
                        {messages.twoMonthsColumnName}
                    </Text>
                </Box>
            </Table.ColumnHeader>
        ),
    },
    {
        id: TableColumn.ThreeMonths,
        minWidth: 100,
        value: (item) =>
            item.itemRole === ItemRole.BillItem
                ? item.tableColumn === TableColumn.ThreeMonths
                    ? intl.formatCurrency(item.amountDue, item.currency)
                    : noValue
                : '',
        cellComponent: (props) => (
            <Table.Cell {...props}>
                <Box maxWidth={280}>
                    <OpacityWrapper
                        $shouldApplyOpacity={props.item.amount === 0 && props.item.itemRole === ItemRole.BillItem}
                    >
                        <Text font='body' ellipsis={1}>
                            {props.value}
                        </Text>
                    </OpacityWrapper>
                </Box>
            </Table.Cell>
        ),
        columnComponent: (props) => (
            <Table.ColumnHeader {...props}>
                <Box maxWidth={280}>
                    <Text font='body' ellipsis={1}>
                        {messages.threeMonthsColumnName}
                    </Text>
                </Box>
            </Table.ColumnHeader>
        ),
    },
    {
        id: TableColumn.Older,
        minWidth: 100,
        value: (item) =>
            item.itemRole === ItemRole.BillItem
                ? item.tableColumn === TableColumn.Older
                    ? intl.formatCurrency(item.amountDue, item.currency)
                    : noValue
                : '',
        cellComponent: (props) => (
            <Table.Cell {...props}>
                <Box maxWidth={280}>
                    <OpacityWrapper
                        $shouldApplyOpacity={props.item.amount === 0 && props.item.itemRole === ItemRole.BillItem}
                    >
                        <Text font='body' ellipsis={1}>
                            {props.value}
                        </Text>
                    </OpacityWrapper>
                </Box>
            </Table.Cell>
        ),
        columnComponent: (props) => (
            <Table.ColumnHeader {...props}>
                <Box maxWidth={280}>
                    <Text font='body' ellipsis={1}>
                        {messages.olderColumnName}
                    </Text>
                </Box>
            </Table.ColumnHeader>
        ),
    },

    {
        id: TableColumn.AmountDue,
        sticky: 'right',
        spacing: '12 16',
        minWidth: 120,
        value: (item) =>
            item.itemRole === ItemRole.BillItem ? intl.formatCurrency(item.amountDue, item.currency) : '',
        cellComponent: (props) => (
            <Table.Cell {...props}>
                <Box maxWidth={280}>
                    <OpacityWrapper
                        $shouldApplyOpacity={props.item.amount === 0 && props.item.itemRole === ItemRole.BillItem}
                    >
                        <Text font='body' ellipsis={1}>
                            {props.value}
                        </Text>
                    </OpacityWrapper>
                </Box>
            </Table.Cell>
        ),
        columnComponent: (props) => (
            <Table.ColumnHeader {...props}>
                <Box maxWidth={280}>
                    <Text font='body' ellipsis={1}>
                        {messages.amountDueColumnName}
                    </Text>
                </Box>
            </Table.ColumnHeader>
        ),
    },
];
