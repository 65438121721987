import { Mods, mods, theme } from '@approvalmax/theme';
import { StyledMods } from '@styled-kit/mods';
import styled from 'styled-components';

import { LayoutWithSidebarProps } from './LayoutWithSidebar.types';

export const Root = styled.div<StyledMods<Pick<LayoutWithSidebarProps, 'bordered'> & Mods<'orientation'>>>`
    display: flex;
    height: 100%;
    overflow: hidden;
    position: relative;

    ${mods.bordered.true`
        border-top: 1px solid ${theme.color.midnight50};
    `}

    ${mods.orientation.vertical`
        flex-direction: column;
    `}
`;
