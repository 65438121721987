import { enabledByParams, RequestParams, RequestQueryOptions } from '@approvalmax/data';
import { useGet } from 'shared/data';

import { integrationApiPaths } from '../paths';
import { GetNetSuiteIncoterms } from './useGetNetSuiteIncoterms.types';

export const useGetNetSuiteIncoterms = (
    params?: RequestParams<GetNetSuiteIncoterms>,
    queryOptions?: RequestQueryOptions<GetNetSuiteIncoterms>
) => {
    return useGet(integrationApiPaths.getNetSuiteIncoterms, {
        params,
        queryOptions: {
            ...queryOptions,
            enabled: enabledByParams<GetNetSuiteIncoterms['params']['query']>(
                {
                    companyId: params?.query?.companyId,
                    requestId: params?.query?.requestId,
                    ruleType: params?.query?.ruleType,
                },
                queryOptions
            ),
        },
        apiVersion: 'v1',
        mapToCamelCase: true,
    });
};
