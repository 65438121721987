import { hooks, mathService } from '@approvalmax/utils';
import { domain } from 'modules/data';
import React, { FC, PropsWithChildren, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { notificationService } from 'services/notification';

import { MatchingData, PurchaseOrder } from '../../../../types';
import { messages } from './ChangeMatchingPopupContext.messages';

type UpdatedAllocation = Pick<PurchaseOrder, 'id' | 'allocatedAmount' | 'isMatched'>;

interface ChangeMatchingPopupContextType {
    onChangeAllocatedAmount: (id: string, amount: number | null) => void;
    onSelectPO: (id: string, selected: boolean) => void;
    getAllocatedAmountById: (id: string) => number;
    getIsMatchedById: (id: string) => boolean;
    save: () => Promise<void>;
    getIsWarningById: (id: string) => boolean;
    totalAllocatedAmount: number;
    matchingData: MatchingData;
    isSaving: boolean;
    billId: string;
    companyId: string;
}

const ChangeMatchingPopupContext = React.createContext<ChangeMatchingPopupContextType>(null as any);

const matchingDataEmpty = {
    totalAllocatedAmountToBilledPOs: 0,
    totalAmount: 0,
    currency: '',
    purchaseOrders: [],
    remaining: 0,
};

const isMatchFilter = (po: PurchaseOrder, filter: domain.XeroMatchingV2POFilter) => {
    const excludedByAmountGreaterEquals = filter.amountGreaterEquals && filter.amountGreaterEquals > po.totalAmount;
    const excludedByAmountLessEquals = filter.amountLessEquals && filter.amountLessEquals < po.totalAmount;
    const excludedByDateGreaterEquals =
        filter.dateGreaterEquals && new Date(filter.dateGreaterEquals) > new Date(po.date);
    const excludedByDateLessEquals = filter.dateLessEquals && new Date(filter.dateLessEquals) < new Date(po.date);
    const excludedByNumber = filter.number && !po.name.includes(filter.number);

    return !(
        excludedByAmountGreaterEquals ||
        excludedByAmountLessEquals ||
        excludedByDateGreaterEquals ||
        excludedByDateLessEquals ||
        excludedByNumber
    );
};

interface ChangeMatchingPopupContextProviderProps extends PropsWithChildren {
    fetchedMatchingData?: MatchingData;
    onSave: (matchingPurchaseOrders: PurchaseOrder[]) => Promise<void>;
    billId: string;
    appliedPOFilter: domain.XeroMatchingV2POFilter;
    companyId: string;
}

export const ChangeMatchingPopupContextProvider: FC<ChangeMatchingPopupContextProviderProps> = (props) => {
    const { children, fetchedMatchingData, onSave, billId, appliedPOFilter, companyId } = props;

    const [isSaving, setIsSaving] = useState(false);
    const [totalAmountFetched, setTotalAmountFetched] = useState(0);

    const [matchingData, setMatchingData] = useState<MatchingData>(matchingDataEmpty);
    const prevMatchingData = hooks.usePrevious(matchingData);
    const prevAppliedPOFilter = hooks.usePrevious(appliedPOFilter);

    // stores filtered and updated POs according to updatedAllocations
    const [filteredPurchaseOrders, setFilteredPurchaseOrders] = useState<PurchaseOrder[]>(
        matchingData?.purchaseOrders || []
    );

    // stores all POs from backend as is. There is no guarantee that first request to API
    // returns all POs, so we need have separate entity and fill it with incoming data
    const [allInitialPurchaseOrders, setAllInitialPurchaseOrders] = useState<PurchaseOrder[]>([]);

    // stores user modified allocations, that have not yet been sent to the backend
    const [updatedAllocations, setUpdatedAllocations] = useState<UpdatedAllocation[]>([]);

    useEffect(() => {
        if (fetchedMatchingData) {
            setMatchingData(fetchedMatchingData);
        }
    }, [fetchedMatchingData]);

    useEffect(() => {
        if (!totalAmountFetched && matchingData?.totalAmount) {
            setTotalAmountFetched(matchingData.totalAmount);
        }
    }, [matchingData, totalAmountFetched]);

    // fill up allInitialPurchaseOrders with data from backend
    useEffect(() => {
        if (!matchingData) {
            return;
        }

        // If we have many purchase orders (more than backend may return at once), we should collect all purchase orders
        // Purchase orders may be filtered, and if user press "Save", we should save not only filtered matching purchase
        // orders, but also purchase orders out of filter.
        const purchaseOrdersToAddToAllInitialPOs: PurchaseOrder[] = [];

        matchingData.purchaseOrders.forEach((po) => {
            const isCollected = !!allInitialPurchaseOrders.find((colletedPO) => colletedPO.id === po.id);

            if (!isCollected) {
                purchaseOrdersToAddToAllInitialPOs.push(po);
            }
        });

        if (purchaseOrdersToAddToAllInitialPOs.length) {
            setAllInitialPurchaseOrders([...allInitialPurchaseOrders, ...purchaseOrdersToAddToAllInitialPOs]);
        }
    }, [matchingData, allInitialPurchaseOrders.length, allInitialPurchaseOrders]);

    const save = useCallback(async () => {
        try {
            setIsSaving(true);

            const upToDatePurchaseOrders = allInitialPurchaseOrders.map((po) => {
                const updatedPO = updatedAllocations.find((allocation) => allocation.id === po.id);

                return {
                    ...po,
                    ...updatedPO,
                };
            });
            const matchedPurchaseOrders = upToDatePurchaseOrders.filter(({ isMatched }) => isMatched);
            const purchaseOrdersWithZeroAllocatedAmount = matchedPurchaseOrders.find((po) => po.allocatedAmount === 0);

            if (purchaseOrdersWithZeroAllocatedAmount) {
                setFilteredPurchaseOrders((prevFilteredPurchaseOrders) =>
                    prevFilteredPurchaseOrders.map((po) => ({
                        ...po,
                        warning: po.allocatedAmount === 0 && po.isMatched,
                    }))
                );
                setAllInitialPurchaseOrders((prevAllInitialPurchaseOrders) =>
                    prevAllInitialPurchaseOrders.map((po) => ({
                        ...po,
                        warning: po.allocatedAmount === 0 && po.isMatched,
                    }))
                );
                notificationService.showErrorToast(
                    messages.amountError({
                        name: purchaseOrdersWithZeroAllocatedAmount.name,
                    })
                );

                return;
            }

            await onSave(matchedPurchaseOrders);
        } finally {
            setIsSaving(false);
        }
    }, [allInitialPurchaseOrders, updatedAllocations, onSave]);

    const onChangeAllocatedAmount = useCallback(
        (id: string, allocatedAmount: number) => {
            setFilteredPurchaseOrders((prevMatchingPurchaseOrders) =>
                prevMatchingPurchaseOrders.map((po) => ({
                    ...po,
                    allocatedAmount: po.id === id ? allocatedAmount : po.allocatedAmount,
                    warning: false,
                }))
            );

            if (updatedAllocations.find((allocation) => allocation.id === id)) {
                setUpdatedAllocations((prevAllocations) =>
                    prevAllocations.map((prevAllocation) => ({
                        ...prevAllocation,
                        allocatedAmount: prevAllocation.id === id ? allocatedAmount : prevAllocation.allocatedAmount,
                    }))
                );
            } else {
                setUpdatedAllocations((prevAllocations) =>
                    prevAllocations.concat({
                        id,
                        allocatedAmount,
                        isMatched: true,
                    })
                );
            }
        },
        [updatedAllocations]
    );

    const onSelectPO = useCallback(
        (id: string, isMatched: boolean) => {
            setFilteredPurchaseOrders((prevMatchingPurchaseOrders) =>
                prevMatchingPurchaseOrders.map((po) => ({
                    ...po,
                    isMatched: po.id === id ? isMatched : po.isMatched,
                    allocatedAmount: po.id === id && !isMatched ? 0 : po.allocatedAmount,
                    warning: false,
                }))
            );

            if (updatedAllocations.find((allocation) => allocation.id === id)) {
                setUpdatedAllocations((prevAllocations) =>
                    prevAllocations.map((prevAllocation) => ({
                        ...prevAllocation,
                        isMatched: prevAllocation.id === id ? isMatched : prevAllocation.isMatched,
                        allocatedAmount: prevAllocation.id === id && !isMatched ? 0 : prevAllocation.allocatedAmount,
                    }))
                );
            } else {
                const allocatedAmount =
                    (isMatched && filteredPurchaseOrders.find((po) => po.id === id)?.allocatedAmount) || 0;

                setUpdatedAllocations((prevAllocations) =>
                    prevAllocations.concat({
                        id,
                        isMatched,
                        allocatedAmount,
                    })
                );
            }
        },
        [filteredPurchaseOrders, updatedAllocations]
    );

    const totalAllocatedAmount = useMemo(() => {
        return allInitialPurchaseOrders.reduce((accumAmount: number, po) => {
            const updatedAllocation = updatedAllocations.find((allocation) => allocation.id === po.id);

            const updatedPO = {
                ...po,
                ...updatedAllocation,
            };

            return updatedPO.isMatched && !updatedPO.isBilled
                ? mathService.add(accumAmount, updatedPO.allocatedAmount)
                : accumAmount;
        }, 0);
    }, [allInitialPurchaseOrders, updatedAllocations]);

    // applying user allocations to purchase orders received from backend
    useEffect(() => {
        if (prevMatchingData === matchingData && prevAppliedPOFilter === appliedPOFilter) {
            return;
        }

        let updatedPurchaseOrders = matchingData.purchaseOrders;

        updatedAllocations.forEach((allocation) => {
            const shouldExcludeRelatedPurchaseOrder = appliedPOFilter.onlyMatched && !allocation.isMatched;

            if (shouldExcludeRelatedPurchaseOrder) {
                updatedPurchaseOrders = updatedPurchaseOrders.filter((po) => po.id !== allocation.id);

                return;
            }

            const updatedPO = updatedPurchaseOrders.find((po) => po.id === allocation.id);

            if (updatedPO) {
                updatedPO.isMatched = allocation.isMatched;
                updatedPO.allocatedAmount = allocation.allocatedAmount;
            } else {
                const additionalPO = allInitialPurchaseOrders.find(
                    (po) => po.id === allocation.id && allocation.isMatched && isMatchFilter(po, appliedPOFilter)
                );

                if (additionalPO) {
                    updatedPurchaseOrders.unshift({
                        ...additionalPO,
                        isMatched: allocation.isMatched,
                        allocatedAmount: allocation.allocatedAmount,
                    });
                }
            }
        });

        setFilteredPurchaseOrders(updatedPurchaseOrders);
    }, [
        matchingData,
        allInitialPurchaseOrders,
        updatedAllocations,
        appliedPOFilter,
        prevMatchingData,
        prevAppliedPOFilter,
    ]);

    const getAllocatedAmountById = useCallback(
        (poId: string): number => {
            const currentPO = filteredPurchaseOrders.find(({ id }) => id === poId);

            return (currentPO?.isMatched && currentPO?.allocatedAmount) || 0;
        },
        [filteredPurchaseOrders]
    );

    const getIsWarningById = useCallback(
        (poId: string): boolean => {
            const currentPO = filteredPurchaseOrders.find(({ id }) => id === poId);

            return Boolean(currentPO?.warning);
        },
        [filteredPurchaseOrders]
    );

    const getIsMatchedById = useCallback(
        (poId: string): boolean => {
            const currentPO = filteredPurchaseOrders.find(({ id }) => id === poId);

            return currentPO?.isMatched || false;
        },
        [filteredPurchaseOrders]
    );

    return (
        <ChangeMatchingPopupContext.Provider
            value={useMemo(
                () => ({
                    matchingData: {
                        ...matchingData,
                        purchaseOrders: filteredPurchaseOrders,
                    },
                    totalAllocatedAmount,
                    onChangeAllocatedAmount,
                    onSelectPO,
                    getIsMatchedById,
                    getAllocatedAmountById,
                    save,
                    getIsWarningById,
                    isSaving,
                    billId,
                    companyId,
                }),
                [
                    matchingData,
                    filteredPurchaseOrders,
                    totalAllocatedAmount,
                    onChangeAllocatedAmount,
                    onSelectPO,
                    getIsMatchedById,
                    getAllocatedAmountById,
                    save,
                    getIsWarningById,
                    isSaving,
                    billId,
                    companyId,
                ]
            )}
        >
            {children}
        </ChangeMatchingPopupContext.Provider>
    );
};

export function useChangeMatchingPopupContext() {
    return useContext(ChangeMatchingPopupContext);
}
