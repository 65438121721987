import { toast } from '@approvalmax/ui/src/components';
import { selectors } from 'modules/common';
import { domain, schemas, State } from 'modules/data';
import { createAction, createAsyncAction, createErrorAction, ExtractActions } from 'modules/react-redux';
import { api } from 'services/api';

import { getActiveTemplate } from '../selectors/pageSelectors';
import { messages } from './templateSettings.messages';

export const OPEN_SETTINGS_POPUP = 'WORKFLOW_TEMPLATES/OPEN_SETTINGS_POPUP';
export const OPEN_SETTINGS_POPUP_RESPONSE = 'WORKFLOW_TEMPLATES/OPEN_SETTINGS_POPUP_RESPONSE';
export const OPEN_SETTINGS_POPUP_FAILURE = 'WORKFLOW_TEMPLATES/OPEN_SETTINGS_POPUP_FAILURE';
export const openSettingsPopup = () =>
    createAsyncAction({
        request: (state: State) => {
            return createAction(OPEN_SETTINGS_POPUP, {
                template: getActiveTemplate(state)!,
            });
        },

        response: async (request) => {
            const response = await api.templates.getSettings({
                templateId: request.template.id,
                companyId: request.template.companyId,
            });

            return createAction(OPEN_SETTINGS_POPUP_RESPONSE, {
                request,
                response: schemas.template.mapTemplateSettings(response.data),
            });
        },

        failure: (error) => createErrorAction(OPEN_SETTINGS_POPUP_FAILURE, error, {}),
    });

export const APPLY_TEMPLATE_SETTINGS = 'WORKFLOW_TEMPLATES/APPLY_TEMPLATE_SETTINGS';
export const APPLY_TEMPLATE_SETTINGS_RESPONSE = 'WORKFLOW_TEMPLATES/APPLY_TEMPLATE_SETTINGS_RESPONSE';
export const APPLY_TEMPLATE_SETTINGS_FAILURE = 'WORKFLOW_TEMPLATES/APPLY_TEMPLATE_SETTINGS_FAILURE';
export const applyTemplateSettings = (
    settings: domain.TemplateSettings,
    successfulCallback?: () => void | Promise<void>
) =>
    createAsyncAction({
        request: (state: State) =>
            createAction(APPLY_TEMPLATE_SETTINGS, {
                settings,
                transfer: schemas.template.mapTemplateSettingsBack(settings),
                companyId: selectors.navigation.getActiveCompanyId(state)!,
            }),

        response: async (request) => {
            await api.templates.editSettings({
                ...request.transfer,
                companyId: request.companyId,
            });

            await successfulCallback?.();

            return createAction(APPLY_TEMPLATE_SETTINGS_RESPONSE, {
                request,
            });
        },

        failure: (error) => createErrorAction(APPLY_TEMPLATE_SETTINGS_FAILURE, error, {}),

        didDispatchResponse: () => {
            toast.success(messages.msgTemplateSettingsUpdatedSuccess);
        },
    });

export const DISCARD_SETTINGS_POPUP = 'WORKFLOW_TEMPLATES/DISCARD_SETTINGS_POPUP';
export const discardSettingsPopup = () => createAction(DISCARD_SETTINGS_POPUP, {});

export type Action = ExtractActions<
    typeof applyTemplateSettings | typeof discardSettingsPopup | typeof openSettingsPopup
>;
