import { IconFC } from '@approvalmax/ui/src/components';
import * as flags from '@approvalmax/ui/src/images/flags';

export const getCountryFlagByCountryCode = (countryCode: string): IconFC | null => {
    const flagKey = `ImageFlag${countryCode.toUpperCase()}`;

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return flags[flagKey] ?? null;
};
