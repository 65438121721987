import { Popup } from '@approvalmax/ui';
import { useAtomValue } from 'jotai';
import { useResetAtom } from 'jotai/utils';
import { memo, useCallback } from 'react';

import { qBooksMatchingPopupAccountLineItemState, qBooksMatchingPopupLineItemState } from '../QBooksMatchingPopup';
import { Content } from './components';
import type { QBooksMatchingPopupProps } from './QBooksMatchingPopupOld.types';

export const QBooksMatchingPopupOld = memo<QBooksMatchingPopupProps>((props) => {
    const accountLineItem = useAtomValue(qBooksMatchingPopupAccountLineItemState);
    const lineItem = useAtomValue(qBooksMatchingPopupLineItemState);
    const resetAccountLineItem = useResetAtom(qBooksMatchingPopupAccountLineItemState);
    const resetLineItem = useResetAtom(qBooksMatchingPopupLineItemState);

    const onPopupClose = useCallback(() => {
        resetAccountLineItem();
        resetLineItem();
    }, [resetAccountLineItem, resetLineItem]);

    return (
        <Popup isOpen={Boolean(accountLineItem) || Boolean(lineItem)} disableAutoClose onRequestClose={onPopupClose}>
            <Content {...props} onClose={onPopupClose} />
        </Popup>
    );
});

QBooksMatchingPopupOld.displayName = 'QBooksMatchingPopupOld';
