import { enabledByParams, RequestParams, RequestQueryOptions } from '@approvalmax/data';
import { useGet } from 'shared/data';

import { integrationApiPaths } from '../paths';
import { GetAllContacts } from './useGetAllContacts.types';

export const useGetAllContacts = (
    params?: RequestParams<GetAllContacts<true>>,
    queryOptions?: RequestQueryOptions<GetAllContacts<true>>
) => {
    return useGet(integrationApiPaths.getAllContacts, {
        params,
        queryOptions: {
            enabled: enabledByParams<GetAllContacts<true>['params']['query']>(
                {
                    requestId: params?.query?.requestId,
                    companyId: params?.query?.companyId,
                    ruleType: params?.query?.ruleType,
                },
                queryOptions
            ),
            ...queryOptions,
        },
        apiVersion: 'v1',
        mapToCamelCase: true,
    });
};

export const useGetAllContactsRaw = (
    params?: RequestParams<GetAllContacts>,
    queryOptions?: RequestQueryOptions<GetAllContacts>
) => {
    return useGet(integrationApiPaths.getAllContacts, {
        params,
        queryOptions: {
            enabled: enabledByParams<GetAllContacts['params']['query']>(
                {
                    requestId: params?.query?.requestId,
                    companyId: params?.query?.companyId,
                    ruleType: params?.query?.ruleType,
                },
                queryOptions
            ),
            ...queryOptions,
        },
        apiVersion: 'v1',
        mapToCamelCase: false,
    });
};
