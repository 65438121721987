import { useConfirmation } from '@approvalmax/ui/src/components';

import { messages } from './GroupOfValuesPopup.messages';

export const useConfirmationPopup = () =>
    useConfirmation({
        confirmTitle: messages.title,
        confirmMessage: messages.description,
        cancelButtonMessage: messages.cancelButtonText,
        confirmButtonMessage: messages.confirmButtonText,
    });
