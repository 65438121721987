import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('pages.RequestList.Toolbar.PrimaryActions', {
    rejectButtonTitle: 'Reject the request and make a comment',
    approveButtonTitle: 'Approve the request',
    submitButtonTitle: 'Submit request for approval. Approvers will be notified.',
    openAuditReportButtonText: 'Open Audit Report',
    rejectButtonText: 'Reject',
    approveButtonText: 'Approve',
    submitButtonText: 'Submit for approval',
    add: 'Add',
    watchersButtonTitle: 'Change Watchers in this request',
    watchersButtonTitleReadOnly: 'View Watchers in this request',
    retryButtonTitle: 'Resend to Netsuite',
    retryButtonText: 'Resend',
    versionHistoryButtonTitle: 'Version history',
    forceSubmitForApprovalButtonText: 'Force submit for approval',
    forceSubmitForApprovalButtonTitle: 'Submit request for approval. Approvers will be notified.',
    editButtonText: 'Edit',
    editButtonTitle: 'Edit the request',
    submitForApprovalButtonText: 'Submit for approval',
    submitForApprovalButtonTitle: 'Submit request for approval. Approvers will be notified.',
    sendToReview: 'Send to Review',
});
