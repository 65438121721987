import { Popup } from '@approvalmax/ui/src/components';
import { useAtomValue } from 'jotai';
import { useResetAtom } from 'jotai/utils';
import { memo, useCallback } from 'react';

import { QBooksMatchingPopupContent } from './components';
import {
    qBooksMatchingPopupAccountLineItemState,
    qBooksMatchingPopupLineItemState,
} from './QBooksMatchingPopup.states';
import { QBooksMatchingPopupProps } from './QBooksMatchingPopup.types';

export const QBooksMatchingPopup = memo<QBooksMatchingPopupProps>((props) => {
    const accountLineItem = useAtomValue(qBooksMatchingPopupAccountLineItemState);
    const lineItem = useAtomValue(qBooksMatchingPopupLineItemState);
    const resetAccountLineItem = useResetAtom(qBooksMatchingPopupAccountLineItemState);
    const resetLineItem = useResetAtom(qBooksMatchingPopupLineItemState);

    const onPopupClose = useCallback(() => {
        resetAccountLineItem();
        resetLineItem();
    }, [resetAccountLineItem, resetLineItem]);

    return (
        <Popup open={Boolean(accountLineItem) || Boolean(lineItem)} onClose={onPopupClose} preventAutoClose fullScreen>
            <QBooksMatchingPopupContent {...props} onClose={onPopupClose} />
        </Popup>
    );
});

QBooksMatchingPopup.displayName = 'QBooksMatchingPopup';
