import { intl, mathService, stringHelpers } from '@approvalmax/utils';
import { backend } from 'modules/data';
import { ApiTypes } from 'shared/data/webApp/v2';

import { MULTILINE_PLACEHOLDERS } from '../constants';
import { XeroMatchingBillMatchingData, XeroMatchingBillTableItem } from '../selectors/types/matchingTypes';
import { getDisplayName } from '../selectors/userSelectors';

export const transformTotalAmount = (amount: number, currency: string, targetText: string) => {
    return amount && targetText
        ? targetText.replace(/\[TOTALAMOUNT\]/g, intl.formatCurrency(amount, currency))
        : targetText;
};

export const formatMultilineReplacement = (replacement: string) => {
    if (!replacement.includes('\n')) {
        return replacement;
    }

    let newReplacement = '';
    let flag = true;

    for (let i = 0; i < replacement.length; i++) {
        if (replacement[i] == '\n') {
            newReplacement = newReplacement + '</p>';
            flag = true;
        }

        if (flag) {
            newReplacement = newReplacement + '<p>';
            flag = false;
        }

        if (replacement[i] != '\n') {
            newReplacement = newReplacement + replacement[i];
        }
    }

    return newReplacement + '</p>';
};

// Nested paragraph tags in RichEditor generate extra spaces, so we need to get rid of them
export const replaceMultilinePlaceholders = (body: string, replacement: string, placeholder: string) => {
    let newBody = body;

    const paragraphRegex = /<p[^>]*>/g;

    if (paragraphRegex.test(replacement)) {
        const indexes = stringHelpers.findSubstringIndexes(body, placeholder);

        for (let i = 0; i < indexes.length; i++) {
            let lastPlaceholderIndex = stringHelpers.findSubstringIndexes(newBody, placeholder, i).pop() as number;

            const sub1 = newBody.substring(0, lastPlaceholderIndex);
            const sub2 = newBody.substring(lastPlaceholderIndex, newBody.length);

            const lastParagraphIndex = stringHelpers.findSubstringIndexes(sub1, paragraphRegex).pop();

            if (lastParagraphIndex != undefined) {
                const paragraphString = newBody.substring(lastParagraphIndex, lastPlaceholderIndex);

                if (paragraphString.indexOf('</p>') === -1) {
                    const sub1Changed = newBody
                        .substring(lastParagraphIndex, lastPlaceholderIndex)
                        .replace(paragraphRegex, '');
                    const sub2Changed = sub2.replace('</p>', '');

                    newBody = newBody.substring(0, lastParagraphIndex) + sub1Changed + sub2Changed;
                }
            }
        }
    }

    return newBody.replaceAll(new RegExp(`\\[${placeholder}\\]`, 'g'), replacement);
};

export const replacePlaceholders = (text: string, replacements: Record<string, string | null>) => {
    if (!text) {
        return '';
    }

    let newText = text;

    Object.keys(replacements).forEach((placeholder) => {
        const replacement = replacements[placeholder];

        if (replacement) {
            if (MULTILINE_PLACEHOLDERS.includes(placeholder)) {
                const newReplacement = formatMultilineReplacement(replacement);

                newText = replaceMultilinePlaceholders(newText, newReplacement, placeholder);
            } else {
                newText = newText.replaceAll(new RegExp(`\\[${placeholder}\\]`, 'g'), replacement);
            }
        } else {
            newText = newText.replaceAll(
                new RegExp(`\\[(${placeholder})\\](?!</span>)`, 'g'),
                `<span data-type="mention" class="mention" data-placeholdername="${placeholder}">[${placeholder}]</span>`
            );
        }
    });

    return newText;
};

export const getXeroBillMatchingData = (
    data: backend.XeroBillMatchingInfo | ApiTypes['MatchingXeroBillMatchingInfo']
): XeroMatchingBillMatchingData => {
    const tableData: XeroMatchingBillTableItem[] = [];

    let totalPOsAmount = 0;
    let allocatedAmountToBilledPOs = 0;
    let allocatedAmount = 0;

    data.documents.forEach((request) => {
        const tableItem = {
            id: request.requestId,
            name: request.friendlyName || '',
            matchedBy: getDisplayName(request.matchedBy),
            allocatedAmount: request.allocatedAmount || 0,
            totalAmount: request.totalAmount,
            currency: request.currency,
            billed: Boolean(request.isBilled),
            grnStatus: request.grnStatus,
        };

        if (request.isBilled) {
            allocatedAmountToBilledPOs = mathService.add(allocatedAmountToBilledPOs, request.allocatedAmount || 0);
        } else {
            allocatedAmount = mathService.add(allocatedAmount, request.allocatedAmount || 0);
        }

        totalPOsAmount = mathService.add(totalPOsAmount, request.totalAmount);

        tableData.push(tableItem);
    });

    const remaining = mathService.subtract(data.totalAmount, data.totalAllocatedAmount);

    return {
        totalAmount: data.totalAmount,
        totalAllocatedAmountToThisBill: data.totalAllocatedAmount,
        currency: data.currency,
        tableData,
        totalPOsAmount,
        allocatedAmountToBilledPOs,
        allocatedAmount,
        remaining,
    };
};
