import { RequestMutationOptions } from '@approvalmax/data';
import { useMutate } from 'shared/data/api';

import { paymentServicesApiPaths } from '../paths';
import { CreateAmaxPayConsent } from './useCreateAmaxPayConsent.types';

export const useCreateAmaxPayConsent = (
    mutationOptions?: RequestMutationOptions<CreateAmaxPayConsent>,
    otherOptions?: { skipInvalidateQueries?: true }
) =>
    useMutate(paymentServicesApiPaths.amaxPayOpenBankingBankAccounts, {
        skipInvalidateQueries: otherOptions?.skipInvalidateQueries,
        mutationOptions,
        apiVersion: 'v1',
        method: 'post',
    });
