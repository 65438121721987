import { Mods, mods } from '@approvalmax/theme';
import { TransparentButton } from '@approvalmax/ui';
import { StyledMods } from '@styled-kit/mods';
import { DropdownTriangleIcon } from 'modules/sprites';
import { FC, PropsWithChildren } from 'react';
import { useToggle } from 'react-use';
import styled from 'styled-components';
import { VelocityTransitionGroup } from 'velocity-react';

const ToggleButton = styled(TransparentButton)`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: fill 500ms ease-in-out;
    cursor: pointer;
`;

const StyledIcon = styled(DropdownTriangleIcon)<StyledMods<Mods<'collapsed'>>>`
    margin-right: 14px;
    fill: #477753;
    transform: rotate(180deg);
    transition: transform 300ms ease-in-out;

    ${mods.collapsed.true`
        fill: #000;
        transform: rotate(0);
    `}
`;

const TextContainer = styled.span`
    width: 100%;
    padding: 20px 0;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #cacccd;
`;

const Title = styled.span`
    margin-right: auto;
    font-weight: bold;
`;

const Date = styled.span`
    margin-right: 70px;
`;

const Amount = styled.span`
    min-width: 160px;
    text-align: right;
`;

interface CollapsibleSectionProps extends PropsWithChildren {
    defaultCollapsed?: boolean;
    title: string;
    date: string;
    amount: string;
}

const CollapsibleSection: FC<CollapsibleSectionProps> = (props) => {
    const { defaultCollapsed = false, children, title, date, amount } = props;

    const [collapsed, toggleCollapsed] = useToggle(defaultCollapsed);

    return (
        <div>
            <ToggleButton execute={toggleCollapsed}>
                <StyledIcon $collapsed={collapsed} />

                <TextContainer>
                    <Title>{title}</Title>

                    <Date>{date}</Date>

                    <Amount>{amount}</Amount>
                </TextContainer>
            </ToggleButton>

            <VelocityTransitionGroup
                enter={{ animation: 'slideDown', duration: 300 }}
                leave={{ animation: 'slideUp', duration: 300 }}
            >
                {collapsed ? <div>{children}</div> : undefined}
            </VelocityTransitionGroup>
        </div>
    );
};

export default CollapsibleSection;
