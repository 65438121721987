import { mathService } from '@approvalmax/utils';
import { domain } from 'modules/data';
import { GetBatchPaymentsRequestAmounts } from 'shared/data/webApp/v2';

export const calculateBillAmountDue = (
    bill:
        | domain.XeroBillBatchPaymentDetails['items'][number]
        | domain.XeroAmaxPayBatchPaymentDetails['items'][number]
        | domain.XeroAirwallexBatchPaymentDetails['items'][number],
    amounts?: GetBatchPaymentsRequestAmounts['response']['items'],
    shouldFallbackToBillData = false
) => {
    const billAmount = amounts?.find((amount) => amount.invoiceRequestId === bill.xeroBillInvoiceRequestId);

    let amountDue = bill.amountDue;

    if (billAmount) {
        amountDue = mathService.subtract(amountDue, billAmount.amountAwaitingPayment);
        amountDue = mathService.subtract(amountDue, billAmount.amountInProcessing);
        amountDue = mathService.subtract(amountDue, billAmount.amountOnApproval);
        amountDue = mathService.subtract(amountDue, billAmount.amountOnReview);

        return amountDue;
    }

    if (shouldFallbackToBillData) {
        amountDue = mathService.subtract(amountDue, bill.amountAwaitingPayment);
        amountDue = mathService.subtract(amountDue, bill.amountInProcessing);
        amountDue = mathService.subtract(amountDue, bill.amountOnApproval);
        amountDue = mathService.subtract(amountDue, bill.amountOnReview);
    }

    return amountDue;
};
