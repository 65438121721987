import { enabledByParams, RequestParams, RequestQueryOptions } from '@approvalmax/data';
import { useGet, useLazyGet } from 'shared/data/api';

import { integrationApiPaths } from '../paths';
import { GetNetSuiteLineCustomers } from './useGetNetSuiteLineCustomers.types';

export const useGetNetSuiteLineCustomers = (
    params?: RequestParams<GetNetSuiteLineCustomers>,
    queryOptions?: RequestQueryOptions<GetNetSuiteLineCustomers>
) => {
    return useGet(integrationApiPaths.getNetSuiteCustomers, {
        params,
        queryOptions: {
            ...queryOptions,
            enabled: enabledByParams<GetNetSuiteLineCustomers['params']['query']>(
                {
                    requestId: params?.query?.requestId,
                    companyId: params?.query?.companyId,
                    ruleType: params?.query?.ruleType,
                },
                queryOptions
            ),
        },
        apiVersion: 'v1',
        mapToCamelCase: true,
    });
};

export const useLazyGetNetSuiteLineCustomers = (queryOptions?: RequestQueryOptions<GetNetSuiteLineCustomers>) => {
    return useLazyGet<GetNetSuiteLineCustomers>(integrationApiPaths.getNetSuiteCustomers, {
        queryOptions,
        apiVersion: 'v1',
        mapToCamelCase: true,
    });
};
