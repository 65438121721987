import { Guid } from '@approvalmax/types';
import { selectors } from 'modules/common';
import { domain, State } from 'modules/data';
import { createSelector } from 'reselect';

import { PAGE_ID } from '../config';
import { Page } from '../reducers/pageReducer';
import { messages } from './pageSelectors.messages';

export function getPage(state: State): Page {
    return selectors.page.getPage(state, PAGE_ID);
}

export function checkRequestLoaded(state: State, requestId: Guid): boolean {
    const isActive = selectors.navigation.getActivePageId(state) === PAGE_ID;

    if (!isActive) {
        return false;
    }

    return getRequest(state).id === requestId;
}

export function getDocumentTitle(state: State): string {
    const request = getRequest(state);
    const templateName =
        selectors.template.getTemplateDisplayNameByCode(request.integrationCode) || request.template.text;

    if (request.statusV2 === domain.RequestStatusV2.Draft) {
        return messages.newRequestDocumentTitleText({
            requestName: templateName,
        });
    } else {
        return request.displayName || templateName;
    }
}

/**
 * @deprecated Please use memoized version instead {@link requestSelector}
 */
export function getRequest<TRequest extends domain.Request = domain.Request>(state: State): TRequest {
    return getPage(state).request as any as TRequest;
}

/**
 * Memoized version of {@link getRequest}
 */
export const requestSelector = createSelector([(state: State) => getRequest(state)], (request) => request);

export function getContext(state: State) {
    return getPage(state).context;
}

export function isLoading(state: State) {
    return selectors.ui.isBlocked(state);
}

export function getActivePopupId(state: State): string | null {
    const activePopup = getPage(state).activePopup;

    return activePopup ? activePopup.id : null;
}

export function getActivePopup<TPopupData>(state: State): TPopupData {
    return getPage(state).activePopup as any as TPopupData;
}

export function getNewAttachments(state: State) {
    return getPage(state).newAttachments;
}

export function getNewSupplierAttachments(state: State) {
    return getPage(state).newSupplierAttachments;
}

export function getShowValidationErrors(state: State) {
    return getPage(state).showValidationErrors;
}

export function getSupplierAttachments(state: State) {
    return getPage(state).supplierAttachments;
}
