import { Mods } from '@approvalmax/theme';
import { Identifiable, SpacingProp } from '@approvalmax/types';
import { ComponentType, HTMLAttributes, ReactNode } from 'react';

import type {
    BodyCell,
    BodyCellProps,
    BodyCellWithState,
    BodyFormCell,
    ColumnHeader,
    ColumnHeaderProps,
} from './components';

export enum SortOrder {
    asc = 'asc',
    desc = 'desc',
}

export interface BaseItem extends Identifiable {}

export enum TableCheckType {
    checkbox = 'checkbox',
    radio = 'radio',
}

export interface RowActionMenuItem<Item extends BaseItem> {
    label: string;
    disabled?: boolean;
    onClick: (item: Item) => void;
}

export interface TableProps<Item extends BaseItem>
    extends Mods<'bordered' | 'fontSize' | 'divider' | 'rounded'>,
        HTMLAttributes<HTMLTableElement> {
    /** Columns definition */
    columns: ColumnDefinition<Item>[];

    /** Table data */
    data: Item[];

    /** Common spacing for all columns */
    columnSpacing?: SpacingProp['spacing'];

    /** Set header color */
    headerColor?: Mods<'color', 'silver80' | 'white100' | 'transparent'>['color'];

    /** Sort callback function */
    onSort?: (columnId: string, newSortOrder: SortOrder) => void;

    /** Row click callback function */
    onRowClick?: (item: Item) => void;

    /**
     * Row ID.
     * Used as a row key and when selecting rows.
     * If not pass, then will be use value from "id" prop in Item.
     */
    getRowId?: (item: Item) => string;

    /** If set, the column with the checkboxes is activated. */
    checkedItems?: string[];

    /** Make rows invalid */
    invalidItems?: string[];

    /** Replace checkbox column */
    checkboxColumnCover?: ColumnDefinition<Item>;

    /** Checked items change callback function */
    onCheckedItemsChange?: (checkedItems: string[]) => void;

    /** Disabled row cannot be checked and clickable */
    getIsDisabledRow?: (item: Item) => boolean;

    /** Make header row is sticky */
    stickyHeader?: boolean;

    /** Make last row is sticky */
    stickyLastRow?: boolean;

    /** Virtualization */
    virtualized?: boolean;

    /** Make table reorderable: works with onReorderColumns and onReorderRows */
    reorderable?: boolean;

    /** Show header row */
    hideHeader?: boolean;

    /** Define check type */
    checkType?: TableCheckType;

    /** Callback to reorder table columns: must have reorderable prop */
    onReorderColumns?: (
        columnsReordered: ColumnDefinition<Item>[],
        oldIndex: number,
        newIndex: number,
        columns: ColumnDefinition<Item>[]
    ) => void;

    /** Callback to reorder table rows: must have reorderable prop */
    onReorderRows?: (rowsReordered: Item[], oldIndex: number, newIndex: number, rows: Item[]) => void;

    /** Show skeleton instead of rows */
    progress?: boolean;

    /** Number of skeleton rows to display while data is loading (progress props). */
    progressRowCount?: number;

    /**
     * The prefix of a `data-qa` attribute
     */
    qa?: string;

    /**
     * Hide column if all row values are empty
     */
    hideEmptyColumns?: boolean;

    /**
     * Handle reaching the end of virtualized table
     */
    handleEndReached?: VoidFunction;

    /** Hoverable rows */
    hoverable?: boolean;

    /** Hide border-bottom on last row when bordered=false */
    hideBorderLastRow?: boolean;

    /** Hide header border-bottom when bordered=false */
    hideHeaderBottomBorder?: boolean;

    /** Row action menu items */
    rowActionMenuItems?: RowActionMenuItem<Item>[];
}

export interface ColumnDefinition<Item extends BaseItem> extends SpacingProp, Mods<'textAlign'> {
    /** Column key. Used by default as a key on data props for get value. */
    id: string;

    index?: number;

    /**
     * Column title: value of title attribute and value in header cell.
     * The Value of header cell may be overridden by "columnComponent" prop
     */
    name?: string;

    /** Function to override the way to get the value instead of used "id" prop */
    value?: (item: Item) => ReactNode;

    /** Function for overriding title attribute of cell */
    title?: (item: Item) => string;

    /** Component for overriding cell render */
    cellComponent?: ComponentType<BodyCellProps<Item>>;

    /** Component for overriding header of column render */
    columnComponent?: ComponentType<ColumnHeaderProps<Item>>;

    /** Width of column (% only supported for a string) */
    width?: number | string;

    /** Min-width of column (% only supported for a string) */
    minWidth?: number | string;

    /** Enable sort for column. See table's 'onSort' prop also. */
    sortable?: boolean;

    /** If set, displays the corresponding sort icon in the column header */
    sortOrder?: SortOrder;

    /**
     * Sticky column.
     */
    sticky?: 'left' | 'right';

    /**
     * Vertical align in cells.
     */
    verticalAlign?: 'top' | 'middle' | 'bottom';

    /**
     * Text align in header cell.
     */
    headerTextAlign?: 'left' | 'center' | 'right';

    /**
     * Vertical align in header cell.
     */
    headerVerticalAlign?: 'top' | 'middle' | 'bottom';

    /**
     * Function to check if column value is empty
     * If isEmpty(item) call will return true for every row in table
     * then column will be hidden
     */
    isEmpty?: (item: Item) => boolean;
}

export interface ChildrenComponents {
    Cell: typeof BodyCell;
    ColumnHeader: typeof ColumnHeader;
    FormCell: typeof BodyFormCell;
    CellWithState: typeof BodyCellWithState;
}

export type TableDraggableItem<Item extends BaseItem> =
    | ({ type: 'row' } & Item)
    | ({ type: 'column' } & ColumnDefinition<Item>);

export interface TableContextValue {
    isLeftStickyShadow: boolean;
    isRightStickyShadow: boolean;
    setIsLeftStickyShadow: (value: boolean) => void;
    setIsRightStickyShadow: (value: boolean) => void;
}
