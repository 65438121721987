import { LoadingSpinner } from '@approvalmax/ui';
import { Box } from '@approvalmax/ui/src/components';
import FieldsSection from 'pages/requestList/components/FieldsSection';
import { SectionContainer } from 'pages/requestList/components/SectionContainer/SectionContainer';
import { memo } from 'react';
import BemFactory from 'react-bem-factory';
import { useGetWorkflowVersionDocumentFields } from 'shared/data/webApp/v1';
import { useNetSuitePurchaseOrderFieldSettings } from 'shared/hooks';

import { isFieldVisible } from '../../NetSuiteRequestCard.helpers';
import { FieldsSectionWrap, StyledField } from '../../NetSuiteRequestCard.styles';
import { messages } from './NetSuitePurchaseOrderFooterFields.messages';
import { NetSuitePurchaseOrderFooterFieldsProps } from './NetSuitePurchaseOrderFooterFields.types';

const qa = BemFactory.qa('netsuite-po-fields-section');

export const NetSuitePurchaseOrderFooterFields = memo<NetSuitePurchaseOrderFooterFieldsProps>((props) => {
    const { request } = props;

    const { data: workflowVersionDocumentFields, isFetching: isFetchingGetWorkflowVersionDocumentFields } =
        useGetWorkflowVersionDocumentFields({
            query: {
                companyId: request.companyId,
                workflowVersionIds: [request.workflowVersionId],
            },
        });

    const fieldSettings = useNetSuitePurchaseOrderFieldSettings(workflowVersionDocumentFields?.data);

    const { details } = request;

    const hasShippingDetailsSection =
        (details.shipTo && isFieldVisible(fieldSettings.shipTo)) ||
        (details.shippingAddress && isFieldVisible(fieldSettings.shippingAddress)) ||
        (details.shipDate && isFieldVisible(fieldSettings.shipDate)) ||
        (details.shipMethod && isFieldVisible(fieldSettings.shipMethod)) ||
        (details.freeOnBoard && isFieldVisible(fieldSettings.fob)) ||
        (details.trackingNumbers && isFieldVisible(fieldSettings.trackingNumbers));

    if (!hasShippingDetailsSection) {
        return null;
    }

    return (
        <SectionContainer title={messages.shippingDetailsSection} noPadding withHeaderPadding>
            {isFetchingGetWorkflowVersionDocumentFields ? (
                <Box spacing='20'>
                    <LoadingSpinner />
                </Box>
            ) : (
                <FieldsSectionWrap>
                    {isFieldVisible(fieldSettings.shipTo) && (
                        <StyledField
                            qa={qa('ship-shipTo')}
                            title={messages.shipTo}
                            valueType={FieldsSection.Field.ValueType.String}
                            value={details.shipTo}
                            hideIfEmpty
                        />
                    )}

                    {isFieldVisible(fieldSettings.shippingAddress) && (
                        <StyledField
                            qa={qa('shipping-address')}
                            title={messages.shippingAddress}
                            valueType={FieldsSection.Field.ValueType.String}
                            value={details.shippingAddress?.text?.replace(/<br>/g, ' ').trim() ?? null}
                            hideIfEmpty
                        />
                    )}

                    {isFieldVisible(fieldSettings.shipDate) && (
                        <StyledField
                            qa={qa('ship-date')}
                            title={messages.shipDate}
                            valueType={FieldsSection.Field.ValueType.Date}
                            value={details.shipDate}
                            hideIfEmpty
                        />
                    )}

                    {isFieldVisible(fieldSettings.shipMethod) && (
                        <StyledField
                            qa={qa('ship-method')}
                            title={messages.shipMethod}
                            valueType={FieldsSection.Field.ValueType.String}
                            value={details.shipMethod}
                            hideIfEmpty
                        />
                    )}

                    {isFieldVisible(fieldSettings.fob) && (
                        <StyledField
                            qa={qa('fob')}
                            title={messages.fob}
                            valueType={FieldsSection.Field.ValueType.String}
                            value={details.freeOnBoard}
                            hideIfEmpty
                        />
                    )}

                    {isFieldVisible(fieldSettings.trackingNumbers) && (
                        <StyledField
                            qa={qa('tracking-numbers')}
                            title={messages.trackingNumbers}
                            valueType={FieldsSection.Field.ValueType.String}
                            value={details.trackingNumbers}
                            hideIfEmpty
                        />
                    )}
                </FieldsSectionWrap>
            )}
        </SectionContainer>
    );
});

NetSuitePurchaseOrderFooterFields.displayName = 'NetSuitePurchaseOrderFooterFields';
