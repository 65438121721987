import { useMutation } from '@tanstack/react-query';
import { api } from 'services/api';
import { notificationService } from 'services/notification';

import { messages } from './hooks.messages';

export function useRequestToSalesMutation() {
    const requestToSalesMutation = useMutation<
        any,
        unknown,
        {
            phone: string;
            message: string;
            accountId: string;
        }
    >((options) => api.support.requestToSales(options), {
        onSuccess: () => {
            notificationService.showInfoToast(messages.requestToSalesSuccessMessage);
        },
        onError: () => {
            notificationService.showErrorToast(messages.requestToSalesFailedMessage);
        },
    });

    return {
        requestToSales: requestToSalesMutation.mutateAsync,
        isLoading: requestToSalesMutation.isLoading,
    };
}
