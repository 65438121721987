import { selectors } from 'modules/common';
import { domain, State } from 'modules/data';
import { memo } from 'react';
import bemFactory from 'react-bem-factory';
import { useSelector } from 'react-redux';

import DuplicateBillWarning from '../../../components/DuplicateBillWarning/DuplicateBillWarning';
import { RequestOnHoldInfo } from '../../../components/RequestOnHoldInfo/RequestOnHoldInfo';
import { useXeroBillMatchingData } from '../../../hooks';
import { isActiveRequestLocked } from '../../../selectors/pageSelectors';
import ContactInfo from '../ContactInfo/ContactInfo';
import IntegrationHeader from '../integrationHeader/integrationHeader';
import DeadlineText from './components/DeadlineText/DeadlineText';
import { DelegationHeader } from './components/DelegationHeader/DelegationHeader';
import { NudgeAction } from './components/NudgeAction/NudgeAction';
import { useCheckNudgeActionAvailable } from './components/NudgeAction/NudgeAction.hooks';
import { PrimaryActions } from './components/PrimaryActions/PrimaryActions';
import { SecondaryActions } from './components/SecondaryActions/SecondaryActions';
import ToolbarHeader from './components/ToolbarHeader/ToolbarHeader';
import ToolbarNotifications from './components/ToolbarNotifications/ToolbarNotifications';
import {
    useGetQBooksMatchingValidationError,
    useGetXeroMatchingV2InsufficientBudgetError,
    useGetXeroMatchingV2ValidationError,
    useRequestDetails,
} from './Toolbar.hooks';
import { Container, ToolbarBody, ToolbarBodyLeft, ToolbarBodyRight } from './Toolbar.styles';
import { ToolbarProps } from './Toolbar.types';

const qa = bemFactory.qa('reql-toolbar');

const Toolbar = memo<ToolbarProps>((props) => {
    const {
        className,
        request,
        preloadedRequestDetails,
        preloadedMatchingData,
        readonly,
        showAddress,
        showVatRegistration,
        amountText,
    } = props;

    const isNudgeActionAvailable = useCheckNudgeActionAvailable(request);
    const isRequestLocked = useSelector(isActiveRequestLocked);
    const hasActiveIntegration = useSelector((state: State) =>
        selectors.integration.hasActiveIntegration(state, request.companyId)
    );

    const { isInitialLoading, data: requestDetails } = useRequestDetails(request, preloadedRequestDetails);

    const { data: billMatchingData } = useXeroBillMatchingData(request, preloadedMatchingData);

    const xeroMatchingValidationError = useGetXeroMatchingV2ValidationError(request, billMatchingData);
    const qbooksMatchingValidationError = useGetQBooksMatchingValidationError(request);
    const matchingInsufficientBudgetError = useGetXeroMatchingV2InsufficientBudgetError(request, preloadedMatchingData);

    const matchingValidationError = xeroMatchingValidationError || qbooksMatchingValidationError;

    const possibleDuplicates = requestDetails?.possibleDuplicates ?? [];

    const showPossibleDuplicateBillWarning = possibleDuplicates.length > 0;

    return (
        <Container className={className} data-qa={qa()}>
            <DelegationHeader request={request} />

            <ToolbarHeader request={request} amountText={amountText} />

            {showPossibleDuplicateBillWarning && possibleDuplicates && (
                <DuplicateBillWarning possibleDuplicates={possibleDuplicates} companyId={request.companyId} />
            )}

            {request.statusV2 === domain.RequestStatusV2.OnHold && <RequestOnHoldInfo />}

            <ToolbarBody>
                <ToolbarBodyLeft>
                    {request.integrationType !== domain.IntegrationType.None && hasActiveIntegration && (
                        <ContactInfo
                            counterparty={requestDetails?.counterparty ?? null}
                            request={request}
                            showAddress={showAddress}
                            showVatRegistration={showVatRegistration}
                            isLoading={isInitialLoading}
                        />
                    )}

                    <IntegrationHeader request={request} />

                    {!readonly && (
                        <SecondaryActions
                            request={request}
                            isLocked={isRequestLocked}
                            hasMatchingValidationError={Boolean(matchingValidationError)}
                        />
                    )}
                </ToolbarBodyLeft>

                <ToolbarBodyRight>
                    <DeadlineText request={request} />

                    <PrimaryActions
                        request={request}
                        readonly={readonly}
                        isLocked={isRequestLocked}
                        hasMatchingValidationError={Boolean(matchingValidationError)}
                        hasMatchingInsufficientBudgetError={Boolean(matchingInsufficientBudgetError)}
                    />

                    {isNudgeActionAvailable && <NudgeAction request={request} readonly={readonly} />}
                </ToolbarBodyRight>
            </ToolbarBody>

            <ToolbarNotifications
                request={request}
                matchingValidationError={matchingValidationError || matchingInsufficientBudgetError}
                isLocked={isRequestLocked}
                readonly={readonly}
            />
        </Container>
    );
});

Toolbar.displayName = 'Toolbar';

export default Toolbar;
