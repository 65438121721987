import { constants } from '@approvalmax/utils';
import { FC, memo } from 'react';

import Dropdown from '../../../Dropdown/Dropdown';
import { useDropdownOpen } from './DropdownMenu.hooks';
import { DropdownMenuProps } from './DropdownMenu.types';

/**
 * Dropdown menu for the Select component
 */
const DropdownMenu: FC<DropdownMenuProps> = memo((props) => {
    const {
        children,
        activator,
        initOpen,
        onOpen,
        open,
        floatingFocusManagerProps,
        floatingPortalProps,
        dropdownRef,
        ...restProps
    } = props;

    const [dropdownOpen, setDropdownOpen] = useDropdownOpen({ open, onOpen, initOpen });

    return (
        <Dropdown
            activator={activator}
            open={dropdownOpen}
            onOpen={setDropdownOpen}
            isClicking={false}
            hasArrow={false}
            initOpen={initOpen}
            maxHeight={0}
            floatingPortalProps={{
                ...constants.removeDropdownFocus.defaultFloatingPortalProps,
                ...floatingPortalProps,
            }}
            floatingFocusManagerProps={{
                ...constants.removeDropdownFocus.defaultFloatingFocusManagerProps,
                ...floatingFocusManagerProps,
            }}
            ref={dropdownRef}
            {...restProps}
        >
            {children}
        </Dropdown>
    );
});

DropdownMenu.displayName = 'DropdownMenu';

export default DropdownMenu;
