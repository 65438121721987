import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('pages.RequestList.XeroMatchingV2Section.PurchaseOrderView', {
    sectionHeaderText: 'Bill to PO matching',
    noMatchedBills: 'This Purchase order is not currently matched with any Bill.',
    markAsBilledButtonTitle: 'Mark this Purchase Order as Billed',
    unmarkAsBilledMenuItemTitle: 'Unmark this Purchase Order as Billed',
    allocationsToApprovedBills: 'Allocations to Approved Bills',
    allocatedAmount: 'Allocations to Bills on Approval',
    remaining: 'Remaining',
    extraAmount: '{remaining} + extra {extraAmount} are allowed',
    showTable: 'Matched Bills',
});
