import { openSplitViewState, Spacing } from '@approvalmax/ui/src/components';
import { useAtomValue, useSetAtom } from 'jotai';
import { domain } from 'modules/data';
import { BeneficiaryStatusLegend } from 'modules/request/components/xero/BeneficiaryStatusLegend/BeneficiaryStatusLegend';
import { LineItemsTable } from 'pages/requestList/components/lineItemsSection/LineItemsTable';
import OpenInSourceLink from 'pages/requestList/components/OpenInSourceLink/OpenInSourceLink';
import { FC, memo, useCallback, useMemo } from 'react';
import { usePrevious } from 'react-use';
import { splitViewGoToBarSettingsState } from 'shared/components';
import { useGetBatchPaymentsRequestAmounts } from 'shared/data/webApp/v2';
import { useCompanyBetaFeatures } from 'shared/hooks';
import { activeAttachmentIdState, ContentSplitViewMode, contentSplitViewState } from 'shared/states';

import PaymentProcessingDetails from '../PaymentProcessingDetails/PaymentProcessingDetails';
import { getColumnDefinitions, getColumnDefinitionsWithPaymentDetails } from './BillsInformationSection.config';
import { messages } from './BillsInformationSection.messages';
import { Footer, LinkContainer, Root, SectionHeaderText } from './BillsInformationSection.styles';
import { BillsInformationSectionProps, OnSelectAttachmentParams } from './BillsInformationSection.types';
import ScheduledPaymentSection from './components/ScheduledPaymentSection/ScheduledPaymentSection';
import TotalContainer from './components/TotalContainer/TotalContainer';

const BillsInformationSection: FC<BillsInformationSectionProps> = (props) => {
    const {
        billBatchPayment: { items, url, editUrl, paymentProcessingDetails, paymentStatus, scheduledPaymentDate },
        currency,
        request,
    } = props;

    const prevRequest = usePrevious(request);

    const setOpenSplitView = useSetAtom(openSplitViewState);
    const setContentSplitView = useSetAtom(contentSplitViewState);
    const setSplitViewGoToBarSettings = useSetAtom(splitViewGoToBarSettingsState);
    const activeAttachmentId = useAtomValue(activeAttachmentIdState);

    const { isBatchPaymentAmountsV2 } = useCompanyBetaFeatures(request.companyId);

    const { data: batchPaymentsRequestAmounts, isFetching: isFetchingGetBatchPaymentsRequestAmounts } =
        useGetBatchPaymentsRequestAmounts(
            {
                path: {
                    companyId: request.companyId,
                    requestId: request.id,
                },
            },
            {
                enabled: isBatchPaymentAmountsV2,
            }
        );

    const onSelectAttachment = useCallback(
        ({ attachmentId, xeroBatchPaymentInvoiceId, xeroBatchPaymentInvoiceName }: OnSelectAttachmentParams) => {
            setOpenSplitView(true);
            setContentSplitView({
                mode: ContentSplitViewMode.AttachmentsToBillLinkedToBatchPayment,
                attachmentId,
                billId: xeroBatchPaymentInvoiceId,
                billFriendlyName: xeroBatchPaymentInvoiceName,
            });
            setSplitViewGoToBarSettings({
                show: true,
                targetContent: {
                    mode: ContentSplitViewMode.BillLinkedToBatchPayment,
                    billId: xeroBatchPaymentInvoiceId,
                },
                targetContentName: xeroBatchPaymentInvoiceName,
            });
        },
        [setContentSplitView, setSplitViewGoToBarSettings, setOpenSplitView]
    );

    const openBill = useCallback(
        (xeroBatchPaymentInvoiceId: string) => {
            setContentSplitView({
                mode: ContentSplitViewMode.BillLinkedToBatchPayment,
                billId: xeroBatchPaymentInvoiceId,
            });
            setOpenSplitView(true);
        },
        [setContentSplitView, setOpenSplitView]
    );

    const columnDefinitions = useMemo(
        () =>
            getColumnDefinitions({
                currency,
                activeAttachmentId,
                onSelectAttachment,
                openBill,
                amounts: batchPaymentsRequestAmounts?.items,
                isAmountsLoading: isFetchingGetBatchPaymentsRequestAmounts,
                isBatchPaymentAmountsV2,
            }),
        [
            activeAttachmentId,
            currency,
            onSelectAttachment,
            openBill,
            batchPaymentsRequestAmounts?.items,
            isFetchingGetBatchPaymentsRequestAmounts,
            isBatchPaymentAmountsV2,
        ]
    );

    const justPaid =
        prevRequest?.details.paymentStatus === domain.AirwallexPaymentStatus.AwaitingPayment &&
        request.details.paymentStatus === domain.AirwallexPaymentStatus.Processing;

    const columnDefinitionsWithPaymentDetails = useMemo(
        () =>
            getColumnDefinitionsWithPaymentDetails({
                currency,
                activeAttachmentId,
                onSelectAttachment,
                openBill,
                defaultOpenAttachments: !justPaid,
            }),
        [currency, activeAttachmentId, onSelectAttachment, openBill, justPaid]
    );

    const showPaymentDetails =
        paymentStatus &&
        paymentStatus !== domain.AirwallexPaymentStatus.AwaitingPayment &&
        paymentStatus !== domain.AirwallexPaymentStatus.None;

    return (
        <Root>
            {showPaymentDetails && <PaymentProcessingDetails paymentProcessingDetails={paymentProcessingDetails} />}

            {scheduledPaymentDate && (
                <>
                    <ScheduledPaymentSection request={request} />

                    <Spacing height={12} />
                </>
            )}

            <BeneficiaryStatusLegend redText={messages.bankDetailsChanged} />

            <SectionHeaderText>{messages.airwallexBatchPaymentBills}</SectionHeaderText>

            <LineItemsTable
                columnDefinitions={showPaymentDetails ? columnDefinitionsWithPaymentDetails : columnDefinitions}
                lineItems={items}
            />

            <Footer>
                <LinkContainer>
                    <OpenInSourceLink
                        url={url}
                        editUrl={editUrl}
                        statusV2={request.statusV2}
                        integrationType={request.integrationType}
                        integrationCode={request.integrationCode}
                    />
                </LinkContainer>

                <TotalContainer airwallexPaymentItems={items} currency={request.currency} amount={request.amount} />
            </Footer>
        </Root>
    );
};

export default memo(BillsInformationSection);
