import { FocusEvent, RefObject, useCallback } from 'react';
import { useClickAway, useKeyPressEvent, useMount } from 'react-use';

type FocusElement = HTMLInputElement | HTMLTextAreaElement | HTMLButtonElement;

export interface BaseDropdownProps<FocusEventElement = FocusElement> {
    toggleFocus: (value?: boolean) => void;
    toggleOpen: (value?: boolean) => void;
    initFocus?: boolean;
    focus?: boolean;
    open?: boolean;
    disabled?: boolean;
    readOnly?: boolean;
    onFocus?: (event: FocusEvent<FocusEventElement>) => void;
    onBlur?: (event: FocusEvent<FocusEventElement>) => void;
    inputRef: RefObject<HTMLInputElement | HTMLTextAreaElement>;
    activatorRef: RefObject<HTMLDivElement>;
    dropdownRef: RefObject<HTMLDivElement>;
}

/**
 * The useOpenControl and useCloseControl hooks provide functionality for managing
 * the opening and closing of dropdown UI elements.
 *
 * useOpenControl handles focus and opening of dropdown elements when users interact
 * with input fields.
 */
export const useOpenControl = (
    props: Pick<
        BaseDropdownProps,
        'toggleFocus' | 'toggleOpen' | 'focus' | 'open' | 'disabled' | 'readOnly' | 'inputRef' | 'initFocus' | 'onFocus'
    >
) => {
    const { inputRef, readOnly, disabled, toggleFocus, toggleOpen, onFocus, initFocus, focus, open } = props;

    /**
     * Handle input focus and open dropdown
     */
    const handleFocus = useCallback(
        (event: FocusEvent<FocusElement>) => {
            if (readOnly || disabled) return;

            toggleFocus(true);
            toggleOpen(true);
            onFocus?.(event);
        },
        [onFocus, toggleFocus, toggleOpen, readOnly, disabled]
    );

    /**
     * Focus on input on mount if initFocus is true
     */
    useMount(() => {
        if (initFocus) {
            inputRef?.current?.focus();
        }
    });

    /**
     * Opening the dropdown by pressing Enter, Space, ArrowDown, or ArrowUp when the field is focused
     */
    useKeyPressEvent(
        (event) => ['Enter', 'Space', 'ArrowDown', 'ArrowUp'].includes(event.code),
        (event) => {
            if (focus && !open && !disabled && !readOnly) {
                event.code !== 'Space' && event.stopPropagation();
                toggleOpen(true);
            }
        }
    );

    return { handleFocus };
};

/**
 * The useOpenControl and useCloseControl hooks provide functionality for managing
 * the opening and closing of dropdown UI elements.
 *
 * useCloseControl manages closing dropdown elements when focus is lost or when
 * users interact with other elements on the page.
 */
export const useCloseControl = (
    props: Pick<
        BaseDropdownProps,
        | 'toggleFocus'
        | 'toggleOpen'
        | 'focus'
        | 'open'
        | 'disabled'
        | 'readOnly'
        | 'activatorRef'
        | 'dropdownRef'
        | 'onBlur'
    >
) => {
    const { activatorRef, dropdownRef, focus, toggleFocus, open, toggleOpen, disabled, readOnly, onBlur } = props;

    /**
     * Handle input blur and blur input only if dropdown is closed.
     */
    const handleBlur = useCallback(
        (event: FocusEvent<FocusElement>) => {
            /**
             * We should not blur if:
             * 1. The new focused element is inside the activator
             * 2. The new focused element is inside the dropdown
             */
            const newFocusedElement = event.relatedTarget;
            const isInsideActivator = activatorRef?.current?.contains(newFocusedElement);
            const isInsideDropdown = dropdownRef?.current?.contains(newFocusedElement);

            if (isInsideActivator || isInsideDropdown) return;

            toggleFocus(false);
            toggleOpen(false);
            onBlur?.(event);
        },
        [onBlur, toggleFocus, toggleOpen, activatorRef, dropdownRef]
    );

    /**
     * Closing the dropdown by clicking outside the activator when the field is focused and dropdown is closed
     */
    useClickAway(activatorRef, () => {
        if (focus && !open && !disabled && !readOnly) {
            toggleFocus(false);
            toggleOpen(false);
        }
    });

    /**
     * Closing the dropdown by pressing Esc when the field is focused and dropdown is closed
     */
    useKeyPressEvent('Escape', (event) => {
        if (focus && !open && !disabled && !readOnly) {
            event.stopPropagation();
            toggleFocus(false);
        }
    });

    return { handleBlur };
};
