import { atom } from 'jotai';

export interface ContentOcrNotificationState {
    buttonText?: string;
    description?: string;
}

export const ocrNotificationPopupOpenState = atom(false);

export const contentOcrNotificationState = atom<ContentOcrNotificationState>({});
