import { errorHelpers } from '@approvalmax/utils';
import { domain } from 'modules/data';

import {
    addonPayAvailableCurrencies,
    disabledAddOnsBillingSystems,
    disabledIntegrationsForAddonsByTypes,
} from './addons.constants';
import { IsAddonAllowedInProductCheckParams, IsAddonCanBeAddedToSubscriptionCheckParams } from './addons.types';

// Keep this functions in consistence with "my-account/src/pages/account/helpers/addon.helpers.ts" and visa-versa
// iIf you change something here copy the changes to that files!

export const getIsAddonAllowedInProduct = ({
    integrationType,
    addonType,
}: IsAddonAllowedInProductCheckParams): boolean => {
    return Boolean(integrationType && !disabledIntegrationsForAddonsByTypes[addonType].includes(integrationType));
};

export const isAddonsCanBeAddedToSubscription = ({
    addonType,
    hasAddon,
    integrationType,
    currency,
    billingSystem,
}: IsAddonCanBeAddedToSubscriptionCheckParams): boolean => {
    if (hasAddon) {
        return false;
    }

    const isAddOnsEnabledByBillingSystem = billingSystem && !disabledAddOnsBillingSystems.includes(billingSystem);
    const isAddOnsEnabledByProduct = getIsAddonAllowedInProduct({
        integrationType,
        addonType,
    });

    switch (addonType) {
        case domain.AddonTypes.Capture: {
            return Boolean(isAddOnsEnabledByBillingSystem && isAddOnsEnabledByProduct);
        }
        case domain.AddonTypes.Pay: {
            const isEnabledByCurrency = currency && addonPayAvailableCurrencies.includes(currency);

            return Boolean(isAddOnsEnabledByBillingSystem && isAddOnsEnabledByProduct && isEnabledByCurrency);
        }

        default:
            throw errorHelpers.assertNever(addonType);
    }
};
