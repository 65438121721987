import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('pages.RequestList.ContactRequestCard.RequestContactAddressSection', {
    sameAsBillingAddress: 'Same as Billing Address',
    country: 'Country',
    cityTown: 'City/town',
    stateRegion: 'State/region',
    address: 'Address',
    attention: 'Attention',
});
