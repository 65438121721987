import { useTheme } from '@approvalmax/theme';
import { ComponentProps, memo, useCallback } from 'react';

import { Flex, Spacing, Tag } from '../../../../components';
import { useSelectContext } from '../../Select.context';
import { SelectOnChange } from '../../Select.types';
import { SelectedItemsProps } from './SelectedItems.types';

/**
 * Selected items of the Select component (multiple mode)
 */
const SelectedItems = memo(
    <Item extends Record<string, any>, Multiple extends boolean>(props: SelectedItemsProps<Item, Multiple>) => {
        const {
            items,
            itemIdKey,
            itemNameKey,
            selectedItems,
            multiple,
            onChange,
            customTagItem,
            tagColor,
            ...restProps
        } = props;

        const { theme } = useTheme();
        const { setInputFocus } = useSelectContext<Item>();

        /**
         * Remove selected item
         */
        const removeSelectedItem = useCallback(
            (removedItem: Item) => {
                if (multiple) {
                    const newSelectedItems = selectedItems
                        .filter((item) => item[itemIdKey] !== removedItem[itemIdKey])
                        .map<string | number>((item) => item[itemIdKey]);

                    onChange?.(newSelectedItems as Parameters<SelectOnChange<Item, Multiple>>[0], items);
                }
            },
            [selectedItems, multiple, itemIdKey, onChange, items]
        );

        /**
         * Handle remove selected item with animation timeout
         */
        const handleRemove = useCallback<(removedItem: Item) => Required<ComponentProps<typeof Tag>>['onClose']>(
            (removedItem) => (event) => {
                event.stopPropagation();

                setTimeout(() => setInputFocus(true), 0);

                setTimeout(() => removeSelectedItem(removedItem), +theme.duration.medium);
            },
            [removeSelectedItem, setInputFocus, theme.duration.medium]
        );

        const getTagProps = useCallback(
            (item: (typeof selectedItems)[number]) => ({
                ...item,
                name: item[itemNameKey],
                title: item[itemNameKey],
                onClose: handleRemove(item),
                key: item[itemIdKey],
                closable: true,
                color: tagColor,
                ...restProps,
            }),
            [handleRemove, itemIdKey, itemNameKey, restProps, tagColor]
        );

        if (!multiple || selectedItems.length === 0) return null;

        return (
            <>
                <Flex spacing='4'>
                    {selectedItems.map((item) => {
                        // reason to extract key: https://github.com/jsx-eslint/eslint-plugin-react/issues/613
                        const { key, ...tagProps } = getTagProps(item);

                        return customTagItem ? (
                            customTagItem({ key, ...tagProps } as Item)
                        ) : (
                            <Tag key={key} {...tagProps}>
                                {item[itemNameKey]}
                            </Tag>
                        );
                    })}
                </Flex>

                <Spacing height={4} />
            </>
        );
    }
);

export default SelectedItems;
