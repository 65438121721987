import { RequestParams, RequestQueryOptions } from '@approvalmax/data';
import { useGet, useLazyGet } from 'shared/data';

import { netSuiteApiPaths } from '../paths';
import { GetNetSuiteEmployees } from './useGetNetSuiteEmployees.types';

export const useGetNetSuiteEmployees = (
    params?: RequestParams<GetNetSuiteEmployees>,
    queryOptions?: RequestQueryOptions<GetNetSuiteEmployees>
) => {
    return useGet(netSuiteApiPaths.employees, {
        params,
        queryOptions,
        apiVersion: 'v2',
    });
};

export const useLazyGetNetSuiteEmployees = (queryOptions?: RequestQueryOptions<GetNetSuiteEmployees>) => {
    return useLazyGet<GetNetSuiteEmployees>(netSuiteApiPaths.employees, {
        queryOptions,
        apiVersion: 'v2',
    });
};
