import { z } from 'zod';

import { messages } from './messages';

/**
 * Single select can be nullable
 * null value is set when user click Clear button
 */
export const select = {
    string: (message?: string) => {
        return z
            .string({
                invalid_type_error: message ?? messages.requiredField,
                required_error: message ?? messages.requiredField,
            })
            .nullable()
            .refine((value) => Boolean(value), message ?? messages.requiredField);
    },
    enum: <T extends z.EnumLike>(nativeEnum: T, message?: string) => {
        return z
            .nativeEnum(nativeEnum, {
                invalid_type_error: message || messages.requiredField,
                required_error: message || messages.requiredField,
            })
            .refine((value) => Boolean(value), message || messages.requiredField);
    },
    number: (message?: string) => {
        return z
            .number()
            .nullable()
            .refine((value) => typeof value === 'number', message ?? messages.requiredField);
    },
};

export const textField = {
    string: (message?: string) => {
        return z
            .string({
                required_error: message ?? messages.requiredField,
                invalid_type_error: message ?? messages.requiredField,
            })
            .trim()
            .min(1, message ?? messages.requiredField);
    },
    url: function (message?: string) {
        return this.string(message).url();
    },
};

export const numberField = {
    number: (message?: string) => {
        return z.number({ required_error: message ?? messages.requiredField });
    },
    numberNullable: (message?: string) => {
        return (
            z
                .number({
                    required_error: message ?? messages.requiredField,
                    invalid_type_error: message ?? messages.requiredField,
                })
                .nullable()
                // provide type to refine is needed
                // cuz after refine `| null` type disappears without it
                .refine<number | null>((value) => typeof value === 'number', message ?? messages.requiredField)
        );
    },
};
