import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('modules.IntegrationModule.DearIntegrationPopup', {
    popupTitle: 'Cin7 Core account connection',
    connectButton: 'Connect',

    fieldAccountId: 'Cin7 Core Account ID',
    fieldApplicationKey: 'Application Key',
    errorFieldAccountId: 'Account ID is not valid',
    errorFieldApplicationKey: 'Application Key is not valid',
    errorCompanyCreate: 'Failed to create company',
});
