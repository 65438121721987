import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('app.WorkflowIdPage.MatrixPopupContent', {
    approvalMatrixForTheStep: 'Approval matrix for the step "{step}"',
    reviewAndCodingMatrix: 'Review and coding matrix',
    requesterMatrix: 'Requester matrix',
    invalidConditions: 'Please ensure that all set conditions are valid',
    invalidAutoApprovalConditions: 'Please specify the Auto-Approval rule',
    autoApprovalMatrix: 'Auto-Approval matrix',
    editingMatrix: 'Editing matrix',
});
