import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('modules.IntegrationModule.IntegrationErrorPopup', {
    connectedTitle: '{integrationName} connection error',
    connectedManagerDescription: `
        Request can not be created due to an error.
        Sorry for the inconvenience.
        Please try again later.
        You are welcome to <supportLink>contact ApprovalMax Support</supportLink> for assistance.`,
    connectedUserDescription: `
        Request can not be created due to an error.
        Sorry for the inconvenience.
        Please try again later.`,
    disconnectedTitle: 'Disconnected from {integrationName}',
    disconnectedManagerDescription: `
        Request can not be created since the {integrationName} connection has been disabled.
        Please {link}.`,
    reconnectLinkText: 'reconnect to {integrationName}.',
    disconnectedUserDescription: `
        Request can not be created since the {integrationName} connection has been disabled.
        The company administrator has been informed about this.
        Sorry for the inconvenience.`,
    closePopup: 'Close',
});
