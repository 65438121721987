import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('pages.RequestList.AddCommentSection', {
    placeholderText: 'Leave a comment',
    fileTooBigError: 'Sorry, the file "{fileName}" exceeds the maximum size of {maxSize}.',
    fileExtensionNotSupportedError: 'Sorry, the submitted file type is not supported.',
    failedToUploadFileGeneric: 'Oops... The file upload has failed.',
    uploadErrorRestrictedFileType: 'Sorry, the file type of "{fileName}" cannot be uploaded due to security reasons.',
    uploadErrorTooBig: 'Sorry, the file "{fileName}" exceeds the maximum size of {maxSize}.',
    uploadErrorVirusFound:
        'Sorry, the file "{fileName}" cannot be uploaded; our antivirus software indicates that it contains a virus.',
    uploadErrorGeneric: 'Oops, something went wrong.',
    maxNumberOfAttachmentsExceeded: 'Sorry, you cannot attach more that 10 files to a comment.',
    dropHereText: 'Drop the files here',
    uploadingText: 'Uploading',
});
