import { typeGuardHelpers } from '@approvalmax/utils';
import { selectors } from 'modules/common';
import moment from 'moment/moment';

import { nudgeCooldownTimeSeconds } from './NudgeAction.constants';

export const getMaxDate = (dates: (string | null | undefined)[]) => {
    const values = dates.filter(typeGuardHelpers.isTruthy);
    const valuesDates = values.map((date) => moment(date));

    const maxValue = moment.max(valuesDates);

    return maxValue.toISOString();
};

export const getCooldownTimeDueToRequest = (request: selectors.types.ExpandedRequest) => {
    const { createdDate, submittedAt, lastNudgedAt } = request;

    const now = moment();

    const maxDate = getMaxDate([createdDate, submittedAt, lastNudgedAt]);
    const cooldownDate = moment(maxDate).add(nudgeCooldownTimeSeconds, 'seconds');

    return cooldownDate.diff(now, 'seconds');
};

export const getCooldownTimeDueToSubmit = (request: selectors.types.ExpandedRequest) => {
    const { createdDate, submittedAt } = request;

    const now = moment();

    const maxDate = getMaxDate([createdDate, submittedAt]);
    const cooldownDate = moment(maxDate).add(nudgeCooldownTimeSeconds, 'seconds');

    return cooldownDate.diff(now, 'seconds');
};
