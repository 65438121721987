import { RequestMutationOptions } from '@approvalmax/data';
import { notificationService } from 'services/notification';
import { useMutate } from 'shared/data';

import { companiesApiPaths } from '../paths';
import { useInvalidateGetCompanies } from '../useGetCompanies/useGetCompanies';
import { DeleteCompany } from './useDeleteCompany.types';

export const useDeleteCompany = (mutationOptions?: RequestMutationOptions<DeleteCompany>) => {
    const invalidateGetCompanies = useInvalidateGetCompanies();

    return useMutate(companiesApiPaths.delete, {
        mutationOptions: {
            ...mutationOptions,
            onSuccess: async (data, variables, context) => {
                await invalidateGetCompanies({ query: { companyId: variables.body?.companyId } });
                mutationOptions?.onSuccess?.(data, variables, context);
            },
            onError: (error, variables, context) => {
                notificationService.showErrorToast(error.message);
                mutationOptions?.onError?.(error, variables, context);
            },
        },
        apiVersion: 'v1',
    });
};
