export interface CreateOrganisationPopupProps {
    closable?: boolean;
    isLoading?: boolean;
    isLoadStepFromLocalStorage?: boolean;
    withInviteTeammate?: boolean;
    onClose?: () => void;
    onConnectXero: () => void;
    onConnectQBooks: () => void;
    onConnectNetSuite: () => void;
    onConnectCin7: () => void;
    onConnectStandalone: () => void;
    onInviteTeammate: () => void;
}

export enum CurrentStep {
    Initial = 'Initial',
    Xero = 'Xero',
    QBooks = 'QBooks',
    NetSuite = 'NetSuite',
    Cin7 = 'Cin7',
    QBooksVideo = 'QBooksVideo',
    XeroVideo = 'XeroVideo',
    InviteTeammate = 'InviteTeammate',
    ConfirmSkip = 'ConfirmSkip',
}

export type ConfirmSkipPrevStep = CurrentStep.Xero | CurrentStep.QBooks | CurrentStep.NetSuite | CurrentStep.Cin7;
