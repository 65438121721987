import { FC, memo } from 'react';

import { ChevronDownIcon } from '../../../../icons';
import Button from '../../../Button/Button';
import Dropdown from '../../../Dropdown/Dropdown';
import Menu from '../../../Menu/Menu';
import { getUrlProps } from '../../Breadcrumbs.helpers';
import { getActivatorName } from './DropdownSections.helpers';
import { DropdownSectionsProps } from './DropdownSections.types';

const DropdownSections: FC<DropdownSectionsProps> = memo((props) => {
    const { items, as, value, ...restProps } = props;

    if (!items?.length) return null;

    return (
        <Dropdown
            display='flex'
            activator={
                <Button
                    noPadding
                    noUppercase
                    variant='text'
                    color='blue100'
                    size='small'
                    endIcon={<ChevronDownIcon size={12} />}
                >
                    {getActivatorName(value, items)}
                </Button>
            }
            hasArrow
            width='max-content'
            maxHeight='auto'
            {...restProps}
        >
            <Menu divider size='small'>
                {items.map(({ name, to }, index) => (
                    <Menu.Item name={name} {...getUrlProps(as, to)} key={`${to}-${index}`} />
                ))}
            </Menu>
        </Dropdown>
    );
});

export default DropdownSections;
