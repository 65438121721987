import { InfoCircleIcon } from '@approvalmax/ui';
import { Flex, Text } from '@approvalmax/ui/src/components';
import { memo } from 'react';

import { LineItemsSummaryRowProps } from './LineItemsSummaryRow.types';

export const LineItemsSummaryRow = memo<LineItemsSummaryRowProps>((props) => {
    const { label, value, bold, warning, qa } = props;

    return (
        <Flex alignItems='center' justifyContent='end' wrap={false}>
            <Flex alignItems='center' spacing='4'>
                {warning && <InfoCircleIcon width={12} height={12} color='midnight70' />}

                <Text font='label' color='midnight70' textAlign='right' fontSize={bold ? 'medium' : 'small'}>
                    {label}
                </Text>
            </Flex>

            <Flex width={180} shrink={0} justifyContent='end'>
                <Text
                    font='label'
                    color='midnight100'
                    textAlign='right'
                    fontSize={bold ? 'medium' : 'small'}
                    fontWeight={bold ? 'bold' : 'regular'}
                    data-qa={qa}
                >
                    {value}
                </Text>
            </Flex>
        </Flex>
    );
});

LineItemsSummaryRow.displayName = 'LineItemsSummaryRow';
