import { RequestMutationOptions } from '@approvalmax/data';
import { useMutate } from 'shared/data';

import { companiesApiPaths } from '../paths';
import { useInvalidateGetBudgetSettings } from '../useGetBudgetSettings/useGetBudgetSettings';
import { UpdateBudgetSettings } from './useUpdateBudgetSettings.types';

export const useUpdateBudgetSettings = (mutationOptions?: RequestMutationOptions<UpdateBudgetSettings>) => {
    const invalidateGetBudgetSettings = useInvalidateGetBudgetSettings();

    return useMutate(companiesApiPaths.updateBudgetSettings, {
        mutationOptions: {
            ...mutationOptions,
            onSuccess: async (data, variables, context) => {
                await invalidateGetBudgetSettings({ query: { companyId: variables.body?.companyId } });

                mutationOptions?.onSuccess?.(data, variables, context);
            },
        },
        apiVersion: 'v1',
    });
};
