import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('modules.FirstStartModule.ConnectToPanel', {
    connectButtonText: 'Connect',
    xeroTitle: 'Xero',
    xeroDescription: `
        Connect to your Xero account in order to set up multi-step approval workflows for your Xero documents such as
        Purchase Orders, Bills, AP Credit Notes, Sales Invoices, AR Credit Notes.`,
    qbTitle: 'QuickBooks Online',
    qbDescription: `
        Connect to your QuickBooks Online account in order to setup multi-step approval workflows for QuickBooks Online
        documents, such as Bills, Purchase Orders and Expenses`,
    learnMoreText: 'Learn more',
    sectionHeader: 'Connect your accounting software:',
    xeroConnectButtonTitle: 'Connect to your Xero account.',
    qbConnectButtonTitle: 'Connect to your QuickBooks Online account.',
});
