import { enabledByParams, RequestParams, RequestQueryOptions, useInvalidateQuery } from '@approvalmax/data';
import { useGet } from 'shared/data';

import { requestsApiPaths } from '../paths';
import { GetQuoteForCustomerDecision } from './useGetQuoteForCustomerDecision.types';

export const useGetQuoteForCustomerDecision = (
    params: RequestParams<GetQuoteForCustomerDecision>,
    queryOptions?: RequestQueryOptions<GetQuoteForCustomerDecision>
) => {
    return useGet(requestsApiPaths.getQuoteForCustomerDecision, {
        params,
        queryOptions: {
            ...queryOptions,
            enabled: enabledByParams<GetQuoteForCustomerDecision['params']['query']>(
                {
                    companyId: params.query?.companyId,
                    requestId: params.query?.requestId,
                    code: params.query?.code,
                },
                queryOptions
            ),
        },
        apiVersion: 'v1',
    });
};

export const useInvalidateGetQuoteForCustomerDecision = () => {
    return useInvalidateQuery<RequestParams<GetQuoteForCustomerDecision> | void>(
        requestsApiPaths.getQuoteForCustomerDecision
    );
};
