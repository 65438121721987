// Advanced plan
export const NS_AUTO_APPROVAL_LEARN_MORE_LINK =
    'https://support.approvalmax.com/portal/en/kb/articles/what-is-auto-approval-step-netsuite';

export const NETSUITE_NAVIGATION_ADVANCED_FEATURES = [];

export const NETSUITE_BILL_LEARN_MORE_LINK =
    'https://support.approvalmax.com/portal/en/kb/articles/how-do-i-set-up-the-pull-from-oracle-netsuite-step-for-bills';

export const NETSUITE_PO_LEARN_MORE_LINK =
    'https://support.approvalmax.com/portal/en/kb/articles/how-do-i-set-up-the-pulling-from-oracle-netsuite-step-for-purchase-orders';

export const NETSUITE_BILL_PULLING_LEARN_MORE_LINK =
    'https://support.approvalmax.com/portal/en/kb/articles/how-to-pull-bills-from-netsuite';

export const NETSUITE_PO_PULLING_LEARN_MORE_LINK =
    'https://support.approvalmax.com/portal/en/kb/articles/how-do-i-set-up-the-pulling-from-oracle-netsuite-step-for-purchase-orders';

// Numeric
export const maxTotalAmount = 1e15 - 1;
export const maxLineItemAmount = 1e13 - 1;
export const exchangeRateMaxValue = 1e9 - 1;
export const quantityMaxValue = 1e10 - 1;
export const unitPriceMaxValue = 1e9 - 1;
export const discountAmountMaxValue = 1e15 - 1;
export const taxAmountMaxValue = 1e15 - 1;
export const taxAmountMaxDigitsCount = 15;
export const referenceMaxLength = 45;
export const descriptionMaxLength = 4000;
export const memoMaxLength = 999;
export const purposeMaxLength = 4000;
export const maxCustomNumericFieldAmount = 1e15 - 1;
export const advanceMaxValue = 1e15 - 1;
export const maxCreditLimit = 1e13 - 0.01;
export const discountRateMaxLength = 18;
export const discountRateMaxValue = 1e9 - 0.01;
export const customFormIdMaxValue = Math.pow(2, 31) - 1;

// Vendor creation
export const companyNameMaxLength = 83;
export const webAddressMaxLength = 99;
export const salutationMaxLength = 15;
export const firstNameMaxLength = 32;
export const middleNameMaxLength = 32;
export const lastNameMaxLength = 32;
export const jobTitleMaxLength = 99;
export const commentsMaxLength = 999;
export const emailMaxLength = 254;
export const phoneMaxLength = 32;
export const altPhoneMaxLength = 32;
export const faxMaxLength = 32;
export const legalNameMaxLength = 83;
export const accountNumberMaxLength = 99;
export const taxNumberMaxLength = 25;

// Vendor Address lines
export const labelMaxLength = 150;
export const attentionMaxLength = 150;
export const addresseeMaxLength = 100;
export const addressPhoneMaxLength = 50;
export const primaryAddressMaxLength = 150;
export const secondaryAddressMaxLength = 150;
export const stateMaxLength = 30;
export const cityMaxLength = 50;
export const zipMaxLength = 36;

// Custom fields related
export const ignoredCustomFields = ['Password', 'Image', 'Help', 'InlineHtml', 'Document'];
export const numericCustomFields = ['IntegerNumber', 'DecimalNumber', 'Currency', 'Percent'];
export const booleanCustomFields = ['CheckBox'];
export const referenceCustomFields = ['ListRecord', 'MultipleSelect'];

export const skippedExternalStatuses = ['unknown', 'pending supervisor approval'];
