import { forwardRef, memo } from 'react';

import { Box } from '../../../Box/Box';
import { Flex } from '../../../Flex/Flex';
import { Item } from '../Item/Item';
import { ListProps } from './List.types';

/* The List component creates lists with menu items */
export const List = memo(
    forwardRef<HTMLUListElement, ListProps>((props, ref) => {
        const { items, spacing, direction = 'column', wrap = true, qa, ...restProps } = props;

        if (!items) return null;

        return (
            <Box role='menu' {...restProps} as='ul' spacing={spacing} ref={ref} data-qa={qa}>
                <Flex direction={direction} wrap={wrap} spacing={direction === 'row' ? '8 0' : '0 16'}>
                    {items?.length &&
                        items.map((item, index) => (
                            <Item
                                {...item}
                                data-qa={`${qa}-${item.id || item.name || index}`}
                                key={item.id || index}
                                id={item.id || index}
                            />
                        ))}
                </Flex>
            </Box>
        );
    })
);

List.displayName = 'List';
