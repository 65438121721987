import { useAtom, useSetAtom } from 'jotai';
import { checkExpenseLineIsEmpty } from 'pages/requestForm/containers/netsuite/NetSuiteExpenseReportRequestForm/helpers';
import { useCallback } from 'react';
import { hasChangesState, netSuiteExpenseLinesState } from 'shared/states';

export const useRemoveNetSuiteExpenseReportExpenseLineAttachment = () => {
    const setExpenseLines = useSetAtom(netSuiteExpenseLinesState);
    const [hasChanges, setHasChanges] = useAtom(hasChangesState);

    return useCallback(
        (attachmentId: string) => {
            setExpenseLines((expenseLines) =>
                expenseLines.map((line) => {
                    if (line.attachment?.id !== attachmentId) {
                        return line;
                    }

                    const newLine = {
                        ...line,
                        attachment: null,
                    };

                    newLine.isEmpty = checkExpenseLineIsEmpty(newLine);

                    return newLine;
                })
            );

            if (!hasChanges) {
                setHasChanges(true);
            }
        },
        [hasChanges, setHasChanges, setExpenseLines]
    );
};
