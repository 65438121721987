import { useBillLinkedToNetSuiteBillPayment } from 'pages/requestList/hooks/useBillLinkedToNetSuiteBillPayment';

import { UseBillPaymentBillParams } from './NetSuiteBillLinkedToBillPaymentRequestCardWrapper.types';

export const useBillPaymentBill = ({
    companyId,
    lineRequestId,
    billPaymentRequestId,
    accountId,
    currencyId,
    payeeId,
    enabled,
}: UseBillPaymentBillParams) => {
    const { request } = useBillLinkedToNetSuiteBillPayment(
        {
            query: {
                payeeId,
                accountId,
                currencyId,
            },
            path: {
                companyId,
                lineRequestId,
                billPaymentRequestId,
            },
        },
        {
            enabled,
        }
    );

    return {
        request,
    };
};
