import { arrayHelpers, compareHelpers, errorHelpers } from '@approvalmax/utils';
import { constants, selectors, statics } from 'modules/common';
import { backend, domain, stateTree } from 'modules/data';
import moment from 'moment';
import createCachedSelector from 're-reselect';
import { createSelector } from 'reselect';
import { isOcrEnabledForTemplate } from 'shared/helpers';

import { findCurrencyByCode } from '../statics/currency';
import { messages } from './companySelectors.messages';
import { findIntegrationById } from './integrationSelectors';
import { getCreatableTemplates } from './metaSelectors';
import * as navigationSelectors from './navigationSelectors';
import * as profileSelectors from './profileSelectors';
import { ExpandedCompany, ExpandedCompanyUser, ExpandedUser } from './types';
import * as userSelectors from './userSelectors';
import { getNewUsers, getUserById, getUsers } from './userSelectors';

const { xeroConstants, qBooksConstants } = constants;

type State = stateTree.State;

function getAvailableIntegrations(company: domain.Company) {
    let result: domain.IntegrationType[] = [];

    if (company.licenseFeatures.includes(domain.CompanyLicenseFeature.XeroWorkflows)) {
        result.push(domain.IntegrationType.Xero);
    }

    if (company.licenseFeatures.includes(domain.CompanyLicenseFeature.QBOWorkflows)) {
        result.push(domain.IntegrationType.QBooks);
    }

    if (company.licenseFeatures.includes(domain.CompanyLicenseFeature.NetSuiteWorkflows)) {
        result.push(domain.IntegrationType.NetSuite);
    }

    if (company.licenseFeatures.includes(domain.CompanyLicenseFeature.DearWorkflows)) {
        result.push(domain.IntegrationType.Dear);
    }

    return result;
}

function getAvailableStandaloneTemplates(company: domain.Company) {
    return company.licenseFeatures.includes(domain.CompanyLicenseFeature.AMWorkflows);
}

function getLicenseExpiresInDays(expires: string | null) {
    if (!expires) {
        return null;
    }

    const expirationDate = moment.utc(expires);
    const now = moment.utc();
    const days = Math.floor(moment.duration(expirationDate.diff(now)).as('days')) + 1;

    return Math.max(0, days);
}

const getQBooksMatchingFlags = (company: domain.Company) => {
    const hasQBooksMatchingLicenseFeature = company.licenseFeatures.includes(domain.CompanyLicenseFeature.QBOMatching);

    return {
        isQBooksMatching: hasQBooksMatchingLicenseFeature,
    };
};

export const getOcrAvailableCompanies = createSelector(
    (state: State) => state.entities.templates,
    getCreatableTemplates,
    getCompanies,
    (templates, creatableTemplates, companies) =>
        companies.filter((company) => {
            if (company.integration && company.integration.status === domain.IntegrationStatus.Connected) {
                return Object.values(templates).some(
                    (template) =>
                        template.companyId === company.id &&
                        isOcrEnabledForTemplate(company, template.integrationCode) &&
                        creatableTemplates.includes(template.id)
                );
            }

            return false;
        })
);

const getXeroMatchingFlags = (params: { company: domain.Company; integrationType?: domain.IntegrationType }) => {
    const { company, integrationType } = params;

    if (integrationType !== domain.IntegrationType.Xero) {
        return {
            isXeroMatchingV2ReadOnly: false,
            isXeroMatchingV2: false,
        };
    }

    const hasXeroMatchingV2Documents = company.matchingMetadata?.hasMatchedV2Documents || false;
    const isXeroMatchingV2 =
        company.betaFeatures.includes(domain.CompanyBetaFeature.XeroMatchingV2) ||
        company.licenseFeatures.includes(domain.CompanyLicenseFeature.XeroMatchingV2);

    return {
        isXeroMatchingV2,
        isXeroMatchingV2ReadOnly: !isXeroMatchingV2 && hasXeroMatchingV2Documents,
    };
};

const expandCompanyCombiner = (
    company: domain.Company,
    integration: selectors.types.ExpandedIntegration | null,
    me: selectors.types.ExpandedUser,
    members: selectors.types.ExpandedCompanyUser[],
    subscription: domain.Subscription,
    accountProfile: selectors.types.ExpandedProfile | null
) => {
    const defaultCurrency = company.defaultCurrency || statics.currency.defaultCurrency;
    const defaultCurrencyReference = findCurrencyByCode(defaultCurrency);
    const hasActiveIntegration = Boolean(integration && integration.status === domain.IntegrationStatus.Connected);

    const licenseExpiresInDays = getLicenseExpiresInDays(company.licenseExpires);
    const trialOfAdvancedExpiresInDays = getLicenseExpiresInDays(company.allFeaturesTrialEndDate);
    const isTrialLicense = company.licenseProductPlan === domain.CompanyPlan.Trial;
    const isAllFeaturesTrialActive = company.allFeaturesTrialStatus === domain.AllFeaturesTrialStatus.Active;
    const isPartnerLicense = company.licenseProductPlan === domain.CompanyPlan.Partner;
    const isExpired = licenseExpiresInDays != null && licenseExpiresInDays <= 0 && !isPartnerLicense;
    const isRetired = company.licenseProductPlan === domain.CompanyPlan.Retired;
    const isAirwallexPaymentAvailable = Boolean(
        integration?.integrationType === domain.IntegrationType.Xero &&
            (company.betaFeatures.includes(domain.CompanyBetaFeature.XeroAirwallexBatchPayment) ||
                (company.licenseFeatures.includes(domain.CompanyLicenseFeature.AirwallexXeroBatchPayments) &&
                    getAirwallexIsAvailableByCountryCode(integration.countryCode)))
    );

    const meInCompany = members.find((member) => member.id === me.id);
    const myRole = meInCompany ? meInCompany.role : domain.CompanyUserRole.None;

    const xeroMatchingFlags = getXeroMatchingFlags({
        company,
        integrationType: integration?.integrationType,
    });
    const qbooksMatchingFlags = getQBooksMatchingFlags(company);

    const isInAccountOwnerAccount = accountProfile?.account?.ownerUserProfileId === company.authorId;
    const isUserAccountManager = accountProfile?.account?.userRole === domain.AccountMemberRole.Manager;

    const isAccountOwner = me.id === company.author;

    return {
        ...company,
        flags: {
            isAccountOwner,
            canManageAccount: (isInAccountOwnerAccount && isUserAccountManager) || isAccountOwner,
            isManager: myRole === domain.CompanyUserRole.Manager,
            isAuditor: myRole === domain.CompanyUserRole.Auditor,
            isWorkflowManager: myRole === domain.CompanyUserRole.WorkflowManager,
            hasActiveIntegration,
            isPaid: organisationPaidPlans.includes(company.licenseProductPlan),
            isTrialLicense,
            isAllFeaturesTrialActive,
            isExpired,
            isRetired,
            isGraceSubscription: subscription?.status === domain.SubscriptionStatus.Grace,
            isAirwallexPaymentAvailable,
            ...xeroMatchingFlags,
            ...qbooksMatchingFlags,
        },
        allMembers: members,
        myRole,
        licenseExpiresInDays,
        trialOfAdvancedExpiresInDays,
        integration,
        betaFeatures: company.betaFeatures || [],
        availableIntegrations: getAvailableIntegrations(company),
        availableStandaloneTemplates: getAvailableStandaloneTemplates(company),
        defaultCurrency,
        displayName: company.name || messages.defaultDisplayName,
        defaultCurrencyText: defaultCurrencyReference ? defaultCurrencyReference.text : '',
    };
};

const getCompaniesMap = (state: State) => state.entities.companies;

const getIntegrationsMap = (state: State) => state.entities.integrations;

const getSubscriptionsMap = (state: State) => state.entities.subscriptions;

const extendedCompanySelector = createCachedSelector(
    (company: domain.Company) => company,
    (company: domain.Company, integrationsMap: ReturnType<typeof getIntegrationsMap>) =>
        findIntegrationById(integrationsMap, company.integrationId),
    (
        company: domain.Company,
        integrationsMap: ReturnType<typeof getIntegrationsMap>,
        subscriptionsMap: ReturnType<typeof getSubscriptionsMap>,
        users: ReturnType<typeof selectors.user.getUsers>,
        profile: selectors.types.ExpandedUser
    ) => profile,
    (
        company: domain.Company,
        integrationsMap: ReturnType<typeof getIntegrationsMap>,
        subscriptionsMap: ReturnType<typeof getSubscriptionsMap>,
        users: ReturnType<typeof selectors.user.getUsers>
    ) => getCompanyTeamInternal(users, company),
    (
        company: domain.Company,
        integrationsMap: ReturnType<typeof getIntegrationsMap>,
        subscriptionsMap: ReturnType<typeof getSubscriptionsMap>
    ) => Object.values(subscriptionsMap).find((subscription) => subscription.companyIds.includes(company.id)) || null,
    (
        company: domain.Company,
        integrationsMap: ReturnType<typeof getIntegrationsMap>,
        subscriptionsMap: ReturnType<typeof getSubscriptionsMap>,
        users: ReturnType<typeof selectors.user.getUsers>,
        profile: selectors.types.ExpandedUser,
        accountProfile: selectors.types.ExpandedProfile | null
    ) => accountProfile,
    expandCompanyCombiner
)((company) => company.id);

const organisationPaidPlans = [
    domain.CompanyPlan.Professional,
    domain.CompanyPlan.QBooksPremium,
    domain.CompanyPlan.XeroPremium,
    domain.CompanyPlan.QBooks,
    domain.CompanyPlan.Xero,
    domain.CompanyPlan.XeroAdvanced,
    domain.CompanyPlan.QBooksAdvanced,
    domain.CompanyPlan.NetSuiteAdvanced,
];

export const findCompanyById = createCachedSelector(
    (state: State, companyId: string) => state.entities.companies[companyId],
    getIntegrationsMap,
    getSubscriptionsMap,
    getUsers,
    (state: State) => profileSelectors.findProfileUser(state),
    (state: State) => profileSelectors.findProfile(state),
    (company, integrations, subscriptions, users, profile, accountProfile) => {
        if (!company) {
            return null;
        }

        return extendedCompanySelector(company, integrations, subscriptions, users, profile, accountProfile);
    }
)((state, companyId) => companyId);

export function getCompanyById(state: State, companyId: string): ExpandedCompany {
    const company = findCompanyById(state, companyId);

    if (!company) {
        throw errorHelpers.notFoundError(`Company not found.`);
    }

    return company;
}

export function getCompanyTeam(state: State, company: domain.Company) {
    return getCompanyById(state, company.id).allMembers;
}

const getCompanyTeamInternal: (
    users: ReturnType<typeof selectors.user.getUsers>,
    company: domain.Company
) => ExpandedCompanyUser[] = createCachedSelector(
    (users: ReturnType<typeof selectors.user.getUsers>) => users,
    (users: ReturnType<typeof selectors.user.getUsers>, company: domain.Company) => company,
    (users: ReturnType<typeof selectors.user.getUsers>, company: domain.Company) => getNewUsers(company),
    (users, company, newUsers) => {
        const companyPermanentMembers = [
            ...company.auditors,
            ...company.managers,
            ...company.participants,
            ...company.workflowManagers,
        ];

        return companyPermanentMembers
            .map<ExpandedCompanyUser>((userId) => {
                const delegateLink = company.delegates.find((delegate) => delegate.userId === userId);
                const status = getCompanyUserStatus(company, userId);
                const user = users.find((user) => user.id === userId);

                if (!user) {
                    throw errorHelpers.notFoundError(`getCompanyTeam: The user '${userId}' is missing in the store.`);
                }

                let displayName = user.displayName;

                if (status !== domain.CompanyUserStatus.Active) {
                    displayName = user.userEmail;
                }

                return {
                    ...user,
                    displayName,
                    delegateUserId: delegateLink ? delegateLink.delegateUserId : undefined,
                    role: getCompanyUserRole(company, userId),
                    status,
                    statusText: getCompanyUserStatusText(status),
                };
            })
            .concat(
                newUsers
                    .filter((newUser) => {
                        let role = getCompanyUserRole(company, newUser.id);

                        // Those who have roles are already in the list (permanent members)
                        return role === domain.CompanyUserRole.None;
                    })
                    .map((newUser) => {
                        return {
                            ...newUser,
                            role: domain.CompanyUserRole.Participant,
                            status: domain.CompanyUserStatus.NotInvited,
                            statusText: getCompanyUserStatusText(domain.CompanyUserStatus.NotInvited),
                        };
                    })
            )
            .sort(userSelectors.userComparatorAsc);
    }
)((users, company) => company.id);

/**
 * The usage of this method is discouraged for most cases as you normally have a userId
 * which could have been removed from the company. It leads to 'notFound' bugs.
 * @see getCompanyUserById as the most preferable alternative.
 */
export function getCompanyMemberById(state: State, company: domain.Company, userId: string): ExpandedCompanyUser {
    const user = findCompanyMemberById(state, company, userId);

    if (!user) {
        throw errorHelpers.notFoundError(userId);
    }

    return user;
}

export const findCompanyMemberById: (
    state: State,
    company: domain.Company,
    userId: string
) => ExpandedCompanyUser | null = createCachedSelector(
    (state: State, company: domain.Company) => getCompanyById(state, company.id).allMembers,
    (state: State, company: domain.Company, userId: string) => userId,
    (team, userId) => {
        const user = team.find((x) => x.id === userId);

        if (!user) {
            return null;
        }

        return user;
    }
)((state, company, userId) => `${company.id}+${userId}`);

export function getCompanyUserById(state: State, company: domain.Company, userId: string): ExpandedUser {
    return findCompanyMemberById(state, company, userId) || getUserById(state, userId);
}

/**
 * @deprecated if you don't need sorted company list, please consider using of {@link companiesSelector}
 *  if you need sorted companies, though, use {@link useSortedCompanies}
 */
export function getCompanies(state: State): ExpandedCompany[] {
    return arrayHelpers.arraySort(
        Object.keys(state.entities.companies || {}).map((id) => getCompanyById(state, id)),
        compareHelpers.comparatorFor<ExpandedCompany>(compareHelpers.stringComparator2AscI, 'displayName')
    );
}

export const companiesSelector = createSelector(
    [
        getCompaniesMap,
        getIntegrationsMap,
        getSubscriptionsMap,
        getUsers,
        (state: State) => profileSelectors.findProfileUser(state),
        (state: State) => profileSelectors.findProfile(state),
    ],
    (companiesMap, integrations, subscriptions, users, profile, accountProfile) =>
        Object.values(companiesMap).map((company) =>
            extendedCompanySelector(company, integrations, subscriptions, users, profile, accountProfile)
        )
);

export function getCompanyUserRole(company: domain.Company, userId: string) {
    if (company.auditors.includes(userId)) {
        return domain.CompanyUserRole.Auditor;
    }

    if (company.managers.includes(userId)) {
        return domain.CompanyUserRole.Manager;
    }

    if (company.participants.includes(userId)) {
        return domain.CompanyUserRole.Participant;
    }

    if (company.workflowManagers.includes(userId)) {
        return domain.CompanyUserRole.WorkflowManager;
    }

    return domain.CompanyUserRole.None;
}

export function getCompanyUserStatus(company: domain.Company, userId: string): domain.CompanyUserStatus {
    return company.userStatuses[userId] || domain.CompanyUserStatus.NotInvited;
}

export function canUpdateCompanySettings(state: State, company: domain.Company): boolean {
    const me = profileSelectors.getProfileUser(state);
    const role = getCompanyUserRole(company, me.id);

    return role === domain.CompanyUserRole.Manager || role === domain.CompanyUserRole.WorkflowManager;
}

export function canUpdateActiveCompanySettings(state: State): boolean {
    const company = navigationSelectors.getActiveCompany(state);

    return canUpdateCompanySettings(state, company);
}

export const canChangeTeamMembersRoles = (
    state: State,
    company: selectors.types.ExpandedCompany,
    meProfile: selectors.types.ExpandedUser | null
) => {
    const canUpdateRoles = selectors.company.canUpdateCompanySettings(state, company);

    return (email: string) => {
        const isSuperAdmin = email === company.author;

        // disable everything while profile data not loaded
        if (!meProfile) {
            return false;
        }

        return !(
            email === meProfile.id ||
            isSuperAdmin ||
            company.flags.isRetired ||
            company.isReadonly ||
            company.flags.isWorkflowManager ||
            !canUpdateRoles
        );
    };
};

export function getCompanyUserRoleText(role: domain.CompanyUserRole, isSuperAdmin?: boolean) {
    if (isSuperAdmin) {
        return messages.userRoleAccountOwner;
    }

    switch (role) {
        case domain.CompanyUserRole.Participant:
            return messages.userRoleParticipant;

        case domain.CompanyUserRole.Auditor:
            return messages.userRoleAuditor;

        case domain.CompanyUserRole.Manager:
            return messages.userRoleManager;

        case domain.CompanyUserRole.WorkflowManager:
            return messages.userRoleWorkflowManager;

        case domain.CompanyUserRole.None:
            throw errorHelpers.invalidOperationError();

        default: {
            const _exhaustiveCheck: never = role;

            throw errorHelpers.invalidOperationError();
        }
    }
}

export function getCompanyUserStatusText(status: domain.CompanyUserStatus) {
    switch (status) {
        case domain.CompanyUserStatus.Active:
            return messages.userStatusActive;

        case domain.CompanyUserStatus.Invited:
            return messages.userStatusInvited;

        case domain.CompanyUserStatus.Rejected:
            return messages.userStatusRejected;

        case domain.CompanyUserStatus.NotInvited:
            return messages.userStatusNotInvited;

        default:
            throw errorHelpers.invalidOperationError();
    }
}

export function isExpandedCompanyUser(user: domain.User | undefined): user is ExpandedCompanyUser {
    return Boolean((user as ExpandedCompanyUser).status);
}

/**
 * Returns an arrays of companies that I manage and that have their trial coming to an end (<14 days)
 */
export function getTrialCompaniesExpiringIn14Days(state: State) {
    return getCompanies(state).filter(
        (c) =>
            c.flags.isManager &&
            c.flags.isTrialLicense &&
            !c.flags.isExpired &&
            c.licenseExpiresInDays != null &&
            c.licenseExpiresInDays <= 14
    );
}

/**
 * Returns an arrays of companies that I manage and that have expired
 */
export function getExpiredTrialCompanies(state: State) {
    return getCompanies(state).filter((c) => c.flags.isManager && c.flags.isTrialLicense && c.flags.isExpired);
}

/**
 * Returns an arrays of companies that I manage and that have expired
 */
export function getExpiredTrialOfAdvancedFeaturesCompanies(state: State) {
    return getCompanies(state).filter((c) => c.allFeaturesTrialStatus === domain.AllFeaturesTrialStatus.Expired);
}

export function getTrialOfAdvancedFeaturesCompanies(state: State) {
    return getCompanies(state).filter((c) => c.flags.isAllFeaturesTrialActive && c.flags.isManager);
}

export const getAirwallexIsAvailableByCountryCode = (countryCode?: backend.OrganisationVersion) => {
    if (!countryCode) return false;

    return [backend.OrganisationVersion.UK, backend.OrganisationVersion.AU, backend.OrganisationVersion.NZ].includes(
        countryCode
    );
};

export function getFeaturesByProductPlan(companyPlan: domain.CompanyPlan, countryCode?: backend.OrganisationVersion) {
    switch (companyPlan) {
        case domain.CompanyPlan.Xero:
            return getAirwallexIsAvailableByCountryCode(countryCode)
                ? [
                      ...xeroConstants.XERO_AMXO_FEATURES,
                      xeroConstants.XERO_AIRWALLEX_FEATURE,
                      xeroConstants.XERO_QUOTE_FEATURE,
                  ]
                : xeroConstants.XERO_AMXO_FEATURES;

        case domain.CompanyPlan.XeroAdvanced:
            return getAirwallexIsAvailableByCountryCode(countryCode)
                ? [...xeroConstants.XERO_AMXV_FEATURES, xeroConstants.XERO_AIRWALLEX_FEATURE]
                : xeroConstants.XERO_AMXV_FEATURES;

        case domain.CompanyPlan.QBooks:
            return qBooksConstants.QBO_AMQB_FEATURES;

        case domain.CompanyPlan.QBooksAdvanced:
            return qBooksConstants.QBO_AMQV_FEATURES;

        default:
            return [];
    }
}

export const getAwaitingActivationTrialCompaniesAllRoles: (state: State) => selectors.types.ExpandedCompany[] =
    createSelector(
        (state: State) => getCompanies(state),
        (expandedCompanies: selectors.types.ExpandedCompany[]) =>
            expandedCompanies.filter(
                (company) =>
                    company.integration?.integrationType &&
                    company.allFeaturesTrialStatus === domain.AllFeaturesTrialStatus.AwaitingActivation
            )
    );

export const getAwaitingActivationTrialCompanies: (state: State) => selectors.types.ExpandedCompany[] = createSelector(
    (state: State) => getAwaitingActivationTrialCompaniesAllRoles(state),
    (expandedCompanies: selectors.types.ExpandedCompany[]) =>
        expandedCompanies.filter((company) => company.flags.isManager)
);

export const getQBOMatchingIsAvailable = (company: domain.Company) => {
    return company.licenseFeatures.includes(domain.CompanyLicenseFeature.QBOMatching);
};

export function getCompanyEditTransfer(company: domain.Company): backend.transfers.CompanyEditTransfer {
    if (company.isReadonly) {
        return {
            companyId: company.id,
            name: company.name,
            enforcementTfaType: company.enforcementTfaType,
        };
    }

    return {
        companyId: company.id,
        name: company.name,
        defaultCurrency: company.defaultCurrency,
        timeZone: company.timeZone,
        enforcementTfaType: company.enforcementTfaType,
    };
}

export const getIsReviewStepAvailable = (company: ExpandedCompany) => {
    switch (company.integration?.integrationType) {
        case domain.IntegrationType.Xero:
            return (
                company.betaFeatures.includes(domain.CompanyBetaFeature.RequestReviewStep) ||
                company.licenseFeatures.includes(domain.CompanyLicenseFeature.RequestReviewStep)
            );

        case domain.IntegrationType.QBooks:
            return (
                company.betaFeatures.includes(domain.CompanyBetaFeature.QBORequestReviewStep) ||
                company.licenseFeatures.includes(domain.CompanyLicenseFeature.RequestReviewStep)
            );

        case domain.IntegrationType.NetSuite:
            return true;

        default:
            return false;
    }
};

export const getIsOnHoldStatusAvailable = (company: ExpandedCompany) => {
    return (
        company.betaFeatures.includes(domain.CompanyBetaFeature.OnHoldStatus) ||
        company.licenseFeatures.includes(domain.CompanyLicenseFeature.OnHoldStatus)
    );
};

export const getIsEditingOnApprovalAvailable = (
    company: ExpandedCompany,
    integrationCode: domain.IntegrationCode | null | undefined
) => {
    if (
        company.betaFeatures.includes(domain.CompanyBetaFeature.ReviewerV1) &&
        integrationCode === domain.IntegrationCode.XeroBill
    )
        return false;

    return (
        company.betaFeatures.includes(domain.CompanyBetaFeature.EditingOnApproval) ||
        company.licenseFeatures.includes(domain.CompanyLicenseFeature.EditingOnApproval)
    );
};
