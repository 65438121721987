import { errorHelpers } from '@approvalmax/utils';
import Intercom from '@intercom/messenger-js-sdk';
import { selectors } from 'modules/common';
import { domain, State } from 'modules/data';

class IntercomService {
    public init(state: State) {
        try {
            const profile = selectors.profile.findProfile(state);

            if (!profile || !profile.betaFeatures?.includes(domain.ProfileBetaFeature.Intercom)) {
                return;
            }

            const companies = selectors.company.companiesSelector(state);

            let isAdmin = false;
            let isUser = false;
            let isAuditor = false;
            let isWFmanager = false;

            for (const company of companies) {
                switch (company.myRole) {
                    case domain.CompanyUserRole.Manager:
                        isAdmin = true;
                        break;
                    case domain.CompanyUserRole.Participant:
                        isUser = true;
                        break;
                    case domain.CompanyUserRole.Auditor:
                        isAuditor = true;
                        break;
                    case domain.CompanyUserRole.WorkflowManager:
                        isWFmanager = true;
                        break;
                }
            }

            Intercom({
                app_id: 'habioeru',
                region: 'eu',

                user_id: profile.id,
                email: profile.email,
                name: profile.name,
                'First name': profile.firstName,
                Surname: profile.lastName,
                'Account ID': profile.account?.accountId,
                'Account Type': profile.account?.accountType,
                IsAdmin: isAdmin,
                IsUser: isUser,
                IsAuditor: isAuditor,
                IsWFmanager: isWFmanager,

                companies:
                    companies.length > 0
                        ? (companies.map((company) => {
                              const subscription = selectors.subscription.findCompanySubscription(state, company.id);

                              return {
                                  company_id: company.id,
                                  name: company.name,
                                  'Subscription ID': company.subscriptionId,
                                  'Account Owner Email': company.author,
                                  IntegrationType: company.integration?.integrationType,
                                  plan: company.licenseProductPlan,
                                  Lifecycle: company.licenseProductPlan === domain.CompanyPlan.Trial ? 'Trial' : 'Paid',
                                  'Billing system': subscription?.billingSystem,
                                  NFP: subscription?.edition === domain.CompanyPlanEdition.NotForProfit,
                                  UpcomingBillingDate: subscription?.nextBillingDate
                                      ? new Date(subscription.nextBillingDate).getTime() / 1000
                                      : null,
                                  HasCapture: company.licenseFeatures.includes(domain.CompanyLicenseFeature.Capture),
                                  HasPay: company.licenseFeatures.includes(domain.CompanyLicenseFeature.Pay),
                              };
                          }) as any)
                        : undefined,
            });
        } catch (error) {
            errorHelpers.captureException(error);
        }
    }
}

export const intercomService = new IntercomService();
