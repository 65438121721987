import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { useMount } from 'react-use';
import { useVerifyTwoFaEnablingCode } from 'shared/data/webApp/v1';
import { z } from 'zod';

import { useTwoFaWizardController, useTwoFaWizardStep } from '../../TwoFaEnablingWizard.hooks';
import { WizardStep } from '../../TwoFaEnablingWizard.types';
import { messages } from './StepCodeVerify.messages';
import { schema } from './StepCodeVerify.schema';

export const useStepCodeVerify = () => {
    const { setWizardStep } = useTwoFaWizardStep();
    const { wizardController, setWizardController } = useTwoFaWizardController();

    const { stateId } = wizardController;

    const form = useForm<z.infer<typeof schema>>({
        defaultValues: {
            setupVerificationCode: '',
        },
        resolver: zodResolver(schema),
    });

    const { mutate: verifyCode, isLoading } = useVerifyTwoFaEnablingCode({
        onSuccess: () => {
            setWizardStep(WizardStep.backupMethods);
        },
        onError: () => {
            form.setError(
                'setupVerificationCode',
                { message: messages.errorSetupVerificationCode },
                { shouldFocus: true }
            );
        },
    });

    const handleSubmit = form.handleSubmit((data) => {
        if (stateId && !isLoading) {
            verifyCode({
                body: {
                    stateId,
                    userCode: data.setupVerificationCode,
                },
            });
        }
    });

    useMount(() => {
        setWizardController((prev) => ({
            ...prev,
            title: messages.title,
            onPrev: () => setWizardStep(WizardStep.codeScan),
            onNext: handleSubmit,
        }));
    });

    return {
        form,
        handleSubmit,
    };
};
