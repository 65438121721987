import { numberHelpers } from '@approvalmax/utils';

import FilterType from './FilterType';
import { messages } from './numberRangeFilter.messages';

export interface NumberRangeFilter {
    type: FilterType.NumberRange;
    greaterOrEqual: number | null;
    lessOrEqual: number | null;
}

export function createNumberRangeFilter(greaterOrEqual: number | null, lessOrEqual: number | null): NumberRangeFilter {
    return {
        type: FilterType.NumberRange,
        greaterOrEqual,
        lessOrEqual,
    };
}

export function createAlwaysTrueFilter() {
    return createNumberRangeFilter(null, null);
}

export function parseFilter(filterAnswer: any): NumberRangeFilter {
    filterAnswer = filterAnswer || {};

    let greaterOrEqual = null;
    let lessOrEqual = null;

    if (numberHelpers.isNumber(filterAnswer.GreaterOrEqual)) {
        greaterOrEqual = filterAnswer.GreaterOrEqual;
    }

    if (numberHelpers.isNumber(filterAnswer.LessOrEqual)) {
        lessOrEqual = filterAnswer.LessOrEqual;
    }

    return {
        type: FilterType.NumberRange,
        greaterOrEqual,
        lessOrEqual,
    };
}

export function getFilterTransfer(filter: NumberRangeFilter): any {
    if (filter.greaterOrEqual === null && filter.lessOrEqual === null) {
        return null;
    }

    return {
        GreaterOrEqual: filter.greaterOrEqual,
        LessOrEqual: filter.lessOrEqual,
    };
}

export function getFilterPreviewText(filter: NumberRangeFilter): string | null {
    let startNumber = filter.greaterOrEqual;
    let endNumber = filter.lessOrEqual;

    if (startNumber === null && endNumber === null) {
        return null;
    }

    if (startNumber !== null && endNumber !== null) {
        // full range
        return messages.previewTextFull({
            startNumber,
            endNumber,
        });
    } else if (startNumber !== null) {
        // start number only
        return messages.previewTextStart({
            startNumber,
        });
    } else {
        // end number only
        return messages.previewTextEnd({
            endNumber,
        });
    }
}
