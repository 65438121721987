import { RequestParams, RequestQueryOptions } from '@approvalmax/data';
import { useGet } from 'shared/data/api';

import { paymentServicesApiPaths } from '../paths';
import { GetAmaxPayOpenBankingBanks } from './useGetAmaxPayOpenBankingBanks.types';

export const useGetAmaxPayOpenBankingBanks = (
    params: RequestParams<GetAmaxPayOpenBankingBanks>,
    queryOptions?: RequestQueryOptions<GetAmaxPayOpenBankingBanks>
) =>
    useGet(paymentServicesApiPaths.amaxPayOpenBankingBanks, {
        params,
        queryOptions,
        apiVersion: 'v1',
        method: 'get',
    });
