import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('pages.RequestList.Toolbar', {
    matchingPolicyViolationNotMatchedToAnyPurchaseOrder:
        "{bill} can't be approved since it is not matched to any Purchase Order.\nYou need to match this Bill to a Purchase Order in order to approve it.",
    matchingPolicyViolationNotMatchedToAnyPurchaseOrderAndExceedsThreshold:
        '{bill} cannot be approved since it is not matched to any Purchase Order and exceeds {threshold}\xA0{currency}.\nYou need to match this Bill to a Purchase Order in order to approve it.',
    insufficientBudgetWithThresholdOrNever:
        "{requestName} can't be approved since its Allocated amount is lower than the Total amount. You need to match this Bill to a Purchase Order(s) with the Allocated amount equal to the Total amount, considering the net/gross setting.",
    insufficientBudgetWithPercentageThreshold:
        "{requestName} can't be approved since its Allocated amount is lower than the Total amount, considering the allowed threshold. You need to match this Bill to a Purchase Order(s) with the Allocated amount equal to the Total amount considering the allowed threshold of {threshold}% and the net/gross setting.",
});
