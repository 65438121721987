import './addFileButton.scss';

import { TransparentButton } from '@approvalmax/ui';
import { PaperClipIcon } from 'modules/sprites';
import { FC, memo } from 'react';
import bemFactory from 'react-bem-factory';

import { AttachmentKind } from '../../data/AttachmentKind';
import FileDropProvider from '../FileDropProvider';
import { messages } from './AddFileButton.messages';

const bem = bemFactory.block('reqf-add-file-button');
const qa = bemFactory.block('reqf-add-file-button');

interface AddFileButtonProps {
    className?: string;
    attachmentKind?: AttachmentKind;
    title?: string;
}

const AddFileButton: FC<AddFileButtonProps> = (props) => {
    const { attachmentKind, title } = props;

    return (
        <FileDropProvider.Consumer>
            {({ openFileDialog, setAttachmentKind }) => (
                <TransparentButton
                    className={bem()}
                    qa={qa('add-file-button-new')}
                    execute={() => {
                        setAttachmentKind(attachmentKind || AttachmentKind.General);
                        openFileDialog();
                    }}
                >
                    <div className={bem('content')}>
                        <PaperClipIcon className={bem('paper-clip-icon')} width={16} height={15} />

                        {title || messages.buttonText}
                    </div>
                </TransparentButton>
            )}
        </FileDropProvider.Consumer>
    );
};

export default memo(AddFileButton);
