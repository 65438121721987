import { api, Env } from '@approvalmax/data';

export const getDefaultEnvironment = (): Env => {
    switch (window.ApprovalMax.environment) {
        case 'debug':
            return 'local';

        case 'debugweb':
            return window.location.host.includes('.approvalmax.com') ? 'staging' : 'dev04';

        default:
            return window.ApprovalMax.environment || 'production';
    }
};

export const defaultApiSource = 'webApp';

export const {
    useGet,
    useLazyGet,
    useMutate,
    useInfiniteGet,
    getApiUrl,
    rootApi,
    rootApiLegacy,
    setApiUrlByEnv,
    setApiUrl,
    resetApiUrl,
} = api.init(defaultApiSource, getDefaultEnvironment());
