import { Guid } from '@approvalmax/types';
import { Flex } from '@approvalmax/ui/src/components';
import { intl, mathService, numberHelpers } from '@approvalmax/utils';
import { domain } from 'modules/data';
import { DoneIcon } from 'modules/sprites';
import moment from 'moment/moment';
import FileItem from 'pages/requestForm/components/filesSection/FileItem/FileItem';
import { ColumnDefinition } from 'pages/requestList/selectors/types/ColumnDefinition';
import { useNetSuiteExpenseReportExpenseLinesFieldSettings } from 'shared/hooks';

import { isFieldVisible } from '../../NetSuiteRequestCard.helpers';
import { messages } from './RequestCardExpenseReport.messages';
import { AttachmentWrap } from './RequestCardExpenseReport.styles';

type ColumnDefinitionExpenseLine = ColumnDefinition<domain.NetSuiteExpenseLineExpenseReport> & { isHidden?: boolean };

export const getColumnDefs = (
    fieldSettings: ReturnType<typeof useNetSuiteExpenseReportExpenseLinesFieldSettings>,
    exchangeRate: number | null,
    onPreviewAttachment: (attachmentId: Guid) => void
): ColumnDefinitionExpenseLine[] => {
    const cols: ColumnDefinitionExpenseLine[] = [
        {
            id: 'refLineNumber',
            name: messages.columnRefLineNumber,
            value: (li) => li.refLineNumber?.toString() || null,
            minWidth: 50,
            isHidden: !isFieldVisible(fieldSettings.refLineNumber),
        },
        {
            id: 'expenseDate',
            name: messages.columnExpenseDate,
            value: (li) => moment.utc(li.expenseDate).format('ll'),
            minWidth: 90,
            isHidden: !isFieldVisible(fieldSettings.date),
        },
        {
            id: 'category',
            name: messages.columnCategory,
            value: (li) => (li.category ? li.category.text : null),
            minWidth: 180,
            isHidden: !isFieldVisible(fieldSettings.category),
        },
        {
            id: 'expenseAccount',
            name: messages.columnExpenseAccount,
            value: (li) => (li.expenseAccount ? li.expenseAccount.text : null),
            minWidth: 150,
            isHidden: !isFieldVisible(fieldSettings.account),
        },
        {
            id: 'foreignAmount',
            name: messages.columnForeignAmount,
            value: (li) => (li.foreignAmount ? intl.formatNumber(li.foreignAmount || 0, 'auto') : null),
            alignRight: true,
            minWidth: 100,
            isHidden: !isFieldVisible(fieldSettings.foreignAmount),
        },
        {
            id: 'currency',
            name: messages.columnCurrency,
            value: (li) => li.currency?.text ?? null,
            minWidth: 100,
            isHidden: !isFieldVisible(fieldSettings.currency),
        },
        {
            id: 'exchangeRate',
            name: messages.columnExchangeRate,
            value: (li) =>
                numberHelpers.isNumber(li.exchangeRate)
                    ? intl.formatNumber(li.exchangeRate || 0, 8, true)
                    : li.exchangeRate,
            alignRight: true,
            minWidth: 100,
            isHidden: !isFieldVisible(fieldSettings.exchangeRate),
        },
        {
            id: 'amount',
            name: messages.columnAmount,
            value: (li) => {
                let amount = li.amount || 0;

                if (numberHelpers.isNumber(exchangeRate)) {
                    amount = mathService.multiply(amount, exchangeRate);
                    amount = mathService.round(amount, 2);
                }

                return intl.formatNumber(amount, 'auto');
            },
            alignRight: true,
            minWidth: 100,
            isHidden: !isFieldVisible(fieldSettings.amount),
        },
        {
            id: 'taxCode',
            name: messages.columnTaxCode,
            value: (li) => li.taxCode?.text ?? null,
            minWidth: 100,
            isHidden: !isFieldVisible(fieldSettings.taxCode),
        },
        {
            id: 'taxRate',
            name: messages.columnTaxRate,
            value: (li) => (numberHelpers.isNumber(li.taxRate) ? `${intl.formatNumber(li.taxRate, 'auto')}%` : null),
            alignRight: true,
            minWidth: 60,
            isHidden: !isFieldVisible(fieldSettings.taxRate),
        },
        {
            id: 'taxAmount',
            name: messages.columnTaxAmount,
            value: (li) => {
                let taxAmount = li.taxAmount || 0;

                if (numberHelpers.isNumber(exchangeRate)) {
                    taxAmount = mathService.multiply(taxAmount, exchangeRate);
                    taxAmount = mathService.round(taxAmount, 2);
                }

                return intl.formatNumber(taxAmount, 'auto');
            },
            alignRight: true,
            minWidth: 100,
            isHidden: !isFieldVisible(fieldSettings.taxAmount),
        },
        {
            id: 'grossAmount',
            name: messages.columnGrossAmount,
            value: (li) => {
                let { grossAmount } = li;

                if (!grossAmount) {
                    return null;
                }

                if (numberHelpers.isNumber(exchangeRate)) {
                    grossAmount = mathService.multiply(grossAmount, exchangeRate);
                    grossAmount = mathService.round(grossAmount, 2);
                }

                return intl.formatNumber(grossAmount, 'auto');
            },
            alignRight: true,
            minWidth: 100,
            isHidden: !isFieldVisible(fieldSettings.grossAmount),
        },
        {
            id: 'memo',
            name: messages.columnMemo,
            value: (li) => li.memo,
            minWidth: 115,
            isHidden: !isFieldVisible(fieldSettings.memo),
        },
        {
            id: 'department',
            name: messages.columnDepartment,
            value: (li) => li.department?.text ?? null,
            minWidth: 150,
            isHidden: !isFieldVisible(fieldSettings.department),
        },
        {
            id: 'class',
            name: messages.columnClass,
            value: (li) => li.class?.text ?? null,
            minWidth: 100,
            isHidden: !isFieldVisible(fieldSettings.class),
        },
        {
            id: 'location',
            name: messages.columnLocation,
            value: (li) => li.location?.text ?? null,
            minWidth: 100,
            isHidden: !isFieldVisible(fieldSettings.location),
        },
        {
            id: 'customer',
            name: messages.columnCustomer,
            value: (li) => li.customer?.text ?? null,
            minWidth: 80,
            isHidden: !isFieldVisible(fieldSettings.customer),
        },
        {
            id: 'attachment',
            name: messages.columnLineAttachment,
            value: (li) => li.attachment?.id || null,
            cell: (value, li) => (
                <AttachmentWrap>
                    {value && li.attachment && (
                        <FileItem
                            readonly
                            fileId={value}
                            fileName={li.attachment.name}
                            onRemove={() => undefined}
                            onOpen={() => onPreviewAttachment(value)}
                        />
                    )}
                </AttachmentWrap>
            ),
            isHidden: !isFieldVisible(fieldSettings.attachment),
        },
        {
            id: 'isBillable',
            name: messages.columnIsBillable,
            value: (li) => (li.isBillable ? messages.columnIsBillableTooltip : null),
            cell: (val) =>
                val && (
                    <Flex alignItems='center' justifyContent='center'>
                        <DoneIcon width={13} height={10} />
                    </Flex>
                ),
            minWidth: 40,
            isHidden: !isFieldVisible(fieldSettings.billable),
        },
        {
            id: 'isNonReimbursable',
            name: messages.columnIsNonReimbursable,
            value: (li) => (li.isNonReimbursable ? messages.columnIsNonReimbursableTooltip : null),
            cell: (val) =>
                val && (
                    <Flex alignItems='center' justifyContent='center'>
                        <DoneIcon width={13} height={10} />
                    </Flex>
                ),
            minWidth: 40,
            isHidden: !isFieldVisible(fieldSettings.nonReimbursable),
        },
        {
            id: 'isReceipt',
            name: messages.columnIsReceipt,
            value: (li) => (li.isReceipt ? messages.columnIsReceiptTooltip : null),
            cell: (val) =>
                val && (
                    <Flex alignItems='center' justifyContent='center'>
                        <DoneIcon width={13} height={10} />
                    </Flex>
                ),
            minWidth: 40,
            isHidden: !isFieldVisible(fieldSettings.receipt),
        },
        {
            id: 'isCorporateCreditCard',
            name: messages.columnIsCorporateCreditCard,
            value: (li) => (li.isCorporateCreditCard ? messages.columnIsCorporateCreditCardTooltip : null),
            cell: (val) =>
                val && (
                    <Flex alignItems='center' justifyContent='center'>
                        <DoneIcon width={13} height={10} />
                    </Flex>
                ),
            minWidth: 40,
            isHidden: !isFieldVisible(fieldSettings.corporateCreditCard),
        },
    ];

    return cols.filter((col) => !col.isHidden);
};
