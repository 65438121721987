import { cloneElement, FC, isValidElement, MouseEvent, RefCallback, useRef, useState } from 'react';
import { useUnmount } from 'react-use';

import { FocusEmitterProps } from './FocusEmitter.types';

/**
 * FocusEmitter is a component that emits focus and blur events to the wrapped element.
 */
export const FocusEmitter: FC<FocusEmitterProps> = (props) => {
    const { onFocus, onBlur, children, shouldPreventFocus } = props;

    const elementRef = useRef<HTMLElement | null>(null);
    const [currentElement, setCurrentElement] = useState<HTMLElement | null>(null);

    // Function to set ref and subscribe to focus/blur events
    const setRef: RefCallback<HTMLElement> = (node) => {
        if (elementRef.current) {
            onFocus && elementRef.current.removeEventListener('focus', onFocus);
            onBlur && elementRef.current.removeEventListener('blur', onBlur);
        }

        if (node) {
            onFocus && node.addEventListener('focus', onFocus);
            onBlur && node.addEventListener('blur', onBlur);
        }

        elementRef.current = node;
        setCurrentElement(node);
    };

    // Handler to unsubscribe from focus/blur events
    useUnmount(() => {
        if (elementRef.current) {
            onFocus && elementRef.current.removeEventListener('focus', onFocus);
            onBlur && elementRef.current.removeEventListener('blur', onBlur);
        }
    });

    // Handler for click on wrapped element that sets focus on target element
    const handleClick = () => {
        if (elementRef.current && document.activeElement !== elementRef.current) {
            elementRef.current.focus();
        }
    };

    // Get element from children function with both ref setter and current value
    const child = children({ ref: setRef, current: currentElement });

    // If element is valid, clone it and add onClick handler
    if (isValidElement<{ onClick?: (e: MouseEvent) => void }>(child)) {
        const existingOnClick = child.props.onClick;

        const combinedOnClick = (e: MouseEvent) => {
            if (existingOnClick) existingOnClick(e);

            if (shouldPreventFocus?.(e.target)) return;

            handleClick();
        };

        return cloneElement(child, { onClick: combinedOnClick });
    }

    return child;
};

FocusEmitter.displayName = 'FocusEmitter';
