import { RequestParams, RequestQueryOptions } from '@approvalmax/data';
import { useGet, useLazyGet } from 'shared/data';

import { authApiPaths } from '../paths';
import { GetFido2CredentialOptions } from './useGetFido2CredentialOptions.types';

export const useGetFido2CredentialOptions = (
    params?: RequestParams<GetFido2CredentialOptions>,
    queryOptions?: RequestQueryOptions<GetFido2CredentialOptions>
) => {
    return useGet(authApiPaths.fido2CredentialOptions, {
        params,
        queryOptions,
        apiVersion: 'v2',
    });
};

export const useLazyGetFido2CredentialOptions = (queryOptions?: RequestQueryOptions<GetFido2CredentialOptions>) => {
    return useLazyGet<GetFido2CredentialOptions>(authApiPaths.fido2CredentialOptions, {
        queryOptions,
        apiVersion: 'v2',
    });
};
