import { toast } from '@approvalmax/ui/src/components';
import { dateTimeHelpers } from '@approvalmax/utils';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useMountedState } from 'react-use';
import { useCreateTrialRequest } from 'shared/data/webApp/v2';

import { timeoutsEndStorageKey } from './useNotifyAdmins.constants';
import { restoreTimeouts, saveTimeouts } from './useNotifyAdmins.helpers';
import { messages } from './useNotifyAdmins.messages';

export const useNotifyAdmins = () => {
    const isMounted = useMountedState();
    const [timeoutsByCompanyId, setTimeoutsByCompanyId] = useState<Record<string, number>>(restoreTimeouts);
    const { mutate: createTrialRequest, isLoading: isLoadingCreateTrialRequest } = useCreateTrialRequest({
        onSuccess: () => {
            toast.success(messages.notifyAdminSuccess);
        },
    });

    const handleNotifyAdmins = useCallback(
        (companyId: string) => {
            const newTimeouts = {
                ...timeoutsByCompanyId,
                [companyId]: 60 * 60,
            };

            setTimeoutsByCompanyId(newTimeouts);
            saveTimeouts(newTimeouts);

            createTrialRequest({
                params: {
                    path: {
                        companyId,
                    },
                },
            });
        },
        [createTrialRequest, timeoutsByCompanyId]
    );

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            if (Object.values(timeoutsByCompanyId).every((timeout) => !timeout || timeout <= 0)) {
                localStorage.removeItem(timeoutsEndStorageKey);

                return;
            }

            if (isMounted()) {
                const newTimeouts = Object.entries(timeoutsByCompanyId).reduce<Record<string, number>>(
                    (result, [companyId, timeout]) => {
                        if (timeout) {
                            const nextTimeout = timeout - 1;

                            if (nextTimeout > 0) {
                                result[companyId] = nextTimeout;
                            }
                        }

                        return result;
                    },
                    {}
                );

                setTimeoutsByCompanyId(newTimeouts);
            }
        }, 1000);

        return () => {
            timeoutId && clearTimeout(timeoutId);
        };
    }, [isMounted, timeoutsByCompanyId]);

    const notifyAdminTimeoutsByCompanyId = useMemo(() => {
        return Object.entries(timeoutsByCompanyId).reduce<Record<string, string>>((result, [companyId, timeout]) => {
            result[companyId] = dateTimeHelpers.formatTime(timeout * 1000);

            return result;
        }, {});
    }, [timeoutsByCompanyId]);

    return { handleNotifyAdmins, notifyAdminTimeoutsByCompanyId, isLoadingCreateTrialRequest };
};
