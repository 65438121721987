import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('modules.common.LicenseOnGracePopup', {
    titleText: `
        Please note that the Subscription(s) used for your organisation(s) went into the grace period.{br}{br}
        Take action to prevent the cancellation of the Subscription.`,
    organisationsSection: 'Organisations',
    goToAccountButton: 'Go to "My account"',
    contactOwnerButton: 'Contact account owner',
});
