/**
 * Type guards for checking DOM element types
 */
const isHTMLButtonElement = (element: Element | null): element is HTMLButtonElement => {
    return element !== null && element instanceof HTMLButtonElement;
};

/**
 * Gets the currently focused element within the container
 */
export const getFocusedElement = (container: HTMLElement | null) => {
    return container?.querySelector<HTMLElement>(':focus') || null;
};

/**
 * Checks if we are in a specific view
 */
export const isInView = (element: HTMLElement | null, className: string) => {
    return !!element?.querySelector(className);
};

/**
 * Gets all buttons for the specified view
 */
export const getAllButtons = (calendarElement: HTMLDivElement | null, className: string) => {
    return calendarElement ? Array.from(calendarElement.querySelectorAll<HTMLElement>(className)) : [];
};

/**
 * Finds the first available button in the calendar
 */
export const getFirstButton = (calendarElement: HTMLDivElement | null, className: string) => {
    return calendarElement?.querySelector<HTMLDivElement>(`${className} button:not(:disabled)`);
};

/**
 * Focuses on the horizontally adjacent element
 */
export const focusHorizontalSibling = (focusedElement: HTMLElement | null, isNext: boolean = true) => {
    if (!focusedElement) return;

    let siblingElement = isNext ? focusedElement.nextElementSibling : focusedElement.previousElementSibling;

    // Continue searching for the next/previous available element if the current one is disabled
    while (siblingElement) {
        if (isHTMLButtonElement(siblingElement) && !siblingElement.disabled) {
            return siblingElement.focus();
        }

        siblingElement = isNext ? siblingElement.nextElementSibling : siblingElement.previousElementSibling;
    }
};

/**
 * Focuses the button on the previous/next row
 */
export const focusVerticalSibling = (
    allButtons: HTMLElement[],
    currentIndex: number,
    isUp: boolean,
    countInRow: number
) => {
    let targetIndex;

    if (currentIndex < 0) {
        targetIndex = isUp ? -1 : 0;
    } else if (currentIndex >= allButtons.length) {
        return false;
    } else {
        targetIndex = currentIndex + (isUp ? -countInRow : countInRow);
    }

    // Continue searching for an available button in the specified direction
    while (targetIndex >= 0 && targetIndex < allButtons.length) {
        const targetButton = allButtons[targetIndex];

        if (isHTMLButtonElement(targetButton) && !targetButton.disabled) {
            targetButton.focus();

            return true;
        }

        // Move to the next row in the same direction
        targetIndex += isUp ? -countInRow : countInRow;
    }

    return false;
};

/**
 * Disables Tab navigation inside the calendar
 */
export const disableTabNavigation = (event: KeyboardEvent, calendarElement: HTMLDivElement | null) => {
    const target = event.target;

    if (event.key === 'Tab' && target instanceof Node && calendarElement?.contains(target)) {
        event.preventDefault();
    }
};

/**
 * Sets tabindex="-1" for all focusable elements in calendar
 */
export const setNegativeTabIndex = (calendarElement: HTMLDivElement | null) => {
    if (!calendarElement) return;

    const focusableElements = calendarElement.querySelectorAll(
        'a, button, input, select, textarea, [tabindex]:not([tabindex="-1"])'
    );

    focusableElements.forEach((element) => {
        element.setAttribute('tabindex', '-1');
    });
};
