import { Reference } from '@approvalmax/types';
import { errorHelpers } from '@approvalmax/utils';
import keyBy from 'lodash/keyBy';
import { State } from 'modules/data';

import { ReportColumnDefinition } from '../../config/standard/columnDefinitions';
import { anyValueReference, anyValueReferenceId, NullableBoolean } from '../../types/domain';
import FilterType from './FilterType';
import { messages } from './referenceValueFilter.messages';

export interface ReferenceValueFilter {
    type: FilterType.ReferenceValue;
    value: Reference | undefined;
}

export function createReferenceValueFilter(value: Reference): ReferenceValueFilter {
    return {
        type: FilterType.ReferenceValue,
        value,
    };
}

export function createAlwaysTrueFilter() {
    return createReferenceValueFilter(anyValueReference);
}

const nullableBooleanOptions = [
    anyValueReference,
    {
        id: NullableBoolean.Yes,
        text: messages.nullableBooleanOptionTextYes,
    },
    {
        id: NullableBoolean.No,
        text: messages.nullableBooleanOptionTextNo,
    },
];

export const getNullableBooleanOptions = () => nullableBooleanOptions;

export function parseNullableBooleanFilter(
    this: ReportColumnDefinition,
    filterAnswer: any,
    state: State,
    companyId: string
): ReferenceValueFilter {
    let value;

    const options: Reference[] = this.getOptions!(state, null, companyId);

    switch (filterAnswer) {
        case true:
            value = options.find((x) => x.id === NullableBoolean.Yes)!;
            break;

        case false:
            value = options.find((x) => x.id === NullableBoolean.No)!;
            break;

        default:
            value = options.find((x) => x.id === anyValueReferenceId)!;
            break;
    }

    return {
        type: FilterType.ReferenceValue,
        value,
    };
}

export function getNullableBooleanFilterTransfer(filter: ReferenceValueFilter): any {
    if (!filter.value) {
        return null;
    }

    switch (filter.value.id) {
        case anyValueReferenceId:
            return null;

        case NullableBoolean.Yes:
            return true;

        case NullableBoolean.No:
            return false;

        default:
            throw errorHelpers.invalidOperationError();
    }
}

export const enumBackend = {
    createParseFilter(backendToDomainMap: { [backendValueStr: string]: string; [backendValueNum: number]: string }) {
        return function (
            this: ReportColumnDefinition,
            filterAnswer: string | number,
            state: State,
            companyId: string
        ): ReferenceValueFilter {
            const optionsById = keyBy(this.getOptions!(state, null, companyId), 'id');

            const value = optionsById[backendToDomainMap[filterAnswer]];

            if (!value) {
                throw errorHelpers.invalidOperationError(
                    `Wrong configuration of column filter mapping. Filter value '${value}' not found in the map.`
                );
            }

            let result: ReferenceValueFilter = {
                type: FilterType.ReferenceValue,
                value,
            };

            return result;
        };
    },
    createGetFilterTransfer(domainToBackendMap: { [domainValue: string]: string | number | null }) {
        return (filter: ReferenceValueFilter) => {
            if (!filter.value) {
                return null;
            }

            return domainToBackendMap[filter.value.id];
        };
    },
};

export function getFilterPreviewText(filter: ReferenceValueFilter): string | null {
    if (!filter.value) {
        return null;
    }

    if (filter.value.id === anyValueReferenceId) {
        return null;
    }

    return filter.value.text;
}
