import { Checkbox } from '@approvalmax/ui/src/components';
import { domain } from 'modules/data';
import { FC, memo, useCallback } from 'react';

import { SettingsSection } from '../SettingsSection/SettingsSection';
import SettingsSubsection from '../SettingsSubsection/SettingsSubsection';
import { messages } from './SectionNetSuiteExpenseReportFraudChangesAfter.messages';
import { SectionNetSuiteExpenseReportFraudChangesAfterProps } from './SectionNetSuiteExpenseReportFraudChangesAfter.types';

const SectionNetSuiteExpenseReportFraudChangesAfter: FC<SectionNetSuiteExpenseReportFraudChangesAfterProps> = memo(
    (props) => {
        const { company, templateSettings, setTemplateSettings, readonly, qa } = props;

        const {
            Vendor: Employee,
            Account: ExpenseAccount,
            Date,
            Category,
            Memo: Purpose,
            Amount: TotalAmount,
        } = domain.TemplateSettingsNetSuitePostApprovalChangeDetectionTarget;

        const settings = templateSettings?.netSuitePostApprovalChangeDetectionSettings;

        const handleChange = useCallback(
            (newTargets: domain.TemplateSettingsNetSuitePostApprovalChangeDetectionTarget[]) => {
                setTemplateSettings((prevTemplateSettings) => ({
                    ...prevTemplateSettings,
                    netSuitePostApprovalChangeDetectionSettings:
                        prevTemplateSettings.netSuitePostApprovalChangeDetectionSettings
                            ? {
                                  ...prevTemplateSettings.netSuitePostApprovalChangeDetectionSettings,
                                  targets: newTargets,
                              }
                            : undefined,
                }));
            },
            [setTemplateSettings]
        );

        if (!settings) {
            return null;
        }

        return (
            <SettingsSection
                title={messages.title}
                learnMoreLink='https://support.approvalmax.com/portal/en/kb/articles/what-is-the-fraud-detection-changes-after-approval-setting-netsuite'
            >
                <SettingsSubsection subtitle={messages.subtitle}>
                    <Checkbox.Group
                        name='detectionChangesAfterApproval'
                        disabled={readonly}
                        onChange={handleChange}
                        value={settings.targets}
                        block
                    >
                        <Checkbox
                            value={Employee}
                            data-qa={qa(`NetSuiteExpenseReportFraudDetectionChangesAfterSection-${Employee}`)}
                        >
                            {messages.optionEmployee}
                        </Checkbox>

                        <Checkbox
                            value={TotalAmount}
                            data-qa={qa(`NetSuiteExpenseReportFraudDetectionChangesAfterSection-${TotalAmount}`)}
                        >
                            {messages.optionTotalAmount({ companyCurrencyCode: company.defaultCurrencyText })}
                        </Checkbox>

                        <Checkbox
                            value={Date}
                            data-qa={qa(`NetSuiteExpenseReportFraudDetectionChangesAfterSection-${Date}`)}
                        >
                            {messages.optionDate}
                        </Checkbox>

                        <Checkbox
                            value={Purpose}
                            data-qa={qa(`NetSuiteExpenseReportFraudDetectionChangesAfterSection-${Purpose}`)}
                        >
                            {messages.optionPurpose}
                        </Checkbox>

                        <Checkbox
                            value={ExpenseAccount}
                            data-qa={qa(`NetSuiteExpenseReportFraudDetectionChangesAfterSection-${ExpenseAccount}`)}
                        >
                            {messages.optionExpenseAccount}
                        </Checkbox>

                        <Checkbox
                            value={Category}
                            data-qa={qa(`NetSuiteExpenseReportFraudDetectionChangesAfterSection-${Category}`)}
                        >
                            {messages.optionCategory}
                        </Checkbox>
                    </Checkbox.Group>
                </SettingsSubsection>
            </SettingsSection>
        );
    }
);

export default SectionNetSuiteExpenseReportFraudChangesAfter;
