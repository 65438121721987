import { domain } from 'modules/data';

import entitiesReducer from './resources/reducers/entitiesReducer';
import navigationReducer from './resources/reducers/navigationReducer';
import pageReducer from './resources/reducers/pageReducer';

export const pageConfig = {
    access: [domain.CompanyUserRole.Manager, domain.CompanyUserRole.Auditor, domain.CompanyUserRole.WorkflowManager],
    store: {
        id: 'reports',
        pageReducer: pageReducer,
        reducers: {
            entities: entitiesReducer,
            navigation: navigationReducer,
        },
    },
};
