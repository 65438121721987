import { FC, useState } from 'react';

import { AlertCircleFilledIcon } from '../../../../icons';
import { Button } from '../../../Button/Button';
import { Flex } from '../../../Flex/Flex';
import { Text } from '../../../Text/Text';
import { Tooltip } from '../../../Tooltip/Tooltip';
import { messages } from './ErrorPlaceholder.messages';
import { Root } from './ErrorPlaceholder.styles';
import { ErrorPlaceholderProps } from './ErrorPlaceholder.types';

export const ErrorPlaceholder: FC<ErrorPlaceholderProps> = (props) => {
    const { onContactSupport } = props;

    const [root, setRoot] = useState<HTMLDivElement | null>(null);

    return (
        <Root
            ref={(node) => {
                if (!root && node) {
                    setRoot(node);
                }
            }}
        >
            {/* We need to wait before root-node will be cached within `root`
             to make portal append to it. This is required to prevent loosing
             hover effect on moving mouse over tooltip (because otherwise, body
             will be the parent of the portal */}
            {root && (
                <Tooltip
                    safePolygon
                    activator={
                        <AlertCircleFilledIcon
                            // used as css-selector
                            id='alert-icon'
                            color='midnight70'
                        />
                    }
                    floatingPortalProps={{ root }}
                >
                    <Flex direction='column' spacing='2' alignItems='start'>
                        <Text font='label' fontSize='xxsmall' fontWeight='medium'>
                            {messages.weCouldntLoad}
                        </Text>

                        <Text font='label' fontSize='xxsmall'>
                            {messages.pleaseReloadThisPage}
                        </Text>

                        <Button link noPadding size='xsmall' onClick={onContactSupport} variant='text' color='blue100'>
                            {messages.contactSupport}
                        </Button>
                    </Flex>
                </Tooltip>
            )}
        </Root>
    );
};

ErrorPlaceholder.displayName = 'ErrorPlaceholder';
