import { Guid, Reference } from '@approvalmax/types';
import { intl } from '@approvalmax/utils';
import { selectors } from 'modules/common';
import { getTemplateDisplayNameByCode } from 'modules/common/selectors/templateSelectors';
import { domain, schemas } from 'modules/data';
import moment from 'moment';
import React from 'react';

import { getDearEventHeader } from './requestActivitySelectors.Dear';
import { messages } from './requestActivitySelectors.messages';
import { getNetSuiteEventHeader, isNetSuiteSystemEvent } from './requestActivitySelectors.NetSuite';
import { getQBooksEventHeader, isQBooksSystemEvent } from './requestActivitySelectors.QBooks';
import { getXeroEventChanges, getXeroEventHeader, isXeroSystemEvent } from './requestActivitySelectors.Xero';

export function resolveStepName(request: selectors.types.ExpandedRequest, step: Reference | undefined): string {
    let stepName = step && step.text;

    if (!stepName) {
        let requestStep;

        if (step) {
            requestStep = request.steps.find((s) => s.id === step.id);
        }

        stepName = requestStep ? requestStep.name : messages.unknownStepName;
    }

    return stepName!;
}

export function getSystemEventOrigin(
    request: selectors.types.ExpandedRequest,
    event: domain.RequestHistoryEvent
): domain.IntegrationType {
    if (isXeroSystemEvent(request, event)) {
        return domain.IntegrationType.Xero;
    }

    if (isQBooksSystemEvent(request, event)) {
        return domain.IntegrationType.QBooks;
    }

    if (isNetSuiteSystemEvent(request, event)) {
        return domain.IntegrationType.NetSuite;
    }

    return domain.IntegrationType.None;
}

export function getEventHeader(
    request: selectors.types.ExpandedRequest,
    event: domain.RequestHistoryEvent,
    me: selectors.types.ExpandedUser,
    getUser: (userId: Guid) => selectors.types.ExpandedUser,
    hasEventChanges: boolean
): React.ReactNode {
    const stepText = resolveStepName(request, event.step);
    const step = stepText ? <b>{`"${stepText}"`}</b> : '';
    const nextStepText = resolveStepName(request, event.nextStep);
    const nextStep = nextStepText ? <b>{`"${nextStepText}"`}</b> : '';

    let header = getXeroEventHeader(request, event);

    if (request.integrationType === domain.IntegrationType.QBooks) {
        header = getQBooksEventHeader(request, event) || header;
    }

    if (request.integrationType === domain.IntegrationType.NetSuite) {
        header = getNetSuiteEventHeader(request, event);
    }

    if (request.integrationType === domain.IntegrationType.Dear) {
        header = getDearEventHeader(request, event);
    }

    if (header) {
        return header;
    }

    switch (event.type) {
        case domain.RequestHistoryEventType.RequesterSubmitted:
            return messages.headerRequesterSubmitted;

        case domain.RequestHistoryEventType.ApproverApproved:
            return messages.headerApproverApproved({ step });

        case domain.RequestHistoryEventType.ApproverApprovedStepApproved:
            return messages.headerApproverApprovedStepApproved({ step });

        case domain.RequestHistoryEventType.ApproverApprovedRequestApproved:
            return messages.headerApproverApprovedRequestApproved({ step });

        case domain.RequestHistoryEventType.ApproverRejected:
            return messages.headerApproverRejected({ step });

        case domain.RequestHistoryEventType.ApproverRevoked:
            return messages.headerApproverRevoked({ step });

        case domain.RequestHistoryEventType.ApproverRevokedStepChanged:
            return messages.headerApproverRevokedStepChanged({ nextStep });

        case domain.RequestHistoryEventType.RequesterClosed:
            return messages.headerRequesterClosed;

        case domain.RequestHistoryEventType.RequesterCancelled:
            return messages.headerRequesterCancelled;

        case domain.RequestHistoryEventType.RequesterReopened:
            return messages.headerRequesterReopened;

        case domain.RequestHistoryEventType.RequesterReopenedWithReset:
            if (!hasEventChanges) {
                return messages.headerRequesterReopenedWithResetNoChanges;
            }

            return messages.headerRequesterReopenedWithReset;

        case domain.RequestHistoryEventType.RequesterReopenedStepChanged:
            if (!hasEventChanges) {
                return messages.headerRequesterReopenedStepChangedNoChanges;
            }

            return messages.headerRequesterReopenedStepChanged;

        case domain.RequestHistoryEventType.RequesterReopenedRequestApproved:
            if (!hasEventChanges) {
                return messages.headerRequesterReopenedRequestApprovedNoChanges;
            }

            return messages.headerRequesterReopenedRequestApproved;

        case domain.RequestHistoryEventType.RequesterUpdated:
            return messages.headerRequesterUpdated;

        case domain.RequestHistoryEventType.RequesterUpdatedWithReset:
            if (!hasEventChanges) {
                return messages.headerRequesterUpdatedWithResetNoChanges;
            }

            return messages.headerRequesterUpdatedWithReset;

        case domain.RequestHistoryEventType.RequesterUpdatedStepChanged:
            if (!hasEventChanges) {
                return messages.headerRequesterUpdatedStepChangedNoChanges;
            }

            return messages.headerRequesterUpdatedStepChanged;

        case domain.RequestHistoryEventType.RequesterUpdatedRequestApproved:
            if (!hasEventChanges) {
                return messages.headerRequesterUpdatedRequestApprovedNoChanges;
            }

            return messages.headerRequesterUpdatedRequestApproved;

        case domain.RequestHistoryEventType.StepSkipped:
            return messages.headerStepSkipped({ step });

        case domain.RequestHistoryEventType.ForcedApproval:
            return messages.headerForcedApproval;

        case domain.RequestHistoryEventType.ForcedReject:
            return messages.headerForcedReject;

        case domain.RequestHistoryEventType.RequesterResetTemplate:
            return messages.headerRequesterResetTemplate;

        case domain.RequestHistoryEventType.EditorEdited:
            return messages.headerEditorEdited({ step });

        case domain.RequestHistoryEventType.EditorEditedStepApproved:
            return messages.headerEditorEditedStepApproved({ step });

        case domain.RequestHistoryEventType.EditorEditedRequestApproved:
            return messages.headerEditorEditedRequestApproved({ step });

        case domain.RequestHistoryEventType.PulledFromSource:
            if (request.origin === domain.RequestOrigin.ReceiptBank) {
                return messages.headerPulledFromSourceRB;
            }

            return messages.headerPulledFromSource({
                external: request.company.integration!.displayName,
            });

        case domain.RequestHistoryEventType.PushedToSource: {
            const templateName = getTemplateDisplayNameByCode(request.integrationCode);

            return messages.headerPushedToSource({
                templateName,
                external: request.company.integration!.displayName,
            });
        }

        case domain.RequestHistoryEventType.DocumentClosed:
            return messages.headerDocumentClosed;

        case domain.RequestHistoryEventType.DocumentOpened:
            return messages.headerDocumentOpened;

        case domain.RequestHistoryEventType.DocumentClosedStatusSynced:
            return messages.headerDocumentClosedStatusSynced;

        case domain.RequestHistoryEventType.DocumentOpenedStatusSynced:
            return messages.headerDocumentOpenedStatusSynced;

        case domain.RequestHistoryEventType.AirwallexBatchPaymentRequestPaid:
            return messages.headerAirwallexBatchPaymentRequestPaid;

        case domain.RequestHistoryEventType.AirwallexRequestScheduledForPayment:
            return messages.headerAirwallexRequestScheduledForPayment;

        case domain.RequestHistoryEventType.AirwallexScheduledPaymentCancelled:
            return messages.headerAirwallexScheduledPaymentCancelled;

        case domain.RequestHistoryEventType.AirwallexScheduledPaymentFailedDueToPaymentServiceIntegrationDisconnected:
            return messages.headerAirwallexScheduledPaymentFailedDueToPaymentServiceIntegrationDisconnected;

        case domain.RequestHistoryEventType.AirwallexScheduledPaymentFailedDueToExceededAmountDue:
            return messages.headerAirwallexScheduledPaymentFailedDueToExceededAmountDue;

        case domain.RequestHistoryEventType.AirwallexScheduledPaymentFailedDueToBeneficiaryDiscrepancy:
            return messages.headerAirwallexScheduledPaymentFailedDueToBeneficiaryDiscrepancy;

        case domain.RequestHistoryEventType.AirwallexScheduledPaymentFailedDueToInsufficientFunds:
            return messages.headerAirwallexScheduledPaymentFailedDueToInsufficientFunds;

        case domain.RequestHistoryEventType.AirwallexScheduledPaymentFailedDueToCompanyIntegrationDisabled:
            return messages.headerAirwallexScheduledPaymentFailedDueToCompanyIntegrationDisabled;

        case domain.RequestHistoryEventType.ReviewCompleted:
            return messages.headerReviewCompleted;

        case domain.RequestHistoryEventType.ReviewersChanged:
            return messages.headerReviewersChanged;

        case domain.RequestHistoryEventType.ReviewForcedByAdmin:
            return messages.headerReviewForcedByAdmin;

        case domain.RequestHistoryEventType.ReturnedToReview:
            return messages.headerReturnedToReview;

        case domain.RequestHistoryEventType.ReviewerEdited:
            return messages.headerReviewerEdited;

        case domain.RequestHistoryEventType.ApproverEdited:
            return messages.headerApproverEdited({ step });

        case domain.RequestHistoryEventType.AttachmentsAdded:
            return messages.headerAttachmentsAdded;

        case domain.RequestHistoryEventType.AttachmentDeleted:
            return messages.headerAttachmentDeleted;

        case domain.RequestHistoryEventType.PutOnHold:
            return messages.headerRequestPutOnHold;

        case domain.RequestHistoryEventType.TakenOffHold:
            return messages.headerRequestTakenOffHold;

        case domain.RequestHistoryEventType.ApproverNudged:
            return request.reviewStep === null || request.reviewStep.isCompleted
                ? messages.headerRequestApproverNudgedApprovalStep
                : messages.headerRequestApproverNudgedReviewStep;

        default:
            return null;
    }
}

export function getEventChanges(
    request: selectors.types.ExpandedRequest,
    event: domain.RequestHistoryEvent
): Array<React.ReactElement<any>> | null {
    if (!event.changes) {
        return null;
    }

    const result: Array<React.ReactElement<any>> = [];
    const changes = event.changes;

    result.push(...getXeroEventChanges(request, event));

    if (changes.newName) {
        result.push(<span>{messages.changeNewName}</span>);
    }

    if (changes.newDescription !== undefined) {
        if (changes.newDescription) {
            result.push(<span>{messages.changeChangedDescription}</span>);
        } else {
            result.push(<span>{messages.changeRemovedDescription}</span>);
        }
    }

    if (changes.amountFieldChange) {
        const change = changes.amountFieldChange;

        let newAmount: string | number = String(change.newValue);
        let newCurrency = change.newDetails;

        if (!newCurrency) {
            const whiteSpaceIndex = newAmount.indexOf(' ');

            if (whiteSpaceIndex !== -1) {
                newCurrency = newAmount.substr(whiteSpaceIndex + 1);
                newAmount = newAmount.substr(0, whiteSpaceIndex);
            } else {
                newCurrency = request.currency;
            }
        }

        newAmount = parseFloat(newAmount);
        result.push(
            <span>{messages.changeChangedAmount({ amount: intl.formatCurrency(newAmount, newCurrency, 'auto') })}</span>
        );
    }

    if (changes.fieldChanges) {
        result.push(
            ...changes.fieldChanges.map((fc) => (
                <span key={fc.fieldName}>
                    {messages.changeChangedField({
                        name: fc.fieldName,
                        value: fc.newValue,
                    })}
                </span>
            ))
        );
    }

    if (changes.addedAttachments && changes.addedAttachments.length > 0) {
        result.push(
            <span>
                {messages.changeAddedAttachments({
                    attachments: changes.addedAttachments.map((a) => a.name).join(', '),
                })}
            </span>
        );
    }

    if (changes.removedAttachments && changes.removedAttachments.length > 0) {
        result.push(
            <span>
                {messages.changeRemovedAttachments({
                    attachments: changes.removedAttachments.map((a) => a.name).join(', '),
                })}
            </span>
        );
    }

    if (
        changes.stepChanges &&
        event.type !== domain.RequestHistoryEventType.ParticipantsWereAddedDueToMatchingBillWithPO
    ) {
        changes.stepChanges.forEach((stepChange) => {
            let stepName = resolveStepName(request, stepChange.step);

            if (stepChange.addedApprovers) {
                result.push(
                    ...stepChange.addedApprovers.map((a) => (
                        <span key={a.user.UserId}>
                            {messages.stepChangeAddedApprover({
                                approver: selectors.user.expandUser(schemas.mapUser(a.user)).displayName,
                                stepName,
                            })}
                        </span>
                    ))
                );
            }

            if (stepChange.removedApprovers) {
                result.push(
                    ...stepChange.removedApprovers.map((a) => (
                        <span key={a.user.UserId}>
                            {messages.stepChangeRemovedApprover({
                                approver: selectors.user.expandUser(schemas.mapUser(a.user)).displayName,
                                stepName,
                            })}
                        </span>
                    ))
                );
            }

            if (stepChange.deadline || stepChange.deadlineRemoved) {
                let text: React.ReactElement<any> | undefined;

                if (stepChange.deadline) {
                    text = (
                        <span>
                            {messages.stepChangeChangedDeadline({
                                deadline: moment(stepChange.deadline).format('LLL'),
                                stepName,
                            })}
                        </span>
                    );
                }

                if (stepChange.deadlineRemoved) {
                    text = <span>{messages.stepChangeRemovedDeadline({ stepName })}</span>;
                }

                result.push(text!);
            }
        });
    }

    if (changes.reviewStepChanges && event.type === domain.RequestHistoryEventType.ReviewersChanged) {
        if (changes.reviewStepChanges.addedReviewers) {
            result.push(
                ...changes.reviewStepChanges.addedReviewers.map((reviewer) => (
                    <span key={reviewer.UserId}>
                        {messages.stepChangeAddedReviewer({
                            reviewer: selectors.user.expandUser(schemas.mapUser(reviewer)).displayName,
                        })}
                    </span>
                ))
            );
        }

        if (changes.reviewStepChanges.removedReviewers) {
            result.push(
                ...changes.reviewStepChanges.removedReviewers.map((reviewer) => (
                    <span key={reviewer.UserId}>
                        {messages.stepChangeRemovedReviewer({
                            reviewer: selectors.user.expandUser(schemas.mapUser(reviewer)).displayName,
                        })}
                    </span>
                ))
            );
        }
    }

    if (result.length === 0) {
        return null;
    }

    return result.map((el, i) => <div key={i}>{el}</div>);
}

export function getEventFooter(
    request: selectors.types.ExpandedRequest,
    event: domain.RequestHistoryEvent
): React.ReactNode {
    const nextStepText = resolveStepName(request, event.nextStep);
    const nextStep = nextStepText ? <b>{`"${nextStepText}"`}</b> : '';

    switch (event.type) {
        case domain.RequestHistoryEventType.ApproverApprovedStepApproved:
            return messages.footerApproverApprovedStepApproved({ nextStep });

        case domain.RequestHistoryEventType.ApproverApprovedRequestApproved:
            if (request.integrationCode === domain.IntegrationCode.XeroAirwallexBatchPayment) {
                return messages.footerApproverApprovedRequestApprovedAirwallex;
            }

            return messages.footerApproverApprovedRequestApproved;

        case domain.RequestHistoryEventType.ApproverRevokedStepChanged:
            return messages.footerApproverRevokedStepChanged({ nextStep });

        case domain.RequestHistoryEventType.RequesterReopenedWithReset:
            return messages.footerRequesterReopenedWithReset({ nextStep });

        case domain.RequestHistoryEventType.RequesterReopenedStepChanged:
            return messages.footerRequesterReopenedStepChanged({ nextStep });

        case domain.RequestHistoryEventType.RequesterReopenedRequestApproved:
            return messages.footerRequesterReopenedRequestApproved;

        case domain.RequestHistoryEventType.RequesterUpdatedWithReset:
            if (event.nextStep) {
                return messages.footerRequesterUpdatedWithReset({ nextStep });
            }

            return null;

        case domain.RequestHistoryEventType.RequesterUpdatedStepChanged:
            return messages.footerRequesterUpdatedStepChanged({ nextStep });

        case domain.RequestHistoryEventType.RequesterUpdatedRequestApproved:
            return messages.footerRequesterUpdatedRequestApproved;

        case domain.RequestHistoryEventType.StepSkipped:
            return messages.footerStepSkipped({ nextStep });

        case domain.RequestHistoryEventType.StepSkippedRequestApproved:
            return messages.footerStepSkippedRequestApproved;

        case domain.RequestHistoryEventType.ParticipantsWereChangedRequestApproved:
            return messages.footerParticipantsWereChangedRequestApproved;

        case domain.RequestHistoryEventType.EditorEditedStepApproved:
            return messages.footerEditorEditedStepApproved({ nextStep });

        case domain.RequestHistoryEventType.EditorEditedRequestApproved:
            return messages.footerEditorEditedRequestApproved;

        case domain.RequestHistoryEventType.StepSkippedRequestRejected:
            return messages.footerStepSkippedRequestRejected;

        case domain.RequestHistoryEventType.ReviewSkipped:
            return messages.footerReviewSkipped;

        case domain.RequestHistoryEventType.MarkedAsSentSynced:
            return messages.headerMarkedAsSentSynced;

        default:
            return null;
    }
}
