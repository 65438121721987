import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('reports.data.filters.dateRangeFilter', {
    rangeTypeTextNone: 'Any date',
    rangeTypeTextCustom: 'Custom range',
    rangeTypeTextToday: 'Today',
    rangeTypeTextYesterday: 'Yesterday',
    rangeTypeTextTomorrow: 'Tomorrow',
    rangeTypeTextThisWeek: 'This week',
    rangeTypeTextLastWeek: 'Last week',
    rangeTypeTextNextWeek: 'Next week',
    rangeTypeTextThisMonth: 'This month',
    rangeTypeTextLastMonth: 'Last month',
    rangeTypeTextNextMonth: 'Next month',
    previewTextCustomFull: 'from {startDate} to {endDate}',
    previewTextCustomStart: 'from {startDate}',
    previewTextCustomEnd: 'to {endDate}',
});
