import { Popup } from '@approvalmax/ui';
import { Popup as NewPopup } from '@approvalmax/ui/src/components';
import { useAtomValue } from 'jotai';
import { useResetAtom } from 'jotai/utils';
import { useDispatch, useSelector } from 'modules/react-redux';
import { memo, useCallback, useMemo } from 'react';

import { cancelActivePopup, cancelCompanyConnectionErrorPopup } from './actions';
import { PreviewImagePopup } from './components';
import AnotherCompanyIntegratedPopup from './containers/AnotherCompanyIntegratedPopup/AnotherCompanyIntegratedPopup';
import CompanyMismatchPopup from './containers/CompanyMismatchPopup/CompanyMismatchPopup';
import DearIntegrationPopup from './containers/dear/DearIntegrationPopup/DearIntegrationPopup';
import IntegrationErrorPopup from './containers/IntegrationErrorPopup';
import { NetSuiteIntegrationPopup } from './containers/netsuite/NetSuiteIntegrationPopup/NetSuiteIntegrationPopup';
import XeroIntegrationSelectPopup from './containers/xero/XeroIntegrationSelectPopup';
import { integrationPopupPreviewImageState } from './ModuleComponent.states';
import { DEAR_INTEGRATION_POPUP } from './reducers/module/activePopup/dearIntegrationPopupReducer';
import { INTEGRATION_ERROR_POPUP } from './reducers/module/activePopup/integrationErrorPopupReducer';
import { NETSUITE_INTEGRATION_POPUP } from './reducers/module/activePopup/netSuiteIntegrationPopupReducer';
import { XERO_INTEGRATION_SELECT_POPUP } from './reducers/module/activePopup/xeroIntegrationSelectPopupReducer';
import { ANOTHER_COMPANY_INTEGRATED_POPUP } from './reducers/module/companyConnectionErrorPopup/anotherCompanyIntegratedPopupReducer';
import { COMPANY_MISMATCH_POPUP } from './reducers/module/companyConnectionErrorPopup/companyMismatchPopupReducer';
import { getActiveCompanyConnectionErrorPopupId, getActivePopupId } from './selectors/moduleSelectors';

export const ModuleComponent = memo(() => {
    const dispatch = useDispatch();

    const activePopupId = useSelector((state) => getActivePopupId(state));
    const activeCompanyConnectionErrorPopupId = useSelector((state) => getActiveCompanyConnectionErrorPopupId(state));

    const { isOpen: isPreviewImagePopupOpen } = useAtomValue(integrationPopupPreviewImageState);
    const resetPreviewImagePopupState = useResetAtom(integrationPopupPreviewImageState);

    const isNetSuiteIntegrationPopupOpen = useMemo(() => activePopupId === NETSUITE_INTEGRATION_POPUP, [activePopupId]);

    const cancleActivePopupOnRequestClose = useCallback(() => dispatch(cancelActivePopup()), [dispatch]);

    const cancelCompanyConnectionErrorPopupOnRequestClose = useCallback(
        () => dispatch(cancelCompanyConnectionErrorPopup()),
        [dispatch]
    );

    const onNetSuiteIntegrationPopupToggle = useCallback(
        (open: boolean) => {
            if (!open) {
                cancleActivePopupOnRequestClose();
            }
        },
        [cancleActivePopupOnRequestClose]
    );

    const onPreviewImagePopupToggle = useCallback(
        (open: boolean) => {
            if (!open) {
                resetPreviewImagePopupState();
            }
        },
        [resetPreviewImagePopupState]
    );

    return (
        <>
            <Popup
                isOpen={activePopupId === XERO_INTEGRATION_SELECT_POPUP}
                onRequestClose={cancleActivePopupOnRequestClose}
            >
                <XeroIntegrationSelectPopup />
            </Popup>

            <Popup isOpen={activePopupId === INTEGRATION_ERROR_POPUP} onRequestClose={cancleActivePopupOnRequestClose}>
                <IntegrationErrorPopup />
            </Popup>

            <Popup
                isOpen={activePopupId === DEAR_INTEGRATION_POPUP}
                disableAutoClose
                onRequestClose={cancleActivePopupOnRequestClose}
            >
                <DearIntegrationPopup />
            </Popup>

            <NewPopup
                open={isNetSuiteIntegrationPopupOpen}
                preventAutoClose
                onToggle={onNetSuiteIntegrationPopupToggle}
                zIndex={0}
                size='large'
            >
                <NetSuiteIntegrationPopup />
            </NewPopup>

            <Popup
                isOpen={activeCompanyConnectionErrorPopupId === COMPANY_MISMATCH_POPUP}
                onRequestClose={cancelCompanyConnectionErrorPopupOnRequestClose}
            >
                <CompanyMismatchPopup />
            </Popup>

            <Popup
                isOpen={activeCompanyConnectionErrorPopupId === ANOTHER_COMPANY_INTEGRATED_POPUP}
                onRequestClose={cancelCompanyConnectionErrorPopupOnRequestClose}
            >
                <AnotherCompanyIntegratedPopup />
            </Popup>

            <NewPopup open={isPreviewImagePopupOpen} fullScreen onToggle={onPreviewImagePopupToggle}>
                <PreviewImagePopup />
            </NewPopup>
        </>
    );
});

ModuleComponent.displayName = 'ModuleComponent';
