import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('pages.RequestList.ForceDecisionButton', {
    forceDecisionMenuButtonText: 'Force decision',
    title: 'Forcing the approval decision',
    description: `
        As an Administrator, you can force approval or rejection of this request.
        {br}{br}This will override the decisions of other approvers.`,
    forceApprovalButtonText: 'Force the approval',
    forceRejectionButtonText: 'Force the rejection',
    forceDecisionMenuButtonTitle: 'Force the approval / rejection of this request',
    forceApprovalButtonTitle: 'Force approval of this request as the company administrator',
    forceRejectionButtonTitle: 'Force rejection of this request as the company administrator',
});
