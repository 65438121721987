import { Avatar, Button, Flex, Form, Popup, Text, TextField } from '@approvalmax/ui/src/components';
import { zodResolver } from '@hookform/resolvers/zod';
import { selectors } from 'modules/common';
import { FC, memo } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { z } from 'zod';

import { commentMaxLength, formFieldNames } from './AddCommentPopupContent.constants';
import { messages } from './AddCommentPopupContent.messages';
import { schema } from './AddCommentPopupContent.schema';
import { AddCommentPopupContentProps } from './AddCommentPopupContent.types';

export const AddCommentPopupContent: FC<AddCommentPopupContentProps> = memo((props) => {
    const { onSubmit } = props;

    const profile = useSelector(selectors.profile.findProfileUser);

    const form = useForm<z.infer<typeof schema>>({
        defaultValues: { comment: '' },
        resolver: zodResolver(schema),
    });

    const handleSubmit = form.handleSubmit(onSubmit);

    return (
        <Form form={form} onSubmit={handleSubmit}>
            <Popup.Header
                title={messages.popupTitle}
                actions={
                    <Button size='medium' color='blue10' type='submit'>
                        {messages.buttonText}
                    </Button>
                }
            />

            <Popup.Body>
                <Text font='body' spacing='0 0 24'>
                    {messages.description1}

                    <br />

                    {messages.description2}
                </Text>

                <Flex>
                    {profile && (
                        <Flex shrink={0}>
                            <Avatar
                                alt={`${profile.firstName} ${profile.lastName}`}
                                src={profile.avatar}
                                size={32}
                                color='blue10'
                            />
                        </Flex>
                    )}

                    <Flex grow={1}>
                        <TextField
                            name={formFieldNames.comment}
                            maxLength={commentMaxLength}
                            placeholder={messages.addCommentPlaceholder}
                            multiline
                            initFocus
                        />
                    </Flex>
                </Flex>
            </Popup.Body>
        </Form>
    );
});

AddCommentPopupContent.displayName = 'AddCommentPopupContent';
