import { atom } from 'jotai';
import { atomWithDefault } from 'jotai/utils';
import { domain } from 'modules/data';

type CompanyBetaFeatureStatus = {
    [K in domain.CompanyBetaFeature as `is${Capitalize<K>}`]: boolean;
};

export const companiesBetaFeaturesState = atom<Map<string, domain.CompanyBetaFeature[]>>(new Map());

/**
 * @deprecated Direct usage is prohibited. Please use a {@link useCompanyBetaFeatures} hook instead
 */
export const companyBetaFeaturesState = atomWithDefault((get) => {
    return (companyId: string | undefined) => {
        const companiesBetaFeatures = get(companiesBetaFeaturesState);
        const companyBetaFeatures = companyId ? companiesBetaFeatures.get(companyId) : [];

        // Do not move `allCompanyBetaFeatures` or `initialStatus` to the global scope.
        // Otherwise, errors occur in tests or runtime.
        const allCompanyBetaFeatures = Object.values(domain.CompanyBetaFeature);
        const initialStatus = Object.fromEntries(allCompanyBetaFeatures.map((feature) => [`is${feature}`, false]));

        companyBetaFeatures?.forEach((feature) => {
            const key = `is${feature}`;

            if (key in initialStatus) {
                initialStatus[key] = true;
            }
        });

        return initialStatus as CompanyBetaFeatureStatus;
    };
});
