import { mixins, mods, theme } from '@approvalmax/theme';
import { StyledMods } from '@styled-kit/mods';
import styled, { css } from 'styled-components';

import { stickyColumnZIndex } from '../../Table.constants';
import { stickyCellBorder, stickyShadow, verticalAlign } from '../../Table.mixins';
import { BaseItem, ColumnDefinition } from '../../Table.types';
import { BodyCellProps } from './BodyCell.types';

export const StyledCell = styled.td<
    StyledMods<
        Pick<BodyCellProps<BaseItem>, 'cursor' | 'invalid' | 'focus' | 'bordered' | 'disabled' | 'hover'> &
            Pick<ColumnDefinition<BaseItem>, 'sticky' | 'textAlign' | 'verticalAlign'> & {
                spacing?: string;
                withStickyShadow?: boolean;
            }
    >
>`
    padding: ${({ $spacing }) => $spacing};

    ${mods('sticky', 'left')`
        position: sticky;
        left: -1px; // -1 for Intersection Observer
        z-index: ${stickyColumnZIndex};
        background: ${theme.color.white100};

        ${mods('withStickyShadow', true)`
            ${mods.bordered(stickyCellBorder)};
            ${stickyShadow(false)}
        `};
    `}

    ${mods('sticky', 'right')`
        position: sticky;
        right: -1px; // -1 for Intersection Observer
        z-index: ${stickyColumnZIndex};
        background: ${theme.color.white100};

        ${mods('withStickyShadow', true)`
            ${mods.bordered(stickyCellBorder)};
            ${stickyShadow(true)}
        `};
    `}

    ${mods.textAlign(mixins.textAlign)};

    ${mods('verticalAlign')(verticalAlign)};

    ${mods.invalid.true`
        background: ${theme.color.red20} !important; // important is needed to override the row hover background color
        border: 1px solid ${theme.color.red100};
    `};

    ${mods.focus.true`
        border: 1px solid ${theme.color.blue100};
    `};

    ${mods.hover.true`
        &:focus,
        &:hover {
            border: 1px solid ${theme.color.blue100};
        }
    `};

    ${mods.disabled.true`
        background-color: ${theme.color.midnight30} !important; // important is needed to override the row hover background color
        color: ${theme.color.midnight70};
    `};

    ${mods('cursor')(
        (value) => css`
            cursor: ${value};
        `
    )};
`;
